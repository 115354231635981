import React, { useState } from "react";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import TodayAttendanceTable from "../components/TodayAttendanceTable";
import * as FiIcons from "react-icons/fi";

import GButton from "components/ui/button/Button";
import TodayAbsenceTable from "../components/TodayAbsenceTable";
import { useSelector } from "react-redux";
import GetAttendanceModal from "../modal/GetAttendanceModal";
import ErrorHandelText from "components/ui/errorHandel/ErrorHandelText";
import NoDataFound from "components/ui/noDataFound/NoDataFound";

const TodayAttendancePage = () => {
  const { t } = useTranslation();
  const [sortType, setSortType] = useState("attendance");
  const [showModal, setShowModal] = useState(false);

  const data = useSelector((state) => state.hrTodayAttendance);
  // console.log(data);
  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.today_attendance")}
          </Text>

          <Flex gap={2}>
            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => setShowModal(true)}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.hr.getAttendance")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>

      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <GButton
              title={t("pages.hr.attendance_list")}
              bg="none"
              borderRadius={0}
              onClick={() => setSortType("attendance")}
              color={sortType === "attendance" ? "primary" : "none"}
              borderBottom={sortType === "attendance" ? "2px solid" : "none"}
              borderColor={sortType === "attendance" ? "primary" : "none"}
            />
            <GButton
              title={t("pages.hr.absence_list")}
              bg="none"
              borderRadius={0}
              onClick={() => setSortType("absence")}
              color={sortType === "absence" ? "primary" : "none"}
              borderBottom={sortType === "absence" ? "2px solid" : "none"}
              borderColor={sortType === "absence" ? "primary" : "none"}
            />
          </Flex>

          {/* <GButton
            title={t("general.filter")}
            border="1px solid"
            borderColor="border"
            postfixElement={<FiIcons.FiFilter size={16} />}
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            //onClick={() => setShowFilterCategoriesModal(true)}
          /> */}
        </Flex>

        {sortType === "attendance" ? (
          <>
            {data?.isLoading ? (
              <Flex bg="light" p={4} justifyContent="center">
                <Spinner />
              </Flex>
            ) : data?.errors ? (
              <Flex bg="light" p={4} justifyContent="center">
                <ErrorHandelText />
              </Flex>
            ) : data?.TodayAttendance?.length === 0 ? (
              <NoDataFound />
            ) : (
              <TodayAttendanceTable data={data?.TodayAttendance} />
            )}
          </>
        ) : (
          <>
            {data?.isLoading ? (
              <Flex bg="light" p={4} justifyContent="center">
                <Spinner />
              </Flex>
            ) : data?.errors ? (
              <Flex bg="light" p={4} justifyContent="center">
                <ErrorHandelText />
              </Flex>
            ) : data?.TodayAbsence?.length === 0 ? (
              <NoDataFound />
            ) : (
              <TodayAbsenceTable data={data?.TodayAbsence} />
            )}
          </>
        )}
      </Box>

      {showModal && <GetAttendanceModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default TodayAttendancePage;
