import React from 'react';

const WelcomeHrPage = () => {
  return (
    <div>
      welcome hr
    </div>
  );
}

export default WelcomeHrPage;
