import { Box, Button, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import NoTimedEvents from "./NoTimedEvents";
import { useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({
  allTimedEvents,
  ListStatus,
  onClickCreateTimedLimitedBtn,
}) => {
  console.log("allTimedEvents", allTimedEvents);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ActiveTimedEvents = allTimedEvents.filter(
    (timedEvent) => timedEvent.IsActive === true
  );
  const InActiveTimedEvents = allTimedEvents.filter(
    (timedEvent) => timedEvent.IsActive === false
  );

  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const [currentPageInActive, setCurrentPageInActive] = useState(1);

  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const recordsPerPage = 12;

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  const recordsAll = calculateRecords(
    allTimedEvents,
    currentPageAll,
    recordsPerPage
  );
  const recordsActive = calculateRecords(
    ActiveTimedEvents,
    currentPageActive,
    recordsPerPage
  );
  const recordsInActive = calculateRecords(
    InActiveTimedEvents,
    currentPageInActive,
    recordsPerPage
  );
  return (
    <Fragment>
      {ListStatus === "all" && allTimedEvents.length > 0 && (
        <Fragment>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
            spacing={6}
            mt={6}
          >
            {recordsAll.map((timedEvent, index) => (
              <Box
                key={index}
                bg="light"
                p={6}
                borderRadius={4}
                boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
                onClick={() =>
                  navigate(
                    `/management/timed_events_details/${timedEvent.TimedEventId}`
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <Flex justifyContent="space-between">
                  <Text textTransform="capitalize">
                    {timedEvent?.TimedEventName || "-"}
                  </Text>
                </Flex>
                <Text textTransform="capitalize" color="text" mb="2">
                  {timedEvent?.Date}
                </Text>
                <Button
                  bg={timedEvent?.IsActive ? "#00D1D1" : "red"}
                  color="light"
                  _hover={"none"}
                  style={{ cursor: "default" }}
                >
                  <Text>
                    {timedEvent?.IsActive
                      ? t("general.activated")
                      : t("general.inactive")}
                  </Text>
                </Button>
              </Box>
            ))}
          </SimpleGrid>
          <Pagination
            currentPage={currentPageAll}
            setCurrentPage={setCurrentPageAll}
            npage={Math.ceil(allTimedEvents.length / recordsPerPage)}
            prePage={() => prePage(currentPageAll, setCurrentPageAll)}
            nextPage={() =>
              nextPage(
                currentPageAll,
                setCurrentPageAll,
                Math.ceil(allTimedEvents.length / recordsPerPage)
              )
            }
          />
        </Fragment>
      )}

      {ListStatus === "active" && ActiveTimedEvents.length > 0 && (
        <Fragment>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
            spacing={6}
            mt={6}
          >
            {recordsActive.map((timedEvent, index) => (
              <Box
                key={index}
                bg="light"
                p={6}
                borderRadius={4}
                boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
                onClick={() =>
                  navigate(
                    `/management/timed_events_details/${timedEvent.TimedEventId}`
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <Flex justifyContent="space-between">
                  <Text textTransform="capitalize">
                    {timedEvent?.TimedEventName || "-"}
                  </Text>
                </Flex>
                <Text textTransform="capitalize" color="text" mb="2">
                  {timedEvent?.Date}
                </Text>
                <Button
                  bg={timedEvent?.IsActive ? "#00D1D1" : "red"}
                  color="light"
                  _hover={"none"}
                  style={{ cursor: "default" }}
                >
                  <Text>
                    {timedEvent?.IsActive
                      ? t("general.activated")
                      : t("general.inactive")}
                  </Text>
                </Button>
              </Box>
            ))}
          </SimpleGrid>
          <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(ActiveTimedEvents.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(ActiveTimedEvents.length / recordsPerPage)
              )
            }
          />
        </Fragment>
      )}

      {ListStatus === "inactive" && InActiveTimedEvents.length > 0 && (
        <Fragment>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
            spacing={6}
            mt={6}
          >
            {recordsInActive.map((timedEvent, index) => (
              <Box
                key={index}
                bg="light"
                p={6}
                borderRadius={4}
                boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
                onClick={() =>
                  navigate(
                    `/management/timed_events_details/${timedEvent.TimedEventId}`
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <Flex justifyContent="space-between">
                  <Text textTransform="capitalize">
                    {timedEvent?.TimedEventName || "-"}
                  </Text>
                </Flex>
                <Text textTransform="capitalize" color="text" mb="2">
                  {timedEvent?.Date}
                </Text>
                <Button
                  bg={timedEvent?.IsActive ? "#00D1D1" : "red"}
                  color="light"
                  _hover={"none"}
                  style={{ cursor: "default" }}
                >
                  <Text>
                    {timedEvent?.IsActive
                      ? t("general.activated")
                      : t("general.inactive")}
                  </Text>
                </Button>
              </Box>
            ))}
          </SimpleGrid>
          <Pagination
            currentPage={currentPageInActive}
            setCurrentPage={setCurrentPageInActive}
            npage={Math.ceil(InActiveTimedEvents.length / recordsPerPage)}
            prePage={() => prePage(currentPageInActive, setCurrentPageInActive)}
            nextPage={() =>
              nextPage(
                currentPageInActive,
                setCurrentPageInActive,
                Math.ceil(InActiveTimedEvents.length / recordsPerPage)
              )
            }
          />
        </Fragment>
      )}
      {ListStatus === "all" && allTimedEvents.length === 0 && (
        <NoTimedEvents
          onClickCreateTimedLimitiedBtn={onClickCreateTimedLimitedBtn}
        />
      )}
      {((ListStatus === "inactive" && InActiveTimedEvents.length === 0) ||
        (ListStatus === "active" && ActiveTimedEvents.length === 0)) && (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}
    </Fragment>
  );
};

export default CTable;
