import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import {
  Button,
  Flex,
  Text,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import ExportModal from "components/ui/modal/ExportModal";
import InventoryLevelsTable from "./components/InventoryLevelsTable";
import { getInventoryLevelsReportList } from "./services/inventoryLevelsServices";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


export default function InventoryLevels() {

  const retrievedArray =useDecryptedPrivilege()
  const Auth = checkIdExists(retrievedArray, 65);


  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [exportModal, SetExportModal] = useState(false);

  const inventoryLevels = useSelector((state) => state.inventoryLevelsReport);

  useEffect(() => {
    dispatch(getInventoryLevelsReportList());
  }, [dispatch]);


  if(Auth===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.reports.inventory_reports.inventory_levels")}
          </Text>
          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
              <Text>{t("general.export")}</Text>
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>
      <>
        <InventoryLevelsTable
          data={inventoryLevels?.data}
          filteredData={inventoryLevels?.filteredData}
        />
      </>
      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={inventoryLevels.data}
          fileName={"inventorylevels"}
        ></ExportModal>
      )}
    </>
  );
}
