import {
  Box,
  Flex,
  Input,
  Stack,
  Text,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import { BiSelectMultiple } from "react-icons/bi";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  addNewLeaveRequest,
  getRequestTypeDropDown,
  getVactionChangeById,
} from "features/hr/selfServices/services/HrSelfServices";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default function FormTab({ onClose, UserId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  /* -------------------------------------------------------------------------- */
  const { employeeDropdown, RequestTypeDropdown, isLoadingRequestType } =
    useSelector((state) => state.HrSelfServices);
  /* -------------------------------------------------------------------------- */
  const [employeeIdSelected, setemployeeIdSelected] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [employeeVacationIdSelected, setemployeeVacationIdSelected] =
    useState(null);
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const ViewData = {
      EmployeeId: employeeIdSelected,
      VacationTypeId: employeeVacationIdSelected || 1,
      FromDate: fromDate,
      ToDate: toDate,
    };
    if (employeeVacationIdSelected) {
      dispatch(getVactionChangeById(ViewData));
    } else if (employeeIdSelected) {
      dispatch(getRequestTypeDropDown(employeeIdSelected))
        .unwrap()
        .then((_) => {
          dispatch(getVactionChangeById(ViewData));
        });
    }
  }, [dispatch, employeeIdSelected, employeeVacationIdSelected]);

  /* -------------------------------------------------------------------------- */
  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.UserId = UserId;

          if (
            values?.EmployeeId === undefined ||
            values?.VacationTypeId === undefined
          ) {
            toast2({
              description: t("validation.choose_first"),
            });
          } else {
            dispatch(addNewLeaveRequest(values))
              .unwrap()
              .then((res) => {
                toast({
                  description: t("pages.hr.leave_Request_Added"),
                });
                onClose();
              });
          }
        })}
      >
        <Stack spacing={6}>
          <Box>
            <MultiSelect
              title={t("pages.hr.self_service_object.select_employee")}
              isMulti={false}
              // value={getValues()?.EmployeeId}
              data={
                employeeDropdown &&
                employeeDropdown?.map((el) => ({
                  ...el,
                  label: el.EmployeeName,
                  value: el.EmployeeId,
                }))
              }
              onChange={(e) => {
                setValue("EmployeeId", e?.EmployeeId);
                setemployeeVacationIdSelected(null);
                setemployeeIdSelected(e?.EmployeeId);
              }}
            />
          </Box>
          {isLoadingRequestType ? (
            <Flex
              bg="light"
              p={4}
              gap={2}
              justifyContent="center"
              flexDir={"column"}
              alignItems={"center"}
            >
              <Spinner />
              <Text fontWeight={"bold"}>{t("general.loading")}</Text>
            </Flex>
          ) : (
            <>
              {employeeIdSelected ? (
                <>
                  <Box>
                    <MultiSelect
                      title={t("pages.hr.self_service_object.request_type")}
                      isMulti={false}
                      value={getValues()?.VacationTypeId}
                      data={
                        RequestTypeDropdown &&
                        RequestTypeDropdown?.map((el) => ({
                          ...el,
                          label: el.VacationTypeNameEn,
                          value: el.VacationTypeId,
                        }))
                      }
                      onChange={(e) => {
                        setValue("VacationTypeId", e?.VacationTypeId);
                        setemployeeVacationIdSelected(e?.VacationTypeId);
                      }}
                    />
                  </Box>

                  <FormControl isInvalid={errors.FromDate}>
                    <FormLabel
                      htmlFor="FromDate"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.extraction_start_date")}
                    </FormLabel>
                    <Controller
                      name="FromDate"
                      control={control}
                      // defaultValue={`${year}-${month}-${day}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.extraction_start_date")}
                          onChange={(e) => {
                            handleInputChange(e, "FromDate");
                            setFromDate(e?.target?.value);
                          }}
                          type="date"
                          value={getValues()?.FromDate}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.FromDate && errors.FromDate.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.ToDate}>
                    <FormLabel
                      htmlFor="ToDate"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.extraction_end_date")}
                    </FormLabel>
                    <Controller
                      name="ToDate"
                      control={control}
                      // defaultValue={`${year2}-${month2}-${day2}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.extraction_end_date")}
                          onChange={(e) => {
                            handleInputChange(e, "ToDate");
                            setToDate(e?.target?.value);
                          }}
                          type="date"
                          value={getValues()?.ToDate}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.ToDate && errors.ToDate.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Box>
                    <Text>{t("pages.hr.self_service_object.document")}</Text>
                    <Input
                      value={getValues()?.AttachmentDoc}
                      type="file"
                      placeholder={t("pages.hr.self_service_object.document")}
                      onChange={(e) => {
                        handleInputChange(e, "AttachmentDoc");
                      }}
                    />
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.self_service_object.comments")}</Text>
                    <Textarea
                      placeholder="Here is a sample placeholder"
                      marginTop={"2"}
                      value={getValues()?.VacationNotes}
                      onChange={(e) =>
                        setValue("VacationNotes", e.target.value)
                      }
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Flex display={"flex"} alignItems={"center"} gap={2}>
                    <Box color={"blue.500"}>
                      <BiSelectMultiple size={25} />
                    </Box>
                    <Text color={"blue.500"} fontSize={"14px"}>
                      {t("pages.hr.slectEmployee")}
                    </Text>
                  </Flex>
                </>
              )}
            </>
          )}

          <Flex gap={4}>
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{
                color: "primary",
                borderColor: "primary",
                bg: "none",
              }}
            />
            <GButton
              title={t("general.close")}
              onClick={onClose}
              bg="red.600"
              border="1px solid transparent"
              color="light"
              hover={{
                color: "red.600",
                borderColor: "red.600",
                bg: "none",
              }}
            />
          </Flex>
        </Stack>
      </form>
    </>
  );
}
