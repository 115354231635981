import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../../../services/axios";


  export const getDigitalWalletSettings = createAsyncThunk(
    "digitalWallet/getDigitalWalletSettings",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get("/Manage/Setting/DigitalWallet/ListDigitalWallet");
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );