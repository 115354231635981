import React, { useEffect } from 'react';
import GModal from "components/ui/modal/Modal"
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button"
import { Box,Flex, Stack, useToast } from "@chakra-ui/react"
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import { useDispatch, useSelector } from 'react-redux';
import { AddKitchenFlowCertinBranche, getBrancheKitchenFlow, getKitchenFlowBranches } from '../../services/kitchenFlowService';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';


const AddBrancheKitchenFlowModal = ({onClose}) => {
    const { t } = useTranslation();
    const dispatch=useDispatch();
    const id = useParams();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
    const { handleSubmit,setValue} = useForm();

    const inventoryCatData=[];
    const branchesData = useSelector(state => state.kitchenFlow);

    useEffect(()=>{
        dispatch(getBrancheKitchenFlow());
    },[dispatch]);

  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.kitchenFlow.add_branche")}
    modalContent={(

        <Box p={4}>
        
        <form onSubmit={handleSubmit(values=>{
            values.BranchId= values.BranchId?.BranchId  || null
            values.KitchenFlowsId=id?.id

            if(values.BranchId === null){
              toast2({ description: t("validation.choose_first") });
            }
            else {
              dispatch(AddKitchenFlowCertinBranche(values)).then(_ => {
                toast({ description: t("pages.kitchenFlow.branch_added") });
                dispatch(getKitchenFlowBranches(id.id))
                onClose()
              });
            }
        })}>
        
        <Stack spacing={6}>
        
        
        <MultiSelect
        title={t("pages.kitchenFlow.branches")}
        data={branchesData.branches && branchesData.branches.map(el => ({
          ...el,
          label: el.BranchName,
          value: el.BranchId
        }))}
        isMulti={false}
        isRequired={true}
        onChange={(e) => setValue("BranchId", e)}
      />


        <Flex gap={4}>
        <GButton
          type="submit"
          title={t("general.save")}
          bg="primary"
          color="light"
          border="1px solid transparent"
          hover={{ color: "primary", borderColor: "primary", bg: "none" }}
        />
        <GButton
          title={t("general.close")}
          onClick={onClose}
          bg="red.600"
          border="1px solid transparent"
          color="light"
          hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
        />
      </Flex>

        </Stack>
        
        </form>
        
        </Box>


    )}
    />
  );
}

export default AddBrancheKitchenFlowModal;
