import { createSlice } from "@reduxjs/toolkit";
import {getSuppliers,linkSupplierToItem,getSuppliersForItem} from "../services/supplieritemService"


let initialState = {
    isLoading: false,
    data: [],
    pages: 0,
    errors: null,
    itemSuppliers:[]
  };


  const suppliersItemSlice = createSlice({
    name: "suppliers",
    initialState,

    extraReducers: (builder) => {

        builder.addCase(getSuppliers.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getSuppliers.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.data)
            state.errors = null;
          })
      
          builder.addCase(getSuppliers.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //

          builder.addCase(linkSupplierToItem.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(linkSupplierToItem.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //console.log(payload)
            //state.data.push(payload);
            state.errors = null;
          })
          builder.addCase(linkSupplierToItem.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //

          builder.addCase(getSuppliersForItem.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getSuppliersForItem.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.itemSuppliers = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.itemSuppliers)
            state.errors = null;
          })
      
          builder.addCase(getSuppliersForItem.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
    }

  })

  export default suppliersItemSlice.reducer