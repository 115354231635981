import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import NoDiscounts from "./NoDiscounts";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import RestoreModal from "./modals/RestoreModal";
import { MdSettingsBackupRestore } from "react-icons/md";
import GButton from "components/ui/button/Button";
import Pagination from "components/ui/pagination/Pagination";

export default function CTable({
  discounts,
  discountsSelectedTable,
  onClickCreateDiscountBtn,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log("discounts", discounts);

  const filteredDiscounts = useSelector((state) => state.discounts);

  const deletedDiscountsFiltered = filteredDiscounts.filteredData.filter(
    (discount) => discount.IsDeleted === true
  );
  const activeDiscountsFiltered = filteredDiscounts.filteredData.filter(
    (discount) => discount.IsDeleted === false
  );

  const deletedDiscounts = discounts.filter(
    (discount) => discount.IsDeleted === true
  );
  const activeDiscounts = discounts.filter(
    (discount) => discount.IsDeleted === false
  );

  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState(null);
  const clickedbuttom = (id) => {
    setShowModel(true);
    setId(id);
  };

  const [currentPageActive, setCurrentPageActive] = useState(1);
  const [currentPageDeleted, setCurrentPageDeleted] = useState(1);
  const [filteredPageActive, setFilteredPageActive] = useState(1);
  const [filteredPageDeleted, setFilteredPageDeleted] = useState(1);

  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const recordsPerPage = 10;

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const recordsActive = calculateRecords(
    activeDiscounts,
    currentPageActive,
    recordsPerPage
  );

  // Calculate records for deletedDiscounts
  const recordsDeleted = calculateRecords(
    deletedDiscounts,
    currentPageDeleted,
    recordsPerPage
  );
  // Calculate records for deletedDiscounts
  const filteredRecordsActive = calculateRecords(
    activeDiscountsFiltered,
    filteredPageActive,
    recordsPerPage
  );
  // Calculate records for deletedDiscounts
  const filterdRecordsDeleted = calculateRecords(
    deletedDiscountsFiltered,
    filteredPageDeleted,
    recordsPerPage
  );

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      {filteredDiscounts.filteredData.length === 0 ? (
        <Fragment>
          {discountsSelectedTable === "all" && activeDiscounts.length > 0 && (
            <Fragment>
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>{t("pages.discounts.name")}</Th>
                    <Th>{t("pages.discounts.name_localized")}</Th>
                    <Th>{t("pages.discounts.reference")}</Th>
                    <Th>{t("pages.discounts.qualifications")}</Th>
                    <Th>{t("pages.discounts.discount")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {recordsActive.map((discount, index) => (
                    <Tr
                      key={discount.DisCountId}
                      onClick={() =>
                        navigate(
                          `/management/discount_details/${discount.DisCountId}`
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Td>{index + 1}</Td>
                      <Td>{discount.DisCountName}</Td>
                      <Td>{discount.DisCountNameLoc}</Td>
                      <Td>{discount.Reference}</Td>
                      <Td>
                        {
                          discount?.QualificationDiscountType[0]
                            ?.QualificationDiscountTypeName
                        }
                      </Td>
                      <Td>{discount.DiscountAmount}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                currentPage={currentPageActive}
                setCurrentPage={setCurrentPageActive}
                npage={Math.ceil(activeDiscounts.length / recordsPerPage)}
                prePage={() => prePage(currentPageActive, setCurrentPageActive)}
                nextPage={() =>
                  nextPage(
                    currentPageActive,
                    setCurrentPageActive,
                    Math.ceil(activeDiscounts.length / recordsPerPage)
                  )
                }
              />
            </Fragment>
          )}
          {discountsSelectedTable === "all" && activeDiscounts.length === 0 && (
            <NoDiscounts onClickCreateDiscountBtn={onClickCreateDiscountBtn} />
          )}
          {discountsSelectedTable === "deleted" &&
            deletedDiscounts.length > 0 && (
              <Fragment>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>{t("pages.discounts.name")}</Th>
                      <Th>{t("pages.discounts.name_localized")}</Th>
                      <Th>{t("pages.discounts.reference")}</Th>
                      <Th>{t("pages.discounts.qualifications")}</Th>
                      <Th>{t("pages.discounts.discount")}</Th>
                      <Th>{t("general.action")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {recordsDeleted.map((discount, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{discount.DisCountName}</Td>
                        <Td>{discount.DisCountNameLoc}</Td>
                        <Td>{discount.Reference}</Td>
                        <Td>
                          {
                            discount?.QualificationDiscountType[0]
                              ?.QualificationDiscountTypeName
                          }
                        </Td>
                        <Td>{discount.DiscountAmount}</Td>
                        <Td className="activeIcon">
                          <Flex>
                            <Box
                              color="#C53030"
                              _hover={{ color: "#38A169" }}
                              p={2}
                              width={"fit-content"}
                              cursor="pointer"
                              onClick={() => clickedbuttom(discount.DisCountId)}
                            >
                              <MdSettingsBackupRestore size={36} />
                            </Box>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Pagination
                  currentPage={currentPageDeleted}
                  setCurrentPage={setCurrentPageDeleted}
                  npage={Math.ceil(deletedDiscounts.length / recordsPerPage)}
                  prePage={() =>
                    prePage(currentPageDeleted, setCurrentPageDeleted)
                  }
                  nextPage={() =>
                    nextPage(
                      currentPageDeleted,
                      setCurrentPageDeleted,
                      Math.ceil(deletedDiscounts.length / recordsPerPage)
                    )
                  }
                />
              </Fragment>
            )}
          {discountsSelectedTable === "deleted" &&
            deletedDiscounts.length === 0 && (
              <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
                color="text"
                textTransform="capitalize"
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                borderRadius={6}
              >
                {t("pages.branches.no_data")}
              </Flex>
            )}
        </Fragment>
      ) : (
        <>
          {discountsSelectedTable === "all" &&
            activeDiscountsFiltered.length > 0 && (
              <Fragment>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>{t("pages.discounts.name")}</Th>
                      <Th>{t("pages.discounts.name_localized")}</Th>
                      <Th>{t("pages.discounts.reference")}</Th>
                      <Th>{t("pages.discounts.qualifications")}</Th>
                      <Th>{t("pages.discounts.discount")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredRecordsActive.map((discount, index) => (
                      <Tr
                        key={discount.DisCountId}
                        onClick={() =>
                          navigate(
                            `/management/discount_details/${discount.DisCountId}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Td>{index + 1}</Td>
                        <Td>{discount.DisCountName}</Td>
                        <Td>{discount.DisCountNameLoc}</Td>
                        <Td>{discount.Reference}</Td>
                        <Td>
                          {
                            discount?.QualificationDiscountType[0]
                              ?.QualificationDiscountTypeName
                          }
                        </Td>
                        <Td>{discount.DiscountAmount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Pagination
                  currentPage={filteredPageActive}
                  setCurrentPage={setFilteredPageActive}
                  npage={Math.ceil(
                    activeDiscountsFiltered.length / recordsPerPage
                  )}
                  prePage={() =>
                    prePage(filteredPageActive, setFilteredPageActive)
                  }
                  nextPage={() =>
                    nextPage(
                      filteredPageActive,
                      setFilteredPageActive,
                      Math.ceil(activeDiscountsFiltered.length / recordsPerPage)
                    )
                  }
                />
              </Fragment>
            )}
          {discountsSelectedTable === "all" &&
            activeDiscountsFiltered.length === 0 && (
              <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
                color="text"
                textTransform="capitalize"
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                borderRadius={6}
              >
                {t("pages.branches.no_data")}
              </Flex>
            )}
          {discountsSelectedTable === "deleted" &&
            deletedDiscountsFiltered.length > 0 && (
              <Fragment>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>{t("pages.discounts.name")}</Th>
                      <Th>{t("pages.discounts.name_localized")}</Th>
                      <Th>{t("pages.discounts.reference")}</Th>
                      <Th>{t("pages.discounts.qualifications")}</Th>
                      <Th>{t("pages.discounts.discount")}</Th>
                      <Th>{t("general.action")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filterdRecordsDeleted.map((discount, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{discount.DisCountName}</Td>
                        <Td>{discount.DisCountNameLoc}</Td>
                        <Td>{discount.Reference}</Td>
                        <Td>
                          {
                            discount?.QualificationDiscountType[0]
                              ?.QualificationDiscountTypeName
                          }
                        </Td>
                        <Td>{discount.DiscountAmount}</Td>
                        <Td className="activeIcon">
                          <Flex>
                            <Box
                              color="#C53030"
                              _hover={{ color: "#38A169" }}
                              p={2}
                              width={"fit-content"}
                              cursor="pointer"
                              onClick={() => clickedbuttom(discount.DisCountId)}
                            >
                              <MdSettingsBackupRestore size={36} />
                            </Box>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Pagination
                  currentPage={filteredPageDeleted}
                  setCurrentPage={setFilteredPageDeleted}
                  npage={Math.ceil(
                    deletedDiscountsFiltered.length / recordsPerPage
                  )}
                  prePage={() =>
                    prePage(filteredPageDeleted, setFilteredPageDeleted)
                  }
                  nextPage={() =>
                    nextPage(
                      filteredPageDeleted,
                      setFilteredPageDeleted,
                      Math.ceil(
                        deletedDiscountsFiltered.length / recordsPerPage
                      )
                    )
                  }
                />
              </Fragment>
            )}
          {discountsSelectedTable === "deleted" &&
            deletedDiscountsFiltered.length === 0 && (
              <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
                color="text"
                textTransform="capitalize"
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                borderRadius={6}
              >
                {t("pages.branches.no_data")}
              </Flex>
            )}
        </>
      )}
      {showModel && (
        <RestoreModal id={id} onClose={() => setShowModel(false)} />
      )}
    </Box>
  );
}
