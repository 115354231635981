import { createSlice } from "@reduxjs/toolkit";
import {
  getGiftsCards,
  getGiftsCardById,
  createGiftsCard,
  updateGiftsCard,
  //deleteGiftsCard,
  deleteGiftCard,
  getFilteredData,
  resetFilteredData,
  activeDeActiveGiftsCards,
} from "../services/giftsCardsService";

let initialState = {
  isLoading: false,
  data: [],
  giftsCard: null,
  pages: 0,
  errors: null,
  filteredData:[],
};

const giftsCardsSlice = createSlice({
  name: "giftsCards",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getGiftsCards.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getGiftsCards.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getGiftsCards.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getGiftsCardById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getGiftsCardById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.giftsCard =
        (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    });
    builder.addCase(getGiftsCardById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(createGiftsCard.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createGiftsCard.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createGiftsCard.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(updateGiftsCard.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(updateGiftsCard.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(
        (giftsCard) => giftsCard.id === payload.id
      );
      state.data[indexAt] = payload;
      state.errors = null;
    });
    builder.addCase(updateGiftsCard.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(deleteGiftCard.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteGiftCard.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(
        (giftsCard) => giftsCard.id !== payload.id
      );
      state.errors = null;
    });
    builder.addCase(deleteGiftCard.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    builder.addCase(getFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      console.log("youssef" , state.filteredData);
      state.errors = null;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ------------------------------------------------- Active DeActive Gifts Cards --------------------------------------------- */
    builder.addCase(activeDeActiveGiftsCards.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(activeDeActiveGiftsCards.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(activeDeActiveGiftsCards.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default giftsCardsSlice.reducer;
