import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Get Drawer Operations Report List ------------------------------- */
  export const getDrawerOperationsReportList = createAsyncThunk("drawerOperationsReport/getDrawerOperationsReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetDrawerOperationsReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

 /* --------------------------------------- Drawer Operations  Filter Data ------------------------------*/
 export const getDrawerOperationsReportFilteredData = createAsyncThunk("drawerOperationsReport/getDrawerOperationsReportFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetDrawerOperationsReportFilteredData = createAsyncThunk( "drawerOperationsReport/resetDrawerOperationsReportFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);