import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getModifiersOptions = createAsyncThunk(
  "modifiersOptions/getModifiersOptions",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Menu/Modifers/ModiferOptionList");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getModifiersOptionById = createAsyncThunk(
  "modifiersOptions/getModifiersOptionById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/ModiferOption/ModiferOptionById?modiferoptionid=${JSON.stringify({ ModiferOptionId: args.modifiersOptionId })}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createModifiersOption = createAsyncThunk(
  "modifiersOptions/createModifiersOption",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/ModiferOption/AddModiferOption?modiferoption=${JSON.stringify(args)}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateModifiersOption = createAsyncThunk(
  "modifiersOptions/updateModifiersOption",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/ModiferOption/EditModiferOption?modiferoptionid=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteModifiersOption = createAsyncThunk(
  "modifiersOptions/deleteModifiersOption",
  async (args, thunkApi) => {
    try {
      console.log(args)
      //const { data } = await axios.get(`/modifiersOptions/${args.id}`);
      const { data } = await axios.get(`/Menu/ModiferOption/DeleteModiferOptionById?modiferoptionid={"ModiferOptionId":${args}}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const addCustomPriceModifierOption= createAsyncThunk(
  "modifiersOptions/addCustomPriceModifierOption",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/ModiferOption/AddModiferOptionCustomPrice?modiferoptionprice=${JSON.stringify(args)}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const activateModifier = createAsyncThunk(
  "modifiersOptions/activateModifier",
  async (args, thunkApi) => {
    try {
      console.log(args)
      //const { data } = await axios.get(`/modifiersOptions/${args.id}`);
      const { data } = await axios.get(`/Menu/ModiferOption/DeActivateModiferOptionById?modiferoptionid={"ModiferOptionId":${args}}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getFilteredData = createAsyncThunk(
  "modifiersOptions/getFilteredData ",
  async (args, thunkApi) => {
    
    try {
      console.log("jooo", args);
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const resetFilteredData = createAsyncThunk(
  "modifiersOptions/resetFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);