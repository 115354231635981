import { createSlice } from "@reduxjs/toolkit";
import { getCostTypes } from "../services/costTypesService";

let initialState = {
  isLoading: false,
  data: [],
  product: null,
  pages: 0,
  errors: null
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCostTypes.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCostTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getCostTypes.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default productsSlice.reducer;