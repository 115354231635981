import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Box, Flex, SimpleGrid, Spinner, Stack, Text,Button, useToast } from "@chakra-ui/react"
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb"
import UpdateModal from "../components/modals/UpdateModal"
import GButton from "components/ui/button/Button"
import {  activateModifier, getModifiersOptionById } from "../services/modifiersOptionsService"
import DeleteCompoModifier from '../components/DeleteCompoModifier'
import CustomPrice from '../components/CustomPrice'
import OutOfStockModifier from '../components/OutOfStockModifier'
import DeactaiveInSomeBranches from '../components/DeactaiveInSomeBranches'
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege '

const Details = () => {
 
  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  // <LinkModifierToProduct></LinkModifierToProduct>
  // import LinkModifierToProduct from '../components/LinkModifierToProduct'

  const { modifiersOptionId } = useParams();
  const dispatch = useDispatch();
  const modifiersOptions = useSelector(state => state.modifiersOptions);

  //console.log(modifiersOptions)

  const { t } = useTranslation();

  const modifiersOption = modifiersOptions.modifiersOption;

  const [showUpdateModifiersOptionModal, setShowUpdateModifiersOptionModal] = useState(null);

  useEffect(() => {
    dispatch(getModifiersOptionById({ modifiersOptionId }));
  }, [dispatch, modifiersOptionId]);

  const id = useParams();

  
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  //console.log(id.modifiersOptionId)
  const activeFunction=()=>{
    dispatch(activateModifier(id.modifiersOptionId)).unwrap()
    .then((_) => {
      dispatch(getModifiersOptionById({ modifiersOptionId }));
      toast({
        description: t(
          "pages.modifiers_options.modifiers_option_created"
        ),
      });

    });
  }

  
  //console.log(modifiersOption)



  // useEffect(()=>{
  //   dispatch(getCustomPriceModifierOptionById())
  // },[dispatch])

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  if(showUpdateModifiersOptionModal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowUpdateModifiersOptionModal(false)
  }


  if (modifiersOptions.isLoading) return <Spinner />

  return modifiersOption ? (
    <>
      <BreadCrumb>
        <Flex justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">
          <Box
            as="p" fontSize="1.6rem" textTransform="capitalize"
          >
            {modifiersOption?.ModiferOptionNameAr || "-"}
            <Button
            // title=
            bg={modifiersOption.IsActive ? "#00D1D1" : "red"}
            color="light"
            _hover={"none"}
            style={{ cursor: "default" }}
          >
            <Text>
              {modifiersOption.IsActive
                ? t("general.activated")
                : t("general.inactive")}
            </Text>
          </Button>
          </Box>
         


          <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between",gap:"10px"}}>
          

          <GButton
          title={t("general.edit")}
          bg="#54A0FF"
          color="light"
          hover={{ bg: "primary" }}
          onClick={() => setShowUpdateModifiersOptionModal({ ...modifiersOption, ModifiersOptionId: +modifiersOptionId || null })}
        />
        
        
        <GButton
        title={
          modifiersOption.IsActive ? t("general.deactive") : t("general.active")
        }
        bg="#54A0FF"
        color="light"
        hover={{ bg: "primary" }}
        onClick={() => activeFunction()}
      />
          
          
          </Box>
     

        </Flex>
      </BreadCrumb>
      <Stack spacing={12}>
        {/* details */}
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4} borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.modifiers_options.name")}
            </Text>
            <Text marginBlock={2}>
              {modifiersOption?.ModiferOptionNameEng || "-"}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.modifiers_options.name_localized")}
            </Text>
            <Text marginBlock={2}>
              {modifiersOption?.ModiferOptionNameAr || "-"}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.modifiers.modifiers")}
          </Text>
          <Text marginBlock={2}>
            {modifiersOption?.ProductCostType[0].Modifers[0].ModifersNameEng || "-"}
          </Text>
        </Box>
          <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.inventory.costing_method")}
          </Text>
          <Text marginBlock={2}>
            {modifiersOption?.ProductCostType[0].ProductCostTypeName || "-"}
          </Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.modifiers_options.name_localized")}
            </Text>
            <Text marginBlock={2}>
              {modifiersOption?.ProductCostType[0].Modifers[0].TaxGroup[0].TaxGroupName || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.modifiers_options.price")}
            </Text>
            <Text marginBlock={2}>
              {modifiersOption?.ModiferOptionPrice || "-"}
            </Text>
          </Box>

        </SimpleGrid>
        <CustomPrice></CustomPrice>
        <OutOfStockModifier></OutOfStockModifier>
        <DeactaiveInSomeBranches></DeactaiveInSomeBranches>
        <DeleteCompoModifier></DeleteCompoModifier>
      </Stack>

      {showUpdateModifiersOptionModal && (
        <UpdateModal
          data={showUpdateModifiersOptionModal}
          onClose={() => setShowUpdateModifiersOptionModal(null)}
        />
      )}
     
    </>
  ) : (
    <Flex bg="light" alignItems="center" justifyContent="center" p={4} textTransform="capitalize">
      {t("pages.modifiers_options.no_modifiers_option")}
    </Flex>
  )
}

export default Details