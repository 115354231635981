import React, { useEffect } from 'react';
import GModal from "components/ui/modal/Modal";
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from "react-hook-form";
import {Box, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import Input from "components/form/Input";
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { editDeliveryZone, getAllBranchesDeliverZone, getDeliveryZoneDetails } from '../../services/deliveryZoneNewService';
import { useParams } from 'react-router-dom';


const EditDeliveryZoneModal = ({onClose}) => {
    const { t } = useTranslation();
      const dispatch=useDispatch();
      const id =useParams();
      const toast2 = useToast({position: "top",duration: 3000, status: "error",});
      const toast = useToast({ position: "top", duration: 3000, status: "success" });
      const { handleSubmit, control, setValue ,  formState: { errors },  trigger} = useForm();

      const data = useSelector(state => state.deliveryZoneNew);
      console.log(data);

      useEffect(()=>{
          dispatch(getAllBranchesDeliverZone())
      },[dispatch])

     const handleInputChange = async (e, type) => {
      setValue(type, e.target.value);
      await trigger(type);
    };
  
    const isOnlySpaces = (value) => {
      // Check if the value contains only spaces
      return !value.trim();
    };

  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.DeliverZone.edit_delivery_zone")}
    modalContent={
        <Box p={4}>
        
        <form onSubmit={handleSubmit(values=>{
            values.RefCode= data?.deliveryZoneDetails[0]?.RefCode
            values.BranchId=values.BranchId?.BranchId
            values.DeliveryZoneId=id?.id
            if(values.BranchId===undefined ){
              toast2({ description: t("validation.choose_first")  });
            }else{
                dispatch(editDeliveryZone(values)).unwrap().then(_ => {
                toast({ description: t("pages.DeliverZone.item_updated")  });
                dispatch(getDeliveryZoneDetails(id.id))
                onClose()
              });

            }
        })}>
        
        <Stack spacing={6}>
        
        
      <FormControl isInvalid={errors.DeliveryZoneName}>
          <FormLabel
            htmlFor="DeliveryZoneName"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.DeliverZone.name")}
          </FormLabel>
          <Controller
            name="DeliveryZoneName"
            control={control}
            defaultValue={data?.deliveryZoneDetails[0]?.DeliveryZoneName}
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(e) => {
                  handleInputChange(e, "DeliveryZoneName");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.DeliveryZoneName &&
              errors.DeliveryZoneName.message}
          </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.DeliveryZoneNameLoc}>
          <FormLabel
            htmlFor="DeliveryZoneNameLoc"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.DeliverZone.name_localized")}
          </FormLabel>
          <Controller
            name="DeliveryZoneNameLoc"
            control={control}
            defaultValue={data?.deliveryZoneDetails[0]?.DeliveryZoneNameLoc}
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(e) => {
                  handleInputChange(e, "DeliveryZoneNameLoc");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.DeliveryZoneNameLoc &&
              errors.DeliveryZoneNameLoc.message}
          </FormErrorMessage>
      </FormControl>

    <Input
    label={t("pages.DeliverZone.Coordinates")}
    labelStyle={{ textTransform: "capitalize" }}
    placeholder={t("pages.DeliverZone.Coordinates")}
    name="Coordinates"
    isFormController
    control={control}
    isRequired={true}
  />

    <Input
          label={t("pages.DeliverZone.refrance")}
          labelStyle={{ textTransform: "capitalize" }}
          placeholder={data?.deliveryZoneDetails[0]?.RefCode}
          name="RefCode"
          isFormController
          control={control}
          disabled
    />


    <MultiSelect
          title={t("pages.DeliverZone.Branches")}
          data={data.branchesDeliverZone && data.branchesDeliverZone.map(el => ({
          ...el,
          label: el.BranchName,
          value: el.BranchId
          }))}
          isMulti={false}
          isRequired={true}
          onChange={(e) => setValue("BranchId", e)}
    />


<Flex gap={4}>
<GButton
  type="submit"
  title={t("general.save")}
  bg="primary"
  color="light"
  border="1px solid transparent"
  //isLoading={categories.isLoading}
  hover={{
    color: "primary",
    borderColor: "primary",
    bg: "none",
  }}
/>
<GButton
  title={t("general.close")}
  onClick={onClose}
  bg="red.600"
  border="1px solid transparent"
  color="light"
  //isLoading={categories.isLoading}
  hover={{
    color: "red.600",
    borderColor: "red.600",
    bg: "none",
  }}
/>
</Flex>

        
        </Stack>
        
        </form>
        
        </Box>
    }
    />
  );
}

export default EditDeliveryZoneModal;
