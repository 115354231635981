import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spinner,
} from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  editNationalityInfo,
  getEmployeeById,
} from "../../services/employeeServices";

export default function EditNationalityModal({
  id,
  onClose,
  nationalityDropDown,
  details,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  // ----------  Start Default Date   -------
  const dateExtractionDate = details?.ExtractionDate;
  const [day, month, year] = dateExtractionDate?.split("-");
  const dateEndDate = details?.EndDate;
  const [day2, month2, year2] = dateEndDate?.split("-");
  const datePassportStartDate = details?.PassportStartDate;
  const [day3, month3, year3] = datePassportStartDate?.split("-");
  const datePassportEndDate = details?.PassportEndDate;
  const [day4, month4, year4] = datePassportEndDate?.split("-");
  // ----------  End Default Date   -------

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, []);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.change_identity_info")}
        modalContent={
          showContent ? (
            <Box p={4}>
              <form
                onSubmit={handleSubmit((values) => {
                  values.EmployeeId = id;
                  values.NationalityId =
                    values.NationalityId?.NationalityId ||
                    details?.NationalityId;

                  if (values?.NationalityId === undefined) {
                    toast2({ description: t("validation.choose_first") });
                  } else {
                    dispatch(editNationalityInfo(values))
                      .unwrap()
                      .then((_) => {
                        toast({
                          description: t(
                            "pages.hr.nationality_info_updated_successfuly"
                          ),
                        });
                        dispatch(getEmployeeById(id));
                        onClose();
                      });
                  }
                })}
              >
                <Stack spacing={6}>
                  <FormControl isInvalid={errors.NationalID}>
                    <FormLabel
                      htmlFor="NationalID"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.National_id")}
                    </FormLabel>
                    <Controller
                      name="NationalID"
                      control={control}
                      defaultValue={details.NationalID}
                      rules={{
                        required: t("validation.required"),
                        minLength: {
                          value: 10,
                          message: t("pages.hr.hr_validation.number_short_10"),
                        },
                        maxLength: {
                          value: 15,
                          message: t("pages.hr.hr_validation.number_long"),
                        },
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.National_id")}
                          onChange={(e) => {
                            handleInputChange(e, "NationalID");
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.NationalID && errors.NationalID.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.NationalIDPlace}>
                    <FormLabel
                      htmlFor="NationalIDPlace"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.extraction_place")}
                    </FormLabel>
                    <Controller
                      name="NationalIDPlace"
                      control={control}
                      defaultValue={details?.NationalIDPlace}
                      rules={{
                        required: t("validation.required"),
                        minLength: {
                          value: 2,
                          message: t("validation.name_long_2"),
                        },
                        maxLength: {
                          value: 25,
                          message: t("validation.name_long_25"),
                        },
                        pattern: {
                          value: /^[\p{L}\s]+$/u,
                          message: t("validation.this_name_invalid"),
                        },
                        validate: {
                          noOnlySpaces: (value) =>
                            !isOnlySpaces(value) ||
                            t("validation.no_only_spaces"),
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.extraction_place")}
                          onChange={(e) => {
                            handleInputChange(e, "NationalIDPlace");
                          }}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.NationalIDPlace && errors.NationalIDPlace.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.StartDate}>
                    <FormLabel
                      htmlFor="StartDate"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.extraction_start_date")}
                    </FormLabel>
                    <Controller
                      name="StartDate"
                      control={control}
                      defaultValue={`${year}-${month}-${day}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.extraction_start_date")}
                          onChange={(e) => {
                            handleInputChange(e, "StartDate");
                          }}
                          type="date"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.StartDate && errors.StartDate.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.EndDate}>
                    <FormLabel
                      htmlFor="EndDate"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.extraction_end_date")}
                    </FormLabel>
                    <Controller
                      name="EndDate"
                      control={control}
                      defaultValue={`${year2}-${month2}-${day2}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.extraction_end_date")}
                          onChange={(e) => {
                            handleInputChange(e, "EndDate");
                          }}
                          type="date"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.EndDate && errors.EndDate.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.PassportNo}>
                    <FormLabel
                      htmlFor="PassportNo"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.passport_No")}
                    </FormLabel>
                    <Controller
                      name="PassportNo"
                      control={control}
                      defaultValue={details?.PassportNo}
                      rules={{
                        required: t("validation.required"),
                        minLength: {
                          value: 10,
                          message: t("pages.hr.hr_validation.number_short_10"),
                        },
                        maxLength: {
                          value: 15,
                          message: t("pages.hr.hr_validation.number_long"),
                        },
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.passport_No")}
                          onChange={(e) => {
                            handleInputChange(e, "PassportNo");
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.PassportNo && errors.PassportNo.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.PassportStartDate}>
                    <FormLabel
                      htmlFor="PassportStartDate"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.passport_start_date")}
                    </FormLabel>
                    <Controller
                      name="PassportStartDate"
                      control={control}
                      defaultValue={`${year3}-${month3}-${day3}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.passport_start_date")}
                          onChange={(e) => {
                            handleInputChange(e, "PassportStartDate");
                          }}
                          type="date"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.PassportStartDate &&
                        errors.PassportStartDate.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.PassportEndDate}>
                    <FormLabel
                      htmlFor="PassportEndDate"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.passport_end_date")}
                    </FormLabel>
                    <Controller
                      name="PassportEndDate"
                      control={control}
                      defaultValue={`${year4}-${month4}-${day4}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.passport_end_date")}
                          onChange={(e) => {
                            handleInputChange(e, "PassportEndDate");
                          }}
                          type="date"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.PassportEndDate && errors.PassportEndDate.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Box>
                    <MultiSelect
                      title={t("pages.hr.nationality")}
                      isMulti={false}
                      defaultValue={{
                        label:
                          nationalityDropDown[details?.NationalityId - 1]
                            ?.NationalityNameEn,
                      }}
                      data={
                        nationalityDropDown &&
                        nationalityDropDown?.map((el) => ({
                          ...el,
                          label: el.NationalityNameEn,
                          value: el.NationalityId,
                        }))
                      }
                      onChange={(e) => setValue("NationalityId", e)}
                    />
                  </Box>

                  <Flex gap={4}>
                    <GButton
                      type="submit"
                      title={t("general.save")}
                      bg="primary"
                      color="light"
                      border="1px solid transparent"
                      hover={{
                        color: "primary",
                        borderColor: "primary",
                        bg: "none",
                      }}
                    />
                    <GButton
                      title={t("general.close")}
                      onClick={onClose}
                      bg="red.600"
                      border="1px solid transparent"
                      color="light"
                      hover={{
                        color: "red.600",
                        borderColor: "red.600",
                        bg: "none",
                      }}
                    />
                  </Flex>
                </Stack>
              </form>
            </Box>
          ) : (
            <Flex bg="light" p={4} justifyContent="center">
              <Spinner />
            </Flex>
          )
        }
      ></GModal>
    </>
  );
}
