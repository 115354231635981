import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getAcoountCycleNew = createAsyncThunk(
  "AccountAdd/getAcoountCycleNew",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Accountant/GetFinancialPeriodsList?data=${JSON.stringify(args)}`
      );
      // console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createAccountCycel = createAsyncThunk(
  "AccountAdd/createAccountCycel",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `Accountant/AddFinancialPeriods?financialperiods=${JSON.stringify(
          args
        )}`
      );
      console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const CloseFinancialPeriods = createAsyncThunk(
  "AccountAdd/CloseFinancialPeriods",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Accountant/IsCloseFinancialPeriodsById?financialperiodsid=${JSON.stringify(
          args
        )}`
      );
      console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
