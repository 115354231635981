import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const getCustomerPhoneNumber = createAsyncThunk(
    "callcenter/getCustomerPhoneNumber",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Customers/LookupCustomerPhone`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getorderTypesforcallCenter = createAsyncThunk(
    "callcenter/getorderTypesforcallCenter",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpOrderTypes`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );




  export const getAgents = createAsyncThunk(
    "callcenter/getAgents",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUsers`);
        //console.log("123", data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ------------------------------------------------------- Get Branches For CallCenter ---------------------------------------------- */
  export const getBranchesForCallCenter = createAsyncThunk( "callcenter/getBranchesForCallCenter", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getMenuGroupForCallCenter = createAsyncThunk(
    "callcenter/getMenuGroupForCallCenter",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpMenuGroup`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );




  export const getPaymentMethodForCallCenter = createAsyncThunk(
    "callcenter/getPaymentMethodForCallCenter",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/CallCenter/LookupPaymentMethodType`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getPaymentModes = createAsyncThunk(
    "callcenter/getPaymentModes",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpPayment`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ------------------------------------------------- Create Call Center Order ------------------------------------------------- */
  export const createCallCenterOrder = createAsyncThunk( "callcenter/createCallCenterOrder" , async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/CallCenter/Order/AddOrder?order=${JSON.stringify(args)}`);
       console.log(data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const modifayCallCenterState = createAsyncThunk( "callcenter/modifayCallCenterState" , async (args, thunkApi) => {
    try {
    //  const { data } = await axios.get(`/CallCenter/Order/AddOrder?order=${JSON.stringify(args)}`);
    //  console.log(data)
     return thunkApi.fulfillWithValue(args);
    } catch (e) {
      console.log(e)
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
 

  export const customerDetails = createAsyncThunk( "callcenter/customerDetails" , async (args, thunkApi) => {
    try {
     //console.log(args)
     return thunkApi.fulfillWithValue(args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const GetAllCatigoresWithProducts = createAsyncThunk( "callcenter/GetAllCatigoresWithProducts" , async (args, thunkApi) => {
  try {
   const { data } = await axios.get(`/Orders/Orders/FY10?data=${JSON.stringify(args)}`);
   //console.log(data)
   return thunkApi.fulfillWithValue(data);
  } catch (e) {
    console.log(e)
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);




