import { Box } from "@chakra-ui/react";
import React from "react";
import BranchChart from "./BranchCart";

const BranchTrendCard = ({ data, date }) => {
  return (
    <Box
      bg="light"
      borderRadius={4}
      marginBottom={6}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      padding={15}
    >
      <div style={{ display: "flex", gap: "50px", flexDirection: "column" }}>
        <div>
          <h1 style={{ fontWeight: "bold" }}>{data.BranchName}</h1>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100px",
            }}
          >
            <h1>{data.FixedIncome}</h1>
            <h1>{data.PercentageIncome}%</h1>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <BranchChart date={date} data={data.PercentageIncome} />
        </div>
      </div>
    </Box>
  );
};

export default BranchTrendCard;
