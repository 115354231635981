import React from 'react';
import GRestoreModal from "components/ui/modal/RestoreModal";
import { useDispatch } from 'react-redux';
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { getInventoryCategoriesList, restoreInventoryCategories } from '../../services/InventoryCategoriesServices';

const RestoreModal = ({onClose,id}) => {
  const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();

  return (
    <GRestoreModal
        onClose={onClose}
        onSubmit={()=>{
          dispatch(restoreInventoryCategories(id)).unwrap().then(_ => {
            toast({ description: t("pages.inventoryCategories.inventoryCategories_Restored") });
            onClose();
            dispatch(getInventoryCategoriesList());
          })
        }}  
  />
  );
}

export default RestoreModal;

