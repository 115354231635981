import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  createNewCostAdjustment,
  getCostAdjustmentBranch,
  getCostAdjustmentList,
} from "../../services/costAdjustment";

export default function CreateCostAdjustment({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue } = useForm();

  const { costAdjustmentBranch, isLoading } = useSelector(
    (state) => state.costAdjustment
  );

  useEffect(() => {
    dispatch(getCostAdjustmentBranch());
  }, [dispatch]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.cost_adjustment.create_new")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.UserId = localStorage.getItem("userId");
                if (values.BranchId === undefined) {
                  toast2({ description: t("validation.choose_first") });
                } 
                else {
                  values.BranchId = values.BranchId.BranchId;
                  dispatch(createNewCostAdjustment(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.cost_adjustment.cost_created"),
                      });
                      dispatch(getCostAdjustmentList());
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <MultiSelect
                  title={t("pages.cost_adjustment.branch")}
                  isMulti={false}
                  data={
                    costAdjustmentBranch &&
                    costAdjustmentBranch.map((el) => ({
                      ...el,
                      label: el.BranchName,
                      value: el.BranchId,
                    }))
                  }
                  onChange={(e) => setValue("BranchId", e)}
                />
                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    isLoading={isLoading}
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    isLoading={isLoading}
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
