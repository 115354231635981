import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useParams } from "react-router-dom";
import {
  deleteBranchInDiscount,
  getDiscountsBranches,
} from "../../services/discountsBranchesService";
import {
  deleteCategoryInDiscount,
  getDiscountsCategories,
} from "../../services/discountsCategoriesService";
import {
  deleteProductInDiscount,
  getDiscountsProducts,
} from "../../services/discountsProductsService";
import {
  deleteComboInDiscount,
  getDiscountsCombos,
} from "../../services/discountsCombosService";
import {
  deleteProductTagInDiscount,
  getDiscountsProductTags,
} from "../../services/discountsProductTagsService";
import {
  deleteCustomerTagInDiscount,
  getDiscountLimitCustomerTags,
} from "../../services/discountsLimitCustomerTagsService";

const DeleteAppliesModal = ({ id, onClose, type }) => {
  console.log("id", id);
  const ID = useParams();
  const { t } = useTranslation();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const dispatch = useDispatch();

  const submissionFunctions = {
    Branch: () => {
      dispatch(deleteBranchInDiscount(id.DisCountBranchesId))
        .unwrap()
        .then((_) => {
          toast({ description: t("pages.discounts.branch_discount_deleted") });
          dispatch(getDiscountsBranches(ID.id));
          onClose();
        });
    },
    Cateogry: () => {
      dispatch(deleteCategoryInDiscount(id.DisCountProductCatgoryId))
        .unwrap()
        .then((_) => {
          toast({
            description: t("pages.discounts.category_discount_deleted"),
          });
          dispatch(getDiscountsCategories(ID.id));
          onClose();
        });
    },
    Product: () => {
      dispatch(deleteProductInDiscount(id.DisCountProductId))
        .unwrap()
        .then((_) => {
          toast({ description: t("pages.discounts.product_discount_deleted") });
          dispatch(getDiscountsProducts(ID.id));
          onClose();
        });
    },
    Combo: () => {
      dispatch(deleteComboInDiscount(id.DisCountComboId))
        .unwrap()
        .then((_) => {
          toast({ description: t("pages.discounts.combo_discount_deleted") });
          dispatch(getDiscountsCombos(ID.id));
          onClose();
        });
    },
    ProductTag: () => {
      dispatch(deleteProductTagInDiscount(id.DisCountProductTagId))
        .unwrap()
        .then((_) => {
          toast({
            description: t("pages.discounts.product_tag_discount_deleted"),
          });
          dispatch(getDiscountsProductTags(ID.id));
          onClose();
        });
    },
    CusomerTag: () => {
      dispatch(deleteCustomerTagInDiscount(id.DisCountCustomerTagId))
        .unwrap()
        .then((_) => {
          toast({
            description: t("pages.discounts.customer_tag_discount_deleted"),
          });
          dispatch(getDiscountLimitCustomerTags(ID.id));
          onClose();
        });
    },
  };

  const onSubmit =
    type in submissionFunctions ? submissionFunctions[type] : undefined;

  return <GDeleteModal onClose={onClose} onSubmit={onSubmit} />;
};

export default DeleteAppliesModal;
