import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import { useTranslation } from 'react-i18next';
import AddStationModal from './modal/AddStationModal';
import { useDispatch, useSelector } from 'react-redux';
import { getKitchenFlowStations } from '../services/kitchenFlowService';
import { useParams } from 'react-router-dom';
import StationTable from './StationTable';

const StationKitchenFlow = () => {
    const { t } = useTranslation();
    const [showModal,setShowModal]=useState(false)
    const dispatch=useDispatch()
    const id =useParams()
    useEffect(()=>{
      dispatch(getKitchenFlowStations(id.id))
    },[dispatch,id])

    const stationData = useSelector(state => state.kitchenFlow);

    //console.log(stationData)
  return (
    <Box>
    <Flex
    alignItems="center" justifyContent="space-between"
    gap={2} flexWrap="wrap" mb={6}
  >
    <Text color="text" textTransform="capitalize">
      {t("pages.kitchenFlow.station")}
    </Text>
    <GButton
      title={t("pages.kitchenFlow.add_station")}
      bg="light"
      color="text"
      fontSize="14px"
      border="1px solid"
      borderColor="border"
      hover={{ bg: "light" }}
      onClick={() => setShowModal( true)}
    />
  </Flex>


  
  
  {stationData.isLoading ? (
    <Spinner />
  ) : stationData.stations?.length === 0 ? (
    <Flex
      p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
    >
      {t("pages.branches.no_data")}
    </Flex>
  ) : stationData.stations?.length > 0 ? (
    <StationTable
    data={stationData.stations}
    />
  ):(
    <Flex
    p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  >
    {t("pages.branches.no_data")}
  </Flex>
  )
}



  



 {
    showModal&&(
        <AddStationModal onClose={() => setShowModal(false)}></AddStationModal>
    )

 }



    </Box>
  );
}

export default StationKitchenFlow;
