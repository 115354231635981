import React, { useEffect, useState } from 'react';
import { Box, Flex, Text,Spinner, useToast } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button"

import AddSupplierModal from './modals/AddSupplierModal';
import { useDispatch, useSelector } from 'react-redux';

import {getSuppliersForItem} from "../services/supplieritemService"
import { useParams } from 'react-router-dom';
import SupplierTable from './SupplierTable';
import { checkIdExists } from 'utils/cheackAuth';
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


const SupplierItem = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageItems = checkIdExists(retrievedArray, 10);
  
    const { t } = useTranslation();
    const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);

    const dispatch=useDispatch()

    const id = useParams()

    useEffect(()=>{
      dispatch(getSuppliersForItem(id.itemId))
    },[dispatch,id])

    const suppliers = useSelector(state => state.suppliers);
    //console.log(suppliers.itemSuppliers)

    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


    if(showCreateSupplierModal===true && ManageItems===false){
      toast2({ description:  t("general.authorization")})
      setShowCreateSupplierModal(false)
    }
  return (
    <Box>
        <Flex
            alignItems="center" justifyContent="space-between"
            gap={2} flexWrap="wrap" mb={6}
        >

            <Text color="text" textTransform="capitalize">
                {t("pages.inventory_item_details.suppliers")}
            </Text>
  
            <GButton
                title={t("pages.inventory_item_details.link_supplier")}
                bg="light"
                color="text"
                fontSize="14px"
                border="1px solid"
                borderColor="border"
                hover={{ bg: "light" }}
                onClick={() => setShowCreateSupplierModal(true)}
            />
         
            
            
        {showCreateSupplierModal && (
            <AddSupplierModal
            //data={showCreateSectionModal}
            onClose={() => setShowCreateSupplierModal(false)}
            />
        )}
        
        </Flex>

       

        {suppliers.isLoading ? (
          <Spinner />
        ) : suppliers.itemSuppliers?.length === 0 ? (
          <Flex
            p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
          >
            {t("pages.branches.no_data")}
          </Flex>
        ) : suppliers.itemSuppliers?.length > 0?  (
          <SupplierTable
          suppliersData={suppliers.itemSuppliers}
          />
        ):(
          <Flex
          p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
        )
      }
       
    </Box>
  );
}

export default SupplierItem;
