import GModal from "components/ui/modal/Modal";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/form/Input";
import {
  getCallcenter,
  editTransferOrders,
  getTransfersOrdersDetails,
  getWareHouseDropdown,
} from "../../services/transfersOrdersService";

const EditTransferOrderModal = ({ onClose, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, control, setValue } = useForm();

  const productionsData = useSelector((state) => state.transfersorders);

  useEffect(() => {
    dispatch(getCallcenter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWareHouseDropdown());
  }, [dispatch]);
  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.transfers_orders.edit_transfer_order")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              if (
                values.WarehouseId === undefined ||
                values.DestinationId === undefined
              ) {
                toast2({ description: t("validation.choose_first") });
              } else {
                values.WarehouseId = values.WarehouseId.WarehouseId;
                values.DestinationId = values.DestinationId.BranchId;
                values.TransferOrdersId = id.TransferOrdersId;
                dispatch(editTransferOrders(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.transfers_orders.updated_successfuly"
                      ),
                    });
                    dispatch(getTransfersOrdersDetails(id));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.transfers_orders.warehouse")}
                data={productionsData.warehouseDropdown.map((el) => ({
                  ...el,
                  label: el.Name,
                  value: el.WarehouseId,
                }))}
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("WarehouseId", e)}
              />

              <MultiSelect
                title={t("pages.transfers_orders.destination")}
                data={productionsData.DropData.map((el) => ({
                  ...el,
                  label: el.BranchName,
                  value: el.BranchId,
                }))}
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("DestinationId", e)}
              />

              <Input
                label={t("pages.transfers_orders.notes")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.transfers_orders.notes")}
                name="Notes"
                isFormController
                control={control}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default EditTransferOrderModal;
