import React, { useEffect } from 'react';
import GModal from "components/ui/modal/Modal"
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import { useDispatch, useSelector } from 'react-redux';
import { AddProductKitchenFlow, getAllProductKitchenFlow, getKitchenFlowProduct } from '../../services/kitchenFlowService';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';


const AddProductKitchenFlowModal = ({onClose}) => {
  const dispatch=useDispatch();
  const { t } = useTranslation();
  const id =useParams();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit,    setValue,     register,     control    } = useForm();

    const branchesData=[]

    const productData = useSelector(state => state.kitchenFlow);

    useEffect(()=>{
        dispatch(getAllProductKitchenFlow())
    },[dispatch])

  return (
    <GModal
    
    onClose={onClose}
    modalHeader={t("pages.kitchenFlow.Add_products")}
    modalContent={(

        <Box p={4}>
        
        <form onSubmit={handleSubmit(values=>{
            values.ProductId=values.ProductId?.ProductId  || null  ;
            values.KitchenFlowsId=id?.id ;

            if(values.ProductId === null){
              toast2({ description: t("validation.choose_first") });
            }
            else {
            dispatch(AddProductKitchenFlow(values)).then(_ => {
              toast({ description: t("pages.kitchenFlow.product_added") });
              dispatch(getKitchenFlowProduct(id.id))
                onClose()
              });
            } ;
        })}>

        
        <Stack spacing={6}>
        
        
        <MultiSelect
        title={t("pages.kitchenFlow.products")}
        data={productData.products && productData.products.map(el => ({
          ...el,
          label: el.ProductName,
          value: el.ProductId
        }))}
        isMulti={false}
        isRequired={true}
        onChange={(e) => setValue("ProductId", e)}
      />


        <Flex gap={4}>
        <GButton
          type="submit"
          title={t("general.save")}
          bg="primary"
          color="light"
          border="1px solid transparent"
          //isLoading={devices.isLoading}
          hover={{ color: "primary", borderColor: "primary", bg: "none" }}
        />
        <GButton
          title={t("general.close")}
          onClick={onClose}
          bg="red.600"
          border="1px solid transparent"
          color="light"
          //isLoading={devices.isLoading}
          hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
        />
      </Flex>
        
        </Stack>

        </form>

        
        
        </Box>


    )}
    />
  );
}

export default AddProductKitchenFlowModal;
