import React, { useState } from "react";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";
import NoDataFound from "components/ui/noDataFound/NoDataFound";

export default function AbsenceDays({ absenceList }) {
  const { t } = useTranslation();
  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (absenceList, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(
      currentPage * recordsPerPage,
      absenceList?.length
    );
    return absenceList?.slice(firstIndex, lastIndex) || [];
  };

  // Calculate records for activeDiscounts
  const absenceListData = calculateRecords(
    absenceList,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return (
    <>
      <Table>
        <Thead bg={"blue.500"}>
          <Tr>
            <Th color={"white"}>#</Th>
            <Th color={"white"}>{t("pages.hr.Employee_code")}</Th>
            <Th color={"white"}>{t("pages.hr.Employee_name")}</Th>
            <Th color={"white"}>{t("pages.hr.job_title")}</Th>
            <Th color={"white"}>{t("pages.hr.employee_department")}</Th>
            <Th color={"white"}>{t("pages.hr.date")}</Th>
          </Tr>
        </Thead>

        {absenceList?.length > 0 ? (
          <Tbody>
            {absenceListData?.map((item, index) => (
              <Tr key={index}>
                <Td> {index + 1} </Td>
                <Td>{item?.Code}</Td>
                <Td>{item?.Name}</Td>
                <Td>{item?.Title}</Td>
                <Td>{item?.Departmen}</Td>
                <Td>{item?.Date}</Td>
              </Tr>
            ))}
          </Tbody>
        ) : (
          ""
        )}
      </Table>

      <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(absenceList?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(absenceList?.length / recordsPerPage)
          )
        }
      />

      {absenceList?.length === 0 && (
        <>
          <NoDataFound />
        </>
      )}
    </>
  );
}
