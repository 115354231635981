import React, { useEffect } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
  Input as ChInput,
  Input,
} from "@chakra-ui/react";
import { generator } from "utils/generator";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { Controller, useForm } from "react-hook-form";
import { getProfileData, updateProfileData } from "../services/profileServices";

export default function ProfileSection({data}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const profileID = localStorage.getItem("userId");

  // const data = useSelector((state) => state.profile);
  const {   handleSubmit,   control,   getValues,   watch,   setValue,  formState: { errors },  trigger,  setError,  clearErrors, } = useForm();
  watch(["loginPin"]);
  
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
      setError("ParLevel", {
        type: "manual",
        message: t("validation.must_be_within_min_max_range"),
      });
    } else {
      clearErrors("ParLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };
  
  if(data?.length === 0){
    return(
        console.log("")
      )
  };

    return (
      <>
        <form
          onSubmit={handleSubmit((values) => {
            if (values.languageId === undefined) {
              toast2({ description: t("validation.choose_first") });
            } else {
              values.userLoginId = data?.userLoginId
                values.languageId = values?.languageId?.LanguageId;
                dispatch(updateProfileData(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.profile.profile_updated") });
                    dispatch(getProfileData(profileID));
                  });
              }
          })}
        >
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <FormControl isInvalid={errors.userFullName}>
              <FormLabel
                htmlFor="userFullName"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.categories.name")}
              </FormLabel>
              <Controller
                name="userFullName"
                control={control}
                defaultValue={data?.userFullName}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.categories.name")}
                    onChange={(e) => {
                      handleInputChange(e, "userFullName");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.userFullName &&
                  errors.userFullName.message}
              </FormErrorMessage>
            </FormControl>
  
            <FormControl isInvalid={errors.phone}>
              <FormLabel htmlFor="phone" style={{ textTransform: "capitalize" }}>
              {t("pages.profile.phone")}
              </FormLabel>
              <Controller
                name="phone"
                control={control}
                defaultValue={data?.phone || "+ 02"}
                rules={{
                  required: t("validation.required"),
                  pattern: {
                    value: /^[0-9]+$/,
                    message: t("validation.only_numbers_allowed"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.profile.phone")}
                    onChange={(e) => {
                      handleInputChange(e, "phone");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
  
            <FormControl isInvalid={errors.email}>
                  <FormLabel
                    htmlFor="email"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.profile.email")}
                  </FormLabel>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={data?.email}
                    rules={{
                      required: t("validation.required"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t("validation.invalid_email"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) || t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.profile.email")}
                        onChange={(e) => {
                          handleInputChange(e, "email");
                        }}
                        type="email"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {" "}
                    {errors.email && errors.email.message}{" "}
                  </FormErrorMessage>
                </FormControl>
  
            <FormControl isInvalid={errors.loginPin}>
              <FormLabel
                htmlFor="loginPin"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.profile.loginpin")}
              </FormLabel>
  
                <Flex alignItems="center" gap={4}>
                  <Input
                    label={t("pages.profile.loginpin")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={data?.loginPin}
                    isRequired
                    value={getValues("loginPin") || ""}
                    readOnly
                  />
                  <GButton
                    title={t("pages.inventory.generate_sku")}
                    height="50px"
                    bg="light"
                    border="1px solid"
                    borderColor="border"
                    hover={{
                      bg: "primary",
                      borderColor: "primary",
                      color: "light",
                    }}
                    onClick={() => setValue("loginPin", generator({}))}
                  />
                </Flex>
  
            </FormControl>
  
            <MultiSelect
              title={t("pages.profile.language")}
              data={data?.languageDropDown?.map((el) => ({
                ...el,
                label: el.LanguageNameEng,
                value: el.LanguageId,
              }))}
              onChange={(e) => setValue("languageId", e)}
              isMulti={false}
            />
  
          </SimpleGrid>
          <Box bg={"white"}  paddingY={5} paddingX={5}>
              <Flex gap={2} flexWrap="wrap">
              <GButton
                  title={t("pages.profile.save_changes")}
                  type="submit"
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              />
              </Flex>
          </Box>
        </form>
      </>
    );
}
