import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function DetailsTable({ data }) {
  const { t } = useTranslation();
  console.log("data", data);

  return (
    <>
      <SimpleGrid
        spacing={6}
        columns={{ base: 1, md: 2 }}
        bg="white"
        p={4}
        borderRadius={6}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        {/* name */}
        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.timedEvents.name")}
          </Text>
          <Text marginBlock={2}>
            {data?.TimedEventName ? data?.TimedEventName : "-"}
          </Text>
        </Box>

        {/* name Localized */}
        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.timedEvents.name_localized")}
          </Text>
          <Text marginBlock={2}>
            {data?.TimedEventNameLoc ? data?.TimedEventNameLoc : "-"}
          </Text>
        </Box>

        {/* date */}
        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.timedEvents.date")}
          </Text>
          <Text marginBlock={2}>
            {data?.StartDate && data?.EndDate ? data?.Date : "-"}
          </Text>
        </Box>

        {/* time */}
        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.timedEvents.time")}
          </Text>
          <Text marginBlock={2}>
            {data?.StartTime && data?.EndTime ? data?.Time : "-"}
          </Text>
        </Box>

        {/* fixed price */}
        {data?.EventTypeId === 1 && (
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.timedEvents.fixed_price")}
            </Text>
            <Text marginBlock={2}>
              {data?.Amount ? `SAR ${data?.Amount}` : "-"}
            </Text>
          </Box>
        )}
      </SimpleGrid>
    </>
  );
}
