import React from "react";
import { useTranslation } from "react-i18next";
import routes from "navigations/routes";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";

export default function AnalysisReports(){
  const { t, i18n } = useTranslation();
  const getArrowIcon = () => {
    return i18n.language === "ar" ? (
      <MdKeyboardArrowLeft size={25} /> // Right arrow for Arabic
    ) : (
      <MdKeyboardArrowRight size={25} /> // Left arrow for English
    );
  };

  const items = [
    {
      id: 1,
      text: t("pages.reports.analysis_reports.menu_engineering"),
      icon: getArrowIcon(),
      path: routes.reports_analysis_reports_menu_engineering,
    },
    {
      id: 2,
      text: t("pages.reports.analysis_reports.inventory_cost_analysis"),
      icon: getArrowIcon(),
      path: routes.reports_analysis_reports_inventory_cost_analysis,
    },
    {
      id: 3,
      text: t("pages.reports.analysis_reports.branches_trend"),
      icon: getArrowIcon(),
      path: routes.reports_analysis_reports_branches_trend,
    },
    {
      id: 4,
      text: t("pages.reports.analysis_reports.speed_of_services"),
      icon: getArrowIcon(),
      path: routes.reports_analysis_reports_speed_of_services,
    },
    {
      id: 5,
      text: t("pages.reports.analysis_reports.product_cost"),
      icon: getArrowIcon(),
      path: routes.reports_analysis_reports_product_cost,
    },
    {
      id: 6,
      text: t("pages.reports.analysis_reports.modifier_options_cost"),
      icon: getArrowIcon(),
      path: routes.reports_analysis_reports_modifier_options_cost,
    },
    {
      id: 7,
      text: t("pages.reports.analysis_reports.inventory_items_cost"),
      icon: getArrowIcon(),
      path: routes.reports_analysis_reports_inventory_items__cost,
    },
  ];

  return (
    <>
      <BreadCrumb>
        <Box as="p" fontSize="1.6rem" textTransform="capitalize">
          {t("pages.reports.analysis_reports.analysis_reports")}
        </Box>
      </BreadCrumb>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
        {items.map((item) => (
          <Flex
            key={item.id}
            as={NavLink}
            to={`/${item.path}`}
            gap={2}
            alignItems="center"
            justifyContent="center"
            bg="light"
            p={4}
            borderRadius={4}
            paddingBlock={6}
            color="text"
            textTransform="capitalize"
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
          >
            {item.text}
            {item.icon}
          </Flex>
        ))}
      </SimpleGrid>
    </>
  );
};

