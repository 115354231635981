import { createSlice } from "@reduxjs/toolkit";
import {
  getCoupons,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  getFilteredData,
  resetFilteredData,
  getDiscountsCoupons,
  createBulkCoupon,
  activateCoupon,
  getCouponById,
  getAllDiscountsForCoupons,
} from "../services/couponsService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  filteredData: [],
  discounts: [],
  couponDetails: null,
  allDiscounts: [],
};

const couponsSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    clearDiscounts: (state) => {
      state.discounts = [];
    },
  },
  extraReducers: (builder) => {
    /* ------------------------------------------------------- Get Coupons  -------------------------------------------------------------------*/
    builder.addCase(getCoupons.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getCoupons.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getCoupons.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create Coupon  -------------------------------------------------------------------*/
    builder.addCase(createCoupon.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createCoupon.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createCoupon.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create Bulk Coupon  -------------------------------------------------------------------*/
    builder.addCase(createBulkCoupon.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createBulkCoupon.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createBulkCoupon.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Edit Coupons  -------------------------------------------------------------------*/
    builder.addCase(updateCoupon.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(updateCoupon.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(
        (coupon) => coupon.id === payload.id
      );
      state.data[indexAt] = payload;
      state.errors = null;
    });
    builder.addCase(updateCoupon.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Coupons  -------------------------------------------------------------------*/
    builder.addCase(deleteCoupon.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteCoupon.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deleteCoupon.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* -------------------------------------------------------  Get coupons Discounts List  -------------------------------------------------------------------*/
    builder.addCase(getDiscountsCoupons.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDiscountsCoupons.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.discounts = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getDiscountsCoupons.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -----------------------------------------------  Activate Coupon  --------------------------------------- */
    builder.addCase(activateCoupon.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(activateCoupon.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(activateCoupon.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------------- Get Coupon By ID (Details) --------------------------------------- */
    builder.addCase(getCouponById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getCouponById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.couponDetails =
        (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    });
    builder.addCase(getCouponById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Get all coupons Discounts List  -------------------------------------------------------------------*/
    builder.addCase(getAllDiscountsForCoupons.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getAllDiscountsForCoupons.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.allDiscounts =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(getAllDiscountsForCoupons.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export const { clearDiscounts } = couponsSlice.actions;

export default couponsSlice.reducer;
