import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import ProductPricesPerBranchesModal from "./modals/ProductPricesPerBranchesModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBranchesOfProduct } from "../services/customPriceService";
import CustomPriceLevelBranche from "./CustomPriceLevelBranche";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const CustomPriceBranche = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [customPriceBranche, setCustomPriceBranche] = useState(false);

  const branchesOfProduct = useSelector((state) => state.customPrice);
  //console.log(branchesOfProduct);

  useEffect(() => {
    dispatch(getBranchesOfProduct(id.productId));
  }, [dispatch, id]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(customPriceBranche && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setCustomPriceBranche(false)
  }

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.inventory.custom_level")}
        </Text>

        <GButton
          title={t("pages.inventory.select_branche")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setCustomPriceBranche(true)}
        />
      </Flex>

      {branchesOfProduct.isLoading ? (
        <Spinner />
      ) : branchesOfProduct.priceBranche?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : branchesOfProduct.priceBranche?.length > 0 ? (
        <CustomPriceLevelBranche
          data={branchesOfProduct.priceBranche}
        />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {customPriceBranche && (
        <ProductPricesPerBranchesModal
          onClose={() => setCustomPriceBranche(false)}
        />
      )}
    </Box>
  );
};

export default CustomPriceBranche;
