import { createSlice } from "@reduxjs/toolkit";
import {
  getGroups,
  getGroupById,
  createGroup,
  updateGroup,
  deleteGroup,
  getFilteredData,
  resetFilteredData,
} from "../services/groupsService";

let initialState = {
  isLoading: false,
  data: [],
  group: null,
  pages: 0,
  errors: null,
  filteredData:[]
  // subGroupData: []
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getGroups.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getGroups.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getGroups.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getGroupById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getGroupById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.group = (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    })
    builder.addCase(getGroupById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(group => group.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(group => group.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });



    
  }
});

export default groupsSlice.reducer;