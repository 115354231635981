import { Controller } from "react-hook-form";
import { Box, Input as ChInput, FormControl, FormLabel, Text } from "@chakra-ui/react"

const Input = ({
  //label
  label,
  labelStyle = {},

  // input
  type = "text",
  name,
  isFormController = false,
  control,
  isRequired = false,
  onChange,
  height = "50px",
  border = "1px solid",
  borderColor = "border",
  borderRadius = 6,
  bg = "transparent",

  //placeholder
  placeholder,
  placeholderStyle = {},

  //validation
  rules = {},

  //errors
  error,

  ...res
}) => {
  const CLabel =
    <FormLabel
      requiredIndicator={<Box color="red" fontSize="1.2rem" display="inline-block" ms={1}>*</Box>}
      {...labelStyle}
    >
      {label}
    </FormLabel>;

  const CInput = ({ value, onChange, onBlur }) => (
    <ChInput
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value || ""}
      onBlur={onBlur}
      border={border}
      borderColor={borderColor}
      borderRadius={borderRadius}
      height={height}
      bg={bg}
      _focus={{ borderColor: "primary" }}
      _placeholder={{ ...placeholderStyle }}
      {...res}
      autoComplete="new-password"    />
  );


  if (isFormController) return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, onBlur } }) => (
        <FormControl isRequired={isRequired}>
          {label && CLabel}
          {CInput({ value, onBlur,onChange: onChange })}
          {error && (
            <Text fontSize=".9rem" color="red" textTransform="capitalize">
              {error.message}
            </Text>
          )}
        </FormControl>
      )}
    />
  );

  return (
    <FormControl isRequired={isRequired}>
      {label && CLabel}
      {CInput({ onChange: onChange })}
    </FormControl>
  )
}

export default Input