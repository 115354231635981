import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useForm } from "react-hook-form"
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import Input from "components/form/Input"
import GButton from "components/ui/button/Button"
import { createSection, getSectiones } from "../../services/sectionsService"
import { useParams } from "react-router-dom"

export default function CreateSectionModal ({ data,onClose }){
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {handleSubmit,control,formState: { errors },
  trigger,setValue,} = useForm();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  
  const sections = useSelector(state => state.sections);

  const { branchId } = useParams();

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.branches.create_section")}
        modalContent={(
          <Box p={4}>
            <form onSubmit={handleSubmit(values => {
              values.BranchId = data.branchId;
              dispatch(createSection(values))
                .unwrap()
                .then(_ => {
                  toast({ description: t("pages.branches.section_created") });
                  dispatch(getSectiones({branchId}))
                  onClose()
                });
            })}>
              <Stack spacing={6}>
            

                <FormControl isInvalid={errors.BranchSectionName}>
                <FormLabel
                  htmlFor="BranchSectionName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.branches.name")}{" "}
                </FormLabel>
                <Controller
                  name="BranchSectionName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.branches.name")}
                      onChange={(e) => handleInputChange(e, "BranchSectionName")}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.BranchSectionName && errors.BranchSectionName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.BranchSectionTable}>
              <FormLabel
                htmlFor="BranchSectionTable"
                style={{ textTransform: "capitalize" }}
              >
              {t("pages.branches.tables")}
                            </FormLabel>
              <Controller
                name="BranchSectionTable"
                control={control}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.branches.tables")}
                    onChange={(e) => {
                      handleInputChange(e, "BranchSectionTable");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.BranchSectionTable && errors.BranchSectionTable.message}
              </FormErrorMessage>
            </FormControl>


            

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    isLoading={sections.isLoading}
                    hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    isLoading={sections.isLoading}
                    hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        )}
      />
  </>
};