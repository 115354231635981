import { createSlice } from "@reduxjs/toolkit";
import {
  deletePositionById,
  editPositionById,
  getDepartmentDropdown,
  getJobTitleDropdown,
  getPositionDetailsById,
  getPositionDropdown,
  getPositionsList,
  getReportingToPositionDropdown,
} from "../services/positionsServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  positionList: [],
  positionDetails: [],
  // DropDowns
  DepartmentDropdown: [],
  JobTitleDropdown: [],
  PositionDropdown: [],
  ReportingToPositionDropdown: [],

  // Filter data
  filteredData: [],
};

const positionsSlice = createSlice({
  name: "positions",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get Classifications dropDown -------------------------------------------- */
    builder.addCase(getPositionsList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPositionsList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.positionList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getPositionsList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------- Get Classifications dropDown -------------------------------------------- */
    builder.addCase(
      getReportingToPositionDropdown.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.errors = null;
      }
    );
    builder.addCase(
      getReportingToPositionDropdown.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.ReportingToPositionDropdown =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(
      getReportingToPositionDropdown.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      }
    );
    // /* ----------------------------------------------  Get Department Dropdown -------------------------------------------*/
    builder.addCase(getDepartmentDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDepartmentDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.DepartmentDropdown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getDepartmentDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* ----------------------------------------------  Get Job Title Dropdown -------------------------------------------*/
    builder.addCase(getJobTitleDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getJobTitleDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.JobTitleDropdown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getJobTitleDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* ----------------------------------------------  Get Positio nDropdown  -------------------------------------------*/
    builder.addCase(getPositionDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPositionDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.PositionDropdown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getPositionDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* ----------------------------------------------  Get Position Details  -------------------------------------------*/
    builder.addCase(getPositionDetailsById.pending, (state, { payload }) => {
      // state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPositionDetailsById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.positionDetails =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getPositionDetailsById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* ----------------------------------------------  Edit Position  -------------------------------------------*/
    builder.addCase(editPositionById.pending, (state, { payload }) => {
      // state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editPositionById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editPositionById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* ----------------------------------------------  Deleted Vacation  -------------------------------------------*/
    builder.addCase(deletePositionById.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deletePositionById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deletePositionById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default positionsSlice.reducer;
