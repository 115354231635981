import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import InActiveBrancheModal from "./modals/InActiveBrancheModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBranchesOfGift } from "../services/inActiveBranchesService";
import InActiveLevelBranche from "./InActiveLevelBranche";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const InActiveBranche = () => {


  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);


  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showInActiveBranche, setShowInActiveBranche] = useState(false);

  const branchesOfGift = useSelector((state) => state.inActiveGiftCards);

  useEffect(() => {
    dispatch(getBranchesOfGift(id.giftsCardId));
  }, [dispatch, id]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(showInActiveBranche && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowInActiveBranche(false)
  }


  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.inventory.custom_level")}
        </Text>

        <GButton
          title={t("pages.inventory.select_branche")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowInActiveBranche(true)}
        />
      </Flex>

      {branchesOfGift.isLoading ? (
        <Spinner />
      ) : branchesOfGift.branchesOfGift?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : branchesOfGift.branchesOfGift?.length > 0 ? (
        <InActiveLevelBranche
          branchesOfGiftCards={branchesOfGift.branchesOfGift}
        />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {showInActiveBranche && (
        <InActiveBrancheModal onClose={() => setShowInActiveBranche(false)} />
      )}
    </Box>
  );
};

export default InActiveBranche;
