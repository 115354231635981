import React  from 'react';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";


const QuantityItemTable = ({data}) => {
    const { t } = useTranslation();

    return (
    <Box
    bg="light" overflow="auto"
    borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >

    <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.quantity_adjustment.name")}</Th>
            <Th>{t("pages.quantity_adjustment.name_Localized")}</Th>
            <Th>{t("pages.quantity_adjustment.quantity")}</Th>
            <Th>sku</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((el, index) => (
            <Tr key={index}  style={{cursor:"pointer"}}>
              <Td> {index + 1} </Td>
              <Td >{el.InventoryItemNameEng}</Td>
              <Td>{el.InventoryItemNameAr}</Td>
              <Td>{el.DeductedQuantity}</Td>
              <Td>{el.SUK}</Td>
            </Tr>
          ))}
        </Tbody>
    </Table>

  </Box>
  );
}

export default QuantityItemTable;
