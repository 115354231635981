import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { getFilteredData } from "../../services/modifiersOptionsService";
import { getModifiers } from "features/products/modifiers/services/modifiersService";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Input from "components/form/Input";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getTaxesGroups } from "features/management/taxesGroups/services/taxesGroupsService";

const FilterModal = ({ onClose, data }) => {
  console.log("al data", data);
  const [filterCriteria, setFilterCriteria] = useState({
    ModiferOptionNameEng: "",
    ModiferOptionNameAr: "",
    ModifersNameEng: "",
    TaxGroupName: "",
    Price: "",
  });
  const [filteredData, setFilteredData] = useState(data);
  // const toast = useToast({
  //   position: "top",
  //   duration: 3000,
  //   status: "success",
  // });
  const modifiers = useSelector((state) => state.modifiers);
  const taxesGroups = useSelector((state) => state.taxesGroups);
  console.log("all categories", taxesGroups);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleFilterGiftsCard = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const {
        ModiferOptionNameEng,
        ModiferOptionNameAr,
        ModifersNameEng,
        TaxGroupName,
        Price,
      } = filterCriteria;

      console.log("wala", filterCriteria);

      // Create variables to hold filter conditions
      let filterByNameEng = true;
      let filterByNameLoc = true;
      let filterByProductCategoriesNameEng = true;
      let filterBySUK = true;
      let filterByPrice = true;

      // Check if the filter criteria are defined, and if so, apply the filter
      if (ModiferOptionNameEng) {
        filterByNameEng = item.ModiferOptionNameEng.toLowerCase().includes(
          ModiferOptionNameEng.toLowerCase()
        );
      }

      if (ModiferOptionNameAr) {
        filterByNameLoc = item.ModiferOptionNameAr.toLowerCase().includes(
          ModiferOptionNameAr.toLowerCase()
        );
      }

      if (ModifersNameEng) {
        filterByProductCategoriesNameEng =
          item.ModifersNameEng.toLowerCase().includes(
            ModifersNameEng.toLowerCase()
          );
      }

      if (TaxGroupName) {
        filterBySUK = item.TaxGroupName.toLowerCase().includes(
          TaxGroupName.toLowerCase()
        );
      }

      if (Price) {
        filterByPrice = item.Price === parseInt(Price);
      }

      // Return true if all conditions are met
      return (
        filterByNameEng &&
        filterByNameLoc &&
        filterByProductCategoriesNameEng &&
        filterBySUK &&
        filterByPrice
      );
    });
    console.log("walaaaaa", filteredResult);
    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredData(filteredResult));
  };

  useEffect(() => {
    dispatch(getModifiers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTaxesGroups());
  }, [dispatch]);

  const handleClose = () => {
    onClose(filteredData);
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.modifiers_options.filter_modifiers_options")}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleFilterGiftsCard}>
            <Stack spacing={6}>
              <Input
                label={t("pages.gifts_cards.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.name")}
                name="ModiferOptionNameEng"
                value={filterCriteria.ModiferOptionNameEng}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ModiferOptionNameEng: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.gifts_cards.name_localized")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.name_localized")}
                name="ModiferOptionNameAr"
                value={filterCriteria.ModiferOptionNameAr}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ModiferOptionNameAr: e.target.value,
                  })
                }
              />

              <MultiSelect
                title={t("pages.gifts_cards.category")}
                data={
                  modifiers.data &&
                  modifiers.data.map((el) => ({
                    ...el,
                    label: el.ModifersNameEng,
                    value: el.ModifersId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.ModifersNameEng}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ModifersNameEng: e.ModifersNameEng,
                  })
                }
              />

              <Input
                type="number"
                label={t("pages.gifts_cards.price")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.price")}
                name="Price"
                value={filterCriteria.Price}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Price: e.target.value,
                  })
                }
              />

              <MultiSelect
                title={t("pages.gifts_cards.category")}
                data={
                  taxesGroups.data &&
                  taxesGroups.data.map((el) => ({
                    ...el,
                    label: el.TaxGroupName,
                    value: el.TaxGroupId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.TaxGroupName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    TaxGroupName: e.TaxGroupName,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={handleClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default FilterModal;
