import React from 'react';
import GRestoreModal from "components/ui/modal/RestoreModal";
import { useDispatch } from 'react-redux';
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { deleteSupllierInventory, getAllSuppliers, getDeletedSuppliers } from '../../services/inventorySupplierService';

const RestoreSupplierModal = ({onClose,id}) => {
  const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();

  return (
    <GRestoreModal
        onClose={onClose}
        onSubmit={()=>{
          dispatch(deleteSupllierInventory(id)).unwrap().then(_ => {
            toast({ description: t("pages.suppliers.waiting_success") });//             
            onClose();
            dispatch(getAllSuppliers());
            dispatch(getDeletedSuppliers());
          })
        }}  
  />
  );
}

export default RestoreSupplierModal;

