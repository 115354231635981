import { createSlice } from "@reduxjs/toolkit";
import {
  getHrTodayAbsence,
  getHrTodayAttendance,
} from "../services/hrTodayattendanceServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  TodayAttendance: [],
  TodayAbsence: [],

  // Filter data
  filteredData: [],
};
//

const hrTodayAttendanceSlice = createSlice({
  name: "hrTodayAttendance",
  initialState,
  extraReducers: (builder) => {
    /* -------------------  Get Hr Today Attendance  ---------------------*/
    builder.addCase(getHrTodayAttendance.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getHrTodayAttendance.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TodayAttendance =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getHrTodayAttendance.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Hr Today Absence  ---------------------*/
    builder.addCase(getHrTodayAbsence.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getHrTodayAbsence.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TodayAbsence =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getHrTodayAbsence.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default hrTodayAttendanceSlice.reducer;
