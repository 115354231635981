import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getComboById, updateCombo } from "../../services/combosService";
import { getCategories } from "features/products/categories/services/categoriesService";
import { generator } from "utils/generator";

const UpdateModal = ({ data, onClose, comboId }) => {
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const dispatch = useDispatch();
  const combos = useSelector((state) => state.combos);
  const categories = useSelector((state) => state.categories);
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: data,
  });

  watch(["ComboSUK"]);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.combos.update_combo")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");

              values.ComboCategoriesId =
                values.ComboCategoriesId?.ComboCategoriesId;
              values.ComboCostTypeId = values.ComboCostTypeId?.ComboCostTypeId;
              values.TaxGroupId = values.TaxGroupId?.map((el) => ({
                TaxGroupId: el.TaxGroupId,
              }));
              if (
                values.ProductCategoriesId === undefined ||
                values.ComboSUK === undefined
              ) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                dispatch(updateCombo({ values }))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.combos.combo_updated") });
                    dispatch(getComboById({ comboId }));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <FormControl isInvalid={errors.ComboName}>
                <FormLabel
                  htmlFor="ComboName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.combos.name")}
                </FormLabel>
                <Controller
                  name="ComboName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.combos.name")}
                      onChange={(e) => {
                        handleInputChange(e, "ComboName");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.ComboName && errors.ComboName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.ComboNameLoc}>
                <FormLabel
                  htmlFor="ComboNameLoc"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.combos.name_localized")}
                </FormLabel>
                <Controller
                  name="ComboNameLoc"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.combos.name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "ComboNameLoc");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.ComboNameLoc && errors.ComboNameLoc.message}
                </FormErrorMessage>
              </FormControl>

              <MultiSelect
                title={t("pages.combos.category")}
                data={
                  categories.data &&
                  categories.data.map((el) => ({
                    ...el,
                    label: el.ProductCategoriesNameEng,
                    value: el.ProductCategoriesId,
                  }))
                }
                isMulti={false}
                onChange={(e) => setValue("ProductCategoriesId", e)}
              />

              <Flex alignItems="flex-end" gap={4}>
                <Input
                  label={t("pages.inventory.sku")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.inventory.sku")}
                  value={getValues("ComboSUK") || ""}
                  readOnly
                />
                <GButton
                  title={t("pages.inventory.generate_sku")}
                  height="50px"
                  bg="light"
                  border="1px solid"
                  borderColor="border"
                  hover={{
                    bg: "primary",
                    borderColor: "primary",
                    color: "light",
                  }}
                  onClick={() => setValue("ComboSUK", generator({}))}
                />
              </Flex>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={combos.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={combos.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default UpdateModal;
