import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* --------------------------------------------------------  Get Storage Unit  ---------------------------------------------- */
  export const getStorageUnit = createAsyncThunk("items/getStorageUnit",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Items/LookupStorageUnit`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* --------------------------------------------------------  Get Ingredient Unit  ---------------------------------------------- */
  export const getIngredientUnit = createAsyncThunk("items/getIngredientUnit",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Items/LookupIngredientUnit?storageunitid=${args}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );