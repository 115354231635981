import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Spinner,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
  Stack,
  Input,
  Checkbox,
  Td,
  Tr,
  Thead,
  Table,
  Th,
  Tbody,
  Button,
  Select,
} from "@chakra-ui/react";
import { useForm, Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewGroupShift,
  getGroupShiftDays,
  getLookUpTimeLeave,
} from "../../services/attendanceSettingServices";
import { useNavigate } from "react-router-dom";
import NewMultiSelect from "components/ui/NewMultiSelect/NewMultiSelect";

export default function AddNewGroupShift() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
    register,
    setError,
    clearErrors,
  } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const [showIsWeekendCycle, setShowIsWeekendCycle] = useState(false);
  const [showIsFlexableHour, setShowIsFlexableHour] = useState(false);

  const data = useSelector((state) => state.attendanceSetting);
  // console.log(data);

  //-------------------------------- Validation -----------------------------
  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };
  //-------------------------------- -------------------------------------
  const daysOfWeek = data?.GroupShiftDays;
  const [days, setDays] = useState([]);

  const handleCheckboxChange = (index) => (e) => {
    const newDays = [...days];
    newDays[index].IS_Vacation = e.target.checked;
    setDays(newDays);
  };

  const handleSelectChange = (index, e) => {
    const newDays = [...days];
    newDays[index].Time_ID = e.Time_ID;
    setDays(newDays);
  };

  //--------------------------------- Effects ----------------------------------------//
  useEffect(() => {
    dispatch(getGroupShiftDays());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getLookUpTimeLeave());
  }, [dispatch]);
  useEffect(() => {
    setTimeout(() => {
      setDays(
        daysOfWeek?.map((day) => ({
          Time_Day: day?.Time_Day,
          IS_Vacation: false,
          Time_ID: 0,
        }))
      );
    }, 500);
  }, [daysOfWeek]);
  //--------------------------------- Compponents Return ----------------------------------------//
  if (data?.isLoading) {
    return (
      <Flex
        bg="light"
        p={4}
        gap={2}
        justifyContent="center"
        flexDir={"column"}
        alignItems={"center"}
      >
        <Spinner />
        <Text fontWeight={"bold"}> Loading </Text>
      </Flex>
    );
  }
  //--------------------------------------------------------------------------------------------//
  return (
    <>
      <Box>
        <Box
          bg="light"
          marginInline={-6}
          marginTop={-6}
          padding={6}
          borderTop="1px solid"
          borderColor="border"
          marginBottom={6}
        >
          <Flex alignItems="center" justifyContent="space-between" gap={4}>
            <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
              {t("pages.hr.attendance.new_group")}
            </Text>
          </Flex>
        </Box>

        <Box
          bg="light"
          overflow="auto"
          borderRadius={4}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.Attend_Leave_GroupID = null;
                values.LeaveGroupsDetails = days;
                values.IsWeekendCycle = showIsWeekendCycle;
                values.WeekendCycleDays = values.IsWeekendCycle
                  ? values.WeekendCycleDays
                  : 0;
                values.IsFlexibleHour = showIsFlexableHour;
                values.HourPerDay = values.IsFlexibleHour
                  ? values.HourPerDay
                  : 0;

                dispatch(addNewGroupShift(values))
                  .unwrap()
                  .then((res) => {
                    toast({
                      description: t("pages.hr.attendance.NewGroupShiftAdded"),
                    });
                    navigate("/GroupShift");
                  });
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.Attend_Leave_GroupName}>
                  <FormLabel
                    htmlFor="Attend_Leave_GroupName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.attendance.group_shift_name")}
                  </FormLabel>
                  <Controller
                    name="Attend_Leave_GroupName"
                    control={control}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.attendance.group_shift_name")}
                        onChange={(e) => {
                          handleInputChange(e, "Attend_Leave_GroupName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Attend_Leave_GroupName &&
                      errors.Attend_Leave_GroupName.message}
                  </FormErrorMessage>
                </FormControl>

                <Checkbox
                  onChange={() => setShowIsWeekendCycle((prev) => !prev)}
                >
                  {t("pages.hr.attendance.is_weekend_cycle")}
                </Checkbox>
                {showIsWeekendCycle && (
                  <FormControl isInvalid={errors.WeekendCycleDays}>
                    <Controller
                      name="WeekendCycleDays"
                      control={control}
                      rules={{
                        required: t("validation.required"),
                        minLength: {
                          value: 1,
                          message: t("pages.hr.hr_validation.number_short_10"),
                        },
                        maxLength: {
                          value: 2,
                          message: t("pages.hr.hr_validation.number_long"),
                        },
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t(
                            "pages.hr.attendance.weekend_cycle_days"
                          )}
                          onChange={(e) => {
                            handleInputChange(e, "WeekendCycleDays");
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.WeekendCycleDays &&
                        errors.WeekendCycleDays.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                <Checkbox
                  onChange={() => setShowIsFlexableHour((prev) => !prev)}
                >
                  {t("pages.hr.attendance.is_flexiable_hours")}
                </Checkbox>
                {showIsFlexableHour && (
                  <FormControl isInvalid={errors.HourPerDay}>
                    <Controller
                      name="HourPerDay"
                      control={control}
                      rules={{
                        required: t("validation.required"),
                        minLength: {
                          value: 1,
                          message: t("pages.hr.hr_validation.number_short_10"),
                        },
                        maxLength: {
                          value: 2,
                          message: t("pages.hr.hr_validation.number_long"),
                        },
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t(
                            "pages.hr.attendance.is_flexiable_hours"
                          )}
                          onChange={(e) => {
                            handleInputChange(e, "HourPerDay");
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.HourPerDay && errors.HourPerDay.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                <Table>
                  <Thead>
                    <Tr bg={"blue.500"}>
                      <Th textColor={"white"}>
                        {t("pages.hr.attendance.days")}
                      </Th>
                      <Th textColor={"white"}>
                        {t("pages.hr.attendance.is_vacation")}
                      </Th>
                      <Th textColor={"white"}>
                        {t("pages.hr.attendance.time_code")}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {days?.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.Time_Day}</Td>
                        <Td>
                          <Checkbox
                            isChecked={item.IS_Vacation}
                            onChange={handleCheckboxChange(index)}
                          >
                            Is Vacation
                          </Checkbox>
                        </Td>
                        <Td w={"350px"}>
                          <NewMultiSelect
                            data={
                              data?.TimeLeaveList &&
                              data?.TimeLeaveList?.map((el) => ({
                                ...el,
                                label: el.Time_Code,
                                value: el.Time_ID,
                              }))
                            }
                            onSelectionChange={(e) => {
                              // console.log(e.Time_ID);
                              handleSelectChange(index, e);
                              // SetShowDiscountFiled(true);
                            }}
                            IsRequired={true}
                          />

                          {/* <Select
                            placeholder="Select time code"
                            value={item?.Time_ID}
                            onChange={handleSelectChange(index)}
                          >
                            {data?.TimeLeaveList &&
                              data?.TimeLeaveList.map((el) => (
                                <option key={el?.Time_ID} value={el?.Time_ID}>
                                  {el?.Time_Code}
                                </option>
                              ))}
                          </Select> */}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>

                <Flex gap={4} marginTop={"50px"}>
                  <Button
                    bg="primary"
                    size="md"
                    color={"white"}
                    w={"250px"}
                    type="submit"
                  >
                    {t("general.save")}
                  </Button>
                </Flex>
              </Stack>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}
