import React, { useState , useEffect } from 'react';
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Button, Flex, SimpleGrid, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PriceTagsProducts from '../components/PriceTagsProducts';
import EditPriceTags from '../components/Modals/EditPriceTags';
import PriceTagsCombos from '../components/PriceTagsCombos';
import PriceTagsModifier from '../components/PriceTagsModifier';
import { getPriceTagsDetails } from '../services/priceTagsServices';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';

export default function PriceTagsDetails() {

    const retrievedArray =useDecryptedPrivilege()
    const ManageBranches = checkIdExists(retrievedArray, 58);


    const { t } = useTranslation();
    const dispatch = useDispatch();
    const id = useParams();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });

    const PriceTagsData = useSelector(state => state.priceTags);

    const [showEditModal, setShowEditModal] = useState(null);
    const [activeBar, setActiveBar] = useState("products");

    const changeBar = (arg)=>{
        setActiveBar(arg);
    };

    useEffect(() => {
       dispatch(getPriceTagsDetails(id))
    }, [dispatch])
    

    if(ManageBranches===false  ){
        return(
          <NotAuthorizedCompo/>
        )
      }

  return <>

<BreadCrumb>

    <Flex justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">

            <Box as="p" fontSize="1.6rem" textTransform="capitalize">  {PriceTagsData.priceTagDetails[0]?.PriceTagsName} </Box>

            <GButton
                title={t("general.edit")}
                bg="#54A0FF"
                color="light"
                hover={{ bg: "primary" }}
                  onClick={() => setShowEditModal(true)}
            />
    </Flex>

    <Flex
        alignItems="center" flexWrap="wrap"
        textTransform="capitalize" gap={4} color="text" bg="light" paddingX={2}
        >
            <GButton
                title={t("pages.price_tags.products")}
                bg="none"
                color={activeBar === "products" ? "primary" : ""}
                isDisabled={false}
                borderRadius={0}
                borderColor="primary"
                borderBottom={activeBar === "products" ? "2px solid" : ""}
                onClick={()=>changeBar("products")}
            />
            <GButton
                title={t("pages.price_tags.combos")}
                bg="none"
                color={activeBar === "combos" ? "primary" : ""}
                isDisabled={false}
                borderRadius={0}
                borderColor="primary"
                borderBottom={activeBar === "combos" ? "2px solid" : ""}
                onClick={()=>changeBar("combos")}
            />
            <GButton
                title={t("pages.price_tags.modifier")}
                bg="none"
                color={activeBar === "modifier" ? "primary" : ""}
                isDisabled={false}
                borderRadius={0}
                borderColor="primary"
                borderBottom={activeBar === "modifier" ? "2px solid" : ""}
                onClick={()=>changeBar("modifier")}
            />
    </Flex>

</BreadCrumb>   

{
    activeBar === "products"  ? 
    <PriceTagsProducts  data={PriceTagsData?.data} />
    : 
    activeBar === "combos" ?
    <PriceTagsCombos  data={PriceTagsData?.data} />
    :
    <PriceTagsModifier  data={PriceTagsData?.data} />
}
  

{
    showEditModal  &&  (
        <EditPriceTags 
            data={PriceTagsData?.priceTagDetails[0]} 
            onClose={()=>setShowEditModal(null)} 
            id={id}
            />
    )
}
  
  </>
};
