import { createSlice } from "@reduxjs/toolkit";
import {
  assigenTagsToProduct,
  getTags,
  getTagsInProduct,
  getTagsOfProduct,
} from "../services/addingTagsService";

let initialState = {
  isLoading: false,
  data: [],
  errors: null,
  tags: [],
  tagsInProduct : []
};

const customPriceSlice = createSlice({
  name: "addTags",
  initialState,
  extraReducers: (builder) => {
    /* --------------------------------------------------Get Tags Of Product---------------------------------------------------*/
    builder.addCase(getTagsOfProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTagsOfProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getTagsOfProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------- Get Tags In Product---------------------------------------------------*/
    builder.addCase(getTagsInProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getTagsInProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInProduct = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getTagsInProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* --------------------------------------------------Add Tags To Product---------------------------------------------------*/
    builder.addCase(assigenTagsToProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(assigenTagsToProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });

    builder.addCase(assigenTagsToProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });


  },
});

export default customPriceSlice.reducer;
