import React from 'react';
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteKitchenFlow, getAllKitchenFlow } from '../../services/kitchenFlowService';
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import routes from "navigations/routes";


const DeleteKitchenFlowModal = ({onClose}) => {
    const id = useParams()
    const dispatch=useDispatch()
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const navigate = useNavigate();
    const { t } = useTranslation();
  return (
    <GDeleteModal
    onClose={onClose}
    onSubmit={()=>{
        dispatch(deleteKitchenFlow(id.id)).
          unwrap().
          then(_ => {
                dispatch(getAllKitchenFlow());
                toast({ description: t("pages.inventory.delete_item") });
                onClose();
                navigate(`/${routes.management_kitchenFlow}`);
              })
    }}
/>
  );
}

export default DeleteKitchenFlowModal;
