import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text , useToast} from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button"
import { useDispatch, useSelector } from 'react-redux';
import ReturnedItemsTable from './ReturnedItemsTable';
import { getReturnedItemList } from '../services/returnedSuppliersServices';
import EditQuantityReturnItems from './modals/EditQuantityReturnItems';
import AddReturnItems from './modals/AddReturnItems';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

export default function ReturnItems ({Status , id , PurchasingId}){
    const dispatch=useDispatch()
    const { t } = useTranslation();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const [ShowAddTagModal, setShowAddTagModal] = useState(false);
    const [showEditQuantity, setShowEditQuantity] = useState(false)

    const returndedSupplierItems = useSelector(state => state.returnSupplier);
    const data = returndedSupplierItems?.returnedItemsList;

    useEffect(()=>{
      dispatch(getReturnedItemList(id))
    },[dispatch , id]);

/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManagePurchasing  = checkIdExists(retrievedArray, 22);

if(ShowAddTagModal === true && ManagePurchasing === false){
  toast2({ description:  t("general.authorization")});
  setShowAddTagModal(false);
};
if(showEditQuantity === true && ManagePurchasing === false){
  toast2({ description:  t("general.authorization")});
  setShowEditQuantity(false);
};
/* -----------------------------------Privilige-------------------------------*/


  return <>
      <Box>
        <Flex
        alignItems="center" justifyContent="space-between"
        gap={2} flexWrap="wrap" mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.purchases.items")}
        </Text>
            <Flex>  
                {
                  data?.length > 0 && Status === "Draft" ? 
                    <GButton
                      title={t("pages.purchases.edit_quantities")}
                      bg="light"
                      color="text"
                      fontSize="14px"
                      border="1px solid"
                      borderColor="border"
                      hover={{ bg: "light" }}
                      onClick={() => setShowEditQuantity( true)}
                    />
                : 
                ""
                }
                {
                  Status === "Closed" ? 
                  ""
                  :
                  <>
                      <GButton
                        title={t("pages.purchases.add_items")}
                        bg="light"
                        color="text"
                        fontSize="14px"
                        border="1px solid"
                        borderColor="border"
                        hover={{ bg: "light" }}
                        onClick={() => setShowAddTagModal( true)}
                      />
                      {/* <GButton
                        title={t("pages.purchases.import_item")}
                        bg="light"
                        color="text"
                        fontSize="14px"
                        border="1px solid"
                        borderColor="border"
                        hover={{ bg: "light" }}
                      /> */}
                  </>
                }

            </Flex>
        </Flex>

        {returndedSupplierItems?.isLoading ? (
          <Spinner />
        ) : data?.length === 0 ? (
          <Flex
            p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
          >
            {t("pages.branches.no_data")}
          </Flex>
        ) : data?.length > 0 ? (
          <ReturnedItemsTable
              data={data}
          />
        ):(
          <Flex
          p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
        )
        
        }

        {ShowAddTagModal && (
          <AddReturnItems
          onClose={() => setShowAddTagModal(false)}
          PurchasingId={PurchasingId}
          ReturnId={id}
          />
        )}

        {showEditQuantity && (
          <EditQuantityReturnItems
              onClose={() => setShowEditQuantity( false)}
              id={id}
          />
        )}

      </Box>
  </>
};
