import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/*-------------------------------------------------- Get Suplliers  List -------------------------------------------- */
export const getAllSuppliers = createAsyncThunk( "inventorySuppliers/getAllSuppliers", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Suppliers/SuppliersList`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/*-------------------------------------------------- Create New Suplliers  -------------------------------------------- */
  export const createSupplier = createAsyncThunk("inventorySuppliers/createSupplier", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/Suppliers/AddSuppliers?suppliers=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/*-------------------------------------------------- Get Supplier Details  -------------------------------------------- */
  export const getSupplierDetails = createAsyncThunk("inventorySuppliers/getSupplierDetails",  async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/Suppliers/SuppliersById?suppliersId={"SuppliersId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

/*-------------------------------------------------- Delete Supllier Inventory -------------------------------------------- */
  export const deleteSupllierInventory = createAsyncThunk("inventorySuppliers/deleteSupllierInventory", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Suppliers/DeleteSuppliersById?suppliersid={"SuppliersId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/*-------------------------------------------------- Get Deleted Suppliers -------------------------------------------- */
  export const getDeletedSuppliers = createAsyncThunk("inventorySuppliers/getDeletedSuppliers",  async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Suppliers/DeletedList`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/*-------------------------------------------------- Edit Supplier -------------------------------------------- */
  export const editSupplierData = createAsyncThunk("inventorySuppliers/editSupplierData",  async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/Suppliers/EditSuppliers?suppliers=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

/*-------------------------------------------------- Filter Supplier -------------------------------------------- */
  export const getSuppliersFilteredData = createAsyncThunk("inventorySuppliers/getSuppliersFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetSuppliersFilteredData = createAsyncThunk( "inventorySuppliers/resetSuppliersFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
