import { createSlice } from "@reduxjs/toolkit";
import { getCostAdjustmentReportList , getCostAdjustmentHistoryFilteredData , resetCostAdjustmentHistoryFilteredData} from "../services/costAdjustmentHistoryServices";


let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const costAdjustmentHistory = createSlice({
  name: "costAdjustmentHistory",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------------- Get CostAdjustment Reports List ------------------------------------ */
    builder.addCase(getCostAdjustmentReportList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentReportList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentReportList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

   /* --------------------------------------------------------------------------------------------------- */
   /* -----------------------------------------Inventory Filter Filter Data --------------------------------------------- */
    builder.addCase(getCostAdjustmentHistoryFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getCostAdjustmentHistoryFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getCostAdjustmentHistoryFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetCostAdjustmentHistoryFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetCostAdjustmentHistoryFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetCostAdjustmentHistoryFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default costAdjustmentHistory.reducer;