import { useTranslation } from "react-i18next";
import * as FiIcons from "react-icons/fi";
import { Button, Flex, Text, SimpleGrid, Stack, Box, Badge } from "@chakra-ui/react"
import BreadCrumb from "../../../components/ui/breadcrumb/BreadCrumb";
import { useEffect, useState } from "react";
import CreateCallCenterOrderModal from "../components/modal/CreateCallCenterOrderModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetAllCatigoresWithProducts, customerDetails, getAgents, getCustomerPhoneNumber, getMenuGroupForCallCenter, getPaymentMethodForCallCenter, getPaymentModes } from "../service/callCenterService";
import { FaPhoneVolume } from "react-icons/fa6";

const CallCenter = () => {
  const { t } = useTranslation();
  const [showModal,setshowModal]=useState(false)
  const dispatch = useDispatch()


  const data = {
    DeviceIP: "192.10.12.1",
    Type: "cachir",
    subtype: "null",
  };

  useEffect(() => {
    dispatch(GetAllCatigoresWithProducts(data));
  }, [dispatch]);








const navigate = useNavigate()

const navigatefunction=(el)=>{
  dispatch(customerDetails(el))
  //console.log(el)
  navigate(`${el.OrderId}`)
}



const callCenterOrder = useSelector(state => state.callcenter);


//console.log(callCenterOrder.data)



  return (
    <>













      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.call_center.call_center")}
          </Text>

          <Flex gap={2}>
        
            <Button
              type="button" bg="primary" color="light"
              textTransform="capitalize" fontSize="1rem"
              onClick={()=>setshowModal(true)}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.call_center.create_call_center_order")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
      <Box>
        <Badge
          display="flex" alignItems="center" bg="light" paddingBlock={2} mb={4}
          paddingInline={4} color="dark" fontSize="1rem" textTransform="capitalize"
        >
        {t("pages.call_center.drafts")}
          {/*<Badge ms={2}>0</Badge>*/}
        </Badge>
  
        <Stack spacing={6}>
   
        {
          callCenterOrder.data.map((el=>{
            //console.log(el.pending)
            if(el.pending===false){
              return(
                <Box bg="light" paddingBlock={3} paddingInline={4} key={el.OrderId}  style={{cursor:"pointer"}}>
                <Flex alignItems="baseline">
                  <FiIcons.FiUser />
                  <Stack ms={2} color="text">
                    <Text>{el.CustomerName}</Text>
                  </Stack>
                </Flex>
                <Flex alignItems="baseline">
                <FaPhoneVolume />
                <Stack ms={2} color="text">
                  <Text>{el.PhoneNo}</Text>
                  </Stack>
                </Flex>
                <Flex alignItems="baseline">
                  <FiIcons.FiClock />
                  <Stack ms={2} color="text">
                    <Text>{el.UserDate}</Text>
                  </Stack>
                </Flex>
                <Flex alignItems="baseline">
                  <FiIcons.FiFile />
                  <Stack ms={2} color="text">
                    <Text>{el.BranchName}</Text>
                  </Stack>
                </Flex>
              </Box>
              )
            }
        
          }))
        }
        </Stack>
      </Box>
      <Box>
        <Badge
          display="flex" alignItems="center" bg="light" paddingBlock={2} mb={4}
          paddingInline={4} color="dark" fontSize="1rem" textTransform="capitalize"
        >
        {t("pages.call_center.pending")}

          {/*<Badge ms={2}>{callCenterOrder?.data.length}</Badge>*/}
        </Badge>
  
        <Stack spacing={6}>
    
        {
          callCenterOrder.data.map((el=>{
            //console.log(el.pending)
            if(el.pending===true){
              return(
                <Box bg="light" paddingBlock={3} paddingInline={4} key={el.OrderId} onClick={()=>navigatefunction(el)} style={{cursor:"pointer"}}>
                <Flex alignItems="baseline">
                  <FiIcons.FiUser />
                  <Stack ms={2} color="text">
                    <Text>{el.CustomerName}</Text>
                  </Stack>
                </Flex>
                <Flex alignItems="baseline">
                <FaPhoneVolume />
                <Stack ms={2} color="text">
                  <Text>{el.PhoneNo}</Text>
                  </Stack>
                </Flex>
                <Flex alignItems="baseline">
                  <FiIcons.FiClock />
                  <Stack ms={2} color="text">
                    <Text>{el.UserDate}</Text>
                  </Stack>
                </Flex>
                <Flex alignItems="baseline">
                  <FiIcons.FiFile />
                  <Stack ms={2} color="text">
                    <Text>{el.BranchName}</Text>
                  </Stack>
                </Flex>
              </Box>
              )
            }
        
          }))
        }

    
        </Stack>
  
        {
          showModal&&(
            <CreateCallCenterOrderModal onClose={() => setshowModal(false)} />
          )
        }
  
  
      </Box>
    </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
      

        


      

         


        

           


          {
            showModal&&(
              <CreateCallCenterOrderModal onClose={() => setshowModal(false)} />
            )
          }


      </SimpleGrid>
    </>
  )
}

export default CallCenter