import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const getItemTags = createAsyncThunk(
    "itemstags/getItemTags",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Brand/LookUpBrandTags`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const addTagToItem = createAsyncThunk(
    "itemstags/addTagToItem",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/ItemsTags/AddItemsTags?itemstags=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getTagsOfItem = createAsyncThunk(
    "itemstags/getTagsOfItem",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/Items/TagsByItemId?ItemId={"InventoryItemId":${args}}`);
       //console.log(data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


