import { createSlice } from "@reduxjs/toolkit";
import {
  GetAbsenceDays,
  GetAttendanceDays,
  GetContractList,
  GetEmployeePremissions,
  GetEmployeeVacations,
  addNewEmployeeBankInfo,
  addNewEmployeeBasicInfo,
  addNewEmployeeContactInfo,
  addNewEmployeeContractInfo,
  addNewEmployeeNationalIdInfo,
  addNewEmployeeWorkPermitInfo,
  editBasicInfo,
  editContactInfo,
  editExtraInfo,
  editJobDetails,
  editNationalityInfo,
  emptyNewEmployee,
  getBankDropdown,
  getEmployeeById,
  getEmployeeList,
  getMartialStatus,
  getNationalityDropdown,
  getRecruitmentSituation,
  getReligion,
  viewContractById,
} from "./../services/employeeServices";

let initialState = {
  isLoading: false,
  isLoadingDetails: false,
  pages: 0,
  errors: null,

  employeeList: [],
  employeeDetails: [],
  employeeContractList: [],
  employeeVacationsList: [],
  employeePermessionsList: [],
  contractById: [],
  // Attendance && Absence List
  attendanceList: [],
  absenceList: [],

  // Martial Status
  BankDropDown: [],
  MartialStatusDropDown: [],
  ReligionDropDown: [],
  RecruitmentSituationDropDown: [],
  nationalityDropDown: [],
  // Multi Step EmployId
  NewEmployeeId: null,
  // Filter data
  filteredData: [],
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get Employee List dropDown -------------------------------------------- */
    builder.addCase(getEmployeeList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getEmployeeList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getEmployeeList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------- Get Employee Details dropDown -------------------------------------------- */
    builder.addCase(getEmployeeById.pending, (state, { payload }) => {
      state.isLoadingDetails = true;
      state.errors = null;
    });
    builder.addCase(getEmployeeById.fulfilled, (state, { payload }) => {
      state.isLoadingDetails = false;
      state.employeeDetails =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getEmployeeById.rejected, (state, { payload }) => {
      state.isLoadingDetails = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Family Status DropDown ---------------------*/
    builder.addCase(getMartialStatus.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getMartialStatus.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.MartialStatusDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getMartialStatus.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Religion DropDown ---------------------*/
    builder.addCase(getReligion.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getReligion.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ReligionDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getReligion.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Recruitment Situation DropDown ---------------------*/
    builder.addCase(getRecruitmentSituation.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getRecruitmentSituation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.RecruitmentSituationDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getRecruitmentSituation.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Edit Basic Info ---------------------*/
    builder.addCase(editBasicInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editBasicInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editBasicInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Nationality DropDown  ---------------------*/
    builder.addCase(getNationalityDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getNationalityDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.nationalityDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getNationalityDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Edit Nationality Info  ---------------------*/
    builder.addCase(editNationalityInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editNationalityInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editNationalityInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Edit Contact Info  ---------------------*/
    builder.addCase(editContactInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editContactInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editContactInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Edit Job Details Info  ---------------------*/
    builder.addCase(editJobDetails.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editJobDetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editJobDetails.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Edit Extra Info  ---------------------*/
    builder.addCase(editExtraInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editExtraInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editExtraInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Attendance Days  ---------------------*/
    builder.addCase(GetAttendanceDays.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetAttendanceDays.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.attendanceList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(GetAttendanceDays.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Absence Days  ---------------------*/
    builder.addCase(GetAbsenceDays.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetAbsenceDays.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.absenceList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(GetAbsenceDays.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Contract List  ---------------------*/
    builder.addCase(GetContractList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetContractList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeContractList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(GetContractList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Contract List  ---------------------*/
    builder.addCase(viewContractById.pending, (state, { payload }) => {
      // state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(viewContractById.fulfilled, (state, { payload }) => {
      // state.isLoading = false;
      state.contractById =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(viewContractById.rejected, (state, { payload }) => {
      // state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Vacations List  ---------------------*/
    builder.addCase(GetEmployeeVacations.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetEmployeeVacations.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeVacationsList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(GetEmployeeVacations.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Premission Orders List  BY Id  ---------------------*/
    builder.addCase(GetEmployeePremissions.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetEmployeePremissions.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeePermessionsList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(GetEmployeePremissions.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Add New Employee Basic Info  ---------------------*/
    builder.addCase(addNewEmployeeBasicInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewEmployeeBasicInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.NewEmployeeId =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(addNewEmployeeBasicInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Add New Employee NationalId  Info  ---------------------*/
    builder.addCase(
      addNewEmployeeNationalIdInfo.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.errors = null;
      }
    );
    builder.addCase(
      addNewEmployeeNationalIdInfo.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.NewEmployeeId =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(
      addNewEmployeeNationalIdInfo.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      }
    );
    /* -------------------   Add New Employee Contact  Info  ---------------------*/
    builder.addCase(addNewEmployeeContactInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      addNewEmployeeContactInfo.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.NewEmployeeId =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(
      addNewEmployeeContactInfo.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      }
    );
    /* -------------------   Add New Employee Work Permit  Info  ---------------------*/
    builder.addCase(
      addNewEmployeeWorkPermitInfo.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.errors = null;
      }
    );
    builder.addCase(
      addNewEmployeeWorkPermitInfo.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.NewEmployeeId =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(
      addNewEmployeeWorkPermitInfo.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      }
    );
    /* -------------------  Get Bank DropDown   ---------------------*/
    builder.addCase(getBankDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getBankDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.BankDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getBankDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Add New Employee Bank  Info  ---------------------*/
    builder.addCase(addNewEmployeeBankInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewEmployeeBankInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.NewEmployeeId =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(addNewEmployeeBankInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Add New Employee Contarct  Info  ---------------------*/
    builder.addCase(
      addNewEmployeeContractInfo.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.errors = null;
      }
    );
    builder.addCase(
      addNewEmployeeContractInfo.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.NewEmployeeId =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(
      addNewEmployeeContractInfo.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      }
    );
    /* -------------------   Emty Data ---------------------*/
    builder.addCase(emptyNewEmployee.fulfilled, (state, { payload }) => {
      state.NewEmployeeId = null;
    });
  },
});

export default employeeSlice.reducer;
