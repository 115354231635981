import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Get Menu Engineering Report List ------------------------------- */
  export const getProductCostReportList = createAsyncThunk("productCostReport/getProductCostReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetProductCostReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


 /* --------------------------------------- Menu Engineering Filter Data ------------------------------*/
 export const getProductCostFilteredData = createAsyncThunk("productCostReport/getProductCostFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetProductCostFilteredData = createAsyncThunk( "productCostReport/resetProductCostFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);