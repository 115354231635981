import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteCharge, getCharges } from "../../services/chargesService";

export default function DeleteModal ({ data, onClose , setActiveNavbar}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  return <>
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteCharge(data.ChargeId))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.charges.charge_deleted") });
            setActiveNavbar("all");
            dispatch(getCharges());
            onClose();
          })
      }}
    />

  </>
};

