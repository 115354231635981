import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteGroup } from "../../services/groupsService";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";

const DeleteModal = ({ data, onClose}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groups = useSelector(state => state.groups);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteGroup({ id: data.id }))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.groups.group_deleted") });
            onClose();
            navigate(`/${routes.products_groups}`);
          })
      }}
      isLoading={groups.isLoading}
    />
  )
}

export default DeleteModal