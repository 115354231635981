import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr,} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { MdSettingsBackupRestore } from "react-icons/md";
import GButton from "components/ui/button/Button";
import { IoCloseOutline } from "react-icons/io5";
import { useState } from "react";
import { useDispatch } from "react-redux";
import NoData from "components/ui/noData/NoData";
import EditModal from "./Modal/EditModal";
import DeletedModal from "./Modal/DeletedModal";
import FilterModal from "./Modal/FilterModal";
import { resetInventoryCategoriesFilteredData } from "../services/InventoryCategoriesServices";
import RestoreModal from "./Modal/RestoreModal";
import ActiveDataTable from "./ActiveDataTable";
import DeletedDataTable from "./DeletedDataTable";
import FilterDataTable from "./FilterDataTable";

export default function InventoryCategoriesTable({ data, filteredData }) {
  // console.log(filteredData);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sortType, setSortType] = useState("all");

  // Show Filter Modal State
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showDeletedModal, setDeletedModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  const resetFunction = () => {
    dispatch(resetInventoryCategoriesFilteredData());
  };

  const activeData = data.filter((item)=>{
    return item.IsDeleted === false
  })
  const deletedData = data.filter((item)=>{
    return item.IsDeleted === true
  })

  const activeFilterData = filteredData?.filter((item)=>{
    return item.IsDeleted === false
  })

  const deletedFilterData = filteredData?.filter((item)=>{
    return item.IsDeleted === true
  })

  return <>
    <>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <GButton
              title={t("general.all")}
              bg="none"
              isDisabled={false}
              borderRadius={0}
              color={sortType === "all" ? "primary" :"none"}  
              borderBottom={sortType === "all" ? "2px solid" :"none"}   
              borderColor={sortType === "all" ? "primary" :"none"}  
              onClick={() => setSortType("all")}
            />

            <GButton
              title={t("general.deleted")}
              bg="none"
              color={sortType === "deleted" ? "primary" :"none"}  
              isDisabled={false}
              borderRadius={0}
              borderBottom={sortType === "deleted" ? "2px solid" :"none"}   
              borderColor={sortType === "deleted" ? "primary" :"none"}  
              onClick={() => setSortType("deleted")}
            />
          </Flex>

          <GButton
            title={
              filteredData?.length === 0
                ? t("general.filter")
                : t("general.clear_filter")
            }
            border="1px solid"
            borderColor="border"
            postfixElement={
              filteredData?.length === 0 ? (
                <FiIcons.FiFilter size={16} />
              ) : (
                <IoCloseOutline fontWeight={800} size={20} />
              )
            }
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            onClick={
              filteredData?.length === 0
                ? () => setShowFilterModal(true)
                : resetFunction
            }
          />
        </Flex>
        {data.length === 0 ? (
          <Flex
            p={4}
            bg="white"
            alignItems="center"
            justifyContent="center"
            color="text"
            textTransform="capitalize"
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            borderRadius={6}
          >
            {t("pages.branches.no_data")}
          </Flex>
        ) : 
        <>
          {filteredData?.length > 0 ? (
                <FilterDataTable 
                data={ sortType === "all" ? activeFilterData :  deletedFilterData } 
                setDeletedModal={setDeletedModal} 
                setEditModal={setEditModal}
                />
          )
          : sortType === "all" ? (
            <>
              <ActiveDataTable 
                  data={activeData} 
                  setDeletedModal={setDeletedModal} 
                  setEditModal={setEditModal}
              />
            </>
          ) : sortType === "deleted" ? (
            <>
              <DeletedDataTable 
                  data={deletedData} 
                  setDeletedModal={setDeletedModal} 
                  setEditModal={setEditModal}
              />
            </>
          ) : (
            ""
          )}
        </>
        }

      </Box>

      {showFilterModal && (
        <FilterModal
          onClose={() => setShowFilterModal(false)}
          data={data}
          filteredData={filteredData}
        />
      )}

      {showEditModal && (
        <EditModal onClose={() => setEditModal(false)} id={showEditModal} />
      )}

      {showDeletedModal && (
        <DeletedModal
          onClose={() => setDeletedModal(false)}
          id={showDeletedModal}
        />
      )}

      {showRestoreModal && (
        <RestoreModal
          onClose={() => setShowRestoreModal(false)}
          id={showRestoreModal}
        />
      )}
    </>
  </>;
}
