import React, { useEffect }  from 'react';
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useForm } from "react-hook-form";
import {Box,Checkbox, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal"
import Input from "components/form/Input"
import GButton from "components/ui/button/Button"
import { getBrands, getProductCatForBrands, getProductsForBrand, getTagsForBrand, updateBrand } from "../../services/brandsService"
import MultiSelect from "components/ui/multiSelect/MultiSelect";


const UpdateModal = ({data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, control, setValue,  register,  formState: { errors },  trigger} =  useForm();

  const brands = useSelector(state => state.brands);

  useEffect(()=>{
    dispatch(getProductsForBrand())
  },[dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.brands.update_brand")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.Brands = values.Brands?.map(el => ({ ProductId: el.ProductId ,BrandsId:data.BrandsId}));
            values.BrandDetailsId=null
            dispatch(updateBrand(values))
              .unwrap()
              .then(_ => {
                toast({ description: t("pages.brands.brand_updated") });
                dispatch(getBrands());
                onClose();
              });
          })}>
            <Stack spacing={6}>
            <FormControl isInvalid={errors.BrandName}>
              <FormLabel
                htmlFor="BrandName"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.brands.brand_name")}
              </FormLabel>
              <Controller
                name="BrandName"
                control={control}
                defaultValue={data?.BrandName}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.brands.brand_name")}
                    onChange={(e) => {
                      handleInputChange(e, "BrandName");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.BrandName &&
                  errors.BrandName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.BrandNameLocalized}>
                <FormLabel
                  htmlFor="BrandNameLocalized"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.brands.brand_name_localized")}
                </FormLabel>
                <Controller
                  name="BrandNameLocalized"
                  control={control}
                  defaultValue={data?.BrandNameLocalized}
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.brands.brand_name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "BrandNameLocalized");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.BrandNameLocalized &&
                    errors.BrandNameLocalized.message}
                </FormErrorMessage>
            </FormControl>

          <FormControl isInvalid={errors.BrandHeader}>
              <FormLabel
                htmlFor="BrandHeader"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.brands.brand_header")}
              </FormLabel>
              <Controller
                name="BrandHeader"
                control={control}
                defaultValue={data?.BrandHeader}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.brands.brand_header")}
                    onChange={(e) => {
                      handleInputChange(e, "BrandHeader");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.BrandHeader &&
                  errors.BrandHeader.message}
              </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.FooterBrand}>
              <FormLabel
                htmlFor="FooterBrand"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.brands.brand_footer")}
              </FormLabel>
              <Controller
                name="FooterBrand"
                control={control}
                defaultValue={data?.FooterBrand}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.brands.brand_footer")}
                    onChange={(e) => {
                      handleInputChange(e, "FooterBrand");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.FooterBrand &&
                  errors.FooterBrand.message}
              </FormErrorMessage>
          </FormControl>

              <Checkbox
                {...register("IsActive")}
                textTransform="capitalize"
              >
                {t("pages.brands.Is_Active")}
              </Checkbox>


              <MultiSelect
                data={brands.products && brands.products.map(el => ({
                  ...el,
                  label: el.ProductName,
                  value: el.ProductId
                }))}
                title={t("pages.brands.products")}
                onChange={(e) => setValue("Brands", e)}
                isMulti={true}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={brands.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={brands.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default UpdateModal ;