import { createSlice } from "@reduxjs/toolkit";
import {
  getCustomersTags,
  createCustomerTag,
  updateCustomerTag,
} from "../services/customersTagsService";

let initialState = {
  isLoading: false,
  data: [],
  section: null,
  pages: 0,
  errors: null
};

const customersTagsSlice = createSlice({
  name: "customersTags",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCustomersTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCustomersTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getCustomersTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createCustomerTag.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createCustomerTag.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createCustomerTag.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateCustomerTag.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateCustomerTag.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const indexAt = state.data.findIndex(section => section.id === payload.id);
      // state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateCustomerTag.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default customersTagsSlice.reducer;