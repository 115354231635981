import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteModifiersOption } from "../../services/modifiersOptionsService";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";

const DeleteModal = ({ data, onClose }) => {
  const id = useParams();
  //console.log(id.modifiersOptionId)
  const { t } = useTranslation();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const dispatch = useDispatch();
  const modifiersOptions = useSelector((state) => state.modifiersOptions);

  const navigate = useNavigate();

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteModifiersOption(id.modifiersOptionId))
          .unwrap()
          .then((_) => {
            toast({
              description: t(
                "pages.modifiers_options.modifiers_option_deleted"
              ),
            });
            onClose();
            navigate(`/${routes.products_modifiers_options}`);
          });
      }}
      isLoading={modifiersOptions.isLoading}
    />
  );
};

export default DeleteModal;
