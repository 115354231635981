import { useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr,} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";

export default function CashierFilterDataTable({ data }) {
  const { t } = useTranslation();

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const cashierSalesFilteredData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return  <>
      <>
        <Table>
        <Thead>
            <Tr>
              <Th>{t("pages.reports.reports_tables.cashier")}</Th>
              <Th>{t("pages.reports.reports_tables.gross_sales")}</Th>
              <Th>{t("pages.reports.reports_tables.gross_sales%")}</Th>
              <Th>{t("pages.reports.reports_tables.net_sales_with_tax")}</Th>
              <Th>{t("pages.reports.reports_tables.taxes")}</Th>
              <Th>{t("pages.reports.reports_tables.discount_amount")}</Th>
              <Th>{t("pages.reports.reports_tables.gross_sales_without_tax")}</Th>
              <Th>{t("pages.reports.reports_tables.net_sales")}</Th>

              <Th>{t("pages.reports.reports_tables.order_count")}</Th>
              <Th>{t("pages.reports.reports_tables.average_order")}</Th>
              <Th>{t("pages.reports.reports_tables.guest_count")}</Th>
              <Th>{t("pages.reports.reports_tables.average_per_guest")}</Th>
              <Th>{t("pages.reports.reports_tables.customers_count")}</Th>

              <Th>{t("pages.reports.reports_tables.net_quantity")}</Th>
              <Th>{t("pages.reports.reports_tables.cost")}</Th>
              <Th>{t("pages.reports.reports_tables.return_amount")}</Th>
              <Th>{t("pages.reports.reports_tables.return_quantity")}</Th>
              <Th>{t("pages.reports.reports_tables.void_amount")}</Th>
              <Th>{t("pages.reports.reports_tables.void_quantity")}</Th>
              <Th>{t("pages.reports.reports_tables.profit")}</Th>
              <Th>{t("pages.reports.reports_tables.charges")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            { cashierSalesFilteredData?.map((item, index) => (
              <Tr key={index}>
                <Td>{item?.Cashier}</Td>
                <Td> {item?.GrossSales} </Td>
                <Td> {item?.GrossSalesPercentage} </Td>
                <Td> {item?.NetSalesWithTax} </Td>
                <Td> {item?.Taxes} </Td>
                <Td> {item?.DiscountAmount} </Td>
                <Td> {item?.GrossSalesWithoutTax} </Td>
                <Td> {item?.NetSales} </Td>

                <Td> {item?.OrderCount} </Td>
                <Td> {item?.AverageOrder} </Td>
                <Td> {item?.GuestCount} </Td>
                <Td> {item?.AveragePerGuest} </Td>
                <Td> {item?.CustomersCount} </Td>
                
                <Td> {item?.NetQuantity} </Td>
                <Td> {item?.Cost} </Td>
                <Td> {item?.ReturnAmount} </Td>
                <Td> {item?.ReturnQuantity} </Td>
                <Td> {item?.VoidAmount} </Td>
                <Td> {item?.VoidQuantity} </Td>
                <Td> {item?.Profit} </Td>
                <Td> {item?.Charges} </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(data?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(data?.length / recordsPerPage)
            )
          }
        />
      </>
    </>
};

