import { createSlice } from "@reduxjs/toolkit";
import {
  getTimedEventsCombos,
  createTimedEventCombos,
  deleteComboInTimedEvent,
  getTagsInCombo,
} from "../services/timedEventsCombosService";

let initialState = {
  isLoading: false,
  data: [],
  errors: null,
  tagsInCombo: [],
};

const timedEventsCombosSlice = createSlice({
  name: "timedEventsCombos",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get Timed Events List  -------------------------------------------------------------------*/
    builder.addCase(getTimedEventsCombos.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTimedEventsCombos.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getTimedEventsCombos.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create Timed Event  -------------------------------------------------------------------*/
    builder.addCase(createTimedEventCombos.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createTimedEventCombos.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createTimedEventCombos.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Combo In Timed Event  -------------------------------------------------------------------*/
    builder.addCase(deleteComboInTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteComboInTimedEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deleteComboInTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------- Get Tags In Combo ---------------------------------------------------*/
    builder.addCase(getTagsInCombo.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTagsInCombo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInCombo = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getTagsInCombo.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default timedEventsCombosSlice.reducer;
