import React, { useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr,} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Pagination from "components/ui/pagination/Pagination";

export default function CostAdjustmentHistoryDataTable({data}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const navigatefunction = (id) => {
    //   navigate(`${id}`);
    // };
/* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
        return data?.slice(firstIndex, lastIndex);
      };
    
    // Calculate records for activeDiscounts
    const inventoryLevelsData = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
 /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <>
    <Table>
        <Thead>
                <Tr>
                    <Th>{t("pages.reports.reports_tables.name")}</Th>
                    <Th>{t("pages.reports.reports_tables.sku")}</Th>
                    <Th>{t("pages.reports.reports_tables.barcode")}</Th>
                    <Th>{t("pages.reports.reports_tables.storage_unit")}</Th>
                    <Th>{t("pages.reports.reports_tables.branch")}</Th>
                    <Th>{t("pages.reports.reports_tables.reference")}</Th>
                    <Th>{t("pages.reports.reports_tables.original_cost_per_unit")}</Th>
                    <Th>{t("pages.reports.reports_tables.new_cost_per_unit")}</Th>
                    <Th>{t("pages.reports.reports_tables.total_cost")}</Th>
                </Tr>
        </Thead>
        <Tbody>
            {
            inventoryLevelsData?.map((item, index) => (
                <Tr key={index}>  
                    <Td>{item?.InventoryItemNameEng}</Td>
                    <Td>  </Td>
                    <Td>{item?.Barcode}</Td>
                    <Td> {item?.StorageUnit}  </Td>
                    <Td>{item?.BranchName}</Td>
                    <Td> {item?.CostAdjustmentRef} </Td>
                    <Td> {item?.OriginalCostPerUnit} </Td>
                    <Td> {item?.NewCostPerUnit} </Td>
                    <Td> {item?.TotalCost} </Td>
                </Tr>
            ))
            }
        </Tbody>
    </Table>
    <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </>

  </>
};
