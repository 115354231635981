import React from 'react';
import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import GButton from 'components/ui/button/Button';
import { IoCloseOutline } from "react-icons/io5";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterModal from "./modals/FilterModal";
import { resetCostFilteredData } from '../services/costAdjustment';
import FilteredDataTable from './FilteredDataTable';
import CostAdjustmentDataTable from './CostAdjustmentDataTable';
import DraftDataTable from './DraftDataTable';
import ClosedDataTable from './ClosedDataTable';

export default function CostAdjustmentTable({data , filteredData}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sortType, setSortType] = useState("All");

    // Show Filter Modal State
    const [showFilterModal, setShowFilterModal] = useState(false);
    
    const resetFunction = () => {
        dispatch(resetCostFilteredData());
      };

    const closedData = data?.filter((item)=>{
        return   item.CostAdjustmentStatus === "Closed"
    })
    const draftData = data?.filter((item)=>{
        return   item.CostAdjustmentStatus === "Draft"
    })
    const closedFilteredData = filteredData?.filter((item)=>{
        return   item.CostAdjustmentStatus === "Closed"
    })
    const draftFilteredData = filteredData?.filter((item)=>{
        return   item.CostAdjustmentStatus === "Draft"
    })

  return <>
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
        <Flex
                flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
                p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
            >

                <Flex
                    alignItems="center" flexWrap="wrap"
                    textTransform="capitalize" gap={4} color="text" bg="light"
            >
                    <GButton
                        title={t("pages.cost_adjustment.all")}
                        bg="none"
                        borderRadius={0}
                        onClick={() => setSortType("All")}
                        color={sortType === "All" ? "primary" :"none"}  
                        borderBottom={sortType === "All" ? "2px solid" :"none"}   
                        borderColor={sortType === "All" ? "primary" :"none"} 
                    />

                    <GButton
                        title={t("pages.cost_adjustment.draft")}
                        bg="none"
                        borderRadius={0}
                        onClick={() => setSortType("Draft")}
                        color={sortType === "Draft" ? "primary" :"none"}  
                        borderBottom={sortType === "Draft" ? "2px solid" :"none"}   
                        borderColor={sortType === "Draft" ? "primary" :"none"}
                    />

                    <GButton
                        title={t("pages.cost_adjustment.closed")}
                        bg="none"
                        borderRadius={0}
                        onClick={() => setSortType("Closed")}
                        color={sortType === "Closed" ? "primary" :"none"}  
                        borderBottom={sortType === "Closed" ? "2px solid" :"none"}   
                        borderColor={sortType === "Closed" ? "primary" :"none"}
                    />

                </Flex>

                <GButton
                    title={
                        filteredData?.length === 0
                        ? t("general.filter")
                        : t("general.clear_filter")
                    }
                    border="1px solid"
                    borderColor="border"
                    postfixElement={
                        filteredData?.length === 0 ? (
                        <FiIcons.FiFilter size={16} />
                        ) : (
                        <IoCloseOutline fontWeight={800} size={20} />
                        )
                    }
                    hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                    onClick={
                        filteredData?.length === 0
                        ? () => setShowFilterModal(true)
                        : resetFunction
                    }
                />

        </Flex>
        {
            data?.length > 0 ? 
            <>
            {
                filteredData?.length > 0 ? 
                <FilteredDataTable 
                    data={ 
                        sortType === "All" ? filteredData 
                        : sortType === "Closed" ? closedFilteredData 
                        :  draftFilteredData 
                    } 
                />
                : sortType === "All" ?
                <CostAdjustmentDataTable data={data} />
                : sortType === "Draft" ? 
                <DraftDataTable data={draftData} />
                : 
                <ClosedDataTable data={closedData} />
            }
            </>
            :
            <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
                color="text"
                textTransform="capitalize"
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                borderRadius={6}
                >
                {t("pages.branches.no_data")}
            </Flex>
        }

    </Box>
    {
        showFilterModal && (
            <FilterModal 
                onClose={()=>setShowFilterModal(false)} 
                data={data} 
                filterData={filteredData}
            />
        )
    }
  </>
}


