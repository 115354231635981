import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/*----------------------------------------- Get Customer list ----------------------------------------- */
export const getCustomerlist  = createAsyncThunk("customers/getCustomerlist", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Customers/CustomerList");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/*----------------------------------------- Create New Customer ----------------------------------------- */
export const createNewCustomer = createAsyncThunk("customers/createNewCustomer",  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Customers/AddCustomer?customer=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const defaultCustomerResponse = createAsyncThunk("customers/defaultCustomerResponse",  async (args, thunkApi) => {
  try {
    //const { data } = await axios.get(`/Customers/AddCustomer?customer=${JSON.stringify(args)}`);
    //return thunkApi.fulfillWithValue(args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);

/*------------------------------------------------------  Update Customers -------------------------------------------------- */
export const updateCustomer = createAsyncThunk("customers/updateCustomer", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Customers/EditCustomer?customer=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/*----------------------------------------- Get Customer Details By ID ----------------------------------------- */
export const getCustomerDetailsById = createAsyncThunk("customers/getCustomerDetailsById", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Customers/CustomerListById?customerid={"CustomerId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/*------------------------------------------------------ 3- Delete Customers -------------------------------------------------- */
/*----------------------------------------- Delete Customer ----------------------------------------- */
export const deleteCustomer = createAsyncThunk("customers/deleteCustomer", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Customers/DeleteCustomerById?customerid={"CustomerId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/*----------------------------------------- Delete Customer List ----------------------------------------- */
export const getDeletedList = createAsyncThunk("customers/getDeletedList",  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Customers/DeletedCustomerList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getCustomersFilteredData = createAsyncThunk("customers/getCustomersFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetCustomersFilteredData = createAsyncThunk( "customers/resetCustomersFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);