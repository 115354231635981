import React from 'react';
import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import GButton from 'components/ui/button/Button';
import { IoCloseOutline } from "react-icons/io5";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoData from 'components/ui/noData/NoData';
import FilterModal from '../Modal/FilterModal';
import { resetTransfersFilteredData } from '../services/transfersServices';
import FilteredDataTable from './FilteredDataTable';
import TransfersDataTable from './TransfersDataTable';
import SendeingDataTable from './SendeingDataTable';
import ReceviningDataTable from './ReceviningDataTable';

export default function TransfersTable({data , filteredData}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [sortType, setSortType] = useState("all");

    // Show Filter Modal State
    const [showFilterModal, setShowFilterModal] = useState(false);

    const resetFunction = () => {
        dispatch(resetTransfersFilteredData());
    };
// -------------------------------------------------------------------------------
    const draftData = data?.filter((item)=>{
           return item?.Status === "Draft"
    });
    const PendingData = data?.filter((item)=>{
           return item?.Status === "Pending"
    });
    const ClosedData = data?.filter((item)=>{
        return item?.Status === "Closed"  || item?.Status === "Rejected" 
    });


    const draftFilteredData = filteredData?.filter((item)=>{
        return item?.Status === "Draft"
    })
    const pendingFilteredData = filteredData?.filter((item)=>{
        return item?.Status === "Pending"
    })
    const closedFilteredData = filteredData?.filter((item)=>{
        return   item.Status === "Closed" || item?.Status === "Rejected" 
    })

  return <>

    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
        <Flex
                flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
                p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
            >

                <Flex
                    alignItems="center" flexWrap="wrap"
                    textTransform="capitalize" gap={4} color="text" bg="light"
            >
                    <GButton
                        title={t("general.all")}
                        bg="none"
                        borderRadius={0}
                        onClick={() => setSortType("all")}
                        color={sortType === "all" ? "primary" :"none"}  
                        borderBottom={sortType === "all" ? "2px solid" :"none"}   
                        borderColor={sortType === "all" ? "primary" :"none"} 
                    />
                    <GButton
                        title={t("general.draft")}
                        bg="none"
                        borderRadius={0}
                        onClick={() => setSortType("draft")}
                        color={sortType === "draft" ? "primary" :"none"}  
                        borderBottom={sortType === "draft" ? "2px solid" :"none"}   
                        borderColor={sortType === "draft" ? "primary" :"none"} 
                    />
                    <GButton
                        title={t("general.sending")}
                        bg="none"
                        borderRadius={0}
                        onClick={() => setSortType("sending")}
                        color={sortType === "sending" ? "primary" :"none"}  
                        borderBottom={sortType === "sending" ? "2px solid" :"none"}   
                        borderColor={sortType === "sending" ? "primary" :"none"} 
                    />
                    <GButton
                        title={t("general.receiving")}
                        bg="none"
                        borderRadius={0}
                        onClick={() => setSortType("receiving")}
                        color={sortType === "receiving" ? "primary" :"none"}  
                        borderBottom={sortType === "receiving" ? "2px solid" :"none"}   
                        borderColor={sortType === "receiving" ? "primary" :"none"} 
                    />

                </Flex>

                <GButton
                    title={
                        filteredData?.length === 0
                        ? t("general.filter")
                        : t("general.clear_filter")
                    }
                    border="1px solid"
                    borderColor="border"
                    postfixElement={
                        filteredData?.length === 0 ? (
                        <FiIcons.FiFilter size={16} />
                        ) : (
                        <IoCloseOutline fontWeight={800} size={20} />
                        )
                    }
                    hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                    onClick={
                        filteredData?.length === 0
                        ? () => setShowFilterModal(true)
                        : resetFunction
                    }
                />

        </Flex>

        {
          data?.length > 0 ? 
          <>
          {
              filteredData?.length > 0 ? 
              <FilteredDataTable 
                  data={ 
                      sortType === "all" ? filteredData 
                      : sortType === "draft" ? draftFilteredData 
                      : sortType === "sending" ? pendingFilteredData 
                      : closedFilteredData 
                  } 
              />
              : sortType === "all" ?
              <TransfersDataTable data={data} />
              : sortType === "draft" ?
              <TransfersDataTable data={draftData} />
              : sortType === "sending" ? 
              <SendeingDataTable data={PendingData} />
              : 
              <ReceviningDataTable data={ClosedData} />
          }
          </>
          :
          <Flex
              p={4}
              bg="white"
              alignItems="center"
              justifyContent="center"
              color="text"
              textTransform="capitalize"
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
              borderRadius={6}
              >
              {t("pages.branches.no_data")}
          </Flex>
      }

    </Box>

    {
        showFilterModal && (
            <FilterModal 
                onClose={()=>setShowFilterModal(false)} 
                data={data} 
            />
        )
    }
  
  </>
}
