import React, { useState } from 'react';
import { Flex, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import DeleteDeliveryZoneModal from './Modal/DeleteDeliveryZoneModal';

const DeleteDeliveryZone = () => {
    const { t } = useTranslation();
    const [showModal,setShowModal]=useState(false);

  return <>
        <div>
            <Flex
                bg="light" borderRadius={6} alignItems="center" justifyContent="space-between" gap={4} p={4}
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                >
                <Text fontSize=".9rem" color="text"> {t("pages.DeliverZone.if_deleted")} </Text>
                <GButton
                  bg="red"
                  color="light"
                  title={t("general.delete")}
                  hover={{ bg: "red" }}
                onClick={() => setShowModal(true)}
                />
                { showModal&& ( <DeleteDeliveryZoneModal     onClose={() => setShowModal(false)}/> ) }
            </Flex>
        </div>
    </>;
}

export default DeleteDeliveryZone;
