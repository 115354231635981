import { Box } from "@chakra-ui/react"
import React from 'react'

const BreadCrumb = ({
  children,
  bg = "light"
}) => {
  return (
    <Box
      bg={bg} marginInline={-6} marginTop={-6} paddingInline={6} paddingBlock={4}
      borderTop="1px solid" borderColor="border" marginBottom={6}
    >
      {children}
    </Box>
  )
}

export default BreadCrumb