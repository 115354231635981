import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text, Spinner } from "@chakra-ui/react"
import List from "../components/List";
import GroupsList from "../components/GroupsList";
import GButton from "../../../../components/ui/button/Button";
import NoTaxes from "../components/NoTaxes";
import CreateModal from "../components/modals/CreateModal";
import CreateGroupModal from "../components/modals/CreateGroupModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import AddBranchToGroupModal from "../components/modals/AddBranchToGroupModal";
import RemoveBranchFromGroupModal from "../components/modals/RemoveBranchFromGroupModal";
import AddProductToGroupModal from "../components/modals/AddProductToGroupModal";
import RemoveProductFromGroupModal from "../components/modals/RemoveProductFromGroupModal";
import AddModifiersOptionToGroupModal from "../components/modals/AddModifiersOptionToGroupModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import NoData from "components/ui/noData/NoData";
import { getTaxes } from "../services/taxesService";
import { getTaxesGroups } from "../services/taxesGroupsService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Taxes = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 50);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const taxes = useSelector(state => state.taxes);
  const taxesGroups = useSelector(state => state.taxesGroups);
  
  const [showCreateTaxModal, setShowCreateTaxModal] = useState(null);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(null);
  const [showUpdateTaxModal, setShowUpdateTaxModal] = useState(null);
  const [showDeleteTaxModal, setShowDeleteTaxModal] = useState(null);
  const [showAddBranchToGroupModal, setShowAddBranchToGroupModal] = useState(null);
  const [showRemoveBranchFromGroupModal, setShowRemoveBranchFromGroupModal] = useState(null);
  const [showAddProductToGroupModal, setShowAddProductToGroupModal] = useState(null);
  const [showRemoveProductFromGroupModal, setShowRemoveProductFromGroupModal] = useState(null);
  const [showAddModifiersOptionToGroupModal, setShowAddModifiersOptionToGroupModal] = useState(null);

  useEffect(() => {
    dispatch(getTaxes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTaxesGroups());
  }, [dispatch]);

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <BreadCrumb bg="transparent">
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.taxes.taxes")}
          </Text>

          <GButton
            title={t("pages.taxes.create_tax")}
            onClick={() => setShowCreateTaxModal(true)}
            bg="primary"
            color="light"
            border="1px solid transparent"
            hover={{ bg: "none", borderColor: "primary", color: "primary" }}
          />
        </Flex>
      </BreadCrumb>

      {taxes.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : taxes.data.length === 0 ? (
        <NoTaxes
          onClickCreateTaxBtn={() => setShowCreateTaxModal(true)}
        />
      ) : (
        <List
          data={taxes.data}
        />
      )}

      <Flex alignItems="center" marginTop={20} justifyContent="space-between" gap={4} flexWrap="wrap">
        <Text
          fontSize="1.2rem" color="dark" textTransform="capitalize"
        >
          {t("pages.taxes.groups")}
        </Text>

        <GButton
          title={t("pages.taxes.create_tax_group")}
          onClick={() => setShowCreateGroupModal(true)}
          bg="primary"
          color="light"
          border="1px solid transparent"
          hover={{ bg: "none", borderColor: "primary", color: "primary" }}
        />
      </Flex>

      {taxesGroups?.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : taxesGroups?.data?.length === 0 ? (
        <NoData
          title={t("pages.taxes.no_groups_hint")}
          btnTitle={t("pages.taxes.create_tax_group")}
          onClickCreateBtn={() => setShowCreateGroupModal(true)}
        />
      ) : (
        <GroupsList
          data={taxesGroups?.data}
          handleUpdate={(taxGroup) => setShowUpdateTaxModal(taxGroup)}
          handleDelete={(taxGroup) => setShowDeleteTaxModal(taxGroup)}
          handleAddBranchToGroup={(taxGroup) => setShowAddBranchToGroupModal(taxGroup)}
          handleRemoveBranchFromGroup={(taxGroup) => setShowRemoveBranchFromGroupModal(taxGroup)}
          handleAddProductToGroup={(taxGroup) => setShowAddProductToGroupModal(taxGroup)}
          handleRemoveProductFromGroup={(taxGroup) => setShowRemoveProductFromGroupModal(taxGroup)}
          handleAddModifiersOptionToGroup={(taxGroup) => setShowAddModifiersOptionToGroupModal(taxGroup)}
        />
      )}

      {showCreateTaxModal && (
        <CreateModal
          onClose={() => setShowCreateTaxModal(null)}
        />
      )}

      {showCreateGroupModal && (
        <CreateGroupModal
          onClose={() => setShowCreateGroupModal(null)}
        />
      )}

      {showUpdateTaxModal && (
        <UpdateModal
          data={showUpdateTaxModal}
          onClose={() => setShowUpdateTaxModal(null)}
        />
      )}

      {showDeleteTaxModal && (
        <DeleteModal
          data={showDeleteTaxModal}
          onClose={() => setShowDeleteTaxModal(null)}
        />
      )}

      {showAddBranchToGroupModal && (
        <AddBranchToGroupModal
          data={showAddBranchToGroupModal}
          onClose={() => setShowAddBranchToGroupModal(null)}
        />
      )}

      {showRemoveBranchFromGroupModal && (
        <RemoveBranchFromGroupModal
          data={showRemoveBranchFromGroupModal}
          onClose={() => setShowRemoveBranchFromGroupModal(null)}
        />
      )}

      {showAddProductToGroupModal && (
        <AddProductToGroupModal
          data={showAddProductToGroupModal}
          onClose={() => setShowAddProductToGroupModal(null)}
        />
      )}

      {showRemoveProductFromGroupModal && (
        <RemoveProductFromGroupModal
          data={showRemoveProductFromGroupModal}
          // productId={}
          onClose={() => setShowRemoveProductFromGroupModal(null)}
        />
      )}

      {showAddModifiersOptionToGroupModal && (
        <AddModifiersOptionToGroupModal
          data={showAddModifiersOptionToGroupModal}
          onClose={() => setShowAddModifiersOptionToGroupModal(null)}
        />
      )}
    </>
  )
}

export default Taxes