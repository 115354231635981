import { createSlice } from "@reduxjs/toolkit";
import {
  getInventoryControlList,
  getInventoryControlFilteredData,
  resetInventoryControlFilteredData,
} from "../services/inventoryControlServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,
  data: [],
  filteredData: [],
};

const reportsInventoryControl = createSlice({
  name: "reportsInventoryControl",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get Transfers List ------------------------------------ */
    builder.addCase(getInventoryControlList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getInventoryControlList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getInventoryControlList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- get Transfers Filtered Data------------------------------------------------ */
    builder.addCase(getInventoryControlFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getInventoryControlFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        state.errors = null;
      }
    );
    builder.addCase(getInventoryControlFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- reset Transfers Filtered Data------------------------------------------------ */
    builder.addCase(resetInventoryControlFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      resetInventoryControlFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.filteredData = [];
        state.errors = null;
      }
    );
    builder.addCase(resetInventoryControlFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default reportsInventoryControl.reducer;
