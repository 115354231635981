import React, { useState } from "react";
import { Box, Text, Flex, SimpleGrid, Button } from "@chakra-ui/react";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import EditEmergencyContact from "../modal/EditEmergencyContact";

const EmergencyContactCompo = ({ details }) => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <Box
      marginBottom={10}
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      border="1px"
      borderColor={"gray.400"}
      width="100%"
      padding="10px"
    >
      <Box padding={3}>
        <Box>
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"2px"}
            borderColor={"gray.300"}
            padding={3}
          >
            <Flex gap={2} alignItems={"center"}>
              <HiOutlineClipboardDocumentList style={{ fontSize: "22px" }} />
              <Text fontSize="xl" fontWeight={"bold"} color="gray.600">
                {t("pages.hr.Emergency_contact")}
              </Text>
            </Flex>
            <Box>
              <GButton
                title={t("general.edit")}
                color="black"
                border="1px solid gray"
                onClick={() => setShowEditModal(true)}
              />
            </Box>
          </Flex>
        </Box>
        <Box padding={3}>
          <SimpleGrid columns={2} spacing={5}>
            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.Name")} :
              <Text fontWeight={"bold"} textColor="gray.600" mx={"5px"}>
                {/* {details?.Email} */}
                {"Name"}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.Relation")} :
              <Text fontWeight={"bold"} textColor="gray.600" mx={"5px"}>
                {/* {details?.Email} */}
                Relation
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.Phone")} :
              <Text fontWeight={"bold"} textColor="gray.600" mx={"5px"}>
                {/* {details?.Email} */}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
      {showEditModal && (
        <EditEmergencyContact
          details={details}
          onClose={() => setShowEditModal(false)}
          // InsuranceOffice={InsuranceOffice}
        />
      )}
    </Box>
  );
};

export default EmergencyContactCompo;
