import { createSlice } from "@reduxjs/toolkit";
import {
  getPromotions,
  createPromotion,
  updatePromotion,
  deletePromotion,
  getPrmotionsTime
} from "../services/promotionsService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  prmotionTime:[]
};

const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPromotions.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getPromotions.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
      state.errors = null;
    })
    builder.addCase(getPromotions.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createPromotion.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createPromotion.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createPromotion.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updatePromotion.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updatePromotion.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(promotion => promotion.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updatePromotion.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deletePromotion.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deletePromotion.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(promotion => promotion.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deletePromotion.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(getPrmotionsTime.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })

    builder.addCase(getPrmotionsTime.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.prmotionTime = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.prmotionTime)
      state.errors = null;
    })

    builder.addCase(getPrmotionsTime.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default promotionsSlice.reducer;