import { createSlice } from "@reduxjs/toolkit";
import { login } from "../services/authService";
import CryptoJS from 'crypto-js';

let initialState = {
  isLoading: false,
  token: null,
  user: null,
  errors: null
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("privilege");
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.token = null;
      state.user = null;
      state.errors = null;
    })
      .addCase(login.fulfilled, (state, { payload }) => {
        //console.log(payload.listRoutes)
        // const flattenedArray = payload.listRoutes.flatMap(item => item.listLinks);
        // // localStorage.setItem('privilege', JSON.stringify(flattenedArray));
        // // const storedPrivilege = localStorage.getItem('privilege');
        // // const retrievedArray = JSON.parse(storedPrivilege);
        // // console.log(retrievedArray);

        const flattenedArray = payload.listRoutes.flatMap(item => item.listLinks);
        const jsonString = JSON.stringify(flattenedArray);
        //Encryption Algorithm: AES (Advanced Encryption Standard)
        const encryptedString = CryptoJS.AES.encrypt(jsonString, 'yourSecretKey').toString();
        localStorage.setItem('privilege', encryptedString);
        // const storedPrivilege = localStorage.getItem('privilege');
        // const decryptedString = CryptoJS.AES.decrypt(storedPrivilege, 'yourSecretKey').toString(CryptoJS.enc.Utf8);
        // const retrievedArray = JSON.parse(decryptedString);
        // console.log(retrievedArray);


        state.isLoading = false;
        state.token = payload.token;
        localStorage.setItem("userId", payload.userId);
        localStorage.setItem("token", payload.token);
        state.user = payload.fullName;
        state.errors = null;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.user = null;
        state.token = null;
        state.errors = payload.msg
      })
  }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;


