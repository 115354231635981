import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
  Input as ChInput,
} from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getTimedEventById,
  updateTimedEvent,
} from "../../services/timedEventsService";
import { getTime } from "services/timeService";

export default function EditModal({ onClose, data }) {
  const id = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: data,
  });
  watch(["Reference"]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  useEffect(() => {
    const StartDate = getValues("StartDate");
    const EndDate = getValues("EndDate");
    if (StartDate && EndDate && new Date(StartDate) > new Date(EndDate)) {
      setError("StartDate", {
        type: "manual",
        message: t("validation.start_date_must_be_before_end_date"),
      });
    } else {
      clearErrors("StartDate");
    }
  }, [getValues("StartDate"), getValues("EndDate")]);

  const [timedEventType, setTimedEventType] = useState({});

  useEffect(() => {
    const selectedEventType = timedEventsType.timedEventsTypeDropdown.find(
      (event) => event.timedEventsId === data.EventTypeId
    );
    setTimedEventType(selectedEventType || {});
  }, [data]);

  const timedEventsType = {
    timedEventsTypeDropdown: [
      {
        timedEventsId: 1,
        timedEventsName: "Set Fixed Price",
      },
      {
        timedEventsId: 2,
        timedEventsName: "Reduce Price by Fixed Amount",
      },
      {
        timedEventsId: 3,
        timedEventsName: "Reduce Price by Percentage Amount",
      },
      {
        timedEventsId: 4,
        timedEventsName: "Increase Price by Fixed Amount",
      },
      {
        timedEventsId: 5,
        timedEventsName: "Increase Price by Percentage Amount",
      },
      {
        timedEventsId: 6,
        timedEventsName: "Active Products",
      },
      {
        timedEventsId: 7,
        timedEventsName: "Deactive Products",
      },
    ],
  };

  const defaultOrderTypes = [
    {
      OrderTypeId: 1,
      OrderTypeName: "Dine in",
    },
    {
      OrderTypeId: 2,
      OrderTypeName: "Pick Up",
    },
    {
      OrderTypeId: 3,
      OrderTypeName: "Delivery",
    },
    {
      OrderTypeId: 4,
      OrderTypeName: "Drive Thru",
    },
  ];

  const time = useSelector((state) => state.time);

  useEffect(() => {
    dispatch(getTime());
  }, [dispatch]);

  const defaultDays = [
    {
      DayId: 1,
      DayName: "Sun",
    },
    {
      DayId: 2,
      DayName: "Mon",
    },
    {
      DayId: 3,
      DayName: "Tue",
    },
    {
      DayId: 4,
      DayName: "Wed",
    },
    {
      DayId: 5,
      DayName: "Thu",
    },
    {
      DayId: 6,
      DayName: "Fri",
    },
    {
      DayId: 7,
      DayName: "Sat",
    },
  ];

  const getLabelByTimedEventsId = (timedEventsId) => {
    const event = timedEventsType.timedEventsTypeDropdown.find(
      (item) => item.timedEventsId === timedEventsId
    );

    return event
      ? { label: event.timedEventsName, value: timedEventsId }
      : null;
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.timedEvents.edit_timed_event")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.TimedEventId = id.id;
                values.OrderType = values.OrderType?.map((el) => ({
                  OrderTypeId: el.OrderTypeId,
                }));
                values.Days = values.Days?.map((el) => ({
                  DayId: el.DayId,
                }));
                values.Name = values.TimedEventName;
                values.NameLoc = values.TimedEventNameLoc;
                values.StartTimeId = values.StartTime.DayTimeId;
                values.EndTimeId = values.EndTime.DayTimeId;
                values.UserId = localStorage.getItem("userId");
                if (
                  Array.isArray(values.OrderType) &&
                  values.OrderType.length === 0 &&
                  Array.isArray(values.Days) &&
                  values.Days.length === 0
                ) {
                  toast2({ description: t("validation.required") });
                } else {
                  dispatch(updateTimedEvent(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.timedEvents.timed_event_updated"),
                      });
                      dispatch(getTimedEventById(id.id));
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.TimedEventName}>
                  <FormLabel
                    htmlFor="TimedEventName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.timedEvents.name")}
                  </FormLabel>
                  <Controller
                    name="TimedEventName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.timedEvents.name")}
                        onChange={(e) => {
                          handleInputChange(e, "TimedEventName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.TimedEventName && errors.TimedEventName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.TimedEventNameLoc}>
                  <FormLabel
                    htmlFor="TimedEventNameLoc"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.timedEvents.name_localized")}
                  </FormLabel>
                  <Controller
                    name="TimedEventNameLoc"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.timedEvents.name_localized")}
                        onChange={(e) => {
                          handleInputChange(e, "TimedEventNameLoc");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.TimedEventNameLoc &&
                      errors.TimedEventNameLoc.message}
                  </FormErrorMessage>
                </FormControl>

                <MultiSelect
                  title={t("pages.timedEvents.type")}
                  defaultValue={getLabelByTimedEventsId(data?.EventTypeId)}
                  data={
                    timedEventsType.timedEventsTypeDropdown &&
                    timedEventsType.timedEventsTypeDropdown.map((el) => ({
                      ...el,
                      label: el.timedEventsName,
                      value: el.timedEventsId,
                    }))
                  }
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => {
                    setValue("EventTypeId", e.timedEventsId);
                    setTimedEventType(e);
                  }}
                />

                {timedEventType &&
                  timedEventType?.timedEventsName === "Set Fixed Price" && (
                    <FormControl isInvalid={errors.Amount}>
                      <FormLabel
                        htmlFor="Amount"
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("pages.timedEvents.fixed_price")}
                      </FormLabel>
                      <Controller
                        name="Amount"
                        control={control}
                        rules={{
                          required: t("validation.required"),
                          validate: (value) => {
                            const intValue = parseInt(value, 10);
                            return (
                              intValue >= 0 ||
                              t("validation.must_be_non_negative")
                            );
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={t("pages.timedEvents.fixed_price")}
                            onChange={(e) => {
                              handleInputChange(e, "Amount");
                            }}
                            type="number"
                            min="0"
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.Amount && errors.Amount.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                {timedEventType &&
                  (timedEventType?.timedEventsName ===
                    "Reduce Price by Fixed Amount" ||
                    timedEventType?.timedEventsName ===
                      "Increase Price by Fixed Amount") && (
                    <FormControl isInvalid={errors.Amount}>
                      <FormLabel
                        htmlFor="Amount"
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("pages.timedEvents.amount")}
                      </FormLabel>
                      <Controller
                        name="Amount"
                        control={control}
                        rules={{
                          required: t("validation.required"),
                          validate: (value) => {
                            const intValue = parseInt(value, 10);
                            return (
                              intValue >= 0 ||
                              t("validation.must_be_non_negative")
                            );
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={t("pages.timedEvents.amount")}
                            onChange={(e) => {
                              handleInputChange(e, "Amount");
                            }}
                            type="number"
                            min="0"
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.Amount && errors.Amount.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}

                {timedEventType &&
                  (timedEventType?.timedEventsName ===
                    "Reduce Price by Percentage Amount" ||
                    timedEventType?.timedEventsName ===
                      "Increase Price by Percentage Amount") && (
                    <FormControl isInvalid={errors.Amount}>
                      <FormLabel
                        htmlFor="Amount"
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("pages.timedEvents.percentage_amount")}
                      </FormLabel>
                      <Controller
                        name="Amount"
                        control={control}
                        rules={{
                          required: t("validation.required"),
                          validate: (value) => {
                            const intValue = parseInt(value, 10);
                            return (
                              intValue >= 0 ||
                              t("validation.must_be_non_negative")
                            );
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={t(
                              "pages.timedEvents.percentage_amount"
                            )}
                            onChange={(e) => {
                              handleInputChange(e, "Amount");
                            }}
                            type="number"
                            min="0"
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.Amount && errors.Amount.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                {/* Start Date */}
                <FormControl isInvalid={errors.StartDate}>
                  <FormLabel
                    htmlFor="StartDate"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.timedEvents.start_date")}
                  </FormLabel>
                  <Controller
                    name="StartDate"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="date"
                        placeholder={t("pages.timedEvents.start_date")}
                        onChange={(e) => {
                          handleInputChange(e, "StartDate");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.StartDate && errors.StartDate.message}
                  </FormErrorMessage>
                </FormControl>

                {/* End Date */}
                <FormControl isInvalid={errors.EndDate}>
                  <FormLabel
                    htmlFor="EndDate"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.timedEvents.end_date")}
                  </FormLabel>
                  <Controller
                    name="EndDate"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="date"
                        placeholder={t("pages.timedEvents.end_date")}
                        onChange={(e) => {
                          handleInputChange(e, "EndDate");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.EndDate && errors.EndDate.message}
                  </FormErrorMessage>
                </FormControl>

                {/* Start Time */}
                <MultiSelect
                  data={
                    time.data &&
                    time.data.map((el) => ({
                      ...el,
                      label: el.DayTimeName,
                      value: el.DayTimeId,
                    }))
                  }
                  onChange={(e) => setValue("StartTime", e)}
                  isMulti={false}
                  isRequired
                  title={t("pages.timedEvents.start_hour")}
                />

                {/* End Time */}
                <MultiSelect
                  data={
                    time.data &&
                    time.data.map((el) => ({
                      ...el,
                      label: el.DayTimeName,
                      value: el.DayTimeId,
                    }))
                  }
                  onChange={(e) => setValue("EndTime", e)}
                  isMulti={false}
                  isRequired
                  title={t("pages.timedEvents.end_hour")}
                />

                <MultiSelect
                  title={t("pages.timedEvents.applies_on_order_types")}
                  data={
                    Array.isArray(defaultOrderTypes)
                      ? defaultOrderTypes.map((el) => ({
                          ...el,
                          label: el.OrderTypeName,
                          value: el.OrderTypeId,
                        }))
                      : []
                  }
                  isMulti={true}
                  isRequired={true}
                  defaultValue={data?.OrderType.map((order) => ({
                    ...order,
                    label: order.OrderTypeName,
                    value: order.OrderTypeId,
                  }))}
                  onChange={(e) => {
                    setValue("OrderType", e);
                    trigger("OrderType");
                  }}
                />

                <MultiSelect
                  title={t("pages.timedEvents.applies_on_days")}
                  data={
                    Array.isArray(defaultDays)
                      ? defaultDays.map((el) => ({
                          ...el,
                          label: el.DayName,
                          value: el.DayId,
                        }))
                      : []
                  }
                  isMulti={true}
                  isRequired={true}
                  defaultValue={data?.WeekDays.map((order) => ({
                    ...order,
                    label: order.DayName,
                    value: order.DayId,
                  }))}
                  onChange={(e) => {
                    setValue("Days", e);
                    trigger("Days");
                  }}
                />

                <FormControl isInvalid={errors.Priority}>
                  <FormLabel
                    htmlFor="Priority"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.timedEvents.priority")}
                  </FormLabel>
                  <Controller
                    name="Priority"
                    control={control}
                    rules={{
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.timedEvents.priority")}
                        onChange={(e) => {
                          handleInputChange(e, "Priority");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Priority && errors.Priority.message}
                  </FormErrorMessage>
                </FormControl>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
