import React from 'react';
import Chart from 'react-apexcharts';

const DonutChart = () => {
  const donutChartData = {
    series: [20, 30, 40, 10],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4'],
    },
  };

  return (
    <div>
      <Chart
        options={donutChartData.options}
        series={donutChartData.series}
        type="donut"
        width="380"
      />
    </div>
  );
};

export default DonutChart;
