import { createSlice } from "@reduxjs/toolkit";
import { createInventoryCategory, deleteInventoryCategories, getInventoryCategoriesFilteredData, getInventoryCategoriesList, resetInventoryCategoriesFilteredData, restoreInventoryCategories, updateInventoryCategories } from "../services/InventoryCategoriesServices";

let initialState = {
    isLoading: false,
    errors: null,

    data: [],

    filteredData:[],
  };

  const InventoryCategoriesSlice = createSlice({
    name: "inventoryCategories",
    initialState,

    extraReducers:(builder)=>{
/* ----------------------------------------------------------- Get Inventory Categories List  -------------------------------------------------------*/
      builder.addCase(getInventoryCategoriesList.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getInventoryCategoriesList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getInventoryCategoriesList.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Crate New inventory Categories  -------------------------------------------------------*/
    builder.addCase(createInventoryCategory.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(createInventoryCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(createInventoryCategory.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Update inventory Categories   -------------------------------------------------------*/
      builder.addCase(updateInventoryCategories.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(updateInventoryCategories.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(updateInventoryCategories.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Delete inventory Categories   -------------------------------------------------------*/
builder.addCase(deleteInventoryCategories.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(deleteInventoryCategories.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(deleteInventoryCategories.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Restore Inventory Categories  -------------------------------------------------------*/
      builder.addCase(restoreInventoryCategories.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(restoreInventoryCategories.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(restoreInventoryCategories.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

         /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
    builder.addCase(getInventoryCategoriesFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getInventoryCategoriesFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getInventoryCategoriesFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetInventoryCategoriesFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetInventoryCategoriesFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetInventoryCategoriesFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
    }
  })


  export default InventoryCategoriesSlice.reducer;