import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Business Days Cards  Report List ------------------------------- */
  export const getBusinessDaysReportList = createAsyncThunk("businessDaysReport/getBusinessDaysReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetBusinessDaysReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

 /* --------------------------------------- Gift Cards Filter Data ------------------------------*/
 export const getBusinessDaysReportFilteredData = createAsyncThunk("businessDaysReport/getBusinessDaysReportFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetBusinessDaysReportFilteredData = createAsyncThunk( "businessDaysReport/resetBusinessDaysReportFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);