import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text,} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import DrawerOperationsTable from "./components/DrawerOperationsTable";
import ExportModal from "components/ui/modal/ExportModal";
import { Spinner } from '@chakra-ui/react';
import { getDrawerOperationsReportList } from "./services/drawerOperReportServices";


export default function ReportDrawerOperations() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [exportModal, SetExportModal] = useState(false);

  const businessReports = useSelector((state) => state.drawerOperationsReport);

  useEffect(() => {
    dispatch(getDrawerOperationsReportList());
  }, [dispatch]);

  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.reports.business_reports.drawer_operation")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
              <Text>{t("general.export")}</Text>
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>

      <>
      {
        businessReports?.isLoading ? 
        <Flex
            p={4}
            bg="white"
            alignItems="center"
            justifyContent="center"
        >
            <Spinner /> 
        </Flex>
        :
        <DrawerOperationsTable data={businessReports?.data} filteredData={businessReports?.filteredData}/>
      }
      </>

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={businessReports?.data}
          fileName={"ReportDrawerOperations"}
        ></ExportModal>
      )}
    </>
  );
}
