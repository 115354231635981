import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";



  /* -------------------------------------------------- Product DropDown List  ---------------------------------------------------------------*/
export const productDropList = createAsyncThunk("product/productDropList", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Tax/LookUpProduct`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* -------------------------------------------------- Get Product List  ---------------------------------------------------------------*/
  export const productList = createAsyncThunk( "product/productList", async (args, thunkApi) => {
    //console.log(args);
      try {
        const { data } = await axios.get(`/Menu/Group/GroupProductListById?groupid={"MenuGroupId":${args}}`);

        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* -------------------------------------------------- Add Product To Group  ---------------------------------------------------------------*/
  export const addProduct = createAsyncThunk("product/addProduct", async (args, thunkApi) => {
    console.log("args" , args);
      try {
        const { data } = await axios.get(`/Menu/Group/AddGroupProducts?group=${JSON.stringify(args)}`);
        console.log(data);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );