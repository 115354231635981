import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
  Input as ChInput,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { Controller, useForm } from "react-hook-form";
import { generator } from "utils/generator";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  createNewWarehouse,
  getInventoryDayTimeList,
  getWarehouseList,
} from "../../services/WarehouseServices";

export default function CreateWarehouse({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();
  watch(["Ref"]);

  const warehouse = useSelector((state) => state.warehouse);

  useEffect(() => {
    dispatch(getInventoryDayTimeList());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.warehouse.create_warehouse")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.UserId = localStorage.getItem("userId");
                if (values.Ref === undefined) {
                  toast2({ description: t("general.should_generate") });
                } else {
                  if (values.DayTimeId === undefined) {
                    toast2({ description: t("validation.choose_first") });
                  } else {
                    values.DayTimeId = values.DayTimeId?.DayTimeId;
                    dispatch(createNewWarehouse(values))
                      .unwrap()
                      .then((_) => {
                        toast({
                          description: t("pages.warehouse.warehouse_created"),
                        });
                        dispatch(getWarehouseList());
                        onClose();
                      });
                  }
                }
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.Name}>
                  <FormLabel
                    htmlFor="Name"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.warehouse.name")}
                  </FormLabel>
                  <Controller
                    name="Name"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.warehouse.name")}
                        onChange={(e) => {
                          handleInputChange(e, "Name");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Name && errors.Name.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.NameLoc}>
                  <FormLabel
                    htmlFor="NameLoc"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.warehouse.name_Localized")}
                  </FormLabel>
                  <Controller
                    name="NameLoc"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalide"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.warehouse.name_Localized")}
                        onChange={(e) => {
                          handleInputChange(e, "NameLoc");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.NameLoc && errors.NameLoc.message}
                  </FormErrorMessage>
                </FormControl>

                <MultiSelect
                  title={t("pages.warehouse.end_of_day")}
                  isMulti={false}
                  data={
                    warehouse?.InventoryDayTimeList &&
                    warehouse?.InventoryDayTimeList.map((el) => ({
                      ...el,
                      label: el.DayTimeName,
                      value: el.DayTimeId,
                    }))
                  }
                  onChange={(e) => setValue("DayTimeId", e)}
                />

                <Flex alignItems="flex-end" gap={4}>
                  <Input
                    label={t("pages.warehouse.reference")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={t("pages.warehouse.reference")}
                    isRequired
                    value={getValues("Ref") || ""}
                    readOnly
                  />
                  <GButton
                    title={t("pages.warehouse.generate")}
                    height="50px"
                    bg="light"
                    border="1px solid"
                    borderColor="border"
                    hover={{
                      bg: "primary",
                      borderColor: "primary",
                      color: "light",
                    }}
                    onClick={() => setValue("Ref", generator({}))}
                  />
                </Flex>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
