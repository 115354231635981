import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTransfereOrdersFilteredData } from '../../services/transfersOrdersService';

export default function FilterModal({onClose , data}) {
    const { t } = useTranslation();
    const dispatch =useDispatch();

    const [filteredData, setFilteredData] = useState(data);

    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
        Destnisiton: "",
        TransferOrdersStatusName: "",
        Werahose: "",
      });

      const handleFilterTransfereOrders= (e) => {
        e.preventDefault();
    
        const filteredResult = data?.filter((item) => {
          const { Destnisiton , TransferOrdersStatusName , Werahose } = filterCriteria;
    
          let filterDestnisiton = true;
          let filterTransferOrdersStatusName = true;
          let filterWerahose = true;
    
          if (Destnisiton) {
            filterDestnisiton = item?.Destnisiton?.toLowerCase().includes(Destnisiton.toLowerCase());
          }
    
          if (TransferOrdersStatusName) {
            filterTransferOrdersStatusName = item?.TransferOrdersStatusName?.toLowerCase().includes(TransferOrdersStatusName.toLowerCase());
          }
    
          if (Werahose) {
            filterWerahose = item?.Werahose?.toLowerCase().includes(Werahose.toLowerCase());
          }

          return (
            filterDestnisiton &&
            filterTransferOrdersStatusName &&
            filterWerahose 
          );

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getTransfereOrdersFilteredData(filteredResult));
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.transfers_orders.transfers_orders_filter")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterTransfereOrders}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.transfers_orders.warehouse_name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.transfers_orders.warehouse_name")}
                name="Werahose"
                value={filterCriteria.Werahose}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Werahose : e.target.value,
                  })
                }
              />
              <Input
                label={t("pages.transfers_orders.status")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.transfers_orders.status")}
                name="TransferOrdersStatusName"
                value={filterCriteria.TransferOrdersStatusName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    TransferOrdersStatusName : e.target.value,
                  })
                }
              />
              <Input
                label={t("pages.transfers_orders.destination_name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.transfers_orders.destination_name")}
                name="Destnisiton"
                value={filterCriteria.Destnisiton}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Destnisiton: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
