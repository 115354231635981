export const generator = ({
  length = 6,
  isNumeric = true
}) => {
  const alfaNumeric = isNumeric ? "123456789" : "abcdefgh123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomChar = Math.floor((Math.random() * alfaNumeric.length) + 1);
    result += randomChar;
  }
  return result;
}