import { useTranslation } from "react-i18next";
import GModal from "./Modal";
import * as FiIcons from "react-icons/fi";
import { Box, Flex, Heading, Spinner } from "@chakra-ui/react";
import { MdSettingsBackupRestore } from "react-icons/md";
import GButton from "../button/Button";

const RestoreModal = ({
  onClose,
  onSubmit,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  return (
    <GModal
      modalHeader={t("general.restore")}
      onClose={onClose}
      modalContent={(
        isLoading ? (
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <Box p={6}>
            <Heading
              as="h4" fontSize="1.3rem" mb={6} textTransform="capitalize"
            >
              {t("general.restore_item")}
            </Heading>
            <Flex gap={4}>
              <GButton
                title={t("general.restore")}
                prefixElement={<MdSettingsBackupRestore size={16} />}
                bg="primary"
                color="light"
                border="1px solid transparent"
                hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                onClick={onSubmit}
              />
              <GButton
                title={t("general.close")}
                prefixElement={<FiIcons.FiMinimize size={16} />}
                bg="none"
                color="dark"
                border="1px solid"
                borderColor="border"
                hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                onClick={onClose}
              />
            </Flex>
          </Box>
        )
      )}
    />
  )
}

export default RestoreModal;