import { createSlice } from "@reduxjs/toolkit";
import {
  getTags,
  createTag,
  updateTag,
  deleteTag
} from "../services/tagsService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createTag.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createTag.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createTag.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateTag.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateTag.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(updateTag.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* -------------------------------------------------------------- Delete Tag ------------------------------------------------------------------ */
    builder.addCase(deleteTag.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteTag.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(deleteTag.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default tagsSlice.reducer;