import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import * as FiIcons from "react-icons/fi";
import DeliveryZoneTable from '../components/DeliveryZoneTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDeliveryZone, getDeletedDeliveryZone } from '../services/deliveryZoneNewService';
import CreateDeliveryZoneModal from '../components/Modal/CreateDeliveryZoneModal';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';

const DeliveryZonesPage = () => {


  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 46);

    const { t } = useTranslation();
    const dispatch=useDispatch()

    useEffect(()=>{
      dispatch(getAllDeliveryZone())
    },[dispatch])

    useEffect(()=>{
      dispatch(getDeletedDeliveryZone())
    },[dispatch])

    const data = useSelector(state => state.deliveryZoneNew);

    const [showmodal,setShowModal]=useState(false)

    if(ManageBranches===false  ){
      return(
        <NotAuthorizedCompo/>
      )
    }

  return <>
    <div>
    <Box
    bg="light" marginInline={-6} marginTop={-6} padding={6}
    borderTop="1px solid" borderColor="border" marginBottom={6}
  >
    <Flex alignItems="center" justifyContent="space-between" gap={4}>
      <Text
        fontSize="1.2rem" color="dark" textTransform="capitalize"
      >
        {t("pages.DeliverZone.delivery_zone")}
      </Text>

      <Flex gap={2}>
        <Button
          type="button" bg="primary" color="light"
          textTransform="capitalize" fontSize="1rem"
          onClick={() => setShowModal(true)}
        >
          <Flex alignItems="center" gap={1} >
            {t("pages.DeliverZone.create_delivery_zone")}
          </Flex>
        </Button>
      </Flex>
    </Flex>
  </Box>


  {
    data.isLoading?(
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    ):(
      <DeliveryZoneTable data={data.data} filteredData={data?.filteredData} 
                         deletedData={data.deletedDelvieryZone}
      >
      </DeliveryZoneTable>
    )
  }



  {
    showmodal&&(<CreateDeliveryZoneModal onClose={() => setShowModal(false)}/>)
  }
  
    </div>
  </>;
}

export default DeliveryZonesPage;
