import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Tabs from "../components/Tabs";
import routes from "../../../navigations/routes";
import BreadCrumb from "../../../components/ui/breadcrumb/BreadCrumb";

const Dashboard = () => {
  const { t } = useTranslation();

  const items = [
    { path: routes.dashboard_general, text: t("pages.dashboard.general") },
    { path: routes.dashboard_branches, text: t("pages.dashboard.branches") },
    { path: routes.dashboard_inventory, text: t("pages.dashboard.inventory") },
  ];

  return (
    <>
      <BreadCrumb>
        <Box as="p" fontSize="1.6rem" textTransform="capitalize">
          {t("general.welcome")}, muhammed
        </Box>
      </BreadCrumb>
      <Tabs items={items} />

      <Outlet />
    </>
  );
};

export default Dashboard;
