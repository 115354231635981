import { useTranslation } from "react-i18next";
import GButton from "../button/Button";

const Pagination = ({
  currentPage,
  setCurrentPage,
  npage,
  prePage,
  nextPage,
}) => {
  const totalPagesToShow = 5;
  const { t } = useTranslation();

  const getVisiblePageNumbers = () => {
    const totalVisiblePages = Math.min(totalPagesToShow, npage);
    const half = Math.floor(totalVisiblePages / 2);
    let startPage = currentPage - half;
    startPage = Math.max(startPage, 1);
    const endPage = Math.min(startPage + totalVisiblePages - 1, npage);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const visiblePageNumbers = getVisiblePageNumbers();

  return (
    <nav style={{ margin: "25px 0px" }}>
      <ul
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          maxWidth: "100%",
          margin: "0 20px",
          gap: "10px",
        }}
      >
        <li style={{ listStyle: "none" }}>
          <GButton
            title={t("general.prev")}
            bg="primary"
            color="light"
            hover={{ bg: "primary" }}
            onClick={prePage}
            isDisabled={currentPage === 1}
          />
        </li>

        <li
          style={{
            listStyle: "none",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          {visiblePageNumbers.map((pageNumber) => (
            <div
              key={pageNumber}
              onClick={() => setCurrentPage(pageNumber)}
              style={{
                cursor: "pointer",
                fontWeight: currentPage === pageNumber ? "bold" : "normal",
              }}
            >
              <GButton
                title={pageNumber}
                color="light"
                hover={{ bg: "primary" }}
                style={{
                  cursor: "pointer",
                  background: currentPage === pageNumber ? "green" : "#54A0FF",
                }}
              />
            </div>
          ))}
        </li>

        <li style={{ listStyle: "none" }}>
          <GButton
            title={t("general.next")}
            bg="primary"
            color="light"
            hover={{ bg: "primary" }}
            onClick={nextPage}
            isDisabled={currentPage === npage}
          />
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
