import { createSlice } from "@reduxjs/toolkit";
import {
  addBranchToGroup,
  removeBranchFromGroup,
  getBranchesForSpecificGroup,
  getBranchesInTaxesGroup,
  getTaxesProductBranches,
  getTaxesGroupsBranchesToRemove,
} from "../services/branchesToGroupsService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  branchDetails:[],
  TaxesGroupsBranches : [],
  TaxesGroupsBranchesToRemove:[]
};

const branchesToGroupsSlice = createSlice({
  name: "branchGroups",
  initialState,
  extraReducers: (builder) => {

    builder.addCase(addBranchToGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addBranchToGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    });
    builder.addCase(addBranchToGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------- Get Taxes GroupsB ranches To Remove -------------------------------------------*/
    builder.addCase(getTaxesGroupsBranchesToRemove.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTaxesGroupsBranchesToRemove.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TaxesGroupsBranchesToRemove =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getTaxesGroupsBranchesToRemove.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------- Get Taxes Groups Branches -------------------------------------------*/
    builder.addCase(getBranchesInTaxesGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getBranchesInTaxesGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TaxesGroupsBranches =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getBranchesInTaxesGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* --------------------------------------------  Taxes Groups  -------------------------------------------*/
    builder.addCase(removeBranchFromGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(removeBranchFromGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //   state.data = state.data.filter(tax => tax.id !== payload.id);
      state.errors = null;
    });
    builder.addCase(removeBranchFromGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    builder.addCase(getBranchesForSpecificGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(
      getBranchesForSpecificGroup.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.branchDetails =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      }
    );

    builder.addCase(getBranchesForSpecificGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default branchesToGroupsSlice.reducer;
