import React, { Fragment, useState } from "react";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import RestoreModal from "./modals/RestoreModal";
import { MdSettingsBackupRestore } from "react-icons/md";
import { useSelector } from "react-redux";
import Pagination from "components/ui/pagination/Pagination";

const DeletedTable = ({ data }) => {
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState(null);
  const { t } = useTranslation();

  const filteredmodifierss = useSelector((state) => state.modifiers);
  console.log(filteredmodifierss);
  const filteredmodifiers = filteredmodifierss.filteredData.filter(
    (obj) => obj.IsDeleted === true
  );
  console.log(filteredmodifiers);

  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [filteredPageAll, setFilteredPageAll] = useState(1);

  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const recordsPerPage = 10;

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  const recordsAll = calculateRecords(data, currentPageAll, recordsPerPage);

  const filteredRecordsAll = calculateRecords(
    filteredmodifiers,
    filteredPageAll,
    recordsPerPage
  );

  const clickedbuttom = (id) => {
    setShowModel(true);
    setId(id);
  };

  return (
    <div>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        {filteredmodifiers.length === 0 ? (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.modifiers.name")}</Th>
                  <Th>{t("pages.modifiers.ref")}</Th>
                  <Th>{t("general.action")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recordsAll.map((modifier, index) => (
                  <Tr key={modifier.ModifersId}>
                    <Td>{index + 1}</Td>
                    <Td>{modifier.ModifersNameEng}</Td>
                    <Td>{modifier.ModifersRef}</Td>
                    <Td className="activeIcon">
                      <Flex>
                        <Box
                          color="#C53030"
                          _hover={{ color: "#38A169" }}
                          p={2}
                          width={"fit-content"}
                          cursor="pointer"
                          onClick={() => clickedbuttom(modifier.ModifersId)}
                        >
                          <MdSettingsBackupRestore size={36} />
                        </Box>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={currentPageAll}
              setCurrentPage={setCurrentPageAll}
              npage={Math.ceil(data.length / recordsPerPage)}
              prePage={() => prePage(currentPageAll, setCurrentPageAll)}
              nextPage={() =>
                nextPage(
                  currentPageAll,
                  setCurrentPageAll,
                  Math.ceil(data.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.modifiers.name")}</Th>
                  <Th>{t("pages.modifiers.ref")}</Th>
                  <Th>{t("general.action")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredRecordsAll.map((modifier, index) => (
                  <Tr key={modifier.ModifersId}>
                    <Td>{index + 1}</Td>
                    <Td>{modifier.ModifersNameEng}</Td>
                    <Td>{modifier.ModifersRef}</Td>
                    <Td className="activeIcon">
                      <Flex>
                        <Box
                          color="#C53030"
                          _hover={{ color: "#38A169" }}
                          p={2}
                          width={"fit-content"}
                          cursor="pointer"
                          onClick={() => clickedbuttom(modifier.ModifersId)}
                        >
                          <MdSettingsBackupRestore size={36} />
                        </Box>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={filteredPageAll}
              setCurrentPage={setFilteredPageAll}
              npage={Math.ceil(filteredmodifiers.length / recordsPerPage)}
              prePage={() => prePage(filteredPageAll, setFilteredPageAll)}
              nextPage={() =>
                nextPage(
                  filteredPageAll,
                  setFilteredPageAll,
                  Math.ceil(filteredmodifiers.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        )}

        {showModel && (
          <RestoreModal id={id} onClose={() => setShowModel(false)} />
        )}
      </Box>
    </div>
  );
};

export default DeletedTable;
