import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text , useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import { useTranslation } from 'react-i18next';
import AddTagsModal from './modals/AddTagsModal';
import { useDispatch, useSelector } from 'react-redux';
import { getlinkedTagswithSupplier } from '../services/linkItemToSupplierService';
import LinkedTagsTable from './LinkedTagsTable';
import { useParams } from 'react-router-dom';
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const AddingTags = () => {
    const { t } = useTranslation();
    const dispatch=useDispatch();
    const id = useParams();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const [showModal,setshowModal]=useState(false);
    const data = useSelector(state => state.linkItemToSupplier);

    useEffect(()=>{
      dispatch(getlinkedTagswithSupplier(id.suppliersId));
    },[dispatch ,id]);

/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege();

const ManageSuppliers = checkIdExists(retrievedArray, 12);

if(showModal===true && ManageSuppliers===false){
  toast2({ description:  t("general.authorization")});
  setshowModal(false);
};
/* -----------------------------------Privilige-------------------------------*/

  return (
    <Box>
    
    <Flex
    alignItems="center" justifyContent="space-between"
    gap={2} flexWrap="wrap" mb={6}
  >
    <Text color="text" textTransform="capitalize">
      {t("pages.suppliers.Tags")}
    </Text>
    <GButton
      title={t("pages.suppliers.Add_tags")}
      bg="light"
      color="text"
      fontSize="14px"
      border="1px solid"
      borderColor="border"
      hover={{ bg: "light" }}
      onClick={() => setshowModal( true)}
    />
  </Flex>



  {data.isLoading ? (
    <Spinner />
  ) : data.linkedTags?.length === 0 ? (
    <Flex
      p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
    >
      {t("pages.branches.no_data")}
    </Flex>
  ) : data.linkedTags?.length > 0 ? (
    <LinkedTagsTable
    data={data.linkedTags}
    />
  ):(
    <Flex
    p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  >
    {t("pages.branches.no_data")}
  </Flex>
  )
  }


{ showModal &&(
        <AddTagsModal id={id} onClose={() => setshowModal(false)}></AddTagsModal>
        )
}
  
    </Box>
  );
}

export default AddingTags;
