import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { getBranchesForSpecificGroup, getBranchesInTaxesGroup, getTaxesGroupsBranches, getTaxesGroupsBranchesToRemove } from "../../services/branchesToGroupsService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { removeBranchFromGroup } from "../../services/branchesToGroupsService";
import { getTaxesGroups } from "../../services/taxesGroupsService";

export default function RemoveBranchFromGroupModal ({ data, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top",  duration: 3000,  status: "success" });
  const toast2 = useToast({ position: "top",  duration: 3000,  status: "error" });
  const { handleSubmit, setValue } = useForm();

  const branches = useSelector((state) => state.branchGroups);

  useEffect(() => {
    dispatch(getBranchesForSpecificGroup(data.TaxGroupId));
    dispatch(getTaxesGroupsBranchesToRemove());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.taxes.remove_from_branches")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.TaxGroupBranchesId = values.TaxGroupBranchesId?.TaxGroupBranchesId;
              values.IsDeleted = true;
              if( values.TaxGroupBranchesId === undefined ){
                  toast2({description: t("general.validate")});
              }
              else {
                  dispatch(removeBranchFromGroup(values))
                    .unwrap()
                    .then((_) => {
                      toast({description: t("pages.taxes.branch_removed_to_tax_group"),});
                      dispatch(getTaxesGroups());
                      onClose();
                    });
                }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                data={
                  branches.TaxesGroupsBranchesToRemove &&
                  branches?.TaxesGroupsBranchesToRemove?.map((el) => ({
                    ...el,
                    label: el.Branches[0].BranchName,
                    value: el.TaxGroupBranchesId,
                  }))
                }
                isRequired
                title={t("pages.taxes.branches")}
                onChange={(e) => setValue("TaxGroupBranchesId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.taxes.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={data.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={data.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

