import React, { useEffect, useState } from "react";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function TransferDetailsTable({data}) {
  // console.log(data);

    const id = useParams()
    const { t } = useTranslation();
    const dispatch = useDispatch();

  return <>
  
      <Stack spacing={12}>
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.transfers.source")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.Source}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
            {t("pages.transfers.destination")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
            {data[0]?.Destination}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
            {t("pages.transfers.to_reference")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
            {data[0]?.TransfersRef}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
            {t("pages.transfers.business_date")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
            {/* {data[0]?.Source} */}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
            {t("pages.transfers.creator")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
            {/* {data[0]?.Source} */}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.cost_adjustment.created")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
            {data[0]?.CreateDate}
            </Text>
          </Box>

        </SimpleGrid>

      </Stack>
  
  </>
}
