import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addTagToSupplier,
  getAllTagsToSupplier,
  getlinkedTagswithSupplier,
} from "../../services/linkItemToSupplierService";
import { useParams } from "react-router-dom";

const AddTagsModal = ({ onClose }) => {
  const { t } = useTranslation();
  const id = useParams();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue } = useForm();

  const data = useSelector((state) => state.linkItemToSupplier);

  useEffect(() => {
    dispatch(getAllTagsToSupplier());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.suppliers.Add_tags")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.SuppliersTag = values.SuppliersTag?.map((el) => ({
                TagId: el.TagId,
                SuppliersId: id.suppliersId,
              }));
              values.SuppliersTagId = null;
              values.UserId = localStorage.getItem("userId");
              if (
                values.SuppliersTag === undefined ||
                values.SuppliersTag?.length === 0
              ) {
                toast2({ description: t("validation.choose_first") });
              } else {
                dispatch(addTagToSupplier(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.suppliers.tag_added") });
                    dispatch(getlinkedTagswithSupplier(id.suppliersId));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.suppliers.Tags")}
                data={
                  Array.isArray(data.Tags)
                    ? data.Tags.map((el) => ({
                        ...el,
                        label: el.TagName,
                        value: el.TagId,
                      }))
                    : []
                }
                isMulti={true}
                isRequired={true}
                onChange={(e) => setValue("SuppliersTag", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddTagsModal;
