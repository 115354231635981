import React, { useEffect, useState } from 'react';
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb"
import { Box, Flex, SimpleGrid, Spinner, Stack, Text , useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSupplierDetails } from '../services/inventorySupplierService';
import { useParams } from 'react-router-dom';
import EditSupplierModal from '../components/modals/EditSupplierModal';
import DeleteSupplier from '../components/DeleteSupplier';
import LinkItemToSupplier from '../components/LinkItemToSupplier';
import AddingTags from '../components/AddingTags';
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const SuppliersDetailsInventory = () => {
    const { t } = useTranslation();
    const dispatch=useDispatch();
    const id = useParams();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const [showModal,setShoModal]=useState(false)

    const supplierDetails = useSelector(state => state.inventorySuppliers);
    const data=supplierDetails.SupplierDetails

    useEffect(()=>{
        dispatch(getSupplierDetails(id.suppliersId));
    },[dispatch,id])


/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()

const ManageSuppliers = checkIdExists(retrievedArray, 12);
const ReadSuppliers = checkIdExists(retrievedArray, 11);

if(showModal===true && ManageSuppliers===false){
  toast2({ description:  t("general.authorization")});
  setShoModal(false);
};
// Cant Show Any Thing
if(ManageSuppliers===false && ReadSuppliers===false ){
  return(
    <NotAuthorizedCompo/>
  )
};
/* -----------------------------------Privilige-------------------------------*/

  return (
   <>
   
   <BreadCrumb>
   <Flex justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">
     <Box
       as="p" fontSize="1.6rem" textTransform="capitalize"
     >
        {data[0]?.SuppliersName || "-"} 
     </Box>
     <GButton
       title={t("general.edit")}
       bg="#54A0FF"
       color="light"
       hover={{ bg: "primary" }}
       onClick={() => setShoModal(true)}
     />
     
   </Flex>
 </BreadCrumb>

 <Stack spacing={12}>
 

 <SimpleGrid
    spacing={6}
    columns={{ base: 1, md: 2 }}
    bg="white"
    p={4} borderRadius={6}
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >


<Box borderBottom="1px solid" borderColor="border">
<Text textTransform="capitalize" color="text">
  {t("pages.suppliers.contact_name")}
</Text>
<Text marginBlock={2}>
  {data[0]?.ContactName|| "-"} 
</Text>
</Box>


<Box borderBottom="1px solid" borderColor="border">
<Text textTransform="capitalize" color="text">
  {t("pages.suppliers.supplier_code")}
</Text>
<Text marginBlock={2}>
  {data[0]?.SuppliersCode|| "-"} 
</Text>
</Box>


<Box borderBottom="1px solid" borderColor="border">
<Text textTransform="capitalize" color="text">
  {t("pages.suppliers.supplier_name")}
</Text>
<Text marginBlock={2}>
  {data[0]?.SuppliersName|| "-"} 
</Text>
</Box>


<Box borderBottom="1px solid" borderColor="border">
<Text textTransform="capitalize" color="text">
  {t("pages.suppliers.email")}
</Text>
<Text marginBlock={2}>
  {data[0]?.PrimaryEmail|| "-"} 
</Text>
</Box>


<Box borderBottom="1px solid" borderColor="border">
<Text textTransform="capitalize" color="text">
  {t("pages.suppliers.additional_emails")}
</Text>
<Text marginBlock={2}>
  {data[0]?.AdditionalEmails || "-"} 
</Text>
</Box>

<Box borderBottom="1px solid" borderColor="border">
<Text textTransform="capitalize" color="text">
  {t("pages.suppliers.supplier_phone")}
</Text>
<Text marginBlock={2}>
  {data[0]?.Phone || "-"} 
</Text>
</Box>


{
    data[0]?.IsActive?(
        <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
                {t("pages.suppliers.isActive")}
            </Text>
            <Text marginBlock={2}>
            IsActive
            </Text>
        </Box>
        ):(
            <Box borderBottom="1px solid" borderColor="border">
                <Text textTransform="capitalize" color="text">
                    {t("pages.suppliers.isActive")}
                </Text>
                <Text marginBlock={2}>
                    inActive
                </Text>
            </Box>
        )
}


    </SimpleGrid>
    
    <LinkItemToSupplier />
    <AddingTags />
    <DeleteSupplier />

 </Stack>
{ showModal&&(
        <EditSupplierModal onClose={() => setShoModal(false)} data={data}></EditSupplierModal>
    )
}
   
   </>
  );
}

export default SuppliersDetailsInventory;
