import { createSlice } from "@reduxjs/toolkit";
import {addItemToPurchase,deletingPurchasing,editItemsQuantity,getItemsReturnDropdown,getPurchasingItemList, sendReceiveItems, sendReturnedItems} from "../services/PurchasingItemsService"

let initialState = {
    isLoading: false,
    data: [],
    itemsReturnDropsown: [],
    returnSuppliersDetails: [],
    pages: 0,
    errors: null,
  };


  const PurchasingItemsSlice = createSlice({
    name: "purchasesItems",
    initialState,

    extraReducers: (builder) => {
/*  ------------------------------------------------- Add  Item To purchasing ------------------------------------------------ */
        builder.addCase(addItemToPurchase.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(addItemToPurchase.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(addItemToPurchase.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
/* --------------------------------------------------------Get Purchasing Item List----------------------------------------------------- */
          builder.addCase(getPurchasingItemList.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getPurchasingItemList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
      
          builder.addCase(getPurchasingItemList.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
/* --------------------------------------------------------Get Purchasing Item List----------------------------------------------------- */
          builder.addCase(editItemsQuantity.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(editItemsQuantity.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(editItemsQuantity.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
/* -------------------------------------------------------- Delete Purchasing ----------------------------------------------------- */
          builder.addCase(deletingPurchasing.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(deletingPurchasing.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(deletingPurchasing.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
  /*  ------------------------------------------------- Send Receive Items ------------------------------------------------ */
          builder.addCase(sendReceiveItems.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(sendReceiveItems.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(sendReceiveItems.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
  /*  ------------------------------------------------- get Items Return to supplier Dropdown ------------------------------------------------ */
          builder.addCase(getItemsReturnDropdown.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getItemsReturnDropdown.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.itemsReturnDropsown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
      
          builder.addCase(getItemsReturnDropdown.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
  /*  ------------------------------------------------- Send Returned Items------------------------------------------------ */
          builder.addCase(sendReturnedItems.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(sendReturnedItems.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(sendReturnedItems.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });

    }
  })


  export default PurchasingItemsSlice.reducer;