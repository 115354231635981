import {
  Box,
  Checkbox,
  Td,
  Tr,
  Thead,
  Table,
  Th,
  Tbody,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getGroupShiftDetails } from "../services/attendanceSettingServices";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NoDataFound from "components/ui/noDataFound/NoDataFound";

export default function GroupshiftDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector((state) => state.attendanceSetting);
  useEffect(() => {
    dispatch(getGroupShiftDetails(id));
  }, [dispatch, id]);

  if (data?.isLoading) {
    return (
      <Flex bg="light" p={4} justifyContent="center" mt={"50px"}>
        <Spinner />
      </Flex>
    );
  }
  if (data?.GroupShiftDetails?.length === 0) {
    return <NoDataFound />;
  }
  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th py={5} bg={"gray.500"} textColor={"white"}>
              {t("pages.hr.attendance.days")}
            </Th>
            <Th py={5} bg={"gray.500"} textColor={"white"}>
              {t("pages.hr.attendance.is_vacation")}
            </Th>
            <Th py={5} bg={"gray.500"} textColor={"white"}>
              {t("pages.hr.attendance.from_date")}
            </Th>
            <Th py={5} bg={"gray.500"} textColor={"white"}>
              {t("pages.hr.attendance.to_date")}
            </Th>
            <Th py={5} bg={"gray.500"} textColor={"white"}>
              {t("pages.hr.attendance.from_time")}
            </Th>
            <Th py={5} bg={"gray.500"} textColor={"white"}>
              {t("pages.hr.attendance.to_time")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.GroupShiftDetails?.map((item, index) => (
            <Tr key={index}>
              <Td>{item?.Time_Day}</Td>
              <Td>
                <Checkbox colorScheme="green" isChecked={item?.IS_Vacation}>
                  Is Vacation
                </Checkbox>
              </Td>
              <Td>{item?.From_Date}</Td>
              <Td>{item?.To_Date}</Td>
              <Td>{item?.FromTime}</Td>
              <Td>{item?.ToTime}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
