import { createSlice } from "@reduxjs/toolkit";

import {getCahers} from "../services/casherForOnlineOrdersService"


let initialState = {
    isLoading: false,
    data: [],
    pages: 0,
    errors: null
  };


  const cashersForOnlineOrdersSlice = createSlice({
    name: "casherOnlineOrder",
    initialState,


    extraReducers: (builder) => {


                   
    builder.addCase(getCahers.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(getCahers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.data)
        state.errors = null;
      })
      builder.addCase(getCahers.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

    }


})




export default cashersForOnlineOrdersSlice.reducer;