import React from "react";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";
import { approvingRegectedPurchaseOrder } from "../../services/purchasrOrderServices";

export default function RejectedModal({ onClose, id }) {


    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const toast = useToast({
        position: "top",
        duration: 3000,
        status: "success",
      });
      
  return (
    <>
      <GDeleteModal
        onClose={onClose}
        onSubmit={() => {
          dispatch(approvingRegectedPurchaseOrder(id))
            .unwrap()
            .then((_) => {
              toast({ description: t("pages.purchase_orders.reject") });
              onClose();
              navigate(`/${routes.inventory_purchase_orders}`);
            });
        }}
      />
    </>
  );
}
