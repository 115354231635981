import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text } from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import UpdateModal from "../components/modals/UpdateModal";
import GButton from "components/ui/button/Button";
import Sections from "../components/Sections";
import Tags from "../components/Tags";
import DeliveryZones from "../components/DeliveryZones";
import Delete from "../components/Delete";
import { getBranchById } from "../services/branchesService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Details = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 39);

  const { branchId } = useParams();
  const dispatch = useDispatch();
  const branches = useSelector((state) => state.branches);
  const { t } = useTranslation();

  const branch = branches.branch;
 // console.log(branch);

  const [showUpdateBranchModal, setShowUpdateBranchModal] = useState(null);

  useEffect(() => {
    dispatch(getBranchById({ branchId }));
  }, [dispatch, branchId]);

  if (branches.isLoading) return <Spinner />;


  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }
  

  return branch ? (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            {branch?.BranchName || "-"}
          </Box>
          <GButton
            title={t("general.edit")}
            bg="#54A0FF"
            color="light"
            hover={{ bg: "primary" }}
            onClick={() =>
              setShowUpdateBranchModal({
                ...branch,
                BranchId: +branchId || null,
              })
            }
          />
        </Flex>
      </BreadCrumb>
      <Stack spacing={12}>
        {/* details */}
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.name")}
            </Text>
            <Text marginBlock={2}>{branch?.BranchName || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.name_localized")}
            </Text>
            <Text marginBlock={2}>{branch?.BranchNameLocalize || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.branch_code")}
            </Text>
            <Text marginBlock={2}>{branch?.BranchCode || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.branch_tax_number")}
            </Text>
            <Text marginBlock={2}>{branch?.BranchTaxNumber || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.branch_tax_registeration_name")}
            </Text>
            <Text marginBlock={2}>{branch?.BranchTaxRegistName || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.commercial_registeration_no")}
            </Text>
            <Text marginBlock={2}>
              {branch?.CommercialRegistrationNo || "-"}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.lat")}
            </Text>
            <Text marginBlock={2}>{branch?.Latitude || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.long")}
            </Text>
            <Text marginBlock={2}>{branch?.Longitude || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.phone")}
            </Text>
            <Text marginBlock={2}>{branch?.Phone || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.receipt_footer")}
            </Text>
            <Text marginBlock={2}>{branch?.ReceiptFooter || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.receipt_header")}
            </Text>
            <Text marginBlock={2}>{branch?.ReceiptHeader || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.opening_from")}
            </Text>
            <Text marginBlock={2}>{branch?.OpeningFrom || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.opening_to")}
            </Text>
            <Text marginBlock={2}>{branch?.OpeningTo || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.inventory_end_of_day")}
            </Text>
            <Text marginBlock={2}>{branch?.InventoryEndofDay || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.branches.tax_group_id")}
            </Text>
            <Text marginBlock={2}>{branch?.TaxGroupName || "-"}</Text>
          </Box>
        </SimpleGrid>

        {/* sections */}
        <Sections />

        {/* tags */}
        <Tags />

        {/* delivery zones */}
        <DeliveryZones />

        {/* delete */}
        <Delete Id={branchId} />
      </Stack>

      {showUpdateBranchModal && (
        <UpdateModal
          data={showUpdateBranchModal}
          onClose={() => setShowUpdateBranchModal(null)}
        />
      )}
    </>
  ) : (
    <Flex
      bg="light"
      alignItems="center"
      justifyContent="center"
      p={4}
      textTransform="capitalize"
    >
      {t("pages.branches.no_data")}
    </Flex>
  );
};

export default Details;
