import { createSlice } from "@reduxjs/toolkit";
import {
  getDiscounts,
  createDiscount,
  updateDiscount,
  deleteDiscount,
  getQualificationsDropdown,
  resetFilteredData,
  getFilteredData,
  getDiscountById
} from "../services/discountsService";

let initialState = {
  isLoading: false,
  data: [],
  QualificationsDropdown: [],
  errors: null,
  filteredData: [],
  discountDetails: null,
};

const discountsSlice = createSlice({
  name: "discounts",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get Discounts List  -------------------------------------------------------------------*/
    builder.addCase(getDiscounts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDiscounts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getDiscounts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Get Qualifications Dropdown -------------------------------------------------------------------*/
    builder.addCase(getQualificationsDropdown.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getQualificationsDropdown.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.QualificationsDropdown =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(getQualificationsDropdown.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create Discount  -------------------------------------------------------------------*/
    builder.addCase(createDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createDiscount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    });
    builder.addCase(createDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Edit Discount  -------------------------------------------------------------------*/
    builder.addCase(updateDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(updateDiscount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(
        (discount) => discount.id === payload.id
      );
      state.data[indexAt] = payload;
      state.errors = null;
    });
    builder.addCase(updateDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(deleteDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteDiscount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = state.data.filter((discount) => discount.id !== payload.id);
      state.errors = null;
    });
    builder.addCase(deleteDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      console.log("youssef", state.filteredData);
      state.errors = null;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------------- Get  Discount By Id (Details) --------------------------------------- */
    builder.addCase(getDiscountById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDiscountById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.discountDetails =
        (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    });
    builder.addCase(getDiscountById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default discountsSlice.reducer;
