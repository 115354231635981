import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteDevice } from "../../services/devicesService";
import { useNavigate, useParams } from "react-router-dom";
import routes from "navigations/routes";

const DeleteModalDevice = ({
  data,
  onClose
}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const devices = useSelector(state => state.devices);
  const id = useParams()
  //console.log(id.id)
  const navigate = useNavigate();

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteDevice(id.id))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.devices.device_deleted") });
            onClose();
            navigate(`/${routes.management_devices}`);
          })
      }}
      // isLoading={devices.isLoading}
    />
  )
}

export default DeleteModalDevice