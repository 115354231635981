import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteUser, getUsers } from "../../services/usersService";

const DeleteModal = ({ data, onClose}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteUser({ id: data.userLoginId }))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.users.user_deleted") });
            dispatch(getUsers());
            onClose();
          })
      }}
      isLoading={users.isLoading}
    />
  )
}

export default DeleteModal