import { useToast } from '@chakra-ui/react';
import routes from 'navigations/routes';
import React from 'react'
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProduction } from '../../services/productionService';

const ProductionDeleteModal = ({ onClose, id }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToast({ position: "top", duration: 3000,  status: "success"});
  
    return (
      <GDeleteModal
        onClose={onClose}
        onSubmit={() => {
          dispatch(deleteProduction(id))
            .unwrap()
            .then((_) => {
              toast({ description: t("pages.production.production_deleted") });
              onClose();
              navigate(`/${routes.inventory_production}`);
            });
        }}
      />
    );
  };

export default ProductionDeleteModal