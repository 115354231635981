import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- create Discount Combos  -------------------------------------------------------------------*/
export const createDiscountCombos = createAsyncThunk(
  "discountsCombos/createDiscountCombos",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCount/AddDiscountCombo?comboid=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Get Combos Discounts List  -------------------------------------------------------------------*/
export const getDiscountsCombos = createAsyncThunk(
  "discountsCombos/getDiscountsCombos",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetDiscountCombo?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Delete Combo In Discount  -------------------------------------------------------------------*/
export const deleteComboInDiscount = createAsyncThunk(
  "discountsCombos/deleteComboInDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCountCombo/DisCountComboById?DisCountComboId={"DisCountComboId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* -------------------------------------------------- Get Tags In Combo ---------------------------------------------------*/
export const getTagsInCombo = createAsyncThunk(
  "discountsCombos/getTagsInCombo",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/LookupCombo?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
