import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { getAllProductsForOrder } from '../service/callCenterService';
import {
  Button,
  Flex,
  Text,
  SimpleGrid,
  Stack,
  Box,
  Badge,
  useToast,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { getProducts } from "features/products/products/services/productsService";
import { getAllProductsForOrderCall } from "../service/callCenterProductService";
import QuantatyModal from "../components/modal/QuantatyModal";
//import { getAllProductsForOrderCall } from '../service/callCenterProductService';
import { MdProductionQuantityLimits } from "react-icons/md";
import { MdPriceChange } from "react-icons/md";
import { IoFastFood } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { GetAllCatigoresWithProducts, modifayCallCenterState } from "../service/callCenterService";
import { Tooltip } from "@chakra-ui/react";
import { FaPhoneVolume } from "react-icons/fa6";
import "./styels/CallCenterProduct.css"
import { useNavigate, useParams } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import Receipt_NotesModal from "../components/modal/ReceiptnNotesModal";
import ReceiptnNotesModal from "../components/modal/ReceiptnNotesModal";
import { sendOrder } from "features/orders/services/ordersService";
import KitchennNoteModal from "../components/modal/KitchennNoteModal";
import routes from "navigations/routes";
import DiscountModal from "features/orders/components/modals/DiscountModal";
const CallCenterProduct = () => {
  
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsForOrderCall());
  }, [dispatch]);

  //const callCenterOrder=[]
  const callCenterOrder = useSelector((state) => state.callcenterProducts);
  const customer = useSelector((state) => state.callcenter);

  //console.log(customer.catigoryWithProducts);

  //console.log(customer.data)

  //console.log(callCenterOrder.allProducts);

  const customerDataDetails = customer.customerDetails;

  const [showModal, setshowmodal] = useState(false);

  const [id, setid] = useState();

  const clickedproduct = (id) => {
    setshowmodal(true);
    setid(id);
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const productsContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (productsContainerRef.current) {
      productsContainerRef.current.scrollTop =
        productsContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedProducts]);

  const handleAddClick = (productId, el) => {
    //console.log(el);
    // Check if the product is already in the selectedProducts array
    const existingProductIndex = selectedProducts.findIndex(
      (product) => product.ProductId === productId
    );

    if (existingProductIndex !== -1) {
      // If the product is already selected, update its quantity
      const updatedProducts = [...selectedProducts];
      updatedProducts[existingProductIndex].quantity += 1;
      setSelectedProducts(updatedProducts);
    } else {
      // If the product is not selected, add it to the array with quantity 1
      const selectedProduct = selectedCategory.find(
        (product) => product.ProductId === productId
      );
      setSelectedProducts([
        ...selectedProducts,
        { ...selectedProduct, quantity: 1 },
      ]);
    }
  };

  //console.log(selectedProducts)

  // Calculate total price
  const totalPrice = selectedProducts.reduce(
    (total, product) => total + product.ProductPrice * product.quantity,
    0
  );

  const handleRemoveClick = (productId) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.ProductId !== productId
    );
    setSelectedProducts(updatedProducts);
  };

  const handleIncreaseQuantity = (productId) => {
    const updatedProducts = selectedProducts.map((product) => {
      if (product.ProductId === productId) {
        return { ...product, quantity: product.quantity + 1 };
      }
      return product;
    });
    setSelectedProducts(updatedProducts);
  };

  const handleDecreaseQuantity = (productId) => {
    const updatedProducts = selectedProducts.map((product) => {
      if (product.ProductId === productId && product.quantity > 1) {
        return { ...product, quantity: product.quantity - 1 };
      }
      return product;
    });
    setSelectedProducts(updatedProducts);
  };

  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showProducts, setShowProducts] = useState(false);

  const handleCategoryClick = (el) => {
    setSelectedCategory(el.Product);
    setShowProducts(true);
  };

 // console.log(selectedProducts);

 const orderId=useParams()
 //console.log(orderId.orderid)

 const [ReceiptNotesModal,SetReceiptNotesModal]=useState(false)
 const [ReceiptNoteData,setReceiptNotesData]=useState("")

 const [KitchenNotesModal,SetKitchenNotesModal]=useState(false)
 const [KitchenNoteData,setKitchetNotesData]=useState("")

 const extractProperties = (inputArray) => {
  // Use the map function to create a new array based on the input array
  const newArray = inputArray.map(({ ProductId, quantity }) => ({
    ProductId,
    Quantity: quantity,
  }));

  return newArray;
};

const navigate=useNavigate()

 const sendOrderHandler=()=>{
  const newData = extractProperties(selectedProducts);
  // var IsPaid=true
  // if(OrderTypeData.OrderTypeId===1){
  //   IsPaid=false
  // }

  const dataSend=[
    {
      OrderId:orderId.orderid,
      ReceiptNotes: ReceiptNoteData,
      KitchenNotes: KitchenNoteData,
      TotalPrice: totalPrice,
      Subtotal: calculateTotalPrice(),
      DevicesId: 80,
      OrderStatusId: 1, 
      products: newData,
      
    }
]
if(
  dataSend[0].products.length == 0
){
  toast2({
    description: t("pages.DailyRestrictions.searchvalidation"),
  });
}else{
  //const newArray = updatePendingStatus(customer.data, orderId.orderid);
  //console.log(customer.data)
  //console.log(orderId.orderid)
  const newArray = updatePendingStatus(customer.data, orderId.orderid);
  //console.log(newArray)
  dispatch(sendOrder(dataSend))
  .unwrap()
  .then(_ => {
    dispatch(modifayCallCenterState(newArray))
    toast({ description: t("pages.customers.customer_created") });
    navigate(`/${routes.call_center}`);
  })
}
 }

 


 const updatePendingStatus = (arrayOfObjects, targetOrderId) => {
  // Create a new array to avoid mutating the original array
  const newArray = arrayOfObjects.map((obj) => {
    //console.log(obj.OrderId)
    //console.log(targetOrderId)
    // Check if the OrderId matches the targetOrderId
    if (obj.OrderId == targetOrderId) {
      // If it matches, update the pending property to false
      //console.log(obj)
      return { ...obj, pending: false };
    }
    // If it doesn't match, return the original object
    return obj;
  });

  // Return the new array
  return newArray;
};

// Example usage:
const originalArray = [
  {
    OrderId: 307,
    pending: true
  },
  {
    OrderId: 308,
    pending: true
  },
  {
    OrderId: 309,
    pending: true
  },

  // Add more objects as needed
];

 const idToUpdate = 307; // Specify the id you want to update



// console.log(newArray);








 const [cart, setCart] = useState([]);

  const addToCart = (product) => {
    // Check if the product is already in the cart
    const isProductInCart = cart.some((item) => item.ProductId === product.ProductId);

    if (!isProductInCart) {
      // Add the product to the cart
      setCart([...cart, { ...product, quantity: 1 }]);
    } else {
      // If the product is already in the cart, update its quantity
      setCart((prevCart) =>
        prevCart.map((item) =>
          item.ProductId === product.ProductId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    }
  };



  const removeFromCart = (productId) => {
    // Remove the product from the cart
    setCart((prevCart) => prevCart.filter((item) => item.ProductId !== productId));
  };


  const increaseQuantityCart = (productId) => {
    // Increase the quantity of the product in the cart
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.ProductId === productId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };


  const decreaseQuantitycart = (productId) => {
    // Decrease the quantity of the product in the cart
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.ProductId === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + item.ProductPrice * item.quantity, 0);
  };

  const toast3 = useToast({ position: "top", duration: 3000, status: "error" });

 //console.log(customerDataDetails)
 
 const [productIdForDiscount,SetproductIdForDiscount]=useState()
 const [discountModal,SetDiscountModal]=useState(false)
 const [applyDiscount,setapplyDiscount]=useState(false)
 const [discountAmount,setDiscountAmount]=useState(null)



 const handleButtonClick = (productId,discountAmount) => {
  // Find the product with the given productId
  const updatedProducts = selectedProducts.map((product) => {
    if (product.ProductId === productId) {
      // Update ProductPrice to 0
      if(discountAmount.type==="fixed"){
        //console.log(product.ProductPrice)
        const afterDiscountFixed=product.ProductPrice-discountAmount.amount
        if(afterDiscountFixed<0){
          toast3({
            description:t("pages.orders.discountError") ,
          });
        }else{
          return { ...product, ProductPrice: afterDiscountFixed };
        }
      }
      
      else if(discountAmount.type==="precentage"){
        const afterDiscountPrecentage=product.ProductPrice-(product.ProductPrice*(discountAmount.amount/100))
        if(afterDiscountPrecentage<0){
          toast3({
            description:t("pages.orders.discountError") ,
          });
        }else{
          return { ...product, ProductPrice: afterDiscountPrecentage };

        }
        //console.log(product.ProductPrice)
      }
      //return { ...product, ProductPrice: discountAmount.amount };
    }
    return product;
  });

  // Update the state with the modified array
  setSelectedProducts(updatedProducts);
};

 if(applyDiscount){
  //console.log("true apply")
  //console.log(productIdForDiscount)
  handleButtonClick(productIdForDiscount,discountAmount)
  setapplyDiscount(false)
  // setDiscountAmount(null)
  // productIdForDiscount=null

}else{
  
}

 const handelDiscount=(el)=>{
  //console.log(el.ProductId)
  SetproductIdForDiscount(el.ProductId)
  //console.log(selectedProducts)
  SetDiscountModal(true)

}



  return (
    <>
      <div className="mobileViewCallCenter">
        <div>
          <Box>
            <Badge
              display="flex"
              alignItems="center"
              bg="light"
              paddingBlock={2}
              mb={4}
              paddingInline={4}
              color="dark"
              fontSize="1rem"
              textTransform="capitalize"
            >
              {t("pages.call_center.New_Call_Center_Order")}
              <Badge ms={2}></Badge>
            </Badge>

            <Stack spacing={6}>
              <Box bg="light" paddingBlock={3} paddingInline={4}>
                <Text fontWeight="bold" mb={4}>
                  {customerDataDetails.OrderId}
                </Text>
                <Flex alignItems="baseline" marginTop={5}>
                  <FiIcons.FiUser />
                  <Stack ms={2} color="text">
                    <Text>{customerDataDetails?.CustomerName}</Text>
                  </Stack>
                </Flex>
                <Flex alignItems="baseline" marginTop={5}>
                  <FaPhoneVolume />
                  <Stack ms={2} color="text">
                    <Text>{customerDataDetails?.PhoneNo}</Text>
                  </Stack>
                </Flex>
                <Flex alignItems="baseline" marginTop={5}>
                  <FiIcons.FiClock />
                  <Stack ms={2} color="text">
                    <Text>{customerDataDetails?.UserDate}</Text>
                  </Stack>
                </Flex>
                <Flex alignItems="baseline" marginTop={5}>
                  <FiIcons.FiFile />
                  <Stack ms={2} color="text">
                    <Text>{customerDataDetails?.BranchName}</Text>
                  </Stack>
                </Flex>
                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  gap={15}
                  marginTop={5}
                >
                  <Flex alignItems="baseline">
                    <FiIcons.FiFile />
                    <Stack ms={2} color="text">
                      <Text>{t("pages.orders.receipt_notes")}</Text>
                    </Stack>
                  </Flex>
                  <Tooltip label={ReceiptNoteData}>
                    <Text
                      fontWeight="bold"
                      mb={4}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "10%",
                        whiteSpace: "nowrap",
                        width: "50px",
                      }}
                    >
                      {ReceiptNoteData || "-"}
                    </Text>
                  </Tooltip>

                  <Text
                    fontWeight="bold"
                    mb={4}
                    style={{ cursor: "pointer" }}
                    onClick={() => SetReceiptNotesModal(true)}
                  >
                    <FaRegEdit size={20} />
                  </Text>
                </Flex>
                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  gap={15}
                >
                  <Flex alignItems="baseline">
                    <FiIcons.FiFile />
                    <Stack ms={2} color="text">
                      <Text>{t("pages.orders.kitchen_note")}</Text>
                    </Stack>
                  </Flex>
                  <Tooltip label={KitchenNoteData}>
                    <Text
                      fontWeight="bold"
                      mb={4}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "10%",
                        whiteSpace: "nowrap",
                        width: "50px",
                      }}
                    >
                      {KitchenNoteData || "-"}
                    </Text>
                  </Tooltip>
                  <Text
                    fontWeight="bold"
                    mb={4}
                    style={{ cursor: "pointer" }}
                    onClick={() => SetKitchenNotesModal(true)}
                  >
                    <FaRegEdit size={20} />
                  </Text>
                </Flex>
              </Box>

              <div
                style={{ overflowY: "auto", maxHeight: "50vh" }}
                ref={productsContainerRef}
              >
                {selectedProducts.map((el, index) => {
                  return (
                    <Box
                      bg="light"
                      paddingBlock={3}
                      paddingInline={4}
                      key={el.ProductId}
                      style={{ marginBottom: "15px" }}
                    >
                      <Text fontWeight="bold" mb={4}></Text>
                      <Flex alignItems="baseline">
                        <IoFastFood />
                        <Stack ms={2} color="text">
                          <Text>{el.ProductName}</Text>
                        </Stack>
                      </Flex>

                      <Flex alignItems="baseline">
                        <MdPriceChange />
                        <Flex
                          ms={2}
                          color="text"
                          style={{
                            justifyContent: "space-between",
                            gap: "90px",
                            alignItems: "center",
                          }}
                        >
                          <Text>{el.ProductPrice}</Text>
                          <div
                            onClick={() => handelDiscount(el)}
                            style={{ cursor: "pointer", color: "green" }}
                          >
                            {t("pages.orders.discount")}
                          </div>
                        </Flex>
                      </Flex>

                      <Flex alignItems="baseline">
                        <MdProductionQuantityLimits />
                        <Stack ms={2} color="text">
                          <Text
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {t("pages.call_center.Quantity")}

                            <div
                              onClick={() => {
                                increaseQuantityCart(el.ProductId);
                                handleIncreaseQuantity(el.ProductId);
                              }}
                              style={{
                                margin: "15px",
                                backgroundColor: "green",
                                padding: "5px",
                                borderRadius: "8px",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              +
                            </div>
                            {el.quantity}

                            <div
                              onClick={() => {
                                decreaseQuantitycart(el.ProductId);
                                handleDecreaseQuantity(el.ProductId);
                              }}
                              style={{
                                margin: "15px",
                                backgroundColor: "red",
                                padding: "5px",
                                borderRadius: "8px",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              -
                            </div>
                          </Text>
                        </Stack>
                      </Flex>

                      <Button
                        type="button"
                        bg="primary"
                        color="light"
                        textTransform="capitalize"
                        fontSize="1rem"
                        style={{ marginTop: "15px" }}
                        onClick={() => {
                          removeFromCart(el.ProductId);
                          handleRemoveClick(el.ProductId);
                        }}
                      >
                        {t("pages.call_center.Remove")}
                      </Button>
                    </Box>
                  );
                })}
              </div>
              <Button
                type="button"
                bg="primary"
                color="light"
                textTransform="capitalize"
                fontSize="1rem"
                style={{ marginTop: "15px" }}
                onClick={sendOrderHandler}
              >
                {t("pages.call_center.Submit_Order")} : {totalPrice}
              </Button>
            </Stack>
          </Box>
        </div>
        <SimpleGrid
          columns={{ base: 2, md: 3, lg: 4, xl: 6 }}
          spacing={6}
          style={{ height: "20%" }}
        >
          {showProducts ? (
            <>
              <div
                style={{
                  backgroundColor: "gray",
                  maxHeight: "150px",
                  color: "white",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowProducts(false)}
              >
                {t("pages.hr.back")}
              </div>
              {selectedCategory?.map((el) => (
                <Box
                  bg="light"
                  paddingBlock={3}
                  paddingInline={4}
                  key={el.ProductId}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    minHeight: "150px",
                    borderRadius: "5%",
                  }}
                  //onClick={() => clickedproduct(el.ProductId)}
                >
                  <img
                    src={
                      `https://rostoapi.smartgate-egypt.com/` +
                      el.ProductImagePath
                    }
                    alt="Selected"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      marginTop: "10px",
                    }}
                  />
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text" style={{ width: "100px" }}>
                      <Tooltip label={el.ProductName}>
                        <Text
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "90%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {el.ProductName}
                        </Text>
                      </Tooltip>
                      <Text>{el.ProductPrice}</Text>
                    </Stack>
                  </Flex>
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{el.UserDate}</Text>
                    </Stack>
                  </Flex>
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{el.BranchName}</Text>
                    </Stack>
                    <Button
                      type="button"
                      bg="primary"
                      color="light"
                      textTransform="capitalize"
                      fontSize="1rem"
                      style={{ marginTop: "15px" }}
                      onClick={() => {
                        addToCart(el);
                        handleAddClick(el.ProductId, el);
                      }}
                    >
                      add
                    </Button>
                  </Flex>
                </Box>
              ))}
            </>
          ) : (
            <>
              {customer?.catigoryWithProducts?.map((el) => (
                <Box
                  bg="light"
                  paddingBlock={3}
                  paddingInline={4}
                  key={el.ProductId}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    minheight: "150px",
                    borderRadius: "5%",
                  }}
                  onClick={() => handleCategoryClick(el)}
                >
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <div>
                        <img
                          src={
                            `https://rostoapi.smartgate-egypt.com/` +
                            el.ProductCategoriesImagePath
                          }
                          alt="Selected"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "200px",
                            marginTop: "10px",
                          }}
                        />
                      </div>
                      <Text>{el.ProductCategoriesNameEng}</Text>
                    </Stack>
                  </Flex>
                </Box>
              ))}
            </>
          )}

          {showModal && (
            <QuantatyModal
              onClose={() => setshowmodal(false)}
              id={id}
            ></QuantatyModal>
          )}
        </SimpleGrid>
      </div>

      {ReceiptNotesModal && (
        <ReceiptnNotesModal
          onClose={() => SetReceiptNotesModal(false)}
          setReceiptNotesData={setReceiptNotesData}
        />
      )}

      {KitchenNotesModal && (
        <KitchennNoteModal
          onClose={() => SetKitchenNotesModal(false)}
          setKitchetNotesData={setKitchetNotesData}
        />
      )}

      {discountModal && (
        <DiscountModal
          onClose={() => SetDiscountModal(false)}
          setDiscountAmount={setDiscountAmount}
          setapplyDiscount={setapplyDiscount}
        />
      )}
    </>
  );
};

export default CallCenterProduct;
