import React from 'react';
import Chart from 'react-apexcharts';

const BranchChart = ({ date, data }) => {
  return (
    <Chart
      options={{
        chart: {
          toolbar: {
            show: false, // Hide the toolbar
          },
        },
        colors: ["black"],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: date,
        },
        plotOptions: {
          area: {
            distributed: true, // Spread areas evenly along the x-axis
          }
        },
      }}
      series={[
        {
          name: "Value",
          data: [0, 0, 0, 0, 0, 0, data], // Adjust data values accordingly
        },
      ]}
      type="area" // Change chart type to "area" for area chart
      width="100%"
      height="400px"
    />
  );
};

export default BranchChart;
