import React from 'react';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import DeleteWarehouseModal from "./Modal/DeleteWarehouseModal"

const DeletedWarehouse = ({id}) => {
    const { t } = useTranslation();
    const [showDeletedModal,setShowDeletedModal]=useState(false)

  return <> 
    
    <Flex flexDir={"column"} bg="light" gap={4} borderRadius={6}  p={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" mt={5} >
            <Text fontSize="1rem" color="text" fontWeight={"semibold"}>   {t("pages.warehouse.delete")}  </Text>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text fontSize=".9rem" color="text">   {t("pages.warehouse.if_deleted_warehouse")}  </Text>
                
                <GButton  
                bg="red"
                color="light"
                title={t("pages.warehouse.delete")}
                hover={{ bg: "red" }}
                className="deleteBtn"   
                onClick={() => setShowDeletedModal(true)}
                />
            </Flex>
    </Flex>

    {showDeletedModal && (
        <DeleteWarehouseModal
        onClose={() => setShowDeletedModal(false)}
        id={id}
        />
    )}
  </>
}

export default DeletedWarehouse;
