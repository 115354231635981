import { createSlice } from "@reduxjs/toolkit";
import {
  getbranches,
  assigenItemToBranch,
  getBranchesOfItem,
} from "../services/customLevelItemService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  branchesOfItem: [],
};

const customLevelItemSlice = createSlice({
  name: "customLevel",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getbranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getbranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      // console.log(state.data)
      state.errors = null;
    });

    builder.addCase(getbranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(assigenItemToBranch.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(assigenItemToBranch.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      // console.log(state.data)
      state.errors = null;
    });

    builder.addCase(assigenItemToBranch.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //
    builder.addCase(getBranchesOfItem.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getBranchesOfItem.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branchesOfItem =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.branchesOfItem)
      state.errors = null;
    });

    builder.addCase(getBranchesOfItem.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default customLevelItemSlice.reducer;
