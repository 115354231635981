import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import UpdateModal from "../components/modals/UpdateModal";
import GButton from "components/ui/button/Button";
import {
  activeDeActiveGiftsCards,
  getGiftsCardById,
} from "../services/giftsCardsService";
import DeleteGiftCards from "../components/DeleteGiftCard";
import InActiveBranche from "../components/InActiveBranche";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Details = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const { giftsCardId } = useParams();
  const dispatch = useDispatch();
  const giftsCards = useSelector((state) => state.giftsCards);
  const { t } = useTranslation();
  const id = useParams();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const giftsCard = giftsCards.giftsCard;

  const [showUpdateGiftsCardModal, setShowUpdateGiftsCardModal] =
    useState(null);
  // const [showActiveModal, setShowActiveModal] = useState(false);

  const activeFunction = () => {
    dispatch(activeDeActiveGiftsCards(id.giftsCardId))
      .unwrap()
      .then((_) => {
        toast({ description: t("Gift Card Activated") });
        dispatch(getGiftsCardById({ giftsCardId }));
        // navigate(`/${routes.products_gifts_cards}`);
      });
  };

  useEffect(() => {
    dispatch(getGiftsCardById({ giftsCardId }));
  }, [dispatch, giftsCardId]);

  if (giftsCards.isLoading) return <Spinner />;

  //console.log(giftsCard)



  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  if(showUpdateGiftsCardModal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowUpdateGiftsCardModal(false)
  }


  return giftsCard ? (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <Box as="p" fontSize="1.6rem" textTransform="capitalize">
              {giftsCard?.GiftCardsName || "-"}
            </Box>
            <Button
              // title=
              bg={giftsCard.IsActive ? "#00D1D1" : "red"}
              color="light"
              _hover={"none"}
              style={{ cursor: "default" }}
            >
              <Text>
                {giftsCard.IsActive
                  ? t("general.activated")
                  : t("general.inactive")}
              </Text>
            </Button>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <GButton
              title={t("general.edit")}
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() =>
                setShowUpdateGiftsCardModal({
                  ...giftsCard,
                  GiftsCardId: +giftsCardId || null,
                })
              }
            />
            <GButton
              title={
                giftsCard.IsActive ? t("general.deactive") : t("general.active")
              }
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() => activeFunction()}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        {/* details */}
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.gifts_cards.name")}
            </Text>
            <Text marginBlock={2}>{giftsCard?.GiftCardsName || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.gifts_cards.name_localized")}
            </Text>
            <Text marginBlock={2}>{giftsCard?.GiftCardsNameLoc || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.gifts_cards.sku")}
            </Text>
            <Text marginBlock={2}>{giftsCard?.GiftCardsSUK || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.gifts_cards.is_price_fixed")}
            </Text>
            <Text marginBlock={2}>
              {(giftsCard?.IsFixedPrice ? "yes" : "no") || "-"}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.gifts_cards.price")}
            </Text>
            <Text marginBlock={2}>{giftsCard?.Price || "-"}</Text>
          </Box>
        </SimpleGrid>

        <InActiveBranche></InActiveBranche>

        <DeleteGiftCards Id={id.giftsCardId}></DeleteGiftCards>
      </Stack>

      {showUpdateGiftsCardModal && (
        <UpdateModal
          data={showUpdateGiftsCardModal}
          onClose={() => setShowUpdateGiftsCardModal(null)}
          giftsCardId={id.giftsCardId}
        />
      )}

      {/* {showActiveModal && (
        <GiftCardsActiveModal
          id={{ giftsCardId }}
          onClose={() => setShowActiveModal(false)}
        />
      )} */}
    </>
  ) : (
    <Flex
      bg="light"
      alignItems="center"
      justifyContent="center"
      p={4}
      textTransform="capitalize"
    >
      {t("pages.gifts_cards.no_gifts_card")}
    </Flex>
  );
};

export default Details;
