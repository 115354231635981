import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
  Input as ChInput,
} from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { generator } from "utils/generator";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import {
  createDiscount,
  getDiscounts,
  getQualificationsDropdown,
} from "../../services/discountsService";
import { useEffect, useState } from "react";

export default function CreateModal({ onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    register,
    formState: { errors },
    trigger,
  } = useForm();
  watch(["Ref"]);

  const discounts = useSelector((state) => state.discounts);
  console.log("discounts", discounts);

  const [discountType, setDiscountType] = useState({});

  const discountsType = {
    discountsTypeDropdown: [
      {
        discountsTypeTypeId: 1,
        discountsTypeTypeName: "Fixed",
      },
      {
        discountsTypeTypeId: 2,
        discountsTypeTypeName: "Percentage",
      },
    ],
  };

  useEffect(() => {
    dispatch(getQualificationsDropdown());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.discounts.create_discount")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.QualificationId =
                  values.QualificationId.QualificationDiscountTypeId;
                values.UserId = localStorage.getItem("userId");
                if (
                  values.QualificationId === undefined ||
                  Object.keys(discountType).length === 0
                ) {
                  toast2({ description: t("validation.required") });
                } else {
                  dispatch(createDiscount(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.discounts.discount_created"),
                      });
                      dispatch(getDiscounts());
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.Name}>
                  <FormLabel
                    htmlFor="Name"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.discounts.name")}
                  </FormLabel>
                  <Controller
                    name="Name"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.discounts.name")}
                        onChange={(e) => {
                          handleInputChange(e, "Name");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Name && errors.Name.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.NameLoc}>
                  <FormLabel
                    htmlFor="NameLoc"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.discounts.name_localized")}
                  </FormLabel>
                  <Controller
                    name="NameLoc"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.discounts.name_localized")}
                        onChange={(e) => {
                          handleInputChange(e, "NameLoc");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.NameLoc && errors.NameLoc.message}
                  </FormErrorMessage>
                </FormControl>

                <MultiSelect
                  title={t("pages.discounts.qualifications")}
                  data={
                    discounts.QualificationsDropdown &&
                    discounts.QualificationsDropdown.map((el) => ({
                      ...el,
                      label: el.QualificationDiscountTypeName,
                      value: el.QualificationDiscountTypeId,
                    }))
                  }
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("QualificationId", e)}
                />

                <MultiSelect
                  title={t("pages.discounts.discount_type")}
                  data={
                    discountsType.discountsTypeDropdown &&
                    discountsType.discountsTypeDropdown.map((el) => ({
                      ...el,
                      label: el.discountsTypeTypeName,
                      value: el.discountsTypeTypeId,
                    }))
                  }
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => {
                    let isFixedValue;
                    if (e.discountsTypeTypeName === "Fixed") {
                      isFixedValue = true;
                    } else if (e.discountsTypeTypeName === "Percentage") {
                      isFixedValue = false;
                    }
                    setValue("IsFixed", isFixedValue);
                    // console.log("waryny al e kdh" , e);
                    setDiscountType(e);
                  }}
                />
                {discountType &&
                  discountType?.discountsTypeTypeName === "Fixed" && (
                    <FormControl isInvalid={errors.DiscountAmount}>
                      <FormLabel
                        htmlFor="DiscountAmount"
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("pages.discounts.discount_amount")}
                      </FormLabel>
                      <Controller
                        name="DiscountAmount"
                        control={control}
                        rules={{
                          required: t("validation.required"),
                          validate: (value) => {
                            const intValue = parseInt(value, 10);
                            return (
                              intValue >= 0 ||
                              t("validation.must_be_non_negative")
                            );
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={t("pages.discounts.discount_amount")}
                            onChange={(e) => {
                              handleInputChange(e, "DiscountAmount");
                            }}
                            type="number"
                            min="0"
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.DiscountAmount && errors.DiscountAmount.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}

                {discountType &&
                  discountType?.discountsTypeTypeName === "Percentage" && (
                    <>
                      <FormControl isInvalid={errors.DiscountAmount}>
                        <FormLabel
                          htmlFor="DiscountAmount"
                          style={{ textTransform: "capitalize" }}
                        >
                          {t("pages.discounts.discount_amount_percentage")}
                        </FormLabel>
                        <Controller
                          name="DiscountAmount"
                          control={control}
                          rules={{
                            required: t("validation.required"),
                            validate: (value) => {
                              const intValue = parseInt(value, 10);
                              return (
                                intValue >= 0 ||
                                t("validation.must_be_non_negative")
                              );
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t(
                                "pages.discounts.discount_amount_percentage"
                              )}
                              onChange={(e) => {
                                handleInputChange(e, "DiscountAmount");
                              }}
                              type="number"
                              min="0"
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors.DiscountAmount &&
                            errors.DiscountAmount.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={errors.MaxDiscount}>
                        <FormLabel
                          htmlFor="MaxDiscount"
                          style={{ textTransform: "capitalize" }}
                        >
                          {t("pages.discounts.maximum_discount")}
                        </FormLabel>
                        <Controller
                          name="MaxDiscount"
                          control={control}
                          rules={{
                            required: t("validation.required"),
                            validate: (value) => {
                              const intValue = parseInt(value, 10);
                              return (
                                intValue >= 0 ||
                                t("validation.must_be_non_negative")
                              );
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t(
                                "pages.discounts.maximum_discount"
                              )}
                              onChange={(e) => {
                                handleInputChange(e, "MaxDiscount");
                              }}
                              type="number"
                              min="0"
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors.MaxDiscount && errors.MaxDiscount.message}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  )}

                <Flex alignItems="flex-end" gap={4}>
                  <Input
                    label={t("pages.discounts.sku")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={t("pages.discounts.sku")}
                    value={getValues("Ref") || ""}
                    readOnly
                  />
                  <GButton
                    title={t("pages.discounts.generate")}
                    height="50px"
                    bg="light"
                    border="1px solid"
                    borderColor="border"
                    hover={{
                      bg: "primary",
                      borderColor: "primary",
                      color: "light",
                    }}
                    onClick={() => setValue("Ref", generator({}))}
                  />
                </Flex>

                <Checkbox {...register("IsTaxable")}>
                  {t("pages.discounts.IsTaxable")}
                </Checkbox>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    isLoading={discounts.isLoading}
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    isLoading={discounts.isLoading}
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
