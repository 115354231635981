import React, { useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr,} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Pagination from "components/ui/pagination/Pagination";

export default function DraftPurchasingDataTable({data}) {
    const { t } = useTranslation();    
    const navigate = useNavigate();
    const navigateTo = (IdNumber,IdType)=>{
      if(IdType === "Purchasing"){
            navigate(`/inventory/purchase_details/${IdNumber}`)
          }
      else {
          navigate(`/inventory/returnItems_toSupplier/${IdNumber}`)
        }
    };
/* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
        return data?.slice(firstIndex, lastIndex);
      };
    
    // Calculate records for activeDiscounts
    const draftPurchasingData = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
 /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <>
    <Table>
        <Thead>
            <Tr>
                <Th>#</Th>
                <Th>{t("pages.purchases.ref")}</Th>
                <Th>{t("pages.purchases.supplier")}</Th>
                <Th>{t("pages.purchases.destination")}</Th>
                <Th>{t("pages.purchases.type")}</Th>
                <Th>{t("pages.purchases.status")}</Th>
                <Th>{t("pages.purchases.business_date")}</Th>
                <Th>{t("pages.purchases.created")}</Th>
            </Tr>
        </Thead>
        <Tbody>
            {
            draftPurchasingData?.map((item, index) => (
                <Tr key={index} onClick={()=>navigateTo(item.Id , item.Type)} cursor={"pointer"}  >
                    <Td>{index + 1}</Td>
                    <Td>{item?.Ref || "-"}</Td>
                    <Td>{item?.SuppliersName || "-"}  </Td>
                    <Td>{item?.Destination || "-"} </Td>
                    <Td>{item?.Type || "-"} </Td>
                    <Td>{item?.Status || "-"} </Td>
                    <Td>{item?.BussinusDate || "-"} </Td>
                    <Td>{item?.Created || "-"} </Td>
                </Tr>
            ))
            }
        </Tbody>
    </Table>
    <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </>

  </>
};
