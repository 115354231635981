import { Box, Text } from "@chakra-ui/react";
import {
  Button,
  Flex,
  SimpleGrid,
  Input as ChInput,
  Stack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import AccountAddSearch from "features/general_accounts/AccountAdd/components/AccountAddSearch";
import AddAccountCycel from "../components/modal/AddAccountCycel";
import { convertDateFormat } from "utils/convertDate";
import { useDispatch } from "react-redux";
import { getAcoountCycleNew } from "../service/AccountAddService";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import { checkIdExists } from "utils/cheackAuth";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const AccountAddPage = () => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch();
  const data = [];
  const [showSearch, setShowSearch] = useState(false);
  const [showModal, setShowModal] = useState(false);

  /* ------------------------------------- Authrzation ------------------------------ */
  const retrievedArray = useDecryptedPrivilege();
  const ManageAccountant_AddAccounting = checkIdExists(retrievedArray, 1018);

  if (ManageAccountant_AddAccounting === false) {
    return <NotAuthorizedCompo />;
  }
  /* -------------------------------------------------------------------------------- */

  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.AccountAdd.Accounting_Courses")}
          </Text>

          <Button
            type="button"
            bg="primary"
            color="light"
            textTransform="capitalize"
            fontSize="1rem"
            onClick={() => setShowModal(true)}
          >
            <Flex alignItems="center" gap={1}>
              {t("pages.general_accounts.add")}
            </Flex>
          </Button>
        </Flex>
      </Box>

      <Box
        bg="light"
        marginTop={2}
        padding={5}
        borderTop="1px solid"
        borderColor="border"
        marginInline={-4}
        borderRadius={5}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          padding={5}
          borderBottom={"1px solid"}
          borderColor="border"
        >
          <Text fontSize="1.5rem" color="dark" textTransform="capitalize">
            {t("pages.AccountAdd.search")}
          </Text>
        </Flex>

        <Stack spacing={12}>
          <form
            onSubmit={handleSubmit((values) => {
              if (values.DateFrom && values.DateTo) {
                values.DateFrom = convertDateFormat(values.DateFrom);
                values.DateTo = convertDateFormat(values.DateTo);
                dispatch(getAcoountCycleNew(values));
                setShowSearch(true);
              } else {
                const dataSend = { DateFrom: "0" };
                dispatch(getAcoountCycleNew(dataSend));
                setShowSearch(true);
              }
            })}
          >
            <SimpleGrid
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  gap: "10px",
                }}
              >
                <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                  {t("pages.AccountAdd.date_From")}
                </label>

                <Controller
                  name="DateFrom"
                  control={control}
                  render={({ field }) => <ChInput {...field} type="date" />}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  gap: "10px",
                }}
              >
                <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                  {t("pages.AccountAdd.date_to")}
                </label>

                <Controller
                  name="DateTo"
                  control={control}
                  render={({ field }) => <ChInput {...field} type="date" />}
                />
              </div>
            </SimpleGrid>
            <Box bg="light" padding={5} marginInline={0}>
              <Button
                type="submit"
                bg="primary"
                color="light"
                textTransform="capitalize"
                fontSize="1rem"
                _hover={{ color: "primary", background: "light" }}
                border={"1px solid gray"}
              >
                <Flex alignItems="center" gap={1}>
                  {t("pages.AccountAdd.search")}
                </Flex>
              </Button>
            </Box>
          </form>
        </Stack>
      </Box>

      {showSearch && <AccountAddSearch />}

      {showModal && <AddAccountCycel onClose={() => setShowModal(false)} />}
    </>
  );
};

export default AccountAddPage;
