import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";

const ProductTagsTable = ({ tagsOfDiscount, handleDelete }) => {
  const { t } = useTranslation();

  console.log("tagsOfDiscount", tagsOfDiscount);

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.branches.name")}</Th>
            <Th>{t("general.action")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tagsOfDiscount.map((productTag, index) => (
            <Tr key={productTag.DisCountProductTagId}>
              <Td>{index + 1}</Td>
              <Td>{productTag?.Tag[0].TagName}</Td>
              <Td className="activeIcon">
                <GButton
                  postfixElement={<FiIcons.FiTrash2 />}
                  bg="red.600"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    bg: "none",
                    borderColor: "red.600",
                    color: "red.600",
                  }}
                  onClick={() => handleDelete(productTag)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ProductTagsTable;
