
import React, { useEffect, useState } from "react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import { Box, Flex, useToast } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TransferDetailsTable from "../components/TransferDetailsTable";
import TransferItems from "../components/TransferItems";
import EditTransfers from "../Modal/EditTransfers";
import SendTransfer from "../Modal/SendTransfer";
import { getTransfersList } from "../services/transfersServices";
import RecieveTransfers from "../Modal/RecieveTransfers";
import RecjectTransfer from "../Modal/RecjectTransfer";
import DeleteTransfers from "../Modal/DeleteTransfers";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 

export default function TransferDetails() {
    const id = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const transfersData = useSelector(state => state.inventoryTransfers);

    const [showEditTransfer, setShowEditTransfer] = useState(false);
    const [showSubmitTransfer, setShowSubmitTransfer] = useState(false);
    const [showReceiveModal, setShowReceiveModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [showDeleteTransfer, setShowDeleteTransfer] = useState(false);

    const transferDetails = transfersData?.data?.filter((item)=>{
        return item?.TransfersId  == id?.TransfersId
    });

    useEffect(() => {
      dispatch(getTransfersList())
    }, [dispatch])
    
    const handlePrint = () => {
        window.print();
      };
/* -----------------------------------Privilige-------------------------------*/
      const retrievedArray =useDecryptedPrivilege();
      const ManageTransfers = checkIdExists(retrievedArray, 20);
    
      if(showSubmitTransfer === true && ManageTransfers === false){
        toast2({ description:  t("general.authorization")});
        setShowSubmitTransfer(false);
      };
      if(showReceiveModal === true && ManageTransfers === false){
        toast2({ description:  t("general.authorization")});
        setShowReceiveModal(false);
      };
      if(showRejectModal === true && ManageTransfers === false){
        toast2({ description:  t("general.authorization")});
        setShowRejectModal(false);
      };
      if(showDeleteTransfer === true && ManageTransfers === false){
        toast2({ description:  t("general.authorization")});
        setShowDeleteTransfer(false);
      };
      if(showEditTransfer === true && ManageTransfers === false){
        toast2({ description:  t("general.authorization")});
        setShowEditTransfer(false);
      };
/* -----------------------------------Privilige-------------------------------*/  
return <>

      <BreadCrumb>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                flexWrap="wrap"
              >
                <Box as="p" fontSize="1.6rem" textTransform="capitalize">
                  {t("pages.transfers.transfers")}
                  <Box
                    bg={"#718096"}
                    display={"inline-block"}
                    marginX={"5px"}
                    borderRadius={"5px"}
                    color={"white"}
                    fontSize={"15px"}
                    padding={" 2px 10px"}
                  >
                    {transferDetails[0]?.Status}
                  </Box>
                </Box>
                <Flex gap={2}>
                  {/* Print Button */}
                  <GButton
                    prefixElement={<FiIcons.FiPrinter size={16} />}
                    title={t("pages.transfers.print")}
                    bg="light"
                    color="text"
                    border="1px"
                    borderColor={"#CBD5E0"}
                    opacity="0.7"
                    onClick={() => handlePrint()}
                  /> 
                                        {/* Edit Button */}
                    {transferDetails[0]?.Status === "Closed" ? 
                      ""
                      :
                      <>
                            <GButton
                              prefixElement={<FiIcons.FiEdit2 size={16} />}
                              title={t("pages.transfers.edit")}
                              bg="light"
                              color="text"
                              border="1px"
                              borderColor={"#CBD5E0"}
                              opacity="0.7"
                              onClick={() => setShowEditTransfer(true)}
                            />

                        </>
                    }
                  {transferDetails[0]?.Status === "Draft" ? (
                    <>
                      <GButton
                        prefixElement={<FiIcons.FiTrash2 size={16} />}
                        title={t("pages.transfers.delete_permenantly")}
                        bg="light"
                        color="text"
                        border="1px"
                        borderColor={"#CBD5E0"}
                        opacity="0.7"
                        onClick={() => setShowDeleteTransfer(true)}
                      />

                      <GButton
                        title={t("pages.transfers.send_items")}
                        bg="#54A0FF"
                        color="light"
                        hover={{ bg: "primary" }}
                        onClick={() => setShowSubmitTransfer(true)}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {transferDetails[0]?.Status === "Pending" ? (
                    <>
                      <GButton
                        prefixElement={<IoMdCloseCircleOutline size={16} />}
                        title={t("pages.transfers.reject")}
                        bg="light"
                        color="text"
                        border="1px"
                        borderColor={"#CBD5E0"}
                        opacity="0.7"
                        onClick={() => setShowRejectModal(true)}
                      />
                      <GButton
                        title={t("pages.transfers.receive_items")}
                        bg="#54A0FF"
                        color="light"
                        hover={{ bg: "primary" }}
                        onClick={() => setShowReceiveModal(true)}
                      />

                    </>
                  ) : (
                    ""
                  )}
                </Flex>
              </Flex>
      </BreadCrumb>

      <TransferDetailsTable  data={transferDetails} />

      <TransferItems id={id} status={transferDetails[0]?.Status}  />

        {/*Show Edit Item Transfer Modal*/}
        {showEditTransfer && (
          <EditTransfers
            onClose={() => setShowEditTransfer(false)}
            id={id?.TransfersId}
          />
        )}

        {/*Show Send Transfere*/}
        {showSubmitTransfer && (
          <SendTransfer
            onClose={() => setShowSubmitTransfer(false)}
            id={id}
          ></SendTransfer>
        )}

        
        {/*Show Recieve Transfere*/}
        {showReceiveModal && (
          <RecieveTransfers
            onClose={() => setShowReceiveModal(false)}
            id={id}
          ></RecieveTransfers>
        )}

        {/*Show Reject Transfere*/}
        {showRejectModal && (
          <RecjectTransfer
            onClose={() => setShowRejectModal(false)}
            id={id}
          ></RecjectTransfer>
        )}

        {/*Show Deleted Cost Adjustment Permenantly*/}
        {showDeleteTransfer && (
          <DeleteTransfers
            onClose={() => setShowDeleteTransfer(false)}
            id={id}
          ></DeleteTransfers>
        )}
  
  </>
}



