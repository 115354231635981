import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getCostTypes = createAsyncThunk(
  "products/getCostTypes",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/ProductCostType/LookupProductCostType");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);