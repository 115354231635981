import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  Text,
  useToast,
  Icon,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { FaUserXmark } from "react-icons/fa6";
import { GetAbsenceDays } from "features/hr/employees/services/employeeServices";

export default function AbsenceModal({ onClose, id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const { handleSubmit, setValue } = useForm();

  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const year = today.getFullYear();
  const printCurrentdays = `${day}/${month}/${year} 00:00:00`;

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={
          <Flex cursor={"pointer"} alignItems={"center"} gap={3}>
            <Icon
              as={FaUserXmark}
              w={"40px"}
              h={"40px"}
              color="red.500"
              padding={"5px"}
            />
            {t("pages.hr.employee_absence")}
          </Flex>
        }
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                if (
                  values.FromDate === undefined ||
                  values.ToDate === undefined
                ) {
                  toast2({
                    description: t("pages.hr.hr_validation.select_date_first"),
                  });
                } else {
                  const [day, month, year] = values?.FromDate?.split("-");
                  const [dayTo, monthTo, yearTo] = values?.ToDate?.split("-");

                  values.EmployeeId = id;
                  values.FromDate = `${year}/${month}/${day} 00:00:00`;
                  values.ToDate = `${yearTo}/${monthTo}/${dayTo} 00:00:00`;
                  if (values?.FromDate >= printCurrentdays) {
                    toast2({
                      description: t(
                        "pages.hr.hr_validation.select_another_date"
                      ),
                    });
                  } else {
                    dispatch(GetAbsenceDays(values))
                      .unwrap()
                      .then((_) => {
                        onClose();
                      });
                  }
                }
              })}
            >
              <Stack spacing={6}>
                <Box>
                  <Text fontWeight={"bold"} textTransform={"capitalize"}>
                    {t("general.from")}
                  </Text>
                  <Input
                    size="md"
                    type="date"
                    marginTop={"2"}
                    onChange={(e) => setValue("FromDate", e.target.value)}
                  />
                </Box>
                <Box>
                  <Text fontWeight={"bold"} textTransform={"capitalize"}>
                    {t("general.to")}
                  </Text>
                  <Input
                    size="md"
                    type="date"
                    marginTop={"2"}
                    onChange={(e) => setValue("ToDate", e.target.value)}
                  />
                </Box>
                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="blue.600"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "blue.600",
                      borderColor: "blue.600",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
