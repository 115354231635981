import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text, Spinner } from "@chakra-ui/react";
import {
  getInventoryControlFilteredData,
  getInventoryControlList,
} from "./services/inventoryControlServices";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import InventoryControlTable from "./components/InventoryControlTable";
import ExportModal from "components/ui/modal/ExportModal";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


export default function InventoryControl() {

  const retrievedArray =useDecryptedPrivilege()
  const Auth = checkIdExists(retrievedArray, 64);


  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data, isLoading, filteredData } = useSelector(
    (state) => state.reportsInventoryControl
  );

  const [exportModal, SetExportModal] = useState(false);

  useEffect(() => {
    // Dispatch getInventoryControlList action first
    dispatch(getInventoryControlList())
      .then(() => {
        // Once getReportTransfersFilteredData is completed, dispatch getPurchasingFilteredData
        dispatch(getInventoryControlFilteredData());
      })
      .catch((error) => {
        throw Error("Error fetching Transfers Orders report data:", error);
      });
  }, [dispatch]);


  
if(Auth===false ){
  return(
    <NotAuthorizedCompo/>
  )
}


  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.reports.inventory_reports.inventory_control")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
              {t("general.export")}
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>

      <>
        {isLoading ? (
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <InventoryControlTable data={data} filteredData={filteredData} />
        )}
      </>

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={data}
          fileName={"InventoryControl"}
        ></ExportModal>
      )}
    </>
  );
}
