import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import AddSizeModal from "./modals/AddSizeModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCombosSize, getGroupofOptions, getGroupofSize, getProductCompoSize } from "../services/comboSizeService";
import ComboSizeTable from "./ComboSizeTable";
import GroupTable from "./GroupTable";
import OptionsTable from "./OptionsTable";
import NewCompoSizeTable from "./NewCompoSizeTable";
import { getproducts } from "../services/combosService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


const CompoSize = () => {

  
  
  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);



  const { t } = useTranslation();

  const [Showmodal, setShowModal] = useState(false);

  const id = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCombosSize(id.comboId));
  }, [dispatch, id]);

  useEffect(()=>{
    dispatch(getGroupofSize(id.comboId))
  },[dispatch,id])

  useEffect(()=>{
    dispatch(getGroupofOptions(id.comboId))
  },[dispatch,id])

  const comboSize = useSelector((state) => state.comboSize);

  //console.log(comboSize.options)

  const [catchId, setCatchId] = useState(null);

  // 

    useEffect(()=>{
    dispatch(getProductCompoSize())
  },[dispatch])

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(Showmodal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowModal(false)
  }

  return (
    <>
      <Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          flexWrap="wrap"
          mb={6}
        >
          <Text color="text" textTransform="capitalize">
            {t("pages.combos.products")}
          </Text>
          <GButton
            title={t("pages.combos.add_size")}
            bg="light"
            color="text"
            fontSize="14px"
            border="1px solid"
            borderColor="border"
            hover={{ bg: "light" }}
            onClick={() => setShowModal(true)}
          />
        </Flex>

        {comboSize.isLoading ? (
          <Spinner />
        ) : comboSize.data?.length === 0 ? (
          <Flex
            p={4}
            bg="white"
            alignItems="center"
            justifyContent="center"
            color="text"
            textTransform="capitalize"
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            borderRadius={6}
          >
            {t("pages.branches.no_data")}
          </Flex>
        ) : comboSize.data?.length > 0 ? (
          <NewCompoSizeTable data={comboSize.data} groups={comboSize.groupofSize} products={comboSize.Products}
          options={comboSize.options}
          />
        ) : (
          <Flex
            p={4}
            bg="white"
            alignItems="center"
            justifyContent="center"
            color="text"
            textTransform="capitalize"
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            borderRadius={6}
          >
            {t("pages.branches.no_data")}
          </Flex>
        )}

        

       
      </Box>
     {Showmodal && <AddSizeModal onClose={() => setShowModal(false)} />}
      {/* 
      {comboSize.groupofSize.length > 0 ? (
        <GroupTable catchId={catchId}></GroupTable>
      ) : null}

      {comboSize.options.length > 0 ? <OptionsTable></OptionsTable> : null}
    */}
      </>
  );
};

export default CompoSize;
