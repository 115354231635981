import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import { useTranslation } from 'react-i18next';
import InactiveBrancheModal from './modals/InactiveBrancheModal';
import { useDispatch } from 'react-redux';
import { getInactiveBranhesById } from '../services/combosService';
import { useParams } from 'react-router-dom';
import InActiveBranchesTable from './InActiveBranchesTable';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';


const DeactivateComboInSome = ({combos}) => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  

 

  //console.log(combos)

    const { t } = useTranslation();

    const [Showmodal,setShowModal]=useState(false)

    const dispatch= useDispatch()

   const id = useParams()
   //console.log(id.comboId)

   
 
   
  
  
   
  //   {comboSize.isLoading ? (
  //     <Spinner />
  //   ) : comboSize.data?.length === 0 ? (
  //     <Flex
  //       p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
  //       boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  //     >
  //       {t("pages.branches.no_data")}
  //     </Flex>
  //   ) : comboSize.data?.length > 0 ? (
  //     <ComboSizeTable
  //     data={comboSize.data}
  //     />
  //   ):(
  //     <Flex
  //     p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
  //     boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  //   >
  //     {t("pages.branches.no_data")}
  //   </Flex>
  //   )
  // }

    
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(Showmodal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowModal(false)
  }

  return (
    <div>
    
    <Box>
    <Flex
    alignItems="center" justifyContent="space-between"
    gap={2} flexWrap="wrap" mb={6}
  >
    <Text color="text" textTransform="capitalize">
      {t("pages.combos.inactive_in_branches")}
    </Text>
    <GButton
      title={t("pages.combos.select_branches")}
      bg="light"
      color="text"
      fontSize="14px"
      border="1px solid"
      borderColor="border"
      hover={{ bg: "light" }}
      onClick={() => setShowModal( true)}
    />
  </Flex>



    {Showmodal&&(
        <InactiveBrancheModal onClose={() => setShowModal(false)}/>
      )}



        {combos.isLoading ? (
      <Spinner />
    ) : combos.inActiveBranches?.length === 0 ? (
      <Flex
        p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
      >
        {t("pages.branches.no_data")}
      </Flex>
    ) : combos.inActiveBranches?.length > 0 ? (
      <InActiveBranchesTable
      data={combos.inActiveBranches}
      />
    ):(
      <Flex
      p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
    >
      {t("pages.branches.no_data")}
    </Flex>
    )
  }






    </Box>


    </div>
  );
}

export default DeactivateComboInSome;
