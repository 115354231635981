import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getbranches = createAsyncThunk("customLevel/getbranches", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const assigenItemToBranch = createAsyncThunk(
    "customLevel/assigenItemToBranch",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Items/AddItemsBranches?itemsbranches=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  
  export const getBranchesOfItem = createAsyncThunk(
    "customLevel/getBranchesOfItem",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/Items/BranchesByItemId?ItemId={"InventoryItemId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
