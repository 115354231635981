import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


/* ----------------------------------------------------------------- Get Warehouse List  -------------------------------------------------------*/
export const getWarehouseList = createAsyncThunk("warehouse/getWarehouseList",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/GetWarehousesList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Get Inventory End of Day List  -------------------------------------------------------*/
export const getInventoryDayTimeList = createAsyncThunk("warehouse/getInventoryDayTimeList",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage/Branches/LookUpDayTime`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Crate New Warehouse  -------------------------------------------------------*/
export const createNewWarehouse = createAsyncThunk("warehouse/createNewWarehouse",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/AddWarehouse?warehouse=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Update Warehouse  -------------------------------------------------------*/
export const updateWarehouse = createAsyncThunk("warehouse/updateWarehouse",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/UpdateWarehouseById?warehouseid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Delete Warehouse  -------------------------------------------------------*/
export const deleteWarehouse = createAsyncThunk("warehouse/deleteWarehouse",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/DeleteWarehouseById?warehouseid={"WarehouseId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getWarehouseFilteredData = createAsyncThunk("warehouse/getWarehouseFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetWarehouseFilteredData = createAsyncThunk( "warehouse/resetPurchasingFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);