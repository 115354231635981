import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import AddItemCostAdjustment from "./modals/AddItemCostAdjustment";
import EditItemCostAdjustment from "./modals/EditItemCostAdjustment";
import { getCostAdjustmentItemsList } from "../services/costAdjustmentItem";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

export default function CostAdjustmentItems({id}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const costAdjustMentData = useSelector(state => state.costAdjustment);

    const [showIAddItem, setShowIAddItem] = useState(false);
    const [showIEditCostItem, setShowIEditCostItem] = useState(false);

    useEffect(() => {
      dispatch(getCostAdjustmentItemsList(id));
    }, [dispatch , id]);


/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageCostAdjustments = checkIdExists(retrievedArray, 30);

if(showIAddItem===true && ManageCostAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowIAddItem(false);
};
if(showIEditCostItem===true && ManageCostAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowIEditCostItem(false);
};
/* -----------------------------------Privilige-------------------------------*/

return <>
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
            <Text color="text" textTransform="capitalize">
              {t("general.items")}
            </Text>

            {
              costAdjustMentData?.costAdjustmentDetails[0]?.CostAdjustmentStatus !== "Closed" ? 
            <Flex gap={4}>
              {
                costAdjustMentData?.costAdjusmentItemsList.length > 0 ? 
                    <GButton
                      title={t("pages.cost_adjustment.edit_cost")}
                      bg="light"
                      color="text"
                      fontSize="14px"
                      border="1px"
                      borderColor={"#CBD5E0"}
                      hover={{ bg: "light" }}
                      opacity="0.7"
                      onClick={() => setShowIEditCostItem(true)}
                    />
                : 
                ""
              }
              <GButton
                title={t("pages.purchase_orders.add_items")}
                bg="light"
                color="text"
                fontSize="14px"
                border="1px"
                borderColor={"#CBD5E0"}
                hover={{ bg: "light" }}
                opacity="0.7"
                onClick={() => setShowIAddItem(true)}
              />
              {/* <GButton
                title={t("pages.purchase_orders.import_items")}
                bg="light"
                color="text"
                fontSize="14px"
                border="1px"
                borderColor={"#CBD5E0"}
                hover={{ bg: "light" }}
                opacity="0.7"
                // onClick={() => setShowImportItemToPurchaseOrderModal(true)}
              /> */}
            </Flex>
              : 
              ""
            }

      </Flex>

      {costAdjustMentData.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : costAdjustMentData?.costAdjusmentItemsList?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : costAdjustMentData?.costAdjusmentItemsList?.length > 0 ? (
        <Box
          bg="light"
          overflow="auto"
          borderRadius={4}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Table>
            <Thead>
              <Tr>
                <Th>{t("pages.cost_adjustment.name")}</Th>
                <Th>{t("pages.cost_adjustment.suk")}</Th>
                <Th>{t("pages.cost_adjustment.storage_unit")}</Th>
                <Th>{t("pages.cost_adjustment.new_cost_perunit")}</Th>
                {/* <Th> <Icon boxSize={4}  as={DeleteIcon} /> </Th> */}
                
              </Tr>
            </Thead>
            <Tbody>
              {costAdjustMentData?.costAdjusmentItemsList.map((el, index) => (
                <Tr key={index}>
                  <Td>{el?.InventoryItemNameEng}</Td>
                  <Td>{el?.SUK}</Td>
                  <Td>{el?.StorageUnit}</Td>
                  <Td>{el?.NewCostPerUnit}</Td>
                  {/* <Td><Icon color='red.500' boxSize={4}  as={DeleteIcon} /> </Td> */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

    </Box>


      {/*Show Edit Quantity Purchase Order Modal*/}
      {showIEditCostItem && ( 
        <EditItemCostAdjustment  
          onClose={() => setShowIEditCostItem(false)} 
          data={costAdjustMentData.costAdjusmentItemsList} 
          id={id}
        >
        </EditItemCostAdjustment>
      )}

      {/*Show EditPurchase Order Modal*/}
      {showIAddItem && (
        <AddItemCostAdjustment
          onClose={() => setShowIAddItem(false)}
          data={costAdjustMentData.costAdjustmentItems}
          id={id}
        ></AddItemCostAdjustment>
      )}
  </>
}
