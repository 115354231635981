import axios from "axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");

// /* -------------------  Get Eployee DeropDown List ---------------------*/
export const getEmployeeDropDown = createAsyncThunk(
  "selfservices/getEmployeeDropDown",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/lookupGetEmpForDep?data={"UserId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

// /* -------------------  get RequestType DropDown---------------------*/
export const getRequestTypeDropDown = createAsyncThunk(
  "selfservices/getRequestTypeDropDown",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/lookupGetAvailableLeave?data={"EmployeeId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

// /* -------------------  Get Vacation details By Change Employee Id or Vacation Id  ---------------------*/
export const getVactionChangeById = createAsyncThunk(
  "selfservices/getVactionChangeById",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetLeaveValidator?data=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Add New LeaveRequest ---------------------*/
export const addNewLeaveRequest = createAsyncThunk(
  "selfservices/addNewLeaveRequest",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/VacationRequest/AddVacationRequest?vacationrequest=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* ------------------------------------------------------  Bank Letter --------------------------------------------------------*/
// /* -------------------  Get Bank Letter ---------------------*/

export const getBankLetterData = createAsyncThunk(
  "selfservices/getBankLetterData",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetHrLetterBankList?data={"UserId":${args},"IsBank":1}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Get Bank LookUp ---------------------*/
export const getBankDropDown = createAsyncThunk(
  "selfservices/getBankDropDown",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupBank`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Create New Bank Letter ---------------------*/
export const createNewBankLetter = createAsyncThunk(
  "selfservices/createNewBankLetter",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/HrLetterRequest/AddHrLetterRequest?hrletterrequest=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  View Bank Letter By Id---------------------*/
export const viewBankLetterById = createAsyncThunk(
  "selfservices/viewBankLetterById",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetHrLetterBankById?data=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

/* ----------------------------------------------------------------------------------------------------------------------- */

// /* -------------------  Get Hr Letter List ---------------------*/

export const getHrLetterData = createAsyncThunk(
  "selfservices/getHrLetterData",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetHrLetterBankList?data={"UserId":${args},"IsBank":0}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

// /* -------------------  Create New Bank Letter ---------------------*/
export const createNewHrLetter = createAsyncThunk(
  "selfservices/createNewHrLetter",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/HrLetterRequest/AddHrLetterRequest?hrletterrequest=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

// /* -------------------  View Hr Letter By Id---------------------*/
export const viewHrLetterById = createAsyncThunk(
  "selfservices/viewHrLetterById",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetHrLetterBankById?data=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
