import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text, Spinner , Box} from "@chakra-ui/react";
import Table from "../components/Table";
import GButton from "components/ui/button/Button";
import * as FiIcons from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { getUsers, resetUsersFilteredData } from "../services/usersService";
import NoData from "components/ui/noData/NoData";
import ExportModal from 'components/ui/modal/ExportModal';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import FilterDataTable from "../components/FilterDataTable";
import ActiveUserDataTable from "../components/ActiveUserDataTable";
import InActiveUserDataTable from "../components/InActiveUserDataTable";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Users = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 48);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showCreateUserModal, setShowCreateUserModal] = useState(null);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(null);
  const [exportModal,SetExportModal]=useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const users = useSelector(state => state.users);

    // Show Filter Modal State
  
  const resetFunction = () => {
      dispatch(resetUsersFilteredData());
    };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const [selectedTable, setSelectedTable] = useState("active");
  //console.log(selectedTable)

  
  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  };


  const activeData = users?.data.filter((item)=>{
    return item.isActive === true ;
  });

  const inActiveData = users?.data.filter((item)=>{
    return item.isActive === false ;
  });

  const activeDatafilteredData = users?.filteredData.filter((item)=>{
    return item.isActive === true ;
  });

  const inActiveDatafilteredData = users?.filteredData.filter((item)=>{
    return item.isActive === false ;
  });

  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.users.users")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
                type="button" bg="none" border="1px solid" borderColor="border"
                textTransform="capitalize" fontSize="1rem" color="text"
                _hover={{ bg: "primary", color: "light" }}
                onClick={() => SetExportModal(true)}
              >
                  <Text>{t("general.export")}</Text>
            </Button>

            <GButton
              title={t("pages.users.create_user")}
              onClick={() => setShowCreateUserModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
      >
        <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
        >
        <GButton
        title={t("pages.gifts_cards.active")}
        bg="none"
        color={selectedTable === "active" ? "primary" : "text"}
        isDisabled={false}
        borderRadius={0}
        borderBottom={selectedTable === "active" ? "2px solid" : "none"}
        borderColor="primary"
        onClick={() => setSelectedTable("active")}
      />
      <GButton
        title={t("pages.gifts_cards.in_active")}
        bg="none"
        color={selectedTable === "inActive" ? "primary" : "text"}
        isDisabled={false}
        borderRadius={0}
        borderBottom={selectedTable === "inActive" ? "2px solid" : "none"}
        borderColor="primary"
        onClick={() => setSelectedTable("inActive")}
      />

        </Flex>

            <GButton
                title={
                    users.filteredData?.length === 0
                    ? t("general.filter")
                    : t("general.clear_filter")
                }
                border="1px solid"
                borderColor="border"
                postfixElement={
                    users.filteredData?.length === 0 ? (
                    <FiIcons.FiFilter size={16} />
                    ) : (
                    <IoCloseOutline fontWeight={800} size={20} />
                    )
                }
                hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                onClick={
                    users.filteredData?.length === 0
                    ? () => setShowFilterModal(true)
                    : resetFunction
                }
            />

      </Flex>
      <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >

    
      { 
      users.isLoading ?
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
      :
        <>
        {                  
          users?.filteredData?.length > 0 ? 
          <FilterDataTable data={ selectedTable === "active" ? activeDatafilteredData : inActiveDatafilteredData } />
          :
          users?.data?.length > 0 ? 
          <>
            {
              selectedTable === "active" ?
              <ActiveUserDataTable 
                  data={activeData}
                  handleUpdate={(user) => setShowUpdateUserModal(user)}
                  handleDelete={(user) => setShowDeleteUserModal(user)}
              />
              :
              <InActiveUserDataTable 
                data={inActiveData}
                handleUpdate={(user) => setShowUpdateUserModal(user)}
                handleDelete={(user) => setShowDeleteUserModal(user)}
              />
            }
          </>
          :
          <NoData
            title={t("pages.users.no_data_hint")}
            btnTitle={t("pages.users.create_user")}
            onClickCreateBtn={() => setShowCreateUserModal(true)}
          />
        }
        </>
      }
    </Box>

      {showCreateUserModal && (
        <CreateModal
          onClose={() => setShowCreateUserModal(null)}
        />
      )}

      {showUpdateUserModal && (
        <UpdateModal
          data={showUpdateUserModal}
          onClose={() => setShowUpdateUserModal(null)}
        />
      )}

      {showDeleteUserModal && (
        <DeleteModal
          data={showDeleteUserModal}
          onClose={() => setShowDeleteUserModal(null)}
        />
      )}
        {exportModal&&(
          <ExportModal 
            onClose={() => SetExportModal(false)}   
            data={users?.data}
            fileName={"users"}
            >
          </ExportModal>
          )}

      {showFilterModal && (
            <FilterModal 
                onClose={()=>setShowFilterModal(false)} 
                data = {users?.data}
            />
        )}
    </>
  )
}

export default Users;