import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductCat } from '../../services/devicesTypesService';
import { getBranches } from 'features/management/branches/services/branchesService';
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button";
import GModal from "components/ui/modal/Modal";
import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react";
import Input from "components/form/Input";
import { Controller, useForm } from 'react-hook-form';
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getDeviceDetails, updateDevice } from '../../services/devicesService';
import { useParams } from 'react-router-dom';
import { generator } from 'utils/generator';


const UpdateModal = ({ onClose,data }) => {
  const id = useParams()
  const { t } = useTranslation();
  const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({position: "top",duration: 3000, status: "error",});
  const { handleSubmit, control, getValues, setValue, register,watch,formState: { errors }, trigger } =useForm({  defaultValues: {    ...data[0],  },});


  const productCat = useSelector(state => state.devicesTypes);
  const branches = useSelector(state => state.branches);

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);


  useEffect(() => {
    dispatch(getProductCat());
  }, [dispatch]);


  watch(["DevicesRef"]);


  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };


  return (
    <GModal
    
    onClose={onClose}
    modalHeader={t("pages.devices.update_device")}
    modalContent={

      <Box p={4}>
      
      <form onSubmit={handleSubmit(values=>{
            if(values.BranchId===undefined)
            {
              toast2({ description: t("pages.DailyRestrictions.searchvalidation")  });
            }
            else{
              values.BranchId=values.BranchId.BranchId
            values.DevicesId=id.id ;
                  dispatch(updateDevice(values))
                  .unwrap()
                  .then(_ => {
                    toast({ description: t("pages.devices.device_updated") });
                    dispatch(getDeviceDetails(id.id))
                    onClose()
                  });
                  //console.log(values)
            } 
    
      })}>
      
      <Stack spacing={6}>
      
   



            <FormControl isInvalid={errors.DevicesName}>
            <FormLabel htmlFor="DevicesName" style={{textTransform:"capitalize"}}>
            {t("pages.devices.name")}
            </FormLabel>
            <Controller
              name="DevicesName"
              control={control}
              defaultValue=""
              rules={{
                required: t("validation.required"),
                minLength: {
                  value: 2,
                  message: t("validation.name_long_2"),
                },
                maxLength: {
                  value: 25,
                  message: t("validation.name_long_25"),
                },
                pattern: {
                  value: /^[\p{L}\s]+$/u,
                  message:t("validation.this_name_invalid") ,
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t("pages.devices.name")}
                  onChange={(e)=>handleInputChange(e, "DevicesName")} 
                />
              )}
            />
            <FormErrorMessage>{errors.DevicesName && errors.DevicesName.message}</FormErrorMessage>
          </FormControl>

          <Flex alignItems="flex-end" gap={4}>
          <Input
            label={t("pages.branches.branch_code")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.branches.branch_code")}
            isRequired
            value={getValues("DevicesRef") || ""}
            readOnly
          />
          <GButton
            title={t("pages.branches.generate")}
            height="50px"
            bg="light"
            border="1px solid"
            borderColor="border"
            hover={{
              bg: "primary",
              borderColor: "primary",
              color: "light",
            }}
            onClick={() => setValue("DevicesRef", generator({}))}
          />
        </Flex>


  
  <MultiSelect
  title={t("pages.devices.branch")}
  data={
    branches.data &&
    branches.data.map((el) => ({
      ...el,
      label: el.BranchName,
      value: el.BranchId,
    }))
  }
  isMulti={false}
  isRequired={true}
  onChange={(e) => setValue("BranchId", e)}
/>





<Flex gap={4}>
<GButton
  type="submit"
  title={t("general.save")}
  bg="primary"
  color="light"
  border="1px solid transparent"
  //isLoading={categories.isLoading}
  hover={{
    color: "primary",
    borderColor: "primary",
    bg: "none",
  }}
/>
<GButton
  title={t("general.close")}
  onClick={onClose}
  bg="red.600"
  border="1px solid transparent"
  color="light"
  //isLoading={categories.isLoading}
  hover={{
    color: "red.600",
    borderColor: "red.600",
    bg: "none",
  }}
/>
</Flex>
      
      
      </Stack>
      
      </form>
      
      
      </Box>

    }
    />
  );
}

export default UpdateModal;
