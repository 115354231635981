import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getTaxesFilteredData } from "../services/taxesServices";

export default function FilterModal({ onClose, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const [filteredData, setFilteredData] = useState(data);

  // Filter Data
  const [filterCriteria, setFilterCriteria] = useState({ Tax: "" });

  const handleFilterTaxes = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const { Tax } = filterCriteria;

      let filterTax = true;

      if (Tax) {
        filterTax = item?.Tax?.toLowerCase().includes(Tax.toLowerCase());
      }

      return (
        filterTax
      );

    });

    setFilteredData(filteredResult);
    onClose();
    dispatch(getTaxesFilteredData(filteredResult));
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.reports.business_reports.filter")}
        modalContent={
          <Box p={4}>
            <form onSubmit={handleFilterTaxes}>
              <Stack spacing={6}>
                <Input
                  label={t("pages.reports.reports_tables.tax")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.tax")}
                  name="Tax"
                  value={filterCriteria.Tax}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      Tax: e.target.value,
                    })
                  }
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
