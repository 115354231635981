import React from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import { Box, Flex, Heading, Spinner } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { getCategories, getDeleted, restoreCategory } from "../../services/categoriesService";

const Restore = ({ onClose, isLoading, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const deletefunction = () => {
    dispatch(restoreCategory(id))
      .unwrap()
      .then((_) => {
        toast({ description: t("pages.categories.restored") });
        dispatch(getDeleted());
        dispatch(getCategories());
        onClose();
      });
  };
  return (
    <div>
      <GModal
        modalHeader={t("pages.categories.restore_category")}
        onClose={onClose}
        modalContent={
          isLoading ? (
            <Flex bg="light" p={4} justifyContent="center">
              <Spinner />
            </Flex>
          ) : (
            <Box p={6}>
              <Heading
                as="h4"
                fontSize="1.3rem"
                mb={6}
                textTransform="capitalize"
              >
                {t("pages.categories.restore_confirm")}
              </Heading>
              <Flex gap={4}>
                <GButton
                  title={t("general.yes")}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                  onClick={() => deletefunction()}
                />
                <GButton
                  title={t("general.no")}
                  bg="none"
                  color="dark"
                  border="1px solid"
                  borderColor="border"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                  onClick={onClose}
                />
              </Flex>
            </Box>
          )
        }
      />
    </div>
  );
};

export default Restore;
