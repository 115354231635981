import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Flex} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import FilterModal from "./modals/FilterModal";
import { resetPurchaseOrderFilteredData } from "../services/purchasrOrderServices";
import FilteredDataTable from "./FilteredDataTable";
import ClosedDataTable from "./ClosedDataTable";
import DraftDataTable from "./DraftDataTable";
import PendeingDataTable from "./PendeingDataTable";
import PurchaseOrderDataTable from "./PurchaseOrderDataTable";

import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege .jsx";

export default function PurchaseOrderTable({ data, filteredData }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [sortType, setSortType] = useState("All");

  const resetFunction = () => {
    dispatch(resetPurchaseOrderFilteredData());
  };

const closedData = data?.filter((item)=>{
    return   item.Status === "Approved"
})
const draftData = data?.filter((item)=>{
    return   item.Status === "Draft"
})
const pendingData = data?.filter((item)=>{
    return   item.Status === "Pending"
})


const closedFilteredData = filteredData?.filter((item)=>{
    return   item.Status === "Approved"
})
const draftFilteredData = filteredData?.filter((item)=>{
    return   item.Status === "Draft"
})
const pendingFilteredData = filteredData?.filter((item)=>{
    return   item.Status === "Pending"
})


/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageApprovingTable = checkIdExists(retrievedArray, 16);

/* -----------------------------------Privilige-------------------------------*/

  return (
    <>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <GButton
              title={t("general.all")}
              bg="none"
              isDisabled={false}
              borderRadius={0}
              color={sortType === "All" ? "primary" :"none"}  
              borderBottom={sortType === "All" ? "2px solid" :"none"}   
              borderColor={sortType === "All" ? "primary" :"none"} 
              onClick={() => setSortType("All")}
            />
            <GButton
              title={t("pages.transfers_orders.draft")}
              bg="none"
              isDisabled={false}
              borderRadius={0}
              color={sortType === "Draft" ? "primary" :"none"}  
              borderBottom={sortType === "Draft" ? "2px solid" :"none"}   
              borderColor={sortType === "Draft" ? "primary" :"none"} 
              onClick={() => setSortType("Draft")}
            />
            <GButton
              title={t("pages.transfers_orders.pending")}
              bg="none"
              isDisabled={false}
              borderRadius={0}
              color={sortType === "Pending" ? "primary" :"none"}  
              borderBottom={sortType === "Pending" ? "2px solid" :"none"}   
              borderColor={sortType === "Pending" ? "primary" :"none"} 
              onClick={() => setSortType("Pending")}
            />
            <GButton
              title={t("pages.transfers_orders.closed")}
              bg="none"
              isDisabled={false}
              borderRadius={0}
              color={sortType === "Closed" ? "primary" :"none"}  
              borderBottom={sortType === "Closed" ? "2px solid" :"none"}   
              borderColor={sortType === "Closed" ? "primary" :"none"} 
              onClick={() => setSortType("Closed")}
            />

          </Flex>

          <GButton
            title={
              filteredData?.length === 0
                ? t("general.filter")
                : t("general.clear_filter")
            }
            border="1px solid"
            borderColor="border"
            postfixElement={
              filteredData?.length === 0 ? (
                <FiIcons.FiFilter size={16} />
              ) : (
                <IoCloseOutline fontWeight={800} size={20} />
              )
            }
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            onClick={
              filteredData?.length === 0
                ? () => setShowFilterModal(true)
                : resetFunction
            }
          />
        </Flex>

        {
            data?.length > 0 ? 
            <>
            {
                filteredData?.length > 0 ? 
                <FilteredDataTable 
                    data={ 
                        sortType === "All" ? filteredData 
                        : sortType === "Draft" ? draftFilteredData 
                        : sortType === "Pending" ? pendingFilteredData 
                        : closedFilteredData 
                    } 
                />
                : sortType === "All" ?
                <PurchaseOrderDataTable data={data} />
                : sortType === "Draft" ? 
                <DraftDataTable data={draftData} />
                : sortType === "Pending" ? 
                <PendeingDataTable data={pendingData} />
                : 
                <>
                {
                  ManageApprovingTable ?
                  <ClosedDataTable data={closedData} />
                  :
                  <NotAuthorizedCompo/>
                }
                </>
            }
            </>
            :
            <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
                color="text"
                textTransform="capitalize"
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                borderRadius={6}
                >
                {t("pages.branches.no_data")}
            </Flex>
        }


        {showFilterModal && (
          <FilterModal onClose={() => setShowFilterModal(false)} data={data} />
        )}
      </Box>
    </>
  );
}
