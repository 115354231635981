import React, { useState } from "react";
import {Box, Flex, Table, Tbody, Td, Th, Thead, Tr, Checkbox } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { IoCloseOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { resetProductionFilteredData } from "../services/productionService";
import { useDispatch } from "react-redux";
import FilterModal from "./modals/FilterModal";
import FilteredDataTable from "./FilteredDataTable";
import ProductionDataTable from "./ProductionDataTable";
import AllProductionDataTable from "./AllProductionDataTable";
import ConsumptionDataTable from "./ConsumptionDataTable";
import WasteDataTable from "./WasteDataTable";

const ProductionTable = ({ data  , filteredData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedTable, setSelectedTable] = useState("All");

  // Show Filter Modal State
  const [showFilterModal, setShowFilterModal] = useState(false);

  const resetFunction = () => {
      dispatch(resetProductionFilteredData());
    };

    const productionData = data?.filter((item)=>{
      return   item.ProductionTypeName === "Production"
  })
  const consumptionData = data?.filter((item)=>{
      return   item.ProductionTypeName === "Consumption of Production"
  })
  const wasteData = data?.filter((item)=>{
      return   item.ProductionTypeName === "Waste From Production"
  })
  
  // Waste From Production
// Consumption of Production
  const productionFilteredData = filteredData?.filter((item)=>{
      return   item.ProductionTypeName === "Production"
  })
  const consumptionFilteredData = filteredData?.filter((item)=>{
      return   item.ProductionTypeName === "Consumption of Production"
  })
  const wasteFilteredData = filteredData?.filter((item)=>{
      return   item.ProductionTypeName === "Waste From Production"
  })

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            color={selectedTable === "All" ? "primary" :"none"}  
            borderBottom={selectedTable === "All" ? "2px solid" :"none"}   
            borderColor={selectedTable === "All" ? "primary" :"none"} 
            onClick={() => setSelectedTable("All")}
          />
          
          <GButton
            title={t("pages.inventory.production")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            color={selectedTable === "Production" ? "primary" :"none"}  
            borderBottom={selectedTable === "Production" ? "2px solid" :"none"}   
            borderColor={selectedTable === "Production" ? "primary" :"none"} 
            onClick={() => setSelectedTable("Production")}
          />
          
          <GButton
            title={t("pages.inventory.consumption")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            color={selectedTable === "Consumption" ? "primary" :"none"}  
            borderBottom={selectedTable === "Consumption" ? "2px solid" :"none"}   
            borderColor={selectedTable === "Consumption" ? "primary" :"none"} 
            onClick={() => setSelectedTable("Consumption")}
          />
          
          <GButton
            title={t("pages.inventory.waste")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            color={selectedTable === "Waste" ? "primary" :"none"}  
            borderBottom={selectedTable === "Waste" ? "2px solid" :"none"}   
            borderColor={selectedTable === "Waste" ? "primary" :"none"} 
            onClick={() => setSelectedTable("Waste")}
          />

        </Flex>

          <GButton
              title={
                  filteredData?.length === 0
                  ? t("general.filter")
                  : t("general.clear_filter")
              }
                border="1px solid"
                borderColor="border"
                postfixElement={
                    filteredData?.length === 0 ? (
                    <FiIcons.FiFilter size={16} />
                    ) : (
                    <IoCloseOutline fontWeight={800} size={20} />
                    )
                }
                hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                onClick={
                    filteredData?.length === 0
                    ? () => setShowFilterModal(true)
                    : resetFunction
                }
            />
      </Flex>

      {
          data?.length > 0 ? 
          <>
          {
              filteredData?.length > 0 ? 
              <FilteredDataTable 
                  data={ 
                      selectedTable === "All" ? filteredData 
                      : selectedTable === "Production" ? productionFilteredData 
                      : selectedTable === "Consumption" ? consumptionFilteredData 
                      : wasteFilteredData 
                  } 
              />
              : selectedTable === "All" ?
              <AllProductionDataTable data={data} />
              : selectedTable === "Production" ? 
              <ProductionDataTable data={productionData} />
              : selectedTable === "Consumption" ? 
              <ConsumptionDataTable data={consumptionData} />
              : 
              <WasteDataTable data={wasteData} />
          }
          </>
          :
          <Flex
              p={4}
              bg="white"
              alignItems="center"
              justifyContent="center"
              color="text"
              textTransform="capitalize"
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
              borderRadius={6}
              >
              {t("pages.branches.no_data")}
          </Flex>
      }
      
        { showFilterModal && (
              <FilterModal 
                  onClose={()=>setShowFilterModal(false)} 
                  filterData={filteredData}
                  data ={data}
              />
        )}
    </Box>
  );
};

export default ProductionTable;
