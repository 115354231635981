import React, { useEffect, useState } from "react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, SimpleGrid, Stack, Text, useToast , Spinner } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  getTranOrderItems,
  getTransfersOrdersDetails,
} from "../services/transfersOrdersService";
import EditTransferOrderModal from "../components/modals/EditTransferOrderModal";
import TransfersOrdersItems from "../components/TransfersOrdersItems";
import SubmitModal from "../components/modals/SubmitModal";
import SendItemsModal from "../components/modals/SendItemsModal";
import DeleteModal from "../components/modals/DeleteModal";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  


const SuppliersDetailsInventory = () => {
  const { t } = useTranslation();
  const id = useParams();
  const dispatch = useDispatch();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  
  const supplierDetails = useSelector((state) => state.transfersorders);
  // console.log(supplierDetails);
  const data = supplierDetails.databyId;

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    dispatch(getTransfersOrdersDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getTranOrderItems(id));
  }, [dispatch, id]);
/* -----------------------------------Privilige-------------------------------*/
  const retrievedArray =useDecryptedPrivilege();
  const ManageTransferOrders = checkIdExists(retrievedArray, 18);

  if(showSubmitModal === true && ManageTransferOrders === false){
    toast2({ description:  t("general.authorization")})
    setShowSubmitModal(false)
  };
  if(showEditModal === true && ManageTransferOrders === false){
    toast2({ description:  t("general.authorization")})
    setShowEditModal(false)
  };
  if(showDeleteModal === true && ManageTransferOrders === false){
    toast2({ description:  t("general.authorization")})
    setShowDeleteModal(false)
  };
  if(showSendModal === true && ManageTransferOrders === false){
    toast2({ description:  t("general.authorization")})
    setShowSendModal(false)
  };
/* -----------------------------------Privilige-------------------------------*/
  return (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            <Flex>
                <Box> {t("layout.sidebar.transfers_requests")} </Box>
                <Box marginX={"5px"} > { data[0]?.TransferOrdersRef ? `(${data[0]?.TransferOrdersRef})`  : ""} </Box>
                <Box
                  bg={"#718096"}
                  display={"flex"}
                  alignItems={"center"}
                  marginX={"5px"}
                  borderRadius={"5px"}
                  color={"white"}
                  fontSize={"15px"}
                  padding={"2px 10px"}
                >
                  {data[0]?.TransferOrdersStatusName}
                </Box>
            </Flex>
          </Box>

          <Flex gap={2}>
            <GButton
              prefixElement={<FiIcons.FiPrinter size={16} />}
              title={t("pages.transfers_orders.print")}
              bg="light"
              color="text"
              border="1px"
              borderColor={"#CBD5E0"}
              opacity="0.7"
              onClick={handlePrint}
            />
            {data[0]?.TransferOrdersStatusName === "Pending" ? (
              <>
                <GButton
                  prefixElement={
                    <FiIcons.FiCheck
                      size={16}
                      style={{ top: "2px", position: "relative" }}
                    />
                  }
                  title={t("general.close")}
                  bg="light"
                  color="text"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  opacity="0.7"
                />

                <GButton
                  title={t("pages.transfers_orders.send_items")}
                  bg="#54A0FF"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    bg: "none",
                    borderColor: "#54A0FF",
                    color: "#54A0FF",
                  }}
                  onClick={() => setShowSendModal(true)}
                />
              </>
            ) : data[0]?.TransferOrdersStatusName === "Draft" ? (
              <>
                <GButton
                  prefixElement={<FiIcons.FiTrash2 size={16} />}
                  title={t("pages.transfers_orders.delete_permenantly")}
                  bg="light"
                  color="text"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  opacity="0.7"
                  onClick={() => setShowDeleteModal(true)}
                />

                <GButton
                  prefixElement={<FiIcons.FiEdit2 size={16} />}
                  title={t("general.edit")}
                  bg="light"
                  color="text"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  opacity="0.7"
                  onClick={() => setShowEditModal(true)}
                />

                <GButton
                  title={t("pages.transfers_orders.submit_for_review")}
                  bg="#54A0FF"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    bg: "none",
                    borderColor: "#54A0FF",
                    color: "#54A0FF",
                  }}
                  onClick={() => setShowSubmitModal(true)}
                />
              </>
            ) :
            ""
              }
          </Flex>
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.transfers_orders.warehouse")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.Warehouse || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.transfers_orders.destination")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.Destnisiton || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.inventory.business_date")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.SuppliersName || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.transfers_orders.creator")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.PrimaryEmail || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.inventory.created")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.CreateDate || "-"}
            </Text>
          </Box>
        </SimpleGrid>

        <TransfersOrdersItems
          dataStatus={data[0]}
          subGroup={supplierDetails}
          id={id}
        />

      </Stack>

        {showEditModal && (
          <EditTransferOrderModal
            onClose={() => setShowEditModal(false)}
            data={data}
            id={id}
          ></EditTransferOrderModal>
        )}

        {showSubmitModal && (
          <SubmitModal
            onClose={() => setShowSubmitModal(false)}
            id={id}
          ></SubmitModal>
        )}

        {showSendModal && (
          <SendItemsModal
            onClose={() => setShowSendModal(false)}
            id={id}
          ></SendItemsModal>
        )}

        {showDeleteModal && (
          <DeleteModal
            onClose={() => setShowDeleteModal(false)}
            id={id}
          ></DeleteModal>
        )}
    </>
  );
};

export default SuppliersDetailsInventory;
