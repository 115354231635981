import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteTag, getTags } from "../../services/tagsService";

const DeleteModal = ({id,onClose}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const tags = useSelector(state => state.tags);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteTag(id))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.tags.tag_deleted") });
            dispatch(getTags());
            onClose();
          })
      }}
      isLoading={tags.isLoading}
    />
  )
}

export default DeleteModal