import { createSlice } from "@reduxjs/toolkit";
import {
  getDevices,
  createDevice,
  updateDevice,
  deleteDevice,
  getDeviceDetails,
  DeactivateDevice,
  resetDevicesFilteredData,
  getDevicesFilteredData,
} from "../services/devicesService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  deviceDetaisl:[],
  errors: null ,
  filteredData : []
};

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDevices.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDevices.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getDevices.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createDevice.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createDevice.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createDevice.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateDevice.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateDevice.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //const indexAt = state.data.findIndex(device => device.id === payload.id);
      //state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateDevice.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteDevice.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteDevice.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(device => device.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteDevice.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //


    builder.addCase(getDeviceDetails.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDeviceDetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //console.log(payload)
      state.deviceDetaisl = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      // console.log(state.deviceDetaisl)
      state.errors = null;
    })
    builder.addCase(getDeviceDetails.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    //


    builder.addCase(DeactivateDevice.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(DeactivateDevice.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //console.log(payload)
      //state.deviceDetaisl = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.deviceDetaisl)
      state.errors = null;
    })
    builder.addCase(DeactivateDevice.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

       /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
   builder.addCase(getDevicesFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getDevicesFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getDevicesFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetDevicesFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetDevicesFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetDevicesFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* --------------------------------------------------------------------------------------------------- */
  }
});

export default devicesSlice.reducer;