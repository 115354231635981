import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
  Checkbox,
} from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import {
  addNewVacationType,
  getVacationTypeList,
} from "features/hr/hrSetting/services/hrSettingServices";

export default function AddVacationTypeSetting({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const [formNote, setFormNote] = useState("");
  const [IsAcademicLeave, setIsAcademicLeave] = useState(false);
  const [IsCalendarDay, setIsCalendarDay] = useState(false);
  const [IsUnPaid, setIsUnPaid] = useState(false);

  /**************************** Validation ******************************* */

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  // values.UserId = localStorage.getItem("userId");
  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.AddVacationTypeSetting")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.VacationTypeId = null;
                values.IsAcademicLeave = IsAcademicLeave;
                values.IsCalendarDay = IsCalendarDay;
                values.IsUnPaid = IsUnPaid;
                values.Notes = formNote;
                console.log(values);
                dispatch(addNewVacationType(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.hr.VacationType_Added_successfuly"),
                    });
                    dispatch(getVacationTypeList());
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.VacationTypeName}>
                  <FormLabel
                    htmlFor="VacationTypeName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.VacationTypeName")}
                  </FormLabel>
                  <Controller
                    name="VacationTypeName"
                    control={control}
                    // defaultValue={details?.InsuranceJob}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.VacationTypeName")}
                        onChange={(e) => {
                          handleInputChange(e, "VacationTypeName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.VacationTypeName && errors.VacationTypeName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.VacationTypeNameEn}>
                  <FormLabel
                    htmlFor="VacationTypeNameEn"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.VacationTypeNameEn")}
                  </FormLabel>
                  <Controller
                    name="VacationTypeNameEn"
                    control={control}
                    // defaultValue={details?.InsuranceJob}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.VacationTypeNameEn")}
                        onChange={(e) => {
                          handleInputChange(e, "VacationTypeNameEn");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.VacationTypeNameEn &&
                      errors.VacationTypeNameEn.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.MaxDays}>
                  <FormLabel
                    htmlFor="MaxDays"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.maxDays")}
                  </FormLabel>
                  <Controller
                    name="MaxDays"
                    control={control}
                    // defaultValue={details.NationalID}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 1,
                        message: t("pages.hr.hr_validation.number_short_1"),
                      },
                      maxLength: {
                        value: 3,
                        message: t("pages.hr.hr_validation.number_long_2"),
                      },
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.maxDays")}
                        onChange={(e) => {
                          handleInputChange(e, "MaxDays");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.MaxDays && errors.MaxDays.message}
                  </FormErrorMessage>
                </FormControl>

                <Box>
                  <Text>{t("general.notes")}</Text>
                  <Textarea
                    placeholder="Here is a sample placeholder"
                    marginTop={"2"}
                    value={formNote}
                    onChange={(e) => setFormNote(e.target.value)}
                  />
                </Box>

                <Checkbox
                  colorScheme="blue"
                  // defaultChecked={positionDetails[0]?.IsFullTime}
                  onChange={(e) => setIsAcademicLeave(e.target.checked)}
                >
                  {t("pages.hr.IsAcademicLeave")}
                </Checkbox>
                <Checkbox
                  colorScheme="blue"
                  // defaultChecked={positionDetails[0]?.IsFullTime}
                  onChange={(e) => setIsCalendarDay(e.target.checked)}
                >
                  {t("pages.hr.IsCalendarDay")}
                </Checkbox>
                <Checkbox
                  colorScheme="blue"
                  // defaultChecked={positionDetails[0]?.IsFullTime}
                  onChange={(e) => setIsUnPaid(e.target.checked)}
                >
                  {t("pages.hr.IsUnPaid")}
                </Checkbox>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
