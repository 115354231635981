import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSectionTables } from '../services/branchesService'
import TableSectionTable from '../components/TableSectionTable'
import GButton from 'components/ui/button/Button'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import AddTableToSectionModal from '../components/modals/AddTableToSectionModal'
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege '

const BrancheSectionTable = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 39);

    const dispatch=useDispatch()
    const id = useParams()
    //console.log(id.BranchSectionId)
    useEffect(()=>{
        dispatch(getSectionTables(id.BranchSectionId))
    },[dispatch,id])

    const sections = useSelector(state => state.branches);
    //console.log(sections);
    const { t } = useTranslation();
    const [showAddTableSection,setshowAddTableSection]=useState(false)

    if(ManageBranches===false  ){
      return(
        <NotAuthorizedCompo/>
      )
    }
    

  return (
    <div>
    <Box>
      <Flex
        alignItems="center" justifyContent="space-between"
        gap={2} flexWrap="wrap" mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.branches.tables")}
        </Text>
        <GButton
          title={t("pages.branches.add_table")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setshowAddTableSection(true)}
        />
      </Flex>
      <TableSectionTable data={sections.SectionTables} isLoading={sections.isLoading}/>
      </Box>
      {
        showAddTableSection&&(
          <AddTableToSectionModal  onClose={() => setshowAddTableSection(false)}/>
        )
      }
    </div>


  )
}

export default BrancheSectionTable
