import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const GetAccountTypeNew = createAsyncThunk(
    "AccountTreeNew/GetAccountTypeNew",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Accountant/LookupGetAccountType`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const GetFinancialPeriodsList = createAsyncThunk(
    "AccountTreeNew/GetFinancialPeriodsList",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Accountant/GetFinancialPeriodsList?data={"FinId":"1"}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getAcoountsTree = createAsyncThunk(
    "AccountTreeNew/getAcoountsTree",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Accountant/GetTree`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const createAccountTreeNode = createAsyncThunk(
    "AccountTreeNew/createAccountTreeNode",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Accountant/AddAccountChart?accountchart=${JSON.stringify(args)}`);
       console.log(data)
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );