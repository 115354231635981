import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

const CTable = ({
  customersTags,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.customers.name")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {customersTags?.map((tag, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>{tag?.TagName}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default CTable