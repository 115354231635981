import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBrandsFilteredData } from '../../services/brandsService';

export default function FilterModal({onClose , data }) {
    const { t } = useTranslation();
    const dispatch =useDispatch();

    const [filteredData, setFilteredData] = useState(data);

    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
         BrandName: ""
      });

      const handleFilterBrands = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const { BrandName } = filterCriteria;
    
          let filterBrandName = true;
    
          if (BrandName) {
            filterBrandName = item.BrandName.toLowerCase().includes(BrandName.toLowerCase());
          }

          return (
            filterBrandName 
          );

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getBrandsFilteredData(filteredResult));
      };
      
  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.brands.filter_brands")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterBrands}
          >
            <Stack spacing={6}>
                  <Input
                    label={t("pages.brands.brand_name")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={t("pages.brands.brand_name")}
                    name="BrandName"
                    value={filterCriteria.BrandName}
                    onChange={(e) =>
                      setFilterCriteria({
                        ...filterCriteria,
                        BrandName: e.target.value,
                      })
                    }
                  />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
