import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { getFilteredData } from "../../services/groupsService";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/form/Input";
import MultiSelect from "components/ui/multiSelect/MultiSelect";

const FilterModal = ({ onClose, data }) => {
  const [filterCriteria, setFilterCriteria] = useState({
    GroupName: "",
    GiftCardsNameLoc: "",
    ProductCategoriesNameEng: "",
    GiftCardsSUK: "",
    Price: "",
  });
  const [filteredData, setFilteredData] = useState(data);

  const categories = useSelector((state) => state.categories);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleFilterGiftsCard = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const {
        GroupName,
        GiftCardsNameLoc,
        ProductCategoriesNameEng,
        GiftCardsSUK,
        Price,
      } = filterCriteria;


      // Create variables to hold filter conditions
      let filterByNameEng = true;
      let filterByNameLoc = true;
      let filterByProductCategoriesNameEng = true;
      let filterBySUK = true;
      let filterByPrice = true;

      // Check if the filter criteria are defined, and if so, apply the filter
      if (GroupName) {
        filterByNameEng = item.GroupName.toLowerCase().includes(
          GroupName.toLowerCase()
        );
      }

      if (GiftCardsNameLoc) {
        filterByNameLoc = item.GiftCardsNameLoc.toLowerCase().includes(
          GiftCardsNameLoc.toLowerCase()
        );
      }

      if (ProductCategoriesNameEng) {
        filterByProductCategoriesNameEng =
          item.ProductCategoriesNameEng.toLowerCase().includes(
            ProductCategoriesNameEng.toLowerCase()
          );
      }

      if (GiftCardsSUK) {
        filterBySUK = item.GiftCardsSUK.toLowerCase().includes(
          GiftCardsSUK.toLowerCase()
        );
      }

      if (Price) {
        filterByPrice = item.Price === parseInt(Price);
      }

      // Return true if all conditions are met
      return (
        filterByNameEng &&
        filterByNameLoc &&
        filterByProductCategoriesNameEng &&
        filterBySUK &&
        filterByPrice
      );
    });
    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredData(filteredResult));
  };
  // useEffect(() => {
  //   dispatch(getCategories());
  // }, [dispatch]);

  const handleClose = () => {
    onClose(filteredData);
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.groups.filter_groups")}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleFilterGiftsCard}>
            <Stack spacing={6}>
              <Input
                label={t("pages.gifts_cards.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.name")}
                name="GroupName"
                value={filterCriteria.GroupName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    GroupName: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={handleClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default FilterModal;
