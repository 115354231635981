import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";



export const createQuantityAdjasment = createAsyncThunk(
    "QuantityAdjustment/createQuantityAdjasment",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/AddQuantityAdjustment?quantityadjustment=${JSON.stringify(args)}`);
       console.log(data)
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ---------------------------------------------------- Get Branches For Quantity Adjusment -------------------------------------*/
  export const getBranchesForQuantityAdjusment = createAsyncThunk("QuantityAdjustment/getBranchesForQuantityAdjusment", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ---------------------------------------------------- Get Reason For Quantity Adjusment -------------------------------------*/
  export const getReasonForQuantityAdjusment = createAsyncThunk("QuantityAdjustment/getReasonForQuantityAdjusment", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/QuantityAdjustment/LookupReasons`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  /* ------------------------------------------------------- Get All Quantity Adjusment -----------------------------------------------------*/
  export const getAllQuantityAdjusment = createAsyncThunk("QuantityAdjustment/getAllQuantityAdjusment", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/QuantityAdjustmentList`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getQuantityAdjustmentDetails = createAsyncThunk("QuantityAdjustment/getQuantityAdjustmentDetails", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/QuantityAdjustmentById?quantityadjustmentid={"QuantityAdjustmentId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );



  export const getItemsForQuantityAdjusment = createAsyncThunk("QuantityAdjustment/getItemsForQuantityAdjusment",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/InventoryItem/LookupInventoryItem`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );



  export const AddItemForQuantityAdjusment= createAsyncThunk(
    "QuantityAdjustment/AddItemForQuantityAdjusment",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/AddQuantityAdjustmentItem?quantityadjustmentitem=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getQuantityAdjustmentItems = createAsyncThunk("QuantityAdjustment/getQuantityAdjustmentItems", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/QuantityAdjustmentListItemById?quantityadjustmentid={"QuantityAdjustmentId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ----------------------------------------------------------- Edit  Quantity Adjustmen -------------------------------------------------------- */
  export const editQuantityAdjasment = createAsyncThunk("QuantityAdjustment/editQuantityAdjasment", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/EditQuantityAdjustment?quantityadjustmentid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);

      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ----------------------------------------------------------- Edit  Quantity Adjustmen Items -------------------------------------------------------- */
  export const EditQuantityAdjustmenItems = createAsyncThunk("QuantityAdjustment/EditQuantityAdjustmenItems", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/EditQuantityAdjustmenItems?quantityadjustmentid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);

      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );



  export const deleteQuantityAdjustment = createAsyncThunk(
    "QuantityAdjustment/deleteQuantityAdjustment",
    async (args, thunkApi) => {
      
      try {
        const { data } = await axios.get(`/Inventory/DeleteQuantityAdjustmentById?quantityadjustmentid={"QuantityAdjustmentId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const submitQuantityAdjustment = createAsyncThunk(
    "QuantityAdjustment/submitQuantityAdjustment",
    async (args, thunkApi) => {
      
      try {
        const { data } = await axios.get(`/Inventory/SubmittingQuantityAdjustmen?quantityadjustmentid={"QuantityAdjustmentId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  export const getQuantityAdjustmentItemsToEdit = createAsyncThunk( "QuantityAdjustment/getQuantityAdjustmentItemsToEdit", async (args, thunkApi) => {  
    try {
        const { data } = await axios.get(`/Inventory/GetEditQuantityAdjustmentItemsByQAId?quantityadjustmentid={"QuantityAdjustmentId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

    /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getQuantityAdjustmentFilteredData = createAsyncThunk("QuantityAdjustment/getQuantityAdjustmentFilteredData ", async (args, thunkApi) => {
      try {
        return (args);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  export const resetQuantityAdjustmentFilteredData = createAsyncThunk( "QuantityAdjustment/resetQuantityAdjustmentFilteredData ", async (args, thunkApi) => { 
      try {
        return args;
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );