import { createSlice } from "@reduxjs/toolkit";
import { getvoidReturnFilteredData, getvoidReturnReportList, resetvoidReturnFilteredData } from "../services/voidsReportServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const voidReturnReportSlice = createSlice({
  name: "voidReturnReport",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------- Get Taxes Report List ------------------------------- */
    builder.addCase(getvoidReturnReportList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getvoidReturnReportList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getvoidReturnReportList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

   /* --------------------------------------------------------------------------------------------------- */
 /* --------------------------------------- Taxes Filter Data ------------------------------*/
    builder.addCase(getvoidReturnFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getvoidReturnFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getvoidReturnFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetvoidReturnFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetvoidReturnFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetvoidReturnFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default voidReturnReportSlice.reducer;