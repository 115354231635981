import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { createReservation, getReservationBranches, getReservationsList } from "../../services/reservationsService";

const CreateModal = ({onClose}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {handleSubmit,setValue} = useForm();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const reservations = useSelector(state => state.reservations);

  useEffect(() => {
    dispatch(getReservationBranches());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.reservations.add_branches")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.BranchId = values.BranchId?.BranchId;
            if( values.BranchId === undefined){
              toast2({ description: t("validation.choose_first")});
            }
            else {
                  dispatch(createReservation(values.BranchId))
                    .unwrap()
                    .then(_ => {
                      toast({ description: t("pages.reservations.reservation_created") });
                      dispatch(getReservationsList());
                      onClose();
                    });
            }
          })}>
            <Stack spacing={6}>

              <MultiSelect
                data={reservations.reservationBranches && reservations.reservationBranches.map(el => ({
                  ...el,
                  label: el.BranchName,
                  value: el.BranchId
                }))}
                onChange={(e) => setValue("BranchId", e)}
                isMulti={false}
                isRequired
                title={t("pages.reservations.branch")}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={reservations.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={reservations.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default CreateModal;