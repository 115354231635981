import GModal from "components/ui/modal/Modal";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductionItem,
  getAddItemDropList,
  getProductionItems,
} from "../../services/productionService";

const AddProductionItemsModal = ({ onClose, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue } = useForm();

  const productionsData = useSelector((state) => state.productions);
  const ProductionId = id.ProductionId;

  useEffect(() => {
    dispatch(getAddItemDropList());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.purchases.add_items")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.ProductionItem = values.InventoryItemId?.map((el) => ({
                InventoryItemId: el.InventoryItemId,
                ProductionId,
              }));
              values.UserId = localStorage.getItem("userId");
              if (
                values.ProductionItem === undefined ||
                values.ProductionItem.length === 0
              ) {
                toast2({ description: t("validation.choose_first") });
              } else {
                dispatch(addProductionItem(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.production.production_item_added"),
                    });
                    dispatch(getProductionItems(id));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("general.items")}
                data={productionsData.addItemDropData.map((el) => ({
                  ...el,
                  label: el.InventoryItemNameEng,
                  value: el.InventoryItemId,
                }))}
                isMulti={true}
                isRequired={true}
                onChange={(e) => setValue("InventoryItemId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddProductionItemsModal;
