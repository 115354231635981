import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import * as FiIcons from "react-icons/fi";
import { t } from "i18next";
import CreateModal from "../modals/CreateModal";
import DeleteModal from "../modals/DeleteModal";
import UpdateModal from "../modals/UpdateModal";
import { useState } from "react";

export default function ReasonsList ({resons}) {
  const [showCreateReasonModal, setShowCreateReasonModal] = useState(null);
  const [showDeleteReasonModal, setShowDeleteReasonModal] = useState(null);
  const [showUpdateReasonModal, setShowUpdateReasonModal] = useState(null);

  const [reasonType, setReasonType] = useState(null);

  return (
    <Stack spacing={6} mt={6}>
      {resons.map((reason , index) => (
        <>
          <Flex
            key={index}
            alignItems="center" justifyContent="space-between" gap={2} flexWrap="wrap"
          >
            <Text>  {reason?.ReasonTypeNameEng}  </Text>
            <GButton
              title={t("pages.reasons.create_reason")}
              bg="light"
              color="text"
              fontSize="12px"
              border="1px solid"
              borderColor="border"
              hover={{ bg: "light" }}
              onClick={() =>    {
                        setShowCreateReasonModal(true)
                        setReasonType(reason.ReasonTypeId)
                      }
               }
            />
          </Flex>
          <Box
            bg="light" borderRadius={4}
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
          >
            {reason.Reasons?.map((el , index) => (
              <Box  borderBottom="1px solid" borderColor="border" paddingInline={6} paddingBlock={3}>
                <Flex justifyContent={"space-between"} > 
                    <Text key={index} textTransform="capitalize"> {el?.ReasonNameEng} </Text>
                    <Flex gap={2}>
                      <GButton
                        postfixElement={<FiIcons.FiEdit />}
                        bg="primary"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "primary",
                          color: "primary",
                        }}
                        onClick={() => 
                          {
                              setShowUpdateReasonModal(el)
                              setReasonType(reason.ReasonTypeId)
                          }
                        }
                      />
                      <GButton
                        postfixElement={<FiIcons.FiTrash2 />}
                        bg="red.600"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "red.600",
                          color: "red.600",
                        }}
                        onClick={() => setShowDeleteReasonModal(el.ReasonId)}
                      />
                    </Flex>
                </Flex>
              </Box>
            ))}
          </Box>
        </>
      ))}

      {showCreateReasonModal && (
        <CreateModal
          onClose={() => setShowCreateReasonModal(false)}
          reasonType={reasonType}
        />
      )}

      {showUpdateReasonModal && (
        <UpdateModal
          data={showUpdateReasonModal}
          reasonType={reasonType}
          onClose={() => setShowUpdateReasonModal(false)}
        />
      )}

      {showDeleteReasonModal && (
        <DeleteModal
          onClose={() => setShowDeleteReasonModal(false)}
          data={showDeleteReasonModal}
        />
      )}
    </Stack>



  )
};
