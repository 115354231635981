import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import UpdateModal from "../components/modals/UpdateModal";
import GButton from "components/ui/button/Button";
import { getProductById, activateProducts } from "../services/productsService";
import CustomPriceBranche from "../components/CustomPriceBranche";
import AddingTags from "../components/AddingTags";
import DeletedProduct from "../components/DeletedProduct";
import SetOutOfStock from "../components/SetOutOfStock";
import LinkingIngredients from "../components/LinkingIngredients";
import LinkingModifiers from "../components/LinkingModifiers";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Details = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);


  const { productId } = useParams();
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const products = useSelector((state) => state.products);
  const product = products.productDetails;

  // console.log(product);

  const [showUpdateProductModal, setShowUpdateProductModal] = useState(null);

  const activeFunction = () => {
    dispatch(activateProducts(id.productId))
      .unwrap()
      .then((_) => {
        dispatch(getProductById({ productId }));
      });
  };

  useEffect(() => {
    dispatch(getProductById({ productId }));
  }, [dispatch, productId]);

  //console.log(product);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  if(showUpdateProductModal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowUpdateProductModal(false)
  }

  return products.isLoading ? (
    <Spinner />
  ) : product ? (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <Box as="p" fontSize="1.6rem" textTransform="capitalize">
              {product?.ProductName || "-"}
            </Box>
            <Button
              // title=
              bg={product.IsActive ? "#00D1D1" : "red"}
              color="light"
              _hover={"none"}
              style={{ cursor: "default" }}
            >
              <Text>
                {product.IsActive
                  ? t("general.activated")
                  : t("general.inactive")}
              </Text>
            </Button>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <GButton
              title={t("general.edit")}
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() =>
                setShowUpdateProductModal({
                  ...product,
                  ProductId: +productId || null,
                })
              }
            />
            <GButton
              title={
                product.IsActive ? t("general.deactive") : t("general.active")
              }
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() => activeFunction()}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        {/* details */}

        <Box
          borderBottom="1px solid"
          borderColor="border"
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          style={{display:"flex",alignItems:"center",justifyContent:"space-around"}}
        >
          <Text textTransform="capitalize" color="text">
            {t("general.img")}
          </Text>
          <img
            src={
              `https://rostoapi.smartgate-egypt.com/` +
              product?.ProductImagePath
            }
            alt="Selected"
            style={{
              maxWidth: "100%",
              maxHeight: "200px",
              marginTop: "10px",
            }}
          />{" "}
        </Box>

        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.name")}
            </Text>
            <Text marginBlock={2}>{product?.ProductName || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.name_localized")}
            </Text>
            <Text marginBlock={2}>{product?.ProductNameLoc || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.barcode")}
            </Text>
            <Text marginBlock={2}>{product?.Barcode || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.price")}
            </Text>
            <Text marginBlock={2}>{product?.ProductPrice || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.category")}
            </Text>
            <Text marginBlock={2}>
              {product?.ProductCategoriesNameEng || "-"}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.ref")}
            </Text>
            <Text marginBlock={2}>{product?.ProductSKURef || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.tax_group")}
            </Text>
            <Text marginBlock={2}>{product?.TaxGroupName || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.Pricing_Method")}
            </Text>
            <Text marginBlock={2}>{product?.PricingMethod || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.purchases.costing_method")}
            </Text>
            <Text marginBlock={2}>{product?.ProductCostTypeName || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.stock_product")}
            </Text>
            <Text marginBlock={2}>{product?.StockProduct || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.Selling_Method")}
            </Text>
            <Text marginBlock={2}>{product?.SellingMethod || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.calories")}
            </Text>
            <Text marginBlock={2}>{product?.Calories || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.preperation_time")}
            </Text>
            <Text marginBlock={2}>{product?.PreperationTime || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.products.desc")}
            </Text>
            <Text marginBlock={2}>{product?.ProductDescr || "-"}</Text>
          </Box>
        </SimpleGrid>

        <CustomPriceBranche></CustomPriceBranche>
        <AddingTags></AddingTags>
        <LinkingModifiers></LinkingModifiers>
        <LinkingIngredients></LinkingIngredients>
        <SetOutOfStock></SetOutOfStock>
        <DeletedProduct idOfProduct={id.productId}></DeletedProduct>
      </Stack>
      {showUpdateProductModal && (
        <UpdateModal
          data={showUpdateProductModal}
          onClose={() => setShowUpdateProductModal(null)}
        />
      )}
    </>
  ) : (
    <Flex
      bg="light"
      alignItems="center"
      justifyContent="center"
      p={4}
      textTransform="capitalize"
    >
      {t("pages.products.no_product")}
    </Flex>
  );
};

export default Details;
