import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteCoupon } from "../../services/couponsService";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";

const DeleteModal = ({ id, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const dispatch = useDispatch();
  const coupons = useSelector((state) => state.coupons);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteCoupon(id))
          .unwrap()
          .then((_) => {
            toast({ description: t("pages.coupons.coupon_deleted") });
            onClose();
            navigate(`/${routes.management_coupons}`);
          });
      }}
      isLoading={coupons.isLoading}
    />
  );
};

export default DeleteModal;
