import React from 'react';
import GRestoreModal from "components/ui/modal/RestoreModal";
import { useDispatch } from 'react-redux';
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { deleteCharge, getCharges } from "../../services/chargesService";

export default function RestoreCharge({data, onClose , setActiveNavbar}){
  const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();

  return (
    <GRestoreModal
        onClose={onClose}
        onSubmit={()=>{
        dispatch(deleteCharge(data.ChargeId))
          .unwrap().then(_ => {
            toast({ description: t("general.item_restored") });
            setActiveNavbar("all");
            dispatch(getCharges());
            onClose();
          })
        }}  
  />
  );
};