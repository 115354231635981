import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import Input from "components/form/Input";
import {  Flex, Stack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPriceTagsCombosFilteredData } from "../../services/priceTagsServices";

export default  function PriceTagsCombosFilter ({ onClose, data})  {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState(data);

  const [filterCriteria, setFilterCriteria] = useState({
    ComboName:""
  });


  const handleFilterCombos = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const { ComboName } = filterCriteria;

      let filterComboName = true;

      if (ComboName) {
        filterComboName = item?.Combo[0]?.ComboName?.toLowerCase().includes(ComboName.toLowerCase());
      }

      return (
        filterComboName 
         
      );

    });

    setFilteredData(filteredResult);
    dispatch(getPriceTagsCombosFilteredData(filteredResult));
    onClose();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.price_tags.filter_price_tags_combos")}
      modalContent={(
        <Box p={4}>
          <form 
          onSubmit={handleFilterCombos}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.price_tags.combos_name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.price_tags.combos_name")}
                name="ComboName"
                value={filterCriteria.ComboName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ComboName: e.target.value,
                  })
                }
              />
             

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
};