import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------- Get Modifiers For Product -----------------------------------------------*/
export const getModifiersForProduct = createAsyncThunk("linkModifiers/getModifiersForProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/ModiferList?productid={"ProductId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------- Get Modifiers In Product -----------------------------------------------*/
export const getModifiers = createAsyncThunk("linkModifiers/getModifiers",async (args, thunkApi) => {
    try {
      const { data } = await axios.get( `/menu/Modifers/LookupModifers`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------- Add Modifiers To Product -----------------------------------------------*/
export const addModifiersForProduct = createAsyncThunk("linkModifiers/addModifiersForProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Modifiers/AddLinkModifiersToProducts?linkmodifierstoproducts=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
