import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getSectiones = createAsyncThunk("sectiones/getSectiones",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/SecByBranchId?branchid=${args.branchId}`);
      //console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createSection = createAsyncThunk("sectiones/createSection", async (args, thunkApi) => {
    try {
      const { data } = await axios.get( `/Manage/Branches/AddBranchSection?branchid=${JSON.stringify(args)}` );
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateSection = createAsyncThunk("sectiones/updateSection", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/AddEditTableToSec?tablesec=${JSON.stringify(args)}`
      );
      //console.log("update")
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
