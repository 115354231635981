import React, { useEffect } from "react";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import PositionsListTable from "../components/PositionsListTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartmentDropdown,
  getJobTitleDropdown,
  getPositionDropdown,
  getPositionsList,
  getReportingToPositionDropdown,
} from "../services/positionsServices";
import { getClassification } from "features/hr/VacationList/services/vacationServices";

const PositionsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const positions = useSelector((state) => state.positionsSlice);
  const vacations = useSelector((state) => state.vacationSlice);
  useEffect(() => {
    dispatch(getPositionsList());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getReportingToPositionDropdown());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getDepartmentDropdown());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getJobTitleDropdown());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getPositionDropdown());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getClassification());
  }, [dispatch]);

  return (
    <div>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.Positions")}
          </Text>

          <Flex gap={2}></Flex>
        </Flex>
      </Box>

      <PositionsListTable
        data={positions?.positionList}
        positionDetails={positions?.positionDetails}
        classifications={vacations?.classifications}
        departments={positions?.DepartmentDropdown}
        jobTitles={positions?.JobTitleDropdown}
        positions={positions?.PositionDropdown}
        reportingToPosition={positions?.ReportingToPositionDropdown}
        isLoading={positions?.isLoading}
      />
    </div>
  );
};

export default PositionsPage;
