import React, { useEffect, useState } from "react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getItemDetails } from "../services/itemService";
import EditItemModal from "../components/modals/EditItemModal";
import DeleteItem from "./DeleteItem";
import SupplierItem from "./SupplierItem";
import ItemTags from "./ItemTags";
import CustomItemLevels from "./CustomItemLevels";
import Ingredients from "./Ingredients";
import { checkIdExists } from "utils/cheackAuth";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


const DetailsItem = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageItems = checkIdExists(retrievedArray, 10);
  const ReadItems = checkIdExists(retrievedArray, 9);

  const { t } = useTranslation();
  const id = useParams();
  const dispatch = useDispatch();
  const [EditItemModalstate, setEditItemModalstate] = useState(false);

  const itemDetailsData = useSelector((state) => state.items);
  const data = itemDetailsData.itemDetails;

  useEffect(() => {
    dispatch(getItemDetails(id.itemId));
  }, [dispatch, id]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if (itemDetailsData.isLoading)
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    );


    if(ManageItems===false && ReadItems===false ){
      return(
        <NotAuthorizedCompo/>
      )
    }

    if(EditItemModalstate===true && ManageItems===false){
      toast2({ description:  t("general.authorization")})
      setEditItemModalstate(false)
    }

  return (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            {data[0]?.InventoryItemNameEng || "-"}
          </Box>
          <GButton
            title={t("pages.items.edit")}
            bg="#54A0FF"
            color="light"
            hover={{ bg: "primary" }}
            onClick={() => setEditItemModalstate(true)}
          />
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        {/* details */}
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory_item_details.name")}
            </Text>
            <Text marginBlock={2}>{data[0]?.InventoryItemNameEng || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory_item_details.name_Localized")}
            </Text>
            <Text marginBlock={2}>{data[0]?.InventoryItemNameAr || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.combos.sku")}
            </Text>
            <Text marginBlock={2}>{data[0]?.InventoryItemRef || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory_item_details.barcode")}
            </Text>
            <Text marginBlock={2}>{data[0]?.Barcode || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory.minimum_level")}
            </Text>
            <Text marginBlock={2}>{data[0]?.MinLevel || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory_item_details.maximum-Level")}
            </Text>
            <Text marginBlock={2}>{data[0]?.MaxLevel || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory.par_level")}
            </Text>
            <Text marginBlock={2}>{data[0]?.ParLevel || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory.storage_unit")}
            </Text>
            <Text marginBlock={2}>{data[0]?.StorageUnit || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory.costing_method")}
            </Text>
            <Text marginBlock={2}>{data[0]?.CostingMethod || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.combos.category")}
            </Text>
            <Text marginBlock={2}>{data[0]?.InventoryCategoryName || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory_item_details.total_cost")}
            </Text>
            <Text marginBlock={2}>{data[0]?.TotalCost || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory.item_cost")}
            </Text>
            <Text marginBlock={2}>{data[0]?.InventoryItemCostPerStorageUnit || "-"}</Text>
          </Box>

        </SimpleGrid>

        <SupplierItem></SupplierItem>

        <ItemTags></ItemTags>

        <Ingredients></Ingredients>

        <CustomItemLevels></CustomItemLevels>

        <DeleteItem id={id.itemId}></DeleteItem>
      </Stack>

      {EditItemModalstate && (
        <EditItemModal
          onClose={() => setEditItemModalstate(false)}
        ></EditItemModal>
      )}
    </>
  );
};

export default DetailsItem;
