import React, { useState } from "react";
import { Box, Flex, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import AddTOItemsModal from "./modals/AddICItemsModal";
import ImportModal from "./modals/ImportModal";
import EditQuantitiesModal from "./modals/EditQuantitiesModal";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 

const InventoryCountItems = ({ subGroup, id ,InventoryCountStatus}) => {
  const { t } = useTranslation();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [showAddItems, setShowAddItems] = useState(false);
  const [showImportItems, setShowImportItems] = useState(false);
  const [showEditQuantities, setShowEditQuantities] = useState(false);

    /* -----------------------------------Privilige-------------------------------*/
    const retrievedArray =useDecryptedPrivilege();

    const ManageInventoryCount = checkIdExists(retrievedArray, 32);
  
    if(showAddItems === true && ManageInventoryCount === false){
      toast2({ description:  t("general.authorization")});
      setShowAddItems(false);
    };
    if(showEditQuantities === true && ManageInventoryCount === false){
      toast2({ description:  t("general.authorization")});
      setShowEditQuantities(false);
    };
  /* -----------------------------------Privilige-------------------------------*/  

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
          <Text color="text" textTransform="capitalize">
            {t("general.items")}
          </Text>

          {  InventoryCountStatus !== "Closed" ?
            <Flex gap={4}>
            {
              subGroup?.data1?.length > 0 ?   
                  <GButton
                  title={t("pages.transfers_orders.edit_quantities")}
                  bg="light"
                  color="text"
                  fontSize="14px"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  hover={{ bg: "light" }}
                  opacity="0.7"
                  onClick={() => setShowEditQuantities(true)}
                />
              : 
              ""
            }


            <GButton
              title={t("pages.purchases.add_items")}
              bg="light"
              color="text"
              fontSize="14px"
              border="1px"
              borderColor={"#CBD5E0"}
              hover={{ bg: "light" }}
              opacity="0.7"
              onClick={() => setShowAddItems(true)}
            />

            {/* <GButton
              title={t("general.import_items")}
              bg="light"
              color="text"
              fontSize="14px"
              border="1px"
              borderColor={"#CBD5E0"}
              hover={{ bg: "light" }}
              opacity="0.7"
              onClick={() => setShowImportItems(true)}
            /> */}
            </Flex>
            :
            ""
          }

      </Flex>

      {subGroup.isLoading ? (
        <Spinner />
      ) : subGroup.data1?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : subGroup.data1?.length > 0 ? (
        <Box
          bg="light"
          overflow="auto"
          borderRadius={4}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Table>
            <Thead>
              <Tr>
                <Th>{t("pages.branches.name")}</Th>
                <Th>{t("pages.inventory.sku")}</Th>
                <Th>{t("pages.call_center.quantity")}</Th>
                <Th>{t("pages.transfers_orders.avail_quantity")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {subGroup.data1.map((el, index) => (
                <Tr key={index}>
                  <Td>{el?.InventoryItemNameEng}</Td>
                  <Td>{el?.SUK}</Td>
                  <Td>{el?.Quantity} Kilo</Td>
                  <Td>{el?.AvalibleQnty} Kilo</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {showAddItems && (
      <AddTOItemsModal
        onClose={() => setShowAddItems(false)}
        id={id} />
      )}

      {showImportItems && (
        <ImportModal
          onClose={() => setShowImportItems(false)}
        />
      )}

      {showEditQuantities && (
        <EditQuantitiesModal
          onClose={() => setShowEditQuantities(false)}
          id={id} />
      )}
    </Box>
  );
};

export default InventoryCountItems;
