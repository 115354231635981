import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { generator } from "utils/generator";
import { getTime } from "services/timeService";
import { createBranch, getBranches } from "../../services/branchesService";
import { getTaxesGroups } from "features/management/taxesGroups/services/taxesGroupsService";

export default function CreateModal ({ onClose }){
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({position: "top",duration: 3000,status: "success",});
  const toast2 = useToast({position: "top",duration: 3000, status: "error",});

  const { handleSubmit, register, control, watch, getValues, setValue,formState: { errors }, trigger } =useForm();
  watch(["BranchCode"]);

  const branches = useSelector((state) => state.branches);
  const taxesGroups = useSelector((state) => state.taxesGroups);
  const time = useSelector((state) => state.time);

  useEffect(() => {
    dispatch(getTime());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTaxesGroups());
  }, [dispatch]);


 
 
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };


  return <>
      <GModal
        onClose={onClose}
      modalHeader={t("pages.branches.create_branch")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
            if(values.TaxGroupId===undefined || values.OpeningFromId===undefined || values.OpeningToId ===undefined || values.InventoryEndofDayId ===undefined || values.BranchCode===undefined){
              toast2({ description: t("pages.DailyRestrictions.searchvalidation")  });
            }else{
               values.BranchId = null;
              values.DisplayAppImage = null;
              values.TaxGroupId = values.TaxGroupId?.TaxGroupId;
              values.OpeningFromId = values.OpeningFromId?.DayTimeId;
              values.OpeningToId = values.OpeningToId?.DayTimeId;
              values.InventoryEndofDayId = values.InventoryEndofDayId?.DayTimeId;
              values.Address = "it is ok";
              //console.log(values)
              //console.log(values);
              dispatch(createBranch(values))
              .then((_) => {
                  toast({ description: t("pages.branches.branch_created") });
                  dispatch(getBranches());
                  onClose();
              });
              // dispatch(createBranch(values)).unwrap().then((_) => {
              //   onClose();
              // });
                // .unwrap()
                // .then((_) => {
                //   toast({ description: t("pages.branches.branch_created") });
                //   onClose();
                // });
            }

            })
          }
          >
            <Stack spacing={6}>
             


            <FormControl isInvalid={errors.BranchName}>
            <FormLabel htmlFor="BranchName" style={{textTransform:"capitalize"}}>{t("pages.branches.name")} </FormLabel>
            <Controller
              name="BranchName"
              control={control}
              defaultValue=""
              rules={{
                required: t("validation.required"),
                minLength: {
                  value: 2,
                  message: t("validation.name_long_2"),
                },
                maxLength: {
                  value: 25,
                  message: t("validation.name_long_25"),
                },
                pattern: {
                  value: /^[\p{L}\s]+$/u,
                  message:t("validation.this_name_invalid") ,
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t("pages.branches.name")}
                  onChange={(e)=>handleInputChange(e, "BranchName")} 
                />
              )}
            />
            <FormErrorMessage>{errors.BranchName && errors.BranchName.message}</FormErrorMessage>
          </FormControl>
          


          <FormControl isInvalid={errors.BranchNameLocalize}>
          <FormLabel htmlFor="BranchNameLocalize" style={{textTransform:"capitalize"}}>{t("pages.branches.name_localized")}</FormLabel>
          <Controller
            name="BranchNameLocalize"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message:t("validation.this_name_invalid") ,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.branches.name_localized")}
                onChange={(e)=>handleInputChange(e, "BranchNameLocalize")} 
                />
            )}
          />
          <FormErrorMessage>{errors.BranchNameLocalize && errors.BranchNameLocalize.message}</FormErrorMessage>
        </FormControl>


              <Flex alignItems="flex-end" gap={4}>
                <Input
                  label={t("pages.branches.branch_code")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.branches.branch_code")}
                  isRequired
                  value={getValues("BranchCode") || ""}
                  readOnly
                />
                <GButton
                  title={t("pages.branches.generate")}
                  height="50px"
                  bg="light"
                  border="1px solid"
                  borderColor="border"
                  hover={{
                    bg: "primary",
                    borderColor: "primary",
                    color: "light",
                  }}
                  onClick={() => setValue("BranchCode", generator({}))}
                />
              </Flex>

              <MultiSelect
                data={
                  taxesGroups.data &&
                  taxesGroups.data.map((el) => ({
                    ...el,
                    label: el.TaxGroupName,
                    value: el.TaxGroupId,
                  }))
                }
                onChange={(e) => setValue("TaxGroupId", e)}
                isMulti={false}
                isRequired
                title={t("pages.branches.tax_group_id")}
              />


              <FormControl isInvalid={errors.BranchTaxRegistName}>
          <FormLabel htmlFor="BranchTaxRegistName" style={{textTransform:"capitalize"}}>{t("pages.branches.branch_tax_registeration_name")}</FormLabel>
          <Controller
            name="BranchTaxRegistName"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              }
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.branches.branch_tax_registeration_name")}
                onChange={(e)=>handleInputChange(e, "BranchTaxRegistName")} 
                />
            )}
          />
          <FormErrorMessage>{errors.BranchTaxRegistName && errors.BranchTaxRegistName.message}</FormErrorMessage>
        </FormControl>

              

        <FormControl isInvalid={errors.BranchTaxNumber}>
                <FormLabel
                  htmlFor="BranchTaxNumber"
                  style={{ textTransform: "capitalize" }}
                >
                {t("pages.branches.branch_tax_number")}
                </FormLabel>
                <Controller
                  name="BranchTaxNumber"
                  control={control}
                  defaultValue="0"
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.branches.branch_tax_number")}
                      onChange={(e) => {
                        handleInputChange(e, "BranchTaxNumber");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.BranchTaxNumber && errors.BranchTaxNumber.message}
                </FormErrorMessage>
              </FormControl>



          

              <MultiSelect
                data={
                  time.data &&
                  time.data.map((el) => ({
                    ...el,
                    label: el.DayTimeName,
                    value: el.DayTimeId,
                  }))
                }
                onChange={(e) => setValue("OpeningFromId", e)}
                isMulti={false}
                isRequired
                title={t("pages.branches.opening_from")}
              />

              <MultiSelect
                data={
                  time.data &&
                  time.data.map((el) => ({
                    ...el,
                    label: el.DayTimeName,
                    value: el.DayTimeId,
                  }))
                }
                onChange={(e) => setValue("OpeningToId", e)}
                isMulti={false}
                isRequired
                title={t("pages.branches.opening_to")}
              />

              <MultiSelect
                data={
                  time.data &&
                  time.data.map((el) => ({
                    ...el,
                    label: el.DayTimeName,
                    value: el.DayTimeId,
                  }))
                }
                onChange={(e) => setValue("InventoryEndofDayId", e)}
                isMulti={false}
                isRequired
                title={t("pages.branches.inventory_end_of_day")}
              />




              <FormControl isInvalid={errors.Phone}>
              <FormLabel
                htmlFor="Phone"
                style={{ textTransform: "capitalize" }}
              >
              {t("pages.branches.phone")}
                            </FormLabel>
              <Controller
                name="Phone"
                control={control}
                defaultValue="0"
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.branches.phone")}
                    onChange={(e) => {
                      handleInputChange(e, "Phone");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.Phone && errors.Phone.message}
              </FormErrorMessage>
            </FormControl>



            <FormControl isInvalid={errors.Latitude}>
            <FormLabel
              htmlFor="Latitude"
              style={{ textTransform: "capitalize" }}
            >
            {t("pages.branches.lat")}                          </FormLabel>
            <Controller
              name="Latitude"
              control={control}
              defaultValue="0"
              rules={{
                required: t("validation.required"),
                validate: (value) => {
                  const intValue = parseInt(value, 10);
                  return (
                    intValue >= 0 || t("validation.must_be_non_negative")
                  );
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t("pages.branches.lat")}
                  onChange={(e) => {
                    handleInputChange(e, "Latitude");
                  }}
                  type="number"
                  min="0"
                />
              )}
            />
            <FormErrorMessage>
              {errors.Latitude && errors.Latitude.message}
            </FormErrorMessage>
          </FormControl>

             

          <FormControl isInvalid={errors.Longitude}>
          <FormLabel
            htmlFor="Longitude"
            style={{ textTransform: "capitalize" }}
          >
          {t("pages.branches.long")}                      </FormLabel>
          <Controller
            name="Longitude"
            control={control}
            defaultValue="0"
            rules={{
              required: t("validation.required"),
              validate: (value) => {
                const intValue = parseInt(value, 10);
                return (
                  intValue >= 0 || t("validation.must_be_non_negative")
                );
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.branches.long")}
                onChange={(e) => {
                  handleInputChange(e, "Longitude");
                }}
                type="number"
                min="0"
              />
            )}
          />
          <FormErrorMessage>
            {errors.Longitude && errors.Longitude.message}
          </FormErrorMessage>
        </FormControl>
              

        <FormControl isInvalid={errors.CommercialRegistrationNo}>
        <FormLabel
          htmlFor="CommercialRegistrationNo"
          style={{ textTransform: "capitalize" }}
        >
        {t("pages.branches.commercial_registeration_no")}                     </FormLabel>
        <Controller
          name="CommercialRegistrationNo"
          control={control}
          defaultValue="0"
          rules={{
            required: t("validation.required"),
            validate: (value) => {
              const intValue = parseInt(value, 10);
              return (
                intValue >= 0 || t("validation.must_be_non_negative")
              );
            },
          }}
          render={({ field }) => (
            <Input
              {...field}
              placeholder={t("pages.branches.commercial_registeration_no")}
              onChange={(e) => {
                handleInputChange(e, "CommercialRegistrationNo");
              }}
              type="number"
              min="0"
            />
          )}
        />
        <FormErrorMessage>
          {errors.CommercialRegistrationNo && errors.CommercialRegistrationNo.message}
        </FormErrorMessage>
      </FormControl>

           
    
      <FormControl isInvalid={errors.ReceiptHeader}>
      <FormLabel htmlFor="ReceiptHeader" style={{textTransform:"capitalize"}}>{t("pages.branches.receipt_header")}</FormLabel>
      <Controller
        name="ReceiptHeader"
        control={control}
        defaultValue=""
        rules={{
          required: t("validation.required"),   
        }}
        render={({ field }) => (
          <Input
            {...field}
            placeholder={t("pages.branches.receipt_header")}
            onChange={(e)=>handleInputChange(e, "ReceiptHeader")} 
            />
        )}
      />
      <FormErrorMessage>{errors.ReceiptHeader && errors.ReceiptHeader.message}</FormErrorMessage>
    </FormControl>

              
    <FormControl isInvalid={errors.ReceiptFooter}>
    <FormLabel htmlFor="ReceiptFooter" style={{textTransform:"capitalize"}}>{t("pages.branches.receipt_footer")}</FormLabel>
    <Controller
      name="ReceiptFooter"
      control={control}
      defaultValue=""
      rules={{
        required: t("validation.required"),   
      }}
      render={({ field }) => (
        <Input
          {...field}
          placeholder={t("pages.branches.receipt_footer")}
          onChange={(e)=>handleInputChange(e, "ReceiptFooter")} 
          />
      )}
    />
    <FormErrorMessage>{errors.ReceiptFooter && errors.ReceiptFooter.message}</FormErrorMessage>
  </FormControl>

              

             

              <Checkbox 
              {...register("ReceiveCCenterAPIOrders")}
              >
                {t("pages.branches.receive_center_api_orders")}
              </Checkbox>



              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  </>
};
