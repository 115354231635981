import { useTranslation } from "react-i18next";
import { Box, Flex, Stack } from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getFilteredData } from "../../services/combosService";
import { useEffect } from "react";
import { getCategories } from "features/products/categories/services/categoriesService";
import Input from "components/form/Input";

const FilterModal = ({ onClose, data }) => {
  const [filterCriteria, setFilterCriteria] = useState({
    ComboName: "",
    ComboNameLoc: "",
    ComboSUK: "",
    ProductCategoriesNameEng: "",
  });
  console.log("tot", data);
  const [filteredData, setFilteredData] = useState(data);

  // const toast = useToast({
  //   position: "top",
  //   duration: 3000,
  //   status: "success",
  // });

  // lsh htt3dl

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleFilterProducts = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const {
        ComboName,
        ComboNameLoc,
        ComboSUK,
        ProductCategoriesNameEng,
      } = filterCriteria;

      console.log("wala", filterCriteria);

      // Create variables to hold filter conditions
      let filterByName = true;
      let filterByProductCategoriesNameEng = true;
      let filterByPrice = true;
      let fileterByTaxGroup = true;

      // Check if the filter criteria are defined, and if so, apply the filter
      if (ComboName) {
        filterByName = item.ComboName.toLowerCase().includes(
          ComboName.toLowerCase()
        );
      }

      if (ComboNameLoc) {
        filterByProductCategoriesNameEng =
          item.ComboNameLoc.toLowerCase().includes(
            ComboNameLoc.toLowerCase()
          );
      }

      if (ComboSUK) {
        filterByPrice = item.ComboSUK.toLowerCase().includes(
          ComboSUK.toLowerCase()
        );
      }

      if (ProductCategoriesNameEng) {
        fileterByTaxGroup = item.ProductCategoriesNameEng.toLowerCase().includes(
          ProductCategoriesNameEng.toLowerCase()
        );
      }

      // Return true if all conditions are met
      return (
        filterByName &&
        filterByProductCategoriesNameEng &&
        filterByPrice &&
        fileterByTaxGroup
      );
    });
    console.log("wala", filteredResult);
    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredData(filteredResult));
  };

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const categories = useSelector((state) => state.categories);

  const handleClose = () => {
    onClose(filteredData);
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.combos.filter_combos")}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleFilterProducts}>
            <Stack spacing={6}>
              <Input
                label={t("pages.gifts_cards.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.name")}
                name="ComboName"
                value={filterCriteria.ComboName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ComboName: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.combos.name_localized")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.combos.name_localized")}
                name="ComboNameLoc"
                value={filterCriteria.ComboNameLoc}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ComboNameLoc: e.target.value,
                  })
                }
              />

              <MultiSelect
                title={t("pages.gifts_cards.category")}
                data={
                  categories.data &&
                  categories.data.map((el) => ({
                    ...el,
                    label: el.ProductCategoriesNameEng,
                    value: el.ProductCategoriesId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.ProductCategoriesNameEng}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ProductCategoriesNameEng: e.ProductCategoriesNameEng,
                  })
                }
              />
{/* 
              <MultiSelect
                title={t("pages.products.tax_group")}
                data={
                  taxesGroups.data &&
                  taxesGroups.data.map((el) => ({
                    ...el,
                    label: el.TaxGroupName,
                    value: el.TaxGroupId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.TaxGroupName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    TaxGroupName: e.TaxGroupName,
                  })
                }
              /> */}

              <Input
                type="number"
                label={t("pages.combos.sku")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.combos.sku")}
                name="ComboSUK"
                value={filterCriteria.ComboSUK}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ComboSUK: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={handleClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default FilterModal;
