import React, { useEffect, useState } from 'react';
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Button, Flex, Text, Spinner, Menu, MenuButton, MenuList, MenuItem, Box } from "@chakra-ui/react"
import * as FiIcons from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import GButton from "../../../../components/ui/button/Button";
import CreateKitchenFlowModal from '../components/modal/CreateKitchenFlowModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllKitchenFlow } from '../services/kitchenFlowService';
import KitchenFlowTable from '../components/KitchenFlowTable';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';

const KitchenFlow = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 55);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [showModal,setShowModal]=useState(false);

    const data = useSelector(state => state.kitchenFlow);

    useEffect(()=>{
      dispatch(getAllKitchenFlow());
    },[dispatch]);


    if(ManageBranches===false  ){
      return(
        <NotAuthorizedCompo/>
      )
    }

  return <>
    <BreadCrumb>
    <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
      <Text
        fontSize="1.2rem" color="dark" textTransform="capitalize"
      >
        {t("pages.kitchenFlow.kitchenFlow")}
      </Text>

      <Flex gap={2} flexWrap="wrap">
        

        <GButton
          title={t("pages.kitchenFlow.create_kitchen_flow")}
          onClick={() => setShowModal(true)}
          bg="primary"
          color="light"
          border="1px solid transparent"
          hover={{ bg: "none", borderColor: "primary", color: "primary" }}
        />

      </Flex>
    </Flex>
  </BreadCrumb>




 {
  data.isLoading?(
    <Flex bg="light" p={4} justifyContent="center">
      <Spinner />
    </Flex>
  ):(
    <KitchenFlowTable data={data.data}></KitchenFlowTable>
  )
}


  {
    showModal&&(
        <CreateKitchenFlowModal onClose={() => setShowModal(false)}></CreateKitchenFlowModal>
    )
  }
    
    
    </>
};

export default KitchenFlow;
