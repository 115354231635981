import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button"
import CustomPriceModal from './modals/CustomPriceModal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomPriceModifierOptionById } from '../services/modifiersOptionsCustomService';
import CustomPriceTable from './CustomPriceTable';
//import { getCustomPriceModifierOptionById } from '../services/modifiersOptionsService';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';


const CustomPrice = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

    // {showModal&&(
//     <CustomPriceModal onClose={() => setShowModal(false)} />
// )}
const { t } = useTranslation();



// const id = useParams()
// //console.log(id.comboId)
// const dispatch= useDispatch()


// useEffect(()=>{
//   dispatch(getCombosSize(id.comboId))
// },[dispatch,id])

const dispatch=useDispatch()

var id = useParams()

//id = id.modifiersOptionId
//console.log(id)

useEffect(()=>{
dispatch(getCustomPriceModifierOptionById(id.modifiersOptionId))
},[dispatch,id])


const modifiersOptions = useSelector(state => state.modifiersOptionscustom);

//console.log(modifiersOptions.data)

const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


const [showModal,setshowModal]=useState(false)

if(showModal && ManageMenu===false){
  toast2({ description:  t("general.authorization")})
  setshowModal(false)
}

  return (
    <Box>
    <Flex
    alignItems="center" justifyContent="space-between"
    gap={2} flexWrap="wrap" mb={6}
  >
    <Text color="text" textTransform="capitalize">
      {t("pages.combos.custom_price")}
    </Text>
    <GButton
      title={t("pages.combos.select_branches")}
      bg="light"
      color="text"
      fontSize="14px"
      border="1px solid"
      borderColor="border"
      hover={{ bg: "light" }}
      onClick={() => setshowModal( true)}
    />
  </Flex>








{showModal&&(
    <CustomPriceModal onClose={() => setshowModal(false)} />
)}



{modifiersOptions.isLoading ? (
  <Spinner />
) : modifiersOptions.data?.length === 0 ? (
  <Flex
    p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  >
    {t("pages.branches.no_data")}
  </Flex>
) : modifiersOptions.data?.length > 0 ? (
  <CustomPriceTable
  data={modifiersOptions.data}
  />
):(
  <Flex
  p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
  boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
>
  {t("pages.branches.no_data")}
</Flex>
)
}





    </Box>
  );
}

export default CustomPrice;
