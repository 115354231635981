import { createSlice } from "@reduxjs/toolkit";
import { getDashbordBranches, getDashbordInventory,GetGeneralDashBoard } from "../services/dashbordChartsServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,
  dataInventory: [],
  dataBranches: [],
  Generaldata:[],
  // Filter data
  filteredData : []
};

const dashbordChartsSlice = createSlice({
  name: "dashbordCharts",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------------- Get Dashbord Inventory List -------------------------------------------- */
    builder.addCase(getDashbordInventory.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDashbordInventory.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.dataInventory = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getDashbordInventory.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  /* -------------------------------------------- Get Dashbord Branches List -------------------------------------------- */
    builder.addCase(getDashbordBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDashbordBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.dataBranches = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getDashbordBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

      /* -------------------------------------------- Get Dashbord Branches List -------------------------------------------- */

      builder.addCase(GetGeneralDashBoard.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(GetGeneralDashBoard.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.Generaldata = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      })
      builder.addCase(GetGeneralDashBoard.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });

  }
});

export default dashbordChartsSlice.reducer;