import { createSlice } from "@reduxjs/toolkit";
import {
  addNewBounces,
  addNewPermessionType,
  addNewSettingCities,
  addNewSettingNationality,
  addNewSettingReligion,
  addNewVacationType,
  deleteBounces,
  deletePermissionType,
  deleteSettingCities,
  deleteSettingNationality,
  deleteSettingReligion,
  deleteVacationType,
  editNewBounces,
  editPermissionType,
  editSettingCities,
  editSettingNationality,
  editSettingReligion,
  editVacationType,
  getBouncesList,
  getHrCitiesList,
  getHrNationalityList,
  getHrReligionsList,
  getPermessionTypeList,
  getVacationTypeList,
} from "../services/hrSettingServices";
let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  religionsList: [],
  nationalityList: [],
  citiesList: [],
  permessionTypeList: [],
  VacationtypeSettingList: [],
  BouncesSettingList: [],

  // Filter data
  filteredData: [],
};

const hrSettingSlice = createSlice({
  name: "hrSetting",
  initialState,
  extraReducers: (builder) => {
    /* -------------------  Get Hr Religions List ---------------------*/
    builder.addCase(getHrReligionsList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getHrReligionsList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.religionsList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getHrReligionsList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Add New Setting Religion ---------------------*/
    builder.addCase(addNewSettingReligion.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewSettingReligion.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //   state.religionsList =
      //     (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(addNewSettingReligion.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Edit Setting Religion ---------------------*/
    builder.addCase(editSettingReligion.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editSettingReligion.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //   state.religionsList =
      //     (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(editSettingReligion.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Delete Setting Religion ---------------------*/
    builder.addCase(deleteSettingReligion.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteSettingReligion.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //   state.religionsList =
      //     (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(deleteSettingReligion.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Hr Nationality List ---------------------*/

    builder.addCase(getHrNationalityList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getHrNationalityList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.nationalityList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getHrNationalityList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Add New Nationality  ---------------------*/

    builder.addCase(addNewSettingNationality.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      addNewSettingNationality.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        // state.nationalityList =
        //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(addNewSettingNationality.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Edit New Nationality  ---------------------*/

    builder.addCase(editSettingNationality.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editSettingNationality.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.nationalityList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(editSettingNationality.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Delete Seting Nationality  ---------------------*/

    builder.addCase(deleteSettingNationality.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      deleteSettingNationality.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        // state.nationalityList =
        //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(deleteSettingNationality.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Hr Cities List ---------------------*/

    builder.addCase(getHrCitiesList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getHrCitiesList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.citiesList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getHrCitiesList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Add New Setting Cities  ---------------------*/

    builder.addCase(addNewSettingCities.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewSettingCities.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.nationalityList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(addNewSettingCities.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Edit  Setting Cities   ---------------------*/

    builder.addCase(editSettingCities.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editSettingCities.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.nationalityList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(editSettingCities.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Delete Setting Cities  ---------------------*/

    builder.addCase(deleteSettingCities.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteSettingCities.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.nationalityList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(deleteSettingCities.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Permession Type List ---------------------*/
    builder.addCase(getPermessionTypeList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPermessionTypeList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.permessionTypeList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getPermessionTypeList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* -------------------  Add New Permession Type  ---------------------*/
    builder.addCase(addNewPermessionType.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewPermessionType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.nationalityList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(addNewPermessionType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Delete Permession Type  ---------------------*/
    builder.addCase(deletePermissionType.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deletePermissionType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.nationalityList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(deletePermissionType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Edit Permession Type  ---------------------*/
    builder.addCase(editPermissionType.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editPermissionType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.nationalityList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(editPermissionType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Vacation Type List ---------------------*/
    builder.addCase(getVacationTypeList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getVacationTypeList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.VacationtypeSettingList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getVacationTypeList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Add New Vacation Type  ---------------------*/
    builder.addCase(addNewVacationType.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewVacationType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.VacationtypeSettingList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(addNewVacationType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Edit Vacatin Type  ---------------------*/
    builder.addCase(editVacationType.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editVacationType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.VacationtypeSettingList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(editVacationType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Delete Vacatin Type  ---------------------*/
    builder.addCase(deleteVacationType.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteVacationType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.VacationtypeSettingList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(deleteVacationType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Get Bounces List  ---------------------*/
    builder.addCase(getBouncesList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getBouncesList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.BouncesSettingList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getBouncesList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Add New Bounces  ---------------------*/
    builder.addCase(addNewBounces.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewBounces.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.BouncesSettingList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(addNewBounces.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Edit Bounces  ---------------------*/
    builder.addCase(editNewBounces.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editNewBounces.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.BouncesSettingList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(editNewBounces.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Delete Bounces ---------------------*/
    builder.addCase(deleteBounces.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteBounces.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.BouncesSettingList =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(deleteBounces.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default hrSettingSlice.reducer;
