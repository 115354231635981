import { createSlice } from "@reduxjs/toolkit";

import {getAllSuppliers,createSupplier,getSupplierDetails,deleteSupllierInventory,getDeletedSuppliers,editSupplierData, resetSuppliersFilteredData, getSuppliersFilteredData} from "../services/inventorySupplierService"



let initialState = {
    isLoading: false,
    data: [],
    SupplierDetails:[],
    pages: 0,
    errors: null,
    filteredData : []
  };


  const inventorySupplierSlice = createSlice({


    name: "inventorySuppliers",
    initialState,

    extraReducers:(builder)=>{


        builder.addCase(getAllSuppliers.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getAllSuppliers.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.data)
            state.errors = null;
          })
    
          builder.addCase(getAllSuppliers.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          builder.addCase(createSupplier.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(createSupplier.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //console.log(payload)
            //state.data.push(payload);
            state.errors = null;
          })
          builder.addCase(createSupplier.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //

          
      builder.addCase(getSupplierDetails.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getSupplierDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.SupplierDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.SupplierDetails)
        state.errors = null;
      })

      builder.addCase(getSupplierDetails.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //


      builder.addCase(deleteSupllierInventory.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(deleteSupllierInventory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(deleteSupllierInventory.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //

      builder.addCase(getDeletedSuppliers.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getDeletedSuppliers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.DeletedSuppliers = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.DeletedSuppliers)
        state.errors = null;
      })

      builder.addCase(getDeletedSuppliers.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //
      
      builder.addCase(editSupplierData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(editSupplierData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(editSupplierData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
   /* -------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
   builder.addCase(getSuppliersFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getSuppliersFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getSuppliersFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetSuppliersFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetSuppliersFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetSuppliersFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });

  }
  })

  export default inventorySupplierSlice.reducer