import { createSlice } from "@reduxjs/toolkit";
import {
  getProducts,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,
  activateProducts,
  getFilteredData,
  resetFilteredData,
} from "../services/productsService";

let initialState = {
  isLoading: false,
  data: [],
  productDetails: null,
  pages: 0,
  errors: null,
  branchesOfProduct: [],
  filteredData:[],
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: (builder) => {

    /* ----------------------------------------- Get Products List --------------------------------------- */
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
state.data = ((payload[0] && JSON.parse(payload[0].jsonData)) || []).reverse();
      state.errors = null;
    });
    builder.addCase(getProducts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ----------------------------------------- Get  Product By Id (Details) --------------------------------------- */
    builder.addCase(getProductById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getProductById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.productDetails =(payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    });
    builder.addCase(getProductById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ----------------------------------------- Create New Products --------------------------------------- */
    builder.addCase(createProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ----------------------------------------- Update Products --------------------------------------- */
    builder.addCase(updateProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(updateProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex((product) => product.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    });
    builder.addCase(updateProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -----------------------------------------  Deleted Products --------------------------------------- */
    builder.addCase(deleteProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter((product) => product.id !== payload.id);
      state.errors = null;
    });
    builder.addCase(deleteProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -----------------------------------------  Products --------------------------------------- */

    builder.addCase(activateProducts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(activateProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.data = state.data.filter(modifiersOption => modifiersOption.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(activateProducts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
    builder.addCase(getFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      console.log("youssef" , state.filteredData);
      state.errors = null;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default productsSlice.reducer;
