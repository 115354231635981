import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";
/* ------------------------------------------------------- create categories Combos  -------------------------------------------------------------------*/
export const createTimedEventCategories = createAsyncThunk(
  "timedEventsCategories/createTimedEventCategories",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/AddTimedEventCategories?timeeventid=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Get categories Timed Events List  -------------------------------------------------------------------*/
export const getTimedEventCategories = createAsyncThunk(
  "timedEventsCategories/getTimedEventCategories",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetTimedEventCategories?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete Category In Timed Event  -------------------------------------------------------------------*/
export const deleteCategoryInTimedEvent = createAsyncThunk(
  "timedEventsCategories/deleteCategoryInTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DeleteTimedEventCategory?timeeventcategoryid={"TimedEventCategoryId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Get Tags In categories Tags ---------------------------------------------------*/
export const getTagsInCategoriesTags = createAsyncThunk(
  "timedEventsCategories/getTagsInCategoriesTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/LookupTimedEventCategories?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
