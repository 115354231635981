import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import {Button, Flex, Text, Spinner, Menu, MenuButton, MenuList, MenuItem, useToast} from "@chakra-ui/react";
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import { getGroups, resetFilteredData } from "../services/groupsService";
import NoData from "components/ui/noData/NoData";
import ExportModal from "components/ui/modal/ExportModal";
import { IoCloseOutline } from "react-icons/io5";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


const Groups = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const groups = useSelector((state) => state.groups);

  const [showCreateGroupModal, setShowCreateGroupModal] = useState(null);
  const [showUpdateGroupModal, setShowUpdateGroupModal] = useState(null);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(null);
  const [showFilterGroupsModal, setShowFilterGroupsModal] = useState(null);
  const [exportModal, SetExportModal] = useState(false);

  useEffect(() => {
    dispatch(getGroups());
  }, [dispatch]);

  const resetFunction = () => {
    dispatch(resetFilteredData());
  };


  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  if(showCreateGroupModal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowCreateGroupModal(false)
  }

  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.groups.groups")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
            >
              <div onClick={() => SetExportModal(true)}>
                {t("general.export")}
              </div>
            </Button>

            <GButton
              title={t("pages.groups.create_group")}
              onClick={() => setShowCreateGroupModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom="2px solid"
            borderColor="primary"
          />
        </Flex>

        <GButton
          title={
            groups.filteredData.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
            groups.filteredData.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          onClick={
            groups.filteredData.length === 0
              ? () => setShowFilterGroupsModal(true)
              : resetFunction
          }
        />
      </Flex>

      {groups.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : groups.data.length === 0 ? (
        <NoData
          btnTitle={t("pages.groups.create_group")}
          title={t("pages.groups.no_data_hint")}
          onClickCreateBtn={() => setShowCreateGroupModal(true)}
          onClickCreateGroupBtn={() => setShowCreateGroupModal(true)}
        />
      ) : (
        <Table
          groups={groups.data}
          handleUpdate={(group) => setShowUpdateGroupModal(group)}
          handleDelete={(group) => setShowDeleteGroupModal(group)}
        />
      )}

      {showCreateGroupModal && (
        <CreateModal onClose={() => setShowCreateGroupModal(null)} />
      )}

      {showUpdateGroupModal && (
        <UpdateModal
          data={showUpdateGroupModal}
          onClose={() => setShowUpdateGroupModal(null)}
        />
      )}

      {showDeleteGroupModal && (
        <DeleteModal
          data={showDeleteGroupModal}
          onClose={() => setShowDeleteGroupModal(null)}
        />
      )}

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={groups.data}
          fileName={"Groups"}
        ></ExportModal>
      )}

      {showFilterGroupsModal && (
        <FilterModal
          data={groups.data}
          onClose={() => setShowFilterGroupsModal(null)}
        />
      )}
    </>
  );
};

export default Groups;
