import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

    /* --------------------------------------------------Get Tags Of Product---------------------------------------------------*/
export const getTagsOfProduct = createAsyncThunk("addingTags/getTagsOfProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/ProductTagListById?productid={"ProductId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* --------------------------------------------------Get Tags In Product---------------------------------------------------*/
export const getTagsInProduct = createAsyncThunk("addingTags/getTagsInProduct", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/LookupTagProduct`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* --------------------------------------------------Add Tags To Product---------------------------------------------------*/
export const assigenTagsToProduct = createAsyncThunk("addingTags/assigenTagsToProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/ProductTag/AddProductTag?producttag=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
