import React from 'react';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr ,Checkbox} from "@chakra-ui/react"
import GButton from "components/ui/button/Button";
import * as FiIcons from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const StationTable = ({data}) => {

    //console.log(data)
    const { t } = useTranslation();


  return (
    <Box
    bg="light" overflow="auto"
    borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >

      
      <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.categories.name")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((el, index) => (
          <Tr key={el.KitchenFlowsStationsId}  style={{cursor:"pointer"}}>
            <Td>
              <form>
                <Checkbox  >
                </Checkbox>
              </form>
            </Td>
            <Td >{el.StationsName}</Td>
            <Td>
            
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
    
  </Box>
  );
}

export default StationTable;
