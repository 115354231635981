import { createSlice } from "@reduxjs/toolkit";
import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  getDeleted,
  restoreCategory,
  getFilteredData,
  resetFilteredData
} from "../services/categoriesService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  deletedcategorie : [],
  filteredData:[]
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCategories.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)).reverse() || [];
      state.errors = null;
    })
    builder.addCase(getCategories.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createCategory.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createCategory.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createCategory.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateCategory.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateCategory.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(category => category.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateCategory.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteCategory.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteCategory.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(category => category.id !== payload);
      state.errors = null;
    })
    builder.addCase(deleteCategory.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(restoreCategory.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(restoreCategory.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(category => category.id !== payload);
      state.errors = null;
    })
    builder.addCase(restoreCategory.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  

    //

    builder.addCase(getDeleted.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDeleted.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.item = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getDeleted.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      console.log("youssef" , state.filteredData);
      state.errors = null;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  }
});

export default categoriesSlice.reducer;