import React, { useState } from 'react';
import { Box, Collapse, Icon, List, ListItem, Text } from '@chakra-ui/react';
import {  Button, Flex, Spinner,SimpleGrid,Checkbox,Input as ChInput, Radio, RadioGroup, Stack } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import {GiTakeMyMoney} from "react-icons/gi"
import { useForm } from 'react-hook-form';
import Input from "components/form/Input"
import { Controller } from 'react-hook-form';
import MultiSelect from "components/ui/multiSelect/MultiSelect"

const BankAccountsPage = () => {
    const { t,i18n } = useTranslation();
    const { handleSubmit, control, getValues, watch, setValue, register } = useForm();
    const data=[]

    const [selectedValue, setSelectedValue] = useState("all");

    const handleRadioChange = (value) => {
      setSelectedValue(value);
      //console.log(selectedValue)
    };

  return (
   <>
   
   
   <Box
   bg="light" marginInline={-6} marginTop={-6} padding={6}
   borderTop="1px solid" borderColor="border" marginBottom={6}
   >
   <Flex alignItems="center" justifyContent="space-between" gap={4}>
   <Text
   fontSize="1.2rem" color="dark" textTransform="capitalize"
     >
     {t("pages.bankingAccount.bankingAccount")}
     </Text>
  
         </Flex>
         </Box>

  

         <Box
         bg="light" marginInline={-6}  padding={6}
         border="2px solid" borderColor="#00008b" marginBottom={-6}
         borderRadius="15px"
   >
   <Flex alignItems="center" justifyContent="space-between" gap={4}>
   <Text
   style={{display:"flex",alignItems:"center",gap:"10px"}}
   fontSize="1.2rem" color="dark" textTransform="capitalize"
     >
     {t("pages.bankingAccount.DataRecording")}
     </Text>
  
         </Flex>
         </Box>


         <Box   bg="light" marginInline={-6} marginTop={6} padding={6}
         borderTop="1px solid" borderColor="border" style={{display:"flex",alignItems:"center",justifyContent:"space-around"}} >
         
         <SimpleGrid
         spacing={6}
         columns={{ base: 1, md: 2 }}
         bg="white"
         p={4} borderRadius={6}
         boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
         style={{width:"100%"}}
       >
       
       <Flex style={{flexDirection:"column"}}>
          
       <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
   
       <Input
       label={t("pages.bankingAccount.type")}
       labelStyle={{ textTransform: "capitalize" }}
       placeholder={t("pages.bankingAccount.type")}
       name="amount"
       isFormController
       isRequired
       control={control}
     />
   
       </div>
   
       <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
   
       <Input
       label={t("pages.bankingAccount.account_name")}
       labelStyle={{ textTransform: "capitalize" }}
       placeholder={t("pages.bankingAccount.account_name")}
       name="amount"
       isFormController
       isRequired
       control={control}
     />
   
       </div>
   
   
       <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
       
       
       <MultiSelect
       title={t("pages.bankingAccount.currency")}
       data={data.Accounts?.map(el => ({
           ...el,
           label: el.AccountNumber,
           value: el.AccountNumber
       }))}
       isMulti={false}
       isRequired={true}
       onChange={(e) => setValue("AccountNumber", e)}
       //isDisabled={selectedValue === "all"}
   
       />
       
       </div>
    
       
   
       <div style={{display:"flex",flexDirection:"column" ,marginTop:"10px",gap:"10px"}}>
    
       <label style={{marginLeft:"3px" ,marginRight:"3px"}}>
       {t("pages.Expenses.description")}
       </label>
       <textarea style={{width:"80%",border:"2px solid black",borderRadius:"10px",}}  control={control}></textarea>
       
       </div>
   
   </Flex>



   <Flex style={{flexDirection:"column"}}>
          
   <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>

   <Input
   label={t("pages.bankingAccount.name")}
   labelStyle={{ textTransform: "capitalize" }}
   placeholder={t("pages.bankingAccount.name")}
   name="amount"
   isFormController
   isRequired
   control={control}
 />

   </div>

   <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>

   <Input
   label={t("pages.bankingAccount.numberBankAccount")}
   labelStyle={{ textTransform: "capitalize" }}
   placeholder={t("pages.bankingAccount.numberBankAccount")}
   name="amount"
   isFormController
   isRequired
   control={control}
 />

   </div>


   <Box   bg="light" marginInline={-6} marginTop={5} padding={6}
   borderTop="1px solid" borderColor="border" style={{display:"flex",alignItems:"center",justifyContent:"space-around"}} >
   
   <div>
   {t("pages.bankingAccount.state")}
   </div>
   
   <Stack spacing={4}>
   <RadioGroup onChange={handleRadioChange} value={selectedValue}  >
     <Radio value="all">{t("pages.bankingAccount.active")}</Radio>
     <Radio value="selected">{t("pages.bankingAccount.inactive")}</Radio>
   </RadioGroup>
 </Stack>

   
 </Box>

   

   


</Flex>
       
       
       </SimpleGrid>
         </Box>


         <Box
         bg="light" marginInline={-6}  padding={6}
         border="2px solid" borderColor="#00008b" marginBottom={-6}
         borderRadius="15px"
   >
   <Flex alignItems="center" justifyContent="space-between" gap={4}>
   <Text
   style={{display:"flex",alignItems:"center",gap:"10px"}}
   fontSize="1.2rem" color="dark" textTransform="capitalize"
     >
     {t("pages.bankingAccount.Privilege")}
     </Text>
  
         </Flex>
         </Box>
   



         
         <Box   bg="light" marginInline={-6} marginTop={6} padding={6}
         borderTop="1px solid" borderColor="border" style={{display:"flex",alignItems:"center",justifyContent:"space-around"}} >
         

         <SimpleGrid
         spacing={6}
         columns={{ base: 1, md: 2 }}
         bg="white"
         p={4} borderRadius={6}
         boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
         style={{width:"100%"}}
       >
       
       
       <Flex style={{flexDirection:"column"}}>
          
  
   
       <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
       
       
       <MultiSelect
       title={t("pages.bankingAccount.Deposit")}
       data={data.Accounts?.map(el => ({
           ...el,
           label: el.AccountNumber,
           value: el.AccountNumber
       }))}
       isMulti={false}
       isRequired={true}
       onChange={(e) => setValue("AccountNumber", e)}
       //isDisabled={selectedValue === "all"}
   
       />
       
       </div>
   
   
   </Flex>



   <Flex style={{flexDirection:"column"}}>
          
  
   
   <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
       
       
   <MultiSelect
   title={t("pages.bankingAccount.withdraw")}
   data={data.Accounts?.map(el => ({
       ...el,
       label: el.AccountNumber,
       value: el.AccountNumber
   }))}
   isMulti={false}
   isRequired={true}
   onChange={(e) => setValue("AccountNumber", e)}
   //isDisabled={selectedValue === "all"}

   />
   
   </div>


</Flex>
       
       </SimpleGrid>
         
       
       

       <Button
       type="button" bg="primary" color="light"
       textTransform="capitalize" fontSize="1rem"
       //onClick={() => setShowModal(true)}
       >
       <Flex alignItems="center" gap={1} >
       {t("pages.Expenses.save")}
       </Flex>
       </Button>


         </Box>
   </>
  );
}

export default BankAccountsPage;
