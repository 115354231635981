
import React, { useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr,} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Pagination from "components/ui/pagination/Pagination";
import Restore from "./modals/Restore";
import { MdSettingsBackupRestore } from "react-icons/md";


export default function DeletedItemsTable({data}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showModel, setShowModel] = useState(false);
    const [id, setId] = useState(null);

    const clickedbuttom = (id) => {
      setShowModel(true);
      setId(id);
    };

    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
        return data?.slice(firstIndex, lastIndex);
      };
    
    // Calculate records for activeDiscounts
    const deletedItemsData = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
 /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <>
    <Table>
        <Thead>
            <Tr>
              <Th>#</Th>
              <Th>{t("pages.categories.name")}</Th>
              <Th>Sku</Th>
              <Th>{t("pages.categories.categories")}</Th>
              <Th>{t("general.action")}</Th>
            </Tr>
        </Thead>
        <Tbody>
            {
            deletedItemsData?.map((item, index) => (
                <Tr key={index} >
                      <Td>{index+1}</Td>  
                      <Td>
                        <Text
                          display={"inline-block"}
                          backgroundColor={"#FC8181"}
                          borderRadius={"5px"}
                          padding={"2px"}
                        >
                          Deleted
                        </Text>
                          {" " + item?.InventoryItemNameEng}
                      </Td>
                      <Td>{item?.InventoryItemRef}</Td>
                      <Td>{item?.InventoryCategoryName}</Td>
                      <Td className="activeIcon">
                        <Flex>
                          <Box
                            color="#C53030"
                            _hover={{ color: "#38A169" }}
                            p={2}
                            width={"fit-content"}
                            cursor="pointer"
                            onClick={() => clickedbuttom(item.InventoryItemId)}
                          >
                            <MdSettingsBackupRestore size={36} />
                          </Box>
                        </Flex>
                      </Td>
                </Tr>
            ))
            }
        </Tbody>
    </Table>
    <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </>
    {showModel && <Restore id={id} onClose={() => setShowModel(false)} />}
  </>
};
