import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import CreateQuantityAdjasmentModal from '../components/modal/CreateQuantityAdjasmentModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllQuantityAdjusment } from '../services/quantityAdjustmentService';
import QuantityAdjustmentTable from '../components/QuantityAdjustmentTable';
import ExportModal from 'components/ui/modal/ExportModal';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 

const QuantityAdjustmentPage = () => {
    const { t } = useTranslation();
    const dispatch=useDispatch()
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const [showModal,setShowModal]=useState(false)
    const [exportModal,SetExportModal]=useState(false);

    const data = useSelector(state => state.QuantityAdjustment);

    useEffect(()=>{
      dispatch(getAllQuantityAdjusment());
    },[dispatch])



/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageQuantityAdjustments = checkIdExists(retrievedArray, 27);

if(showModal===true && ManageQuantityAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowModal(false);
};
/* -----------------------------------Privilige-------------------------------*/

  return (
    <div>
    <Box
    bg="light" marginInline={-6} marginTop={-6} padding={6}
    borderTop="1px solid" borderColor="border" marginBottom={6}
  >
    <Flex alignItems="center" justifyContent="space-between" gap={4}>
      <Text
        fontSize="1.2rem" color="dark" textTransform="capitalize"
      >
        {t("pages.quantity_adjustment.quantity_adjustment")}
      </Text>

      <Flex gap={2}>
       <Button
              type="button" bg="none" border="1px solid" borderColor="border"
              textTransform="capitalize" fontSize="1rem" color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
                <Text>{t("general.export")}</Text>
            </Button>
        <Button
          type="button" bg="primary" color="light"
          textTransform="capitalize" fontSize="1rem"
          onClick={()=>setShowModal(true)}
        >
          <Flex alignItems="center" gap={1} >
            {t("pages.quantity_adjustment.new_quantity_adjustment")}
          </Flex>
        </Button>
      </Flex>
    </Flex>
  </Box>



  {
    data.isLoading?(
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    ):(
      <QuantityAdjustmentTable filteredData={data.filteredData} data={data.data} ></QuantityAdjustmentTable>
    )
  }


  

  {
    showModal&&(
        <CreateQuantityAdjasmentModal onClose={() => setShowModal(false)}></CreateQuantityAdjasmentModal>
    )
  }
 
 {exportModal&&(
          <ExportModal 
            onClose={() => SetExportModal(false)}   
            data={data.data}
            fileName={"quantityAdjustment"}
            >
          </ExportModal>
  )}
    </div>
  );
}

export default QuantityAdjustmentPage;
