import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
  Input,
  Text,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { Controller, useForm } from "react-hook-form";
import {
  addNewEmployeeBasicInfo,
  getEmployeeList,
} from "features/hr/employees/services/employeeServices";

export default function AddBasicInfoCompo({ setshowCompoNumber }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm();
  const { classifications } = useSelector((state) => state.vacationSlice);
  const {
    MartialStatusDropDown,
    ReligionDropDown,
    nationalityDropDown,
    RecruitmentSituationDropDown,
  } = useSelector((state) => state?.employeeSlice);

  const [genderValue, setGenderValue] = useState("1");
  /*-------------------------------------------- Form Handeling ------------------------------------------------*/
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
      setError("ParLevel", {
        type: "manual",
        message: t("validation.must_be_within_min_max_range"),
      });
    } else {
      clearErrors("ParLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };
  /*-------------------------------------------- Return ------------------------------------------------*/
  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.HiringNewEmployeeRequestId = null;
          values.BirthDate = `${values.BirthDate} 00:00:00`;
          values.UserId = localStorage.getItem("userId");
          values.EmpClassificationId =
            values.EmpClassificationId?.EmployeeClassificationId;
          values.FamilyStatusId = values.FamilyStatusId?.FamilyStatusId;
          values.NationalityId = values.NationalityId?.NationalityId;
          values.RecruitmentSituationId =
            values.RecruitmentSituationId?.RecruitmentSituationId;
          values.ReligionId = values.ReligionId?.ReligionId;
          values.Gendar = genderValue;

          if (
            values.EmpClassificationId === undefined ||
            values.FamilyStatusId === undefined ||
            values.NationalityId === undefined ||
            values.ReligionId === undefined ||
            values.RecruitmentSituationId === undefined
          ) {
            toast2({ description: t("validation.choose_first") });
          } else {
            dispatch(addNewEmployeeBasicInfo(values))
              .unwrap()
              .then((res) => {
                toast({ description: t("pages.hr.new_employee_created") });
                dispatch(getEmployeeList());
                setshowCompoNumber(2);
              });
          }
        })}
      >
        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Text
            color="dark"
            textTransform="capitalize"
            fontSize="xl"
            fontWeight={"bold"}
          >
            {t("pages.hr.basic_information")}
          </Text>
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <FormControl isInvalid={errors.FirstNameAr}>
              <FormLabel
                htmlFor="FirstNameAr"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.FirstNameAr")}
              </FormLabel>
              <Controller
                name="FirstNameAr"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\u0621-\u064A\u0660-\u0669 ]+$/,
                    message: t(
                      "pages.hr.hr_validation.mustContainArabicLetter"
                    ),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.FirstNameAr")}
                    onChange={(e) => {
                      handleInputChange(e, "FirstNameAr");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.FirstNameAr && errors.FirstNameAr.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.SecondNameAr}>
              <FormLabel
                htmlFor="SecondNameAr"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.SecondNameAr")}
              </FormLabel>
              <Controller
                name="SecondNameAr"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\u0621-\u064A\u0660-\u0669 ]+$/,
                    message: t(
                      "pages.hr.hr_validation.mustContainArabicLetter"
                    ),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.SecondNameAr")}
                    onChange={(e) => {
                      handleInputChange(e, "SecondNameAr");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.SecondNameAr && errors.SecondNameAr.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.ThirdNameAr}>
              <FormLabel
                htmlFor="ThirdNameAr"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.ThirdNameAr")}
              </FormLabel>
              <Controller
                name="ThirdNameAr"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\u0621-\u064A\u0660-\u0669 ]+$/,
                    message: t(
                      "pages.hr.hr_validation.mustContainArabicLetter"
                    ),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.ThirdNameAr")}
                    onChange={(e) => {
                      handleInputChange(e, "ThirdNameAr");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.ThirdNameAr && errors.ThirdNameAr.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.FourthNameAr}>
              <FormLabel
                htmlFor="FourthNameAr"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.FourthNameAr")}
              </FormLabel>
              <Controller
                name="FourthNameAr"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\u0621-\u064A\u0660-\u0669 ]+$/,
                    message: t(
                      "pages.hr.hr_validation.mustContainArabicLetter"
                    ),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.FourthNameAr")}
                    onChange={(e) => {
                      handleInputChange(e, "FourthNameAr");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.FourthNameAr && errors.FourthNameAr.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.FirstNameEn}>
              <FormLabel
                htmlFor="FirstNameEn"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.FirstNameEn")}
              </FormLabel>
              <Controller
                name="FirstNameEn"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.FirstNameEn")}
                    onChange={(e) => {
                      handleInputChange(e, "FirstNameEn");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.FirstNameEn && errors.FirstNameEn.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.SecondNameEn}>
              <FormLabel
                htmlFor="SecondNameEn"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.SecondNameEn")}
              </FormLabel>
              <Controller
                name="SecondNameEn"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.SecondNameEn")}
                    onChange={(e) => {
                      handleInputChange(e, "SecondNameEn");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.SecondNameEn && errors.SecondNameEn.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.ThirdNameEn}>
              <FormLabel
                htmlFor="ThirdNameEn"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.ThirdNameEn")}
              </FormLabel>
              <Controller
                name="ThirdNameEn"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.ThirdNameEn")}
                    onChange={(e) => {
                      handleInputChange(e, "ThirdNameEn");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.ThirdNameEn && errors.ThirdNameEn.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.FourthNameEn}>
              <FormLabel
                htmlFor="FourthNameEn"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.FourthNameEn")}
              </FormLabel>
              <Controller
                name="FourthNameEn"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.FourthNameEn")}
                    onChange={(e) => {
                      handleInputChange(e, "FourthNameEn");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.FourthNameEn && errors.FourthNameEn.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.BirthDate}>
              <FormLabel htmlFor="date" style={{ textTransform: "capitalize" }}>
                {t("pages.hr.date_of_birth")}
              </FormLabel>
              <Controller
                name="BirthDate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="date"
                    {...field}
                    placeholder={t("pages.hr.date_of_birth")}
                    onChange={(e) => {
                      handleInputChange(e, "BirthDate");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.BirthDate && errors.BirthDate.message}
              </FormErrorMessage>
            </FormControl>

            <Box>
              <MultiSelect
                title={t("pages.hr.nationality")}
                isMulti={false}
                data={
                  nationalityDropDown &&
                  nationalityDropDown?.map((el) => ({
                    ...el,
                    label: el.NationalityNameEn,
                    value: el.NationalityId,
                  }))
                }
                onChange={(e) => setValue("NationalityId", e)}
              />
            </Box>

            <Box>
              <MultiSelect
                title={t("pages.hr.Classification")}
                isMulti={false}
                data={
                  classifications &&
                  classifications?.map((el) => ({
                    ...el,
                    label: el.EmployeeClassificationNameEn,
                    value: el.EmployeeClassificationId,
                  }))
                }
                onChange={(e) => setValue("EmpClassificationId", e)}
              />
            </Box>

            <Box>
              <MultiSelect
                title={t("pages.hr.marital_status")}
                isMulti={false}
                data={
                  MartialStatusDropDown &&
                  MartialStatusDropDown?.map((el) => ({
                    ...el,
                    label: el.FamilyStatusNameEn,
                    value: el.FamilyStatusId,
                  }))
                }
                onChange={(e) => setValue("FamilyStatusId", e)}
              />
            </Box>

            <Box>
              <MultiSelect
                title={t("pages.hr.religion")}
                isMulti={false}
                data={
                  ReligionDropDown &&
                  ReligionDropDown?.map((el) => ({
                    ...el,
                    label: el.ReligionNameEn,
                    value: el.ReligionId,
                  }))
                }
                onChange={(e) => setValue("ReligionId", e)}
              />
            </Box>

            <Box>
              <MultiSelect
                title={t("pages.hr.recruitment_situation")}
                isMulti={false}
                data={
                  RecruitmentSituationDropDown &&
                  RecruitmentSituationDropDown?.map((el) => ({
                    ...el,
                    label: el.RecruitmentSituationNameEn,
                    value: el.RecruitmentSituationId,
                  }))
                }
                onChange={(e) => setValue("RecruitmentSituationId", e)}
              />
            </Box>

            <Box>
              <Text mb={3}>{t("general.gender")} </Text>
              <RadioGroup onChange={setGenderValue} value={genderValue}>
                <Stack direction="row">
                  <Radio value="1"> {t("general.male")} </Radio>
                  <Radio value="0">{t("general.female")}</Radio>
                </Stack>
              </RadioGroup>
            </Box>
          </SimpleGrid>
        </Box>

        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Flex gap={2} flexWrap="wrap">
            <GButton
              title={t("pages.profile.save_changes")}
              color="light"
              border="1px solid transparent"
              bg="primary"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              type="submit"
            />
          </Flex>
        </Box>
      </form>
    </>
  );
}
