import React from 'react';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import GButton from 'components/ui/button/Button';
import { useTranslation } from 'react-i18next';

export default function LoyalityModal() {
    const {t} = useTranslation();

  return <>
    <Box>
      <Flex
        alignItems="center" justifyContent="space-between"
        gap={2} flexWrap="wrap" mb={6}
      >
        <Text color="text" textTransform="capitalize">
            {t("pages.customers.transaction")}
        </Text>
        {/* <GButton
        //   title={t("pages.customers.transaction")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
        //   onClick={() => setShowCreateAddressModal({ customerId })}
        /> */}
      </Flex>
        <Box 
            bg="light"
            p={6}
            borderRadius={4}
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)">

            <Text color="text" textTransform="capitalize" textAlign="center">
                "fdfhdf hdfhidf ndjffdjdf"
            </Text>
            
        </Box>
    </Box>

    <Box>
      <Flex
        alignItems="center" justifyContent="space-between"
        gap={2} flexWrap="wrap" mb={6}
      >
        <Text color="text" textTransform="capitalize">
            {t("pages.customers.rewards")}
        </Text>
        {/* <GButton
        //   title={t("pages.customers.transaction")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
        //   onClick={() => setShowCreateAddressModal({ customerId })}
        /> */}
      </Flex>
      <Box 
            bg="light"
            p={6}
            borderRadius={4}
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)">

            <Text color="text" textTransform="capitalize" textAlign="center">
                "fdfhdf hdfhidf ndjffdjdf"
            </Text>

        </Box>
    </Box>
  </>
}
