import React from 'react';
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import AttendanceByDateTable from '../components/AttendanceByDateTable';
import { SlCalender } from "react-icons/sl";
import { useState } from 'react';


const AttendanceByDatePage = () => {
    const { t } = useTranslation();

    const [showTabel,setShowTable]=useState(false)

    return (
        <div>
            <Box
                bg="light" marginInline={-6} marginTop={-6} padding={6}
                borderTop="1px solid" borderColor="border" marginBottom={6}
            >
                <Flex alignItems="center" justifyContent="space-between" gap={4}>
                    <Text
                        fontSize="1.2rem" color="dark" textTransform="capitalize"
                    >
                        {t("pages.hr.attendance_by_date")}
                    </Text>


                    <Flex gap={2}>
                    </Flex>
                </Flex>
            </Box>


            <div className='MobileViewEmployee' style={{width:"100%",  display: "flex", alignItems: "center", justifyContent: "space-between", gap: "25px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "8px", backgroundColor: "#45b6fe",  padding: "15px", marginBottom: "25px", borderRadius: "15px" }}>
                    <SlCalender style={{ color: "white", fontSize: "22px" }} />

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "15px" }}>
                        <label style={{ color: "white", fontSize: "18px" }}> {t("pages.hr.from")}</label>
                        <input type='date' style={{ padding: "8px", borderRadius: "15px" }}></input>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "15px" }}>
                        <label style={{ color: "white", fontSize: "18px" }}>{t("pages.hr.to")}</label>
                        <input type='date' style={{ padding: "8px", borderRadius: "15px" }}></input>
                    </div>
                </div>
                <button onClick={()=>setShowTable(true)} style={{backgroundColor:"#45b6fe",padding:"15px",borderRadius:"15px",color:"white",cursor:"pointer",marginBottom:"25px"}}>
                    submit
                </button>
            </div>

            {
                showTabel&&(
                    <AttendanceByDateTable />
                )
            }

        </div>
    );
}

export default AttendanceByDatePage;
