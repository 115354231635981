import { useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";

export default function ReportTransfersOrdersDataTable({ data }) {
  const { t } = useTranslation();

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const reportTransfersOrdersData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return (
    <>
      <>
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>{t("pages.reports.reports_tables.name")}</Th>
              <Th>{t("pages.reports.reports_tables.sku")}</Th>
              <Th>{t("pages.reports.reports_tables.barcode")}</Th>
              <Th>{t("pages.reports.reports_tables.storage_unit")}</Th>
              <Th>{t("pages.reports.reports_tables.destination")}</Th>
              <Th>{t("pages.reports.reports_tables.reference")}</Th>
              <Th>{t("pages.reports.reports_tables.warehouse")}</Th>
              <Th>{t("pages.reports.reports_tables.quantity")}</Th>
              <Th>{t("pages.reports.reports_tables.submitted_by")}</Th>
              <Th>{t("pages.reports.reports_tables.submitted_at")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {reportTransfersOrdersData?.map((item, index) => (
              <Tr key={item?.TransferOrdersItemsId}>
                <Td>{index + 1}</Td>
                <Td>{item?.InventoryItemNameEng}</Td>
                <Td>{item?.ItemRef}</Td>
                <Td>{item?.Barcode}</Td>
                <Td>{item?.StorageUnit}</Td>
                <Td>{item?.BranchName}</Td>
                <Td>{item?.TORef}</Td>
                <Td>{item?.Warehouse}</Td>
                <Td>{item?.Quantity}</Td>
                <Td>{item?.SubmittedBy}</Td>
                <Td>{item?.SubmittedAt}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(data?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(data?.length / recordsPerPage)
            )
          }
        />
      </>
    </>
  );
}
