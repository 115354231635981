import React from 'react';
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from 'react-redux';
// import {deleteItem} from "../../services/itemService"
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from "navigations/routes";

const DeletedInventoryModal = ({onClose,id}) => {
  //console.log(id)
  const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <GDeleteModal
        onClose={onClose}
        onSubmit={()=>{
          // dispatch(deleteItem(id)).unwrap().then(_ => {
          //   toast({ description: t("pages.warehouse.delete_warehouse") });
          //   onClose();
          //   navigate(`/${routes.inventory_warehouse}`);
          // })
        }}
        
  />
  );
}

export default DeletedInventoryModal;
