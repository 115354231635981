import React, { useEffect } from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import { generator } from "utils/generator";
import { Controller, useForm } from "react-hook-form";
import {Box, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import Input from "components/form/Input";
import { useDispatch, useSelector } from 'react-redux';
import { createDeliverZone, getAllBranchesDeliverZone, getAllDeliveryZone } from '../../services/deliveryZoneNewService';

const CreateDeliveryZoneModal = ({ onClose }) => {
  const { t } = useTranslation();
  const { handleSubmit, control, setValue,  getValues, watch ,  formState: { errors },  trigger} =  useForm();
  watch(["RefCode"]);

  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({position: "top",duration: 3000, status: "error",});

  const data = useSelector(state => state.deliveryZoneNew);

  const inventoryCatData = []
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllBranchesDeliverZone())
  }, [dispatch])

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.DeliverZone.create_delivery_zone")}
      modalContent={(

        <Box p={4}>

          <form onSubmit={handleSubmit(values => {
            if(values.BranchId===undefined )
            {
              toast2({ description: t("validation.choose_first")  });
            }else{
              values.BranchId = values.BranchId.BranchId
              dispatch(createDeliverZone(values)).unwrap().then(_ => {
              toast({ description: t("pages.DeliverZone.delivery_created") });
              dispatch(getAllDeliveryZone())
              onClose()
            });
            }
            
          })}>

    <Stack spacing={6}>

      <FormControl isInvalid={errors.DeliveryZoneName}>
          <FormLabel
            htmlFor="DeliveryZoneName"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.DeliverZone.name")}
          </FormLabel>
          <Controller
            name="DeliveryZoneName"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.DeliverZone.name")}
                onChange={(e) => {
                  handleInputChange(e, "DeliveryZoneName");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.DeliveryZoneName &&
              errors.DeliveryZoneName.message}
          </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.DeliveryZoneNameLoc}>
          <FormLabel
            htmlFor="DeliveryZoneNameLoc"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.DeliverZone.name_localized")}
          </FormLabel>
          <Controller
            name="DeliveryZoneNameLoc"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.DeliverZone.name_localized")}
                onChange={(e) => {
                  handleInputChange(e, "DeliveryZoneNameLoc");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.DeliveryZoneNameLoc &&
              errors.DeliveryZoneNameLoc.message}
          </FormErrorMessage>
      </FormControl>

      <Flex alignItems="flex-end" gap={4}>
          <Input
            label={t("pages.DeliverZone.refrance")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.DeliverZone.refrance")}
            isRequired
            value={getValues("RefCode") || ""}
            readOnly
          />
          <GButton
            title={t("pages.DeliverZone.generate")}
            height="50px"
            bg="light"
            border="1px solid"
            borderColor="border"
            hover={{
              bg: "primary",
              borderColor: "primary",
              color: "light",
            }}
            onClick={() => setValue("RefCode", generator({}))}
          />
      </Flex>

      <MultiSelect
        title={t("pages.DeliverZone.Branches")}
        data={data.branchesDeliverZone && data.branchesDeliverZone.map(el => ({
          ...el,
          label: el.BranchName,
          value: el.BranchId
        }))}
        isMulti={false}
        isRequired={true}
        onChange={(e) => setValue("BranchId", e)}
      />


              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>

            </Stack>

          </form>


        </Box>

      )}
    />
  );
}

export default CreateDeliveryZoneModal;
