import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({ combos }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const navigatefunction = (id) => {
    navigate(`${id}`);
  };

  const filteredcombos = useSelector((state) => state.combos);
  // console.log(combos);
  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

    // Calculate records for activeDiscounts
    const combosData = calculateRecords(
      combos,
      currentPageActive,
      recordsPerPage
    );
    const combosFilteredData = calculateRecords(
      filteredcombos?.filteredData,
      currentPageActive,
      recordsPerPage
    );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.combos.name")}</Th>
            <Th>{t("pages.combos.name_localized")}</Th>
            <Th>{t("pages.combos.category")}</Th>
            <Th>{t("pages.combos.sku")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredcombos?.filteredData?.length === 0
            ? combosData.map((combo, index) => (
                <Tr
                  key={combo.ComboId}
                  onClick={() => navigatefunction(combo.ComboId)}
                  style={{ cursor: "pointer" }}
                >
                  <Td>{index + 1}</Td>
                  <Td>{combo.ComboName}</Td>
                  <Td>{combo.ComboNameLoc}</Td>
                  <Td>{combo.ProductCategoriesNameEng}</Td>
                  <Td>{combo.ComboSUK}</Td>
                </Tr>
              ))
            : combosFilteredData?.map((combo, index) => (
                <Tr
                  key={combo.ComboId}
                  onClick={() => navigatefunction(combo.ComboId)}
                  style={{ cursor: "pointer" }}
                >
                  <Td>{index + 1}</Td>
                  <Td>{combo.ComboName}</Td>
                  <Td>{combo.ComboNameLoc}</Td>
                  <Td>{combo.ProductCategoriesNameEng}</Td>
                  <Td>{combo.ComboSUK}</Td>
                </Tr>
              ))}
        </Tbody>
      </Table>
      { filteredcombos?.filteredData?.length === 0 ? 
        <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(combos.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(combos.length / recordsPerPage)
                )
              }
        />
      :
        <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(filteredcombos?.filteredData?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(filteredcombos?.filteredData?.length / recordsPerPage)
                )
              }
        />
      }
    </Box>
  );
};

export default CTable;
