import React, { useEffect } from "react";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useForm } from "react-hook-form";
import GModal from "components/ui/modal/Modal";
import {
  addNewPurchaseOrders,
  getPurchaseOrdersList,
  getPurchesOrderBranch,
  getPurchesOrderSuppliers,
} from "../../services/purchasrOrderServices";
import Input from "components/form/Input";

export default function CreatePurchaseOrdersModal({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, control, setValue } = useForm();

  const { suplliersData, branchData } = useSelector(
    (state) => state.purchaseOrder
  );

  useEffect(() => {
    dispatch(getPurchesOrderSuppliers());
    dispatch(getPurchesOrderBranch());
  }, [dispatch]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.purchase_orders.new_purchase_order")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                if (
                  values.SuppliersId === undefined ||
                  values.BranchId === undefined ||
                  values.DeliveryDate === undefined
                ) {
                  toast2({ description: t("validation.choose_first") });
                } else {
                  values.SuppliersId = values.SuppliersId.SuppliersId;
                  values.BranchId = values.BranchId.BranchId;
                  values.PurchaseOrdersId = null;
                  values.UserId = localStorage.getItem("userId");
                  dispatch(addNewPurchaseOrders(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: "Purchase order created Successfully",
                      });
                      dispatch(getPurchaseOrdersList());
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <MultiSelect
                  title={t("pages.purchase_orders.suplliers")}
                  data={suplliersData.map((el) => ({
                    ...el,
                    label: el.SuppliersName,
                    value: el.SuppliersId,
                  }))}
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("SuppliersId", e)}
                />

                <MultiSelect
                  title={t("pages.purchase_orders.destination")}
                  data={branchData.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))}
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("BranchId", e)}
                />

                <Input
                  label={t("pages.purchase_orders.delivery_Date")}
                  name="DeliveryDate"
                  control={control}
                  type="date"
                  labelStyle={{ textTransform: "capitalize" }}
                  isFormController
                  onChange={(e) => setValue("DeliveryDate", e.target.value)}
                />

                <Input
                  label={t("pages.purchase_orders.notes")}
                  labelStyle={{ textTransform: "capitalize" }}
                  isFormController
                  name="Notes"
                  type="text"
                  control={control}
                  onChange={(e) => setValue("Notes", e.target.value)}
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
