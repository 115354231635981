import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteCategory, getCategories, getDeleted } from "../../services/categoriesService";

const DeleteModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000,  status: "success" });
  const dispatch = useDispatch();
  
  const categories = useSelector((state) => state.categories);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteCategory({ values: data }))
          .unwrap()
          .then((_) => {
            toast({ description: t("pages.categories.category_deleted") });
            dispatch(getDeleted());
            dispatch(getCategories());
            onClose();
          });
      }}
      isLoading={categories.isLoading}
    />
  );
};

export default DeleteModal;
