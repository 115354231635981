import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { viewContractById } from "../../services/employeeServices";

export default function ViewContractModal({ onClose, id }) {
  const disPatch = useDispatch();
  const { t } = useTranslation();
  const { contractById } = useSelector((state) => state?.employeeSlice);
  const [showContent, setShowContent] = useState(false);
  console.log(contractById);
  useEffect(() => {
    disPatch(viewContractById(id));
    setTimeout(() => {
      setShowContent(true);
    }, 2000);
  }, [disPatch, id]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.view_contract")}
        modalContent={
          showContent ? (
            <Box p={4}>
              <Stack spacing={6}>
                <Box>
                  <Text>{t("pages.hr.Employee_code")}</Text>
                  <Box
                    border={"1px"}
                    borderColor={"gray.300"}
                    padding={2}
                    borderRadius={5}
                    textColor="gray.600"
                    // fontWeight={"semibold"}
                    marginTop={"2"}
                  >
                    <Text>{contractById[0]?.EmployeeCode}</Text>
                  </Box>
                </Box>

                <Box>
                  <Text>{t("pages.hr.Employee_name")}</Text>
                  <Box
                    border={"1px"}
                    borderColor={"gray.300"}
                    padding={2}
                    borderRadius={5}
                    textColor="gray.600"
                    // fontWeight={"semibold"}
                    marginTop={"2"}
                  >
                    <Text>{contractById[0]?.FullEnName}</Text>
                  </Box>
                </Box>

                <Box>
                  <Text>{t("pages.hr.SeniorityDate")}</Text>
                  <Box
                    border={"1px"}
                    borderColor={"gray.300"}
                    padding={2}
                    borderRadius={5}
                    textColor="gray.600"
                    // fontWeight={"semibold"}
                    marginTop={"2"}
                  >
                    <Text>{contractById[0]?.SeniorityDate}</Text>
                  </Box>
                </Box>

                <Box>
                  <Text>{t("pages.hr.from_date")}</Text>
                  <Box
                    border={"1px"}
                    borderColor={"gray.300"}
                    padding={2}
                    borderRadius={5}
                    textColor="gray.600"
                    // fontWeight={"semibold"}
                    marginTop={"2"}
                  >
                    <Text>{contractById[0]?.StartDateofContract}</Text>
                  </Box>
                </Box>

                <Box>
                  <Text>{t("pages.hr.to_date")}</Text>
                  <Box
                    border={"1px"}
                    borderColor={"gray.300"}
                    padding={2}
                    borderRadius={5}
                    textColor="gray.600"
                    // fontWeight={"semibold"}
                    marginTop={"2"}
                  >
                    <Text>{contractById[0]?.EndDateofContract}</Text>
                  </Box>
                </Box>

                <Box>
                  <Text>{t("pages.hr.SchoolYearId")}</Text>
                  <Box
                    border={"1px"}
                    borderColor={"gray.300"}
                    padding={2}
                    borderRadius={5}
                    textColor="gray.600"
                    // fontWeight={"semibold"}
                    marginTop={"2"}
                  >
                    <Text>{contractById[0]?.SchoolYearId}</Text>
                  </Box>
                </Box>

                <Box>
                  <Text>{t("pages.hr.position_code")}</Text>
                  <Box
                    border={"1px"}
                    borderColor={"gray.300"}
                    padding={2}
                    borderRadius={5}
                    textColor="gray.600"
                    // fontWeight={"semibold"}
                    marginTop={"2"}
                  >
                    <Text>{contractById[0]?.PositionCode}</Text>
                  </Box>
                </Box>

                <Box>
                  <Text>{t("pages.hr.leave_type_name")}</Text>
                  <Box
                    border={"1px"}
                    borderColor={"gray.300"}
                    padding={2}
                    borderRadius={5}
                    textColor="gray.600"
                    // fontWeight={"semibold"}
                    marginTop={"2"}
                  >
                    <Text>{contractById[0]?.LeaveTypeName}</Text>
                  </Box>
                </Box>

                <Flex gap={4}>
                  {/* <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                /> */}
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </Box>
          ) : (
            <Flex bg="light" p={4} justifyContent="center">
              <Spinner />
            </Flex>
          )
        }
      ></GModal>
    </>
  );
}
