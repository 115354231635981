import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- create products Timed Events  -------------------------------------------------------------------*/
export const createTimedEventProducts = createAsyncThunk(
  "timedEventsProducts/createTimedEventProducts",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/AddTimedEventProducts?timeeventid=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Get products Timed Events List  -------------------------------------------------------------------*/
export const getTimedEventsProducts = createAsyncThunk(
  "timedEventsProducts/getTimedEventsProducts",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetTimedEventProducts?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete Product In Timed Event  -------------------------------------------------------------------*/
export const deleteProductInTimedEvent = createAsyncThunk(
  "timedEventsProducts/deleteProductInTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DeleteTimedEventProduct?timeeventproductid={"TimedEventProductId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Get Tags In Product ---------------------------------------------------*/
export const getTagsInProduct = createAsyncThunk(
  "timedEventsProducts/getTagsInProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/LookupTimedEventProducts?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
