import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";


/*  -----------------------------------------------  Get Branches In Reservation --------------------------------------- */
export const getReservationBranches = createAsyncThunk("reservations/getReservationBranches", async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage2/LookUpReservationsBranches`);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* ----------------------------------------- Create Branches In Reservation  ----------------------------------------------------- */
export const createReservation = createAsyncThunk("reservations/createReservation",async (args, thunkApi) => {
  try {
   const { data } = await axios.get(`/Manage/Reservation/AddReservationBranch?resrvbranch={"BranchId" : ${args}}`);
   return thunkApi.fulfillWithValue(data)
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* ----------------------------------------- Get Reservations List ----------------------------------------------------- */
export const getReservationsList = createAsyncThunk("reservations/getReservationsList", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Reservation/ListReservation`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------------ Reservation By ID -------------------------------------------------------*/
export const getReservationById = createAsyncThunk("reservations/getReservationById", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Reservation/ReservationById?reservationid=${args}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------------ Update Reservation  -------------------------------------------------------*/
export const updateReservation = createAsyncThunk("reservations/updateReservation", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Reservation/EditTables?resrvtable=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Delete Reservation -------------------------------------------------------*/
// export const deleteReservation = createAsyncThunk("reservations/deleteReservation", async (args, thunkApi) => {
//     try {
//       const { data } = await axios.get(`/Manage/Reservations/AddEditReservation?reservation=${JSON.stringify(args.values)}`);
//       return thunkApi.fulfillWithValue(data);
//     } catch (e) {
//       return thunkApi.rejectWithValue(e.response.data);
//     }
//   }
// );
/* ----------------------------------------------------------------- Get Days Time  -------------------------------------------------------*/
export const getTimeInReservation = createAsyncThunk("reservations/getTimeInReservation", async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage/Reservation/LookupTimeRange`);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* ----------------------------------------------------------------- Get Tables  -------------------------------------------------------*/
export const getTableInReservation = createAsyncThunk("reservations/getTableInReservation", async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage/Reservation/LookupTables?branchid={"ReservationId":${args}}`);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* ----------------------------------------------------------------- Get Table Time  -------------------------------------------------------*/
export const getSectionsInReservation = createAsyncThunk("reservations/getSectionsInReservation", async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage2/LookUpReservationsSections?branchid={"ReservationId":${args}}`);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getReservationFilteredData = createAsyncThunk("reservations/getReservationFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetReservationFilteredData = createAsyncThunk( "reservations/resetReservationFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);