import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDevicesFilteredData } from '../../services/devicesService';

export default function FilterModal({onClose , data }) {
    const { t } = useTranslation();
    const dispatch =useDispatch();

    const [filteredData, setFilteredData] = useState(data);
    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
      DevicesName: "",
    });

    const handleFilterDevices = (e) => {
      e.preventDefault();
  
      const filteredResult = data.filter((item) => {
        const {
          DevicesName,
        } = filterCriteria;
  
  
        let filterDevicesName = true;
  
        if (DevicesName) {
          filterDevicesName = item.DevicesName.toLowerCase().includes(DevicesName.toLowerCase());
        }
  
        
  
  
        return (
          filterDevicesName 
        );
      });
  
      setFilteredData(filteredResult);
      //console.log(filteredResult)
      onClose(filteredResult);
      dispatch(getDevicesFilteredData(filteredResult));
    };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.devices.filter_devices")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterDevices}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.devices.branch_name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.devices.branch")}
                name="DevicesName"
                value={filterCriteria.DevicesName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    DevicesName: e.target.value,
                  })
                }
              />
              


              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
