import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useForm } from "react-hook-form";
import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal"
import Input from "components/form/Input"
import GButton from "components/ui/button/Button"
import { getCustomerDetailsById, updateCustomer } from "../../services/customersService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";


export default function UpdateCustomerModal ({ onClose , customerId}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const customers = useSelector(state => state.customers);


  const { handleSubmit, register, control, watch, getValues, setValue,formState: { errors }, trigger } = useForm({
    defaultValues: {
      ...customers.customerDetails,
    },
  });

  // console.log(customers.customerDetails);
  const Gender = [
    { gender : "Male" , value : true } ,
    { gender : "Female" , value : false } 
  ];

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return <>
      <GModal
      onClose={onClose}
      modalHeader={t("pages.customers.update_customer")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit( values => {

            if(values.CustomerName === undefined || values.PhoneNo === undefined  || values.Email === undefined || values.GenderIsMale  === undefined || values.BirthDate === undefined ){
                    toast2({ description: t("general.validate") });
            }
            else {
              values.CustomerName = values.CustomerName;
              values.PhoneNo = values.PhoneNo;
              values.Email = values.Email;
              values.BirthDate = values.BirthDate;
              values.GenderIsMale = values.GenderIsMale.value;
              values.IsBlacklisted = true; 
              values.HouseAccountLimit = values.HouseAccountLimit; 
              values.CustomerId = customerId ; 
                dispatch(updateCustomer(values))
                  .unwrap()
                  .then(_ => {
                    toast({ description: t("pages.customers.customer_updated") });
                    dispatch(getCustomerDetailsById(customerId));
                    onClose()
                  })
            }
          })}>
            <Stack spacing={6}>
            <FormControl isInvalid={errors.CustomerName}>
                    <FormLabel
                      htmlFor="CustomerName"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.customers.name")}
                    </FormLabel>
                    <Controller
                      name="CustomerName"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t("validation.required"),
                        minLength: {
                          value: 2,
                          message: t("validation.name_long_2"),
                        },
                        maxLength: {
                          value: 25,
                          message: t("validation.name_long_25"),
                        },
                        pattern: {
                          value: /^[\p{L}\s]+$/u,
                          message: t("validation.this_name_invalide"),
                        },
                        validate: {
                          noOnlySpaces: (value) =>
                            !isOnlySpaces(value) || t("validation.no_only_spaces"),
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.customers.name")}
                          onChange={(e) => {
                            handleInputChange(e, "CustomerName");
                          }}
                        />
                      )}
                    />
                    <FormErrorMessage>  {errors.CustomerName &&   errors.CustomerName.message}  </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.PhoneNo}>
            <FormLabel htmlFor="PhoneNo" style={{ textTransform: "capitalize" }}>
              {t("pages.customers.phone")}
            </FormLabel>
            <Controller
              name="PhoneNo"
              control={control}
              defaultValue=""
              rules={{
                required: t("validation.required"),
                pattern: {
                  value: /^[0-9]+$/,
                  message: t("validation.only_numbers_allowed"),
                },
                validate: {
                  noOnlySpaces: (value) =>
                    !isOnlySpaces(value) || t("validation.no_only_spaces"),
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t("pages.customers.phone")}
                  onChange={(e) => {
                    handleInputChange(e, "PhoneNo");
                  }}
                />
              )}
            />
            <FormErrorMessage>
              {errors.PhoneNo && errors.PhoneNo.message}
            </FormErrorMessage>
          </FormControl>

            <FormControl isInvalid={errors.Email}>
                <FormLabel
                  htmlFor="Email"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.customers.email")}
                </FormLabel>
                <Controller
                  name="Email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t("validation.invalid_email"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.customers.email")}
                      onChange={(e) => {
                        handleInputChange(e, "Email");
                      }}
                      type="email"
                    />
                  )}
                />
                <FormErrorMessage>
                  {" "}
                  {errors.Email && errors.Email.message}{" "}
                </FormErrorMessage>
            </FormControl>

              <Input
                type="date"
                label={t("pages.customers.date")}
                labelStyle={{ textTransform: "capitalize" }}
                name="BirthDate"
                isFormController
                control={control}
                onChange={(e) => setValue("BirthDate", e)}
              />
                <MultiSelect
                  name="GenderIsMale"
                  title={t("pages.customers.gender")}
                  data={Gender.map((el) => ({
                    label: el.gender,
                    value: el.value,
                  }))}
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("GenderIsMale", e)}
                />
            
              <Input
                type="number"
                label={t("pages.customers.house_account")}
                labelStyle={{ textTransform: "capitalize" }}
                name="HouseAccountLimit"
                isFormController
                control={control}
                onChange={(e) => setValue("HouseAccountLimit", e)}
              />


              <Checkbox 
                  label={t("pages.customers.house_account")}
                  defaultChecked
                  name="IsBlacklisted"
                  onChange={(e) => setValue("IsBlacklisted", e)}
              > 
                  Checkbox 
              </Checkbox>


              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={customers.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={customers.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  </>
}

