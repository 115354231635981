import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import GModal from "components/ui/modal/Modal";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input as ChInput } from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch } from "react-redux";
import { createAccountCycel } from "../../service/AccountAddService";
import { convertDateFormat } from "utils/convertDate";
const AddAccountCycel = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const {
    handleSubmit,
    control,

    setValue,

    formState: { errors },
    trigger,
  } = useForm();
  const data = [
    { label: "نشط", value: 1 },
    { label: "غير نشط", value: 0 },
  ];

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.AccountAdd.Add_Accounts_cycle")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              if (values.IsActive == undefined) {
                toast2({ description: t("validation.choose_first") });
              } else {
                values.UserId = localStorage.getItem("userId");
                values.IsActive = values.IsActive.value;
                values.OperatingUserID = 1;
                values.DateFrom = convertDateFormat(values.DateFrom);
                values.DateTo = convertDateFormat(values.DateTo);
                dispatch(createAccountCycel(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.AccountAdd.created_Done"),
                    });
                    onClose();
                  });
                console.log(values);
              }
            })}
          >
            <Stack spacing={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  gap: "10px",
                }}
              >
                <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                  {t("pages.AccountAdd.date_From")}
                </label>

                <Controller
                  name="DateFrom"
                  control={control}
                  render={({ field }) => (
                    <ChInput required {...field} type="date" />
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  gap: "10px",
                }}
              >
                <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                  {t("pages.AccountAdd.date_to")}
                </label>

                <Controller
                  name="DateTo"
                  control={control}
                  render={({ field }) => (
                    <ChInput required {...field} type="date" />
                  )}
                />
              </div>

              <MultiSelect
                title={t("pages.AccountAdd.Active")}
                data={data?.map((el) => ({
                  ...el,
                  label: el.label,
                  value: el.value,
                }))}
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("IsActive", e)}
              />

              <FormControl isInvalid={errors.Description}>
                <FormLabel
                  htmlFor="Description"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.AccountAdd.description")}
                </FormLabel>
                <Controller
                  name="Description"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.AccountAdd.description")}
                      onChange={(e) => {
                        handleInputChange(e, "Description");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.Description && errors.Description.message}
                </FormErrorMessage>
              </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddAccountCycel;
