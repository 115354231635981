import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { getProductsForSpecificGroup, getTaxesGroupsProductsToRemove } from "../../services/productsToGroupsService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { removeProductFromGroup } from "../../services/productsToGroupsService";
import { getTaxesGroups } from "../../services/taxesGroupsService";

const RemoveProductFromGroupModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000,  status: "success",});
  const toast2 = useToast({ position: "top", duration: 3000,  status: "error",});
  const { handleSubmit, setValue } = useForm();

  const products = useSelector((state) => state.productGroups);
  
  useEffect(() => {
    dispatch(getTaxesGroupsProductsToRemove());
  }, [dispatch]);


  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.taxes.remove_from_products")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.TaxGroupProductsId = values.TaxGroupProductsId?.TaxGroupProductsId
              values.IsDeleted = true;
              if( values.TaxGroupProductsId  === undefined){
                  toast2({description: t("general.validate")});
              }
              else {
              dispatch(removeProductFromGroup(values))
                .unwrap()
                .then((_) => {
                  toast({description: t("pages.taxes.product_removed_to_tax_group")});
                  dispatch(getTaxesGroups());
                  onClose();
                });
                }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                data={
                  products.TaxesGroupsBranchesToRemove &&
                  products.TaxesGroupsBranchesToRemove?.map((el) => ({
                    ...el,
                    label: el.Product[0]?.ProductName ,
                    value: el.TaxGroupProductsId ,
                  }))
                }
                isRequired
                isMulti={false}
                title={t("pages.taxes.products")}
                onChange={(e) => setValue("TaxGroupProductsId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.taxes.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={data.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={data.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default RemoveProductFromGroupModal;
