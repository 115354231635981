import { createSlice } from "@reduxjs/toolkit";
import {
  getModifiersOptions,
  getModifiersOptionById,
  createModifiersOption,
  updateModifiersOption,
  deleteModifiersOption,
  addCustomPriceModifierOption,
  activateModifier,
  getFilteredData,
  resetFilteredData
} from "../services/modifiersOptionsService";

let initialState = {
  isLoading: false,
  data: [],
  modifiersOption: null,
  pages: 0,
  errors: null,
  filteredData:[],
  custompriceModifireOption:[]
};

const modifiersOptionsSlice = createSlice({
  name: "modifiersOptions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getModifiersOptions.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getModifiersOptions.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getModifiersOptions.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getModifiersOptionById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getModifiersOptionById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiersOption = (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    })
    builder.addCase(getModifiersOptionById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createModifiersOption.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createModifiersOption.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createModifiersOption.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateModifiersOption.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateModifiersOption.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(modifiersOption => modifiersOption.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateModifiersOption.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteModifiersOption.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteModifiersOption.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.data = state.data.filter(modifiersOption => modifiersOption.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteModifiersOption.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //

    builder.addCase(addCustomPriceModifierOption.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(addCustomPriceModifierOption.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(addCustomPriceModifierOption.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    


    // builder.addCase(getCustomPriceModifierOptionById.pending, (state) => {
    //   state.isLoading = true;
    //   state.errors = null;
    // })
    // builder.addCase(getCustomPriceModifierOptionById.fulfilled, (state, { payload }) => {
    //   state.isLoading = false;
    //   state.custompriceModifireOption = (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
    //   console.log(state.custompriceModifireOption)
    //   state.errors = null;
    // })
    // builder.addCase(getCustomPriceModifierOptionById.rejected, (state) => {
    //   state.isLoading = false;
    //   state.errors = [{ msg: "something went wrong!" }];
    // })

    //


    builder.addCase(activateModifier.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(activateModifier.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.data = state.data.filter(modifiersOption => modifiersOption.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(activateModifier.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      console.log("youssef" , state.filteredData);
      state.errors = null;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

  }
});

export default modifiersOptionsSlice.reducer;