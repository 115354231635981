import { createSlice } from "@reduxjs/toolkit";

import {getBrancheTrendsReports} from "../services/branchesTrendsServices";


let initialState = {
    isLoading: false,
    pages: 0,
    errors: null,
  
    data: [],
    // Filter data
    filteredData : []
  };


  const BrancheTrends = createSlice({

    name: "BrancheTrends",
    initialState,
    extraReducers: (builder) => {

        builder.addCase(getBrancheTrendsReports.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getBrancheTrendsReports.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
            //console.log(state.data)
            state.errors = null;
          })
          builder.addCase(getBrancheTrendsReports.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

    }

  })

  export default BrancheTrends.reducer;