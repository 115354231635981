import { createSlice } from "@reduxjs/toolkit";
import {
  getCallcenter,
  getInvItemDrop,
  deleteOrder,
  getEditQuantities,
  addInventoryCount,
  getListinventoryitem,
  getInventoryCountDetails,
  addInvCountItems,
  editInventoryCount,
  editQuantities,
  getInvCountItems,
  submitCount,
  getInventoryCountFilteredData,
  resetInventoryCountFilteredData,
} from "../services/inventoryCountService";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,
  data: [],
  data1: [],
  data2: [],
  DropData: [],
  DropData1: [],
  databyId: [],
  filteredData : []
};

const inventoryCountSlice = createSlice({
  name: "inventoryCount",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getCallcenter.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getCallcenter.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.DropData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getCallcenter.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getInvItemDrop.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getInvItemDrop.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.DropData1 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getInvItemDrop.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getListinventoryitem.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getListinventoryitem.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getListinventoryitem.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getInventoryCountDetails.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(
      getInventoryCountDetails.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.databyId = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      }
    );

    builder.addCase(getInventoryCountDetails.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ------------------------------------------------------------ Add Inventory Count  -----------------------------------------------------------------*/
    builder.addCase(addInventoryCount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addInventoryCount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(addInventoryCount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(submitCount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(submitCount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(submitCount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(editInventoryCount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editInventoryCount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editInventoryCount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(deleteOrder.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deleteOrder.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    //

    builder.addCase(addInvCountItems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(addInvCountItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });

    builder.addCase(addInvCountItems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(editQuantities.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(editQuantities.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });

    builder.addCase(editQuantities.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getInvCountItems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getInvCountItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data1 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getInvCountItems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getEditQuantities.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getEditQuantities.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data2 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getEditQuantities.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

      /* ----------------------------------------- Filter Data --------------------------------------------- */
   builder.addCase(getInventoryCountFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getInventoryCountFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getInventoryCountFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetInventoryCountFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetInventoryCountFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetInventoryCountFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
  },
});

export default inventoryCountSlice.reducer;
