import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "./axios";

/* -------------------------------------------------------- Get Time Zone ------------------------------------------------------------------ */
export const getTimeZone = createAsyncThunk("timezome/getTimeZone", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/api/GeneralSettingJson/GetTimeZone");
      const parsedData = (data[0] && JSON.parse(data[0].jsonData)) || [];
      const finalResponse = {
        data: parsedData,
        itemsCount: parsedData.length
      }
      return thunkApi.fulfillWithValue(finalResponse);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
)