import { createSlice } from "@reduxjs/toolkit";
import {
  getReservations,
  getReservationById,
  createReservation,
  updateReservation,
  // deleteReservation,
  getReservationBranches,
  getReservationsList,
  getReservationFilteredData,
  resetReservationFilteredData,
  getTimeInReservation,
  getTableInReservation,
  getSectionsInReservation,
} from "../services/reservationsService";

let initialState = {
  isLoading: false,
  data: [],
  reservationBranches: [],
  reservation: null,
  pages: 0,
  errors: null,

  filteredData:[],
  daysTime:[],
  tableTime:[],
  sectionsTime:[]
};

const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  extraReducers: (builder) => {
/*  -----------------------------------------Get Branches In Reservation -------------------------- */
builder.addCase(getReservationBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getReservationBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.reservationBranches = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getReservationBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ----------------------------------------- Create Branches In Reservation  ----------------------------------------------------- */
    builder.addCase(createReservation.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createReservation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(createReservation.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ----------------------------------------- Get Reservations List ----------------------------------------------------- */
    builder.addCase(getReservationsList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getReservationsList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getReservationsList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ----------------------------------------- Get Reservation By Id  (Details)  ----------------------------------------------------- */
    builder.addCase(getReservationById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getReservationById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.reservation = (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    })
    builder.addCase(getReservationById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })  

    builder.addCase(updateReservation.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateReservation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(reservation => reservation.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateReservation.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    // builder.addCase(deleteReservation.pending, (state) => {
    //   state.isLoading = true;
    //   state.errors = null;
    // })
    // builder.addCase(deleteReservation.fulfilled, (state, { payload }) => {
    //   state.isLoading = false;
    //   state.data = state.data.filter(reservation => reservation.id !== payload.id);
    //   state.errors = null;
    // })
    // builder.addCase(deleteReservation.rejected, (state) => {
    //   state.isLoading = false;
    //   state.errors = [{ msg: "something went wrong!" }];
    // })
    /* ----------------------------------------------------------------- Get Days Time  -------------------------------------------------------*/
    builder.addCase(getTimeInReservation.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTimeInReservation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.daysTime = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getTimeInReservation.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
    /* ----------------------------------------------------------------- Get Table Time  -------------------------------------------------------*/
    builder.addCase(getTableInReservation.pending, (state) => {
      // state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTableInReservation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tableTime = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getTableInReservation.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
    /* ----------------------------------------------------------------- Get Sections Time  -------------------------------------------------------*/
    builder.addCase(getSectionsInReservation.pending, (state) => {
      // state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getSectionsInReservation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.sectionsTime = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getSectionsInReservation.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

       /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
   builder.addCase(getReservationFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getReservationFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getReservationFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetReservationFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetReservationFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetReservationFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
  }
});

export default reservationsSlice.reducer;