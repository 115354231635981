import { useState } from "react";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Pagination from "components/ui/pagination/Pagination";

export default function DrawerOperationsDataTable({ data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const navigatefunction = (id) => {
  //   navigate(`${id}`);
  // };
  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const drawerOperationsData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return (
    <>
      <>
        <Table>
          <Thead>
            <Tr>
              <Th>{t("pages.reports.reports_tables.date")}</Th>
              <Th>{t("pages.reports.reports_tables.type")}</Th>
              <Th>{t("pages.reports.reports_tables.amount")}</Th>
              <Th>{t("pages.reports.reports_tables.user")}</Th>
              <Th>{t("pages.reports.reports_tables.branch")}</Th>
              <Th>{t("pages.reports.reports_tables.reason")}</Th>
              <Th>{t("pages.reports.reports_tables.notes")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {drawerOperationsData?.map((item, index) => (
              <Tr key={index}>
                <Td>{item?.Date}</Td>
                <Td>{item?.Type}</Td>
                <Td> {item?.Amount} </Td>
                <Td> {item?.User} </Td>
                <Td> {item?.BranchName} </Td>
                <Td> {item?.Reason} </Td>
                <Td> {item?.Notes} </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(data?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(data?.length / recordsPerPage)
            )
          }
        />
      </>
    </>
  );
}
