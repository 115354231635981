import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { Box, Flex, Stack, useToast } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import Input from "components/form/Input"
import GButton from "components/ui/button/Button"
import { createPromotion } from "../../services/promotionsService"

const CreateModal = ({
  onClose
}) => {
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const promotions = useSelector(state => state.promotions);
  const { handleSubmit, formState: { errors }, control } = useForm();
  const { t } = useTranslation();
  const createPromotionValidationSchema = {
    name: {
      required: t("validation.required")
    }
  };
  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.promotions.create_promotion")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            dispatch(createPromotion(values))
              .unwrap()
              .then(_ => {
                toast({ description: t("pages.promotions.promotion_created") });
                onClose()
              });
          })}>
            <Stack spacing={6}>
              <Input
                label={t("pages.promotions.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.promotions.name")}
                name="name"
                rules={createPromotionValidationSchema.name}
                error={errors.name}
                isFormController
                isRequired
                control={control}
              />
              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={promotions.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={promotions.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default CreateModal