import { createSlice } from "@reduxjs/toolkit";
import {
  CategoriesDropList,
  createDiscountCategories,
  getDiscountsCategories,
  deleteCategoryInDiscount,
} from "../services/discountsCategoriesService";

let initialState = {
  isLoading: false,
  errors: null,
  categoriesList: [],
};

const discountsCategoriesSlice = createSlice({
  name: "discountsCategories",
  initialState,

  extraReducers: (builder) => {
    /* ------------------------------------------------- Categories DropDown List ------------------------------------------------ */
    builder.addCase(CategoriesDropList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(CategoriesDropList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.categoriesList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(CategoriesDropList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* -------------------------------------------------------  Get categories Discounts List  -------------------------------------------------------------------*/
    builder.addCase(getDiscountsCategories.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDiscountsCategories.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getDiscountsCategories.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create categories Combos  -------------------------------------------------------------------*/
    builder.addCase(createDiscountCategories.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      createDiscountCategories.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(createDiscountCategories.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* ------------------------------------------------------- Delete Category In Discount  -------------------------------------------------------------------*/
    builder.addCase(deleteCategoryInDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      deleteCategoryInDiscount.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(deleteCategoryInDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default discountsCategoriesSlice.reducer;
