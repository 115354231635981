import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getModifiers = createAsyncThunk(
  "modifiers/getModifiers",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Modifers/ModifersList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createModifier = createAsyncThunk(
  "modifiers/createModifier",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/Modifers/AddModifers?modifers=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateModifier = createAsyncThunk(
  "modifiers/updateModifier",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/Modifers/EditModifers?modifersid=${JSON.stringify(args.values)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteModifier = createAsyncThunk(
  "modifiers/deleteModifier",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/Modifers/DeleteModifersById?modifersid=${JSON.stringify({
          ModifersId: args.values.ModifersId,
        })}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getDeleted = createAsyncThunk(
  "modifiers/getDeleted",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Modifers/DeletedModifersList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const restoreModifier = createAsyncThunk(
  "modifiers/restoreModifier",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/Modifers/DeleteModifersById?modifersid=${JSON.stringify({
          ModifersId: args,
        })}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getFilteredData = createAsyncThunk(
  "modifiers/getFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const resetFilteredData = createAsyncThunk(
  "modifiers/resetFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
