import { createSlice } from "@reduxjs/toolkit";
import {
  getBranches,
  getBranchById,
  createBranch,
  updateBranch,
  deleteBranch,
  getSectionTables,
  AddTableToSection,
  getFilteredDataBranches,
  resetFilteredDataBranches
} from "../services/branchesService";

let initialState = {
  isLoading: false,
  data: [],
  filteredData:[],
  branch: null,
  pages: 0,
  errors: null
};

const branchesSlice = createSlice({
  name: "branches",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getBranchById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getBranchById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branch = (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    })
    builder.addCase(getBranchById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(createBranch.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createBranch.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //console.log(payload)
      //state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createBranch.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    // builder.addCase(createBranch.pending, (state) => {
    //   state.isLoading = true;
    //   state.errors = null;
    // })
    // builder.addCase(createBranch.fulfilled, (state, { payload }) => {
    //   state.isLoading = false;
    //   //state.data.push(payload);
    //   state.errors = null;
    // })
    // builder.addCase(createBranch.rejected, (state) => {
    //   state.isLoading = false;
    //   state.errors = [{ msg: "something went wrong!" }];
    // })

    builder.addCase(updateBranch.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateBranch.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //const indexAt = state.data.findIndex(branch => branch.id === payload.id);
      //state.data[indexAt] = payload;
      
      state.errors = null;
    })
    builder.addCase(updateBranch.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteBranch.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteBranch.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(branch => branch.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteBranch.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(getSectionTables.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getSectionTables.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.SectionTables = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.SectionTables)
      state.errors = null;
    })
    builder.addCase(getSectionTables.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(AddTableToSection.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(AddTableToSection.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.SectionTables = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.SectionTables)
      state.errors = null;
    })
    builder.addCase(AddTableToSection.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })




    builder.addCase(getFilteredDataBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredDataBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      console.log("youssef" , state.filteredData);
      state.errors = null;
    });
    builder.addCase(getFilteredDataBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredDataBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredDataBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredDataBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

  }
});

export default branchesSlice.reducer;