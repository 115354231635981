import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import AppliesOnProductTagsModal from "./modals/AppliesOnProductTagsModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductTagsTable from "./ProductTagsTable";

import DeleteAppliesModal from "./modals/DeleteAppliesModal";
import { getTimedEventsProductTags } from "../services/timedEventsProductTagsService";

const AppliesOnProductTags = () => {
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showProductTagsOfTimedEvent, setShowProductTagsOfTimedEvent] =
    useState(false);
  const [showDeleteProductTagModal, setShowDeleteProductTagModal] =
    useState(null);

  useEffect(() => {
    dispatch(getTimedEventsProductTags(id.id));
  }, [dispatch, id]);

  const productTags = useSelector((state) => state.timedEventsProductTags);

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.timedEvents.apply_on_product_tags")}
        </Text>

        <GButton
          title={t("pages.timedEvents.add_product_tags")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowProductTagsOfTimedEvent(true)}
        />
      </Flex>

      {productTags.isLoading ? (
        <Spinner />
      ) : productTags.data?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : productTags.data?.length > 0 ? (
        <ProductTagsTable
          productTagsOfTimedEvent={productTags.data}
          handleDelete={(productTag) =>
            setShowDeleteProductTagModal(productTag)
          }
        />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {showProductTagsOfTimedEvent && (
        <AppliesOnProductTagsModal
          onClose={() => setShowProductTagsOfTimedEvent(false)}
        />
      )}
      {showDeleteProductTagModal && (
        <DeleteAppliesModal
          id={showDeleteProductTagModal}
          onClose={() => setShowDeleteProductTagModal(null)}
          type="ProductTag"
        />
      )}
    </Box>
  );
};

export default AppliesOnProductTags;
