import React, { useEffect, useState } from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Stack, useToast, Input as ChInput, Text, } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from 'react-redux';
import { addItemsToTransfers, editTransfersQuantityItems, getItemDropdown, getTransferItems } from '../services/transfersServices';

export default function EditQuantityItems({onClose,id , data}) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
    const { handleSubmit, setValue } = useForm();

    const transfersData = useSelector(state => state.inventoryTransfers);
    const [itemData, setItemData] = useState({});

    useEffect(() => {
        dispatch(getItemDropdown());
      }, [dispatch])

      useEffect(() => {
        const initialItemData = {};
        data.forEach((el) => {
          // Store each element in a separate object
          initialItemData[el.TransfersItemsId] = {
            TransfersItemsId: el.TransfersItemsId,
            Quantity: el.Quantity,
          };
        });
        // Set the state with both the object and the array
        setItemData(initialItemData);
      }, [data]);
    
      const handleQuantityChange = (newQuantity, itemId) => {
        // Step 3: Update the state with the new data using a unique identifier
        setItemData((prevData) => {
          return {
            ...prevData,
            [itemId]: {
              ...prevData[itemId],
              Quantity: newQuantity,
            },
          };
        });
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.transfers.edit_quantity")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            
                const editedItems = Object.keys(itemData).map((itemId) => ({
                    TransfersItemsId: parseInt(itemId,10),
                    Quantity: itemData[itemId].Quantity,
                  }));
                const QuantityValues = {"TransfersItems" : editedItems , "TransfersId" : id.TransfersId};
                if( values.TransfersItems?.length === 0 ){
                    toast2({ description: t("validation.choose_first") });
                }
                else {
                    dispatch(editTransfersQuantityItems(QuantityValues))
                    .unwrap().then(_ => {
                    toast({ description: t("pages.transfers.edited_succesfuly") });
                      dispatch(getTransferItems(id));
                    onClose()
                    });
                }
          })} >

            <Stack spacing={6}>
            {data?.map((el) => (
                  <Box>
                    <Text
                      marginBottom={"7px"}
                      fontSize={"1rem"}
                      fontWeight={"500"}
                      textTransform={"capitalize"}
                      textAlign={"start"}
                      marginInlineEnd={"0.75rem"}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {el.InventoryItemNameEng}
                    </Text>
                    <ChInput
                      placeholder={data[el.TransfersId]?.Quantity || ""}
                      name="Quantity"
                      onChange={(e) =>
                        handleQuantityChange(
                          e.target.value,
                          el.TransfersItemsId
                        )
                      }
                    />
                  </Box>
                ))}

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />

  
  </>
};
