//test git test
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useForm } from "react-hook-form"
import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import Input from "components/form/Input"
import GButton from "components/ui/button/Button"
import { getLanguage, getTagGroup, getUsers, updateUser } from "../../services/usersService"
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import { useEffect } from "react"
import { getRoles } from "features/management/roles/services/rolesService"
import { generator } from "utils/generator"

const UpdateModal = ({
  data,
  onClose
}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);
  const roles = useSelector(state => state.roles);
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
    trigger,
    watch, getValues,
  } = useForm({
    defaultValues: data
  });

  

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(()=>{
    dispatch(getLanguage())
  },[dispatch])

  useEffect(()=>{
    dispatch(getTagGroup())
  },[dispatch])


  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  watch(["loginPin"]);
  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });


  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.users.update_user")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            if (values.LanguageId === undefined || values.roleId===undefined ||  values.TagGroupId===undefined) {
              toast2({ description: t("validation.choose_first") });
            }else{
              values.isDeleted = false;
              values.roleId = values.roleId?.roleId;
              values.TagGroupId=values.TagGroupId.TagGroupId
              values.LanguageId=values.LanguageId.LanguageId
              values.Phone="123-456-7890"
              console.log(values)
              dispatch(updateUser( values ))
                .unwrap()
                .then(_ => {
                  toast({ description: t("pages.users.user_updated") });
                  dispatch(getUsers());
                  onClose()
                });
            }
          })}>
            <Stack spacing={6}>
       

              <FormControl isInvalid={errors.userFullName}>
                <FormLabel
                  htmlFor="userFullName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.users.full_name")}
                </FormLabel>
                <Controller
                  name="userFullName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 50,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.users.full_name")}
                      onChange={(e) => {
                        handleInputChange(e, "userFullName");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.userFullName && errors.userFullName.message}
                </FormErrorMessage>
              </FormControl>


              <FormControl isInvalid={errors.userName}>
              <FormLabel
                htmlFor="userName"
                style={{ textTransform: "capitalize" }}
              >
              {t("pages.users.user_name")}
              </FormLabel>
              <Controller
                name="userName"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 50,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.users.user_name")}
                    onChange={(e) => {
                      handleInputChange(e, "userName");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.userName && errors.userName.message}
              </FormErrorMessage>
            </FormControl>

              
              <FormControl isInvalid={errors.email}>
                <FormLabel
                  htmlFor="email"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.suppliers.email")}
                </FormLabel>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t("validation.invalid_email"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.suppliers.email")}
                      onChange={(e) => {
                        handleInputChange(e, "email");
                      }}
                      type="Email"
                    />
                  )}
                />
                <FormErrorMessage>
                  {" "}
                  {errors.email && errors.email.message}{" "}
                </FormErrorMessage>
              </FormControl>


              <Flex alignItems="flex-end" gap={4}>
                <Input
                  label={t("pages.users.pin")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.users.pin")}
                  isRequired
                  value={getValues("loginPin") || ""}
                  readOnly
                />
                <GButton
                  title={t("pages.users.pin")}
                  height="50px"
                  bg="light"
                  border="1px solid"
                  borderColor="border"
                  hover={{
                    bg: "primary",
                    borderColor: "primary",
                    color: "light",
                  }}
                  onClick={() => setValue("loginPin", generator({}))}
                />
              </Flex>



              <MultiSelect
              data={
                users.language &&
                users.language.map((el) => ({
                  ...el,
                  label: el.LanguageNameEng,
                  value: el.LanguageId,
                }))
              }
              onChange={(e) => setValue("LanguageId", e)}
              isMulti={false}
              isRequired
              title={t("pages.users.language")}
            />


            <MultiSelect
            data={roles.data && roles.data.map(el => ({
              ...el,
              label: el.roleName,
              value: el.roleId
            }))}
            isMulti={false}
            title={t("pages.users.role")}
            onChange={(e) => setValue("roleId", e)}
          />

          <MultiSelect
            data={
              users.TagGroup &&
              users.TagGroup.map((el) => ({
                ...el,
                label: el.TagGroupName,
                value: el.TagGroupId,
              }))
            }
            isMulti={false}
            title={t("pages.users.TagGroup")}
            onChange={(e) => setValue("TagGroupId", e)}
          />


              <Checkbox {...register("isActive")}>
                {t("pages.users.is_active")}
              </Checkbox>

              <Checkbox {...register("isConsoleAccess")}>
                {t("pages.users.console_access")}
              </Checkbox>

              <Checkbox {...register("isAppAccess")}>
                {t("pages.users.app_access")}
              </Checkbox>

              <Checkbox {...register("isAdmin")}>
                {t("pages.users.is_admin")}
              </Checkbox>

             

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={users.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={users.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default UpdateModal