import { createSlice } from "@reduxjs/toolkit";
import {
  addNewGroupShift,
  addNewTimeLeave,
  addNewTimeShift,
  editTimeShift,
  getGroupShift,
  getGroupShiftDays,
  getGroupShiftDetails,
  getLookUpTimeLeave,
  getTimeLevelDetailsById,
  getYearLookUp,
} from "../services/attendanceSettingServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  GroupShiftList: [],
  GroupShiftDays: [],
  GroupShiftDetails: [],

  TimeLeaveList: [],
  TimeLeavelDetails: [],
  YearLookUpDropDown: [],

  // Filter data
  filteredData: [],
};

const attendanceSettingSlice = createSlice({
  name: "attendanceSettingSlice",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get Employee List dropDown -------------------------------------------- */
    builder.addCase(getLookUpTimeLeave.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getLookUpTimeLeave.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TimeLeaveList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getLookUpTimeLeave.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------------------------------------  Add New Time ----------------------------------------------------------*/
    builder.addCase(addNewTimeLeave.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewTimeLeave.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(addNewTimeLeave.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* ------------------------------------------------- get TimeLevel Details By Id----------------------------------------------------------*/
    builder.addCase(getTimeLevelDetailsById.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTimeLevelDetailsById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TimeLeavelDetails =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getTimeLevelDetailsById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------------------------------------get Year LookUp----------------------------------------------------------*/
    builder.addCase(getYearLookUp.pending, (state, { payload }) => {
      // state.isLoading = true;
      // state.errors = null;
    });
    builder.addCase(getYearLookUp.fulfilled, (state, { payload }) => {
      // state.isLoading = false;
      state.YearLookUpDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      // state.errors = null;
    });
    builder.addCase(getYearLookUp.rejected, (state, { payload }) => {
      // state.isLoading = false;
      // state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------------------------------------add New Time Shift----------------------------------------------------------*/
    builder.addCase(addNewTimeShift.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewTimeShift.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(addNewTimeShift.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------------------------------------add New Time Shift----------------------------------------------------------*/
    builder.addCase(editTimeShift.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editTimeShift.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editTimeShift.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------------------------------------Group Shift----------------------------------------------------------*/
    /* -------------------------------------------------Get Days Shift ----------------------------------------------------------*/
    builder.addCase(getGroupShiftDays.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getGroupShiftDays.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.GroupShiftDays =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getGroupShiftDays.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------Get Group Shift List----------------------------------------------------------*/
    builder.addCase(getGroupShift.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getGroupShift.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.GroupShiftList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getGroupShift.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------------------------------------add New Group Shift----------------------------------------------------------*/
    builder.addCase(addNewGroupShift.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewGroupShift.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(addNewGroupShift.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------------------------------------Get Group Shift By Id----------------------------------------------------------*/
    builder.addCase(getGroupShiftDetails.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getGroupShiftDetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.GroupShiftDetails =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getGroupShiftDetails.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default attendanceSettingSlice.reducer;
