import { createSlice } from "@reduxjs/toolkit";
import {
  getHrOvertimeDropdown,
  getMasterOvertimeReport,
  getMasterOvertimeReportWithDetails,
} from "../services/hrOvertimeServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  overTimeDropdown: [],
  materOverTimeList: [],
  materOverTimeDtailsList: [],

  // Filter data
  filteredData: [],
};
//

const hrOvertimeSlice = createSlice({
  name: "hrOvertime",
  initialState,
  extraReducers: (builder) => {
    /* -------------------  Get Hr Religions List ---------------------*/
    builder.addCase(getHrOvertimeDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getHrOvertimeDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.overTimeDropdown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getHrOvertimeDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Master OverTime Report List ---------------------*/
    builder.addCase(getMasterOvertimeReport.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getMasterOvertimeReport.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.materOverTimeList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getMasterOvertimeReport.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Master OverTime Report With Details  List ---------------------*/
    builder.addCase(
      getMasterOvertimeReportWithDetails.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.errors = null;
      }
    );
    builder.addCase(
      getMasterOvertimeReportWithDetails.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.materOverTimeDtailsList =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(
      getMasterOvertimeReportWithDetails.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      }
    );
  },
});

export default hrOvertimeSlice.reducer;
