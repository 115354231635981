import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const getItems = createAsyncThunk(
    "items/getItems",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/InventoryItem/InventoryItemList`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const createItem = createAsyncThunk(
    "items/createItem",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Menu/InventoryItem/AddInventoryItem?inventoryitem=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getItemDetails = createAsyncThunk(
    "items/getItemDetails",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Menu/Items/ItemsDataById?InventoryItemId={"InventoryItemId":${args}}`);
       //console.log(data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  //

  export const editItem = createAsyncThunk(
    "items/editItem",
    async (args, thunkApi) => {
      try {
        //console.log(args)
        const { data } = await axios.get(`/Menu/InventoryItem/EditInventoryItem?inventoryitem=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);

      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const deleteItem = createAsyncThunk(
    "items/deleteItem",
    async (args, thunkApi) => {
      
      try {
        //console.log(args)
        const { data } = await axios.get(`/Inventory/Item/DeleteItemById?inventoryitemid={"InventoryItemId":${args}}`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getDeletedItem = createAsyncThunk(
    "items/getDeletedItem",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/Item/DeletedItemList`);
       //console.log(data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  export const restoreItem = createAsyncThunk(
    "items/restoreItem",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/Item/DeleteItemById?inventoryitemid={"InventoryItemId":${args}}`);
       //console.log(data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  // export const getStorageUnit = createAsyncThunk(
  //   "items/getItems",
  //   async (args, thunkApi) => {
  //     try {
  //       const { data } = await axios.get(`/Menu/Items/LookupStorageUnit`);
  //       console.log(data)
  //       return thunkApi.fulfillWithValue(data);
  //     } catch (e) {
  //       return thunkApi.rejectWithValue(e.response.data);
  //     }
  //   }
  // );

  export const getFilteredData = createAsyncThunk("items/getFilteredData ", async (args, thunkApi) => {
      try {
        return (args);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  export const resetFilteredData = createAsyncThunk( "items/resetFilteredData ", async (args, thunkApi) => { 
      try {
        return (args);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );