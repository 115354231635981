import React from 'react';
import { useState } from "react";
import { useTranslation } from "react-i18next"
import { Flex, Text, useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import DeleteQuantityAdjustmentModal from './modal/DeleteQuantityAdjustmentModal';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 

const DeleteQuantityAdjustment = () => {
    const { t } = useTranslation();
    const [showModal,setShowModal]=useState(false)
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageQuantityAdjustments = checkIdExists(retrievedArray, 28);

if(showModal===true && ManageQuantityAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowModal(false);
};
/* -----------------------------------Privilige-------------------------------*/
  return (
    <div>
        <Flex
    bg="light" borderRadius={6} alignItems="center" justifyContent="space-between" gap={4} p={4}
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >
    <Text fontSize=".9rem" color="text">
      if you delete this branch, all of its data will be permanently deleted.
    </Text>
    <GButton
      bg="red"
      color="light"
      title={t("general.delete")}
      hover={{ bg: "red" }}
        onClick={() => setShowModal(true)}
    />

  

    {
        showModal&&(
            <DeleteQuantityAdjustmentModal onClose={() => setShowModal(false)}/>
        )
    }
   
  </Flex>
    </div>
  );
}

export default DeleteQuantityAdjustment;
