import { createSlice } from "@reduxjs/toolkit";
import {
  getTimedEventsBranches,
  createTimedEventsBranches,
  allBranchesInTimedEvent,
  deleteBranchInTimedEvent,
  getTagsInBranches,
} from "../services/timedEventsBranchesService";

let initialState = {
  isLoading: false,
  data: [],
  errors: null,
  tagsInBranch: [],
};

const timedEventsBranchesSlice = createSlice({
  name: "timedEventsBranches",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get Timed Events List  -------------------------------------------------------------------*/
    builder.addCase(getTimedEventsBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTimedEventsBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getTimedEventsBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create Timed Event  -------------------------------------------------------------------*/
    builder.addCase(createTimedEventsBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createTimedEventsBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createTimedEventsBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- all Branches In Timed Event  -------------------------------------------------------------------*/
    builder.addCase(allBranchesInTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(allBranchesInTimedEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(allBranchesInTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Branch In Timed Event  -------------------------------------------------------------------*/
    builder.addCase(deleteBranchInTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteBranchInTimedEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deleteBranchInTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------- Get Tags In Branches ---------------------------------------------------*/
    builder.addCase(getTagsInBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getTagsInBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInBranch =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getTagsInBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default timedEventsBranchesSlice.reducer;
