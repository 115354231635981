import React from 'react';

import GDeleteModal from "components/ui/modal/DeleteModal";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteSupllierInventory, getAllSuppliers } from '../../services/inventorySupplierService';
import { useToast } from "@chakra-ui/react";
import routes from "navigations/routes";
import { useTranslation } from 'react-i18next';


const DeleteSupplierModal = ({onClose}) => {

    const id = useParams()
    const dispatch= useDispatch()
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const navigate = useNavigate();
    const { t } = useTranslation();

  return (
    <GDeleteModal
    onClose={onClose}
    onSubmit={()=>{
      dispatch(deleteSupllierInventory(id.suppliersId)).unwrap().then(_ => {
            toast({ description: t("pages.suppliers.deleted") });
            dispatch(getAllSuppliers());
            onClose();
            navigate(`/${routes.inventory_suppliers}`);
          })
    }}
    />
  );
}

export default DeleteSupplierModal;
