import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";







  export const getAllProductsForOrderCall = createAsyncThunk(
    "callcenterProducts/getAllProductsForOrderCall",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Product/LookupProduct`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );



  export const getProductQuantaity = createAsyncThunk(
    "callcenterProducts/getProductQuantaity",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/CallCenter/ProductById?productid={"ProductId":${args}}`);
       console.log(data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const addQuantaty = createAsyncThunk(
    "callcenterProducts/addQuantaty",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/CallCenter/AddOrderProduct?orderproduct=${JSON.stringify(args)}`);
       console.log(data)
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );