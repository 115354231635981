import { useSelector } from "react-redux";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "./navbar/Navbar";
import Sidebar from "./sidebar/Sidebar";

const AppLayout = ({ children }) => {
  const sidebar = useSelector(state => state.sidebar);

  return (
    <>
      <Flex>
        <Sidebar />
        <Box
          width={{ base: "100%", lg: `calc(100% - ${sidebar.isActive ? "80px" : "260px"})` }}
          marginStart={{ base: 0, lg: sidebar.isActive ? "80px" : "260px" }} transition=".3s"
        >
          <Navbar />
          <Box
            minHeight="calc(100vh - 70px)"
            padding={{ base: 3, md: 6 }}
          >
            {children}
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default AppLayout