import { createSlice } from "@reduxjs/toolkit";
import {GetOrderByDate, getBranchesLookUpOrder,GetOrderDetails,getorderTypesforOrders,getCustomerPhoneNumberOrders,sendOrder, payThereceiptService} from "../services/ordersService"


let initialState = {
    isLoading: false,
    branches:[],
    ordersType:[],
    customers:[],
    ordersData:[],
    orderDetails:[],
    data:[],
    pages: 0,
    errors: null,
   
  };

  const ordersSlice = createSlice({
    name: "OrdersServices",
    initialState,

    extraReducers:(builder)=>{

        builder.addCase(getBranchesLookUpOrder.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getBranchesLookUpOrder.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.branches = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.branches)
            state.errors = null;
          })
    
          builder.addCase(getBranchesLookUpOrder.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          

          builder.addCase(GetOrderByDate.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(GetOrderByDate.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.ordersData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.ordersData)
            state.errors = null;
          })
    
          builder.addCase(GetOrderByDate.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          
          builder.addCase(GetOrderDetails.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(GetOrderDetails.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.orderDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.orderDetails)
            state.errors = null;
          })
    
          builder.addCase(GetOrderDetails.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          builder.addCase(getorderTypesforOrders.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getorderTypesforOrders.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.ordersType = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.branches)
            state.errors = null;
          })
    
          builder.addCase(getorderTypesforOrders.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          

          builder.addCase(getCustomerPhoneNumberOrders.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getCustomerPhoneNumberOrders.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.customers = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.branches)
            state.errors = null;
          })
    
          builder.addCase(getCustomerPhoneNumberOrders.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          builder.addCase(sendOrder.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(sendOrder.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //state.customers = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.branches)
            state.errors = null;
          })
    
          builder.addCase(sendOrder.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
          
          builder.addCase(payThereceiptService.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(payThereceiptService.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //state.customers = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.branches)
            state.errors = null;
          })
    
          builder.addCase(payThereceiptService.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
          
        }

})

export default ordersSlice.reducer