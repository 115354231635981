
export function getCurrentDate() {
    // Create a new Date object representing the current date and time
    const currentDate = new Date();
    
    // Extract the components of the date (year, month, day)
    const year = currentDate.getFullYear();
    // Month is zero-based, so we add 1 to get the actual month number
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    
    // Format the date as desired (e.g., YYYY-MM-DD)
    const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    
    return formattedDate;
}