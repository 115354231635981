import {
  Box,
  Input,
  Flex,
  SimpleGrid,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { GetFinancialPeriodsList } from "features/general_accounts/AccountingTree/service/AccountingTreeService";
import IncomeStatementtable from "features/general_accounts/IncomeStatement/components/IncomeStatementtable";
import { GetIncomeStatment } from "features/general_accounts/service/accountsTreeService";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils/FormatDate";
import { checkIdExists } from "utils/cheackAuth";
import NoDataInThisPeriod from "../components/NoDataInThisPeriod";

const IncomeStatementpage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const [showTable, SetShowTabel] = useState(false);
  const { FinancialPeriodList } = useSelector((state) => state.AccountTreeNew);
  const { incomeStatment_Data } = useSelector((state) => state.AccountTree);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    dispatch(GetFinancialPeriodsList());
  }, []);

  /* ------------------------------------- Authrzation ------------------------------ */
  const retrievedArray = useDecryptedPrivilege();
  const ManageAccountant_IncomeStatement = checkIdExists(retrievedArray, 1018);

  if (ManageAccountant_IncomeStatement === false) {
    return <NotAuthorizedCompo />;
  }

  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.Ledger.income_statement")}
          </Text>
        </Flex>
      </Box>
      <form
        onSubmit={handleSubmit((values) => {
          values.FinancialPeriodsId =
            values.FinancialPeriodsId?.FinancialPeriodsId;
          if (values?.FinancialPeriodsId === undefined) {
            toast2({ description: t("validation.choose_first") });
          } else {
            dispatch(GetIncomeStatment(values))
              .unwrap()
              .then((_) => {
                toast({
                  description: t("pages.general_accounts.dataFetchingOk"),
                });
                setValue("DateTo", 0);
                setValue("DateFrom", 0);
                // setValue("FinancialPeriodsId", 0);
              });
          }
        })}
      >
        <Box paddingX={5}>
          <Box
            bg="light"
            marginInline={-6}
            padding={6}
            borderTop="1px solid"
            borderColor="border"
            marginBottom={6}
          >
            <SimpleGrid
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
              style={{ width: "100%" }}
              columnGap={20}
              rowGap={5}
            >
              <GridItem colSpan={2}>
                <MultiSelect
                  title={t("pages.DailyRestrictions.finacial_period")}
                  data={
                    FinancialPeriodList &&
                    FinancialPeriodList?.map((el) => ({
                      ...el,
                      label: ` ${t(
                        "pages.AccountAdd.date_From"
                      )} : ${formatDate(el.DateFrom)} ${t(
                        "pages.AccountAdd.date_to"
                      )} ${formatDate(el.DateTo)}`,
                      value: el.FinancialPeriodsId,
                    }))
                  }
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("FinancialPeriodsId", e)}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl isInvalid={errors.DateFrom}>
                  <FormLabel
                    htmlFor="DateFrom"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.Ledger.period_from")}
                  </FormLabel>
                  <Controller
                    name="DateFrom"
                    control={control}
                    // defaultValue={`${year}-${month}-${day}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.Ledger.period_from")}
                        onChange={(e) => {
                          handleInputChange(e, "DateFrom");
                        }}
                        type="date"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.DateFrom && errors.DateFrom.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={1}>
                <FormControl isInvalid={errors.DateTo}>
                  <FormLabel
                    htmlFor="DateTo"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.Ledger.period_to")}
                  </FormLabel>
                  <Controller
                    name="DateTo"
                    control={control}
                    // defaultValue={`${year}-${month}-${day}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.Ledger.period_to")}
                        onChange={(e) => {
                          handleInputChange(e, "DateTo");
                        }}
                        type="date"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.DateTo && errors.DateTo.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </SimpleGrid>

            <GButton
              type="submit"
              title={t("pages.Ledger.dispaly")}
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              style={{ marginTop: "15px" }}
            />
          </Box>
        </Box>
      </form>

      {incomeStatment_Data?.length > 0 ? (
        <IncomeStatementtable data={incomeStatment_Data} />
      ) : (
        <NoDataInThisPeriod />
      )}
    </>
  );
};

export default IncomeStatementpage;
