import { createSlice } from "@reduxjs/toolkit";
import {
  createBranchTag,
  updateBranchTag,
  getTagsInBranches,
  getBranchesTags,
} from "../services/branchesTagsService";

let initialState = {
  isLoading: false,
  data: [],
  section: null,
  pages: 0,
  errors: null,
  BranchesTags : [],
};

const branchesTagsSlice = createSlice({
  name: "branchesTags",
  initialState,
  extraReducers: (builder) => {

    builder.addCase(getTagsInBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTagsInBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getTagsInBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

// getBranchesTags
    builder.addCase(getBranchesTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getBranchesTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.BranchesTags = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getBranchesTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })



    builder.addCase(createBranchTag.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createBranchTag.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createBranchTag.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateBranchTag.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateBranchTag.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const indexAt = state.data.findIndex(section => section.id === payload.id);
      // state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateBranchTag.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default branchesTagsSlice.reducer;