import React, { useState } from "react";
import { Box, Text, Flex, SimpleGrid, Button, Spinner } from "@chakra-ui/react";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import EditBasicModal from "../modal/EditBasicModal";
import GButton from "components/ui/button/Button";

const BasicInfoCompo = ({
  details,
  classifications,
  MartialStatusDropDown,
  ReligionDropDown,
  RecruitmentSituationDropDown,
  id,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [showEditBasicInfoModal, setShowEditBasicInfoModal] = useState(false);
  if (isLoading) {
    return (
      <Flex
        bg="light"
        p={4}
        gap={2}
        justifyContent="center"
        flexDir={"column"}
        alignItems={"center"}
      >
        <Spinner />
        <Text fontWeight={"bold"}> {t("general.loading")} </Text>
      </Flex>
    );
  }

  return (
    <Box
      marginBottom={10}
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      border="1px"
      borderColor={"gray.400"}
      width="100%"
      padding="10px"
    >
      <Box padding={3}>
        <Box>
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"2px"}
            borderColor={"gray.300"}
            padding={3}
          >
            <Flex gap={2} alignItems={"center"}>
              <HiOutlineClipboardDocumentList style={{ fontSize: "22px" }} />
              <Text fontSize="xl" fontWeight={"bold"} color="gray.600">
                {t("pages.hr.basic_information")}
              </Text>
            </Flex>
            <Box onClick={() => setShowEditBasicInfoModal(true)}>
              <GButton
                title={t("general.edit")}
                color="black"
                border="1px solid gray"
              />
            </Box>
          </Flex>
        </Box>
        <Box padding={3}>
          <SimpleGrid columns={2} spacing={5}>
            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.Employee_code")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.EmployeeCode}
              </Text>
            </Box>
            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.Employee_name")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.FullEnName}
              </Text>
            </Box>
            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.date_of_birth")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.BirthDate}
              </Text>
            </Box>
            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.Marital_Status")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.FamilyStatusNameEn}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
      {showEditBasicInfoModal && (
        <EditBasicModal
          id={id}
          classifications={classifications}
          MartialStatusDropDown={MartialStatusDropDown}
          ReligionDropDown={ReligionDropDown}
          RecruitmentSituationDropDown={RecruitmentSituationDropDown}
          onClose={() => setShowEditBasicInfoModal(false)}
          details={details}
        />
      )}
    </Box>
  );
};

export default BasicInfoCompo;
