import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {Box,Checkbox, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import { axios } from "services/axios";
import { useTranslation } from "react-i18next";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";

const Loyality = () => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });
  const {control,register,setValue, reset, handleSubmit , formState: { errors }, trigger} = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const getLoyality = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get("/GeneralSettingJson/GetSettings");
      if (typeof data === "object" && Array.isArray(data)) {
        const jsonData = JSON.parse(data[0]?.jsonData)[0] || {};
        //console.log(jsonData)
        reset(jsonData);
      }
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  }
  const updateLoyality = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage/Setting/Loyalty/AddLoyalty?loyalty=${JSON.stringify(values)}`);
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  }

  useEffect(() => {
    getLoyality();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const [EnableLoyalty,setEnableLoyalty]=useState(false)
  //console.log(EnableLoyalty)


  
//   <Checkbox
//   {...register("EnableLoyalty")}
//   textTransform="capitalize"
// >
//   {t("pages.management.settings.loyality.enable_loyalty")}
// </Checkbox>


  return (
    <>
      <form
        onSubmit={handleSubmit(values => {
          values.LoyaltyMethod=values.LoyaltyMethod.value
          values.RewardType=values.RewardType.value
          // values.Loyaltyid=3 
          //console.log(values)
          updateLoyality(values)
            .then(_ => toast({ description: t("pages.management.settings.settings_updated") }))
            .catch(_ => toast({ status: "error", description: "something went wrong!" }))
     
        })}
      >
        <Stack spacing={6} bg="light" p={4}>


          <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px"
          }}
        >
          <input
            type="checkbox"
            checked={EnableLoyalty}
            onChange={() => setEnableLoyalty(!EnableLoyalty)}
          />
          <label>{t("pages.management.settings.loyality.enable_loyalty")}</label>
        </div>

        {
          EnableLoyalty&&(
            <>
            <MultiSelect
            title={t("pages.management.settings.loyality.loyalty_method")}
            data={[{value:1,label:"points"},{value:2,label:"purchase"}]}
            // data={timezone?.data.map(el => ({ ...el, label: el.TimeZoneName, value: el.TimeZoneId }))}
            onChange={(e) => setValue("LoyaltyMethod", e)}
            isMulti={false}
          />

          <FormControl isInvalid={errors.MinimumOrderPrice}>
                <FormLabel
                  htmlFor="MinimumOrderPrice"
                  style={{ textTransform: "capitalize" }}
                >
                   {t("pages.management.settings.loyality.minimum_order_price")}
                </FormLabel>
                <Controller
                  name="MinimumOrderPrice"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.management.settings.loyality.minimum_order_price")}
                      onChange={(e) => {
                        handleInputChange(e, "MinimumOrderPrice");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.MinimumOrderPrice &&
                    errors.MinimumOrderPrice.message}
                </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.EarningDelay}>
                <FormLabel
                  htmlFor="EarningDelay"
                  style={{ textTransform: "capitalize" }}
                >
                   {t("pages.management.settings.loyality.earning_delay")}
                </FormLabel>
                <Controller
                  name="EarningDelay"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.management.settings.loyality.minimum_order_price")}
                      onChange={(e) => {
                        handleInputChange(e, "EarningDelay");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.EarningDelay &&
                    errors.EarningDelay.message}
                </FormErrorMessage>
          </FormControl>

          <MultiSelect
          title={t("pages.management.settings.loyality.reward_type")}
          data={[{value:1,label:"Discount Percentage "},{value:2,label:"Discount Amount"}]}
          // data={timezone?.data.map(el => ({ ...el, label: el.TimeZoneName, value: el.TimeZoneId }))}
          onChange={(e) => setValue("RewardType", e)}
          isMulti={false}
        />


          <FormControl isInvalid={errors.RewardValue}>
                <FormLabel
                  htmlFor="RewardValue"
                  style={{ textTransform: "capitalize" }}
                >
                   {t("pages.management.settings.loyality.reward_value")}
                </FormLabel>
                <Controller
                  name="RewardValue"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.management.settings.loyality.reward_value")}
                      onChange={(e) => {
                        handleInputChange(e, "RewardValue");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.RewardValue &&
                    errors.RewardValue.message}
                </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.MaxDiscountAmount}>
                <FormLabel
                  htmlFor="MaxDiscountAmount"
                  style={{ textTransform: "capitalize" }}
                >
                   {t("pages.management.settings.loyality.maximum_discount_amount")}
                </FormLabel>
                <Controller
                  name="MaxDiscountAmount"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.management.settings.loyality.maximum_discount_amount")}
                      onChange={(e) => {
                        handleInputChange(e, "MaxDiscountAmount");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.MaxDiscountAmount &&
                    errors.MaxDiscountAmount.message}
                </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.RewardRequiredPoints}>
                <FormLabel
                  htmlFor="RewardRequiredPoints"
                  style={{ textTransform: "capitalize" }}
                >
                   {t("pages.management.settings.loyality.reward_required_points")}
                </FormLabel>
                <Controller
                  name="RewardRequiredPoints"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.management.settings.loyality.reward_required_points")}
                      onChange={(e) => {
                        handleInputChange(e, "RewardRequiredPoints");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.RewardRequiredPoints &&
                    errors.RewardRequiredPoints.message}
                </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.RewardValidity}>
                <FormLabel
                  htmlFor="RewardValidity"
                  style={{ textTransform: "capitalize" }}
                >
                   {t("pages.management.settings.loyality.reward_validity")}
                </FormLabel>
                <Controller
                  name="RewardValidity"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.management.settings.loyality.reward_validity")}
                      onChange={(e) => {
                        handleInputChange(e, "RewardValidity");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.RewardValidity &&
                    errors.RewardValidity.message}
                </FormErrorMessage>
          </FormControl>

          <Checkbox
              {...register("IsSendSMS")}
              textTransform="capitalize"
            >
              {t("pages.management.settings.loyality.send_notification")}
          </Checkbox>
          
          <Flex justifyContent="flex-start">
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              isDisabled={isLoading}
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              error={errors.email}
            />
          </Flex>
            </>
          )
        }
     

    


        </Stack>
      </form>
    </>
  )
}

export default Loyality