import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPrmotionsTime } from "../services/promotionsService";
import { getorderTypesforcallCenter } from "features/callCenter/service/callCenterService";

const CreatePromotionPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrmotionsTime());
  }, [dispatch]);

  const Data = useSelector((state) => state.promotions);

  //console.log(Data.prmotionTime)

  const {
    handleSubmit,
    setValue,
    register,
    control,
    getValues,
    watch,
    formState: { errors },
    trigger,
  } = useForm();

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const [selectedRadio, setSelectedRadio] = useState(null);

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };

  const [selectedRadio2, setSelectedRadio2] = useState(null);

  const handleRadioChange2 = (value) => {
    setSelectedRadio2(value);
  };

  const [selectedRadio3, setSelectedRadio3] = useState(null);

  const handleRadioChange3 = (value) => {
    setSelectedRadio3(value);
  };

  //console.log(selectedRadio2);

  const devicesTypes = [];

  const radioContainerStyle = {
    marginBottom: "10px",
  };

  const radioLabelStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
  };

  const radioInputStyle = {
    width: "20px",
    height: "20px",
    marginRight: "8px",
    marginLeft: "8px",
  };

  //console.log(selectedRadio)
  const DiscountType = [
    {
      label: "precentage",
      value: 1,
    },
    {
      label: "value",
      value: 2,
    },
  ];

  const [SelectedDiscountType, SetSlectedDiscountType] = useState(0);
  //console.log(SelectedDiscountType.value)

  const defaultDays = [
    {
      DayId: 1,
      DayName: "Sun",
    },
    {
      DayId: 2,
      DayName: "Mon",
    },
    {
      DayId: 3,
      DayName: "Tue",
    },
    {
      DayId: 4,
      DayName: "Wed",
    },
    {
      DayId: 5,
      DayName: "Thu",
    },
    {
      DayId: 6,
      DayName: "Fri",
    },
    {
      DayId: 7,
      DayName: "Sat",
    },
  ];

  function extractDayName(obj) {
    // Check if the input object has a 'days' key
    if ("days" in obj && Array.isArray(obj.days)) {
      // Extract 'DayName' from each object in the 'days' array
      const dayNames = obj.days.map((day) => day.DayName);

      // Create an object with keys as day names and values as 1 or 0
      const dayNamesObj = {};
      defaultDays.forEach((day) => {
        const isDayPresent = dayNames.includes(day.DayName);
        dayNamesObj[`Is${day.DayName}`] = isDayPresent ? 1 : 0;
      });

      // Create a new object with the same keys and values except for 'days' and 'DayNames'
      const newObj = {
        ...obj,
        days: undefined,
        DayNames: undefined,
        ...dayNamesObj,
      };

      // Create an array with the key "PromotionsBasic" containing the modified object
      const promotionsBasicArray = [
        {
          PromoName: newObj.PromoName,
          PromoNameLoc: newObj.PromoNameLoc,
          StartDate: newObj.StartDate,
          EndDate: newObj.EndDate,
          StartTimeId: newObj.StartTimeId.DayTimeId,
          EndTimeId: newObj.EndTimeId.DayTimeId,
          IsSat: newObj.IsSat,
          IsSun: newObj.IsSun,
          IsMon: newObj.IsMon,
          IsTue: newObj.IsTue,
          IsWed: newObj.IsWed,
          IsThu: newObj.IsThu,
          IsFri: newObj.IsFri,
          Priority: newObj.Priority,
          IncludeModifiers: newObj.IncludeModifiers,
          MinimumAmount: newObj.MinimumAmount,
        },
      ];

      // Create the final object with the "PromotionsBasic" key
      const finalObject = { PromotionsBasic: promotionsBasicArray };

      return finalObject;
    }

    // If 'days' key is not present or not an array, return the original object
    return obj;
  }

  function mergePromoOrderT(promotions, orderTypes) {
    // Assuming promotions is an object with key "PromotionsBasic"
    if (
      !promotions ||
      !promotions.PromotionsBasic ||
      !Array.isArray(orderTypes)
    ) {
      // Handle invalid inputs
      return null;
    }

    // Clone the promotions object to avoid modifying the original object
    const mergedPromotions = { ...promotions };

    // Add the second input array under the key "PromoOrderT"
    mergedPromotions.PromoOrderT = orderTypes;

    return mergedPromotions;
  }

  useEffect(() => {
    dispatch(getorderTypesforcallCenter());
  }, [dispatch]);

  const orderTypes = useSelector((state) => state.callcenter);

  //console.log(orderTypes.orderType)

  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.MinimumAmount = "10";
          if (values.IncludeModifiers) {
            values.IncludeModifiers = 1;
          } else {
            values.IncludeModifiers = 0;
          }
          values.PromoOrderT = values.PromoOrderT?.map((el) => ({
            OrderTypeId: el.OrderTypeId,
          }));
          //console.log(values.PromoOrderT)
          const outputObject = extractDayName(values);

          //console.log(outputObject);

          const result = mergePromoOrderT(outputObject, values.PromoOrderT);
          console.log(result);
        })}
      >
        <div style={{ marginTop: "15px" }}>
          <BreadCrumb>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap={4}
              flexWrap="wrap"
            >
              <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
                {t("pages.promotions.new_promotion")}
              </Text>

              <Flex gap={2} flexWrap="wrap">
                <GButton
                  title={t("pages.promotions.save_Promotion")}
                  //onClick={navigateCreate}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    bg: "none",
                    borderColor: "primary",
                    color: "primary",
                  }}
                  type="submit"
                />
              </Flex>
            </Flex>
          </BreadCrumb>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            fontSize="1.8rem"
            color="dark"
            textTransform="capitalize"
            marginBottom={5}
            width={"50%"}
          >
            {t("pages.promotions.Basic_Details")}{" "}
          </Text>
          <Stack
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            width={"50%"}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <FormControl isInvalid={errors.PromoName}>
              <FormLabel
                htmlFor="PromoName"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.devices.name")}
              </FormLabel>
              <Controller
                name="PromoName"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.devices.name")}
                    onChange={(e) => handleInputChange(e, "PromoName")}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.PromoName && errors.PromoName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.PromoNameLoc}>
              <FormLabel
                htmlFor="PromoNameLoc"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.promotions.name_localized")}
              </FormLabel>
              <Controller
                name="PromoNameLoc"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.promotions.name_localized")}
                    onChange={(e) => handleInputChange(e, "PromoNameLoc")}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.PromoNameLoc && errors.PromoNameLoc.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.StartDate}>
              <FormLabel
                htmlFor="StartDate"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.promotions.startDate")}
              </FormLabel>
              <Controller
                name="StartDate"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="date" // Change input type to date
                    placeholder={t("pages.promotions.startDate")}
                    onChange={(e) => handleInputChange(e, "StartDate")}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.StartDate && errors.StartDate.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.EndDate}>
              <FormLabel
                htmlFor="EndDate"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.promotions.endDate")}
              </FormLabel>
              <Controller
                name="EndDate"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="date" // Change input type to date
                    placeholder={t("pages.promotions.endDate")}
                    onChange={(e) => handleInputChange(e, "EndDate")}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.EndDate && errors.EndDate.message}
              </FormErrorMessage>
            </FormControl>

            <MultiSelect
              data={
                Data.prmotionTime &&
                Data.prmotionTime.map((el) => ({
                  ...el,
                  label: el.DayTimeName,
                  value: el.DayTimeId,
                }))
              }
              title={t("pages.promotions.startTime")}
              isMulti={false}
              onChange={(e) => setValue("StartTimeId", e)}
            />

            <MultiSelect
              data={
                Data.prmotionTime &&
                Data.prmotionTime.map((el) => ({
                  ...el,
                  label: el.DayTimeName,
                  value: el.DayTimeId,
                }))
              }
              title={t("pages.promotions.endTime")}
              isMulti={false}
              onChange={(e) => setValue("EndTimeId", e)}
            />

            <MultiSelect
              data={
                defaultDays &&
                defaultDays.map((el) => ({
                  ...el,
                  label: el.DayName,
                  value: el.DayId,
                }))
              }
              title={t("pages.promotions.Applies_On_Days")}
              isMulti={true}
              onChange={(e) => setValue("days", e)}
            />

            <MultiSelect
              data={
                orderTypes.orderType &&
                orderTypes.orderType.map((el) => ({
                  ...el,
                  label: el.OrderTypeName,
                  value: el.OrderTypeId,
                }))
              }
              title={t("pages.promotions.Applies_On_Order_Types")}
              isMulti={true}
              onChange={(e) => setValue("PromoOrderT", e)}
            />

            <FormControl isInvalid={errors.Priority}>
              <FormLabel
                htmlFor="Priority"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.promotions.Priority")}
              </FormLabel>
              <Controller
                name="Priority"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.promotions.Priority")}
                    onChange={(e) => handleInputChange(e, "Priority")}
                  />
                )}
              />
            </FormControl>

            <Checkbox {...register("IncludeModifiers")}>
              {t("pages.promotions.include_modifiers")}
            </Checkbox>
          </Stack>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Text
            fontSize="1.8rem"
            color="dark"
            textTransform="capitalize"
            marginBottom={5}
            width={"50%"}
          >
            {t("pages.promotions.Promotion_Details")}
          </Text>
          <Stack
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            width={"50%"}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
              {t("pages.promotions.Promotion_Type")}
            </Text>
            <div>
              <div style={radioContainerStyle}>
                <label style={radioLabelStyle}>
                  <input
                    type="radio"
                    value="Basic"
                    checked={selectedRadio === "Basic"}
                    onChange={() => handleRadioChange("Basic")}
                    style={radioInputStyle}
                  />
                  {t("pages.promotions.Basic")}
                </label>
              </div>

              <div style={radioContainerStyle}>
                <label style={radioLabelStyle}>
                  <input
                    type="radio"
                    value="Advanced"
                    checked={selectedRadio === "Advanced"}
                    onChange={() => handleRadioChange("Advanced")}
                    style={radioInputStyle}
                  />
                  {t("pages.promotions.Advanced")}
                </label>
              </div>
            </div>

            {(() => {
              if (selectedRadio === "Basic") {
                return (
                  <>
                    <MultiSelect
                      data={
                        DiscountType &&
                        DiscountType.map((el) => ({
                          ...el,
                          label: el.label,
                          value: el.value,
                        }))
                      }
                      title={t("pages.promotions.Discount_Type")}
                      isMulti={false}
                      onChange={(e) => {
                        setValue("DevicesTypeId", e);
                        SetSlectedDiscountType(e);
                      }}
                    />

                    {SelectedDiscountType?.value == 1 ? (
                      <>
                        <FormControl isInvalid={errors.Discount_Amount}>
                          <FormLabel
                            htmlFor="Discount_Amount"
                            style={{ textTransform: "capitalize" }}
                          >
                            {t("pages.promotions.Discount_Amount")}
                            (%)
                          </FormLabel>
                          <Controller
                            name="Discount_Amount"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: (value) => {
                                const intValue = parseInt(value, 10);
                                return (
                                  intValue >= 0 ||
                                  t("validation.must_be_non_negative")
                                );
                              },
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder={t(
                                  "pages.promotions.Discount_Amount"
                                )}
                                onChange={(e) => {
                                  handleInputChange(e, "Discount_Amount");
                                }}
                                type="number"
                                min="0"
                              />
                            )}
                          />

                          <FormErrorMessage>
                            {errors.Discount_Amount &&
                              errors.Discount_Amount.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.Maximum_Discount_Value}>
                          <FormLabel
                            htmlFor="Maximum_Discount_Value"
                            style={{ textTransform: "capitalize" }}
                          >
                            {t("pages.promotions.Maximum_Discount_Value")}
                            (%)
                          </FormLabel>
                          <Controller
                            name="Maximum_Discount_Value"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: (value) => {
                                const intValue = parseInt(value, 10);
                                return (
                                  intValue >= 0 ||
                                  t("validation.must_be_non_negative")
                                );
                              },
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder={t(
                                  "pages.promotions.Maximum_Discount_Value"
                                )}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    "Maximum_Discount_Value"
                                  );
                                }}
                                type="number"
                                min="0"
                              />
                            )}
                          />

                          <FormErrorMessage>
                            {errors.Maximum_Discount_Value &&
                              errors.Maximum_Discount_Value.message}
                          </FormErrorMessage>
                        </FormControl>

                        <MultiSelect
                          data={
                            devicesTypes.data &&
                            devicesTypes.data.map((el) => ({
                              ...el,
                              label: el.DevicesTypeName,
                              value: el.DevicesTypeId,
                            }))
                          }
                          title={t("pages.promotions.Products")}
                          isMulti={false}
                          onChange={(e) => setValue("DevicesTypeId", e)}
                        />
                      </>
                    ) : (
                      <>
                        <FormControl isInvalid={errors.Discount_Amount}>
                          <FormLabel
                            htmlFor="Discount_Amount"
                            style={{ textTransform: "capitalize" }}
                          >
                            {t("pages.promotions.Discount_Amount")}
                          </FormLabel>
                          <Controller
                            name="Discount_Amount"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: (value) => {
                                const intValue = parseInt(value, 10);
                                return (
                                  intValue >= 0 ||
                                  t("validation.must_be_non_negative")
                                );
                              },
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder={t(
                                  "pages.promotions.Discount_Amount"
                                )}
                                onChange={(e) => {
                                  handleInputChange(e, "Discount_Amount");
                                }}
                                type="number"
                                min="0"
                              />
                            )}
                          />

                          <FormErrorMessage>
                            {errors.Discount_Amount &&
                              errors.Discount_Amount.message}
                          </FormErrorMessage>
                        </FormControl>

                        <MultiSelect
                          data={
                            devicesTypes.data &&
                            devicesTypes.data.map((el) => ({
                              ...el,
                              label: el.DevicesTypeName,
                              value: el.DevicesTypeId,
                            }))
                          }
                          title={t("pages.promotions.Products")}
                          isMulti={false}
                          onChange={(e) => setValue("DevicesTypeId", e)}
                        />
                      </>
                    )}
                  </>
                );
              } else if (selectedRadio === "Advanced") {
                return (
                  <>
                    <Text
                      fontSize="1.2rem"
                      color="dark"
                      textTransform="capitalize"
                    >
                      {t("pages.promotions.When_customer")}
                    </Text>
                    <div>
                      <div style={radioContainerStyle}>
                        <label style={radioLabelStyle}>
                          <input
                            type="radio"
                            value="Buys_Quantity"
                            checked={selectedRadio2 === "Buys_Quantity"}
                            onChange={() => handleRadioChange2("Buys_Quantity")}
                            style={radioInputStyle}
                          />
                          {t("pages.promotions.Buys_Quantity")}
                        </label>
                      </div>

                      <div style={radioContainerStyle}>
                        <label style={radioLabelStyle}>
                          <input
                            type="radio"
                            value="Spends"
                            checked={selectedRadio2 === "Spends"}
                            onChange={() => handleRadioChange2("Spends")}
                            style={radioInputStyle}
                          />
                          {t("pages.promotions.Spends")}
                        </label>
                      </div>
                    </div>

                    {selectedRadio2 === "Spends" && (
                      <>
                        <FormControl isInvalid={errors.amount}>
                          <FormLabel
                            htmlFor="amount"
                            style={{ textTransform: "capitalize" }}
                          >
                            {t("pages.promotions.amount")}
                          </FormLabel>
                          <Controller
                            name="amount"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: (value) => {
                                const intValue = parseInt(value, 10);
                                return (
                                  intValue >= 0 ||
                                  t("validation.must_be_non_negative")
                                );
                              },
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder={t("pages.promotions.amount")}
                                onChange={(e) => {
                                  handleInputChange(e, "amount");
                                }}
                                type="number"
                                min="0"
                              />
                            )}
                          />

                          <FormErrorMessage>
                            {errors.amount && errors.amount.message}
                          </FormErrorMessage>
                        </FormControl>

                        <MultiSelect
                          data={
                            devicesTypes.data &&
                            devicesTypes.data.map((el) => ({
                              ...el,
                              label: el.DevicesTypeName,
                              value: el.DevicesTypeId,
                            }))
                          }
                          title={t("pages.promotions.Products")}
                          isMulti={false}
                          onChange={(e) => setValue("DevicesTypeId", e)}
                        />
                      </>
                    )}

                    {selectedRadio2 === "Buys_Quantity" && (
                      <>
                        <FormControl isInvalid={errors.Buys_Quantity}>
                          <FormLabel
                            htmlFor="Buys_Quantity"
                            style={{ textTransform: "capitalize" }}
                          >
                            {t("pages.promotions.Quantity")}
                          </FormLabel>
                          <Controller
                            name="Buys_Quantity"
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: (value) => {
                                const intValue = parseInt(value, 10);
                                return (
                                  intValue >= 0 ||
                                  t("validation.must_be_non_negative")
                                );
                              },
                            }}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder={t("pages.promotions.Quantity")}
                                onChange={(e) => {
                                  handleInputChange(e, "Buys_Quantity");
                                }}
                                type="number"
                                min="0"
                              />
                            )}
                          />

                          <FormErrorMessage>
                            {errors.Buys_Quantity &&
                              errors.Buys_Quantity.message}
                          </FormErrorMessage>
                        </FormControl>

                        <MultiSelect
                          data={
                            devicesTypes.data &&
                            devicesTypes.data.map((el) => ({
                              ...el,
                              label: el.DevicesTypeName,
                              value: el.DevicesTypeId,
                            }))
                          }
                          title={t("pages.promotions.Applies_On_Order_Types")}
                          isMulti={false}
                          onChange={(e) => setValue("DevicesTypeId", e)}
                        />
                      </>
                    )}

                    {selectedRadio2 && (
                      <>
                        <div>
                          <div style={radioContainerStyle}>
                            <label style={radioLabelStyle}>
                              <input
                                type="radio"
                                value="Get_discount_on_order"
                                checked={
                                  selectedRadio3 === "Get_discount_on_order"
                                }
                                onChange={() =>
                                  handleRadioChange3("Get_discount_on_order")
                                }
                                style={radioInputStyle}
                              />
                              {t("pages.promotions.Get_discount_on_order")}
                            </label>
                          </div>

                          <div style={radioContainerStyle}>
                            <label style={radioLabelStyle}>
                              <input
                                type="radio"
                                value="Get_discount_on_product"
                                checked={
                                  selectedRadio3 === "Get_discount_on_product"
                                }
                                onChange={() =>
                                  handleRadioChange3("Get_discount_on_product")
                                }
                                style={radioInputStyle}
                              />
                              {t("pages.promotions.Get_discount_on_product")}
                            </label>
                          </div>

                          {selectedRadio2 === "Buys_Quantity" && (
                            <div style={radioContainerStyle}>
                              <label style={radioLabelStyle}>
                                <input
                                  type="radio"
                                  value="Pay_fixed_amount"
                                  checked={
                                    selectedRadio3 === "Pay_fixed_amount"
                                  }
                                  onChange={() =>
                                    handleRadioChange3("Pay_fixed_amount")
                                  }
                                  style={radioInputStyle}
                                />
                                {t("pages.promotions.Pay_fixed_amount")}
                              </label>
                            </div>
                          )}

                          {selectedRadio3 === "Get_discount_on_order" && (
                            <>
                              <MultiSelect
                                data={
                                  devicesTypes.data &&
                                  devicesTypes.data.map((el) => ({
                                    ...el,
                                    label: el.DevicesTypeName,
                                    value: el.DevicesTypeId,
                                  }))
                                }
                                title={t("pages.promotions.Discount_Type")}
                                isMulti={false}
                                onChange={(e) => setValue("Discount_Type", e)}
                              />

                              <FormControl isInvalid={errors.Discount_Amount}>
                                <FormLabel
                                  htmlFor="Discount_Amount"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {t("pages.promotions.Discount_Amount")}
                                  (%)
                                </FormLabel>
                                <Controller
                                  name="Discount_Amount"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    validate: (value) => {
                                      const intValue = parseInt(value, 10);
                                      return (
                                        intValue >= 0 ||
                                        t("validation.must_be_non_negative")
                                      );
                                    },
                                  }}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      placeholder={t(
                                        "pages.promotions.Discount_Amount"
                                      )}
                                      onChange={(e) => {
                                        handleInputChange(e, "Discount_Amount");
                                      }}
                                      type="number"
                                      min="0"
                                    />
                                  )}
                                />

                                <FormErrorMessage>
                                  {errors.Discount_Amount &&
                                    errors.Discount_Amount.message}
                                </FormErrorMessage>
                              </FormControl>

                              <FormControl
                                isInvalid={errors.Maximum_Discount_Value}
                              >
                                <FormLabel
                                  htmlFor="Maximum_Discount_Value"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {t("pages.promotions.Maximum_Discount_Value")}
                                </FormLabel>
                                <Controller
                                  name="Maximum_Discount_Value"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    validate: (value) => {
                                      const intValue = parseInt(value, 10);
                                      return (
                                        intValue >= 0 ||
                                        t("validation.must_be_non_negative")
                                      );
                                    },
                                  }}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      placeholder={t(
                                        "pages.promotions.Maximum_Discount_Value"
                                      )}
                                      onChange={(e) => {
                                        handleInputChange(
                                          e,
                                          "Maximum_Discount_Value"
                                        );
                                      }}
                                      type="number"
                                      min="0"
                                    />
                                  )}
                                />

                                <FormErrorMessage>
                                  {errors.Maximum_Discount_Value &&
                                    errors.Maximum_Discount_Value.message}
                                </FormErrorMessage>
                              </FormControl>
                            </>
                          )}

                          {selectedRadio3 === "Get_discount_on_product" && (
                            <>
                              <MultiSelect
                                data={
                                  devicesTypes.data &&
                                  devicesTypes.data.map((el) => ({
                                    ...el,
                                    label: el.DevicesTypeName,
                                    value: el.DevicesTypeId,
                                  }))
                                }
                                title={t("pages.promotions.Discount_Type")}
                                isMulti={false}
                                onChange={(e) => setValue("Discount_Type", e)}
                              />

                              <FormControl isInvalid={errors.Discount_Amount}>
                                <FormLabel
                                  htmlFor="Discount_Amount"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {t("pages.promotions.Discount_Amount")}
                                  (%)
                                </FormLabel>
                                <Controller
                                  name="Discount_Amount"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    validate: (value) => {
                                      const intValue = parseInt(value, 10);
                                      return (
                                        intValue >= 0 ||
                                        t("validation.must_be_non_negative")
                                      );
                                    },
                                  }}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      placeholder={t(
                                        "pages.promotions.Discount_Amount"
                                      )}
                                      onChange={(e) => {
                                        handleInputChange(e, "Discount_Amount");
                                      }}
                                      type="number"
                                      min="0"
                                    />
                                  )}
                                />

                                <FormErrorMessage>
                                  {errors.Discount_Amount &&
                                    errors.Discount_Amount.message}
                                </FormErrorMessage>
                              </FormControl>

                              <FormControl
                                isInvalid={errors.Maximum_Discount_Value}
                              >
                                <FormLabel
                                  htmlFor="Maximum_Discount_Value"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {t("pages.promotions.Maximum_Discount_Value")}
                                </FormLabel>
                                <Controller
                                  name="Maximum_Discount_Value"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    validate: (value) => {
                                      const intValue = parseInt(value, 10);
                                      return (
                                        intValue >= 0 ||
                                        t("validation.must_be_non_negative")
                                      );
                                    },
                                  }}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      placeholder={t(
                                        "pages.promotions.Maximum_Discount_Value"
                                      )}
                                      onChange={(e) => {
                                        handleInputChange(
                                          e,
                                          "Maximum_Discount_Value"
                                        );
                                      }}
                                      type="number"
                                      min="0"
                                    />
                                  )}
                                />

                                <FormErrorMessage>
                                  {errors.Maximum_Discount_Value &&
                                    errors.Maximum_Discount_Value.message}
                                </FormErrorMessage>
                              </FormControl>

                              <FormControl
                                isInvalid={errors.Number_of_products}
                              >
                                <FormLabel
                                  htmlFor="Number_of_products"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {t("pages.promotions.Number_of_products")}
                                </FormLabel>
                                <Controller
                                  name="Number_of_products"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    validate: (value) => {
                                      const intValue = parseInt(value, 10);
                                      return (
                                        intValue >= 0 ||
                                        t("validation.must_be_non_negative")
                                      );
                                    },
                                  }}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      placeholder={t(
                                        "pages.promotions.Number_of_products"
                                      )}
                                      onChange={(e) => {
                                        handleInputChange(
                                          e,
                                          "Number_of_products"
                                        );
                                      }}
                                      type="number"
                                      min="0"
                                    />
                                  )}
                                />

                                <FormErrorMessage>
                                  {errors.Number_of_products &&
                                    errors.Number_of_products.message}
                                </FormErrorMessage>
                              </FormControl>

                              <MultiSelect
                                data={
                                  devicesTypes.data &&
                                  devicesTypes.data.map((el) => ({
                                    ...el,
                                    label: el.DevicesTypeName,
                                    value: el.DevicesTypeId,
                                  }))
                                }
                                title={t("pages.promotions.Discount_Type")}
                                isMulti={false}
                                onChange={(e) => setValue("Discount_Type", e)}
                              />
                            </>
                          )}

                          {selectedRadio3 === "Pay_fixed_amount" && (
                            <>
                              <FormControl isInvalid={errors.Fixed_amount}>
                                <FormLabel
                                  htmlFor="Fixed_amount"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {t("pages.promotions.Fixed_amount")}
                                </FormLabel>
                                <Controller
                                  name="Fixed_amount"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    validate: (value) => {
                                      const intValue = parseInt(value, 10);
                                      return (
                                        intValue >= 0 ||
                                        t("validation.must_be_non_negative")
                                      );
                                    },
                                  }}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      placeholder={t(
                                        "pages.promotions.Fixed_amount"
                                      )}
                                      onChange={(e) => {
                                        handleInputChange(e, "Fixed_amount");
                                      }}
                                      type="number"
                                      min="0"
                                    />
                                  )}
                                />

                                <FormErrorMessage>
                                  {errors.Fixed_amount &&
                                    errors.Fixed_amount.message}
                                </FormErrorMessage>
                              </FormControl>

                              <MultiSelect
                                data={
                                  devicesTypes.data &&
                                  devicesTypes.data.map((el) => ({
                                    ...el,
                                    label: el.DevicesTypeName,
                                    value: el.DevicesTypeId,
                                  }))
                                }
                                title={t("pages.promotions.Products")}
                                isMulti={false}
                                onChange={(e) => setValue("Products", e)}
                              />
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                );
              }
            })()}
          </Stack>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Box style={{ width: "50%" }}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              flexWrap="wrap"
              mb={6}
            >
              <Text color="text" textTransform="capitalize">
                {t("pages.promotions.Applies_On_Branches")}
              </Text>

              <GButton
                title={t("pages.promotions.add_branche")}
                bg="light"
                color="text"
                fontSize="14px"
                border="1px solid"
                borderColor="border"
                hover={{ bg: "light" }}
                //onClick={() => setShowCreateSupplierModal(true)}
              />
            </Flex>

            <Box
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {" "}
                no data found now
              </div>
            </Box>
          </Box>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Box style={{ width: "50%" }}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              flexWrap="wrap"
              mb={6}
            >
              <Text color="text" textTransform="capitalize">
                {t("pages.promotions.Limit_To_Customer_Tags")}
              </Text>

              <GButton
                title={t("pages.promotions.add_customer_tags")}
                bg="light"
                color="text"
                fontSize="14px"
                border="1px solid"
                borderColor="border"
                hover={{ bg: "light" }}
                //onClick={() => setShowCreateSupplierModal(true)}
              />
            </Flex>

            <Box
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {" "}
                no data found now
              </div>
            </Box>
          </Box>
        </div>
      </form>
    </>
  );
};

export default CreatePromotionPage;
