
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ---------------------------------Cost Adjustment Details ------------------------------------- */
export const getCostAdjustmentDetails = createAsyncThunk("costAdjustment/getCostAdjustmentDetails", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/CostAdjustmenById?costadjustmentid={"CostAdjustmentId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );