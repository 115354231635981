import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";


/*---------------------------------------------------- Get Linking Ingredients List ------------------------------------------------- */
export const getIngredientsForProduct = createAsyncThunk("ingredients/getIngredientsForProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/IngredientsProductById?productid={"ProductId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/*-------------------------------------------------------  Get Items To Linking Ingredients ----------------------------------------------- */
export const getItems = createAsyncThunk("ingredients/getItems",async (args, thunkApi) => {
    try {
      const { data } = await axios.get( `/Menu/InventoryItem/LookupInventoryItem` );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/*-------------------------------------------------------  Linking Ingredients To Products ----------------------------------------------- */
export const addIngredientsForProduct = createAsyncThunk("ingredients/addIngredientsForProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get( `/Menu/ProductRecipes/AddProductRecipes?productrecipes=${JSON.stringify( args )}` );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
