import React, { useEffect, useState } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import { Flex, SimpleGrid, Input as ChInput } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import GButton from "components/ui/button/Button";
import TrialBalanceTable from "features/general_accounts/TrialBalance/components/TrialBalanceTable";

import { useDispatch, useSelector } from "react-redux";
import { GetTrialBalance } from "features/general_accounts/service/accountsTreeService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getAcoountCycleNew } from "features/general_accounts/AccountAdd/service/AccountAddService";
import { filterObjectsByValue } from "utils/DynamicFilter";
import { formatDate } from "utils/FormatDate";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import { checkIdExists } from "utils/cheackAuth";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const TrialBalancePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue, reset } = useForm();
  const [showModal, setShowModal] = useState(false);

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const [selectedPeriod, setSelectedPeriod] = useState(null);

  useEffect(() => {
    const dataSend = { DateFrom: "0" };
    dispatch(getAcoountCycleNew(dataSend));
  }, [dispatch]);

  const AccountCycleData = useSelector((state) => state.AccountAdd);
  // console.log(AccountCycleData.accountCycle);
  const filteredArray = filterObjectsByValue(
    AccountCycleData.accountCycle,
    "IsClosed",
    false
  );

  /* ------------------------------------- Authrzation ------------------------------ */
  const retrievedArray = useDecryptedPrivilege();
  const ManageAccountant_TrialBalance = checkIdExists(retrievedArray, 1018);

  if (ManageAccountant_TrialBalance === false) {
    return <NotAuthorizedCompo />;
  }

  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.Trial_Balance.Trial_Balance")}
          </Text>
        </Flex>
      </Box>

      <form
        onSubmit={handleSubmit((values) => {
          if (values.FinancialPeriodsId === undefined) {
            toast2({
              description: t("pages.DailyRestrictions.searchvalidation"),
            });
          } else {
            values.FinancialPeriodsId =
              values.FinancialPeriodsId.FinancialPeriodsId;
            dispatch(GetTrialBalance(values))
              .unwrap()
              .then((_) => {
                toast({
                  description: t("pages.general_accounts.dataFetchingOk"),
                });
                setValue("DateTo", 0);
                setValue("DateFrom", 0);
                // setValue("FinancialPeriodsId", undefined);
              });
          }
        })}
      >
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          style={{ width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <label
              style={{ marginLeft: "3px", marginRight: "3px", width: "120px" }}
            >
              {t("pages.Ledger.period_from")}
            </label>
            <Controller
              name="DateFrom"
              control={control}
              render={({ field }) => (
                <ChInput
                  {...field}
                  style={{ width: "100%" }}
                  required
                  type="date"
                />
              )}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <label
              style={{ marginLeft: "3px", marginRight: "3px", width: "120px" }}
            >
              {t("pages.Ledger.period_to")}
            </label>
            <Controller
              name="DateTo"
              control={control}
              render={({ field }) => (
                <ChInput
                  {...field}
                  style={{ width: "100%" }}
                  required
                  type="date"
                />
              )}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
              gap: "50px",
            }}
          >
            <label
              style={{
                marginLeft: "3px",
                marginRight: "3px",
                width: "120px",
              }}
            >
              {t("pages.DailyRestrictions.finacial_period")}
            </label>
            <div style={{ width: "100%" }}>
              <MultiSelect
                title={""}
                data={
                  filteredArray &&
                  filteredArray.map((el) => ({
                    ...el,
                    label: ` ${t("pages.AccountAdd.date_From")} : ${formatDate(
                      el.DateFrom
                    )} ${t("pages.AccountAdd.date_to")} ${formatDate(
                      el.DateTo
                    )}`,
                    value: el.FinancialPeriodsId,
                  }))
                }
                isMulti={false}
                // isDisabled={selectedValue === "all"}
                onChange={(e) => {
                  setValue("FinancialPeriodsId", e);
                  setSelectedPeriod(e);
                }}
              />
            </div>
          </div>
        </SimpleGrid>

        <GButton
          title={t("pages.Ledger.dispaly")}
          bg="#54A0FF"
          color="light"
          hover={{ bg: "primary" }}
          style={{ marginTop: "15px", marginBottom: "15px" }}
          onClick={() => setShowModal(true)}
          type="submit"
        />
      </form>

      {showModal && <TrialBalanceTable></TrialBalanceTable>}
    </>
  );
};

export default TrialBalancePage;
