import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createDiscountLimitCustomerTags,
  getDiscountLimitCustomerTags,
  getTagsInCustomer,
} from "../../services/discountsLimitCustomerTagsService";

const LimitsToCustomerTagsModal = ({ onClose }) => {
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { handleSubmit, setValue } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  useEffect(() => {
    dispatch(getTagsInCustomer(id.id));
  }, [dispatch, id.id]);

  const customerTags = useSelector((state) => state.discountsLimitCustomerTags);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.discounts.limits_to-customer-tags")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.CustomerTags = values.CustomerTags?.map((el) => ({
                TagId: el.TagId,
              }));
              values.DisCountId = id.id;
              values.UserId = localStorage.getItem("userId");
              if (
                values.CustomerTags === undefined ||
                values.CustomerTags?.length === 0
              ) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                dispatch(createDiscountLimitCustomerTags(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.discounts.customer_tag_discount_created"
                      ),
                    });
                    dispatch(getDiscountLimitCustomerTags(id.id));
                    onClose();
                  });
              }
            })}
          >
            <Stack>
              <MultiSelect
                title={t("pages.tags.tags")}
                data={
                  Array.isArray(customerTags.tagsInCustomer)
                    ? customerTags.tagsInCustomer.map((el) => ({
                        ...el,
                        label: el.TagName,
                        value: el.TagId,
                      }))
                    : []
                }
                isMulti={true}
                isRequired={true}
                onChange={(e) => setValue("CustomerTags", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.inventory_item_details.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={customerTags.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={customerTags.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default LimitsToCustomerTagsModal;
