import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa6";
import AddBasicInfoCompo from "./addCompo/AddBasicInfoCompo";
import AddNationalIdCompo from "./addCompo/AddNationalIdCompo";
import AddContactCompo from "./addCompo/AddContactCompo";
import { getClassification } from "features/hr/VacationList/services/vacationServices";
import {
  getBankDropdown,
  getMartialStatus,
  getNationalityDropdown,
  getRecruitmentSituation,
  getReligion,
} from "../../services/employeeServices";
import AddEmployeeWorkPermit from "./addCompo/AddEmployeeWorkPermit";
import AddBankCompo from "./addCompo/AddBankCompo";
import { useTranslation } from "react-i18next";
import AddContractInfo from "./addCompo/AddContractInfo";
import EmployeeAddedSuccessfuly from "./addCompo/EmployeeAddedSuccessfuly";
import {
  getPositionDropdown,
  getReportingToPositionDropdown,
} from "features/hr/Positions/services/positionsServices";
import {
  getBouncesDropDown,
  getHomeLeaveTypeDropdown,
  getLeaveTypeDropdown,
} from "../../services/generalEmployeeServices";

const steps = [
  { id: 1, title: "Basic Info" },
  { id: 2, title: "NationalId Info" },
  { id: 3, title: "Contact Info" },
  { id: 4, title: "Work Permit Info" },
  { id: 5, title: "Bank Info" },
  { id: 6, title: "Contract Info" },
];

export default function AddEmployeePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showCompoNumber, setshowCompoNumber] = useState(1);

  const data = useSelector((state) => state.employeeSlice);

  useEffect(() => {
    dispatch(getClassification());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMartialStatus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getReligion());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRecruitmentSituation());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNationalityDropdown());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getBankDropdown());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPositionDropdown());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getReportingToPositionDropdown());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getHomeLeaveTypeDropdown());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getBouncesDropDown());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getLeaveTypeDropdown());
  }, [dispatch]);

  /*-------------------------------------------- Close ------------------------------------------------*/

  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     const confirmationMessage =
  //       "Are you sure you want to leave? Your changes may not be saved.";
  //     e.returnValue = confirmationMessage; // Standard for most browsers
  //     return confirmationMessage; // For some older browsers
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  // }, []);

  /*-------------------------------------------- Return ------------------------------------------------*/

  if (data?.isLoading) {
    return (
      <Flex
        bg="light"
        p={4}
        gap={2}
        justifyContent="center"
        flexDir={"column"}
        alignItems={"center"}
      >
        <Spinner />
        <Text fontWeight={"bold"}> {t("general.loading")} </Text>
      </Flex>
    );
  }

  if (showCompoNumber === 7) {
    return (
      <>
        <EmployeeAddedSuccessfuly />
      </>
    );
  }

  return (
    <>
      <Box
        mb={"10px"}
        bg={"white"}
        paddingY={5}
        paddingX={5}
        gap={"5"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        {steps?.map((el, index) => (
          <Box
            key={index}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDir={"column"}
          >
            <Box
              width={"50px"}
              height={"50px"}
              borderRadius={"full"}
              border={"1px solid"}
              borderColor={"gray.300"}
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
              fontSize={"25px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              cursor={"pointer"}
              // onClick={() => handelCompoNumber(el?.id)}
              backgroundColor={
                showCompoNumber > el.id
                  ? "green.500"
                  : showCompoNumber === el.id
                  ? "primary"
                  : ""
              }
              textColor={
                showCompoNumber > el.id
                  ? "white"
                  : showCompoNumber === el.id
                  ? "white"
                  : "gray.500"
              }
            >
              {showCompoNumber > el.id ? <FaCheck /> : el?.id}
            </Box>
            <Text
              fontSize={"16px"}
              fontWeight={"semibold"}
              textColor={"gray.600"}
            >
              {el?.title}
            </Text>
          </Box>
        ))}
      </Box>
      <Box
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        {showCompoNumber === 1 ? (
          <AddBasicInfoCompo setshowCompoNumber={setshowCompoNumber} />
        ) : showCompoNumber === 2 ? (
          <AddNationalIdCompo setshowCompoNumber={setshowCompoNumber} />
        ) : showCompoNumber === 3 ? (
          <AddContactCompo setshowCompoNumber={setshowCompoNumber} />
        ) : showCompoNumber === 4 ? (
          <AddEmployeeWorkPermit setshowCompoNumber={setshowCompoNumber} />
        ) : showCompoNumber === 5 ? (
          <AddBankCompo setshowCompoNumber={setshowCompoNumber} />
        ) : showCompoNumber === 6 ? (
          <AddContractInfo setshowCompoNumber={setshowCompoNumber} />
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
