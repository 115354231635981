import { useTranslation } from "react-i18next";

const PrintCart = ({ text, tableData }) => {
  const { t } = useTranslation();
  const printCart = () => {
    const printWindow = window.open("", "_blank");

    // Generate table rows from the table data
    const tableRows = tableData
      .map((rowData, index) => {
        const cells = Object.values(rowData)
          .map((cellData, cellIndex) => `<td>${cellData}</td>`)
          .join("");
        return `<tr key=${index}>${cells}</tr>`;
      })
      .join("");

    printWindow.document.write(`
      <html>
        <head>
          <title>${text}</title>
          <style>
            body {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0;
              font-size: 20px;
            }
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            p {
              text-align: center;
            }
          </style>
        </head>
        <body>
          <p>${text}</p>
          <table>
            <thead>
              <tr>
                ${Object.keys(tableData[0])
                  .map((header) => `<th>${header}</th>`)
                  .join("")}
              </tr>
            </thead>
            <tbody>
              ${tableRows}
            </tbody>
          </table>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div>
      <button onClick={printCart}>{t("pages.Ledger.Export_to_PDF")}</button>
    </div>
  );
};

export default PrintCart;
