import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  Flex,
  Stack,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  getGiftsCardById,
  updateGiftsCard,
} from "../../services/giftsCardsService";
import { getCategories } from "features/products/categories/services/categoriesService";
import { generator } from "utils/generator";

const UpdateModal = ({ data, onClose, giftsCardId }) => {
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const dispatch = useDispatch();
  const giftsCards = useSelector((state) => state.giftsCards);
  const categories = useSelector((state) => state.categories);
  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: data,
  });
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  watch(["GiftCardsSUK"]);
  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.gifts_cards.update_gifts_card")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");

              values.GiftsCardCategoriesId =
                values.GiftsCardCategoriesId?.GiftsCardCategoriesId;
              values.ProductCategoriesId =
                values.ProductCategoriesId?.ProductCategoriesId;
              if (
                values.ProductCategoriesId === undefined ||
                values.GiftCardsSUK === undefined
              ) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                dispatch(updateGiftsCard({ values }))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.gifts_cards.gifts_card_updated"),
                    });
                    dispatch(getGiftsCardById({ giftsCardId }));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <FormControl isInvalid={errors.GiftCardsName}>
                <FormLabel
                  htmlFor="GiftCardsName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.gifts_cards.name")}
                </FormLabel>
                <Controller
                  name="GiftCardsName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.gifts_cards.name")}
                      onChange={(e) => {
                        handleInputChange(e, "GiftCardsName");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.GiftCardsName && errors.GiftCardsName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.GiftCardsNameLoc}>
                <FormLabel
                  htmlFor="GiftCardsNameLoc"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.gifts_cards.name_localized")}
                </FormLabel>
                <Controller
                  name="GiftCardsNameLoc"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.gifts_cards.name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "GiftCardsNameLoc");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.GiftCardsNameLoc && errors.GiftCardsNameLoc.message}
                </FormErrorMessage>
              </FormControl>

              <MultiSelect
                title={t("pages.gifts_cards.category")}
                data={
                  categories.data &&
                  categories.data.map((el) => ({
                    ...el,
                    label: el.ProductCategoriesNameEng,
                    value: el.ProductCategoriesId,
                  }))
                }
                isMulti={false}
                isRequired
                onChange={(e) => setValue("ProductCategoriesId", e)}
              />

              <Flex alignItems="flex-end" gap={4}>
                <Input
                  label={t("pages.gifts_cards.sku")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.gifts_cards.sku")}
                  isRequired
                  value={getValues("GiftCardsSUK") || ""}
                  readOnly
                />
                <GButton
                  title={t("pages.gifts_cards.generate_sku")}
                  height="50px"
                  bg="light"
                  border="1px solid"
                  borderColor="border"
                  hover={{
                    bg: "primary",
                    borderColor: "primary",
                    color: "light",
                  }}
                  onClick={() => setValue("GiftCardsSUK", generator({}))}
                />
              </Flex>

              <Checkbox {...register("IsFixedPrice")}>
                {t("pages.gifts_cards.is_price_fixed")}
              </Checkbox>

              <FormControl isInvalid={errors.Price}>
                <FormLabel
                  htmlFor="Price"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.gifts_cards.price")}
                </FormLabel>
                <Controller
                  name="Price"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.gifts_cards.price")}
                      onChange={(e) => {
                        handleInputChange(e, "Price");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.Price && errors.Price.message}
                </FormErrorMessage>
              </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={giftsCards.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={giftsCards.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default UpdateModal;
