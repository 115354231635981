import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";


/* ---------------------------------------------------- Get Reasons ---------------------------------------------------*/
export const getReasons = createAsyncThunk( "reasons/getReasons",  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Resons/ResonsgroupByType");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ---------------------------------------------------- Create Reasons ---------------------------------------------------*/
export const createReason = createAsyncThunk( "reasons/createReason", async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Resons/AddEditResons?resons=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------------ Update Reason -------------------------------------------------------- */
export const updateReason = createAsyncThunk("reasons/updateReason", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Reasons/EditReasons?reasonid=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteReason = createAsyncThunk( "reasons/deleteReason", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/Reasons/ReasonsById?ReasonId={"ReasonId":${args} }`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);