import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const getCallcenter = createAsyncThunk(
    "transfersorders/getCallcenter",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getInvItemDrop = createAsyncThunk(
    "transfersorders/getInvItemDrop",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/InventoryItem/LookupInventoryItem`);
        // console.log("eses",data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ------------------------------------------------------------  get WareHouse Dropdown  ----------------------------------------------------------*/
  export const getWareHouseDropdown = createAsyncThunk(  "transfersorders/getWareHouseDropdown", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/GetWarehousesList`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const addTransfersorders = createAsyncThunk( "transfersorders/addTransfersorders",  async (args, thunkApi) => {
      // console.log("toto",args)
      try {
       const { data } = await axios.get(`/Inventory/TransferOrders/AddTransferOrders?transferorders=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ---------------------------------------------------------  Get Transfer Order List  ----------------------------------------------------- */
  export const getListTransferorder = createAsyncThunk( "transfersorders/getListTransferorder", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/TransferOrders/TransferOrdersList`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ---------------------------------------------------------  Get Transfer Order Details ----------------------------------------------------- */
  export const getTransfersOrdersDetails = createAsyncThunk("transfersorders/getTransfersOrdersDetails", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/GetTransferOrdersById?transferordersid=${JSON.stringify(args)}`);        
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

/* --------------------------------------------- Edit Transfer Orders ------------------------------------------ */
  export const editTransferOrders = createAsyncThunk("transfersorders/editTransferOrders", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/EditTransferOrders?transferordersid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


/* ---------------------------------------------------------  Add Transfer Order Items  ----------------------------------------------------- */
export const addTranOrderItems = createAsyncThunk( "transfersorders/addTranOrderItems", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/AddTransferOrdersItems?transferordersitems=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
    }
);
/* ---------------------------------------------------------  Get Transfer Order Items  ----------------------------------------------------- */
  export const getTranOrderItems = createAsyncThunk("transfersorders/getTranOrderItems",   async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/GetListTransferOrdersItemsByTOId?transferordersid=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getEditQuantities = createAsyncThunk( "/getEditQuantities", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/GetEditTransferOrdersItems?transferordersid=${JSON.stringify(args)}`);
      //  console.log("booo",data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

/* ------------------------------------------- Edit Transfere Orders Items Quantities ---------------------------------------------- */
      export const editTransfereOrdersItemsQuantities = createAsyncThunk( "transfersorders/editTransfereOrdersItemsQuantities",  
        async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/EditTransferOrdersItems?transferordersitemsid=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const submitForReview = createAsyncThunk(
    "transfersorders/submitForReview",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/TransferOrders/SubmittingTransferOrder?transferordersid=${JSON.stringify(args)}`);
      //  console.log("booo",data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const sendItems = createAsyncThunk(
    "transfersorders/sendItems",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/TransferOrders/SendItemsToTransfer?transferordersid=${JSON.stringify(args)}`);
      //  console.log("booo",data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const deleteOrder = createAsyncThunk(
    "transfersorders/deleteOrder",
    async (args, thunkApi) => {
      
      try {
        //console.log(args)
        const { data } = await axios.get(`/Inventory/TransferOrders/DeleteTransferOrdersById?transferordersid=${JSON.stringify(args)}`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
export const getTransfereOrdersFilteredData = createAsyncThunk("transfersorders/getTransfereOrdersFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetTransfereOrdersFilteredData = createAsyncThunk( "transfersorders/resetTransfereOrdersFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
