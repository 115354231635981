import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------------- Get Dashbord Inventory List -------------------------------------------- */
  export const getDashbordInventory = createAsyncThunk("dashbordCharts/getDashbordInventory",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Dashboard/GetInventoryDashboard`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* -------------------------------------------- Get Dashbord Branches List -------------------------------------------- */
  export const getDashbordBranches = createAsyncThunk("dashbordCharts/getDashbordBranches",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Dashboard/GetBranchesDashboard`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* -------------------------------------------- Get Dashbord General List -------------------------------------------- */

export const GetGeneralDashBoard = createAsyncThunk(
  "dashbordCharts/GetGeneralDashBoard",
  async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/Dashboard/GetGeneralDashboard?date=${JSON.stringify(args)}`);
     return thunkApi.fulfillWithValue(data);
      
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);