import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import TimeShiftTable from "../components/TimeShiftTable";
import { getLookUpTimeLeave } from "../services/attendanceSettingServices";
import { useDispatch, useSelector } from "react-redux";
import AddTimeModal from "../components/modal/AddTimeModal";
import NoDataFound from "components/ui/noDataFound/NoDataFound";
//Spinner
export default function TimeShift() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.attendanceSetting);

  const [showViewModal, setShowViewModal] = useState(false);

  // console.log(data);
  useEffect(() => {
    dispatch(getLookUpTimeLeave());
  }, [dispatch]);

  //--------------------------------- Compponents Return ----------------------------------------//
  if (data?.isLoading) {
    return (
      <Flex
        bg="light"
        p={4}
        gap={2}
        justifyContent="center"
        flexDir={"column"}
        alignItems={"center"}
      >
        <Spinner />
        <Text fontWeight={"bold"}> Loading </Text>
      </Flex>
    );
  }

  return (
    <>
      <Box>
        <Box
          bg="light"
          marginInline={-6}
          marginTop={-6}
          padding={6}
          borderTop="1px solid"
          borderColor="border"
          marginBottom={6}
        >
          <Flex alignItems="center" justifyContent="space-between" gap={4}>
            <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
              {t("pages.hr.attendance.time_shift")}
            </Text>

            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => setShowViewModal(true)}
            >
              {t("pages.hr.attendance.new_time")}
            </Button>
          </Flex>
        </Box>

        <Box>
          {data?.TimeLeaveList?.length > 0 ? (
            <TimeShiftTable />
          ) : (
            <NoDataFound />
          )}
        </Box>

        {showViewModal && (
          <AddTimeModal onClose={() => setShowViewModal(false)} />
        )}
      </Box>
    </>
  );
}
