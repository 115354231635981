import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {Box,Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import { generator } from "utils/generator";
import GButton from "components/ui/button/Button";
import { createTaxGroup, getTaxesGroups } from "../../services/taxesGroupsService";
import { getTaxesLookup } from "../../services/taxesLookupService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";

const CreateGroupModal = ({ onClose  }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, control, setValue,  getValues, watch ,  formState: { errors },  trigger} =  useForm();
  watch(["Reference"]);

  const taxesGroups = useSelector(state => state.taxesGroups);
  const taxesLookup = useSelector(state => state.taxesLookup);

  useEffect(() => {
    dispatch(getTaxesLookup());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };


  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.taxes.create_tax_group")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.Reference = values.Reference;
            values.TaxGroupId = null;
            if(values.Reference === undefined ){
              toast2({ description: t("validation.required")});
            }
            else  {
              if (values.TaxsId === undefined  || values.TaxsId?.length === 0  ){
                toast2({ description: t("validation.choose_first")});
              }
              else {
                values.TaxsId = values.TaxsId?.map(el => ({ TaxsId: el.TaxsId }));
                dispatch(createTaxGroup(values))
                  .unwrap()
                  .then(_ => {
                    toast({ description: t("pages.taxes.tax_group_created") });
                    dispatch(getTaxesGroups());
                    onClose()
                  });
                }
            }



          })}>
            <Stack spacing={6}>
      <FormControl isInvalid={errors.TaxGroupName}>
          <FormLabel
            htmlFor="TaxGroupName"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.taxes.name")}
          </FormLabel>
          <Controller
            name="TaxGroupName"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.taxes.name")}
                onChange={(e) => {
                  handleInputChange(e, "TaxGroupName");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.TaxGroupName &&
              errors.TaxGroupName.message}
          </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.TaxGroupNameLocalized}>
          <FormLabel
            htmlFor="TaxGroupNameLocalized"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.taxes.name_localized")}
          </FormLabel>
          <Controller
            name="TaxGroupNameLocalized"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.taxes.name_localized")}
                onChange={(e) => {
                  handleInputChange(e, "TaxGroupNameLocalized");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.TaxGroupNameLocalized &&
              errors.TaxGroupNameLocalized.message}
          </FormErrorMessage>
      </FormControl>

        <MultiSelect
          data={taxesLookup?.data && taxesLookup.data?.map(el => ({
            ...el,
            label: el.TaxsName,
            value: el.TaxsId
          }))}
          title={t("pages.taxes.taxes")}
          onChange={(e) => setValue("TaxsId", e)}
          isMulti={true}
        />

      <Flex alignItems="flex-end" gap={4}>
          <Input
            label={t("pages.taxes.reference")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.taxes.reference")}
            isRequired
            value={getValues("Reference") || ""}
            readOnly
          />
          <GButton
            title={t("pages.taxes.generate")}
            height="50px"
            bg="light"
            border="1px solid"
            borderColor="border"
            hover={{
              bg: "primary",
              borderColor: "primary",
              color: "light",
            }}
            onClick={() => setValue("Reference", generator({}))}
          />
      </Flex>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={taxesGroups.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={taxesGroups.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default CreateGroupModal