import { createSlice } from "@reduxjs/toolkit";
import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getUsersFilteredData,
  resetUsersFilteredData,
  getLanguage,
  getTagGroup,
  getUserById,
} from "../services/usersService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  filteredData:[],
  language:[],
  TagGroup:[],
  userDetails:[]
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload || [];
      state.errors = null;
    })
    builder.addCase(getUsers.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createUser.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createUser.rejected, (state,{payload}) => {
      state.isLoading = false;
      console.log(payload)
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const indexAt = state.data.findIndex(user => user.id === payload.id);
      // state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateUser.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteUser.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = state.data.filter(user => user.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteUser.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

       /* ----------------------------------------- Filter Data --------------------------------------------- */
       builder.addCase(getUsersFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(getUsersFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        state.errors = null;
      });
      builder.addCase(getUsersFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
     /* -------------------------------------------------------------------------------------- */
      builder.addCase(resetUsersFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(resetUsersFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = [];
        state.errors = null;
      });
      builder.addCase(resetUsersFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
     /* --------------------------------------------------------------------------------------------------- */
  
     builder.addCase(getLanguage.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getLanguage.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.language = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getLanguage.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getTagGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTagGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TagGroup = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getTagGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getUserById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getUserById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.userDetails =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.userDetails)
      state.errors = null;
    })
    builder.addCase(getUserById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

  }

});

export default usersSlice.reducer;