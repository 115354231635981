import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

const ProductTable = ({ productList }) => {

  

  // console.log("list",productList);
 


  const { t } = useTranslation();
  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.branches.name")}</Th>
          </Tr>
        </Thead>
        <Tbody>      
          {productList.map((el, index) => (
            <Tr key={el.ProductId}>
              <Td>{index + 1}</Td>
              <Td>{el?.ProductName}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ProductTable;
