import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- Get Discounts List  -------------------------------------------------------------------*/
export const getDiscounts = createAsyncThunk(
  "discounts/getDiscounts",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `https://rostoapi.smartgate-egypt.com/Manage2/DisCount/GetDiscountList?discount`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Get Qualifications Dropdown -------------------------------------------------------------------*/
export const getQualificationsDropdown = createAsyncThunk(
  "discounts/getQualificationsDropdown",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `https://rostoapi.smartgate-egypt.com/Manage2/GetQualifications`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- create Discount  -------------------------------------------------------------------*/
export const createDiscount = createAsyncThunk(
  "discounts/createDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `https://rostoapi.smartgate-egypt.com/Manage2/DisCount/AddEditDiscount?discount=${JSON.stringify(
          args
        )}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Edit Discount  -------------------------------------------------------------------*/
export const updateDiscount = createAsyncThunk(
  "discounts/updateDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `https://rostoapi.smartgate-egypt.com/Manage2/DisCount/AddEditDiscount?discount=${JSON.stringify(
          args
        )}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteDiscount = createAsyncThunk(
  "discounts/deleteDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCount/DeleteDisCountById?DisCountId={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getFilteredData = createAsyncThunk(
  "discounts/getFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetFilteredData = createAsyncThunk(
  "discounts/resetFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ----------------------------------------- Get Discount By ID (Details) --------------------------------------- */
export const getDiscountById = createAsyncThunk(
  "discounts/getDiscountById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCount/DiscountById?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
