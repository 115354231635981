import React from "react";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const InActiveBranchesTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>{t("pages.branches.name")}</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data &&
              data.map((el, index) => (
                <Tr key={el.ComboInActiveBranchId}>
                  <Td>{index + 1}</Td>
                  <Td>{el?.BranchName}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </div>
  );
};

export default InActiveBranchesTable;
