import { createSlice } from "@reduxjs/toolkit";
import { getLanguages, getProfileData, updateProfileData } from "../services/profileServices";

let initialState = {
    isLoading: false,
    profileData: [],
    languageDropDown: [],
    pages: 0,
    errors: null,
    filteredData:[]
  };


  const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers : {
        clearProfileData : (state)=>{
            state.profileData = [];
        },
    },
    extraReducers: (builder) => {
      /* ------------------- get Profile Data ------------------ */
        builder.addCase(getProfileData.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getProfileData.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.profileData = payload || [];
            state.errors = null;
          })
          builder.addCase(getProfileData.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
          /* ------------------- Update Profile Data ------------------ */
        builder.addCase(updateProfileData.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(updateProfileData.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
          builder.addCase(updateProfileData.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
        /* ------------------- Get Language ------------------ */
        builder.addCase(getLanguages.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getLanguages.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            // state.profileData = payload || [];
            state.languageDropDown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
          builder.addCase(getLanguages.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

    }
});


export default profileSlice.reducer;
export const {clearProfileData} = profileSlice.actions;