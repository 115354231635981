import { useTranslation } from "react-i18next";
import { Box, Table, Flex , Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { Link } from "react-router-dom";
import UpdateModal from "../components/modals/UpdateModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "components/ui/pagination/Pagination";
import { Spinner } from '@chakra-ui/react';

const CTable = ({data}) => {
  const { t } = useTranslation();
  const [showUpdateModal, setShowUpdateModal] = useState(null);
  const reservationsData = useSelector(state => state.reservations);
      /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
        return data?.slice(firstIndex, lastIndex);
      };
    
    // Calculate records for activeDiscounts
    const reservationsTableData = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return <>
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      { 
        reservationsData?.isLoading  && ( 
        <Flex p={4}  bg="white"  alignItems="center" justifyContent="center">
            <Spinner /> 
        </Flex>)
      }
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.reservations.branch")}</Th>
            <Th>{t("pages.reservations.ref")}</Th>
            <Th>{t("pages.reservations.tables_no")}</Th>
            <Th>{t("pages.reservations.reservation_duration")}</Th>
            <Th> Actions </Th>
          </Tr>
        </Thead>
        <Tbody>
          {reservationsTableData.map((reservation, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td> {reservation.BranchName}  </Td>
              <Td>{reservation.BranchCode}</Td>
              <Td>{reservation.tabllcount}</Td>
              <Td>{reservation.ReservationDuration}</Td>
              <Td>
                  <Flex gap={2} justifyContent={"center"}>
                      <GButton
                        postfixElement={<FiIcons.FiEdit />}
                        bg="primary"
                        color="light"
                        border="1px solid transparent"
                        hover={{ bg: "none", borderColor: "primary", color: "primary" }}
                        onClick={() => setShowUpdateModal({ ...reservation  || null })}
                      />
                  </Flex>
              </Td>
              
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </Box>
    {showUpdateModal && (
          <UpdateModal
            data={showUpdateModal}
            onClose={() => setShowUpdateModal(null)}
          />
    )}
  </>
}

export default CTable;