import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import { Button, Flex, Text, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react"
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import NoBranches from "../components/NoBranches";
import CreateModal from "../components/modals/CreateModal";
import ImportModal from "../components/modals/ImportModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import { getBranches, resetFilteredDataBranches } from "../services/branchesService";
import ExportModal from "components/ui/modal/ExportModal";
import FilterBranchModal from "../components/modals/FilterBranchModal";
import { IoCloseOutline } from "react-icons/io5";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Branches = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 39);

  const dispatch = useDispatch();
  const branches = useSelector(state => state.branches);
  const { t } = useTranslation();

  const [showCreateBranchModal, setShowCreateBranchModal] = useState(null);
 
  const [showFilterBranchesModal, setShowFilterBranchesModal] = useState(null);


  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  const [exportModal, SetExportModal] = useState(false);

  const resetFunction = () => {
    dispatch(resetFilteredDataBranches());
  };


  
  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.branches.branches")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
       
          <Button
          type="button"
          bg="none"
          border="1px solid"
          borderColor="border"
          textTransform="capitalize"
          fontSize="1rem"
          color="text"
          _hover={{ bg: "primary", color: "light" }}
        >
          <div onClick={() => SetExportModal(true)}>
            {t("general.export")}
          </div>
        </Button>


            <GButton
              title={t("pages.branches.create_branch")}
              onClick={() => setShowCreateBranchModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
      >
        <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom="2px solid"
            borderColor="primary"
          />
        </Flex>

        <GButton
        title={
          branches?.filteredData.length === 0
            ? t("general.filter")
            : t("general.clear_filter")
        }
        border="1px solid"
        borderColor="border"
        postfixElement={
          branches?.filteredData.length === 0 ? (
            <FiIcons.FiFilter size={16} />
          ) : (
            <IoCloseOutline fontWeight={800} size={20} />
          )
        }
        hover={{ bg: "primary", borderColor: "primary", color: "light" }}
        onClick={
          branches?.filteredData.length === 0
            ? () => setShowFilterBranchesModal(true)
            : resetFunction
        }
      />
      </Flex>

      {branches.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : branches.data.length === 0 ? (
        <NoBranches
          onClickCreateBranchBtn={() => setShowCreateBranchModal(true)}
        />
      ) : (
        <Table
          branches={branches.data}
          filteredData={branches.filteredData}
        />
      )}

      {showCreateBranchModal && (
        <CreateModal
          onClose={() => setShowCreateBranchModal(null)}
        />
      )}

     

   

      {showFilterBranchesModal && (
        <FilterBranchModal
          onClose={() => setShowFilterBranchesModal(null)}
          data={branches.data}
        />
      )}

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={branches.data}
          fileName={"branches"}
        ></ExportModal>
      )}
    </>
  )
}

export default Branches