import React from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function HrLetterProcessLetter({ details }) {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <Box>
        <Text>{t("pages.hr.ProcessName")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.ProcessName}</Text>
        </Box>
      </Box>
      <Box>
        <Text>{t("pages.hr.date")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.CreatedDate}</Text>
        </Box>
      </Box>
      <Box>
        <Text>{t("pages.hr.status")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.Status}</Text>
        </Box>
      </Box>

      <Box>
        <Text>{t("pages.hr.to")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.HrLetterTo}</Text>
        </Box>
      </Box>

      {/* <Box>
        <Text>{t("pages.hr.requster")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.HrLetterComment}</Text>
        </Box>
      </Box> */}

      {/* <Box>
        <Text>{t("pages.hr.current_activity")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.HrLetterComment}</Text>
        </Box>
      </Box> */}
    </Stack>
  );
}
