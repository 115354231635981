import { createSlice } from "@reduxjs/toolkit";
import { getDeliveryZones } from "../services/deliveryZonesService";

let initialState = {
  isLoading: false,
  data: [],
  section: null,
  pages: 0,
  errors: null
};

const deliveryZonesSlice = createSlice({
  name: "deliveryZones",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDeliveryZones.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDeliveryZones.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getDeliveryZones.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

  }
});

export default deliveryZonesSlice.reducer;