import { useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr,Box , Flex} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";

export default function InActiveUserDataTable({ data,  handleUpdate,  handleDelete}) {
  const { t } = useTranslation();

  const handleStatus = (status) => {
    const bg = status === true ? "#00D2D2" : "#ED5253";
    return (
      <Box
        height="10px" width="10px" borderRadius="50%"
        bg={bg} margin="auto"
      />
    )
  }
  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const inAcvtiveUsersData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return  <>
      <>
        <Table>
        <Thead>
            <Tr>
                <Th>#</Th>
                <Th>{t("pages.users.full_name")}</Th>
                <Th>{t("pages.users.console_access")}</Th>
                <Th>{t("pages.users.app_access")}</Th>
                <Th>{t("general.action")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            { inAcvtiveUsersData?.map((item, index) => (
              <Tr key={item?.userLoginId}>
              <Td>{index + 1}</Td>
              <Td style={{ cursor: "pointer" }}>   {item?.userFullName}  </Td>
              <Td>{handleStatus(item?.isConsoleAccess)}</Td>
              <Td>{handleStatus(item?.isAppAccess)}</Td>
              <Td>
                <Flex gap={2}>
                  <GButton
                    postfixElement={<FiIcons.FiEdit />}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      bg: "none",
                      borderColor: "primary",
                      color: "primary",
                    }}
                    onClick={() => handleUpdate(item)}
                  />
                  <GButton
                    postfixElement={<FiIcons.FiTrash2 />}
                    bg="red.600"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      bg: "none",
                      borderColor: "red.600",
                      color: "red.600",
                    }}
                    onClick={() => handleDelete(item)}
                  />
                </Flex>
              </Td>
            </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(data?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(data?.length / recordsPerPage)
            )
          }
        />
      </>
    </>
};
