import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- create customer tags discounts  -------------------------------------------------------------------*/
export const createDiscountLimitCustomerTags = createAsyncThunk(
  "discountsLimitCustomerTags/createDiscountLimitCustomerTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCount/AddDiscountTags?tagid=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Get customer tags Discounts List  -------------------------------------------------------------------*/
export const getDiscountLimitCustomerTags = createAsyncThunk(
  "discountsLimitCustomerTags/getDiscountLimitCustomerTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetDiscountTags?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Get Tags In customer ---------------------------------------------------*/
export const getTagsInCustomer = createAsyncThunk(
  "discountsLimitCustomerTags/getTagsInCustomer",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/LookupCustomerTags?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete customer Tag In Discount  -------------------------------------------------------------------*/
export const deleteCustomerTagInDiscount = createAsyncThunk(
  "discountsLimitCustomerTags/deleteCustomerTagInDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCountCustomerTag/DisCountCustomerTagById?DisCountCustomerTagId={"DisCountCustomerTagId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
