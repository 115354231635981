import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getBrands = createAsyncThunk(
  "brands/getBrands",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Brands/ListBrands");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createBrand = createAsyncThunk(
  "brands/createBrand",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Brands/AddEditBrands?brand=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Updated Brand ----------------------------------------------------------- */
export const updateBrand = createAsyncThunk( "brands/updateBrand",  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Brands/AddEditBrandDetails?branddetails=${JSON.stringify(args)}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete Brand ----------------------------------------------------------- */
export const deleteBrand = createAsyncThunk("brands/deleteBrand", async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Brands/AddEditBrands?brand=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getProductsForBrand = createAsyncThunk(
  "brands/getProductsForBrand",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Tax/LookUpProduct");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getTagsForBrand = createAsyncThunk(
  "brands/getTagsForBrand",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Brand/LookUpBrandTags");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const getProductCatForBrands = createAsyncThunk(
  "brands/getProductCatForBrands",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Brand/LookUpBrandProductcatg");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getBrandsFilteredData = createAsyncThunk("brands/getBrandsFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetBeandsFilteredData = createAsyncThunk( "brands/resetBeandsFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);