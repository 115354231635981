import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const UploadImg = createAsyncThunk(
  "UploadImg/UploadImg",
  async (formData, thunkApi) => {
    try {
      const response = await axios.post(
        "https://rostoapi.smartgate-egypt.com/Image/Upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { fileName } = response.data;
      return fileName;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
