import React, { useEffect, useState } from 'react';
import { Button, Flex, Text} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ExportModal from 'components/ui/modal/ExportModal';
import InventoryCategoriesTable from '../components/InventoryCategoriesTable';
import CreateModal from '../components/Modal/CreateModal';
import { getInventoryCategoriesList } from '../services/InventoryCategoriesServices';


export default function InventoryCategories() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(state => state.inventoryCategories);

  const [showCreateInvntoryCategories, setShowCreateInvntoryCategories] = useState(false);
  const [exportModal,SetExportModal]=useState(false);

  useEffect(() => {
    dispatch(getInventoryCategoriesList());
  }, [dispatch])

  return <>
  <BreadCrumb>
      <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize" >
            {t("pages.inventoryCategories.inventoryCategories")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
                <Button
                  type="button" bg="none" border="1px solid" borderColor="border"
                  textTransform="capitalize" fontSize="1rem" color="text"
                  _hover={{ bg: "primary", color: "light" }}
                  onClick={() => SetExportModal(true)}
                >
                    <Text>{t("general.export")}</Text>
                </Button>

                <GButton
                  title={t("pages.inventoryCategories.create_inventoryCategories")}
                  onClick={() => setShowCreateInvntoryCategories(true)}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ bg: "none", borderColor: "primary", color: "primary" }}
                />
          </Flex>
      </Flex>
    </BreadCrumb>

    <InventoryCategoriesTable data={data.data} filteredData={data?.filteredData} />


    {exportModal&&(
      <ExportModal 
        onClose={() => SetExportModal(false)}   
        data={data.data}
        fileName={"InventoryCategories"}
        >
      </ExportModal>
    )}

    { showCreateInvntoryCategories && 
      <>
          <CreateModal
              onClose={()=>setShowCreateInvntoryCategories(false)}
          > </CreateModal>
      </>
    }
  
  </>
}
