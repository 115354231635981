import React from 'react'
import { useTranslation } from 'react-i18next';
import DonutChart from './DounatShape';

const ChartContanier = ({ data, title }) => {
    const { t } = useTranslation();
  return (
    <div>
    <div
      style={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ fontWeight: "bold", fontSize: "17px" }}>{t(title)}</h1>
      </div>
      <h3
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
      </h3>

      <DonutChart data={data}/>

    </div>
  </div>
  )
}

export default ChartContanier
