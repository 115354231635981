import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { getFilteredData } from "../../services/giftsCardsService";
import { getCategories } from "../../../categories/services/categoriesService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useEffect } from "react";

const FilterModal = ({ onClose, data }) => {
  console.log("al data", data);
  const [filterCriteria, setFilterCriteria] = useState({
    GiftCardsName: "",
    GiftCardsNameLoc: "",
    ProductCategoriesNameEng: "",
    GiftCardsSUK: "",
    Price: "",
  });
  const [filteredData, setFilteredData] = useState(data);
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const categories = useSelector((state) => state.categories);
  console.log("all categories", categories);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleFilterGiftsCard = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const {
        GiftCardsName,
        GiftCardsNameLoc,
        ProductCategoriesNameEng,
        GiftCardsSUK,
        Price,
      } = filterCriteria;

      console.log("wala", filterCriteria);

      // Create variables to hold filter conditions
      let filterByNameEng = true;
      let filterByNameLoc = true;
      let filterByProductCategoriesNameEng = true;
      let filterBySUK = true;
      let filterByPrice = true;

      // Check if the filter criteria are defined, and if so, apply the filter
      if (GiftCardsName) {
        filterByNameEng = item.GiftCardsName.toLowerCase().includes(
          GiftCardsName.toLowerCase()
        );
      }

      if (GiftCardsNameLoc) {
        filterByNameLoc = item.GiftCardsNameLoc.toLowerCase().includes(
          GiftCardsNameLoc.toLowerCase()
        );
      }

      if (ProductCategoriesNameEng) {
        filterByProductCategoriesNameEng =
          item.ProductCategoriesNameEng.toLowerCase().includes(
            ProductCategoriesNameEng.toLowerCase()
          );
      }

      if (GiftCardsSUK) {
        filterBySUK = item.GiftCardsSUK.toLowerCase().includes(
          GiftCardsSUK.toLowerCase()
        );
      }

      if (Price) {
        filterByPrice = item.Price === parseInt(Price);
      }

      // Return true if all conditions are met
      return (
        filterByNameEng &&
        filterByNameLoc &&
        filterByProductCategoriesNameEng &&
        filterBySUK &&
        filterByPrice
      );
    });
    console.log("walaaaaa", filteredResult);
    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredData(filteredResult));
  };
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const handleClose = () => {
    onClose(filteredData);
  };

  return (
    <GModal
      onClose={handleClose}
      modalHeader={t("pages.gifts_cards.filter_gifts_cards")}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleFilterGiftsCard}>
            <Stack spacing={6}>
              <Input
                label={t("pages.gifts_cards.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.name")}
                name="GiftCardsName"
                value={filterCriteria.GiftCardsName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    GiftCardsName: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.gifts_cards.name_localized")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.name_localized")}
                name="GiftCardsNameLoc"
                value={filterCriteria.GiftCardsNameLoc}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    GiftCardsNameLoc: e.target.value,
                  })
                }
              />

              <MultiSelect
                title={t("pages.gifts_cards.category")}
                data={
                  categories.data &&
                  categories.data.map((el) => ({
                    ...el,
                    label: el.ProductCategoriesNameEng,
                    value: el.ProductCategoriesId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.filterByProductCategoriesNameEng}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ProductCategoriesNameEng: e.ProductCategoriesNameEng,
                  })
                }
              />

              <Input
                label={t("pages.gifts_cards.sku")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.sku")}
                name="GiftCardsSUK"
                value={filterCriteria.GiftCardsSUK}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    GiftCardsSUK: e.target.value,
                  })
                }
              />

              <Input
                type="number"
                label={t("pages.gifts_cards.price")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.price")}
                name="Price"
                value={filterCriteria.Price}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Price: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={handleClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default FilterModal;
