import axios from "axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");
/* -------------------  Get Hr Today Attendance  ---------------------*/
export const getHrTodayAttendance = createAsyncThunk(
  "hrTodayAttendance/getHrTodayAttendance",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetAttendanceToday?data=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Hr Today Absence  ---------------------*/
export const getHrTodayAbsence = createAsyncThunk(
  "hrTodayAttendance/getHrTodayAbsence",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetAbsentToday?data=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
