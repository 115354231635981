import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getIngredientsForProduct } from "../services/linkingIngredientsService";
import LinkingIngredientsTable from "./LinkingIngredientsTable";
import LinkingIngredientsModal from "./modals/LinkingIngredientsModal";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const LinkingIngredients = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const { t } = useTranslation();
  const [linkingIngredientsToProduct, setLinkingIngredientsToProduct] =
    useState(false);

  const id = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIngredientsForProduct(id.productId));
  }, [dispatch, id]);

  const ingredientsSpecificProduct = useSelector(
    (state) => state.addIngredient
  );

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(linkingIngredientsToProduct && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setLinkingIngredientsToProduct(false)
  }

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.products.ingredients")}
        </Text>

        <GButton
          title={t("pages.products.add_ingredients")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setLinkingIngredientsToProduct(true)}
        />
      </Flex>

      {ingredientsSpecificProduct.isLoading ? (
        <Spinner />
      ) : ingredientsSpecificProduct.ingredintsData?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : ingredientsSpecificProduct.ingredintsData?.length > 0 ? (
        <LinkingIngredientsTable
          allIngredients={ingredientsSpecificProduct.ingredintsData}
        />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {linkingIngredientsToProduct && (
        <LinkingIngredientsModal
          onClose={() => setLinkingIngredientsToProduct(false)}
        />
      )}
    </Box>
  );
};

export default LinkingIngredients;
