import GModal from "components/ui/modal/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {Box,  Flex, Stack, useToast, Input as ChInput, Text} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { editQuantityPurchaseOrderItems, getPurchaseOrdersitems } from "../../services/purchasrOrderServices";


const EditQuantityItemPurchaseModal = ({ onClose, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({  position: "top",  duration: 3000,  status: "success",});
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const {handleSubmit } = useForm();

  const purchaseOrder= useSelector((state) => state.purchaseOrder);

  const [itemData, setItemData] = useState({});
  const [dataValid, setDatavalid] = useState(true);

  useEffect(() => {
    const initialItemData = {};

    purchaseOrder?.PurchaseOrdersitems.forEach((el) => {
      // Store each element in a separate object
      initialItemData[el.PurchaseOrdersItemsId] = {
        PurchaseOrdersItemsId: el.PurchaseOrdersItemsId,
        Quantity: el.Quantity,
        UnitCost: el.CostPerUnit,
      };
    });

    // Set the state with both the object and the array
    setItemData(initialItemData);
  }, [purchaseOrder?.PurchaseOrdersitems]);

  const handleQuantityChange = (newQuantity, itemId , NewUnitCost ) => {
    // Step 3: Update the state with the new data using a unique identifier
    setItemData((prevData) => {
      return {
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          Quantity: newQuantity,
          UnitCost: NewUnitCost,
        },
      };
    });
  };

  const handelValidate = (values) => {
    let isValid = true;
  
    const items = values.PurchaseOrdersItems;
    items.forEach((el) => {
      if (el.Quantity === undefined || el.Quantity === "" || el.Quantity < 0) {
        toast2({ description: t("validation.required") });
        isValid = false;
      }
    });
  
    if (isValid) {
      items.forEach((el) => {
        dispatch(editQuantityPurchaseOrderItems(values))
          .unwrap()
          .then((_) => {
            toast({ description: t("pages.purchase_orders.edited_succesfuly") });
            dispatch(getPurchaseOrdersitems(id));
            onClose();
          });
      });
    } else {
      setDatavalid(false);
    }
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.purchase_orders.edit_quantities")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              const editedItems = Object.keys(itemData).map((itemId) => ({
                PurchaseOrdersItemsId: parseInt(itemId, 10),
                Quantity: itemData[itemId].Quantity,
                UnitCost: itemData[itemId].UnitCost,
              }));
              values.PurchaseOrdersItems = editedItems;
              values.PurchaseOrdersId = id;
              console.log("values" , values);
              handelValidate(values);
            })}
          >
            <Stack spacing={6}>
              {purchaseOrder?.PurchaseOrdersitems.map( (el) => (
                  <Box>
                    <Text
                      marginBottom={"7px"}
                      fontSize={"1rem"}
                      fontWeight={"500"}
                      textTransform={"capitalize"}
                      textAlign={"start"}
                      marginInlineEnd={"0.75rem"}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {el.InventoryItemNameEng}
                    </Text>
                    <ChInput
                      value={itemData[el.PurchaseOrdersItemsId]?.Quantity || ""}
                      name="Quantity"
                      type="number"
                      onChange={(e) =>
                        handleQuantityChange(
                          e.target.value,
                          el.PurchaseOrdersItemsId,
                          el.CostPerUnit,
                        )
                      }
                    />

                  </Box>

                )
              )}

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default EditQuantityItemPurchaseModal;
