import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text,useToast } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import EditPurchaseModal from "../components/modals/EditPurchaseModal";
import GButton from "components/ui/button/Button";
import { getPurchaseDetails } from "../services/purchaseService";
import PurchasingItems from "../components/PurchasingItems";
import DeletePurchaseModal from "../components/modals/DeletePurchaseModal";
import { getPurchasingItemList } from "../services/PurchasingItemsService";
import RecieveItems from "../components/modals/RecieveItems";
import ReturnToSupplier from "../components/modals/ReturnToSupplier";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

export default function InventoryPurchasesDetails (){
  const purchaseId = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = useParams();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const purchases = useSelector((state) => state.purchases);
  const purchase = purchases.purchaseDetails;

  const PurchaseItems = useSelector(state => state.purchasesitems);

  const [showUpdateProductModal, setShowUpdateProductModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReceiveItems, setShowReceiveItems] = useState(null);
  const [showReturnToSupplier, setShowReturnToSupplier] = useState(null);

  useEffect(() => {
    dispatch(getPurchaseDetails(purchaseId.purchaseId));
  }, [dispatch , purchaseId.purchaseId]);

  useEffect(()=>{
    dispatch(getPurchasingItemList(id.purchaseId));
  },[dispatch,id]);


/* -----------------------------------Privilige-------------------------------*/
    const retrievedArray =useDecryptedPrivilege()
    const ManagePurchasing  = checkIdExists(retrievedArray, 22);
  
    if(showUpdateProductModal === true && ManagePurchasing === false){
      toast2({ description:  t("general.authorization")});
      setShowUpdateProductModal(false);
    };
    if(showDeleteModal === true && ManagePurchasing === false){
      toast2({ description:  t("general.authorization")});
      setShowDeleteModal(false);
    };
    if(showReceiveItems === true && ManagePurchasing === false){
      toast2({ description:  t("general.authorization")});
      setShowReceiveItems(false);
    };
    if(showReturnToSupplier === true && ManagePurchasing === false){
      toast2({ description:  t("general.authorization")});
      setShowReturnToSupplier(false);
    };
/* -----------------------------------Privilige-------------------------------*/

return <> 
  {
    purchases?.isLoading? <Spinner /> 
    :
    (   
    <>
        {
        purchase.length > 0 ? 
            <>
              <BreadCrumb>
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    flexWrap="wrap"
                  >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        flexWrap="wrap"
                      >
                                <Box as="p" fontSize="1.6rem" textTransform="capitalize">
                                  {purchase[0]?.Type || "-"} 
                                </Box>
                                <Box
                                    bg={"#718096"}
                                    display={"inline-block"}
                                    marginX={"5px"}
                                    borderRadius={"5px"}
                                    color={"white"}
                                    fontSize={"15px"}
                                    padding={" 2px 10px"}
                                >
                                    {purchase[0]?.Status || "-"}
                                </Box>
                                
                      </Flex>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        flexWrap="wrap"
                      >
                            {
                              PurchaseItems?.data?.length > 0  &&  purchase[0]?.Status === "Draft" ? 
                                <GButton
                                  title={t("pages.purchases.recieve_items")}
                                  bg="#54A0FF"
                                  color="light"
                                  border="1px solid transparent"
                                  hover={{
                                    bg: "none",
                                    borderColor: "#54A0FF",
                                    color: "#54A0FF",
                                  }}
                                  onClick={() => setShowReceiveItems(true)}
                                />
                                :
                                ""
                            }

                            {
                              purchase[0]?.Status === "Closed" ? 
                                <GButton
                                  title={t("pages.purchases.return_to_supplier")}
                                  bg="#54A0FF"
                                  color="light"
                                  border="1px solid transparent"
                                  hover={{
                                    bg: "none",
                                    borderColor: "#54A0FF",
                                    color: "#54A0FF",
                                  }}
                                  onClick={() => setShowReturnToSupplier(true)}
                                />
                                :
                                ""
                            }

                                <GButton
                                  prefixElement={<FiIcons.FiTrash2 size={16} />}
                                  title={t("pages.transfers_orders.delete_permenantly")}
                                  bg="light"
                                  color="text"
                                  border="1px"
                                  borderColor={"#CBD5E0"}
                                  opacity="0.7"
                                  onClick={() => setShowDeleteModal(true)}
                                />

                                <GButton
                                  prefixElement={<FiIcons.FiEdit2 size={16} />}
                                  title={t("general.edit")}
                                  bg="light"
                                  color="text"
                                  border="1px"
                                  borderColor={"#CBD5E0"}
                                  opacity="0.7"
                                  onClick={() =>setShowUpdateProductModal(true)}
                                />
                      </Flex>
                </Flex>
              </BreadCrumb>

              <Stack spacing={12}>
                {/* details */}
                <SimpleGrid
                  spacing={6}
                  columns={{ base: 1, md: 2 }}
                  bg="white"
                  p={4}
                  borderRadius={6}
                  boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                >
                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.supplier")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.SuppliersName || "-"}</Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.branche")}
                    </Text>
                    <Text marginBlock={2}>
                      {purchase[0]?.BranchName || "-"}
                    </Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.invoice_number")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.PurchasingRef || "-"}</Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.business_date")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.BussinusDate || "-"}</Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.created_at")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.Created || "-"}</Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.invoice_date")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.BussinusDate || "-"}</Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.creator")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.Creator || "-"}</Text>
                  </Box>

                </SimpleGrid>

                <PurchasingItems Status={purchase[0]?.Status} />

                {/* <DeletedProduct idOfProduct={id.productId}></DeletedProduct> */}
              </Stack>
            </>
        
        :
            <>
              <Flex
                bg="light"
                alignItems="center"
                justifyContent="center"
                p={4}
               >
                {t("pages.purchases.No_purchase")}
              </Flex>
            </>
        }
    </>
    )
  }
        {showDeleteModal && (
          <DeletePurchaseModal
            onClose={() => setShowDeleteModal(false)}
            id={purchaseId.purchaseId}
          ></DeletePurchaseModal>
        )}

        {showUpdateProductModal && (
            <EditPurchaseModal
              onClose={() => setShowUpdateProductModal(false)}
            />
        )}

        {showReceiveItems && (
            <RecieveItems
              id={id}
              onClose={() => setShowReceiveItems(false)}
            />
        )}

        {showReturnToSupplier && (
            <ReturnToSupplier
              id={id}
              SuppliersId={purchase[0]?.SuppliersId}
              onClose={() => setShowReturnToSupplier(false)}
            />
        )}
  </>

}


