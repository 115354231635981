import { Box, Button, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import NewAddGroupModal from "./modals/NewAddGroupModal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGroupofSize } from "../services/comboSizeService";
import { useTranslation } from "react-i18next";
import NewAddOptianModal from "./modals/NewAddOptianModal";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const NewCompoSizeTable = ({ data, groups, products, options }) => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);


  //console.log(data);
  const { t } = useTranslation();

  const [ShowAddGroupModal, SetShowAddGroupModal] = useState(false);
  const [showOptianModal, SetShowOptionModal] = useState(false);

  const [ComboSizeGroupId, SetComboSizeGroupId] = useState("");

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(ShowAddGroupModal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    SetShowAddGroupModal(false)
  }


  if(showOptianModal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    SetShowOptionModal(false)
  }


  //   <Table>
  //   <Thead>
  //     <Tr>
  //       <Th></Th>
  //       {data?.map((el) => {
  //         return <Th key={el.ComboSizeId}>{el.ComboSizeName}</Th>;
  //       })}
  //     </Tr>
  //   </Thead>

  //   <Tbody>

  //       {groups?.map((el) => {
  //         return (
  //           <>
  //           <Tr key={el.ComboSizeGroupId}>
  //             <Td>
  //                 <p>
  //                     {el.ComboSizeGroupName}
  //                 </p>
  //                 <p style={{marginTop:"25px",color:"gray",cursor:"pointer"}}
  //                  onClick={()=>{
  //                     SetShowOptionModal(true)
  //                     SetComboSizeGroupId(el.ComboSizeGroupId)
  //                 } }>
  //                     {t("pages.combos.add_options")}
  //                 </p>

  //             </Td>
  //             {
  //               options?.map((OptionEl=>{
  //                 if(OptionEl.ComboSizeGroupId==el.ComboSizeGroupId){
  //                   return(
  //                     <>
  //                     <Td>
  //                       <p>{OptionEl.OptionName}</p>
  //                       <p>{OptionEl.ProductName}</p>
  //                       <p>{OptionEl.OptionPrice}</p>
  //                     </Td>
  //                     </>
  //                   )
  //                 }else{

  //                 }
  //               }))
  //             }
  //             </Tr>
  //           </>
  //         );
  //       })}

  //   </Tbody>
  // </Table>

  // function groupByComboSizeId(data) {
  //   return data.reduce((result, currentItem) => {
  //     const { ComboSizeid } = currentItem;
  //     const existingGroup = result.find(
  //       (group) => group[0].ComboSizeid === ComboSizeid
  //     );

  //     if (existingGroup) {
  //       existingGroup.push(currentItem);
  //     } else {
  //       result.push([currentItem]);
  //     }

  //     return result;
  //   }, []);
  // }

  // const groupedData = groupByComboSizeId(options);
  //console.log(groupedData);

  const getComboSizeIds = (arrayOfObjects) => {
    return arrayOfObjects.map((obj) => obj.ComboSizeId);
  };

  const comboSizeIds = getComboSizeIds(data);
//console.log(comboSizeIds);

  return (
    <div>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Table>
          <Thead>
            <Tr>
              <Th>groups</Th>
              {data?.map((el) => {
                return <Th key={el.ComboSizeId}>{el.ComboSizeName}</Th>;
              })}
            </Tr>
          </Thead>

          <Tbody>
            {groups?.map((el) => {
              return (
                <>
                  <Tr key={el.ComboSizeGroupId}>
                    <Td>
                      <p>{el.ComboSizeGroupName}</p>
                      <p
                        style={{
                          marginTop: "25px",
                          color: "gray",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          SetShowOptionModal(true);
                          SetComboSizeGroupId(el.ComboSizeGroupId);
                        }}
                      >
                        {t("pages.combos.add_options")}
                      </p>
                    </Td>

                    {comboSizeIds?.map((testEl) => {
                      return (
                        <Td>
                          
                          {
                            options?.map((optionsEl)=>{
                              //console.log(options)
                              if(optionsEl.ComboSizeid==testEl && optionsEl.ComboSizeGroupId== el.ComboSizeGroupId )
                              {
                                return(
                                  <div style={{marginBottom:"25px"}}>
                                    <p>{optionsEl.OptionName}</p>
                                    <p>{optionsEl.ProductName}</p>
                                  </div>
                                )
                              }
                            })
                          }
                        </Td>
                      );
                    })}
                  </Tr>
                </>
              );
            })}
          </Tbody>
        </Table>

        <Button
          bg={"white"}
          color="black"
          _hover={"none"}
          style={{ cursor: "pointer", margin: "25px 50px" }}
          onClick={() => SetShowAddGroupModal(true)}
        >
          {t("pages.combos.add_group")}
        </Button>
      </Box>

      {ShowAddGroupModal && (
        <NewAddGroupModal onClose={() => SetShowAddGroupModal(false)} />
      )}

      {showOptianModal && (
        <NewAddOptianModal
          onClose={() => SetShowOptionModal(false)}
          sizes={data}
          products={products}
          ComboSizeGroupId={ComboSizeGroupId}
        />
      )}
    </div>
  );
};

export default NewCompoSizeTable;
