import { createSlice } from "@reduxjs/toolkit";
import {
  getTimedEventsProducts,
  createTimedEventProducts,
  deleteProductInTimedEvent,
  getTagsInProduct,
} from "../services/timedEventsProductsService";

let initialState = {
  isLoading: false,
  errors: null,
  tagsInProduct: [],
};

const timedEventsProductsSlice = createSlice({
  name: "timedEventsProducts",
  initialState,

  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get categories Timed Events List  -------------------------------------------------------------------*/
    builder.addCase(getTimedEventsProducts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTimedEventsProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getTimedEventsProducts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create Timed Events Combos  -------------------------------------------------------------------*/
    builder.addCase(createTimedEventProducts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createTimedEventProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createTimedEventProducts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Product In Timed Event  -------------------------------------------------------------------*/
    builder.addCase(deleteProductInTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteProductInTimedEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deleteProductInTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------- Get Tags In Product ---------------------------------------------------*/
    builder.addCase(getTagsInProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTagsInProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInProduct =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getTagsInProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default timedEventsProductsSlice.reducer;
