import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* -------------------------------------------- Get CostAdjustment Reports List ------------------------------------ */
export const getPurchaseOrderList = createAsyncThunk(
  "reportsPurchaseOrder/getPurchaseOrderList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Reports/GetPurchaseOrderList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* --------------------------------------- Menu Engineering Filter Data ------------------------------*/

export const getPurchaseOrderFilteredData = createAsyncThunk(
  "reportsPurchaseOrder/getPurchaseOrderFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetPurchaseOrderFilteredData = createAsyncThunk(
  "reportsPurchaseOrder/resetPurchaseOrderFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
