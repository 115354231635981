import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/*--------------------------------------------- Add Product To Group ------------------------------------------ */
export const addProductToGroup = createAsyncThunk("productGroups/addProductToGroup", async (args, thunkApi) => {
    try { 
      const { data } = await axios.get( `/Manage/Taxs/AddEditProductTaxGroup?producttaxsgroup=${JSON.stringify( args )}` );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* -------------------------------------------- Get Taxes Group Products To Remove -------------------------------------------*/
export const getTaxesGroupsProductsToRemove = createAsyncThunk("productGroups/getTaxesGroupsProductsToRemove",async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`/Manage/Taxs/ProductTaxsGroupList` );
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* ---------------------------------------------------- Remove Product From Group ------------------------------------ */
export const removeProductFromGroup = createAsyncThunk("productGroups/removeProductFromGroup", async (args, thunkApi) => {
    try {
      const { data } = await axios.get( `/Manage/DeleteTaxGroupProductsById?taxgroupproductsid=${JSON.stringify(args )}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getProductsForSpecificGroup = createAsyncThunk( "productGroups/getProductsForSpecificGroup",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage/Taxs/ProductTaxsGroupListByTaxGroupId?taxgroupid={"TaxGroupId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


