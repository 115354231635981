import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryCharges } from '../services/deliveryChargeService';
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import DeliveryChargeTable from '../components/DeliveryChargeTable';
import { useTranslation } from 'react-i18next';
import ExportModal from 'components/ui/modal/ExportModal';
import CreateModal from '../components/modal/CreateModal';



const DeliverChargepage = () => {
    const dispatch =useDispatch();
    const { t } = useTranslation();

    const deliveryCharges = useSelector(state => state.deliverycharge);
    console.log(deliveryCharges);

    const [showCreateChargesModal, setShowCreateChargesModal] = useState(null);
    const [exportModal,SetExportModal]=useState(false);


    useEffect(()=>{
      dispatch(getDeliveryCharges())
  },[dispatch])

  return <>

    <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
              <Text
                fontSize="1.2rem" color="dark" textTransform="capitalize"
              >
                {t("pages.DeliverCharge.delivery_charge")}
              </Text>

              <Flex gap={2} flexWrap="wrap">
                <Button
                    type="button" bg="none" border="1px solid" borderColor="border"
                    textTransform="capitalize" fontSize="1rem" color="text"
                    _hover={{ bg: "primary", color: "light" }}
                    onClick={() => SetExportModal(true)}
                  >
                      <Text>{t("general.export")}</Text>
                </Button>

                <GButton
                  title={t("pages.DeliverCharge.create_delivery_charge")}
                  onClick={() => setShowCreateChargesModal(true)}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ bg: "none", borderColor: "primary", color: "primary" }}
                />
              </Flex>
        </Flex>
    </BreadCrumb>

    <div>
    {
        deliveryCharges.isLoading?(
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ):(
          <DeliveryChargeTable data={deliveryCharges.data} />
        )
      }
    </div>

    {exportModal&&(
        <ExportModal 
          onClose={() => SetExportModal(false)}   
          data={deliveryCharges.data}
          fileName={"delivery charges"}
          >
        </ExportModal>
    )}

    {showCreateChargesModal && (
        <CreateModal
          onClose={() => setShowCreateChargesModal(null)}
        />
    )}

  </>;
}

export default DeliverChargepage;
