import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text,useToast } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import ReturnItems from "../components/ReturnItems";
import DeleteReturnToSupplier from "../components/modals/DeleteReturnToSupplier";
import { getReturnSuppliserDetails } from "../services/returnedSuppliersServices";
import EditPurchasingReturn from "../components/modals/EditPurchasingReturn";
import SubmitReturnItems from "../components/modals/SubmitReturnItems";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

export default function ReturnSupplierDetails (){
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const returnedData = useSelector((state) => state.returnSupplier);
  const purchase = returnedData?.data;

  const [showUpdateReturnModal, setShowUpdateReturnModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReceiveItems, setShowReceiveItems] = useState(null);

  useEffect(() => {
    dispatch(getReturnSuppliserDetails(id.ReturnId));
  }, [dispatch , id.ReturnId]);


  /* -----------------------------------Privilige-------------------------------*/
  const retrievedArray =useDecryptedPrivilege()
  const ManagePurchasing  = checkIdExists(retrievedArray, 22);

  if(showDeleteModal === true && ManagePurchasing === false){
    toast2({ description:  t("general.authorization")});
    setShowDeleteModal(false);
  };
  if(showReceiveItems === true && ManagePurchasing === false){
    toast2({ description:  t("general.authorization")});
    setShowReceiveItems(false);
  };
  if(showUpdateReturnModal === true && ManagePurchasing === false){
    toast2({ description:  t("general.authorization")});
    setShowUpdateReturnModal(false);
  };
/* -----------------------------------Privilige-------------------------------*/
return <> 
  {
    returnedData?.isLoading? <Spinner /> 
    :
    (   
    <>
        {
        purchase?.length > 0 ? 
            <>
              <BreadCrumb>
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    flexWrap="wrap"
                  >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        flexWrap="wrap"
                      >
                                <Box as="p" fontSize="1.6rem" textTransform="capitalize">
                                  {t("pages.purchases.supplier")}
                                  {" "}
                                  ({purchase[0]?.ReturnRef})
                                </Box>
                                <Box
                                    bg={"#718096"}
                                    display={"inline-block"}
                                    marginX={"5px"}
                                    borderRadius={"5px"}
                                    color={"white"}
                                    fontSize={"15px"}
                                    padding={" 2px 10px"}
                                >
                                    {purchase[0]?.Status || "-"}
                                </Box>
                                
                      </Flex>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                        flexWrap="wrap"
                      >
                                {   purchase[0]?.Status === "Draft" ?
                                <>
                                    <GButton
                                      prefixElement={<FiIcons.FiTrash2 size={16} />}
                                      title={t("pages.purchases.delete_permenantly")}
                                      bg="light"
                                      color="text"
                                      border="1px"
                                      borderColor={"#CBD5E0"}
                                      opacity="0.7"
                                      onClick={() => setShowDeleteModal(true)}
                                    />

                                    <GButton
                                      prefixElement={<FiIcons.FiEdit2 size={16} />}
                                      title={t("general.edit")}
                                      bg="light"
                                      color="text"
                                      border="1px"
                                      borderColor={"#CBD5E0"}
                                      opacity="0.7"
                                      onClick={() =>setShowUpdateReturnModal(true)}
                                    />

                                    <GButton
                                      title={t("pages.purchases.return_items")}
                                      bg="#54A0FF"
                                      color="light"
                                      border="1px solid transparent"
                                      hover={{
                                        bg: "none",
                                        borderColor: "#54A0FF",
                                        color: "#54A0FF",
                                      }}
                                      onClick={() => setShowReceiveItems(true)}
                                    />
                                </>
                                :
                                ""
                              }
                      </Flex>
                </Flex>
              </BreadCrumb>

              <Stack spacing={12}>
                {/* details */}
                <SimpleGrid
                  spacing={6}
                  columns={{ base: 1, md: 2 }}
                  bg="white"
                  p={4}
                  borderRadius={6}
                  boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                >
                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.supplier")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.SuppliersName || "-"}</Text>
                  </Box>

                    {/* lsh */}
                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.invoice_number")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.PurchasingRef || "-"}</Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.business_date")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.BussinusDate || "-"}</Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.created_at")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.CreateDate || "-"}</Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.branche")}
                    </Text>
                    <Text marginBlock={2}>
                      {purchase[0]?.BranchName || "-"}
                    </Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.invoice_date")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.BussinusDate || "-"}</Text>
                  </Box>

                  <Box borderBottom="1px solid" borderColor="border">
                    <Text textTransform="capitalize" color="text">
                      {t("pages.purchases.creator")}
                    </Text>
                    <Text marginBlock={2}>{purchase[0]?.Creator || "-"}</Text>
                  </Box>

                </SimpleGrid>

                {/*---------- Return To Suppliers Items ----------- */}
                <ReturnItems id={id?.ReturnId} Status={purchase[0]?.Status} PurchasingId={purchase[0]?.PurchasingId} />
                {/*---------- Return To Suppliers Items ----------- */}

              </Stack>
            </>
        
        :
            <>
              <Flex
                bg="light"
                alignItems="center"
                justifyContent="center"
                p={4}
               >
                {t("pages.purchases.No_supplier")}
              </Flex>
            </>
        }
    </>
    )
  }
        {showDeleteModal && (
          <DeleteReturnToSupplier
            onClose={() => setShowDeleteModal(false)}
            id={id.ReturnId}
          ></DeleteReturnToSupplier>
        )}
        {showUpdateReturnModal && (
            <EditPurchasingReturn
              id={id.ReturnId}
              onClose={() => setShowUpdateReturnModal(false)}
            />
        )}
        {showReceiveItems && (
            <SubmitReturnItems
              id={id.ReturnId}
              onClose={() => setShowReceiveItems(false)}
            />
        )}
  </>

}


