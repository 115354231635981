import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import Pagination from "components/ui/pagination/Pagination";
import { useState } from "react";

const CTable = ({ categories, handleUpdate, handleDelete }) => {
  const { t } = useTranslation();
  console.log(categories);

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const categoriesData = calculateRecords(
    categories,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.categories.image")}</Th>
            <Th>{t("pages.categories.name")}</Th>
            <Th>{t("pages.categories.name_localized")}</Th>
            <Th>{t("pages.categories.ref")}</Th>
            <Th>{t("pages.categories.products")}</Th>
            <Th>{t("pages.categories.combos")}</Th>
            <Th>{t("pages.categories.giftcards")}</Th>
            <Th>{t("pages.categories.created")}</Th>
            <Th>{t("general.action")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {categoriesData.map((category, index) => (
            <Tr key={category.ProductCategoriesId || "-"}>
              <Td>{index + 1}</Td>
              <Td
                style={{
                  width: "200px",
                  height: "100px",
                  textAlign: "center",
                }}
              >
                <img
                  src={`https://rostoapi.smartgate-egypt.com/${category.ProductCategoriesImagePath}`}
                  alt="img"
                  className="activeIcon"
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    borderRadius: "5%",
                  }}
                />
              </Td>
              <Td>{category.ProductCategoriesNameEng || "-"}</Td>
              <Td>{category.ProductCategoriesNameAr || "-"}</Td>
              <Td>{category.ProductCategoriesRef || "-"}</Td>
              <Td>{category.Products || "-"}</Td>
              <Td>{category.Combo || "-"}</Td>
              <Td>{category.GiftCards || "-"}</Td>
              <Td>{category.CreatedDate || "-"}</Td>
              <Td className="activeIcon">
                <Flex gap={2}>
                  <GButton
                    postfixElement={<FiIcons.FiEdit />}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      bg: "none",
                      borderColor: "primary",
                      color: "primary",
                    }}
                    onClick={() => handleUpdate(category)}
                  />
                  <GButton
                    postfixElement={<FiIcons.FiTrash2 />}
                    bg="red.600"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      bg: "none",
                      borderColor: "red.600",
                      color: "red.600",
                    }}
                    onClick={() => handleDelete(category)}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(categories.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(categories.length / recordsPerPage)
          )
        }
      />
    </Box>
  );
};

export default CTable;
