import React from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import {Box, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import Input from "components/form/Input";
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import { sendReturnedItems } from '../../services/PurchasingItemsService';
 
export default function EditPurchasingReturn({onClose , id }){
    const { t } = useTranslation();
    const dispatch=useDispatch();
    const {handleSubmit,setValue,trigger,formState: { errors }, control} = useForm();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const PurchaseItems = useSelector(state => state.purchasesitems);

    const handleInputChange = async (e, type) => {
        setValue(type, e.target.value);
        await trigger(type);
      };

    const isOnlySpaces = (value) => {
        // Check if the value contains only spaces
        return !value.trim();
      };


  return (
    <GModal
            onClose={onClose}
            modalHeader={t("pages.purchases.edit_return")}
            modalContent={(
        <Box  p={4}>
        
        <form onSubmit={handleSubmit(values=>{
            values.ReturnId = id;
            if(values.ReturnTax === undefined || values.AdditionalCost === undefined){
                toast2({ description: t("validation.choose_first") });
            }else {
                dispatch(sendReturnedItems(values)).unwrap().then( _=> {
                    toast({ description: t("pages.purchases.purchase_item_returned") });
                    onClose();
                });
            }
        })}>
        
        <Stack spacing={6}>

            
            <FormControl isInvalid={errors.ReturnTax}>
                <FormLabel
                htmlFor="ReturnTax"
                style={{ textTransform: "capitalize" }}
                >
                {t("pages.purchases.return_tax")}
                </FormLabel>
            <Controller
                name="ReturnTax"
                control={control}
                defaultValue=""
                rules={{
                required: t("validation.required"),
                validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                    intValue >= 0 || t("validation.must_be_non_negative")
                    );
                },
                }}
                render={({ field }) => (
                <Input
                    {...field}
                    placeholder={0}
                    onChange={(e) => {
                    handleInputChange(e, "ReturnTax");
                    }}
                    type="number"
                    min="0"
                />
                )}
            />
            <FormErrorMessage>
                {errors.ReturnTax && errors.ReturnTax.message}
            </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.AdditionalCost}>
                <FormLabel
                htmlFor="AdditionalCost"
                style={{ textTransform: "capitalize" }}
                >
                {t("pages.purchases.additional_cost")}
                </FormLabel>
            <Controller
                name="AdditionalCost"
                control={control}
                defaultValue=""
                rules={{
                required: t("validation.required"),
                validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                    intValue >= 0 || t("validation.must_be_non_negative")
                    );
                },
                }}
                render={({ field }) => (
                <Input
                    {...field}
                    placeholder={0}
                    onChange={(e) => {
                    handleInputChange(e, "AdditionalCost");
                    }}
                    type="number"
                    min="0"
                />
                )}
            />
            <FormErrorMessage>
                {errors.AdditionalCost && errors.AdditionalCost.message}
            </FormErrorMessage>
            </FormControl>

            <Input
                label={t("pages.purchases.notes")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.purchases.notes")}
                name="Notes"
                isFormController
                control={control}
            />

        <Flex gap={4} >
            <GButton
                type="submit"
                title={t("pages.inventory_item_details.apply")}
                bg="primary"
                color="light"
                border="1px solid transparent"
                isLoading={PurchaseItems.isLoading}
                hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
            <GButton
                title={t("general.close")}
                onClick={onClose}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                isLoading={PurchaseItems.isLoading}
                hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
        </Flex>


        </Stack>
        
        
        </form>
        
        </Box>

    )}
    />
  );
};