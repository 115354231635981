import { useEffect } from "react";
import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getItems, getDeletedItem } from "../services/itemService.js";
import { useState } from "react";
import CreateItemModal from "../components/modals/CreateItemModal";
import ExportModal from "components/ui/modal/ExportModal";
import ItemsTable from "../components/ItemsTable";

import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege .jsx";

const Items = () => {
  
  const retrievedArray =useDecryptedPrivilege()
  const ManageItems = checkIdExists(retrievedArray, 10);
  const ReadItems = checkIdExists(retrievedArray, 9);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [itemExported, setItemExported] = useState("all");
  const [CreateItemModalstate, setCreateItemModalstate] = useState(false);
  const [exportModal, SetExportModal] = useState(false);


  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const items = useSelector((state) => state.items);
// console.log(items);

  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDeletedItem());
  }, [dispatch]);

  if(CreateItemModalstate===true && ManageItems===false){
    toast2({ description:  t("general.authorization")})
    setCreateItemModalstate(false)
  }

  
  if(ManageItems===false && ReadItems===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  return (
    <div>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.inventory.inventory_item")}
          </Text>

          <Flex gap={2}>
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
            >
              <div onClick={() => SetExportModal(true)}>
                {t("general.export")}
              </div>
            </Button>
            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => setCreateItemModalstate(true)}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.inventory.create_item")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>

      {items.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <ItemsTable
          data={items.data}
          filteredData={items.filteredData}
        ></ItemsTable>
      )}

      {CreateItemModalstate && (
        <CreateItemModal onClose={() => setCreateItemModalstate(false)} />
      )}

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={itemExported === "all" ? items.data : items.deletedItemsData}
          fileName={itemExported === "all" ? "items" : "deletedItems"}
        ></ExportModal>
      )}
    </div>
  );
};

export default Items;
