import React from 'react';
//import PopChart from '../components/charts/PopChart';
import DonutChart from '../components/charts/DonatShape';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Spinner, Text, SimpleGrid, Stack } from "@chakra-ui/react"
import RadarChart from '../components/charts/RadarShape';
import ColumnChart from '../components/charts/ColumnShape';

function DashboardHrPage() {

  const { t } = useTranslation();

  const testData=[
    {
      id:1,
      number:"50",
      text:"number of employees",
      color:"red"
    }
    ,
    {
      id:2,
      number:"80",
      text:"number of employees",
      color:"green"
    }
    ,
    {
      id:3,
      number:"90",
      text:"number of employees",
      color:"orange"
    }
    ,
    {
      id:3,
      number:"30",
      text:"number of employees",
      color:"blue"
    }
  ]



  return (
    <div>
      <Box
        bg="light" marginInline={-6} marginTop={-6} padding={6}
        borderTop="1px solid" borderColor="border" marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.hr.hr_dashboard")}
          </Text>



        </Flex>
      </Box>

      <Stack spacing={12}>


      <SimpleGrid
      spacing={6}
      columns={{ base: 2, md: 4 }}
      bg="white"
      p={4} borderRadius={6}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
    {
      testData.map((el)=>{
        return(
          <div key={el.id} style={{ backgroundColor: el.color ,padding:"15px",borderRadius:"6px"}}> 
            <p style={{color:"white",fontSize:"30px"}}>{el.number}</p>
            <p style={{color:"white",fontSize:"18px"}}>{el.text}</p>
          </div>
        )
      }) 
    }
    </SimpleGrid>

        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4} borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >

          <div style={{display:"flex",alignItems:"center"}}>
            <DonutChart></DonutChart>
          </div>


          <div>
            <ColumnChart></ColumnChart>
          </div>

        </SimpleGrid>



      </Stack>

    </div>
  );
}

export default DashboardHrPage;
