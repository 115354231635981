import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Stack } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  getAllDiscountsForCoupons,
  getFilteredData,
} from "../../services/couponsService";

const FilterModal = ({ onClose, data }) => {
  console.log("totos ya totos", data);

  const couponsType = {
    couponsTypeDropdown: [
      {
        couponsTypeId: 1,
        couponsTypeName: "Order",
      },
      {
        couponsTypeId: 2,
        couponsTypeName: "Product",
      },
    ],
  };

  const [filterCriteria, setFilterCriteria] = useState({
    CouponName: "",
    CouponNameLoc: "",
    CouponCode: "",
    CouponType: "",
    DisCountName: "",
  });

  const [filteredData, setFilteredData] = useState(data);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleFilterProducts = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const {
        CouponName,
        CouponNameLoc,
        CouponCode,
        CouponType,
        DisCountName,
      } = filterCriteria;

      console.log("CouponType", CouponType);

      // Create variables to hold filter conditions
      let filterByName = true;
      let filterByNameLoc = true;
      let filterBySKU = true;
      let filterByCouponType = true;
      let filterByDiscountName = true;

      // Check if the filter criteria are defined, and if so, apply the filter
      if (CouponName) {
        filterByName = item?.CouponName?.toLowerCase().includes(
          CouponName.toLowerCase()
        );
      }

      if (CouponNameLoc) {
        filterByNameLoc = item?.CouponNameLoc?.toLowerCase().includes(
          CouponNameLoc.toLowerCase()
        );
      }

      if (CouponCode) {
        filterBySKU = String(item.CouponCode).trim().includes(CouponCode);
      }

      if (CouponType) {
        filterByCouponType =
          item?.DisCount[0]?.CouponType?.toLowerCase() ===
          CouponType.toLowerCase();
      }

      if (DisCountName) {
        filterByDiscountName =
          item?.DisCount[0]?.DisCountName?.toLowerCase() ===
          DisCountName.toLowerCase();
      }

      // Return true if all conditions are met
      return (
        filterByName &&
        filterByNameLoc &&
        filterBySKU &&
        filterByCouponType &&
        filterByDiscountName
      );
    });

    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredData(filteredResult));
  };

  const handleClose = () => {
    onClose(filteredData);
  };

  useEffect(() => {
    dispatch(getAllDiscountsForCoupons());
  }, []);

  let discounts = useSelector((state) => state.coupons);

  return (
    <GModal
      onClose={handleClose}
      modalHeader={t("pages.coupons.filter_coupons")}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleFilterProducts}>
            <Stack spacing={6}>
              <Input
                label={t("pages.coupons.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.coupons.name")}
                name="CouponName"
                value={filterCriteria.CouponName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    CouponName: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.coupons.name_localized")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.coupons.name_localized")}
                name="CouponNameLoc"
                value={filterCriteria.CouponNameLoc}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    CouponNameLoc: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.coupons.code")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.coupons.code")}
                name="Ref"
                value={filterCriteria.CouponCode}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    CouponCode: e.target.value,
                  })
                }
              />

              <MultiSelect
                title={t("pages.coupons.coupon_type")}
                data={
                  couponsType.couponsTypeDropdown &&
                  couponsType.couponsTypeDropdown.map((el) => ({
                    ...el,
                    label: el.couponsTypeName,
                    value: el.couponsTypeId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.CouponType}
                onChange={(e) => {
                  console.log("e", e);
                  setFilterCriteria({
                    ...filterCriteria,
                    CouponType: e.couponsTypeName,
                  });
                }}
              />

              <MultiSelect
                title={t("pages.coupons.discount")}
                data={
                  discounts?.allDiscounts &&
                  discounts?.allDiscounts?.map((el) => ({
                    ...el,
                    label: el.DisCountName,
                    value: el.DisCountId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.DisCountName}
                onChange={(e) => {
                  console.log("e", e);
                  setFilterCriteria({
                    ...filterCriteria,
                    DisCountName: e.DisCountName,
                  });
                }}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={handleClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default FilterModal;
