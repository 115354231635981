import React from 'react';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr ,Checkbox} from "@chakra-ui/react"
import GButton from "components/ui/button/Button";
import * as FiIcons from "react-icons/fi";
import { useTranslation } from 'react-i18next';


const KitchenFlowBrancheTable = ({data}) => {

    const { t } = useTranslation();

    //console.log(data)
    //const data=[]
  return (
    <Box
    bg="light" overflow="auto"
    borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >

      
      <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.categories.name")}</Th>
          <Th>{t("pages.kitchenFlow.branche_code")}</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((el, index) => (
          <Tr key={el.KitchenFlowsBranchesId}  style={{cursor:"pointer"}}>
            <Td>
              <form>
                <Checkbox  >
                </Checkbox>
              </form>
            </Td>
            <Td >{el.BranchName}</Td>
            <Td >{el.BranchCode}</Td>
            <Td>
                <GButton
                    title={t("pages.kitchenFlow.Assigning_KDS")}
                    bg="light"
                    color="text"
                    fontSize="14px"
                    border="1px solid"
                    borderColor="border"
                    hover={{ bg: "light" }}
                />
           
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
    
  </Box>
  );
}

export default KitchenFlowBrancheTable;
