import React, { useEffect } from 'react';
import { Box, Button, Flex, Spinner, Text , useToast} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ProductionTable from '../components/ProductionTable';
import { useDispatch, useSelector } from 'react-redux';
import {getProductions} from "../services/productionService";
import { useState } from 'react';
import CreateProductionModal from "../components/modals/CreateProductionModal";
import ExportModal from 'components/ui/modal/ExportModal';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

const Production = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const [CreateProductionModalstate, setCreateProductionModalstate] = useState(false);
    const [exportModal,SetExportModal]=useState(false);

    const items = useSelector(state => state.productions);

    useEffect(()=>{
      dispatch(getProductions())
    },[dispatch])

/* -----------------------------------Privilige-------------------------------*/
    const retrievedArray =useDecryptedPrivilege()
    const ManageProduction  = checkIdExists(retrievedArray, 25);

    if(CreateProductionModalstate === true && ManageProduction === false){
      toast2({ description:  t("general.authorization")});
      setCreateProductionModalstate(false);
    };
/* -----------------------------------Privilige-------------------------------*/

  return (
    <div>
    <Box
    bg="light" marginInline={-6} marginTop={-6} padding={6}
    borderTop="1px solid" borderColor="border" marginBottom={6}
  >
    <Flex alignItems="center" justifyContent="space-between" gap={4}>
      <Text
        fontSize="1.2rem" color="dark" textTransform="capitalize"
      >
        {t("pages.production.production_page")}
      </Text>

      <Flex gap={2}>
        <Button
            type="button" bg="none" border="1px solid" borderColor="border"
            textTransform="capitalize" fontSize="1rem" color="text"
            _hover={{ bg: "primary", color: "light" }}
            onClick={() => SetExportModal(true)}
          >
              <Text>{t("general.export")}</Text>
          </Button>
        <Button
          type="button" bg="primary" color="light"
          textTransform="capitalize" fontSize="1rem"
          onClick={() => setCreateProductionModalstate(true)}
        >
          <Flex alignItems="center" gap={1} >
            {t("pages.production.create_production")}
          </Flex>
        </Button>
      </Flex>
    </Flex>
  </Box>

  {
    items.isLoading?(
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    ):(
      <ProductionTable  
        data={items.data} 
        filteredData={items.filteredData}
        ></ProductionTable>
    )
  }
  

  { CreateProductionModalstate && (
    <CreateProductionModal onClose={() => setCreateProductionModalstate(false)}/>
  )}

  {exportModal&&(
            <ExportModal 
              onClose={() => SetExportModal(false)}   
              data={items.data}
              fileName={"production"}
              >
            </ExportModal>
  )}

    </div>
  );
}

export default Production;
