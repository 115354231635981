import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {  Button, Flex, Text,} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import ExportModal from "components/ui/modal/ExportModal";
import { Spinner } from '@chakra-ui/react';
import PaymentReportTable from "./components/PaymentReportTable";
import { getPaymentsReportList } from "./services/paymentReportsServices";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

export default function PaymentReport() {
  const retrievedArray =useDecryptedPrivilege()
  const Auth = checkIdExists(retrievedArray, 70);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [exportModal, SetExportModal] = useState(false);
  const salesReports = useSelector((state) => state.paymentsReport);

  useEffect(() => {
    dispatch(getPaymentsReportList());
  }, [dispatch]);

    
if(Auth===false ){
  return(
    <NotAuthorizedCompo/>
  )
}

  return  <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.reports.sales_reports.payment_reports")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
              <Text>{t("general.export")}</Text>
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>

      <>
      { salesReports?.isLoading ? 
        <Flex
            p={4}
            bg="white"
            alignItems="center"
            justifyContent="center"
        >
            <Spinner /> 
        </Flex>
        :
        <PaymentReportTable data={salesReports?.data} filteredData={salesReports?.filteredData} />
      }
      </>

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={salesReports?.data}
          fileName={"PaymentReports"}
        ></ExportModal>
      )}
    </>
};
