import * as FiIcons from "react-icons/fi";
import routes from "../../navigations/routes";

const links = [
  {
    path: routes.dashboard_general,
    icon: <FiIcons.FiHome size={18} />,
    text: "layout.sidebar.dashboard",
    subLinks: null,
  },
  {
    path: routes.orders,
    icon: <FiIcons.FiShoppingCart size={18} />,
    text: "layout.sidebar.orders",
    subLinks: null,
  },
  {
    path: "call_center",
    icon: <FiIcons.FiPhoneCall size={18} />,
    text: "layout.sidebar.call_center",
    subLinks: null,
  },
  {
    path: "customers",
    icon: <FiIcons.FiUsers size={18} />,
    text: "layout.sidebar.customers",
    subLinks: null,
  },
  {
    icon: <FiIcons.FiFileText size={18} />,
    text: "layout.sidebar.reports",
    subLinks: [
      // Sales_reports
      {
        path: routes.reports_sales,
        text: "pages.reports.sales_reports.sales_reports",
      },
      // Analysis_reports
      {
        path: routes.reports_analysis_reports,
        text: "pages.reports.analysis_reports.analysis_reports",
      },
      // Inventory_reports
      {
        path: routes.reports_inventory_reports,
        text: "pages.reports.inventory_reports.inventory_reports",
      },
      // Business_reports
      {
        path: routes.reports_business_reports,
        text: "pages.reports.business_reports.business_reports",
      },
    ],
  },
  {
    icon: <FiIcons.FiBriefcase size={18} />,
    text: "layout.sidebar.inventory",
    subLinks: [
      {
        path: routes.inventory_items,
        text: "layout.sidebar.items",
      },
      {
        path: routes.inventory_suppliers,
        text: "layout.sidebar.suppliers",
      },
      {
        path: routes.inventory_transfers_orders,
        text: "layout.sidebar.transfers_requests",
      },
      {
        path: routes.inventory_inventory_count,
        text: "layout.sidebar.inventory_count",
      },
      {
        path: routes.inventory_purchases,
        text: "layout.sidebar.purchases",
      },
      {
        path: routes.inventory_transfers,
        text: "layout.sidebar.transfers",
      },
      {
        path: routes.inventory_production,
        text: "layout.sidebar.production",
      },
      {
        path: routes.inventory_purchase_orders,
        text: "layout.sidebar.purchase_orders",
      },
      {
        text: "layout.sidebar.more",
        path: routes.inventory_More,
      },
    ],
  },
  {
    icon: <FiIcons.FiShoppingCart size={18} />,
    text: "layout.sidebar.menu",
    subLinks: [
      {
        path: routes.products_categories,
        text: "layout.sidebar.categories",
      },
      {
        path: routes.products_products,
        text: "layout.sidebar.products",
      },
      {
        path: routes.products_combos,
        text: "layout.sidebar.combos",
      },
      {
        path: routes.products_gifts_cards,
        text: "layout.sidebar.gifts_cards",
      },
      {
        path: routes.products_modifiers,
        text: "layout.sidebar.modifiers",
      },
      {
        path: routes.products_modifiers_options,
        text: "layout.sidebar.modifiers_options",
      },
      {
        path: routes.products_groups,
        text: "layout.sidebar.groups",
      },
    ],
  },
  {
    path: routes.HrNavigations,
    subLinks: null,
    icon: <FiIcons.FiUserPlus size={18} />,
    text: "layout.sidebar.hr",
  },
  {
    icon: <FiIcons.FiDollarSign size={18} />,
    text: "pages.general_accounts.accounts",
    path: routes.Daily_restrictions,
    subLinks: null,

    // subLinks: [
    //   {
    //     text:"pages.general_accounts.General_accounts",
    //     path:routes.permation,
    //     icon:<GiTakeMyMoney  size={18}/>,
    //   }
    //   ,
    //   {
    //     text:"pages.general_accounts.Financial_operations",
    //   }
    // ]
  },
  {
    icon: <FiIcons.FiUser size={18} />,
    text: "layout.sidebar.management",
    subLinks: [
      {
        path: "management/users",
        text: "layout.sidebar.users",
      },
      {
        path: "management/roles",
        text: "layout.sidebar.roles",
      },
      {
        path: "management/branches",
        text: "layout.sidebar.branches",
      },
      {
        path: "management/devices",
        text: "layout.sidebar.devices",
      },
      {
        path: "management/discount",
        text: "layout.sidebar.discount",
      },
      {
        path: "management/coupons",
        text: "layout.sidebar.coupons",
      },
      {
        path: "management/promotions",
        text: "layout.sidebar.promotions",
      },
      {
        path: "management/timed_events",
        text: "layout.sidebar.timed_events",
      },
      {
        path: "management/more",
        text: "layout.sidebar.more",
      },
    ],
  },
];

export default links;
