import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- create Timed Event Branches  -------------------------------------------------------------------*/
export const createTimedEventsBranches = createAsyncThunk(
  "timedEventsBranches/createTimedEventsBranches",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/AddTimedEventBranches?timeeventid=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Get Branches Timed Events List  -------------------------------------------------------------------*/
export const getTimedEventsBranches = createAsyncThunk(
  "timedEventsBranches/getTimedEventsBranches",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetTimedEventBranches?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- all Branches In Timed Event  -------------------------------------------------------------------*/
export const allBranchesInTimedEvent = createAsyncThunk(
  "timedEventsBranches/allBranchesInTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/TimedEventApplyAllBranches?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete Branch In Timed Event  -------------------------------------------------------------------*/
export const deleteBranchInTimedEvent = createAsyncThunk(
  "timedEventsBranches/deleteBranchInTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DeleteTimedEventBranch?timeeventbranchid={"TimedEventBranchId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Get Tags In Branches ---------------------------------------------------*/
export const getTagsInBranches = createAsyncThunk(
  "timedEventsBranches/getTagsInBranches",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/LookupTimedEventBranches?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
