import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { getProducts } from "features/products/products/services/productsService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { addProductToGroup } from "../../services/productsToGroupsService";
import { getTaxesGroups } from "../../services/taxesGroupsService";

const AddProductToGroupModal = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({ position: "top",duration: 3000,  status: "success",});
  const toast2 = useToast({ position: "top",duration: 3000,  status: "error",});
  const { handleSubmit, setValue } = useForm();

  const products = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.taxes.add_to_products")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.TaxGroupId = null;
              if( values.ProductId === undefined ){
                toast2({ description: t("validation.choose_first")});
              }
              else {
                values.ProductId = [{"ProductId":values.ProductId?.ProductId}] ;
                dispatch(addProductToGroup(values))
                  .unwrap()
                  .then((_) => {
                    toast({description: t("pages.taxes.product_added_to_tax_group") });
                    dispatch(getTaxesGroups());
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                data={
                  products.data &&
                  products.data.map((el) => ({
                    ...el,
                    label: el.ProductName,
                    value: el.ProductId,
                  }))
                }
                isRequired
                title={t("pages.taxes.products")}
                onChange={(e) => setValue("ProductId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.taxes.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={data.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={data.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddProductToGroupModal;
