import GModal from "components/ui/modal/Modal";
import React, { useState } from "react";
import { Box, Stack, Input as ChInput, Flex, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import NewMultiSelect from "components/ui/NewMultiSelect/NewMultiSelect";

const DiscountModal = ({ onClose, setDiscountAmount, setapplyDiscount }) => {
  const { t } = useTranslation();

  const [validationNumber, setValidationNumber] = useState(null);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const handelApply = (e) => {
    e.preventDefault(); // Add parentheses to call the preventDefault method
    if (validationNumber === null) {
      toast2({
        description: t("pages.DailyRestrictions.searchvalidation"),
      });
    } else {
      setapplyDiscount(true);
      onClose();
    }
  };

  const DiscountType = [{ type: "fixed" }, { type: "precentage" }];
  const [DiscountTypeSelected, SetDiscountTypeSelected] = useState();
  const [showDisocuntFiled, SetShowDiscountFiled] = useState(false);
  //console.log(DiscountTypeSelected?.type)
  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.orders.discount")}
      modalContent={
        <Box p={4}>
          <Stack>
            <form onSubmit={handelApply}>
              <NewMultiSelect
                data={
                  DiscountType &&
                  DiscountType.map((el) => ({
                    ...el,
                    label: el.type,
                    value: el.type,
                  }))
                }
                onSelectionChange={(e) => {
                  SetDiscountTypeSelected(e);
                  SetShowDiscountFiled(true);
                }}
                title={t("pages.orders.discount_type")}
              />

              {showDisocuntFiled && (
                <div style={{ marginTop: "10px" }}>
                  <div>
                    {t("pages.orders.discount")}{" "}
                    {DiscountTypeSelected?.type === "fixed" ? "" : "%"}
                  </div>
                  <ChInput
                    placeholder={t("pages.orders.discount")}
                    onChange={(e) => {
                      setDiscountAmount({
                        amount: e.target.value,
                        type: DiscountTypeSelected.type,
                      });
                      setValidationNumber(e.target.value);
                    }}
                    type="number"
                  />
                </div>
              )}

              <Flex gap={4} marginTop={5}>
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />

                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
              </Flex>
            </form>
          </Stack>
        </Box>
      }
    />
  );
};

export default DiscountModal;
