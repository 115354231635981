import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {Box,Checkbox, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { getCharges, updateCharge } from "../../services/chargesService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getTaxesGroups } from "features/management/taxesGroups/services/taxesGroupsService";
import { getOrdersTypes } from "features/orders/services/ordersTypesService";
import { getChargesTypes } from "../../services/chargesTypesService";

const UpdateModal = ({data,onClose}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, control, setValue,  register,  formState: { errors },  trigger} = useForm({defaultValues: data});

  const charges = useSelector(state => state.charges);
  const taxesGroups = useSelector(state => state.taxesGroups);
  const ordersTypes = useSelector(state => state.ordersTypes);
  const chargesTypes = useSelector(state => state.chargesTypes);

  useEffect(() => {
    dispatch(getTaxesGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getOrdersTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getChargesTypes());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.charges.update_charge")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.ChargeTypeId = values.ChargeTypeId?.ChargeTypeId;
            values.OrderTypeId = values.OrderTypeId?.OrderTypeId;
            values.TaxGroupId = values.TaxGroupId?.TaxGroupId;
            if( values.OrderTypeId === undefined  ||  values.TaxGroupId === undefined  ||  values.ChargeTypeId === undefined )
              {
                toast2({ description: t("validation.choose_first") });
              }
              else {
                dispatch(updateCharge({ values }))
                .unwrap()
                .then(_ => {
                  toast({ description: t("pages.charges.charge_updated") });
                  dispatch(getCharges());
                  onClose();
                });
              }

          })}>
  <Stack spacing={6}>
      <FormControl isInvalid={errors.ChargeName}>
          <FormLabel
            htmlFor="ChargeName"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.charges.name")}
          </FormLabel>
          <Controller
            name="ChargeName"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.charges.name")}
                onChange={(e) => {
                  handleInputChange(e, "ChargeName");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.ChargeName &&
              errors.ChargeName.message}
          </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.ChargeNameLocalized}>
          <FormLabel
            htmlFor="ChargeNameLocalized"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.charges.name_localized")}
          </FormLabel>
          <Controller
            name="ChargeNameLocalized"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.charges.name_localized")}
                onChange={(e) => {
                  handleInputChange(e, "ChargeNameLocalized");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.ChargeNameLocalized &&
              errors.ChargeNameLocalized.message}
          </FormErrorMessage>
      </FormControl>

      <MultiSelect
        title={t("pages.charges.charge_type_id")}
        isMulti={false}
        data={chargesTypes.data && chargesTypes.data.map(el => ({
          ...el,
          label: el.ChargeTypeName,
          value: el.ChargeTypeId
        }))}
        onChange={(e) => setValue("ChargeTypeId", e)}
      />

      <Checkbox {...register("IsOpenValue")}>
        {t("pages.charges.is_open_value")}
      </Checkbox>

      <FormControl isInvalid={errors.Value}>
    <FormLabel
      htmlFor="Value"
      style={{ textTransform: "capitalize" }}
    >
      {t("pages.charges.value")}
    </FormLabel>
    <Controller
      name="Value"
      control={control}
      defaultValue="0"
      // rules={{
      //   required: t("validation.required"),
      //   validate: (value) => {
      //     const intValue = parseInt(value, 10);
      //     return (
      //       intValue >= 0 || t("validation.must_be_non_negative")
      //     );
      //   },
      // }}
      rules={{
        required: t("validation.required"),
        validate: {
          nonNegative: (value) => parseInt(value, 10) >= 0 || t("validation.must_be_non_negative"),
          lessThanOrEqual100: (value) => parseInt(value, 10) <= 100 || t("validation.must_be_less_than_or_equal_to_100"),
        },
      }}
      render={({ field }) => (
        <Input
          {...field}
          placeholder={t("pages.charges.value")}
          onChange={(e) => {
            handleInputChange(e, "Value");
          }}
          type="number"
          min="0"
          max="100"
        />
      )}
    />
    <FormErrorMessage>
      {errors.Value &&
        errors.Value.message}
    </FormErrorMessage>
      </FormControl>

      <MultiSelect
        title={t("pages.charges.order_type_id")}
        data={ordersTypes.data && ordersTypes.data.map(el => ({
          ...el,
          label: el.OrderTypeName,
          value: el.OrderTypeId
        }))}
        isMulti={false}
        onChange={(e) => setValue("OrderTypeId", e)}
      />

      <MultiSelect
        title={t("pages.charges.tax_group_id")}
        data={taxesGroups.data && taxesGroups.data.map(el => ({
          ...el,
          label: el.TaxGroupName,
          value: el.TaxGroupId
        }))}
        isMulti={false}
        onChange={(e) => setValue("TaxGroupId", e)}
      />

      <Checkbox {...register("AutoApply")}>
        {t("pages.charges.auto_apply")}
      </Checkbox>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={charges.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={charges.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default UpdateModal