import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


  export const AddReciptJornul = createAsyncThunk(
    "ReciptJornul/AddReciptJornul",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(
          `/Accountant/AddReciptJornul?accountchart=${JSON.stringify(args)}`
        );
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );