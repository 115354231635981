import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { updateSection } from "../../services/sectionsService";

const UpdateSectionModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  //console.log(data);
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const dispatch = useDispatch();
  const sections = useSelector((state) => state.sections);
 

  const { handleSubmit, control } = useForm({
    defaultValues: data,
  });

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.branches.update_section")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              //console.log("kol al values" ,values);
              // values.TableName = values.BranchSectionName;
              // values.SeatsNo = values.BranchSectionTable;
              // values.BranchSectionId = null;
              // values.BranchId = null;
              console.log(values)
              //dispatch(updateSection(values ))
                // .unwrap()
                // .then((_) => {
                //   toast({ description: t("pages.branches.section_updated") });
                //   onClose();
                // });
            })}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.branches.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.branches.name")}
                name="TableName"
                isFormController
                isRequired
                control={control}
              />

              <Input
                type="number"
                label={t("pages.branches.tables")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.branches.tables")}
                name="SeatsNo"
                isFormController
                isRequired
                control={control}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={sections.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={sections.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default UpdateSectionModal;
