import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* --------------------------------------------------Get Stocks In Products ------------------------------------------------ */
export const getOutOfStockForProduct = createAsyncThunk("settingOutStack/getOutOfStockForProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/ProductOutOfStockListById?productid={"ProductId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* --------------------------------------------------Get Branches In Stack ------------------------------------------------ */
export const getBranchesInStock = createAsyncThunk("settingOutStack/getBranchesInStock",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* --------------------------------------------------Add Stock In Product ------------------------------------------------ */
export const setOutStockForProduct = createAsyncThunk("settingOutStack/setOutStockForProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/ProductOutOfStock/AddProductOutOfStock?productoutofstock=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
