import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { createBranchTag, getBranchesTags, getTagsInBranches } from "../../services/branchesTagsService";
import { useParams } from "react-router-dom";

export default function CreateTagModal ({ data, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit, setValue } = useForm();
  const toast = useToast({position: "top",duration: 3000,status: "success",});
 
  const branchesTags = useSelector((state) => state.branchesTags);
  //console.log(branchesTags);
  useEffect(() => {
    dispatch(getBranchesTags());
  }, [dispatch]);

  const id = useParams()
  //console.log(id.branchId)
  const { branchId } = useParams();

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.branches.add_tags")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              //console.log(values);
              //values.TagId = values.TagId.TagId;
              if (values.TagId=== undefined||values.TagId.length<=0){
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              }else{
                values.TagId = values.TagId?.map((el) => ({
                  TagId: el.TagId,
                }));
                values.BranchId=id.branchId
                //console.log(values);
  
                dispatch(createBranchTag(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.branches.tag_created") });
                    dispatch(getTagsInBranches(branchId));
                    onClose();
                  });
              }
            
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.branches.tags")}
                data={
                  branchesTags.BranchesTags &&
                  branchesTags.BranchesTags.map((el) => ({
                    ...el,
                    label: el.TagName,
                    value: el.TagId,
                  }))
                }
                isMulti={true}
                onChange={(e) => setValue("TagId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={branchesTags.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={branchesTags.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
};
