import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";


export const addComboSize = createAsyncThunk(
    "comboSize/addComboSize",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/ComboSize/AddComboSize?combosize=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getCombosSize = createAsyncThunk(
    "comboSize/getCombosSize",
    async (args, thunkApi) => {
      try {
        //console.log(args)
        const { data } = await axios.get(`/Menu/ComboSize/ComboSizeByComboId?comboid={"ComboId":${args}}`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getAllCombosSize = createAsyncThunk(
    "comboSize/getAllCombosSize",
    async (args, thunkApi) => {
      try {
        //console.log(args)
        const { data } = await axios.get(`/Menu/Combo/LookupComboSize`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getAllGroups = createAsyncThunk(
    "comboSize/getAllGroups",
    async (args, thunkApi) => {
      try {
        //console.log(args)
        const { data } = await axios.get(`/Menu/Combo/LookupComboSizeGroup`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );




  export const addComboGroup = createAsyncThunk(
    "comboSize/addComboGroup",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/ComboSizeGroup/AddComboSizeGroup?combosizegroup=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getGroupofSize = createAsyncThunk(
    "comboSize/getGroupofSize",
    async (args, thunkApi) => {
      // args=1
      try {
        //console.log(args)
        const { data } = await axios.get(`/Menu/ComboSizeGroup/ComboSizeGroupByComboSizeId?comboSizeid={"ComboId":${args}}`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const deleteGroup = createAsyncThunk(
    "comboSize/deleteGroup",
    async (args, thunkApi) => {
      try {
        // const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Menu/Combo/DeleteComboById?comboid=={"InventoryItemId":${args}}`);
  
        // const { data } = await axios.get(`/combos/${args.id}`);
        const { data } = await axios.get(`/Menu/ComboSizeGroup/DeleteComboSizeGroupById?combosizegroupid={"ComboSizeGroupId":${args}}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const addComboGroupOptions = createAsyncThunk(
    "comboSize/addComboGroupOptions",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/ComboGroupOption/AddComboGroupOption?combogroupoption=${JSON.stringify(args)}`);
        //console.log("data")
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
    );

    export const getGroupofOptions = createAsyncThunk(
      "comboSize/getGroupofOptions",
      async (args, thunkApi) => {
        // args=17
        try {
          //console.log(args)
          const { data } = await axios.get(`/Menu/ComboGroupOption/ComboGroupOptionByComboSizeGroupId?combosizegroupid={"ComboId":${args}}`);
          //console.log(data)
          return thunkApi.fulfillWithValue(data);
        } catch (e) {
          return thunkApi.rejectWithValue(e.response.data);
        }
      }
    );


    export const getProductCompoSize = createAsyncThunk(
      "comboSize/getProductCompoSize",
      async (args, thunkApi) => {
        // args=17
        try {
          //console.log(args)
          const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage/Tax/LookUpProduct`);
          //console.log(data)
          return thunkApi.fulfillWithValue(data);
        } catch (e) {
          return thunkApi.rejectWithValue(e.response.data);
        }
      }
    );

    export const getCustomPriceBranches = createAsyncThunk(
      "comboSize/getCustomPriceBranches",
      async (args, thunkApi) => {
        try {
          const { data } = await axios.get(`/Menu/Combo/GetComboCustomPriceBranch?comboid=${JSON.stringify({ "ComboId": args })}`);
          console.log(data)
          return thunkApi.fulfillWithValue(data);
        } catch (e) {
          return thunkApi.rejectWithValue(e.response.data);
        }
      }
    );