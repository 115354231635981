import { useTranslation } from "react-i18next"
import { Flex, Input, Stack } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import GButton from "components/ui/button/Button";
import { Link } from "react-router-dom";

const ImportModal = ({
  onClose
}) => {
  const { t } = useTranslation();

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("general.import_items")}
      modalContent={(
        <Stack spacing={4} p={4}>
          <Input
            type="file"
          />

          <Flex gap={4} justifyContent="space-between" alignItems="center">
            <Flex gap={4}>
              <GButton
                type="submit"
                title={t("general.submit")}
                bg="primary"
                color="light"
                border="1px solid transparent"
                hover={{ color: "primary", borderColor: "primary", bg: "none" }}
              />
              <GButton
                title={t("general.close")}
                onClick={onClose}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
              />
            </Flex>
            <Link to="!#"
            >{t("general.download_sample")}</Link>
          </Flex>
        </Stack>
      )}
    />
  )
}

export default ImportModal