import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Button, Flex, SimpleGrid, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import EditKitchenFlowModal from '../components/modal/EditKitchenFlowModal';
import StationKitchenFlow from '../components/StationKitchenFlow';
import KitchenFlowBranche from '../components/KitchenFlowBranche';
import KitchenFlowProduct from '../components/KitchenFlowProduct';
import DeleteKitchenFlow from '../components/DeleteKitchenFlow';
import { useParams } from 'react-router-dom';
import { activeKitchenFlow, getAllKitchenFlow, getkitchenDetails } from '../services/kitchenFlowService';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';

const KitchenFlowDetails = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 55);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useParams();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });

  const data = useSelector(state => state.kitchenFlow);

  useEffect(()=>{
    dispatch(getAllKitchenFlow())
  },[dispatch])
 
  const [showmodal, setShoModal] = useState(false)

  function filterObjectsById(objectsArray, targetId) {
    // Use the Array.find() method to find the first object with a matching KitchenFlowsId
    const resultObject = objectsArray.find(obj => obj.KitchenFlowsId == targetId);
  
    // Return the found object or null if no match was found
    return resultObject || null;
  }
  
  // Example usage:
  const arrayOfObjects =data.data
  
  const targetId = id.id;
  const result = filterObjectsById(arrayOfObjects, targetId);
 

  const activeFunction = () => {
    dispatch(activeKitchenFlow(id.id)).unwrap()
      .then(_ => {
        dispatch(getAllKitchenFlow())
        toast({ description: t("pages.kitchenFlow.activate_process") });
      });
  }

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  return (
    <>

      <BreadCrumb>

        <Flex justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">
        
        <Flex style={{justifyContent:"center",alignItems:"center"}}>
          <Box
            as="p" fontSize="1.6rem" textTransform="capitalize"
          >
            {result?.KitchenFlowsName || "-"}
          </Box>

          {
            result?.IsActive?(
               <div style={{backgroundColor:"#65B741",color:"white",margin:"15px",
                            borderRadius : "8px",
                            padding:"6px 15px 6px 15px",fontSize:"18px"}}> 
                              {t("pages.kitchenFlow.active")}
                            </div>
               ):(
                <div style={{backgroundColor:"#FF6868",color:"white",margin:"15px",
                            borderRadius : "8px",
                            padding:"6px 15px 6px 15px",fontSize:"18px"}}> 
                              {t("pages.kitchenFlow.inactive")}
                            </div>
                 )
          }

          </Flex>
          <Box>

            <GButton
              title={t("general.edit")}
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() => setShoModal(true)}
            />

            <Button
              type="button" bg="none" border="1px solid" borderColor="border"
              textTransform="capitalize" fontSize="1rem" color="text"
              _hover={{ bg: "primary", color: "light" }}
              style={{ margin: "10px" }}
              onClick={activeFunction}

            >

              <Flex alignItems="center" gap={1}>
                { result?.IsActive? 
                    <>
                      {t("pages.kitchenFlow.deactivate")}
                    </>
                    : 
                    <>
                      {t("pages.kitchenFlow.activate")}
                    </>
                  }

              </Flex>
            </Button>

          </Box>


        </Flex>





      </BreadCrumb>

      <StationKitchenFlow></StationKitchenFlow>

      <KitchenFlowBranche></KitchenFlowBranche>

      <KitchenFlowProduct></KitchenFlowProduct>

      <DeleteKitchenFlow></DeleteKitchenFlow>



      {
        showmodal && (
          <EditKitchenFlowModal onClose={() => setShoModal(false)} ></EditKitchenFlowModal>
        )
      }


    </>
  );
}

export default KitchenFlowDetails;
