import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  Text,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import {
  addNewSettingCities,
  getHrCitiesList,
} from "features/hr/hrSetting/services/hrSettingServices";

export default function AddNewDepartmentsModal({ onClose }) {
  const [notes, setNotes] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.add_new_department")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.CityId = null;
                values.CityNotes = notes;
                dispatch(addNewSettingCities(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.hr.department_ceated_successfuly"),
                    });
                    dispatch(getHrCitiesList());
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.CityName}>
                  <FormLabel
                    htmlFor="CityName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.arabicName")}
                  </FormLabel>
                  <Controller
                    name="CityName"
                    control={control}
                    // defaultValue={details.CityName}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\u0621-\u064A\u0660-\u0669 ]+$/,
                        message: t(
                          "pages.hr.hr_validation.mustContainArabicLetter"
                        ),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.arabicName")}
                        onChange={(e) => {
                          handleInputChange(e, "CityName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.CityName && errors.CityName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.CityNameEn}>
                  <FormLabel
                    htmlFor="CityNameEn"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.englishName")}
                  </FormLabel>
                  <Controller
                    name="CityNameEn"
                    control={control}
                    // defaultValue={details.CityNameEn}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.englishName")}
                        onChange={(e) => {
                          handleInputChange(e, "CityNameEn");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.CityNameEn && errors.CityNameEn.message}
                  </FormErrorMessage>
                </FormControl>
                
                <Box>
                  <Text mb="8px"> {t("general.notes")} </Text>
                  <Textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder=""
                  />
                </Box>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
