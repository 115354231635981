import GModal from "components/ui/modal/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {Box,Checkbox, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import Input from "components/form/Input";
import { Controller, useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {getStorageUnit,} from "../../services/storageUnitService";
import { getinventoryCat } from "../../services/inventoryCatService.js";
import { editItem, getItemDetails } from "../../services/itemService.js";
import { useNavigate, useParams } from "react-router-dom";
import NewMultiSelect from "components/ui/NewMultiSelect/NewMultiSelect.jsx";

const EditItemModal = ({ onClose }) => {
  const id = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({position: "top",duration: 3000,status: "success",});
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const inventoryCatData = useSelector((state) => state.inventoryCat);
  const itemDetailsData = useSelector((state) => state.items);

  const { handleSubmit, control,  getValues, setValue,  formState: { errors },  trigger,  setError,  clearErrors,} = useForm();

  const [disabledState,setDisabledState]=useState(2);
  const [IsFixed,setIsFixed]=useState(false);

  useEffect(() => {
    dispatch(getStorageUnit());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getinventoryCat());
  }, [dispatch]);

  useEffect(() => {
    if(disabledState == 2){
      setValue("InventoryItemCostPerStorageUnit", 0);
      setIsFixed(false);
    }
    else {
      setIsFixed(true);
    }
  }, [disabledState]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel")]);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.inventory.edit_item_head")}
    modalContent={
      <Box p={4}>
        <form
          onSubmit={handleSubmit((values) => {
            values.InventoryItemId = itemDetailsData?.itemDetails[0]?.InventoryItemId;
            values.InventoryItemRef = itemDetailsData?.itemDetails[0]?.InventoryItemRef;
            values.InventoryCategoryId = values.InventoryCategoryId?.InventoryCategoryId;
            values.IsFixed = IsFixed ;
            values.UserId = localStorage.getItem("userId");
            if ( values.InventoryCategoryId === undefined ) {
                  toast2({ description: t("validation.required"), });
            } else {
              dispatch(editItem(values))
                .unwrap()
                .then((_) => {
                  toast({ description: t("pages.items.edit_done") });
                  dispatch(getItemDetails(id.itemId));
                  onClose();
                });
            }
          })}
        >
          <Stack spacing={6}>
            <FormControl isInvalid={errors.InventoryItemNameEng}>
              <FormLabel
                htmlFor="InventoryItemNameEng"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.categories.name")}
              </FormLabel>
              <Controller
                name="InventoryItemNameEng"
                control={control}
                defaultValue={itemDetailsData?.itemDetails[0]?.InventoryItemNameEng}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.categories.name")}
                    onChange={(e) => {
                      handleInputChange(e, "InventoryItemNameEng");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.InventoryItemNameEng &&
                  errors.InventoryItemNameEng.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.InventoryItemNameAr}>
              <FormLabel
                htmlFor="InventoryItemNameAr"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.categories.name_localized")}
              </FormLabel>
              <Controller
                name="InventoryItemNameAr"
                control={control}
                defaultValue={itemDetailsData?.itemDetails[0]?.InventoryItemNameAr}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalide"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.categories.name_localized")}
                    onChange={(e) => {
                      handleInputChange(e, "InventoryItemNameAr");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.InventoryItemNameAr &&
                  errors.InventoryItemNameAr.message}
              </FormErrorMessage>
            </FormControl>

            <Flex alignItems="flex-end" gap={4}>
                <Input
                label={t("pages.inventory.sku")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={itemDetailsData?.itemDetails[0]?.InventoryItemRef}
                readOnly
                />
            </Flex>
            
            <MultiSelect
              title={t("pages.inventory.inventory_category")}
              data={
                inventoryCatData.data &&
                inventoryCatData.data.map((el) => ({
                  ...el,
                  label: el.InventoryCategoryName,
                  value: el.InventoryCategoryId,
                }))
              }
              isMulti={false}
              isRequired={true}
              onChange={(e) => setValue("InventoryCategoryId", e)}
            />

            <FormControl isInvalid={errors.StorageUnit}>
              <FormLabel
                htmlFor="StorageUnit"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.inventory.storage_unit")}
              </FormLabel>
              <Controller
                name="StorageUnit"
                control={control}
                defaultValue={itemDetailsData?.itemDetails[0]?.StorageUnit}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.inventory.storage_unit")}
                    onChange={(e) => {
                      handleInputChange(e, "StorageUnit");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.StorageUnit &&
                  errors.StorageUnit.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.IngredientUnit}>
              <FormLabel
                htmlFor="IngredientUnit"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.inventory.ingredients_unit")}
              </FormLabel>
              <Controller
                name="IngredientUnit"
                control={control}
                defaultValue={itemDetailsData?.itemDetails[0]?.IngredientUnit}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.inventory.ingredients_unit")}
                    onChange={(e) => {
                      handleInputChange(e, "IngredientUnit");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.IngredientUnit &&
                  errors.IngredientUnit.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.StorageToIngredient}>
              <FormLabel
                htmlFor="StorageToIngredient"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.inventory.storage_to_ingredients")}
              </FormLabel>
              <Controller
                name="StorageToIngredient"
                control={control}
                defaultValue={itemDetailsData?.itemDetails[0]?.StorageToIngredient}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.inventory.storage_to_ingredients")}
                    onChange={(e) => {
                      handleInputChange(e, "StorageToIngredient");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.StorageToIngredient &&
                  errors.StorageToIngredient.message}
              </FormErrorMessage>
            </FormControl>

            <NewMultiSelect
              title={t("pages.inventory.costing_method")}
              data={[{value:"1",label:"fixed"},{value:"2",label:"from Transaction"}]}
              onSelectionChange={(e) => {
                setDisabledState(e.value)
              }}
              defaultValue={{value:"2",label:"from Transaction"}}
            />

            {
              disabledState == 2 ? 
              <Flex alignItems="flex-end" gap={4}>
                  <Input
                    label={t("pages.inventory.from_transactions")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={t("pages.inventory.from_transactions")}
                    readOnly
                  />
              </Flex>  
              : 
              <FormControl isInvalid={errors.InventoryItemCostPerStorageUnit}>
              <FormLabel
                htmlFor="InventoryItemCostPerStorageUnit"
                style={{ textTransform: "capitalize" }}
              >
                  {t("pages.inventory.item_cost")}
              </FormLabel>
              <Controller
                name="InventoryItemCostPerStorageUnit"
                control={control}
                disabled={(disabledState == 2)}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder= {t("pages.inventory.item_cost")}
                    onChange={(e) => {
                      handleInputChange(e, "InventoryItemCostPerStorageUnit");
                    }}
                    type="number"
                    min="1"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.InventoryItemCostPerStorageUnit &&
                  errors.InventoryItemCostPerStorageUnit.message}
              </FormErrorMessage>
              </FormControl>
            }

            <FormControl isInvalid={errors.Barcode}>
              <FormLabel
                htmlFor="Barcode"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.inventory.barcode")}
              </FormLabel>
              <Controller
                name="Barcode"
                control={control}
                defaultValue={parseInt(itemDetailsData?.itemDetails[0]?.Barcode)}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.inventory.barcode")}
                    onChange={(e) => {
                      handleInputChange(e, "Barcode");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.Barcode && errors.Barcode.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.MinLevel}>
              <FormLabel
                htmlFor="MinLevel"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.inventory.minimum_level")}
              </FormLabel>
              <Controller
                name="MinLevel"
                control={control}
                defaultValue={itemDetailsData?.itemDetails[0]?.MinLevel}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.inventory.minimum_level")}
                    onChange={(e) => {
                      handleInputChange(e, "MinLevel");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.MinLevel && errors.MinLevel.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.MaxLevel}>
              <FormLabel
                htmlFor="MaxLevel"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.inventory.maximum_level")}
              </FormLabel>
              <Controller
                name="MaxLevel"
                control={control}
                defaultValue={itemDetailsData?.itemDetails[0]?.MaxLevel}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.inventory.maximum_level")}
                    onChange={(e) => {
                      handleInputChange(e, "MaxLevel");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.MaxLevel && errors.MaxLevel.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.ParLevel} >
              <FormLabel
                htmlFor="ParLevel"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.inventory.par_level")}
              </FormLabel>
              <Controller
                name="ParLevel"
                control={control}
                defaultValue={itemDetailsData?.itemDetails[0]?.ParLevel}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.inventory.par_level")}
                    onChange={(e) => {
                      handleInputChange(e, "ParLevel");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.ParLevel && errors.ParLevel.message}
              </FormErrorMessage>
            </FormControl>

            <Flex gap={4}  >
              <GButton
                type="submit"
                title={t("general.save")}
                bg="primary"
                color="light"
                border="1px solid transparent"
                //isLoading={categories.isLoading}
                hover={{
                  color: "primary",
                  borderColor: "primary",
                  bg: "none",
                }}
              />
              <GButton
                title={t("general.close")}
                onClick={onClose}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                //isLoading={categories.isLoading}
                hover={{
                  color: "red.600",
                  borderColor: "red.600",
                  bg: "none",
                }}
              />
            </Flex>
          </Stack>
          
        </form>
      </Box>
    }
  />
  );
};

export default EditItemModal;
