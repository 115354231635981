import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


/* -----------------------------------------------  Get Suppliers  ------------------------------------------------------- */
export const getSuppliers = createAsyncThunk("suppliers/getSuppliers", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/menu/Inventory/LookupSuppliers`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* -----------------------------------------------  Add Supplier To Item ------------------------------------------------------- */
export const linkSupplierToItem = createAsyncThunk("suppliers/linkSupplierToItem", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Menu/Inventory/AddItemsSuppliers?itemssuppliers=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -----------------------------------------------  Get Suppliers For Item- ------------------------------------------------------ */
export const getSuppliersForItem = createAsyncThunk("suppliers/getSuppliersForItem", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/Items/SuppliersByItemId?ItemId={"InventoryItemId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);