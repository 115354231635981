import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { Box, Flex, Stack, useToast } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import Input from "components/form/Input"
import GButton from "components/ui/button/Button"
import { updateTax } from "../../services/taxesService"

const UpdateModal = ({
  data,
  onClose
}) => {
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const taxes = useSelector(state => state.taxes);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: data
  });

  const updateTaxValidationSchema = {
    name: {
      required: t("validation.required")
    }
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.taxes.update_tax")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            dispatch(updateTax({
              id: values.id,
              values
            }))
              .unwrap()
              .then(_ => {
                toast({ description: t("pages.taxes.tax_updated") });;
                onClose()
              });
          })}>
            <Stack spacing={6}>
              <Input
                label={t("pages.taxes.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.taxes.name")}
                name="name"
                rules={updateTaxValidationSchema.name}
                error={errors.name}
                isFormController
                isRequired
                control={control}
              />
              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={taxes.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={taxes.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default UpdateModal