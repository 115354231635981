const routes = {
  login: "login",
  signup: "signup",
  profile: "profile",
  dashboard: "",
  dashboard_general: "dashboard",
  dashboard_branches: "dashboard/branches",
  dashboard_inventory: "dashboard/inventory",
  dashboard_call_center: "dashboard/call_center",
  orders: "orders",
  orderDetails: "orders/:id",
  createOrder: "orders/createOrder",
  call_center: "call_center",
  call_center_product: "call_center/:orderid",
  customers: "customers",
  customersـdetails: "customers/:customerId",

  // --------------------------------------- Start Reports ---------------------------------------------
  // inventory_reports
  reports_inventory_reports: "reports/inventory_reports",
  reports_inventory_reports_inventory_levels:
    "reports/inventory_reports/inventory_levels",
  reports_inventory_reports_inventory_history:
    "reports/inventory_reports/inventory_history",
  reports_inventory_reports_inventory_control:
    "reports/inventory_reports/inventory_control",
  reports_inventory_reports_purchase_orders:
    "reports/inventory_reports/purchase_orders",
  reports_inventory_reports_transfers_orders:
    "reports/inventory_reports/transfers_orders",
  reports_inventory_reports_transfers: "reports/inventory_reports/transfers",
  reports_inventory_reports_purchasing: "reports/inventory_reports/purchasing",
  reports_inventory_reports_cost_adjustment_history:
    "reports/inventory_reports/cost_adjustment_history",
  // sales_reports
  reports_sales: "reports/sales_reports",
  reports_sales_report_sales: "reports/sales_reports/sales_reports",
  reports_sales_payment_reports: "reports/sales_reports/payment_report",
  // Business_reports
  reports_business_reports: "reports/business_reports",
  reports_business_reports_taxes: "reports/business_reports/taxes",
  reports_business_reports_tips: "reports/business_reports/tips",
  reports_business_reports_shifts: "reports/business_reports/shifts",
  reports_business_reports_tills: "reports/business_reports/tills",
  reports_business_reports_drawer_operations:
    "reports/business_reports/drawer_operations",
  reports_business_reports_gift_cards: "reports/business_reports/gift_cards",
  reports_business_reports_business_days:
    "reports/business_reports/business_days",
  reports_business_reports_void_returns:
    "reports/business_reports/void_returns",
  // Analysis_reports
  reports_analysis_reports: "reports/analysis_reports",
  reports_analysis_reports_menu_engineering:
    "reports/analysis_reports/menu_engineering",
  reports_analysis_reports_inventory_cost_analysis:
    "reports/analysis_reports/inventory_cost_analysis",
  reports_analysis_reports_branches_trend:
    "reports/analysis_reports/branches_trend",
  reports_analysis_reports_speed_of_services:
    "reports/analysis_reports/speed_of_services",
  reports_analysis_reports_product_cost:
    "reports/analysis_reports/product_cost",
  reports_analysis_reports_modifier_options_cost:
    "reports/analysis_reports/modifier_options_cost",
  reports_analysis_reports_inventory_items__cost:
    "reports/analysis_reports/inventory_items__cost",

  // --------------------------------------- End Reports -----------------------------------------------

  inventory_items: "inventory/items",
  inventory_items_details: "inventory/items/:itemId",

  inventory_suppliers: "inventory/suppliers",
  inventory_suppliers_details: "inventory/suppliers/:suppliersId",

  inventory_transfers_orders: "inventory/transfers_orders",
  inventory_transfers_orders_details:
    "inventory/transfers_orders/:TransferOrdersId",
  inventory_inventory_count: "inventory/inventory_count",
  inventory_inventory_count_details:
    "inventory/inventory_count/:InventoryCountId",

  inventory_purchases: "inventory/purchases",
  inventory_purchases_details: "inventory/purchase_details/:purchaseId",
  inventory_purchases_return_details:
    "inventory/returnItems_toSupplier/:ReturnId",

  // purchases: "inventory/transfers_orders",
  // inventory_transfers: "inventory/transfers",
  inventory_production: "inventory/production",
  inventory_production_details: "inventory/production/:ProductionId",

  // purchases: "inventory/purchase_orders",
  inventory_purchase_orders: "inventory/purchase_orders",
  inventory_purchase_orders_details:
    "inventory/purchase_orders/:purchaseOrderId/:purchaseOrderStatus",

  // transfers: "inventory/transfers",
  inventory_transfers: "inventory/transfers",
  inventory_transfers_details: "inventory/transfer_details/:TransfersId",

  //inventory more
  inventory_More: "inventory/More",

  //Cost_adjustment
  inventory_more_costadjsutment: "inventory/costAdjsutment",
  inventory_more_costadjsutment_Details: "inventory/costAdjsutment/:id",

  //quantity_adjustment
  inventory_quantity_adjustment: "inventory/quantityAdjustment",
  inventory_quantity_adjustment_details: "inventory/quantityAdjustment/:id",

  //order_transactions
  inventory_order_transactions: "inventory/order_transactions",

  //WareHouse
  inventory_warehouse: "inventory/warehouse",
  inventory_warehouse_details: "inventory/warehouse_details/:WarehouseId",

  //Inventory Categories
  inventory_Categories: "inventory/inventorycategories",
  // inventory_warehouse_details: "inventory/warehouse_details/:id",

  products_categories: "products/categories",
  products_sort_categories: "products/categories/sort",
  products_products: "products/products",
  products_products_details: "products/products/:productId",
  products_combos: "products/combos",
  products_combos_details: "products/combos/:comboId",
  products_gifts_cards: "products/gifts_cards",
  products_gifts_cards_details: "products/gifts_cards/:giftsCardId",
  products_modifiers: "products/modifiers",
  products_modifiers_options: "products/modifiers_options",
  products_modifiers_options_details:
    "products/modifiers_options/:modifiersOptionId",
  products_groups: "products/groups",
  products_groups_details: "products/groups/:groupId",

  management_users: "management/users",
  management_users_Details: "management/users/:userId",
  management_roles: "management/roles",
  management_branches: "management/branches",
  management_branches_details: "management/branches/:branchId",
  management_branches_details_sectionTables:
    "management/branches/:branchId/BranchSectionTables/:BranchSectionId",
  management_devices: "management/devices",
  management_devices_details: "management/devices/:id",

  management_discount: "management/discount",
  management_discount_details: "management/discount_details/:id",

  management_coupons: "management/coupons",
  management_coupons_details: "management/coupons_details/:id",

  management_promotion: "management/promotions",
  management_CreatePromotion: "management/promotions/create",

  management_timed_events: "management/timed_events",
  management_timed_events_details: "management/timed_events_details/:id",

  management_more: "management/more",
  management_settings: "management/settings",
  management_settings_general: "management/settings/general",
  management_settings_loyality: "management/settings/loyality",
  management_settings_casherApp: "management/settings/CasherApp",
  management_settings_receipt: "management/settings/receipt",
  management_settings_call_center: "management/settings/call_center",
  management_settings_display_app: "management/settings/display_app",
  management_settings_digital_wallet: "management/settings/digital_wallet",
  management_settings_sms_providers: "management/settings/sms_providers",
  management_settings_inventory_transactions:
    "management/settings/inventory_transactions",
  management_taxes_groups: "management/taxes_groups",
  management_payment_methods: "management/payment_methods",

  management_OnlineOrdering: "management/OnlineOrdering",
  management_deliverCharge: "management/deliver_charge",

  management_charges: "management/charges",
  management_brands: "management/brands",
  management_tags: "management/tags",
  management_reasons: "management/reasons",

  management_kitchenFlow: "management/KitchenFlow",
  management_kitchenFlow_details: "management/KitchenFlow/:id",

  //mangment Delivery zone
  management_DeliverZone: "management/deliver_zone",
  management_DeliverZone_details: "management/deliver_zone/:id",

  //mangment Price Tags
  management_PriceTags: "management/price_tags",
  management_PriceTag_Details: "management/price_tags/:PriceTagsId",

  management_reservations: "management/reservations",
  management_reservation_details: "management/reservations/:reservationId",

  hr: "hr",

  general_accounts: "general_accounts",

  Daily_restrictions: "Daily_restrictions",
  Ledger: "Ledger",
  Ledger_branches: "Ledger_branches",
  income_statement: "income_statement",
  Trial_Balance: "Trial_Balance",
  Expenses: "Expenses",
  bankingAccount: "bankingAccount",
  receipt: "receipt",
  AccountAdd: "AccountAdd",

  //---------------------------------------------  HR Section  ------------------------------------------------
  HrNavigations: "HrNavigations",
  //   -------------------- Hr Self Services --------------------
  HrSelfServices: "HrSelfServices",
  ServicesLeaveRequests: "HrServicesLeaveRequests",
  ServicesLeavePermission: "HrServicesLeavePermission",
  ServicesBankLetter: "HrServicesBankLetter",
  HrLetter: "HrLetter",

  //   -------------------- Hr Section --------------------
  HrSection: "HrSection",
  MyTasks: "MyTasks",
  dashboardHr: "dashboardHr",
  Employees: "HREmployees",
  AddEmployee: "HRAddEmployee",
  EmployeesDetails: "HREmployeesPage/:id",
  TodayAttendance: "HRTodayAttendance",
  addendanceByDate: "HRaddendanceByDate",
  LeaveReports: "HRLeaveReports",
  // Attendance Settings
  AttendanceSettings: "HRAttendanceSettings",
  GroupShift: "/GroupShift",
  GroupShiftDetails: "/GroupShiftDetails/:id",
  AddGroupShift: "/addgroupshift",
  TimeShift: "/TimeShift",
  TimeShiftDetails: "/TimeShiftDetails/:id",
  overTime: "HRoverTime",
  Positions: "HRPositions",
  VacationList: "HRVacationList",
  //--------------- Hr Setting Routers  ---------------
  setting: "HRsetting",
  religionsetting: "/HRsetting/ReligionSetting",
  citiessetting: "/HRsetting/CitiesSetting",
  nationalitysetting: "/HRsetting/NationalitySetting",
  vacationTypessetting: "/HRsetting/VacationTypesSetting",
  permessionTypessetting: "/HRsetting/PermessionTypesSetting",
  bouncessetting: "/HRsetting/BouncesSetting",
  departmentssetting: "/HRsetting/DepartmentsSetting",
  //--------------- Hr Setting Routers  ---------------

  // Payroll: "Payroll",

  // welcomHr: "welcomHr",

  permation: "permation",
  AccountingDashboard: "AccountingDashboard",
};

export default routes;
