import { createSlice } from "@reduxjs/toolkit";

import {getReciveingOnlineOrders,setSettingsOnlineOrder} from "../services/onlineOrderService"


let initialState = {
    isLoading: false,
    data: [],
    cashers:[],
    pages: 0,
    errors: null
  };


  const onlineOrdersSlice = createSlice({
    name: "RecivingOrders",
    initialState,

    extraReducers: (builder) => {
        builder.addCase(getReciveingOnlineOrders.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(getReciveingOnlineOrders.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          //console.log(state.data)
          state.errors = null;
        })
        builder.addCase(getReciveingOnlineOrders.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })


        //


        
          builder.addCase(setSettingsOnlineOrder.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(setSettingsOnlineOrder.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.data)
            state.errors = null;
          })
          builder.addCase(setSettingsOnlineOrder.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

        //





     
    }

})


export default onlineOrdersSlice.reducer;