import React from 'react';
import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from 'components/ui/button/Button';
import { IoCloseOutline } from "react-icons/io5";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterModal from './Modal/FilterModal';
import { resetOrderTransactionsFilteredData } from '../services/OrderTransactionServices';
import Pagination from "components/ui/pagination/Pagination";
import FilteredDataTable from './FilteredDataTable';
import ConsumptionDataTable from './ConsumptionDataTable';
import WasteDataTable from './WasteDataTable';
import ReturnedDataTable from './ReturnedDataTable';
import OrderTransactionDataTable from './OrderTransactionDataTable';

export default function OrderTransactionsTable({data , filteredData}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [sortType, setSortType] = useState("all");

    // Show Filter Modal State
    const [showFilterModal, setShowFilterModal] = useState(false);
    
    const resetFunction = () => {
        dispatch(resetOrderTransactionsFilteredData());
      };

    // Consumption Data ------------------------------------------------- 
    const consumbtionData = data?.filter((item)=>{
        return item?.Type === "Consumption From Order" ;
    });
    const consumbtionFilterData = filteredData?.filter((item)=>{
        return item?.Type === "Consumption From Order" ;
    });

  return <>
  
  <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
        <Flex
                flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
                p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
            >

                <Flex
                    alignItems="center" flexWrap="wrap"
                    textTransform="capitalize" gap={4} color="text" bg="light"
            >
                    <GButton
                      title={t("general.all")}
                      bg="none"
                      isDisabled={false}
                      borderRadius={0}
                      color={sortType === "all" ? "primary" :"none"}  
                      borderBottom={sortType === "all" ? "2px solid" :"none"}   
                      borderColor={sortType === "all" ? "primary" :"none"} 
                      onClick={() => setSortType("all")}
                      />

                    <GButton
                    title={t("general.consumption")}
                    bg="none"
                    isDisabled={false}
                    borderRadius={0}
                    color={sortType === "consumption" ? "primary" :"none"}  
                    borderBottom={sortType === "consumption" ? "2px solid" :"none"}   
                    borderColor={sortType === "consumption" ? "primary" :"none"} 
                    onClick={() => setSortType("consumption")}
                    />

                    <GButton
                    title={t("general.return")}
                    bg="none"
                    isDisabled={false}
                    borderRadius={0}
                    color={sortType === "return" ? "primary" :"none"}  
                    borderBottom={sortType === "return" ? "2px solid" :"none"}   
                    borderColor={sortType === "return" ? "primary" :"none"} 
                    onClick={() => setSortType("return")}
                    />

                    <GButton
                    title={t("general.waste")}
                    bg="none"
                    isDisabled={false}
                    borderRadius={0}
                    color={sortType === "waste" ? "primary" :"none"}  
                    borderBottom={sortType === "waste" ? "2px solid" :"none"}   
                    borderColor={sortType === "waste" ? "primary" :"none"} 
                    onClick={() => setSortType("waste")}
                    />

                </Flex>

                <GButton
                    title={
                        filteredData?.length === 0
                        ? t("general.filter")
                        : t("general.clear_filter")
                    }
                    border="1px solid"
                    borderColor="border"
                    postfixElement={
                        filteredData?.length === 0 ? (
                        <FiIcons.FiFilter size={16} />
                        ) : (
                        <IoCloseOutline fontWeight={800} size={20} />
                        )
                    }
                    hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                    onClick={
                        filteredData?.length === 0
                        ? () => setShowFilterModal(true)
                        : resetFunction
                    }
                />

        </Flex>
        
                {
                    data?.length === 0 ?
                    <Flex
                            p={4}
                            bg="white"
                            alignItems="center"
                            justifyContent="center"
                            color="text"
                            textTransform="capitalize"
                            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                            borderRadius={6}
                            >
                            {t("pages.branches.no_data")}
                    </Flex>
                    :
                    <>
                    {
                        filteredData?.length > 0 ? 
                        <FilteredDataTable 
                            data={
                                sortType === "consumption" ?
                                consumbtionFilterData
                                : sortType === "waste" ?
                                ""
                                :
                                sortType === "return" ?
                                ""
                                :
                                filteredData
                            } 
                        />
                        : sortType === "consumption" ? 
                        <ConsumptionDataTable data={consumbtionData} />
                        : sortType === "waste" ? 
                        <WasteDataTable data={null} />
                        : sortType === "return" ? 
                        <ReturnedDataTable data={null} />
                        : 
                        <OrderTransactionDataTable data={data} />
                    }
                    </>
                }
    </Box>


    {
        showFilterModal && (
            <FilterModal 
                onClose={()=>setShowFilterModal(false)} 
                data={data} 
            />
        )
    }
  
  </>
}
