import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { addSupplierReturnItems, getDropDownReturnItems, getReturnedItemList } from "../../services/returnedSuppliersServices";

const AddReturnItems = ({ onClose , PurchasingId , ReturnId}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, setValue } = useForm();
  const toast = useToast({   position: "top", duration: 3000,   status: "success", });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const items = useSelector(state => state.returnSupplier);  

  useEffect(() => {
    dispatch(getDropDownReturnItems(PurchasingId));
  }, [dispatch , PurchasingId]);

// dropDownReturnedItems
  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.purchases.add_items")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              values.InventoryItems = values.InventoryItems?.map((el) => ({
                InventoryItemId: el.InventoryItemId,
                Quantity: el.Quantity,
                UnitCost: el?.UnitCost,
                TotalCost: el?.TotalCost,
              }));
              values.ReturnId = ReturnId;
              if (
                values.InventoryItems?.length === 0 ||
                values.InventoryItems === undefined
              ) {
                toast2({ description: t("validation.choose_first") });
              } else {
                dispatch(addSupplierReturnItems(values))
                  .unwrap()
                  .then((_) => {
                    toast({  description: t("pages.purchases.supplier_add_items"),});
                    dispatch(getReturnedItemList(ReturnId));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.purchases.items")}
                data={
                  items.dropDownReturnedItems &&
                  items.dropDownReturnedItems.map((el) => ({
                    ...el,
                    label: el.InventoryItemNameEng,
                    value: {
                      InventoryItemId : el?.InventoryItemId,
                      Quantity : el?.Quantity,
                      UnitCost : el?.UnitCost,
                      TotalCost : el?.TotalCost,
                    }
                  }))
                }
                isMulti={true}
                isRequired={true}
                onChange={(e) => setValue("InventoryItems", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.inventory_item_details.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={items.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={items.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};
export default AddReturnItems;