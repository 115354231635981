import { useToast } from "@chakra-ui/react";
import { getBranches } from "features/management/branches/services/branchesService";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GModal from "components/ui/modal/Modal";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import Input from "components/form/Input";
import { Box, Flex, Stack } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useParams } from "react-router-dom";
import { addCustomPriceModifierOption } from "../../services/modifiersOptionsService";

const CustomPriceModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { handleSubmit, control, getValues, watch, setValue, register } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  const branches = useSelector((state) => state.branches);

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const id = useParams();

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.combos.create_custom_price")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              values.BranchId = values.BranchId?.BranchId;
              values.ModiferOptionId = id.modifiersOptionId;
              if (values.BranchId === undefined) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                dispatch(addCustomPriceModifierOption(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.combos.combo_created") });
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.combos.branches")}
                data={
                  branches.data &&
                  branches.data.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))
                }
                isMulti={false}
                isRequired
                onChange={(e) => setValue("BranchId", e)}
              />

              <Input
                label={t("pages.combos.price")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.combos.price")}
                name="Price"
                isFormController
                control={control}
                isRequired
                type="number"
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default CustomPriceModal;
