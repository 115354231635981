import { createSlice } from "@reduxjs/toolkit";
import {
  getBranchesOfProduct,
  assigenProductToBranch,
  getBranchesInProducts
} from "../services/customPriceService.js";

let initialState = {
  isLoading: false,
  data: [],
  product: null,
  pages: 0,
  errors: null,
  priceBranche:[],
  branchInSelectBranch : []
};


const customPriceSlice = createSlice({
    name: "customPrice",
    initialState,
    extraReducers: (builder) => {
/* --------------------------------------------- Get Product Branches ------------------------------------------------*/
/* --------------------------------------------- Get Product Branches ------------------------------------------------*/
// getBranchesOfProduct
      builder.addCase(getBranchesOfProduct.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
  
      builder.addCase(getBranchesOfProduct.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.priceBranche = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      });
  
      builder.addCase(getBranchesOfProduct.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
/* --------------------------------------------- Get Branches In Product ------------------------------------------------*/
      builder.addCase(getBranchesInProducts.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
  
      builder.addCase(getBranchesInProducts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.branchInSelectBranch = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      });
  
      builder.addCase(getBranchesInProducts.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
/* --------------------------------------------------------------------------------------------------------------*/
      builder.addCase(assigenProductToBranch.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
  
      builder.addCase(assigenProductToBranch.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      });
  
      builder.addCase(assigenProductToBranch.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
    }
  });
  
  export default customPriceSlice.reducer;