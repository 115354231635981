import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { createProduct, getProducts } from "../../services/productsService";
import { getCategories } from "features/products/categories/services/categoriesService";
import { getTaxesGroups } from "features/management/taxesGroups/services/taxesGroupsService";
import { getCostTypes } from "../../services/costTypesService";
import { useLocation, useNavigate } from "react-router-dom";
import { generator } from "utils/generator";
import NewMultiSelect from "components/ui/NewMultiSelect/NewMultiSelect";
import { UploadImg } from "services/uploadImg";

export default function CreateModal({ onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    register,
    control,
    formState: { errors },
    trigger,
  } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);
  const costTypes = useSelector((state) => state.costTypes);
  const taxesGroups = useSelector((state) => state.taxesGroups);

  watch(["ProductSKURef"]);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTaxesGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCostTypes());
  }, [dispatch]);

  /* ------------------------- Reload Data ------------------------- */

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const bollenData = [
    {
      lable: "yes",
      value: true,
    },
    {
      lable: "no",
      value: false,
    },
  ];

  const SellingMethodData = [
    {
      lable: "unit",
      value: false,
    },
    {
      lable: "wight",
      value: true,
    },
  ];

  const PricingMethod = [
    {
      lable: "fixed",
      value: true,
    },
    {
      lable: "open price",
      value: true,
    },
  ];

  const [showProductFixedCoast, setshowProductFixedCoast] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setImageUrl(URL.createObjectURL(file));
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.products.create_product")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
               
                 if (!selectedFile) {
                   toast2({
                     description: t("validation.you_must_choose_image"),
                   });
                   return;
                 }

                 const formData = new FormData();
                 formData.append("imageFile", selectedFile);

                values.UserId = localStorage.getItem("userId");

                if (showProductFixedCoast && values.ProductFixedCoast === "") {
                  toast2({
                    description: t("pages.DailyRestrictions.searchvalidation"),
                  });
                } else if (
                  values.ProductCategoriesId === undefined ||
                  values.ProductCostTypeId === undefined ||
                  values.ProductSKURef === undefined ||
                  //values.TaxGroupId[0]?.TaxGroupId === undefined
                  values.TaxGroupId === undefined ||
                  values.TaxGroupId.length === 0 ||
                  values.IsReadyProduct === undefined ||
                  values.IsFixedPrice === undefined ||
                  values.IsPriceBasedOnWeight === undefined
                ) {
                  toast2({
                    description: t("pages.DailyRestrictions.searchvalidation"),
                  });
                } else {
                  values.ProductId = null;
                  values.ProductCategoriesId =
                    values.ProductCategoriesId?.ProductCategoriesId;
                  values.ProductCostTypeId =
                    values.ProductCostTypeId?.ProductCostTypeId;
                  values.IsReadyProduct = values.IsReadyProduct.value;
                  values.IsPriceBasedOnWeight =
                    values.IsPriceBasedOnWeight.value;
                  values.IsFixedPrice = values.IsFixedPrice.value;
                  values.TaxGroupId = [
                    { TaxGroupId: values.TaxGroupId?.TaxGroupId },
                  ];
                   dispatch(UploadImg(formData)).then((result) => {
                     console.log(result.payload);
                     values.imgPath = result.payload;
                      dispatch(createProduct(values))
                        .unwrap()
                        .then((_) => {
                          toast({
                            description: t("pages.products.product_created"),
                          });
                          dispatch(getProducts());
                          onClose();
                        });
                   });
                 
                }
              })}
            >
              <Stack spacing={6}>
              
                <div>
                  <input type="file" onChange={handleFileChange} />
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Selected"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "200px",
                        marginTop: "10px",
                      }}
                    />
                  )}
                </div>

                <FormControl isInvalid={errors.ProductName}>
                  <FormLabel
                    htmlFor="ProductName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.products.name")}
                  </FormLabel>
                  <Controller
                    name="ProductName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.products.name")}
                        onChange={(e) => handleInputChange(e, "ProductName")}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.ProductName && errors.ProductName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.ProductNameLoc}>
                  <FormLabel
                    htmlFor="ProductNameLoc"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.products.name_localized")}
                  </FormLabel>
                  <Controller
                    name="ProductNameLoc"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.products.name_localized")}
                        onChange={(e) => handleInputChange(e, "ProductNameLoc")}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.ProductNameLoc && errors.ProductNameLoc.message}
                  </FormErrorMessage>
                </FormControl>

                <MultiSelect
                  title={t("pages.products.category")}
                  data={
                    categories.data &&
                    categories.data.map((el) => ({
                      ...el,
                      label: el.ProductCategoriesNameEng,
                      value: el.ProductCategoriesId,
                    }))
                  }
                  isMulti={false}
                  onChange={(e) => setValue("ProductCategoriesId", e)}
                />

                {/* <Input
                  type="number"
                  label={t("pages.products.ref")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.products.ref")}
                  name="ProductSKURef"
                  isFormController
                  isRequired
                  control={control}
                /> */}
                <Flex alignItems="flex-end" gap={4}>
                  <Input
                    label={t("pages.inventory.sku")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={t("pages.inventory.sku")}
                    isRequired
                    value={getValues("ProductSKURef") || ""}
                    readOnly
                  />
                  <GButton
                    title={t("pages.inventory.generate_sku")}
                    height="50px"
                    bg="light"
                    border="1px solid"
                    borderColor="border"
                    hover={{
                      bg: "primary",
                      borderColor: "primary",
                      color: "light",
                    }}
                    onClick={() => setValue("ProductSKURef", generator({}))}
                  />
                </Flex>

                <NewMultiSelect
                  data={
                    bollenData &&
                    bollenData.map((el) => ({
                      ...el,
                      label: el.lable,
                      value: el.value,
                    }))
                  }
                  onSelectionChange={(e) => {
                    setValue("IsReadyProduct", e);
                  }}
                  title={t("pages.products.stock_product")}
                />

                <FormControl isInvalid={errors.ProductPrice}>
                  <FormLabel
                    htmlFor="ProductPrice"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.products.price")}
                  </FormLabel>
                  <Controller
                    name="ProductPrice"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.products.price")}
                        onChange={(e) => {
                          handleInputChange(e, "ProductPrice");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />

                  <FormErrorMessage>
                    {errors.ProductPrice && errors.ProductPrice.message}
                  </FormErrorMessage>
                </FormControl>

                <NewMultiSelect
                  data={
                    PricingMethod &&
                    PricingMethod.map((el) => ({
                      ...el,
                      label: el.lable,
                      value: el.value,
                    }))
                  }
                  onSelectionChange={(e) => {
                    setValue("IsFixedPrice", e);
                    //console.log(e)
                  }}
                  title={t("pages.products.Pricing_Method")}
                />

                <MultiSelect
                  title={t("pages.products.cost_type")}
                  data={
                    costTypes.data &&
                    costTypes.data.map((el) => ({
                      ...el,
                      label: el.ProductCostTypeName,
                      value: el.ProductCostTypeId,
                    }))
                  }
                  isMulti={false}
                  onChange={(e) => {
                    setValue("ProductCostTypeId", e);
                    //console.log(e);
                    if (e.value == 2) {
                      setshowProductFixedCoast(true);
                    } else if (e.value == 1) {
                      setshowProductFixedCoast(false);
                      setValue("ProductFixedCoast", "");
                    }
                  }}
                />

                {showProductFixedCoast && (
                  <FormControl isInvalid={errors.ProductFixedCoast}>
                    <FormLabel
                      htmlFor="ProductFixedCoast"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.products.Product_Fixed_Cost")}
                    </FormLabel>
                    <Controller
                      name="ProductFixedCoast"
                      control={control}
                      defaultValue=""
                      rules={{
                        pattern: {
                          value: /^[0-9]+$/,
                          message: t("validation.only_numbers_allowed"),
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.products.Product_Fixed_Cost")}
                          onChange={(e) => {
                            handleInputChange(e, "ProductFixedCoast");
                          }}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.ProductFixedCoast &&
                        errors.ProductFixedCoast.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                <NewMultiSelect
                  data={
                    SellingMethodData &&
                    SellingMethodData.map((el) => ({
                      ...el,
                      label: el.lable,
                      value: el.value,
                    }))
                  }
                  onSelectionChange={(e) => {
                    setValue("IsPriceBasedOnWeight", e);
                  }}
                  title={t("pages.products.Selling_Method")}
                />

                <MultiSelect
                  title={t("pages.products.tax_group")}
                  data={
                    taxesGroups.data &&
                    taxesGroups.data.map((el) => ({
                      ...el,
                      label: el.TaxGroupName,
                      value: el.TaxGroupId,
                    }))
                  }
                  isMulti={false}
                  onChange={(e) => setValue("TaxGroupId", e)}
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    isLoading={products.isLoading}
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    isLoading={products.isLoading}
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
