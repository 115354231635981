import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getCustomersTags = createAsyncThunk(
  "sectiones/getCustomersTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Customers/CustomerTagList?customerid=${JSON.stringify({ CustomerId: args })}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createCustomerTag = createAsyncThunk(
  "sectiones/createCustomerTag",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Customers/AddCustomerTags?customertags=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateCustomerTag = createAsyncThunk(
  "sectiones/updateCustomerTag",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Sectiones/AddEditSection?section=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);