import { useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";

export default function PendingTransactionsDataTable({ data }) {
  console.log(data);
  const { t } = useTranslation();

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const pendeingTransactions = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.dashboard.Branch")}</Th>
            <Th>{t("pages.dashboard.ref")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pendeingTransactions?.map((item, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>{item?.BranchName}</Td>
              <Td>{item?.Ref}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(data?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(data?.length / recordsPerPage)
          )
        }
      />
    </>
  );
}
