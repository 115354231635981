import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const GetAccByFinancialId = createAsyncThunk(
  "DailyRestrictions/GetAccByFinancialId",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Accountant/GetAccJournalByFinancialId?data={"FinancialPeriodsId":${args}}`
      );
    //   console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
