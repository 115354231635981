import { NavLink } from "react-router-dom"
import { Box, Flex, SimpleGrid } from "@chakra-ui/react"
import * as FiIcons from "react-icons/fi";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb"
import { useTranslation } from "react-i18next"
import routes from "navigations/routes";

const More = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 1,
      text: t("pages.taxes.taxes_groups"),
      icon: <FiIcons.FiDollarSign size={16} />,
      path: routes.management_taxes_groups
    },
    {
      id: 2,
      text: t("pages.payment_methods.payment_methods"),
      icon: <FiIcons.FiPlay size={16} />,
      path: routes.management_payment_methods
    },
    {
      id: 3,
      text: t("pages.charges.charges"),
      icon: <FiIcons.FiDollarSign size={16} />,
      path: routes.management_charges
    },
    {
      id: 4,
      text: t("pages.DeliverZone.delivery_zone"),
      path: routes.management_DeliverZone
    },

    {
      id: 5,
      text: t("pages.tags.tags"),
      icon: <FiIcons.FiTag size={16} />,
      path: routes.management_tags
    },
    {
      id: 6,
      text: t("pages.reasons.reasons"),
      icon: <FiIcons.FiMessageCircle size={16} />,
      path: routes.management_reasons
    },
    {
      id: 7,
      text: t("pages.kitchenFlow.kitchenFlow"),
      path:routes.management_kitchenFlow
    },
    {
      id: 8,
      text: t("pages.reservations.reservations"),
      icon: <FiIcons.FiBook size={16} />,
      path: routes.management_reservations
    },
    {
      id: 9,
      text: t("pages.brands.brands"),
      icon: <FiIcons.FiBarChart size={16} />,
      path: routes.management_brands
    },
    {
      id: 10,
      text: t("pages.online_Orders.online_ordering"),
      path: routes.management_OnlineOrdering
    },
    {
      id: 11,
      text: t("pages.price_tags.price_tags"),
      path: routes.management_PriceTags
    },
    {
      id: 12,
      text: t("pages.management.settings.settings"),
      icon: <FiIcons.FiSettings size={16} />,
      path: routes.management_settings
    }
    ,
    {
      id: 12,
      text: t("pages.DeliverCharge.Delivery_Charges"),
      path: routes.management_deliverCharge
    }
  ];

  return (
    <>
      <BreadCrumb>
        <Box
          as="p" fontSize="1.6rem" textTransform="capitalize"
        >
          {t("pages.management.management")}
        </Box>
      </BreadCrumb>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
        {items.map((item,index) => (
          <Flex
            key={index} as={NavLink} to={`/${item.path}`} gap={2}
            alignItems="center" justifyContent="center"
            bg="light" p={4} borderRadius={4} paddingBlock={6} color="text"
            textTransform="capitalize"
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
          >
            {item.icon}
            {item.text}
          </Flex>
        ))}
      </SimpleGrid>
    </>
  )
};

export default More;