import { createSlice } from "@reduxjs/toolkit";
import {getItems,createItem,getItemDetails,editItem,deleteItem,getDeletedItem, getFilteredData, resetFilteredData} from "../services/itemService"


let initialState = {
    isLoading: false,
    data: [],
    itemDetails:[],
    pages: 0,
    errors: null,
    deletedItemsData:[],
    filteredData:[]
  };


  const itemsSlice = createSlice({
    name: "items",
    initialState,

    extraReducers:(builder)=>{

      builder.addCase(getItems.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getItems.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getItems.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //

      //  builder.addCase(getStorageUnit.pending, (state) => {
      //    state.isLoading = true;
      //    state.errors = null;
      //  })

      // builder.addCase(getStorageUnit.fulfilled, (state, { payload }) => {
      //   state.isLoading = false;
      //   state.storageUnitData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //   console.log(state.storageUnitData)
      //   state.errors = null;
      // })

      // builder.addCase(getStorageUnit.rejected, (state) => {
      //   state.isLoading = false;
      //   state.errors = [{ msg: "something went wrong!" }];
      // })


    
      builder.addCase(createItem.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(createItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        //console.log(payload)
        //state.data.push(payload);
        state.errors = null;
      })
      builder.addCase(createItem.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //

      builder.addCase(getItemDetails.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getItemDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.itemDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getItemDetails.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //

      builder.addCase(editItem.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(editItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(editItem.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      //
      builder.addCase(deleteItem.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(branch => branch.id !== payload.id);
        state.errors = null;
      })
      builder.addCase(deleteItem.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      //
      builder.addCase(getDeletedItem.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getDeletedItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.deletedItemsData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.deletedItemsData)
        state.errors = null;
      })

      builder.addCase(getDeletedItem.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(getFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        console.log("youssef" , state.filteredData);
        state.errors = null;
      });
      builder.addCase(getFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
  
      builder.addCase(resetFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.filteredData = [];
        state.errors = null;
      });
      builder.addCase(resetFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });


    }
  })


  export default itemsSlice.reducer