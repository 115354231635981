import { createSlice } from "@reduxjs/toolkit";
import {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getOrderAuth,
  getCustomerAuth,
  getInventoryAuth,
  getMenuAuth,
  getAdminMangmentAuth,
  getMangmentOthersAuth,
  getReportsAuth,
  getDashBoardAuth,
  getApplicationsAuth,
  getRoleDetailsAuthById,
  getAccountAuth,
  
} from "../services/rolesService";

let initialState = {
  isLoading: false,
  data: [],
  RoleDetails:[],
  pages: 0,
  errors: null
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getRoles.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
      state.errors = null;
    })
    builder.addCase(getRoles.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createRole.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createRole.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createRole.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateRole.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateRole.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const indexAt = state.data.findIndex(role => role.id === payload.id);
      // state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateRole.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteRole.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteRole.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = state.data.filter(role => role.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteRole.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(getOrderAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getOrderAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.OrderAuthLookUp = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.OrderAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getOrderAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(getCustomerAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCustomerAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.CustomerAuthLookUp = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.CustomerAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getCustomerAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(getInventoryAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getInventoryAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.InventoryAuthLookUp = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.CustomerAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getInventoryAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getMenuAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getMenuAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.menuAuthLookUp = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.CustomerAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getMenuAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(getAdminMangmentAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getAdminMangmentAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.AdminMangmentAuthLookUp = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.CustomerAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getAdminMangmentAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })



    builder.addCase(getMangmentOthersAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getMangmentOthersAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.MangmentOtherAuthLookUp = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.CustomerAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getMangmentOthersAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(getReportsAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getReportsAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.ReportsAuthLookUp = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.CustomerAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getReportsAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(getDashBoardAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDashBoardAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.DashBoardAuthLookUp = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.CustomerAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getDashBoardAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(getApplicationsAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getApplicationsAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.ApplicationsAuthLookUp = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.CustomerAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getApplicationsAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })




    builder.addCase(getAccountAuth.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getAccountAuth.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.AccountAuthLookUp =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      // console.log(state.AccountAuthLookUp)
      state.errors = null;
    });
    builder.addCase(getAccountAuth.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });



    builder.addCase(getRoleDetailsAuthById.pending, (state) => {
      //state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getRoleDetailsAuthById.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.RoleDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.CustomerAuthLookUp)
      state.errors = null;
    })
    builder.addCase(getRoleDetailsAuthById.rejected, (state) => {
      //state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

   


  }
});

export default rolesSlice.reducer;