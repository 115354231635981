import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {  Box, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput,} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { Controller, useForm } from "react-hook-form";
import { getInventoryCategoriesList, updateInventoryCategories,} from "../../services/InventoryCategoriesServices";

export default function EditModal({ onClose, id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({ defaultValues: { ...id } });

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.inventoryCategories.edit_inventoryCategories")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.InventoryCategoryId = id.InventoryCategoryId;
                values.Ref = id.InventoryCategoryRef;
                values.UserId = localStorage.getItem("userId");
                dispatch(updateInventoryCategories(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.inventoryCategories.inventoryCategories_Updated"
                      ),
                    });
                    dispatch(getInventoryCategoriesList());
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.Name}>
                  <FormLabel
                    htmlFor="Name"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.inventoryCategories.name")}
                  </FormLabel>
                  <Controller
                    name="Name"
                    control={control}
                    defaultValue={id.InventoryCategoryName}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.inventoryCategories.name")}
                        onChange={(e) => {
                          handleInputChange(e, "Name");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Name && errors.Name.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.NameLoc}>
                  <FormLabel
                    htmlFor="NameLoc"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.inventoryCategories.name_Localized")}
                  </FormLabel>
                  <Controller
                    name="NameLoc"
                    control={control}
                    defaultValue={id.InventoryCategoryNameLoc}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalide"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t(
                          "pages.inventoryCategories.name_Localized"
                        )}
                        onChange={(e) => {
                          handleInputChange(e, "NameLoc");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.NameLoc && errors.NameLoc.message}
                  </FormErrorMessage>
                </FormControl>

                <Flex alignItems="flex-end" gap={4}>
                  <Input
                    label={t("pages.inventoryCategories.reference")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={id.InventoryCategoryRef}
                    readOnly
                  />
                </Flex>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    //   isLoading={isLoading}
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    //   isLoading={isLoading}
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
