import { createSlice } from "@reduxjs/toolkit";
import { getDevicesTypes,getProductCat ,getordersType,getordersTypeDisabled,getkitchenLanguage,getorderTags,editSubCasherSettings,getBrnachesSection} from "../services/devicesTypesService";

let initialState = {
  isLoading: false,
  data: [],
  productCat:[],
  ordersType:[],
  ordersTypeDisabled:[],
  kitchenLanguage:[],
  ordertags:[],
  branchesSection:[],
  pages: 0,
  errors: null
};

const devicesTypesSlice = createSlice({
  name: "devicesTypes",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDevicesTypes.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDevicesTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getDevicesTypes.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //


    builder.addCase(getProductCat.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getProductCat.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.productCat = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getProductCat.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //

    builder.addCase(getordersType.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getordersType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ordersType = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.ordersType)
      state.errors = null;
    })
    builder.addCase(getordersType.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    //

    builder.addCase(getordersTypeDisabled.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getordersTypeDisabled.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ordersTypeDisabled = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.ordersType)
      state.errors = null;
    })
    builder.addCase(getordersTypeDisabled.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //

    
    builder.addCase(getkitchenLanguage.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getkitchenLanguage.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.kitchenLanguage = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.ordersType)
      state.errors = null;
    })
    builder.addCase(getkitchenLanguage.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    //

        
    builder.addCase(getorderTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getorderTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ordertags = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.ordersType)
      state.errors = null;
    })
    builder.addCase(getorderTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })



    //



    builder.addCase(getBrnachesSection.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getBrnachesSection.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branchesSection = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.ordersType)
      state.errors = null;
    })
    builder.addCase(getBrnachesSection.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    //

    builder.addCase(editSubCasherSettings.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(editSubCasherSettings.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.ordertags = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.ordersType)
      state.errors = null;
    })
    builder.addCase(editSubCasherSettings.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

  }
});

export default devicesTypesSlice.reducer;