import React from 'react'
import AuthImg from "../../../assets/images/2.jpg"
import { useTranslation } from 'react-i18next';
import "./NotAuthorizedCompo.css"
const NotAuthorizedCompo = () => {
    const { t } = useTranslation();

  return (
    <div style={{maxHeight:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",gap:"25px",position:"relative"}}>
            <h1 className='notAuthText' style={{color:"#1778f2",fontWeight:"bold",position:"absolute",top:"20px"}}>
            {t("general.authorization")}
                
            </h1>
            <img src={AuthImg} alt='p' style={{borderRadius:"40px"}}></img>
        </div>
    </div>
  )
}

export default NotAuthorizedCompo
