import React from 'react';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import DeleteKitchenFlowModal from './modal/DeleteKitchenFlowModal';

const DeleteKitchenFlow = () => {
    const { t } = useTranslation();
    const [showModal,setShowModal]=useState(false)

  return (
    <div style={{marginTop:"25px"}}>
    <Flex
      bg="light" borderRadius={6} alignItems="center" justifyContent="space-between" gap={4} p={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
      <Text fontSize=".9rem" color="text">  {t("pages.kitchenFlow.if_deleted")}  </Text>
      <GButton
        bg="red"
        color="light"
        title={t("general.delete")}
        hover={{ bg: "red" }}
      onClick={() => setShowModal(true)}
      />

{
    showModal&&
     <DeleteKitchenFlowModal
        onClose={() => setShowModal(false)}
    />
}

</Flex>
</div>
  );
}

export default DeleteKitchenFlow;
