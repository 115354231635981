import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- create products discounts  -------------------------------------------------------------------*/
export const createDiscountProductTags = createAsyncThunk(
  "discountsProductTags/createDiscountProductTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCount/AddDiscountProductTags?producttagid=${JSON.stringify(
          args
        )}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Get products Discounts List  -------------------------------------------------------------------*/
export const getDiscountsProductTags = createAsyncThunk(
  "discountsProductTags/getDiscountsProductTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetDiscountProductTags?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Delete Product Tag In Discount  -------------------------------------------------------------------*/
export const deleteProductTagInDiscount = createAsyncThunk(
  "discountsProductTags/deleteProductTagInDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCountProductTag/DisCountProductTagById?DisCountProductTagId={"DisCountProductTagId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Get Tags In Product Tags ---------------------------------------------------*/
export const getTagsInProductTags = createAsyncThunk(
  "discountsProductTags/getTagsInProductTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/LookupProductTags?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
