import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import {deleteReason, getReasons } from "../../services/reasonsService";


const DeleteModal = ({ data,onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const reasons = useSelector(state => state.reasons);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteReason(data))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.reasons.reason_deleted") });
            dispatch(getReasons());
            onClose();
          })
      }}
      isLoading={reasons.isLoading}
    />
  )
}

export default DeleteModal;