import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import Tabs from "../components/Tabs";
import routes from "navigations/routes";
import { Outlet } from "react-router-dom";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Settings = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 45);

  const { t } = useTranslation();

  const items = [
    {
      path: routes.management_settings_general,
      text: t("pages.management.settings.general.general"),
    },
    {
      path: routes.management_settings_loyality,
      text: t("pages.management.settings.loyality.loyality"),
    },
    {
      path: routes.management_settings_receipt,
      text: t("pages.management.settings.receipt.receipt"),
    },
    {
      path: routes.management_settings_casherApp,
      text: t("pages.management.settings.casher_app.casher_app"),
    },
    {
      path: routes.management_settings_call_center,
      text: t("pages.management.settings.call_center.call_center"),
    },
    {
      path: routes.management_settings_display_app,
      text: t("pages.management.settings.display_app.display_app"),
    },
    {
      path: routes.management_settings_digital_wallet,
      text: t("pages.management.settings.digital_wallet.digital_wallet"),
    },
    {
      path: routes.management_settings_sms_providers,
      text: t("pages.management.settings.sms_providers.sms_providers"),
    },
    {
      path: routes.management_settings_inventory_transactions,
      text: t(
        "pages.management.settings.inventory_transactions.inventory_transactions"
      ),
    },
  ];

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <BreadCrumb>
        <Box as="p" fontSize="1.6rem" textTransform="capitalize">
          {t("pages.management.settings.settings")}
        </Box>
      </BreadCrumb>

      <Tabs items={items} />

      <Box padding={{ base: 0, md: 16 }}>
        <Outlet />
      </Box>
    </>
  );
};

export default Settings;
