import { createSlice } from "@reduxjs/toolkit";
import {
  getBouncesDropDown,
  getHomeLeaveTypeDropdown,
  getInsuranceOffice,
  getLeaveTypeDropdown,
  getLookupRspTypeDropDownById,
  getScalePointDropDownById,
} from "../services/generalEmployeeServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  InsuranceOffice: [],
  HomeLeaveType: [],
  LeaveTypeDropDown: [],
  BouncesDropDown: [],
  ScalePointDropDown: [],
  RspTypeDropDown: [],

  // Filter data
  filteredData: [],
};

const generalEmployee = createSlice({
  name: "generalEmployee",
  initialState,
  extraReducers: (builder) => {
    /* ----------------------------------  Get Insurance Office Dropdown  ---------------------------------------*/
    builder.addCase(getInsuranceOffice.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getInsuranceOffice.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.InsuranceOffice =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getInsuranceOffice.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------  Get HomeLeaveType Dropdown  ---------------------------------------*/
    builder.addCase(getHomeLeaveTypeDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getHomeLeaveTypeDropdown.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.HomeLeaveType =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(getHomeLeaveTypeDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------  Get LeaveType Dropdown  ---------------------------------------*/
    builder.addCase(getLeaveTypeDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getLeaveTypeDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.LeaveTypeDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getLeaveTypeDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------  Get Bounces Dropdown  ---------------------------------------*/
    builder.addCase(getBouncesDropDown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getBouncesDropDown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.BouncesDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getBouncesDropDown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------  Get ScalePointDropDown ById   ---------------------------------------*/
    builder.addCase(getScalePointDropDownById.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getScalePointDropDownById.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.ScalePointDropDown =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(
      getScalePointDropDownById.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      }
    );
    /* ----------------------------------  Get RspTypeDropDownById ById   ---------------------------------------*/
    builder.addCase(
      getLookupRspTypeDropDownById.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.errors = null;
      }
    );
    builder.addCase(
      getLookupRspTypeDropDownById.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.RspTypeDropDown =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(
      getLookupRspTypeDropDownById.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      }
    );
  },
});

export default generalEmployee.reducer;
