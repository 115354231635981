import { createSlice } from "@reduxjs/toolkit";
import {  getCustomerlist ,
  updateCustomer,
  deleteCustomer,
  getDeletedList,
  createNewCustomer,
  getCustomerDetailsById,
  getCustomersFilteredData,
  resetCustomersFilteredData,
  defaultCustomerResponse,
} from "../services/customersService";

let initialState = {
  isLoading: false,
  customerData: [],
  customerDetails: null,
  pages: 0,
  errors: null,
  deletedList: [],
  cretateCustomerResponse:{},
  filteredData : []
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  extraReducers: (builder) => {
/*------------------------------------------------------ 1- Creating Customers -------------------------------------------------- */
/*---------------------------------------------------- Get Customer list ----------------------------------------- */
    builder.addCase(getCustomerlist.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCustomerlist.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.customerData = ((payload[0] && JSON.parse(payload[0].jsonData)) || []).reverse();
      state.errors = null;
    })
    builder.addCase(getCustomerlist.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
    /* --------------------------------------------- Create New Customer ---------------------------------------- */
    builder.addCase(createNewCustomer.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createNewCustomer.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.cretateCustomerResponse = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      console.log(state.cretateCustomerResponse)
      state.errors = null;
    })
    builder.addCase(createNewCustomer.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    builder.addCase(defaultCustomerResponse.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(defaultCustomerResponse.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.cretateCustomerResponse = {}
      //console.log(state.cretateCustomerResponse)
      state.errors = null;
    })
    builder.addCase(defaultCustomerResponse.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

/*------------------------------------------------------ 2- Update Customers -------------------------------------------------- */
/*----------------------------------------- Update Customer ----------------------------------------- */
    builder.addCase(updateCustomer.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateCustomer.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const indexAt = state.data.findIndex(customer => customer.id === payload.id);
      // state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateCustomer.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
    /*----------------------------------------- Get Customer Details----------------------------------------- */
    builder.addCase(getCustomerDetailsById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCustomerDetailsById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.customerDetails = ( payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      //console.log(state.customerDetails)
      state.errors = null;
    })
    builder.addCase(getCustomerDetailsById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/*------------------------------------------------------ 2- Delete Customers -------------------------------------------------- */
/*----------------------------------------- Delete Customer ----------------------------------------- */
    builder.addCase(deleteCustomer.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteCustomer.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(deleteCustomer.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/*----------------------------------------- Delete Customer List ----------------------------------------- */
    builder.addCase(getDeletedList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDeletedList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.deletedList = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getDeletedList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

   /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
   builder.addCase(getCustomersFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getCustomersFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getCustomersFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetCustomersFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetCustomersFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetCustomersFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* --------------------------------------------------------------------------------------------------- */

  }
});

export default customersSlice.reducer;