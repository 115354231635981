import { createSlice } from "@reduxjs/toolkit";
import { createNewWarehouse, deleteWarehouse, getInventoryDayTimeList, getWarehouseFilteredData, getWarehouseList, resetWarehouseFilteredData, updateWarehouse } from "../services/WarehouseServices";

let initialState = {
    isLoading: false,
    errors: null,

    data: [],
    InventoryDayTimeList: [],

    filteredData:[],
  };

  const WarehouseSlice = createSlice({
    name: "warehouse",
    initialState,

    extraReducers:(builder)=>{
      /* ---------------------------------------------------- Get Warehouse Data List ------------------------------------------------- */
      builder.addCase(getWarehouseList.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getWarehouseList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getWarehouseList.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      /* ---------------------------------------------------- Get Inventory Day Time List ------------------------------------------------- */
      builder.addCase(getInventoryDayTimeList.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getInventoryDayTimeList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.InventoryDayTimeList = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getInventoryDayTimeList.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Crate New Warehouse  -------------------------------------------------------*/
builder.addCase(createNewWarehouse.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(createNewWarehouse.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(createNewWarehouse.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Update Warehouse  -------------------------------------------------------*/
builder.addCase(updateWarehouse.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(updateWarehouse.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(updateWarehouse.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Delete Warehouse  -------------------------------------------------------*/
builder.addCase(deleteWarehouse.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(deleteWarehouse.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(deleteWarehouse.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

         /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
    builder.addCase(getWarehouseFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getWarehouseFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getWarehouseFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetWarehouseFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetWarehouseFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetWarehouseFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
    }
  })


  export default WarehouseSlice.reducer;