import {
  Box,
  Checkbox,
  Flex,
  SimpleGrid,
  Text,
  Input as ChInput,
} from "@chakra-ui/react";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import GButton from "components/ui/button/Button";
import LedgerBranchestable from "features/general_accounts/LedgerBranches/components/LedgerBranchestable";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { checkIdExists } from "utils/cheackAuth";

const LedgerBranchesPage = () => {
  const { t, i18n } = useTranslation();

  const { handleSubmit, control, getValues, watch, setValue, register } =
    useForm();

  const [showTable, SetShowTabel] = useState(false);

  /* ------------------------------------- Authrzation ------------------------------ */
  const retrievedArray = useDecryptedPrivilege();
  const ManageAccountant_Ledger = checkIdExists(retrievedArray, 1018);

  if (ManageAccountant_Ledger === false) {
    return <NotAuthorizedCompo />;
  }

  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.Ledger.Ledger_account_details")}
          </Text>
        </Flex>
      </Box>

      <Box
        bg="light"
        marginInline={-6}
        marginTop={5}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div>{t("pages.Ledger.account_type")}</div>

        <Checkbox {...register("IsFixed")}>{t("pages.Ledger.all")}</Checkbox>

        <Checkbox {...register("IsFixed")}>
          {t("pages.Ledger.selected")}
        </Checkbox>
      </Box>

      <Box
        bg="light"
        marginInline={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex>
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            style={{ width: "100%" }}
          >
            <Flex style={{ flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                  {t("pages.Ledger.account_control_code")}
                </label>
                <ChInput disabled={true} style={{ width: "70%" }}></ChInput>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                  {t("pages.Ledger.period_from")}
                </label>
                <ChInput style={{ width: "70%" }} type="date"></ChInput>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Checkbox {...register("IsFixed")}>
                  {t("pages.Ledger.Display_uncleared_checks")}
                </Checkbox>
              </div>
            </Flex>

            <Flex style={{ flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                  {t("pages.Ledger.account_control_name")}
                </label>
                <ChInput disabled={true} style={{ width: "70%" }}></ChInput>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                  {t("pages.Ledger.period_to")}
                </label>
                <ChInput style={{ width: "70%" }} type="date"></ChInput>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Checkbox {...register("IsFixed")}>
                  {t("pages.Ledger.View_current_account")}
                </Checkbox>
              </div>
            </Flex>
          </SimpleGrid>
        </Flex>

        <GButton
          title={t("pages.Ledger.dispaly")}
          bg="#54A0FF"
          color="light"
          hover={{ bg: "primary" }}
          style={{ marginTop: "15px" }}
          onClick={() => SetShowTabel(true)}
          // onClick={() => setShowUpdateComboModal({ ...combo, ComboId: +comboId || null })}
        />
      </Box>

      {showTable && <LedgerBranchestable></LedgerBranchestable>}
    </>
  );
};

export default LedgerBranchesPage;
