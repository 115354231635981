import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  Flex,
  Input,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getPositionDetailsById } from "../../services/positionsServices";

export default function ViewModal({
  onClose,
  id,
  classifications,
  departments,
  jobTitles,
  reportingToPosition,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { positionDetails } = useSelector((state) => state.positionsSlice);
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    dispatch(getPositionDetailsById(id));
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, [dispatch, id]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.position.view_position_details")}
        modalContent={
          showContent ? (
            <Box p={4}>
              <Box>
                <Stack spacing={6}>
                  <Box>
                    <Text>{t("pages.hr.position.position_code")}</Text>
                    <Box
                      border={"1px"}
                      borderColor={"gray.300"}
                      padding={2}
                      borderRadius={5}
                      textColor="gray.600"
                      // fontWeight={"semibold"}
                      marginTop={"2"}
                    >
                      <Text> {positionDetails[0]?.PositionCode}</Text>
                    </Box>
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.position.position_department")}</Text>
                    <Box
                      border={"1px"}
                      borderColor={"gray.300"}
                      padding={2}
                      borderRadius={5}
                      textColor="gray.600"
                      // fontWeight={"semibold"}
                      marginTop={"2"}
                    >
                      <Text> {positionDetails[0]?.DepartmentName}</Text>
                    </Box>
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.position.reporting_to")}</Text>
                    <Box
                      border={"1px"}
                      borderColor={"gray.300"}
                      padding={2}
                      borderRadius={5}
                      textColor="gray.600"
                      // fontWeight={"semibold"}
                      marginTop={"2"}
                    >
                      <Text>
                        {" "}
                        {
                          reportingToPosition[
                            positionDetails[0]?.ReportingToPositionId - 1
                          ]?.ReportingTo
                        }
                      </Text>
                    </Box>
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.position.Positions_name")}</Text>
                    <Box
                      border={"1px"}
                      borderColor={"gray.300"}
                      padding={2}
                      borderRadius={5}
                      textColor="gray.600"
                      // fontWeight={"semibold"}
                      marginTop={"2"}
                    >
                      <Text>{positionDetails[0]?.PositionName}</Text>
                    </Box>
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.position.classification")}</Text>
                    <Box
                      border={"1px"}
                      borderColor={"gray.300"}
                      padding={2}
                      borderRadius={5}
                      textColor="gray.600"
                      // fontWeight={"semibold"}
                      marginTop={"2"}
                    >
                      <Text>
                        {
                          classifications[
                            positionDetails[0]?.EmployeeClassificationId - 1
                          ]?.EmployeeClassificationNameEn
                        }
                      </Text>
                    </Box>
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.position.job_title")}</Text>
                    <Box
                      border={"1px"}
                      borderColor={"gray.300"}
                      padding={2}
                      borderRadius={5}
                      textColor="gray.600"
                      // fontWeight={"semibold"}
                      marginTop={"2"}
                    >
                      <Text>
                        {
                          jobTitles[positionDetails[0]?.JobTitleId - 1]
                            ?.JobTitleEn
                        }
                      </Text>
                    </Box>
                  </Box>

                  <Checkbox
                    colorScheme="green"
                    isChecked={positionDetails[0]?.IsAcademicLeave}
                  >
                    {t("pages.hr.IsAcademicLeave")}
                  </Checkbox>

                  <Checkbox
                    colorScheme="green"
                    isChecked={positionDetails[0]?.IsFullTime}
                  >
                    {t("pages.hr.position.isFullTime")}
                  </Checkbox>
                  <Checkbox
                    colorScheme="green"
                    isChecked={positionDetails[0]?.IsActive}
                  >
                    {t("pages.hr.is_active")}
                  </Checkbox>

                  <Checkbox
                    colorScheme="green"
                    isChecked={positionDetails[0]?.IsSMG}
                  >
                    {t("pages.hr.position.is_smg")}
                  </Checkbox>

                  <Flex gap={4} justifyContent={"flex-end"}>
                    <GButton
                      title={t("general.close")}
                      onClick={onClose}
                      bg="red.600"
                      border="1px solid transparent"
                      color="light"
                      hover={{
                        color: "red.600",
                        borderColor: "red.600",
                        bg: "none",
                      }}
                    />
                  </Flex>
                </Stack>
              </Box>
            </Box>
          ) : (
            <Flex bg="light" p={4} justifyContent="center">
              <Spinner />
            </Flex>
          )
        }
      ></GModal>
    </>
  );
}
