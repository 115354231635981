import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrancheTrendsReports } from "./services/branchesTrendsServices";
import BranchChart from "./components/BranchCart";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getBranchesLookUpOrder } from "features/orders/services/ordersService";
import { getLastTwoMonthsDates } from "utils/GetDates";
import { filterData, removeDuplicates } from "utils/RemoveDublicatedData";
import BranchTrendCard from "./components/BranchTrendCard";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

export default function BranchesTrend() {

  const retrievedArray =useDecryptedPrivilege()
  const Auth = checkIdExists(retrievedArray, 67);


  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [branch, setBranchData] = useState("All");
  const Dates = getLastTwoMonthsDates();

  useEffect(() => {
    dispatch(getBrancheTrendsReports());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBranchesLookUpOrder());
  }, [dispatch]);

  const data = useSelector((state) => state.ordersSlice);
  const branchTrendData = useSelector((state) => state.BrancheTrends);
  const uniqueData = removeDuplicates(branchTrendData?.data);
  const filteredData = filterData(uniqueData, branch);

  if (data.isLoading || branchTrendData.isLoading) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if(Auth===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <div>
        <BreadCrumb>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            flexWrap="wrap"
          >
            <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
              {t("pages.reports.analysis_reports.branches_trend")}
            </Text>

            <Flex gap={2} flexWrap="wrap">
              <div style={{ width: "170px" }}>
                <MultiSelect
                  data={
                    data?.branches &&
                    data?.branches.map((el) => ({
                      ...el,
                      label: el.BranchName,
                      value: el.BranchId,
                    }))
                  }
                  isMulti={false}
                  onChange={(e) => setBranchData(e.BranchName)}
                  defaultValue={{ label: "All", value: "0" }}
                />
              </div>
            </Flex>
          </Flex>
        </BreadCrumb>
      </div>

      {filteredData?.map((el) => {
        return <BranchTrendCard data={el} date={Dates} />;
      })}
    </>
  );
}
