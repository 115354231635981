import Input from "components/form/Input";
import { Box, Flex, Stack } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getTipsFilteredData } from "../services/tipsReportServices";

export default function FilterModal({ onClose, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState(data);

  // Filter Data
  const [filterCriteria, setFilterCriteria] = useState({
    PaymentMethod: "",
    User: "",
  });

  const handleFilterTips = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const { User , PaymentMethod } = filterCriteria;

      let filterUser = true;
      let filterPaymentMethod = true;

      if (User) {
        filterUser = item?.User?.toLowerCase().includes(User.toLowerCase());
      }
      if (PaymentMethod) {
        filterPaymentMethod = item?.PaymentMethod?.toLowerCase().includes(PaymentMethod.toLowerCase());
      }

      return (
        filterUser &&
        filterPaymentMethod 
      );

    });

    setFilteredData(filteredResult);
    onClose();
    dispatch(getTipsFilteredData(filteredResult));
  };

  return  <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.reports.business_reports.filter")}
        modalContent={
          <Box p={4}>
            <form onSubmit={handleFilterTips}>
              <Stack spacing={6}>
                <Input
                  label={t("pages.reports.reports_tables.user")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.user")}
                  name="User"
                  value={filterCriteria.User}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      User: e.target.value,
                    })
                  }
                />
                <Input
                  label={t("pages.reports.reports_tables.payment_method")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.payment_method")}
                  name="PaymentMethod"
                  value={filterCriteria.PaymentMethod}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      PaymentMethod: e.target.value,
                    })
                  }
                />
                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
};
