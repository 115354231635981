import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getSuppliers,
  getSuppliersForItem,
  linkSupplierToItem,
} from "../../services/supplieritemService";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

const AddSupplierModal = ({ onClose }) => {
  const { t } = useTranslation();
  const id = useParams();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();

  const supplierdata = useSelector((state) => state.suppliers);

  useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.inventory_item_details.add_supplier")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              if (values.ItemsSuppliers === undefined) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                values.ItemsSuppliers = [
                  {
                    SuppliersId: values.ItemsSuppliers?.SuppliersId,
                    InventoryItemId: id.itemId,
                  },
                ];
                dispatch(linkSupplierToItem(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.items.supplier_linked") });
                    dispatch(getSuppliersForItem(id.itemId));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.inventory_item_details.suppliers")}
                data={
                  supplierdata.data &&
                  supplierdata.data.map((el) => ({
                    ...el,
                    label: el.SuppliersName,
                    value: el.SuppliersId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("ItemsSuppliers", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.inventory_item_details.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={supplierdata.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={supplierdata.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddSupplierModal;
