import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteCombo } from "../../services/combosService";

const DeleteModal = ({
  data,
  onClose
}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const combos = useSelector(state => state.combos);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteCombo({ id: data.id }))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.combos.combo_deleted") });
            onClose();
          })
      }}
      isLoading={combos.isLoading}
    />
  )
}

export default DeleteModal