import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Get Menu Engineering Report List ------------------------------- */
  export const getSpeedOfServicesReportList = createAsyncThunk("speedOfServicesReport/getSpeedOfServicesReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetSpeedOfServiceReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


 /* --------------------------------------- Menu Engineering Filter Data ------------------------------*/
 export const getSpeedOfServicesFilteredData = createAsyncThunk("speedOfServicesReport/getSpeedOfServicesFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetSpeedOfServicesFilteredData = createAsyncThunk( "speedOfServicesReport/resetSpeedOfServicesFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);