import React, { useState } from "react";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
  Button,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NoDataFound from "components/ui/noDataFound/NoDataFound";
import Pagination from "components/ui/pagination/Pagination";
import ExportModal from "components/ui/modal/ExportModal";
import ExportPDFButton from "components/exportPdf/ExportPdf";
import PrintCart from "components/exportPdf/ExportPdf";
import PrintTable from "components/exportPdf/ExportPdf";

const TrialBalanceTable = () => {
  function filterArray(arr) {
    // Check if arr is an array
    if (!Array.isArray(arr)) {
      console.error("Input is not an array");
      return; // Exit the function
    }

    // Map the array and transform each object
    return arr.map((obj) => ({
      AccountNameAr: obj.AccountNameAr,
      DebitBfore: obj.DebitBfore,
      CreditBfore: obj.CreditBfore,
      Debit: obj.Debit,
      Credit: obj.Credit,
      CrruDebit: obj.CrruDebit,
      CrruCredit: obj.CrruCredit,
    }));
  }

  const [exportModal, SetExportModal] = useState(false);

  const dataTrialBalance = useSelector((state) => state.AccountTree);

  const newArray = filterArray(dataTrialBalance.trialBalance);

  const { t } = useTranslation();

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    if (Array.isArray(data)) {
      console.log("array");
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
      return data?.slice(firstIndex, lastIndex);
    } else {
      console.error("data.trialBalance is not an array.");
      return [];
    }
  };

  // Calculate records for activeDiscounts
  const itemsData = calculateRecords(
    dataTrialBalance.trialBalance,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  if (
    dataTrialBalance?.trialBalance &&
    Object.keys(dataTrialBalance.trialBalance).length === 0
  ) {
    return <NoDataFound />;
  }



  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <div style={{ padding: "15px", borderBottom: "2px solid gray" }}>
        <Flex gap={2}>
          <Button
            type="button"
            bg="none"
            border="1px solid"
            borderColor="border"
            textTransform="capitalize"
            fontSize="1rem"
            color="text"
            _hover={{ bg: "primary", color: "light" }}
          >
            <div onClick={() => SetExportModal(true)}>
              {t("general.export")}
            </div>
          </Button>

          <Button
            type="button"
            bg="none"
            border="1px solid"
            borderColor="border"
            textTransform="capitalize"
            fontSize="1rem"
            color="text"
            _hover={{ bg: "primary", color: "light" }}
          >
            <PrintCart text="ميزان المراجعة" tableData={newArray} />
          </Button>
        </Flex>
      </div>
      <Table>
        <Thead>
          <Tr>
            {/*<Th>#</Th>*/}
            <Th>{t("pages.Trial_Balance.account_name")}</Th>
            <Th>{t("pages.general_accounts.Account_code")}</Th>
            <Th>{t("pages.Trial_Balance.balance_of_debit_side")}</Th>
            <Th>{t("pages.Trial_Balance.balance_of_Creditor_side")}</Th>
            <Th>{t("pages.Trial_Balance.Move_debit_side")}</Th>
            <Th>{t("pages.Trial_Balance.Move_Creditor_side")}</Th>
            <Th>{t("pages.Trial_Balance.close_debit_side")}</Th>
            <Th>{t("pages.Trial_Balance.close_Creditor_side")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {itemsData?.map((el, index) => (
            <Tr key={el.InventoryItemId} style={{ cursor: "pointer" }}>
              {/*<Td>
                <form>
                  <Checkbox></Checkbox>
                </form>
          </Td>*/}
              <Td>{el.AccountNameAr}</Td>
              <Td>{el.AccountNumber}</Td>
              <Td>{el.DebitBfore}</Td>
              <Td>{el.CreditBfore}</Td>
              <Td>{el.Debit}</Td>
              <Td>{el.Credit}</Td>
              <Td> {el.CrruDebit}</Td>
              <Td>{el.CrruCredit}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={dataTrialBalance?.trialBalance}
          fileName={"ميزان المراجعة"}
        ></ExportModal>
      )}

      {/* Pagination  */}

      <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(
          dataTrialBalance.trialBalance?.length / recordsPerPage
        )}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(dataTrialBalance.trialBalance?.length / recordsPerPage)
          )
        }
      />
    </Box>
  );
};

export default TrialBalanceTable;
