import React, { useEffect, useState } from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from "react-hook-form";
import {Box, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Radio, RadioGroup, Input as ChInput} from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import Input from "components/form/Input";
import { useDispatch, useSelector } from 'react-redux';
import { TbRuler } from 'react-icons/tb';
import { createEditPriceTag, getBranchesTags, getOrderTags, getPriceTags } from '../../services/priceTagsServices';

export default function CreatePriceTagsModal ({ onClose , id }){
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue,  getValues, watch ,  formState: { errors },  trigger} =  useForm();
  // watch(["RefCode"]);

  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({position: "top",duration: 3000, status: "error",});

  const PriceTagsData = useSelector(state => state.priceTags);
  const [valueRadio, setValueRadio] = useState("true");


  useEffect(() => {
    dispatch(getOrderTags());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBranchesTags());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return <>
    <GModal
      onClose={onClose}
      modalHeader={t("pages.price_tags.create_price_tag")}
      modalContent={(

        <Box p={4}>

          <form onSubmit={handleSubmit(values => {
            values.ApplyAllBranche = valueRadio === "true" ? true : false  ;
            values.TagId = values.TagId?.TagId;
            if( values.ApplyAllBranche === false  && values.Branches === undefined){
              values.Branches = values.Branches?.map((el) => ({ BranchId: el.BranchId }))  || [] ;
              toast2({ description: t("validation.choose_first")  });
            }
            else if(values.TagId === undefined) {
              toast2({ description: t("validation.choose_first")  });
            }
            else{
              dispatch(createEditPriceTag(values)).unwrap().then(_ => {
              toast({ description: t("pages.DeliverZone.delivery_created") });
              dispatch(getPriceTags());
              onClose()
            });
            }
          })}>

    <Stack spacing={6}>

      <FormControl isInvalid={errors.Name}>
          <FormLabel
            htmlFor="Name"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.price_tags.name")}
          </FormLabel>
          <Controller
            name="Name"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.price_tags.name")}
                onChange={(e) => {
                  handleInputChange(e, "Name");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.Name &&
              errors.Name.message}
          </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.NameLoc}>
          <FormLabel
            htmlFor="NameLoc"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.price_tags.name_localized")}
          </FormLabel>
          <Controller
            name="NameLoc"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.price_tags.name_localized")}
                onChange={(e) => {
                  handleInputChange(e, "NameLoc");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.NameLoc &&
              errors.NameLoc.message}
          </FormErrorMessage>
      </FormControl>

      <MultiSelect
        title={t("pages.price_tags.order_tags")}
        data={PriceTagsData?.orderTagsDropdown && PriceTagsData?.orderTagsDropdown.map(el => ({
          ...el,
          label: el.TagName,
          value: el.TagId
        }))}
        isMulti={false}
        isRequired={true}
        onChange={(e) => setValue("TagId", e)}
      />

    <MultiSelect
        title={t("pages.price_tags.branches")}
        data={PriceTagsData?.branchsDropdown && PriceTagsData?.branchsDropdown.map(el => ({
          ...el,
          label: el.BranchName,
          value: el.BranchId
        }))}
        isMulti={true}
        isRequired={true}
        onChange={(e) => setValue("Branches", e)}
        isDisabled={ valueRadio === "true" ?  true : false }
      />

    <RadioGroup value={valueRadio} >
      <Stack direction='column'>
            <Radio value='true' 
                  onChange={(e)=>setValueRadio("true")}
            >  {t("pages.price_tags.automatically_apply")} 
            </Radio>

            <Radio value='false' 
                  onChange={(e)=>setValueRadio("false")}
            >  {t("pages.price_tags.branches")}
            </Radio>
      </Stack>
    </RadioGroup>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>

            </Stack>

          </form>


        </Box>

      )}
    />
  </>
};
