import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text, Spinner, Box } from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import NoData from "components/ui/noData/NoData";
import { getReasons } from "../services/reasonsService";
import ReasonsList from "../components/modals/ReasonsList";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Reasons = () => {
  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 54);


  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reasons = useSelector((state) => state.reasons);
  useEffect(() => {
    dispatch(getReasons());
  }, [dispatch]);


  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return <>
    <>
    <BreadCrumb>
        <Flex
          marginLeft="1%"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="2.2rem" color="dark" textTransform="capitalize">
            {t("pages.reasons.reasons")}
          </Text>
        </Flex>
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
        </Flex>
    </BreadCrumb>

    {reasons.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : reasons.data.length === 0 ? (
        <NoData
          title={t("pages.tags.no_data_hint")}
          btnTitle={t("pages.tags.create_tag")}
        />
      ) : (
        <ReasonsList
          resons={reasons.data}
        />
      )}


    </>
  </>;
};

export default Reasons;
