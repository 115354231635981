import React, { useEffect, useState } from "react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BusinessDetails from "./BusinessDetails";
import MyProfile from "./MyProfile";
import { getLanguages, getProfileData } from "./services/profileServices";

export default function Profile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [actibeButton, setActibeButton] = useState("profile");

  const data = useSelector((state) => state.profile);
  // console.log(data);

  const profileID = localStorage.getItem("userId");

  useEffect(() => {
    dispatch(getProfileData(profileID));
  }, [dispatch, profileID]);

  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch,]);


  return <>
    {
      data?.isLoading ? 
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
      :
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Flex gap={3} alignItems="center">
            <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
              {t("pages.profile.console")}
            </Text>
            <Text
              color="white"
              bg={"dark"}
              textTransform="capitalize"
              border={"1px"}
              paddingX={3}
              paddingY={1}
              borderRadius={"full"}
            >
              {t("pages.profile.account_owner")}
            </Text>
          </Flex>

          <Flex gap={2} flexWrap="wrap">
            <GButton
              title={t("pages.profile.change_Password")}
              //   onClick={() => setShowCreatCostAdjustmentModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>
      {/* {
        data?.profileData?.isAdmin === true ? 
        <>
          <BreadCrumb>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap={4}
              flexWrap="wrap"
            >
              <Flex gap={3} alignItems="center">
                <Text
                  textTransform="capitalize"
                  cursor={"pointer"}
                  onClick={() => setActibeButton("profile")}
                  color={actibeButton === "profile" ? "primary" : "none"}
                  borderBottom={actibeButton === "profile" ? "2px solid" : "none"}
                  borderColor={actibeButton === "profile" ? "primary" : "none"}
                >
                  {t("pages.profile.myProfile")}
                </Text>
                <Text
                  textTransform="capitalize"
                  cursor={"pointer"}
                  onClick={() => setActibeButton("details")}
                  color={actibeButton === "details" ? "primary" : "none"}
                  borderBottom={actibeButton === "details" ? "2px solid" : "none"}
                  borderColor={actibeButton === "details" ? "primary" : "none"}
                >
                  {t("pages.profile.business_Details")}
                </Text>
              </Flex>
            </Flex>
          </BreadCrumb>
        </>
        :
        <>
        
        </>
      } */}

      <Box>
        {/* {data?.profileData?.isAdmin === true ? (
          <>
            {actibeButton === "profile" ? <MyProfile data={data} /> : <BusinessDetails />}
          </>
        ) : (
          <>
            <MyProfile />
          </>
        )} */}
         <MyProfile data={data} />
      </Box>
    </>
    }
    </>;
}
