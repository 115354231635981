  
  import { createAsyncThunk } from "@reduxjs/toolkit";
  import { axios } from "../../../../services/axios";

  /* --------------------------------------------Get Items List By CostAdjustmentId-------------------------------------------- */
export const getCostAdjustmentItemsList = createAsyncThunk("costAdjustment/getCostAdjustmentItemsList",async (args, thunkApi) => {
  try {
   const { data } = await axios.get(`/Inventory/CostAdjustmentListItemById?costadjustmentid={"CostAdjustmentId":${args}}`);
   return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
  /*  -----------------------------------------------  Get General Items To Push In CostAdjustment  --------------------------------------------------*/ 
  export const getCostAdjustmentItems = createAsyncThunk("costAdjustment/getCostAdjustmentItems", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/InventoryItem/LookupInventoryItem`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
  );
    /*  -----------------------------------------------  Add Items In CostAdjustment  --------------------------------------------------*/ 
    export const addItemCostAdjustment = createAsyncThunk("costAdjustment/addItemCostAdjustment", async (args, thunkApi) => {
      try { 
      const { data } = await axios.get(`/Inventory/AddCostAdjustmentItem?costadjustmentitem=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
      } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
      }
      }
      
      );



 /*  -----------------------------------------------  Get Items CostAdjustment By InventoryId  --------------------------------------------------*/ 
  export const getItemsListByInventoryId = createAsyncThunk("costAdjustment/getItemsListByInventoryId", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/CostAdjustmentListItemById?costadjustmentid=`);
      console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
  );
    // Get Edit Item Cost Adjustments by CostAdjustmentId
    export const getCostAdjustmentEditItem = createAsyncThunk("costAdjustment/getCostAdjustmentEditItem", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/GetEditCostAdjustmentItemsByACId?costadjustmentid={"CostAdjustmentId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

    // Edit Item Cost Adjustments by CostAdjustmentId
    export const editItemCostAdjustment = createAsyncThunk("costAdjustment/editItemCostAdjustment", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/EditCostAdjustmentItems?costadjustmentitemid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );