import React from 'react';
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from 'react-redux';
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { deleteInventoryCategories, getInventoryCategoriesList } from '../../services/InventoryCategoriesServices';

const DeletedModal = ({onClose,id}) => {
  const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();

  return (
    <GDeleteModal
        onClose={onClose}
        onSubmit={()=>{
          dispatch(deleteInventoryCategories(id)).unwrap().then(_ => {
            toast({ description: t("pages.inventoryCategories.inventoryCategories_deleted") });
            onClose();
            dispatch(getInventoryCategoriesList());
          })
        }}  
  />
  );
}

export default DeletedModal;
