import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getDevices = createAsyncThunk(
  "devices/getDevices",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Device/ListDevice`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createDevice = createAsyncThunk(
  "devices/createDevice",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Device/AddEditDevice?device=${JSON.stringify(args)}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateDevice = createAsyncThunk(
  "devices/updateDevice",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Device/EditDeviceMenuGroup?devicesmenugroupid=${JSON.stringify(args)}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteDevice = createAsyncThunk(
  "devices/deleteDevice",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`Manage2/Devices/DevicesById?DevicesId={"DevicesId":${args}}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getDeviceDetails = createAsyncThunk(
  "devices/getDeviceDetails",
  async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/Manage/Device/DevicesByDeviceId?deviceid=${args}`);
     //console.log(data)
     return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const DeactivateDevice = createAsyncThunk(
  "devices/DeactivateDevice",
  async (args, thunkApi) => {
    //console.log(args)
    try {
      const { data } = await axios.get(`/Manage/Device/DeactivatingDevice?deactivdevice={"DevicesId":${args}}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getDevicesFilteredData = createAsyncThunk("devices/getDevicesFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetDevicesFilteredData = createAsyncThunk( "devices/resetDevicesFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);