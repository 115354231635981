import React, { useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr,} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Pagination from "components/ui/pagination/Pagination";

export default function FilteredDataTable({data}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const navigatefunction = (id) => {
        navigate(`${id}`);
      };

/* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
        return data?.slice(firstIndex, lastIndex);
      };
    
    // Calculate records for activeDiscounts
    const filteredDataTable = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
 /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <>
    <Table>
        <Thead>
            <Tr>
                <Th>{t("pages.inventory.reference")}</Th>
                <Th>{t("pages.inventory.branch")}</Th>
                <Th>{t("pages.inventory.type")}</Th>
                <Th>{t("pages.inventory.status")}</Th>
                <Th>{t("pages.inventory.business_date")}</Th>
                <Th>{t("pages.inventory.created")}</Th>
            </Tr>
        </Thead>
        <Tbody>
            {
            filteredDataTable?.map((item, index) => (
                <Tr
                    key={index}
                    onClick={() => navigatefunction(item.ProductionId)}
                    style={{ cursor: "pointer" }}
                >
                    <Td>{item.Referance}</Td>
                    <Td>{item.BranchName}</Td>
                    <Td>{item.ProductionTypeName}</Td>
                    <Td>{item.Status}</Td>
                    <Td>{item.BusinessDate}</Td>
                    <Td>{item.CreatedDate}</Td>
                </Tr>
            ))
            }
        </Tbody>
    </Table>
    <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </>

  </>
};
