import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";

const CTable = ({
  promotions,
  handleUpdate,
  handleDelete
}) => {
  const { t } = useTranslation();
  return (
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.promotions.name")}</Th>
            <Th>{t("general.action")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {promotions.map((promotion, index) => (
            <Tr key={promotion.id}>
              <Td>{index + 1}</Td>
              <Td>{promotion.name}</Td>
              <Td>
                <Flex gap={2}>
                  <GButton
                    postfixElement={<FiIcons.FiEdit />}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{ bg: "none", borderColor: "primary", color: "primary" }}
                    onClick={() => handleUpdate(promotion)}
                  />
                  <GButton
                    postfixElement={<FiIcons.FiTrash2 />}
                    bg="red.600"
                    color="light"
                    border="1px solid transparent"
                    hover={{ bg: "none", borderColor: "red.600", color: "red.600" }}
                    onClick={() => handleDelete(promotion)}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default CTable