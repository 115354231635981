import { createSlice } from "@reduxjs/toolkit";
import { productDropList , productList , addProduct } from "../services/productService";

let initialState = {
  isLoading: false,
  errors: null,
  productData: [],
  productData1: [],
};

const inActiveBranchesSlice = createSlice({
  name: "product",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(productDropList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(productDropList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.productData1 =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(productDropList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    
    builder.addCase(addProduct.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
    });
    
    builder.addCase(addProduct.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
    });
    
    builder.addCase(addProduct.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(productList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(productList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.productData =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(productList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default inActiveBranchesSlice.reducer;
