import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function DetailsTableCoupon({ data }) {
  console.log("data", data);
  const { t } = useTranslation();

  return (
    <>
      <SimpleGrid
        spacing={6}
        columns={{ base: 1, md: 2 }}
        bg="white"
        p={4}
        borderRadius={6}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.coupons.name")}
          </Text>
          <Text marginBlock={2}>{data?.CouponName || "-"}</Text>
        </Box>
        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.coupons.name_localized")}
          </Text>
          <Text marginBlock={2}>{data?.CouponNameLoc || "-"}</Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.coupons.discount")}
          </Text>
          <Text marginBlock={2}>
            {data?.DisCount[0]?.DisCountName || "-"}
          </Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.coupons.time")}
          </Text>
          <Text marginBlock={2}>
            {`${data?.CouponStartTime} - ${data?.CouponEndTime}` || "-"}
          </Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.coupons.coupon_type")}
          </Text>
          <Text marginBlock={2}>{data?.DisCount[0]?.CouponType || "-"}</Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.coupons.total_usage")}
          </Text>
          <Text marginBlock={2}>{data?.TotalUsage}</Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.coupons.code")}
          </Text>
          <Text marginBlock={2}>{data?.CouponCode || "-"}</Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.coupons.date")}
          </Text>
          <Text marginBlock={2}>
            {`${data?.CouponStartDate} - ${data?.CouponEndDate}` || "-"}
          </Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.coupons.maximum_uses")}
          </Text>
          <Text marginBlock={2}>{data?.CouponMaximumUses || "-"}</Text>
        </Box>

        {data?.DisCount[0]?.CouponType === "Product" && (
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.coupons.number_of_products")}
            </Text>
            <Text marginBlock={2}>{data?.NumberOfProduct || "-"}</Text>
          </Box>
        )}
      </SimpleGrid>
    </>
  );
}
