import { createSlice } from "@reduxjs/toolkit";
import { getDrawerOperationsReportFilteredData, getDrawerOperationsReportList, resetDrawerOperationsReportFilteredData } from "../services/drawerOperReportServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const drawerOperationsReportSlice = createSlice({
  name: "drawerOperationsReport",
  initialState,
  extraReducers: (builder) => {

  /* --------------------------------- Drawer Operations Report List ------------------------------- */
    builder.addCase(getDrawerOperationsReportList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDrawerOperationsReportList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getDrawerOperationsReportList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

   /* ------------------------------------------------------------------------------------------- */
 /* --------------------------------------- Drawer Operations Filter Data ------------------------------*/
 builder.addCase(getDrawerOperationsReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDrawerOperationsReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getDrawerOperationsReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetDrawerOperationsReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetDrawerOperationsReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetDrawerOperationsReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default drawerOperationsReportSlice.reducer;