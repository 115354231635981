import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* ------------------- get Profile Data ------------------ */
export const getProfileData = createAsyncThunk(
  "profile/getProfileData",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/Users/GetUser/${args}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------- Update Profile Data ------------------ */
export const updateProfileData = createAsyncThunk(
  "profile/updateProfileData",
  async (args, thunkApi) => {
    console.log(args);
    try {
      const { data } = await axios.put(`/api/Users/UpdateUser` , args);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------- Get Language ------------------ */
export const getLanguages = createAsyncThunk(
  "profile/getLanguages",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Setting/ReciptSetting/LookUpLanguage`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);