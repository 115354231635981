import { useState } from "react";
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import Pagination from "components/ui/pagination/Pagination";


const CTable = ({ data }) => {

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 12;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

// Calculate records for activeDiscounts
const taxesData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
/* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6} mt={6}>
      {taxesData?.map((tax,index) => (
        <Box key={index}
          bg="light" p={6} borderRadius={4}
          boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
        >
          <Flex justifyContent="space-between">
            <Text textTransform="capitalize">{tax?.TaxsName || '-'}</Text>
          </Flex>
          <Text textTransform="capitalize" color="text">{tax?.Rate || 0} %</Text>
        </Box>
      ))}
    </SimpleGrid>
    <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(data?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(data?.length / recordsPerPage)
            )
          }
    />
  </>
};

export default CTable;