import React from 'react';
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from 'react-redux';
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getPurchasesList } from '../../services/purchaseService';
import { deletingReturnSupllier } from '../../services/returnedSuppliersServices';

const DeleteReturnToSupplier = ({onClose, id}) => {
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={()=>{
        dispatch(deletingReturnSupllier(id)).unwrap().then(_ => {
          toast({ description: t("pages.purchases.returned_deleted") });
          onClose();
          dispatch(getPurchasesList());
          navigate("/inventory/purchases", { state: { replace: true } });
        });
      }}    
    />
  );
}

export default DeleteReturnToSupplier;
