import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import GButton from "components/ui/button/Button";
import { IoCloseOutline } from "react-icons/io5";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoData from "components/ui/noData/NoData";
import FilterModal from "./Modal/FilterModal";
import { resetWarehouseFilteredData } from "../services/WarehouseServices";
import Pagination from "components/ui/pagination/Pagination";

export default function WarehouseTable({ data, filteredData }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Show Filter Modal State
  const [showFilterModal, setShowFilterModal] = useState(false);

  const resetFunction = () => {
    dispatch(resetWarehouseFilteredData());
  };

  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [filteredPageAll, setFilteredPageAll] = useState(1);

  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const recordsPerPage = 10;

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  const recordsAll = calculateRecords(data, currentPageAll, recordsPerPage);

  const filteredRecordsAll = calculateRecords(
    filteredData,
    filteredPageAll,
    recordsPerPage
  );

  return (
    <Fragment>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <GButton
              title={t("general.all")}
              bg="none"
              color="primary"
              isDisabled={false}
              borderRadius={0}
              borderBottom="2px solid"
              borderColor="primary"
            />
          </Flex>

          <GButton
            title={
              filteredData?.length === 0
                ? t("general.filter")
                : t("general.clear_filter")
            }
            border="1px solid"
            borderColor="border"
            postfixElement={
              filteredData?.length === 0 ? (
                <FiIcons.FiFilter size={16} />
              ) : (
                <IoCloseOutline fontWeight={800} size={20} />
              )
            }
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            onClick={
              filteredData?.length === 0
                ? () => setShowFilterModal(true)
                : resetFunction
            }
          />
        </Flex>

        {filteredData?.length > 0 ? (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t("pages.warehouse.name")}</Th>
                  <Th>{t("pages.warehouse.name_Localized")}</Th>
                  <Th>{t("pages.warehouse.reference")}</Th>
                  <Th>{t("pages.warehouse.created")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData?.length > 0 ? (
                  filteredRecordsAll?.map((item, index) => (
                    <Tr
                      key={index}
                      cursor={"pointer"}
                      onClick={() =>
                        navigate(
                          `/inventory/warehouse_details/${item.WarehouseId}`
                        )
                      }
                    >
                      <Td>{item.Name}</Td>
                      <Td>{item.NameLocalized}</Td>
                      <Td>{item.Reference}</Td>
                      <Td>{item.CreateDate}</Td>
                    </Tr>
                  ))
                ) : (
                  <NoData btnTitle={"Data Not Founded"} />
                )}
              </Tbody>
            </Table>
            <Pagination
              currentPage={filteredPageAll}
              setCurrentPage={setFilteredPageAll}
              npage={Math.ceil(filteredData.length / recordsPerPage)}
              prePage={() => prePage(filteredPageAll, setFilteredPageAll)}
              nextPage={() =>
                nextPage(
                  filteredPageAll,
                  setFilteredPageAll,
                  Math.ceil(filteredData.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t("pages.warehouse.name")}</Th>
                  <Th>{t("pages.warehouse.name_Localized")}</Th>
                  <Th>{t("pages.warehouse.reference")}</Th>
                  <Th>{t("pages.warehouse.created")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.length > 0 ? (
                  recordsAll?.map((item, index) => (
                    <Tr
                      key={index}
                      cursor={"pointer"}
                      onClick={() =>
                        navigate(
                          `/inventory/warehouse_details/${item.WarehouseId}`
                        )
                      }
                    >
                      <Td>{item.Name}</Td>
                      <Td>{item.NameLocalized}</Td>
                      <Td>{item.Reference}</Td>
                      <Td>{item.CreateDate}</Td>
                    </Tr>
                  ))
                ) : (
                  <NoData btnTitle={"Data Not Founded"} />
                )}
              </Tbody>
            </Table>
            <Pagination
              currentPage={currentPageAll}
              setCurrentPage={setCurrentPageAll}
              npage={Math.ceil(data.length / recordsPerPage)}
              prePage={() => prePage(currentPageAll, setCurrentPageAll)}
              nextPage={() =>
                nextPage(
                  currentPageAll,
                  setCurrentPageAll,
                  Math.ceil(data.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        )}
      </Box>

      {showFilterModal && (
        <FilterModal onClose={() => setShowFilterModal(false)} data={data} />
      )}
    </Fragment>
  );
}
