import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { getReportTransfersFilteredData } from "../services/reportTransfersServices";
import Input from "components/form/Input";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";

export default function FilterModal({ onClose, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState(data);

  // Filter Data
  const [filterCriteria, setFilterCriteria] = useState({
    Name: "",
    SKU: "",
    From: "",
    To: "",
  });

  const handleFilterCostAdjustment = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const { Name, SKU, From, To } = filterCriteria;

      let filterName = true;
      let filterRef = true;
      let filterFrom = true;
      let filterTo = true;

      if (Name) {
        filterName = item?.InventoryItemNameEng?.toLowerCase().includes(
          Name.toLowerCase()
        );
      }

      if (SKU) {
        filterRef = item?.ItemRef?.toLowerCase().includes(SKU?.toLowerCase());
      }

      if (From) {
        filterFrom = item?.From?.toLowerCase().includes(From?.toLowerCase());
      }

      if (To) {
        filterTo = item?.To?.toLowerCase().includes(To?.toLowerCase());
      }

      return filterName && filterRef && filterFrom && filterTo;
    });

    setFilteredData(filteredResult);
    onClose();
    dispatch(getReportTransfersFilteredData(filteredResult));
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.reports.business_reports.filter")}
        modalContent={
          <Box p={4}>
            <form onSubmit={handleFilterCostAdjustment}>
              <Stack spacing={6}>
                <Input
                  label={t("pages.reports.reports_tables.name")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.name")}
                  name="Name"
                  value={filterCriteria.Name}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      Name: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.reports.reports_tables.reference")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.reference")}
                  name="SKU"
                  value={filterCriteria.SKU}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      SKU: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.reports.reports_tables.from")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.from")}
                  name="From"
                  value={filterCriteria.From}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      From: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.reports.reports_tables.to")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.to")}
                  name="To"
                  value={filterCriteria.To}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      To: e.target.value,
                    })
                  }
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
