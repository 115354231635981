import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import TimedEventsList from "../components/TimedEventsList";
import GButton from "../../../../components/ui/button/Button";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import CreateTimedEventsModal from "../components/modals/CreateTimedEventsModal";
import { getTimedEvents } from "../services/timedEventsService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const TimedEvents = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 47);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allTimedEvents = useSelector((state) => state.timedEvents);
  console.log("allTimedEvents",allTimedEvents);

  const [ListStatus, setListStatus] = useState("all");
  const [showCreateTimedModal, setShowCreateTimedModal] = useState(null);

  useEffect(() => {
    dispatch(getTimedEvents());
  }, [dispatch]);


  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  return (
    <>
      <BreadCrumb bg="transparent">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.timedEvents.timed_events")}
          </Text>

          <GButton
            title={t("pages.timedEvents.create_timed_events")}
            onClick={() => setShowCreateTimedModal(true)}
            bg="primary"
            color="light"
            border="1px solid transparent"
            hover={{ bg: "none", borderColor: "primary", color: "primary" }}
          />
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color={ListStatus === "all" ? "primary" : "text"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={ListStatus === "all" ? "2px solid" : ""}
            borderColor="primary"
            onClick={() => {
              setListStatus("all");
            }}
          />

          <GButton
            title={t("general.active")}
            bg="none"
            color={ListStatus === "active" ? "primary" : "text"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={ListStatus === "active" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setListStatus("active")}
          />

          <GButton
            title={t("general.inactive")}
            bg="none"
            color={ListStatus === "inactive" ? "primary" : "text"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={ListStatus === "inactive" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setListStatus("inactive")}
          />
        </Flex>
      </Flex>

      {allTimedEvents.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <TimedEventsList
          allTimedEvents={allTimedEvents.data}
          ListStatus={ListStatus}
          onClickCreateTimedLimitedBtn={() => setShowCreateTimedModal(true)}
        ></TimedEventsList>
      )}

      {showCreateTimedModal && (
        <CreateTimedEventsModal onClose={() => setShowCreateTimedModal(null)} />
      )}
    </>
  );
};

export default TimedEvents;
