import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "./axios";

export const getLanguages = createAsyncThunk(
  "languages/getLanguages",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Setting/ReciptSetting/LookUpLanguage");
      const parsedData = (data[0] && JSON.parse(data[0].jsonData)) || [];
      const finalResponse = {
        data: parsedData,
        itemsCount: parsedData.length
      }
      return thunkApi.fulfillWithValue(finalResponse);
    } catch (e) {
      console.log(e);
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
)