import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Flex, Stack, useToast } from "@chakra-ui/react";
import { axios } from "services/axios";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getOrdersTypes } from "features/orders/services/ordersTypesService";
import { getCategories } from "features/products/categories/services/categoriesService";
import {
  getAgents,
  getBranchesForCallCenter,
  getMenuGroupForCallCenter,
  getPaymentModes,
  getorderTypesforcallCenter,
} from "features/callCenter/service/callCenterService";

const CallCenter = () => {
  const [allowDiscounts, setAllowDiscounts] = useState(false);
  const [allowCoupons, setAllowCoupons] = useState(false);
  const [allowEditingOrders, setAllowEditingOrders] = useState(false);
  const [allowVoidingActiveOrders, setAllowVoidingActiveOrders] =
    useState(false);
  const [allowAgentsToReadAllCCOrders, setAllowAgentsToReadAllCCOrders] =
    useState(false);
  const [allowAgentsToReadAllOrders, setAllowAgentsToReadAllOrders] =
    useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({  position: "top", status: "success", duration: 3000,});
  const callCenter = useSelector((state) => state.callcenter);

  // console.log("bos hna", callCenter);

  const { register, reset, setValue, handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const getCallCenterSetting = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        "/Manage/Setting/CallCenter/ListSettingCallCenter"
      );
      if (typeof data === "object" && Array.isArray(data)) {
        const jsonData = JSON.parse(data[0]?.jsonData)[0] || {};
        reset(jsonData);
        setAllowDiscounts(jsonData.AllowDiscounts);
        setAllowCoupons(jsonData.AllowCoupons);
        setAllowEditingOrders(jsonData.AllowEditingOrders);
        setAllowVoidingActiveOrders(jsonData.AllowVoidingActiveOrders);
        setAllowAgentsToReadAllCCOrders(jsonData.AllowReadAllCCOrders);
        setAllowAgentsToReadAllOrders(jsonData.AllowReadAllOrders);
      }
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  };
  const updateCallCenterSetting = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `/Manage/Setting/CallCenter/AddSettingCallCenter?scallcenter=${JSON.stringify(
          values
        )}`
      );
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  };

  useEffect(() => {
    getCallCenterSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getBranchesForCallCenter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPaymentModes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getorderTypesforcallCenter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMenuGroupForCallCenter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAgents());
  }, [dispatch]);

  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.UserLoginId = values.UserLoginId?.map((el) => ({
            UserLoginId: el.UserLoginId,
          }));
          values.PaymentMethodId = values.PaymentMethodId?.map((el) => ({
            PaymentMethodId: el.PaymentMethodId,
          }));
          values.BranchId = values.BranchId?.map((el) => ({
            BranchId: el.BranchId,
          }));
          values.MenuGroupId = values.MenuGroupId?.map((el) => ({
            MenuGroupId: el.ProductCategoriesId,
          }));
          values.InactiveOrderTypeId = values.InactiveOrderTypeId?.map(
            (el) => ({ InactiveOrderTypeId: el.OrderTypeId })
          );
          values.SettingCallCenterId = 1;
          console.log(values);
          updateCallCenterSetting(values)
            .then((_) =>
              toast({
                description: t("pages.management.settings.settings_updated"),
              })
            )
            .catch((_) =>
              toast({ status: "error", description: "something went wrong!" })
            );
        })}
      >
        {/* <Flex alignItems="center" justifyContent="center"> */}
        <Stack spacing={6} bg="light" p={4}>
          <MultiSelect
            data={
              callCenter.users &&
              callCenter.users.map((el) => ({
                ...el,
                label: el.UserFullName,
                value: el.UserLoginId,
              }))
            }
            title={t("pages.management.settings.call_center.user_login_id")}
            onChange={(e) => setValue("UserLoginId", e)}
            isMulti={true}
          />

          <MultiSelect
            data={
              callCenter.paymentModes &&
              callCenter.paymentModes.map((el) => ({
                ...el,
                label: el.PaymentMethodName,
                value: el.PaymentMethodId,
              }))
            }
            title={t("pages.management.settings.call_center.payment_method_id")}
            onChange={(e) => setValue("PaymentMethodId", e)}
            isMulti={true}
          />

          <MultiSelect
            data={
              callCenter.branches &&
              callCenter.branches.map((branch) => ({
                ...branch,
                label: branch.BranchName,
                value: branch.BranchId,
              }))
            }
            title={t("pages.management.settings.call_center.branch_id")}
            onChange={(e) => setValue("BranchId", e)}
            isMulti={true}
          />

          <MultiSelect
            data={
              callCenter.menuGroup &&
              callCenter.menuGroup.map((el) => ({
                ...el,
                label: el.GroupName,
                value: el.MenuGroupId,
              }))
            }
            isRequired
            title={t("pages.management.settings.call_center.menu_group_id")}
            onChange={(e) => setValue("MenuGroupId", e)}
            isMulti={true}
          />

          <MultiSelect
            data={
              callCenter.orderType &&
              callCenter.orderType.map((el) => ({
                ...el,
                label: el.OrderTypeName,
                value: el.OrderTypeId,
              }))
            }
            isRequired
            title={t(
              "pages.management.settings.call_center.inactive_order_type_id"
            )}
            onChange={(e) => setValue("InactiveOrderTypeId", e)}
            isMulti={true}
          />

          <Checkbox
            {...register("AllowDiscounts")}
            isChecked={allowDiscounts}
            onChange={(e) => setAllowDiscounts(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.call_center.allow_discounts")}
          </Checkbox>

          <Checkbox
            {...register("AllowCoupons")}
            isChecked={allowCoupons}
            onChange={(e) => setAllowCoupons(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.call_center.allow_coupons")}
          </Checkbox>

          <Checkbox
            {...register("AllowEditingOrders")}
            isChecked={allowEditingOrders}
            onChange={(e) => setAllowEditingOrders(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.call_center.allow_editing_orders")}
          </Checkbox>

          <Checkbox
            {...register("AllowVoidingActiveOrders")}
            isChecked={allowVoidingActiveOrders}
            onChange={(e) => setAllowVoidingActiveOrders(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.call_center.allow_active_orders")}
          </Checkbox>

          <Checkbox
            {...register("AllowReadAllCCOrders")}
            isChecked={allowAgentsToReadAllCCOrders}
            onChange={(e) => setAllowAgentsToReadAllCCOrders(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.call_center.allow_read_cc")}
          </Checkbox>

          <Checkbox
            {...register("AllowReadAllOrders")}
            isChecked={allowAgentsToReadAllOrders}
            onChange={(e) => setAllowAgentsToReadAllOrders(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.call_center.allow_agents_orders")}
          </Checkbox>

          <Flex justifyContent="flex-end">
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              isDisabled={isLoading}
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Stack>
        {/* </Flex> */}
      </form>
    </>
  );
};

export default CallCenter;
