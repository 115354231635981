import { Box, Flex, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { emptyNewEmployee } from "features/hr/employees/services/employeeServices";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function EmployeeAddedSuccessfuly() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gotoEmployeePage = () => {
    dispatch(emptyNewEmployee()).then((_) => {
      navigate("/HREmployees");
    });
  };
  return (
    <Box>
      <Flex
        bg="light"
        px={4}
        py={"70px"}
        gap={2}
        justifyContent="center"
        flexDir={"column"}
        alignItems={"center"}
      >
        <Box textColor={"green.500"}>
          <FaCheckCircle size={150} />
        </Box>
        <Text fontWeight={"bold"} fontSize={"20px"} textColor={"gray.700"}>
          {t("pages.hr.new_employee_created")}
        </Text>
        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Flex gap={2} flexWrap="wrap">
            <GButton
              title={t("pages.hr.goToEmployee")}
              color="light"
              border="1px solid transparent"
              bg="primary"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              onClick={() => gotoEmployeePage()}
            />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
