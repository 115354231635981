import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* ------------------------------------------Get Purchase List ---------------------------------------------- */
export const getPurchasesList = createAsyncThunk("purchases/getPurchasesList",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Purchasing/PurchasingList`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ------------------------------------------Get Branches In Purchase ---------------------------------------------- */
export const getbranchesInPurchase = createAsyncThunk("purchases/getbranchesInPurchase",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ------------------------------------------Get Suppliers In Purchase ---------------------------------------------- */
export const getSuppliersInPurchase = createAsyncThunk("purchases/getSuppliersInPurchase",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Inventory/LookupSuppliers`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ------------------------------------------Create Purchase ---------------------------------------------- */
  export const createPurchase = createAsyncThunk("purchases/createPurchase", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/AddPurchasing?purchasing=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getPurchaseDetails = createAsyncThunk("purchases/getPurchaseDetails", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/PurchasingListById?purchasingid={"PurchasingId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  /*  --------------------------------------------- Edit Purchasing ---------------------------------------------*/
  export const editPurchase = createAsyncThunk("purchases/editPurchase",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Purchasing/EditPurchasing?purchasingid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);

      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  
  export const deleteItem = createAsyncThunk("items/deleteItem",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Item/DeleteItemById?inventoryitemid={"InventoryItemId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  // lsh
  export const getDeletedItem = createAsyncThunk(
    "items/getDeletedItem",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/Item/DeletedItemList`);
       //console.log(data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getFilteredData = createAsyncThunk(
    "purchases/getFilteredData ",
    async (args, thunkApi) => {
      try {
        console.log("jooo", args);
        return (args);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  
  
  export const resetFilteredData = createAsyncThunk(
    "purchases/resetFilteredData ",
    async (args, thunkApi) => {
      try {
        return args;
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  
  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getPurchasingFilteredData = createAsyncThunk("purchases/getPurchasingFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetPurchasingFilteredData = createAsyncThunk( "purchases/resetPurchasingFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);