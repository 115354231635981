import GModal from "components/ui/modal/Modal";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
  Input as ChInput,
} from "@chakra-ui/react";
import Input from "components/form/Input";
import { Controller, useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { getSuppliers } from "../../../items/services/supplieritemService";
import { getbranches } from "../../../items/services/customLevelItemService";
import {
  editPurchase,
  getPurchaseDetails,
} from "../../services/purchaseService.js";

export default function EditPurchaseModal({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useParams();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const allSuppliers = useSelector((state) => state.suppliers);
  const branches = useSelector((state) => state.customLevel);
  const itemDetailsData = useSelector((state) => state.items);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({ defaultValues: { ...itemDetailsData.itemDetails[0] } });

  watch(["InventoryItemRef"]);

  useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getbranches());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.purchases.edit")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.BranchId = values?.BranchId?.BranchId;
                values.SuppliersId = values?.SuppliersId?.SuppliersId;
                values.PurchasingId = id.purchaseId;
                values.UserId = localStorage.getItem("userId");
                if (
                  values.BranchId === undefined ||
                  values.SuppliersId === undefined
                ) {
                  toast2({ description: t("validation.choose_first") });
                } else {
                  dispatch(editPurchase(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.purchases.purchase_edit"),
                      });
                      dispatch(getPurchaseDetails(id.purchaseId));
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <MultiSelect
                  title={t("pages.purchases.supplier")}
                  isRequired
                  data={
                    allSuppliers.data &&
                    allSuppliers.data.map((el) => ({
                      ...el,
                      label: el.SuppliersName,
                      value: el.SuppliersId,
                    }))
                  }
                  isMulti={false}
                  onChange={(e) => setValue("SuppliersId", e)}
                />

                <MultiSelect
                  isRequired
                  title={t("pages.purchases.branche")}
                  data={
                    branches.data &&
                    branches.data.map((el) => ({
                      ...el,
                      label: el.BranchName,
                      value: el.BranchId,
                    }))
                  }
                  isMulti={false}
                  onChange={(e) => setValue("BranchId", e)}
                />

                <Input
                  label={t("pages.purchases.invoice_date")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.purchases.invoice_date")}
                  name="InvoiceDate"
                  isFormController
                  type="date"
                  control={control}
                  isRequired
                />

                <FormControl isInvalid={errors.InvoiceNo}>
                  <FormLabel
                    htmlFor="InvoiceNo"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.purchases.invoice_number")}
                  </FormLabel>
                  <Controller
                    name="InvoiceNo"
                    control={control}
                    defaultValue="0"
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.purchases.invoice_number")}
                        onChange={(e) => {
                          handleInputChange(e, "InvoiceNo");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.InvoiceNo && errors.InvoiceNo.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.AdditionalCosts}>
                  <FormLabel
                    htmlFor="AdditionalCosts"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.purchases.additional_cost")}
                  </FormLabel>
                  <Controller
                    name="AdditionalCosts"
                    control={control}
                    defaultValue="0"
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.purchases.additional_cost")}
                        onChange={(e) => {
                          handleInputChange(e, "AdditionalCosts");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.AdditionalCosts && errors.AdditionalCosts.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.PaidTax}>
                  <FormLabel
                    htmlFor="PaidTax"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.purchases.paid_tax")}
                  </FormLabel>
                  <Controller
                    name="PaidTax"
                    control={control}
                    defaultValue="0"
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.purchases.paid_tax")}
                        onChange={(e) => {
                          handleInputChange(e, "PaidTax");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.PaidTax && errors.PaidTax.message}
                  </FormErrorMessage>
                </FormControl>

                <Input
                  label={t("pages.purchases.notes")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.purchases.notes")}
                  name="Notes"
                  isFormController
                  control={control}
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    //isLoading={categories.isLoading}
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    //isLoading={categories.isLoading}
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
