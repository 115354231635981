import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import GButton from "components/ui/button/Button"
import CreateSectionModal from "./modals/CreateSectionModal";
import { getSectiones } from "../services/sectionsService";
import STable from "./SectionsTable";
import UpdateSectionModal from "./modals/UpdateSectionModal";

const Sections = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { branchId } = useParams();

  const sections = useSelector(state => state.sections);
  //console.log(sections);

  const [showCreateSectionModal, setShowCreateSectionModal] = useState(null);
  const [showUpdateSectionModal, setShowUpdateSectionModal] = useState(null);

  useEffect(() => {
    dispatch(getSectiones({branchId}))
  }, [dispatch, branchId])

  return (
    <Box>
      <Flex
        alignItems="center" justifyContent="space-between"
        gap={2} flexWrap="wrap" mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.branches.sections")}
        </Text>
        <GButton
          title={t("pages.branches.create_section")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowCreateSectionModal({ branchId })}
        />
      </Flex>
      {sections.isLoading ? (
        <Spinner />
      ) : sections.data?.length === 0 ? (
        <Flex
          p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : (
        <STable
          sections={sections.data}
          handleUpdate={(e) => setShowUpdateSectionModal(e)}
        />
      )}

      {showCreateSectionModal && (
        <CreateSectionModal
          data={showCreateSectionModal}
          onClose={() => setShowCreateSectionModal(null)}
        />
      )}

      {showUpdateSectionModal && (
        <UpdateSectionModal
          data={showUpdateSectionModal}
          onClose={() => setShowUpdateSectionModal(null)}
        />
      )}
    </Box>
  )
}

export default Sections