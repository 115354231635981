import React, { useEffect } from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import {Box, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import Input from "components/form/Input";
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import { useNavigate} from 'react-router-dom';
import { getItemsReturnDropdown, sendReturnedItems } from '../../services/PurchasingItemsService';
 
export default function ReturnToSupplier({onClose , id , SuppliersId}){
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const {handleSubmit,setValue,trigger,formState: { errors }, control} = useForm();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const PurchaseItems = useSelector(state => state.purchasesitems);
    useEffect(()=>{
        dispatch(getItemsReturnDropdown(id?.purchaseId))
    },[dispatch , id?.purchaseId])


    const handleInputChange = async (e, type) => {
        setValue(type, e.target.value);
        await trigger(type);
      };

    const isOnlySpaces = (value) => {
        // Check if the value contains only spaces
        return !value.trim();
      };


  return (
    <GModal
            onClose={onClose}
            modalHeader={t("pages.purchases.return_items")}
            modalContent={(
        <Box  p={4}>
        
        <form onSubmit={handleSubmit(values=>{
            
            values.InventoryItems = values.InventoryItems?.map(el => (
                {
                    InventoryItemId: el.InventoryItemId , 
                    Quantity : el.Quantity , 
                    UnitCost : el.UnitCost,
                    TotalCost : el.TotalCost
                }
            ));

            values.PurchasingId = id.purchaseId;
            values.SuppliersId = SuppliersId;
            if(values.InventoryItems?.length === 0 || values.InventoryItems === undefined){
                toast2({ description: t("validation.choose_first") });
            }else {
                dispatch(sendReturnedItems(values)).unwrap().then( Result=> {
                    const navigateId = JSON.parse(Result[0].jsonData) ;
                    if(navigateId){
                        toast({ description: t("pages.purchases.purchase_item_returned") });
                        onClose();
                        navigate(`/inventory/returnItems_toSupplier/${navigateId?.PurchasingReturnId}`)
                    }
                    else{
                        console.log("Errorrr");
                    };
                });
            }
        })}>
        
        <Stack spacing={6}>

            
            <FormControl isInvalid={errors.ReturnTax}>
                <FormLabel
                htmlFor="ReturnTax"
                style={{ textTransform: "capitalize" }}
                >
                {t("pages.purchases.return_tax")}
                </FormLabel>
            <Controller
                name="ReturnTax"
                control={control}
                defaultValue=""
                rules={{
                required: t("validation.required"),
                validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                    intValue >= 0 || t("validation.must_be_non_negative")
                    );
                },
                }}
                render={({ field }) => (
                <Input
                    {...field}
                    placeholder={0}
                    onChange={(e) => {
                    handleInputChange(e, "ReturnTax");
                    }}
                    type="number"
                    min="0"
                />
                )}
            />
            <FormErrorMessage>
                {errors.ReturnTax && errors.ReturnTax.message}
            </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.AdditionalCost}>
                <FormLabel
                htmlFor="AdditionalCost"
                style={{ textTransform: "capitalize" }}
                >
                {t("pages.purchases.additional_cost")}
                </FormLabel>
            <Controller
                name="AdditionalCost"
                control={control}
                defaultValue=""
                rules={{
                required: t("validation.required"),
                validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                    intValue >= 0 || t("validation.must_be_non_negative")
                    );
                },
                }}
                render={({ field }) => (
                <Input
                    {...field}
                    placeholder={0}
                    onChange={(e) => {
                    handleInputChange(e, "AdditionalCost");
                    }}
                    type="number"
                    min="0"
                />
                )}
            />
            <FormErrorMessage>
                {errors.AdditionalCost && errors.AdditionalCost.message}
            </FormErrorMessage>
            </FormControl>

            <Input
                label={t("pages.purchases.notes")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.purchases.notes")}
                name="Notes"
                isFormController
                control={control}
            />

            <MultiSelect
                title={t("pages.purchases.items")}
                data={PurchaseItems?.itemsReturnDropsown && PurchaseItems?.itemsReturnDropsown.map(el => ({
                    ...el,
                    label: el.InventoryItemNameEng,
                    value: [{InventoryItemId:el.InventoryItemId} , {Quantity:el.Quantity} , {TotalCost:el.TotalCost} , {UnitCost:el.UnitCost}]
                    }))}
                isMulti={true}
                isRequired={true}
                onChange={(e) => setValue("InventoryItems", e)}
            />

        <Flex gap={4} >
            <GButton
                type="submit"
                title={t("pages.inventory_item_details.apply")}
                bg="primary"
                color="light"
                border="1px solid transparent"
                isLoading={PurchaseItems.isLoading}
                hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
            <GButton
                title={t("general.close")}
                onClick={onClose}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                isLoading={PurchaseItems.isLoading}
                hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
        </Flex>


        </Stack>
        
        
        </form>
        
        </Box>

    )}
    />
  );
};