import GModal from "components/ui/modal/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {Box,Checkbox, Flex,FormControl, FormErrorMessage, Text ,FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { editCombosPricesPriceTags, getPriceTagsCombos } from "../../services/priceTagsServices";

export default function EditCombosPrices ({ onClose, id , data}){
    console.log(data);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({position: "top", duration: 3000, status: "success"});
  const toast2 = useToast({position: "top", duration: 3000, status: "error"});
  const { handleSubmit, control, setValue,  register,  formState: { errors },  trigger} =  useForm();

  const [itemData, setItemData] = useState({});

  const PriceTagsData = useSelector(state => state.priceTags);

  useEffect(() => {
    const initialItemData = {};
      data?.forEach((el) => {
      // Store each element in a separate object
      initialItemData[el.PriceTagsComboId] = {
        PriceTagsComboId: el.PriceTagsComboId,
        NewComboPrice: el.NewComboPrice,
      };
    });

    // Set the state with both the object and the array
    setItemData(initialItemData);
  }, [data]);

  const handleQuantityChange = (newQuantity, itemId) => {
    // Step 3: Update the state with the new data using a unique identifier
    setItemData((prevData) => {
      return {
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          NewComboPrice: newQuantity,
        },
      };
    });
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.price_tags.edit_prices")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
                  const editedItems = Object.keys(itemData).map((itemId) => ({
                    PriceTagsComboId: parseInt(itemId,10),
                    NewComboPrice: +itemData[itemId].NewComboPrice,
                  }));
                  values.Prices = editedItems;
                  values.PriceTagsId = +id.PriceTagsId;
                  dispatch(editCombosPricesPriceTags(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.price_tags.prices_updated") });
                    dispatch(getPriceTagsCombos(id.PriceTagsId));
                    onClose();
                  });
            })}
          >
            <Stack spacing={6}>
              {data?.map((el , index) => (
                  <Box>
                      <Text
                        marginBottom={"7px"}
                        fontSize={"1rem"}
                        fontWeight={"500"}
                        textTransform={"capitalize"}
                        textAlign={"start"}
                        marginInlineEnd={"0.75rem"}
                        style={{ marginBottom: "0.5rem" }}
                      >
                        {el.Combo[0]?.ComboName}
                      </Text>
                      <ChInput
                        defaultValue={itemData[el.PriceTagsComboId]?.NewComboPrice || ""}
                        min={0}
                        name="NewComboPrice"
                        onChange={(e) =>
                          handleQuantityChange(
                            e.target.value,
                            el.PriceTagsComboId
                          )
                        }
                      />
                  </Box>
                ))}

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

