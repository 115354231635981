import React, { useEffect, useState } from "react";
import { Box, Flex, Stack, Text, useToast } from "@chakra-ui/react";
import { axios } from "services/axios";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "components/form/Input";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { getPaymentModes } from "features/callCenter/service/callCenterService";
import { useDispatch, useSelector } from "react-redux";
import GModal from "components/ui/modal/Modal";

const SettingsModal = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, setValue, handleSubmit } = useForm();
  const toast = useToast({
    position: "top",
    status: "success",
    duration: 3000,
  });

  const paymentMethods = useSelector((state) => state.callcenter);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getPaymentModes());
  }, [dispatch]);

  const updateSettings = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `/Manage/Setting/DigitalWalletSettings/AddSettingDigitalWallet?digitalwallet=${JSON.stringify(
          values
        )}`
      );
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  };
  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.management.settings.digital_wallet.settings")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.DigitalWalletSettingsId = null;
                values.PaymentMethodId =
                  values.PaymentMethodId?.PaymentMethodId;
                console.log("seka", values);
                updateSettings(values)
                  .then((_) =>
                    toast({
                      description: t(
                        "pages.management.settings.settings_updated"
                      ),
                    })
                  )
                  .catch((_) =>
                    toast({
                      status: "error",
                      description: "something went wrong!",
                    })
                  );
              })}
            >
              <Stack spacing={6} bg="light" p={4}>
                <Input
                  type="number"
                  label={t(
                    "pages.management.settings.digital_wallet.merchant_id"
                  )}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t(
                    "pages.management.settings.digital_wallet.merchant_id"
                  )}
                  isFormController
                  control={control}
                  name="MerchantId"
                />

                <MultiSelect
                  data={
                    paymentMethods.paymentModes &&
                    paymentMethods.paymentModes.map((el) => ({
                      ...el,
                      label: el.PaymentMethodName,
                      value: el.PaymentMethodId,
                    }))
                  }
                  isMulti={false}
                  title={t(
                    "pages.management.settings.digital_wallet.payment_method_id"
                  )}
                  onChange={(e) => setValue("PaymentMethodId", e)}
                />

                <Flex justifyContent="flex-end" gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    isDisabled={isLoading}
                    border="1px solid transparent"
                    hover={{
                      bg: "none",
                      borderColor: "primary",
                      color: "primary",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    isLoading={isLoading}
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
};

export default SettingsModal;
