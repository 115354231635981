import { createSlice } from "@reduxjs/toolkit";
import {
  getTaxes,
  createTax,
  updateTax,
  deleteTax
} from "../services/taxesService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null
};

const taxesSlice = createSlice({
  name: "taxes",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTaxes.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTaxes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getTaxes.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createTax.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createTax.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createTax.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateTax.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateTax.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(tax => tax.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateTax.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteTax.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteTax.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(tax => tax.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteTax.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default taxesSlice.reducer;