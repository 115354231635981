import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- Get Coupons  -------------------------------------------------------------------*/
export const getCoupons = createAsyncThunk(
  "coupons/getCoupons",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Coupons/CouponsList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- create Coupon  -------------------------------------------------------------------*/
export const createCoupon = createAsyncThunk(
  "coupons/createCoupon",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `Manage2/Coupons/AddEditCoupons?coupon=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- create Bulk Coupon  -------------------------------------------------------------------*/
export const createBulkCoupon = createAsyncThunk(
  "coupons/createBulkCoupon",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `Manage2/Coupons/AddBulkCoupons?coupon=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Edit Coupons  -------------------------------------------------------------------*/
export const updateCoupon = createAsyncThunk(
  "coupons/updateCoupon",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/Coupons/AddEditCoupons?coupon=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete Coupons  -------------------------------------------------------------------*/
export const deleteCoupon = createAsyncThunk(
  "coupons/deleteCoupon",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/Coupon/DeleteCouponById?couponid={"CouponId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Get coupons Discounts List  -------------------------------------------------------------------*/
export const getDiscountsCoupons = createAsyncThunk(
  "coupons/getDiscountsCoupons",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/LookupDiscounts?coupontypeid={"CouponTypeId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getFilteredData = createAsyncThunk(
  "coupons/getFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetFilteredData = createAsyncThunk(
  "coupons/resetFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -----------------------------------------------  Activate Coupon  --------------------------------------- */
export const activateCoupon = createAsyncThunk(
  "coupons/activateCoupon",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/Coupons/DeActiveCoupons?couponid={"CouponId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------- Get Coupon By ID (Details) --------------------------------------- */
export const getCouponById = createAsyncThunk(
  "coupons/getCouponById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage/Coupons/CouponById?couponid={"CouponId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Get all coupons Discounts List  -------------------------------------------------------------------*/
export const getAllDiscountsForCoupons = createAsyncThunk(
  "coupons/getAllDiscountsForCoupons",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/LookupAllDiscounts`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
