import { useTranslation } from "react-i18next";
import * as FiIcons from "react-icons/fi";
import {
  Button,
  Flex,
  Text,
  Link as ChakraLink,
  Box,
  Stack,
  Input as ChInput,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import Table from "../../../components/ui/table/Table";
import BreadCrumb from "../../../components/ui/breadcrumb/BreadCrumb";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  GetOrderByDate,
  getBranchesLookUpOrder,
} from "../services/ordersService";
import ExportModal from "components/ui/modal/ExportModal";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Orders = () => {

  const retrievedArray =useDecryptedPrivilege()
        const ManageOrders = checkIdExists(retrievedArray, 2);
        const ReadOrders = checkIdExists(retrievedArray, 1);

        
        //console.log(ReadOrders)

        

  const { t } = useTranslation();
  //const testData = [{}];
  const { handleSubmit, control, getValues, watch, setValue, register } =
    useForm();

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranchesLookUpOrder());
  }, [dispatch]);

  // useEffect(()=>{
  //   dispatch(GetOrderByDate(date))
  // },[dispatch])

  const data = useSelector((state) => state.ordersSlice);

  //console.log(data.isLoading)

  const orderData = data.ordersData;

  const navigate = useNavigate();

  const createOrderHandler = () => {
    navigate("createOrder");
  };


  const [exportModal, SetExportModal] = useState(false);

  //console.log(data)

  // const [branchName,setBranchName]=useState()
  // const [Date,setDate]=useState()

  // const ManageOrdersTest=true
  //       const ReadOrdersTest=false
  

if(ManageOrders===false && ReadOrders===false ){
  return(
    <NotAuthorizedCompo/>
  )
};



  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.orders.orders")}
          </Text>

          <Flex gap={2}>
          
          {
            orderData.length == 0?(""):(  <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
            >
              <div onClick={() => SetExportModal(true)}>
                {t("general.export")}
              </div>
            </Button>)
          }
        
            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={createOrderHandler}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.orders.create_order")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>

      <Box
        bg="light"
        borderRadius={4}
        marginBottom={6}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        padding={15}
      >
        <form
          onSubmit={handleSubmit((values) => {
            if (
              values.BranchId === undefined ||
              values.UserDate === undefined
            ) {
              toast2({
                description: t("pages.DailyRestrictions.searchvalidation"),
              });
            } else {
              values.BranchId = values.BranchId.BranchId;
              console.log(values);
              dispatch(GetOrderByDate(values));
            }
          })}
        >
          <Flex alignItems="center" justifyContent="space-between" gap={4}>
            <div style={{ width: "50%" }}>
              <MultiSelect
                title={t("pages.inventory.select_branche")}
                data={data?.branches.map((el) => ({
                  ...el,
                  label: el.BranchName,
                  value: el.BranchId,
                }))}
                isMulti={false}
                isRequired={true}
                onChange={(e) =>{ 
                
                  setValue("BranchId", e)
                }}
              />
            </div>

            <div style={{ width: "50%" }}>
              <h3 style={{ marginBottom: "7px" }}>
                {t("pages.Expenses.data")}
              </h3>
              <Controller
                name="UserDate"
                control={control}
                render={({ field }) => (
                  <ChInput {...field} style={{ width: "100%" }} type="date" />
                )}
              />
            </div>
        
          </Flex>
          <Button
            type="submit"
            bg="primary"
            color="light"
            textTransform="capitalize"
            fontSize="1rem"
            marginTop={5}
          >
            <Flex alignItems="center" gap={1}>
              {t("pages.customers.apply")}
            </Flex>
          </Button>
        </form>
      </Box>

      {data.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : orderData.length == 0 ? (
        <Box
          bg="light"
          borderRadius={4}
          marginBottom={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          padding={15}
        >
          <h1 style={{ textAlign: "center" }}>
            {t("pages.orders.validationFilter")}
          </h1>
        </Box>
      ) : (
        <>
        {/*<Flex
            alignItems="center"
            flexWrap="wrap"
            p={4}
            borderBottom="1px solid"
            borderColor="border"
            textTransform="capitalize"
            gap={6}
            color="text"
            bg="light"
          >
           <ChakraLink
              as={NavLink}
              to=""
              display="inline-block"
              pb={1}
              fontSize=".9rem"
              fontWeight="normal"
              _hover={{ textDecoration: "none" }}
            >
              {t("general.all")}
            </ChakraLink>
            <ChakraLink
              as={NavLink}
              display="inline-block"
              pb={1}
              fontSize=".9rem"
              fontWeight="normal"
              _hover={{ textDecoration: "none" }}
            >
              {t("pages.orders.today")}
            </ChakraLink>
            <ChakraLink
              as={NavLink}
              display="inline-block"
              pb={1}
              fontSize=".9rem"
              fontWeight="normal"
              _hover={{ textDecoration: "none" }}
            >
              {t("pages.orders.pending")}
            </ChakraLink>
            <ChakraLink
              as={NavLink}
              display="inline-block"
              pb={1}
              fontSize=".9rem"
              fontWeight="normal"
              _hover={{ textDecoration: "none" }}
            >
              {t("pages.orders.active")}
            </ChakraLink>
          </Flex>*/}
          <Table orderData={orderData} />
        </>
      )}
      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={orderData}
          fileName={"Orders"}
        ></ExportModal>
      )}
    </>
  );
};

export default Orders;
