import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  useToast,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  editJobDetails,
  getEmployeeById,
} from "../../services/employeeServices";

export default function EditJobDetails({
  id,
  onClose,
  PositionDropdown,
  details,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const {
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, []);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.edit_job_details")}
        modalContent={
          showContent ? (
            <Box p={4}>
              <form
                onSubmit={handleSubmit((values) => {
                  values.EmployeeId = id;
                  values.ToJob =
                    values.ToJob?.PositionId || details?.PositionId;

                  if (values?.ToJob === undefined) {
                    toast2({
                      description: t("pages.hr.hr_validation.select_item"),
                    });
                  } else {
                    dispatch(editJobDetails(values))
                      .unwrap()
                      .then((_) => {
                        toast({
                          description: t(
                            "pages.hr.job_details_info_updated_successfuly"
                          ),
                        });
                        dispatch(getEmployeeById(id));
                        onClose();
                      });
                  }
                })}
              >
                <Stack spacing={6}>

                  <Box>
                    <Text>{t("pages.hr.Employee_code")}</Text>
                    <Box
                      border={"1px"}
                      borderColor={"gray.300"}
                      padding={2}
                      borderRadius={5}
                      textColor="gray.600"
                      // fontWeight={"semibold"}
                      marginTop={"2"}
                    >
                      <Text>{details?.EmployeeCode}</Text>
                    </Box>
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.Employee_name")}</Text>
                    <Box
                      border={"1px"}
                      borderColor={"gray.300"}
                      padding={2}
                      borderRadius={5}
                      textColor="gray.600"
                      // fontWeight={"semibold"}
                      marginTop={"2"}
                    >
                      <Text>{details?.FullEnName}</Text>
                    </Box>
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.from")}</Text>
                    <Box
                      border={"1px"}
                      borderColor={"gray.300"}
                      padding={2}
                      borderRadius={5}
                      textColor="gray.600"
                      // fontWeight={"semibold"}
                      marginTop={"2"}
                    >
                      <Text>
                        {PositionDropdown[details?.PositionId - 1]?.Position}
                      </Text>
                    </Box>
                  </Box>

                  <Box>
                    <MultiSelect
                      title={t("pages.hr.to")}
                      isMulti={false}
                      defaultValue={{
                        label:
                          PositionDropdown[details?.PositionId - 1]?.Position,
                      }}
                      data={
                        PositionDropdown &&
                        PositionDropdown?.map((el) => ({
                          ...el,
                          label: el.Position,
                          value: el.PositionId,
                        }))
                      }
                      onChange={(e) => setValue("ToJob", e)}
                    />
                  </Box>

                  <Flex gap={4}>
                    <GButton
                      type="submit"
                      title={t("general.save")}
                      bg="primary"
                      color="light"
                      border="1px solid transparent"
                      hover={{
                        color: "primary",
                        borderColor: "primary",
                        bg: "none",
                      }}
                    />
                    <GButton
                      title={t("general.close")}
                      onClick={onClose}
                      bg="red.600"
                      border="1px solid transparent"
                      color="light"
                      hover={{
                        color: "red.600",
                        borderColor: "red.600",
                        bg: "none",
                      }}
                    />
                  </Flex>
                </Stack>
              </form>
            </Box>
          ) : (
            <Flex bg="light" p={4} justifyContent="center">
              <Spinner />
            </Flex>
          )
        }
      ></GModal>
    </>
  );
}
