import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Pagination from "components/ui/pagination/Pagination";
import { useState } from "react";


export default function CTable({ devices }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateFunction = (id) => {
    navigate(`${id}`);
  };

    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
    const recordsPerPage = 10;
    const [currentPageActive, setCurrentPageActive] = useState(1);
    const prePage = (currentPage, setCurrentPage) => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const nextPage = (currentPage, setCurrentPage, npage) => {
      if (currentPage < npage) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const calculateRecords = (devices, currentPage, recordsPerPage) => {
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, devices?.length);
      return devices?.slice(firstIndex, lastIndex);
    };
  
    // Calculate records for activeDiscounts
    const devicesData = calculateRecords(
      devices,
      currentPageActive,
      recordsPerPage
    );
    /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.devices.name")}</Th>
            <Th>{t("pages.devices.ref")}</Th>
            <Th>{t("pages.devices.status")}</Th>
            <Th>{t("pages.devices.type")}</Th>
            <Th>{t("pages.devices.branch")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {devicesData?.map((device, index) => (
            <Tr key={device?.DevicesId}>
              <Td>{index + 1}</Td>
              <Td
                style={{ cursor: "pointer" }}
                onClick={() => navigateFunction(device?.DevicesId)}
              >
                {device?.DevicesName}
              </Td>
              <Td>{device?.DevicesRef}</Td>
              {device?.DevicesType[0].Branches[0]?.DeviceStatus === "Used" ? (
                <Td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Text
                    padding={"10px"}
                    maxWidth={"max-content"}
                    textColor={"white"}
                    bg={"green"}
                    style={{ borderRadius: "10px" }}
                  >
                    {device?.DevicesType[0]?.Branches[0]?.DeviceStatus}
                  </Text>
                </Td>
              ) : (
                <Td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Text
                    padding={"10px"}
                    maxWidth={"max-content"}
                    textColor={"white"}
                    bg={"red.500"}
                    style={{ borderRadius: "10px" }}
                  >
                    {device?.DevicesType[0]?.Branches[0]?.DeviceStatus}
                  </Text>
                </Td>
              )}
              <Td>{device?.DevicesType[0]?.DevicesTypeName}</Td>
              <Td>{device?.DevicesType[0]?.Branches[0]?.BranchName}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(devices?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(devices?.length / recordsPerPage)
            )
          }
      />
    </Box>
  );
}
