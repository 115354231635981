import React from 'react';
import GRestoreModal from "components/ui/modal/RestoreModal";
import { useDispatch } from 'react-redux';
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { deleteDeliveryZone, getAllDeliveryZone, getDeletedDeliveryZone } from '../../services/deliveryZoneNewService';

export default function RestoreDeliveryZoneModal ({onClose,id}){
  const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();

  return (
    <GRestoreModal
        onClose={onClose}
        onSubmit={()=>{
          dispatch(deleteDeliveryZone(id)).unwrap().then(_ => {
            toast({ description: t("pages.DeliverZone.delivery_zone_restored") });
            onClose();
            dispatch(getAllDeliveryZone());
            dispatch(getDeletedDeliveryZone());
          })
        }}  
  />
  );
};

