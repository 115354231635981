import React, { useEffect, useState } from "react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CostAdjustmentItems from "../components/CostAdjustmentItems";
import EditCostAdjustment from "../components/modals/EditCostAdjustment";
import SubmitAdjustment from "../components/modals/SubmitAdjustment";
import DeleteCostAdjustment from "../components/modals/DeleteCostAdjustment";
import { getCostAdjustmentDetails } from "../services/costAdjustmentDetails";
import { getListItemAfterSubmitByCostAdjustmentId } from "../services/costAdjustment";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

export default function CostAdjustmentDetails() {
    const id = useParams()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const costAdjustMentData = useSelector(state => state.costAdjustment);
    const purchaseOrderStatus = costAdjustMentData?.costAdjustmentDetails[0]?.CostAdjustmentStatus ;

    const [showEditCostAdjustment, setShowEditCostAdjustment] = useState(false);
    const [showSubmitAdjustment, setShowSubmitAdjustment] = useState(false);
    const [showDeleteAdjustment, setShowDeleteAdjustment] = useState(false);

    const handlePrint = () => {
        window.print();
      };

  useEffect(() => {
    dispatch(getCostAdjustmentDetails(id.id));
    dispatch(getListItemAfterSubmitByCostAdjustmentId(id.id));
  }, [dispatch,id])

/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageCostAdjustments = checkIdExists(retrievedArray, 30);

if(showSubmitAdjustment===true && ManageCostAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowSubmitAdjustment(false);
};
if(showEditCostAdjustment===true && ManageCostAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowEditCostAdjustment(false);
};
if(showDeleteAdjustment===true && ManageCostAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowDeleteAdjustment(false);
};
/* -----------------------------------Privilige-------------------------------*/
    
  return <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            {t("pages.cost_adjustment.cost_adjustment")}
            <Box
              bg={"#718096"}
              display={"inline-block"}
              marginX={"5px"}
              borderRadius={"5px"}
              color={"white"}
              fontSize={"15px"}
              padding={" 2px 10px"}
            >
              {costAdjustMentData.costAdjustmentDetails[0]?.CostAdjustmentStatus}
            </Box>
          </Box>
          <Flex gap={2}>
            {/* Print Button */}
            <GButton
              prefixElement={<FiIcons.FiPrinter size={16} />}
              title={t("pages.purchase_orders.print")}
              bg="light"
              color="text"
              // hover={{ bg: "primary" }}
              border="1px"
              borderColor={"#CBD5E0"}
              opacity="0.7"
              onClick={() => handlePrint()}
            /> 
            {purchaseOrderStatus === "Draft" ? (
              <>
                {/* Edit Button */}
                <GButton
                    prefixElement={<FiIcons.FiEdit2 size={16} />}
                    title={t("pages.purchase_orders.edit")}
                    bg="light"
                    color="text"
                    border="1px"
                    borderColor={"#CBD5E0"}
                    opacity="0.7"
                    onClick={() => setShowEditCostAdjustment(true)}
                  />
                <GButton
                  prefixElement={<FiIcons.FiTrash2 size={16} />}
                  title={t("pages.purchase_orders.delete_permenantly")}
                  bg="light"
                  color="text"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  opacity="0.7"
                  onClick={() => setShowDeleteAdjustment(true)}
                />

                <GButton
                  title={t("pages.purchase_orders.submit_for_review")}
                  bg="#54A0FF"
                  color="light"
                  hover={{ bg: "primary" }}
                  onClick={() => setShowSubmitAdjustment(true)}
                />
              </>
            ) : (
              ""
            )}
            {purchaseOrderStatus === "Pending" ? (
              <>
                <GButton
                  prefixElement={<FiIcons.FiTrash2 size={16} />}
                  title={t("pages.purchase_orders.reject")}
                  bg="light"
                  color="text"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  opacity="0.7"
                //   onClick={() => setShowRejectedModal(true)}
                />
                <GButton
                  title={t("pages.purchase_orders.approve")}
                  bg="#54A0FF"
                  color="light"
                  hover={{ bg: "primary" }}
                //   onClick={() => setShowSubmitReviewModal(true)}
                />
              </>
            ) : (
              ""
            )}
            {purchaseOrderStatus === "Approved" ? (
              <>
                <GButton
                  prefixElement={<FiIcons.FiTrash2 size={16} />}
                  title={t("pages.purchase_orders.close")}
                  bg="light"
                  color="text"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  opacity="0.7"
                  // onClick={() => setShowDeleteAdjustment(true)}
                />
                <GButton
                  title={t("pages.purchase_orders.creat_purchasing")}
                  bg="#54A0FF"
                  color="light"
                  hover={{ bg: "primary" }}
                //   onClick={() => setShowCreatePurchsing(true)}
                />
              </>
            ) : (
              ""
            )}
          </Flex>
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        {
          costAdjustMentData.isLoading ?
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
          :
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text" opacity={"0.7"}>
                {t("pages.cost_adjustment.branch")}
              </Text>
              <Text marginBlock={2} fontWeight={"bold"}>
                {costAdjustMentData?.costAdjustmentDetails[0]?.BranchName || "-"}
              </Text>
            </Box>

            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text" opacity={"0.7"}>
                {t("pages.cost_adjustment.business_date")}
              </Text>
              <Text marginBlock={2} fontWeight={"bold"}>
              {costAdjustMentData?.costAdjustmentDetails[0]?.BusinessDate || "-"}
              </Text>
            </Box>

            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text" opacity={"0.7"}>
                {t("pages.cost_adjustment.creator")}
              </Text>
              <Text marginBlock={2} fontWeight={"bold"}>
              {costAdjustMentData?.costAdjustmentDetails[0]?.Creator || "-"}
              </Text>
            </Box>

            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text" opacity={"0.7"}>
                {t("pages.cost_adjustment.created")}
              </Text>
              <Text marginBlock={2} fontWeight={"bold"}>
              {costAdjustMentData?.costAdjustmentDetails[0]?.CreatedDate || "-"}
              </Text>
            </Box>
          </SimpleGrid>
          }
        {/* Cost Adjustment Items */}
        <CostAdjustmentItems id={id.id} costAdjustmentStatus={costAdjustMentData.costAdjustmentDetails[0]?.CostAdjustmentStatus} />

      </Stack>

        {/*Show Edit Item In Purchase Order Modal*/}
        {showEditCostAdjustment && (
          <EditCostAdjustment
            onClose={() => setShowEditCostAdjustment(false)}
            data={costAdjustMentData.costAdjustmentBranch}
            id={id.id}
          />
        )}

        {/*Show Submitting Cost Adjustment*/}
        {showSubmitAdjustment && (
          <SubmitAdjustment
            onClose={() => setShowSubmitAdjustment(false)}
            id={id.id}
          ></SubmitAdjustment>
        )}

        {/*Show Deleted Cost Adjustment Permenantly*/}
        {showDeleteAdjustment && (
          <DeleteCostAdjustment
            onClose={() => setShowDeleteAdjustment(false)}
            id={id.id}
          ></DeleteCostAdjustment>
        )}
  
  </>
}


