import { createSlice } from "@reduxjs/toolkit";
import {
  createDiscountLimitCustomerTags,
  getDiscountLimitCustomerTags,
  getTagsInCustomer,
  deleteCustomerTagInDiscount,
} from "../services/discountsLimitCustomerTagsService";

let initialState = {
  isLoading: false,
  errors: null,
  tagsInCustomer: [],
};

const discountsLimitCustomerTagsSlice = createSlice({
  name: "discountsLimitCustomerTags",
  initialState,

  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get customer tags Discounts List  -------------------------------------------------------------------*/
    builder.addCase(getDiscountLimitCustomerTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getDiscountLimitCustomerTags.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(getDiscountLimitCustomerTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create customer tags Discounts  -------------------------------------------------------------------*/
    builder.addCase(createDiscountLimitCustomerTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      createDiscountLimitCustomerTags.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(createDiscountLimitCustomerTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------- Get Tags In customer ---------------------------------------------------*/
    builder.addCase(getTagsInCustomer.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getTagsInCustomer.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInCustomer =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getTagsInCustomer.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete customer Tag In Discount  -------------------------------------------------------------------*/
    builder.addCase(deleteCustomerTagInDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      deleteCustomerTagInDiscount.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(deleteCustomerTagInDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default discountsLimitCustomerTagsSlice.reducer;
