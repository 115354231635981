import { useTranslation } from "react-i18next"
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal"
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Input from "components/form/Input";
import { getFilteredData } from "../../services/categoriesService";

const FilterModal = ({ onClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState(data);

  const categories = useSelector((state) => state.categories);

  const [filterCriteria, setFilterCriteria] = useState({
    ProductCategoriesNameEng: "",
    ProductCategoriesRef: "",
  });

  const handleFilterGiftsCard = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const {
        ProductCategoriesNameEng,
        ProductCategoriesRef,
      } = filterCriteria;


      let filterByNameEng = true;
      let filterByNameRef = true;

      if (ProductCategoriesNameEng) {
        filterByNameEng = item.ProductCategoriesNameEng.toLowerCase().includes(ProductCategoriesNameEng.toLowerCase());
      }

      if (ProductCategoriesRef) {
        filterByNameRef = item.ProductCategoriesRef.toLowerCase().includes(ProductCategoriesRef.toLowerCase());
      }


      return (
        filterByNameEng &&
        filterByNameRef
      );
    });


    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredData(filteredResult));
  };

  const handleClose = () => {
    onClose(filteredData);
  };

  return (
    <GModal
      onClose={handleClose}
      modalHeader={t("pages.categories.filter_categories")}
      modalContent={(
        <Box p={4}>

          <form onSubmit={handleFilterGiftsCard}>
            <Stack spacing={6}>
              <Input
                label={t("pages.gifts_cards.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.name")}
                name="ProductCategoriesNameEng"
                value={filterCriteria.ProductCategoriesNameEng}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ProductCategoriesNameEng: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.gifts_cards.sku")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.sku")}
                name="ProductCategoriesRef"
                value={filterCriteria.ProductCategoriesRef}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ProductCategoriesRef: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={handleClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default FilterModal ;