import axios from "axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");
/* -------------------  Get Hr Religions List ---------------------*/
export const getHrReligionsList = createAsyncThunk(
  "hrSetting/getHrReligionsList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      "https://rostoapi.smartgate-egypt.com/Hr/ReligionList"
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Add New Setting Religion ---------------------*/
export const addNewSettingReligion = createAsyncThunk(
  "hrSetting/addNewSettingReligion",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Religion/AddReligion?religion=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Edit Setting Religion ---------------------*/
export const editSettingReligion = createAsyncThunk(
  "hrSetting/editSettingReligion",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Religion/EditReligion?religionid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Delete Setting Religion ---------------------*/
// /Hr/Religion/DeleteReligionById?religionid={"ReligionId":1}
export const deleteSettingReligion = createAsyncThunk(
  "hrSetting/deleteSettingReligion",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Religion/DeleteReligionById?religionid={"ReligionId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Hr Nationality List ---------------------*/
export const getHrNationalityList = createAsyncThunk(
  "hrSetting/getHrNationalityList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/NationalityList`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Add New Nationality  ---------------------*/
export const addNewSettingNationality = createAsyncThunk(
  "hrSetting/addNewSettingNationality",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Nationality/AddNationality?nationality=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Edit New Nationality  ---------------------*/
export const editSettingNationality = createAsyncThunk(
  "hrSetting/editSettingNationality",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Nationality/EditNationality?nationalityid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Delete Seting Nationality  ---------------------*/
export const deleteSettingNationality = createAsyncThunk(
  "hrSetting/deleteSettingNationality",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Nationality/DeleteNationalityById?nationalityid={"NationalityId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Hr Cities List ---------------------*/
export const getHrCitiesList = createAsyncThunk(
  "hrSetting/getHrCitiesList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/CityList`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Add New Cities  ---------------------*/
export const addNewSettingCities = createAsyncThunk(
  "hrSetting/addNewSettingCities",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/City/AddCity?city=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Edit  Cities  ---------------------*/
export const editSettingCities = createAsyncThunk(
  "hrSetting/editSettingCities",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/City/EditCity?cityid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Delete Seting Cities  ---------------------*/
export const deleteSettingCities = createAsyncThunk(
  "hrSetting/deleteSettingCities",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/City/DeleteCityById?cityid={"CityId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

/* -------------------  Get Permession Type List ---------------------*/
export const getPermessionTypeList = createAsyncThunk(
  "hrSetting/getPermessionTypeList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/PermissionTypeList`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

/* -------------------  Add New Permession Type  ---------------------*/
export const addNewPermessionType = createAsyncThunk(
  "hrSetting/addNewPermessionType",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/PermissionType/AddPermissionType?permissiontype=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Edit  Permession Type  ---------------------*/
export const editPermissionType = createAsyncThunk(
  "hrSetting/editPermissionType",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/PermissionType/EditPermissionType?permissiontypeid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Delete Permession Type  ---------------------*/
export const deletePermissionType = createAsyncThunk(
  "hrSetting/deletePermissionType",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/PermissionType/DeletePermissionTypeById?permissiontypeid={"PermissionTypeId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Vacation Type List ---------------------*/
export const getVacationTypeList = createAsyncThunk(
  "hrSetting/getVacationTypeList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/VacationTypeList`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

// /* -------------------  Add New Vacation Type  ---------------------*/
export const addNewVacationType = createAsyncThunk(
  "hrSetting/addNewVacationType",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/VacationType/AddVacationType?vacationtype=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Edit Vacatin Type  ---------------------*/
export const editVacationType = createAsyncThunk(
  "hrSetting/editVacationType",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/VacationType/EditVacationType?vacationtypeid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Delete Vacatin Type  ---------------------*/
export const deleteVacationType = createAsyncThunk(
  "hrSetting/deleteVacationType",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/VacationType/DeleteVacationTypeById?vacationtypeid={"VacationTypeId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Delete Permession Type  ---------------------*/
// export const deletePermissionType = createAsyncThunk(
//   "hrSetting/deletePermissionType",
//   async (args, thunkAPI) => {
//     const { rejectWithValue, fulfillWithValue } = thunkAPI;
//     const { data } = await axios.get(
//       `https://rostoapi.smartgate-egypt.com/Hr/PermissionType/DeletePermissionTypeById?permissiontypeid={"PermissionTypeId":${args}}`
//     );
//     try {
//       return fulfillWithValue(data);
//     } catch (e) {
//       return rejectWithValue(e.response.data);
//     }
//   }
// );
// /* -------------------  Get Bounces List  ---------------------*/
export const getBouncesList = createAsyncThunk(
  "hrSetting/getBouncesList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/BonusList`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Add New Bounces  ---------------------*/
export const addNewBounces = createAsyncThunk(
  "hrSetting/addNewBounces",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Bonus/AddBonus?bonus=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Edit Bounces  ---------------------*/
export const editNewBounces = createAsyncThunk(
  "hrSetting/editNewBounces",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Bonus/EditBonus?bonusid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Delete Bounces ---------------------*/
export const deleteBounces = createAsyncThunk(
  "hrSetting/deleteBounces",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Bonus/DeleteBonusById?bonusid={"BonusId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
