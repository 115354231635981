import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Box, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput,} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {getInventoryDayTimeList, getWarehouseList, updateWarehouse,} from "../../services/WarehouseServices";

export default function EditWarehouse({ onClose, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({position: "top",  duration: 3000,  status: "success",});
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const {handleSubmit,  control,  getValues,  setValue,  formState: { errors },  trigger,  setError,  clearErrors,} = useForm({ defaultValues: { ...data } });

  const warehouse = useSelector((state) => state.warehouse);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
      setError("ParLevel", {
        type: "manual",
        message: t("validation.must_be_within_min_max_range"),
      });
    } else {
      clearErrors("ParLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

  useEffect(() => {
    dispatch(getInventoryDayTimeList());
  }, [dispatch]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.warehouse.update_warehouse")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.WarehouseId = data[0]?.WarehouseId;
                values.Ref = data[0]?.Reference;
                values.UserId = localStorage.getItem("userId");
                if (values.DayTimeId === undefined) {
                  toast2({ description: t("validation.choose_first") });
                } else {
                  values.DayTimeId = values.DayTimeId.DayTimeId;
                  dispatch(updateWarehouse(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.warehouse.warehouse_updated"),
                      });
                      dispatch(getWarehouseList());
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.Name}>
                  <FormLabel
                    htmlFor="Name"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.warehouse.name")}
                  </FormLabel>
                  <Controller
                    name="Name"
                    control={control}
                    defaultValue={data[0].Name}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.warehouse.name")}
                        onChange={(e) => {
                          handleInputChange(e, "Name");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Name && errors.Name.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.NameLoc}>
                  <FormLabel
                    htmlFor="NameLoc"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.warehouse.name_Localized")}
                  </FormLabel>
                  <Controller
                    name="NameLoc"
                    control={control}
                    defaultValue={data[0].NameLocalized}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalide"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.warehouse.name_Localized")}
                        onChange={(e) => {
                          handleInputChange(e, "NameLoc");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.NameLoc && errors.NameLoc.message}
                  </FormErrorMessage>
                </FormControl>

                <MultiSelect
                  title={t("pages.warehouse.end_of_day")}
                  isMulti={false}                    
                  defaultValue={data[0].DayTimeName}
                  data={
                    warehouse?.InventoryDayTimeList &&
                    warehouse?.InventoryDayTimeList.map((el) => ({
                      ...el,
                      label: el.DayTimeName,
                      value: el.DayTimeId,
                    }))
                  }
                  onChange={(e) => setValue("DayTimeId", e)}
                />


                <Flex alignItems="flex-end" gap={4}>
                  <Input
                    label={t("pages.warehouse.reference")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={data[0].Reference}
                    readOnly
                  />
                </Flex>

                <FormControl isInvalid={errors.Latitude}>
                  <FormLabel
                    htmlFor="Latitude"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.warehouse.latitude")}
                  </FormLabel>
                  <Controller
                    name="Latitude"
                    control={control}
                    defaultValue={data[0].Latitude}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.warehouse.latitude")}
                        onChange={(e) => {
                          handleInputChange(e, "Latitude");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Latitude && errors.Latitude.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.Longitude}>
                  <FormLabel
                    htmlFor="Longitude"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.warehouse.longtude")}
                  </FormLabel>
                  <Controller
                    name="Longitude"
                    control={control}
                    defaultValue={data[0].Longitude}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.warehouse.longtude")}
                        onChange={(e) => {
                          handleInputChange(e, "Longitude");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Longitude && errors.Longitude.message}
                  </FormErrorMessage>
                </FormControl>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    //   isLoading={isLoading}
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    //   isLoading={isLoading}
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
