import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* -------------------------------------------- Add Branch To Tax Group -------------------------------------------*/
export const addBranchToGroup = createAsyncThunk("branchGroups/addBranchToGroup",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Taxs/AddEditBranchTaxGroup?branchtaxsgroup=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* -------------------------------------------- Get Taxes Group Branches To Remove -------------------------------------------*/
export const getTaxesGroupsBranchesToRemove = createAsyncThunk("branchGroups/getTaxesGroupsBranchesToRemove",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Taxs/BranchTaxsGroupList` );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------- Remove Branch From TaxGroup -------------------------------------------*/
export const removeBranchFromGroup = createAsyncThunk("branchGroups/removeBranchFromGroup",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/DeleteTaxGroupBranchesById?taxgroupbranchesid=${JSON.stringify( args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------- Get Branches In Taxes Group  -------------------------------------------*/
export const getBranchesInTaxesGroup = createAsyncThunk("branchGroups/getTaxesGroupsBranches", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------------------------------------------------------------------------*/

export const getBranchesForSpecificGroup = createAsyncThunk(
  "branchGroups/getBranchesForSpecificGroup",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage/Taxs/BranchTaxsGroupListByTaxGroupId?taxgroupid={"TaxGroupId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
