import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useNavigate } from "react-router-dom";

const CTable = ({
  selectedTable,
  users,
  handleUpdate,
  handleDelete
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //console.log(selectedTable)

  const handelNavigation=(id)=>{
    navigate(`${id}`);
  }

  const handleStatus = (status) => {
    const bg = status === true ? "#00D2D2" : "#ED5253";
    return (
      <Box
        height="10px" width="10px" borderRadius="50%"
        bg={bg} margin="auto"
      />
    )
  }

  return (
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.users.full_name")}</Th>
            <Th>{t("pages.users.console_access")}</Th>
            <Th>{t("pages.users.app_access")}</Th>
            <Th>{t("general.action")}</Th>
          </Tr>
        </Thead>
        <Tbody>
        {users.map((user, index) => {
          if (selectedTable === "active"&& user.isActive === true ) {
            return (
              <Tr key={user.userLoginId}>
                <Td>{index + 1}</Td>
                <Td
                  onClick={() => handelNavigation(user.userLoginId)}
                  style={{ cursor: "pointer" }}
                >
                  {user.userFullName}
                </Td>
                <Td>{handleStatus(user.isConsoleAccess)}</Td>
                <Td>{handleStatus(user.isAppAccess)}</Td>
                <Td>
                  <Flex gap={2}>
                    <GButton
                      postfixElement={<FiIcons.FiEdit />}
                      bg="primary"
                      color="light"
                      border="1px solid transparent"
                      hover={{
                        bg: "none",
                        borderColor: "primary",
                        color: "primary",
                      }}
                      onClick={() => handleUpdate(user)}
                    />
                    <GButton
                      postfixElement={<FiIcons.FiTrash2 />}
                      bg="red.600"
                      color="light"
                      border="1px solid transparent"
                      hover={{
                        bg: "none",
                        borderColor: "red.600",
                        color: "red.600",
                      }}
                      onClick={() => handleDelete(user)}
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          } else if(selectedTable === "inActive" &&  user.isActive === false ){
            return (
              <Tr key={user.userLoginId}>
              <Td>{index + 1}</Td>
              <Td
                style={{ cursor: "pointer" }}
              >
                {user.userFullName}
              </Td>
              <Td>{handleStatus(user.isConsoleAccess)}</Td>
              <Td>{handleStatus(user.isAppAccess)}</Td>
              <Td>
                <Flex gap={2}>
                  <GButton
                    postfixElement={<FiIcons.FiEdit />}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      bg: "none",
                      borderColor: "primary",
                      color: "primary",
                    }}
                    onClick={() => handleUpdate(user)}
                  />
                  <GButton
                    postfixElement={<FiIcons.FiTrash2 />}
                    bg="red.600"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      bg: "none",
                      borderColor: "red.600",
                      color: "red.600",
                    }}
                    onClick={() => handleDelete(user)}
                  />
                </Flex>
              </Td>
            </Tr>
            );
          }
        })}
        
        </Tbody>
      </Table>
    </Box>
  )
}

export default CTable