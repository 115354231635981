import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import HrLetterTable from "./components/HrLetterTable";
import AddHrLetterModal from "./components/modal/AddHrLetterModal";
import { getHrLetterData } from "../services/HrSelfServices";
import { useDispatch, useSelector } from "react-redux";

export default function HrLetter() {
  const UserId = localStorage.getItem("userId");
  const disPatch = useDispatch();
  const { t } = useTranslation();
  const [showNewModal, setShowNewModal] = useState(false);

  const data = useSelector((state) => state.HrSelfServices);
  console.log(data);

  useEffect(() => {
    disPatch(getHrLetterData(UserId));
  }, [disPatch, UserId]);

  return (
    <Box>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.selfservices.hr_letter")}
          </Text>

          <Button
            type="button"
            bg="primary"
            color="light"
            textTransform="capitalize"
            fontSize="1rem"
            onClick={() => setShowNewModal(true)}
          >
            <Flex alignItems="center" gap={1}>
              {t("pages.hr.selfservices.new_hr_letter")}
            </Flex>
          </Button>
        </Flex>
      </Box>
      <HrLetterTable data={data?.HrLetterList} />
      {showNewModal && (
        <AddHrLetterModal onClose={() => setShowNewModal(false)} />
      )}
    </Box>
  );
}
