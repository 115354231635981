import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ----------------------------------------- Get Products List --------------------------------------- */
export const getProducts = createAsyncThunk("products/getProducts", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Menu/Product/ProductList");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------- Get Products By ID (Details) --------------------------------------- */
export const getProductById = createAsyncThunk("products/getProductById",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/ProductById?productid=${JSON.stringify({ ProductId: args.productId})}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------- Create New Products --------------------------------------- */
export const createProduct = createAsyncThunk("products/createProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/AddProduct?product=${JSON.stringify(args)}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------- Update Products --------------------------------------- */
export const updateProduct = createAsyncThunk("products/updateProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/EditProduct?productid=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ---------------------------------------------------  Deleting Products --------------------------------------------- */
export const deleteProduct = createAsyncThunk("products/deleteProduct",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/DeleteProductById?productid={"ProductId":${args}}` );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -----------------------------------------------  Activate Products  --------------------------------------- */
export const activateProducts = createAsyncThunk("products/activateProducts",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Product/DeActivateProductById?productid={"ProductId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getFilteredData = createAsyncThunk(
  "products/getFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const resetFilteredData = createAsyncThunk(
  "products/resetFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
