import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr, Checkbox, Input as ChInput, Button } from "@chakra-ui/react"

const DailyRestrictionDataTable = ({ data }) => {

  console.log("data",data)
  const items = []
  const { t } = useTranslation();
  //console.log(data[0].JournalDetail);

  return (
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >


      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.DailyRestrictions.account_number")}</Th>
            <Th>{t("pages.DailyRestrictions.account_name")}</Th>
            <Th>{t("pages.DailyRestrictions.debtor")}</Th>
            <Th>{t("pages.DailyRestrictions.Creditor")}</Th>
            <Th>{t("pages.DailyRestrictions.Description")}</Th>
          </Tr>
        </Thead>


        <Tbody>

          {data[0]?.JournalDetail?.map((el, index) => (
            <Tr style={{ cursor: "pointer" }}>
              <Td>
                <Checkbox></Checkbox>
              </Td>
              <Td>
                {el.AccountNumber}
              </Td>
              <Td>
                {el.AccountNameAr}
              </Td>
              <Td>
                {el.Debit}
              </Td>
              <Td>
                {el.Credit}
              </Td>
              <Td>
                {el.Row_Description}
              </Td>
            </Tr>
          ))}







        </Tbody>
     
      </Table>

    </Box>
  );
}

export default DailyRestrictionDataTable;
