import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReciveingOnlineOrders } from '../services/onlineOrderService';
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import OnlineOrdersTable from '../components/OnlineOrdersTable';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';

export default function OnlineOrdering (){

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 57);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const data = useSelector(state => state.RecivingOrders);

    useEffect(()=>{
        dispatch(getReciveingOnlineOrders())
    },[dispatch])

    if(ManageBranches===false  ){
      return(
        <NotAuthorizedCompo/>
      )
    }
    

  return <>
      <Box
        bg="light" marginInline={-6} marginTop={-6} padding={6}
        borderTop="1px solid" borderColor="border" marginBottom={6}
      >
          <Flex alignItems="center" justifyContent="space-between" gap={4}>
              <Text fontSize="1.2rem" color="dark" textTransform="capitalize"> {t("pages.online_Orders.online_ordering_setting")} </Text>
          </Flex>
      </Box>


    <div>
    {
        data.isLoading?(
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ):(
          <OnlineOrdersTable data={data.data} />
        )
      }
    </div>
  </>;
};