import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr , useToast} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import AddProductionItemsModal from "./modals/AddProductionItemsModal";
import ImportProductionItemsModal from "./modals/ImportProductionItemsModal";
import EditProductionQuantitiesModal from "./modals/EditProductionQuantitiesModal";
import { useDispatch, useSelector } from "react-redux";
import { getProductionDetails } from "../services/productionService";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 


const ProductionItems = ({ subGroup, id}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();  
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [showAddItems, setShowAddItems] = useState(false);
  const [showImportItems, setShowImportItems] = useState(false);
  const [showEditQuantities, setShowEditQuantities] = useState(false);

  const productionData = useSelector((state) => state.productions);

  useEffect(() => {
    dispatch(getProductionDetails(id));  
  }, [dispatch, id]);


  /* -----------------------------------Privilige-------------------------------*/
  const retrievedArray =useDecryptedPrivilege()
  const ManageProduction  = checkIdExists(retrievedArray, 26);

  if(showAddItems === true && ManageProduction === false){
    toast2({ description:  t("general.authorization")});
    setShowAddItems(false);
  };
  if(showEditQuantities === true && ManageProduction === false){
    toast2({ description:  t("general.authorization")});
    setShowEditQuantities(false);
  };
/* -----------------------------------Privilige-------------------------------*/

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("general.items")}
        </Text>

        <Flex gap={4}>
          {
            productionData?.data1[0]?.Status === "Closed" ? 
            ""
            :
            <>
                {
                  productionData?.data2?.length > 0 ? 
                    <GButton
                      title={t("pages.transfers_orders.edit_quantities")}
                      bg="light"
                      color="text"
                      fontSize="14px"
                      border="1px"
                      borderColor={"#CBD5E0"}
                      hover={{ bg: "light" }}
                      opacity="0.7"
                      onClick={() => setShowEditQuantities(true)}
                    />
                  :
                  "" 
                }
                  <GButton
                  title={t("pages.purchases.add_items")}
                  bg="light"
                  color="text"
                  fontSize="14px"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  hover={{ bg: "light" }}
                  opacity="0.7"
                  onClick={() => setShowAddItems(true)}
                />

                {/* <GButton
                  title={t("general.import_items")}
                  bg="light"
                  color="text"
                  fontSize="14px"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  hover={{ bg: "light" }}
                  opacity="0.7"
                /> */}
            </>
          }
        </Flex>
      </Flex>

      {subGroup.isLoading ? (
        <Spinner />
      ) : subGroup.data2?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : subGroup.data2?.length > 0 ? (
        <Box
          bg="light"
          overflow="auto"
          borderRadius={4}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Table>
            <Thead>
              <Tr>
                <Th>{t("pages.branches.name")}</Th>
                <Th>{t("pages.inventory.sku")}</Th>
                <Th>{t("pages.call_center.quantity")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {subGroup.data2.map((el, index) => (
                <Tr key={index}>
                  <Td>{el?.InventoryItemNameEng}</Td>
                  <Td>{el?.SUK}</Td>
                  <Td>{el?.StorageQuantity}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {showAddItems && (
        <AddProductionItemsModal
          onClose={() => setShowAddItems(false)}
          id={id} />
      )}

      {showImportItems && (
        <ImportProductionItemsModal
          onClose={() => setShowImportItems(false)}
        />
      )}

      {showEditQuantities && (
        <EditProductionQuantitiesModal
          onClose={() => setShowEditQuantities(false)}
          id={id} />
      )}
    </Box>
  );
};

export default ProductionItems;
