import { Box, Stack,Input as ChInput, Flex, useToast  } from '@chakra-ui/react';
import GButton from 'components/ui/button/Button';
import GModal from 'components/ui/modal/Modal'
import { sendOrder } from 'features/orders/services/ordersService';
import routes from 'navigations/routes';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PaymentModal = ({onClose,dataForPaymentModal,printCart}) => {
  //console.log(dataForPaymentModal)
  const { t } = useTranslation();

  const [customerPay,SetCustomerPay]=useState()
  const [showCutomFiled,SetshowCustomFiled]=useState(false)
  const [cutsomerCharge,setcustCharge]=useState()
  const [showCharge,setShowCharge]=useState(false)

const handelCustomerCharge=(num)=>{
  SetCustomerPay(num)
}
 
//console.log(customerPay)

const dispatch=useDispatch()
const navigate =useNavigate()
const toast = useToast({
  position: "top",
  duration: 3000,
  status: "success",
});
const toast3 = useToast({ position: "top", duration: 3000, status: "error" });

const ApplyCharge=()=>{
  const Charge=customerPay-dataForPaymentModal[0].TotalPrice
  if(Charge<0)
  {
    toast3({
      description:t("pages.orders.chargeError") ,
    });
  }else{
    setcustCharge(Charge)
    setShowCharge(true)
  }


}

const pay=()=>{
    dispatch(sendOrder(dataForPaymentModal))
          .unwrap()
          .then((_) => {
            toast({ description: "Item Created" });
          navigate(`/${routes.orders}`);
        });
}


  return (
    <GModal
    onClose={onClose}
    modalHeader="payment"
    modalContent={
      <Box p={4}>
      {
        showCharge?(      
          <>
          <div style={{ width: "100%", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer",marginBottom:"10px",color:"white" }} >{cutsomerCharge}
          </div>
             <Flex style={{justifyContent:"space-between"}}>
             
             <GButton
             onClick={()=>pay()}
             title={t("pages.orders.pay")}
             bg="primary"
             color="light"
             border="1px solid transparent"
             hover={{
               color: "primary",
               borderColor: "primary",
               bg: "none",
             }}
           />


           <GButton
           onClick={()=>printCart()}
           title={t("pages.orders.print_receipt")}
           bg="primary"
           color="light"
           border="1px solid transparent"
           hover={{
             color: "primary",
             borderColor: "primary",
             bg: "none",
           }}
         />
             </Flex>
      
          </>  
          )
          :
          (
            <Stack>
          <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",gap:"15px"}} >
              <div style={{ width: "200px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer",color:"white" }} onClick={()=>handelCustomerCharge(10)}>10</div>
              <div style={{ width: "200px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer" ,color:"white"}} onClick={()=>handelCustomerCharge(50)}>50</div>
            </div>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",gap:"15px"}}>
              <div style={{ width: "200px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer",color:"white" }} onClick={()=>handelCustomerCharge(100)}>100</div>
              <div style={{ width: "200px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer" ,color:"white"}} onClick={()=>handelCustomerCharge(200)}>200</div>
            </div>
          </div>
          <div style={{ width: "100%", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer" ,color:"white"}} onClick={()=>SetshowCustomFiled(!showCutomFiled)}>
          {t("pages.orders.custom")}
        </div>
           
        {
          showCutomFiled&&(
            <ChInput  
            type='number'   
            placeholder={t("pages.orders.custom")}
            onChange={(e)=>SetCustomerPay(e.target.value)}
        />
          )
        }
    
        

    
        
    
       
          <Flex gap={4}>
        
          <GButton
            title={t("general.close")}
            onClick={onClose}
            bg="red.600"
            border="1px solid transparent"
            color="light"
            hover={{
              color: "red.600",
              borderColor: "red.600",
              bg: "none",
            }}
          />
    
          
          <GButton
          onClick={()=>ApplyCharge()}
          title={t("general.save")}
          bg="primary"
          color="light"
          border="1px solid transparent"
          hover={{
            color: "primary",
            borderColor: "primary",
            bg: "none",
          }}
        />
        </Flex>
          </Stack>)
      }
      

      
  
      </Box>
    }/>
  )
}

export default PaymentModal
