import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"

const CTable = ({
  deliveryZones,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.branches.name")}</Th>
            <Th>{t("pages.branches.ref")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {deliveryZones.map((deliveryZone, index) => (
            <Tr key={deliveryZone.BranchDelivZoneId}>
              <Td>{index + 1}</Td>
              <Td>{deliveryZone?.DeliveryZoneName}</Td>
              <Td>{deliveryZone?.RefCode || "-"}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default CTable