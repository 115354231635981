import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* --------------------------------------------- Get Branches In Product ------------------------------------------------*/
export const getBranchesInProducts = createAsyncThunk("products/getBranchesInProducts", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* --------------------------------------------- Get Product Branches ------------------------------------------------*/
export const getBranchesOfProduct = createAsyncThunk("products/getBranchesOfProduct", async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`/Menu/Product/ProductCustomPriceListById?productid={"ProductId":${args}}`);
    //console.log(data);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* --------------------------------------------- Assigen Product To Branch------------------------------------------------*/
  export const assigenProductToBranch = createAsyncThunk("products/assigenProductToBranch",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/ProductCustomPrice/AddProductCustomPrice?productcustomprice=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );