import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

/* ----------------------------------------------------------- Get Inventory Categories List  -------------------------------------------------------*/
export const getInventoryCategoriesList = createAsyncThunk("inventoryCategories/getInventoryCategoriesList",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/GetInventoryCategoriesList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Crate New inventory Categories  -------------------------------------------------------*/
export const createInventoryCategory = createAsyncThunk("inventoryCategories/createInventoryCategory",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/AddInventoryCategories?inventorycategory=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Update Warehouse  -------------------------------------------------------*/
export const updateInventoryCategories = createAsyncThunk("inventoryCategories/updateInventoryCategories",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/UpdateInventoryCategoryById?inventorycategoryid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Delete Inventory Categories  -------------------------------------------------------*/
export const deleteInventoryCategories = createAsyncThunk("inventoryCategories/deleteInventoryCategories",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/DeleteInventoryCategoryById?inventorycategoryid={"InventoryCategoryId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Restore Inventory Categories  -------------------------------------------------------*/
export const restoreInventoryCategories = createAsyncThunk("inventoryCategories/restoreInventoryCategories",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/DeleteInventoryCategoryById?inventorycategoryid={"InventoryCategoryId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getInventoryCategoriesFilteredData = createAsyncThunk("inventoryCategories/getInventoryCategoriesFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetInventoryCategoriesFilteredData = createAsyncThunk( "inventoryCategories/resetInventoryCategoriesFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);