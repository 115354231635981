import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as FiIcons from "react-icons/fi";
import Pagination from "components/ui/pagination/Pagination";
import { Spinner } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const CTable = ({ brands, handleUpdate, handleDelete }) => {
  const { t } = useTranslation();
  const brandsData = useSelector(state => state.brands);
  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (brands, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, brands?.length);
    return brands?.slice(firstIndex, lastIndex);
  };

// Calculate records for activeDiscounts
const brandsTableData = calculateRecords(
    brands,
    currentPageActive,
    recordsPerPage
  );
/*--------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    { 
      brandsData?.isLoading  && ( 
      <Flex p={4}  bg="white"  alignItems="center" justifyContent="center">
          <Spinner /> 
      </Flex>)
    }
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mt={6}>
      {brandsTableData?.map(brand => (
        <Flex key={brand.BrandsId}
          bg="light" p={6} borderRadius={4} justifyContent="space-between"
          boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
        >
          <Flex flexWrap="wrap" gap={6}>
            <Box width="60px" height="60px" bg="bg" />
            <Stack>

              <Text textTransform="capitalize">{brand?.BrandName || "-"}</Text>
              <Text textTransform="capitalize" color="text">{brand?.Value || '-'} Products</Text>
              <Text textTransform="capitalize" color="text">{brand?.Value || '-'} combos</Text>
            </Stack>
          </Flex>
          <Flex justifyContent="space-between">
            <Menu>
              <MenuButton as={Button}
                size="sm"
                bg="none"
                border="1px solid"
                borderColor="border"
                color="text"
                _hover={{ bg: "primary", borderColor: "primary", color: "light" }}
              >
                <Flex alignItems="center" gap={2}>
                  <FiIcons.FiMoreHorizontal size={18} />
                </Flex>
              </MenuButton>
              <MenuList p={0} minW="auto">
                <MenuItem onClick={() => handleUpdate(brand)}>
                  <Flex alignItems="center" justifyContent={"space-between"} width={"100%"} gap={2}>
                    <Text> {t("general.edit")} </Text>
                    <FiIcons.FiEdit />
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => handleDelete(brand)}>
                    <Flex alignItems="center" gap={2}>
                        <Flex alignItems="center" justifyContent={"space-between"} width={"100%"} gap={2}>
                        <Text> {t("general.delete")} </Text>
                            <FiIcons.FiTrash2 color="#C53030" />
                        </Flex>
                    </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      ))}
    </SimpleGrid>
    <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(brands?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(brands?.length / recordsPerPage)
            )
          }
      />
  </>
}

export default CTable;