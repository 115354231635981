import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* --------------------------------------------- Get Purchase Order Suppliers  -------------------------------------------------- */
export const getPurchesOrderSuppliers = createAsyncThunk( "orderPurchase/getSuppliersId", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Inventory/LookupSuppliers`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* --------------------------------------------- Get Purchase Order Suppliers  -------------------------------------------------- */
export const getPurchesOrderBranch = createAsyncThunk("orderPurchase/getPurchesOrderBranch", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


export const addNewPurchaseOrders = createAsyncThunk("orderPurchase/addNewPurchaseOrders",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/PurchaseOrders/AddPurchaseOrders?purchaseorders=${JSON.stringify(args)}`);

        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

/* --------------------------------------------- Purchase Order List  -------------------------------------------------- */
export const getPurchaseOrdersList = createAsyncThunk( "orderPurchase/getPurchaseOrdersList", async (args, thunkApi) => {
    try {
      const {data} = await axios.get(`/Inventory/PurchaseOrders/PurchaseOrdersList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* --------------------------------------------- Purchase Order Details -------------------------------------------------- */
  export const getPurchaseOrdersDetails = createAsyncThunk( "orderPurchase/getPurchaseOrdersDetails", async (args, thunkApi) => {
      try {
        const {data} = await axios.get(`/Inventory/PurchaseOrders/PurchaseOrdersById?purchaseordersid={"PurchaseOrdersId":${(args)}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* --------------------------------------------- Purchase Order Items -------------------------------------------------- */
  export const getPurchaseOrdersitems = createAsyncThunk( "orderPurchase/getPurchaseOrdersitems", async (args, thunkApi) => {
      try {
        const {data} = await axios.get(`/Inventory/PurchaseOrdersItems/ListByPOId?purchaseordersid={"PurchaseOrdersId":${(args)}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* --------------------------------------------- Get Inventory Items To Push in Purchase Order -------------------------------------------------- */
  export const getInventoryItemId = createAsyncThunk( "orderPurchase/getInventoryItemId", async (args, thunkApi) => {
      try {
        const {data} = await axios.get(`/Menu/InventoryItem/LookupInventoryItem`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

/* ------------------------------------------ --- Add  Purchase Order Items -------------------------------------------------- */
  export const addPurchaseOrderItems = createAsyncThunk( "orderPurchase/addPurchaseOrderItems", async (args, thunkApi) => {
      try {
        const {data} = await axios.get(`/Inventory/PurchaseOrdersItems/AddPurchaseOrdersItems?purchaseordersitems=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ---------------------------------------------  Edit  Purchase Order -------------------------------------------------- */
  export const editPurchaseOrderItems = createAsyncThunk( "orderPurchase/editPurchaseOrderItems", async (args, thunkApi) => {

      try {
        const {data} = await axios.get(`/Inventory/PurchaseOrders/EditPurchaseOrders?purchaseordersid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ---------------------------------------------  Edit Quantites Purchase Order Items -------------------------------------------------- */
  export const editQuantityPurchaseOrderItems = createAsyncThunk( "orderPurchase/editQuantityPurchaseOrderItems", async (args, thunkApi) => {

      try {
        const {data} = await axios.get(`/Inventory/PurchaseOrdersItems/EditPurchaseOrdersItems?purchaseordersitems=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ---------------------------------------------  Submit  Purchase Order   -------------------------------------------------- */
  export const submitPurchaseOrderReview = createAsyncThunk( "orderPurchase/submitPurchaseOrderReview", async (args, thunkApi) => {
      try {
        const {data} = await axios.get(`/Inventory/PurchaseOrders/SubmmitPurchaseOrders?purchaseordersid={"PurchaseOrdersId":${(args)}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ---------------------------------------------  Approved / Rejected  Purchase Order -------------------------------------------------- */
  export const approvingRegectedPurchaseOrder = createAsyncThunk( "orderPurchase/approvingRegectedPurchaseOrder", async (args, thunkApi) => {
    try {
      const {data} = await axios.get(`/Inventory/PurchaseOrders/ApprovePurchaseOrders?purchaseordersid={"PurchaseOrdersId":${(args)}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ---------------------------------------------  Delete  Purchase Order Items -------------------------------------------------- */
  export const DeleteItemFromPurchaseOrder = createAsyncThunk( "orderPurchase/DeleteItemFromPurchaseOrder", async (args, thunkApi) => {
      try {
        const {data} = await axios.get(`/Inventory/PurchaseOrders/DeletePurchaseOrdersById?purchaseordersid={"PurchaseOrdersId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const createPurchasing = createAsyncThunk( "orderPurchase/createPurchasing", async (args, thunkApi) => {
      try {
        const {data} = await axios.get(`/Inventory/PurchaseOrders/ApprovePurchaseOrders?purchaseordersid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

    /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
    export const getPurchaseOrderFilteredData = createAsyncThunk("orderPurchase/getPurchaseOrderFilteredData ", async (args, thunkApi) => {
      try {
        return (args);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  export const resetPurchaseOrderFilteredData = createAsyncThunk( "orderPurchase/resetPurchaseOrderFilteredData ", async (args, thunkApi) => { 
      try {
        return args;
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  