import { createSlice } from "@reduxjs/toolkit";
import { getPaymentMethodsTypes } from "../services/paymentMethodsTypesService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null
};

const paymentMethodsTypesSlice = createSlice({
  name: "paymentMethodsTypes",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPaymentMethodsTypes.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getPaymentMethodsTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getPaymentMethodsTypes.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

  }
});

export default paymentMethodsTypesSlice.reducer;