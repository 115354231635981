import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /*  ------------------------------------------------- Add  Item To purchasing ------------------------------------------------ */
  export const addItemToPurchase = createAsyncThunk( "purchasesItems/addItemToPurchase", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/AddPurchasingItems?purchasingitems=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /*  -------------------------------------------------Get purchasing Item List------------------------------------------------ */
  export const getPurchasingItemList = createAsyncThunk("purchasesItems/getPurchasingItemList", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/PurchasingItemsList?purchasingid={"PurchasingId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /*  -------------------------------------------------  Edit purchasing Item Quantity  ------------------------------------------------ */
  export const editItemsQuantity = createAsyncThunk("purchasesItems/editItemsQuantity", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/EditPurchasingItems?purchasingitemsid=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /*  -------------------------------------------------Get purchasing Item List------------------------------------------------ */
  export const deletingPurchasing = createAsyncThunk("purchasesItems/deletingPurchasing", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/Purchasing/DeletePurchasingById?purchasingid={"PurchasingId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /*  ------------------------------------------------- Send Receive Items ------------------------------------------------ */
  export const sendReceiveItems = createAsyncThunk("purchasesItems/sendReceiveItems", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/PurchasingRecived?purchasingid={"PurchasingId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  /*  ------------------------------------------------- get Items Return To Supplier Dropdown ------------------------------------------------ */
  export const getItemsReturnDropdown = createAsyncThunk("purchasesItems/getItemsReturnDropdown", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/SecInventory/ReturnItems?returns={"PurchasingId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /*  ------------------------------------------------- Send Items Return To Supplier ------------------------------------------------ */
  export const sendReturnedItems = createAsyncThunk("purchasesItems/sendReturnedItems", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/SecInventory/AddReturnReq?returns=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  

  