import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";

import { getFilteredData } from "../../services/productsService";
import { getCategories } from "../../../categories/services/categoriesService";
import { getTaxesGroups } from "../../../../management/taxesGroups/services/taxesGroupsService";
import { useEffect } from "react";

const FilterModal = ({ onClose, data }) => {
  console.log("al data", data);

  const [filterCriteria, setFilterCriteria] = useState({
    ProductName: "",
    Ref: "",
    ProductCategoriesNameEng: "",
    ProductPrice: "",
    TaxGroupName: "",
  });

  const [filteredData, setFilteredData] = useState(data);

  // const toast = useToast({
  //   position: "top",
  //   duration: 3000,
  //   status: "success",
  // });

  // lsh htt3dl

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleFilterProducts = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const {
        ProductName,
        Ref,
        ProductCategoriesNameEng,
        ProductPrice,
        TaxGroupName,
      } = filterCriteria;

      console.log("wala", filterCriteria);

      // Create variables to hold filter conditions
      let filterByName = true;
      let filterBySKU = true;
      let filterByProductCategoriesNameEng = true;
      let filterByPrice = true;
      let fileterByTaxGroup = true;

      // Check if the filter criteria are defined, and if so, apply the filter
      if (ProductName) {
        filterByName = item.ProductName.toLowerCase().includes(
          ProductName.toLowerCase()
        );
      }

      if (Ref) {
        filterBySKU = String(item.Ref).trim().includes(Ref);
      }

      if (ProductCategoriesNameEng) {
        filterByProductCategoriesNameEng =
          item.ProductCategoriesNameEng.toLowerCase().includes(
            ProductCategoriesNameEng.toLowerCase()
          );
      }

      if (ProductPrice) {
        filterByPrice = item.ProductPrice === parseInt(ProductPrice);
      }

      if (TaxGroupName) {
        fileterByTaxGroup = item.TaxGroupName.toLowerCase().includes(
          TaxGroupName.toLowerCase()
        );
      }

      // Return true if all conditions are met
      return (
        filterByName &&
        filterBySKU &&
        filterByProductCategoriesNameEng &&
        filterByPrice &&
        fileterByTaxGroup
      );
    });

    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredData(filteredResult));
  };

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const categories = useSelector((state) => state.categories);
  console.log("all categories", categories);

  useEffect(() => {
    dispatch(getTaxesGroups());
  }, [dispatch]);

  const taxesGroups = useSelector((state) => state.taxesGroups);

  const handleClose = () => {
    onClose(filteredData);
  };

  return (
    <GModal
      onClose={handleClose}
      modalHeader={t("pages.products.filter_products")}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleFilterProducts}>
            <Stack spacing={6}>
              <Input
                label={t("pages.gifts_cards.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.name")}
                name="ProductName"
                value={filterCriteria.ProductName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ProductName: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.products.sku")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.products.sku")}
                name="Ref"
                value={filterCriteria.Ref}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Ref: e.target.value,
                  })
                }
              />

              <MultiSelect
                title={t("pages.products.category")}
                data={
                  categories.data &&
                  categories.data.map((el) => ({
                    ...el,
                    label: el.ProductCategoriesNameEng,
                    value: el.ProductCategoriesId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.ProductCategoriesNameEng}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ProductCategoriesNameEng: e.ProductCategoriesNameEng,
                  })
                }
              />

              <MultiSelect
                title={t("pages.products.tax_group")}
                data={
                  taxesGroups.data &&
                  taxesGroups.data.map((el) => ({
                    ...el,
                    label: el.TaxGroupName,
                    value: el.TaxGroupId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.TaxGroupName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    TaxGroupName: e.TaxGroupName,
                  })
                }
              />

              <Input
                type="number"
                label={t("pages.gifts_cards.price")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.gifts_cards.price")}
                name="ProductPrice"
                value={filterCriteria.ProductPrice}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ProductPrice: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={handleClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default FilterModal;
