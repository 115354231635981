import { createSlice } from "@reduxjs/toolkit";
import {getProductions,getProdBranchesDropList, getAddItemDropList, getProductionItems, getProductionDetails, addProductionItem, editProduction, deleteItem, deleteProduction, produceItems, getEditItemsQuan, editProductionItems, resetProductionFilteredData, getProductionFilteredData} from "../services/productionService"


let initialState = {
    isLoading: false,
    // itemDetails:[],
    pages: 0,
    errors: null,
    // deletedItemsData:[],
    data:[],
    data1:[],
    data2:[],
    data3:[],
    branchesDropData:[],
    addItemDropData:[],
    
    filteredData:[],
  };


  const productionSlice = createSlice({
    name: "productions",
    initialState,

    extraReducers:(builder)=>{

      builder.addCase(getProductions.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getProductions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getProductions.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(getProductionDetails.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getProductionDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data1 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getProductionDetails.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(produceItems.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(produceItems.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(produceItems.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(getProductionItems.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getProductionItems.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data2 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getProductionItems.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })



       builder.addCase(getProdBranchesDropList.pending, (state) => {
         state.isLoading = true;
         state.errors = null;
       })

      builder.addCase(getProdBranchesDropList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.branchesDropData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getProdBranchesDropList.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(editProduction.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(editProduction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(editProduction.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(getAddItemDropList.pending, (state) => {
         state.isLoading = true;
         state.errors = null;
       })

      builder.addCase(getAddItemDropList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.addItemDropData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getAddItemDropList.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


    
      builder.addCase(addProductionItem.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(addProductionItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(addProductionItem.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      // //

      // builder.addCase(getItemDetails.pending, (state) => {
      //   state.isLoading = true;
      //   state.errors = null;
      // })

      // builder.addCase(getItemDetails.fulfilled, (state, { payload }) => {
      //   state.isLoading = false;
      //   state.itemDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //   state.errors = null;
      // })

      // builder.addCase(getItemDetails.rejected, (state) => {
      //   state.isLoading = false;
      //   state.errors = [{ msg: "something went wrong!" }];
      // })

      // //


      //
      builder.addCase(deleteProduction.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(deleteProduction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(deleteProduction.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      
      builder.addCase(getEditItemsQuan.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getEditItemsQuan.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data3 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getEditItemsQuan.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(editProductionItems.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(editProductionItems.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(editProductionItems.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
   /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
   builder.addCase(getProductionFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getProductionFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getProductionFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetProductionFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetProductionFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetProductionFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* --------------------------------------------------------------------------------------------------- */

    }
  })


  export default productionSlice.reducer