import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* -------------------------------------------- Get inventory Control Reports List ------------------------------------ */
export const getInventoryControlList = createAsyncThunk(
  "reportsInventoryControl/getInventoryControlList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Reports/GetInventoryControlReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* --------------------------------------- Menu inventory Control Filter Data ------------------------------*/

export const getInventoryControlFilteredData = createAsyncThunk(
  "reportsInventoryControl/getInventoryControlFilteredData",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetInventoryControlFilteredData = createAsyncThunk(
  "reportsInventoryControl/resetInventoryControlFilteredData",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
