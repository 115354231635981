import React, { useEffect, useState } from "react";
import DetailsTableCoupon from "./../components/DetailsTableCoupon";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { activateCoupon, getCouponById } from "../services/couponsService";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, Stack, Button, Text, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import UpdateModal from "./../components/modals/UpdateModal";
import DeleteCoupons from "../components/DeleteCoupons";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


export default function CouponsDetails() {
  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 40);

  const dispatch = useDispatch();
  const id = useParams();
  const { t } = useTranslation();
  const coupon = useSelector((state) => state.coupons);
  console.log("coupon",coupon);

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    dispatch(getCouponById(id.id));
  }, [dispatch, id.id]);

  const activeFunction = () => {
    dispatch(activateCoupon(id.id))
      .unwrap()
      .then((_) => {
        dispatch(getCouponById(id.id));
      });
  };

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return coupon.isLoading ? (
    <Spinner />
  ) : (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <Box as="p" fontSize="1.6rem" textTransform="capitalize">
              {coupon?.couponDetails?.CouponName || "-"}
            </Box>
            <Button
              bg={coupon?.couponDetails?.IsActive ? "#00D1D1" : "red"}
              color="light"
              _hover={"none"}
              style={{ cursor: "default" }}
            >
              <Text>
                {coupon?.couponDetails?.IsActive
                  ? t("general.activated")
                  : t("general.inactive")}
              </Text>
            </Button>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <GButton
              title={
                coupon?.couponDetails?.IsActive
                  ? t("general.deactive")
                  : t("general.active")
              }
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() => activeFunction()}
            />
            <GButton
              title={t("pages.items.edit")}
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() => setShowUpdateModal(true)}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        <DetailsTableCoupon data={coupon?.couponDetails} />
        <DeleteCoupons idOfCoupon={id.id}></DeleteCoupons>
      </Stack>

      {showUpdateModal && (
        <UpdateModal
          onClose={() => setShowUpdateModal(false)}
          data={coupon?.couponDetails}
        />
      )}
    </>
  );
}
