import { Box, FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";
import Select from "react-select";
import "./style.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const MultiSelect = ({
  title,
  isMulti,
  defaultValue,
  data,
  onChange,
  isDisabled,
  isRequired,
}) => {
  const [t,i18n]=useTranslation()

  //console.log(data)

  const [isValid, setIsValid] = useState(false);

  const handleSelectionChange = (selectedOptions) => {
    onChange(selectedOptions);
    setIsValid(selectedOptions.length > 0);
  };

  return (
    <FormControl isRequired={isRequired} isInvalid={!isValid}>
      <FormLabel textTransform="capitalize" display="flex" gap={2}>
        {title}
        {/* {isRequired && (
          <Box fontSize={18} color="red">
            *
          </Box>
        )} */}
      </FormLabel>
      <Select
        w
        placeholder={i18n.language === "en"? "Select..." : "اختر ..."}
        isRequired={isRequired}
        closeMenuOnSelect={true}
        isMulti={isMulti}
        defaultValue={defaultValue}
        options={data}
        onChange={handleSelectionChange}
        className="react-select-container"
        classNamePrefix="react-select"
        isDisabled={isDisabled}
      />
    </FormControl>
  );
};

export default MultiSelect;
