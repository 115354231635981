import React, { useEffect, useState } from "react";
import { Box, Flex, Stack, Text, Image, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "./EmployeeDetailsPage.css";
import BasicInfoCompo from "../components/employeDataCompo/BasicInfoCompo";
import NationalIdCompo from "../components/employeDataCompo/NationalIdCompo";
import JobDetailsCompo from "../components/employeDataCompo/JobDetailsCompo";
import ContactInformationCompo from "../components/employeDataCompo/ContactInformationCompo";
import EmergencyContactCompo from "../components/employeDataCompo/EmergencyContactCompo";
import ExtraInformationCompo from "../components/employeDataCompo/ExtraInformationCompo";
import AttendanceCompo from "../components/employeDataCompo/AttendanceCompo";
import ContractCompo from "../components/employeDataCompo/ContractCompo";
import LeaveOrdersCompo from "../components/employeDataCompo/LeaveOrdersCompo";
import PermissionOrdersCompo from "../components/employeDataCompo/PermissionOrdersCompo";
import {
  getEmployeeById,
  getMartialStatus,
  getNationalityDropdown,
  getRecruitmentSituation,
  getReligion,
} from "../services/employeeServices";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getClassification } from "features/hr/VacationList/services/vacationServices";
import {
  getPositionDropdown,
  getReportingToPositionDropdown,
} from "features/hr/Positions/services/positionsServices";
import { getInsuranceOffice } from "../services/generalEmployeeServices";

const EmployeeDetailsPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [pageDetails, setPageDetails] = useState("basic");
  const { classifications } = useSelector((state) => state.vacationSlice);

  const { PositionDropdown, ReportingToPositionDropdown } = useSelector(
    (state) => state.positionsSlice
  );
  const { InsuranceOffice } = useSelector((state) => state.generalEmployee);
  const {
    MartialStatusDropDown,
    ReligionDropDown,
    RecruitmentSituationDropDown,
    nationalityDropDown,
    employeeDetails,
    attendanceList,
    absenceList,
    employeeContractList,
    employeeVacationsList,
    employeePermessionsList,
    isLoadingDetails,
    isLoading,
  } = useSelector((state) => state?.employeeSlice);
  const details = employeeDetails[0];

  useEffect(() => {
    dispatch(getEmployeeById(params?.id));
  }, [dispatch, params?.id]);

  useEffect(() => {
    dispatch(getClassification());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMartialStatus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getReligion());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRecruitmentSituation());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNationalityDropdown());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPositionDropdown());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInsuranceOffice());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getReportingToPositionDropdown());
  }, [dispatch]);

  if (isLoadingDetails) {
    return (
      <Flex
        bg="light"
        p={4}
        gap={2}
        justifyContent="center"
        flexDir={"column"}
        alignItems={"center"}
      >
        <Spinner />
        <Text fontWeight={"bold"}> {t("general.loading")} </Text>
      </Flex>
    );
  }

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Stack spacing={8}>
        <div
          style={{ display: "flex", padding: "25px", gap: "15px" }}
          className="MobileViewEmployee"
        >
          <Box width={"250px"}>
            <Box width="100%" marginBottom={3}>
              <Image
                borderRadius={5}
                objectFit="cover"
                src="https://tse4.mm.bing.net/th?id=OIP.tGZ5kxcOjgqCQz8twG27mQHaFj&pid=Api&P=0&h=220"
                alt="Dan Abramov"
              />
            </Box>

            <Flex
              flexDirection={"column"}
              onClick={() => setPageDetails("basic")}
            >
              <Flex
                gap={2}
                justifyContent={"start"}
                alignItems={"center"}
                height="50px"
                padding={2}
                borderTopRadius={3}
                bg={pageDetails === "basic" ? "blue.500" : "gray.300"}
                textColor={pageDetails === "basic" ? "gray.100" : "gray.700"}
                fontWeight={"semibold"}
                borderBottom={"1px"}
                borderColor={"white"}
              >
                <Text>{t("pages.hr.basic_information")}</Text>
              </Flex>
            </Flex>

            <Flex
              flexDirection={"column"}
              onClick={() => setPageDetails("Attendance")}
            >
              <Flex
                gap={2}
                justifyContent={"start"}
                alignItems={"center"}
                height="50px"
                padding={2}
                bg={pageDetails === "Attendance" ? "blue.500" : "gray.300"}
                textColor={
                  pageDetails === "Attendance" ? "gray.100" : "gray.700"
                }
                fontWeight={"semibold"}
                borderBottom={"1px"}
                borderColor={"white"}
              >
                <Text>{t("pages.hr.Attendance")}</Text>
              </Flex>
            </Flex>

            <Flex
              flexDirection={"column"}
              onClick={() => setPageDetails("contract")}
            >
              <Flex
                gap={2}
                justifyContent={"start"}
                alignItems={"center"}
                height="50px"
                padding={2}
                bg={pageDetails === "contract" ? "blue.500" : "gray.300"}
                textColor={pageDetails === "contract" ? "gray.100" : "gray.700"}
                fontWeight={"semibold"}
                borderBottom={"1px"}
                borderColor={"white"}
              >
                <Text>{t("pages.hr.contract")}</Text>
              </Flex>
            </Flex>

            <Flex
              flexDirection={"column"}
              onClick={() => setPageDetails("leave_orders")}
            >
              <Flex
                gap={2}
                justifyContent={"start"}
                alignItems={"center"}
                height="50px"
                padding={2}
                bg={pageDetails === "leave_orders" ? "blue.500" : "gray.300"}
                textColor={
                  pageDetails === "leave_orders" ? "gray.100" : "gray.700"
                }
                fontWeight={"semibold"}
                borderBottom={"1px"}
                borderColor={"white"}
              >
                <Text>{t("pages.hr.leave_orders")}</Text>
              </Flex>
            </Flex>

            <Flex
              flexDirection={"column"}
              onClick={() => setPageDetails("Permission_Orders")}
            >
              <Flex
                gap={2}
                justifyContent={"start"}
                alignItems={"center"}
                borderBottomRadius={3}
                height="50px"
                padding={2}
                bg={
                  pageDetails === "Permission_Orders" ? "blue.500" : "gray.300"
                }
                textColor={
                  pageDetails === "Permission_Orders" ? "gray.100" : "gray.700"
                }
                fontWeight={"semibold"}
                borderBottom={"1px"}
                borderColor={"white"}
              >
                <Text>{t("pages.hr.Permission_Orders")}</Text>
              </Flex>
            </Flex>

            <Flex
              flexDirection={"column"}
              onClick={() => setPageDetails("extra")}
            >
              <Flex
                gap={2}
                justifyContent={"start"}
                alignItems={"center"}
                borderBottomRadius={3}
                height="50px"
                padding={2}
                bg={pageDetails === "extra" ? "blue.500" : "gray.300"}
                textColor={pageDetails === "extra" ? "gray.100" : "gray.700"}
                fontWeight={"semibold"}
                borderBottom={"1px"}
                borderColor={"white"}
              >
                <Text>{t("pages.hr.Extra_information")}</Text>
              </Flex>
            </Flex>
          </Box>

          <div style={{ width: "100%" }}>
            {pageDetails === "basic" ? (
              <>
                <BasicInfoCompo
                  id={params?.id}
                  details={details}
                  classifications={classifications}
                  MartialStatusDropDown={MartialStatusDropDown}
                  ReligionDropDown={ReligionDropDown}
                  RecruitmentSituationDropDown={RecruitmentSituationDropDown}
                  isLoading={isLoading}
                />
                <NationalIdCompo
                  details={details}
                  nationalityDropDown={nationalityDropDown}
                  isLoading={isLoading}
                />
                <JobDetailsCompo
                  details={details}
                  PositionDropdown={PositionDropdown}
                  ReportingToPositionDropdown={ReportingToPositionDropdown}
                  isLoading={isLoading}
                />
                <ContactInformationCompo
                  details={details}
                  isLoading={isLoading}
                />
                <EmergencyContactCompo
                  details={details}
                  isLoading={isLoading}
                />
              </>
            ) : pageDetails === "extra" ? (
              <ExtraInformationCompo
                details={details}
                InsuranceOffice={InsuranceOffice}
                isLoading={isLoading}
              />
            ) : pageDetails === "Attendance" ? (
              <AttendanceCompo
                details={details}
                attendanceList={attendanceList}
                absenceList={absenceList}
                isLoading={isLoading}
              />
            ) : pageDetails === "contract" ? (
              <ContractCompo
                id={params?.id}
                contractsList={employeeContractList}
                isLoading={isLoading}
              />
            ) : pageDetails === "leave_orders" ? (
              <LeaveOrdersCompo
                id={params?.id}
                employeeVacationsList={employeeVacationsList}
                isLoading={isLoading}
              />
            ) : pageDetails === "Permission_Orders" ? (
              <PermissionOrdersCompo
                id={params?.id}
                employeePermessionsList={employeePermessionsList}
                isLoading={isLoading}
              />
            ) : null}
          </div>
        </div>
      </Stack>
    </Box>
  );
};

export default EmployeeDetailsPage;
