import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getReservationFilteredData } from '../../services/reservationsService';

export default function FilterModal({onClose , data }) {
    const { t } = useTranslation();
    const dispatch =useDispatch();

    const [filteredData, setFilteredData] = useState(data);

    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
        BranchName: ""      
      });

      const handleFilterReservation = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const { BranchName } = filterCriteria;
    
          let filterBranchName = true;
    
          if (BranchName) {
            filterBranchName = item.BranchName.toLowerCase().includes(BranchName.toLowerCase());
          }
    

          return (
            filterBranchName 
          );

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getReservationFilteredData(filteredResult));
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.cost_adjustment.cost_adjustment_filter")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterReservation}
          >
            <Stack spacing={6}>
                  <Input
                    label={t("pages.cost_adjustment.branch")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={t("pages.cost_adjustment.branch")}
                    name="BranchName"
                    value={filterCriteria.BranchName}
                    onChange={(e) =>
                      setFilterCriteria({
                        ...filterCriteria,
                        BranchName: e.target.value,
                      })
                    }
                  />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
