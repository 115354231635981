import React from 'react';
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from 'react-redux';
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from "navigations/routes";
import { deletingPurchasing } from '../../services/PurchasingItemsService';
import { getPurchasesList } from '../../services/purchaseService';

const DeletePurchaseModal = ({onClose,id}) => {
  const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  return <>

      <GDeleteModal
        onClose={onClose}
        onSubmit={()=>{
          dispatch(deletingPurchasing(id)).unwrap().then(_ => {
            toast({ description: t("pages.purchases.purchase_deleted") });
            onClose();
            dispatch(getPurchasesList());
            navigate("/inventory/purchases");
          })
        }}
        
      />
  </>
}

export default DeletePurchaseModal;
