import { createSlice } from "@reduxjs/toolkit";
import { getOrderTransactions, getOrderTransactionsFilteredData, resetOrderTransactionsFilteredData } from "../services/OrderTransactionServices";

let initialState = {
    isLoading: false,
    data: [],
    errors: null,

    filteredData:[],
  };

  const OrderTransactionsSlice = createSlice({
    name: "OrderTransactions",
    initialState,
    extraReducers:(builder)=>{
    /* ---------------------------------------------------- Get Order Transactions  ------------------------------------------------- */
      builder.addCase(getOrderTransactions.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getOrderTransactions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getOrderTransactions.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


         /* ----------------------------------------- Filter Data --------------------------------------------- */
    builder.addCase(getOrderTransactionsFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getOrderTransactionsFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getOrderTransactionsFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetOrderTransactionsFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetOrderTransactionsFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetOrderTransactionsFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    }
  })

  export default OrderTransactionsSlice.reducer;