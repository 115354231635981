import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getWarehouseFilteredData } from '../../services/WarehouseServices';

export default function FilterModal({onClose , data }) {
    const { t } = useTranslation();
    const dispatch =useDispatch();

    const [filteredData, setFilteredData] = useState(data);

    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
        Name: "",
        NameLoc: "",
      });

      const handleFilterWarehouse = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const { Name , NameLoc } = filterCriteria;
    
          let filterName = true;
          let filterNameLoc = true;
    
          if (Name) {
            filterName = item.Name.toLowerCase().includes(Name.toLowerCase());
          }
    
          if (NameLoc) {
            filterNameLoc = item.NameLocalized.toLowerCase().includes(NameLoc.toLowerCase());
          }

          return (
            filterName &&
            filterNameLoc 
          );

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getWarehouseFilteredData(filteredResult));
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.warehouse.warehouse_filter")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterWarehouse}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.warehouse.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.warehouse.name")}
                name="Name"
                value={filterCriteria.Name}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Name: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.warehouse.name_Localized")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.warehouse.name_Localized")}
                name="NameLoc"
                value={filterCriteria.NameLoc}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    NameLoc: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
