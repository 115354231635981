import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const login = createAsyncThunk(
  "auth/login",
  async (args, thunkApi) => {
    try {
      const {data} = await axios.post("/api/Users/TryLogin", args);
      //console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue({ message: "invaild login, please try again" });
    }
  }
);

/* --------------------------------------------------------------------------- */
export const signUp = createAsyncThunk(
  "auth/signUp",
  async (args, thunkApi) => {
    try {
      const {data} = await axios.post("/api/Users/TryLogin", args);
      //console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue({ message: "invaild login, please try again" });
    }
  }
);