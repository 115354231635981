import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { addBranchToGroup, getBranchesInTaxesGroup } from "../../services/branchesToGroupsService";
import { getTaxesGroups } from "../../services/taxesGroupsService";

const AddBranchToGroupModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top",duration: 3000, status: "success",});
  const toast2 = useToast({ position: "top",duration: 3000, status: "error",});
  const { handleSubmit, setValue } = useForm();

  const branches = useSelector((state) => state.branchGroups);

  useEffect(() => {
    dispatch(getBranchesInTaxesGroup());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.taxes.add_to_branches")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.TaxGroupId = null ;
              if(values.BranchId === undefined) {
                toast2({ description: t("validation.choose_first")});
              }
              else {
                values.BranchId = [{"BranchId":values.BranchId?.BranchId}];
                dispatch(addBranchToGroup(values))
                .unwrap()
                .then((_) => {
                  toast({description: t("pages.taxes.branch_added_to_tax_group"), });
                  dispatch(getTaxesGroups());
                  onClose();
                });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                data={
                  branches.TaxesGroupsBranches &&
                  branches.TaxesGroupsBranches.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))
                }
                isRequired
                title={t("pages.taxes.branches")}
                onChange={(e) => setValue("BranchId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.taxes.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={data.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={data.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddBranchToGroupModal;
