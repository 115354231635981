import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import VacationListTabel from "../components/VacationListTabel";
import CreateVacationModal from "../components/modal/CreateVacationModal";
import {
  getClassification,
  getOfficialVacationList,
} from "../services/vacationServices";
import { useDispatch, useSelector } from "react-redux";
import ErrorHandelText from "components/ui/errorHandel/ErrorHandelText";
import NoDataFound from "components/ui/noDataFound/NoDataFound";

const VacationListPage = () => {
  const UserId = localStorage.getItem("userId");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAddModal, setShowAddModal] = useState(false);

  const data = useSelector((state) => state.vacationSlice);

  useEffect(() => {
    dispatch(getClassification());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getOfficialVacationList(UserId));
  }, [dispatch, UserId]);

  return (
    <Box>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.Official_Vacation_List")}
          </Text>

          <Flex gap={2}>
            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => setShowAddModal(true)}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.hr.new_Vacation")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>

      {/* --------------------------------------------------------------------------------------*/}
      {data?.isLoading ? (
        <Flex
          bg="light"
          p={4}
          gap={2}
          justifyContent="center"
          flexDir={"column"}
          alignItems={"center"}
        >
          <Spinner />
          <Text fontWeight={"bold"}> {t("general.loading")} </Text>
        </Flex>
      ) : data?.errors ? (
        <Flex bg="light" p={4} justifyContent="center">
          <ErrorHandelText />
        </Flex>
      ) : data?.leaveReportData?.length === 0 ? (
        <NoDataFound />
      ) : (
        <VacationListTabel
          data={data?.vacationList}
          classifications={data?.classifications}
        />
      )}

      {/* ------------------------  Show Add  Modals ------------------------*/}
      {showAddModal && (
        <CreateVacationModal
          onClose={() => setShowAddModal(false)}
          classifications={data.classifications}
        />
      )}
    </Box>
  );
};

export default VacationListPage;
