import { createSlice } from "@reduxjs/toolkit";
import {
  getCharges,
  createCharge,
  updateCharge,
  deleteCharge,
  getChargesFilteredData,
  resetChargesFilteredData
} from "../services/chargesService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,

  filteredData : []
};

const chargesSlice = createSlice({
  name: "charges",
  initialState,
  extraReducers: (builder) => {

    /* -------------------------------------------------- Get All Charges ----------------------------------------------------- */
    builder.addCase(getCharges.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCharges.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getCharges.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* -------------------------------------------------- Create New Charge ----------------------------------------------------- */
    builder.addCase(createCharge.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createCharge.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(createCharge.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* -------------------------------------------------- Update Charge ----------------------------------------------------- */
    builder.addCase(updateCharge.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateCharge.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(updateCharge.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* -------------------------------------------------- Delete Charge ----------------------------------------------------- */
    builder.addCase(deleteCharge.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteCharge.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(deleteCharge.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
    /* ----------------------------------------- Filter Data --------------------------------------------- */
  builder.addCase(getChargesFilteredData.pending, (state) => {
  state.isLoading = true;
  state.errors = null;
  });
  builder.addCase(getChargesFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getChargesFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
  /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetChargesFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetChargesFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetChargesFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
     /* --------------------------------------------------------------------------------------------------- */
  }
});

export default chargesSlice.reducer;