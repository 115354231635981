import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { Button, Flex, Text, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react"
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import NoReservations from "../components/NoReservations";
import CreateModal from "../components/modals/CreateModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import { getReservationsList, resetReservationFilteredData } from "../services/reservationsService";
import ExportModal from 'components/ui/modal/ExportModal';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Reservations = () => {
  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 56);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showCreateReservationModal, setShowCreateReservationModal] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [exportModal,SetExportModal]=useState(false);

  const reservations = useSelector(state => state.reservations);
  // console.log(reservations);

  const resetFunction = () => {
      dispatch(resetReservationFilteredData());
    };

  useEffect(() => {
    dispatch(getReservationsList());
  }, [dispatch]);

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }
  
  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.reservations.reservations")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
                  type="button" bg="none" border="1px solid" borderColor="border"
                  textTransform="capitalize" fontSize="1rem" color="text"
                  _hover={{ bg: "primary", color: "light" }}
                  onClick={() => SetExportModal(true)}
                >
                    <Text>{t("general.export")}</Text>
                </Button>

            <GButton
              title={t("pages.reservations.add_branches")}
              onClick={() => setShowCreateReservationModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />

          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
      >
        <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom="2px solid"
            borderColor="primary"
          />
        </Flex>

            <GButton
                title={
                    reservations?.filteredData?.length === 0
                    ? t("general.filter")
                    : t("general.clear_filter")
                }
                border="1px solid"
                borderColor="border"
                postfixElement={
                    reservations?.filteredData?.length === 0 ? (
                    <FiIcons.FiFilter size={16} />
                    ) : (
                    <IoCloseOutline fontWeight={800} size={20} />
                    )
                }
                hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                onClick={
                    reservations?.filteredData?.length === 0
                    ? () => setShowFilterModal(true)
                    : resetFunction
                }
            />

      </Flex>

      {reservations.isLoading ? (
              <Flex bg="light" p={4} justifyContent="center">
                <Spinner />
              </Flex>
            ) : reservations?.data.length === 0 ? (
              <NoReservations
                onClickCreateReservationBtn={() => setShowCreateReservationModal(true)}
              />
            ) : reservations?.filteredData.length > 0 ? (
              <Table
                data={reservations?.filteredData}
              />
            )
            : (
              <Table
                data={reservations?.data}
              />
            )
      }



      {showCreateReservationModal && (
        <CreateModal
          onClose={() => setShowCreateReservationModal(null)}
        />
      )}

      {exportModal&&(
                <ExportModal 
                  onClose={() => SetExportModal(false)}   
                  data={reservations.data}
                  fileName={"reservation"}
                  >
                </ExportModal>
        )}

      {
        showFilterModal && (
            <FilterModal 
                onClose={()=>setShowFilterModal(false)} 
                data={reservations.data} 
            />
        )
      }

    </>
  )
};

export default Reservations;