import React, { useEffect, useState } from 'react';
import { Button, Flex, Text} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ExportModal from 'components/ui/modal/ExportModal';
import WarehouseTable from '../components/WarehouseTable';
import CreateWarehouse from '../components/Modal/CreateWarehouse';
import { getWarehouseList } from '../services/WarehouseServices';


export default function Warehouse() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const warehouse = useSelector((state) => state.warehouse);

  const [showCreatWarehouse, setShowCreatWarehouse] = useState(false);
  const [exportModal,SetExportModal]=useState(false);

  useEffect(() => {
    dispatch(getWarehouseList());
  }, [dispatch]);
  
  

  return <>
  <BreadCrumb>
      <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize" >
            {t("pages.warehouse.warehouse")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
                <Button
                  type="button" bg="none" border="1px solid" borderColor="border"
                  textTransform="capitalize" fontSize="1rem" color="text"
                  _hover={{ bg: "primary", color: "light" }}
                  onClick={() => SetExportModal(true)}
                >
                    <Text>{t("general.export")}</Text>
                </Button>

                <GButton
                  title={t("pages.warehouse.create_warehouse")}
                  onClick={() => setShowCreatWarehouse(true)}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ bg: "none", borderColor: "primary", color: "primary" }}
                />
          </Flex>
      </Flex>
    </BreadCrumb>


    <WarehouseTable  data={warehouse.data} filteredData={warehouse.filteredData} />


    {exportModal&&(
      <ExportModal 
        onClose={() => SetExportModal(false)}   
        data={warehouse.data}
        fileName={"WareHouse"}
        >
      </ExportModal>
    )}

    { showCreatWarehouse && 
      <>
          <CreateWarehouse
              onClose={()=>setShowCreatWarehouse(false)}
          > </CreateWarehouse>
      </>
    }
  
  </>
}
