import React from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import Input from "components/form/Input";
import { Controller, useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import { useDispatch } from "react-redux";
import { addComboSize, getCombosSize } from "../../services/comboSizeService";
import { useParams } from "react-router-dom";

const AddSizeModal = ({ onClose }) => {
  const id = useParams();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.combos.add_size")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              values.ComboId = id.comboId;
              dispatch(addComboSize(values))
                .unwrap()
                .then((_) => {
                  toast({ description: t("pages.combos.combo_created") });
                  dispatch(getCombosSize(id.comboId));
                  onClose();
                });
            })}
          >
            <Stack spacing={6}>
              <FormControl isInvalid={errors.ComboSizeName}>
                <FormLabel
                  htmlFor="ComboSizeName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.categories.name")}
                </FormLabel>
                <Controller
                  name="ComboSizeName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.categories.name")}
                      onChange={(e) => {
                        handleInputChange(e, "ComboSizeName");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.ComboSizeName && errors.ComboSizeName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.ComboSizeNameLoc}>
                <FormLabel
                  htmlFor="ComboSizeNameLoc"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.categories.name_localized")}
                </FormLabel>
                <Controller
                  name="ComboSizeNameLoc"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.categories.name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "ComboSizeNameLoc");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.ComboSizeNameLoc && errors.ComboSizeNameLoc.message}
                </FormErrorMessage>
              </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.inventory_item_details.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddSizeModal;
