import { createSlice } from "@reduxjs/toolkit";
import {getAllDeliveryZone,getAllBranchesDeliverZone,createDeliverZone,getDeliveryZoneDetails,editDeliveryZone,deleteDeliveryZone,getDeletedDeliveryZone, getFilteredDataDeliveryZone, resetFilteredDataDeliveryZone} from "../services/deliveryZoneNewService"

let initialState = {
    isLoading: false,
    data: [],
    branchesDeliverZone:[],
    deliveryZoneDetails:[],
    deletedDelvieryZone:[],
    filteredData:[],
    pages: 0,
    errors: null,
  };


  const deliveryZoneNewSlice = createSlice({
    name: "deliveryZoneNew",
    initialState,
    extraReducers:(builder)=>{

        
      builder.addCase(getAllDeliveryZone.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getAllDeliveryZone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.data)
        state.errors = null;
      })

      builder.addCase(getAllDeliveryZone.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })



      //


              
      builder.addCase(getAllBranchesDeliverZone.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getAllBranchesDeliverZone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.branchesDeliverZone = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.branchesDeliverZone)
        state.errors = null;
      })

      builder.addCase(getAllBranchesDeliverZone.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //


      builder.addCase(createDeliverZone.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(createDeliverZone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        //state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.data)
        state.errors = null;
      })

      builder.addCase(createDeliverZone.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //


      
      builder.addCase(getDeliveryZoneDetails.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getDeliveryZoneDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.deliveryZoneDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.deliveryZoneDetails)
        state.errors = null;
      })

      builder.addCase(getDeliveryZoneDetails.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //



      builder.addCase(editDeliveryZone.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(editDeliveryZone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(editDeliveryZone.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //


      builder.addCase(deleteDeliveryZone.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(deleteDeliveryZone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(deleteDeliveryZone.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //

      

      
      builder.addCase(getDeletedDeliveryZone.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getDeletedDeliveryZone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.deletedDelvieryZone = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.deletedDelvieryZone)
        state.errors = null;
      })

      builder.addCase(getDeletedDeliveryZone.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //

      builder.addCase(getFilteredDataDeliveryZone.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(getFilteredDataDeliveryZone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        state.errors = null;
      });
      builder.addCase(getFilteredDataDeliveryZone.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
  
      builder.addCase(resetFilteredDataDeliveryZone.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(resetFilteredDataDeliveryZone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.filteredData = [];
        state.errors = null;
      });
      builder.addCase(resetFilteredDataDeliveryZone.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });


    }
  })

  
  export default deliveryZoneNewSlice.reducer