import React from 'react';
import { useState } from "react";
import { useTranslation } from "react-i18next"
import { Flex, Text, useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import DeleteitemModal from './modals/DeleteitemModal';
import { checkIdExists } from 'utils/cheackAuth';
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const DeleteItem = ({id}) => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageItems = checkIdExists(retrievedArray, 10);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

     // {showDeleteBranchModal && (
    //   <DeleteModal
    //     onClose={() => setShowDeleteBtanchModal(null)}
    //   />
    // )}

    //console.log(id)
    
    const { t } = useTranslation();

    const [showDeleteBranchModal, setShowDeleteBtanchModal] = useState(null);

    if(showDeleteBranchModal===true && ManageItems===false){
      toast2({ description:  t("general.authorization")})
      setShowDeleteBtanchModal(false)
    }

  return (
    <div>
        <Flex
    bg="light" borderRadius={6} alignItems="center" justifyContent="space-between" gap={4} p={4}
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >
    <Text fontSize=".9rem" color="text">
      if you delete this branch, all of its data will be permanently deleted.
    </Text>
    <GButton
      bg="red"
      color="light"
      title={t("general.delete")}
      hover={{ bg: "red" }}
     onClick={() => setShowDeleteBtanchModal(true)}
    />

    {showDeleteBranchModal && (
        <DeleteitemModal
          id={id}
          onClose={() => setShowDeleteBtanchModal(null)}
        />
      )}
   
  </Flex>
    </div>
  );
}

export default DeleteItem;
