import * as FiIcons from "react-icons/fi";
import routes from "../../../navigations/routes";
import { GrTree } from "react-icons/gr";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { TbReportMoney } from "react-icons/tb";
import { CiBank } from "react-icons/ci";
import { GiRecycle } from "react-icons/gi";

import { IoMdArrowRoundBack } from "react-icons/io";

const AccountingLinks = [
  {
    text: "pages.general_accounts.Accounts_tree",
    icon: <GrTree size={18} />,
    subLinks: null,
    path: routes.general_accounts,
  },
  {
    text: "pages.Trial_Balance.Trial_Balance",
    icon: <GiTakeMyMoney size={18} />,
    subLinks: null,
    path: routes.Trial_Balance,
  },
  {
    text: "pages.Ledger.income_statement",
    path: routes.income_statement,
    icon: <FaMoneyBillTransfer size={18} />,
    subLinks: null,
  },
  {
    text: "pages.Ledger.Ledger_account",
    path: routes.Ledger,
    subLinks: null,
    icon: <TbReportMoney size={18} />,
  },
  // {
  //   text:"pages.Ledger.Ledger_account_details",
  //   path:routes.Ledger_branches,
  //   subLinks:null,
  //   icon:<TbReportMoney size={18} />

  // },
  // {
  //   text:"pages.bankingAccount.bankingAccount",
  //   path:routes.bankingAccount,
  //   subLinks:null,
  //   icon:<CiBank size={18}/>

  // }
  // ,
  {
    text: "pages.AccountAdd.AccountAdd",
    path: routes.AccountAdd,
    subLinks: null,
    icon: <GiRecycle size={18} />,
  },
  {
    text: "pages.hr.back",
    icon: <IoMdArrowRoundBack size={18} />,
    subLinks: null,
    path: routes.Daily_restrictions,
  },

  // قائمة الدخل – أرباح وخسائر – الصالحيات
];

export default AccountingLinks;