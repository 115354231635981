import { createSlice } from "@reduxjs/toolkit";
import {
  getTimedEventCategories,
  createTimedEventCategories,
  deleteCategoryInTimedEvent,
  getTagsInCategoriesTags,
} from "../services/timedEventsCategoriesService";

let initialState = {
  isLoading: false,
  errors: null,
  categoriesList: [],
  tagsInCategory: [],
};

const timedEventsCategoriesSlice = createSlice({
  name: "timedEventsCategories",
  initialState,

  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get categories Timed Events List  -------------------------------------------------------------------*/
    builder.addCase(getTimedEventCategories.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTimedEventCategories.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getTimedEventCategories.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create categories Combos  -------------------------------------------------------------------*/
    builder.addCase(createTimedEventCategories.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      createTimedEventCategories.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(createTimedEventCategories.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Category In Timed Event  -------------------------------------------------------------------*/
    builder.addCase(deleteCategoryInTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      deleteCategoryInTimedEvent.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(deleteCategoryInTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------- Get Tags In categories Tags ---------------------------------------------------*/
    builder.addCase(getTagsInCategoriesTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTagsInCategoriesTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInCategory =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getTagsInCategoriesTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default timedEventsCategoriesSlice.reducer;
