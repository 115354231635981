import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* ---------------------------------------  Get All  Delivery Zone ------------------------------------------------- */
export const getAllDeliveryZone = createAsyncThunk( "deliveryZoneNew/getAllDeliveryZone",  async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/DeliveryZone/DeliveryZoneList`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  /* ---------------------------------------  Get All Branch Has Delivery Zone ------------------------------------------------- */
  export const getAllBranchesDeliverZone = createAsyncThunk( "deliveryZoneNew/getAllBranchesDeliverZone", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ---------------------------------------  Create Delivery Zone ------------------------------------------------- */
  export const createDeliverZone = createAsyncThunk("deliveryZoneNew/createDeliverZone",  async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Manage/DeliveryZone/AddDeliveryZone?deliveryzone=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getDeliveryZoneDetails = createAsyncThunk( "deliveryZoneNew/getDeliveryZoneDetails", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage/DeliveryZone/GetDeliveryZoneById?deliveryzoneid={"DeliveryZoneId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  
  export const editDeliveryZone = createAsyncThunk(
    "deliveryZoneNew/editDeliveryZone",
    async (args, thunkApi) => {
      try {
        //console.log(args)
        const { data } = await axios.get(`/Manage/DeliveryZone/EditDeliveryZone?deliveryzoneid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);

      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const deleteDeliveryZone= createAsyncThunk(
    "deliveryZoneNew/deleteDeliveryZone",
    async (args, thunkApi) => {
      
      try {
        const { data } = await axios.get(`/Manage/DeliveryZone/DeleteDeliveryZoneById?deliveryzoneid={"DeliveryZoneId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getDeletedDeliveryZone = createAsyncThunk(
    "deliveryZoneNew/getDeletedDeliveryZone",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/DeliveryZone/DeletedList`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getFilteredDataDeliveryZone = createAsyncThunk("deliveryZoneNew/getFilteredDataDeliveryZone ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetFilteredDataDeliveryZone = createAsyncThunk( "deliveryZoneNew/resetFilteredDataDeliveryZone ", async (args, thunkApi) => { 
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);