

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const getModifierToTaxesGroup = createAsyncThunk( "modifierGroups/getModifierToTaxesGroup", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage2/Tax/LookupModiferOption`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const addModifierToGroup = createAsyncThunk("modifierGroups/addModifierToGroup", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Taxs/AddEditModiferOptionTaxGroup?modiferoption=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
