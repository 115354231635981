import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import Input from "components/form/Input";
import GModal from "components/ui/modal/Modal";
import { Controller, useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import { useDispatch } from "react-redux";
import {
  editSupplierData,
  getSupplierDetails,
} from "../../services/inventorySupplierService";
import { useParams } from "react-router-dom";

const EditSupplierModal = ({ onClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useParams();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({ defaultValues: { ...data[0] } });

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };
  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.suppliers.edit_supplier")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              dispatch(editSupplierData(values))
                .unwrap()
                .then((_) => {
                  toast({ description: t("pages.suppliers.supplier_updated") });
                  dispatch(getSupplierDetails(id.suppliersId));
                  onClose();
                });
            })}
          >
            <Stack spacing={6}>
              <FormControl isInvalid={errors.SuppliersName}>
                <FormLabel
                  htmlFor="SuppliersName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.suppliers.supplier_name")}
                </FormLabel>
                <Controller
                  name="SuppliersName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.suppliers.supplier_name")}
                      onChange={(e) => {
                        handleInputChange(e, "SuppliersName");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.SuppliersName && errors.SuppliersName.message}
                </FormErrorMessage>
              </FormControl>

              <Flex alignItems="flex-end" gap={4}>
                <Input
                  label={t("pages.warehouse.reference")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={data[0].SuppliersCode}
                  readOnly
                />
              </Flex>

              <FormControl isInvalid={errors.ContactName}>
                <FormLabel
                  htmlFor="ContactName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.suppliers.contact_name")}
                </FormLabel>
                <Controller
                  name="ContactName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.suppliers.contact_name")}
                      onChange={(e) => {
                        handleInputChange(e, "ContactName");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.ContactName && errors.ContactName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.Phone}>
                <FormLabel
                  htmlFor="Phone"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.suppliers.supplier_phone")}
                </FormLabel>
                <Controller
                  name="Phone"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.suppliers.supplier_phone")}
                      onChange={(e) => {
                        handleInputChange(e, "Phone");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.Phone && errors.Phone.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.PrimaryEmail}>
                <FormLabel
                  htmlFor="PrimaryEmail"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.suppliers.email")}
                </FormLabel>
                <Controller
                  name="PrimaryEmail"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t("validation.invalid_email"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.suppliers.email")}
                      onChange={(e) => {
                        handleInputChange(e, "PrimaryEmail");
                      }}
                      type="email"
                    />
                  )}
                />
                <FormErrorMessage>
                  {" "}
                  {errors.PrimaryEmail && errors.PrimaryEmail.message}{" "}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.AdditionalEmails}>
                <FormLabel
                  htmlFor="AdditionalEmails"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.suppliers.additional_emails")}
                </FormLabel>
                <Controller
                  name="AdditionalEmails"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t("validation.invalid_email"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.suppliers.additional_emails")}
                      onChange={(e) => {
                        handleInputChange(e, "AdditionalEmails");
                      }}
                      type="email"
                    />
                  )}
                />
                <FormErrorMessage>
                  {" "}
                  {errors.AdditionalEmails &&
                    errors.AdditionalEmails.message}{" "}
                </FormErrorMessage>
              </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default EditSupplierModal;
