import { createSlice } from "@reduxjs/toolkit";
import {
  subGroupDropList,
  updateGroupParent,
  subGroupList,
} from "../services/subGroupsService";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,
  subGroupsData: [],
  subGroupsData1: [],
};

const inActiveBranchesSlice = createSlice({
  name: "subGroups",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(subGroupDropList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(subGroupDropList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.subGroupsData1 =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(subGroupDropList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(updateGroupParent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(updateGroupParent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });

    builder.addCase(updateGroupParent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(subGroupList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(subGroupList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.subGroupsData =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
      console.log(state.subGroupsData)
      state.errors = null;
    });

    builder.addCase(subGroupList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default inActiveBranchesSlice.reducer;
