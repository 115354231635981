import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text,useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import { useTranslation } from 'react-i18next';
import LinkItemToSupplierModal from './modals/LinkItemToSupplierModal';
import { useDispatch, useSelector } from 'react-redux';
import { getLinkedSupplier } from '../services/linkItemToSupplierService';
import { useParams } from 'react-router-dom';
import LinkedSuppliersToItemTable from './LinkedSuppliersToItemTable';
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const LinkItemToSupplier = () => {
    const { t } = useTranslation();
    const dispatch=useDispatch();
    const id = useParams();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const [showmodal,setshowModal]=useState(false)

    const linkedSuppliers = useSelector(state => state.linkItemToSupplier);

    useEffect(()=>{
      dispatch(getLinkedSupplier(id.suppliersId))
    },[dispatch,id])


    /* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()

const ManageSuppliers = checkIdExists(retrievedArray, 12);

if(showmodal===true && ManageSuppliers===false){
  toast2({ description:  t("general.authorization")});
  setshowModal(false);
};
/* -----------------------------------Privilige-------------------------------*/

  return (
    <Box>
    
    <Flex
    alignItems="center" justifyContent="space-between"
    gap={2} flexWrap="wrap" mb={6}
  >
    <Text color="text" textTransform="capitalize">
      {t("pages.suppliers.inventory_items")}
    </Text>
    <GButton
      title={t("pages.suppliers.link_items")}
      bg="light"
      color="text"
      fontSize="14px"
      border="1px solid"
      borderColor="border"
      hover={{ bg: "light" }}
      onClick={() => setshowModal( true)}
    />
  </Flex>

{

    showmodal&&(
        <LinkItemToSupplierModal  onClose={() => setshowModal(false)}></LinkItemToSupplierModal>
    )

}

{linkedSuppliers.isLoading ? (
  <Spinner />
) : linkedSuppliers.linkedSuppliers?.length === 0 ? (
  <Flex
    p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  >
    {t("pages.branches.no_data")}
  </Flex>
) : linkedSuppliers.linkedSuppliers?.length > 0 ? (
  <LinkedSuppliersToItemTable
  data={linkedSuppliers.linkedSuppliers}
  />
):(
  <Flex
  p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
  boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
>
  {t("pages.branches.no_data")}
</Flex>
)
}
    
    </Box>
  );
}

export default LinkItemToSupplier;
