import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Get Tips Report List ------------------------------- */
  export const getvoidReturnReportList = createAsyncThunk("voidReturnReport/getvoidReturnReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetVoidsAndReturnsReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

 /* --------------------------------------- Tips Filter Data ------------------------------*/
 export const getvoidReturnFilteredData = createAsyncThunk("voidReturnReport/getvoidReturnFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetvoidReturnFilteredData = createAsyncThunk( "voidReturnReport/resetvoidReturnFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);