import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getCombos = createAsyncThunk(
  "combos/getCombos",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Menu/Combo/ComboList");
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getComboById = createAsyncThunk(
  "combos/getComboById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Combo/ComboListByComboId?comboid=${JSON.stringify({ "ComboId": args.comboId })}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createCombo = createAsyncThunk(
  "combos/createCombo",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Combo/AddCombo?combo=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateCombo = createAsyncThunk(
  "combos/updateCombo",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Combo/EditCombo?comboid=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteCombo = createAsyncThunk(
  "combos/deleteCombo",
  async (args, thunkApi) => {
    try {
      // const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Menu/Combo/DeleteComboById?comboid=={"InventoryItemId":${args}}`);

      // const { data } = await axios.get(`/combos/${args.id}`);
      const { data } = await axios.get(`/Menu/Combo/DeleteComboById?comboid={"ComboId":${args}}`);
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getbranches = createAsyncThunk(
  "combos/getbranches",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("https://rostoapi.smartgate-egypt.com/Manage/Setting/CallCenter/LookUpBranches");
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getproducts = createAsyncThunk(
  "combos/getproducts",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Tax/LookUpProduct");
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const addCustomPriceCombo = createAsyncThunk(
  "combos/addCustomPriceCombo",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Combo/AddComboCustomPriceBranch?custompricebranch=${JSON.stringify(args)}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const deactiveComboinsomebranches = createAsyncThunk(
  "combos/deactiveComboinsomebranches",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Combo/AddComboInActiveBranch?comboinactivebranch=${JSON.stringify(args)}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getInactiveBranhesById = createAsyncThunk(
  "combos/getInactiveBranhesById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Combo/InActiveBranchByComboId?comboid=${JSON.stringify({ "ComboId": args })}`);
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);





export const deactivateComboById = createAsyncThunk(
  "combos/deactivateComboById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Combo/DeActivateComboById?comboid=${JSON.stringify({ "ComboId": args })}`);
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getFilteredData = createAsyncThunk(
  "combos/getFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const resetFilteredData = createAsyncThunk(
  "combos/resetFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

