import React, { Fragment, useState } from "react";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";
import GButton from "components/ui/button/Button";
import ExportModal from "components/ui/modal/ExportModal";

const IncomeStatementtable = ({ data }) => {
  const { t } = useTranslation();
  const [exportModal, SetExportModal] = useState(false);

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const itemsData = calculateRecords(data, currentPageActive, recordsPerPage);
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return (
    <Fragment>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        paddingBottom={5}
        paddingTop={2}
      >
        <Flex
          bg="light"
          overflow="auto"
          borderRadius={4}
          paddingX={5}
          marginBottom={5}
          justifyContent={"end"}
        >
          <GButton
            onClick={() => SetExportModal(true)}
            title={t("general.export")}
            bg="green.600"
            color="light"
            hover={{ bg: "green.700" }}
            style={{ marginTop: "15px" }}
          />
        </Flex>
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>{t("pages.Ledger.AccNo")}</Th>
              <Th>{t("pages.Ledger.AccName")}</Th>
              <Th>{t("pages.Ledger.AccValue")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {itemsData.map((el, index) => (
              <Tr key={el.InventoryItemId} style={{ cursor: "pointer" }}>
                <Td>{index + 1}</Td>
                <Td>{el.AccountChartNo}</Td>
                <Td>{el.AccountNameAr}</Td>
                <Td>{el.AccValue}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(data?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(data?.length / recordsPerPage)
            )
          }
        />
      </Box>

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={data}
          fileName={"Income_Statement"}
        ></ExportModal>
      )}
    </Fragment>
  );
};

export default IncomeStatementtable;
