import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* -------------------------------------------------------- Get General Settings ------------------------------------------------------------------ */
export const getGeneralSettings = createAsyncThunk( "settings/getGeneralSettings",async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/api/GeneralSettingJson/GetSettings");
      const parsedData = (data[0] && JSON.parse(data[0].jsonData)[0]) || {};
      const finalResponse = {
        data: parsedData,
        itemsCount: parsedData.length
      }
      return thunkApi.fulfillWithValue(finalResponse);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* -------------------------------------------------------- Update General Settings ------------------------------------------------------------------ */
export const updateGeneralSettings = createAsyncThunk("settings/updateGeneralSettings", async (args, thunkApi) => {
    try {
      await axios.post(`/api/GeneralSettingJson/AddSettingData?persondata=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue({});
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);