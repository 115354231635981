import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  assigenProductToBranch,
  getBranchesInProducts,
  getBranchesOfProduct,
} from "../../services/customPriceService.js";
import { useParams } from "react-router-dom";
import Input from "components/form/Input";

const ProductPricesPerBranchesModal = ({ onClose }) => {
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit, control, setValue,formState: { errors }, trigger } = useForm();

  const branchesOfProduct = useSelector((state) => state.customPrice);

  useEffect(() => {
    dispatch(getBranchesInProducts());
  }, [dispatch]);

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.inventory.select_branche")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.BranchId = values.BranchId?.BranchId;
              values.ProductId = id.productId;
              values.UserId = localStorage.getItem("userId");

              if (values.BranchId === undefined) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                dispatch(assigenProductToBranch(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: "Branch created" });
                    dispatch(getBranchesOfProduct(id.productId));
                    onClose();
                  });
              }
            })}
          >
            <Stack>
              <MultiSelect
                title={t("pages.inventory.branches")}
                data={
                  branchesOfProduct.branchInSelectBranch &&
                  branchesOfProduct.branchInSelectBranch.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("BranchId", e)}
              />


              <FormControl isInvalid={errors.Price}>
              <FormLabel
                htmlFor="Price"
                style={{ textTransform: "capitalize" }}
              >
              {t("pages.products.price_sar")}
                            </FormLabel>
              <Controller
                name="Price"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.products.price_sar")}
                    onChange={(e) => {
                      handleInputChange(e, "Price");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.Price && errors.Price.message}
              </FormErrorMessage>
            </FormControl>


             

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.inventory_item_details.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={branchesOfProduct.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={branchesOfProduct.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default ProductPricesPerBranchesModal;
