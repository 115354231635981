import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({ modifiers, handleUpdate, handleDelete }) => {
  const { t } = useTranslation();

  const filteredmodifierss = useSelector((state) => state.modifiers);
  const filteredmodifiers = filteredmodifierss.filteredData.filter(
    (obj) => obj.IsDeleted === false
  );

  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [filteredPageAll, setFilteredPageAll] = useState(1);

  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const recordsPerPage = 10;

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  const recordsAll = calculateRecords(
    modifiers,
    currentPageAll,
    recordsPerPage
  );

  const filteredRecordsAll = calculateRecords(
    filteredmodifiers,
    filteredPageAll,
    recordsPerPage
  );

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      {filteredmodifiers.length === 0 ? (
        <Fragment>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{t("pages.modifiers.name")}</Th>
                <Th>{t("pages.modifiers.name_localized")}</Th>
                <Th>{t("pages.modifiers.ref")}</Th>
                <Th>{t("general.action")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {recordsAll.map((modifier, index) => (
                <Tr key={modifier.ModifersId}>
                  <Td>{index + 1}</Td>
                  <Td>{modifier.ModifersNameEng}</Td>
                  <Td>{modifier.ModifersNameAr}</Td>
                  <Td>{modifier.ModifersRef}</Td>
                  <Td className="activeIcon">
                    <Flex gap={2}>
                      <GButton
                        postfixElement={<FiIcons.FiEdit />}
                        bg="primary"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "primary",
                          color: "primary",
                        }}
                        onClick={() => handleUpdate(modifier)}
                      />
                      <GButton
                        postfixElement={<FiIcons.FiTrash2 />}
                        bg="red.600"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "red.600",
                          color: "red.600",
                        }}
                        onClick={() => handleDelete(modifier)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            currentPage={currentPageAll}
            setCurrentPage={setCurrentPageAll}
            npage={Math.ceil(modifiers.length / recordsPerPage)}
            prePage={() => prePage(currentPageAll, setCurrentPageAll)}
            nextPage={() =>
              nextPage(
                currentPageAll,
                setCurrentPageAll,
                Math.ceil(modifiers.length / recordsPerPage)
              )
            }
          />
        </Fragment>
      ) : (
        <Fragment>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{t("pages.modifiers.name")}</Th>
                <Th>{t("pages.modifiers.name_localized")}</Th>
                <Th>{t("pages.modifiers.ref")}</Th>
                <Th>{t("general.action")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredRecordsAll.map((modifier, index) => (
                <Tr key={modifier.ModifersId}>
                  <Td>{index + 1}</Td>
                  <Td>{modifier.ModifersNameEng}</Td>
                  <Td>{modifier.ModifersNameAr}</Td>
                  <Td>{modifier.ModifersRef}</Td>
                  <Td className="activeIcon">
                    <Flex gap={2}>
                      <GButton
                        postfixElement={<FiIcons.FiEdit />}
                        bg="primary"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "primary",
                          color: "primary",
                        }}
                        onClick={() => handleUpdate(modifier)}
                      />
                      <GButton
                        postfixElement={<FiIcons.FiTrash2 />}
                        bg="red.600"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "red.600",
                          color: "red.600",
                        }}
                        onClick={() => handleDelete(modifier)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            currentPage={filteredPageAll}
            setCurrentPage={setFilteredPageAll}
            npage={Math.ceil(filteredmodifiers.length / recordsPerPage)}
            prePage={() => prePage(filteredPageAll, setFilteredPageAll)}
            nextPage={() =>
              nextPage(
                filteredPageAll,
                setFilteredPageAll,
                Math.ceil(filteredmodifiers.length / recordsPerPage)
              )
            }
          />
        </Fragment>
      )}
    </Box>
  );
};

export default CTable;
