import React from 'react';
import Chart from 'react-apexcharts';
import { separateArrays } from 'utils/ObjectToArray';

const DonutChart = ({data}) => {
  
const [productNames, productCounts] = separateArrays(data);


  const donutChartData = {
    series: productCounts,
    options: {
      chart: {
        type: 'donut',
      },
      labels: productNames,
    },
  };

  return (
    <div>
      <Chart
        options={donutChartData.options}
        series={donutChartData.series}
        type="donut"
        width="350"
      />
    </div>
  );
};

export default DonutChart;
