import React, { useEffect } from "react";
import GButton from "components/ui/button/Button";
import {
  Box,
  Flex,
  Stack,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  addNewTimeShift,
  getTimeLevelDetailsById,
  getYearLookUp,
} from "../../services/attendanceSettingServices";

export default function AddNewShift({ onClose, id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const data = useSelector((state) => state.attendanceSetting);

  useEffect(() => {
    dispatch(getYearLookUp());
  }, [dispatch]);

  //-------------------------------- Validation -----------------------------
  // const isOnlySpaces = (value) => {
  //   // Check if the value contains only spaces
  //   return !value.trim();
  // };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };
  //------------------------------------------------------------------

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.attendance.new_time_shift")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.UserId = localStorage.getItem("userId");
                values.TimeDetails_ID = null;
                values.Time_ID = id;
                values.Year_ID = values.Year_ID?.SchoolYearId;
                if (values.Year_ID === undefined) {
                  toast2({
                    description: t("validation.choose_first"),
                  });
                } else {
                  dispatch(addNewTimeShift(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.hr.attendance.NewTimeShiftAdded"),
                      });
                      dispatch(getTimeLevelDetailsById(id));
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <Box>
                  <MultiSelect
                    title={t("pages.hr.attendance.year")}
                    isMulti={false}
                    data={
                      data?.YearLookUpDropDown &&
                      data?.YearLookUpDropDown?.map((el) => ({
                        ...el,
                        label: el?.Year,
                        value: el?.SchoolYearId,
                      }))
                    }
                    onChange={(e) => setValue("Year_ID", e)}
                  />
                </Box>

                <FormControl isInvalid={errors.From_Date}>
                  <FormLabel
                    htmlFor="From_Date"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.attendance.from_date")}
                  </FormLabel>
                  <Controller
                    name="From_Date"
                    control={control}
                    // defaultValue={`${year2}-${month2}-${day2}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.attendance.from_date")}
                        onChange={(e) => {
                          handleInputChange(e, "From_Date");
                        }}
                        type="date"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.From_Date && errors.From_Date.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.To_Date}>
                  <FormLabel
                    htmlFor="To_Date"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.attendance.to_date")}
                  </FormLabel>
                  <Controller
                    name="To_Date"
                    control={control}
                    // defaultValue={`${year2}-${month2}-${day2}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.attendance.to_date")}
                        onChange={(e) => {
                          handleInputChange(e, "To_Date");
                        }}
                        type="date"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.To_Date && errors.To_Date.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.FromTime}>
                  <FormLabel
                    htmlFor="FromTime"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.attendance.from_time")}
                  </FormLabel>
                  <Controller
                    name="FromTime"
                    control={control}
                    // defaultValue={`${year2}-${month2}-${day2}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.attendance.from_time")}
                        onChange={(e) => {
                          handleInputChange(e, "FromTime");
                        }}
                        type="time"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.FromTime && errors.FromTime.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.ToTime}>
                  <FormLabel
                    htmlFor="ToTime"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.attendance.to_time")}
                  </FormLabel>
                  <Controller
                    name="ToTime"
                    control={control}
                    // defaultValue={`${year2}-${month2}-${day2}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.attendance.to_time")}
                        onChange={(e) => {
                          handleInputChange(e, "ToTime");
                        }}
                        type="time"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.ToTime && errors.ToTime.message}
                  </FormErrorMessage>
                </FormControl>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
