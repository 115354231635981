import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Get Menu Engineering Report List ------------------------------- */
  export const getInventoryItemsCostReportList = createAsyncThunk("inventoryItemsCostReport/getInventoryItemsCostReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetInventoryItemsCostReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
 /* --------------------------------------- Menu Engineering Filter Data ------------------------------*/
 export const getInventoryItemsCostFilteredData = createAsyncThunk("inventoryItemsCostReport/getInventoryItemsCostFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetInventoryItemsCostFilteredData = createAsyncThunk( "inventoryItemsCostReport/resetInventoryItemsCostFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);