import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "./axios";

export const getTime = createAsyncThunk("timezome/getTime", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Branches/LookUpDayTime");
      const parsedData = (data[0] && JSON.parse(data[0].jsonData)) || [];
      const finalResponse = {
        data: parsedData,
        itemsCount: parsedData.length
      }
      return thunkApi.fulfillWithValue(finalResponse);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
