import { createSlice } from "@reduxjs/toolkit";
import {
  getOrderType,
  getRoundingMethod,
  getKitchenSortingMethod,
  createCaherApp,
} from "../services/chasherAppService";

let initialState = {
  isLoading: false,
  orderType: [],
  RoundingMethod: [],
  KitchenSortingMethod: [],
  pages: 0,
  error: null,
};

const casherAppSlice = createSlice({
  name: "casherApp",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getOrderType.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getOrderType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.orderType = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.data);
      state.errors = null;
    });
    builder.addCase(getOrderType.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(getRoundingMethod.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getRoundingMethod.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.RoundingMethod =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.RoundingMethod);
      state.errors = null;
    });
    builder.addCase(getRoundingMethod.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(getKitchenSortingMethod.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getKitchenSortingMethod.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.KitchenSortingMethod =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.KitchenSortingMethod);
      state.errors = null;
    });
    builder.addCase(getKitchenSortingMethod.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(createCaherApp.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createCaherApp.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.orderType = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.data);
      state.errors = null;
    });
    builder.addCase(createCaherApp.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default casherAppSlice.reducer;
