import React, { useEffect, useState } from "react";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GetOrderDetails, payThereceiptService } from "../services/ordersService";
import { useDispatch, useSelector } from "react-redux";
import GButton from "components/ui/button/Button";
import PaymentModalDineIn from "../components/modals/PaymentModalDineIn";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const OrderDetails = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageOrders = checkIdExists(retrievedArray, 2);
  const ReadOrders = checkIdExists(retrievedArray, 1);

  const { t } = useTranslation();

  const id = useParams();

  //console.log(id.id)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetOrderDetails(id.id));
  }, [dispatch, id]);

  const data = useSelector((state) => state.ordersSlice);

  //console.log(data.orderDetails);



  const [showPaymentDineInModal,setShowPaymentDineInModal]=useState(false)
  const payThereceipt=()=>{
  setShowPaymentDineInModal(true)
  }


  
if(ManageOrders===false && ReadOrders===false ){
  return(
    <NotAuthorizedCompo/>
  )
}

  return (
    <div>
      <Stack spacing={12}>
        {/* details */}
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.inventory_item_details.name")}
            </Text>
            <Text marginBlock={2}>
              {data.orderDetails[0]?.CustomerName || "-"}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.orders.branch")}
            </Text>
            <Text marginBlock={2}>
              {data.orderDetails[0]?.BranchName || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.orders.phone_num")}
            </Text>
            <Text marginBlock={2}>{data.orderDetails[0]?.PhoneNo || "-"}</Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.orders.OrderStatus")}
            </Text>
            <Text marginBlock={2}>
              {data.orderDetails[0]?.OrderStatusNameLoc || "-"}{" "}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.orders.OrderType")}
            </Text>
            <Text marginBlock={2}>
              {" "}
              {data.orderDetails[0]?.OrderTypeName || "-"}{" "}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.Expenses.data")}
            </Text>
            <Text marginBlock={2}>
              {" "}
              {data.orderDetails[0]?.UserDate || "-"}
            </Text>
          </Box>

          {data.orderDetails[0]?.OrderTypeName === "Delivery" ? (
            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text">
                {t("pages.orders.address")}
              </Text>
              {data.orderDetails[0]?.Address_name?.map((el) => {
                return (
                  <Text
                    marginBlock={2}
                    style={{ paddingBottom: "5px", borderBottom: "1px solid" }}
                  >
                    {" "}
                    {el.AddressName || "-"}
                  </Text>
                );
              })}
            </Box>
          ) : null}
        </SimpleGrid>
      </Stack>

      <Stack spacing={12} style={{ marginTop: "20px" }}>
        {/* details */}
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
        {data.orderDetails[0]?.products?.length > 0
          ? data.orderDetails[0]?.products.map((el, index) => {
              return (
                <>
                  <Box borderBottom="1px solid" borderColor="border">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Text textTransform="capitalize" color="text">
                          {t("pages.orders.product")} {index + 1}
                        </Text>

                        <Text marginBlock={2}>{el.ProductName || "-"}</Text>
                      </div>

                      <div>
                      <Text textTransform="capitalize" color="text">
                          {t("pages.orders.quantity")} 
                        </Text>
                      <Text marginBlock={2}>{el.Quantity || "-"}</Text>

                      </div>
                    </div>
                  </Box>
                </>
              );
            })
          : null}
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.orders.totalPrice")}
            </Text>
            <Text marginBlock={2}>
              {data.orderDetails[0]?.TotalPrice || "-"}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.orders.subTotal")}
          </Text>
          <Text marginBlock={2}>
            {data.orderDetails[0]?.Subtotal || "-"}
          </Text>
        </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.orders.kitchen_note")}
            </Text>
            <Text marginBlock={2}>
              {data.orderDetails[0]?.KitchenNotes || "-"}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.orders.receipt_notes")}
            </Text>
            <Text marginBlock={2}>
              {data.orderDetails[0]?.ReceiptNotes || "-"}
            </Text>
          </Box>

        
        </SimpleGrid>
      </Stack>

      {
        data.orderDetails[0]?.OrderTypeName==="Dine in"?(
          <Stack spacing={12} style={{ marginTop: "20px" }}>
          <Stack
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
        <Flex style={{justifyContent:"space-between",alignItems:"center"}}>
        <div> {t("pages.orders.Pay_the_receipt")}</div>
        <button
        onClick={() => payThereceipt("hi")}
        disabled={data.orderDetails[0]?.IsPaid}
        //disabled={false}
        style={{
            backgroundColor: data.orderDetails[0]?.IsPaid ? "red" : "green",
            cursor:data.orderDetails[0]?.IsPaid ? "not-allowed" : "pointer",     
            padding: "5px 35px 5px 35px",
            borderRadius: "8px",
            color: "white"
        }}
    >
        {t("pages.orders.pay")}
        </button>
        </Flex>
        
        </Stack>
          
          </Stack>
        ):(null)

      }
 

      {
        showPaymentDineInModal&&(
          <PaymentModalDineIn  
          onClose={() => setShowPaymentDineInModal(false)}
          TotalPrice={data.orderDetails[0]?.TotalPrice}
          />
        )
      }
    </div>
  );
};

export default OrderDetails;
