import React from 'react';
import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from 'components/ui/button/Button';
import { IoCloseOutline } from "react-icons/io5";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilteredDataTable from './FilteredDataTable';
import InventoryItemsCostDataTable from './InventoryItemsCostDataTable';
import { resetInventoryItemsCostFilteredData } from '../services/inventoryItemsCostServices';
import FilterModal from '../modal/FilterModal';
import { Spinner } from '@chakra-ui/react';


export default function InventoryItemsCostTable({ data,filteredData}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [sortType, setSortType] = useState("All");
    const {isLoading} = useSelector((state) => state.inventoryItemsCostReport);
    // Show Filter Modal State
    const [showFilterModal, setShowFilterModal] = useState(false);
    
    const resetFunction = () => {
        dispatch(resetInventoryItemsCostFilteredData());
      };
      
  return <>
    <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
    >

        <Flex
            alignItems="center" flexWrap="wrap"
            textTransform="capitalize" gap={4} color="text" bg="light"
    >
            <GButton
                title={t("general.all")}
                bg="none"
                borderRadius={0}
                onClick={() => setSortType("All")}
                color={sortType === "All" ? "primary" :"none"}  
                borderBottom={sortType === "All" ? "2px solid" :"none"}   
                borderColor={sortType === "All" ? "primary" :"none"} 
            />

        </Flex>

        <GButton
            title={
                filteredData?.length === 0
                ? t("general.filter")
                : t("general.clear_filter")
            }
            border="1px solid"
            borderColor="border"
            postfixElement={
                filteredData?.length === 0 ? (
                <FiIcons.FiFilter size={16} />
                ) : (
                <IoCloseOutline fontWeight={800} size={20} />
                )
            }
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            onClick={
                filteredData?.length === 0
                ? () => setShowFilterModal(true)
                : resetFunction
            }
        />

    </Flex>
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >

        {
            isLoading ? 
            <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
            >
                <Spinner /> 
            </Flex>
            :
            data?.length > 0 ? 
            <>
            {
                filteredData?.length > 0 ? 
                <FilteredDataTable  data={  filteredData } />
                : 
                <InventoryItemsCostDataTable data={data} />
            }
            </>
            :
            <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
                color="text"
                textTransform="capitalize"
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                borderRadius={6}
                >
                {t("pages.branches.no_data")}
            </Flex>
        }

    </Box>
    {
        showFilterModal && (
            <FilterModal 
                onClose={()=>setShowFilterModal(false)} 
                data={data} 
                filterData={filteredData}
            />
        )
    }
  </>
};
