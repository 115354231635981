import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTransfersFilteredData } from '../services/transfersServices';


export default function FilterModal({onClose , data }) {
    const { t } = useTranslation();
    const dispatch =useDispatch();

    const [filteredData, setFilteredData] = useState(data);

    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
      Source: "",
      Destination: "",
      Status: ""
      });

      const handleFilterTransfers = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const { Source , Destination , Status } = filterCriteria;
    
          let filterSource = true;
          let filterDestination = true;
          let filterStatus = true;
    
          if (Source) {
            filterSource = item.Source.toLowerCase().includes(Source.toLowerCase());
          }

          if (Destination) {
            filterDestination = item.Destination.toLowerCase().includes(Destination.toLowerCase());
          }

          if (Status) {
            filterStatus = item.Status.toLowerCase().includes(Status.toLowerCase());
          }

          return (
            filterSource &&           
            filterStatus &&           
            filterDestination            
            );

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getTransfersFilteredData(filteredResult));
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.transfers.transfers_filter")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterTransfers}
          >
            <Stack spacing={6}>

              <Input
                label={t("pages.transfers.source")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.transfers.source")}
                name="Source"
                value={filterCriteria.Source}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Source: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.transfers.destination")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.transfers.destination")}
                name="Destination"
                value={filterCriteria.Destination}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Destination: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.transfers.status")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.transfers.status")}
                name="Status"
                value={filterCriteria.Status}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Status: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
