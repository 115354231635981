import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text } from "@chakra-ui/react"
import { useState } from "react";
import * as FiIcons from "react-icons/fi";
import { RiAdminLine } from "react-icons/ri";
import Addprivileges from "./modals/Addprivileges";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({
  data,
  handleUpdate,
  handleDelete
}) => {
  
  const [handlePrvladge,sethandlePrvladge]=useState(false);
  const [role,setRole]=useState("");

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 12;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

// Calculate records for activeDiscounts
const rolesData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
/* --------------------------------------------- End Pagination  -----------------------------------------------*/
    return <>
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6} mt={6}>
      {rolesData?.map(role => (
        <Box key={role.roleId}
          bg="light" p={6} borderRadius={4}
          boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
        >
          <Flex justifyContent="space-between">
            <Text textTransform="capitalize">{role?.roleName}</Text>
            <Menu>
              <MenuButton as={Button}
                size="sm"
                bg="none"
                border="1px solid"
                borderColor="border"
                color="text"
                _hover={{ bg: "primary", borderColor: "primary", color: "light" }}
              >
                <Flex alignItems="center" gap={2}>
                  <FiIcons.FiMoreHorizontal size={18} />
                </Flex>
              </MenuButton>
              <MenuList p={0} minW="auto">
                <MenuItem onClick={() => handleUpdate(role)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiEdit />
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => handleDelete(role)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiTrash2 />
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => {
                  sethandlePrvladge(true)
                  setRole(role)
                }} >
                  <Flex alignItems="center" gap={2}>
                    <RiAdminLine  />
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Text textTransform="capitalize" color="text">({role.userCount}) users</Text>
        </Box>
        
      ))}   
    </SimpleGrid>
    <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        /> 
    { handlePrvladge&&(
        <Addprivileges onClose={()=>sethandlePrvladge(false)} role={role} />
    )}

  </>
}

export default CTable