import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPurchaseOrderFilteredData } from "../services/purchaseOrderServices";

export default function FilterModal({ onClose, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const [filteredData, setFilteredData] = useState(data);

  // Filter Data
  const [filterCriteria, setFilterCriteria] = useState({
    BranchName: "",
    InventoryItemNameEng: "",
    SuppliersName: "",
  });

  const handleFilterCostAdjustment = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const { BranchName, InventoryItemNameEng, SuppliersName } =
        filterCriteria;

      let filterBranchName = true;
      let filterItemName = true;
      let filterSuppliersName = true;

      if (BranchName) {
        filterBranchName = item.BranchName.toLowerCase().includes(
          BranchName.toLowerCase()
        );
      }

      if (InventoryItemNameEng) {
        filterBranchName = item.InventoryItemNameEng.toLowerCase().includes(
          InventoryItemNameEng.toLowerCase()
        );
      }

      if (SuppliersName) {
        filterSuppliersName = item.SuppliersName.toLowerCase().includes(
          SuppliersName.toLowerCase()
        );
      }

      return filterBranchName && filterItemName && filterSuppliersName;
    });

    setFilteredData(filteredResult);
    onClose();
    dispatch(getPurchaseOrderFilteredData(filteredResult));
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.reports.business_reports.filter")}
        modalContent={
          <Box p={4}>
            <form onSubmit={handleFilterCostAdjustment}>
              <Stack spacing={6}>
                <Input
                  label={t("pages.reports.reports_tables.branch")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.branch")}
                  name="BranchName"
                  value={filterCriteria.BranchName}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      BranchName: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.reports.reports_tables.item_name")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.item_name")}
                  name="InventoryItemNameEng"
                  value={filterCriteria.InventoryItemNameEng}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      InventoryItemNameEng: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.reports.reports_tables.suppliers_name")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.suppliers_name")}
                  name="Order Tag"
                  value={filterCriteria.SuppliersName}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      SuppliersName: e.target.value,
                    })
                  }
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
