import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr,Flex } from "@chakra-ui/react";
import GButton from "../../../../components/ui/button/Button";
import * as FiIcons from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getkitchenDetails, resetkitchenFlowFilteredData } from '../services/kitchenFlowService';
import FilterModal from './modal/FilterModal';
import Pagination from "components/ui/pagination/Pagination";
import { Spinner } from '@chakra-ui/react';

const KitchenFlowTable = ({data}) => {
    const { t } = useTranslation();
    const navigate= useNavigate()
    const dispatch = useDispatch()

    const KitchenFlow = useSelector(state => state.kitchenFlow);

    const [showFilterModal, setShowFilterModal] = useState(false);

    const navigateFunction=(el)=>{
        dispatch(getkitchenDetails(el))
        navigate(`${el.KitchenFlowsId}`)
      }

    const resetFunction = () => {
      dispatch(resetkitchenFlowFilteredData());
    };
         /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
    const recordsPerPage = 10;
    const [currentPageActive, setCurrentPageActive] = useState(1);
    const prePage = (currentPage, setCurrentPage) => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const nextPage = (currentPage, setCurrentPage, npage) => {
      if (currentPage < npage) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const calculateRecords = (data, currentPage, recordsPerPage) => {
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
      return data?.slice(firstIndex, lastIndex);
    };
  
  // Calculate records for activeDiscounts
  const KitchenFlowTableData = calculateRecords(
      data,
      currentPageActive,
      recordsPerPage
    );
/* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    
    
    <Flex
    flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
    p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
  >
        <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
        >
        <Box>
              <GButton
                title={t("general.all")}
                bg="none"
                color="primary"
                isDisabled={false}
                borderRadius={0}
                borderBottom="2px solid"
                borderColor="primary"
              />

        </Box>
        </Flex>


        <GButton
            title={
                KitchenFlow?.filteredData?.length === 0
                ? t("general.filter")
                : t("general.clear_filter")
            }
            border="1px solid"
            borderColor="border"
            postfixElement={
                KitchenFlow?.filteredData?.length === 0 ? (
                <FiIcons.FiFilter size={16} />
                ) : (
                <IoCloseOutline fontWeight={800} size={20} />
                )
            }
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            onClick={
                KitchenFlow?.filteredData?.length === 0 
                ? () => setShowFilterModal(true)
                : resetFunction
            }
        />

  </Flex>
    

    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
        { 
          KitchenFlow?.isLoading  && ( 
          <Flex p={4}  bg="white"  alignItems="center" justifyContent="center">
              <Spinner /> 
          </Flex>)
        }
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.devices.name")}</Th>
            <Th>{t("pages.kitchenFlow.active")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            KitchenFlow?.filteredData?.length > 0 ? 
            <>
              {KitchenFlow?.filteredData?.map((el, index) => (
                <Tr key={el.KitchenFlowsId}>
                  <Td>{index + 1}</Td>
                  <Td style={{cursor:"pointer"}} onClick={()=>navigateFunction(el)} >{el?.KitchenFlowsName}</Td>
                  <Td>
                      {
                        el.IsActive?
                        <Text style={{color : "#65B741" , fontWeight:"bold"}}>
                        {t("pages.kitchenFlow.activate")}
                        </Text>
                        : 
                        <Text style={{color : "#FF6868" , fontWeight:"bold"}}>
                          {t("pages.kitchenFlow.deactivate")}
                        </Text>
                      }
                  </Td>
                </Tr>
              ))}
            </>
            :
            <>
              {KitchenFlowTableData?.map((el, index) => (
                <Tr key={el.KitchenFlowsId}>
                  <Td>{index + 1}</Td>
                  <Td style={{cursor:"pointer"}} onClick={()=>navigateFunction(el)} >{el.KitchenFlowsName}</Td>
                  <Td>
                      {
                        el.IsActive?
                        <Text style={{color : "#65B741" , fontWeight:"bold"}}>
                        {t("pages.kitchenFlow.activate")}
                        </Text>
                        : 
                        <Text style={{color : "#FF6868" , fontWeight:"bold"}}>
                          {t("pages.kitchenFlow.deactivate")}
                        </Text>
                      }
                  </Td>
                </Tr>
              ))}
            </>
          }
        </Tbody>
      </Table>
      <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </Box>

    
    {
        showFilterModal && (
            <FilterModal 
                onClose={()=>setShowFilterModal(false)} 
                data={KitchenFlow?.data} 
            />
        )
      }

    </>
};

export default KitchenFlowTable;
