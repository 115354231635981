import { createSlice } from "@reduxjs/toolkit";
import {
  CloseFinancialPeriods,
  createAccountCycel,
  getAcoountCycleNew,
} from "../service/AccountAddService";

let initialState = {
  isLoading: false,
  accountCycle: [],
  pages: 0,
  errors: null,
};

const AccountAddSlice = createSlice({
  name: "AccountAdd",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getAcoountCycleNew.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getAcoountCycleNew.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.accountCycle =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      // console.log(state.accountCycle);
      state.errors = null;
    });

    builder.addCase(getAcoountCycleNew.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //create account cycle

    builder.addCase(createAccountCycel.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(createAccountCycel.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.accountCycle =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.accountCycle);
      state.errors = null;
    });

    builder.addCase(createAccountCycel.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });


    //

     builder.addCase(CloseFinancialPeriods.pending, (state) => {
       state.isLoading = true;
       state.errors = null;
     });

     builder.addCase(CloseFinancialPeriods.fulfilled, (state, { payload }) => {
       state.isLoading = false;
       // state.accountCycle =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
       //console.log(state.accountCycle);
       state.errors = null;
     });

     builder.addCase(CloseFinancialPeriods.rejected, (state) => {
       state.isLoading = false;
       state.errors = [{ msg: "something went wrong!" }];
     });


  },
});

export default AccountAddSlice.reducer;
