import React, { useEffect , useState } from 'react';
import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import CreatePurchaseOrdersModal from '../components/modals/CreatePurchaseOrdersModal';
import PurchaseOrderTable from '../components/PurchaseOrderTable';
import { getPurchaseOrdersList } from '../services/purchasrOrderServices';
import ExportModal from 'components/ui/modal/ExportModal';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege .jsx";

const PurchaseOrders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const [CreatePurchaseOrdersModalstate, setCreatePurchaseOrdersModalstate] = useState(false);
    const [exportModal,SetExportModal]=useState(false);

    const items = useSelector(state => state.purchaseOrder);

    useEffect(() => {
      dispatch(getPurchaseOrdersList());
    }, [dispatch]);

/* -----------------------------------Privilige-------------------------------*/
    const retrievedArray =useDecryptedPrivilege()
    const ManagePurchaseOrder = checkIdExists(retrievedArray, 13);

    if(CreatePurchaseOrdersModalstate===true && ManagePurchaseOrder===false){
      toast2({ description:  t("general.authorization")});
      setCreatePurchaseOrdersModalstate(false);
    };
/* -----------------------------------Privilige-------------------------------*/
    return (
        <div>
        <Box
        bg="light" marginInline={-6} marginTop={-6} padding={6}
        borderTop="1px solid" borderColor="border" marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("layout.sidebar.purchase_orders")}
          </Text>
    
          <Flex gap={2}>
            
            <Button
                type="button" bg="none" border="1px solid" borderColor="border"
                textTransform="capitalize" fontSize="1rem" color="text"
                _hover={{ bg: "primary", color: "light" }}
                onClick={() => SetExportModal(true)}
              >
                  <Text>{t("general.export")}</Text>
              </Button>

            <Button
              type="button" bg="primary" color="light"
              textTransform="capitalize" fontSize="1rem"
              border="1px solid transparent"
              _hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              onClick={() => setCreatePurchaseOrdersModalstate(true)}
            >
              <Flex alignItems="center" gap={1} >
                {t("pages.purchase_orders.new_purchase_order")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>
    
      {
        items.isLoading?(
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ):(
          <PurchaseOrderTable data={items.purchasOrderData} filteredData={items.filteredData} ></PurchaseOrderTable>
        )
      } 
    
      { CreatePurchaseOrdersModalstate && (
        <CreatePurchaseOrdersModal 
          onClose={() => setCreatePurchaseOrdersModalstate(false)}
        />
      )}

      {exportModal &&(
            <ExportModal 
              onClose={() => SetExportModal(false)}   
              data={items.purchasOrderData}
              fileName={"purchase_orders"}
              >
            </ExportModal>
      )}
    
        </div>
      );
}

export default PurchaseOrders;