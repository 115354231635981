import { Box, Flex, Stack, useToast } from '@chakra-ui/react';
import GButton from 'components/ui/button/Button';
import GModal from 'components/ui/modal/Modal';
import React from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { editQtyDrop, wastePercentage } from '../../services/ingrediantsService';
import Input from 'components/form/Input';

const EditWasteModal = ({ onClose, data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useToast({ position: "top",  duration: 3000,  status: "success", });
    const { handleSubmit, control } = useForm({    defaultValues: {    ...data, },});
  
    console.log("here",data)
  
    return (
      <GModal
        onClose={onClose}
        modalHeader={t("pages.ingrediants.update_ingrediant")+`: ${data.InventoryItemNameEng} ( ${data.SUK} )`}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {

                // console.log("tttt",values)
                dispatch(wastePercentage(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: "edit done" });
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                <Input
                  label={t("pages.ingrediants.net_qty")}
                  labelStyle={{ textTransform: "capitalize" }}
                  // placeholder={t("pages.ingrediants.net_qty")}
                  name="NetQty"
                  isFormController
                  isRequired
                  control={control}
                />
  
                <Input
                  label={t("pages.ingrediants.yield")}
                  labelStyle={{ textTransform: "capitalize" }}
                  // placeholder={t("pages.ingrediants.yield")}
                  name="YieId"
                  isFormController
                  isRequired
                  control={control}
                />
  
                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    );
  };

export default EditWasteModal