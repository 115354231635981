import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* -------------------------------------------- Get CostAdjustment Reports List ------------------------------------ */
export const getReportTransfersList = createAsyncThunk(
  "reportsReportTransfers/getReportTransfersList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Reports/GetTransfersReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* --------------------------------------- Menu Engineering Filter Data ------------------------------*/

export const getReportTransfersFilteredData = createAsyncThunk(
  "reportsReportTransfers/getReportTransfersFilteredData",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetReportTransfersFilteredData = createAsyncThunk(
  "reportsReportTransfers/resetReportTransfersFilteredData",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
