import { createSlice } from "@reduxjs/toolkit";
import { addIngerdiants, editQtyDrop, getIngerdiants, getIngerdiantsDrop, wastePercentage } from "../services/ingrediantsService";

let initialState = {
    isLoading: false,
    data: [],
    data1: [],
    data2: [],
    pages: 0,
    errors: null,
  };


  const ingrediantsSlice = createSlice({
    name: "ingrediants",
    initialState,

    extraReducers: (builder) => {

        builder.addCase(getIngerdiants.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getIngerdiants.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
      
          builder.addCase(getIngerdiants.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          builder.addCase(getIngerdiantsDrop.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getIngerdiantsDrop.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data1 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
      
          builder.addCase(getIngerdiantsDrop.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          builder.addCase(addIngerdiants.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(addIngerdiants.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(addIngerdiants.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })          

          builder.addCase(editQtyDrop.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(editQtyDrop.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data2 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
      
          builder.addCase(editQtyDrop.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          builder.addCase(wastePercentage.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(wastePercentage.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(wastePercentage.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


    }
  })


  export default ingrediantsSlice.reducer