import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import { Button, Flex, Text, Select, Spinner} from "@chakra-ui/react"
import Pagination from "components/ui/pagination/Pagination";
import List from "../components/List";
import GButton from "../../../../components/ui/button/Button";
import { IoCloseOutline } from "react-icons/io5";
import NoBrands from "../components/NoBrands";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { getBrands, resetBeandsFilteredData } from "../services/brandsService";
import ExportModal from 'components/ui/modal/ExportModal';


const Brands = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [showCreateBrandModal, setShowCreateBrandModal] = useState(null);
  const [showUpdateBrandModal, setShowUpdateBrandModal] = useState(null);
  const [showDeleteBrandModal, setShowDeleteBrandModal] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [exportModal,SetExportModal]=useState(false);

  const brands = useSelector(state => state.brands);

  const resetFunction = () => {
    dispatch(resetBeandsFilteredData());
  };

  useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);

  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.brands.brands")} 
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button" bg="none" border="1px solid" borderColor="border"
              textTransform="capitalize" fontSize="1rem" color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
                <Text>{t("general.export")}</Text>
            </Button>

            <GButton
              title={t("pages.brands.create_brand")}
              onClick={() => setShowCreateBrandModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
      >
        <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom="2px solid"
            borderColor="primary"
          />
        </Flex>

            <GButton
                title={
                    brands.filteredData?.length === 0
                    ? t("general.filter")
                    : t("general.clear_filter")
                }
                border="1px solid"
                borderColor="border"
                postfixElement={
                    brands.filteredData?.length === 0 ? (
                    <FiIcons.FiFilter size={16} />
                    ) : (
                    <IoCloseOutline fontWeight={800} size={20} />
                    )
                }
                hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                onClick={
                    brands.filteredData?.length === 0
                    ? () => setShowFilterModal(true)
                    : resetFunction
                }
            />

      </Flex>

      {brands.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : brands.data.length === 0 ? (
        <NoBrands
          onClickCreateBrandBtn={() => setShowCreateBrandModal(true)}
        />
      ) :  brands.filteredData.length > 0 ? (
        <List
          brands={brands.filteredData}
          handleUpdate={(brand) => setShowUpdateBrandModal(brand)}
          handleDelete={(brand) => setShowDeleteBrandModal(brand)}
        />
      )
      : (
        <List
          brands={brands.data}
          handleUpdate={(brand) => setShowUpdateBrandModal(brand)}
          handleDelete={(brand) => setShowDeleteBrandModal(brand)}
        />
      )
      }

      {showCreateBrandModal && (
        <CreateModal
          onClose={() => setShowCreateBrandModal(null)}
        />
      )}

      {showUpdateBrandModal && (
        <UpdateModal
          data={showUpdateBrandModal}
          onClose={() => setShowUpdateBrandModal(null)}
        />
      )}

      {showDeleteBrandModal && (
        <DeleteModal
          data={showDeleteBrandModal}
          onClose={() => setShowDeleteBrandModal(null)}
        />
      )}
      {exportModal&&(
        <ExportModal 
          onClose={() => SetExportModal(false)}   
          data={brands.data}
          fileName={"brands"}
          >
        </ExportModal>
      )}

      { showFilterModal && (
              <FilterModal 
                  onClose={()=>setShowFilterModal(false)} 
                  data={brands.data} 
              />
      )}
    </>
  )
}

export default Brands