import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteBrand, getBrands } from "../../services/brandsService";

const DeleteModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  
  const brands = useSelector(state => state.brands);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        let values = {
          BrandsId: data.BrandsId,
          isDeleted: true,
        };

        dispatch(deleteBrand({ values }))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.brands.brand_deleted") });
            dispatch(getBrands());
            onClose();
          })
      }}
      isLoading={brands.isLoading}
    />
  )
}

export default DeleteModal;