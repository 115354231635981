import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CountCompo = ({ link, count, title }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1 style={{ fontWeight: "bold", fontSize: "17px" }}>{t(title)}</h1>
          <Link to={link}>
            <h2 style={{ fontSize: "17px", cursor: "pointer" }}>
              {t("pages.dashboard.viewReports")}
            </h2>
          </Link>
        </div>
        <h3
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            marginBottom: "10px",
            marginTop: "20px",
          }}
        >
          {count}
        </h3>
      </div>
    </div>
  );
};

export default CountCompo;
