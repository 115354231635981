import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteRole, getRoles } from "../../services/rolesService";

const DeleteModal = ({
  data,
  onClose
}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const roles = useSelector(state => state.roles);

  console.log(data);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteRole({ id: data.roleId }))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.roles.role_deleted") });
            dispatch(getRoles())
            onClose();
          })
      }}
      isLoading={roles.isLoading}
    />
  )
}

export default DeleteModal