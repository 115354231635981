import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserById } from "../services/usersService";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const UserDetails = () => {


  
  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 48);

  const id = useParams();
  //console.log(id.userId)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserById(id.userId));
  }, [dispatch, id]);
  const users = useSelector((state) => state.users);
  console.log(users.userDetails);
  const { t } = useTranslation();

    
  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <div>
      <Stack spacing={12}>
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.users.full_name")}
            </Text>
            <Text marginBlock={2}>
              {users?.userDetails[0]?.UserName || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.users.full_name")}
          </Text>
          <Text marginBlock={2}>
            {users?.userDetails[0]?.UserFullName || "-"}
          </Text>
        </Box>

    

        <Box borderBottom="1px solid" borderColor="border">
        <Text textTransform="capitalize" color="text">
          {t("pages.users.email")}
        </Text>
        <Text marginBlock={2}>
          {users?.userDetails[0]?.Email|| "-"}
        </Text>
      </Box>


      <Box borderBottom="1px solid" borderColor="border">
      <Text textTransform="capitalize" color="text">
        {t("pages.users.role")}
      </Text>
      <Text marginBlock={2}>
        {users?.userDetails[0]?.Role_Name|| "-"}
      </Text>
    </Box>


    <Box borderBottom="1px solid" borderColor="border">
    <Text textTransform="capitalize" color="text">
      {t("pages.users.TagGroup")}
    </Text>
    <Text marginBlock={2}>
      {users?.userDetails[0]?.TagGroupName|| "-"}
    </Text>
  </Box>

      <Box borderBottom="1px solid" borderColor="border">
      <Text textTransform="capitalize" color="text">
        {t("pages.users.is_active")}
      </Text>
      <Text marginBlock={2}>
        {
            users?.userDetails?.isActive?(
                <div> {t("pages.gifts_cards.active")}</div>
            ):(
                <div> {t("pages.gifts_cards.in_active")}</div>
                )
        }
      </Text>
    </Box>

    
    <Box borderBottom="1px solid" borderColor="border">
    <Text textTransform="capitalize" color="text">
      {t("pages.users.is_admin")}
    </Text>
    <Text marginBlock={2}>
      {
          users?.userDetails?.isActive?(
              <div> True</div>
          ):(
              <div> false</div>
              )
      }
    </Text>
  </Box>

  <Box borderBottom="1px solid" borderColor="border">
  <Text textTransform="capitalize" color="text">
    {t("pages.users.app_access")}
  </Text>
  <Text marginBlock={2}>
    {
        users?.userDetails?.isAppAccess?(
            <div> True</div>
        ):(
            <div> false</div>
            )
    }
  </Text>
</Box>


<Box borderBottom="1px solid" borderColor="border">
<Text textTransform="capitalize" color="text">
  {t("pages.users.console_access")}
</Text>
<Text marginBlock={2}>
  {
      users?.userDetails?.isConsoleAccess?(
          <div> True</div>
      ):(
          <div> false</div>
          )
  }
</Text>
</Box>

        </SimpleGrid>
      </Stack>
    </div>
  );
};

export default UserDetails;
