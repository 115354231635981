import React from "react";
import { useTranslation } from "react-i18next";
import * as FiIcons from "react-icons/fi";
import { Box, Flex, Heading, useToast, Button, Modal } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { getPurchaseOrdersDetails, submitPurchaseOrderReview } from "../../services/purchasrOrderServices";

export default function SubmitPurchaseOrder({ onClose, id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({position: "top", duration: 3000, status: "success",});

  return <>
    <>
      <GModal
        modalHeader={t("general.confirm")}
        onClose={onClose}
        modalContent={
          <Box p={6}>
            <Heading
              as="h4"
              fontSize="1.3rem"
              mb={6}
              textTransform="capitalize"
            >
              {t("general.submit_item")}
            </Heading>
            <Flex gap={4}>
              <GButton
                title={t("general.yes")}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                onClick={(_) => {
                  dispatch(submitPurchaseOrderReview(id))
                    .unwrap()
                    .then((_) => {
                      toast({description:  t("pages.purchase_orders.purchase_orders_submitted") });
                      dispatch(getPurchaseOrdersDetails(id));
                      onClose();
                    });
                }}
              />
              <GButton
                title={t("general.cancel")}
                bg="none"
                color="dark"
                border="1px solid"
                borderColor="border"
                hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                onClick={onClose}
              />
            </Flex>
          </Box>
        }
      />
    </>
</>;
}
