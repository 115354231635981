import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import UpdateModal from "../components/modals/UpdateModal";
import GButton from "components/ui/button/Button";
import {
  deactivateComboById,
  getComboById,
  getInactiveBranhesById,
} from "../services/combosService";
import ComboSize from "../components/ComboSize";
import DeleteCombo from "../components/DeleteCombo";
import DeactivateComboInSome from "../components/DeactivateComboInSome";
import CustomPrice from "../components/CustomPrice";
import { getGroupofSize } from "../services/comboSizeService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Details = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const { comboId } = useParams();
  const dispatch = useDispatch();
  const combos = useSelector((state) => state.combos);
  
  const { t } = useTranslation();

  const combo = combos.combo;

  const [showUpdateComboModal, setShowUpdateComboModal] = useState(null);

  useEffect(() => {
    dispatch(getComboById({ comboId }));
  }, [dispatch, comboId]);

  useEffect(() => {
    dispatch(getInactiveBranhesById(comboId));
  }, [dispatch, comboId]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


 
  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }
 
  if(showUpdateComboModal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowUpdateComboModal(false)
  }

  if (combos.isLoading) return <Spinner />;

  return combo ? (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <Box as="p" fontSize="1.6rem" textTransform="capitalize">
              {combo?.ComboName || "-"}
            </Box>
            {/*<Button
              // title=
              bg={combo.IsActive ? "#00D1D1" : "red"}
              color="light"
              _hover={"none"}
              style={{ cursor: "default" }}
            >
              <Text>
                {combo.IsActive
                  ? t("general.activated")
                  : t("general.inactive")}
              </Text>
            </Button>*/}
          </Flex>

          <Box>
          {/*<GButton
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
              title={
                combo.IsActive ? t("general.deactive") : t("general.active")
              }
              hover={{ bg: "primary" }}
              style={{ margin: "10px" }}
              //onClick={() => dispatch(deactivateComboById(comboId))}
            />*/}

            {/* <Flex alignItems="center" gap={1}>
          
        </Flex> */}
            {/* </Button> */}

            <GButton
              title={t("general.edit")}
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() =>
                setShowUpdateComboModal({ ...combo, ComboId: +comboId || null })
              }
            />
          </Box>
        </Flex>
      </BreadCrumb>
      <Stack spacing={12}>
        {/* details */}
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.combos.name")}
            </Text>
            <Text marginBlock={2}>{combo?.ComboName || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.combos.name_localized")}
            </Text>
            <Text marginBlock={2}>{combo?.ComboNameLoc || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.combos.sku")}
            </Text>
            <Text marginBlock={2}>{combo?.ComboSUK || "-"}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.combos.category")}
            </Text>
            <Text marginBlock={2}>
              {combo?.ProductCategoriesNameEng || "-"}
            </Text>
          </Box>
        </SimpleGrid>

        <ComboSize ></ComboSize>
        <DeactivateComboInSome combos={combos}></DeactivateComboInSome>
          <CustomPrice></CustomPrice>
        <DeleteCombo></DeleteCombo>
      </Stack>

      {showUpdateComboModal && (
        <UpdateModal
          data={showUpdateComboModal}
          onClose={() => setShowUpdateComboModal(null)}
          comboId={comboId}
        />
      )}
    </>
  ) : (
    <Flex
      bg="light"
      alignItems="center"
      justifyContent="center"
      p={4}
      textTransform="capitalize"
    >
      {t("pages.combos.no_combo")}
    </Flex>
  );
};

export default Details;
