import { createSlice } from "@reduxjs/toolkit";
import { addItemsToTransfers, createTransfers, deleteInventoryTransfers, editTransfersQuantityItems, 
  getBranchesDropDown, getItemDropdown, getTransferItems, getTransfersFilteredData, getTransfersList, 
  rejectedRecieveTransfers, resetTransfersFilteredData, sendTransfers, updateTransfers } from "../services/transfersServices";

let initialState = {
    isLoading: false,
    errors: null,

    data: [],
    TransfersItemsList: [],
    transfersDetails: [],
    BranchesDropDown: [],
    ItemsDropDown: [],

    filteredData:[],
  };

  const TransfersSlice = createSlice({
    name: "transfers",
    initialState,

    extraReducers:(builder)=>{
      /* ------------------------------------------------------------  get Transfers List  ----------------------------------------------------------*/
      builder.addCase(getTransfersList.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getTransfersList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getTransfersList.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------- Get Branches DropDown List  -------------------------------------------------------*/
      builder.addCase(getBranchesDropDown.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getBranchesDropDown.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.BranchesDropDown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getBranchesDropDown.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Crate New Transfers  -------------------------------------------------------*/
    builder.addCase(createTransfers.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(createTransfers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(createTransfers.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Update Transfers  -------------------------------------------------------*/
      builder.addCase(updateTransfers.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(updateTransfers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(updateTransfers.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------- Get Items DropDown List  -------------------------------------------------------*/
    builder.addCase(getItemDropdown.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })

    builder.addCase(getItemDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ItemsDropDown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })

    builder.addCase(getItemDropdown.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ----------------------------------------------------------- Add Items  To Transfers  -------------------------------------------------------*/
    builder.addCase(addItemsToTransfers.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })

    builder.addCase(addItemsToTransfers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })

    builder.addCase(addItemsToTransfers.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ----------------------------------------------------------- Get Transfers Items List  -------------------------------------------------------*/
    builder.addCase(getTransferItems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })

    builder.addCase(getTransferItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TransfersItemsList = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })

    builder.addCase(getTransferItems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ----------------------------------------------------------------- Send Transfers  -------------------------------------------------------*/
    builder.addCase(sendTransfers.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })

    builder.addCase(sendTransfers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })

    builder.addCase(sendTransfers.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ----------------------------------------------------------------- Recieve/Rejected Transfers  -------------------------------------------------------*/
    builder.addCase(rejectedRecieveTransfers.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })

    builder.addCase(rejectedRecieveTransfers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })

    builder.addCase(rejectedRecieveTransfers.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ----------------------------------------------------------------- Recieve/Rejected Transfers  -------------------------------------------------------*/
    builder.addCase(editTransfersQuantityItems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })

    builder.addCase(editTransfersQuantityItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })

    builder.addCase(editTransfersQuantityItems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ----------------------------------------------------------------- Delete inventory Categories   -------------------------------------------------------*/
      builder.addCase(deleteInventoryTransfers.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(deleteInventoryTransfers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(deleteInventoryTransfers.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------------- Restore Inventory Categories  -------------------------------------------------------*/
    //   builder.addCase(restoreInventoryCategories.pending, (state) => {
    //     state.isLoading = true;
    //     state.errors = null;
    //   })

    //   builder.addCase(restoreInventoryCategories.fulfilled, (state, { payload }) => {
    //     state.isLoading = false;
    //     state.errors = null;
    //   })

    //   builder.addCase(restoreInventoryCategories.rejected, (state) => {
    //     state.isLoading = false;
    //     state.errors = [{ msg: "something went wrong!" }];
    //   })

         /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
    builder.addCase(getTransfersFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTransfersFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getTransfersFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetTransfersFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetTransfersFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetTransfersFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
    }
  })


  export default TransfersSlice.reducer;