import React from "react";
import { useTranslation } from "react-i18next";
import routes from "navigations/routes";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";


export default function SalesReports(){



  const { t, i18n } = useTranslation();
  const getArrowIcon = () => {
    return i18n.language === "ar" ? (
      <MdKeyboardArrowLeft size={25} /> // Right arrow for Arabic
    ) : (
      <MdKeyboardArrowRight size={25} /> // Left arrow for English
    );
  };

  const items = [
    {
      id: 1,
      text: t("pages.reports.sales_reports.sales_reports"),
      icon: getArrowIcon(),
      path: routes.reports_sales_report_sales,
    },
    {
      id: 2,
      text: t("pages.reports.sales_reports.payment_reports"),
      icon: getArrowIcon(),
      path: routes.reports_sales_payment_reports,
    },
    // {
    //   id: 3,
    //   text: t("pages.order_transactions.order_transactions"),
    //   icon: getArrowIcon(),
    //   path: routes.inventory_order_transactions,
    // },
    // {
    //   id: 4,
    //   text: t("pages.warehouse.warehouse"),
    //   icon: getArrowIcon(),
    //   path: routes.inventory_warehouse,
    // },
    // {
    //   id: 4,
    //   text: t("pages.inventoryCategories.inventoryCategories"),
    //   icon: getArrowIcon(),
    //   path: routes.inventory_Categories,
    // },
  ];



  return (
    <>
      <BreadCrumb>
        <Box as="p" fontSize="1.6rem" textTransform="capitalize">
          {t("pages.inventory.inventory")}
        </Box>
      </BreadCrumb>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
        {items.map((item) => (
          <Flex
            key={item.id}
            as={NavLink}
            to={`/${item.path}`}
            gap={2}
            alignItems="center"
            justifyContent="center"
            bg="light"
            p={4}
            borderRadius={4}
            paddingBlock={6}
            color="text"
            textTransform="capitalize"
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
          >
            {item.text}
            {item.icon}
          </Flex>
        ))}
      </SimpleGrid>
    </>
  );
};

