import React, { useState } from 'react';
import { Box,Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import * as FiIcons from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Pagination from "components/ui/pagination/Pagination";
import AddChargeModal from './modal/AddChargeModal';


export default function DeliveryChargeTable({data}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showmodal,setshowmodal]=useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
/* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
        return data?.slice(firstIndex, lastIndex);
      };
    
    // Calculate records for activeDiscounts
    const deliveryCharageData = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
 /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <Box
        bg="light" overflow="auto"
        borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >  
      <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>{t("pages.DeliverCharge.from_branch")}</Th>
              <Th>{t("pages.DeliverCharge.DeliveryZoneName")}</Th>
              <Th>{t("pages.DeliverCharge.charge")}</Th>
              <Th> Actions </Th>
            </Tr>
          </Thead>
          <Tbody>
              {
              deliveryCharageData?.map((item, index) => (
                <Tr key={index}  style={{cursor:"pointer"}}>
                <Td> {index + 1} </Td>
                <Td onClick={()=>setshowmodal(true)}  >{item?.BranchName}</Td>
                <Td onClick={()=>setshowmodal(true)}  >{item?.DeliveryZoneName}</Td>
                <Td>{item?.Charge}</Td>
                <Td >  
                      <GButton
                            postfixElement={<FiIcons.FiPlus />}
                            margin={"0px 5px"}
                            bg="blue"
                            color="light"
                            border="1px solid transparent"
                            hover={{ bg: "none", borderColor: "primary", color: "primary" }}
                            onClick={() => setShowAddModal(true)}
                      />
                      <GButton
                            postfixElement={<FiIcons.FiEdit />}
                            margin={"0px 5px"}
                            bg="primary"
                            color="light"
                            border="1px solid transparent"
                            hover={{ bg: "none", borderColor: "primary", color: "primary" }}
                            onClick={() => setShowAddModal(true)}
                      />
                </Td>
              
              </Tr>
              ))
              }
          </Tbody>
      </Table>
      <Pagination
              currentPage={currentPageActive}
              setCurrentPage={setCurrentPageActive}
              npage={Math.ceil(data?.length / recordsPerPage)}
              prePage={() => prePage(currentPageActive, setCurrentPageActive)}
              nextPage={() =>
                nextPage(
                  currentPageActive,
                  setCurrentPageActive,
                  Math.ceil(data?.length / recordsPerPage)
                  )
                }
      />
    </Box>
    { showAddModal&&(
        <AddChargeModal  onClose={() => setShowAddModal(false)} >  </AddChargeModal >
    )}
  </>
};
