import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import CustomPriceModal from "./modals/CustomPriceModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomPriceBranches } from "../services/comboSizeService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


const CustomPrice = () => {


  
  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);

  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  // <Flex
  // p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
  // boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  // >
  // {t("pages.branches.no_data")}
  // </Flex>

  const id = useParams()
  //console.log(id.comboId)

  const dispatch=useDispatch()

  useEffect(()=>{
    dispatch(getCustomPriceBranches(id.comboId))
  },[dispatch,id])


  const comboSize = useSelector((state) => state.comboSize);


  //console.log(comboSize.data.length)
  //console.log(comboSize.groupofSize.length)

//console.log(comboSize.customPriceBranchesData)


const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


if(showModal && ManageMenu===false){
  toast2({ description:  t("general.authorization")})
  setShowModal(false)
}

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.combos.custom_price")}
        </Text>
        {
          comboSize.data.length >0 && comboSize.groupofSize.length >0 ?(  <GButton
            title={t("pages.combos.select_branches")}
            bg="light"
            color="text"
            fontSize="14px"
            border="1px solid"
            borderColor="border"
            hover={{ bg: "light" }}
            onClick={() => setShowModal(true)}
          />):(null)
        
        }
    
      </Flex>

      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Table>
          <Thead>
            <Tr>
              <Th>{t("pages.combos.size")}</Th>
              <Th>{t("pages.combos.group")}</Th>
              <Th>{t("pages.combos.price")}</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>{comboSize?.customPriceBranchesData.ComboSizeName}</Td>
              <Td>{comboSize?.customPriceBranchesData.ComboSizeGroupName}</Td>
              <Td>{comboSize?.customPriceBranchesData.Price}</Td>

            </Tr>
          </Tbody>
        </Table>
      </Box>

      {showModal && <CustomPriceModal onClose={() => setShowModal(false)} />}
    </Box>
  );
};

export default CustomPrice;
