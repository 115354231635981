import React from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, Heading , Stack, useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getQuantityAdjustmentDetails, submitQuantityAdjustment } from '../../services/quantityAdjustmentService';

const SubmitingModal = ({ onClose }) => {
    const { t } = useTranslation();
    const id =useParams()
    const dispatch=useDispatch()
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const { handleSubmit} = useForm();

    return <>

        <GModal
            modalHeader={t("pages.transfers.confirm")}
            onClose={onClose}
            modalContent={
            <Box p={6}>
                <Heading
                as="h4"
                fontSize="1.3rem"
                mb={6}
                textTransform="capitalize"
                >
                {t("pages.quantity_adjustment.submit_text")}
                </Heading>
                <Flex gap={4}>
                <GButton
                    title={t("general.yes")}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                    onClick={(_) => {
                        dispatch(submitQuantityAdjustment(id.id)).
                        unwrap().
                        then(_ => {
                            toast({ description: t("pages.quantity_adjustment.quantity_djustment_submitted") });
                            dispatch(getQuantityAdjustmentDetails(id.id));
                            onClose()
                          });
                    }}
                />
                <GButton
                    title={t("general.cancel")}
                    bg="none"
                    color="dark"
                    border="1px solid"
                    borderColor="border"
                    hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                    onClick={onClose}
                />
                </Flex>
            </Box>
            }
      />

    </>;
}

export default SubmitingModal;
