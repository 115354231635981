import { useState } from "react";
import { Table,Thead,Tbody,Td,Th, Tr,} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";

export default function VoidsReturnsDataTable({ data }) {
  const { t } = useTranslation();

/*--------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const voidReturnsData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return (
    <>
      <>
        <Table>
          <Thead>
            <Tr>
              <Th>{t("pages.reports.reports_tables.product")}</Th>
              <Th>{t("pages.reports.reports_tables.branch")}</Th>
              <Th>{t("pages.reports.reports_tables.branch_reference")}</Th>
              <Th>{t("pages.reports.reports_tables.type")}</Th>
              <Th>{t("pages.reports.reports_tables.user")}</Th>
              <Th>{t("pages.reports.reports_tables.order_reference")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {voidReturnsData?.map((item, index) => (
              <Tr key={index}>
                <Td>{item?.ProductName}</Td>
                <Td>{item?.BranchName}</Td>
                <Td> {item?.BranchRef} </Td>
                <Td> {item?.Type} </Td>
                <Td> {item?.User} </Td>
                <Td> {item?.OrderRef} </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(data?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(data?.length / recordsPerPage)
            )
          }
        />
      </>
    </>
  );
}
