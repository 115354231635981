import { useTranslation } from "react-i18next"
import { Box } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import GButton from "components/ui/button/Button";
import Input from "components/form/Input";
import {  Flex, Stack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { getPaymentMethodFilteredData } from "../../services/paymentMethodsService";
import { useDispatch } from "react-redux";
const FilterModal = ({
  onClose,
  data
}) => {
  const { t } = useTranslation();

  const [filteredData, setFilteredData] = useState(data);

  // console.log(filteredData)
  const [filterCriteria, setFilterCriteria] = useState({
    PaymentMethodName:""
  });

  const dispatch = useDispatch();

  const handleFilterPaymentMethod = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const { PaymentMethodName } = filterCriteria;

      let filterPaymentMethodName = true;

      if (PaymentMethodName) {
        filterPaymentMethodName = item?.PaymentMethodName?.toLowerCase().includes(PaymentMethodName.toLowerCase());
      }

      return (
        filterPaymentMethodName 
         
      );

    });

    setFilteredData(filteredResult);
    dispatch(getPaymentMethodFilteredData(filteredResult));
    onClose();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.payment_methods.filter_payment_methods")}
      modalContent={(
        <Box p={4}>
          <form 
          onSubmit={handleFilterPaymentMethod}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.payment_methods.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.payment_methods.name")}
                name="PaymentMethodName"
                value={filterCriteria.PaymentMethodName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    PaymentMethodName: e.target.value,
                  })
                }
              />
             

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default FilterModal