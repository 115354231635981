const { createSlice } = require("@reduxjs/toolkit");
const {
  getEmployeeDropDown,
  getRequestTypeDropDown,
  addNewLeaveRequest,
  getVactionChangeById,
  getBankLetterData,
  getBankDropDown,
  createNewBankLetter,
  viewBankLetterById,
  createNewHrLetter,
  getHrLetterData,
  viewHrLetterById,
} = require("../services/HrSelfServices");

let initialState = {
  isLoading: false,
  isLoadingRequestType: false,
  pages: 0,
  errors: null,

  // List
  BankLetterList: [],
  HrLetterList: [],
  // Details
  vacationDetailsChangeById: [],
  detailsBankLetterById: [],
  detailsHrLetterById: [],
  // DropDowns
  employeeDropdown: [],
  RequestTypeDropdown: [],
  BankDropdown: [],

  // Filter data
  filteredData: [],
};

const hrSelfServicesSlice = createSlice({
  name: "selfservices",
  initialState,
  extraReducers: (builder) => {
    // /* -------------------  Get Eployee DeropDown List ---------------------*/
    builder.addCase(getEmployeeDropDown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getEmployeeDropDown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeDropdown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getEmployeeDropDown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  get RequestType DropDown---------------------*/
    builder.addCase(getRequestTypeDropDown.pending, (state, { payload }) => {
      state.isLoadingRequestType = true;
      state.errors = null;
    });
    builder.addCase(getRequestTypeDropDown.fulfilled, (state, { payload }) => {
      state.isLoadingRequestType = false;
      state.RequestTypeDropdown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getRequestTypeDropDown.rejected, (state, { payload }) => {
      state.isLoadingRequestType = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Get Vacation details By Change Employee Id or Vacation Id  ---------------------*/
    builder.addCase(getVactionChangeById.pending, (state, { payload }) => {
      //   state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getVactionChangeById.fulfilled, (state, { payload }) => {
      //   state.isLoading = false;
      state.vacationDetailsChangeById =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getVactionChangeById.rejected, (state, { payload }) => {
      //   state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Add New LeaveRequest ---------------------*/
    builder.addCase(addNewLeaveRequest.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewLeaveRequest.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //   state.RequestTypeDropdown =
      //     (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(addNewLeaveRequest.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* ------------------------------------------------------  Bank Letter --------------------------------------------------------*/
    // /* -------------------  Get Bank Letter ---------------------*/
    builder.addCase(getBankLetterData.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getBankLetterData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.BankLetterList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getBankLetterData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Get Bank LookUp ---------------------*/
    builder.addCase(getBankDropDown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getBankDropDown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.BankDropdown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getBankDropDown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Create New Bank Letter ---------------------*/
    builder.addCase(createNewBankLetter.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createNewBankLetter.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.BankDropdown =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(createNewBankLetter.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  View Bank Letter By Id ---------------------*/
    builder.addCase(viewBankLetterById.pending, (state, { payload }) => {
      // state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(viewBankLetterById.fulfilled, (state, { payload }) => {
      // state.isLoading = false;
      state.detailsBankLetterById =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(viewBankLetterById.rejected, (state, { payload }) => {
      // state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Create New HR Letter ---------------------*/
    builder.addCase(getHrLetterData.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getHrLetterData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.HrLetterList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getHrLetterData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  Create New HR Letter ---------------------*/
    builder.addCase(createNewHrLetter.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createNewHrLetter.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.detailsBankLetterById =
      //   (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(createNewHrLetter.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    // /* -------------------  View Hr Letter By Id---------------------*/
    builder.addCase(viewHrLetterById.pending, (state, { payload }) => {
      // state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(viewHrLetterById.fulfilled, (state, { payload }) => {
      // state.isLoading = false;
      state.detailsHrLetterById =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(viewHrLetterById.rejected, (state, { payload }) => {
      // state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default hrSelfServicesSlice.reducer;
