import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* -------------------------------------------- Get CostAdjustment Reports List ------------------------------------ */
export const getInventoryHistoryList = createAsyncThunk(
  "reportsInventoryHistory/getInventoryHistoryList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Reports/GetInventoryHistoryList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* --------------------------------------- Menu Engineering Filter Data ------------------------------*/

export const getInventoryHistoryFilteredData = createAsyncThunk(
  "reportsInventoryHistory/getInventoryHistoryFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetInventoryHistoryFilteredData = createAsyncThunk(
  "reportsInventoryHistory/resetInventoryHistoryFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
