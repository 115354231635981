import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deletePaymentMethod, getPaymentMethods } from "../../services/paymentMethodsService";

const DeleteModal = ({ data, onClose}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const paymentMethods = useSelector(state => state.paymentMethods);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        let values = {
          isDeleted: true,
          PaymentMethodId: data.PaymentMethodId
        }
        dispatch(deletePaymentMethod({ values }))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.payment_methods.payment_method_deleted") });
            onClose();
          }).then(dispatch(getPaymentMethods()));
      }}
      isLoading={paymentMethods.isLoading}
    />
  )
}

export default DeleteModal