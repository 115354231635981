import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewEmployeeContractInfo,
  getEmployeeList,
} from "features/hr/employees/services/employeeServices";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  getLookupRspTypeDropDownById,
  getScalePointDropDownById,
} from "features/hr/employees/services/generalEmployeeServices";

export default function AddContractInfo({ setshowCompoNumber }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const { PositionDropdown, ReportingToPositionDropdown } = useSelector(
    (state) => state.positionsSlice
  );
  const data = useSelector((state) => state.employeeSlice);
  const {
    HomeLeaveType,
    BouncesDropDown,
    LeaveTypeDropDown,
    ScalePointDropDown,
    RspTypeDropDown,
  } = useSelector((state) => state.generalEmployee);

  /*-------------------------------------------- State  ------------------------------------------------*/
  const [checkBoxStatus, setcheckBoxStatus] = useState(false);
  const [percentage, setPercentage] = useState(1);
  const handleChange = (event) => {
    let value = parseInt(event.target.value, 10);
    if (value < 1) value = 1;
    if (value > 100) value = 100;
    setPercentage(value);
  };

  /*-------------------------------------------- Form Handeling ------------------------------------------------*/
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
      setError("ParLevel", {
        type: "manual",
        message: t("validation.must_be_within_min_max_range"),
      });
    } else {
      clearErrors("ParLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };
  /*-------------------------------------------- UseEffects ------------------------------------------------*/

  useEffect(() => {
    dispatch(
      getScalePointDropDownById(data?.NewEmployeeId?.HiringNewEmployeeRequestId)
    );
  }, [dispatch, data?.NewEmployeeId?.HiringNewEmployeeRequestId]);

  useEffect(() => {
    dispatch(
      getLookupRspTypeDropDownById(
        data?.NewEmployeeId?.HiringNewEmployeeRequestId
      )
    );
  }, [dispatch, data?.NewEmployeeId?.HiringNewEmployeeRequestId]);

  /*-------------------------------------------- Return ------------------------------------------------*/
  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.UserId = localStorage.getItem("userId");
          //   values.HiringNewEmployeeRequestId = 4094;
          values.HiringNewEmployeeRequestId =
            data?.NewEmployeeId?.HiringNewEmployeeRequestId;
          values.Step = 6;
          //
          values.StartDateofContract = `${values.StartDateofContract} 00:00:00`;
          values.EndDateofContract = `${values.EndDateofContract} 00:00:00`;
          //
          values.ISPartTime = checkBoxStatus ? 1 : 0;
          values.Percent = checkBoxStatus ? percentage : "";
          //
          values.HomeLeaveTypeId = values.HomeLeaveTypeId?.HomeLeaveTypeId;
          values.LeaveTypeId = values.LeaveTypeId?.LeaveTypeId;
          //
          values.BonusId = values.BonusId?.BonusId;
          values.PositionId = values.PositionId?.PositionId;
          values.ReportingToPositionId =
            values.ReportingToPositionId?.ReportingToId;
          values.RspTypeId = values.RspTypeId?.RspTypeId;
          values.ScalePointId = values.ScalePointId?.ScalePointId;
          if (
            values.HomeLeaveTypeId === undefined ||
            values.BonusId === undefined ||
            values.PositionId === undefined ||
            values.RspTypeId === undefined ||
            values.ScalePointId === undefined ||
            values.ReportingToPositionId === undefined
          ) {
            toast2({ description: t("validation.choose_first") });
          } else {
            dispatch(addNewEmployeeContractInfo(values))
              .unwrap()
              .then((res) => {
                toast({
                  description: t("pages.hr.new_employee_contract_Added"),
                });
                dispatch(getEmployeeList());
                setshowCompoNumber(7);
              });
          }
        })}
      >
        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Text
            color="dark"
            textTransform="capitalize"
            fontSize="xl"
            fontWeight={"bold"}
          >
            {t("pages.hr.contract_info")}
          </Text>
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <Box>
              <MultiSelect
                title={t("pages.hr.Position")}
                isMulti={false}
                data={
                  PositionDropdown &&
                  PositionDropdown?.map((el) => ({
                    ...el,
                    label: el.Position,
                    value: el.PositionId,
                  }))
                }
                onChange={(e) => setValue("PositionId", e)}
              />
            </Box>

            <Box>
              <MultiSelect
                title={t("pages.hr.reporting_to")}
                isMulti={false}
                data={
                  ReportingToPositionDropdown &&
                  ReportingToPositionDropdown?.map((el) => ({
                    ...el,
                    label: el.ReportingTo,
                    value: el.ReportingToId,
                  }))
                }
                onChange={(e) => setValue("ReportingToPositionId", e)}
              />
            </Box>

            <Box>
              <MultiSelect
                title={t("pages.hr.scale_point")}
                isMulti={false}
                data={
                  ScalePointDropDown &&
                  ScalePointDropDown?.map((el) => ({
                    ...el,
                    label: el.ScalePointDefinition,
                    value: el.ScalePointId,
                  }))
                }
                onChange={(e) => setValue("ScalePointId", e)}
              />
            </Box>

            <FormControl isInvalid={errors.NetAnnualSalary}>
              <FormLabel
                htmlFor="NetAnnualSalary"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.NetAnnualSalary")}
              </FormLabel>
              <Controller
                name="NetAnnualSalary"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("pages.hr.hr_validation.number_long_2"),
                  },
                  maxLength: {
                    value: 15,
                    message: t("pages.hr.hr_validation.number_long"),
                  },
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.NetAnnualSalary")}
                    onChange={(e) => {
                      handleInputChange(e, "NetAnnualSalary");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.NetAnnualSalary && errors.NetAnnualSalary.message}
              </FormErrorMessage>
            </FormControl>

            <Box>
              <MultiSelect
                title={t("pages.hr.rsp")}
                isMulti={false}
                data={
                  RspTypeDropDown &&
                  RspTypeDropDown?.map((el) => ({
                    ...el,
                    label: el.RspCode,
                    value: el.RspTypeId,
                  }))
                }
                onChange={(e) => setValue("RspTypeId", e)}
              />
            </Box>

            <FormControl isInvalid={errors.NetRSPAnnual}>
              <FormLabel
                htmlFor="NetRSPAnnual"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.NetRSPAnnual")}
              </FormLabel>
              <Controller
                name="NetRSPAnnual"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("pages.hr.hr_validation.number_long_2"),
                  },
                  maxLength: {
                    value: 15,
                    message: t("pages.hr.hr_validation.number_long"),
                  },
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.NetRSPAnnual")}
                    onChange={(e) => {
                      handleInputChange(e, "NetRSPAnnual");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.NetRSPAnnual && errors.NetRSPAnnual.message}
              </FormErrorMessage>
            </FormControl>

            <Box>
              <MultiSelect
                title={t("pages.hr.HomeLeaveType")}
                isMulti={false}
                data={
                  HomeLeaveType &&
                  HomeLeaveType?.map((el) => ({
                    ...el,
                    label: el.HomeLeaveName,
                    value: el.HomeLeaveTypeId,
                  }))
                }
                onChange={(e) => setValue("HomeLeaveTypeId", e)}
              />
            </Box>

            <Box>
              <MultiSelect
                title={t("pages.hr.leave_type")}
                isMulti={false}
                data={
                  LeaveTypeDropDown &&
                  LeaveTypeDropDown?.map((el) => ({
                    ...el,
                    label: el.LeaveTypeName,
                    value: el.LeaveTypeId,
                  }))
                }
                onChange={(e) => setValue("LeaveTypeId", e)}
              />
            </Box>

            <Box>
              <MultiSelect
                title={t("pages.hr.bounces")}
                isMulti={false}
                data={
                  BouncesDropDown &&
                  BouncesDropDown?.map((el) => ({
                    ...el,
                    label: el.BonusName,
                    value: el.BonusId,
                  }))
                }
                onChange={(e) => setValue("BonusId", e)}
              />
            </Box>

            <FormControl isInvalid={errors.StartDateofContract}>
              <FormLabel
                htmlFor="StartDateofContract"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.contract_start_date")}
              </FormLabel>
              <Controller
                name="StartDateofContract"
                control={control}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.contract_start_date")}
                    onChange={(e) => {
                      handleInputChange(e, "StartDateofContract");
                    }}
                    type="date"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.StartDateofContract &&
                  errors.StartDateofContract.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.EndDateofContract}>
              <FormLabel
                htmlFor="EndDateofContract"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.contract_end_date")}
              </FormLabel>
              <Controller
                name="EndDateofContract"
                control={control}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.contract_end_date")}
                    onChange={(e) => {
                      handleInputChange(e, "EndDateofContract");
                    }}
                    type="date"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.EndDateofContract && errors.EndDateofContract.message}
              </FormErrorMessage>
            </FormControl>

            <Box>
              <Checkbox
                checked={checkBoxStatus}
                onChange={(e) => setcheckBoxStatus(e.target.checked)}
              >
                {t("pages.hr.partime")}
              </Checkbox>
              {checkBoxStatus && (
                <Input
                  placeholder={t("pages.hr.ISPartTime")}
                  type="number"
                  value={percentage}
                  onChange={handleChange}
                  min="1"
                  max="100"
                />
              )}
            </Box>
          </SimpleGrid>
        </Box>

        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Flex gap={2} flexWrap="wrap">
            <GButton
              title={t("pages.profile.save_changes")}
              color="light"
              border="1px solid transparent"
              bg="primary"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              type="submit"
            />
          </Flex>
        </Box>
      </form>
    </>
  );
}
