import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr,Flex } from "@chakra-ui/react";
import GButton from "../../../../components/ui/button/Button";
import * as FiIcons from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FilterModal from './Modals/FilterModal';
import { resetPriceTagsFilteredData } from '../services/priceTagsServices';
import Pagination from "components/ui/pagination/Pagination";
import { Spinner } from '@chakra-ui/react';

export default function PriceTagsTable({data}) {
    const { t } = useTranslation();
    const navigate= useNavigate();
    const dispatch = useDispatch();

    const PriceTagsData = useSelector(state => state.priceTags);

    const [showFilterModal, setShowFilterModal] = useState(false);

    const navigateToDetails = (args)=>{
        navigate(`${args}`)
    }

    const resetFunction = () => {
      dispatch(resetPriceTagsFilteredData());
    };

    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
    const recordsPerPage = 10;
    const [currentPageActive, setCurrentPageActive] = useState(1);
    const prePage = (currentPage, setCurrentPage) => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const nextPage = (currentPage, setCurrentPage, npage) => {
      if (currentPage < npage) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const calculateRecords = (data, currentPage, recordsPerPage) => {
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
      return data?.slice(firstIndex, lastIndex);
    };
  
  // Calculate records for activeDiscounts
  const priceTagsTableData = calculateRecords(
      data,
      currentPageActive,
      recordsPerPage
    );
/* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>

    <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
    >
            <Flex
            alignItems="center" flexWrap="wrap"
            textTransform="capitalize" gap={4} color="text" bg="light"
            >
            </Flex>


            <GButton
                title={
                    PriceTagsData?.filteredData?.length === 0
                    ? t("general.filter")
                    : t("general.clear_filter")
                }
                border="1px solid"
                borderColor="border"
                postfixElement={
                    PriceTagsData?.filteredData?.length === 0 ? (
                    <FiIcons.FiFilter size={16} />
                    ) : (
                    <IoCloseOutline fontWeight={800} size={20} />
                    )
                }
                hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                onClick={
                    PriceTagsData?.filteredData?.length === 0 
                    ? () => setShowFilterModal(true)
                    : resetFunction
                }
            />
    </Flex>

    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
        { 
          PriceTagsData?.isLoading  && ( 
          <Flex p={4}  bg="white"  alignItems="center" justifyContent="center">
              <Spinner /> 
          </Flex>)
        }
      <Table>
        <Thead>
          <Tr>
            <Th> # </Th>
            <Th>{t("pages.price_tags.name")}</Th>
            <Th>{t("pages.price_tags.created")}</Th>
          </Tr>
        </Thead>
        
        <Tbody>
        { PriceTagsData?.filteredData?.length > 0 ? 
            <>
            {PriceTagsData?.filteredData?.map((el, index) => (
                <Tr key={index}>
                    <Td style={{cursor:"pointer"}}>{index + 1}</Td>
                    <Td style={{cursor:"pointer"}}>{el.PriceTagsName}</Td>
                    <Td style={{cursor:"pointer"}}>{el.CreateDate}</Td>
                </Tr>
            ))}
            </>
            :
            <>
            {priceTagsTableData.map((el, index) => (
                <Tr key={index} onClick={()=>navigateToDetails(el.PriceTagsId)} >
                    <Td style={{cursor:"pointer"}}>{index + 1}</Td>
                    <Td style={{cursor:"pointer"}}>{el.PriceTagsName}</Td>
                    <Td style={{cursor:"pointer"}}>{el.CreateDate}</Td>
                </Tr>
            ))}
            </>
        }
        </Tbody>
      </Table>
      <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </Box>
      
    {showFilterModal && (
          <FilterModal 
              onClose={()=>setShowFilterModal(false)} 
              data={PriceTagsData?.data} 
          />
        )
    }
  </>
};
