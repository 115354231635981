import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getTagsInBranches = createAsyncThunk("sectiones/getTagsInBranches", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/TagsByBranch?branchid={"BranchId":${args}}`);
      //console.log("now get tags")
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getBranchesTags = createAsyncThunk("sectiones/getBranchesTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/LookupTags`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createBranchTag = createAsyncThunk("sectiones/createBranchTag", async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Branches/AddEditBranchTags?tagbranch=${JSON.stringify(args)}`);
      //console.log("now create tag")
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateBranchTag = createAsyncThunk("sectiones/updateBranchTag", async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Sectiones/AddEditSection?section=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);