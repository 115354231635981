import { NavLink } from "react-router-dom"
import { Box, SimpleGrid } from "@chakra-ui/react"
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb"
import { useTranslation } from "react-i18next"

const Sort = () => {
  const { t } = useTranslation();

  const items = [
    { id: 1, text: "taxes & groups" },
    { id: 2, text: "payment methods" },
    { id: 3, text: "charges" },
    { id: 4, text: "delivery zones" },
    { id: 5, text: "tags" },
    { id: 6, text: "reasons" },
    { id: 7, text: "kitchen flows" },
    { id: 8, text: "reservations" },
    { id: 9, text: "brands" },
    { id: 10, text: "online delivering" },
    { id: 11, text: "price tags" },
    { id: 12, text: "settings" },
  ];

  return (
    <>
      <BreadCrumb>
        <Box
          as="p" fontSize="1.6rem" textTransform="capitalize"
        >
          {t("pages.categories.sort_categories")}
        </Box>
      </BreadCrumb>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
        {items.map(item => (
          <Box key={item.id} as={NavLink} to="/"
            bg="light" p={4} borderRadius={4} paddingBlock={6} color="text"
            textAlign="center" textTransform="capitalize"
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
          >
            {item.text}
          </Box>
        ))}
      </SimpleGrid>
    </>
  )
}

export default Sort