import { Box, Flex, Stack } from '@chakra-ui/react'
import GButton from 'components/ui/button/Button'
import GModal from 'components/ui/modal/Modal'
import React, { useState } from 'react'
import Input from "components/form/Input";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getFilteredData } from 'features/products/products/services/productsService';
import { getFilteredDataBranches } from '../../services/branchesService';

const FilterBranchModal = ({onClose,data}) => {

    const dispatch =useDispatch()

    const [filterCriteria, setFilterCriteria] = useState({
        BranchName: "",
      });

      const handleFilterGiftsCard = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const {
            BranchName,
          } = filterCriteria;
    
    
          let filterByBranchName = true;
        
    
          if (BranchName) {
            filterByBranchName = item.BranchName.toLowerCase().includes(BranchName.toLowerCase());
          }
    
    
    
          return (
            filterByBranchName 
          );
        });
    
        setFilteredData(filteredResult);
        onClose(filteredResult);
        //console.log(filteredResult)
        dispatch(getFilteredDataBranches(filteredResult));
      };
    const [filteredData, setFilteredData] = useState(data);

    const { t } = useTranslation();

    const handleClose = () => {
        onClose(filteredData);
      };

  return (
    <GModal
    onClose={handleClose}
    modalHeader={t("pages.branches.filter_branches")}
    modalContent={
      <Box p={4}>
        <form onSubmit={handleFilterGiftsCard}>
          <Stack spacing={6}>

            <Input
              label={t("pages.gifts_cards.name")}
              labelStyle={{ textTransform: "capitalize" }}
              placeholder={t("pages.gifts_cards.name")}
              name="BranchName"
                value={filterCriteria.BranchName}
                onChange={(e) =>
                    setFilterCriteria({
                    ...filterCriteria,
                    BranchName: e.target.value,
                    })
                }
            />
          


            <Flex gap={4}>
              <GButton
                type="submit"
                title={t("general.save")}
                bg="primary"
                color="light"
                border="1px solid transparent"
                hover={{
                  color: "primary",
                  borderColor: "primary",
                  bg: "none",
                }}
              />
              <GButton
                title={t("general.close")}
                onClick={handleClose}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                hover={{
                  color: "red.600",
                  borderColor: "red.600",
                  bg: "none",
                }}
              />
            </Flex>
          </Stack>
        </form>
      </Box>
    }
  />
  )
}

export default FilterBranchModal
