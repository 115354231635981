import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getVacationTypeList } from "../../services/hrSettingServices";
import VacationTypeSettingTable from "./components/VacationTypeSettingTable";
import AddVacationTypeSetting from "./components/modal/AddVacationTypeSetting";

export default function VacationTypesSetting() {
  const { t } = useTranslation();
  const disPatch = useDispatch();

  const [showAddModal, setShowAddModal] = useState(false);
  const data = useSelector((state) => state.hrSetting);
  console.log(data);

  useEffect(() => {
    disPatch(getVacationTypeList());
  }, [disPatch]);

  return (
    <Box>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.vacationTypeSetting")}
          </Text>

          <Flex gap={2}>
            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => setShowAddModal(true)}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.hr.AddVacationTypeSetting")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>

      <VacationTypeSettingTable
        data={data?.VacationtypeSettingList}
        isLoading={data.isLoading}
        error={data.error}
      />

      {showAddModal && (
        <AddVacationTypeSetting onClose={() => setShowAddModal(false)} />
      )}
    </Box>
  );
}
