import React from 'react';
import { useTranslation } from 'react-i18next';
import GModal from "components/ui/modal/Modal"
import Input from "components/form/Input"
import { Controller , useForm } from "react-hook-form";
import {Box,Checkbox, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GButton from "components/ui/button/Button"
import { useDispatch } from 'react-redux';
import { createKitchenFlow, getAllKitchenFlow } from '../../services/kitchenFlowService';

const CreateKitchenFlowModal = ({onClose}) => {
    const { t } = useTranslation();
    const dispatch=useDispatch();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const { handleSubmit, control, setValue,  register,  formState: { errors },  trigger} =  useForm();

      const handleInputChange = async (e, type) => {
        setValue(type, e.target.value);
        await trigger(type);
      };
    
      const isOnlySpaces = (value) => {
        // Check if the value contains only spaces
        return !value.trim();
      };

  return <>
    <GModal
    

    onClose={onClose}
    modalHeader={t("pages.kitchenFlow.create_kitchen_flow")}

    modalContent={(

        <Box p={4}>
        
        <form onSubmit={handleSubmit(values=>{
            values.KitchenFlowsId=null
            dispatch(createKitchenFlow(values)).unwrap()
            .then(_ => {
              toast({ description: t("pages.kitchenFlow.kitchen_Flow_created") });
              dispatch(getAllKitchenFlow())
              onClose()
            });
        })}>
        
        <Stack spacing={6}>
        
        

        <FormControl isInvalid={errors.KitchenFlowsName}>
                  <FormLabel
                    htmlFor="KitchenFlowsName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.kitchenFlow.name")}
                  </FormLabel>
                  <Controller
                    name="KitchenFlowsName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) || t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.kitchenFlow.name")}
                        onChange={(e) => {
                          handleInputChange(e, "KitchenFlowsName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.KitchenFlowsName &&
                      errors.KitchenFlowsName.message}
                  </FormErrorMessage>
        </FormControl>

  <Flex gap={4}>
  <GButton
    type="submit"
    title={t("general.save")}
    bg="primary"
    color="light"
    border="1px solid transparent"
    //isLoading={devices.isLoading}
    hover={{ color: "primary", borderColor: "primary", bg: "none" }}
  />
  <GButton
    title={t("general.close")}
    onClick={onClose}
    bg="red.600"
    border="1px solid transparent"
    color="light"
    //isLoading={devices.isLoading}
    hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
  />
</Flex>



        </Stack>
        
        
        </form>

        
        </Box>


    )}

    
    />
  </>;
}

export default CreateKitchenFlowModal;
