import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


/* --------------------------------------------------------- Get Ingerdiants Drop ------------------------------------------------- */
export const getIngerdiantsDrop = createAsyncThunk("ingrediants/getIngerdiantsDrop",async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/InventoryItem/LookupInventoryItem`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* --------------------------------------------------------- Get Ingerdiants List------------------------------------------------- */
  export const getIngerdiants = createAsyncThunk("ingrediants/getIngerdiants", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Items/IngredientByItemId?itemid={"InventoryItemId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* --------------------------------------------------------- Add Ingerdiants ------------------------------------------------- */
  export const addIngerdiants = createAsyncThunk("ingrediants/addIngerdiants", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/AddItemsIngredient?itemsingredient=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* --------------------------------------------------------- Get Ingerdiants Items To Edit ------------------------------------------------- */
  export const editQtyDrop = createAsyncThunk(  "ingrediants/editQtyDrop",   async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Items/LookupIngredientByItemId?itemid={"InventoryItemId": ${args} }`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* --------------------------------------------------------- Edit Qauntity Ingerdiants Items ------------------------------------------------- */
  export const editQty = createAsyncThunk( "ingrediants/editQty", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Items/EditItemsIngredient?itemsingredientid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const wastePercentage = createAsyncThunk(
    "ingrediants/wastePercentage",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/Items/EditWestItemsIngredient?itemsingredientid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );