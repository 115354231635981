import React from 'react';
import GRestoreModal from "components/ui/modal/RestoreModal";
import { useDispatch } from 'react-redux';
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { deleteCustomer, getCustomerlist } from 'features/customers/services/customersService';

const RestoreCustomerModal = ({onClose,id}) => {
  const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();

  return (
    <GRestoreModal
        onClose={onClose}
        onSubmit={()=>{
          dispatch(deleteCustomer(id)).unwrap().then(_ => {
            toast({ description: t("pages.customers.waiting_success") });//             
            onClose();
            dispatch(getCustomerlist());
          })
        }}  
  />
  );
}

export default RestoreCustomerModal;

