import React, { useEffect } from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from 'react-redux';
import { addPriceTagsModifierOptions, getModifierOptionsInPriceTags, getPriceTagsModifierOptions } from '../../services/priceTagsServices';

export default function AddModifierOptions({ onClose,id }){
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
    const { handleSubmit, setValue } = useForm();

    const PriceTagsData = useSelector(state => state.priceTags);

    useEffect(() => {
      dispatch(getModifierOptionsInPriceTags());
    }, [dispatch])
    
  
  return <>
    <GModal
      onClose={onClose}
      modalHeader={t("pages.price_tags.add_modifier")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.PriceTagsId = +id.PriceTagsId;
            values.ModifierOptionId = values.ModifierOptionId?.ModiferOptionId;
            values.NewMOPrice = 0;
            if(values.ModifierOptionId === undefined ){
                toast2({ description: t("validation.choose_first") });
            }
            else {
                dispatch(addPriceTagsModifierOptions(values))
                  .unwrap().then(_ => {
                    toast({ description: t("pages.price_tags.moifier_options_added") });
                    dispatch(getPriceTagsModifierOptions(id.PriceTagsId));
                    onClose()
                  });
            }
          })} >

            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.price_tags.modifier")}
                data={PriceTagsData?.modifiersOptionsDropdown?.map(el => ({
                  ...el,
                  label: el.ModiferOptionNameEng,
                  value: el.ModiferOptionId
                }))}
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("ModifierOptionId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  </>
};
