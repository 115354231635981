import React, { useEffect, useState } from "react";
import DetailsTable from "./../components/DetailsTable";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDiscountById } from "../services/discountsService";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, Spinner, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import EditModal from "./../components/modals/EditModal";
import AppliesOnBranches from "../components/AppliesOnBranches";
import LimitsToCustomerTags from "../components/LimitsToCustomerTags";
import AppliesOnCategories from "../components/AppliesOnCategories";
import AppliesOnCombos from "../components/AppliesOnCombos";
import AppliesOnProducts from "../components/AppliesOnProducts";
import AppliesOnProductTags from "../components/AppliesOnProductTags";
import DeleteDiscounts from "../components/DeleteDiscounts";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


export default function DiscountDetails() {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 42);

  const dispatch = useDispatch();
  const id = useParams();
  const { t } = useTranslation();

  const discount = useSelector((state) => state.discounts);

  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    dispatch(getDiscountById(id.id));
  }, [dispatch, id.id]);


  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return discount.isLoading ? (
    <Spinner />
  ) : (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            {discount?.discountDetails?.DisCountName || "-"}
          </Box>
          <GButton
            title={t("pages.items.edit")}
            bg="#54A0FF"
            color="light"
            hover={{ bg: "primary" }}
            onClick={() => setShowEditModal(true)}
          />
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        <DetailsTable data={discount?.discountDetails} />

        <AppliesOnBranches></AppliesOnBranches>
        {(discount?.discountDetails?.QualificationDiscountType[0]
          ?.QualificationDiscountTypeName === "Orders and Products" ||
          discount?.discountDetails?.QualificationDiscountType[0]
            ?.QualificationDiscountTypeName === "Products") && (
          <>
            <AppliesOnCategories></AppliesOnCategories>

            <AppliesOnProducts></AppliesOnProducts>

            <AppliesOnCombos></AppliesOnCombos>

            <AppliesOnProductTags></AppliesOnProductTags>
          </>
        )}
        <LimitsToCustomerTags></LimitsToCustomerTags>
        <DeleteDiscounts idOfDiscount={id.id}></DeleteDiscounts>
      </Stack>

      {showEditModal && (
        <EditModal
          onClose={() => setShowEditModal(false)}
          data={discount?.discountDetails}
        />
      )}
    </>
  );
}
