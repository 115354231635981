import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteBranch } from "../../services/branchesService";
import routes from "navigations/routes";

const DeleteModal = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast({position: "top",duration: 3000,status: "success",});

  const branches = useSelector((state) => state.branches);

  return <>
  
  <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteBranch(id))
          .unwrap()
          .then((_) => {
            toast({ description: t("pages.branches.branch_deleted") });
            onClose();
            navigate(`/${routes.management_branches}`);
          });
      }}
      isLoading={branches.isLoading}
    />
    
  </>
};

export default DeleteModal;
