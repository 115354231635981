import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getBranchesDeliveryZones = createAsyncThunk("sectiones/getDeliveryZones", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/LookDeliveryZone`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const getDeliveryZoneInBranches = createAsyncThunk("sectiones/getBranchesDeliveryZone", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/DelivZonByBranch?branchid={"BranchId":${args}}`);
      //console.log("now getDeliveryZoneInBranches")
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createBranchDeliveryZone = createAsyncThunk("sectiones/createBranchDeliveryZone", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/AddEditBranchDelivZone?delivzone=${JSON.stringify(args)}`);
      //console.log("now createBranchDeliveryZone")
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const updateBranchDeliveryZone = createAsyncThunk(
  "sectiones/updateBranchDeliveryZone",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Sectiones/AddEditSection?section=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);