import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { generator } from "utils/generator";
import {
  getCategories,
  updateCategory,
} from "../../services/categoriesService";
import { useState } from "react";
import { UploadImg } from "services/uploadImg";

const UpdateModal = ({ data, onClose }) => {
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories);
  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({ defaultValues: data });
  watch(["ProductCategoriesRef"]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageData, setImageData] = useState(data?.ProductCategoriesImagePath);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setImageUrl(URL.createObjectURL(file));
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.categories.update_category")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              if (!selectedFile && !imageData) {
                toast2({
                  description: t("validation.you_must_choose_image"),
                });
                return;
              } else if (selectedFile) {
                const formData = new FormData();
                formData.append("imageFile", selectedFile);
                dispatch(UploadImg(formData)).then((result) => {
                  values.ProductCategoriesImagePath = result.payload;
                  dispatch(updateCategory({ id: values.id, values }))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.categories.category_updated"),
                      });
                      dispatch(getCategories());
                      onClose();
                    });
                });
              } else if (imageData) {
                console.log("img found before ");
                values.ProductCategoriesImagePath = imageData;
                dispatch(updateCategory({ id: values.id, values }))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.categories.category_updated"),
                    });
                    dispatch(getCategories());
                    onClose();
                  });
              }
              // const formData = new FormData();
              // formData.append("imageFile", selectedFile);

              // values.UserId = localStorage.getItem("userId");
              // if (imageUrl) {
              //   dispatch(UploadImg(formData)).then((result) => {
              //     values.ProductCategoriesImagePath = imageUrl
              //       ? result.payload
              //       : `https://rostoapi.smartgate-egypt.com/${imageData}`;
              //     dispatch(updateCategory({ id: values.id, values }))
              //       .unwrap()
              //       .then((_) => {
              //         toast({
              //           description: t("pages.categories.category_updated"),
              //         });
              //         dispatch(getCategories());
              //         onClose();
              //       });
              //   });
              // } else if (imageData && imageUrl) {
              //   values.ProductCategoriesImagePath = imageUrl;
              //   dispatch(updateCategory({ id: values.id, values }))
              //     .unwrap()
              //     .then((_) => {
              //       toast({
              //         description: t("pages.categories.category_updated"),
              //       });
              //       dispatch(getCategories());
              //       onClose();
              //     });
              // } else {
              //   values.ProductCategoriesImagePath = imageData;
              //   dispatch(updateCategory({ id: values.id, values }))
              //     .unwrap()
              //     .then((_) => {
              //       toast({
              //         description: t("pages.categories.category_updated"),
              //       });
              //       dispatch(getCategories());
              //       onClose();
              //     });
              // }

              // // dispatch(
              // //   updateCategory({ id: values.id, values, }))
              // //   .unwrap()
              // //   .then((_) => {
              // //     toast({ description: t("pages.categories.category_updated") });
              // //     dispatch(getCategories());
              // //     onClose();
              // //   });
            })}
          >
            <Stack spacing={6}>
              <div>
                <input type="file" onChange={handleFileChange} />
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="Selected"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <img
                    src={`https://rostoapi.smartgate-egypt.com/${imageData}`}
                    alt="Selected"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      marginTop: "10px",
                    }}
                  />
                )}
              </div>

              <FormControl isInvalid={errors.ProductCategoriesNameEng}>
                <FormLabel
                  htmlFor="ProductCategoriesNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.categories.name")}
                </FormLabel>
                <Controller
                  name="ProductCategoriesNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.categories.name")}
                      onChange={(e) => {
                        handleInputChange(e, "ProductCategoriesNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {" "}
                  {errors.ProductCategoriesNameEng &&
                    errors.ProductCategoriesNameEng.message}{" "}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.ProductCategoriesNameAr}>
                <FormLabel
                  htmlFor="ProductCategoriesNameAr"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.categories.name_localized")}
                </FormLabel>
                <Controller
                  name="ProductCategoriesNameAr"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.categories.name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "ProductCategoriesNameAr");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {" "}
                  {errors.ProductCategoriesNameAr &&
                    errors.ProductCategoriesNameAr.message}{" "}
                </FormErrorMessage>
              </FormControl>

              <Flex alignItems="flex-end" gap={4}>
                <Input
                  label={t("pages.categories.ref")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.categories.ref")}
                  isRequired
                  value={getValues("ProductCategoriesRef") || ""}
                  readOnly
                />
                <GButton
                  title={t("pages.inventory.generate_sku")}
                  height="50px"
                  bg="light"
                  border="1px solid"
                  borderColor="border"
                  hover={{
                    bg: "primary",
                    borderColor: "primary",
                    color: "light",
                  }}
                  onClick={() =>
                    setValue("ProductCategoriesRef", generator({}))
                  }
                />
              </Flex>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={categories.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={categories.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default UpdateModal;
