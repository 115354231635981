import React, { useState } from 'react';
import GButton from "components/ui/button/Button"
import { Flex, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import DeleteModal from './modals/DeleteModal';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';

const DeleteCompoModifier = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

    // {showDeleteBranchModal && (
    //     <DeleteitemModal
    //       id={id}
    //       onClose={() => setShowDeleteBtanchModal(null)}
    //     />
    //   )}

    const { t } = useTranslation();

    const [showDeleteModal,setDeleteModal]=useState(false)

    
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


    if(showDeleteModal && ManageMenu===false){
      toast2({ description:  t("general.authorization")})
      setDeleteModal(false)
    }

  return (
    <div>
    <Flex
        bg="light" borderRadius={6} alignItems="center" justifyContent="space-between" gap={4} p={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
    <Text fontSize=".9rem" color="text">
        {t("general.delete_message")}
    </Text>
    <GButton
        bg="red"
        color="light"
        title={t("general.delete")}
        hover={{ bg: "red" }}
        onClick={() => setDeleteModal(true)}
    />


    {showDeleteModal && (
            <DeleteModal
              onClose={() => setDeleteModal(false)}
            />
          )}


    </Flex>
    </div>
  );
}

export default DeleteCompoModifier;
