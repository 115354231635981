import { createSlice } from "@reduxjs/toolkit";
import {
  getCombos,
  getComboById,
  createCombo,
  updateCombo,
  deleteCombo,
  getbranches,
  getproducts,
  addCustomPriceCombo,
  deactiveComboinsomebranches,
  getInactiveBranhesById,
  deactivateComboById,
  getFilteredData,
  resetFilteredData,
} from "../services/combosService";

let initialState = {
  isLoading: false,
  data: [],
  combo: null,
  pages: 0,
  errors: null,
  products:[],
  groups:[],
  inActiveBranches:[],
  filteredData:[],
};

const combosSlice = createSlice({
  name: "combos",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCombos.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCombos.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getCombos.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getComboById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getComboById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.combo = (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    })
    builder.addCase(getComboById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createCombo.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createCombo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createCombo.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateCombo.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateCombo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(combo => combo.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateCombo.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteCombo.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteCombo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(combo => combo.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteCombo.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //

    builder.addCase(getbranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getbranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.branches = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getbranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //


    builder.addCase(getproducts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getproducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.products = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      console.log(state.products)
      state.errors = null;
    })
    builder.addCase(getproducts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //

    
    builder.addCase(addCustomPriceCombo.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(addCustomPriceCombo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(addCustomPriceCombo.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    //

    builder.addCase(deactiveComboinsomebranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deactiveComboinsomebranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(deactiveComboinsomebranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
    
    
    builder.addCase(getInactiveBranhesById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getInactiveBranhesById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.inActiveBranches = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.inActiveBranches)
      state.errors = null;
    })
    builder.addCase(getInactiveBranhesById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deactivateComboById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deactivateComboById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(deactivateComboById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(getFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      // console.log("youssef" , state.filteredData);
      state.errors = null;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

  }
});

export default combosSlice.reducer;