import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Payments  Report List ------------------------------- */
  export const getPaymentsReportList = createAsyncThunk("paymentsReport/getPaymentsReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetPaymentsReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

 /* --------------------------------------- Payments Filter Data ------------------------------*/
 export const getPaymentsReportFilteredData = createAsyncThunk("paymentsReport/getPaymentsReportFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetPaymentsReportFilteredData = createAsyncThunk( "paymentsReport/resetPaymentsReportFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);