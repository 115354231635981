import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { getTransfersOrdersFilteredData } from "../services/transfersOrdersServices";
import Input from "components/form/Input";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";

export default function FilterModal({ onClose, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState(data);

  // Filter Data
  const [filterCriteria, setFilterCriteria] = useState({
    Name: "",
    SKU: "",
    SubmittedBy: "",
    SubmittedAt: "",
  });

  const handleFilterTransfersOrders = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const { Name, SKU, SubmittedBy, SubmittedAt } = filterCriteria;

      let filterName = true;
      let filterRef = true;
      let filterSubmittedBy = true;
      let filterSubmittedAt = true;

      if (Name) {
        filterName = item?.InventoryItemNameEng?.toLowerCase().includes(
          Name.toLowerCase()
        );
      }

      if (SKU) {
        filterRef = item?.ItemRef?.toLowerCase().includes(SKU?.toLowerCase());
      }

      if (SubmittedBy) {
        filterSubmittedBy = item?.SubmittedBy?.toLowerCase().includes(
          SubmittedBy?.toLowerCase()
        );
      }

      if (SubmittedAt) {
        filterSubmittedAt = item?.SubmittedAt?.toLowerCase().includes(
          SubmittedAt?.toLowerCase()
        );
      }

      return filterName && filterRef && filterSubmittedBy && filterSubmittedAt;
    });

    setFilteredData(filteredResult);
    onClose();
    dispatch(getTransfersOrdersFilteredData(filteredResult));
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.reports.business_reports.filter")}
        modalContent={
          <Box p={4}>
            <form onSubmit={handleFilterTransfersOrders}>
              <Stack spacing={6}>
                <Input
                  label={t("pages.reports.reports_tables.name")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.name")}
                  name="Name"
                  value={filterCriteria.Name}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      Name: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.reports.reports_tables.reference")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.reference")}
                  name="SKU"
                  value={filterCriteria.SKU}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      SKU: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.reports.reports_tables.submitted_by")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.submitted_by")}
                  name="SubmittedBy"
                  value={filterCriteria.SubmittedBy}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      SubmittedBy: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.reports.reports_tables.submitted_at")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.submitted_at")}
                  name="SubmittedAt"
                  value={filterCriteria.SubmittedAt}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      SubmittedAt: e.target.value,
                    })
                  }
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
