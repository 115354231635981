import { createSlice } from "@reduxjs/toolkit";
import {
  createDiscountProducts,
  getDiscountsProducts,
  deleteProductInDiscount,
  getTagsInProduct
} from "../services/discountsProductsService";

let initialState = {
  isLoading: false,
  errors: null,
  tagsInProduct: [],
};

const discountsProductsSlice = createSlice({
  name: "discountsProducts",
  initialState,

  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get categories Discounts List  -------------------------------------------------------------------*/
    builder.addCase(getDiscountsProducts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDiscountsProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getDiscountsProducts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create categories Combos  -------------------------------------------------------------------*/
    builder.addCase(createDiscountProducts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createDiscountProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createDiscountProducts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Product In Discount  -------------------------------------------------------------------*/
    builder.addCase(deleteProductInDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteProductInDiscount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deleteProductInDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
     /* -------------------------------------------------- Get Tags In Product ---------------------------------------------------*/
     builder.addCase(getTagsInProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getTagsInProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInProduct =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getTagsInProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default discountsProductsSlice.reducer;
