import { createSlice } from "@reduxjs/toolkit";
import { addPriceTagsCombos, addPriceTagsModifierOptions, addPriceTagsProducts, createEditPriceTag, editCombosPricesPriceTags, editModifierOptionsPricesPriceTags, editProductsPricesPriceTags, getBranchesTags, getCombosInPriceTags, getModifierOptionsInPriceTags, getOrderTags, getPriceTags, getPriceTagsCombos, getPriceTagsCombosFilteredData, getPriceTagsDetails, getPriceTagsFilteredData, getPriceTagsModifierFilteredData, 
          getPriceTagsModifierOptions, 
          getPriceTagsProducts, 
          getPriceTagsProductsFilteredData, getProductInPriceTags, resetPriceTagsCombosFilteredData, resetPriceTagsFilteredData, resetPriceTagsModifierFilteredData, 
          resetPriceTagsProductsFilteredData } from "../services/priceTagsServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  priceTagDetails: [],

  ProductsData : [],
  CombosData : [],
  ModifiresOptionsData : [],

  productsDropdown:[],
  combosDropdown:[],
  modifiersOptionsDropdown:[],
  orderTagsDropdown:[],
  branchsDropdown:[],

  filteredData : [],
  productsFilteredData:[],
  combosFilteredData:[],
  modifiersFilteredData:[],
};

const priceTagsSlice = createSlice({
  name: "priceTags",
  initialState,
  extraReducers: (builder) => {
    /* ------------------------------------------------- Get Price Tags   -------------------------------------------------  */
    builder.addCase(getPriceTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPriceTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPriceTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------- Get Price Tags Details   -------------------------------------------------  */
    builder.addCase(getPriceTagsDetails.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPriceTagsDetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.priceTagDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPriceTagsDetails.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------   get Order Tags DrobDown   -------------------------------------------------  */
    builder.addCase(getOrderTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getOrderTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.orderTagsDropdown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getOrderTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* --------------------------------------------   get Branches Tags DrobDown   ------------------------------------------  */
    builder.addCase(getBranchesTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getBranchesTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branchsDropdown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getBranchesTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* --------------------------------------------  Get Product In Price Tags DrobDown   ------------------------------------------  */
    builder.addCase(getProductInPriceTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getProductInPriceTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.productsDropdown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getProductInPriceTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* --------------------------------------------  Get Combos In Price Tags DrobDown   ------------------------------------------  */
    builder.addCase(getCombosInPriceTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getCombosInPriceTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.combosDropdown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getCombosInPriceTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* --------------------------------------------  Get Combos In Price Tags DrobDown   ------------------------------------------  */
    builder.addCase(getModifierOptionsInPriceTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getModifierOptionsInPriceTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiersOptionsDropdown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getModifierOptionsInPriceTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------   Create Price Tags   -----------------------------------------  */
    builder.addCase(createEditPriceTag.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createEditPriceTag.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createEditPriceTag.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------   Add Price Tags Products   -----------------------------------------  */
    builder.addCase(addPriceTagsProducts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addPriceTagsProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(addPriceTagsProducts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------   Edit Products Prices in Price Tags   -----------------------------------------  */
    builder.addCase(editProductsPricesPriceTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editProductsPricesPriceTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editProductsPricesPriceTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------   Get Price Tags Products  -----------------------------------------  */
    builder.addCase(getPriceTagsProducts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPriceTagsProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ProductsData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPriceTagsProducts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------   Add Price Tags Combos   -----------------------------------------  */
    builder.addCase(addPriceTagsCombos.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addPriceTagsCombos.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(addPriceTagsCombos.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------  Get Price Tags Combos  -----------------------------------------  */
    builder.addCase(getPriceTagsCombos.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPriceTagsCombos.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.CombosData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPriceTagsCombos.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------   Edit Combos Prices in Price Tags   -----------------------------------------  */
    builder.addCase(editCombosPricesPriceTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editCombosPricesPriceTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editCombosPricesPriceTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------   Add Price Tags Modifier Options   -----------------------------------------  */
    builder.addCase(addPriceTagsModifierOptions.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addPriceTagsModifierOptions.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(addPriceTagsModifierOptions.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------   Get Price Tags Modifier Options   -----------------------------------------  */
    builder.addCase(getPriceTagsModifierOptions.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPriceTagsModifierOptions.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ModifiresOptionsData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPriceTagsModifierOptions.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------   Edit Price Tags Modifier Options   -----------------------------------------  */
    builder.addCase(editModifierOptionsPricesPriceTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editModifierOptionsPricesPriceTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editModifierOptionsPricesPriceTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
       /* --------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------- Price Tag  Filtered Data  -------------------------------------------------------*/
  builder.addCase(getPriceTagsFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getPriceTagsFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getPriceTagsFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetPriceTagsFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetPriceTagsFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetPriceTagsFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
  /* ----------------------------------------------------------------- Price Tag Products Filtered Data  -------------------------------------------------------*/
  builder.addCase(getPriceTagsProductsFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getPriceTagsProductsFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.productsFilteredData = payload;
    state.errors = null;
  });
  builder.addCase(getPriceTagsProductsFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetPriceTagsProductsFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetPriceTagsProductsFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.productsFilteredData = [];
    state.errors = null;
  });
  builder.addCase(resetPriceTagsProductsFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
  /* ----------------------------------------------------------------- Price Tag Combos Filtered Data  -------------------------------------------------------*/
  builder.addCase(getPriceTagsCombosFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getPriceTagsCombosFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.combosFilteredData = payload;
    state.errors = null;
  });
  builder.addCase(getPriceTagsCombosFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetPriceTagsCombosFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetPriceTagsCombosFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.combosFilteredData = [];
    state.errors = null;
  });
  builder.addCase(resetPriceTagsCombosFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
  /* ----------------------------------------------------------- Price Tag Modifier Options Filtered Data  -----------------------------------------------*/
  builder.addCase(getPriceTagsModifierFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getPriceTagsModifierFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.modifiersFilteredData = payload;
    state.errors = null;
  });
  builder.addCase(getPriceTagsModifierFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetPriceTagsModifierFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetPriceTagsModifierFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.modifiersFilteredData = [];
    state.errors = null;
  });
  builder.addCase(resetPriceTagsModifierFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
  },
});

export default priceTagsSlice.reducer;
