import { createSlice } from "@reduxjs/toolkit";
import {
  addProductToGroup,
  removeProductFromGroup,
  getProductsForSpecificGroup,
  getTaxesGroupsProductsToRemove,
} from "../services/productsToGroupsService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  productDetails: [],
  TaxesGroupsBranchesToRemove:[]
};

const productsToGroupsSlice = createSlice({
  name: "productGroups",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addProductToGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addProductToGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    });
    builder.addCase(addProductToGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(removeProductFromGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(removeProductFromGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //   state.data = state.data.filter(tax => tax.id !== payload.id);
      state.errors = null;
    });
    builder.addCase(removeProductFromGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    builder.addCase(getProductsForSpecificGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(
      getProductsForSpecificGroup.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.productDetails =
          (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      }
    );

    builder.addCase(getProductsForSpecificGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

/* -------------------------------------------- Get Taxes Group Products To Remove -------------------------------------------*/
    builder.addCase(getTaxesGroupsProductsToRemove.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getTaxesGroupsProductsToRemove.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.TaxesGroupsBranchesToRemove = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      }
    );
    builder.addCase(getTaxesGroupsProductsToRemove.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });


  },
});

export default productsToGroupsSlice.reducer;
