import { createSlice } from "@reduxjs/toolkit";
import {
  getTimedEventsProductTags,
  createTimedEventProductTags,
  deleteProductTagInTimedEvent,
  getTagsInProductTags,
} from "../services/timedEventsProductTagsService";

let initialState = {
  isLoading: false,
  errors: null,
  tagsInProductTag: [],
};

const timedEventsProductTagsSlice = createSlice({
  name: "timedEventsProductTags",
  initialState,

  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get categories Timed Events List  -------------------------------------------------------------------*/
    builder.addCase(getTimedEventsProductTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTimedEventsProductTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getTimedEventsProductTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create categories Combos  -------------------------------------------------------------------*/
    builder.addCase(createTimedEventProductTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      createTimedEventProductTags.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(createTimedEventProductTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Product Tag In Timed Event  -------------------------------------------------------------------*/
    builder.addCase(deleteProductTagInTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      deleteProductTagInTimedEvent.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(deleteProductTagInTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------- Get Tags In Product Tags ---------------------------------------------------*/
    builder.addCase(getTagsInProductTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTagsInProductTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInProductTag =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getTagsInProductTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default timedEventsProductTagsSlice.reducer;
