import { useTranslation } from "react-i18next";
import { Box, Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import * as FiIcons from "react-icons/fi";
import { getSectionTables } from "../services/branchesService";
import { useNavigate } from "react-router-dom";

const STable = ({
  sections,
  handleUpdate
}) => {

 
  const navigate=useNavigate()
  const HandelClickSection=(e)=>{
    //console.log(e)
    navigate(`BranchSectionTables/${e.BranchSectionId}`)
  }

  const { t } = useTranslation();
  return (
    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.branches.name")}</Th>
            <Th>{t("pages.branches.tables")}</Th>
            {/*<Th>{t("general.action")}</Th>*/}
          </Tr>
        </Thead>
        <Tbody>
          {sections?.map((section, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td onClick={()=>HandelClickSection(section)} style={{cursor:"pointer"}}>{section?.BranchSectionName}</Td>
              <Td>{section?.BranchSectionTable}</Td>
              {/*<Td>
                <Button
                  type="button"
                  bg="primary" color="light"
                  onClick={() => handleUpdate(section)}
                >
                  <FiIcons.FiEdit />
                </Button>
          </Td>*/}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default STable