import { SimpleGrid } from "@chakra-ui/react";
import BranchesTable from "./BranchesTable";
import Card from "features/dashboard/components/Card";
import { useDispatch, useSelector } from "react-redux";
import { getDashbordBranches } from "features/dashboard/services/dashbordChartsServices";
import { useEffect } from "react";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";


const Branches = () => {

  const retrievedArray =useDecryptedPrivilege()
  const mangeGeneral = checkIdExists(retrievedArray, 74);

  const dispatch = useDispatch();
  const dashbordChartsData = useSelector(state => state.dashbordChartsSlice);

  const branchesData = [
    {
      title: "pages.dashboard.ActiveOrdersCount",
      count: dashbordChartsData?.dataBranches[0]?.ActiveOrdersInfo[0]?.ActiveOrders ,
      link: "",
    },
    {
      title: "pages.dashboard.ActiveOrdersAmount",
      count:  Math.round(dashbordChartsData?.dataBranches[0]?.ActiveOrdersInfo[0]?.ActiveOrdersAmount) ,
      link: "",
    },
    {
      title: "pages.dashboard.OccupiedTables",
      count: dashbordChartsData?.dataBranches[0]?.OccupiedTablesInfo[0]?.OccupiedTables ,
      link: "",
    }
  ];

  useEffect(() => {
    dispatch(getDashbordBranches());
  }, [dispatch]);


  if(mangeGeneral===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} spacing={4}>
        {branchesData?.map((el) => (
          <Card title={el.title} count={el.count} key={el.count} />
        ))}
      </SimpleGrid>

      <BranchesTable data={dashbordChartsData?.dataBranches[0]?.ByBranch} />
    </>
  );
};

export default Branches;
