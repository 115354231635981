import { createSlice } from "@reduxjs/toolkit";
import {getinventoryCat} from "../services/inventoryCatService"


let initialState = {
    isLoading: false,
    data: [],
    pages: 0,
    errors: null
  };


  const inventoryCatSlice = createSlice({
    name: "inventoryCat",
    initialState,
    extraReducers:(builder)=>{

        builder.addCase(getinventoryCat.pending, (state) => {
               state.isLoading = true;
               state.errors = null;
             })
      
            builder.addCase(getinventoryCat.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
             // console.log(state.data)
              state.errors = null;
            })
      
            builder.addCase(getinventoryCat.rejected, (state) => {
              state.isLoading = false;
              state.errors = [{ msg: "something went wrong!" }];
            })

    }
  })

  export default inventoryCatSlice.reducer