import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Get Tips Report List ------------------------------- */
  export const getTipsReportList = createAsyncThunk("tipsReport/getTipsReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetTipsReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

 /* --------------------------------------- Tips Filter Data ------------------------------*/
 export const getTipsFilteredData = createAsyncThunk("tipsReport/getTipsFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetTipsFilteredData = createAsyncThunk( "tipsReport/resetTipsFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);