import React, { useEffect } from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, Stack, useToast,Input as ChInput, } from "@chakra-ui/react"
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import { useForm } from 'react-hook-form';
import GButton from "components/ui/button/Button"
import { useDispatch, useSelector } from 'react-redux';
import { createCallCenterOrder, getBranchesForCallCenter, getCustomerPhoneNumber, getPaymentMethodForCallCenter, getorderTypesforcallCenter } from 'features/callCenter/service/callCenterService';
import Input from "components/form/Input";


const CreateCallCenterOrderModal = ({ onClose }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const { handleSubmit, control, getValues, watch, setValue, register } = useForm();




    useEffect(()=>{
        dispatch(getCustomerPhoneNumber())
    },[dispatch])

    useEffect(()=>{
        dispatch(getorderTypesforcallCenter())
    },[dispatch])


    useEffect(()=>{
        dispatch(getBranchesForCallCenter())
    },[dispatch])

    useEffect(()=>{
        dispatch(getPaymentMethodForCallCenter())
    },[dispatch])

    const data = useSelector(state => state.callcenter);

    //console.log(data)

    watch(["CustomerPhoneId"]);


  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.call_center.new_call_center_order")}
    
    modalContent={(

        <Box p={4}>
        
        <form onSubmit={handleSubmit(values=>{
            values.CustomerId=values.CustomerPhoneId.CustomerId;
            values.CustomerPhoneId=values.CustomerPhoneId.CustomerPhoneId;
            values.BranchId=values.BranchId.BranchId;
            values.OrderTypeId=values.OrderTypeId.OrderTypeId;
            values.PaymentMethodTypeId=values.PaymentMethodTypeId.PaymentMethodTypeId;
            values.OrderId = null ;
            //console.log(values)
            dispatch(createCallCenterOrder(values)).unwrap().then(_ => {
                toast({ description: t("pages.call_center.created-successfuly") });
                onClose()
              });
        })}>
        
        <Stack spacing={6}>
        

            <MultiSelect
              title={t("pages.call_center.Customer_Phone")}
              data={data.phoneNumbers&& data.phoneNumbers.map(el => ({
                ...el,
                label: `${el.CustomerName} ${el.PhoneNo}`,  // Fixed this line
                value: el.CustomerPhoneId
              }))}
              isMulti={false}
              isRequired={true}
              onChange={(e) => setValue("CustomerPhoneId", e)}
            />


           {/* <div style={{display:"flex",justifyContent:"center",flexDirection:"column",gap:"7px"}}>
            <div>{t("pages.orders.customer_name")}</div>
            <ChInput
            readOnly
            value={getValues(`CustomerPhoneId.CustomerName`) || ""}
        
          />
            </div>
            */}
           
         

            <MultiSelect
              title={t("pages.call_center.Order_Type")}
              data={data.orderType&& data.orderType.map(el => ({
                ...el,
                label:  el.OrderTypeName,
                value: el.OrderTypeId
              }))}
              isMulti={false}
              isRequired={true}
              onChange={(e) => setValue("OrderTypeId", e)}
            />


            <MultiSelect
              title={t("pages.call_center.Branch")}
              data={data.branches&& data.branches.map(el => ({
                ...el,
                label: el.BranchName,
                value: el.BranchId
              }))}
              isMulti={false}
              isRequired={true}
              onChange={(e) => setValue("BranchId", e)}
            />


            <MultiSelect
              title={t("pages.call_center.Payment_Method_Type")}
              data={data.paymentmethod&& data.paymentmethod.map(el => ({
                ...el,
                label: el.PaymentMethodTypeName,
                value: el.PaymentMethodTypeId
              }))}
              isMulti={false}
              isRequired={true}
              onChange={(e) => setValue("PaymentMethodTypeId", e)}
            />


    <Flex gap={4}>
        <GButton
          type="submit"
          title={t("general.save")}
          bg="primary"
          color="light"
          border="1px solid transparent"
          //isLoading={categories.isLoading}
          hover={{ color: "primary", borderColor: "primary", bg: "none" }}
        />
        <GButton
          title={t("general.close")}
          onClick={onClose}
          bg="red.600"
          border="1px solid transparent"
          color="light"
          //isLoading={categories.isLoading}
          hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
        />
    </Flex>
        
        
        </Stack>
        
        
        </form>
        
        </Box>

    )}
    
    />
  );
}

export default CreateCallCenterOrderModal;
