import { createSlice } from "@reduxjs/toolkit";
import { getDigitalWalletSettings } from "../services/digitalWalletService";



let initialState = {
    isLoading: false,
    data: [],
    pages: 0,
    errors: null
  };
  
  const digitalWalletSlice = createSlice({
    name: "digitalWallet",
    initialState,
    extraReducers: (builder) => {
        
        builder.addCase(getDigitalWalletSettings.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getDigitalWalletSettings.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            console.log("hihi",state.data)
            state.errors = null;
          })
          builder.addCase(getDigitalWalletSettings.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
    }
});

export default digitalWalletSlice.reducer;