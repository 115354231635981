import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getFilteredData } from "../../services/itemService";
import { getCategories } from "features/products/categories/services/categoriesService";

const FilterModal = ({ onClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({position: "top", duration: 3000, status: "success", });

  const [filteredData, setFilteredData] = useState(data);

  const [filterCriteria, setFilterCriteria] = useState({
    InventoryItemNameEng: "",
    InventoryItemRef: "",
    InventoryCategoryName: "",
  });
  const categories = useSelector((state) => state.categories);

  const handleFilterGiftsCard = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const {
        InventoryItemNameEng,
        InventoryItemRef,
        InventoryCategoryName,
      } = filterCriteria;


      let filterByNameEng = true;
      let filterByNameRef = true;
      let filterByCategoryName = true;

      if (InventoryItemNameEng) {
        filterByNameEng = item.InventoryItemNameEng.toLowerCase().includes(InventoryItemNameEng.toLowerCase());
      }

      if (InventoryItemRef) {
        filterByNameRef = item.InventoryItemRef.toLowerCase().includes(InventoryItemRef.toLowerCase());
      }

      if (InventoryCategoryName) {
        filterByCategoryName = item.InventoryCategoryName.toLowerCase().includes(InventoryCategoryName.toLowerCase());
      }


      return (
        filterByNameEng &&
        filterByNameRef &&
        filterByCategoryName
      );
    });

    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredData(filteredResult));
  };

  const handleClose = () => {
    onClose(filteredData);
  };

  return (
    <GModal
      onClose={handleClose}
      modalHeader={t("pages.inventory.filter_items")}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleFilterGiftsCard}>
            <Stack spacing={6}>
              <Input
                label={t("pages.inventory.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.inventory.name")}
                name="InventoryItemNameEng"
                value={filterCriteria.InventoryItemNameEng}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    InventoryItemNameEng: e.target.value,
                  })
                }
              />
              <Input
                label={t("pages.inventory.sku")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.inventory.sku")}
                name="InventoryItemRef"
                value={filterCriteria.InventoryItemRef}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    InventoryItemRef: e.target.value,
                  })
                }
              />

              <Input
                type="number"
                label={t("pages.inventory.price")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.inventory.price")}
                name="InventoryCategoryName"
                value={filterCriteria.InventoryCategoryName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    InventoryCategoryName: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={handleClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default FilterModal;