import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getHrOvertimeDropdown,
  getMasterOvertimeReport,
  getMasterOvertimeReportWithDetails,
} from "../services/hrOvertimeServices";
import { useForm } from "react-hook-form";
import ExportOvertimeModal from "../components/ExportOvertimeModal";

const monthesReport = [
  {
    month: "January",
    fromMonth: "00",
    toMonth: "01",
  },
  {
    month: "February",
    fromMonth: "01",
    toMonth: "02",
  },
  {
    month: "March",
    fromMonth: "02",
    toMonth: "03",
  },
  {
    month: "April",
    fromMonth: "03",
    toMonth: "04",
  },
  {
    month: "May",
    fromMonth: "04",
    toMonth: "05",
  },
  {
    month: "June",
    fromMonth: "05",
    toMonth: "06",
  },
  {
    month: "July",
    fromMonth: "06",
    toMonth: "07",
  },
  {
    month: "August",
    fromMonth: "07",
    toMonth: "08",
  },
  {
    month: "September",
    fromMonth: "08",
    toMonth: "09",
  },
  {
    month: "October",
    fromMonth: "09",
    toMonth: "10",
  },
  {
    month: "November",
    fromMonth: "10",
    toMonth: "11",
  },
  {
    month: "December",
    fromMonth: "11",
    toMonth: "12",
  },
];
export default function OverTimePage() {
  const disPatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [exportModal, SetExportModal] = useState(false);
  const [showmasterovertimeExport, setShowMasterOvertimeExport] =
    useState(false);
  const [showmasterovertimeDetailsExport, setShowMasterOvertimeDetailsExport] =
    useState(false);
  const [overTimeFromMonth, setOverTimeFromMonth] = useState("");
  const [overTimeToMonth, setOverTimeToMonth] = useState("");
  const [overTimeYear, setOverTimeYear] = useState("");

  //
  const data = useSelector((state) => state.hrOvertime);

  useEffect(() => {
    disPatch(getHrOvertimeDropdown());
  }, [disPatch]);

  return (
    <>
      <Box>
        <Box
          bg="light"
          marginInline={-6}
          marginTop={-6}
          padding={6}
          borderTop="1px solid"
          borderColor="border"
          marginBottom={6}
        >
          <Flex alignItems="center" justifyContent="space-between" gap={4}>
            <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
              {t("pages.hr.overTime")}
            </Text>
          </Flex>
        </Box>
        <form
          onSubmit={handleSubmit((values) => {
            if (overTimeYear === "" || overTimeToMonth === "") {
              toast2({
                description: t("pages.hr.NoData"),
              });
            } else if (exportModal === "noDetails") {
              const NewValues = {
                FromMonth: overTimeFromMonth,
                ToMonth: overTimeToMonth,
                Year: overTimeYear,
              };
              disPatch(getMasterOvertimeReport(NewValues)).then((_) => {
                setShowMasterOvertimeExport(true);
              });
            } else if (exportModal === "withDetails") {
              const NewValues = {
                ToMonth: overTimeToMonth,
                Year: overTimeYear,
              };

              disPatch(getMasterOvertimeReportWithDetails(NewValues)).then(
                (_) => {
                  setShowMasterOvertimeDetailsExport(true);
                }
              );
            }
          })}
        >
          <Box
            bg="light"
            overflow="auto"
            borderRadius={4}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            py={"50px"}
          >
            <SimpleGrid columns={2} spacing={10} padding={5}>
              <Box>
                <MultiSelect
                  title={t("pages.hr.select_year")}
                  data={
                    data?.overTimeDropdown &&
                    data?.overTimeDropdown.map((el) => ({
                      ...el,
                      label: el.Year,
                      value: el.SchoolYearId,
                    }))
                  }
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => {
                    setOverTimeYear(e.value);
                  }}
                />
              </Box>
              <Box>
                <MultiSelect
                  title={t("pages.hr.select_month")}
                  data={monthesReport.map((el) => ({
                    ...el,
                    label: el.month,
                    fromMonth: el.fromMonth,
                    toMonth: el.toMonth,
                  }))}
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => {
                    setOverTimeFromMonth(e.fromMonth);
                    setOverTimeToMonth(e.toMonth);
                  }}
                />
              </Box>
              <Button
                type="submit"
                bg="primary"
                color="light"
                textTransform="capitalize"
                fontSize="1rem"
                onClick={() => {
                  SetExportModal("noDetails");
                }}
              >
                <Flex alignItems="center" gap={1}>
                  {t("pages.hr.getMasterOvertime")}
                </Flex>
              </Button>
              <Button
                type="submit"
                bg="blue.500"
                color="light"
                textTransform="capitalize"
                fontSize="1rem"
                onClick={() => {
                  SetExportModal("withDetails");
                }}
              >
                <Flex alignItems="center" gap={1}>
                  {t("pages.hr.getMasterOvertimeDetails")}
                </Flex>
              </Button>
            </SimpleGrid>
            {data?.isLoading && (
              <Flex
                bg="light"
                p={4}
                gap={2}
                justifyContent="center"
                flexDir={"column"}
                alignItems={"center"}
              >
                <Spinner />
                <Text fontWeight={"bold"}> {t("general.loading")} </Text>
              </Flex>
            )}
          </Box>
        </form>

        {showmasterovertimeExport && (
          <ExportOvertimeModal
            onClose={() => setShowMasterOvertimeExport(false)}
            data={data?.materOverTimeList}
            fileName={"overTime"}
            title={"Export Master Overtime"}
          ></ExportOvertimeModal>
        )}

        {showmasterovertimeDetailsExport && (
          <ExportOvertimeModal
            onClose={() => setShowMasterOvertimeDetailsExport(false)}
            data={data?.materOverTimeDtailsList}
            fileName={"overTimeDetails"}
            title={"Export Master Overtime With Details"}
          ></ExportOvertimeModal>
        )}
      </Box>
    </>
  );
}
