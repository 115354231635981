import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* -------------------------------------------- Get CostAdjustment Reports List ------------------------------------ */
export const getPurchasingReportList = createAsyncThunk(
  "reportsPurchasing/getPurchasingReportList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Reports/GetPurchasingReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* --------------------------------------- Menu Engineering Filter Data ------------------------------*/

export const getPurchasingFilteredData = createAsyncThunk(
  "reportsPurchasing/getPurchasingFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetPurchasingFilteredData = createAsyncThunk(
  "reportsPurchasing/resetPurchasingFilteredData ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
