import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* -------------------------------------------- Get Transfer Orders Reports List ------------------------------------ */
export const getTransferOrdersList = createAsyncThunk(
  "reportsTransfersOrders/getTransferOrdersList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Reports/GetTransferOrdersReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* --------------------------------------- Menu Transfer Orders Filter Data ------------------------------*/

export const getTransfersOrdersFilteredData = createAsyncThunk(
  "reportsTransfersOrders/getTransfersOrdersFilteredData",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetTransfersOrdersFilteredData = createAsyncThunk(
  "reportsTransfersOrders/resetTransfersOrdersFilteredData",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
