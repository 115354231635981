// import * as FiIcons from "react-icons/fi";
// import routes from "../../../navigations/routes";
// import { GiPayMoney } from "react-icons/gi";
// import { FcMoneyTransfer } from "react-icons/fc";
// import { IoMdArrowRoundBack } from "react-icons/io";
// import { LiaMoneyCheckSolid } from "react-icons/lia";
// import { GiTakeMyMoney } from "react-icons/gi";

// const FinancialLinks = [


//     {
//         text: "pages.NewAccounting.dashboard",
//         path: routes.AccountingDashboard,
//         icon: <FiIcons.FiHome size={18} />,
//         subLinks: null
//     },
//     {
//         text: "pages.NewAccounting.accounts",
//         icon:<GiTakeMyMoney  size={18}/>,
//         subLinks: [ {
//             text: "pages.NewAccounting.bankAccounts",
//           },
//         {
//             text: "pages.NewAccounting.sales",
//             path:routes.Expenses
//         },
//         {
//             text: "pages.NewAccounting.purchase",
//             path:routes.receipt
//         },
//         {
//             text: "pages.NewAccounting.Inventory",
//         },
//         {
//             text: "pages.NewAccounting.ManualJournals",
//             path: routes.Daily_restrictions,


//         },
//         {
//             text: "pages.NewAccounting.chartOfAccounts",
//             path: routes.general_accounts,

//         },
//         ]
//     },
//     // {
//     //     text: "pages.general_accounts.Daily_restrictions",
//     //     path: routes.Daily_restrictions,
//     //     icon: <LiaMoneyCheckSolid size={18} />,
//     //     subLinks: null
//     // },
//     // {
//     //     text: "pages.Expenses.Expenses",
//     //     path: routes.Expenses,
//     //     icon: <GiPayMoney size={18} />,
//     //     subLinks: null
//     // },
//     // {
//     //   text:"pages.receipt.receipt",
//     //   path:routes.receipt,
//     //   icon: <FcMoneyTransfer size={18} />,
//     //   subLinks: null
//     // },
//     // {
//     //      text:"pages.general_accounts.General_accounts",
//     //      path:routes.general_accounts,
//     //      icon:<GiTakeMyMoney  size={18}/>,
//     //      subLinks: null

//     // },
//     {
//         text: "pages.hr.back",
//         icon: <IoMdArrowRoundBack size={18} />,
//         subLinks: null,
//         path: routes.dashboard
//     }
//     ,



// ];

// export default FinancialLinks;



import * as FiIcons from "react-icons/fi";
import routes from "../../../navigations/routes";
import { GiPayMoney } from "react-icons/gi";
import { FcMoneyTransfer } from "react-icons/fc";
import { IoMdArrowRoundBack } from "react-icons/io";
import { LiaMoneyCheckSolid } from "react-icons/lia";
import { GiTakeMyMoney } from "react-icons/gi";

const FinancialLinks = [
  {
    text: "pages.general_accounts.Daily_restrictions",
    path: routes.Daily_restrictions,
    icon: <LiaMoneyCheckSolid size={18} />,
    subLinks: null,
  },
  {
    text: "pages.Expenses.Expenses",
    path: routes.Expenses,
    icon: <GiPayMoney size={18} />,
    subLinks: null,
  },
  {
    text: "pages.receipt.receipt",
    path: routes.receipt,
    icon: <FcMoneyTransfer size={18} />,
    subLinks: null,
  },
  {
    text: "pages.general_accounts.General_accounts",
    path: routes.general_accounts,
    icon: <GiTakeMyMoney size={18} />,
    subLinks: null,
  },
  {
    text: "pages.hr.back",
    icon: <IoMdArrowRoundBack size={18} />,
    subLinks: null,
    path: routes.dashboard_general,
  },
];

export default FinancialLinks;