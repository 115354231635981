import { createSlice } from "@reduxjs/toolkit";
import {
  getPaymentMethods,
  createPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  getPaymentMethodFilteredData,
  resetPaymentMethodFilteredData
} from "../services/paymentMethodsService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  filteredData:[]
};

const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  extraReducers: (builder) => {
    /* ------------------------------------------------- Get  Payment Method  Type  -------------------------------------------------  */
    builder.addCase(getPaymentMethods.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPaymentMethods.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ------------------------------------------------- Create  Payment Method   -------------------------------------------------  */
    builder.addCase(createPaymentMethod.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createPaymentMethod.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    });
    builder.addCase(createPaymentMethod.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ------------------------------------------------- Updated  Payment Method   -------------------------------------------------  */
    builder.addCase(updatePaymentMethod.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(updatePaymentMethod.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(updatePaymentMethod.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ------------------------------------------------- Deleted  Payment Method   -------------------------------------------------  */
    builder.addCase(deletePaymentMethod.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deletePaymentMethod.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = state.data.filter(paymentMethod => paymentMethod.id !== payload.id);
      state.errors = null;
    });
    builder.addCase(deletePaymentMethod.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //


    builder.addCase(getPaymentMethodFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPaymentMethodFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getPaymentMethodFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetPaymentMethodFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetPaymentMethodFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetPaymentMethodFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default paymentMethodsSlice.reducer;
