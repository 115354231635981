import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Input from "components/form/Input";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getproducts } from "../../services/combosService";
import { addComboGroupOptions, getGroupofOptions, getProductCompoSize } from "../../services/comboSizeService";
import { useParams } from "react-router-dom";

const NewAddOptianModal = ({ onClose, sizes,products,ComboSizeGroupId }) => {
  //console.log(ComboSizeGroupId);
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  

//   useEffect(()=>{
//     dispatch(getProductCompoSize())
//   },[dispatch])

  //const products = useSelector((state) => state.combos);

  //console.log(products)

  const reformatJSON = (originalJSON) => {
    const resultJSON = {};
  
    Object.keys(originalJSON).forEach((key) => {
      const match = key.match(/(\d+)$/);
  
      if (match) {
        const endNumber = match[1];
        const optionPrice = originalJSON[`OptionPrice ${endNumber}`];
        const productId = originalJSON[`ProductId ${endNumber}`];
  
        if (optionPrice && productId) {
          resultJSON[`CombinedObject ${endNumber}`] = {
            OptionPrice: optionPrice,
            ProductId: productId,
          };
        }
      } else {
        resultJSON[key] = originalJSON[key];
      }
    });
  
    return resultJSON;
  };



  function processObject(inputObject) {
    const resultObject = {};
  
    Object.keys(inputObject).forEach((key) => {
      if (key.includes('CombinedObject')) {
        // If the key contains 'CombinedObject', create 'Options' array if not exists
        resultObject.Options = resultObject.Options || [];
        resultObject.Options.push(inputObject[key]);
      } else {
        // Otherwise, copy the key-value pair to the resultObject
        resultObject[key] = inputObject[key];
      }
    });
  
    return resultObject;
  }

  

  function flattenOptionsInObject(inputObject) {
    if (inputObject.Options && Array.isArray(inputObject.Options)) {
      const flattenedOptions = inputObject.Options.map((option) => {
        const flattenedOption = {
          OptionPrice: option.OptionPrice,
          ...option.ProductId,
        };
        delete flattenedOption.ProductId;
        return flattenedOption;
      });
  
      // Remove the 'Options' key and add the flattened options to the main object
      delete inputObject.Options;
      inputObject.Options = flattenedOptions;
    }
  
    // Include the 'ProductId' key and value from the main 'ProductId' object
    if (inputObject.ProductId) {
      inputObject.ProductIdKey = 'ProductId';
      inputObject.ProductIdValue = inputObject.ProductId;
      delete inputObject.ProductId;
    }
  
    return inputObject;
  }
  

  function transformObject(originalObject) {
    // Create a deep copy of the original object to avoid modifying it directly
    const newObj = JSON.parse(JSON.stringify(originalObject));
  
    // Rename the 'value' key to 'ProductId' in each object within the 'Options' array
    if (newObj.Options && Array.isArray(newObj.Options)) {
      newObj.Options = newObj.Options.map(option => {
        return { ...option, ProductId: option.value };
      });
  
      // Remove the 'value' key from each object within the 'Options' array
      newObj.Options.forEach(option => {
        delete option.value;
      });
    }
  
    return newObj;
  }


  function transformToFinalFormat(originalObject) {
    const finalObject = {
      OptionName: originalObject.OptionName,
      OptionNameLoc: originalObject.OptionNameLoc,
      ComboSizeGroupId: originalObject.ComboSizeGroupId,
      Options: originalObject.Options.map(option => ({
        ComboSizeId: option.ComboSizeId,
        ProductId: option.ProductId,
        OptionPrice: parseFloat(option.OptionPrice), // Convert to number if needed
      })),
    };
  
    return finalObject;
  }
  

  const dispatch=useDispatch()
  const id = useParams();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.combos.add_options")}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleSubmit((values)=>{
            values.ComboSizeGroupId=ComboSizeGroupId
            //console.log(values)
            const reformattedObject = reformatJSON(values);
            //console.log(reformattedObject)
            const processedObject = processObject(reformattedObject);
            //console.log(processedObject);

            const modifiedObject = flattenOptionsInObject(processedObject);
            //console.log(modifiedObject);

            const transformedObject = transformObject(modifiedObject);

              //console.log(transformedObject);

              const finalObject = transformToFinalFormat(transformedObject);

              //console.log(finalObject);

              dispatch(addComboGroupOptions(finalObject)).unwrap()
              .then((_) => {
                toast({ description: t("pages.combos.combo_created") });
                dispatch(getGroupofOptions(id.comboId))
                onClose();
              });

              
              
      
          })}>
            <Stack spacing={6}>
              <FormControl isInvalid={errors.OptionName}>
                <FormLabel
                  htmlFor="OptionName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.categories.name")}
                </FormLabel>
                <Controller
                  name="OptionName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.categories.name")}
                      onChange={(e) => {
                        handleInputChange(e, "OptionName");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.OptionName && errors.OptionName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.OptionNameLoc}>
                <FormLabel
                  htmlFor="OptionNameLoc"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.categories.name_localized")}
                </FormLabel>
                <Controller
                  name="OptionNameLoc"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.categories.name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "OptionNameLoc");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.OptionNameLoc && errors.OptionNameLoc.message}
                </FormErrorMessage>
              </FormControl>

        
              {
                sizes?.map((outerel,index)=>{
                    return(
                    <>
                    <div style={{fontSize:"22px"}}>{t("pages.combos.size")} : " {outerel.ComboSizeName} "</div>

                 
        <MultiSelect
        title={t("pages.combos.products")}
        data={
          products &&
          products.map((el) => ({
            ...el,
            label: el.ProductName,
            value: el.ProductId,
          }))
        }
        isMulti={false}
        isRequired={true}
        onChange={(selectedProduct) => {
          setValue(`ProductId ${index + 1}`, {
            ...selectedProduct,
            ComboSizeId: outerel.ComboSizeId,
          });
        }}
      />
      
                    <FormControl isInvalid={errors[`OptionPrice ${index + 1}`]}>
                    <FormLabel
                      htmlFor={`OptionPrice ${index + 1}`}
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.products.price")}
                    </FormLabel>
                    <Controller
                      name={`OptionPrice ${index + 1}`}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 || t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.products.price")}
                          onChange={(e) => {
                            handleInputChange(e, `OptionPrice ${index + 1}`);
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                  
                    <FormErrorMessage>
                      {errors[`OptionPrice ${index + 1}`] && errors[`OptionPrice ${index + 1}`].message}
                    </FormErrorMessage>
                  </FormControl>
                  
                  
                    </>
                    )
                })
              }

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.inventory_item_details.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  //isLoading={itemTags.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  //isLoading={itemTags.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default NewAddOptianModal;
