import React, { useEffect, useState } from 'react';
import GModal from "components/ui/modal/Modal";
import { Box, Checkbox, Flex, Stack, useToast,Input as ChInput } from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from 'react-redux';
import { getHeaderLookUp, getJournalById } from 'features/general_accounts/service/accountsTreeService';
import { useForm } from 'react-hook-form';
import { MdOutlineChangeCircle } from "react-icons/md";

const SearchDailyRestrictionModal = ({onClose}) => {
    const inventoryCatData=[]
    const { t } = useTranslation();

    const dispatch=useDispatch()

    useEffect(()=>{
      dispatch(getHeaderLookUp())
    },[dispatch])

    const toast = useToast({
      position: "top",
      duration: 3000,
      status: "error",
    });

    const data = useSelector(state => state.AccountTree);

    const { handleSubmit, control, getValues, watch, setValue, register } =useForm();

    watch(["JournalHeaderID"]);

    const [filterByDate,setFilterByDate]=useState(true)

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.DailyRestrictions.show_daily_journal")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              console.log(values.JournalHeaderID);
              if (values.JournalHeaderID === undefined) {
                toast({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                values.JournalHeaderID = values.JournalHeaderID.JournalHeaderID;
                console.log(values.JournalHeaderID);
                dispatch(getJournalById(values.JournalHeaderID))
                  .unwrap()
                  .then((_) => {
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  gap: "15px",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setFilterByDate(!filterByDate)}
                >
                  <MdOutlineChangeCircle color="green" size={25} />
                </div>
                <MultiSelect
                  title={
                    filterByDate
                      ? t("pages.DailyRestrictions.transaction_date")
                      : t("pages.DailyRestrictions.book_number")
                  }
                  data={
                    data.headerLookUp &&
                    data.headerLookUp.map((el) => ({
                      ...el,
                      label: filterByDate ? el.TransactionDate : el.BookNo,
                      value: el.JournalHeaderID,
                    }))
                  }
                  isMulti={false}
                  onChange={(e) => setValue("JournalHeaderID", e)}
                />
              </div>

              <div>
                <label style={{ marginBottom: "5px" }}>
                  {" "}
                  {t("pages.DailyRestrictions.book_number")}
                </label>
                <ChInput
                  readOnly
                  value={getValues("JournalHeaderID.BookNo") || ""}
                ></ChInput>
              </div>

              <div>
                <label style={{ marginBottom: "5px" }}>
                  {t("pages.DailyRestrictions.transaction_date")}
                </label>
                <ChInput
                  readOnly
                  value={getValues("JournalHeaderID.TransactionDate") || ""}
                ></ChInput>
              </div>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.DailyRestrictions.show")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={data.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={data.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
}

export default SearchDailyRestrictionModal;



              // {
              //   filterType === "BookNo" ? (
              //     <MultiSelect
              //       title={t("pages.DailyRestrictions.book_number")}
              //       data={
              //         data.headerLookUp &&
              //         data.headerLookUp.map((el) => ({
              //           ...el,
              //           label: el.BookNo,
              //           value: el.JournalHeaderID,
              //         }))
              //       }
              //       isMulti={false}
              //       onChange={(e) => setValue("JournalHeaderID", e)}
              //     />
              //   ) : (
              //     <MultiSelect
              //       title={t("pages.DailyRestrictions.transaction_date")}
              //       data={
              //         data.headerLookUp &&
              //         data.headerLookUp.map((el) => ({
              //           ...el,
              //           label: el.TransactionDate,
              //           value: el.JournalHeaderID,
              //         }))
              //       }
              //       isMulti={false}
              //       onChange={(e) => setValue("JournalHeaderID", e)}
              //     />
              //   );
              // }


                //  <div
                //    style={{
                //      display: "flex",
                //      alignItems: "center",
                //      justifyContent: "space-between",
                //      marginBottom: "10px",
                //    }}
                //  >
                //    <div
                //      onClick={() => setFilterType("BookNo")}
                //      style={{
                //        padding: "10px",
                //        backgroundColor: "green",
                //        color: "white",
                //        borderRadius: "6px",
                //        cursor: "pointer",
                //      }}
                //    >
                //      {t("pages.DailyRestrictions.book_number")}
                //    </div>
                //    <div
                //      onClick={() => setFilterType("TransactionDate")}
                //      style={{
                //        padding: "10px",
                //        backgroundColor: "green",
                //        color: "white",
                //        borderRadius: "6px",
                //        cursor: "pointer",
                //      }}
                //    >
                //      {t("pages.DailyRestrictions.transaction_date")}
                //    </div>
                //  </div>;