import React, { useEffect } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
  Input,
  Text,
} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  addNewEmployeeWorkPermitInfo,
  getEmployeeList,
} from "features/hr/employees/services/employeeServices";

export default function AddEmployeeWorkPermit({ setshowCompoNumber }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const data = useSelector((state) => state.employeeSlice);

  /*-------------------------------------------- Form Handeling ------------------------------------------------*/
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
      setError("ParLevel", {
        type: "manual",
        message: t("validation.must_be_within_min_max_range"),
      });
    } else {
      clearErrors("ParLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };
  /*-------------------------------------------- Return ------------------------------------------------*/
  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.UserId = localStorage.getItem("userId");
          values.HiringNewEmployeeRequestId =
            data?.NewEmployeeId?.HiringNewEmployeeRequestId;
          values.Step = 4;
          values.WorkPermitStartDate = `${values.WorkPermitStartDate} 00:00:00`;
          values.WorkPermitEndDate = `${values.WorkPermitEndDate} 00:00:00`;

          dispatch(addNewEmployeeWorkPermitInfo(values))
            .unwrap()
            .then((res) => {
              toast({
                description: t("pages.hr.new_employee_work_permit_Added"),
              });
              dispatch(getEmployeeList());
              setshowCompoNumber(5);
            });
        })}
      >
        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Text
            color="dark"
            textTransform="capitalize"
            fontSize="xl"
            fontWeight={"bold"}
          >
            {t("pages.hr.WorkPermit")}
          </Text>
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <FormControl isInvalid={errors.WorkPermitNumber}>
              <FormLabel
                htmlFor="WorkPermitNumber"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.WorkPermitNumber")}
              </FormLabel>
              <Controller
                name="WorkPermitNumber"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 10,
                    message: t("pages.hr.hr_validation.number_short_10"),
                  },
                  maxLength: {
                    value: 15,
                    message: t("pages.hr.hr_validation.number_long"),
                  },
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.WorkPermitNumber")}
                    onChange={(e) => {
                      handleInputChange(e, "WorkPermitNumber");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.WorkPermitNumber && errors.WorkPermitNumber.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.WorkPermitStartDate}>
              <FormLabel
                htmlFor="WorkPermitStartDate"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.WorkPermitStartDate")}
              </FormLabel>
              <Controller
                name="WorkPermitStartDate"
                control={control}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.WorkPermitStartDate")}
                    onChange={(e) => {
                      handleInputChange(e, "WorkPermitStartDate");
                    }}
                    type="date"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.WorkPermitStartDate &&
                  errors.WorkPermitStartDate.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.WorkPermitEndDate}>
              <FormLabel
                htmlFor="WorkPermitEndDate"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.WorkPermitEndDate")}
              </FormLabel>
              <Controller
                name="WorkPermitEndDate"
                control={control}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.WorkPermitEndDate")}
                    onChange={(e) => {
                      handleInputChange(e, "WorkPermitEndDate");
                    }}
                    type="date"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.WorkPermitEndDate && errors.WorkPermitEndDate.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Box>

        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Flex gap={2} flexWrap="wrap">
            <GButton
              title={t("pages.profile.save_changes")}
              color="light"
              border="1px solid transparent"
              bg="primary"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              type="submit"
            />
          </Flex>
        </Box>
      </form>
    </>
  );
}
