import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  subGroupDropList,
  subGroupList,
  updateGroupParent,
} from "../../services/subGroupsService";
import { useParams } from "react-router-dom";

const AddSubGroupModal = ({ onClose }) => {
  const { t } = useTranslation();
  const { handleSubmit, setValue } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subGroupDropList());
  }, [dispatch]);

  const branches = useSelector((state) => state.subGroups);
  //console.log(branches.subGroupsData1);

  // console.log("all",branches)

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const id = useParams();

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.groups.add_subgroups")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
                  values.UserId = localStorage.getItem("userId");

            
                //console.log(values);
                    // if (values.ParentGroup === undefined) {
                    //   toast2({
                    //     description: t("pages.DailyRestrictions.searchvalidation"),
                    //   });
                    // } else {
                      // values.MenuGroupId = values.ParentGroup?.map((el) => ({
                      //   MenuGroupId: el.MenuGroupId,
                      // }));
                      //values.MenuGroupId = values.ParentGroup?.map((el) => el.MenuGroupId) || [];

                      values.MenuGroupId = values.MenuGroupId.MenuGroupId;
                      values.ParentGroup = id.groupId;
                      // const testData={
                      //   "MenuGroupId": [4, 5, 6],
                      //   "ParentGroup": "23"
                      // }
                      dispatch(updateGroupParent(values))
                        .unwrap()
                        .then((_) => {
                          toast({ description: t("pages.groups.subgroup_added") });
                          dispatch(subGroupList(id.groupId));
                          onClose();
                        });
                      console.log(values)
                    // }
            })}
          >
            <Stack>
                  {/* <MultiSelect
                      title={t("pages.inventory.tags")}
                      data={branches.subGroupsData1 && branches.subGroupsData1.map(el => ({
                      ...el,
                      label: el.GroupName,
                      value: el.MenuGroupId,

                      }))}
                      isMulti={false}
                      isRequired={true}
                      onChange={(e) => setValue("ParentGroup", e)}
                  /> */}

              <MultiSelect
                title={t("pages.groups.subgroups")}
                data={
                      Array.isArray(branches.subGroupsData1)
                        ? branches.subGroupsData1.map((el) => ({
                            ...el,
                            label: el.GroupName,
                            value: el.MenuGroupId,
                          }))
                        : []
                    }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("MenuGroupId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddSubGroupModal;
