import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- create Discount Branches  -------------------------------------------------------------------*/
export const createDiscountBranches = createAsyncThunk(
  "discountsBranches/createDiscountBranches",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCount/AddDiscountBranches?branchid=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Get Branches Discounts List  -------------------------------------------------------------------*/
export const getDiscountsBranches = createAsyncThunk(
  "discountsBranches/getDiscountsBranches",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetDiscountBranches?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- all Branches In Discount  -------------------------------------------------------------------*/
export const allBranchesInDiscount = createAsyncThunk(
  "discountsBranches/allBranchesInDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCount/DiscountAllBranches?discountid=${args}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete Branch In Discount  -------------------------------------------------------------------*/
export const deleteBranchInDiscount = createAsyncThunk(
  "discountsBranches/deleteBranchInDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCountBranches/DisCountBranchesById?DisCountBranchesId={"DisCountBranchesId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Get Tags In Branches ---------------------------------------------------*/
export const getTagsInBranches = createAsyncThunk(
  "discountsBranches/getTagsInBranches",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/LookupDiscountBranches?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
