import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import {
  createBulkCoupon,
  getCoupons,
  getDiscountsCoupons,
} from "../../services/couponsService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useEffect, useState } from "react";
import { clearDiscounts } from "../../store/couponsSlice";

const CreateBulkModal = ({ onClose }) => {
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const dispatch = useDispatch();
  const coupons = useSelector((state) => state.coupons);
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm();
  watch(["Ref"]);
  const { t } = useTranslation();

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  useEffect(() => {
    const StartDate = getValues("StartDate");
    const EndDate = getValues("EndDate");

    console.log("StartDate", StartDate, "EndDate", EndDate);

    if (StartDate && EndDate && new Date(StartDate) > new Date(EndDate)) {
      setError("StartDate", {
        type: "manual",
        message: t("validation.start_date_must_be_before_end_date"),
      });
    } else {
      clearErrors("StartDate");
    }
  }, [getValues("StartDate"), getValues("EndDate")]);

  const [couponType, setCouponType] = useState({});

  const couponsType = {
    couponsTypeDropdown: [
      {
        couponsTypeId: 1,
        couponsTypeName: "Order",
      },
      {
        couponsTypeId: 2,
        couponsTypeName: "Product",
      },
    ],
  };

  let discounts = useSelector((state) => state.coupons);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(clearDiscounts());
    setCouponType({});
  }, []);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.coupons.create_coupon")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.DiscountId = values.DiscountId.DisCountId;
              values.UserId = localStorage.getItem("userId");
              if (
                values.DiscountId === undefined ||
                values.CouponTypeId === undefined
              ) {
                toast2({ description: t("validation.required") });
              } else {
                dispatch(createBulkCoupon(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.coupons.bulk_coupon_created"),
                    });
                    dispatch(getCoupons());
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <FormControl isInvalid={errors.NumOfCoupons}>
                <FormLabel
                  htmlFor="NumOfCoupons"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.number_of_coupons")}
                </FormLabel>
                <Controller
                  name="NumOfCoupons"
                  control={control}
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.coupons.number_of_coupons")}
                      onChange={(e) => {
                        handleInputChange(e, "NumOfCoupons");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.NumOfCoupons && errors.NumOfCoupons.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.Name}>
                <FormLabel
                  htmlFor="Name"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.name")}
                </FormLabel>
                <Controller
                  name="Name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.coupons.name")}
                      onChange={(e) => {
                        handleInputChange(e, "Name");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.Name && errors.Name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.NameLoc}>
                <FormLabel
                  htmlFor="NameLoc"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.name_localized")}
                </FormLabel>
                <Controller
                  name="NameLoc"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.coupons.name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "NameLoc");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.NameLoc && errors.NameLoc.message}
                </FormErrorMessage>
              </FormControl>

              <MultiSelect
                title={t("pages.coupons.coupon_type")}
                data={
                  couponsType.couponsTypeDropdown &&
                  couponsType.couponsTypeDropdown.map((el) => ({
                    ...el,
                    label: el.couponsTypeName,
                    value: el.couponsTypeId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => {
                  let couponTypeID;
                  if (e.couponsTypeName === "Order") {
                    dispatch(getDiscountsCoupons(1));
                    couponTypeID = 1;
                  } else if (e.couponsTypeName === "Product") {
                    dispatch(getDiscountsCoupons(2));
                    couponTypeID = 2;
                  }
                  setValue("CouponTypeId", couponTypeID);
                  setCouponType(e);
                }}
              />

              {couponType && couponType?.couponsTypeName === "Product" && (
                <FormControl isInvalid={errors.NumOfProduct}>
                  <FormLabel
                    htmlFor="NumOfProduct"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.coupons.number_of_products")}
                  </FormLabel>
                  <Controller
                    name="NumOfProduct"
                    control={control}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.coupons.number_of_products")}
                        onChange={(e) => {
                          handleInputChange(e, "NumOfProduct");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.NumOfProduct && errors.NumOfProduct.message}
                  </FormErrorMessage>
                </FormControl>
              )}

              <MultiSelect
                title={t("pages.coupons.discount")}
                data={
                  discounts?.discounts &&
                  discounts?.discounts?.map((el) => ({
                    ...el,
                    label: el.DisCountName,
                    value: el.DisCountId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("DiscountId", e)}
              />

              <FormControl isInvalid={errors.CouponMaxUses}>
                <FormLabel
                  htmlFor="CouponMaxUses"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.maximum_uses")}
                </FormLabel>
                <Controller
                  name="CouponMaxUses"
                  control={control}
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.coupons.maximum_uses")}
                      onChange={(e) => {
                        handleInputChange(e, "CouponMaxUses");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.CouponMaxUses && errors.CouponMaxUses.message}
                </FormErrorMessage>
              </FormControl>

              {/* Start Date */}
              <FormControl isInvalid={errors.StartDate}>
                <FormLabel
                  htmlFor="StartDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.start_date")}
                </FormLabel>
                <Controller
                  name="StartDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      placeholder={t("pages.coupons.start_date")}
                      onChange={(e) => {
                        handleInputChange(e, "StartDate");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.StartDate && errors.StartDate.message}
                </FormErrorMessage>
              </FormControl>

              {/* End Date */}
              <FormControl isInvalid={errors.EndDate}>
                <FormLabel
                  htmlFor="EndDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.end_date")}
                </FormLabel>
                <Controller
                  name="EndDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      placeholder={t("pages.coupons.end_date")}
                      onChange={(e) => {
                        handleInputChange(e, "EndDate");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.EndDate && errors.EndDate.message}
                </FormErrorMessage>
              </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={coupons.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={coupons.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default CreateBulkModal;
