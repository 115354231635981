import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------- Get  Payment Method  Type  -------------------------------------------------  */
export const getPaymentMethods = createAsyncThunk("paymentMethods/getPaymentMethods", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/PaymentMethod/PaymentMethodList" );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------- Create  Payment Method   -------------------------------------------------  */
export const createPaymentMethod = createAsyncThunk("paymentMethods/createPaymentMethod",async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/PaymentMethod/AddEditPaymentMethod?paymentmethod=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------- Updated  Payment Method   -------------------------------------------------  */
export const updatePaymentMethod = createAsyncThunk("paymentMethods/updatePaymentMethod", async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/PaymentMethod/AddEditPaymentMethod?paymentmethod=${JSON.stringify(args.values)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------- Deleted  Payment Method   -------------------------------------------------  */
export const deletePaymentMethod = createAsyncThunk("paymentMethods/deletePaymentMethod",async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/PaymentMethod/AddEditPaymentMethod?paymentmethod=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

//getPaymentMethodFilteredData

export const getPaymentMethodFilteredData = createAsyncThunk("paymentMethods/getPaymentMethodFilteredData ", async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetPaymentMethodFilteredData = createAsyncThunk( "paymentMethods/resetPaymentMethodFilteredData ", async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);