import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button"
import OutOfStockModal from './modals/OutOfStockModal';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOutOfStock } from '../services/modifiersOptionsCustomService';
import OutOfStockTable from './OutOfStockTable';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';

const OutOfStockModifier = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

    const { t } = useTranslation();

    const [showModal,setshowModal]=useState(false)

    const id =useParams()
    //console.log(id.modifiersOptionId)

    const dispatch=useDispatch()

    useEffect(()=>{
      dispatch(getOutOfStock(id.modifiersOptionId))
    },[dispatch,id])


    const outOfStockData = useSelector(state => state.modifiersOptionscustom);

    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


    if(showModal && ManageMenu===false){
      toast2({ description:  t("general.authorization")})
      setshowModal(false)
    }

    //console.log(outOfStockData)

  return (
    <div>
        
        <Box>
        

                
        <Flex
            alignItems="center" justifyContent="space-between"
            gap={2} flexWrap="wrap" mb={6}
        >

        <Text color="text" textTransform="capitalize">
          {t("pages.modifiers_options.out_Of_Stock")}
        </Text>

        <GButton
            title={t("pages.combos.select_branches")}
            bg="light"
            color="text"
            fontSize="14px"
            border="1px solid"
            borderColor="border"
            hover={{ bg: "light" }}
            onClick={() => setshowModal( true)}
                />
            </Flex>
        




          {showModal&&(
            <OutOfStockModal onClose={() => setshowModal(false)} />
        )}


        {outOfStockData.isLoading ? (
          <Spinner />
        ) : outOfStockData.outOfStock?.length === 0 ? (
          <Flex
            p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
          >
            {t("pages.branches.no_data")}
          </Flex>
        ) : outOfStockData.outOfStock?.length > 0 ? (
          <OutOfStockTable
          data={outOfStockData.outOfStock}
          />
        ):(
          <Flex
          p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
        )
        }


        </Box>
    
    
     </div>
  );
}

export default OutOfStockModifier;
