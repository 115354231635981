import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------- Get Gifts Cards List --------------------------------------------- */
export const getGiftsCards = createAsyncThunk("giftsCards/getGiftsCards", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Menu/GiftCards/GiftCardsList");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  },
);
/* ------------------------------------------------- Active DeActive Gifts Cards --------------------------------------------- */
export const activeDeActiveGiftsCards = createAsyncThunk("giftsCards/activeDeActiveGiftsCards",  async (args, thunkApi) => {
  console.log(args);
    try {
      const { data } = await axios.get(`/Menu/GiftCards/DeActivateGiftCardsById?giftcardsid={"GiftCardsId":${args}}`);
      console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  },
)
/* ------------------------------------------------- Gifts Cards --------------------------------------------- */
export const getGiftsCardById = createAsyncThunk(
  "giftsCards/getGiftsCardById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/GiftCard/GiftCardByGiftCardId?giftcardid=${JSON.stringify({ "GiftCardsId": args.giftsCardId })}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createGiftsCard = createAsyncThunk(
  "giftsCards/createGiftsCard",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/GiftCards/AddGiftCards?giftcards=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  },
  
);

export const updateGiftsCard = createAsyncThunk(
  "giftsCards/updateGiftsCard",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/GiftCards/EditGiftCards?giftcardsid=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------- Delete Gifts Cards --------------------------------------------- */
export const deleteGiftCard = createAsyncThunk("giftsCards/deleteGiftCard " , async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/GiftCards/DeleteGiftCardsById?giftcardsid={"GiftCardsId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ---------------------------------------------------------------------------------------------- */

export const getFilteredData = createAsyncThunk(
  "giftsCards/getFilteredData ",
  async (args, thunkApi) => {
    
    try {
      console.log("jooo", args);
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const resetFilteredData = createAsyncThunk(
  "giftsCards/resetFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);