import { createSlice } from "@reduxjs/toolkit";
import { GetBranchSalesReport, GetCashierSalesReport, GetCategorySalesReport, 
         GetComboSalesReport, getBranchSalesReportFilteredData, getCashierSalesReportFilteredData, 
         getCategorySalesReportFilteredData, getComboSalesReportFilteredData, getSalesReportFilteredData, 
         resetBranchSalesReportFilteredData, resetCashierSalesReportFilteredData, resetCategorySalesReportFilteredData, 
         resetComboSalesReportFilteredData, resetSalesReportFilteredData } from "../services/salesReportsServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  branchSalesData: [],
  cashierSalesData: [],
  categorySalesData: [],
  comboSalesData: [],
  // Filter data
  branchFilteredData : [],
  cahshierFilteredData : [],
  categoryFilteredData : [],
  comboFilteredData : [],
};

const salesReportSlice = createSlice({
  name: "salesReport",
  initialState,
  extraReducers: (builder) => {



  /* -------------------------------------- Branch Sales Report List ------------------------------- */
  builder.addCase(GetBranchSalesReport.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(GetBranchSalesReport.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branchSalesData = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(GetBranchSalesReport.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

/* ------------------------------------------------------------------------------------------- */
 /* ----------------------------- Branch Sales Filter Data ------------------------------*/
    builder.addCase(getBranchSalesReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getBranchSalesReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branchFilteredData = payload;
      state.errors = null;
    });
    builder.addCase(getBranchSalesReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetBranchSalesReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetBranchSalesReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branchFilteredData = [];
      state.errors = null;
    });
    builder.addCase(resetBranchSalesReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */



  /* -------------------------------------- Cashier Sales Report List ------------------------------- */
  builder.addCase(GetCashierSalesReport.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(GetCashierSalesReport.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.cashierSalesData = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(GetCashierSalesReport.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

/* ------------------------------------------------------------------------------------------- */
 /* ----------------------------- Cashier Sales Filter Data ------------------------------*/
    builder.addCase(getCashierSalesReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getCashierSalesReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.cahshierFilteredData = payload;
      state.errors = null;
    });
    builder.addCase(getCashierSalesReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetCashierSalesReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetCashierSalesReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.cahshierFilteredData = [];
      state.errors = null;
    });
    builder.addCase(resetCashierSalesReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */



  /* -------------------------------------- Category Sales Report List ------------------------------- */
    builder.addCase(GetCategorySalesReport.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(GetCategorySalesReport.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.categorySalesData = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(GetCategorySalesReport.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

/* ------------------------------------------------------------------------------------------- */
 /* ----------------------------- Category Sales Filter Data ------------------------------*/
    builder.addCase(getCategorySalesReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getCategorySalesReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.categoryFilteredData = payload;
      state.errors = null;
    });
    builder.addCase(getCategorySalesReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetCategorySalesReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetCategorySalesReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.categoryFilteredData = [];
      state.errors = null;
    });
    builder.addCase(resetCategorySalesReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */



  /* -------------------------------------- Combo Sales Report List ------------------------------- */
    builder.addCase(GetComboSalesReport.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(GetComboSalesReport.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.comboSalesData = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(GetComboSalesReport.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

/* ------------------------------------------------------------------------------------------- */
 /* ----------------------------- Combo Sales Filter Data ------------------------------*/
    builder.addCase(getComboSalesReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getComboSalesReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.comboFilteredData = payload;
      state.errors = null;
    });
    builder.addCase(getComboSalesReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetComboSalesReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetComboSalesReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.comboFilteredData = [];
      state.errors = null;
    });
    builder.addCase(resetComboSalesReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */




  }
});

export default salesReportSlice.reducer;