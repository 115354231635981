import React, { useEffect } from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from 'react-redux';
import { addPriceTagsProducts, getPriceTagsProducts, getProductInPriceTags } from '../../services/priceTagsServices';

export default function AddProductsModal({ onClose,id }){
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
    const { handleSubmit, setValue } = useForm();

    const PriceTagsData = useSelector(state => state.priceTags);

    useEffect(() => {
      dispatch(getProductInPriceTags());
    }, [dispatch])
    


  
  return <>
    <GModal
      onClose={onClose}
      modalHeader={t("pages.price_tags.add_products")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.PriceTagsId = +id.PriceTagsId;
            values.NewProductPrice = 0;
            values.ProductId = values.ProductId?.ProductId;
            console.log(values);
            if(values.ProductId === undefined ){
                toast2({ description: t("validation.choose_first") });
            }
            else {
                  dispatch(addPriceTagsProducts(values))
                    .unwrap().then(_ => {
                      toast({ description: t("pages.price_tags.product_added") });
                      dispatch(getPriceTagsProducts(id.PriceTagsId));
                      onClose()
                    });
              }
          })} >

            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.price_tags.products")}
                data={PriceTagsData?.productsDropdown?.map(el => ({
                  ...el,
                  label: el.ProductName,
                  value: el.ProductId
                }))}
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("ProductId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  </>
};