import { createSlice } from "@reduxjs/toolkit";
import { getIngredientUnit, getStorageUnit } from "../services/storageUnitService";


let initialState = {
  isLoading: false,
  data: [],
  data1: [],
  pages: 0,
  errors: null
};

const storageUnitSlice = createSlice({

  name: "storageUnit",
  initialState,

  extraReducers: (builder) => {
/* --------------------------------------------------------  Get Storage Unit  ---------------------------------------------- */
    builder.addCase(getStorageUnit.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })

    builder.addCase(getStorageUnit.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })

    builder.addCase(getStorageUnit.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* --------------------------------------------------------  Get Ingredient Unit  ---------------------------------------------- */
    builder.addCase(getIngredientUnit.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })

    builder.addCase(getIngredientUnit.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data1 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })

    builder.addCase(getIngredientUnit.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }

})

export default storageUnitSlice.reducer