import React, { useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr,} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Pagination from "components/ui/pagination/Pagination";

export default function FilteredDataTable({data}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigatefunction = (id) => {
      navigate(`${id}`);
    };
    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
        return data.slice(firstIndex, lastIndex);
      };
    
    // Calculate records for activeDiscounts
    const suppliersFilteredData = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
 /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <>
    <Table>
        <Thead>
              <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.suppliers.supplier_name")}</Th>
                  <Th>{t("pages.suppliers.supplier_code")}</Th>
                  <Th>{t("pages.suppliers.contact_name")}</Th>
              </Tr>
        </Thead>
        <Tbody>
            {
              suppliersFilteredData?.map((item, index) => (
              <Tr
                  key={index}
                  onClick={() => navigate(`${item.SuppliersId}`)}
                >
                  <Td> {index + 1} </Td>
                  <Td onClick={() => navigatefunction(item.SuppliersId)}>
                    {item.SuppliersName}
                  </Td>
                  <Td>{item.SuppliersCode}</Td>
                  <Td>{item.ContactName}</Td>
              </Tr>
            ))
            }
        </Tbody>
    </Table>
    <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </>

  </>
};
