import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Controller , useForm } from "react-hook-form";
import {Box,Checkbox, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal"
import Input from "components/form/Input"
import GButton from "components/ui/button/Button"
import { createTag, getTags } from "../../services/tagsService"

const CreateModal = ({data,onClose}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tags = useSelector(state => state.tags);
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, control, setValue,  register,  formState: { errors },  trigger} =  useForm();


  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.tags.create_tag")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.TagGroupId = data.TagGroupId;
            values.TagId = null;
            if(values.TagName === undefined  || values.TagNameLocalized == undefined){
              toast2({ description: t("general.validate") });
            }
            else {
              dispatch(createTag(values))
                .unwrap()
                .then(_ => {
                  toast({ description: t("pages.tags.tag_created") });
                  dispatch(getTags());
                  onClose()
                });
            }
          })}>
            <Stack spacing={6}>
      <FormControl isInvalid={errors.TagName}>
          <FormLabel
            htmlFor="TagName"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.tags.name")}
          </FormLabel>
          <Controller
            name="TagName"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.tags.name")}
                onChange={(e) => {
                  handleInputChange(e, "TagName");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.TagName &&
              errors.TagName.message}
          </FormErrorMessage>
      </FormControl>


      <FormControl isInvalid={errors.TagNameLocalized}>
          <FormLabel
            htmlFor="TagNameLocalized"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.tags.name_localized")}
          </FormLabel>
          <Controller
            name="TagNameLocalized"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.tags.name_localized")}
                onChange={(e) => {
                  handleInputChange(e, "TagNameLocalized");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.TagNameLocalized &&
              errors.TagNameLocalized.message}
          </FormErrorMessage>
      </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={tags.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={tags.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default CreateModal