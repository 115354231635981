import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import {
  addNewPermessionType,
  getPermessionTypeList,
} from "features/hr/hrSetting/services/hrSettingServices";

export default function AddPremessionypeModal({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const [formNote, setFormNote] = useState("");

  /**************************** Validation ******************************* */

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.Add_Permission_Type")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.PermissionTypeId = null;
                values.Notes = formNote;
                values.UserId = localStorage.getItem("userId");
                dispatch(addNewPermessionType(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.hr.permissionType_Added_successfuly"
                      ),
                    });
                    dispatch(getPermessionTypeList());
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.PermissionTypeName}>
                  <FormLabel
                    htmlFor="PermissionTypeName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.PermissionTypeName")}
                  </FormLabel>
                  <Controller
                    name="PermissionTypeName"
                    control={control}
                    // defaultValue={details?.InsuranceJob}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.PermissionTypeName")}
                        onChange={(e) => {
                          handleInputChange(e, "PermissionTypeName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.PermissionTypeName &&
                      errors.PermissionTypeName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.PermissionTypeNameEn}>
                  <FormLabel
                    htmlFor="PermissionTypeNameEn"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.PermissionTypeNameEn")}
                  </FormLabel>
                  <Controller
                    name="PermissionTypeNameEn"
                    control={control}
                    // defaultValue={details?.InsuranceJob}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.PermissionTypeNameEn")}
                        onChange={(e) => {
                          handleInputChange(e, "PermissionTypeNameEn");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.PermissionTypeNameEn &&
                      errors.PermissionTypeNameEn.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.HoursCount}>
                  <FormLabel
                    htmlFor="HoursCount"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.HoursCount")}
                  </FormLabel>
                  <Controller
                    name="HoursCount"
                    control={control}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 1,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 5,
                        message: t("validation.name_long_25"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.HoursCount")}
                        onChange={(e) => {
                          handleInputChange(e, "HoursCount");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.HoursCount && errors.HoursCount.message}
                  </FormErrorMessage>
                </FormControl>

                <Box>
                  <Text>{t("general.notes")}</Text>
                  <Textarea
                    placeholder="Here is a sample placeholder"
                    marginTop={"2"}
                    value={formNote}
                    onChange={(e) => setFormNote(e.target.value)}
                  />
                </Box>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
