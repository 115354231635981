import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useForm } from "react-hook-form"
import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import Input from "components/form/Input"
import GButton from "components/ui/button/Button"
import { createDevice, getDevices } from "../../services/devicesService"
import { getDevicesTypes } from "../../services/devicesTypesService"
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import { getBranchesForCallCenter } from "features/callCenter/service/callCenterService"
import { generator } from "utils/generator"

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue,  register,  control,getValues,watch,formState: { errors }, trigger} = useForm();

  const devices = useSelector(state => state.devices);
  const devicesTypes = useSelector(state => state.devicesTypes);
  const branches = useSelector(state => state.callcenter);

  useEffect(() => {
    dispatch(getBranchesForCallCenter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDevicesTypes());
  }, [dispatch]);

  watch(["DevicesRef"]);


  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.devices.create_device")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.DevicesId = null;
            values.BranchId = values.BranchId?.BranchId;
            values.DevicesTypeId = values.DevicesTypeId?.DevicesTypeId;
            if(values.BranchId === undefined || values.DevicesTypeId===undefined){
              toast2({ description: t("general.validate") });
            }
            else {
                dispatch(createDevice(values))
                  .unwrap()
                  .then(_ => {
                    toast({ description: t("pages.devices.device_created") });
                    dispatch(getDevices());
                    onClose()
                  });
            }
            // dispatch(createDevice(values))
            console.log(values)
          })}>
            <Stack spacing={6}>
              <MultiSelect
                data={devicesTypes.data && devicesTypes.data.map(el => ({
                  ...el,
                  label: el.DevicesTypeName,
                  value: el.DevicesTypeId
                }))}
                title={t("pages.devices.device_type")}
                isMulti={false}
                onChange={(e) => setValue("DevicesTypeId", e)}
              />

             

              
              <FormControl isInvalid={errors.DevicesName}>
              <FormLabel htmlFor="DevicesName" style={{textTransform:"capitalize"}}>
              {t("pages.devices.name")}
              </FormLabel>
              <Controller
                name="DevicesName"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message:t("validation.this_name_invalid") ,
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.devices.name")}
                    onChange={(e)=>handleInputChange(e, "DevicesName")} 
                  />
                )}
              />
              <FormErrorMessage>{errors.DevicesName && errors.DevicesName.message}</FormErrorMessage>
            </FormControl>
         

              
              <Flex alignItems="flex-end" gap={4}>
                <Input
                  label={t("pages.branches.branch_code")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.branches.branch_code")}
                  isRequired
                  value={getValues("DevicesRef") || ""}
                  readOnly
                />
                <GButton
                  title={t("pages.branches.generate")}
                  height="50px"
                  bg="light"
                  border="1px solid"
                  borderColor="border"
                  hover={{
                    bg: "primary",
                    borderColor: "primary",
                    color: "light",
                  }}
                  onClick={() => setValue("DevicesRef", generator({}))}
                />
              </Flex>


              <MultiSelect
                data={branches.branches && branches.branches.map(el => ({
                  ...el,
                  label: el.BranchName,
                  value: el.BranchId
                }))}
                title={t("pages.devices.branch")}
                isMulti={false}
                onChange={(e) => setValue("BranchId", e)}
              />

              <Checkbox {...register("DevicStatus")}>
                {t("pages.devices.used")}
              </Checkbox>


              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={devices.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={devices.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default CreateModal;