import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getDevicesTypes = createAsyncThunk(
  "devicesTypes/getDevicesTypes",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Device/LookUpDeviceType`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getProductCat = createAsyncThunk(
  "devicesTypes/getProductCat",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Brand/LookUpBrandProductcatg`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const getordersType = createAsyncThunk(
  "devicesTypes/getordersType",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Device/LookUpDefaultOrderType`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const getordersTypeDisabled = createAsyncThunk(
  "devicesTypes/getordersTypeDisabled",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Device/LookUpDisabledOrderType`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const getkitchenLanguage = createAsyncThunk(
  "devicesTypes/getkitchenLanguage",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Device/LookUpKitchenPrintLang`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);






export const getorderTags = createAsyncThunk(
  "devicesTypes/getorderTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/LookupTags`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);




export const getBrnachesSection = createAsyncThunk(
  "devicesTypes/getBrnachesSection",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/BranchSection/LookupBranchSection`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const editSubCasherSettings = createAsyncThunk(
  "devicesTypes/editSubCasherSettings",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Device/EditDeviceSetting?devicesett=${JSON.stringify(args)}`);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);