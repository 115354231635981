import React from 'react';
import Chart from 'react-apexcharts';

const ColumnChart = () => {
  const columnChartData = {
    series: [
      {
        name: 'Series 1',
        data: [44, 55, 41, 64, 22, 43],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6'],
      },
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <div>
      <Chart
        options={columnChartData.options}
        series={columnChartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ColumnChart;
