import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import Input from "components/form/Input";
import {  Flex, Stack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPriceTagsProductsFilteredData } from "../../services/priceTagsServices";

export default  function PriceTagsProductsFilter ({ onClose, data})  {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState(data);

  // console.log(filteredData)
  const [filterCriteria, setFilterCriteria] = useState({
    ProductName:""
  });

  // Product[0]?.ProductName

  const handleFilterProducts = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const { ProductName } = filterCriteria;

      let filterProductName = true;

      if (ProductName) {
        filterProductName = item.Product[0]?.ProductName.toLowerCase().includes(ProductName.toLowerCase());
      }

      return (
        filterProductName 
      );

    });

    setFilteredData(filteredResult);
    dispatch(getPriceTagsProductsFilteredData(filteredResult));
    onClose();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.price_tags.filter_price_tags_product")}
      modalContent={(
        <Box p={4}>
          <form 
          onSubmit={handleFilterProducts}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.price_tags.product_name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.price_tags.product_name")}
                name="ProductName"
                value={filterCriteria.ProductName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ProductName: e.target.value,
                  })
                }
              />
             

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
};