import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import {
  Button,
  Flex,
  Text,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import NoCategories from "../components/NoCategories";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import {
  getCategories,
  getDeleted,
  resetFilteredData,
} from "../services/categoriesService";
import routes from "../../../../navigations/routes";
import DeletedTable from "../components/DeletedTable";
import { IoCloseOutline } from "react-icons/io5";
import ExportModal from "components/ui/modal/ExportModal";
import CryptoJS from "crypto-js";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Categories = () => {
  const retrievedArray = useDecryptedPrivilege();
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories);

  const [sortType, setSortType] = useState("all");

  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(null);
  const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState(null);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(null);

  const [exportModal, SetExportModal] = useState(false);
  const [showFilterCategoriesModal, setShowFilterCategoriesModal] =
    useState(null);

  const resetFunction = () => {
    dispatch(resetFilteredData());
  };

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDeleted());
  }, [dispatch]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  if (ManageMenu === false && ReadMenu === false) {
    return <NotAuthorizedCompo />;
  }

  if (showCreateCategoryModal === true && ManageMenu === false) {
    toast2({ description: t("general.authorization") });
    setShowCreateCategoryModal(false);
  }

  if (showDeleteCategoryModal && ManageMenu === false) {
    toast2({ description: t("general.authorization") });
    setShowDeleteCategoryModal(false);
  }

  if (showUpdateCategoryModal && ManageMenu === false) {
    toast2({ description: t("general.authorization") });
    setShowUpdateCategoryModal(false);
  }

  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.categories.categories")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
            >
              <div onClick={() => SetExportModal(true)}>
                {t("general.export")}
              </div>
            </Button>

            <GButton
              title={t("pages.categories.create_category")}
              onClick={() => setShowCreateCategoryModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color={sortType === "all" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "all" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setSortType("all")}
          />

          <GButton
            title={t("general.deleted")}
            bg="none"
            color={sortType === "deleted" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "deleted" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setSortType("deleted")}
          />
        </Flex>

        <GButton
          title={
            categories.filteredData.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
            categories.filteredData.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          // onClick={() => setShowFilterModal(true)}
          onClick={
            categories.filteredData.length === 0
              ? () => setShowFilterCategoriesModal(true)
              : resetFunction
          }
        />
      </Flex>

      {categories.filteredData.length === 0 ? (
        categories.isLoading ? (
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ) : categories.data.length === 0 ? (
          <NoCategories
            onClickCreateCategoryBtn={() => setShowCreateCategoryModal(true)}
          />
        ) : sortType === "all" ? (
          <Table
            categories={categories.data}
            handleUpdate={(category) => setShowUpdateCategoryModal(category)}
            handleDelete={(category) => setShowDeleteCategoryModal(category)}
          />
        ) : (
          <DeletedTable data={categories.item}></DeletedTable>
        )
      ) : (
        <Table
          categories={categories.filteredData}
          handleUpdate={(category) => setShowUpdateCategoryModal(category)}
          handleDelete={(category) => setShowDeleteCategoryModal(category)}
        />
      )}

      {showCreateCategoryModal && (
        <CreateModal onClose={() => setShowCreateCategoryModal(null)} />
      )}

      {showUpdateCategoryModal && (
        <UpdateModal
          data={showUpdateCategoryModal}
          onClose={() => setShowUpdateCategoryModal(null)}
        />
      )}

      {showDeleteCategoryModal && (
        <DeleteModal
          data={showDeleteCategoryModal}
          onClose={() => setShowDeleteCategoryModal(null)}
        />
      )}

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={sortType === "all" ? categories.data : categories.item}
          fileName={sortType === "all" ? "Categories" : "Deleted Categories"}
        ></ExportModal>
      )}

      {showFilterCategoriesModal && (
        <FilterModal
          data={categories.data}
          onClose={() => setShowFilterCategoriesModal(null)}
        />
      )}
    </>
  );
};

export default Categories;
