import { createSlice } from "@reduxjs/toolkit";
import {createKitchenFlow,getAllKitchenFlow,getkitchenDetails,updateKitchenFlow,AddStationKitchen,getKitchenFlowStations,getBrancheKitchenFlow,AddKitchenFlowCertinBranche,getKitchenFlowBranches,getAllProductKitchenFlow,AddProductKitchenFlow,getKitchenFlowProduct,deleteKitchenFlow,activeKitchenFlow, resetkitchenFlowFilteredData, getkitchenFlowFilteredData} from "../services/kitchenFlowService"

let initialState = {
    isLoading: false,
    data: [],
    kitchenFlowDetials:[],
    branches:[],
    stations:[],
    kitchenFlowBranches:[],
    products:[],
    kitchenFlowProducts:[],
    pages: 0,
    errors: null,

    filteredData : []
  };
  

  const kitchenFlowSlice = createSlice({
    name: "kitchenFlow",
    initialState,

    extraReducers: (builder) => {
/* ----------------------------------------------- Create kitchen Flow ------------------------------------------------------ */
        builder.addCase(createKitchenFlow.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(createKitchenFlow.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
          builder.addCase(createKitchenFlow.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
/* ----------------------------------------------- Get All kitchen Flow ------------------------------------------------------ */
          builder.addCase(getAllKitchenFlow.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getAllKitchenFlow.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.data)
            state.errors = null;
          })
          builder.addCase(getAllKitchenFlow.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
/* ----------------------------------------------- Get kitchen Flow Details --------------------------------------------------- */
          builder.addCase(getkitchenDetails.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getkitchenDetails.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.kitchenFlowDetials = payload
            //console.log(state.kitchenFlowDetials)
            state.errors = null;
          })
          builder.addCase(getkitchenDetails.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
/* ----------------------------------------------- Update kitchen Flow ------------------------------------------------------ */
          builder.addCase(updateKitchenFlow.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(updateKitchenFlow.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //state.data.push(payload);
            state.errors = null;
          })
          builder.addCase(updateKitchenFlow.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          //


          builder.addCase(AddStationKitchen.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(AddStationKitchen.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //state.data.push(payload);
            state.errors = null;
          })
          builder.addCase(AddStationKitchen.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //


          builder.addCase(getKitchenFlowStations.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getKitchenFlowStations.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.stations = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.stations)
            state.errors = null;
          })
          builder.addCase(getKitchenFlowStations.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //

          builder.addCase(getBrancheKitchenFlow.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getBrancheKitchenFlow.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.branches = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.branches)
            state.errors = null;
          })
          builder.addCase(getBrancheKitchenFlow.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //

          
          builder.addCase(AddKitchenFlowCertinBranche.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(AddKitchenFlowCertinBranche.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //state.data.push(payload);
            state.errors = null;
          })
          builder.addCase(AddKitchenFlowCertinBranche.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //

          builder.addCase(getKitchenFlowBranches.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getKitchenFlowBranches.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.kitchenFlowBranches = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.kitchenFlowBranches)
            state.errors = null;
          })
          builder.addCase(getKitchenFlowBranches.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //

          builder.addCase(getAllProductKitchenFlow.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getAllProductKitchenFlow.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.products = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.products)
            state.errors = null;
          })
          builder.addCase(getAllProductKitchenFlow.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //

          
        builder.addCase(AddProductKitchenFlow.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(AddProductKitchenFlow.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          //state.data.push(payload);
          state.errors = null;
        })
        builder.addCase(AddProductKitchenFlow.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })


        //


        builder.addCase(getKitchenFlowProduct.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(getKitchenFlowProduct.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.kitchenFlowProducts = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          //console.log(state.kitchenFlowProducts)
          state.errors = null;
        })
        builder.addCase(getKitchenFlowProduct.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

        //

        builder.addCase(deleteKitchenFlow.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(deleteKitchenFlow.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          //state.data.push(payload);
          state.errors = null;
        })
        builder.addCase(deleteKitchenFlow.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

        //


        builder.addCase(activeKitchenFlow.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(activeKitchenFlow.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          //state.data.push(payload);
          state.errors = null;
        })
        builder.addCase(activeKitchenFlow.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

               /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
   builder.addCase(getkitchenFlowFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getkitchenFlowFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getkitchenFlowFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetkitchenFlowFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetkitchenFlowFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetkitchenFlowFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });



    }

})

export default kitchenFlowSlice.reducer;