import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IngredientsTable from "./IngredientsTable";
import AddIngrediantsModal from "./modals/AddIngrediantsModal";
import { getIngerdiants } from "../services/ingrediantsService";
import EditQtyModal from "./modals/EditQtyModal";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import { checkIdExists } from "utils/cheackAuth";

const Ingredients = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageItems = checkIdExists(retrievedArray, 10);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useParams();
  const InventoryItemId = id.itemId;

  const [AddIngrediantToItems, setShowAddIngrediantToItems] = useState(false);
  const [ShowEditQtyModal, setShowEditQtyModal] = useState(false);
  
  const IngrediantsItems = useSelector((state) => state.ingrediants);

  useEffect(() => {
    dispatch(getIngerdiants(InventoryItemId));
  }, [dispatch, InventoryItemId]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(AddIngrediantToItems===true && ManageItems===false){
    toast2({ description:  t("general.authorization")})
    setShowAddIngrediantToItems(false)
  }

  if(ShowEditQtyModal===true && ManageItems===false){
    toast2({ description:  t("general.authorization")})
    setShowEditQtyModal(false)
  }

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.ingrediants.ingrediants")}
        </Text>
        <Flex gap={2}>
          <GButton
            title={t("pages.transfers_orders.edit_quantities")}
            bg="light"
            color="text"
            fontSize="14px"
            border="1px solid"
            borderColor="border"
            hover={{ bg: "light" }}
            onClick={() => setShowEditQtyModal(true)}
          />

          <GButton
            title={t("pages.ingrediants.add_ingrediants")}
            bg="light"
            color="text"
            fontSize="14px"
            border="1px solid"
            borderColor="border"
            hover={{ bg: "light" }}
            onClick={() => setShowAddIngrediantToItems(true)}
          />
        </Flex>
      </Flex>

      {IngrediantsItems.isLoading ? (
        <Spinner />
      ) : IngrediantsItems.data?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : IngrediantsItems.data?.length > 0 ? (
        <IngredientsTable ItemTagsData={IngrediantsItems.data} />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {AddIngrediantToItems && (
        <AddIngrediantsModal
          onClose={() => setShowAddIngrediantToItems(false)}
        />
      )}

      {ShowEditQtyModal && (
        <EditQtyModal
          onClose={() => setShowEditQtyModal(false)}
          id={InventoryItemId}
        />
      )}
    </Box>
  );
};

export default Ingredients;