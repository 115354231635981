import { Button, Flex } from "@chakra-ui/react";

const GButton = ({
  type = "button",
  title,
  onClick,
  borderRadius = 6,
  paddingInline = 4,
  paddingBlock = 5,
  textTransform = "capitalize",
  bg = "none",
  color = "dark",
  border = "none",
  borderColor = null,
  hover = { bg: "inherit" },
  isDisabled,
  isLoading = false,
  prefixElement = null,
  postfixElement = null,
  // disabled,
  ...res
}) => {
  return (
    <Button
      // disabled={disabled}
      type={type}
      onClick={onClick}
      borderRadius={borderRadius}
      paddingInline={paddingInline}
      paddingBlock={paddingBlock}
      textTransform={textTransform}
      isLoading={isLoading}
      isDisabled={isDisabled}
      bg={bg}
      color={color}
      border={border}
      borderColor={borderColor}
      _hover={hover}
      {...res}
    >
      <Flex alignItems="center" gap={2}>
        {prefixElement}
        {title}
        {postfixElement}
      </Flex>
    </Button>
  );
};

export default GButton;
