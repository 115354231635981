import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text, Spinner } from "@chakra-ui/react"
import Pagination from "components/ui/pagination/Pagination";
import List from "../components/List";
import GButton from "../../../../components/ui/button/Button";
import NoData from "components/ui/noData/NoData";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { getRoles } from "../services/rolesService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Roles = () => {


  const retrievedArray =useDecryptedPrivilege()
  console.log(retrievedArray);
  const ManageBranches = checkIdExists(retrievedArray, 48);
  console.log(ManageBranches);

  const dispatch = useDispatch();
  const roles = useSelector(state => state.roles);
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [showCreateRoleModal, setShowCreateRoleModal] = useState(null);
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(null);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(null);

  //console.log(roles.data.userCount)

  useEffect(() => {
    dispatch(getRoles({ size }));
  }, [dispatch, size]);

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.roles.roles")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <GButton
              title={t("pages.roles.create_role")}
              onClick={() => setShowCreateRoleModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      {roles.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : roles.data.length === 0 ? (
        <NoData
          btnTitle={t("pages.roles.create_role")}
          onClickCreateBtn={() => setShowCreateRoleModal()}
          title={t("pages.roles.no_roles_hint")}
        />
      ) : (
        <List
          data={roles.data}
          handleUpdate={(role) => setShowUpdateRoleModal(role)}
          handleDelete={(role) => setShowDeleteRoleModal(role)}
        />
      )}

      {/* <Flex
        alignItems="center" flexWrap="wrap" justifyContent="space-between"
        mt={4} gap={4}
      >
        <Pagination
          page={page}
          size={size}
          itemsCouns={100}
          totalItemsCount={100}
          onChange={(page) => setPage(page)}
        />
        <Select
          w="auto" bg="light" border="none"
          onChange={(e) => setSize(e.target.value)}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </Select>
      </Flex> */}



      {showCreateRoleModal && (
        <CreateModal
          onClose={() => setShowCreateRoleModal(null)}
        />
      )}

      {showUpdateRoleModal && (
        <UpdateModal
          data={showUpdateRoleModal}
          onClose={() => setShowUpdateRoleModal(null)}
        />
      )}

      {showDeleteRoleModal && (
        <DeleteModal
          data={showDeleteRoleModal}
          onClose={() => setShowDeleteRoleModal(null)}
        />
      )}
    </>
  )
}

export default Roles