import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getProductions = createAsyncThunk(
  "productions/getProductions",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/Production/ProductionList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------- Get Production Details ---------------------------------------------------*/
export const getProductionDetails = createAsyncThunk("productions/getProductionDetails",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/Production/ProductionById?productionid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ---------------------------------------------------------- Produce Items ---------------------------------------------------------- */
export const produceItems = createAsyncThunk("productions/produceItems", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/Production/CloseProductionById?productionid=${JSON.stringify(args)}` );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const addProduction = createAsyncThunk(
  "productions/addProduction",
  async (args, thunkApi) => {
    // console.log("toto",args)
    try {
      const { data } = await axios.get(
        `/Inventory/Production/AddProduction?production=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


// export const getItemDetails = createAsyncThunk(
//   "items/getItemDetails",
//   async (args, thunkApi) => {
//     try {
//      const { data } = await axios.get(`/Menu/Items/ItemsDataById?InventoryItemId={"InventoryItemId":${args}}`);
//      //console.log(data)
//      return thunkApi.fulfillWithValue(data);
//     } catch (e) {
//       return thunkApi.rejectWithValue(e.response.data);
//     }
//   }
// );

// //

// export const editItem = createAsyncThunk(
//   "items/editItem",
//   async (args, thunkApi) => {
//     try {
//       //console.log(args)
//       const { data } = await axios.get(`/Menu/InventoryItem/EditInventoryItem?inventoryitem=${JSON.stringify(args)}`);
//       console.log(data)
//       return thunkApi.fulfillWithValue(data);

//     } catch (e) {
//       return thunkApi.rejectWithValue(e.response.data);
//     }
//   }
// );

export const deleteProduction = createAsyncThunk(
  "productions/deleteProduction",
  async (args, thunkApi) => {
  
    try {
      const { data } = await axios.get(`/Inventory/Production/DeleteProductionById?productionid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

// export const getDeletedItem = createAsyncThunk(
//   "items/getDeletedItem",
//   async (args, thunkApi) => {
//     try {
//       const { data } = await axios.get(`/Inventory/Item/DeletedItemList`);
//       return thunkApi.fulfillWithValue(data);
//     } catch (e) {
//       return thunkApi.rejectWithValue(e.response.data);
//     }
//   }
// );

export const getProdBranchesDropList = createAsyncThunk(
  "productions/getProdBranchesDropList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage/Setting/CallCenter/LookUpBranches`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const editProduction = createAsyncThunk(
  "productions/editProduction",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Inventory/Production/EditProduction?productionid=${JSON.stringify(args)}`
      );

      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getAddItemDropList = createAsyncThunk(
  "productions/getAddItemDropList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Inventory/Production/LookupItemsProduction`
      );

      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

  export const addProductionItem = createAsyncThunk(
    "productions/addProductionItem",
    async (args, thunkApi) => {
      // console.log("toto",args)
      try {
        const { data } = await axios.get(
          `/Inventory/Production/AddProductionItem?productionitem=${JSON.stringify(args)}`
        );
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getProductionItems = createAsyncThunk(
    "productions/getProductionItems",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(
          `/Inventory/ListItemsByProductionId?productionid=${JSON.stringify(args)}`
        );
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getEditItemsQuan = createAsyncThunk( "productions/getEditItemsQuan", async (args, thunkApi) => {
      try {
        const { data } = await axios.get( `/Inventory/GetEditProductionItemsByProductionId?productionid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const editProductionItems = createAsyncThunk(
    "productions/editProductionItems",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(
          `/Inventory/EditProductionItems?productionitemid=${JSON.stringify(args)}`
        );
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  

    /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
    export const getProductionFilteredData = createAsyncThunk("productions/getProductionFilteredData ", async (args, thunkApi) => {
      try {
        return (args);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  export const resetProductionFilteredData = createAsyncThunk( "productions/resetProductionFilteredData ", async (args, thunkApi) => { 
      try {
        return args;
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );