import { Flex, Text } from '@chakra-ui/react'
import GButton from 'components/ui/button/Button'
import React, { useState } from 'react'
import DeleteModalDevice from './modals/DeleteModal';
import { useTranslation } from 'react-i18next';

const DeleteDeviceCopmo = () => {
    const [showDeleteBranchModal, setShowDeleteBtanchModal] = useState(null);
    const { t } = useTranslation();


  return (
    <Flex
      bg="light" borderRadius={6} alignItems="center" justifyContent="space-between" gap={4} p={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      marginTop={10}
    >
      <Text fontSize=".9rem" color="text">
        if you delete this branch, all of its data will be permanently deleted.
      </Text>
      <GButton
        bg="red"
        color="light"
        title={t("general.delete")}
        hover={{ bg: "red" }}
        onClick={() => setShowDeleteBtanchModal(true)}
      />

      {showDeleteBranchModal && (
        <DeleteModalDevice
        
          onClose={() => setShowDeleteBtanchModal(null)}
        />
      )}
    </Flex>
  )
}

export default DeleteDeviceCopmo
