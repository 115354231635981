import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getModifiersForProduct } from "../services/LinkingModifiersService";
import LinkingModifiersTable from "./LinkingModifiersTable";
import LinkingModifiersModal from "./modals/LinkingModifiersModal";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const LinkingModifiers = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [linkingModifiersToProduct, setLinkingModifiersToProduct] = useState(false);

  const ModifiersToSpecificProduct = useSelector((state) => state.addModifier);

  useEffect(() => {
    dispatch(getModifiersForProduct(id.productId));
  }, [dispatch, id]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(linkingModifiersToProduct && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setLinkingModifiersToProduct(false)
  }

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.modifiers.modifiers")}
        </Text>

        <GButton
          title={t("pages.modifiers_options.add_modifier")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setLinkingModifiersToProduct(true)}
        />
      </Flex>

      {ModifiersToSpecificProduct.isLoading ? (
        <Spinner />
      ) : ModifiersToSpecificProduct.data?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : ModifiersToSpecificProduct.data?.length > 0 ? (
        <LinkingModifiersTable
          data={ModifiersToSpecificProduct.data}
        />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {linkingModifiersToProduct && (
        <LinkingModifiersModal
          onClose={() => setLinkingModifiersToProduct(false)}
        />
      )}
    </Box>
  );
};

export default LinkingModifiers;
