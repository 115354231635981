import { Box, Flex, Stack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import GModal from "components/ui/modal/Modal";
import { useTranslation } from 'react-i18next';
import MultiSelect from 'components/ui/multiSelect/MultiSelect';
import GButton from 'components/ui/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getorderTypesforOrders } from 'features/orders/services/ordersService';

const OrderTypeModal = ({onClose,SetOrderType}) => {
    const { t } = useTranslation();


    const dispatch=useDispatch()

    useEffect(()=>{
        dispatch(getorderTypesforOrders())
    },[dispatch])

    const dataOrder = useSelector((state) => state.ordersSlice);

    //console.log(dataOrder.ordersType)

  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.orders.orderType")}
    modalContent={

        <Box p={4}>
        

        <Stack>
        

        <MultiSelect
        title={t("pages.orders.orderType")}
        data={
            dataOrder?.ordersType &&
            dataOrder?.ordersType.map((el) => ({
            ...el,
            label: el.OrderTypeName,
            value: el.OrderTypeId
            ,
          }))
        }
        isMulti={false}
        isRequired={true}
        onChange={(e) => SetOrderType(e)}
      />
        
      <Flex gap={4}>
      
      <GButton
        title={t("general.close")}
        onClick={onClose}
        bg="red.600"
        border="1px solid transparent"
        color="light"
        hover={{
          color: "red.600",
          borderColor: "red.600",
          bg: "none",
        }}
      />
      <GButton
      title={t("general.save")}
      onClick={onClose}
      bg="green"
      border="1px solid transparent"
      color="light"
      hover={{
        color: "green",
        borderColor: "green",
        bg: "none",
      }}
    />
    </Flex>
        </Stack>

        </Box>
    }
    />
  )
}

export default OrderTypeModal
