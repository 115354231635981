import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

  /* -------------------------------------------- Get CostAdjustment List -------------------------------------------- */
  export const getCostAdjustmentList = createAsyncThunk("costAdjustment/getCostAdjustmentList",  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/CostAdjustmentList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ---------------------------Get Cost Adjustment Branch ----------------------------------------- */
export const getCostAdjustmentBranch = createAsyncThunk("costAdjustment/getCostAdjustmentBranch",  async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* --------------------------- Create New Cost Adjustment Using Branch  ----------------------------------------- */
export const createNewCostAdjustment = createAsyncThunk("costAdjustment/createNewCostAdjustment", async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`/Inventory/AddCostAdjustment?costadjustment=${JSON.stringify(args)}`);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
 /* -------------------- Cost Adjustment ( submit - Get data after Submit )------------------------------------ */
 /* -------------------------------------- Edit  Cost Adjustment  ------------------------------------------ */
  export const editCostAdjustment = createAsyncThunk("costAdjustment/editCostAdjustment", async (args, thunkApi) => {
        try {
          const { data } = await axios.get(`/Inventory/EditCostAdjustmen?costadjustmentid=${JSON.stringify(args)}`);
          return thunkApi.fulfillWithValue(data);
        } catch (e) {
          return thunkApi.rejectWithValue(e.response.data);
        }
      }
    );
 /* --------------------------------------  Delete  Cost Adjustment  ------------------------------------------ */
 export const deleteCostAdjustments = createAsyncThunk("costAdjustment/deleteCostAdjustments", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/DeleteCostAdjustmentById?costadjustmentid={"CostAdjustmentId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
 /* --------------------------------------  Submit CostAdjustments  ------------------------------------------ */
export const submitCostAdjustments = createAsyncThunk("costAdjustment/submitCostAdjustments", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/SubmittingCostAdjustment?costadjustmentid={"CostAdjustmentId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  
  // Get Cost Adjustments After Submit
export const getListItemAfterSubmitByCostAdjustmentId = createAsyncThunk("costAdjustment/getListItemAfterSubmitByCostAdjustmentId", 
      async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/CostAdjustmentItemListAfterSubmit?costadjustmentid={"CostAdjustmentId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getCostFilteredData = createAsyncThunk("costAdjustment/getCostFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetCostFilteredData = createAsyncThunk( "costAdjustment/resetCostFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);