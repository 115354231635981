import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import {
  Button,
  Flex,
  Text,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import ImportModal from "../components/modals/ImportModal";
// import ExportModal from "../components/modals/ExportModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import {
  getGiftsCards,
  resetFilteredData,
} from "../services/giftsCardsService";
import NoData from "components/ui/noData/NoData";
import { IoCloseOutline } from "react-icons/io5";
import ExportModal from "components/ui/modal/ExportModal";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


const GiftsCards = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedTable, setSelectedTable] = useState("all");

  const giftsCards = useSelector((state) => state.giftsCards);
  const filteredGifts = useSelector((state) => state.giftsCards);

  const [showCreateGiftsCardModal, setShowCreateGiftsCardModal] =
    useState(null);
  const [showUpdateGiftsCardModal, setShowUpdateGiftsCardModal] =
    useState(null);
  const [showDeleteGiftsCardModal, setShowDeleteGiftsCardModal] =
    useState(null);
  const [showImportGiftsCardsModal, setShowImportGiftsCardsModal] =
    useState(null);
  const [showExportGiftsCardsModal, setShowExportGiftsCardsModal] =
    useState(null);
  const [showFilterModal, setShowFilterModal] = useState(null);
  const [exportModal, SetExportModal] = useState(false);

  useEffect(() => {
    dispatch(getGiftsCards());
  }, [dispatch]);

  const resetFunction = () => {
    dispatch(resetFilteredData());
  };

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  if(showCreateGiftsCardModal===true && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowCreateGiftsCardModal(false)
  }


  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.gifts_cards.gifts_cards")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            {/* <Menu>
              <MenuButton
                as={Button}
                bg="none"
                border="1px solid"
                borderColor="border"
                color="text"
                _hover={{
                  bg: "primary",
                  borderColor: "primary",
                  color: "light",
                }}
              >
                <Flex alignItems="center" gap={2}>
                  <FiIcons.FiCloud size={16} />
                  <Text>{t("general.import_export")}</Text>
                  <FiIcons.FiChevronDown size={16} />
                </Flex>
              </MenuButton>
              <MenuList p={0} minW="auto">
                <MenuItem onClick={() => setShowImportGiftsCardsModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiUpload />
                    <Text textTransform="capitalize">
                      {t("general.import")}
                    </Text>
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => setShowExportGiftsCardsModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiDownload />
                    <Text textTransform="capitalize">
                      {t("general.export")}
                    </Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu> */}

            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
            >
              <div onClick={() => SetExportModal(true)}>
                {t("general.export")}
              </div>
            </Button>

            <GButton
              title={t("pages.gifts_cards.create_gifts_card")}
              onClick={() => setShowCreateGiftsCardModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color={selectedTable === "all" ? "primary" : "text"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={selectedTable === "all" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setSelectedTable("all")}
          />
          <GButton
            title={t("pages.gifts_cards.active")}
            bg="none"
            color={selectedTable === "active" ? "primary" : "text"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={selectedTable === "active" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setSelectedTable("active")}
          />
          <GButton
            title={t("pages.gifts_cards.in_active")}
            bg="none"
            color={selectedTable === "inActive" ? "primary" : "text"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={selectedTable === "inActive" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setSelectedTable("inActive")}
          />
          {/*
          <GButton
            title={t("general.deleted")}
            bg="none"
            color={selectedTable === "deleted" ? "primary" : "text"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={selectedTable === "deleted" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setSelectedTable("deleted")}
          />
        */}
        </Flex>

        {/* {filteredGifts.filteredData.length === 0 ? null : (
        {/* {filteredGifts.filteredData.length === 0 ? null : (
          <div onClick={resetFunction}>x</div>
        )} */}

        <GButton
          // isDisabled={filteredGifts.filteredData.length === 0 ? false : true}
          // isDisabled={filteredGifts.filteredData.length === 0 ? false : true}
          title={
            filteredGifts.filteredData.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
            filteredGifts.filteredData.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          // onClick={() => setShowFilterModal(true)}
          onClick={
            filteredGifts.filteredData.length === 0
              ? () => setShowFilterModal(true)
              : resetFunction
          }
        />
      </Flex>

      {giftsCards.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : giftsCards.data.length === 0 ? (
        <NoData
          btnTitle={t("pages.gifts_cards.create_gifts_card")}
          title={t("pages.gifts_cards.no_data_hint")}
          onClickCreateBtn={() => setShowCreateGiftsCardModal(true)}
          onClickCreateGiftsCardBtn={() => setShowCreateGiftsCardModal(true)}
        />
      ) : (
        <Table selectedTable={selectedTable} giftsCards={giftsCards.data} />
      )}

      {showCreateGiftsCardModal && (
        <CreateModal onClose={() => setShowCreateGiftsCardModal(null)} />
      )}

      {showUpdateGiftsCardModal && (
        <UpdateModal
          data={showUpdateGiftsCardModal}
          onClose={() => setShowUpdateGiftsCardModal(null)}
        />
      )}

      {showDeleteGiftsCardModal && (
        <DeleteModal
          data={showDeleteGiftsCardModal}
          onClose={() => setShowDeleteGiftsCardModal(null)}
        />
      )}

      {/* {showImportGiftsCardsModal && (
        <ImportModal onClose={() => setShowImportGiftsCardsModal(null)} />
      )}

      {showExportGiftsCardsModal && (
        <ExportModal onClose={() => setShowExportGiftsCardsModal(null)} />
      )} */}

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={
            selectedTable === "active"
              ? giftsCards.data.filter((el) => el.IsActive === true)
              : selectedTable === "inActive"
              ? giftsCards.data.filter((el) => el.IsActive === false)
              : giftsCards.data
          }
          fileName={
            selectedTable === "active"
              ? "Active Gifts Cards"
              : selectedTable === "inActive"
              ? "InActive Gifts Cards"
              : "Gifts Cards"
          }
        ></ExportModal>
      )}

      {showFilterModal && (
        <FilterModal
          onClose={() => setShowFilterModal(null)}
          data={giftsCards.data}
        />
      )}
    </>
  );
};

export default GiftsCards;
