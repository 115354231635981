import { createSlice } from "@reduxjs/toolkit";

import {getItemsToLink,addLinkItemToSupplier,getLinkedSupplier,getAllTagsToSupplier,addTagToSupplier,getlinkedTagswithSupplier} from "../services/linkItemToSupplierService"


let initialState = {
    isLoading: false,
    data: [],
    pages: 0,
    errors: null,
    linkedSuppliers:[],
    Tags:[],
    linkedTags:[],
  };


  const linkItemToSupplierSlice = createSlice({

    name: "linkItemToSupplier",
    initialState,


    extraReducers:(builder)=>{
    
    
/* ----------------------------------------------------- Get Items To Link --------------------------------------------- */
          builder.addCase(getItemsToLink.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getItemsToLink.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
    
          builder.addCase(getItemsToLink.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
/* -------------------------------------------------------------------------------------------------- */

          builder.addCase(addLinkItemToSupplier.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(addLinkItemToSupplier.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //console.log(payload)
            //state.data.push(payload);
            state.errors = null;
          })
          builder.addCase(addLinkItemToSupplier.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          
          builder.addCase(getLinkedSupplier.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getLinkedSupplier.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.linkedSuppliers = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.linkedSuppliers)
            state.errors = null;
          })
      
          builder.addCase(getLinkedSupplier.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          //


          builder.addCase(getAllTagsToSupplier.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getAllTagsToSupplier.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.Tags = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.Tags)
            state.errors = null;
          })
    
          builder.addCase(getAllTagsToSupplier.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          //


          builder.addCase(addTagToSupplier.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(addTagToSupplier.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
          builder.addCase(addTagToSupplier.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          //

          builder.addCase(getlinkedTagswithSupplier.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getlinkedTagswithSupplier.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.linkedTags = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
    
          builder.addCase(getlinkedTagswithSupplier.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
    
    }

  })


  export default linkItemToSupplierSlice.reducer