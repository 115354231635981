import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import Input from "components/form/Input";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getbranches,
  assigenItemToBranch,
  getBranchesOfItem,
} from "../../services/customLevelItemService";
import { useParams } from "react-router-dom";

const CustomLevelModal = ({ onClose }) => {
  const { t } = useTranslation();
  const id = useParams();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const branches = useSelector((state) => state.customLevel);

  useEffect(() => {
    dispatch(getbranches());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
      setError("ParLevel", {
        type: "manual",
        message: t("validation.must_be_within_min_max_range"),
      });
    } else {
      clearErrors("ParLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.inventory.select_branche")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              if (values.BranchId === undefined) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                values.BranchId = values.BranchId.BranchId;
                values.InventoryItemId = id.itemId;
                dispatch(assigenItemToBranch(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.items.branch_created") });
                    dispatch(getBranchesOfItem(id.itemId));
                    onClose();
                  });
              }
            })}
          >
            <Stack>
              <MultiSelect
                title={t("pages.inventory.branches")}
                data={
                  branches.data &&
                  branches.data.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("BranchId", e)}
              />

              <FormControl isInvalid={errors.MinLevel}>
                <FormLabel
                  htmlFor="MinLevel"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.inventory.minimum_level")}
                </FormLabel>
                <Controller
                  name="MinLevel"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.inventory.minimum_level")}
                      onChange={(e) => {
                        handleInputChange(e, "MinLevel");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.MinLevel && errors.MinLevel.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.MaxLevel}>
                <FormLabel
                  htmlFor="MaxLevel"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.inventory.maximum_level")}
                </FormLabel>
                <Controller
                  name="MaxLevel"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.inventory.maximum_level")}
                      onChange={(e) => {
                        handleInputChange(e, "MaxLevel");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.MaxLevel && errors.MaxLevel.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.ParLevel}>
                <FormLabel
                  htmlFor="ParLevel"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.inventory.par_level")}
                </FormLabel>
                <Controller
                  name="ParLevel"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.inventory.par_level")}
                      onChange={(e) => {
                        handleInputChange(e, "ParLevel");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.ParLevel && errors.ParLevel.message}
                </FormErrorMessage>
              </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.inventory_item_details.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default CustomLevelModal;
