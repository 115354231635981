import React, { useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr,} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import Pagination from "components/ui/pagination/Pagination";


export default function ActiveDataTable({data ,setDeletedModal , setEditModal }) {
    const { t } = useTranslation();

      /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
        return data.slice(firstIndex, lastIndex);
      };
    
        // Calculate records for activeDiscounts
        const inventoryCategoriesData = calculateRecords(
          data,
          currentPageActive,
          recordsPerPage
        );
  return <>
    <>
    <Table>
        <Thead>
            <Tr>
            <Th>{t("pages.inventoryCategories.name")}</Th>
            <Th>{t("pages.inventoryCategories.name_Localized")}</Th>
            <Th>{t("pages.inventoryCategories.reference")}</Th>
            <Th>{t("pages.inventoryCategories.created")}</Th>
            <Th>{t("pages.inventoryCategories.actions")}</Th>
            </Tr>
        </Thead>
        <Tbody>
            {inventoryCategoriesData?.map((item, index) => (
                <Tr key={index}>
                <Td>{item.InventoryCategoryName}</Td>
                <Td>{item.InventoryCategoryNameLoc}</Td>
                <Td>{item.InventoryCategoryRef}</Td>
                <Td> {item?.CreateDate} </Td>
                <Td className="activeIcon">
                    <Flex gap={2}>
                    <GButton
                        postfixElement={<FiIcons.FiEdit />}
                        bg="primary"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                        bg: "none",
                        borderColor: "primary",
                        color: "primary",
                        }}
                        onClick={() => setEditModal(item)}
                    />
                    <GButton
                        postfixElement={<FiIcons.FiTrash2 />}
                        bg="red.600"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                        bg: "none",
                        borderColor: "red.600",
                        color: "red.600",
                        }}
                        onClick={() =>
                        setDeletedModal(item.InventoryCategoryId)
                        }
                    />
                    </Flex>
                </Td>
                </Tr>
            ))}
        </Tbody>
    </Table>
    <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </>
  </>
}
