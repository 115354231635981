import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Get Gift Cards  Report List ------------------------------- */
  export const getGiftCardsReportList = createAsyncThunk("giftCardsReport/getGiftCardsReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetGiftCardsReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

 /* --------------------------------------- Gift Cards Filter Data ------------------------------*/
 export const getGiftCardsReportFilteredData = createAsyncThunk("giftCardsReport/getGiftCardsReportFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetGiftCardsReportFilteredData = createAsyncThunk( "giftCardsReport/resetGiftCardsReportFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);