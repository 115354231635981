import React, { useEffect } from 'react'
import GModal from "components/ui/modal/Modal";
import { useTranslation } from 'react-i18next';
import { Box, Flex, Stack } from '@chakra-ui/react';
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from 'components/ui/button/Button';
import { getBranchesLookUpOrder } from 'features/orders/services/ordersService';
import { useDispatch, useSelector } from 'react-redux';

const BrancheModal = ({ onClose,setBranchData }) => {
    const { t } = useTranslation();

    

    const dispatch=useDispatch()
    
    useEffect(() => {
        dispatch(getBranchesLookUpOrder());
      }, [dispatch]);


      const data = useSelector((state) => state.ordersSlice);

  return (
    <GModal
    
    onClose={onClose}
    modalHeader={t("pages.inventory.select_branche")}
    modalContent={

        <Box p={4}>
        
        <Stack>
        
        <MultiSelect
        title={t("pages.inventory.branches")}
        data={
            data?.branches &&
            data?.branches.map((el) => ({
            ...el,
            label: el.BranchName,
            value: el.BranchId,
          }))
        }
        isMulti={false}
        isRequired={true}
        onChange={(e) => setBranchData(e)}
      />
        

      <Flex gap={4}>
      
      <GButton
        title={t("general.close")}
        onClick={onClose}
        bg="red.600"
        border="1px solid transparent"
        color="light"
        hover={{
          color: "red.600",
          borderColor: "red.600",
          bg: "none",
        }}
      />

      <GButton
        title={t("general.save")}
        onClick={onClose}
        bg="green"
        border="1px solid transparent"
        color="light"
        hover={{
          color: "green",
          borderColor: "green",
          bg: "none",
        }}
      />
    </Flex>
        </Stack>

        </Box>

    }
    
    />
  )
}

export default BrancheModal
