import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import GModal from "components/ui/modal/Modal";
import { Box, Flex, Stack } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { getBranches } from "features/management/branches/services/branchesService";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  addDeactiveBranchModifier,
  getDeactiveBranchesModififers,
} from "../../services/modifiersOptionsCustomService";
import { useToast } from "@chakra-ui/react";

const DeactiveInSomeBranchesModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { handleSubmit, control, getValues, watch, setValue, register } =
    useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  const branches = useSelector((state) => state.branches);

  const id = useParams();
  //console.log(id)

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.combos.select_branches")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              values.BranchId = values.BranchId?.BranchId;
              values.ModiferOptionId = id.modifiersOptionId;
              
              if (values.BranchId === undefined) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                dispatch(addDeactiveBranchModifier(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.combos.combo_created") });
                    dispatch(
                      getDeactiveBranchesModififers(id.modifiersOptionId)
                    );
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.combos.branches")}
                data={
                  branches.data &&
                  branches.data.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))
                }
                isMulti={false}
                isRequired
                onChange={(e) => setValue("BranchId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default DeactiveInSomeBranchesModal;
