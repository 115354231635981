import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import * as FiIcons from "react-icons/fi";
import { t } from "i18next";


const CTable = ({tags, handleCreate, handleUpdate, handleDelete}) => {

  return (
    <Stack spacing={6} mt={6}>
      {tags.map((tag , index) => (
        <>
          <Flex
            key={index}
            alignItems="center" justifyContent="space-between" gap={2} flexWrap="wrap"
          >
            <Text>{tag?.TagGroupName} tag</Text>
            <GButton
              title={t("pages.tags.create_tag")}
              bg="light"
              color="text"
              fontSize="12px"
              border="1px solid"
              borderColor="border"
              hover={{ bg: "light" }}
              onClick={() => handleCreate(tag)}
            />
          </Flex>
          <Box
            bg="light" borderRadius={4}
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
          >
            {tag.Tag?.map((ta , index) => (
              <Box key={index} borderBottom="1px solid" borderColor="border" paddingInline={6} paddingBlock={3}>
                <Flex justifyContent={"space-between"} > 
                    <Text textTransform="capitalize"> {ta?.TagName} </Text>
                    <Flex gap={2}>
                      <GButton
                        postfixElement={<FiIcons.FiEdit />}
                        bg="primary"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "primary",
                          color: "primary",
                        }}
                        onClick={() => handleUpdate( { ...ta , TagGroupId : tag.TagGroupId} )}
                      />
                      <GButton
                        postfixElement={<FiIcons.FiTrash2 />}
                        bg="red.600"
                        color="light"
                        border="1px solid transparent"
                        hover={{
                          bg: "none",
                          borderColor: "red.600",
                          color: "red.600",
                        }}
                        onClick={() => handleDelete(ta.TagId)}
                      />
                    </Flex>
                </Flex>
              </Box>
            ))}
          </Box>
        </>
      ))}
    </Stack>
  )
}

export default CTable;