import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getBranchesInStock,
  getOutOfStockForProduct,
  setOutStockForProduct,
} from "../../services/outOfStackService";

const OutOfStockModal = ({ onClose }) => {
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit, setValue } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const branches = useSelector((state) => state.addStock);

  useEffect(() => {
    dispatch(getBranchesInStock());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.inventory.select_branche")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.BranchId = values.BranchId?.BranchId;
              values.ProductId = id.productId;
              values.UserId = localStorage.getItem("userId");

              if (values.BranchId === undefined) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
              dispatch(setOutStockForProduct(values))
                .unwrap()
                .then((_) => {
                  toast({ description: "Branch created" });
                  dispatch(getOutOfStockForProduct(id.productId));
                  onClose();
                });}
            })}
          >
            <Stack>
              <MultiSelect
                title={t("pages.inventory.branches")}
                data={
                  branches.branchesInStocks &&
                  branches.branchesInStocks.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("BranchId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.inventory_item_details.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={branches.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default OutOfStockModal;
