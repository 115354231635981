import { createSlice } from "@reduxjs/toolkit";
import {
  getGeneralSettings,
  updateGeneralSettings
} from "../services/generalSettingsService";

let initialState = {
  isLoading: false,
  data: {},
  errors: null
};

const generalSettingsSlice = createSlice({
  name: "generalSettings",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getGeneralSettings.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getGeneralSettings.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload.data;
      state.errors = null;
    })
    builder.addCase(getGeneralSettings.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateGeneralSettings.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateGeneralSettings.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(updateGeneralSettings.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default generalSettingsSlice.reducer;