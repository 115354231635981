import { Box, Table as ChTable, Checkbox, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Table = ({orderData}) => {
  const { t } = useTranslation();

  //console.log(orderData)

  const navigate=useNavigate()

  const handelNavigate=(id)=>{
    navigate(`${id}`)
  }

  return (
    <Box bg="light" overflow="auto">
      <ChTable>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.orders.customerName")}</Th>
            <Th>{t("pages.orders.phone_num")}</Th>
            <Th>{t("pages.orders.branch")}</Th>
            <Th>{t("pages.orders.orderType")}</Th>
            <Th>{t("pages.orders.totalPrice")}</Th>
            <Th>{t("pages.Expenses.data")}</Th>
          </Tr>
        </Thead>
        <Tbody>
        {
          orderData?.map((el)=>{
            return(

              <Tr onClick={()=>handelNavigate(el.OrderId)} style={{cursor:"pointer"}}>
              <Td>
                <Checkbox size="md" />
              </Td>
              <Td>{el.CustomerName || "-"}</Td>
              <Td>{el.PhoneNo || "-"}</Td>
              <Td>{el.BranchName || "-"}</Td>
              <Td>{el.OrderTypeName || "-"}</Td>
              <Td>{el.TotalPrice || "-"}</Td>
              <Td>{el.UserDate || "-"}</Td>

            </Tr>

            )
          })
        }
       
        </Tbody>
      </ChTable>
    </Box>
  )
}

export default Table