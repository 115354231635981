import React, { useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr,Flex , Box} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Pagination from "components/ui/pagination/Pagination";
import { MdSettingsBackupRestore } from "react-icons/md";
import RestoreDeliveryZoneModal from './Modal/RestoreDeliveryZoneModal';

export default function DeliveryZoneDeletedTable({data}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [restoreDeliveryZone, setRestoreDeliveryZone] = useState(null)

    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
        return data?.slice(firstIndex, lastIndex);
      };
    
    // Calculate records for activeDiscounts
    const deliveryZoneData = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
 /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <>
    <Table>
        <Thead>
                <Tr>
                    <Th>{t("pages.categories.name")}</Th>
                    <Th>{t("pages.categories.name_localized")}</Th>
                    <Th>{t("pages.DeliverZone.refrance")}</Th>
                    <Th>{t("pages.DeliverZone.created")}</Th>
                    <Th>{t("general.actions")}</Th>
                </Tr>
        </Thead>
        <Tbody>
            {
            deliveryZoneData?.map((item, index) => (
                <Tr key={index}>  
                    <Td>{item?.DeliveryZoneName}</Td>
                    <Td>{item?.DeliveryZoneNameLoc}</Td>
                    <Td>{item?.RefCode}</Td>
                    <Td>{item?.Created}</Td>
                    <Td className="activeIcon">
                        <Flex>
                            <Box
                                color="#C53030"
                                _hover={{ color: "#38A169" }}
                                p={2}
                                width={"fit-content"}
                                cursor="pointer"
                                onClick={() => setRestoreDeliveryZone(item?.DeliveryZoneId)}
                            >
                                <MdSettingsBackupRestore size={36} />
                            </Box>
                        </Flex>
                    </Td>
                </Tr>
            ))
            }
        </Tbody>
    </Table>
    <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </>
    {
    restoreDeliveryZone  && (
      <RestoreDeliveryZoneModal id={restoreDeliveryZone} onClose={()=>setRestoreDeliveryZone(null)} />
    )
    }
  </>
};
