import { createSlice } from "@reduxjs/toolkit";
import {createQuantityAdjasment,getBranchesForQuantityAdjusment,getReasonForQuantityAdjusment,
        getAllQuantityAdjusment,getQuantityAdjustmentDetails,getItemsForQuantityAdjusment,
        AddItemForQuantityAdjusment,getQuantityAdjustmentItems,editQuantityAdjasment,
        deleteQuantityAdjustment,submitQuantityAdjustment, 
        resetQuantityAdjustmentFilteredData, getQuantityAdjustmentFilteredData, getQuantityAdjustmentItemsToEdit, EditQuantityAdjustmenItems} from "../services/quantityAdjustmentService"

let initialState = {
    isLoading: false,
    data: [],
    branches:[],
    Reasons:[],
    details:[],
    items:[],
    quantityAdjustmentItems:[],
    pages: 0,
    errors: null,

    quantityAdjustmentItemsToEdit:[],
    filteredData : []
  };


  const quantityAdjustmentSlice = createSlice({
    name: "QuantityAdjustment",
    initialState,

    extraReducers:(builder)=>{

            
      builder.addCase(createQuantityAdjasment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(createQuantityAdjasment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        //console.log(payload)
        //state.data.push(payload);
        state.errors = null;
      })
      builder.addCase(createQuantityAdjasment.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

  /* ---------------------------------------------------- Get Branches For Quantity Adjusment -------------------------------------*/
      builder.addCase(getBranchesForQuantityAdjusment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getBranchesForQuantityAdjusment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.branches = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getBranchesForQuantityAdjusment.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
  /* ---------------------------------------------------- Get Reason For Quantity Adjusment -------------------------------------*/
      builder.addCase(getReasonForQuantityAdjusment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getReasonForQuantityAdjusment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.Reasons = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getReasonForQuantityAdjusment.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //




      
      builder.addCase(getAllQuantityAdjusment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getAllQuantityAdjusment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.data)
        state.errors = null;
      })

      builder.addCase(getAllQuantityAdjusment.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //


      
      builder.addCase(getQuantityAdjustmentDetails.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getQuantityAdjustmentDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.details = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.details)
        state.errors = null;
      })

      builder.addCase(getQuantityAdjustmentDetails.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //

      builder.addCase(getItemsForQuantityAdjusment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getItemsForQuantityAdjusment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.items = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.items)
        state.errors = null;
      })

      builder.addCase(getItemsForQuantityAdjusment.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      //


      builder.addCase(AddItemForQuantityAdjusment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(AddItemForQuantityAdjusment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        //state.items = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.items)
        state.errors = null;
      })

      builder.addCase(AddItemForQuantityAdjusment.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //

      builder.addCase(getQuantityAdjustmentItems.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getQuantityAdjustmentItems.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.quantityAdjustmentItems = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getQuantityAdjustmentItems.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })



      builder.addCase(editQuantityAdjasment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(editQuantityAdjasment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(editQuantityAdjasment.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ----------------------------------------------------------- Edit  Quantity Adjustmen Items -------------------------------------------------------- */
      builder.addCase(EditQuantityAdjustmenItems.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(EditQuantityAdjustmenItems.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(EditQuantityAdjustmenItems.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


              
      builder.addCase(deleteQuantityAdjustment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(deleteQuantityAdjustment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(deleteQuantityAdjustment.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


      builder.addCase(submitQuantityAdjustment.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(submitQuantityAdjustment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(submitQuantityAdjustment.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });

      /* -------------------------------------------------- Quantity Adjustment Items To Edit -------------------------------------------*/ 
      builder.addCase(getQuantityAdjustmentItemsToEdit.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(getQuantityAdjustmentItemsToEdit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.quantityAdjustmentItemsToEdit = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })
      builder.addCase(getQuantityAdjustmentItemsToEdit.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });

   /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
   builder.addCase(getQuantityAdjustmentFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getQuantityAdjustmentFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getQuantityAdjustmentFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetQuantityAdjustmentFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetQuantityAdjustmentFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetQuantityAdjustmentFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* --------------------------------------------------------------------------------------------------- */



    }

})


export default quantityAdjustmentSlice.reducer