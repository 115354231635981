import {
  Badge,
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPhoneVolume } from "react-icons/fa6";
import * as FiIcons from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";
import BrancheModal from "../components/modals/BrancheModal";
import OrderTypeModal from "../components/modals/OrderTypeModal";
import CustomerModal from "../components/modals/CustomerModal";
import { IoFastFood } from "react-icons/io5";
import { MdPriceChange, MdProductionQuantityLimits } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCatigoresWithProducts } from "features/callCenter/service/callCenterService";
import { sendOrder } from "../services/ordersService";
import routes from "navigations/routes";
import { useNavigate } from "react-router-dom";
import ReceiptNotesModal from "../components/modals/ReceiptNotesModal";
import KitchenNoteModal from "../components/modals/KitchenNoteModal";
import PaymentModal from "../components/modals/PaymentModal";
import DiscountModal from "../components/modals/DiscountModal";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import DateModal from "../components/modals/DateModal";

const CreateOrderPage = () => {
  const retrievedArray =useDecryptedPrivilege()
  const ManageOrders = checkIdExists(retrievedArray, 2);
  //console.log(ManageOrders)


  const { t } = useTranslation();

  const [showBranchModal, SetBranchModal] = useState(false);
  const [branchData, setBranchData] = useState("");

  const [showOrderTypeModal, SetOrderTypeModal] = useState(false);
  const [OrderTypeData, SetOrderType] = useState("");

  const [showCustomerModal, setshowCustomerModal] = useState(false);
  const [cutomerData, SetCustomerData] = useState("");

  //console.log(cutomerData)

  const [showReceiptModal, setReceiptModal] = useState(false);
  const [showDateModal, setDateModal] = useState(false);
  const [Date, setDate] = useState(false);

  const [ReceiptNotes, setReceiptNotes] = useState();

  const [showKitchenModal, setKitchenModal] = useState(false);
  const [KitchenNotes, setKitchenNotes] = useState();

  const [paymentModal,setPaymentModal]=useState(false)
  const [dataForPaymentModal,SetDataForPaymentModal]=useState()

  //console.log(KitchenNotes)

  const productsContainerRef = useRef(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showProducts, setShowProducts] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const toast3 = useToast({ position: "top", duration: 3000, status: "error" });

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });




  const handleIncreaseQuantity = (productId) => {
    const updatedProducts = selectedProducts.map((product) => {
      if (product.ProductId === productId) {
        return { ...product, quantity: product.quantity + 1 };
      }
      return product;
    });
    setSelectedProducts(updatedProducts);
 
  };

  const handleDecreaseQuantity = (productId) => {
    const updatedProducts = selectedProducts.map((product) => {
      if (product.ProductId === productId && product.quantity > 1) {
        return { ...product, quantity: product.quantity - 1 };
      }
      return product;
    });
    setSelectedProducts(updatedProducts);
 
  };

  const handleRemoveClick = (productId) => {
    const updatedProducts = selectedProducts.filter(
      (product) => product.ProductId !== productId
    );
    setSelectedProducts(updatedProducts);
 
  };

  

  const totalPrice = selectedProducts.reduce(
    (total, product) => total + product.ProductPrice * product.quantity,
    0
  );

 

  

 

  const handleAddClick = (productId, el) => {
    //console.log(el);
    // Check if the product is already in the selectedProducts array
    const existingProductIndex = selectedProducts.findIndex(
      (product) => product.ProductId === productId
    );

    if (existingProductIndex !== -1) {
      // If the product is already selected, update its quantity
      const updatedProducts = [...selectedProducts];
      updatedProducts[existingProductIndex].quantity += 1;
      setSelectedProducts(updatedProducts);
    } else {
      // If the product is not selected, add it to the array with quantity 1
      const selectedProduct = selectedCategory.find(
        (product) => product.ProductId === productId
      );
      setSelectedProducts([
        ...selectedProducts,
        { ...selectedProduct, quantity: 1 },
      ]);

      
      
    }
  };

  const handleCategoryClick = (el) => {
    setSelectedCategory(el.Product);
    setShowProducts(true);
  };

  const scrollToBottom = () => {
    if (productsContainerRef.current) {
      productsContainerRef.current.scrollTop =
        productsContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedProducts]);

  const data = {
    DeviceIP: "192.10.12.1",
    Type: "cachir",
    subtype: "null",
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllCatigoresWithProducts(data));
  }, [dispatch]);

  const customer = useSelector((state) => state.callcenter);

  //console.log(customer.catigoryWithProducts)
  //console.log(dataSend)
  //console.log(selectedProducts)

  const extractProperties = (inputArray) => {
    // Use the map function to create a new array based on the input array
    const newArray = inputArray.map(({ ProductId, quantity }) => ({
      ProductId,
      Quantity: quantity,
    }));

    return newArray;
  };

  const navigate = useNavigate();

  //console.log(OrderTypeData)

  const SubmitOrder = () => {
    const newData = extractProperties(selectedProducts);
    //console.log(newData)
    var IsPaid=true
    if(OrderTypeData.OrderTypeId===1){
      IsPaid=false
    }
    //console.log(IsPaid)
    const dataSend = [
      {
        BranchId: branchData.BranchId,
        OrderTypeId: OrderTypeData.OrderTypeId,
        CustomerPhoneId: cutomerData.CustomerPhoneId,
        CustomerId: cutomerData.CustomerId,
        ReceiptNotes: ReceiptNotes,
        KitchenNotes: KitchenNotes,
        products: newData,
        TotalPrice: totalPrice,
        Subtotal: calculateTotalPrice(),
        DevicesId: 2,
        OrderStatusId: 1,
        IsPaid:IsPaid
      },
    ];

    //console.log(dataSend[0].OrderTypeId)
    if (
      dataSend[0].BranchId === undefined ||
      dataSend[0].CustomerId === undefined ||
      dataSend[0].CustomerPhoneId === undefined ||
      dataSend[0].OrderTypeId === undefined ||
      dataSend[0].products.length == 0
    ) {
      toast2({
        description: t("pages.DailyRestrictions.searchvalidation"),
      });
    } else {

      if(OrderTypeData.OrderTypeId===1){
        dispatch(sendOrder(dataSend))
        .unwrap()
        .then((_) => {
          toast({ description: "Item Created" });
        navigate(`/${routes.orders}`);
      });
      }else{
        console.log(dataSend)
        setPaymentModal(true)
        SetDataForPaymentModal(dataSend)
      }
      // dispatch(sendOrder(dataSend))
      //   .unwrap()
      //   .then((_) => {
      //     toast({ description: "Item Created" });
      //     navigate(`/${routes.orders}`);
      //   });
   
    }
  };

  const [discountModal,SetDiscountModal]=useState(false)
  
  const handleButtonClick = (productId,discountAmount) => {
    // Find the product with the given productId
    const updatedProducts = selectedProducts.map((product) => {
      if (product.ProductId === productId) {
        // Update ProductPrice to 0
        if(discountAmount.type==="fixed"){
          //console.log(product.ProductPrice)
          const afterDiscountFixed=product.ProductPrice-discountAmount.amount
          if(afterDiscountFixed<0){
            toast3({
              description:t("pages.orders.discountError") ,
            });
          }else{
            return { ...product, ProductPrice: afterDiscountFixed };
          }
        }
        
        else if(discountAmount.type==="precentage"){
          const afterDiscountPrecentage=product.ProductPrice-(product.ProductPrice*(discountAmount.amount/100))
          if(afterDiscountPrecentage<0){
            toast3({
              description:t("pages.orders.discountError") ,
            });
          }else{
            return { ...product, ProductPrice: afterDiscountPrecentage };

          }
          //console.log(product.ProductPrice)
        }
        //return { ...product, ProductPrice: discountAmount.amount };
      }
      return product;
    });

    // Update the state with the modified array
    setSelectedProducts(updatedProducts);
  };

  const [discountAmount,setDiscountAmount]=useState(null)

  const [applyDiscount,setapplyDiscount]=useState(false)

  const [productIdForDiscount,SetproductIdForDiscount]=useState()

  //console.log(discountAmount)
  if(applyDiscount){
    //console.log("true apply")
    //console.log(productIdForDiscount)
    handleButtonClick(productIdForDiscount,discountAmount)
    setapplyDiscount(false)
    // setDiscountAmount(null)
    // productIdForDiscount=null
 
  }else{
    
  }

  //console.log(discountAmount)
  //console.log(applyDiscount)



  const handelDiscount=(el)=>{
    //console.log(el.ProductId)
    SetproductIdForDiscount(el.ProductId)
    //console.log(selectedProducts)
    SetDiscountModal(true)
  
  }





 
  
  // subtotal 

  const [cart, setCart] = useState([]);

  const addToCart = (product) => {
    // Check if the product is already in the cart
    const isProductInCart = cart.some((item) => item.ProductId === product.ProductId);

    if (!isProductInCart) {
      // Add the product to the cart
      setCart([...cart, { ...product, quantity: 1 }]);
    } else {
      // If the product is already in the cart, update its quantity
      setCart((prevCart) =>
        prevCart.map((item) =>
          item.ProductId === product.ProductId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    }
  };



  const removeFromCart = (productId) => {
    // Remove the product from the cart
    setCart((prevCart) => prevCart.filter((item) => item.ProductId !== productId));
  };


  const increaseQuantityCart = (productId) => {
    // Increase the quantity of the product in the cart
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.ProductId === productId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };


  const decreaseQuantitycart = (productId) => {
    // Decrease the quantity of the product in the cart
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.ProductId === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + item.ProductPrice * item.quantity, 0);
  };

  //console.log(cart)




        //console.log(branchData,OrderTypeData,cutomerData,ReceiptNotes,KitchenNotes)
 
  const printCart = () => {
    const tableRows = selectedProducts.map((item, index) => `
      <tr>
        <td>${index + 1}</td>
        <td>${item.ProductName}</td>
        <td>$${item.ProductPrice}</td>
        <td>${item.quantity}</td>
      </tr>
    `).join('');
  
    const totalPricePrint = totalPrice;
  
    const customerDetails = `
      <div>
        <p>Branch: ${branchData.BranchName}</p>
        <p>Customer Phone: ${cutomerData.PhoneNo}</p>
        <p>OrderType : ${OrderTypeData.OrderTypeName}</p>
        <p>ReceiptNotes : ${ReceiptNotes}</p>
        <p>KitchenNotes : ${KitchenNotes}</p>

      </div>
    `;
  
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Receipt</title>
          <style>
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            .totalPrice {
              font-size: 18px;
              margin-top: 10px;
            }
          </style>
        </head>
        <body>
          <h2>Receipt</h2>
          ${customerDetails}
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              ${tableRows}
            </tbody>
          </table>
          <div class="totalPrice">Total price: $${totalPricePrint}</div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  
  
  if(ManageOrders===false){
    return(
      <NotAuthorizedCompo/>
    )
  }
  



  return (
    <>
      <div className="mobileViewCallCenter">
        <div>
          <Box>
            <Badge
              display="flex"
              alignItems="center"
              bg="light"
              paddingBlock={2}
              mb={4}
              paddingInline={4}
              color="dark"
              fontSize="1rem"
              textTransform="capitalize"
              width="200px"
            >
              {t("pages.orders.newOrder")}
              <Badge ms={2}></Badge>
            </Badge>

            <Stack spacing={6}>
              <Box bg="light" paddingBlock={3} paddingInline={4}>
                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  gap={15}
                >
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{t("pages.orders.branch")}</Text>
                    </Stack>
                  </Flex>
                  <Text fontWeight="bold" mb={4}>
                    {branchData.BranchName || "-"}
                  </Text>
                  <Text
                    fontWeight="bold"
                    mb={4}
                    style={{ cursor: "pointer" }}
                    onClick={() => SetBranchModal(true)}
                  >
                    <FaRegEdit size={20} />
                  </Text>
                </Flex>

                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  gap={15}
                >
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{t("pages.orders.orderType")}</Text>
                    </Stack>
                  </Flex>
                  <Text fontWeight="bold" mb={4}>
                    {OrderTypeData.OrderTypeName || "-"}
                  </Text>
                  <Text
                    fontWeight="bold"
                    mb={4}
                    style={{ cursor: "pointer" }}
                    onClick={() => SetOrderTypeModal(true)}
                  >
                    <FaRegEdit size={20} />
                  </Text>
                </Flex>

                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  gap={15}
                >
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{t("pages.orders.customer")}</Text>
                    </Stack>
                  </Flex>

                  <Text fontWeight="bold" mb={4}>
                    {cutomerData.PhoneNo || "-"}
                  </Text>
                  <Text
                    fontWeight="bold"
                    mb={4}
                    style={{ cursor: "pointer" }}
                    onClick={() => setshowCustomerModal(true)}
                  >
                    <FaRegEdit size={20} />
                  </Text>
                </Flex>

                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  gap={15}
                >
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{t("pages.orders.customer_name")}</Text>
                    </Stack>
                  </Flex>

                  <Text fontWeight="bold" mb={4}>
                    {cutomerData.CustomerName || "-"}
                  </Text>
                  <Text
                    fontWeight="bold"
                    mb={4}
                    style={{ cursor: "pointer" }}
                    onClick={() => setshowCustomerModal(true)}
                  ></Text>
                </Flex>

                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  gap={15}
                >
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{t("pages.orders.due_at")}</Text>
                    </Stack>
                  </Flex>

                  <Text fontWeight="bold" mb={4}>
                    {Date || "-"}
                  </Text>

                  <Text
                    fontWeight="bold"
                    mb={4}
                    style={{ cursor: "pointer" }}
                    onClick={() => setDateModal(true)}
                  >
                    <FaRegEdit size={20} />
                  </Text>
                </Flex>

                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  gap={15}
                >
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{t("pages.orders.receipt_notes")}</Text>
                    </Stack>
                  </Flex>
                  <Tooltip label={ReceiptNotes}>
                    <Text
                      fontWeight="bold"
                      mb={4}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "10%",
                        whiteSpace: "nowrap",
                        width: "50px",
                      }}
                    >
                      {ReceiptNotes || "-"}
                    </Text>
                  </Tooltip>

                  <Text
                    fontWeight="bold"
                    mb={4}
                    style={{ cursor: "pointer" }}
                    onClick={() => setReceiptModal(true)}
                  >
                    <FaRegEdit size={20} />
                  </Text>
                </Flex>
                <Flex
                  alignItems="baseline"
                  justifyContent="space-between"
                  gap={15}
                >
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{t("pages.orders.kitchen_note")}</Text>
                    </Stack>
                  </Flex>
                  <Tooltip label={KitchenNotes}>
                    <Text
                      fontWeight="bold"
                      mb={4}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "10%",
                        whiteSpace: "nowrap",
                        width: "50px",
                      }}
                    >
                      {KitchenNotes || "-"}
                    </Text>
                  </Tooltip>
                  <Text
                    fontWeight="bold"
                    mb={4}
                    style={{ cursor: "pointer" }}
                    onClick={() => setKitchenModal(true)}
                  >
                    <FaRegEdit size={20} />
                  </Text>
                </Flex>
              </Box>

              <div
                style={{ overflowY: "auto", maxHeight: "50vh" }}
                ref={productsContainerRef}
              >
                {selectedProducts.map((el, index) => {
                  return (
                    <Box
                      bg="light"
                      paddingBlock={3}
                      paddingInline={4}
                      key={el.ProductId}
                      style={{ marginBottom: "15px" }}
                    >
                      <Text fontWeight="bold" mb={4}></Text>
                      <Flex alignItems="baseline">
                        <IoFastFood />
                        <Stack ms={2} color="text">
                          <Text>{el.ProductName}</Text>
                        </Stack>
                      </Flex>

                      <Flex alignItems="baseline">
                        <MdPriceChange />
                        <Flex
                          ms={2}
                          color="text"
                          style={{
                            justifyContent: "space-between",
                            gap: "90px",
                            alignItems: "center",
                          }}
                        >
                          <Text>{el.ProductPrice}</Text>
                          <div
                            onClick={() => handelDiscount(el)}
                            style={{ cursor: "pointer", color: "green" }}
                          >
                            {t("pages.orders.discount")}
                          </div>
                        </Flex>
                      </Flex>

                      <Flex alignItems="baseline">
                        <MdProductionQuantityLimits />
                        <Stack ms={2} color="text">
                          <Text
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {t("pages.call_center.Quantity")}

                            <div
                              onClick={() => {
                                increaseQuantityCart(el.ProductId);
                                handleIncreaseQuantity(el.ProductId);
                              }}
                              style={{
                                margin: "15px",
                                backgroundColor: "green",
                                padding: "2px 8px 2px 8px",
                                borderRadius: "8px",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              +
                            </div>
                            {el.quantity}

                            <div
                              onClick={() => {
                                decreaseQuantitycart(el.ProductId);
                                handleDecreaseQuantity(el.ProductId);
                              }}
                              style={{
                                margin: "15px",
                                backgroundColor: "red",
                                padding: "2px 8px 2px 8px",
                                borderRadius: "8px",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              -
                            </div>
                          </Text>
                        </Stack>
                      </Flex>

                      <Button
                        type="button"
                        bg="primary"
                        color="light"
                        textTransform="capitalize"
                        fontSize="1rem"
                        style={{ marginTop: "15px" }}
                        onClick={() => {
                          removeFromCart(el.ProductId);
                          handleRemoveClick(el.ProductId);
                        }}
                      >
                        {t("pages.call_center.Remove")}
                      </Button>
                    </Box>
                  );
                })}
              </div>
              <Button
                type="button"
                bg="primary"
                color="light"
                textTransform="capitalize"
                fontSize="1rem"
                style={{ marginTop: "15px" }}
                onClick={SubmitOrder}
              >
                {t("pages.call_center.Submit_Order")} : {totalPrice}
              </Button>
            </Stack>
          </Box>
        </div>
        <SimpleGrid
          columns={{ base: 2, md: 3, lg: 4, xl: 6 }}
          spacing={6}
          style={{ height: "20%" }}
        >
          {showProducts ? (
            <>
              <div
                style={{
                  backgroundColor: "gray",
                  maxHeight: "150px",
                  color: "white",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowProducts(false)}
              >
                {t("pages.hr.back")}
              </div>
              {selectedCategory?.map((el) => (
                <Box
                  bg="light"
                  paddingBlock={3}
                  paddingInline={4}
                  key={el.ProductId}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    minHeight: "150px",
                    borderRadius: "5%",
                  }}
                  //onClick={() => clickedproduct(el.ProductId)}
                >
                  <img
                    src={
                      `https://rostoapi.smartgate-egypt.com/` +
                      el.ProductImagePath
                    }
                    alt="Selected"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      marginTop: "10px",
                    }}
                  />
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text" style={{ width: "100px" }}>
                      <Tooltip label={el.ProductName}>
                        <Text
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "90%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {el.ProductName}
                        </Text>
                      </Tooltip>
                      <Text>{el.ProductPrice}</Text>
                    </Stack>
                  </Flex>
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{el.UserDate}</Text>
                    </Stack>
                  </Flex>
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{el.BranchName}</Text>
                    </Stack>
                    <Button
                      type="button"
                      bg="primary"
                      color="light"
                      textTransform="capitalize"
                      fontSize="1rem"
                      style={{ marginTop: "15px" }}
                      onClick={() => {
                        addToCart(el);
                        handleAddClick(el.ProductId, el);
                      }}
                    >
                      add
                    </Button>
                  </Flex>
                </Box>
              ))}
            </>
          ) : (
            <>
              {customer?.catigoryWithProducts?.map((el) => (
                <Box
                  bg="light"
                  paddingBlock={3}
                  paddingInline={4}
                  key={el.ProductId}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    minheight: "150px",
                    borderRadius:"5%"
                  }}
                  onClick={() => handleCategoryClick(el)}
                >
                  <img
                    src={
                      `https://rostoapi.smartgate-egypt.com/` +
                      el.ProductCategoriesImagePath
                    }
                    alt="Selected"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      marginTop: "10px",
                    }}
                  />
                  <Flex alignItems="baseline">
                    <Stack ms={2} color="text">
                      <Text>{el.ProductCategoriesNameEng}</Text>
                    </Stack>
                  </Flex>
                </Box>
              ))}
            </>
          )}
        </SimpleGrid>
      </div>

      {showBranchModal && (
        <BrancheModal
          //data={showCreateSectionModal}
          onClose={() => SetBranchModal(false)}
          setBranchData={setBranchData}
        />
      )}

      {showOrderTypeModal && (
        <OrderTypeModal
          onClose={() => SetOrderTypeModal(false)}
          SetOrderType={SetOrderType}
        />
      )}

      {showCustomerModal && (
        <CustomerModal
          onClose={() => setshowCustomerModal(false)}
          SetCustomerData={SetCustomerData}
        />
      )}

      {showReceiptModal && (
        <ReceiptNotesModal
          onClose={() => setReceiptModal(false)}
          setReceiptNotes={setReceiptNotes}
        />
      )}

      {showDateModal && (
        <DateModal onClose={() => setDateModal(false)} setDate={setDate} />
      )}

      {showKitchenModal && (
        <KitchenNoteModal
          onClose={() => setKitchenModal(false)}
          setKitchenNotes={setKitchenNotes}
        />
      )}

      {paymentModal && (
        <PaymentModal
          onClose={() => setPaymentModal(false)}
          dataForPaymentModal={dataForPaymentModal}
          printCart={() => printCart()}
        />
      )}

      {discountModal && (
        <DiscountModal
          onClose={() => SetDiscountModal(false)}
          setDiscountAmount={setDiscountAmount}
          setapplyDiscount={setapplyDiscount}
        />
      )}
    </>
  );
};

export default CreateOrderPage;
