import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { Box, Button, Checkbox, Container, Flex, Heading, Image, SimpleGrid, Stack, Link as CLink, Text } from "@chakra-ui/react"
import Input from "../../../components/form/Input";
import logo from "../../../assets/images/logo.png";
import authImg from "../../../assets/images/auth-img.jpeg";
import { login, signUp } from "../services/authService";
import routes from "../../../navigations/routes";
import MultiSelect from "components/ui/multiSelect/MultiSelect";

export default function SignUp (){
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  //console.log("auth" , auth);

  const { handleSubmit,  formState: { errors },setValue ,  control} = useForm();
  const { t } = useTranslation();

  const loginValidationSchema = {
    email: {
      required: t("validation.required")
    },
    password: {
      required: t("validation.required")
    },
    name: {
      required: t("validation.required")
    }
  };

  const location = [
    {loc : "Egypt" , id : 1},
    {loc : "Saudi Arabia" , id : 2},
  ];

  return (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      <Container maxW="container.lg">
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          bg="light" boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          paddingInline={8} paddingBlock={14} borderRadius={6}
        >
          <Box
            flex="1" display={{ base: "none", md: "block" }}
          >
            <Image
              src={logo}
              alt="brand"
              width="120px"
            />
            <Image
              src={authImg}
              alt="brand"
              height="100%"
              borderRadius={6}
              objectFit="contain"
            />
          </Box>
          <Box flex="1" paddingInline={{ base: 2, md: 8 }} >
            <form onSubmit={handleSubmit(values => {
              console.log(values);
              values.BusinessLocation = {
                id : values.BusinessLocation.id,
                loc : values.BusinessLocation.loc,
              }
            // dispatch(signUp(values))
            //     .unwrap()
            //     .then(_ => navigate(`/${routes.dashboard_general}`))
            //     .catch(_ => console.log("something went wrong"))
            })}>
              <Heading
                as="h2" color="dark" fontSize="1.5rem"
                textTransform="capitalize" mb={2}
              >
                Sign Up,
              </Heading>
              <Text
                mb={6} color="text" _firstLetter={{ textTransform: "capitalize" }}
                fontSize=".9rem"
              >
                Sign Up with your data and ensure that your data is correct.
              </Text>

              <Stack spacing={4}>
                <Input
                  type="text"
                  name="name"
                  label="name"
                  isRequired
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder="ristecho"
                  isFormController
                  control={control}
                  rules={loginValidationSchema.name}
                  error={errors.name}
                />
                <Input
                  type="text"
                  name="email"
                  label="email"
                  isRequired
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder="email@example.com"
                  isFormController
                  control={control}
                  rules={loginValidationSchema.email}
                  error={errors.email}
                />
                <Input
                  type="password"
                  name="password"
                  label="password"
                  isRequired
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder="password"
                  isFormController
                  control={control}
                  rules={loginValidationSchema.password}
                  error={errors.password}
                />
                <Input
                  type="text"
                  name="BusinessName"
                  label="Business Name"
                  isRequired
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder="Business Name"
                  isFormController
                  control={control}
                  rules={loginValidationSchema.BusinessName}
                  error={errors.BusinessName}
                />

              <MultiSelect
                data={
                  location &&
                  location.map((el) => ({
                    ...el,
                    label: el.loc,
                    value: el.id,
                  }))
                }
                onChange={(e) => setValue("BusinessLocation", e)}
                isMulti={false}
                isRequired
                title={t("general.businesslocation")}
              />



                <Flex
                  alignItems="center"
                >
                  <Checkbox size="lg" textTransform="capitalize" color="dark" alignItems="center" colorScheme="purple">
                    accept terms and conditions
                  </Checkbox>
                </Flex>
                <Button
                  type="submit" height="45px" bg="primary"
                  color="light" textTransform="capitalize"
                  fontSize="1rem" border="1px solid transparent"
                  _hover={{ bg: "none", color: "primary", borderColor: "primary" }}
                  isLoading={auth.isLoading}
                >
                  sign up
                </Button>
                <Flex
                  alignItems="center" color="dark" textTransform="capitalize" gap={2}
                >
                  you have an account ?
                  <CLink to="/login" color="primary" ms={2}>login now</CLink>
                  {/* <Link to="/login" color={"#EAF2F6"} fontSize={"bold"} ms={"10px"} > login now </Link> */}
                </Flex>
              </Stack>
            </form>
          </Box>
        </SimpleGrid>
      </Container>
    </Flex >
  )
};