import { createSlice } from "@reduxjs/toolkit";
import {
  createDiscountProductTags,
  getDiscountsProductTags,
  deleteProductTagInDiscount,
  getTagsInProductTags,
} from "../services/discountsProductTagsService";

let initialState = {
  isLoading: false,
  errors: null,
  tagsInProductTag: [],
};

const discountsProductTagsSlice = createSlice({
  name: "discountsProductTags",
  initialState,

  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get categories Discounts List  -------------------------------------------------------------------*/
    builder.addCase(getDiscountsProductTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDiscountsProductTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getDiscountsProductTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create categories Combos  -------------------------------------------------------------------*/
    builder.addCase(createDiscountProductTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      createDiscountProductTags.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(createDiscountProductTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Product Tag In Discount  -------------------------------------------------------------------*/
    builder.addCase(deleteProductTagInDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      deleteProductTagInDiscount.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(deleteProductTagInDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------- Get Tags In Product Tags ---------------------------------------------------*/
    builder.addCase(getTagsInProductTags.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getTagsInProductTags.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInProductTag =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getTagsInProductTags.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default discountsProductTagsSlice.reducer;
