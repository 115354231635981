import { createSlice } from "@reduxjs/toolkit";
import {
  getBrands,
  createBrand,
  updateBrand,
  deleteBrand,
  getProductsForBrand,
  getTagsForBrand,
  getProductCatForBrands,
  getBrandsFilteredData,
  resetBeandsFilteredData
} from "../services/brandsService";

let initialState = {
  isLoading: false,
  data: [],
  products:[],
  tags:[],
  productCat:[],
  pages: 0,
  errors: null,

  filteredData : []
};

const brandsSlice = createSlice({
  name: "brands",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBrands.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getBrands.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getBrands.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createBrand.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createBrand.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createBrand.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateBrand.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateBrand.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const indexAt = state.data.findIndex(brand => brand.id === payload.id);
      // state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateBrand.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteBrand.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteBrand.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = state.data.filter(brand => brand.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteBrand.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //

    builder.addCase(getProductsForBrand.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getProductsForBrand.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.products = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.products)
      state.errors = null;
    })
    builder.addCase(getProductsForBrand.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })



    //


    builder.addCase(getTagsForBrand.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTagsForBrand.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tags = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.tags)
      state.errors = null;
    })
    builder.addCase(getTagsForBrand.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    //


    
    builder.addCase(getProductCatForBrands.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getProductCatForBrands.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.productCat = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.productCat)
      state.errors = null;
    })
    builder.addCase(getProductCatForBrands.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


       /* ----------------------------------------- Filter Data --------------------------------------------- */
       builder.addCase(getBrandsFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(getBrandsFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        state.errors = null;
      });
      builder.addCase(getBrandsFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
     /* -------------------------------------------------------------------------------------- */
      builder.addCase(resetBeandsFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(resetBeandsFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = [];
        state.errors = null;
      });
      builder.addCase(resetBeandsFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
  }
});

export default brandsSlice.reducer;