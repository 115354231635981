import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Get Taxes Report List ------------------------------- */
  export const getTaxesReportList = createAsyncThunk("taxesReport/getTaxesReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetTaxesReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

 /* --------------------------------------- Taxes Filter Data ------------------------------*/
 export const getTaxesFilteredData = createAsyncThunk("taxesReport/getTaxesFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetTaxesFilteredData = createAsyncThunk( "taxesReport/resetTaxesFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);