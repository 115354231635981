import { createSlice } from "@reduxjs/toolkit";
import {
  getbranches,
  assigenGiftToBranch,
  getBranchesOfGift,
} from "../services/inActiveBranchesService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  branchesOfGift: [],
};

const inActiveBranchesSlice = createSlice({
  name: "inActiveGiftCards",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getbranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getbranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getbranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(assigenGiftToBranch.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(assigenGiftToBranch.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });

    builder.addCase(assigenGiftToBranch.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //
    builder.addCase(getBranchesOfGift.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getBranchesOfGift.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branchesOfGift =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getBranchesOfGift.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default inActiveBranchesSlice.reducer;
