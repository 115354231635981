import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getTaxesGroups = createAsyncThunk("taxesGroups/getTaxesGroups", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Taxs/TaxsGroupList");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* --------------------------------------------- Create Taxes Groups ----------------------------------------------*/
export const createTaxGroup = createAsyncThunk(  "taxesGroups/createTaxGroup",async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Taxs/AddEditTaxGroup?taxsgroup=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateTaxGroup = createAsyncThunk(
  "taxesGroups/updateTaxGroup",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(`/taxesGroups/${args.id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteTaxGroup = createAsyncThunk(
  "taxesGroups/deleteTaxGroup",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(`/taxesGroups/${args.id}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);