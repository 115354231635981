import { Box, Flex, Spinner } from '@chakra-ui/react'
import StaticsCard from 'components/ui/staticsCard/StaticsCard'
import React from 'react'
import Chart from "react-apexcharts";


const GeneralChart = ({data}) => {
  const dataCount=[data[0]?.DeliveryCount, data[0]?.DineInCount, data[0]?.PickUpCount
  , data[0]?.DriveThruCount ]

  if (!data || data.length === 0) {
    return(
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex> 
    ) }

  return (
    <Box bg="light" borderRadius={2} marginTop={5}>
        <StaticsCard text="orders" count={data[0]?.OrderCount} />
        <Chart
          options={{
            colors: ["black"],
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: [
                "Delivery Orders",
                "Dine In Orders",
                "Pickup Orders",
                "Drive Thru Orders",
              ],
            },
          }}
          series={[
            {
              name: "Value",
              data:dataCount , // Adjust data values accordingly
            },
          ]}
          type="bar" // Change chart type to "bar" for column chart
          width="100%"
          height="200px"
        />
      </Box>
  )
}

export default GeneralChart
