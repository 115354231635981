import { createSlice } from "@reduxjs/toolkit";
import { getShiftsReportReportFilteredData, getShiftsReportReportList, resetShiftsReportReportFilteredData } from "../services/shiftsReportServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const shiftsReportSlice = createSlice({
  name: "shiftsReport",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------- Get Gift Cards  Report List ------------------------------- */
    builder.addCase(getShiftsReportReportList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getShiftsReportReportList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getShiftsReportReportList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

   /* ------------------------------------------------------------------------------------------- */
 /* --------------------------------------- Gift Cards Filter Data ------------------------------*/
 builder.addCase(getShiftsReportReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getShiftsReportReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getShiftsReportReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetShiftsReportReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetShiftsReportReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetShiftsReportReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default shiftsReportSlice.reducer;