import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useParams } from "react-router-dom";
import {
  deleteBranchInTimedEvent,
  getTimedEventsBranches,
} from "../../services/timedEventsBranchesService";
import {
  deleteCategoryInTimedEvent,
  getTimedEventCategories,
} from "../../services/timedEventsCategoriesService";
import {
  deleteComboInTimedEvent,
  getTimedEventsCombos,
} from "../../services/timedEventsCombosService";
import {
  deleteProductInTimedEvent,
  getTimedEventsProducts,
} from "../../services/timedEventsProductsService";
import {
  deleteProductTagInTimedEvent,
  getTimedEventsProductTags,
} from "../../services/timedEventsProductTagsService";

const DeleteAppliesModal = ({ id, onClose, type }) => {
  console.log("id", id);
  const ID = useParams();
  const { t } = useTranslation();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const dispatch = useDispatch();

  const submissionFunctions = {
    Branch: () => {
      dispatch(deleteBranchInTimedEvent(id.TimedEventBranchId))
        .unwrap()
        .then((_) => {
          toast({
            description: t("pages.timedEvents.branch_timed_event_deleted"),
          });
          dispatch(getTimedEventsBranches(ID.id));
          onClose();
        });
    },
    Cateogry: () => {
      dispatch(deleteCategoryInTimedEvent(id.TimedEventCategoryId))
        .unwrap()
        .then((_) => {
          toast({
            description: t("pages.timedEvents.category_timed_event_deleted"),
          });
          dispatch(getTimedEventCategories(ID.id));
          onClose();
        });
    },
    Product: () => {
      dispatch(deleteProductInTimedEvent(id.TimedEventProductId))
        .unwrap()
        .then((_) => {
          toast({
            description: t("pages.timedEvents.product_timed_event_deleted"),
          });
          dispatch(getTimedEventsProducts(ID.id));
          onClose();
        });
    },
    Combo: () => {
      dispatch(deleteComboInTimedEvent(id.TimedEventComboId))
        .unwrap()
        .then((_) => {
          toast({
            description: t("pages.timedEvents.combo_timed_event_deleted"),
          });
          dispatch(getTimedEventsCombos(ID.id));
          onClose();
        });
    },
    ProductTag: () => {
      dispatch(deleteProductTagInTimedEvent(id.TimedEventProductTagId))
        .unwrap()
        .then((_) => {
          toast({
            description: t("pages.timedEvents.product_tag_timed_event_deleted"),
          });
          dispatch(getTimedEventsProductTags(ID.id));
          onClose();
        });
    },
  };

  const onSubmit =
    type in submissionFunctions ? submissionFunctions[type] : undefined;

  return <GDeleteModal onClose={onClose} onSubmit={onSubmit} />;
};

export default DeleteAppliesModal;
