import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


/* -------------------------------------------------- Get Delivery Charges ---------------------------------------------------------- */
export const getDeliveryCharges= createAsyncThunk( "deliverycharge/getDeliveryCharges",   async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/DeliveryCharge/DeliveryChargeList`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* -------------------------------------------------- Get Charges For Delivery Charge---------------------------------------------------------- */
  export const getchargesForDeliveryCharge= createAsyncThunk("deliverycharge/getchargesForDeliveryCharge",   async (args, thunkApi) => {
     try {
        const { data } = await axios.get(`/Manage/Charge/LookUpCharge`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* -------------------------------------------------- Get Charge Type---------------------------------------------------------- */
  export const getchargeType= createAsyncThunk(  "deliverycharge/getchargeType",  async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Charge/LookUpChargeType`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* -------------------------------------------------- Get Order Type Charge ---------------------------------------------------------- */
  export const getorderTypeCharge= createAsyncThunk( "deliverycharge/getorderTypeCharge",  async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpOrderTypes`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* -------------------------------------------------- Get Taxes Group For Charge ---------------------------------------------------------- */
  export const getTaxesGroupForCharge= createAsyncThunk("deliverycharge/getTaxesGroupForCharge", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Charge/LookUpTaxGroup`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* -------------------------------------------------- Save Dilvery Charges---------------------------------------------------------- */
  export const saveDilveryCharges = createAsyncThunk("deliverycharge/saveDilveryCharges", async (args, thunkApi) => {
      try {
        const { data } = await axios.post(`/Manage/Charge/AddEditCharge?charge=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* -------------------------------------------------- Add Dilvery Charges To Delivery Zone---------------------------------------------------------- */
  export const addDilveryChargesToDeliveryZone = createAsyncThunk("deliverycharge/addDilveryChargesToDeliveryZone", async (args, thunkApi) => {
      try {
        const { data } = await axios.post(`/Manage/EditBranchDelivZone?branchdelivzoneid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );