import { createSlice } from "@reduxjs/toolkit";
import {
  getByIdTree,
  getAccounts,
  getAfterFilter,
  createAccountCycle,
  getHeaderLookUp,
  getJournalById,
  getAccountsLookUp,
  addInDailyRestriction,
  GetFinancialPeriodsDailyRestriction,
  GetTrialBalance,
  GetIncomeStatment,
} from "../service/accountsTreeService";

let initialState = {
  isLoading: false,
  data: [],
  dataById: [],
  Accounts: [],
  tableData: [],
  headerLookUp: [],
  searchData: [],
  accountsLookUp: [],
  accountCycle: [],
  pages: 0,
  errors: null,
  FinancialPeriodList: [],
  accountType: [],
  FinancialPeriodDailyRestriction: [],
  trialBalance: [],

  // -------------------- income_statement ------------------------
  incomeStatment_Data: [],
};

const acoountTreeSlice = createSlice({
  name: "AccountTree",
  initialState,

  extraReducers: (builder) => {
    //

    //

    builder.addCase(getByIdTree.pending, (state) => {
      //state.isLoading = true;
      //state.errors = null;
    });

    builder.addCase(getByIdTree.fulfilled, (state, { payload }) => {
      //state.isLoading = false;
      state.dataById = payload;
      //console.log(state.data);
      //state.errors = null;
    });

    builder.addCase(getByIdTree.rejected, (state) => {
      //state.isLoading = false;
      //state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(getAccounts.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.Accounts = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.Accounts);
      state.errors = null;
    });

    builder.addCase(getAccounts.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(getAfterFilter.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getAfterFilter.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tableData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.tableData);
      state.errors = null;
    });

    builder.addCase(getAfterFilter.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(createAccountCycle.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(createAccountCycle.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.data);
      state.errors = null;
    });

    builder.addCase(createAccountCycle.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    //DailyRestrictions

    builder.addCase(getHeaderLookUp.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getHeaderLookUp.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.headerLookUp =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.headerLookUp);
      state.errors = null;
    });

    builder.addCase(getHeaderLookUp.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(getJournalById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getJournalById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.searchData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.searchData);
      state.errors = null;
    });

    builder.addCase(getJournalById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //

    builder.addCase(getAccountsLookUp.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getAccountsLookUp.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.accountsLookUp =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.accountsLookUp);
      state.errors = null;
    });

    builder.addCase(getAccountsLookUp.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //add account tree

    // add in daily restriction

    builder.addCase(addInDailyRestriction.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(addInDailyRestriction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      //state.accountType = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.accountType);
      state.errors = null;
    });

    builder.addCase(addInDailyRestriction.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //finacialPeriod DailyRestriction

    builder.addCase(GetFinancialPeriodsDailyRestriction.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(
      GetFinancialPeriodsDailyRestriction.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.FinancialPeriodDailyRestriction =
          (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.FinancialPeriodDailyRestriction);
        state.errors = null;
      }
    );

    builder.addCase(GetFinancialPeriodsDailyRestriction.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    //ميزان المراجعه

    builder.addCase(GetTrialBalance.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(GetTrialBalance.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.trialBalance =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.trialBalance);
      state.errors = null;
    });

    builder.addCase(GetTrialBalance.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* -------------------------------------------------  Get Income Statment --------------------------------------------------- */
    builder.addCase(GetIncomeStatment.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(GetIncomeStatment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      console.log(payload[0]);
      state.incomeStatment_Data =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(GetIncomeStatment.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default acoountTreeSlice.reducer;
