import { Box, Flex } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SettingsModal from "./modals/SettingsModal";

const WalletList = ({ list }) => {
  const { t } = useTranslation();

  const [showSettings, setShowSettings] = useState(false);

  return (
    <>
      <Flex flexDirection="column" width="100%" textAlign="left">
        {list.map((el, index) => (
          <Box
            className="reasonsData"
            padding="20px 0px 20px 20px"
            key={el.DigitalWalletSettingsId}
          >
            <Box marginLeft="3%">
              <Flex
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>{el.PaymentMethodName}</Box>
                <Box marginRight="3%">
                  <GButton
                    title={t(
                      "pages.management.settings.digital_wallet.settings"
                    )}
                    bg="light"
                    color="text"
                    fontSize="14px"
                    border="1px solid"
                    borderColor="border"
                    hover={{ bg: "light" }}
                    onClick={() => setShowSettings(true)}
                  />
                </Box>
              </Flex>
            </Box>
          </Box>
        ))}
      </Flex>
      {showSettings && <SettingsModal onClose={() => setShowSettings(null)} />}
    </>
  );
};

export default WalletList;
