import { Box, Flex, Stack, Text, useToast,   Input as ChInput, } from '@chakra-ui/react';
import GButton from 'components/ui/button/Button';
import GModal from 'components/ui/modal/Modal';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { editProductionItems, getEditItemsQuan, getProductionItems } from '../../services/productionService';

const EditProductionQuantitiesModal = ({ onClose, id }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toast = useToast({ position: "top", duration: 3000,  status: "success", });
    const { handleSubmit } = useForm();

    const productionsData = useSelector((state) => state.productions);

    const [itemData, setItemData] = useState({});

    useEffect(() => {
      dispatch(getEditItemsQuan(id));
    }, [dispatch, id]);

    useEffect(() => {
      const initialItemData = {};
      productionsData.data3.forEach((el) => {
        // Store each element in a separate object
        initialItemData[el.ProductionItemId] = {
          ProductionItemId: el.ProductionItemId,
          StorageQuantity: el.StorageQuantity,
        };
      });
      // Set the state with both the object and the array
      setItemData(initialItemData);
    }, [productionsData.data3]);
  
    const handleQuantityChange = (newQuantity, itemId) => {
      // Step 3: Update the state with the new data using a unique identifier
      setItemData((prevData) => {
        return { 
            ...prevData, 
            [itemId]: { 
                        ...prevData[itemId], 
                        StorageQuantity: newQuantity, 
                      }, 
              };
      });
    };
  
    return (
      <GModal
        onClose={onClose}
        modalHeader={t("pages.transfers_orders.edit_quantities")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                const editedItems = Object.keys(itemData).map((itemId) => ({
                  ProductionItemId: parseInt(itemId,10),
                  StorageQuantity: itemData[itemId].StorageQuantity,
                }));
                values.ProductionItem = editedItems;  
                dispatch(editProductionItems(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.production.production_item_updated") });
                    dispatch(getProductionItems(id));
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                {productionsData.data3.map((el) => (
                  <Box>
                    <Text
                      marginBottom={"7px"}
                      fontSize={"1rem"}
                      fontWeight={"500"}
                      textTransform={"capitalize"}
                      textAlign={"start"}
                      marginInlineEnd={"0.75rem"}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {el.InventoryItemNameEng}
                    </Text>
                    <ChInput
                      value={itemData[el.ProductionItemId]?.StorageQuantity || ""}
                      name="StorageQuantity"
                      onChange={(e) =>
                        handleQuantityChange(
                          e.target.value,
                          el.ProductionItemId
                        )
                      }
                    />
                    </Box>
                ))}
  
                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    );
  };

export default EditProductionQuantitiesModal