import React, { useEffect } from "react";
import {  Box, Spinner,  Text} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ProfileSection from "./sections/ProfileSection";
import NotificationsSections from "./sections/NotificationsSections";

export default function MyProfile({data}) {
  const { t } = useTranslation();
 
  return (
    <>
      
      <Box marginBottom={5}>
        <Text color="dark" textTransform="capitalize" marginBottom={2}>
          {t("pages.profile.profile")}
        </Text>
        {/* Profile Section */}
        <ProfileSection  data={data?.profileData} languageData={data?.languageDropDown}/>    
      </Box>
      {
        data?.profileData.isAdmin === true  ?
        <Box marginBottom={5}>
          {/* <Text color="dark" textTransform="capitalize" marginBottom={2}>
            {t("pages.profile.notifi")}
          </Text> */}
          {/* Notifications Section */}
          {/* <NotificationsSections /> */}
        </Box>
        :
        ""
      }
    </>
  );
}
