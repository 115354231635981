import React, { useState } from "react";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import CancelVacationModal from "./modal/CancelVacationModal";
import EditVacationModal from "./modal/EditVacationModal";
import ViewVacationModal from "./modal/ViewVacationModal";
import Pagination from "components/ui/pagination/Pagination";

export default function VacationListTabel({ data, classifications }) {
  const { t } = useTranslation();
  const [showCancelModal, setShowCancelModal] = useState(null);
  const [showEditModal, setShowEditModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const vacationData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            borderColor="primary"
          />
        </Flex>

        {/* <GButton
          title={t("general.filter")}
          border="1px solid"
          borderColor="border"
          postfixElement={<FiIcons.FiFilter size={16} />}
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
        /> */}
      </Flex>

      <Table>
        <Thead>
          <Tr>
            <Th>{t("pages.hr.id")}</Th>
            <Th>{t("pages.hr.Official_leave_causes")}</Th>
            <Th>{t("pages.hr.from_date")}</Th>
            <Th>{t("pages.hr.to_date")}</Th>
            <Th>{t("pages.hr.view")}</Th>
            <Th>{t("general.edit")}</Th>
            <Th>{t("pages.hr.cancel")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {vacationData?.map((el, index) => {
            return (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{el.OfficialVacationCause}</Td>
                <Td>{el.FromDate}</Td>
                <Td>{el.ToDate}</Td>

                <Td>
                  <Button
                    colorScheme="green"
                    size="sm"
                    onClick={() => setShowViewModal(el)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("pages.hr.view")} </span>
                    </Flex>
                  </Button>
                </Td>
                <Td>
                  <Button
                    colorScheme="telegram"
                    size="sm"
                    onClick={() => setShowEditModal(el)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("pages.hr.edit")} </span>
                    </Flex>
                  </Button>
                </Td>
                <Td>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => setShowCancelModal(el.OfficialVacationId)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("general.delete")} </span>
                    </Flex>
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(data?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(data?.length / recordsPerPage)
          )
        }
      />

      {/* ------------------------  Show Cancel Modals ------------------------*/}
      {showCancelModal && (
        <CancelVacationModal
          onClose={() => setShowCancelModal(null)}
          id={showCancelModal}
        />
      )}
      {/* ------------------------  Show Edit Modals ------------------------*/}
      {showEditModal && (
        <EditVacationModal
          onClose={() => setShowEditModal(null)}
          item={showEditModal}
          classifications={classifications}
        />
      )}
      {/* ------------------------  Show View Modals ------------------------*/}
      {showViewModal && (
        <ViewVacationModal
          onClose={() => setShowViewModal(null)}
          item={showViewModal}
          classifications={classifications}
        />
      )}
    </Box>
  );
}
