import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import AddItemToPurchaseOrder from "./modals/AddItemToPurchaseOrder";
import ImportItemToPurchaseOrder from "./modals/ImportItemToPurchaseOrder";
import EditQuantityItemPurchaseModal from "./modals/EditQuantityItemPurchaseModal";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege .jsx";

export default function PurchaseOrderItems ({id}) {
  const { t } = useTranslation();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [showAddItemToPurchaseOrderModal, setShowAddItemToPurchaseOrderModal] = useState(false);
  const [showImportItemToPurchaseOrderModal, setShowImportItemToPurchaseOrderModal] = useState(false);
  const [showEditQuantityPurchaseOrderModal, setShowEditQuantityPurchaseOrderModal] = useState(false);
  
  const items = useSelector(state => state.purchaseOrder);
  const data = items.PurchaseOrdersitems ;
  const status = items?.purchasOrderDetails[0]?.Status;

/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageItems = checkIdExists(retrievedArray, 14);

if(showAddItemToPurchaseOrderModal===true && ManageItems===false){
  toast2({ description:  t("general.authorization")});
  setShowAddItemToPurchaseOrderModal(false);
};
if(showImportItemToPurchaseOrderModal===true && ManageItems===false){
  toast2({ description:  t("general.authorization")});
  setShowImportItemToPurchaseOrderModal(false);
};
if(showEditQuantityPurchaseOrderModal===true && ManageItems===false){
  toast2({ description:  t("general.authorization")});
  setShowEditQuantityPurchaseOrderModal(false);
};
/* -----------------------------------Privilige-------------------------------*/

  return <>
      <Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          flexWrap="wrap"
          mb={6}
        >
          <Text color="text" textTransform="capitalize">{t("general.items")} </Text>
          {
            status === "Approved" ? 
              null
                :
              <Flex gap={4}>
                {
                    items?.PurchaseOrdersitems.length > 0 ? 

                    <GButton
                      title={t("pages.purchase_orders.edit_quantities")}
                      bg="light"
                      color="text"
                      fontSize="14px"
                      border="1px"
                      borderColor={"#CBD5E0"}
                      hover={{ bg: "light" }}
                      opacity="0.7"
                      onClick={() => setShowEditQuantityPurchaseOrderModal(true)}
                    />
                    :
                    ""
                }
                <GButton
                  title={t("pages.purchase_orders.add_items")}
                  bg="light"
                  color="text"
                  fontSize="14px"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  hover={{ bg: "light" }}
                  opacity="0.7"
                  onClick={() => setShowAddItemToPurchaseOrderModal(true)}
                />
                {/* <GButton
                  title={t("pages.purchase_orders.import_items")}
                  bg="light"
                  color="text"
                  fontSize="14px"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  hover={{ bg: "light" }}
                  opacity="0.7"
                  // onClick={() => setShowImportItemToPurchaseOrderModal(true)}
                /> */}
              </Flex>
          }
        </Flex>

        {items.isLoading ? (
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ) : data?.length === 0 ? (
          <Flex
            p={4}
            bg="white"
            alignItems="center"
            justifyContent="center"
            color="text"
            textTransform="capitalize"
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            borderRadius={6}
          >
            {t("pages.branches.no_data")}
          </Flex>
        ) : data?.length > 0 ? (
          <Box
            bg="light"
            overflow="auto"
            borderRadius={4}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <Table>
              <Thead>
                <Tr>
                  <Th> # </Th>
                  <Th>{t("pages.purchase_orders.name")}</Th>
                  <Th>{t("pages.purchase_orders.sku")}</Th>
                  <Th>{t("pages.purchase_orders.avilabel_quantity")}</Th>
                  <Th>{t("pages.purchase_orders.cost_per_unit")}</Th>
                  <Th>{t("pages.purchase_orders.quantity")}</Th>
                  <Th>{t("pages.purchase_orders.total_cost")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((el, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{el.InventoryItemNameEng}</Td>
                    <Td>{el?.SUK}</Td>
                    <Td>{el?.AvaliableQuantity}</Td>
                    <Td>{el?.CostPerUnit}</Td>
                    <Td> {el?.Quantity} </Td>
                    <Td>{el?.TotalCoast}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Flex
            p={4}
            bg="white"
            alignItems="center"
            justifyContent="center"
            color="text"
            textTransform="capitalize"
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            borderRadius={6}
          >
            {t("pages.branches.no_data")}
          </Flex>
        )}

        {showImportItemToPurchaseOrderModal && ( 
              <ImportItemToPurchaseOrder  
                onClose={() => setShowImportItemToPurchaseOrderModal(false)} 
                data={data} 
                id={id} >
              </ImportItemToPurchaseOrder>
        )}

        {/*Show Add Purchase Order Modal*/}
        {showAddItemToPurchaseOrderModal && ( 
              <AddItemToPurchaseOrder  
                  onClose={() => setShowAddItemToPurchaseOrderModal(false)} 
                  data={data} 
                  id={id} >
              </AddItemToPurchaseOrder>
        )}

        {/*Show Edit Quantity Purchase Order Modal*/}
        {showEditQuantityPurchaseOrderModal && ( 
              <EditQuantityItemPurchaseModal  
                  onClose={() => setShowEditQuantityPurchaseOrderModal(false)} 
                  data={data} 
                  id={id} >
              </EditQuantityItemPurchaseModal>
        )}
        
      </Box>
  </>
};
