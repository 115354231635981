import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSuppliersFilteredData } from '../../services/inventorySupplierService';

export default function FilterModal({onClose , data}) {
    // filteredData
    const { t } = useTranslation();
    const dispatch =useDispatch();
    
    const [filteredData, setFilteredData] = useState(data);
    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
      SuppliersName : ""
      });

      const handleFilterSuppliers = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const { SuppliersName } = filterCriteria;
    
          let filterSuppliersName = true;
    
          if (SuppliersName) {
            filterSuppliersName = item.SuppliersName.toLowerCase().includes(SuppliersName.toLowerCase());
          }

          return (filterSuppliersName);

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getSuppliersFilteredData(filteredResult));
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.suppliers.supplier_filter")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterSuppliers}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.suppliers.supplier_name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.suppliers.supplier_name")}
                name="SuppliersName"
                value={filterCriteria.SuppliersName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    SuppliersName: e.target.value,
                  })
                }
              />
              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
