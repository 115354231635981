import React, { useState } from "react";
import {Box, Flex, Table, Tbody, Td, Th, Thead, Tr, Checkbox, } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { IoCloseOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { resetTransfereOrdersFilteredData } from "../services/transfersOrdersService";
import { useDispatch } from "react-redux";
import FilterModal from "./modals/FilterModal";
import FilteredDataTable from "./FilteredDataTable";
import TransferOrderDataTable from "./TransferOrderDataTable";
import DraftDataTable from "./DraftDataTable";
import PendeingDataTable from "./PendeingDataTable";
import ClosedDataTable from "./ClosedDataTable";

const TransfersOrdersTable = ({ data , filteredData }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedTable, setSelectedTable] = useState("all");

  // Show Filter Modal State
  const [showFilterModal, setShowFilterModal] = useState(false);

  const resetFunction = () => {
      dispatch(resetTransfereOrdersFilteredData());
    };

  const closedData = data?.filter((item)=>{
      return   item.TransferOrdersStatusName === "Closed"
  })
  const draftData = data?.filter((item)=>{
      return   item.TransferOrdersStatusName === "Draft"
  })
  const pendingData = data?.filter((item)=>{
      return   item.TransferOrdersStatusName === "Pending"
  })

  
  const closedFilteredData = filteredData?.filter((item)=>{
      return   item.TransferOrdersStatusName === "Closed"
  })
  const draftFilteredData = filteredData?.filter((item)=>{
      return   item.TransferOrdersStatusName === "Draft"
  })
  const pendingFilteredData = filteredData?.filter((item)=>{
      return   item.TransferOrdersStatusName === "Pending"
  })

return <>
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            borderRadius={0}
            onClick={() => setSelectedTable("all")}
            color={selectedTable === "all" ? "primary" :"none"}  
            borderBottom={selectedTable === "all" ? "2px solid" :"none"}   
            borderColor={selectedTable === "all" ? "primary" :"none"} 
          />
          <GButton
            title={t("pages.transfers_orders.draft")}
            bg="none"
            borderRadius={0}
            onClick={() => setSelectedTable("draft")}
            color={selectedTable === "draft" ? "primary" :"none"}  
            borderBottom={selectedTable === "draft" ? "2px solid" :"none"}   
            borderColor={selectedTable === "draft" ? "primary" :"none"} 
          />
          <GButton
            title={t("pages.transfers_orders.pending")}
            bg="none"
            borderRadius={0}
            onClick={() => setSelectedTable("pending")}
            color={selectedTable === "pending" ? "primary" :"none"}  
            borderBottom={selectedTable === "pending" ? "2px solid" :"none"}   
            borderColor={selectedTable === "pending" ? "primary" :"none"} 
          />
          <GButton
            title={t("pages.transfers_orders.closed")}
            bg="none"
            borderRadius={0}
            onClick={() => setSelectedTable("closed")}
            color={selectedTable === "closed" ? "primary" :"none"}  
            borderBottom={selectedTable === "closed" ? "2px solid" :"none"}   
            borderColor={selectedTable === "closed" ? "primary" :"none"} 
          />
        </Flex>

        <GButton
              title={
                  filteredData?.length === 0
                  ? t("general.filter")
                  : t("general.clear_filter")
              }
              border="1px solid"
              borderColor="border"
              postfixElement={
                  filteredData?.length === 0 ? (
                  <FiIcons.FiFilter size={16} />
                  ) : (
                  <IoCloseOutline fontWeight={800} size={20} />
                  )
              }
              hover={{ bg: "primary", borderColor: "primary", color: "light" }}
              onClick={
                  filteredData?.length === 0
                  ? () => setShowFilterModal(true)
                  : resetFunction
              }
          />
      </Flex>

      {
          data?.length > 0 ? 
          <>
          {
              filteredData?.length > 0 ? 
              <FilteredDataTable 
                  data={ 
                      selectedTable === "all" ? filteredData 
                      : selectedTable === "draft" ? draftFilteredData 
                      : selectedTable === "pending" ? pendingFilteredData 
                      : closedFilteredData 
                  } 
              />
              : selectedTable === "all" ?
              <TransferOrderDataTable data={data} />
              : selectedTable === "draft" ? 
              <DraftDataTable data={draftData} />
              : selectedTable === "pending" ? 
              <PendeingDataTable data={pendingData} />
              : 
              <ClosedDataTable data={closedData} />
          }
          </>
          :
          <Flex
              p={4}
              bg="white"
              alignItems="center"
              justifyContent="center"
              color="text"
              textTransform="capitalize"
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
              borderRadius={6}
              >
              {t("pages.branches.no_data")}
          </Flex>
      }



    </Box>
      {
          showFilterModal && (
              <FilterModal 
                  onClose={()=>setShowFilterModal(false)} 
                  data={data} 
              />
          )
      }
    </>;
};

export default TransfersOrdersTable;
