import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import EditWasteModal from './modals/EditWasteModal';

const IngredientsTable = ({ItemTagsData}) => {
    const { t } = useTranslation();

    const [showWasteModal, setShowWasteModal] = useState(false)

    const dataAll= {NetQty: ItemTagsData[0].InventoryItem[0].NetQty,
                    YieId: ItemTagsData[0].InventoryItem[0].YieId,
                    InventoryItemNameEng: ItemTagsData[0].InventoryItem[0].InventoryItemNameEng,
                    SUK: ItemTagsData[0].InventoryItem[0].SUK,
                    ItemsIngredientId: ItemTagsData[0].ItemsIngredientId}
  return (
    <Box
    bg="light" overflow="auto"
    borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >
    <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.branches.name")}</Th>
          <Th>SKU</Th>
          <Th>{t("pages.ingrediants.cost_per_unit")}</Th>
          <Th>{t("pages.ingrediants.net_qty")}</Th>
          <Th>{t("pages.ingrediants.yield")}</Th>
          <Th>{t("pages.ingrediants.gross_qty")}</Th>
          <Th>{t("pages.ingrediants.waste_qty")}</Th>
          <Th>{t("pages.ingrediants.actual_cost")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {ItemTagsData?.map((el, index) => (
          <Tr key={el.ItemsIngredientId} onClick={()=> setShowWasteModal(true) } cursor={"pointer"}>
            <Td>{index + 1}</Td>
            <Td>{el?.InventoryItem[0].InventoryItemNameEng}</Td>
            <Td>{el?.InventoryItem[0].SUK}</Td>
            <Td>{el?.InventoryItem[0].CostPerStorageUnit}</Td>
            <Td>{el?.InventoryItem[0].NetQty}</Td>
            <Td>{el?.InventoryItem[0].YieId}</Td>
            <Td>{el?.InventoryItem[0].GrossQty}</Td>
            <Td>{el?.InventoryItem[0].WestQty}</Td>
            <Td>{el?.InventoryItem[0].ActualCost}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
    {showWasteModal && (
      <EditWasteModal
      onClose={() => setShowWasteModal(false)}
      data={dataAll}
      />
    )}
  </Box>
  );
}

export default IngredientsTable;