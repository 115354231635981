import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Icon,
  List,
  ListItem,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  Button,
  Flex,
  Spinner,
  SimpleGrid,
  Checkbox,
  Input as ChInput,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Input from "components/form/Input";
import { Controller, useForm } from "react-hook-form";
import DaliyRestrectionsTable from "../components/DaliyRestrectionsTable";
import DailyRestrictionDataTable from "features/general_accounts/DailyRestrictions/components/DailyRestrictionDataTable";
import SearchDailyRestrictionModal from "features/general_accounts/DailyRestrictions/components/modal/SearchDailyRestrictionModal";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetFinancialPeriodsDailyRestriction,
  addInDailyRestriction,
  getAccountsLookUp,
} from "features/general_accounts/service/accountsTreeService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getAcoountCycleNew } from "features/general_accounts/AccountAdd/service/AccountAddService";
import { filterObjectsByValue } from "utils/DynamicFilter";
import { formatDate } from "utils/FormatDate";
import { groupByNumber } from "utils/Utils_ForAccounting/GroupByNumber";
import { GetAccByFinancialId } from "../service/DailyRestrictoionsServers";
import { MdOutlineChangeCircle } from "react-icons/md";
import { checkAccountNumber } from "utils/Utils_ForAccounting/checkAccountNumber";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import { checkIdExists } from "utils/cheackAuth";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const DailyRestrictionsPage = () => {
  const retrievedArray = useDecryptedPrivilege();
  const ManageBranches = checkIdExists(retrievedArray, 1019);
  const { t, i18n } = useTranslation();

  const { handleSubmit, control, getValues, watch, setValue, register, reset } =
    useForm();

  const [SearchModal, setShowSearchModal] = useState(false);

  const [addModal, setaddModal] = useState(false);

  const dataId = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountsLookUp());
  }, [dispatch]);

  useEffect(() => {
    const dataSend = { DateFrom: "0" };
    dispatch(getAcoountCycleNew(dataSend));
  }, [dispatch]);

  const data = useSelector((state) => state.AccountTree);
  const AccountCycleData = useSelector((state) => state.AccountAdd);
  // console.log(AccountCycleData.accountCycle);

  const filteredArray = filterObjectsByValue(
    AccountCycleData.accountCycle,
    "IsClosed",
    false
  );

  watch(["AccountNumber-1"]);
  watch(["AccountNumber-2"]);

  watch(["AccountNumber-3"]);
  watch(["AccountNumber-4"]);
  watch(["AccountNumber-5"]);
  watch(["AccountNumber-6"]);
  //console.log(data)

  function filterObjectByKeyword(object, keyword) {
    const filteredObject = {};

    for (const key in object) {
      if (key.includes(keyword)) {
        filteredObject[key] = object[key];
      }
    }

    return filteredObject;
  }

  function sumObjectValues(obj) {
    let sum = 0;
    for (const key in obj) {
      if (!isNaN(parseFloat(obj[key]))) {
        sum += parseFloat(obj[key]);
      }
    }
    return sum;
  }

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const [inputValuesdebtor, setInputValuesdebtor] = useState({});

  const handleChange = (id, value) => {
    setInputValuesdebtor((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));
    //handleChange2(id,0)
    //console.log(sumObjectValues(inputValues))
  };

  const [inputResultdebtor, setinputResultdebtor] = useState({});

  const handleResult = (id, value) => {
    setinputResultdebtor((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));
    //handleChange2(id,0)
    //console.log(sumObjectValues(inputValues))
  };

  const [inputValuescreditor, setInputValuescreditor] = useState({});

  const handleChange2 = (id, value) => {
    setInputValuescreditor((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));
    // handleChange(id,0)
    //console.log(sumObjectValues(inputValues))
  };

  const [inputResultcreditor, setinputResultcreditor] = useState({});

  const handleResult2 = (id, value) => {
    setinputResultcreditor((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));
    // handleChange(id,0)
    //console.log(sumObjectValues(inputValues))
  };

  const [count, setCount] = useState(0);

  //test

  const [multiCount, setMultiCount] = useState(0);

  const countnumber = () => {
    setMultiCount(multiCount + 1);
  };

  useEffect(() => {
    //console.log(multiCount)
    //console.log(inputValuesdebtor)
    //console.log(inputValuescreditor)
    //console.log("deptor",inputResultdebtor)
    //console.log("creditor",inputResultcreditor)
    //console.log(sumObjectValues(inputValuesdebtor))
    //console.log(sumObjectValues(inputValuescreditor))
    const count = Object.keys(inputValuesdebtor).length;
    setCount(count);
  }, [
    inputValuesdebtor,
    inputValuescreditor,
    inputResultdebtor,
    inputResultcreditor,
    multiCount,
  ]);

  function searchById(obj, id) {
    return obj.hasOwnProperty(id);
  }

  function getCurrentTimestamp() {
    // Create a new Date object
    const currentDate = new Date();

    // Use toISOString to get the timestamp in the desired format
    const formattedTimestamp = currentDate.toISOString();

    return formattedTimestamp;
  }

  function getTimestamp() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function convertDateFormat(inputDate) {
    // Split the input date into an array of [year, month, day]
    const dateComponents = inputDate.split("-");

    // Create a new date using the components
    const dateObject = new Date(
      dateComponents[0],
      dateComponents[1] - 1,
      dateComponents[2]
    );

    // Extract the year, month, and day from the date object
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");

    // Concatenate the components in the desired format
    const formattedDate = `${year}${month}${day}`;

    return formattedDate;
  }

  const [manualNumber, setmanualNumber] = useState();
  const [pageId, setPageId] = useState();

  //console.log(pageId)

  function transformArray(originalArray) {
    return originalArray.map((item) => {
      const newItem = {
        JournalDetailID: null,
        JournalHeaderID: null,
        RecordTimeStamp: getCurrentTimestamp(),
        VoucherTypeId: 5,
        Field_1: convertDateFormat(getTimestamp()),
        Field_2: manualNumber,
        OperatingUserID: 1,
        PageID: pageId,

        CurrencyCode: "",
        CurrencyRate: 1,
        Notes: "",
        ReferenceNumber: "",
        DescriptionAccountCode: "",
        DescriptionID: 0,
        SpecialRecordTrackID: 0,
      }; // Adding the new key-value pair

      // Iterate over the keys of the inner 'items' object
      for (const key in item.items) {
        if (Object.prototype.hasOwnProperty.call(item.items, key)) {
          // Exclude keys 'value' and 'label'
          if (key === "value" || key === "label") {
            continue;
          }

          // Extract the prefix (e.g., 'creditor', 'debtor', 'description', 'AccountNumber')
          const prefix = key.split("-")[0];

          // Remove the '-1' suffix and update the key in the new object
          const newKey = key.replace(/-\d+$/, "");

          // Check if the key is 'AccountNumber'; if yes, include all keys and values
          if (prefix === "AccountNumber") {
            Object.assign(newItem, { ...item.items[key] });
            newItem.Field_3 = newItem.AccountNumber;
            newItem.DebitAmountInCurrency = newItem.Debit;
            newItem.CreditAmountInCurrency = newItem.Credit;
            newItem.AccountNameAr = newItem.AccountName;
          } else {
            // Otherwise, include the key-value pair in the new object
            newItem[newKey] = item.items[key];
          }
          //console.log("inside",newItem)
        }
      }

      return newItem;
    });
  }

  function isValidJson(jsonString) {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }

  // Example usage:

  const [DisabelState, SetDisabledState] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  useEffect(() => {
    if (selectedPeriod === null) {
      console.log("no fitch");
    } else {
      dispatch(GetAccByFinancialId(selectedPeriod?.FinancialPeriodsId));
    }
  }, [dispatch, selectedPeriod]);

  const AccountData = useSelector((state) => state.DailyRestrictions);

  // console.log(AccountData?.data);

  const [filterByName, setFilterByName] = useState(true);

  // Example usage:

  if (ManageBranches === false) {
    return <NotAuthorizedCompo />;
  }

  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.DailyRestrictions.Daily_Restrictions")}
          </Text>

          <Flex gap={2}>
            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => {
                if (selectedPeriod) {
                  setaddModal(!addModal);
                  SetDisabledState(!DisabelState);
                  // console.log(selectedPeriod?.FinancialPeriodsId);
                  // dispatch(
                  //   GetAccByFinancialId(selectedPeriod?.FinancialPeriodsId)
                  // );
                } else {
                  // console.log("select first ")
                  toast({
                    description: t(
                      "pages.DailyRestrictions.select_finacial_period"
                    ),
                  });
                }
              }}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.general_accounts.add")}
              </Flex>
            </Button>

            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => {
                setaddModal(false);
                setShowSearchModal(true);
                SetDisabledState(true);
              }}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.DailyRestrictions.show")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>

      <form
        onSubmit={handleSubmit((values) => {
          const filteredValues = Object.fromEntries(
            Object.entries(values).filter(
              ([key, value]) => value !== undefined && value !== ""
            )
          );

          //first stage
          const resultArray = groupByNumber(filteredValues);

          //second stage
          const newArray = transformArray(resultArray);

          //thired stage

          if (values.FinancialPeriodsId === undefined) {
            toast({ description: "select finacial period first" });
          } else {
          }

          var lastData = {
            DebitTotal: sumObjectValues(inputValuesdebtor),
            CreditTotal: sumObjectValues(inputValuescreditor),
            TransactionDate: getTimestamp(),
            UserId: 1,
            IsJournalBalance: true,
            JournalHeaderID: null,
            FinancialPeriodsId: values.FinancialPeriodsId?.FinancialPeriodsId,
            JournalDescription: values.JournalDescription,
            OperationNo: values.OperationNo,
            ManualNumber: values.OperationNo,
            BookNo: values.BookNo,
            VoucherTypeId: 5,
            JournalDetail: newArray,
          };

          // console.log("last", lastData);

          //const newData=getFilteredObject(values);

          const creditorFilter = filterObjectByKeyword(filteredValues, "Debit");
          const debtorfilter = filterObjectByKeyword(filteredValues, "Credit");

          //console.log(debtorfilter)

          const sumdebtor = sumObjectValues(debtorfilter);
          const sumCreditor = sumObjectValues(creditorFilter);

          //console.log(sumdebtor)
          //console.log(sumCreditor)

          const testkarim = {
            JournalHeader: [
              {
                JournalHeaderID: null,
                TransactionDate: "2023-11-13",
                ManualNumber: "202301",
                BookNo: "202003",
                OperationNo: "202301",
                UserId: 3,
                JournalDescription: "xxx",
                DebitTotal: 5070,
                CreditTotal: 5070,
                IsJournalBalance: true,
                VoucherTypeId: 5,
                FinancialPeriodsId: 2,
                JournalDetail: [
                  {
                    JournalDetailID: null,
                    Row_Number: 1,
                    JournalHeaderID: null,
                    AccountChartId: 385,
                    AccountNumber: "030104",
                    Debit: 4408.69,
                    Credit: 0,
                    Row_Description: "شراء كمبيوتر + طابعة ابسون",
                    Field_1: "20230101",
                    Field_2: "202301",
                    Field_3: "030104",
                    Notes: "",
                    ReferenceNumber: "",
                    VoucherTypeId: 5,
                    CurrencyCode: "",
                    CurrencyRate: 1,
                    DebitAmountInCurrency: 4408.69,
                    CreditAmountInCurrency: 0,
                    PageID: 202003,
                    DescriptionID: 0,
                    SpecialRecordTrackID: 0,
                    DescriptionAccountCode: "",
                    OperatingUserID: 3,
                    RecordTimeStamp: "2023-01-02T13:02:12",
                    AccountNameAr: " مشروعات تحت التنفيذ  شركة تارتين ",
                  },
                  {
                    JournalDetailID: null,
                    Row_Number: 2,
                    JournalHeaderID: null,
                    AccountChartId: 56,
                    AccountNumber: "0102060001",
                    Debit: 661.31,
                    Credit: 0,
                    Row_Description: "جرير302126455700003-1139",
                    Field_1: "20230101",
                    Field_2: "202301",
                    Field_3: "0102060001",
                    Notes: "",
                    ReferenceNumber: "",
                    VoucherTypeId: 5,
                    CurrencyCode: "",
                    CurrencyRate: 1,
                    DebitAmountInCurrency: 661.31,
                    CreditAmountInCurrency: 0,
                    PageID: 202003,
                    DescriptionID: 0,
                    SpecialRecordTrackID: 0,
                    DescriptionAccountCode: "",
                    OperatingUserID: 3,
                    RecordTimeStamp: "2023-01-02T13:02:12.297",
                    AccountNameAr: "ضريبة القيمة المضافة علي المشتريات ",
                  },
                  {
                    JournalDetailID: null,
                    Row_Number: 3,
                    JournalHeaderID: null,
                    AccountChartId: 35,
                    AccountNumber: "0102020001",
                    Debit: 0,
                    Credit: 70,
                    Row_Description: "سداد باقي فاتورة جرجير كمبيوتر وطابعة",
                    Field_1: "20230101",
                    Field_2: "202301",
                    Field_3: "0102020001",
                    Notes: "",
                    ReferenceNumber: "",
                    VoucherTypeId: 5,
                    CurrencyCode: "",
                    CurrencyRate: 1,
                    DebitAmountInCurrency: 0,
                    CreditAmountInCurrency: 70,
                    PageID: 202003,
                    DescriptionID: 0,
                    SpecialRecordTrackID: 0,
                    DescriptionAccountCode: "",
                    OperatingUserID: 3,
                    RecordTimeStamp: "2023-01-02T13:02:12.297",
                    AccountNameAr: "الخزينة الرئيسة ",
                  },
                  {
                    JournalDetailID: null,
                    Row_Number: 4,
                    JournalHeaderID: null,
                    AccountChartId: 25,
                    AccountNumber: "0102010001",
                    Debit: 0,
                    Credit: 5000,
                    Row_Description: "سداد حق كمبيوتر وطابعة تارتين",
                    Field_1: "20230101",
                    Field_2: "202301",
                    Field_3: "0102010001",
                    Notes: "",
                    ReferenceNumber: "",
                    VoucherTypeId: 5,
                    CurrencyCode: "",
                    CurrencyRate: 1,
                    DebitAmountInCurrency: 0,
                    CreditAmountInCurrency: 5000,
                    PageID: 202003,
                    DescriptionID: 0,
                    SpecialRecordTrackID: 0,
                    DescriptionAccountCode: "",
                    OperatingUserID: 3,
                    RecordTimeStamp: "2023-01-02T13:02:12.297",
                    AccountNameAr: "بنك الراجحي",
                  },
                ],
              },
            ],
          };

          //console.log(testkarim)

          if (sumdebtor - sumCreditor === 0) {
            console.log("zero");
            if (isValidJson(JSON.stringify(lastData))) {
              console.log("The JSON is valid.");
            } else {
              console.log("The JSON is not valid.");
            }
            //console.log({"JournalHeader":[lastData]})
            const test = { JournalHeader: lastData };

            if (
              test?.JournalHeader?.BookNo === undefined ||
              test?.JournalHeader?.OperationNo === undefined ||
              test?.JournalHeader?.ManualNumber === undefined ||
              test?.JournalHeader?.JournalDetail.length < 1 ||
              checkAccountNumber(test?.JournalHeader?.JournalDetail) // Will return true
            ) {
              toast({
                description: t("pages.DailyRestrictions.searchvalidation"),
              });
            } else {
              console.log({ JournalHeader: lastData });
              dispatch(addInDailyRestriction({ JournalHeader: lastData })).then(
                (_) => {
                  toast2({ description: "item created" });
                  reset();
                }
              );
              // console.log(test.JournalHeader.JournalDetail); // Output: 1
            }
          } else {
            toast({
              description: t(
                "pages.DailyRestrictions.creditor_is_not_equal_debtor"
              ),
            });
          }

          //console.log(newData)
        })}
      >
        <Box
          bg="light"
          marginTop={6}
          padding={5}
          borderTop="1px solid"
          borderColor="border"
          marginInline={0}
          borderRadius={5}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <SimpleGrid
              spacing={1}
              columns={{ base: 1, md: 2 }}
              bg="white"
              borderRadius={6}
              m={5}
              columnGap={20}
              rowGap={5}
              width={"100%"}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "120px",
                  }}
                >
                  {t("pages.DailyRestrictions.note_Number")}
                </label>

                {DisabelState ? (
                  <Controller
                    name="BookNo1" // Provide a unique name for your textarea
                    control={control}
                    render={({ field }) => (
                      <ChInput
                        {...field}
                        style={{
                          width: "100%",
                          backgroundColor: "gray",
                          color: "black",
                        }}
                        disabled={DisabelState}
                        value={data.searchData[0]?.BookNo}
                        onChange={(e) => {
                          field.onChange(e); // This is important to update the form state
                          console.log("Field value changed:", e.target.value);
                          setPageId(e.target.value);
                        }}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name="BookNo" // Provide a unique name for your textarea
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <ChInput
                        {...field}
                        style={{ width: "100%" }}
                        disabled={DisabelState}
                        onChange={(e) => {
                          field.onChange(e); // This is important to update the form state
                          console.log("Field value changed:", e.target.value);
                          setPageId(e.target.value);
                        }}
                      />
                    )}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "120px",
                  }}
                >
                  {t("pages.DailyRestrictions.Operations_numbers")}
                </label>

                {DisabelState ? (
                  <Controller
                    name="OperationNo1" // Provide a unique name for your textarea
                    control={control}
                    render={({ field }) => (
                      <ChInput
                        {...field}
                        style={{ width: "100%", backgroundColor: "gray" }}
                        disabled={DisabelState}
                        value={data.searchData[0]?.TransactionNumber}
                        onChange={(e) => {
                          field.onChange(e); // This is important to update the form state
                          console.log("Field value changed:", e.target.value);
                          setmanualNumber(e.target.value);
                        }}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name="OperationNo" // Provide a unique name for your textarea
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <ChInput
                        {...field}
                        style={{ width: "100%" }}
                        disabled={DisabelState}
                        onChange={(e) => {
                          field.onChange(e); // This is important to update the form state
                          console.log("Field value changed:", e.target.value);
                          setmanualNumber(e.target.value);
                        }}
                      />
                    )}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "120px",
                  }}
                >
                  {t("pages.DailyRestrictions.registrations_Number")}
                </label>
                <ChInput style={{ width: "100%" }} value={multiCount}></ChInput>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "120px",
                  }}
                >
                  {t("pages.DailyRestrictions.dataofFile")}
                </label>
                <ChInput
                  style={{ width: "100%", cursor: "not-allowed" }}
                  value={
                    DisabelState
                      ? data.searchData[0]?.TransactionDate
                      : getTimestamp()
                  }
                ></ChInput>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "120px",
                  }}
                >
                  {t("pages.DailyRestrictions.Total_debit_side")}
                </label>
                <ChInput
                  style={{ width: "100%" }}
                  value={sumObjectValues(inputValuesdebtor)}
                ></ChInput>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "120px",
                  }}
                >
                  {t("pages.DailyRestrictions.Total_Creditor_side")}
                </label>
                <ChInput
                  style={{ width: "100%" }}
                  value={sumObjectValues(inputValuescreditor)}
                ></ChInput>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  gap: "50px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "120px",
                  }}
                >
                  {t("pages.DailyRestrictions.finacial_period")}
                </label>
                <div style={{ width: "100%" }}>
                  <MultiSelect
                    data={
                      filteredArray &&
                      filteredArray.map((el) => ({
                        ...el,
                        label: ` ${t(
                          "pages.AccountAdd.date_From"
                        )} : ${formatDate(el.DateFrom)} ${t(
                          "pages.AccountAdd.date_to"
                        )} ${formatDate(el.DateTo)}`,
                        value: el.FinancialPeriodsId,
                      }))
                    }
                    isMulti={false}
                    onChange={(e) => {
                      setValue("FinancialPeriodsId", e);
                      setSelectedPeriod(e);
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <label
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "120px",
                  }}
                >
                  {t("pages.DailyRestrictions.discription")}
                </label>

                <Controller
                  name="JournalDescription" // Provide a unique name for your textarea
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <textarea
                      {...field}
                      style={{
                        border: "2px solid black",
                        borderRadius: "10px",
                        padding: "10px",
                        width: "100%",
                      }}
                      disabled={DisabelState}
                    />
                  )}
                />
              </div>

              {/*   <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  width: "120px",
                }}
              >
                <Checkbox>
                  {t("pages.DailyRestrictions.reviresd_restriction")}
                </Checkbox>
              </div>*/}
            </SimpleGrid>
          </Flex>

          {addModal && (
            <>
              <Box
                bg="light"
                overflow="auto"
                borderRadius={4}
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
              >
                <Table>
                  <Thead>
                    <Tr>
                      <Th style={{ minWidth: "250px" }}>
                        {filterByName
                          ? t("pages.DailyRestrictions.account_name")
                          : t("pages.DailyRestrictions.account_number")}
                      </Th>
                      <Th style={{ minWidth: "250px" }}>
                        {filterByName
                          ? t("pages.DailyRestrictions.account_number")
                          : t("pages.DailyRestrictions.account_name")}
                      </Th>
                      <Th style={{ minWidth: "250px" }}>
                        {t("pages.DailyRestrictions.debtor")}
                      </Th>
                      <Th style={{ minWidth: "250px" }}>
                        {t("pages.DailyRestrictions.Creditor")}
                      </Th>
                      <Th style={{ minWidth: "250px" }}>
                        {t("pages.DailyRestrictions.Description")}
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {dataId.map((el) => (
                      <Tr style={{ cursor: "pointer" }} key={el.id}>
                        <Td
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            gap: "15px",
                          }}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setFilterByName(!filterByName)}
                          >
                            <MdOutlineChangeCircle color="green" size={25} />
                          </div>
                          <MultiSelect
                            data={
                              AccountData?.data &&
                              AccountData?.data?.map((item) => ({
                                ...item,
                                label: filterByName
                                  ? item.AccountName
                                  : item.AccountNumber,
                                value: item.AccountNumber,
                              }))
                            }
                            isMulti={false}
                            onChange={(e) => {
                              // Assuming el is defined somewhere in your component
                              setValue(`AccountNumber-${el.id}`, e);
                              //console.log("hi");
                              countnumber();
                            }}
                          />
                        </Td>

                        <Td>
                          <ChInput
                            readOnly
                            value={
                              getValues(
                                filterByName
                                  ? `AccountNumber-${el.id}.AccountNumber`
                                  : `AccountNumber-${el.id}.AccountName`
                              ) || ""
                            }
                          />
                        </Td>

                        <Td>
                          <Controller
                            name={`Debit-${el.id}`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <ChInput
                                {...field}
                                type="number"
                                onChange={(e) => {
                                  const inputValue = parseFloat(e.target.value);
                                  field.onChange(e); // This line ensures the default behavior is preserved
                                  handleChange(el.id, e.target.value);
                                  //handleChange2(el.id,0)
                                  const result = searchById(
                                    inputValuescreditor,
                                    el.id
                                  );
                                  //console.log(result)
                                  handleResult(el.id, result);
                                  //console.log("hi") // Your custom onChange logic here
                                  if (inputValue > 0) {
                                    // Set the value of the second input to zero
                                    setValue(`Credit-${el.id}`, 0); // Assuming setValue is provided by your form library
                                  }
                                }}
                              />
                            )}
                          />
                        </Td>

                        <Td>
                          <Controller
                            name={`Credit-${el.id}`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <ChInput
                                {...field}
                                type="number"
                                onChange={(e) => {
                                  const inputValue = parseFloat(e.target.value);
                                  field.onChange(e); // This line ensures the default behavior is preserved
                                  handleChange2(el.id, e.target.value);
                                  //handleChange(el.id,0)
                                  // Your custom onChange logic here
                                  const result = searchById(
                                    inputValuesdebtor,
                                    el.id
                                  );
                                  handleResult2(el.id, result);
                                  // console.log(result)
                                  if (inputValue > 0) {
                                    // Set the value of the second input to zero
                                    setValue(`Debit-${el.id}`, 0); // Assuming setValue is provided by your form library
                                  }
                                }}
                              />
                            )}
                          />
                        </Td>

                        <Td>
                          <Input
                            labelStyle={{ textTransform: "capitalize" }}
                            name={`Row_Description-${el.id}`}
                            isFormController
                            type="text"
                            control={control}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Flex gap={2} m={5}>
                <Button
                  type="submit"
                  bg="primary"
                  color="light"
                  textTransform="capitalize"
                  fontSize="1rem"
                  //onClick={() => setShowModal(true)}
                >
                  <Flex alignItems="center" gap={1}>
                    {t("pages.general_accounts.add")}
                  </Flex>
                </Button>
              </Flex>
            </>
          )}
        </Box>
      </form>

      {data.searchData.length > 0 && addModal === false ? (
        <DailyRestrictionDataTable data={data.searchData} />
      ) : null}

      {SearchModal && (
        <SearchDailyRestrictionModal
          onClose={() => setShowSearchModal(false)}
        />
      )}
    </>
  );
};

export default DailyRestrictionsPage;
