import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";




  /* ------------------------------------------------- subGroup DropDown List ------------------------------------------------ */
export const subGroupDropList = createAsyncThunk( "subGroups/subGroupDropList", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Group/LookupMenuGroup`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ------------------------------------------------- Get SubGroup List ------------------------------------------------ */
  export const subGroupList = createAsyncThunk("subGroups/subGroupList",  async (args, thunkApi) => {
      try {
        // const { data } = await axios.get(`/Menu/Group/SubGroupList?groupid={${args}}`)
        const { data } = await axios.get(`/Menu/Group/SubGroupList?groupid={"MenuGroupId":${args}}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ------------------------------------------------- Add SubGroup To Groups ------------------------------------------------ */
  export const updateGroupParent = createAsyncThunk(  "subGroups/updateGroupParent", async (args, thunkApi) => {
      try {
        
        const { data } = await axios.get(`Menu/Group/UpdateGroupParent?menugroup=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );