import React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text, useToast } from "@chakra-ui/react"
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import ExportModal from 'components/ui/modal/ExportModal';
import TransfersTable from '../components/TransfersTable';
import CreateTransferModal from '../Modal/CreateTransferModal';
import { getTransfersList } from '../services/transfersServices';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

export default function Transfers() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const transfersData = useSelector(state => state.inventoryTransfers);

  const [showCreateTransferModal, setShowCreateTransferModal] = useState(false);
  const [exportModal,SetExportModal]=useState(false);

  useEffect(() => {
    dispatch(getTransfersList());
  }, [dispatch]);
  /* -----------------------------------Privilige-------------------------------*/  
  const retrievedArray =useDecryptedPrivilege();
  const ManageTransferOrders = checkIdExists(retrievedArray, 19);

  if(showCreateTransferModal === true && ManageTransferOrders === false){
    toast2({ description:  t("general.authorization")});
    setShowCreateTransferModal(false);
  };
/* -----------------------------------Privilige-------------------------------*/  
  return <>

  <BreadCrumb>
      <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize" >
            {t("pages.transfers.transfers")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
                <Button
                  type="button" bg="none" border="1px solid" borderColor="border"
                  textTransform="capitalize" fontSize="1rem" color="text"
                  _hover={{ bg: "primary", color: "light" }}
                  onClick={() => SetExportModal(true)}
                >
                    <Text>{t("general.export")}</Text>
                </Button>

                <GButton
                  title={t("pages.transfers.new_transfers")}
                  onClick={() => setShowCreateTransferModal(true)}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ bg: "none", borderColor: "primary", color: "primary" }}
                />
          </Flex>
      </Flex>
  </BreadCrumb>

  <TransfersTable  data={transfersData.data}  filteredData={transfersData.filteredData} />


  
  { showCreateTransferModal && 
    <>
        <CreateTransferModal 
            onClose={()=>setShowCreateTransferModal(false)}
        > </CreateTransferModal>
    </>
  }

  {exportModal&&(
      <ExportModal 
        onClose={() => SetExportModal(false)}   
        data={transfersData?.data}
        fileName={"Transfers"}
        >
      </ExportModal>
  )}
  
  </>
}