import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";



export const getCustomPriceModifierOptionById = createAsyncThunk(
    "modifiersOptionscustom/getCustomPriceModifierOptionById",
    async (args, thunkApi) => {
      
      //console.log(args)
      
      //args=1
  
      try {
        const { data } = await axios.get(`/Menu/ModiferOption/ModiferOptionCustomPriceListById?modiferoptionid=${JSON.stringify({ ModiferOptionId: args })}`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const addOutOfStockbranche= createAsyncThunk(
    "modifiersOptionscustom/addOutOfStockbranche",
    async (args, thunkApi) => {
      try {

     
        const { data } = await axios.get(`/Menu/ModiferOption/AddModiferOptionOutOfStock?modiferoptionOutofstock=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  

  export const getOutOfStock = createAsyncThunk(
    "modifiersOptionscustom/getOutOfStock",
    async (args, thunkApi) => {
      
      //console.log(args)
      
      //args=1
  
      try {
        const { data } = await axios.get(`/Menu/ModiferOption/ModiferOptionOutofstockOutOfStockListById?modiferoptionid=${JSON.stringify({ ModiferOptionId: args })}`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const addDeactiveBranchModifier= createAsyncThunk(
    "modifiersOptionscustom/addDeactiveBranchModifier",
    async (args, thunkApi) => {
      try {

     
        const { data } = await axios.get(`/Menu/ModiferOption/AddModiferOptionInActiveBranch?modiferoptioninactivebranch=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getDeactiveBranchesModififers = createAsyncThunk(
    "modifiersOptionscustom/getDeactiveBranchesModififers",
    async (args, thunkApi) => {
      
      //console.log(args)
      
      //args=1
  
      try {
        const { data } = await axios.get(`/Menu/ModiferOption/InActiveBranchById?modiferoptionid=${JSON.stringify({ ModiferOptionId: args })}`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );