import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { PiDotsThreeCircleVerticalDuotone } from "react-icons/pi";

const LinkingModifiersTable = ({ data }) => {
  const { t } = useTranslation();
  
  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.branches.name")}</Th>
          <Th>{t("pages.products.min_options")}</Th>
          <Th>{t("pages.products.max_options")}</Th>
          <Th>{t("pages.products.free_options")}</Th>
        </Tr>
        <Tbody>
          {data?.map((el, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>{el?.ModifersNameEng}</Td>
              <Td>{el?.MinSelectionsNo}</Td>
              <Td>{el?.MaxSelectionsNo}</Td>
              <Td>{el?.NoOfFreeOption}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default LinkingModifiersTable;
