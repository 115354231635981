import { useEffect, useState } from "react";
import { Flex, Stack, useToast } from "@chakra-ui/react"
import { axios } from "services/axios";
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";

const SmsProvider = () => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    handleSubmit
  } = useForm();
  const toast = useToast({ position: "top", status: "success", duration: 3000 });

  const [isLoading, setIsLoading] = useState(false);

  const getSmsProviderSettings = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get("/");
      if (typeof data === "object" && Array.isArray(data)) {
        const jsonData = JSON.parse(data[0]?.jsonData)[0] || {};
        reset(jsonData);
      }
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  }
  const updateSmsProviderSettings = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(`/Manage/Setting/SMSSettings/AddSettingSMS?smssettings=${JSON.stringify(values)}`);
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  }

  useEffect(() => {
    getSmsProviderSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit(values => {
          values.SMSProvidersSettingsId = null;
          updateSmsProviderSettings(values)
            .then(_ => toast({ description: t("pages.management.settings.settings_updated") }))
            .catch(_ => toast({ status: "error", description: "something went wrong!" }))
        })}
      >
        <Stack spacing={6} bg="light" p={4}>
          <Input
            label={t("pages.management.settings.sms_providers.sms_settings_user_name")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.management.settings.sms_providers.sms_settings_user_name")}
            isFormController
            control={control}
            name="SMSSettingsUserName"
          />

          <Input
            label={t("pages.management.settings.sms_providers.sms_settings_api")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.management.settings.sms_providers.sms_settings_api")}
            isFormController
            control={control}
            name="SMSSettingsApi"
          />

          <Input
            label={t("pages.management.settings.sms_providers.sender_name")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.management.settings.sms_providers.sender_name")}
            isFormController
            control={control}
            name="SenderName"
          />


          <Flex justifyContent="flex-end">
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              isDisabled={isLoading}
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Stack>
      </form>
    </>
  )
}

export default SmsProvider