import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import * as FiIcons from "react-icons/fi";
import { MdSettingsBackupRestore } from "react-icons/md";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({ data, handleUpdate, handleDelete , handleRestore }) => {
  const { t } = useTranslation();
    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
    const recordsPerPage = 12;
    const [currentPageActive, setCurrentPageActive] = useState(1);
    const prePage = (currentPage, setCurrentPage) => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const nextPage = (currentPage, setCurrentPage, npage) => {
      if (currentPage < npage) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const calculateRecords = (data, currentPage, recordsPerPage) => {
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
      return data?.slice(firstIndex, lastIndex);
    };
  
  // Calculate records for activeDiscounts
  const chargesData = calculateRecords(
      data,
      currentPageActive,
      recordsPerPage
    );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return <>
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6} mt={6}>
      {chargesData?.map(charge => (
        <Box key={charge.ChargeId}
          bg="light" p={6} borderRadius={4}
          boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
        >
          <Flex justifyContent="space-between">
            <Text textTransform="capitalize">{charge?.ChargeName || "-"}</Text>
            <Menu>
              <MenuButton as={Button}
                size="sm"
                bg="none"
                border="1px solid"
                borderColor="border"
                color="text"
                _hover={{ bg: "primary", borderColor: "primary", color: "light" }}
              >
                <Flex alignItems="center" gap={2}>
                  <FiIcons.FiMoreHorizontal size={18} />
                </Flex>
              </MenuButton>
              <MenuList p={0} minW="auto">
                <MenuItem onClick={() => handleUpdate(charge)}>
                  <Flex alignItems="center" justifyContent={"space-between"} width={"100%"} gap={2}>
                    <Text> {t("general.edit")} </Text>
                    <FiIcons.FiEdit color="#6B46C1" />
                  </Flex>
                </MenuItem>
                <MenuItem>
                  {
                    charge?.Isdeleted === true ? 
                    <Flex alignItems="center" justifyContent={"space-between"} width={"100%"} gap={2}
                        onClick={() => handleRestore(charge)}
                    >
                        <Text>  {t("general.restore")} </Text>
                        <MdSettingsBackupRestore color="#C53030" /> 
                    </Flex>
                    :
                    <Flex alignItems="center" justifyContent={"space-between"} width={"100%"} gap={2}
                        onClick={() => handleDelete(charge)}
                    >
                        <Text>  {t("general.delete")} </Text>
                        <FiIcons.FiTrash2 color="#C53030" /> 
                    </Flex>
                  }
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Text textTransform="capitalize" color="text">{charge?.Value || '-'} SAR</Text>
        </Box>
      ))}
    </SimpleGrid>
    <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(data?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(data?.length / recordsPerPage)
            )
          }
    />
  </>
}

export default CTable;