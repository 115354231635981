import React, { useEffect } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewEmployeeNationalIdInfo,
  getEmployeeList,
} from "features/hr/employees/services/employeeServices";

export default function AddNationalIdCompo({ setshowCompoNumber }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const data = useSelector((state) => state.employeeSlice);
  // console.log(data);

  /*-------------------------------------------- Form Handeling ------------------------------------------------*/
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
      setError("ParLevel", {
        type: "manual",
        message: t("validation.must_be_within_min_max_range"),
      });
    } else {
      clearErrors("ParLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };
  /*-------------------------------------------- Return ------------------------------------------------*/
  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.UserId = localStorage.getItem("userId");
          values.HiringNewEmployeeRequestId =
            data?.NewEmployeeId?.HiringNewEmployeeRequestId;
          values.Step = 2;
          values.StartDate = `${values.StartDate} 00:00:00`;
          values.EndDate = `${values.EndDate} 00:00:00`;
          values.PassportStartDate = `${values.PassportStartDate} 00:00:00`;
          values.PassportEndDate = `${values.PassportEndDate} 00:00:00`;

          if (
            values.StartDate === undefined ||
            values.EndDate === undefined ||
            values.PassportStartDate === undefined ||
            values.PassportEndDate === undefined
          ) {
            toast2({ description: t("validation.choose_first") });
          } else {
            dispatch(addNewEmployeeNationalIdInfo(values))
              .unwrap()
              .then((res) => {
                toast({
                  description: t("pages.hr.new_employee_nationality_Added"),
                });
                dispatch(getEmployeeList());
                setshowCompoNumber(3);
              });
          }
        })}
      >
        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Text
            color="dark"
            textTransform="capitalize"
            fontSize="xl"
            fontWeight={"bold"}
          >
            {t("pages.hr.National_id")}
          </Text>
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <FormControl isInvalid={errors.NationalID}>
              <FormLabel
                htmlFor="NationalID"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.National_id")}
              </FormLabel>
              <Controller
                name="NationalID"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 10,
                    message: t("pages.hr.hr_validation.number_short_10"),
                  },
                  maxLength: {
                    value: 15,
                    message: t("pages.hr.hr_validation.number_long"),
                  },
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.National_id")}
                    onChange={(e) => {
                      handleInputChange(e, "NationalID");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.NationalID && errors.NationalID.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.NationalIDPlace}>
              <FormLabel
                htmlFor="NationalIDPlace"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.extraction_place")}
              </FormLabel>
              <Controller
                name="NationalIDPlace"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.extraction_place")}
                    onChange={(e) => {
                      handleInputChange(e, "NationalIDPlace");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.NationalIDPlace && errors.NationalIDPlace.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.StartDate}>
              <FormLabel
                htmlFor="StartDate"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.extraction_start_date")}
              </FormLabel>
              <Controller
                name="StartDate"
                control={control}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.extraction_start_date")}
                    onChange={(e) => {
                      handleInputChange(e, "StartDate");
                    }}
                    type="date"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.StartDate && errors.StartDate.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.EndDate}>
              <FormLabel
                htmlFor="EndDate"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.extraction_end_date")}
              </FormLabel>
              <Controller
                name="EndDate"
                control={control}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.extraction_end_date")}
                    onChange={(e) => {
                      handleInputChange(e, "EndDate");
                    }}
                    type="date"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.EndDate && errors.EndDate.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Box>

        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Text
            color="dark"
            textTransform="capitalize"
            fontSize="xl"
            fontWeight={"bold"}
          >
            {t("pages.hr.passport_No")}
          </Text>
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <FormControl isInvalid={errors.PassportNo}>
              <FormLabel
                htmlFor="PassportNo"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.passport_No")}
              </FormLabel>
              <Controller
                name="PassportNo"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 10,
                    message: t("pages.hr.hr_validation.number_short_10"),
                  },
                  maxLength: {
                    value: 15,
                    message: t("pages.hr.hr_validation.number_long"),
                  },
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.passport_No")}
                    onChange={(e) => {
                      handleInputChange(e, "PassportNo");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.PassportNo && errors.PassportNo.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.PassportStartDate}>
              <FormLabel
                htmlFor="PassportStartDate"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.passport_start_date")}
              </FormLabel>
              <Controller
                name="PassportStartDate"
                control={control}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.passport_start_date")}
                    onChange={(e) => {
                      handleInputChange(e, "PassportStartDate");
                    }}
                    type="date"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.PassportStartDate && errors.PassportStartDate.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.PassportEndDate}>
              <FormLabel
                htmlFor="PassportEndDate"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.passport_end_date")}
              </FormLabel>
              <Controller
                name="PassportEndDate"
                control={control}
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.passport_end_date")}
                    onChange={(e) => {
                      handleInputChange(e, "PassportEndDate");
                    }}
                    type="date"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.PassportEndDate && errors.PassportEndDate.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Box>

        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Flex gap={2} flexWrap="wrap">
            <GButton
              title={t("pages.profile.save_changes")}
              color="light"
              border="1px solid transparent"
              bg="primary"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              type="submit"
            />
          </Flex>
        </Box>
      </form>
    </>
  );
}
