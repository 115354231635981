export function separateArrays(arrayOfObjects) {
    // Get the keys from the first object in the array
    const keys = Object.keys(arrayOfObjects[0]);

    // Initialize arrays to hold values for each key
    const valueArrays = keys.map(() => []);

    // Iterate over each object in the input array
    arrayOfObjects.forEach(obj => {
        // For each key, push the corresponding value to the respective array
        keys.forEach((key, index) => {
            valueArrays[index].push(obj[key]);
        });
    });

    // Return the arrays
    return valueArrays;
}