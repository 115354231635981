import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {Box,Checkbox, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { getPaymentMethods, updatePaymentMethod } from "../../services/paymentMethodsService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useEffect } from "react";
import { getPaymentMethodsTypes } from "../../services/paymentMethodsTypesService";


const UpdateModal = ({data,onClose}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({position: "top",duration: 3000, status: "error",});
  const dispatch = useDispatch();

  const paymentMethods = useSelector(state => state.paymentMethods);
  const paymentMethodsTypes = useSelector(state => state.paymentMethodsTypes);

  const { handleSubmit, control, setValue,  register,  formState: { errors },  trigger} = 
  useForm({
    defaultValues: {
      ...data,
      PaymentMethodCode: +data.PaymentMethodCode
    }
  });

  useEffect(() => {
    dispatch(getPaymentMethodsTypes());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return <>
    <GModal
      onClose={onClose}
      modalHeader={t("pages.payment_methods.update_payment_method")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.PaymentMethodTypeId = values.PaymentMethodTypeId?.PaymentMethodTypeId || null;
            values.PaymentMethodCode = +values.PaymentMethodCode;
            values.IsActive = true;

            if(values.PaymentMethodTypeId===null){
              toast2({ description: t("validation.choose_first")  });
            }else{
                   dispatch(updatePaymentMethod({ values }))
              .unwrap()
              .then(_ => {
                toast({ description: t("pages.payment_methods.payment_method_updated") });;
                dispatch(getPaymentMethods());
                onClose()
              }).then(dispatch(getPaymentMethods()));
            }
       
          })}>
            <Stack spacing={6}>
              <FormControl isInvalid={errors.PaymentMethodName}>
                  <FormLabel
                    htmlFor="PaymentMethodName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.payment_methods.name")}
                  </FormLabel>
                  <Controller
                    name="PaymentMethodName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) || t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.payment_methods.name")}
                        onChange={(e) => {
                          handleInputChange(e, "PaymentMethodName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.PaymentMethodName &&
                      errors.PaymentMethodName.message}
                  </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.NameLocalized}>
                  <FormLabel
                    htmlFor="NameLocalized"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.payment_methods.name_localized")}
                  </FormLabel>
                  <Controller
                    name="NameLocalized"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) || t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.payment_methods.name_localized")}
                        onChange={(e) => {
                          handleInputChange(e, "NameLocalized");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.NameLocalized &&
                      errors.NameLocalized.message}
                  </FormErrorMessage>
              </FormControl>

              <MultiSelect
                data={paymentMethodsTypes.data && paymentMethodsTypes.data.map(el => ({
                  ...el,
                  label: el.PaymentMethodTypeName,
                  value: el.PaymentMethodTypeId
                }))}
                isRequired
                isMulti={false}
                title={t("pages.payment_methods.payment_method_id")}
                onChange={(e) => setValue("PaymentMethodTypeId", e)}
              />

              <FormControl isInvalid={errors.PaymentMethodCode}>
                    <FormLabel
                      htmlFor="PaymentMethodCode"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.payment_methods.payment_method_code")}
                    </FormLabel>
                    <Controller
                      name="PaymentMethodCode"
                      control={control}
                      defaultValue="0"
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 || t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.payment_methods.payment_method_code")}
                          onChange={(e) => {
                            handleInputChange(e, "PaymentMethodCode");
                          }}
                          type="number"
                          min="0"
                          // max="100"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.PaymentMethodCode &&
                        errors.PaymentMethodCode.message}
                    </FormErrorMessage>
              </FormControl>

              <Checkbox
                {...register("AutoOpenCashDrawer")}
                textTransform="capitalize"
              >
                {t("pages.payment_methods.auto_open_cash_drawer")}
              </Checkbox>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={paymentMethods.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={paymentMethods.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>

            </Stack>
          </form>
        </Box>
      )}
    />
  </>
}

export default UpdateModal;