import { createSlice } from "@reduxjs/toolkit";
import { AddExpensesJornul } from "../service/ExpensesService";


let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
};


  const ExpensesSlice = createSlice({
    name: "ExpensesJornul",
    initialState,
        extraReducers:(builder)=>{
                 builder.addCase(AddExpensesJornul.pending, (state) => {
                   state.isLoading = true;
                   state.errors = null;
                 });

                 builder.addCase(
                   AddExpensesJornul.fulfilled,
                   (state, { payload }) => {
                     state.isLoading = false;
                     // state.Accounts =
                     //   (payload[0] && JSON.parse(payload[0].jsonData)) || [];
                     //console.log(state.Accounts);
                     state.errors = null;
                   }
                 );

                 builder.addCase(AddExpensesJornul.rejected, (state) => {
                   state.isLoading = false;
                   state.errors = [{ msg: "something went wrong!" }];
                 });
        }
  });

      export default ExpensesSlice.reducer;