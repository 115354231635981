import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { getListTransferorder } from "../services/transfersOrdersService"
import CreateTransferOrdersModal from '../components/modals/CreateTransferOrdersModal'
import TransfersOrdersTable from '../components/TransfersOrdersTable'
import ExportModal from 'components/ui/modal/ExportModal';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

const TransfersOrders = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [CreateTranOrdersModalstate, setCreateTranOrdersModalstate] = useState(false);
  const [exportModal,SetExportModal]=useState(false);

  const items = useSelector(state => state.transfersorders);

  useEffect(() => {
    dispatch(getListTransferorder())
  }, [dispatch]);
/* -----------------------------------Privilige-------------------------------*/
  const retrievedArray =useDecryptedPrivilege();
  const ManageTransferOrders = checkIdExists(retrievedArray, 17);

  if(CreateTranOrdersModalstate === true && ManageTransferOrders === false){
    toast2({ description:  t("general.authorization")})
    setCreateTranOrdersModalstate(false)
  };
/* -----------------------------------Privilige-------------------------------*/
  return (
    <div>
      <Box
        bg="light" marginInline={-6} marginTop={-6} padding={6}
        borderTop="1px solid" borderColor="border" marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("layout.sidebar.transfers_requests")}
          </Text>

          <Flex gap={2}>
            <Button
              type="button" bg="none" border="1px solid" borderColor="border"
              textTransform="capitalize" fontSize="1rem" color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
                <Text>{t("general.export")}</Text>
            </Button>
            <Button
              type="button" bg="primary" color="light"
              textTransform="capitalize" fontSize="1rem"
              border="1px solid transparent"
              _hover={{ bg: "none", color: "primary", borderColor: "primary" }}
              onClick={() => setCreateTranOrdersModalstate(true)}
            >
              <Flex alignItems="center" gap={1} >
                {t("pages.transfers_orders.new_transfer_order")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>

      {
        items.isLoading ? (
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <TransfersOrdersTable filteredData={items.filteredData} data={items.data}></TransfersOrdersTable>
        )
      }

      {CreateTranOrdersModalstate && (
        <CreateTransferOrdersModal onClose={() => setCreateTranOrdersModalstate(false)} />
      )}

      {exportModal&&(
            <ExportModal 
              onClose={() => SetExportModal(false)}   
              data={items.data}
              fileName={"transfers_orders"}
              >
            </ExportModal>
      )}
      
    </div>
  );
}

export default TransfersOrders;