import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  Text,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";

export default function AddPermissionTypeModal({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  //   const dateFromStr = details?.BirthDate;
  //   const [day, month, year] = dateFromStr.split("-");

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.self_service_object.add_new_permission_type")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                console.log(values);
                // values.EmployeeId = id;

                // values.EmpClassificationId =
                //   values.EmpClassificationId?.EmployeeClassificationId ||
                //   details.EmployeeClassificationId;

                // values.EmpStatusId =
                //   values.EmpStatusId?.FamilyStatusId || details.FamilyStatusId;

                // values.ReligionId =
                //   values.ReligionId?.ReligionId || details?.ReligionId;

                // values.RecruitmentSituationId =
                //   values.RecruitmentSituationId?.RecruitmentSituationId ||
                //   details?.RecruitmentSituationId;

                // values.BirthDate = values?.BirthDate || details?.BirthDate;

                // dispatch(editBasicInfo(values))
                //   .unwrap()
                //   .then((_) => {
                //     toast({
                //       description: t("pages.hr.basic_info_updated"),
                //     });
                //     dispatch(getEmployeeById(id));
                //     onClose();
                //   });
              })}
            >
              <Stack spacing={6}>
                <Box>
                  <MultiSelect
                    title={t("pages.hr.self_service_object.select_employee")}
                    isMulti={false}
                    // defaultValue={{
                    //   label:
                    //     classifications[details?.EmployeeClassificationId - 1]
                    //       ?.EmployeeClassificationNameEn,
                    // }}
                    // data={
                    //   classifications &&
                    //   classifications?.map((el) => ({
                    //     ...el,
                    //     label: el.EmployeeClassificationNameEn,
                    //     value: el.EmployeeClassificationId,
                    //   }))
                    // }
                    onChange={(e) => setValue("EmpClassificationId", e)}
                  />
                </Box>

                <Box>
                  <MultiSelect
                    title={t("pages.hr.self_service_object.permission_type")}
                    isMulti={false}
                    // defaultValue={{
                    //   label:
                    //     classifications[details?.EmployeeClassificationId - 1]
                    //       ?.EmployeeClassificationNameEn,
                    // }}
                    // data={
                    //   classifications &&
                    //   classifications?.map((el) => ({
                    //     ...el,
                    //     label: el.EmployeeClassificationNameEn,
                    //     value: el.EmployeeClassificationId,
                    //   }))
                    // }
                    onChange={(e) => setValue("EmpClassificationId", e)}
                  />
                </Box>

                <FormControl isInvalid={errors.StartDate}>
                  <FormLabel
                    htmlFor="StartDate"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.extraction_start_date")}
                  </FormLabel>
                  <Controller
                    name="StartDate"
                    control={control}
                    // defaultValue={`${year}-${month}-${day}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.extraction_start_date")}
                        onChange={(e) => {
                          handleInputChange(e, "StartDate");
                        }}
                        type="date"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.StartDate && errors.StartDate.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.EndDate}>
                  <FormLabel
                    htmlFor="EndDate"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.extraction_end_date")}
                  </FormLabel>
                  <Controller
                    name="EndDate"
                    control={control}
                    // defaultValue={`${year2}-${month2}-${day2}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.extraction_end_date")}
                        onChange={(e) => {
                          handleInputChange(e, "EndDate");
                        }}
                        type="date"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.EndDate && errors.EndDate.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.StartDate}>
                  <FormLabel
                    htmlFor="StartDate"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.extraction_start_date")}
                  </FormLabel>
                  <Controller
                    name="StartDate"
                    control={control}
                    // defaultValue={`${year}-${month}-${day}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.extraction_start_date")}
                        onChange={(e) => {
                          handleInputChange(e, "StartDate");
                        }}
                        type="time"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.StartDate && errors.StartDate.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.EndDate}>
                  <FormLabel
                    htmlFor="EndDate"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.extraction_end_date")}
                  </FormLabel>
                  <Controller
                    name="EndDate"
                    control={control}
                    // defaultValue={`${year2}-${month2}-${day2}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.extraction_end_date")}
                        onChange={(e) => {
                          handleInputChange(e, "EndDate");
                        }}
                        type="time"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.EndDate && errors.EndDate.message}
                  </FormErrorMessage>
                </FormControl>

                <Box>
                  <Text> {t("pages.hr.self_service_object.comments")}</Text>
                  <Textarea
                    placeholder="Here is a sample placeholder"
                    marginTop={"2"}
                    // defaultValue={item?.OfficialVacationCause}
                    onChange={(e) =>
                      setValue("OfficialVacationCause", e.target.value)
                    }
                  />
                </Box>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
