import { createSlice } from "@reduxjs/toolkit";
import {getPurchases,createPurchase,getPurchaseDetails,editPurchase,deleteItem,getDeletedItem, getFilteredData,
  resetFilteredData,
  getPurchasesList,
  getbranchesInPurchase,
  getSuppliersInPurchase,
  getPurchasingFilteredData,
  resetPurchasingFilteredData} from "../services/purchaseService"


let initialState = {
    isLoading: false,
    data: [],
    purchaseDetails:[],
    pages: 0,
    errors: null,
    deletedItemsData:[],

    filteredData:[],

    branchesInPurchase : [],
    suppliersInPurchase : [],
  };


  const purchaseSlice = createSlice({
    name: "purchases",
    initialState,

    extraReducers:(builder)=>{
      /* ---------------------------------------------------- Get Purchases List ------------------------------------------------- */
      builder.addCase(getPurchasesList.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getPurchasesList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getPurchasesList.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      /* ---------------------------------------------------- Get Branches In Purchases ------------------------------------------------- */
      builder.addCase(getbranchesInPurchase.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getbranchesInPurchase.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.branchesInPurchase = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getbranchesInPurchase.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      /* ---------------------------------------------------- Get Suppliers In Purchases ------------------------------------------------- */
      builder.addCase(getSuppliersInPurchase.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getSuppliersInPurchase.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.suppliersInPurchase = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getSuppliersInPurchase.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
    
      builder.addCase(createPurchase.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(createPurchase.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        //console.log(payload)
        //state.data.push(payload);
        state.errors = null;
      })
      builder.addCase(createPurchase.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //

      builder.addCase(getPurchaseDetails.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getPurchaseDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.purchaseDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getPurchaseDetails.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //

      builder.addCase(editPurchase.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(editPurchase.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(editPurchase.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      //
      builder.addCase(deleteItem.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = state.data.filter(branch => branch.id !== payload.id);
        state.errors = null;
      })
      builder.addCase(deleteItem.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      //
      builder.addCase(getDeletedItem.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getDeletedItem.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.deletedItemsData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.deletedItemsData)
        state.errors = null;
      })

      builder.addCase(getDeletedItem.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      builder.addCase(getFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        console.log("youssef" , state.filteredData);
        state.errors = null;
      });
      builder.addCase(getFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
  
      builder.addCase(resetFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.filteredData = [];
        state.errors = null;
      });
      builder.addCase(resetFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
         /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
    builder.addCase(getPurchasingFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPurchasingFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getPurchasingFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetPurchasingFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetPurchasingFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetPurchasingFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
    }
  })


  export default purchaseSlice.reducer