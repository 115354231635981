import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import { useTranslation } from 'react-i18next';
import AddItemModal from './modal/AddItemModal';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQuantityAdjustmentItems } from '../services/quantityAdjustmentService';
import QuantityItemTable from './QuantityItemTable';
import EditQuantityAdjasmentModal from "./modal/EditQuantityAdjasmentModal";
 
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 

const QuantityAdjustmentAddtem = ({quantityDetailsStatus}) => {
    const { t } = useTranslation();
    const id = useParams();
    const dispatch=useDispatch();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const data = useSelector(state => state.QuantityAdjustment);

    const [showModal,setShowModal]=useState(false);
    const [showEditQuantityAdjustment, setShowEditQuantityAdjustment] = useState(false);

    useEffect(()=>{
      dispatch(getQuantityAdjustmentItems(id.id))
    },[dispatch,id])

    /* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageQuantityAdjustments = checkIdExists(retrievedArray, 28);

if(showModal===true && ManageQuantityAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowModal(false);
};
if(showEditQuantityAdjustment===true && ManageQuantityAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowEditQuantityAdjustment(false);
};
/* -----------------------------------Privilige-------------------------------*/

  return (
    <Box>

      {
        quantityDetailsStatus === "Closed" ? "" : 
          <Flex
          alignItems="center" justifyContent="space-between"
          gap={2} flexWrap="wrap" mb={6}
        >
              <Text color="text" textTransform="capitalize">
                {t("pages.quantity_adjustment.items")}
              </Text>

              <Flex gap={2} >
                {
                  data.quantityAdjustmentItems.length > 0 ? 
                      <GButton
                          title={t("pages.quantity_adjustment.edit_quantity")}
                          bg="light"
                          color="text"
                          fontSize="14px"
                          border="1px solid"
                          borderColor="border"
                          hover={{ bg: "light" }}
                          onClick={() => setShowEditQuantityAdjustment( true)}
                      />
                  : 
                  ""
                }
                  <GButton
                    title={t("pages.quantity_adjustment.add_item")}
                    bg="light"
                    color="text"
                    fontSize="14px"
                    border="1px solid"
                    borderColor="border"
                    hover={{ bg: "light" }}
                    onClick={() => setShowModal( true)}
                  />
                  {/* <GButton
                    title={t("pages.quantity_adjustment.import_item")}
                    bg="light"
                    color="text"
                    fontSize="14px"
                    border="1px solid"
                    borderColor="border"
                    hover={{ bg: "light" }}
                    // onClick={() => setShowModal( true)}
                  /> */}
              </Flex>

          </Flex>
      }

  {data.isLoading ? (
    <Flex bg="light" p={4} justifyContent="center">
      <Spinner />
    </Flex>

  ) : data.quantityAdjustmentItems?.length === 0 ? (
    <Flex
      p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
    >
      {t("pages.branches.no_data")}
    </Flex>
  ) : data.quantityAdjustmentItems?.length > 0 ? (
    <QuantityItemTable
    data={data.quantityAdjustmentItems}
    />
  ):(
    <Flex
    p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  >
    {t("pages.branches.no_data")}
  </Flex>
  )
}

{
    showModal&&(
        <AddItemModal onClose={() => setShowModal(false)} ></AddItemModal>
    )
}
 
{
    showEditQuantityAdjustment&&(
        <EditQuantityAdjasmentModal onClose={() => setShowEditQuantityAdjustment(false)} id={id.id} ></EditQuantityAdjasmentModal>
    )
}
 
    </Box>
  );
}

export default QuantityAdjustmentAddtem;
