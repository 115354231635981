import React from 'react';
import { Box, Table, Tbody, Td, Th, Thead, Tr, Text} from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';


const CustomPriceTable = ({data}) => {

    const { t } = useTranslation();
    //console.log(data)

  return (
    <div>
    <div>
    <Text color="text" textTransform="capitalize">
     
    </Text>
    <Box
    bg="light" overflow="auto"
    borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >
    <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.branches.name")}</Th>
          <Th>{t("pages.combos.price")}</Th>
        </Tr>
      </Thead>
      

      <Tbody>
          {data&&data.map((el, index) => (
            <Tr key={el.ModiferOptionCustomPriceId}>
              <Td>{index + 1}</Td>
              <Td >{el?.BranchName}</Td>
              <Td >{el?.Price}</Td>
              

            </Tr>
          ))}
        </Tbody>


    </Table>
  </Box>

 
    </div>
    </div>
  );
}

export default CustomPriceTable;
