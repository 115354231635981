import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  Text,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  Checkbox,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  createNewHrLetter,
  getEmployeeDropDown,
  getHrLetterData,
} from "features/hr/selfServices/services/HrSelfServices";

export default function AddHrLetterModal({ onClose }) {
  const UserId = localStorage.getItem("userId");
  const { t } = useTranslation();
  const disPatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const { employeeDropdown, BankDropdown } = useSelector(
    (state) => state.HrSelfServices
  );

  /* ------------------------------------------------------------------------------ */
  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  //   const dateFromStr = details?.BirthDate;
  //   const [day, month, year] = dateFromStr.split("-");
  /* ------------------------------------------------------------------------------ */
  useEffect(() => {
    disPatch(getEmployeeDropDown(UserId));
  }, [disPatch, UserId]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.add_new_hr_letter")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.UserId = UserId;
                values.HrLetterRequestId = null;
                values.IsBank = 0;
                // console.log(values);
                if (values.EmployeeId === undefined) {
                  toast2({
                    description: t("pages.hr.hr_validation.select_item"),
                  });
                } else {
                  disPatch(createNewHrLetter(values))
                    .unwrap()
                    .then((res) => {
                      console.log(res);
                      toast({
                        description: t("pages.hr.hrLetter_Created_succesfuly"),
                      });
                      disPatch(getHrLetterData(UserId));
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <Box>
                  <MultiSelect
                    title={t("pages.hr.self_service_object.select_employee")}
                    isMulti={false}
                    data={
                      employeeDropdown &&
                      employeeDropdown?.map((el) => ({
                        ...el,
                        label: el.EmployeeName,
                        value: el.EmployeeId,
                      }))
                    }
                    onChange={(e) => setValue("EmployeeId", e?.EmployeeId)}
                  />
                </Box>
                <FormControl isInvalid={errors.HrLetterTo}>
                  <FormLabel
                    htmlFor="HrLetterTo"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.to")}
                  </FormLabel>
                  <Controller
                    name="HrLetterTo"
                    control={control}
                    // defaultValue={details.HrLetterTo}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.to")}
                        onChange={(e) => {
                          handleInputChange(e, "HrLetterTo");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.HrLetterTo && errors.HrLetterTo.message}
                  </FormErrorMessage>
                </FormControl>

                {/* <Checkbox
                // defaultChecked={positionDetails[0]?.IsFullTime}
                // onChange={handelIsFullTime}
                >
                  {t("pages.hr.self_service_object.board_sign")}
                </Checkbox> */}

                <Box>
                  <Text> {t("pages.hr.self_service_object.comments")}</Text>
                  <Textarea
                    placeholder="Here is a sample placeholder"
                    marginTop={"2"}
                    // defaultValue={item?.OfficialVacationCause}
                    onChange={(e) =>
                      setValue("HrLetterComment", e.target.value)
                    }
                  />
                </Box>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
