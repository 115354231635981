import { createSlice } from "@reduxjs/toolkit";
import { getGiftCardsReportFilteredData, getGiftCardsReportList, resetGiftCardsReportFilteredData } from "../services/giftCardsReportServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const giftCardsReportSlice = createSlice({
  name: "giftCardsReport",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------- Get Gift Cards  Report List ------------------------------- */
    builder.addCase(getGiftCardsReportList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getGiftCardsReportList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getGiftCardsReportList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

   /* ------------------------------------------------------------------------------------------- */
 /* --------------------------------------- Gift Cards Filter Data ------------------------------*/
 builder.addCase(getGiftCardsReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getGiftCardsReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getGiftCardsReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetGiftCardsReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetGiftCardsReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetGiftCardsReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default giftCardsReportSlice.reducer;