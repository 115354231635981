import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Button,  Flex,  Text,} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import VoidsReturnsTable from "./components/VoidsReturnsTable";
import ExportModal from "components/ui/modal/ExportModal";
import { getvoidReturnReportList } from "./services/voidsReportServices";
import { Spinner } from '@chakra-ui/react';


export default function ReportVoidsReturns() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [exportModal, SetExportModal] = useState(false);

  const businessReports = useSelector((state) => state.voidReturnReport);

  useEffect(() => {
    dispatch(getvoidReturnReportList());
  }, [dispatch]);

  return <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.reports.business_reports.voids_returns")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
              {t("general.export")}
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>

      <>
      {
        businessReports?.isLoading ? 
        <Flex
            p={4}
            bg="white"
            alignItems="center"
            justifyContent="center"
        >
            <Spinner /> 
        </Flex>
        :
        <VoidsReturnsTable data={businessReports?.data} filteredData={businessReports?.filteredData} />
      }
      </>

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={businessReports?.data}
          fileName={"VoidsReturns Report"}
        ></ExportModal>
      )}
    </>
};
