import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const getCallcenter = createAsyncThunk(
    "inventoryCount/getCallcenter",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ---------------------------------------------------------- Add Inventory Count  -----------------------------------------------------------------*/
  export const addInventoryCount = createAsyncThunk( "inventoryCount/addInventoryCount", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/AddInventoryCount?inventorycount=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
    );
    
    export const getInvItemDrop = createAsyncThunk(
      "inventoryCount/getInvItemDrop",
      async (args, thunkApi) => {
        try {
          const { data } = await axios.get(`/Menu/InventoryItem/LookupInventoryItem`);
          // console.log("eses",data)
          return thunkApi.fulfillWithValue(data);
        } catch (e) {
          return thunkApi.rejectWithValue(e.response.data);
        }
      }
    );

  export const addInvCountItems = createAsyncThunk(
    "inventoryCount/addInvCountItems",
    async (args, thunkApi) => {
      // console.log("toto",args)
      try {
       const { data } = await axios.get(`/Inventory/AddInventoryCountItem?inventorycountitem=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getListinventoryitem = createAsyncThunk(
    "inventoryCount/getListinventoryitem",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/InventoryCountList`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getInventoryCountDetails = createAsyncThunk(
    "inventoryCount/getInventoryCountDetails",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Inventory/InventoryCountById?inventorycountid=${JSON.stringify(args)}`);
        // console.log("ya lahwaaaaaaaay", data)
        
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const editInventoryCount = createAsyncThunk(
    "inventoryCount/editInventoryCount",
    async (args, thunkApi) => {
      try {
        //console.log(args)
        const { data } = await axios.get(`/Inventory/EditInventoryCount?inventorycountid=${JSON.stringify(args)}`);
        // console.log(data)
        return thunkApi.fulfillWithValue(data);

      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const editQuantities = createAsyncThunk(
    "inventoryCount/editQuantities",
    async (args, thunkApi) => {
      try {
        //console.log(args)
        const { data } = await axios.get(`/Inventory/EditInventoryCountItems?inventorycountitemid=${JSON.stringify(args)}`);
        // console.log(data)
        return thunkApi.fulfillWithValue(data);

      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getInvCountItems = createAsyncThunk(
    "inventoryCount/getInvCountItems",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/InventoryCountListItemById?inventorycountid=${JSON.stringify(args)}`);
      //  console.log("booo",data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getEditQuantities = createAsyncThunk(
    "inventoryCount/getEditQuantities",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/GetEditInventoryCountItemsByICId?inventorycountid=${JSON.stringify(args)}`);
      //  console.log("booo",data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const submitCount = createAsyncThunk(
    "inventoryCount/submitCount",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/SubmittingInventoryCount?inventorycountid=${JSON.stringify(args)}`);
      //  console.log("booo",data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const sendItems = createAsyncThunk(
    "inventoryCount/sendItems",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/TransferOrders/SendItemsToTransfer?transferordersid=${JSON.stringify(args)}`);
      //  console.log("booo",data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const deleteOrder = createAsyncThunk( "inventoryCount/deleteOrder",  async (args, thunkApi) => {
    console.log(args);
      try {
        const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/DeleteInventoryCountById?inventorycountid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

/*-------------------------------------------------- Filter Inventory Count  -------------------------------------------- */
export const getInventoryCountFilteredData= createAsyncThunk("inventoryCount/getInventoryCountFilteredData", async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetInventoryCountFilteredData = createAsyncThunk( "inventoryCount/resetInventoryCountFilteredData ", async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);

