import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GModal from 'components/ui/modal/Modal';
import { useDispatch } from 'react-redux';
import { addQuantaty, getProductQuantaity } from 'features/callCenter/service/callCenterProductService';
import Input from "components/form/Input"
import { useForm } from 'react-hook-form';
import { Box, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import { useParams } from 'react-router-dom';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

const QuantatyModal = ({ onClose,id }) => {
    console.log(id)
    const { t } = useTranslation();
    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(getProductQuantaity(id))
    },[dispatch,id])
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    
  const { handleSubmit, control, getValues, watch, setValue, register } = useForm();
  const orderid= useParams()
  //console.log(orderid.orderid)
  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.call_center.quantity")}
    modalContent={(

        <Box p={4}>
        
        <form onSubmit={handleSubmit(values=>{
            values.ProductId=id
            values.OrderId=orderid.orderid
            dispatch(addQuantaty(values)).unwrap().then(_ => {
                toast({ description: "item created" });
                onClose()
              });
        })}>
        

        <Stack spacing={6}>
        

        <Input
        label={t("pages.call_center.quantity")}
        labelStyle={{ textTransform: "capitalize" }}
        placeholder={t("pages.call_center.quantity")}
        name="Quantity"
        isFormController
        isRequired
        type='number'
        control={control}
      />


      <Flex gap={4}>
      <GButton
        type="submit"
        title={t("general.save")}
        bg="primary"
        color="light"
        border="1px solid transparent"
        //isLoading={categories.isLoading}
        hover={{ color: "primary", borderColor: "primary", bg: "none" }}
      />
      <GButton
        title={t("general.close")}
        onClick={onClose}
        bg="red.600"
        border="1px solid transparent"
        color="light"
        //isLoading={categories.isLoading}
        hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
      />
    </Flex>
        
        </Stack>
        
        </form>
        
        </Box>
    )}
    />
  );
}

export default QuantatyModal;
