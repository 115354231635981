import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { getCouponById, updateCoupon } from "../../services/couponsService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getTime } from "services/timeService";
import { useParams } from "react-router-dom";

const UpdateModal = ({ data, onClose }) => {
  const id = useParams();
  console.log("data", data);
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const coupons = useSelector((state) => state.coupons);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: data,
  });

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  useEffect(() => {
    const StartDate = getValues("StartDate");
    const EndDate = getValues("EndDate");

    console.log("StartDate", StartDate, "EndDate", EndDate);

    if (StartDate && EndDate && new Date(StartDate) > new Date(EndDate)) {
      setError("StartDate", {
        type: "manual",
        message: t("validation.start_date_must_be_before_end_date"),
      });
    } else {
      clearErrors("StartDate");
    }
  }, [getValues("StartDate"), getValues("EndDate")]);

  const time = useSelector((state) => state.time);

  useEffect(() => {
    dispatch(getTime());
  }, [dispatch]);

  const defaultDays = [
    {
      DayId: 1,
      DayName: "Sun",
    },
    {
      DayId: 2,
      DayName: "Mon",
    },
    {
      DayId: 3,
      DayName: "Tue",
    },
    {
      DayId: 4,
      DayName: "Wed",
    },
    {
      DayId: 5,
      DayName: "Thu",
    },
    {
      DayId: 6,
      DayName: "Fri",
    },
    {
      DayId: 7,
      DayName: "Sat",
    },
  ];

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.coupons.update_coupon")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              
              values.Days = values.Days?.map((el) => ({
                DayId: el.DayId,
              }));
              values.Name = values.CouponName;
              values.NameLoc = values.CouponNameLoc;
              values.NumOfProduct = values.NumberOfProduct;
              values.CouponMaxUses = values.CouponMaximumUses;
              values.StartDate = values.CouponStartDate;
              values.EndDate = values.CouponEndDate;
              values.StartTimeId = values.CouponStartTime.DayTimeId;
              values.EndTimeId = values.CouponEndTime.DayTimeId;
              values.UserId = localStorage.getItem("userId");
              if (
                (Array.isArray(values.Days) && values.Days.length === 0) ||
                values.StartTimeId === undefined ||
                values.EndTimeId === undefined
              ) {
                toast2({ description: t("validation.required") });
              } else {
                dispatch(updateCoupon(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.coupons.coupon_updated"),
                    });
                    dispatch(getCouponById(id.id));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <FormControl isInvalid={errors.CouponName}>
                <FormLabel
                  htmlFor="CouponName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.name")}
                </FormLabel>
                <Controller
                  name="CouponName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.coupons.name")}
                      onChange={(e) => {
                        handleInputChange(e, "CouponName");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.CouponName && errors.CouponName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.CouponNameLoc}>
                <FormLabel
                  htmlFor="CouponNameLoc"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.discounts.name_localized")}
                </FormLabel>
                <Controller
                  name="CouponNameLoc"
                  control={control}
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.discounts.name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "CouponNameLoc");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.CouponNameLoc && errors.CouponNameLoc.message}
                </FormErrorMessage>
              </FormControl>

              {data?.DisCount[0]?.CouponType === "Product" && (
                <FormControl isInvalid={errors.NumberOfProduct}>
                  <FormLabel
                    htmlFor="NumberOfProduct"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.coupons.number_of_products")}
                  </FormLabel>
                  <Controller
                    name="NumberOfProduct"
                    control={control}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.coupons.number_of_products")}
                        onChange={(e) => {
                          handleInputChange(e, "NumberOfProduct");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.NumberOfProduct && errors.NumberOfProduct.message}
                  </FormErrorMessage>
                </FormControl>
              )}

              <FormControl isInvalid={errors.CouponMaximumUses}>
                <FormLabel
                  htmlFor="CouponMaximumUses"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.maximum_uses")}
                </FormLabel>
                <Controller
                  name="CouponMaximumUses"
                  control={control}
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.coupons.maximum_uses")}
                      onChange={(e) => {
                        handleInputChange(e, "CouponMaximumUses");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.CouponMaximumUses && errors.CouponMaximumUses.message}
                </FormErrorMessage>
              </FormControl>

              {/* Start Date */}
              <FormControl isInvalid={errors.CouponStartDate}>
                <FormLabel
                  htmlFor="CouponStartDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.start_date")}
                </FormLabel>
                <Controller
                  name="CouponStartDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      placeholder={t("pages.coupons.start_date")}
                      onChange={(e) => {
                        handleInputChange(e, "CouponStartDate");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.CouponStartDate && errors.CouponStartDate.message}
                </FormErrorMessage>
              </FormControl>

              {/* End Date */}
              <FormControl isInvalid={errors.CouponEndDate}>
                <FormLabel
                  htmlFor="CouponEndDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.coupons.end_date")}
                </FormLabel>
                <Controller
                  name="CouponEndDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      placeholder={t("pages.coupons.end_date")}
                      onChange={(e) => {
                        handleInputChange(e, "CouponEndDate");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.CouponEndDate && errors.CouponEndDate.message}
                </FormErrorMessage>
              </FormControl>

              {/* Start Time */}
              <MultiSelect
                data={
                  time.data &&
                  time.data.map((el) => ({
                    ...el,
                    label: el.DayTimeName,
                    value: el.DayTimeId,
                  }))
                }
                onChange={(e) => setValue("CouponStartTime", e)}
                isMulti={false}
                isRequired
                title={t("pages.coupons.start_time")}
              />

              {/* End Time */}
              <MultiSelect
                data={
                  time.data &&
                  time.data.map((el) => ({
                    ...el,
                    label: el.DayTimeName,
                    value: el.DayTimeId,
                  }))
                }
                onChange={(e) => setValue("CouponEndTime", e)}
                isMulti={false}
                isRequired
                title={t("pages.coupons.end_time")}
              />

              <MultiSelect
                title={t("pages.coupons.applies_on_days")}
                data={
                  Array.isArray(defaultDays)
                    ? defaultDays.map((el) => ({
                        ...el,
                        label: el.DayName,
                        value: el.DayId,
                      }))
                    : []
                }
                isMulti={true}
                isRequired={true}
                defaultValue={data?.WeekDays.map((order) => ({
                  ...order,
                  label: order.DayName,
                  value: order.DayId,
                }))}
                onChange={(e) => {
                  setValue("Days", e);
                  trigger("Days");
                }}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={coupons.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={coupons.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default UpdateModal;
