import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import GButton from "components/ui/button/Button"
import CreateAddressModal from "./modals/CreateAddressModal";
import { getCustomerAddresses } from "../services/addressesService";
import CTable from "./AddressesTable";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

export default function Addresses({id}){
  const retrievedArray =useDecryptedPrivilege()
  const ManageCustomers = checkIdExists(retrievedArray, 6);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addresses = useSelector(state => state.customersAddresses);

  const [showCreateAddressModal, setShowCreateAddressModal] = useState(false);

  useEffect(() => {
    dispatch(getCustomerAddresses(id))
  }, [dispatch, id])

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(showCreateAddressModal===true && ManageCustomers===false){
    toast2({ description:  t("general.authorization") })
    setShowCreateAddressModal(false)
  }


  return<>
      <Box>
      <Flex
        alignItems="center" justifyContent="space-between"
        gap={2} flexWrap="wrap" mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.customers.addresses")}
        </Text>
        <GButton
          title={t("pages.customers.create_address")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowCreateAddressModal(true)}
        />
      </Flex>
      {addresses?.isLoading ? (
        <Spinner />
      ) : addresses?.customerAddresses?.length === 0 ? (
        <Flex
          p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
        >
          {t("pages.customers.no_data_hint")}
        </Flex>
      ) : (
        <CTable
          addresses={addresses?.customerAddresses}
        />
      )}

      {showCreateAddressModal && (
        <CreateAddressModal
          id={id}
          onClose={() => setShowCreateAddressModal(false)}
        />
      )}
      </Box>
  
  </>
}

