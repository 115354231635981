import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { giftCardList } from "../services/giftCardService";
import AddGiftCardModal from "./modals/AddGiftCardModal";
import GiftCardTable from "./GiftCardTable";
import { useParams } from "react-router-dom";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const SubGroups = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const { t } = useTranslation();
  const id = useParams();
  const dispatch = useDispatch();

  const subGroup = useSelector((state) => state.giftsCard);
  const [showSubGroups, setShowSubGroups] = useState(false);

  useEffect(() => {
    dispatch(giftCardList(id.groupId));
  }, [dispatch,id]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(showSubGroups && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowSubGroups(false)
  }

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.groups.giftcards")}
        </Text>

        <GButton
          title={t("pages.groups.add_giftcard")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowSubGroups(true)}
        />
      </Flex>

      {subGroup.isLoading ? (
        <Spinner />
      ) : subGroup.giftCardData?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : subGroup.giftCardData?.length > 0 ? (
        <GiftCardTable
        giftCardList={subGroup.giftCardData}
        />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {showSubGroups && (
        <AddGiftCardModal onClose={() => setShowSubGroups(false)} />
      )}


    </Box>
  );
};

export default SubGroups;
