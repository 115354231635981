import React, { useEffect, useState } from "react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import EditWarehouse from "./Modal/EditWarehouse";

export default function WarehouseTableDetails({data}) {
    const id = useParams()
    const { t } = useTranslation();

    const [showEditWarehouse, setShowEditWarehouse] = useState(false);
        
  return <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >

            <Text marginBlock={2} fontWeight={"bold"}>  {data[0]?.Name || "-"}  </Text>

            <GButton
                title={t("pages.warehouse.edit")}
                bg="#54A0FF"
                color="light"
                hover={{ bg: "primary" }}
                onClick={() => setShowEditWarehouse(true)}
            />

        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.warehouse.name")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.Name || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.warehouse.name_Localized")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
            {data[0]?.NameLocalized || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.warehouse.reference")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
            {data[0]?.Reference || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.warehouse.end_of_day")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
            {data[0]?.DayTimeName || "-"}
            </Text>
          </Box>
        </SimpleGrid>


        {/*Show Edit Warehouse Modal*/}
        {showEditWarehouse && (
          <EditWarehouse
            onClose={() => setShowEditWarehouse(false)}
            data={data}
          />
        )}


      </Stack>
  
  </>
}


