import { useTranslation } from "react-i18next"
import { Stack } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import GButton from "components/ui/button/Button";

const ImportModal = ({
  onClose
}) => {
  const { t } = useTranslation();

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.gifts_cards.import_gifts_cards")}
      modalContent={(
        <Stack spacing={4} p={4}>
          <GButton
            title={t("pages.gifts_cards.import_gifts_cards")}
            bg="primary"
            width="100%"
            color="light"
            border="1px solid transparent"
            hover={{ bg: "none", borderColor: "primary", color: "primary" }}
          />

          <GButton
            title={t("general.download_sample")}
            bg="none"
            width="100%"
            color="primary"
            border="1px solid"
            borderColor="primary"
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          />
        </Stack>
      )}
    />
  )
}

export default ImportModal