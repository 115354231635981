import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* -------------------------------------------------- Get Branches ------------------------------------------- */
export const getBranches = createAsyncThunk("branches/getBranches", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/ListBranches`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getBranchById = createAsyncThunk(
  "branches/getBranchById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage/Branches/BranchById?branchid=${args.branchId}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createBranch = createAsyncThunk("branches/createBranch",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/AddEditBranch?groupcombos=${JSON.stringify(args)}`);
      //console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      console.log(e)
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

// export const createBranch = createAsyncThunk(
//   "branches/createBranch",
//   async (args, thunkApi) => {
//     try {
//      const { data } = await axios.get(`/Manage/Branches/AddEditBranch?groupcombos=${JSON.stringify(args)}`);
//      console.log(data)
//      return thunkApi.fulfillWithValue(data);
      
//     } catch (e) {
//       return thunkApi.rejectWithValue(e.response.data);
//     }
//   }
// );

export const updateBranch = createAsyncThunk(
  "branches/updateBranch",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage/Branches/AddEditBranch?branch=${JSON.stringify(args.values)}`
      );
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "branches/deleteBranch",
  async (args, thunkApi) => {
    try {
      //console.log(args);
      const { data } = await axios.get(
        `/Manage/Branches/DeleteBranchesById?BranchId={"BranchId":${args}}`
      );
      //console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const getSectionTables = createAsyncThunk(
  "branches/getSectionTables",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage/Branches/TableBySecId?secid={"BranchSectionId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const AddTableToSection = createAsyncThunk(
  "branches/AddTableToSection",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage/Branches/AddEditTableToSec?tablesec=${JSON.stringify(args)}`
      );
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const getFilteredDataBranches = createAsyncThunk(
  "branches/getFilteredDataBranches ",
  async (args, thunkApi) => {
    
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const resetFilteredDataBranches = createAsyncThunk(
  "branches/resetFilteredDataBranches ",
  async (args, thunkApi) => {
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);