import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getTagsLookup = createAsyncThunk(
  "tags/getTagsLookup",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Branches/LookupTags");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
