import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import AppliesOnBranchesModal from "./modals/AppliesOnBranchesModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BranchesTable from "./BranchesTable";
import { getDiscountsBranches } from "../services/discountsBranchesService";
import DeleteAppliesModal from "./modals/DeleteAppliesModal";

const AppliesOnBranches = () => {
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showInActiveBranche, setShowInActiveBranche] = useState(false);
  const [showDeleteBranchModal, setShowDeleteBranchModal] = useState(null);

  const branchesOfDiscounts = useSelector((state) => state.discountsBranches);

  useEffect(() => {
    dispatch(getDiscountsBranches(id.id));
  }, [dispatch, id]);

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.discounts.applies_on_branches")}
        </Text>

        <GButton
          title={t("pages.discounts.edit_branches")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowInActiveBranche(true)}
        />
      </Flex>

      {branchesOfDiscounts.isLoading ? (
        <Spinner />
      ) : branchesOfDiscounts.data?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : branchesOfDiscounts.data?.length > 0 ? (
        <BranchesTable
          branchesOfDiscount={branchesOfDiscounts.data}
          handleDelete={(branch) => setShowDeleteBranchModal(branch)}
        />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {showInActiveBranche && (
        <AppliesOnBranchesModal onClose={() => setShowInActiveBranche(false)} />
      )}

      {showDeleteBranchModal && (
        <DeleteAppliesModal
          id={showDeleteBranchModal}
          onClose={() => setShowDeleteBranchModal(null)}
          type="Branch"
        />
      )}
    </Box>
  );
};

export default AppliesOnBranches;
