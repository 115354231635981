export const getLastTwoMonthsDates = () => {
    const currentDate = new Date();
    const result = [];
  
    for (let i = 0; i < 8; i++) {
      const date = new Date(currentDate);
      date.setDate(date.getDate() - i * 7);
  
      // Format the date as desired (DD/MM)
      const formattedDate = `${date.getDate()}-${date.getMonth() + 1}`;
  
      result.push(formattedDate);
    }
  
    return result;
  };