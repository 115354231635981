import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  createTimedEvent,
  getTimedEvents,
} from "../../services/timedEventsService";

export default function CreateTimedEventsModal({ onClose }) {
  const { t } = useTranslation();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm();

  const timeEvents = useSelector((state) => state.timedEvents);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  useEffect(() => {
    const StartDate = getValues("StartDate");
    const EndDate = getValues("EndDate");

    if (StartDate && EndDate && new Date(StartDate) > new Date(EndDate)) {
      setError("StartDate", {
        type: "manual",
        message: t("validation.start_date_must_be_before_end_date"),
      });
    } else {
      clearErrors("StartDate");
    }
  }, [getValues("StartDate"), getValues("EndDate")]);

  const [timedEventType, setTimedEventType] = useState({
    timedEventsId: 1,
    timedEventsName: "Set Fixed Price",
  });

  const timedEventsType = {
    timedEventsTypeDropdown: [
      {
        timedEventsId: 1,
        timedEventsName: "Set Fixed Price",
      },
      {
        timedEventsId: 2,
        timedEventsName: "Reduce Price by Fixed Amount",
      },
      {
        timedEventsId: 3,
        timedEventsName: "Reduce Price by Percentage Amount",
      },
      {
        timedEventsId: 4,
        timedEventsName: "Increase Price by Fixed Amount",
      },
      {
        timedEventsId: 5,
        timedEventsName: "Increase Price by Percentage Amount",
      },
      {
        timedEventsId: 6,
        timedEventsName: "Active Products",
      },
      {
        timedEventsId: 7,
        timedEventsName: "Deactive Products",
      },
    ],
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.timedEvents.create_timed_events")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.EventTypeId = values?.EventTypeId?.timedEventsId;
              values.UserId = localStorage.getItem("userId");
              if (values.EventTypeId === undefined) {
                values.EventTypeId = 1;
              }
              dispatch(createTimedEvent(values))
                .unwrap()
                .then((_) => {
                  toast({
                    description: t("pages.timedEvents.time_event_created"),
                  });
                  dispatch(getTimedEvents());
                  onClose();
                });
            })}
          >
            <Stack spacing={6}>
              <FormControl isInvalid={errors.Name}>
                <FormLabel
                  htmlFor="Name"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.timedEvents.name")}
                </FormLabel>
                <Controller
                  name="Name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.timedEvents.name")}
                      onChange={(e) => {
                        handleInputChange(e, "Name");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.Name && errors.Name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.NameLoc}>
                <FormLabel
                  htmlFor="NameLoc"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.timedEvents.name_localized")}
                </FormLabel>
                <Controller
                  name="NameLoc"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.timedEvents.name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "NameLoc");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.NameLoc && errors.NameLoc.message}
                </FormErrorMessage>
              </FormControl>

              <MultiSelect
                title={t("pages.timedEvents.type")}
                defaultValue={{
                  label: "Set Fixed Price",
                  value: 1,
                }}
                data={
                  timedEventsType.timedEventsTypeDropdown &&
                  timedEventsType.timedEventsTypeDropdown.map((el) => ({
                    ...el,
                    label: el.timedEventsName,
                    value: el.timedEventsId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => {
                  setValue("EventTypeId", e);
                  setTimedEventType(e);
                }}
              />

              {timedEventType &&
                timedEventType?.timedEventsName === "Set Fixed Price" && (
                  <FormControl isInvalid={errors.Amount}>
                    <FormLabel
                      htmlFor="Amount"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.timedEvents.fixed_price")}
                    </FormLabel>
                    <Controller
                      name="Amount"
                      control={control}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.timedEvents.fixed_price")}
                          onChange={(e) => {
                            handleInputChange(e, "Amount");
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.Amount && errors.Amount.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              {timedEventType &&
                (timedEventType?.timedEventsName ===
                  "Reduce Price by Fixed Amount" ||
                  timedEventType?.timedEventsName ===
                    "Increase Price by Fixed Amount") && (
                  <FormControl isInvalid={errors.Amount}>
                    <FormLabel
                      htmlFor="Amount"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.timedEvents.amount")}
                    </FormLabel>
                    <Controller
                      name="Amount"
                      control={control}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.timedEvents.amount")}
                          onChange={(e) => {
                            handleInputChange(e, "Amount");
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.Amount && errors.Amount.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

              {timedEventType &&
                (timedEventType?.timedEventsName ===
                  "Reduce Price by Percentage Amount" ||
                  timedEventType?.timedEventsName ===
                    "Increase Price by Percentage Amount") && (
                  <FormControl isInvalid={errors.Amount}>
                    <FormLabel
                      htmlFor="Amount"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.timedEvents.percentage_amount")}
                    </FormLabel>
                    <Controller
                      name="Amount"
                      control={control}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.timedEvents.percentage_amount")}
                          onChange={(e) => {
                            handleInputChange(e, "Amount");
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.Amount && errors.Amount.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              {/* Start Date */}
              <FormControl isInvalid={errors.StartDate}>
                <FormLabel
                  htmlFor="StartDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.timedEvents.start_date")}
                </FormLabel>
                <Controller
                  name="StartDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      placeholder={t("pages.timedEvents.start_date")}
                      onChange={(e) => {
                        handleInputChange(e, "StartDate");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.StartDate && errors.StartDate.message}
                </FormErrorMessage>
              </FormControl>

              {/* End Date */}
              <FormControl isInvalid={errors.EndDate}>
                <FormLabel
                  htmlFor="EndDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.timedEvents.end_date")}
                </FormLabel>
                <Controller
                  name="EndDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      placeholder={t("pages.timedEvents.end_date")}
                      onChange={(e) => {
                        handleInputChange(e, "EndDate");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.EndDate && errors.EndDate.message}
                </FormErrorMessage>
              </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={timeEvents.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={timeEvents.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
}
