import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { MdOutlineError } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function ErrorHandelText() {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        p={4}
        bg="white"
        alignItems="center"
        justifyContent="center"
        flexDir={"column"}
        color={"gray.500"}
        textTransform="capitalize"
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        borderRadius={6}
      >
        <Box textColor={"red.600"}>
          <MdOutlineError size={50} />
        </Box>
        <Text fontWeight={"bold"} marginY={3} fontSize={"xl"}>
          {t("general.errors")}
        </Text>
      </Flex>
    </>
  );
}
