import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const getTaxesLookup = createAsyncThunk("taxes/getTaxesLookup", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Taxs/LookUpTaxs");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

