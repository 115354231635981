import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { createCustomerTag, getCustomersTags } from "../../services/customersTagsService";
import { getTagsLookup } from "features/management/tags/services/tagsLookupService";

export default function CreateTagModal ({id,onClose}) {
  
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const dispatch = useDispatch();

  const customersTags = useSelector(state => state.customersTags);
  const tagsLookup = useSelector(state => state.tagsLookup);
  const {handleSubmit,setValue} = useForm();

  useEffect(() => {
    dispatch(getTagsLookup());
  }, [dispatch]);

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.customers.create_tag")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            if(values.TagId === undefined){
                toast2({ description: t("general.validate") });
            }
            else {
            values.CustomerId = id;
            values.TagId = values.TagId?.TagId;
            values.CustomerTagsId = null;
            dispatch(createCustomerTag(values))
              .unwrap()
              .then(_ => {
                toast({ description: t("pages.customers.tag_created") });
                dispatch(getCustomersTags(id))
                onClose()
              });
            }
          })}>
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.customers.tags")}
                isMulti={false}
                data={tagsLookup.data && tagsLookup.data.map(el => ({
                  ...el,
                  label: el.TagName,
                  value: el.TagId
                }))}
                onChange={(e) => setValue("TagId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={customersTags.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={customersTags.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  
  </>
}

