import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput,} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import GModal from 'components/ui/modal/Modal';
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from 'react-redux';
import {editPurchaseOrderItems, getPurchaseOrdersDetails, getPurchesOrderBranch } from '../../services/purchasrOrderServices';
import Input from 'components/form/Input';

export default function EditPurchaseOrderItemModal({onClose , id}) {

  const dispatch = useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { t } = useTranslation();
  const { handleSubmit,   control,   getValues,   watch,   setValue,   register,   formState: { errors }, trigger,
    setError, clearErrors, } = useForm();

  const items= useSelector((state) => state.purchaseOrder);

  useEffect(() => {
    dispatch(getPurchesOrderBranch());
  }, [dispatch])
  
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.purchase_orders.edit_purchase_order")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            if(values.BranchId === undefined || values.AdditionalCosts === undefined || 
                values.DeliveryDate === undefined ){
                toast2({ description: t("validation.choose_first") });
            }
            else {
                values.BranchId = values.BranchId.BranchId;
                values.DeliveryDate = values.DeliveryDate;
                values.Notes = values.Notes;
                values.AdditionalCosts = values.AdditionalCosts;
                values.PurchaseOrdersId = id;
                dispatch(editPurchaseOrderItems(values))
                  .unwrap().then(_ => {
                    toast({ description: t('pages.purchase_orders.purchase_orders_updated') });
                    dispatch(getPurchaseOrdersDetails(id))
                    onClose()
                  });
              }
          })} >

            <Stack spacing={6}>
                <MultiSelect
                  title={t("pages.purchase_orders.destination")}
                  data={items?.branchData?.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))}
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("BranchId", e)}
                />
                {/* <Input
                  label={t("pages.purchase_orders.additional_cost")}
                  name="AdditionalCosts"
                  control={control}
                  onChange={(e) => setValue("AdditionalCosts", e.target.value)}
                  type="number"
                  labelStyle={{ textTransform: "capitalize" }}
                  isFormController
                /> */}

                <FormControl isInvalid={errors.AdditionalCosts}>
                      <FormLabel
                        htmlFor="AdditionalCosts"
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("pages.purchase_orders.additional_cost")}
                      </FormLabel>
                      <Controller
                        name="AdditionalCosts"
                        control={control}
                        defaultValue="0"
                        rules={{
                          required: t("validation.required"),
                          validate: (value) => {
                            const intValue = parseInt(value, 10);
                            return (
                              intValue >= 0 || t("validation.must_be_non_negative")
                            );
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={t("pages.purchase_orders.additional_cost")}
                            onChange={(e) => {
                              handleInputChange(e, "AdditionalCosts");
                            }}
                            type="number"
                            min="0"
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.AdditionalCosts &&
                          errors.AdditionalCosts.message}
                      </FormErrorMessage>
                </FormControl>

                <Input
                  label={t("pages.purchase_orders.delivery_Date")}
                  name="DeliveryDate"
                  control={control}
                  type="date"
                  labelStyle={{ textTransform: "capitalize" }}
                  isFormController
                  onChange={(e) => setValue("DeliveryDate", e.target.value)}
                />

                <Input
                  label={t("pages.purchase_orders.notes")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.purchase_orders.notes")}
                  name="Notes"
                  isFormController
                  control={control}
                  onChange={(e) => setValue("Notes", e.target.value)}
                />
              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  
  </>
}
