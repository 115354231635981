import { createSlice } from "@reduxjs/toolkit";
import { getPurchesOrderSuppliers,
         getPurchesOrderBranch, 
         addNewPurchaseOrders , 
         getPurchaseOrdersList,
         getPurchaseOrdersDetails, 
         getPurchaseOrdersitems,
         addPurchaseOrderItems,
         submitPurchaseOrderReview,
         DeleteItemFromPurchaseOrder,
         approvingRegectedPurchaseOrder,
         createPurchasing,
         editPurchaseOrderItems,
         getInventoryItemId,
         getPurchaseOrderFilteredData,
         resetPurchaseOrderFilteredData,
         editQuantityPurchaseOrderItems
        } from "../services/purchasrOrderServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  suplliersData: [],
  branchData: [],
  InventoryItems: [],

  purchasOrderData : [],
  purchasOrderDetails : [],
  PurchaseOrdersitems  : [],

  filteredData : []
};

const purchaseOrdersSlice = createSlice({
  name: "PurchaseOrders",
  initialState,
  extraReducers: (builder) => {

/* --------------------------------------------- Creating Purchase Orders -------------------------------------------------- */
    // Get Suplliers
    builder.addCase(getPurchesOrderSuppliers.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPurchesOrderSuppliers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.suplliersData= (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPurchesOrderSuppliers.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });


    // Get Branched
    builder.addCase(getPurchesOrderBranch.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPurchesOrderBranch.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branchData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPurchesOrderBranch.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    // Get Inventory Items
    builder.addCase(getInventoryItemId.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getInventoryItemId.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.InventoryItems= (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getInventoryItemId.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    
    // Add New Purchase Order
    builder.addCase(addNewPurchaseOrders.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewPurchaseOrders.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(addNewPurchaseOrders.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    
    /*  -------------------------------------------- Get Purchase Order List -------------------------------------------*/
    builder.addCase(getPurchaseOrdersList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPurchaseOrdersList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.purchasOrderData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPurchaseOrdersList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /*  -------------------------------------------- Get Purchase Order Details -------------------------------------------*/
    builder.addCase(getPurchaseOrdersDetails.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPurchaseOrdersDetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.purchasOrderDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPurchaseOrdersDetails.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /*  --------------------------------------------Get Purchase Order Items List-------------------------------------------*/
    builder.addCase(getPurchaseOrdersitems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPurchaseOrdersitems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.PurchaseOrdersitems = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getPurchaseOrdersitems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    // Add Purchase Order Item
    builder.addCase(addPurchaseOrderItems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addPurchaseOrderItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.PurchaseOrdersitems = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(addPurchaseOrderItems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    // Submit Purchase Order
    builder.addCase(submitPurchaseOrderReview.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(submitPurchaseOrderReview.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.PurchaseOrdersitems = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(submitPurchaseOrderReview.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    // Delete Purchase Order
    builder.addCase(DeleteItemFromPurchaseOrder.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(DeleteItemFromPurchaseOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.PurchaseOrdersitems = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(DeleteItemFromPurchaseOrder.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    // Approving Regected Purchase Order
    builder.addCase(approvingRegectedPurchaseOrder.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(approvingRegectedPurchaseOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.PurchaseOrdersitems = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(approvingRegectedPurchaseOrder.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    // Approving Regected Purchase Order
    builder.addCase(createPurchasing.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createPurchasing.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.PurchaseOrdersitems = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(createPurchasing.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  /* ---------------------------------------------  Edit  Purchase Order Items -------------------------------------------------- */
  builder.addCase(editPurchaseOrderItems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editPurchaseOrderItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editPurchaseOrderItems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  /* -------------------------------------  Edit Quantites Purchase Order Items ------------------------------------ */
  builder.addCase(editQuantityPurchaseOrderItems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editQuantityPurchaseOrderItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editQuantityPurchaseOrderItems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

       /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
   builder.addCase(getPurchaseOrderFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(getPurchaseOrderFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = payload;
    state.errors = null;
  });
  builder.addCase(getPurchaseOrderFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* -------------------------------------------------------------------------------------- */
  builder.addCase(resetPurchaseOrderFilteredData.pending, (state) => {
    state.isLoading = true;
    state.errors = null;
  });
  builder.addCase(resetPurchaseOrderFilteredData.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.filteredData = [];
    state.errors = null;
  });
  builder.addCase(resetPurchaseOrderFilteredData.rejected, (state) => {
    state.isLoading = false;
    state.errors = [{ msg: "something went wrong!" }];
  });
 /* --------------------------------------------------------------------------------------------------- */
  },
});

export default purchaseOrdersSlice.reducer;
