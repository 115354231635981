import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {Box,Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { createTax, getTaxes } from "../../services/taxesService";
import { getOrdersTypes } from "features/orders/services/ordersTypesService";

export default function CreateModal ({ onClose }){
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue,  register,  formState: { errors },  trigger} =  useForm();
  
  const ordersTypes = useSelector(state => state.ordersTypes);
  const taxes = useSelector(state => state.taxes);

  useEffect(() => {
    dispatch(getOrdersTypes());
  }, [dispatch]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.taxes.create_tax")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
              values.OrderTypeId = [{ OrderTypeId : values.OrderTypeId?.OrderTypeId }]|| null ;
              values.Rate = values.Rate;
              values.TaxsId = null;
              if(values.OrderTypeId === null){
                    toast2({ description: t("validation.choose_first")});
              }
              else {
                  dispatch(createTax(values))
                  .unwrap()
                  .then(_ => {
                    toast({ description: t("pages.taxes.tax_created") });
                    dispatch(getTaxes());
                    onClose();
                  })
              }
          })}>
            <Stack spacing={6}>

      <FormControl isInvalid={errors.TaxsName}>
          <FormLabel
            htmlFor="TaxsName"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.taxes.name")}
          </FormLabel>
          <Controller
            name="TaxsName"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.taxes.name")}
                onChange={(e) => {
                  handleInputChange(e, "TaxsName");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.TaxsName &&
              errors.TaxsName.message}
          </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.TaxsNameLocalized}>
          <FormLabel
            htmlFor="TaxsNameLocalized"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.taxes.name_localized")}
          </FormLabel>
          <Controller
            name="TaxsNameLocalized"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.taxes.name_localized")}
                onChange={(e) => {
                  handleInputChange(e, "TaxsNameLocalized");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.TaxsNameLocalized &&
              errors.TaxsNameLocalized.message}
          </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.Rate}>
            <FormLabel
              htmlFor="Rate"
              style={{ textTransform: "capitalize" }}
            >
              {t("pages.taxes.rate")}
            </FormLabel>
            <Controller
              name="Rate"
              control={control}
              defaultValue="0"
              rules={{
                required: t("validation.required"),
                validate: (value) => {
                  const intValue = parseInt(value, 10);
                  return (
                    intValue >= 0 || t("validation.must_be_non_negative")
                  );
                },
              }}
              // rules={{
              //   required: t("validation.required"),
              //   validate: {
              //     nonNegative: (value) => parseInt(value, 10) >= 0 || t("validation.must_be_non_negative"),
              //     lessThanOrEqual100: (value) => parseInt(value, 10) <= 100 || t("validation.must_be_less_than_or_equal_to_100"),
              //   },
              // }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t("pages.taxes.rate")}
                  onChange={(e) => {
                    handleInputChange(e, "Rate");
                  }}
                  type="number"
                  min="0"
                  // max="100"
                />
              )}
            />
            <FormErrorMessage>
              {errors.Rate &&
                errors.Rate.message}
            </FormErrorMessage>
      </FormControl>
      
      <MultiSelect
        data={ordersTypes.data && ordersTypes.data.map(el => ({
          ...el,
          label: el.OrderTypeName,
          value: el.OrderTypeId
        }))}
        isRequired
        title={t("pages.taxes.order_type")}
        onChange={(e) => setValue("OrderTypeId", e)}
      />

              <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    isLoading={taxes.isLoading}
                    hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    isLoading={taxes.isLoading}
                    hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                  />
              </Flex>

            </Stack>
          </form>
        </Box>
      )}
    />
  )
};