import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import { Flex, Text, Spinner, Button } from "@chakra-ui/react";
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import CreateModal from "../components/modals/CreateModal";
import { IoCloseOutline } from "react-icons/io5";

import ImportModal from "../components/modals/ImportModal";
import ExportModal from "components/ui/modal/ExportModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { getDiscounts, resetFilteredData } from "../services/discountsService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Discounts = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 42);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const discounts = useSelector((state) => state.discounts);
  const filteredDiscounts = useSelector((state) => state.discounts);

  const [showCreateDiscountModal, setShowCreateDiscountModal] = useState(null);
  const [showImportDiscountsModal, setShowImportDiscountsModal] =
    useState(null);
  const [showFilterDiscountsModal, setShowFilterDiscountsModal] =
    useState(null);
  const [exportModal, SetExportModal] = useState(false);

  const [TableStatus, setTableStatus] = useState("all");

  useEffect(() => {
    dispatch(getDiscounts());
  }, [dispatch]);

  const resetFunction = () => {
    dispatch(resetFilteredData());
  };

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.discounts.discounts")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
            >
              <div onClick={() => SetExportModal(true)}>
                {t("general.export")}
              </div>
            </Button>
            <GButton
              title={t("pages.discounts.create_discount")}
              onClick={() => setShowCreateDiscountModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color={TableStatus === "all" ? "primary" : "text"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={TableStatus === "all" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setTableStatus("all")}
          />

          <GButton
            title={t("general.deleted")}
            bg="none"
            color={TableStatus === "deleted" ? "primary" : "text"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={TableStatus === "deleted" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setTableStatus("deleted")}
          />
        </Flex>

        <GButton
          title={
            filteredDiscounts.filteredData.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
            filteredDiscounts.filteredData.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          onClick={
            filteredDiscounts.filteredData.length === 0
              ? () => setShowFilterDiscountsModal(true)
              : resetFunction
          }
        />
      </Flex>

      {discounts.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <Table
          discountsSelectedTable={TableStatus}
          discounts={discounts.data}
          onClickCreateDiscountBtn={() => setShowCreateDiscountModal(true)}
        />
      )}

      {showCreateDiscountModal && (
        <CreateModal onClose={() => setShowCreateDiscountModal(null)} />
      )}

      {showImportDiscountsModal && (
        <ImportModal onClose={() => setShowImportDiscountsModal(null)} />
      )}

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={
            TableStatus === "deleted"
              ? discounts.data.filter((el) => el.IsDeleted === true)
              : discounts.data.filter((el) => el.IsDeleted === false)
          }
          fileName={
            TableStatus === "deleted" ? "Deleted Discounts" : "Discounts"
          }
        ></ExportModal>
      )}

      {showFilterDiscountsModal && (
        <FilterModal
          onClose={() => setShowFilterDiscountsModal(null)}
          data={discounts.data}
        />
      )}
    </>
  );
};

export default Discounts;
