import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GoCheckCircleFill, GoXCircleFill } from "react-icons/go";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({ products, tabs }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const navigatefunction = (id) => {
    navigate(`${id}`);
  };
  const filteredProducts = useSelector((state) => state.products);

  const ActiveProducts = products.filter(
    (product) => product.IsActive === true
  );
  const InActiveProducts = products.filter(
    (product) => product.IsActive === false
  );
  const ActiveFilteredProducts = filteredProducts.filteredData.filter(
    (product) => product.IsActive === true
  );
  const InActiveFilteredProducts = filteredProducts.filteredData.filter(
    (product) => product.IsActive === false
  );

  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const [currentPageInActive, setCurrentPageInActive] = useState(1);
  const [filteredPageAll, setFilteredPageAll] = useState(1);
  const [filteredPageActive, setFilteredPageActive] = useState(1);
  const [filteredPageInActive, setFilteredPageInActive] = useState(1);

  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const recordsPerPage = 10;

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  const recordsAll = calculateRecords(products, currentPageAll, recordsPerPage);

  const recordsActive = calculateRecords(
    ActiveProducts,
    currentPageActive,
    recordsPerPage
  );
  const recordsInActive = calculateRecords(
    InActiveProducts,
    currentPageInActive,
    recordsPerPage
  );

  const filteredRecordsAll = calculateRecords(
    filteredProducts.filteredData,
    filteredPageAll,
    recordsPerPage
  );
  const filteredRecordsActive = calculateRecords(
    ActiveFilteredProducts,
    filteredPageActive,
    recordsPerPage
  );
  const filteredRecordsInActive = calculateRecords(
    InActiveFilteredProducts,
    filteredPageInActive,
    recordsPerPage
  );
  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      {tabs === "active" ? (
        filteredProducts.filteredData.length === 0 ? (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("general.img")}</Th>
                  <Th>{t("pages.products.name")}</Th>
                  <Th>{t("pages.products.sku")}</Th>
                  <Th>{t("pages.products.category")}</Th>
                  <Th>{t("pages.products.price")}</Th>
                  <Th>{t("pages.products.tax_group")}</Th>
                  <Th>{t("pages.products.active")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recordsActive.map((product, index) => (
                  <Tr
                    key={index}
                    onClick={() => navigatefunction(product.ProductId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td
                      style={{
                        width: "200px",
                        height: "100px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={`https://rostoapi.smartgate-egypt.com/${product.ProductImagePath}`}
                        alt="img"
                        className="activeIcon"
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          objectFit: "contain",
                          borderRadius: "5%",
                        }}
                      />
                    </Td>
                    <Td style={{ maxWidth: "150px" }}>{product.ProductName}</Td>
                    <Td>{product.ProductSKURef}</Td>
                    <Td>{product.ProductCategoriesNameEng}</Td>
                    <Td>{product.ProductPrice}</Td>
                    <Td>{product.TaxGroupName}</Td>
                    <Td className="activeIcon">
                      {product.IsActive ? (
                        <GoCheckCircleFill size="25px" />
                      ) : (
                        <GoXCircleFill size="25px" />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={currentPageAll}
              setCurrentPage={setCurrentPageAll}
              npage={Math.ceil(products.length / recordsPerPage)}
              prePage={() => prePage(currentPageAll, setCurrentPageAll)}
              nextPage={() =>
                nextPage(
                  currentPageAll,
                  setCurrentPageAll,
                  Math.ceil(products.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("general.img")}</Th>
                  <Th>{t("pages.products.name")}</Th>
                  <Th>{t("pages.products.sku")}</Th>
                  <Th>{t("pages.products.category")}</Th>
                  <Th>{t("pages.products.price")}</Th>
                  <Th>{t("pages.products.tax_group")}</Th>
                  <Th>{t("pages.products.active")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredRecordsActive.map((product, index) => (
                  <Tr
                    key={index}
                    onClick={() => navigatefunction(product.ProductId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td
                      style={{
                        width: "200px",
                        height: "100px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={`https://rostoapi.smartgate-egypt.com/${product.ProductImagePath}`}
                        alt="img"
                        className="activeIcon"
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          objectFit: "contain",
                          borderRadius: "5%",
                        }}
                      />
                    </Td>
                    <Td style={{ maxWidth: "150px" }}>{product.ProductName}</Td>
                    <Td>{product.ProductSKURef}</Td>
                    <Td>{product.ProductCategoriesNameEng}</Td>
                    <Td>{product.ProductPrice}</Td>
                    <Td>{product.TaxGroupName}</Td>
                    <Td className="activeIcon">
                      {product.IsActive ? (
                        <GoCheckCircleFill size="25px" />
                      ) : (
                        <GoXCircleFill size="25px" />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={filteredPageActive}
              setCurrentPage={setFilteredPageActive}
              npage={Math.ceil(ActiveFilteredProducts.length / recordsPerPage)}
              prePage={() => prePage(filteredPageActive, setFilteredPageActive)}
              nextPage={() =>
                nextPage(
                  filteredPageActive,
                  setFilteredPageActive,
                  Math.ceil(ActiveFilteredProducts.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        )
      ) : tabs === "inActive" ? (
        filteredProducts.filteredData.length === 0 ? (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("general.img")}</Th>
                  <Th>{t("pages.products.name")}</Th>
                  <Th>{t("pages.products.sku")}</Th>
                  <Th>{t("pages.products.category")}</Th>
                  <Th>{t("pages.products.price")}</Th>
                  <Th>{t("pages.products.tax_group")}</Th>
                  <Th>{t("pages.products.active")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recordsInActive.map((product, index) => (
                  <Tr
                    key={index}
                    onClick={() => navigatefunction(product.ProductId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td
                      style={{
                        width: "200px",
                        height: "100px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={`https://rostoapi.smartgate-egypt.com/${product.ProductImagePath}`}
                        alt="img"
                        className="activeIcon"
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          objectFit: "contain",
                          borderRadius: "5%",
                        }}
                      />
                    </Td>
                    <Td style={{ maxWidth: "150px" }}>{product.ProductName}</Td>
                    <Td>{product.ProductSKURef}</Td>
                    <Td>{product.ProductCategoriesNameEng}</Td>
                    <Td>{product.ProductPrice}</Td>
                    <Td>{product.TaxGroupName}</Td>
                    <Td className="activeIcon">
                      {product.IsActive ? (
                        <GoCheckCircleFill size="25px" />
                      ) : (
                        <GoXCircleFill size="25px" />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={currentPageInActive}
              setCurrentPage={setCurrentPageInActive}
              npage={Math.ceil(InActiveProducts.length / recordsPerPage)}
              prePage={() =>
                prePage(currentPageInActive, setCurrentPageInActive)
              }
              nextPage={() =>
                nextPage(
                  currentPageInActive,
                  setCurrentPageInActive,
                  Math.ceil(InActiveProducts.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("general.img")}</Th>
                  <Th>{t("pages.products.name")}</Th>
                  <Th>{t("pages.products.sku")}</Th>
                  <Th>{t("pages.products.category")}</Th>
                  <Th>{t("pages.products.price")}</Th>
                  <Th>{t("pages.products.tax_group")}</Th>
                  <Th>{t("pages.products.active")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredRecordsInActive.map((product, index) => (
                  <Tr
                    key={index}
                    onClick={() => navigatefunction(product.ProductId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td
                      style={{
                        width: "200px",
                        height: "100px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={`https://rostoapi.smartgate-egypt.com/${product.ProductImagePath}`}
                        alt="img"
                        className="activeIcon"
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          objectFit: "contain",
                          borderRadius: "5%",
                        }}
                      />
                    </Td>
                    <Td style={{ maxWidth: "150px" }}>{product.ProductName}</Td>
                    <Td>{product.ProductSKURef}</Td>
                    <Td>{product.ProductCategoriesNameEng}</Td>
                    <Td>{product.ProductPrice}</Td>
                    <Td>{product.TaxGroupName}</Td>
                    <Td className="activeIcon">
                      {product.IsActive ? (
                        <GoCheckCircleFill size="25px" />
                      ) : (
                        <GoXCircleFill size="25px" />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={filteredPageInActive}
              setCurrentPage={setFilteredPageInActive}
              npage={Math.ceil(
                InActiveFilteredProducts.length / recordsPerPage
              )}
              prePage={() =>
                prePage(filteredPageInActive, setFilteredPageInActive)
              }
              nextPage={() =>
                nextPage(
                  filteredPageInActive,
                  setFilteredPageInActive,
                  Math.ceil(InActiveFilteredProducts.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        )
      ) : filteredProducts.filteredData.length === 0 ? (
        <Fragment>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{t("general.img")}</Th>
                <Th>{t("pages.products.name")}</Th>
                <Th>{t("pages.products.sku")}</Th>
                <Th>{t("pages.products.category")}</Th>
                <Th>{t("pages.products.price")}</Th>
                <Th>{t("pages.products.tax_group")}</Th>
                <Th>{t("pages.products.active")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {recordsAll.map((product, index) => (
                <Tr
                  key={index}
                  onClick={() => navigatefunction(product.ProductId)}
                  style={{ cursor: "pointer" }}
                >
                  <Td>{index + 1}</Td>
                  <Td
                    style={{
                      width: "200px",
                      height: "100px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={`https://rostoapi.smartgate-egypt.com/${product.ProductImagePath}`}
                      alt="img"
                      className="activeIcon"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                        borderRadius: "5%",
                      }}
                    />
                  </Td>
                  <Td style={{ maxWidth: "150px" }}>{product.ProductName}</Td>
                  <Td>{product.ProductSKURef}</Td>
                  <Td>{product.ProductCategoriesNameEng}</Td>
                  <Td>{product.ProductPrice}</Td>
                  <Td>{product.TaxGroupName}</Td>
                  <Td className="activeIcon">
                    {product.IsActive ? (
                      <GoCheckCircleFill size="25px" />
                    ) : (
                      <GoXCircleFill size="25px" />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            currentPage={currentPageAll}
            setCurrentPage={setCurrentPageAll}
            npage={Math.ceil(products.length / recordsPerPage)}
            prePage={() => prePage(currentPageAll, setCurrentPageAll)}
            nextPage={() =>
              nextPage(
                currentPageAll,
                setCurrentPageAll,
                Math.ceil(products.length / recordsPerPage)
              )
            }
          />
        </Fragment>
      ) : (
        <Fragment>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{t("general.img")}</Th>
                <Th>{t("pages.products.name")}</Th>
                <Th>{t("pages.products.sku")}</Th>
                <Th>{t("pages.products.category")}</Th>
                <Th>{t("pages.products.price")}</Th>
                <Th>{t("pages.products.tax_group")}</Th>
                <Th>{t("pages.products.active")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredRecordsAll.map((product, index) => (
                <Tr
                  key={index}
                  onClick={() => navigatefunction(product.ProductId)}
                  style={{ cursor: "pointer" }}
                >
                  <Td>{index + 1}</Td>
                  <Td
                    style={{
                      width: "200px",
                      height: "100px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={`https://rostoapi.smartgate-egypt.com/${product.ProductImagePath}`}
                      alt="img"
                      className="activeIcon"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                        borderRadius: "5%",
                      }}
                    />
                  </Td>
                  <Td style={{ maxWidth: "150px" }}>{product.ProductName}</Td>
                  <Td>{product.ProductSKURef}</Td>
                  <Td>{product.ProductCategoriesNameEng}</Td>
                  <Td>{product.ProductPrice}</Td>
                  <Td>{product.TaxGroupName}</Td>
                  <Td className="activeIcon">
                    {product.IsActive ? (
                      <GoCheckCircleFill size="25px" />
                    ) : (
                      <GoXCircleFill size="25px" />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            currentPage={filteredPageAll}
            setCurrentPage={setFilteredPageAll}
            npage={Math.ceil(
              filteredProducts.filteredData.length / recordsPerPage
            )}
            prePage={() => prePage(filteredPageAll, setFilteredPageAll)}
            nextPage={() =>
              nextPage(
                filteredPageAll,
                setFilteredPageAll,
                Math.ceil(filteredProducts.filteredData.length / recordsPerPage)
              )
            }
          />
        </Fragment>
      )}
    </Box>
  );
};
export default CTable;
