import React, { useEffect, useState } from "react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, SimpleGrid,Spinner,Stack, Text, useToast } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {getPurchaseOrdersDetails,getPurchaseOrdersitems,} from "../services/purchasrOrderServices";
import PurchaseOrderItems from "../components/PurchaseOrderItems";
import EditPurchaseOrderItemModal from "../components/modals/EditPurchaseOrderItemModal";
import SubmitPurchaseOrder from "../components/modals/SubmitPurchaseOrder";
import DeleteItemPurchaseOrderModal from "../components/modals/DeleteItemPurchaseOrderModal";
import ApprovingModal from "../components/modals/ApprovinModal";
import RejectedModal from "../components/modals/RejectedModal";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege .jsx";


export default function PurchaseOrdersDetails (){
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const {purchaseOrderId} = useParams();

  const [showDeleteFromPurchaseOrderModal,setShowDeleteFromPurchaseOrderModal,] = useState(false);
  const [showApprovingModal, setShowApprovingModal] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [showEditItemPurchaseOrderModal, setShowEditItemPurchaseOrderModal] =useState(false);
  const [showSubmitReviewModal, setShowSubmitReviewModal] = useState(false);

  const items= useSelector((state) => state.purchaseOrder);

  useEffect(() => {
    dispatch(getPurchaseOrdersitems(purchaseOrderId));
  }, [dispatch, purchaseOrderId]);



  useEffect(() => {
    dispatch(getPurchaseOrdersDetails(purchaseOrderId))
  }, [dispatch , purchaseOrderId])

  const handlePrint = () => {
    window.print();
  };
/* -----------------------------------Privilige-------------------------------*/
  const retrievedArray =useDecryptedPrivilege()
  const ManagePurchaseOrder = checkIdExists(retrievedArray, 14);
  const ManageApprovingRejecting = checkIdExists(retrievedArray, 15);

  if(showSubmitReviewModal===true && ManagePurchaseOrder===false){
    toast2({ description:  t("general.authorization")});
    setShowSubmitReviewModal(false);
  };
  if(showEditItemPurchaseOrderModal===true && ManagePurchaseOrder===false){
    toast2({ description:  t("general.authorization")});
    setShowEditItemPurchaseOrderModal(false);
  };
  if(showDeleteFromPurchaseOrderModal===true && ManagePurchaseOrder===false){
    toast2({ description:  t("general.authorization")});
    setShowDeleteFromPurchaseOrderModal(false);
  };
  if(showApprovingModal===true && ManageApprovingRejecting===false){
    toast2({ description:  t("general.authorization")});
    setShowApprovingModal(false);
  };
  if(showRejectedModal===true && ManageApprovingRejecting===false){
    toast2({ description:  t("general.authorization")});
    setShowRejectedModal(false);
  };
/* -----------------------------------Privilige-------------------------------*/
  return (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            {t("layout.sidebar.purchase_orders")}
            <Box
              bg={"#718096"}
              display={"inline-block"}
              marginX={"5px"}
              borderRadius={"5px"}
              color={"white"}
              fontSize={"15px"}
              padding={" 2px 10px"}
            >
              {t(`${items.purchasOrderDetails[0]?.Status}`)}
            </Box>
          </Box>
          <Flex gap={2}>
            {/* Print Button */}
            <GButton
              prefixElement={<FiIcons.FiPrinter size={16} />}
              title={t("pages.purchase_orders.print")}
              bg="light"
              color="text"
              // hover={{ bg: "primary" }}
              border="1px"
              borderColor={"#CBD5E0"}
              opacity="0.7"
              onClick={() => handlePrint()}
            />
            {/* Edit Button */}
            { items.purchasOrderDetails[0]?.Status !== "Approved" ?
              <GButton
                prefixElement={<FiIcons.FiEdit2 size={16} />}
                title={t("pages.purchase_orders.edit")}
                bg="light"
                color="text"
                border="1px"
                borderColor={"#CBD5E0"}
                opacity="0.7"
                onClick={() => setShowEditItemPurchaseOrderModal(true)}
              /> : ""
            }
            {items.purchasOrderDetails[0]?.Status === "Draft" ? (
              <>
                <GButton
                  prefixElement={<FiIcons.FiTrash2 size={16} />}
                  title={t("pages.purchase_orders.delete_permenantly")}
                  bg="light"
                  color="text"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  opacity="0.7"
                  onClick={() => setShowDeleteFromPurchaseOrderModal(true)}
                />

                <GButton
                  title={t("pages.purchase_orders.submit_for_review")}
                  bg="#54A0FF"
                  color="light"
                  hover={{ bg: "primary" }}
                  onClick={() => setShowSubmitReviewModal(true)}
                />
              </>
            ) : (
              ""
            )}
            {items.purchasOrderDetails[0]?.Status === "Pending" ? (
              <>
                <GButton
                  prefixElement={<FiIcons.FiTrash2 size={16} />}
                  title={t("pages.purchase_orders.reject")}
                  bg="light"
                  color="text"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  opacity="0.7"
                  onClick={() => setShowRejectedModal(true)}
                />
                <GButton
                  title={t("pages.purchase_orders.approve")}
                  bg="#54A0FF"
                  color="light"
                  hover={{ bg: "primary" }}
                  onClick={() => setShowApprovingModal(true)}
                />
              </>
            ) : (
              ""
            )}
            {items.purchasOrderDetails[0]?.Status === "Approved" ? (
              <>
                {/* <GButton
                  prefixElement={<FiIcons.FiTrash2 size={16} />}
                  title={t("pages.purchase_orders.close")}
                  bg="light"
                  color="text"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  opacity="0.7"
                  onClick={() => setShowRejectedModal(true)}
                />
                <GButton
                  title={t("pages.purchase_orders.creat_purchasing")}
                  bg="#54A0FF"
                  color="light"
                  hover={{ bg: "primary" }}
                  onClick={() => setShowCreatePurchsing(true)}
                /> */}
              </>
            ) : (
              ""
            )}
          </Flex>
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
      {
        items?.isLoading ?
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        :
          <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text" opacity={"0.7"}>
                {t("pages.purchase_orders.suplliers")}
              </Text>
              <Text marginBlock={2} fontWeight={"bold"}>
                {items.purchasOrderDetails[0]?.SuppliersName || "-"}
              </Text>
            </Box>

            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text" opacity={"0.7"}>
                {t("pages.purchase_orders.destination")}
              </Text>
              <Text marginBlock={2} fontWeight={"bold"}>
                {items.purchasOrderDetails[0]?.Destination || "-"}
              </Text>
            </Box>

            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text" opacity={"0.7"}>
                {t("pages.inventory.business_date")}
              </Text>
              <Text marginBlock={2} fontWeight={"bold"}>
                {items.purchasOrderDetails[0]?.BusinessDate || "-"}
              </Text>
            </Box>

            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text" opacity={"0.7"}>
                {t("pages.purchase_orders.creator")}
              </Text>
              <Text marginBlock={2} fontWeight={"bold"}>
              {/* {items.purchasOrderDetails[0]?.Submitter || "-"} */}

              </Text>
            </Box>

            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text" opacity={"0.7"}>
                {t("pages.purchase_orders.created")}
              </Text>
              <Text marginBlock={2} fontWeight={"bold"}>
              {items.purchasOrderDetails[0]?.CreatedDate || "-"}
              </Text>
            </Box>
          </SimpleGrid>
        }

        {/* Purchase Order*/}
        <PurchaseOrderItems data={items.PurchaseOrdersitems} id={purchaseOrderId}/>

        {/*Show Add Purchase Order Modal*/}
        {showSubmitReviewModal && (
          <SubmitPurchaseOrder
            onClose={() => setShowSubmitReviewModal(false)}
            id={purchaseOrderId}
          ></SubmitPurchaseOrder>
        )}

        {/*Show Approving Modal*/}
        {showApprovingModal && (
          <ApprovingModal
            onClose={() => setShowApprovingModal(false)}
            id={purchaseOrderId}
          ></ApprovingModal>
        )}

        {/*Show Rejected Modal*/}
        {showRejectedModal && (
          <RejectedModal
            onClose={() => setShowRejectedModal(false)}
            id={purchaseOrderId}
          ></RejectedModal>
        )}
        
        {/*Show Edit Item In Purchase Order Modal*/}
        {showEditItemPurchaseOrderModal && (
          <EditPurchaseOrderItemModal
            onClose={() => setShowEditItemPurchaseOrderModal(false)}
            id={purchaseOrderId}
          ></EditPurchaseOrderItemModal>
        )}

        {showDeleteFromPurchaseOrderModal && (
          <DeleteItemPurchaseOrderModal
            onClose={() => setShowDeleteFromPurchaseOrderModal(false)}
            id={purchaseOrderId}
          ></DeleteItemPurchaseOrderModal>
        )}

      </Stack>

    </>
  );
};

