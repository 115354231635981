import { createSlice } from "@reduxjs/toolkit";
import {getCustomerPhoneNumber,getorderTypesforcallCenter,getBranchesForCallCenter,getPaymentMethodForCallCenter,createCallCenterOrder,getAllProductsForOrder, getAgents, getPaymentModes, getMenuGroupForCallCenter,customerDetails,GetAllCatigoresWithProducts,modifayCallCenterState} from "../service/callCenterService"


let initialState = {
    isLoading: false,
    phoneNumbers: [],
    users:[],
    orderType:[],
    branches:[],
    menuGroup:[],
    paymentmethod:[],
    paymentModes:[],
    data:[],
    allProducts:[],
    pages: 0,
    errors: null,
    customerDetails:[],
    catigoryWithProducts:[]
  };

  const callCenterSlice = createSlice({
    name: "callcenter",
    initialState,

    extraReducers:(builder)=>{

        builder.addCase(getCustomerPhoneNumber.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getCustomerPhoneNumber.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.phoneNumbers = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
    
          builder.addCase(getCustomerPhoneNumber.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })



          //


          builder.addCase(getorderTypesforcallCenter.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getorderTypesforcallCenter.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.orderType = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
    
          builder.addCase(getorderTypesforcallCenter.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })



          //


          
          builder.addCase(getAgents.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getAgents.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.users = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.users)
            state.errors = null;
          })
    
          builder.addCase(getAgents.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          builder.addCase(getBranchesForCallCenter.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getBranchesForCallCenter.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.branches = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
    
          builder.addCase(getBranchesForCallCenter.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          builder.addCase(getMenuGroupForCallCenter.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getMenuGroupForCallCenter.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.menuGroup = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
    
          builder.addCase(getMenuGroupForCallCenter.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })



          //




          builder.addCase(getPaymentMethodForCallCenter.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getPaymentMethodForCallCenter.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.paymentmethod = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
    
          builder.addCase(getPaymentMethodForCallCenter.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          builder.addCase(getPaymentModes.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getPaymentModes.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.paymentModes = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
    
          builder.addCase(getPaymentModes.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          //




          builder.addCase(createCallCenterOrder.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(createCallCenterOrder.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            const newData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            
            // Add the "pending" key with the value true to each item in newData
            const newDataWithPending = newData.map(item => ({ ...item, pending: true }));
            
            state.data = [...state.data, ...newDataWithPending];
            //console.log(state.data);
            state.errors = null;
          });
    
          builder.addCase(createCallCenterOrder.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //

          


          builder.addCase(modifayCallCenterState.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(modifayCallCenterState.fulfilled, (state, { payload }) => {
            console.log(payload)
            state.isLoading = false;
            state.data = payload
            state.errors = null;
          });
    
          builder.addCase(modifayCallCenterState.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
       

          builder.addCase(customerDetails.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(customerDetails.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            // const newData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            // state.data = [...state.data, ...newData];
            //console.log(state.data);
            state.customerDetails = payload
            //console.log(state.customerDetails)
            state.errors = null;
          });
    
          builder.addCase(customerDetails.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
       
          
          builder.addCase(GetAllCatigoresWithProducts.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(GetAllCatigoresWithProducts.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.catigoryWithProducts = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            console.log(state.catigoryWithProducts)
            state.errors = null;
          })
    
          builder.addCase(GetAllCatigoresWithProducts.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


    }

})

export default callCenterSlice.reducer