import { Box, Flex, Stack,Input as ChInput, useToast } from '@chakra-ui/react'
import GButton from 'components/ui/button/Button'
import GModal from 'components/ui/modal/Modal'
import { GetOrderDetails, payThereceiptService } from 'features/orders/services/ordersService'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const PaymentModalDineIn = ({onClose,TotalPrice}) => {
  //console.log(TotalPrice)
  const { t } = useTranslation();

  const [customerPay,SetCustomerPay]=useState()
  const [showCutomFiled,SetshowCustomFiled]=useState(false)
  const [cutsomerCharge,setcustCharge]=useState()
  const [showCharge,setShowCharge]=useState(false)

const handelCustomerCharge=(num)=>{
  SetCustomerPay(num)
}
 
//console.log(customerPay)


const toast3 = useToast({ position: "top", duration: 3000, status: "error" });

const ApplyCharge=()=>{
  const Charge=customerPay-TotalPrice
  if(Charge<0)
  {
    toast3({
      description:t("pages.orders.chargeError") ,
    });
  }else{
    setcustCharge(Charge)
    setShowCharge(true)
  }
}

const id =useParams()
const dispatch=useDispatch()
  
const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

const payThereceipt=()=>{
  const dataSend=[{
      OrderId:id.id
    }]
    dispatch(payThereceiptService(dataSend)).unwrap()
    .then((_) => {
        onClose()
      dispatch(GetOrderDetails(id.id));
      toast({ description: t("pages.orders.payedDone") });
  });
}

  return (
    <GModal
    onClose={onClose}
    modalHeader="payment"
    modalContent={
      <Box p={4}>
      {
        showCharge?(      
          <>
          <div style={{ width: "100%", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer",marginBottom:"10px",color:"white" }} >{cutsomerCharge}
          </div>
             <Flex style={{justifyContent:"space-between"}}>
             
             <GButton
             onClick={()=>payThereceipt()}
             title={t("pages.orders.pay")}
             bg="primary"
             color="light"
             border="1px solid transparent"
             hover={{
               color: "primary",
               borderColor: "primary",
               bg: "none",
             }}
           />


        
             </Flex>
      
          </>  
          )
          :
          (
            <Stack>

            <div style={{ width: "100%", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white",cursor:"pointer" ,color:"black"}}>{t("pages.orders.totalPrice")} : {TotalPrice}</div>

          <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",gap:"15px"}} >
              <div style={{ width: "200px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer",color:"white" }} onClick={()=>handelCustomerCharge(10)}>10</div>
              <div style={{ width: "200px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer" ,color:"white"}} onClick={()=>handelCustomerCharge(50)}>50</div>
            </div>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",gap:"15px"}}>
              <div style={{ width: "200px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer",color:"white" }} onClick={()=>handelCustomerCharge(100)}>100</div>
              <div style={{ width: "200px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer" ,color:"white"}} onClick={()=>handelCustomerCharge(200)}>200</div>
            </div>
          </div>
          <div style={{ width: "100%", height: "80px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "gray",cursor:"pointer" ,color:"white"}} onClick={()=>SetshowCustomFiled(!showCutomFiled)}>
          {t("pages.orders.custom")}
        </div>
           
        {
          showCutomFiled&&(
            <ChInput  
            type='number'   
            placeholder={t("pages.orders.custom")}
            onChange={(e)=>SetCustomerPay(e.target.value)}
        />
          )
        }
    
        

    
        
    
       
          <Flex gap={4}>
        
          <GButton
            title={t("general.close")}
            onClick={onClose}
            bg="red.600"
            border="1px solid transparent"
            color="light"
            hover={{
              color: "red.600",
              borderColor: "red.600",
              bg: "none",
            }}
          />
    
          
          <GButton
          onClick={()=>ApplyCharge()}
          title={t("general.save")}
          bg="primary"
          color="light"
          border="1px solid transparent"
          hover={{
            color: "primary",
            borderColor: "primary",
            bg: "none",
          }}
        />
        </Flex>
          </Stack>)
      }
      

      
  
      </Box>
    }/>
  )
}

export default PaymentModalDineIn
