import React from "react";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";
import { deleteCostAdjustments, getCostAdjustmentList } from "../../services/costAdjustment";

export default function DeleteCostAdjustment({ onClose, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });

  return <>
      <>
      <GDeleteModal
        onClose={onClose}
        onSubmit={() => {
          dispatch(deleteCostAdjustments(id))
            .unwrap()
            .then((_) => {
              toast({ description: t("pages.cost_adjustment.delete_cost") });
              dispatch(getCostAdjustmentList());
              onClose();
              navigate("/inventory/costAdjsutment")
            });
        }}
      />
    </>
  </>
}
