import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Button,
  Flex,
  SimpleGrid,
  Input as ChInput,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Input from "components/form/Input";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { formatDate } from "utils/FormatDate";
import { filterObjectsByValue } from "utils/DynamicFilter";
import { useDispatch, useSelector } from "react-redux";
import { getAcoountCycleNew } from "features/general_accounts/AccountAdd/service/AccountAddService";
import { GetAccByFinancialId } from "features/general_accounts/DailyRestrictions/service/DailyRestrictoionsServers";
import { MdOutlineChangeCircle } from "react-icons/md";
import { convertDateFormat } from "utils/convertDate";
import { getTimeStamp } from "utils/Utils_ForAccounting/RecordTimeStamp";
import { AddExpensesJornul } from "../service/ExpensesService";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import { checkIdExists } from "utils/cheackAuth";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const ExpensesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  useEffect(() => {
    const dataSend = { DateFrom: "0" };
    dispatch(getAcoountCycleNew(dataSend));
  }, [dispatch]);

  const AccountCycleData = useSelector((state) => state.AccountAdd);

  const filteredArray = filterObjectsByValue(
    AccountCycleData.accountCycle,
    "IsClosed",
    false
  );

  const [selectedPeriod, setSelectedPeriod] = useState(null);

  useEffect(() => {
    if (selectedPeriod === null) {
    } else {
      dispatch(GetAccByFinancialId(selectedPeriod?.FinancialPeriodsId));
    }
  }, [dispatch, selectedPeriod]);

  const AccountData = useSelector((state) => state.DailyRestrictions);

  const [filterByName, setFilterByName] = useState(true);

  watch(["AccountName"]);

  /* -------------------------------------  Validation  ------------------------------ */
  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };
  /* ------------------------------------- Authrzation ------------------------------ */
  const retrievedArray = useDecryptedPrivilege();
  const ManageAccountant_Expenses = checkIdExists(retrievedArray, 1019);

  if (ManageAccountant_Expenses === false) {
    return <NotAuthorizedCompo />;
  }
  /* ---------------------------------------------------------------------------------- */

  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.Expenses.Expenses")}
          </Text>

          <Flex gap={2}></Flex>
        </Flex>
      </Box>

      <Box
        bg="light"
        marginTop={6}
        padding={5}
        borderTop="1px solid"
        borderColor="border"
        marginInline={0}
        borderRadius={5}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <form
            onSubmit={handleSubmit((values) => {
              if (
                values.FinancialPeriodsId === undefined ||
                values.AccountName === undefined
              ) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                values.OperationNo = values.BookNo;
                values.ManualNumber = values.BookNo;
                values.UserId = localStorage.getItem("userId");
                values.FinancialPeriodsId =
                  values.FinancialPeriodsId.FinancialPeriodsId;
                values.JournalHeaderID = null;
                values.FieldTDate_1 = convertDateFormat(values.TransactionDate);
                values.AccountName.JournalDetailID = null;
                values.AccountName.JournalDetailID = null;
                values.AccountName.Field_1 = convertDateFormat(
                  values.TransactionDate
                );
                values.AccountName.OperatingUserID = 1;
                values.AccountName.PageID = values.BookNo;
                values.AccountName.CurrencyCode = "";
                values.AccountName.CurrencyRate = 1;
                values.AccountName.Notes = "";
                values.AccountName.ReferenceNumber = "";
                values.AccountName.DescriptionAccountCode = "";
                values.AccountName.DescriptionID = 0;
                values.AccountName.SpecialRecordTrackID = 0;
                values.AccountName.Debit = 0;
                values.AccountName.Credit = values.Amount;
                values.AccountName.Field_3 = values.AccountName.AccountNumber;
                values.AccountName.DebitAmountInCurrency = 0;
                values.AccountName.DebitAmountInCurrency = values.Amount;
                values.AccountName.RecordTimeStamp = getTimeStamp();
                values.AccountName.Row_Numbe = "2";
                values.AccountName.Field_2 = values.BookNo;
                values.JournalDetail = [values.AccountName];
                values.AccountName = null;

                dispatch(AddExpensesJornul({ JournalHeader: values }))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.general_accounts.addExpenses"),
                    });
                    setValue("Amount", 0);
                    setValue("BookNo", 0);
                    setValue("AccountName", "");
                    setValue("ManualNumber", 0);
                    setValue("OperationNo", 0);
                    setValue("TransactionDate", undefined);
                    setValue("JournalDescription", 0);
                  });
              }
            })}
          >
            <SimpleGrid
              spacing={4}
              columns={{ base: 1, md: 2 }}
              bg="white"
              borderRadius={6}
              m={5}
              width={"100%"}
              columnGap={20}
              rowGap={5}
            >
              <FormControl isInvalid={errors.Amount}>
                <FormLabel
                  htmlFor="Amount"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.Expenses.amount")}
                </FormLabel>
                <Controller
                  name="Amount"
                  control={control}
                  defaultValue={"00.00"}
                  rules={{
                    required: t("validation.numbers_only"),
                    minLength: {
                      value: 2,
                      message: t("pages.hr.hr_validation.number_short_10"),
                    },
                    maxLength: {
                      value: 10,
                      message: t("pages.hr.hr_validation.number_long"),
                    },
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.Expenses.amount")}
                      onChange={(e) => {
                        handleInputChange(e, "Amount");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.Amount && errors.Amount.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.BookNo}>
                <FormLabel
                  htmlFor="BookNo"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.Expenses.code_number")}
                </FormLabel>
                <Controller
                  name="BookNo"
                  control={control}
                  defaultValue={"00"}
                  rules={{
                    required: t("validation.numbers_only"),
                    minLength: {
                      value: 2,
                      message: t("pages.hr.hr_validation.number_short_10"),
                    },
                    maxLength: {
                      value: 10,
                      message: t("pages.hr.hr_validation.number_long"),
                    },
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.Expenses.code_number")}
                      onChange={(e) => {
                        handleInputChange(e, "BookNo");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.BookNo && errors.BookNo.message}
                </FormErrorMessage>
              </FormControl>

              <MultiSelect
                title={t("pages.DailyRestrictions.finacial_period")}
                data={
                  filteredArray &&
                  filteredArray.map((el) => ({
                    ...el,
                    label: ` ${t("pages.AccountAdd.date_From")} : ${formatDate(
                      el.DateFrom
                    )} ${t("pages.AccountAdd.date_to")} ${formatDate(
                      el.DateTo
                    )}`,
                    value: el.FinancialPeriodsId,
                  }))
                }
                isMulti={false}
                onChange={(e) => {
                  setValue("FinancialPeriodsId", e);
                  setSelectedPeriod(e);
                }}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  gap: "15px",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setFilterByName(!filterByName)}
                >
                  <MdOutlineChangeCircle color="green" size={25} />
                </div>
                <MultiSelect
                  title={
                    filterByName
                      ? t("pages.Ledger.account_name")
                      : t("pages.Ledger.account_number")
                  }
                  data={AccountData.data?.map((el) => ({
                    ...el,
                    label: filterByName ? el.AccountName : el.AccountNumber,
                    value: el.AccountNumber,
                  }))}
                  isMulti={false}
                  onChange={(e) => setValue("AccountName", e)}
                  isDisabled={!selectedPeriod}
                />
              </div>

              <Input
                label={
                  filterByName
                    ? t("pages.Ledger.account_number")
                    : t("pages.Ledger.account_name")
                }
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={
                  filterByName
                    ? t("pages.Ledger.account_number")
                    : t("pages.Ledger.account_name")
                }
                value={
                  getValues(
                    filterByName
                      ? "AccountName.AccountNumber"
                      : "AccountName.AccountName"
                  ) || ""
                }
                readOnly
              />

              <FormControl isInvalid={errors.TransactionDate}>
                <FormLabel
                  htmlFor="TransactionDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.Expenses.data")}
                </FormLabel>
                <Controller
                  name="TransactionDate"
                  control={control}
                  // defaultValue={`${year}-${month}-${day}`}
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.Expenses.data")}
                      onChange={(e) => {
                        handleInputChange(e, "TransactionDate");
                      }}
                      type="date"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.TransactionDate && errors.TransactionDate.message}
                </FormErrorMessage>
              </FormControl>

              <Controller
                name="JournalDescription"
                control={control}
                render={({ field }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                      {t("pages.Expenses.description")}
                    </label>
                    <textarea
                      {...field}
                      required
                      style={{
                        width: "100%",
                        border: "2px solid gray",
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                    />
                  </div>
                )}
              />
            </SimpleGrid>

            <Box bg="light" padding={5} marginInline={0}>
              <Button
                type="submit"
                bg="primary"
                color="light"
                textTransform="capitalize"
                fontSize="1rem"
                _hover={{ color: "primary", background: "light" }}
                border={"1px solid gray"}
              >
                <Flex alignItems="center" gap={1}>
                  {t("pages.Expenses.save")}
                </Flex>
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </>
  );
};

export default ExpensesPage;
