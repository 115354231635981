import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import { Button, Flex, Text, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react"
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import { IoCloseOutline } from "react-icons/io5";
import NoDevices from "../components/NoDevices";
import CreateModal from "../components/modals/CreateModal";
import ExportModal from 'components/ui/modal/ExportModal';
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { getDevices, resetDevicesFilteredData } from "../services/devicesService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Devices = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 41);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const devices = useSelector(state => state.devices);

  const [showCreateDeviceModal, setShowCreateDeviceModal] = useState(null);
  const [exportModal,SetExportModal]=useState(false);
  const [sortType, setSortType] = useState("all");
  const [data,setData]=useState([]);

  useEffect(() => {
    dispatch(getDevices());
  }, [dispatch]);

  useEffect(()=>{
    setData(devices?.data)
  },[devices.data])


const functionSort=(type)=>{
  setSortType(type)

  if(type==="all"){
    setData(devices.data)
  }else{
    function filterObjectsByType(type) {
      return devices.data.filter(object => object.DevicesType[0]?.DevicesTypeName === type);
    }
    const filteredObjects = filterObjectsByType(type);
    setData(filteredObjects)
  }
  }

  // Show Filter Modal State
  const [showFilterModal, setShowFilterModal] = useState(false);

  const resetFunction = () => {
      dispatch(resetDevicesFilteredData());
    };


    if(ManageBranches===false  ){
      return(
        <NotAuthorizedCompo/>
      )
    }

  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.devices.devices")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
                type="button" bg="none" border="1px solid" borderColor="border"
                textTransform="capitalize" fontSize="1rem" color="text"
                _hover={{ bg: "primary", color: "light" }}
                onClick={() => SetExportModal(true)}
              >
                  <Text>{t("general.export")}</Text>
              </Button>

            <GButton
              title={t("pages.devices.create_device")}
              onClick={() => setShowCreateDeviceModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
      >
        <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color={sortType === "all" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "all" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={()=>functionSort("all")}
          />
          <GButton
            title={t("pages.devices.cashier")}
            bg="none"
            color={sortType === "Cahsier" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "Cahsier" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={()=>functionSort("Cahsier")}
          />
          <GButton
            title={t("pages.devices.kds")}
            bg="none"
            color={sortType === "KDS" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "KDS" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={()=>functionSort("KDS")}
          />
          <GButton
            title={t("pages.devices.notifier")}
            bg="none"
            color={sortType === "Notifier" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "Notifier" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={()=>functionSort("Notifier")}
          />
          <GButton
            title={t("pages.devices.display")}
            bg="none"
            color={sortType === "Display" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "Display" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={()=>functionSort("Display")}
          />
          <GButton
            title={t("pages.devices.sub_cashier")}
            bg="none"
            color={sortType === "Sub Cashier" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "Sub Cashier" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={()=>functionSort("Sub Cashier")}
          />
          <GButton
            title={t("pages.devices.one_pos")}
            bg="none"
            color={sortType === "One POS" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "One POS" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={()=>functionSort("One POS")}
          />
        </Flex>

            <GButton
                title={
                    devices?.filteredData?.length === 0
                    ? t("general.filter")
                    : t("general.clear_filter")
                }
                border="1px solid"
                borderColor="border"
                postfixElement={
                    devices?.filteredData?.length === 0 ? (
                    <FiIcons.FiFilter size={16} />
                    ) : (
                    <IoCloseOutline fontWeight={800} size={20} />
                    )
                }
                hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                onClick={
                    devices?.filteredData?.length === 0
                    ? () => setShowFilterModal(true)
                    : resetFunction
                }
            />

      </Flex>

      {devices?.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : devices?.data?.length === 0 ? (
        <NoDevices
          onClickCreateDeviceBtn={() => setShowCreateDeviceModal(true)}
        />
      ) :  devices?.filteredData?.length > 0  ? (
          <Table
          devices={devices?.filteredData}
          />
        )
      : (
          <Table
          devices={data}
        />
        )
      }

      {showCreateDeviceModal && (
        <CreateModal
          onClose={() => setShowCreateDeviceModal(null)}
        />
      )}
  {exportModal&&(
      <ExportModal 
        onClose={() => SetExportModal(false)}   
        data={devices.data}
        fileName={"devices"}
        >
      </ExportModal>
  )}

    {
        showFilterModal && (
            <FilterModal 
                onClose={()=>setShowFilterModal(false)} 
                data={devices.data} 
            />
        )
    }

    </>
  )
}

export default Devices