import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, Text, useToast, Textarea } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  createNewBankLetter,
  getBankLetterData,
  getEmployeeDropDown,
} from "features/hr/selfServices/services/HrSelfServices";

export default function AddBankLetterModal({ onClose }) {
  const UserId = localStorage.getItem("userId");
  const { t } = useTranslation();
  const disPatch = useDispatch();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const { handleSubmit, setValue } = useForm();

  const { employeeDropdown, BankDropdown } = useSelector(
    (state) => state.HrSelfServices
  );

  useEffect(() => {
    disPatch(getEmployeeDropDown(UserId));
  }, [disPatch, UserId]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.self_service_object.add_new_bank_letter")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.UserId = UserId;
                values.IsBank = 1;
                values.HrLetterRequestId = null;
                values.HrLetterTo = values.HrLetterTo?.BankNameEn;
                if (values.EmployeeId === undefined) {
                  toast2({
                    description: t("pages.hr.hr_validation.select_item"),
                  });
                } else {
                  disPatch(createNewBankLetter(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t(
                          "pages.hr.bankLetter_Created_succesfuly"
                        ),
                      });
                      disPatch(getBankLetterData(UserId));
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <Box>
                  <MultiSelect
                    title={t("pages.hr.self_service_object.select_employee")}
                    isMulti={false}
                    // defaultValue={{
                    //   label:
                    //     classifications[details?.EmployeeClassificationId - 1]
                    //       ?.EmployeeClassificationNameEn,
                    // }}
                    data={
                      employeeDropdown &&
                      employeeDropdown?.map((el) => ({
                        ...el,
                        label: el.EmployeeName,
                        value: el.EmployeeId,
                      }))
                    }
                    onChange={(e) => setValue("EmployeeId", e?.EmployeeId)}
                  />
                </Box>

                <Box>
                  <MultiSelect
                    title={t("pages.hr.bank_name")}
                    isMulti={false}
                    // defaultValue={{
                    //   label:
                    //     classifications[details?.EmployeeClassificationId - 1]
                    //       ?.EmployeeClassificationNameEn,
                    // }}
                    data={
                      BankDropdown &&
                      BankDropdown?.map((el) => ({
                        ...el,
                        label: el.BankNameEn,
                        value: el.BankId,
                      }))
                    }
                    onChange={(e) => setValue("HrLetterTo", e)}
                  />
                </Box>

                <Box>
                  <Text> {t("pages.hr.self_service_object.comments")}</Text>
                  <Textarea
                    placeholder="Here is a sample placeholder"
                    marginTop={"2"}
                    // defaultValue={item?.OfficialVacationCause}
                    onChange={(e) =>
                      setValue("HrLetterComment", e.target.value)
                    }
                  />
                </Box>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
