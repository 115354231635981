import { createSlice } from "@reduxjs/toolkit";
import {
  getReasons,
  createReason
  // updateReason,
  // deleteReason
} from "../services/reasonsService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null
};

const reasonsSlice = createSlice({
  name: "reasons",
  initialState,
  extraReducers: (builder) => {

    /* ---------------------------------------------------- Get Reasons ---------------------------------------------------*/
    builder.addCase(getReasons.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getReasons.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getReasons.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    /* ----------------------------------------------------Create Reasons ---------------------------------------------------*/
    builder.addCase(createReason.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createReason.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createReason.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    // builder.addCase(updateReason.pending, (state) => {
    //   state.isLoading = true;
    //   state.errors = null;
    // })
    // builder.addCase(updateReason.fulfilled, (state, { payload }) => {
    //   state.isLoading = false;
    //   // const indexAt = state.data.findIndex(reason => reason.id === payload.id);
    //   // state.data[indexAt] = payload;
    //   state.errors = null;
    // })
    // builder.addCase(updateReason.rejected, (state) => {
    //   state.isLoading = false;
    //   state.errors = [{ msg: "something went wrong!" }];
    // })

    // builder.addCase(deleteReason.pending, (state) => {
    //   state.isLoading = true;
    //   state.errors = null;
    // })
    // builder.addCase(deleteReason.fulfilled, (state, { payload }) => {
    //   state.isLoading = false;
    //   // state.data = state.data.filter(reason => reason.id !== payload.id);
    //   state.errors = null;
    // })
    // builder.addCase(deleteReason.rejected, (state) => {
    //   state.isLoading = false;
    //   state.errors = [{ msg: "something went wrong!" }];
    // })
  }
});

export default reasonsSlice.reducer;