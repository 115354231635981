import React from 'react';
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteQuantityAdjustment } from '../../services/quantityAdjustmentService';
import { useToast } from '@chakra-ui/react';
import routes from "navigations/routes";
import { useTranslation } from 'react-i18next';
const DeleteQuantityAdjustmentModal = ({onClose}) => {
    const id =useParams()
    //console.log(id.id)
    const dispatch=useDispatch()
    const navigate = useNavigate();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });

    const { t } = useTranslation();
    
  return (
    <GDeleteModal
        onClose={onClose}
        onSubmit={()=>{
            dispatch(deleteQuantityAdjustment(id.id)).unwrap().then(_ => {
                    toast({ description: t("pages.inventory.delete_item") });
                    onClose();
                    navigate(`/${routes.inventory_quantity_adjustment}`);
                  })
        }}
        
  />
  );
}

export default DeleteQuantityAdjustmentModal;
