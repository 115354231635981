import { SimpleGrid } from "@chakra-ui/react";
import PendingTransactionsTable from "../components/PendingTransactionsTable";
import ItemsReachedLowLevelTable from "../components/ItemsReachedLowLevelTable";
import Card from "features/dashboard/components/Card";
import { useEffect } from "react";
import { getDashbordInventory } from "features/dashboard/services/dashbordChartsServices";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const Inventory = () => {
  const retrievedArray =useDecryptedPrivilege()
  const mangeGeneral = checkIdExists(retrievedArray, 75);


  const dispatch = useDispatch();

  const dashbordChartsData = useSelector(state => state.dashbordChartsSlice);

  const inventoryData = [
    {
      title: "pages.dashboard.purchase_orders",
      count: dashbordChartsData?.dataInventory[0]?.Overall[0]?.PurchaseOrders ,
    },
    {
      title: "pages.dashboard.completed_transfers",
      count: dashbordChartsData?.dataInventory[0]?.Overall[0]?.Transfers ,
    },
    {
      title: "pages.dashboard.purchasing",
      count: dashbordChartsData?.dataInventory[0]?.Overall[0]?.Purchasing ,
    },
  ];

  useEffect(() => {
    dispatch(getDashbordInventory());
  }, [dispatch]);

  if(mangeGeneral===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} spacing={4}>
        {inventoryData?.map((el) => (
          <Card title={el.title} count={el.count} key={el.count} />
        ))}
      </SimpleGrid>

      <PendingTransactionsTable data={dashbordChartsData?.dataInventory[0]?.PendingTransactions} />
      {/* <ItemsReachedLowLevelTable   /> */}
    </>
  );
};

export default Inventory;
