import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomersFilteredData } from 'features/customers/services/customersService';

export default function FilterModal({onClose , data }) {
    const { t } = useTranslation();
    const dispatch =useDispatch();
    const toast = useToast({position: "top", duration: 3000, status: "success", });

    const [filteredData, setFilteredData] = useState(data);

    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
        CustomerName: "",
        PhoneNo: "",
      });

      const handleFilterCustomers = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const { CustomerName , PhoneNo} = filterCriteria;
    
          let filterCustomerName = true;
          let filterPhoneNo = true;
    
          if (CustomerName) {
            filterCustomerName = item?.CustomerName?.toLowerCase().includes(CustomerName.toLowerCase());
          }
    
          if (PhoneNo) {
            filterPhoneNo = item?.PhoneNo?.toLowerCase().includes(PhoneNo.toLowerCase());
          }

          return (
            filterCustomerName &&
            filterPhoneNo 
          );

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getCustomersFilteredData(filteredResult));
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.customers.customers_filter")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterCustomers}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.customers.customer_name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.customers.customer_name")}
                name="CustomerName"
                value={filterCriteria.CustomerName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    CustomerName: e.target.value,
                  })
                }
              />
              <Input
                label={t("pages.customers.phone_num")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.customers.phone_num")}
                name="PhoneNo"
                value={filterCriteria.PhoneNo}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    PhoneNo: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
