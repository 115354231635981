import { createSlice } from "@reduxjs/toolkit";
import {
  getModifiersForProduct,
  getModifiers,
  addModifiersForProduct,
} from "../services/LinkingModifiersService";

let initialState = {
  isLoading: false,
  data: [],
  errors: null,
  modifiersData: [],
};

const LinkingModifiersSlice = createSlice({
  name: "addModifier",
  initialState,
  extraReducers: (builder) => {

    /* ------------------------------------------------- Get Modifiers For Product -----------------------------------------------*/
    builder.addCase(getModifiersForProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getModifiersForProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getModifiersForProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ------------------------------------------------- Get Modifiers In Product -----------------------------------------------*/
    builder.addCase(getModifiers.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getModifiers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiersData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getModifiers.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* ------------------------------------------------- Add Modifiers To Product -----------------------------------------------*/
    builder.addCase(addModifiersForProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(addModifiersForProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });

    builder.addCase(addModifiersForProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

  },
});

export default LinkingModifiersSlice.reducer;
