import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import EditSettingRelgion from "./modal/EditSettingRelgion";
import DeleteSettingReligion from "./modal/DeleteSettingReligion";
import NoDataFound from "components/ui/noDataFound/NoDataFound";

export default function HrSettingReligionTable({ data, isLoading, error }) {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const activeData = data?.filter((el) => {
    return el.isdeleted === false;
  });

  if (isLoading) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    );
  }
  if (error) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Text> Some Thing Went error </Text>
      </Flex>
    );
  }

  if (data?.length === 0) {
    return <NoDataFound />;
  }

  return (
    <>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <GButton
              title={t("general.all")}
              bg="none"
              isDisabled={false}
              borderRadius={0}
              borderColor="primary"
            />
          </Flex>
        </Flex>

        <Table>
          <Thead>
            <Tr>
              <Th>{t("pages.hr.religionId")}</Th>
              <Th>{t("pages.hr.religionName")}</Th>
              <Th>{t("pages.hr.religionNameEn")}</Th>
              <Th>{t("general.edit")}</Th>
              <Th>{t("general.delete")}</Th>
            </Tr>
          </Thead>

          <Tbody>
            {activeData?.map((el, index) => (
              <Tr key={index}>
                <Td> {el?.ReligionId} </Td>
                <Td>{el?.ReligionName}</Td>
                <Td>{el?.ReligionNameEn}</Td>
                <Td>
                  <Button
                    colorScheme="telegram"
                    size="sm"
                    onClick={() => setShowEditModal(el)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("general.edit")} </span>
                    </Flex>
                  </Button>
                </Td>
                <Td>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => setShowDeleteModal(el?.ReligionId)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("general.delete")} </span>
                    </Flex>
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {showEditModal && (
          <EditSettingRelgion
            onClose={() => setShowEditModal(false)}
            details={showEditModal}
          />
        )}
        {showDeleteModal && (
          <DeleteSettingReligion
            onClose={() => setShowDeleteModal(false)}
            id={showDeleteModal}
          />
        )}
      </Box>
    </>
  );
}
