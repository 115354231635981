import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import {
  Box,
  Flex,
  Stack,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import Input from "components/form/Input";
import { Controller, useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import { getBranches } from "features/management/branches/services/branchesService";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllCombosSize,
  getAllGroups,
} from "../../services/comboSizeService";
import { useParams } from "react-router-dom";
import { addCustomPriceCombo } from "../../services/combosService";

const CustomPriceModal = ({ onClose }) => {
  const { t } = useTranslation();


  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  const comboSize = useSelector((state) => state.comboSize);

  //console.log(comboSize.data)
  //console.log(comboSize.groupofSize)


  // useEffect(() => {
  //   dispatch(getAllCombosSize());
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getAllGroups());
  // }, [dispatch]);

  const branches = useSelector((state) => state.branches);

  //const allsize = useSelector((state) => state.comboSize);

  //console.log(allsize)

  const id = useParams();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const data =[]
  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.combos.create_custom_price")}
      modalContent={
        <Box p={4}>
          <form
          
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              if (
                values.BranchId === undefined ||
                values.ComboSizeGroupId === undefined ||
                values.ComboSizeId === undefined
              ) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                values.BranchId = values.BranchId?.BranchId;
                values.ComboSizeGroupId =
                  values.ComboSizeGroupId?.ComboSizeGroupId;
                values.ComboSizeId = values.ComboSizeId?.ComboSizeId;
                values.ComboId = id.comboId;
                dispatch(addCustomPriceCombo(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.combos.combo_created") });
                    onClose();
                  });
                console.log(values)
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.combos.branches")}
                data={
                  branches.data &&
                  branches?.data.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))
                }
                isMulti={false}
                isRequired
                onChange={(e) => setValue("BranchId", e)}
              />

              <MultiSelect
                title={t("pages.combos.size")}
                data={
                  comboSize?.data &&
                  comboSize?.data.map((el) => ({
                    ...el,
                    label: el.ComboSizeName,
                    value: el.ComboId,
                  }))
                }
                isMulti={false}
                isRequired
                onChange={(e) => setValue("ComboSizeId", e)}
              />

              <MultiSelect
                title={t("pages.combos.group")}
                data={
                  comboSize?.groupofSize &&
                  comboSize?.groupofSize.map((el) => ({
                    ...el,
                    label: el.ComboSizeGroupName,
                    value: el.ComboSizeGroupId,
                  }))
                }
                isMulti={false}
                isRequired
                onChange={(e) => setValue("ComboSizeGroupId", e)}
              />

              <FormControl isInvalid={errors.Price}>
                <FormLabel
                  htmlFor="Price"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.combos.price")}
                </FormLabel>
                <Controller
                  name="Price"
                  control={control}
                  rules={{
                    required: t("validation.required"),
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.combos.price")}
                      onChange={(e) => {
                        handleInputChange(e, "Price");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.Price && errors.Price.message}
                </FormErrorMessage>
              </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={comboSize.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={comboSize.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default CustomPriceModal;
