import React, { useState } from "react";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import Restore from "./modals/Restore";
import { MdSettingsBackupRestore } from "react-icons/md";
import Pagination from "components/ui/pagination/Pagination";

const DeletedTable = ({ data }) => {
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState(null);
  const { t } = useTranslation();

  const clickedbuttom = (id) => {
    setShowModel(true);
    setId(id);
  };


    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
    const recordsPerPage = 10;
    const [currentPageActive, setCurrentPageActive] = useState(1);
    const prePage = (currentPage, setCurrentPage) => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
    const nextPage = (currentPage, setCurrentPage, npage) => {
      if (currentPage < npage) {
        setCurrentPage(currentPage + 1);
      }
    };
    const calculateRecords = (data, currentPage, recordsPerPage) => {
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
      return data.slice(firstIndex, lastIndex);
    };
      // Calculate records for activeDiscounts
      const deletedCategories = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
    /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return (
    <div>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>{t("pages.categories.name")}</Th>
              <Th>{t("pages.categories.ref")}</Th>
              <Th>{t("general.restore")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {deletedCategories.map((category, index) => (
              <Tr key={category.ProductCategoriesId}>
                <Td>{index + 1}</Td>
                <Td>{category.ProductCategoriesNameEng}</Td>
                <Td>{category.ProductCategoriesRef}</Td>
                <Td className="activeIcon">
                <Flex>
                  <Box
                    color="#C53030"
                    _hover={{ color: "#38A169" }}
                    p={2}
                    width={"fit-content"}
                    cursor="pointer"
                    onClick={() =>
                      clickedbuttom(category.ProductCategoriesId)
                    }
                  >
                    <MdSettingsBackupRestore size={36} />
                  </Box>
                </Flex>
              </Td>
              </Tr>
            ))}
          </Tbody>

        </Table>
        <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data.length / recordsPerPage)
                )
              }
            />
        {showModel && <Restore id={id} onClose={() => setShowModel(false)} />}
      </Box>
    </div>
  );
};

export default DeletedTable;
