import { createSlice } from "@reduxjs/toolkit";

import {getAllProductsForOrderCall,getProductQuantaity} from "../service/callCenterProductService"


let initialState = {
    isLoading: false,
    allProducts:[],
    productQuantaty:[],
    pages: 0,
    errors: null,
  };


  const callCenterProductsSlice = createSlice({
    name: "callcenterProducts",
    initialState,


    extraReducers:(builder)=>{

        builder.addCase(getAllProductsForOrderCall.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getAllProductsForOrderCall.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.allProducts = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.allProducts)
            state.errors = null;
          })
    
          builder.addCase(getAllProductsForOrderCall.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          //


          builder.addCase(getProductQuantaity.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getProductQuantaity.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.productQuantaty = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            console.log(state.productQuantaty)
            state.errors = null;
          })
    
          builder.addCase(getProductQuantaity.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
    }

})


export default callCenterProductsSlice.reducer

