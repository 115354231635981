import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


  export const getinventoryCat = createAsyncThunk(
    "items/getinventoryCat",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/InventoryCategory/LookupInventoryCategory`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );