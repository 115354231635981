//test
//testyoussef
//test

import { configureStore } from "@reduxjs/toolkit";
import sidebarSlice from "./sidebarSlice";

// general
import timezoneSlice from "./timezoneSlice";
import languagesSlice from "./languagesSlice";
import timeSlice from "./timeSlice";
import casherAppSlice from "./casherAppSlice";

// auth
import authSlice from "features/auth/store/authSlice";

// orders
import ordersTypesSlice from "features/orders/store/ordersTypesSlice";

// customer
import customersSlice from "features/customers/store/customersSlice";
import customersTagsSlice from "features/customers/store/customersTagsSlice";
import addressesSlice from "features/customers/store/addressesSlice";

//inventory
import itemsSlice from "features/inventory/items/store/itemsSlice";
import storageUnitSlice from "features/inventory/items/store/storageUnitSlice";
import inventoryCatSlice from "features/inventory/items/store/inventoryCatSlice";
import suppliersItemSlice from "features/inventory/items/store/suppliersItemSlice";
import itemTagsSlice from "features/inventory/items/store/itemTagsSlice";
import customLevelItemSlice from "features/inventory/items/store/customLevelItem.Slice";
import ingrediantsSlice from "features/inventory/items/store/ingrediantsSlice";
import inventorySupplierSlice from "features/inventory/suppliers/store/inventorySupplierSlice";
import linkItemToSupplierSlice from "features/inventory/suppliers/store/linkItemToSupplierSlice";

import purchaseSlice from "features/inventory/purchasing/store/purchaseSlice";
import PurchasingItemsSlice from "features/inventory/purchasing/store/PurchasingItemsSlice";
import ReturnSupplier from "features/inventory/purchasing/store/supplierReturned";

import purchaseOrdersSlice from "features/inventory/purchaseOrders/store/purchaseOrdersSlice";
import inventoryCountSlice from "features/inventory/inventoryCount/store/inventoryCountSlice";
// inventory More
import costAdjustmentSlice from "features/inventory/costAdjustment/store/costAdjustmentSlice";
//inventory quantity adjustment
import quantityAdjustmentSlice from "features/inventory/quantityAdjustment/store/quantityAdjustmentSlice";
//inventory Warehouse
import WarehouseSlice from "features/inventory/warehouse/store/WarehouseSlice";
//inventory Inventory CategoriesSlice
import InventoryCategoriesSlice from "features/inventory/inventoryCategories/store/InventoryCategoriesSlice";

//inventory Inventory Order Transactions
import OrderTransactionsSlice from "features/inventory/orderTransactions/store/OrderTransactionSlice";

// menus
import categoriesSlice from "features/products/categories/store/categoriesSlice";
import productsSlice from "features/products/products/store/productsSlice";
import customPriceSlice from "features/products/products/store/customPriceSlice";
import outOfStackSlice from "features/products/products/store/outOfStackSlice";
import linkingIngredientsSlice from "features/products/products/store/linkingIngredientsSlice";
import LinkingModifiersSlice from "features/products/products/store/LinkingModifiersSlice";
import costTypesSlice from "features/products/products/store/costTypesSlice";
import addingTagsSlice from "features/products/products/store/addingTagsSlice";
import giftsCardsSlice from "features/products/giftsCards/store/giftsCardsSlice";
import inActiveBranchesSlice from "features/products/giftsCards/store/inActiveBranchesSlice";
import modifiersSlice from "features/products/modifiers/store/modifiersSlice";
import modifiersOptionsSlice from "features/products/modifiersOptions/store/modifiersOptionsSlice";
import modifierOptionCustomSlice from "features/products/modifiersOptions/store/modifierOptionCustomSlice";
import groupsSlice from "features/products/groups/store/groupsSlice";
import subGroupsSlice from "features/products/groups/store/subGroupsSlice";
import productSlice from "features/products/groups/store/productSlice";
import comboSlice from "features/products/groups/store/comboSlice";
import giftCardSlice from "features/products/groups/store/giftCardSlice";

//menus.combo
import combosSlice from "features/products/combos/store/combosSlice";
import comboSizeSlice from "features/products/combos/store/comboSizeSlice";

//management
import usersSlice from "features/management/users/store/usersSlice";
import rolesSlice from "features/management/roles/store/rolesSlice";
import branchesSlice from "features/management/branches/store/branchesSlice";
import branchesTagsSlice from "features/management/branches/store/branchesTagsSlice";
import branchesDeliveryZonesSlice from "features/management/branches/store/branchesDeliveryZonesSlice";

import sectionsSlice from "features/management/branches/store/sectionsSlice";
import devicesSlice from "features/management/devices/store/devicesSlice";
import devicesTypesSlice from "features/management/devices/store/devicesTypesSlice";

import discountsSlice from "features/management/discounts/store/discountsSlice";
import discountsCombosSlice from "features/management/discounts/store/discountsCombosSlice";
import discountsCategoriesSlice from "features/management/discounts/store/discountsCategoriesSlice";
import discountsProductsSlice from "features/management/discounts/store/discountsProductsSlice";
import discountsProductTagsSlice from "features/management/discounts/store/discountsProductTagsSlice";
import discountsLimitCustomerTagsSlice from "features/management/discounts/store/discountsLimitCustomerTagsSlice";
import discountsBranchesSlice from "features/management/discounts/store/discountsBranchesSlice";
import couponsSlice from "features/management/coupons/store/couponsSlice";
import timedEventsSlice from "features/management/timedEvents/store/timedEventsSlice";
import timedEventsProductTagsSlice from "features/management/timedEvents/store/timedEventsProductTagsSlice";
import timedEventsProductsSlice from "features/management/timedEvents/store/timedEventsProductsSlice";
import timedEventsCombosSlice from "features/management/timedEvents/store/timedEventsCombosSlice";
import timedEventsCategoriesSlice from "features/management/timedEvents/store/timedEventsCategoriesSlice";
import timedEventsBranchesSlice from "features/management/timedEvents/store/timedEventsBranchesSlice";
import promotionsSlice from "features/management/promotions/store/promotionsSlice";

// taxes && Groups
import taxesSlice from "features/management/taxesGroups/store/taxesSlice";
import taxesGroupsSlice from "features/management/taxesGroups/store/taxesGroupsSlice";
import taxesLookupSlice from "features/management/taxesGroups/store/taxesLookupSlice";
import branchesToGroupsSlice from "features/management/taxesGroups/store/branchesToGroupsSlice";
import productsToGroupsSlice from "features/management/taxesGroups/store/productsToGroupsSlice";
import modifiersToGroupSlice from "features/management/taxesGroups/store/modifiersToGroupSlice";

import paymentMethodsSlice from "features/management/paymentMethods/store/paymentMethodsSlice";
import paymentMethodsTypesSlice from "features/management/paymentMethods/store/paymentMethodsTypesSlice";
import chargesSlice from "features/management/charges/store/chargesSlice";
import chargesTypesSlice from "features/management/charges/store/chargesTypesSlice";
import brandsSlice from "features/management/brands/store/brandsSlice";
import tagsSlice from "features/management/tags/store/tagsSlice";
import tagsLookupSlice from "features/management/tags/store/tagsLookupSlice";
import reasonsSlice from "features/management/reasons/store/reasonsSlice";
import reservationsSlice from "features/management/reservations/store/reservationsSlice";
import deliveryZonesSlice from "features/management/deliveryZones/store/deliveryZonesSlice";

import generalSettingsSlice from "features/management/settings/tabs/general/store/generalSettingsSlice";
import digitalWalletSlice from "features/management/settings/tabs/digitalWallet/store/digitalWalletSlice";

//mangment
import onlineOrdersSlice from "features/management/onlineOrdering/store/onlineOrdersSlice";
import cashersForOnlineOrdersSlice from "features/management/onlineOrdering/store/cashersForOnlineOrdersSlice";
import deliveryChargeSlice from "features/management/deliveryCharge/store/deliveryChargeSlice";
import callCenterSlice from "features/callCenter/store/callCenterSlice";
import callCenterProductsSlice from "features/callCenter/store/callCenterProductsSlice";
import productionSlice from "features/inventory/production/store/productionSlice";
import transfersOrdersSlice from "features/inventory/transfersOrders/store/transfersOrdersSlice";

//mangment.kitchenflow
import kitchenFlowSlice from "features/management/kitchenFlow/store/kitchenFlowSlice";
import deliveryZoneNewSlice from "features/management/deliveryZones/store/deliveryZoneNewSlice";
import acoountTreeSlice from "features/general_accounts/store/acoountTreeSlice";
import ordersSlice from "features/orders/store/ordersSlice";
import transfersSlice from "features/inventory/transfers/store/transfersSlice";

//mangment.More.PriceTags
import priceTagsSlice from "features/management/priceTags/store/priceTagsSlice";
import AccountAddSlice from "features/general_accounts/AccountAdd/store/AccountAddSlice";
import AccountingTreeSlice from "features/general_accounts/AccountingTree/store/AccountingTreeSlice";

// Reports
// Inventory ---------------------------------------------------
import inventoryLevelsReport from "features/reports/inventory_reports/pages/inventory_levels/store/inventoryLevelsSlice";
import reportsInventoryControlSlice from "features/reports/inventory_reports/pages/inventory_control/store/inventoryControlStore";
import costAdjustmentHistoryReport from "features/reports/inventory_reports/pages/cost_adjustment_history/store/costAdjustmentHistorySlice";
// Analysis Reports  -------------------------------------------
import menuEngineeringReport from "features/reports/analysis_reports/pages/menu_engineering/store/menuEngineeringSlice";
import inventoryCostAnalysis from "features/reports/analysis_reports/pages/inventory_cost_analysis/store/inventoryCostAnalysisSlice";
import speedOfServicesReport from "features/reports/analysis_reports/pages/speed_of_services/store/speedofservicesSlice";
import productCostReport from "features/reports/analysis_reports/pages/product_cost/store/productCostSlice";
import modifierOptionsCostReport from "features/reports/analysis_reports/pages/modifier_options_cost/store/modifierOptionsCostSlice";
import inventoryItemsCostReport from "features/reports/analysis_reports/pages/inventory_items_cost/store/inventoryItemsCostSlice";
//  Reports  -------------------------------------------
import reportsPurchaseOrderSLice from "../features/reports/inventory_reports/pages/purchase_order/store/purchaseOrderStore";
import reportsInventoryHistorySclice from "../features/reports/inventory_reports/pages/inventory_history/store/inventoryHistoryStore";
import inventoryReportPurchasingSlice from "../features/reports/inventory_reports/pages/purchasing/store/purchasingStore";
import reportsTransfersSlice from "../features/reports/inventory_reports/pages/transfers/store/reportTransfersStore";
import reportsTransfersOrdersSlice from "../features/reports/inventory_reports/pages/transfers_orders/store/transfersOrdersStore";
// Business Reports  -------------------------------------------
import taxesReportsSlice from "features/reports/business_reports/pages/taxes/store/taxesReportsSlice";
import tipsReportSlice from "features/reports/business_reports/pages/tips/store/tipsReportSlice";
import voidReturnReportSlice from "features/reports/business_reports/pages/voids_returns/store/voidReportsSlice";
import giftCardsReportsSlice from "features/reports/business_reports/pages/gift_cards/store/giftCardsReportsSlice";
import businessDaysReportSlice from "features/reports/business_reports/pages/business_days/store/businessDaysReportSlice";
import drawerOperationsReportSlice from "features/reports/business_reports/pages/drawer_operations/store/drawerOperReportSlice";
import shiftsReportSlice from "features/reports/business_reports/pages/shifts/store/shiftsReportSlice";
import tillsReportSlice from "features/reports/business_reports/pages/tills/store/tillsReportSlice";
// Sales Reports  -------------------------------------------
import paymentsReportSlice from "features/reports/sales_reports/pages/payment_report/store/paymentReportsSlice";
import salesReportsSlice from "features/reports/sales_reports/pages/sales_seport/store/salesReportsSlice";
import BrancheTrendsSlice from "features/reports/analysis_reports/pages/branches_trend/store/BrancheTrendsSlice";
import dashbordChartsSlice from "features/dashboard/store/dashbordChartsSlice";
import profileSlice from "features/profile/store/profileSlice";
import uploadImgSlice from "./uploadImgSlice";
import DailyRestrictionSlice from "features/general_accounts/DailyRestrictions/store/DailyRestrictionSlice";
import receiptSlice from "features/general_accounts/receipt/store/receiptSlice";
import ExpensesSlice from "features/general_accounts/Expenses/store/ExpensesSlice";
//-------------------------- HR -------------------------
import vacationSlice from "features/hr/VacationList/store/vacationSlice";
import positionsSlice from "features/hr/Positions/store/positionSlice";
import employeeSlice from "features/hr/employees/store/employeeSlice";
import generalEmployee from "features/hr/employees/store/generalEmplyeeSlice";
import attendanceSettingSlice from "features/hr/AttendanceSettings/store/attendanceSettingSlice";
import leaveReportsSlice from "features/hr/LeaveReports/store/leaveReportsSlice";
import hrSettingSlice from "features/hr/hrSetting/store/hrSettingSlice";
import hrOvertimeSlice from "features/hr/overTme/store/hrOvertimeSlice";
import hrTodayattendanceSlice from "features/hr/todayAttendance/store/hrTodayattendanceSlice";
import HrSelfServicesSlice from "features/hr/selfServices/store/HrSelfServicesSlice";

const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    auth: authSlice,
    profile: profileSlice,

    // general
    timezone: timezoneSlice,
    languages: languagesSlice,
    time: timeSlice,
    casherApp: casherAppSlice,

    // Dashbord
    dashbordChartsSlice: dashbordChartsSlice,
    // customer
    customers: customersSlice,
    customersTags: customersTagsSlice,
    customersAddresses: addressesSlice,

    // Reports -----------------------------------------------------------------------------
    // Sales Reports
    paymentsReport: paymentsReportSlice,
    salesReport: salesReportsSlice,
    // Inventory ---------------------------------------------------
    inventoryLevelsReport: inventoryLevelsReport,
    costAdjustmentHistoryReport: costAdjustmentHistoryReport,
    // Analysis Reports  -------------------------------------------
    menuEngineeringReport: menuEngineeringReport,
    inventoryCostAnalysisReport: inventoryCostAnalysis,
    speedOfServicesReport: speedOfServicesReport,
    productCostReport: productCostReport,
    modifierOptionsCostReport: modifierOptionsCostReport,
    inventoryItemsCostReport: inventoryItemsCostReport,
    BrancheTrends: BrancheTrendsSlice,
    // Business Reports  -------------------------------------------
    taxesReport: taxesReportsSlice,
    tipsReport: tipsReportSlice,
    voidReturnReport: voidReturnReportSlice,
    giftCardsReport: giftCardsReportsSlice,
    businessDaysReport: businessDaysReportSlice,
    drawerOperationsReport: drawerOperationsReportSlice,
    shiftsReport: shiftsReportSlice,
    tillsReport: tillsReportSlice,
    //inventory.item
    items: itemsSlice,
    storageUnit: storageUnitSlice,
    inventoryCat: inventoryCatSlice,
    suppliers: suppliersItemSlice,
    tagsOfItem: itemTagsSlice,
    customLevel: customLevelItemSlice,
    ingrediants: ingrediantsSlice,
    productions: productionSlice,
    transfersorders: transfersOrdersSlice,

    //inventory.suppliers

    inventorySuppliers: inventorySupplierSlice,
    linkItemToSupplier: linkItemToSupplierSlice,

    //inventory.purchases
    purchases: purchaseSlice,
    purchasesitems: PurchasingItemsSlice,
    returnSupplier: ReturnSupplier,

    //inventory.purchases_Orders
    purchaseOrder: purchaseOrdersSlice,

    //inventory.purchases_Orders
    inventoryTransfers: transfersSlice,

    /* --------------------------------------------------------------------- */
    //inventory Cost adjustment
    costAdjustment: costAdjustmentSlice,
    //inventory quantity adjustment
    QuantityAdjustment: quantityAdjustmentSlice,
    //inventory Warehouse
    warehouse: WarehouseSlice,
    //inventory Inventory Categories
    inventoryCategories: InventoryCategoriesSlice,
    //inventory count
    inventoryCount: inventoryCountSlice,
    //inventory count
    inventoryOrderTransactions: OrderTransactionsSlice,

    // menu
    categories: categoriesSlice,
    products: productsSlice,
    customPrice: customPriceSlice,
    addTags: addingTagsSlice,
    addStock: outOfStackSlice,
    addIngredient: linkingIngredientsSlice,
    addModifier: LinkingModifiersSlice,
    costTypes: costTypesSlice,
    giftsCards: giftsCardsSlice,
    inActiveGiftCards: inActiveBranchesSlice,
    modifiers: modifiersSlice,
    modifiersOptions: modifiersOptionsSlice,
    modifiersOptionscustom: modifierOptionCustomSlice,
    groups: groupsSlice,
    subGroups: subGroupsSlice,
    product: productSlice,
    combo: comboSlice,
    giftsCard: giftCardSlice,

    //menu.combos

    combos: combosSlice,
    comboSize: comboSizeSlice,

    users: usersSlice,
    roles: rolesSlice,
    branches: branchesSlice,
    branchesTags: branchesTagsSlice,
    branchesDeliveryZones: branchesDeliveryZonesSlice,
    sections: sectionsSlice,
    devices: devicesSlice,
    devicesTypes: devicesTypesSlice,
    discounts: discountsSlice,
    discountsCombos: discountsCombosSlice,
    discountsCategories: discountsCategoriesSlice,
    discountsProducts: discountsProductsSlice,
    discountsProductTags: discountsProductTagsSlice,
    discountsLimitCustomerTags: discountsLimitCustomerTagsSlice,
    discountsBranches: discountsBranchesSlice,
    coupons: couponsSlice,
    timedEvents: timedEventsSlice,
    timedEventsProductTags: timedEventsProductTagsSlice,
    timedEventsProducts: timedEventsProductsSlice,
    timedEventsCombos: timedEventsCombosSlice,
    timedEventsCategories: timedEventsCategoriesSlice,
    timedEventsBranches: timedEventsBranchesSlice,
    promotions: promotionsSlice,

    // taxes && groups
    taxes: taxesSlice,
    ordersTypes: ordersTypesSlice,
    taxesGroups: taxesGroupsSlice,
    taxesLookup: taxesLookupSlice,
    branchGroups: branchesToGroupsSlice,
    productGroups: productsToGroupsSlice,
    modifierGroups: modifiersToGroupSlice,

    paymentMethods: paymentMethodsSlice,
    paymentMethodsTypes: paymentMethodsTypesSlice,
    charges: chargesSlice,
    chargesTypes: chargesTypesSlice,
    brands: brandsSlice,
    tags: tagsSlice,
    tagsLookup: tagsLookupSlice,
    reasons: reasonsSlice,
    reservations: reservationsSlice,
    deliveryZones: deliveryZonesSlice,

    // settings
    generalSettings: generalSettingsSlice,
    digitalWallet: digitalWalletSlice,
    // Management More Tags
    priceTags: priceTagsSlice,

    //mangment.onlineOrders

    RecivingOrders: onlineOrdersSlice,
    casherOnlineOrder: cashersForOnlineOrdersSlice,
    deliverycharge: deliveryChargeSlice,

    //callcenter

    callcenter: callCenterSlice,
    callcenterProducts: callCenterProductsSlice,

    //mangment.kitchenflow
    kitchenFlow: kitchenFlowSlice,

    //mangment.more.deliveryzone
    deliveryZoneNew: deliveryZoneNewSlice,

    //account tree

    AccountTree: acoountTreeSlice,

    //orders

    ordersSlice: ordersSlice,

    AccountAdd: AccountAddSlice,

    AccountTreeNew: AccountingTreeSlice,

    reportsPurchaseOrder: reportsPurchaseOrderSLice,

    reportsInventoryHistory: reportsInventoryHistorySclice,

    inventoryReportPurchasing: inventoryReportPurchasingSlice,
    reportsTransfersOrders: reportsTransfersOrdersSlice,
    reportsTransfers: reportsTransfersSlice,
    reportsInventoryControl: reportsInventoryControlSlice,

    UploadImgSlice: uploadImgSlice,

    DailyRestrictions: DailyRestrictionSlice,

    receiptSlice: receiptSlice,
    ExpensesSlice: ExpensesSlice,

    // ------------------------ HR -------------------
    vacationSlice: vacationSlice,
    positionsSlice: positionsSlice,
    employeeSlice: employeeSlice,
    generalEmployee: generalEmployee,
    attendanceSetting: attendanceSettingSlice,
    leaveReports: leaveReportsSlice,
    hrSetting: hrSettingSlice,
    hrOvertime: hrOvertimeSlice,
    hrTodayAttendance: hrTodayattendanceSlice,
    HrSelfServices: HrSelfServicesSlice,
  },
});

export default store;
