import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text, Spinner } from "@chakra-ui/react";
import { getCostAdjustmentReportList } from "./services/costAdjustmentHistoryServices";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import ExportModal from "components/ui/modal/ExportModal";
import CostAdjustmentHistoryTable from "./components/CostAdjustmentHistoryTable";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

export default function ReportCostAdjustment() {
  const retrievedArray =useDecryptedPrivilege()
  const Auth = checkIdExists(retrievedArray, 69);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [exportModal, SetExportModal] = useState(false);

  const inventoryReports = useSelector(
    (state) => state.costAdjustmentHistoryReport
  );

  useEffect(() => {
    dispatch(getCostAdjustmentReportList());
  }, [dispatch]);

  
if(Auth===false ){
  return(
    <NotAuthorizedCompo/>
  )
}

  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.reports.inventory_reports.cost_adjustment_history")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
              <Text>{t("general.export")}</Text>
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>

      <>
        <CostAdjustmentHistoryTable
          data={inventoryReports?.data}
          filteredData={inventoryReports?.filteredData}
        />
      </>

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={inventoryReports?.data}
          fileName={"CostAdjustmentHistory"}
        ></ExportModal>
      )}
    </>
  );
}
