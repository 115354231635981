import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import { useTranslation } from 'react-i18next';
import AddProductKitchenFlowModal from './modal/AddProductKitchenFlowModal';
import { useDispatch, useSelector } from 'react-redux';
import { getKitchenFlowProduct } from '../services/kitchenFlowService';
import { useParams } from 'react-router-dom';
import KitchenFlowProductTable from './KitchenFlowProductTable';


const KitchenFlowProduct = () => {

    const { t } = useTranslation();

    const [showModal,setShowModal]=useState(false)

    const dispatch=useDispatch()

    const id = useParams()

    useEffect(()=>{
      dispatch(getKitchenFlowProduct(id.id))
    },[dispatch,id])

    const productData = useSelector(state => state.kitchenFlow);

    //console.log(productData.kitchenFlowProducts)
  return (
    <Box style={{marginTop:"15px"}}>
    <Flex
    alignItems="center" justifyContent="space-between"
    gap={2} flexWrap="wrap" mb={6}
  >
    <Text color="text" textTransform="capitalize">
      {t("pages.kitchenFlow.products")}
    </Text>
    <GButton
      title={t("pages.kitchenFlow.Add_products")}
      bg="light"
      color="text"
      fontSize="14px"
      border="1px solid"
      borderColor="border"
      hover={{ bg: "light" }}
      onClick={() => setShowModal( true)}
    />
  </Flex>




    


      
{productData.isLoading ? (
  <Spinner />
) : productData.kitchenFlowProducts?.length === 0 ? (
  <Flex
    p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  >
    {t("pages.branches.no_data")}
  </Flex>
) : productData.kitchenFlowProducts?.length > 0 ? (
  <KitchenFlowProductTable
  data={productData.kitchenFlowProducts}
  />
):(
  <Flex
  p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
  boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
>
  {t("pages.branches.no_data")}
</Flex>
)
}




{
    showModal&&(
        <AddProductKitchenFlowModal onClose={() => setShowModal(false)}/>
    )

 }
  








    </Box>
  );
}

export default KitchenFlowProduct;
