import { createSlice } from "@reduxjs/toolkit";
import { GetAccByFinancialId } from "../service/DailyRestrictoionsServers";



let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
};

  const DailyRestrictionSlice = createSlice({
    name: "DailyRestrictions",
    initialState,

        extraReducers:(builder)=>{


              builder.addCase(GetAccByFinancialId.pending, (state) => {
                state.isLoading = true;
                state.errors = null;
              });

              builder.addCase(
                GetAccByFinancialId.fulfilled,
                (state, { payload }) => {
                  state.isLoading = false;
                  state.data =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
                //   console.log(state.data);
                  state.errors = null;
                }
              );

              builder.addCase(GetAccByFinancialId.rejected, (state) => {
                state.isLoading = false;
                state.errors = [{ msg: "something went wrong!" }];
              });


        }
  });

    export default DailyRestrictionSlice.reducer;