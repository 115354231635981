import GModal from "components/ui/modal/Modal";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  createPurchase,
  getbranchesInPurchase,
  getPurchasesList,
  getSuppliersInPurchase,
} from "../../services/purchaseService.js";

export default function CreatePurchaseModal({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue } = useForm();

  const purchases = useSelector((state) => state.purchases);

  useEffect(() => {
    dispatch(getSuppliersInPurchase());
    dispatch(getbranchesInPurchase());
  }, [dispatch]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.purchases.new_purchase")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.UserId = localStorage.getItem("userId");
                if (
                  values.BranchId === undefined ||
                  values.SuppliersId === undefined
                ) {
                  toast2({ description: t("general.validate") });
                } else {
                  values.BranchId = values.BranchId?.BranchId;
                  values.SuppliersId = values.SuppliersId?.SuppliersId;
                  values.PurchasingId = null;
                  dispatch(createPurchase(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.purchases.purchase_created"),
                      });
                      dispatch(getPurchasesList());
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <MultiSelect
                  title={t("pages.purchases.supplier")}
                  data={
                    purchases.suppliersInPurchase &&
                    purchases.suppliersInPurchase.map((el) => ({
                      ...el,
                      label: el.SuppliersName,
                      value: el.SuppliersId,
                    }))
                  }
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("SuppliersId", e)}
                />

                <MultiSelect
                  title={t("pages.purchases.branche")}
                  data={
                    purchases.branchesInPurchase &&
                    purchases.branchesInPurchase.map((el) => ({
                      ...el,
                      label: el.BranchName,
                      value: el.BranchId,
                    }))
                  }
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("BranchId", e)}
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    //isLoading={categories.isLoading}
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    //isLoading={categories.isLoading}
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
