import { useTranslation } from "react-i18next"
import { Box } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import GButton from "components/ui/button/Button";

const FilterModal = ({
  onClose
}) => {
  const { t } = useTranslation();

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.promotions.filter_promotions")}
      modalContent={(
        <Box p={4}>
          <GButton
            title={t("pages.promotions.filter_promotions")}
            bg="primary"
            width="100%"
            color="light"
            border="1px solid transparent"
            hover={{ bg: "none", borderColor: "primary", color: "primary" }}
          />
        </Box>
      )}
    />
  )
}

export default FilterModal