import { createSlice } from "@reduxjs/toolkit";
import {
  getReportTransfersList,
  getReportTransfersFilteredData,
  resetReportTransfersFilteredData,
} from "../services/reportTransfersServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,
  data: [],
  // Filter data
  filteredData: [],
};

const reportsTransfers = createSlice({
  name: "reportsTransfersOrders",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get Transfers List ------------------------------------ */
    builder.addCase(getReportTransfersList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getReportTransfersList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getReportTransfersList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- get Transfers Filtered Data------------------------------------------------ */
    builder.addCase(getReportTransfersFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getReportTransfersFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        state.errors = null;
      }
    );
    builder.addCase(getReportTransfersFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- reset Transfers Filtered Data------------------------------------------------ */
    builder.addCase(resetReportTransfersFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      resetReportTransfersFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.filteredData = [];
        state.errors = null;
      }
    );
    builder.addCase(resetReportTransfersFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default reportsTransfers.reducer;
