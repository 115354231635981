import React, { useEffect, useState } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import {Box,Checkbox, Flex, Stack, useToast, Input as ChInput, Text} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { editQuantityReturnedItemList, getReturnItemsToEditQuantity, getReturnedItemList } from "../../services/returnedSuppliersServices";


export default function EditQuantityReturnItems ({onClose, id}) {  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success",});
  const toast2 = useToast({ position: "top", duration: 3000, status: "error",});
  const { handleSubmit, control,  getValues,  watch,  setValue,  register,  formState: { errors },  trigger,  setError,  clearErrors,} = useForm();

  const data = useSelector(state => state.returnSupplier);  

  useEffect(() => {
    dispatch(getReturnItemsToEditQuantity(id))
  }, [dispatch])
  

  const [itemData, setItemData] = useState({});
  
  useEffect(() => {
    const initialItemData = {};
    data.itemsToEdit.forEach((el) => {
      // Store each element in a separate object
      initialItemData[el.ReturnItemsId] = {
        ReturnItemsId: el.ReturnItemsId,
        Quantity: el.Quantity,
        UnitCost: el.UnitCost,
      };
    });
    // Set the state with both the object and the array
    setItemData(initialItemData);
  }, [ data.itemsToEdit ]);

  const handleQuantityChange = (newQuantity, itemId , NewUnitCost) => {
    // Step 3: Update the state with the new data using a unique identifier
    setItemData((prevData) => {
      return {
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          Quantity: newQuantity,
          UnitCost: NewUnitCost,
        },
      };
    });
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
        setError("ParLevel", {
          type: "manual",
          message: t("validation.must_be_within_min_max_range"),
        });
      } else {
        clearErrors("ParLevel");
      }
    }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

    const isOnlySpaces = (value) => {
      // Check if the value contains only spaces
      return !value.trim();
    };

  
  return <>
                <GModal
                      onClose={onClose}
                      modalHeader={t("pages.purchases.edit_quantities")}
                      modalContent={(
                      <Box p={4}>
                    <form onSubmit={handleSubmit( (values) => {
                            const editedItems = Object.keys(itemData).map((itemId) => ({
                              ReturnItemsId: parseInt(itemId,10),
                              Quantity: itemData[itemId].Quantity,
                              UnitCost: itemData[itemId].UnitCost,
                            }));
                            values.EditPurchasingReturnItems = editedItems;  
                            values.ReturnId = id;  
                            console.log(values);
                            dispatch(editQuantityReturnedItemList(values))
                            .unwrap()
                            .then((_) => {
                              toast({ description: t("pages.purchases.purchases_item_updated") });
                              dispatch(getReturnedItemList(id))
                              onClose();
                            });
                    })}>
              <Stack spacing={6}>

                {
                data?.itemsToEdit?.map((el) => (
                  <Box>
                    <Text
                      marginBottom={"7px"}
                      fontSize={"1rem"}
                      fontWeight={"500"}
                      textTransform={"capitalize"}
                      textAlign={"start"}
                      marginInlineEnd={"0.75rem"}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {el.InventoryItemNameEng}
                    </Text>
                    <ChInput
                      defaultValue={el.Quantity}
                      name="Quantity"
                      type="number"
                      min={1}
                      onChange={(e) =>
                        handleQuantityChange(
                          e.target.value,
                          el.ReturnItemsId,
                          el.UnitCost
                        )
                      }
                    />
                  </Box>
                ))
                }
  
                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
                    </form>
                </Box>
      )}
    />
  
  </>
};
