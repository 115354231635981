import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (args, thunkApi) => {
    try {
      // /Manage/Brand/LookUpBrandProductcatg
      const { data } = await axios.get(`/Menu/ProductCategories/ProductCategoriesList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getDeleted = createAsyncThunk(
  "categories/getDeleted",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/ProductCategories/ProductCategoriesDeletedList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createCategory = createAsyncThunk(
  "categories/createCategory",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/ProductCategories/AddProductCategories?productcategories=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/ProductCategories/EditProductCategories?productcategoriesid=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (args, thunkApi) => {
    try {
      
      const { data } = await axios.get(`/Menu/ProductCategories/DeleteProductCategoriesById?productcategoriesid=${JSON.stringify({ProductCategoriesId: args.values.ProductCategoriesId})}`);

      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const restoreCategory = createAsyncThunk(
  "categories/restoreCategory",
  async (args, thunkApi) => {
    try {

      const { data } = await axios.get(`/Menu/ProductCategories/RestorProductCategoriesById?productcategoriesid=${JSON.stringify({ProductCategoriesId: args})}`);
      
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getFilteredData = createAsyncThunk(
  "categories/getFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetFilteredData = createAsyncThunk(
  "categories/resetFilteredData ",
  async (args, thunkApi) => {
    
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);