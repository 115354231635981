import { createSlice } from "@reduxjs/toolkit";
import { getPaymentsReportFilteredData, getPaymentsReportList, resetPaymentsReportFilteredData } from "../services/paymentReportsServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const paymentsReportSlice = createSlice({
  name: "paymentsReport",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------- Payments  Report List ------------------------------- */
  builder.addCase(getPaymentsReportList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getPaymentsReportList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getPaymentsReportList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

/* ------------------------------------------------------------------------------------------- */
 /* ----------------------------- Payments Filter Data ------------------------------*/
 builder.addCase(getPaymentsReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPaymentsReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getPaymentsReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetPaymentsReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetPaymentsReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetPaymentsReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default paymentsReportSlice.reducer;