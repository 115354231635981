import React, { useState } from "react";
import { Box, Button, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import EditTableSectionModal from "./modals/EditTableSectionModal";

const TableSectionTable = ({ data,isLoading }) => {
  const { t } = useTranslation();

  //console.log(data)

  

  const [showEditModal,setShowEditModal]=useState(false)
  const [tableData,SetTableData]=useState()

  const HandleShow=(el)=>{
    SetTableData(el)
    setShowEditModal(true)
  }

  if(isLoading===true){
    return(
        <Spinner />
    )
  }
  return (
    <div>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Table>
          <Thead>
            <Tr>
              <Th>{t("pages.branches.TableId")}</Th>
              <Th> {t("pages.branches.TableName")}</Th>
              <Th> {t("pages.branches.number_Of_Sets")}</Th>
              <Th>{t("general.action")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((el) => {
              return (
                <Tr>
                    <Td>{el.TableId}</Td>
                    <Td>{el.TableName}</Td>
                    <Td>{el.SeatsNo}</Td>
                    <Td>
                    <GButton
                    title={t("general.edit")}
                    bg="light"
                    color="text"
                    fontSize="14px"
                    border="1px solid"
                    borderColor="border"
                    hover={{ bg: "light" }}
                   onClick={() => HandleShow(el)}
                  /></Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      {
        showEditModal&&(
          <EditTableSectionModal onClose={()=> setShowEditModal(false)} tableData={tableData}/>
        )
      }
    </div>
  );
};

export default TableSectionTable;
