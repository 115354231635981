import { createSlice } from "@reduxjs/toolkit";
import { getTime } from "../services/timeService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null
};

const timeSlice = createSlice({
  name: "time",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTime.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTime.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload.data;
      state.errors = null;
    })
    builder.addCase(getTime.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default timeSlice.reducer;