import { createSlice } from "@reduxjs/toolkit";
import {
  getDiscountsCombos,
  createDiscountCombos,
  deleteComboInDiscount,
  getTagsInCombo
} from "../services/discountsCombosService";

let initialState = {
  isLoading: false,
  data: [],
  errors: null,
  tagsInCombo: [],
};

const discountsCombosSlice = createSlice({
  name: "discountsCombos",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get Discounts List  -------------------------------------------------------------------*/
    builder.addCase(getDiscountsCombos.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDiscountsCombos.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getDiscountsCombos.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create Discount  -------------------------------------------------------------------*/
    builder.addCase(createDiscountCombos.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createDiscountCombos.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createDiscountCombos.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Combo In Discount  -------------------------------------------------------------------*/
    builder.addCase(deleteComboInDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteComboInDiscount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deleteComboInDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
        /* -------------------------------------------------- Get Tags In Combo ---------------------------------------------------*/
        builder.addCase(getTagsInCombo.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        });
    
        builder.addCase(getTagsInCombo.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.tagsInCombo =
            (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          state.errors = null;
        });
    
        builder.addCase(getTagsInCombo.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        });
  },
});

export default discountsCombosSlice.reducer;
