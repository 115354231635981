import { Box, Text } from "@chakra-ui/react"
import GButton from "components/ui/button/Button";

const NoData = ({
  onClickCreateBtn,
  title,
  btnTitle
}) => {
  return (
    <Box bg="light" paddingInline={4} paddingBlock={8} textAlign="center">
      <Text textTransform="capitalize" color="text" mb={4} fontSize="1.1rem">
        {title}
      </Text>
      <GButton
        onClick={onClickCreateBtn}
        size="sm"
        title={btnTitle}
        bg="primary"
        color="light"
        border="1px solid transparent"
        hover={{ bg: "none", color: "primary", borderColor: "primary" }}
      />
    </Box>
  )
}

export default NoData;