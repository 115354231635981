import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteGiftCard } from "../../services/giftsCardsService";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";

export default  function DeleteModal ({ id, onClose }) {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast({position: "top",duration: 3000,status: "success",});

  const giftsCards = useSelector((state) => state.giftsCards);

  return <>
      <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteGiftCard(id))
          .unwrap()
          .then((_) => {
            toast({ description: t("pages.gifts_cards.gift_card_deleted") });
            onClose();
            navigate(`/${routes.products_gifts_cards}`);
          });
      }}
      isLoading={giftsCards.isLoading}
      />
  </>
};
