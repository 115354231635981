import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* ------------------------------------------------- Combo DropDown List ------------------------------------------------ */
export const comboDropList = createAsyncThunk( "combo/comboDropList",  async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Combo/LookupCombo`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ------------------------------------------------- Get Combo List ------------------------------------------------ */
  export const comboList = createAsyncThunk("combo/comboList",  async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Group/GroupComboListById?groupid={"MenuGroupId":${args}}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ------------------------------------------------- Add Combo To Groups ------------------------------------------------ */
  export const addCombo = createAsyncThunk( "combo/addCombo", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Group/AddGroupCombos?groupcombos=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );