import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";
/* ------------------------------------------------- Categories DropDown List ------------------------------------------------ */
export const CategoriesDropList = createAsyncThunk(
  "discountsCategories/CategoriesDropList",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/ProductCategories/LookupProductCategories?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- create categories Combos  -------------------------------------------------------------------*/
export const createDiscountCategories = createAsyncThunk(
  "discountsCategories/createDiscountCategories",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCount/AddDiscountProductcategories?productcategoryid=${JSON.stringify(
          args
        )}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Get categories Discounts List  -------------------------------------------------------------------*/
export const getDiscountsCategories = createAsyncThunk(
  "discountsCategories/getDiscountsCategories",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetDiscountProductCategories?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Delete Category In Discount  -------------------------------------------------------------------*/
export const deleteCategoryInDiscount = createAsyncThunk(
  "discountsCategories/deleteCategoryInDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCountProductCatgory/DisCountProductCatgoryById?DisCountProductCatgoryId={"DisCountProductCatgoryId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
