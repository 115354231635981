import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Spinner, Text ,  Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import * as FiIcons from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import CreatePriceTagsModal from '../components/Modals/CreatePriceTagsModal';
import PriceTagsTable from '../components/PriceTagsTable';
import { getPriceTags } from '../services/priceTagsServices';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';

export default function PriceTags() {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 58);

  const { t } = useTranslation();
  const dispatch=useDispatch();

  const PriceTagsData = useSelector(state => state.priceTags);

const [showCreateModal, setShowCreateModal] = useState(null);
const [showExportPriceModal, setShowExportPriceModal] = useState(null);

useEffect(() => {
  dispatch(getPriceTags());
}, [dispatch]);


if(ManageBranches===false  ){
  return(
    <NotAuthorizedCompo/>
  )
}

  return <>
  
  <Box
      bg="light" marginInline={-6} marginTop={-6} padding={6}
      borderTop="1px solid" borderColor="border" marginBottom={6}
    >
    <Flex alignItems="center" justifyContent="space-between" gap={4}>
      <Text fontSize="1.2rem" color="dark" textTransform="capitalize"> {t("pages.price_tags.price_tags")} </Text>

      <Flex gap={2}>

          <Menu>
                  <MenuButton as={Button}
                    bg="none"
                    border="1px solid"
                    borderColor="border"
                    color="text"
                    _hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                  >
                    <Flex alignItems="center" gap={2}>
                      {/* <FiIcons.FiCloud size={16} /> */}
                      <Text>{t("general.import_export")}</Text>
                      <FiIcons.FiChevronDown size={16} />
                    </Flex>
                  </MenuButton>

                  <MenuList p={0} minW="auto">

                        <MenuItem  
                        // onClick={() => setShowImportPromotionsModal(true)}
                        >
                          <Flex alignItems="center" gap={2}>
                            <Text textTransform="capitalize">{t("pages.price_tags.import_price_tags")}</Text>
                          </Flex>
                        </MenuItem>

                        <MenuItem  
                        // onClick={() => setShowImportPromotionsModal(true)}
                        >
                          <Flex alignItems="center" gap={2}>
                            <Text textTransform="capitalize">{t("pages.price_tags.import_price_tags_product")}</Text>
                          </Flex>
                        </MenuItem>

                        <MenuItem  
                        // onClick={() => setShowImportPromotionsModal(true)}
                        >
                          <Flex alignItems="center" gap={2}>
                            <Text textTransform="capitalize">{t("pages.price_tags.import_price_modifier")}</Text>
                          </Flex>
                        </MenuItem>

                        <MenuItem 
                          // onClick={() => setShowExportPromotionsModal(true)}
                        >
                          <Flex alignItems="center" gap={2}>
                            <Text textTransform="capitalize">{t("pages.price_tags.export_price_tags")}</Text>
                          </Flex>
                        </MenuItem>

                        <MenuItem 
                          // onClick={() => setShowExportPromotionsModal(true)}
                        >
                          <Flex alignItems="center" gap={2}>
                            <Text textTransform="capitalize">{t("pages.price_tags.export_price_tags_product")}</Text>
                          </Flex>
                        </MenuItem>

                        <MenuItem 
                          // onClick={() => setShowExportPromotionsModal(true)}
                        >
                          <Flex alignItems="center" gap={2}>
                            <Text textTransform="capitalize">{t("pages.price_tags.export_price_modifier")}</Text>
                          </Flex>
                        </MenuItem>

                  </MenuList>
          </Menu>

          <Button
            type="button" bg="primary" color="light"
            textTransform="capitalize" fontSize="1rem"
            onClick={() => setShowCreateModal(true)}
          >
            <Flex alignItems="center" gap={1} >
              {t("pages.price_tags.create_price_tag")}
            </Flex>
          </Button>
      
      </Flex>
    </Flex>

  </Box>

  <PriceTagsTable data={PriceTagsData.data} />

  { showCreateModal  && (
      <CreatePriceTagsModal 
          onClose={()=>setShowCreateModal(null)}
      />
    )
  }

  </>
};
