import { Box, Flex, Stack, Input as ChInput } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";

const DateModal = ({ onClose,setDate }) => {
  const { t } = useTranslation();

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.orders.receipt_notes")}
      modalContent={
        <Box p={4}>
          <Stack>
          <div >
          <h3 style={{ marginBottom: "7px" }}>
            {t("pages.Expenses.data")}
          </h3>
              <ChInput  type="date" onChange={(e)=>setDate(e.target.value)}/>
        </div>

            <Flex gap={4}>
              <GButton
                title={t("general.close")}
                onClick={onClose}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                hover={{
                  color: "red.600",
                  borderColor: "red.600",
                  bg: "none",
                }}
              />
              <GButton
                title={t("general.save")}
                onClick={onClose}
                bg="green"
                border="1px solid transparent"
                color="light"
                hover={{
                  color: "green",
                  borderColor: "green",
                  bg: "none",
                }}
              />
            </Flex>
          </Stack>
        </Box>
      }
    />
  );
};

export default DateModal;
