import React from "react";
import GButton from "components/ui/button/Button";
import {
  Box,
  Flex,
  Stack,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  getHrTodayAbsence,
  getHrTodayAttendance,
} from "../services/hrTodayattendanceServices";

export default function GetAttendanceModal({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  //-------------------------------- Validation -----------------------------
  // const isOnlySpaces = (value) => {
  //   // Check if the value contains only spaces
  //   return !value.trim();
  // };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };
  //------------------------------------------------------------------

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.getAttendance")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.IsMangager = 0;
                values.EmployeeId = 0;
                dispatch(getHrTodayAttendance(values))
                  .unwrap()
                  .then((_) => {
                    dispatch(getHrTodayAbsence(values));
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.StartDate}>
                  <FormLabel
                    htmlFor="StartDate"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.attendance.from_date")}
                  </FormLabel>
                  <Controller
                    name="StartDate"
                    control={control}
                    // defaultValue={`${year2}-${month2}-${day2}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.attendance.from_date")}
                        onChange={(e) => {
                          handleInputChange(e, "StartDate");
                        }}
                        type="date"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.StartDate && errors.StartDate.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.EndDate}>
                  <FormLabel
                    htmlFor="EndDate"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.attendance.to_date")}
                  </FormLabel>
                  <Controller
                    name="EndDate"
                    control={control}
                    // defaultValue={`${year2}-${month2}-${day2}`}
                    rules={{
                      required: t("validation.required"),
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.attendance.to_date")}
                        onChange={(e) => {
                          handleInputChange(e, "EndDate");
                        }}
                        type="date"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.EndDate && errors.EndDate.message}
                  </FormErrorMessage>
                </FormControl>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
