import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller , useForm } from "react-hook-form";
import {Box,Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { getReasons, updateReason } from "../../services/reasonsService";

const UpdateModal = ({ data , onClose, reasonType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { handleSubmit, control, setValue,  formState: { errors },  trigger} =  useForm();

  const reasons = useSelector(state => state.reasons);
  
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };


  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.reasons.update_reason")}
      modalContent={(
        <Box p={4}>
          <form 
                onSubmit={handleSubmit(values => {

                values.ReasonTypeId = reasonType ;
                values.ReasonTypeNameEng = reasonType === 1 ?     "سبب الإلغاء والإرجاع" :
                                           reasonType === 2 ?     "سبب تعديل الكمية" : 
                                            reasonType === 3 ?    "سبب عمليات الصندوق" :  ""  ;

                values.ReasonTypeNameAr = reasonType === 1 ?     "Void and Return Reason" :
                                           reasonType === 2 ?     "Quantity Adjustment Reason" : 
                                            reasonType === 3 ?    "Drawer Operation Reason" : "" ;
                values.ReasonId = data.ReasonId;

                dispatch(updateReason({ values }))
                .unwrap()
                .then(_ => {
                  toast({ description: t("pages.reasons.reason_updated") });
                  dispatch(getReasons());
                  onClose()
                });
          })}>
            <Stack spacing={6}>
      <FormControl isInvalid={errors.ReasonNameEng}>
              <FormLabel
                htmlFor="ReasonNameEng"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.reasons.name")}
              </FormLabel>
              <Controller
                name="ReasonNameEng"
                defaultValue={data.ReasonNameEng}
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(e) => {
                      handleInputChange(e, "ReasonNameEng");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.ReasonNameEng &&
                  errors.ReasonNameEng.message}
              </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.ReasonNameAr}>
          <FormLabel
            htmlFor="ReasonNameAr"
            style={{ textTransform: "capitalize" }}
          >
            {t("pages.reasons.name_localized")}
          </FormLabel>
          <Controller
            name="ReasonNameAr"
            defaultValue={data.ReasonNameAr}
            control={control}
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) || t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(e) => {
                  handleInputChange(e, "ReasonNameAr");
                }}
              />
            )}
          />
          <FormErrorMessage>
            {errors.ReasonNameAr &&
              errors.ReasonNameAr.message}
          </FormErrorMessage>
      </FormControl>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={reasons.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={reasons.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default UpdateModal