import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { getInventoryHistoryFilteredData } from "../services/inventoryHistoryServices";
import Input from "components/form/Input";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";

export default function FilterModal({ onClose, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState(data);

  // Filter Data
  const [filterCriteria, setFilterCriteria] = useState({
    BranchName: "",
    SKU: "",
    Name: "",
  });

  const handleFilterInventoryHistory = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const { BranchName, Name, SKU } = filterCriteria;

      let filterBranchName = true;
      let filterItemName = true;
      let filterRef = true;

      if (BranchName) {
        filterBranchName = item?.Branch?.toLowerCase().includes(
          BranchName.toLowerCase()
        );
      }

      if (Name) {
        filterItemName = item?.Name?.toLowerCase().includes(Name.toLowerCase());
      }

      if (SKU) {
        filterRef = item?.Ref?.toLowerCase().includes(SKU?.toLowerCase());
      }

      return filterBranchName && filterItemName && filterRef;
    });

    setFilteredData(filteredResult);
    onClose();
    dispatch(getInventoryHistoryFilteredData(filteredResult));
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.cost_adjustment.cost_adjustment_filter")}
        modalContent={
          <Box p={4}>
            <form onSubmit={handleFilterInventoryHistory}>
              <Stack spacing={6}>
                <Input
                  label={t("pages.cost_adjustment.branch")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.cost_adjustment.branch")}
                  name="BranchName"
                  value={filterCriteria.BranchName}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      BranchName: e.target.value,
                    })
                  }
                />
                <Input
                  label={t("pages.cost_adjustment.reference")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.cost_adjustment.reference")}
                  name="SKU"
                  value={filterCriteria.SKU}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      SKU: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.cost_adjustment.name")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.cost_adjustment.name")}
                  name="Name"
                  value={filterCriteria.Name}
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      Name: e.target.value,
                    })
                  }
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
