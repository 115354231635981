import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";


export const createKitchenFlow = createAsyncThunk(
    "kitchenFlow/createKitchenFlow",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/KitchenFlows/AddKitchenFlows?kitchenflows=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getAllKitchenFlow = createAsyncThunk(
    "kitchenFlow/getAllKitchenFlow",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/KitchenFlows/KitchenFlowsList`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getkitchenDetails = createAsyncThunk(
    "kitchenFlow/getkitchenDetails",
    async (args, thunkApi) => {
      try {
        //const { data } = await axios.get(`/Manage/KitchenFlows/KitchenFlowsList`);
        //console.log(data)
        return args;
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

/* ----------------------------------------------- Update kitchen Flow ------------------------------------------------------ */
  export const updateKitchenFlow = createAsyncThunk("kitchenFlow/updateKitchenFlow", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/KitchenFlows/EditKitchenFlows?kitchenflowsid=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const diactivateKitchenFlow = createAsyncThunk(
    "kitchenFlow/diactivateKitchenFlow",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/KitchenFlows/EditKitchenFlows?kitchenflowsid=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  ); 

/* ------------------------------------------- Add Station To kitchen Flow ------------------------------------------- */
  export const AddStationKitchen = createAsyncThunk( "kitchenFlow/AddStationKitchen", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/KitchenFlows/AddKitchenFlowsStations?kitchenflowsstations=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


/* ------------------------------------------- Get kitchen Flow Station ------------------------------------------- */
  export const getKitchenFlowStations = createAsyncThunk("kitchenFlow/getKitchenFlowStations", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Manage/KitchenFlows/KitchenFlowsStationsList?kitchenflowsid={"KitchenFlowsId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  
  export const getBrancheKitchenFlow = createAsyncThunk(
    "kitchenFlow/getBrancheKitchenFlow",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  
  export const AddKitchenFlowCertinBranche = createAsyncThunk(
    "kitchenFlow/AddKitchenFlowCertinBranche",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/KitchenFlows/AddKitchenFlowsBranches?kitchenflowsbranches=${JSON.stringify(args)}`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getKitchenFlowBranches= createAsyncThunk(
    "kitchenFlow/getKitchenFlowBranches",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Manage/KitchenFlows/KitchenFlowsBranchesList?kitchenflowsid={"KitchenFlowsId":${args}}`);
       //console.log(data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getAllProductKitchenFlow = createAsyncThunk(
    "kitchenFlow/getAllProductKitchenFlow",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Product/LookupProduct`);
        //console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const AddProductKitchenFlow = createAsyncThunk(
    "kitchenFlow/AddProductKitchenFlow",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/KitchenFlows/AddKitchenFlowsProduct?kitchenflowsproduct=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const getKitchenFlowProduct= createAsyncThunk(
    "kitchenFlow/getKitchenFlowProduct",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Manage/KitchenFlows/KitchenFlowsProductList?kitchenflowsid={"KitchenFlowsId":${args}}`);
       //console.log(data)
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const deleteKitchenFlow = createAsyncThunk(
    "kitchenFlow/deleteKitchenFlow",
    async (args, thunkApi) => {
      
      try {
        //console.log(args)
        const { data } = await axios.get(`/Manage/KitchenFlows/DeleteKitchenFlowsById?kitchenflowsid={"KitchenFlowsId":${args}}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  
  export const activeKitchenFlow = createAsyncThunk(
    "kitchenFlow/activeKitchenFlow",
    async (args, thunkApi) => {
      
      try {
        //console.log(args)
        const { data } = await axios.get(`/Manage/KitchenFlows/DeActivateKitchenFlowsById?kitchenflowsid={"KitchenFlowsId":${args}}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

    /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
    export const getkitchenFlowFilteredData = createAsyncThunk("kitchenFlow/getkitchenFlowFilteredData ", async (args, thunkApi) => {
      try {
        return (args);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  export const resetkitchenFlowFilteredData = createAsyncThunk("kitchenFlow/resetkitchenFlowFilteredData ", async (args, thunkApi) => { 
      try {
        return args;
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );