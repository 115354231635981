import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteProduct } from "../../services/productsService";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";

const DeleteModal = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toast = useToast({position: "top",duration: 3000,status: "success",});

  const allProducts = useSelector((state) => state.products);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteProduct(id))
          .unwrap()
          .then((_) => {
            toast({ description: t("pages.products.product_deleted") });
            onClose();
            navigate(`/${routes.products_products}`);
          });
      }}
      isLoading={allProducts.isLoading}
    />
  );
};

export default DeleteModal;
