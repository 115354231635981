export const AllPrivilegesData = [
  //orders
  { Id: 1, componentName: "Read Orders" },
  { Id: 2, componentName: "Manage Orders" },

  //fadla fe al orders
  { Id: 3, componentName: "Manage Orders Tags" },

  //customer
  { Id: 4, componentName: "Read Customers" },
  { Id: 6, componentName: "Manage Customers" },

  { Id: 5, componentName: "Read Customers Insights" },
  { Id: 7, componentName: "Manage Customers House Account" },
  { Id: 8, componentName: "Manage Customers Loyalty" },

  { Id: 9, componentName: "Read Inventory Items" }, // Working
  { Id: 10, componentName: "Manage Inventory Items" }, // Working

  { Id: 11, componentName: "Read Supplers" }, // Working
  { Id: 12, componentName: "Manage Supplers" }, // Working

  { Id: 13, componentName: "Create Purchase Orders" }, // Working
  { Id: 14, componentName: "Submit Purchase Orders" }, // Working
  { Id: 15, componentName: "Approve Purchase Orders" }, // Working
  { Id: 16, componentName: "View Approved Purchase Orders" }, // Working

  { Id: 17, componentName: "Create Transfer Orders" }, // Working
  { Id: 18, componentName: "Submit Transfer Orders" }, // Working

  { Id: 19, componentName: "Create Transfers" }, // Working
  { Id: 20, componentName: "Send & Receive Transfers" }, // Working

  { Id: 21, componentName: "Create Purchasing" }, // Working
  { Id: 22, componentName: "Submit Purchasing" }, // Working

  { Id: 23, componentName: "Create Purchasing From PO" }, // ???????
  { Id: 24, componentName: "Create Direct Purchasing" }, // ???????

  { Id: 25, componentName: "Create Production" }, // Working
  { Id: 26, componentName: "Submit Production" }, // Working

  { Id: 27, componentName: "Create Quantity Adjustment" }, // Working
  { Id: 28, componentName: "Submit Quantity Adjustment" }, // Working

  { Id: 29, componentName: "Create Cost Adjustment" }, // Working
  { Id: 30, componentName: "Submit Cost Adjustment" }, // Working

  { Id: 31, componentName: "Create Inventory Count" }, // Working
  { Id: 32, componentName: "Submit Inventory Count" }, // Working

  { Id: 33, componentName: "Read Order Transactions" }, // Working

  { Id: 34, componentName: "Create Inventory Spot Check" }, // ???????
  { Id: 35, componentName: "Submit Inventory Spot Check" }, // ???????
  { Id: 36, componentName: "Create Inventory Count Sheets" }, // ???????

  //menu
  { Id: 37, componentName: "Read Menu" },
  { Id: 38, componentName: "Manage Menu" },

  //
  { Id: 39, componentName: "Manage Branches" },
  { Id: 40, componentName: "Manage Coupons" },
  { Id: 41, componentName: "Manage Devices" },
  { Id: 42, componentName: "Manage Discounts" },

  { Id: 43, componentName: "Manage Gift Cards" },
  { Id: 44, componentName: "Manage Promotions" },

  { Id: 45, componentName: "Manage Settings" },
  { Id: 46, componentName: "Manage Delivery Zones" },
  { Id: 47, componentName: "Manage Timed Events" },
  { Id: 48, componentName: "Manage Users" },

  { Id: 49, componentName: "Manage Apps" },

  { Id: 50, componentName: "Manage Taxes & Tax Groups" },
  { Id: 51, componentName: "Payment Methods" },
  { Id: 52, componentName: "Manage Charges" },
  { Id: 53, componentName: "Manage Tags" },
  { Id: 54, componentName: "Manage Reasons" },
  { Id: 55, componentName: "Manage Kitchen Flows" },
  { Id: 56, componentName: "Manage Reservations" },
  { Id: 57, componentName: "Manage Online Ordering" },
  { Id: 58, componentName: "Manage Price Tags" },
  { Id: 59, componentName: "Manage Notifications" },
  { Id: 60, componentName: "Manage Loyalty" },

  { Id: 61, componentName: "Manage Ingredients" },
  { Id: 62, componentName: "Manage Costs" },

  { Id: 63, componentName: "View Cost Analysis Report" },
  { Id: 64, componentName: "View Inventory Control Report" },
  { Id: 65, componentName: "View Inventory Levels Report" },
  { Id: 66, componentName: "Inventory Transactions Reports" },

  { Id: 67, componentName: "View Other Reports" },
  { Id: 68, componentName: "View Sales Reports" },
  { Id: 69, componentName: "View Cost Adjustment History Report" },

  { Id: 70, componentName: "View Ristecho Pay Reports" },
  { Id: 71, componentName: "View Menu Cost Reports" },
  { Id: 72, componentName: "View Inventory Items Cost Report" },

  { Id: 73, componentName: "Access General Dashboard" },
  { Id: 74, componentName: "Access Branches Dashboard" },
  { Id: 75, componentName: "Access Inventory Dashboard" },
  { Id: 76, componentName: "Access Call Center Dashboard" },

  { Id: 77, componentName: "Access Cash Register" },
  { Id: 78, componentName: "Access Devices Management" },
  { Id: 79, componentName: "Access Reports" },
  { Id: 80, componentName: "Act As Driver" },
  { Id: 81, componentName: "Act As Waiter" },
  { Id: 82, componentName: "Add Open Charge" },
  { Id: 83, componentName: "Add Open Price Product" },
  { Id: 84, componentName: "Apply Ahead Orders" },
  { Id: 85, componentName: "Apply Predefined Discounts" },
  { Id: 86, componentName: "Apply Open Discounts" },
  { Id: 87, componentName: "Edit Products Sent To Kitchen" },
  { Id: 88, componentName: "Join Order" },
  { Id: 89, componentName: "Access Drawer Operations" },
  { Id: 90, componentName: "Perform End Of Day" },
  { Id: 91, componentName: "Print Check" },
  { Id: 92, componentName: "Return Order" },
  { Id: 93, componentName: "Split Order" },
  { Id: 94, componentName: "View Done Orders" },
  { Id: 95, componentName: "Void Orders And Products" },
  { Id: 96, componentName: "Perform Payment" },
  { Id: 97, componentName: "Edit Orders Opened By Other Users" },
  { Id: 98, componentName: "Change Table Owner" },
  { Id: 99, componentName: "Register Users Fingerprint" },
  { Id: 100, componentName: "Send To Kitchen Before Payment" },
  { Id: 101, componentName: "Kitchen Reprint" },
  { Id: 102, componentName: "Edit Tables Layout" },
  { Id: 103, componentName: "Close Till/Shift With Active Orders" },
  { Id: 104, componentName: "Manage Product Availability" },
  { Id: 105, componentName: "Perform Spot Check" },
  { Id: 106, componentName: "Pay Orders Without Closing" },
  { Id: 1018, componentName: "Access General Account" },
  { Id: 1019, componentName: "Access Account" },
];
  