import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr,Flex } from "@chakra-ui/react";
import GButton from "../../../../components/ui/button/Button";
import * as FiIcons from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPriceTagsCombos, resetPriceTagsCombosFilteredData } from '../services/priceTagsServices';
import AddComposModal from './Modals/AddComposModal';
import { useEffect } from 'react';
import PriceTagsCombosFilter from './Modals/PriceTagsCombosFilter';
import EditCombosPrices from './Modals/EditCombosPrices';
import Pagination from "components/ui/pagination/Pagination";
import { Spinner } from '@chakra-ui/react';

export default function PriceTagsCombos() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const id = useParams();

    const PriceTagsData = useSelector(state => state.priceTags);

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showAddCombosModal, setShowAddCombosModal] = useState(false);
    const [showEditCombosPricesModal, setShowEditCombosPricesModal] = useState(false);

    useEffect(() => {
      dispatch(getPriceTagsCombos(id.PriceTagsId));
    }, [dispatch])
    

    const resetFunction = () => {
      dispatch(resetPriceTagsCombosFilteredData());
    };

/* --------------------------------------------- Start Pagination  -----------------------------------------------*/
           const recordsPerPage = 10;
           const [currentPageActive, setCurrentPageActive] = useState(1);
           const prePage = (currentPage, setCurrentPage) => {
             if (currentPage > 1) {
               setCurrentPage(currentPage - 1);
             }
           };
         
           const nextPage = (currentPage, setCurrentPage, npage) => {
             if (currentPage < npage) {
               setCurrentPage(currentPage + 1);
             }
           };
         
           const calculateRecords = (data, currentPage, recordsPerPage) => {
             const firstIndex = (currentPage - 1) * recordsPerPage;
             const lastIndex = Math.min(currentPage * recordsPerPage, PriceTagsData?.combosFilteredData?.length);
             return PriceTagsData?.combosFilteredData?.slice(firstIndex, lastIndex);
           };
         
         // Calculate records for activeDiscounts
         const priceTagsCombosData = calculateRecords(
            PriceTagsData?.ProductsData,
             currentPageActive,
             recordsPerPage
           );
/* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    
    <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
          <Text color="text" textTransform="capitalize">  {t("pages.price_tags.combos")}</Text>
          <Flex gap={2}>
                <GButton
                    title={t("pages.price_tags.edit_prices")}
                    bg="light"
                    color="text"
                    fontSize="14px"
                    border="1px"
                    borderColor={"#CBD5E0"}
                    hover={{ bg: "light" }}
                    opacity="0.7"
                    onClick={() => setShowEditCombosPricesModal(true)}
                />
                <GButton
                    title={t("pages.price_tags.add_combos")}
                    bg="light"
                    color="text"
                    fontSize="14px"
                    border="1px"
                    borderColor={"#CBD5E0"}
                    hover={{ bg: "light" }}
                    opacity="0.7"
                    onClick={() => setShowAddCombosModal(true)}
                />
            
          </Flex>

      </Flex>

    <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
    >
            <Flex
            alignItems="center" flexWrap="wrap"
            textTransform="capitalize" gap={4} color="text" bg="light"
            >

            </Flex>


            <GButton
                title={
                    PriceTagsData?.combosFilteredData?.length === 0
                    ? t("general.filter")
                    : t("general.clear_filter")
                }
                border="1px solid"
                borderColor="border"
                postfixElement={
                    PriceTagsData?.combosFilteredData?.length === 0 ? (
                    <FiIcons.FiFilter size={16} />
                    ) : (
                    <IoCloseOutline fontWeight={800} size={20} />
                    )
                }
                hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                onClick={
                    PriceTagsData?.combosFilteredData?.length === 0 
                    ? () => setShowFilterModal(true)
                    : resetFunction
                }
            />
    </Flex>

    <Box
      bg="light" overflow="auto"
      borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
        { 
        PriceTagsData?.isLoading  && ( 
        <Flex p={4}  bg="white"  alignItems="center" justifyContent="center">
            <Spinner /> 
        </Flex>)
        }
      <Table>
        <Thead>
          <Tr>
            <Th>{t("pages.price_tags.name")}</Th>
            <Th>{t("pages.price_tags.original_price")}</Th>
            <Th>{t("pages.price_tags.price")}</Th>
          </Tr>
        </Thead>
        <Tbody>
        {
            PriceTagsData?.combosFilteredData?.length > 0 ? 
            <>
            {PriceTagsData?.combosFilteredData?.map((el, index) => (
                <Tr key={index}>
                    <Td style={{cursor:"pointer"}}>{el?.Combo[0]?.ComboName}</Td>
                    <Td style={{cursor:"pointer"}}> - </Td>
                    <Td style={{cursor:"pointer"}}>{el.NewComboPrice}</Td>
                </Tr>
            ))}
            </>
            :
            <>
            {priceTagsCombosData?.map((el, index) => (
                <Tr key={index}>
                    <Td style={{cursor:"pointer"}}>{el?.Combo[0]?.ComboName}</Td>
                    <Td style={{cursor:"pointer"}}> - </Td>
                    <Td style={{cursor:"pointer"}}>{el.NewComboPrice}</Td>
                </Tr>
            ))}
            </>
        }
        </Tbody>
      </Table>
      <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(PriceTagsData?.ProductsData?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(PriceTagsData?.ProductsData?.length / recordsPerPage)
                )
              }
        />
    </Box>
      
    {showAddCombosModal && (
            <AddComposModal 
                onClose={()=>setShowAddCombosModal(false)} 
                id={id}
            />
        )
    }

    {showEditCombosPricesModal && (
            <EditCombosPrices 
                onClose={()=>setShowEditCombosPricesModal(false)} 
                data={PriceTagsData?.CombosData} 
                id={id}
            />
        )
    }

    {showFilterModal && (
            <PriceTagsCombosFilter 
                onClose={()=>setShowFilterModal(false)} 
                data={PriceTagsData?.CombosData} 
            />
        )
    }

  </>
};
