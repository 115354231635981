import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { productDropList,addProduct, productList,} from "../../services/productService";
import { useParams } from "react-router-dom";

const AddSubGroupModal = ({ onClose }) => {
  const id = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, setValue } = useForm();
  const toast = useToast({position: "top",duration: 3000,  status: "success", });
  const toast2 = useToast({position: "top",duration: 3000,  status: "error", });

  const allProducts = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(productDropList());
  }, [dispatch]);


  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.groups.add_product")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              values.ProductId = values.ProductId?.map((el) => ({
                ProductId: el.ProductId,
              }));
              values.MenuGroupId = id.groupId;
              values.GroupProductsId = null;

              if (values.ProductId === undefined || values.ProductId.length===0) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                dispatch(addProduct(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: t("pages.groups.product_added") });
                    dispatch(productList(id.groupId));
                    onClose();
                  });
              }
              console.log(values);
            })}
          >
            <Stack>
              <MultiSelect
                title={t("general.products")}
                data={
                  Array.isArray(allProducts.productData1)
                    ? allProducts.productData1.map((el) => ({
                        ...el,
                        label: el.ProductName,
                        value: el.ProductId,
                      }))
                    : []
                }
                isMulti={true}
                isRequired={true}
                onChange={(e) => setValue("ProductId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={allProducts.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={allProducts.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddSubGroupModal;
