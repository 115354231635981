import { createSlice } from "@reduxjs/toolkit";
import { getTaxesFilteredData, getTaxesReportList, resetTaxesFilteredData } from "../services/taxesServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const taxesReport = createSlice({
  name: "taxesReport",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------- Get Taxes Report List ------------------------------- */
    builder.addCase(getTaxesReportList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTaxesReportList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getTaxesReportList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

   /* --------------------------------------------------------------------------------------------------- */
 /* --------------------------------------- Taxes Filter Data ------------------------------*/
    builder.addCase(getTaxesFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTaxesFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getTaxesFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetTaxesFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetTaxesFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetTaxesFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default taxesReport.reducer;