import React, { useEffect } from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, Stack, useToast   } from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import Input from "components/form/Input";
import { useForm } from 'react-hook-form';
import GButton from "components/ui/button/Button";
import { addDilveryChargesToDeliveryZone, getchargesForDeliveryCharge } from '../../services/deliveryChargeService';
import { useDispatch, useSelector } from 'react-redux';


export default function AddChargeModal({onClose}) {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
    const { handleSubmit, control, getValues, watch, setValue, register } = useForm();

    const DeliveryCharge = useSelector(state => state.deliverycharge);
    const data = DeliveryCharge.delvierycharege

    useEffect(()=>{
        dispatch(getchargesForDeliveryCharge())
      },[dispatch])

  return <>

    <GModal
        onClose={onClose}
        modalHeader={t("pages.DeliverCharge.Delivery_Charges")}

        modalContent={(

            <Box p={4}>
                <form onSubmit={handleSubmit(values=>{
                        values.ChargeId = values.ChargeId?.ChargeId;
                            if( values.ChargeId === undefined ){
                                toast2( { description: t("general.validate") });
                            }
                            // else {
                            //     dispatch(addDilveryChargesToDeliveryZone(values)).unwrap().then(_ => {
                            //     toast({ description: t("pages.DeliverCharge.created") });
                                
                            //     onClose()
                            //     });
                            // }
                            console.log(values);
                        })}>
                        
                    <Stack spacing={6}>
                        
                            <MultiSelect
                                title={t("pages.DeliverCharge.ChargeName")}
                                data={data && data.map(el => ({
                                    ...el,
                                    label: `${el.ChargeName} ( SAR ${el.Value} )`,
                                    value: el.ChargeId
                                }))}
                                isMulti={false}
                                onChange={(e) => setValue("ChargeId", e)}
                            />

                            <Flex gap={4}>
                                <GButton
                                    type="submit"
                                    title={t("general.save")}
                                    bg="primary"
                                    color="light"
                                    border="1px solid transparent"
                                    hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                                />
                                <GButton
                                    title={t("general.close")}
                                    onClick={onClose}
                                    bg="red.600"
                                    border="1px solid transparent"
                                    color="light"
                                    hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                                />
                            </Flex>


                    </Stack>
                </form>
                    
            </Box>
            
        )}
    />

  </>
}
