import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";


export const getReciveingOnlineOrders = createAsyncThunk(
    "RecivingOrders/getReciveingOnlineOrders",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get("/CallCenter/OnlineOrderingList");
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );



  export const setSettingsOnlineOrder = createAsyncThunk(
    "RecivingOrders/setSettingsOnlineOrder",
    async (args, thunkApi) => {
      try {
        //console.log(args)
        const { data } = await axios.get(`/CallCenter/EditDeviceOnlineOrdering?onlineordering=${JSON.stringify(args)}`);
        console.log(data)
        return thunkApi.fulfillWithValue(data);

      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


