import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* ----------------------------------------------------- Get Items To Link --------------------------------------------- */
export const getItemsToLink = createAsyncThunk("linkItemToSupplier/getItemsToLink",  async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/InventoryItem/LookupInventoryItem`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ----------------------------------------------------- Add Link Item To Supplier --------------------------------------------- */
  export const addLinkItemToSupplier = createAsyncThunk( "linkItemToSupplier/addLinkItemToSupplier", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Inventory/AddItemsSuppliers?itemssuppliers=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ----------------------------------------------------- Get Linked Supplier --------------------------------------------- */
export const getLinkedSupplier = createAsyncThunk("linkItemToSupplier/getLinkedSupplier", async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Inventory/Suppliers/ItemsBySuppliersId?suppliersid={"SuppliersId":${args}}`);
       return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ----------------------------------------------------- Get All Tags To Supplier --------------------------------------------- */
export const getAllTagsToSupplier = createAsyncThunk("linkItemToSupplier/getAllTagsToSupplier", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Setting/Tag/LookupTag`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------- Add Tag To Supplier --------------------------------------------- */
export const addTagToSupplier = createAsyncThunk("linkItemToSupplier/addTagToSupplier", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/SuppliersTag/AddSuppliersTag?supplierstag=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -----------------------------------------------get linked Tags In Supplier--------------------------------------------- */
export const getlinkedTagswithSupplier = createAsyncThunk("linkItemToSupplier/getlinkedTagswithSupplier", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Inventory/SuppliersTag/SuppliersTagListBySupplierId?suppliersid={"SuppliersId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);