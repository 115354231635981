import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr , Flex } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GButton from "components/ui/button/Button";
import { useState } from "react";
import FilterModal from "./modals/FilterModal";
import { resetPurchasingFilteredData } from "../services/purchaseService";
import FilteredDataTable from "./FilteredDataTable";
import PurchasingDataTable from "./PurchasingDataTable";
import DraftPurchasingDataTable from "./DraftPurchasingDataTable";
import ReturnSupplierDataTable from "./ReturnSupplierDataTable";
import AllPurchasingDataTable from "./AllPurchasingDataTable";

const CTable = ({ data , filteredData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedTable, setSelectedTable] = useState("All");
  const [showFilterPurchaseModal, setShowFilterPurchaseModal] =useState(null);

  // Show Filter Modal State
  const resetFunction = () => {
    dispatch(resetPurchasingFilteredData());
  };

  const purchasingData = data?.filter((item)=>{
    return   item?.Type === "Purchasing"
});
const draftData = data?.filter((item)=>{
  return   item?.Status === "Draft"
})
const returnedSupplierData = data?.filter((item)=>{
  return   item?.Type === "Return To Supplier"
})

const purchasingFilteredData = filteredData?.filter((item)=>{
    return   item?.Type === "Purchasing"
})
const draftFilteredData = filteredData?.filter((item)=>{
    return   item?.Status === "Draft"
})
const returnedSupplierFilteredData = filteredData?.filter((item)=>{
    return   item?.Type === "Return To Supplier"
})
  return <>
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
            <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            color={selectedTable === "all" ? "primary" :"none"}  
            borderBottom={selectedTable === "all" ? "2px solid" :"none"}   
            borderColor={selectedTable === "all" ? "primary" :"none"} 
            onClick={() => setSelectedTable("all")}
          />
          
          <GButton
            title={t("general.draft")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            color={selectedTable === "draft" ? "primary" :"none"}  
            borderBottom={selectedTable === "draft" ? "2px solid" :"none"}   
            borderColor={selectedTable === "draft" ? "primary" :"none"} 
            onClick={() => setSelectedTable("draft")}
          />

          <GButton
            title={t("pages.purchases.purchase")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            color={selectedTable === "purchasing" ? "primary" :"none"}  
            borderBottom={selectedTable === "purchasing" ? "2px solid" :"none"}   
            borderColor={selectedTable === "purchasing" ? "primary" :"none"} 
            onClick={() => setSelectedTable("purchasing")}
          />

          <GButton
            title={t("pages.purchases.return_to_supplier")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            color={selectedTable === "supplier" ? "primary" :"none"}  
            borderBottom={selectedTable === "supplier" ? "2px solid" :"none"}   
            borderColor={selectedTable === "supplier" ? "primary" :"none"} 
            onClick={() => setSelectedTable("supplier")}
          />

        </Flex>

        <GButton
            title={
                filteredData?.length === 0
                ? t("general.filter")
                : t("general.clear_filter")
            }
            border="1px solid"
            borderColor="border"
            postfixElement={
                filteredData?.length === 0 ? (
                <FiIcons.FiFilter size={16} />
                ) : (
                <IoCloseOutline fontWeight={800} size={20} />
                )
            }
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            onClick={
                filteredData?.length === 0
                ? () => setShowFilterPurchaseModal(true)
                : resetFunction
            }
        />

            </Flex>

      {
          data?.length > 0 ? 
          <>
          {
              filteredData?.length > 0 ? 
              <FilteredDataTable 
                  data={ 
                      selectedTable === "All" ? filteredData 
                      : selectedTable === "purchasing" ? purchasingFilteredData 
                      : selectedTable === "draft" ? draftFilteredData 
                      : returnedSupplierFilteredData 
                  } 
              />
              : selectedTable === "All" ?
              <AllPurchasingDataTable data={data} />
              : selectedTable === "draft" ? 
              <DraftPurchasingDataTable data={draftData} />
              : selectedTable === "purchasing" ? 
              <PurchasingDataTable data={purchasingData} />
              : 
              <ReturnSupplierDataTable data={returnedSupplierData} />
          }
          </>
          :
          <Flex
              p={4}
              bg="white"
              alignItems="center"
              justifyContent="center"
              color="text"
              textTransform="capitalize"
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
              borderRadius={6}
              >
              {t("pages.branches.no_data")}
          </Flex>
      }

      {showFilterPurchaseModal && (
        <FilterModal
          onClose={() => setShowFilterPurchaseModal(null)}
          data={data}
        />
      )}
    </Box>
  </>;
};

export default CTable;
