import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import GButton from "components/ui/button/Button";
import GModal from 'components/ui/modal/Modal';
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import { useDispatch, useSelector } from 'react-redux';
import { addPurchaseOrderItems } from '../../services/purchasrOrderServices';
import Input from 'components/form/Input';
export default function ImportItemToPurchaseOrder({onClose , data , id}) {

  const dispatch = useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();
  const { handleSubmit, control, getValues, watch, setValue, register } = useForm();


  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.purchase_orders.import_items")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.PurchaseOrdersId = id.PurchaseOrdersId;
            values.PurchaseOrdersItemsId = null;
            values.InventoryItemId = values.InventoryItemId?.map(el => (el.InventoryItemId));

            dispatch(addPurchaseOrderItems(values))
              .unwrap().then(_ => {
                toast({ description: "Purchase order created Successfully" });
                onClose()
              });

          })} >

            <Stack spacing={6}>
              <Input
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.transfers_orders.notes")}
                name="Notes"
                isFormController
                control={control}
                type="file"
                border='none'
              />
              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  
  </>
}
