import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  Text,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
  Alert,
  Input as ChInput,
  AlertIcon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { getTimeZone } from "services/timezoneService";
import {
  getGeneralSettings,
  updateGeneralSettings,
} from "../services/generalSettingsService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";

export default function GeneralSettings() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({
    position: "top",
    status: "success",
    duration: 3000,
  });
  const toast2 = useToast({ position: "top", status: "error", duration: 3000 });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    register,
    formState: { errors },
    trigger,
  } = useForm();

  const generalSettings = useSelector((state) => state.generalSettings);
  const timezone = useSelector((state) => state.timezone);

  const [checkBoxEnableLocalization, setcheckBoxEnableLocalization] =
    useState(false);
  const [checkBoxIsTaxInclusivePricing, setcheckBoxIsTaxInclusivePricing] =
    useState(false);
  const [
    checkBoxRestrictPurchasedItemsToSupplier,
    setcheckBoxRestrictPurchasedItemsToSupplier,
  ] = useState(false);

  const timeZoneSelect = timezone.data?.filter((item) => {
    return item.TimeZoneId === generalSettings.data?.TimeZoneId;
  });

  useEffect(() => {
    dispatch(getGeneralSettings())
      .unwrap()
      .then((response) => {
        reset(response.data);
        setcheckBoxEnableLocalization(response.data.EnableLocalization);
        setcheckBoxIsTaxInclusivePricing(response.data.IsTaxInclusivePricing);
        setcheckBoxRestrictPurchasedItemsToSupplier(
          response.data.RestrictPurchasedItemsToSupplier
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reset]);

  useEffect(() => {
    dispatch(getTimeZone());
  }, [dispatch]);

  useEffect(() => {}, []);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <>
      {generalSettings.errors && (
        <Alert mb={6} status="error">
          <AlertIcon />
          {generalSettings.errors?.map((err) => (
            <Text>{err?.msg || "-"}</Text>
          ))}
        </Alert>
      )}

      <form
        onSubmit={handleSubmit((values) => {
          values.EnableLocalization = checkBoxEnableLocalization;
          values.TimeZoneId = values?.TimeZoneId?.TimeZoneId;
          if (values.TimeZoneId == undefined) {
            toast2({ description: t("validation.choose_first") });
          } else {
            dispatch(updateGeneralSettings({ values }))
              .unwrap()
              .then((_) =>
                toast({
                  description: t("pages.management.settings.settings_updated"),
                })
              )
              .catch((_) =>
                toast2({
                  description: t("pages.management.settings.settings_error"),
                }).dispatch(getGeneralSettings())
              );
          }
        })}
      >
        <Stack
          spacing={6}
          bg="light"
          p={4}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          <FormControl isInvalid={errors?.Country}>
            <FormLabel
              htmlFor="Country"
              style={{ textTransform: "capitalize" }}
            >
              {t("pages.management.settings.general.country")}
            </FormLabel>
            <Controller
              name="Country"
              control={control}
              defaultValue=""
              rules={{
                required: t("validation.required"),
                minLength: {
                  value: 2,
                  message: t("validation.name_long_2"),
                },
                maxLength: {
                  value: 25,
                  message: t("validation.name_long_25"),
                },
                pattern: {
                  value: /^[\p{L}\s]+$/u,
                  message: t("validation.this_name_invalid"),
                },
                validate: {
                  noOnlySpaces: (value) =>
                    !isOnlySpaces(value) || t("validation.no_only_spaces"),
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t("pages.management.settings.general.country")}
                  onChange={(e) => {
                    handleInputChange(e, "Country");
                  }}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.Country && errors?.Country.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors?.Currency}>
            <FormLabel
              htmlFor="Currency"
              style={{ textTransform: "capitalize" }}
            >
              {t("pages.management.settings.general.currency")}
            </FormLabel>
            <Controller
              name="Currency"
              control={control}
              defaultValue=""
              rules={{
                required: t("validation.required"),
                minLength: {
                  value: 2,
                  message: t("validation.name_long_2"),
                },
                maxLength: {
                  value: 25,
                  message: t("validation.name_long_25"),
                },
                pattern: {
                  value: /^[\p{L}\s]+$/u,
                  message: t("validation.this_name_invalid"),
                },
                validate: {
                  noOnlySpaces: (value) =>
                    !isOnlySpaces(value) || t("validation.no_only_spaces"),
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t("pages.management.settings.general.currency")}
                  onChange={(e) => {
                    handleInputChange(e, "Currency");
                  }}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.Currency && errors?.Currency.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors?.BusinessName}>
            <FormLabel
              htmlFor="BusinessName"
              style={{ textTransform: "capitalize" }}
            >
              {t("pages.management.settings.general.business_name")}
            </FormLabel>
            <Controller
              name="BusinessName"
              control={control}
              defaultValue=""
              rules={{
                required: t("validation.required"),
                minLength: {
                  value: 2,
                  message: t("validation.name_long_2"),
                },
                maxLength: {
                  value: 25,
                  message: t("validation.name_long_25"),
                },
                pattern: {
                  value: /^[\p{L}\s]+$/u,
                  message: t("validation.this_name_invalid"),
                },
                validate: {
                  noOnlySpaces: (value) =>
                    !isOnlySpaces(value) || t("validation.no_only_spaces"),
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t(
                    "pages.management.settings.general.business_name"
                  )}
                  onChange={(e) => {
                    handleInputChange(e, "BusinessName");
                  }}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.BusinessName && errors?.BusinessName.message}
            </FormErrorMessage>
          </FormControl>

          <MultiSelect
            title={t("pages.management.settings.general.timezone_id")}
            defaultValue={timeZoneSelect[0]?.TimeZoneName}
            data={timezone?.data.map((el) => ({
              ...el,
              label: el.TimeZoneName,
              value: el.TimeZoneId,
            }))}
            onChange={(e) => setValue("TimeZoneId", e)}
            isMulti={false}
          />

          <FormControl isInvalid={errors?.TaxRegistrationName}>
            <FormLabel
              htmlFor="TaxRegistrationName"
              style={{ textTransform: "capitalize" }}
            >
              {t("pages.management.settings.general.registeration_name")}
            </FormLabel>
            <Controller
              name="TaxRegistrationName"
              control={control}
              defaultValue=""
              rules={{
                required: t("validation.required"),
                minLength: {
                  value: 2,
                  message: t("validation.name_long_2"),
                },
                maxLength: {
                  value: 25,
                  message: t("validation.name_long_25"),
                },
                pattern: {
                  value: /^[\p{L}\s]+$/u,
                  message: t("validation.this_name_invalid"),
                },
                validate: {
                  noOnlySpaces: (value) =>
                    !isOnlySpaces(value) || t("validation.no_only_spaces"),
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t(
                    "pages.management.settings.general.registeration_name"
                  )}
                  onChange={(e) => {
                    handleInputChange(e, "TaxRegistrationName");
                  }}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.TaxRegistrationName &&
                errors?.TaxRegistrationName.message}
            </FormErrorMessage>
          </FormControl>

          <Input
            label={t("pages.management.settings.general.tax_number")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.management.settings.general.tax_number")}
            type="number"
            isFormController
            control={control}
            name="TaxNumber"
          />

          <Checkbox
            {...register("IsTaxInclusivePricing")}
            textTransform="capitalize"
            isChecked={checkBoxIsTaxInclusivePricing}
            onChange={(e) => setcheckBoxIsTaxInclusivePricing(e.target.checked)}
          >
            {t("pages.management.settings.general.is_tax_inclusive_pricing")}
          </Checkbox>

          <Checkbox
            {...register("EnableLocalization")}
            textTransform="capitalize"
            isChecked={checkBoxEnableLocalization}
            onChange={(e) => setcheckBoxEnableLocalization(e.target.checked)}
          >
            {t("pages.management.settings.general.enable_localization")}
          </Checkbox>

          <Checkbox
            {...register("RestrictPurchasedItemsToSupplier")}
            textTransform="capitalize"
            isChecked={checkBoxRestrictPurchasedItemsToSupplier}
            onChange={(e) =>
              setcheckBoxRestrictPurchasedItemsToSupplier(e.target.checked)
            }
          >
            {t(
              "pages.management.settings.general.restrict_purchased_items_to_supplier"
            )}
          </Checkbox>

          <Flex justifyContent="flex-end">
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              isLoading={generalSettings.isLoading}
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Stack>
      </form>
    </>
  );
}
