import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Flex, Stack, useToast } from "@chakra-ui/react";
import { axios } from "services/axios";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getLanguages } from "services/languagesService";

const Receipt = () => {
  const { t } = useTranslation();
  const [showOrderNumber, setShowOrderNumber] = useState(false);
  const [showCalories, setShowCalories] = useState(false);
  const [showSubtotal, setShowSubtotal] = useState(false);
  const [showRounding, setShowRounding] = useState(false);
  const [showCloserUsername, setShowCloserUsername] = useState(false);
  const [showCreatorUsername, setShowCreatorUsername] = useState(false);
  const [showCheckNumber, setShowCheckNumber] = useState(false);
  const [hideFreeModifierOptions, setHideFreeModifierOptions] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages);
  const toast = useToast({
    position: "top",
    status: "success",
    duration: 3000,
  });
  const { control, register, reset, setValue, handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const getReceiptSetting = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        "/Manage/Setting/ReciptSetting/ListReceiptSettings"
      );
      if (typeof data === "object" && Array.isArray(data)) {
        const jsonData = JSON.parse(data[0]?.jsonData)[0] || {};
        reset(jsonData);
        console.log(jsonData);
        setShowOrderNumber(jsonData.ShowOrderNumber || false);
        setShowCalories(jsonData.ShowCalories || false);
        setShowSubtotal(jsonData.ShowSubtotal || false);
        setShowRounding(jsonData.ShowRounding || false);
        setShowCloserUsername(jsonData.ShowCloserUsername || false);
        setShowCreatorUsername(jsonData.ShowCreatorUsername || false);
        setShowCheckNumber(jsonData.ShowCheckNumber || false);
        setHideFreeModifierOptions(jsonData.HideFreeModifierOptions || false);
      }
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  };
  const updateReceiptSetting = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `/Manage/Setting/ReciptSetting/AddReceiptSetting?receiptSetting=${JSON.stringify(
          values
        )}`
      );
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  };

  useEffect(() => {
    getReceiptSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);

  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.ReceiptImagePath = null;
          values.PrintLanguage = values.PrintLanguage?.map((el) => ({
            LanguageId: el.LanguageId,
          }));
          values.MainLanguageId = values.MainLanguageId?.LanguageId;
          values.LocalizedLanguageId = values.LocalizedLanguageId?.LanguageId;
          values.ReceiptSettingsId = 1;

          console.log(values);
          updateReceiptSetting(values)
            .then((_) =>
              toast({
                description: t("pages.management.settings.settings_updated"),
              })
            )
            .catch((_) =>
              toast({ status: "error", description: "something went wrong!" })
            );
        })}
      >
        <Stack spacing={6} bg="light" p={4}>
          <MultiSelect
            data={
              languages.data &&
              languages.data.map((el) => ({
                ...el,
                label: el.LanguageNameAr,
                value: el.LanguageId,
              }))
            }
            title={t("pages.management.settings.receipt.print_language")}
            isMulti={true}
            onChange={(e) => setValue("PrintLanguage", e)}
          />

          <MultiSelect
            data={
              languages.data &&
              languages.data.map((el) => ({
                ...el,
                label: el.LanguageNameAr,
                value: el.LanguageId,
              }))
            }
            isMulti={false}
            title={t("pages.management.settings.receipt.main_language")}
            onChange={(e) => setValue("MainLanguageId", e)}
          />

          <MultiSelect
            data={
              languages.data &&
              languages.data.map((el) => ({
                ...el,
                label: el.LanguageNameAr,
                value: el.LanguageId,
              }))
            }
            isMulti={false}
            title={t("pages.management.settings.receipt.localized_language")}
            onChange={(e) => setValue("LocalizedLanguageId", e)}
          />

          <Input
            label={t("pages.management.settings.receipt.reciept_header")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.management.settings.receipt.reciept_header")}
            isFormController
            control={control}
            name="ReceiptHeader"
          />

          <Input
            label={t("pages.management.settings.receipt.reciept_footer")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.management.settings.receipt.reciept_footer")}
            isFormController
            control={control}
            name="ReceiptFooter"
          />

          <Input
            label={t("pages.management.settings.receipt.invoice_title")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.management.settings.receipt.invoice_title")}
            isFormController
            control={control}
            name="InvoiceTitle"
          />

          <Checkbox
            {...register("ShowOrderNumber")}
            isChecked={showOrderNumber}
            onChange={(e) => setShowOrderNumber(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.receipt.show_order_number")}
          </Checkbox>

          <Checkbox
            {...register("ShowCalories")}
            isChecked={showCalories}
            onChange={(e) => setShowCalories(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.receipt.show_calories")}
          </Checkbox>

          <Checkbox
            {...register("ShowSubtotal")}
            isChecked={showSubtotal}
            onChange={(e) => setShowSubtotal(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.receipt.show_subtotal")}
          </Checkbox>

          <Checkbox
            {...register("ShowRounding")}
            isChecked={showRounding}
            onChange={(e) => setShowRounding(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.receipt.show_rounding")}
          </Checkbox>

          <Checkbox
            {...register("ShowCloserUsername")}
            isChecked={showCloserUsername}
            onChange={(e) => setShowCloserUsername(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.receipt.show_closer_username")}
          </Checkbox>

          <Checkbox
            {...register("ShowCreatorUsername")}
            isChecked={showCreatorUsername}
            onChange={(e) => setShowCreatorUsername(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.receipt.show_creator_username")}
          </Checkbox>

          <Checkbox
            {...register("ShowCheckNumber")}
            isChecked={showCheckNumber}
            onChange={(e) => setShowCheckNumber(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.receipt.show_check_number")}
          </Checkbox>

          <Checkbox
            {...register("HideFreeModifierOptions")}
            isChecked={hideFreeModifierOptions}
            onChange={(e) => setHideFreeModifierOptions(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.receipt.hide_free_modifier_options")}
          </Checkbox>

          <Flex justifyContent="flex-end">
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              isDisabled={isLoading}
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Stack>
      </form>
    </>
  );
};

export default Receipt;
