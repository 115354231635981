import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import NoDataFound from "components/ui/noDataFound/NoDataFound";
import Pagination from "components/ui/pagination/Pagination";
import EditSettingNationality from "./modal/EditSettingNationality";
import DeleteSettingNationality from "./modal/DeleteSettingNationality";

export default function HrSettingNationalityTable({ data, isLoading, error }) {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const activeData = data?.filter((el) => {
    return el.isdeleted === false;
  });

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (activeData, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(
      currentPage * recordsPerPage,
      activeData?.length
    );
    return activeData?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const nationalityData = calculateRecords(
    activeData,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  if (isLoading) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    );
  }
  if (error) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Text> Some Thing Went error </Text>
      </Flex>
    );
  }

  if (data?.length === 0) {
    return <NoDataFound />;
  }

  return (
    <>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <GButton
              title={t("general.all")}
              bg="none"
              isDisabled={false}
              borderRadius={0}
              borderColor="primary"
            />
          </Flex>
        </Flex>

        <Table>
          <Thead>
            <Tr>
              <Th>{t("pages.hr.nationality_id")}</Th>
              <Th>{t("pages.hr.nationality_name")}</Th>
              <Th>{t("pages.hr.nationality_name_en")}</Th>
              <Th>{t("general.edit")}</Th>
              <Th>{t("general.delete")}</Th>
            </Tr>
          </Thead>

          <Tbody>
            {nationalityData?.map((el, index) => (
              <Tr key={index}>
                <Td> {el?.NationalityId} </Td>
                <Td>{el?.NationalityName}</Td>
                <Td>{el?.NationalityNameEn}</Td>
                <Td>
                  <Button
                    colorScheme="telegram"
                    size="sm"
                    onClick={() => setShowEditModal(el)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("general.edit")} </span>
                    </Flex>
                  </Button>
                </Td>
                <Td>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => setShowDeleteModal(el?.NationalityId)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("general.delete")} </span>
                    </Flex>
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(nationalityData?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(nationalityData?.length / recordsPerPage)
            )
          }
        />

        {showEditModal && (
          <EditSettingNationality
            onClose={() => setShowEditModal(false)}
            details={showEditModal}
          />
        )}
        {showDeleteModal && (
          <DeleteSettingNationality
            onClose={() => setShowDeleteModal(false)}
            id={showDeleteModal}
          />
        )}
      </Box>
    </>
  );
}
