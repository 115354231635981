import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* ------------------------- Get Inventory CostAnalysis Services Report List -------------------------- */
  export const getinventoryCostAnalysisReportList = createAsyncThunk("inventoryCostAnalysis/getMenuEngineeringReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Reports/GetInventoryCostReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


 /* --------------------------------- Inventory CostAnalysis  Filter Data ---------------------------*/
 export const getinventoryCostAnalysisFilteredData = createAsyncThunk("inventoryCostAnalysis/getinventoryCostAnalysisFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetinventoryCostAnalysisFilteredData = createAsyncThunk( "inventoryCostAnalysis/resetinventoryCostAnalysisFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);