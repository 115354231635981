import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text } from "@chakra-ui/react"
import GButton from "components/ui/button/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({data,handleUpdate, handleDelete , filteredDate}) => {
  const { t } = useTranslation();
  const paymentMethodsStore = useSelector(state => state.paymentMethods);
  
  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 12;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

// Calculate records for activeDiscounts
const paymentMethodsData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
/* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return <>
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6} mt={6}>
   
      {paymentMethodsData.map(paymentMethod => (
        <Box key={paymentMethod.PaymentMethodId}
          bg="light" p={4} borderRadius={4}
          boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
        >
          <Flex justifyContent="space-between">
            <Text textTransform="capitalize">{paymentMethod?.PaymentMethodName}</Text>
          </Flex>
          <Flex justifyContent="space-between">
                <Text textTransform="capitalize" color="text">{paymentMethod.PaymentMethodCode}</Text>
                <Button bg='cyan' size='xs' color="black">
                    {t("general.active")}
                </Button>
          </Flex>

          <Flex justifyContent="space-between" gap={1} mt={2} >
                <GButton
                  title={t("general.edit")}
                  w="100%"
                  onClick={() => handleUpdate(paymentMethod)}
                  bg="primary"
                  border="1px solid transparent"
                  color="light"
                  isLoading={paymentMethodsStore?.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.delete")}
                  w="100%"
                  onClick={() => handleDelete(paymentMethod)}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={paymentMethodsStore?.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
          </Flex>
        </Box>
      ))}
    </SimpleGrid>
    <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(data?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(data?.length / recordsPerPage)
            )
          }
    />
  </>
};

export default CTable;