import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import {getCustomerDetailsById } from "../services/customersService";
import Tags from "../components/Tags";
import Addresses from "../components/Addresses";
import DeleteCustomerModal from "../components/modals/DeleteCustomerModal";
import LoyalityModal from "../components/modals/LoyalityModal";
import UpdateCustomerModal from "../components/modals/UpdateCustomerModal";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

export default function Details (){

  const retrievedArray =useDecryptedPrivilege()
  const ManageCustomers = checkIdExists(retrievedArray, 6);
  const ReadCustomers = checkIdExists(retrievedArray, 4);



  const {customerId}  = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const customers = useSelector((state) => state.customers);

  const [showUpdateCustomerModal, setShowUpdateCustomerModal] = useState(false);
  const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(false);
  const [showLoyalityStatus, setShowLoyalityStatus] = useState("disabled");

  useEffect(() => {
    dispatch(getCustomerDetailsById(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {

  }, [customers.customerDetails]);

 // console.log(customers.customerDetails)

 const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(ManageCustomers===false && ReadCustomers===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  if(showUpdateCustomerModal===true && ManageCustomers===false){
    toast2({ description:  t("general.authorization") })
    setShowUpdateCustomerModal(false)
  }

  if(showDeleteCustomerModal===true && ManageCustomers===false){
    toast2({ description:  t("general.authorization")})
    setShowDeleteCustomerModal(false)
  }
  
  return <>
  
    {

      customers.customerDetails ? 
      <>
        <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            {customers.customerDetails?.CustomerName || "-"}
          </Box>
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            {
              showLoyalityStatus === "disabled" ?
            
                <GButton
                  // title={t("pages.customers.edit_Customer")}
                  title={t("pages.customers.disable_Loyality")}
                  bg="light"
                  mx={1}
                  color="gray"
                  hover={{ bg: "primary", color: "light" }}
                  border="1px"
              />
            :
                <GButton
                title={t("pages.customers.enable_Loyality")}
                bg="light"
                mx={1}
                color="gray"
                hover={{ bg: "primary", color: "light" }}
                border="1px"
              />
            }
            <GButton
              // title={t("pages.customers.edit_Customer")}
              title={"disabled House Account"}
              bg="light"
              mx={1}
              color="gray"
              hover={{ bg: "primary", color: "light" }}
              border="1px"
            />

            <GButton
              title={t("pages.customers.delete")}
              bg="red.600"
              border="1px solid transparent"
              color="light"
              hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
              onClick={() => setShowDeleteCustomerModal(true)}
            />
            <GButton
              title={t("pages.customers.edit_Customer")}
              bg="#54A0FF"
              mx={1}
              color="light"
              hover={{ bg: "primary" }}
              onClick={() =>setShowUpdateCustomerModal(true)}
            />
          </Box>
        </Flex>
        </BreadCrumb>

        <Stack spacing={12}>
          {/* details */}
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            <Box
              bg="light"
              p={6}
              borderRadius={4}
              boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
            >
              <Flex justifyContent="space-between">
                <Text textTransform="capitalize">total orders</Text>
              </Flex>
              <Text textTransform="capitalize" color="text">
                {customers.customerDetails.TotalOrders}
              </Text>
            </Box>
            <Box
              bg="light"
              p={6}
              borderRadius={4}
              boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
            >
              <Flex justifyContent="space-between">
                <Text textTransform="capitalize">last orders</Text>
              </Flex>
              <Text textTransform="capitalize" color="text">
                {customers.customerDetails.lastorders}
              </Text>
            </Box>
            <Box
              bg="light"
              p={6}
              borderRadius={4}
              boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
            >
              <Flex justifyContent="space-between">
                <Text textTransform="capitalize">account balance</Text>
              </Flex>
              <Text textTransform="capitalize" color="text">
                {customers.customerDetails.accountbalance}
              </Text>
            </Box>
          </SimpleGrid>

          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text">
                {t("pages.customers.name")}
              </Text>
              <Text marginBlock={2}>{customers.customerDetails?.CustomerName || "-"}</Text>
            </Box>
            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text">
                {t("pages.customers.phone")}
              </Text>
              <Text marginBlock={2}>{customers.customerDetails?.PhoneNo || "-"}</Text>
            </Box>
            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text">
                {t("pages.customers.email")}
              </Text>
              <Text marginBlock={2}>{customers.customerDetails?.Email || "-"}</Text>
            </Box>
            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text">
                is loyality enabled
              </Text>
              <Text marginBlock={2}>
                {customers.customerDetails?.IsLoyaltyEnabled ? "yes" : "no"}
              </Text>
            </Box>
            <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
            {t("pages.customers.gender")}
            </Text>
            <Text marginBlock={2}>
              {customers.customerDetails?.Gender==="m"? t("pages.customers.male") : t("pages.customers.female")}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
          {t("pages.customers.date")}
          </Text>
          <Text marginBlock={2}>
            {customers.customerDetails?.BirthDate}
          </Text>
        </Box>
          </SimpleGrid>

        <Tags id={customerId} />
        <Addresses id={customerId} />


        {
          showLoyalityStatus === "disabled" ?
          ""
          :
          <LoyalityModal />
        }
        </Stack>
      </>
      : 
      <>
        <Flex
          bg="light"
          alignItems="center"
          justifyContent="center"
          p={4}
          textTransform="capitalize"
        >
          {t("pages.customers.no_customer")}
        </Flex>
      </>

    }

      {showUpdateCustomerModal && (
        <UpdateCustomerModal
          data={showUpdateCustomerModal}
          onClose={() => setShowUpdateCustomerModal(false)}
          customerId={customerId}
        />
      )}

      {showDeleteCustomerModal && (
        <DeleteCustomerModal
          onClose={() => setShowDeleteCustomerModal(false)}
          id={customerId}
        />
      )}

  </>
};

