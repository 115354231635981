import IAxios from "axios";

const axios = IAxios.create({
  timeout: 10000,
  // baseURL: "http://localhost:8000"
  baseURL: "https://rostoapi.smartgate-egypt.com",
  //baseURL: "https://ristecho.api.niyat.sa",
});

axios.interceptors.request.use(config => {
  return config;
})

export { axios };