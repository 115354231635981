import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getByIdTree = createAsyncThunk(
  "AccountTree/getByIdTree",
  async (args, thunkApi) => {
    try {
      return thunkApi.fulfillWithValue(args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getAccounts = createAsyncThunk(
  "AccountTree/getAccounts",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Accountant/LookupGetAccountJournal`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getAfterFilter = createAsyncThunk(
  "AccountTree/getAfterFilter",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Accountant/TeacherAccount?data=${JSON.stringify(args)}`
      );
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createAccountCycle = createAsyncThunk(
  "AccountTree/createAccountCycle",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Accountant/AddFinancialPeriods?financialperiods=${JSON.stringify(
          args
        )}`
      );
      console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

//DailyRestriction

export const getHeaderLookUp = createAsyncThunk(
  "AccountTree/getHeaderLookUp",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Accountant/LookupGetJournalHeader`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

//get journal by id

export const getJournalById = createAsyncThunk(
  "AccountTree/getJournalById",
  async (args, thunkApi) => {
    try {
      //console.log(args)
      const { data } = await axios.get(
        `/Accountant/GetJournalDetailByHeaderId?journalheaderid={"JournalHeaderID":${args}}`
      );
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

//get finacial perdiod dailyRestriction

export const GetFinancialPeriodsDailyRestriction = createAsyncThunk(
  "AccountTree/GetFinancialPeriodsDailyRestriction",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Accountant/GetFinancialPeriodsList?data=1`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

//get all acounts for add

export const getAccountsLookUp = createAsyncThunk(
  "AccountTree/getAccountsLookUp",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Accountant/LookupGetAccountJournal`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

//add in account tree

// export const createAccountTreeNode = createAsyncThunk(
//   "AccountTree/createAccountTreeNode",
//   async (args, thunkApi) => {
//     try {
//      const { data } = await axios.get(`/Accountant/AddAccountChart?accountchart=${JSON.stringify(args)}`);
//      console.log(data)
//      return thunkApi.fulfillWithValue(data);

//     } catch (e) {
//       return thunkApi.rejectWithValue(e.response.data);
//     }
//   }
// );

//add in daily restriction

export const addInDailyRestriction = createAsyncThunk(
  "AccountTree/addInDailyRestriction",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Accountant/AddJournalDetail?journaldetail=${JSON.stringify(args)}`
      );
      console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

//ميزان المراجعه

//https://rostoapi.smartgate-egypt.com/Accountant/GetTrialBalance?data=

export const GetTrialBalance = createAsyncThunk(
  "AccountTree/GetTrialBalance",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Accountant/GetTrialBalance?data=${JSON.stringify(args)}`
      );
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* -------------------------------------------------  Get Income Statment --------------------------------------------------- */
export const GetIncomeStatment = createAsyncThunk(
  "AccountTree/GetIncomeStatment",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/accountant/GetIncomeStatment?data=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
