import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";
// priceTags

/* ------------------------------------------------- Get  Price Tags -------------------------------------------------  */
export const getPriceTags = createAsyncThunk("priceTags/getPriceTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage2/GetPriceTags" );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------- Get  Price Tags Details -------------------------------------------------  */
export const getPriceTagsDetails = createAsyncThunk("priceTags/getPriceTagsDetails", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`Manage2/GetPriceTagById?pricetagid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   get Order Tags DrobDown   -------------------------------------------------  */
  export const getOrderTags = createAsyncThunk("priceTags/getOrderTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage2/GetOrderTags" );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* --------------------------------------------   get Branches Tags DrobDown   ------------------------------------------  */
  export const getBranchesTags = createAsyncThunk("priceTags/getBranchesTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Setting/CallCenter/LookUpBranches" );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* --------------------------------------------  Get Product In Price Tags DrobDown   ------------------------------------------  */
  export const getProductInPriceTags = createAsyncThunk("priceTags/getProductInPriceTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Product/LookupProduct" );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* --------------------------------------------  Get Combos In Price Tags DrobDown   ------------------------------------------  */
  export const getCombosInPriceTags = createAsyncThunk("priceTags/getCombosInPriceTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Combo/LookupCombo" );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* --------------------------------------------  Get Modifier Options In Price Tags DrobDown   ------------------------------------------  */
  export const getModifierOptionsInPriceTags = createAsyncThunk("priceTags/getModifierOptionsInPriceTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Menu/ModiferOption/LookupAllModiferOption" );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   Create Price Tags   -----------------------------------------  */
  export const createEditPriceTag = createAsyncThunk("priceTags/createEditPriceTag", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage2/AddEditPriceTag?pricetag=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   Add Price Tags Products   -----------------------------------------  */
  export const addPriceTagsProducts = createAsyncThunk("priceTags/addPriceTagsProducts", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/AddPriceTagProduct?pricetagproduct=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   Add Price Tags Combos   -----------------------------------------  */
  export const addPriceTagsCombos = createAsyncThunk("priceTags/addPriceTagsCombos", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/AddPriceTagCombo?pricetagcombo=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   Add Price Tags Modifier Options   -----------------------------------------  */
  export const addPriceTagsModifierOptions = createAsyncThunk("priceTags/addPriceTagsModifierOptions", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/AddPriceTagMO?pricetagmo=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   get Price Tags Products   -----------------------------------------  */
  export const getPriceTagsProducts = createAsyncThunk("priceTags/getPriceTagsProducts", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/GetPriceTagsProductsById?pricetagid={"PriceTagsId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   get Price Tags Combos   -----------------------------------------  */
  export const getPriceTagsCombos = createAsyncThunk("priceTags/getPriceTagsCombos", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/GetPriceTagsCombosById?pricetagid={"PriceTagsId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   get Price Tags Modifier Options   -----------------------------------------  */
  export const getPriceTagsModifierOptions = createAsyncThunk("priceTags/getPriceTagsModifierOptions", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/GetPriceTagsModifierOptionsById?pricetagid={"PriceTagsId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   Edit Products Prices in Price Tags   -----------------------------------------  */
  export const editProductsPricesPriceTags = createAsyncThunk("priceTags/editProductsPricesPriceTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/UpdatePriceTagProduct?productid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   Edit Combos Prices in Price Tags   -----------------------------------------  */
  export const editCombosPricesPriceTags = createAsyncThunk("priceTags/editCombosPricesPriceTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/UpdatePriceTagCombo?comboid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------   Edit Modifier Options Prices in Price Tags   -----------------------------------------  */
  export const editModifierOptionsPricesPriceTags = createAsyncThunk("priceTags/editModifierOptionsPricesPriceTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/UpdatePriceTagMO?modifieroptionid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* ----------------------------------------------------------------- Price Tag Products Filtered Data  -------------------------------------------------------*/
  export const getPriceTagsFilteredData = createAsyncThunk("priceTags/getPriceTagsFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetPriceTagsFilteredData = createAsyncThunk( "priceTags/resetPriceTagsFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* ----------------------------------------------------------------- Price Tag Products Filtered Data  -------------------------------------------------------*/
  export const getPriceTagsProductsFilteredData = createAsyncThunk("priceTags/getPriceTagsProductsFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetPriceTagsProductsFilteredData = createAsyncThunk( "priceTags/resetPriceTagsProductsFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

  /* ----------------------------------------------------------------- Price Tag Combos Filtered Data  -------------------------------------------------------*/
  export const getPriceTagsCombosFilteredData = createAsyncThunk("priceTags/getPriceTagsCombosFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetPriceTagsCombosFilteredData = createAsyncThunk( "priceTags/resetPriceTagsCombosFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* ----------------------------------------------------------- Price Tag Modifier Options Filtered Data  -----------------------------------------------*/
  export const getPriceTagsModifierFilteredData = createAsyncThunk("priceTags/getPriceTagsModifierFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetPriceTagsModifierFilteredData = createAsyncThunk( "priceTags/resetPriceTagsModifierFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);