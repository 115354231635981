import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import AppliesOnCategoriesModal from "./modals/AppliesOnCategoriesModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CategoriesTable from "./CategoriesTable";
import DeleteAppliesModal from "./modals/DeleteAppliesModal";
import { getTimedEventCategories } from "../services/timedEventsCategoriesService";

const AppliesOnCategories = () => {
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showTimedEventCategories, setShowTimedEventCategories] =
    useState(false);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(null);

  useEffect(() => {
    dispatch(getTimedEventCategories(id.id));
  }, [dispatch, id]);

  const categoriesTimedEve = useSelector(
    (state) => state.timedEventsCategories
  );

  console.log("categoriesTimedEve",categoriesTimedEve);

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.timedEvents.applies_on_categories")}
        </Text>

        <GButton
          title={t("pages.timedEvents.add_categories")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowTimedEventCategories(true)}
        />
      </Flex>

      {categoriesTimedEve.isLoading ? (
        <Spinner />
      ) : categoriesTimedEve.data?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : categoriesTimedEve.data?.length > 0 ? (
        <CategoriesTable
          allCategoriesTimedEvent={categoriesTimedEve.data}
          handleDelete={(cateogry) => setShowDeleteCategoryModal(cateogry)}
        />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {showTimedEventCategories && (
        <AppliesOnCategoriesModal
          onClose={() => setShowTimedEventCategories(false)}
        />
      )}

      {showDeleteCategoryModal && (
        <DeleteAppliesModal
          id={showDeleteCategoryModal}
          onClose={() => setShowDeleteCategoryModal(null)}
          type="Cateogry"
        />
      )}
    </Box>
  );
};

export default AppliesOnCategories;
