import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- Get Timed Events List  -------------------------------------------------------------------*/
export const getTimedEvents = createAsyncThunk(
  "timedEvents/getTimedEvents",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/GetTimedEventList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- create Timed Event  -------------------------------------------------------------------*/
export const createTimedEvent = createAsyncThunk(
  "timedEvents/createTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/AddTimedEvent?timeevent=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Edit Timed Event -------------------------------------------------------------------*/
export const updateTimedEvent = createAsyncThunk(
  "timedEvents/updateTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/AddTimedEvent?timeevent=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete Timed Event  -------------------------------------------------------------------*/
export const deleteTimedEvent = createAsyncThunk(
  "timedEvents/deleteTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DeleteTimedEvent?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------- Get Timed Event By ID (Details) --------------------------------------- */
export const getTimedEventById = createAsyncThunk(
  "timedEvents/getTimedEventById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetTimedEventById?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -----------------------------------------------  Activate Timed Event  --------------------------------------- */
export const activateTimedEvent = createAsyncThunk(
  "timedEvents/activateTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DeActivateTimedEvent?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
