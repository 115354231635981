import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const getBrancheTrendsReports = createAsyncThunk("BrancheTrends/getBrancheTrendsReports",  
async (args, thunkApi) => {
try {
  const { data } = await axios.get(`/Reports/GetBranchesTrendReport`);
  return thunkApi.fulfillWithValue(data);
} catch (e) {
  return thunkApi.rejectWithValue(e.response.data);
}
}
);