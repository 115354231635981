import React, { Fragment, useState } from "react";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { IoCloseOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { resetQuantityAdjustmentFilteredData } from "../services/quantityAdjustmentService";
import { useDispatch } from "react-redux";
import FilterModal from "./modal/FilterModal";
import Pagination from "components/ui/pagination/Pagination";

const QuantityAdjustmentTable = ({ data, filteredData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigatefunction = (id) => {
    navigate(`${id}`);
  };

  // Show Filter Modal State
  const [showFilterModal, setShowFilterModal] = useState(false);

  const resetFunction = () => {
    dispatch(resetQuantityAdjustmentFilteredData());
  };

  const [filteredPageAll, setFilteredPageAll] = useState(1);
  const [currentPageAll, setCurrentPageAll] = useState(1);

  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const recordsPerPage = 10;

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  const filteredRecordsAll = calculateRecords(
    filteredData,
    filteredPageAll,
    recordsPerPage
  );
  const recordsAll = calculateRecords(data, currentPageAll, recordsPerPage);

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom="2px solid"
            borderColor="primary"
            //onClick={()=>setdeleteitem(false)}
          />
        </Flex>

        <GButton
          title={
            filteredData?.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
            filteredData?.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          onClick={
            filteredData?.length === 0
              ? () => setShowFilterModal(true)
              : resetFunction
          }
        />
      </Flex>

      <Fragment>
        {filteredData.length > 0 ? (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.quantity_adjustment.branch")}</Th>
                  <Th>{t("pages.quantity_adjustment.reason")}</Th>
                  <Th>{t("pages.quantity_adjustment.status")}</Th>
                  <Th>{t("pages.quantity_adjustment.Business_Date")}</Th>
                  <Th>{t("pages.quantity_adjustment.Created")}</Th>
                  <Th>
                    {t("pages.quantity_adjustment.QuantityAdjustmentRef")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredRecordsAll?.map((el, index) => (
                  <Tr key={index} style={{ cursor: "pointer" }}>
                    <Td> {index + 1} </Td>
                    <Td
                      onClick={() => navigatefunction(el.QuantityAdjustmentId)}
                    >
                      {el.BranchName}
                    </Td>
                    <Td>{el.ReasonNameEng}</Td>
                    <Td>{el.QuantityAdjustmentStatus}</Td>
                    <Td>{el.BusinessDate}</Td>
                    <Td>{el.created}</Td>
                    <Td>{el.QuantityAdjustmentRef}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={filteredPageAll}
              setCurrentPage={setFilteredPageAll}
              npage={Math.ceil(filteredData.length / recordsPerPage)}
              prePage={() => prePage(filteredPageAll, setFilteredPageAll)}
              nextPage={() =>
                nextPage(
                  filteredPageAll,
                  setFilteredPageAll,
                  Math.ceil(filteredData.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.quantity_adjustment.branch")}</Th>
                  <Th>{t("pages.quantity_adjustment.reason")}</Th>
                  <Th>{t("pages.quantity_adjustment.status")}</Th>
                  <Th>{t("pages.quantity_adjustment.Business_Date")}</Th>
                  <Th>{t("pages.quantity_adjustment.Created")}</Th>
                  <Th>
                    {t("pages.quantity_adjustment.QuantityAdjustmentRef")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {recordsAll?.map((el, index) => (
                  <Tr key={index} style={{ cursor: "pointer" }}>
                    <Td> {index + 1} </Td>
                    <Td
                      onClick={() => navigatefunction(el.QuantityAdjustmentId)}
                    >
                      {el.BranchName}
                    </Td>
                    <Td>{el.ReasonNameEng}</Td>
                    <Td>{el.QuantityAdjustmentStatus}</Td>
                    <Td>{el.BusinessDate}</Td>
                    <Td>{el.created}</Td>
                    <Td>{el.QuantityAdjustmentRef}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={currentPageAll}
              setCurrentPage={setCurrentPageAll}
              npage={Math.ceil(data.length / recordsPerPage)}
              prePage={() => prePage(currentPageAll, setCurrentPageAll)}
              nextPage={() =>
                nextPage(
                  currentPageAll,
                  setCurrentPageAll,
                  Math.ceil(data.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        )}
      </Fragment>

      {showFilterModal && (
        <FilterModal
          onClose={() => setShowFilterModal(false)}
          data={data}
          filterData={filteredData}
        />
      )}
    </Box>
  );
};

export default QuantityAdjustmentTable;
