import React from "react";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";
import { deleteOrder } from "../../services/transfersOrdersService";

const DeleteModal = ({ onClose, id }) => {
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000,status: "success"});
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteOrder(id))
          .unwrap()
          .then((_) => {
            toast({ description: t("pages.transfers_orders.deleted_successfuly") });
            onClose();
            navigate(`/${routes.inventory_transfers_orders}`);
          });
      }}
    />
  );
};

export default DeleteModal;
