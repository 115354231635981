import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import CreateTagModal from "./modals/CreateTagModal";
import {getTagsInBranches } from "../services/branchesTagsService";
import STable from "./TagsTable";
import TagsTable from "./TagsTable";

export default function Tags (){
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { branchId } = useParams();

  const [showCreateTagModal, setShowCreateTagModal] = useState(null);

  useEffect(() => {
    dispatch(getTagsInBranches(branchId));
  }, [dispatch, branchId]);

  const branchesTags = useSelector((state) => state.branchesTags);

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.branches.tags")}
        </Text>
        <GButton
          title={t("pages.branches.add_tags")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowCreateTagModal({ branchId })}
        />
      </Flex>
      {branchesTags.isLoading ? (
        <Spinner />
      ) : branchesTags.data?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : (
        <TagsTable branchesTagsData={branchesTags.data} />
      )}

      {showCreateTagModal && (
        <CreateTagModal
          data={showCreateTagModal}
          onClose={() => setShowCreateTagModal(null)}
        />
      )}
    </Box>
  );
};
