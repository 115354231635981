import axios from "axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");

/* -------------------  Get Classification ---------------------*/
export const getClassification = createAsyncThunk(
  "vacation/getClassification",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupEmployeeClassification`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

/* -------------------  Get Official Vacation List ---------------------*/
export const getOfficialVacationList = createAsyncThunk(
  "vacation/getOfficialVacationList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/OfficialVacationList?data={"UserId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Add New Vacation ---------------------*/
export const addNewVacation = createAsyncThunk(
  "vacation/addNewVacation",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/AddOfficialVacation?officialvacation=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Add New Vacation ---------------------*/
export const editVacation = createAsyncThunk(
  "vacation/editVacation",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/EditOfficialVacation?officialvacationid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  DeletedVacation Vacation ---------------------*/
export const deletedVacation = createAsyncThunk(
  "vacation/deletedVacation",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/DeleteOfficialVacationById?officialvacationid={"OfficialVacationId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
