import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Input from "components/form/Input";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { getPurchasingFilteredData } from "../services/purchasingServices";

export default function FilterModal({ onClose, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState(data);

  // Filter Data
  const [filterCriteria, setFilterCriteria] = useState({
    ItemName: "",
    SKU: "",
  });

  const handleFilterPurchasing = (e) => {
    e.preventDefault();
    const filteredResult = data?.filter((item) => {
      const { ItemName, SKU } = filterCriteria;

      let filterhName = true;
      let filterSKU = true;

      if (ItemName) {
        filterhName = item?.InventoryItemNameEng?.toLowerCase()?.includes(
          ItemName?.toLowerCase()
        );
      }

      if (SKU) {
        filterSKU = item?.Ref?.toLowerCase()?.includes(SKU?.toLowerCase());
      }
      return filterhName && filterSKU;
    });
    setFilteredData(filteredResult);
    onClose();
    dispatch(getPurchasingFilteredData(filteredResult));
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.reports.business_reports.filter")}
        modalContent={
          <Box p={4}>
            <form onSubmit={handleFilterPurchasing}>
              <Stack spacing={6}>
                <Input
                  label={t("pages.reports.reports_tables.item_name")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.item_name")}
                  name="ItemName"
                  value={filterCriteria.ItemName} // Fixed
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      ItemName: e.target.value,
                    })
                  }
                />

                <Input
                  label={t("pages.reports.reports_tables.reference")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.reports.reports_tables.reference")}
                  name="SKU"
                  value={filterCriteria.SKU} // Fixed
                  onChange={(e) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      SKU: e.target.value,
                    })
                  }
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
