import { createSlice } from "@reduxjs/toolkit";
import { addSupplierReturnItems, deletingReturnSupllier, editQuantityReturnedItemList, getDropDownReturnItems, getReturnItemsToEditQuantity, getReturnSuppliserDetails, getReturnedItemList, submitReturnItems } from "../services/returnedSuppliersServices";

let initialState = {
    isLoading: false,
    data: [],
    returnedItemsList : [],
    itemsToEdit : [],
    dropDownReturnedItems : [],
    pages: 0,
    errors: null,
  };


  const ReturnSupplier = createSlice({
    name: "returnSupplier",
    initialState,

    extraReducers: (builder) => {
  /*  ------------------------------------------------- Get Return Suppliser Details------------------------------------------------ */
          builder.addCase(getReturnSuppliserDetails.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getReturnSuppliserDetails.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
      
          builder.addCase(getReturnSuppliserDetails.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });
/*  ------------------------------------------------- Delete Return Suppliser ------------------------------------------------ */
          builder.addCase(deletingReturnSupllier.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(deletingReturnSupllier.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(deletingReturnSupllier.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });
  /*  ------------------------------------------------- get Returned Item List------------------------------------------------ */
          builder.addCase(getReturnedItemList.pending, (state) => {
            // state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getReturnedItemList.fulfilled, (state, { payload }) => {
            // state.isLoading = false;
            state.returnedItemsList = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
      
          builder.addCase(getReturnedItemList.rejected, (state) => {
            // state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });
/*  ------------------------------------------------- edit Quantity Returned Item List ------------------------------------------------ */
          builder.addCase(editQuantityReturnedItemList.pending, (state) => {
            // state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(editQuantityReturnedItemList.fulfilled, (state, { payload }) => {
            // state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(editQuantityReturnedItemList.rejected, (state) => {
            // state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });
  /*  ------------------------------------------------- get Return Items To Edit Quantity ------------------------------------------------ */
          builder.addCase(getReturnItemsToEditQuantity.pending, (state) => {
            // state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getReturnItemsToEditQuantity.fulfilled, (state, { payload }) => {
            // state.isLoading = false;
            state.itemsToEdit = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
      
          builder.addCase(getReturnItemsToEditQuantity.rejected, (state) => {
            // state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });
  /*  -------------------------------------------------add Supplier Return Items------------------------------------------------ */
          builder.addCase(addSupplierReturnItems.pending, (state) => {
            // state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(addSupplierReturnItems.fulfilled, (state, { payload }) => {
            // state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(addSupplierReturnItems.rejected, (state) => {
            // state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });
  /*  -------------------------------------------------DropDown Return Items------------------------------------------------ */
          builder.addCase(getDropDownReturnItems.pending, (state) => {
            // state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getDropDownReturnItems.fulfilled, (state, { payload }) => {
            // state.isLoading = false;
            state.dropDownReturnedItems = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
      
          builder.addCase(getDropDownReturnItems.rejected, (state) => {
            // state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });
  /*  ------------------------------------------------- Submit Return Items------------------------------------------------ */
          builder.addCase(submitReturnItems.pending, (state) => {
            // state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(submitReturnItems.fulfilled, (state, { payload }) => {
            // state.isLoading = false;
            state.errors = null;
          })
      
          builder.addCase(submitReturnItems.rejected, (state) => {
            // state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });


    }
  })


  export default ReturnSupplier.reducer;