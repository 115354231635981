import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createTimedEventCategories,
  getTagsInCategoriesTags,
  getTimedEventCategories,
} from "../../services/timedEventsCategoriesService";

const AppliesOnCategoriesModal = ({ onClose }) => {
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { handleSubmit, setValue } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  useEffect(() => {
    dispatch(getTagsInCategoriesTags(id.id));
  }, [dispatch, id.id]);

  const categories = useSelector((state) => state.timedEventsCategories);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.timedEvents.edit_categories")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.Categories = values.Categories?.map((el) => ({
                CategoryId: el.ProductCategoriesId,
              }));
              values.TimedEventId = id.id;
              values.UserId = localStorage.getItem("userId");
              if (
                values.Categories === undefined ||
                values.Categories?.length === 0
              ) {
                toast2({
                  description: t("validation.required"),
                });
              } else {
                dispatch(createTimedEventCategories(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.timedEvents.cateogry_timed_event_created"
                      ),
                    });
                    dispatch(getTimedEventCategories(id.id));
                    onClose();
                  });
              }
            })}
          >
            <Stack>
              <MultiSelect
                title={t("pages.categories.categories")}
                data={
                  Array.isArray(categories.tagsInCategory)
                    ? categories.tagsInCategory.map((el) => ({
                        ...el,
                        label: el.ProductCategoriesNameEng,
                        value: el.ProductCategoriesId,
                      }))
                    : []
                }
                isMulti={true}
                isRequired={true}
                onChange={(e) => setValue("Categories", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.inventory_item_details.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={categories.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={categories.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AppliesOnCategoriesModal;
