import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "./axios";

export const getOrderType = createAsyncThunk(
  "casherApp/getOrderType",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        "/Manage/Setting/CallCenter/LookUpOrderTypes"
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getRoundingMethod = createAsyncThunk(
  "casherApp/getRoundingMethod",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        "/Manage/CasherAppSetting/LookUpRoundingMethod"
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getKitchenSortingMethod = createAsyncThunk(
  "casherApp/getKitchenSortingMethod",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        "/Manage/CasherAppSetting/LookUpKitchenSortingMethod"
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createCaherApp = createAsyncThunk(
  "casherApp/createCaherApp",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `/Manage/CasherAppSetting/AddCashierAppSettings?cashersetting=${JSON.stringify(
          args
        )}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
