import { createSlice } from "@reduxjs/toolkit";
import {
  addNewVacation,
  deletedVacation,
  editVacation,
  getClassification,
  getOfficialVacationList,
} from "../services/vacationServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  vacationList: [],
  classifications: [],

  // Filter data
  filteredData: [],
};

const vacationSlice = createSlice({
  name: "vacation",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get Classifications dropDown -------------------------------------------- */
    builder.addCase(getClassification.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getClassification.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.classifications =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getClassification.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------------------  Get Official Vacation List -------------------------------------------*/
    builder.addCase(getOfficialVacationList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getOfficialVacationList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.vacationList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getOfficialVacationList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------------------  Add New Vacation  -------------------------------------------*/
    builder.addCase(addNewVacation.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(addNewVacation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(addNewVacation.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------------------  Edit Vacation  -------------------------------------------*/
    builder.addCase(editVacation.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editVacation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editVacation.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------------------  Deleted Vacation  -------------------------------------------*/
    builder.addCase(deletedVacation.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deletedVacation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deletedVacation.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default vacationSlice.reducer;
