import React, { useEffect, useState } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Stack,
  useToast,
  Input as ChInput,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  EditQuantityAdjustmenItems,
  getQuantityAdjustmentItems,
  getQuantityAdjustmentItemsToEdit,
} from "../../services/quantityAdjustmentService";

export default function EditQuantityAdjasmentModal({ onClose, id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const { handleSubmit } = useForm();

  const data = useSelector((state) => state.QuantityAdjustment);
  const quantityAdjustmentItemsToEdit = data?.quantityAdjustmentItemsToEdit;

  const [itemData, setItemData] = useState({});

  useEffect(() => {
    dispatch(getQuantityAdjustmentItemsToEdit(id));
  }, [dispatch]);

  useEffect(() => {
    const initialItemData = {};
    quantityAdjustmentItemsToEdit?.forEach((el) => {
      initialItemData[el.QuantityAdjustmentItemId] = {
        QuantityAdjustmentId: el.QuantityAdjustmentId,
        StorageUnitQuantity: el.StorageUnitQuantity,
        IngredientUnitQuantity: el.IngredientUnitQuantity,
      };
    });

    setItemData(initialItemData);
  }, [quantityAdjustmentItemsToEdit]);

  const handleQuantityChange = (newQuantity, itemId) => {
    setItemData((prevData) => {
      return {
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          StorageUnitQuantity: newQuantity,
        },
      };
    });
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.quantity_adjustment.edit_quantity")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                const editedItems = Object.keys(itemData).map((itemId) => ({
                  QuantityAdjustmentItemId: parseInt(itemId, 10),
                  StorageUnitQuantity: itemData[itemId].StorageUnitQuantity,
                }));
                values.QuantityAdjustmentItem = editedItems;
                values.UserId = localStorage.getItem("userId");
                dispatch(EditQuantityAdjustmenItems(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.quantity_adjustment.quantity_adjustment_item_updated"
                      ),
                    });
                    dispatch(getQuantityAdjustmentItems(id));
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                {quantityAdjustmentItemsToEdit?.map((el) => (
                  <Box>
                    <Text
                      marginBottom={"7px"}
                      fontSize={"1rem"}
                      fontWeight={"500"}
                      textTransform={"capitalize"}
                      textAlign={"start"}
                      marginInlineEnd={"0.75rem"}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {el.InventoryItemNameEng}
                    </Text>
                    <ChInput
                      defaultValue={
                        itemData[el.QuantityAdjustmentItemId]
                          ?.StorageUnitQuantity
                      }
                      name="StorageUnitQuantity"
                      onChange={(e) =>
                        handleQuantityChange(
                          e.target.value,
                          el.QuantityAdjustmentItemId
                        )
                      }
                    />
                  </Box>
                ))}

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
