import React from 'react';
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from 'react-redux';
import {deleteCombo} from "../../services/combosService"
import { useToast } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import routes from "navigations/routes";

const DeleteComboModal = ({onClose}) => {

    const id = useParams()
    // console.log(id)

    const dispatch=useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { t } = useTranslation();
  const navigate = useNavigate();


//   dispatch(deleteCombo(id.comboId)).unwrap().then(_ => {
//     toast({ description: t("pages.inventory.delete_item") });
//     onClose();
//     navigate(`/${routes.inventory_items}`);
//   })
// }}


  return (
    <GDeleteModal
    onClose={onClose}
    onSubmit={()=>{
     dispatch(deleteCombo(id.comboId))
     .unwrap().then(_ => {
             toast({ description: t("pages.inventory.delete_item") });
             onClose();
             navigate(`/${routes.products_combos}`);
           })
    }}
/>
  );
}

export default DeleteComboModal;
