import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

    /* ---------------------------------------------------- Get Order Transactions  ------------------------------------------------- */
    export const getOrderTransactions = createAsyncThunk("OrderTransactions/getOrderTransactions",async (args, thunkApi) => {
        try {
        const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/SecInventory/OrderTransactions/OrderTransList`);
        return thunkApi.fulfillWithValue(data);
        } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
        }
    }
);


  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getOrderTransactionsFilteredData = createAsyncThunk("OrderTransactions/getOrderTransactionsFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetOrderTransactionsFilteredData = createAsyncThunk( "OrderTransactions/resetOrderTransactionsFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);