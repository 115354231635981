import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { addModifierToGroup, getModifierToTaxesGroup } from "../../services/modifiersToGroupService";

const AddModifiersOptionToGroupModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top" , duration: 3000, status: "success",});
  const toast2 = useToast({ position: "top" , duration: 3000, status: "error",});
  const { handleSubmit, setValue } = useForm();

  const taxesGroupsModifiers = useSelector((state) => state.modifierGroups);
  console.log(taxesGroupsModifiers);

  useEffect(() => {
    dispatch(getModifierToTaxesGroup());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.taxes.add_to_modifiers")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.TaxGroupId = data.TaxGroupId;
              if(values.ModiferOptionId === undefined || values.ModiferOptionId.length === 0){
                toast2({ description: t("validation.choose_first") });
              }
              else {
                values.ModiferOptionId = values.ModiferOptionId?.map((el) => ({ModiferOptionId: el.ModiferOptionId,}));
                dispatch(addModifierToGroup(values))
                  .unwrap()
                  .then((_) => {
                    toast({description: t( "pages.taxes.modifiers_option_added_to_tax_group" ),
                    });
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                data={
                  taxesGroupsModifiers.data &&
                  taxesGroupsModifiers.data.map((el) => ({
                    ...el,
                    label: el.ModiferOptionNameEng,
                    value: el.ModiferOptionId,
                  }))
                }
                isRequired
                isMulti={true}
                title={t("pages.taxes.modifiers_options")}
                onChange={(e) => setValue("ModiferOptionId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("pages.taxes.apply")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={data.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={data.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddModifiersOptionToGroupModal;
