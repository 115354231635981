export function removeDuplicates(arr) {
    const uniqueBranchNames = {};
    const result = [];

    arr.forEach(obj => {
        if (!uniqueBranchNames[obj.BranchName]) {
            uniqueBranchNames[obj.BranchName] = true;
            result.push(obj);
        }
    });

    return result;
}

export const filterData = (data, filterString) => {
    if (filterString === "All") {
      return data;
    } else {
      return data.filter(item => item.BranchName === filterString);
    }
  };