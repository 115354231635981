import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

/* ----------------------------------------------------------- Get Branches DropDown List  -------------------------------------------------------*/
export const getBranchesDropDown = createAsyncThunk("transfers/getBranchesDropDown",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Manage/Setting/CallCenter/LookUpBranches`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------- Get Transfers List  -------------------------------------------------------*/
export const getTransfersList = createAsyncThunk("transfers/getTransfersList",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/GetTransfersList`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Crate New Transfers  -------------------------------------------------------*/
export const createTransfers = createAsyncThunk("transfers/createTransfers",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/AddTransfers?transfers=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Update Transfers  -------------------------------------------------------*/
export const updateTransfers = createAsyncThunk("transfers/updateTransfers",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/UpdateTransfersById?transfersid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------- Get Items DropDown List  -------------------------------------------------------*/
export const getItemDropdown = createAsyncThunk("transfers/getItemDropdown",async (args, thunkApi) => {
  try {
    const { data } = await axios.get( `https://rostoapi.smartgate-egypt.com/Inventory/Production/LookupItemsProduction`);

    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* ----------------------------------------------------------- Add Items in Transfers  -------------------------------------------------------*/
export const addItemsToTransfers = createAsyncThunk("transfers/addItemsToTransfers",async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/SecInventory/AddTransfersItems?transfers=${JSON.stringify(args)}`);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* ----------------------------------------------------------- Get Transfer  Items -------------------------------------------------------*/
export const getTransferItems = createAsyncThunk("transfers/getTransferItems",async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/SecInventory/TransfersItemsListById?transfersid=${JSON.stringify(args)}`);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
/* ----------------------------------------------------------------- Send Transfers  -------------------------------------------------------*/
export const sendTransfers = createAsyncThunk("transfers/sendTransfers",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/SecInventory/SendTransfers?transfersid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ----------------------------------------------------------------- Recicve / Rejected Transfers  -------------------------------------------------------*/
export const rejectedRecieveTransfers = createAsyncThunk("transfers/rejectedRecieveTransfers",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/SecInventory/RecRejTransfers?transfersid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ----------------------------------------------------------------- Recicve / Rejected Transfers  -------------------------------------------------------*/
export const editTransfersQuantityItems = createAsyncThunk("transfers/editTransfersQuantityItems",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/EditTransfersItems?transfersid=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ----------------------------------------------------------------- Restore Inventory Categories  -------------------------------------------------------*/
export const deleteInventoryTransfers = createAsyncThunk("transfers/deleteInventoryTransfers",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Inventory/DeleteTransfersById?transfersid={"TransfersId":${args}}`);
      
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getTransfersFilteredData = createAsyncThunk("transfers/getTransfersFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetTransfersFilteredData = createAsyncThunk( "transfers/resetTransfersFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);