import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {  Button, Flex, Text , Box} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import ExportModal from "components/ui/modal/ExportModal";
import { Spinner } from '@chakra-ui/react';
import NewMultiSelect from "components/ui/NewMultiSelect/NewMultiSelect";
import { GetBranchSalesReport, GetCashierSalesReport, GetCategorySalesReport, GetComboSalesReport } from "./services/salesReportsServices";
import BranchSalesReport from "./components/BranchSales/BranchSalesReport";
import CashierSalesReport from "./components/CashierSales/CashierSalesReport";
import CategorySalesReport from "./components/CategorySales/CategorySalesReport";
import ComboSalesReport from "./components/ComboSales/ComboSalesReport";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

export default function SalesReport() {
  const retrievedArray =useDecryptedPrivilege()
  const Auth = checkIdExists(retrievedArray, 68);
  const dispatch = useDispatch();
  const [t]=useTranslation();

  const categories = [
    {label : "branch" , value : 1},
    {label : "cashier" , value : 2},
    {label : "category" , value : 3},
    {label : "combo" , value : 4}
  ];
  const [salesTable, setSalesTable] = useState(1);

  const [exportModal, SetExportModal] = useState(false);
  const salesReports = useSelector((state) => state.salesReport);

  useEffect(() => {
    dispatch(GetBranchSalesReport());
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(GetCashierSalesReport());
  }, [dispatch]);
  useEffect(() => {
    dispatch(GetCategorySalesReport());
  }, [dispatch]);
  useEffect(() => {
    dispatch(GetComboSalesReport());
  }, [dispatch]);

  if(Auth===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return  <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
        >
          <Flex width={"100%"} alignItems={"center"} gap={2}>
              <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
                {t("pages.reports.sales_reports.sales_report")}
              </Text>

              <NewMultiSelect
              data={ categories &&
                  categories.map((el) => ({
                        ...el,
                        label: t(`pages.reports.reports_tables.${el.label}`),
                        value:  el.value ,
                      }))
                    }
                    onSelectionChange={(e) => {
                        setSalesTable(e?.value)
                    }}
                defaultValue={{ label: "Branch", value: 1 }}
              />

          </Flex>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
              <Text>{t("general.export")}</Text>
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>

      <>
      { salesReports?.isLoading ? 
        <Flex
            p={4}
            bg="white"
            alignItems="center"
            justifyContent="center"
        >
            <Spinner /> 
        </Flex>
        :
        <Box 
            bg="light"
            overflow="auto"
            borderRadius={4}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          {
            salesTable === 1 ?
            <BranchSalesReport  data={salesReports?.branchSalesData} filteredData={salesReports?.branchFilteredData} />
            :
            salesTable === 2 ?
            <CashierSalesReport  data={salesReports?.cashierSalesData} filteredData={salesReports?.cahshierFilteredData} />
            :
            salesTable === 3 ?
            <CategorySalesReport  data={salesReports?.categorySalesData} filteredData={salesReports?.categoryFilteredData} />
            : 
            <ComboSalesReport  data={salesReports?.comboSalesData} filteredData={salesReports?.comboFilteredData} />
          }
        </Box>
      }
      </>

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={
            salesTable === 1 ?    salesReports?.branchSalesData
            :  salesTable === 2 ? salesReports?.cashierSalesData
            :  salesTable === 3 ? salesReports?.categorySalesData
            :salesReports?.comboSalesData
          }
          fileName={
                      salesTable === 1 ?    "Branch Sales"
                      :  salesTable === 2 ? "Cashier Sales"
                      :  salesTable === 3 ? "Category Sales"
                      :                     "Combo Sales"
                    }
        ></ExportModal>
      )}
    </>
};
