import { createSlice } from "@reduxjs/toolkit";
import {
  getHrDepartmentDropdown,
  getHrLeaveReports,
  getHrVacationTypeDropdown,
} from "../services/leaveReportsServices";
let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  leaveReportData: [],
  departmentDropDown: [],
  vacationTypeDropDown: [],

  // Filter data
  filteredData: [],
};

const leaveReportsSlice = createSlice({
  name: "leaveReports",
  initialState,
  extraReducers: (builder) => {
    /* -------------------  Get Department Dropdown ---------------------*/
    builder.addCase(getHrDepartmentDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getHrDepartmentDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.departmentDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getHrDepartmentDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Hr Vacation Type Dropdown ---------------------*/
    builder.addCase(getHrVacationTypeDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getHrVacationTypeDropdown.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.vacationTypeDropDown =
          (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      }
    );
    builder.addCase(
      getHrVacationTypeDropdown.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      }
    );
    /* -------------------  Get Hr Leave Reports---------------------*/
    builder.addCase(getHrLeaveReports.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getHrLeaveReports.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.leaveReportData =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getHrLeaveReports.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default leaveReportsSlice.reducer;
