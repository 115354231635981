import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";


export const getCahers = createAsyncThunk(
    "casherOnlineOrder/getCahers",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get("/CallCenter/LookupDeviceCashier");
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );