import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { Box, Flex, Stack, useToast } from "@chakra-ui/react"

import GModal from "components/ui/modal/Modal"
import GButton from "components/ui/button/Button"
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import { createAddress, getCustomerAddresses } from "../../services/addressesService"
import { getDeliveryZones } from "features/management/deliveryZones/services/deliveryZonesService"
import Input from "components/form/Input"

export default function CreateAddressModal ({id,onClose}){

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit,setValue, control} = useForm();

  const addresses = useSelector(state => state.customersAddresses);
  const deliveryZones = useSelector(state => state.deliveryZones);

  useEffect(() => {
    dispatch(getDeliveryZones());
  }, [dispatch]);

  return <>

      <GModal
      onClose={onClose}
      modalHeader={t("pages.customers.create_address")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            if( values.DeliveryZoneId === undefined ||  values.Address_description === undefined ){
              toast2({ description: t("general.validate") });
            }
            else {
            values.CustomerId = id;
            values.DeliveryZoneId = values.DeliveryZoneId?.DeliveryZoneId;
            values.CustomerAddressId = null;
            values.Address_description = values.Address_description ;
            dispatch(createAddress(values))
              .unwrap()
              .then(_ => {
                toast({ description: t("pages.customers.address_created") });
                dispatch(getCustomerAddresses(id));
                onClose();
              });
            }
          })}>
            <Stack spacing={6}>
              <Input
                label={t("pages.customers.address")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.customers.address")}
                name="Address_name"
                isFormController
                control={control}
                onChange={(e) => setValue("Address_name", e)}
              />

              <Input
                label={t("pages.customers.description")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.customers.description")}
                name="Address_description"
                isFormController
                control={control}
                onChange={(e) => setValue("Address_description", e)}
              />

              <MultiSelect
                title={t("pages.customers.delivery_zone")}
                isMulti={false}
                data={deliveryZones.data && deliveryZones.data.map(el => ({
                  ...el,
                  label: el.DeliveryZoneName,
                  value: el.DeliveryZoneId
                }))}
                onChange={(e) => setValue("DeliveryZoneId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={addresses.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={addresses.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  
  </>
}

