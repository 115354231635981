import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";
  /* --------------------------------------------------  Groups DropDown List  ---------------------------------------------------------------*/
export const getGroups = createAsyncThunk( "groups/getGroups", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Menu/Group/MenuGroupList");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* --------------------------------------------------  Get Groups List  ---------------------------------------------------------------*/
  export const getGroupById = createAsyncThunk("groups/getGroupById", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Group/MenuGroupListById?groupid=${JSON.stringify({ "MenuGroupId": args.groupId })}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* --------------------------------------------------  Add New Groups  ---------------------------------------------------------------*/
  export const createGroup = createAsyncThunk("groups/createGroup",async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Group/AddMenuGroup?menugroup=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* --------------------------------------------------  Update Groups  ---------------------------------------------------------------*/
export const updateGroup = createAsyncThunk("groups/updateGroup", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Menu/Group/EditMenuGroup?menugroup=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* --------------------------------------------------  Delete Groups  ---------------------------------------------------------------*/
export const deleteGroup = createAsyncThunk("groups/deleteGroup", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/groups/${args.id}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /* --------------------------------------------------  Filter Groups  ---------------------------------------------------------------*/
export const getFilteredData = createAsyncThunk( "groups/getFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const resetFilteredData = createAsyncThunk("groups/resetFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

