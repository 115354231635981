import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import { Button, Flex, Text, Select, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react"
import Pagination from "components/ui/pagination/Pagination";
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import NoPromotions from "../components/NoPromotions";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import ImportModal from "../components/modals/ImportModal";
import ExportModal from "../components/modals/ExportModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { getPromotions } from "../services/promotionsService";
import { useNavigate } from "react-router-dom";

const Promotions = () => {
  const dispatch = useDispatch();
  const promotions = useSelector(state => state.promotions);
  const { t } = useTranslation();

  

  const [showCreatePromotionModal, setShowCreatePromotionModal] = useState(null);
  const [showUpdatePromotionModal, setShowUpdatePromotionModal] = useState(null);
  const [showDeletePromotionModal, setShowDeletePromotionModal] = useState(null);
  const [showImportPromotionsModal, setShowImportPromotionsModal] = useState(null);
  const [showExportPromotionsModal, setShowExportPromotionsModal] = useState(null);
  const [showFilterPromotionsModal, setShowFilterPromotionsModal] = useState(null);

  const navigate = useNavigate()

  const navigateCreate=()=>{
    navigate(`create`);
  }


  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.promotions.promotions")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Menu>
              <MenuButton as={Button}
                bg="none"
                border="1px solid"
                borderColor="border"
                color="text"
                _hover={{ bg: "primary", borderColor: "primary", color: "light" }}
              >
                <Flex alignItems="center" gap={2}>
                  <FiIcons.FiCloud size={16} />
                  <Text>{t("general.import_export")}</Text>
                  <FiIcons.FiChevronDown size={16} />
                </Flex>
              </MenuButton>
              <MenuList p={0} minW="auto">
                <MenuItem onClick={() => setShowImportPromotionsModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiUpload />
                    <Text textTransform="capitalize">{t("general.import")}</Text>
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => setShowExportPromotionsModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiDownload />
                    <Text textTransform="capitalize">{t("general.export")}</Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>

            <GButton
              title={t("pages.promotions.create_promotion")}
              onClick={navigateCreate}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
      >
        <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom="2px solid"
            borderColor="primary"
          />
        </Flex>

        <GButton
          title={t("general.filter")}
          border="1px solid"
          borderColor="border"
          postfixElement={<FiIcons.FiFilter size={16} />}
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          onClick={() => setShowFilterPromotionsModal(true)}
        />
      </Flex>

      {promotions.data.length === 0 ? (
        <NoPromotions
          onClickCreatePromotionBtn={() => setShowCreatePromotionModal(true)}
        />
      ) : promotions.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <Table
          promotions={promotions.data}
          handleUpdate={(promotion) => setShowUpdatePromotionModal(promotion)}
          handleDelete={(promotion) => setShowDeletePromotionModal(promotion)}
        />
      )}

      {/* <Flex
        alignItems="center" flexWrap="wrap" justifyContent="space-between"
        mt={4} gap={4}
      >
        <Pagination
          page={page}
          size={size}
          itemsCouns={100}
          totalItemsCount={100}
          onChange={(page) => setPage(page)}
        />
        <Select
          w="auto" bg="light" border="none"
          onChange={(e) => setSize(e.target.value)}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </Select>
      </Flex> */}

      {showCreatePromotionModal && (
        <CreateModal
          onClose={() => setShowCreatePromotionModal(null)}
        />
      )}

      {showUpdatePromotionModal && (
        <UpdateModal
          data={showUpdatePromotionModal}
          onClose={() => setShowUpdatePromotionModal(null)}
        />
      )}

      {showDeletePromotionModal && (
        <DeleteModal
          data={showDeletePromotionModal}
          onClose={() => setShowDeletePromotionModal(null)}
        />
      )}

      {showImportPromotionsModal && (
        <ImportModal
          onClose={() => setShowImportPromotionsModal(null)}
        />
      )}

      {showExportPromotionsModal && (
        <ExportModal
          onClose={() => setShowExportPromotionsModal(null)}
        />
      )}

      {showFilterPromotionsModal && (
        <FilterModal
          onClose={() => setShowFilterPromotionsModal(null)}
        />
      )}
    </>
  )
}

export default Promotions