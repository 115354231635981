import { createSlice } from "@reduxjs/toolkit";
import {
  getAddressById,
  createAddress,
  updateAddress,
  deleteAddress,
  getCustomerAddresses
} from "../services/addressesService";

let initialState = {
  isLoading: false,
  customerAddresses: [],
  address: null,
  pages: 0,
  errors: null
};

const addressesSlice = createSlice({
  name: "addresses",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCustomerAddresses.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCustomerAddresses.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.customerAddresses = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getCustomerAddresses.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })



    builder.addCase(getAddressById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getAddressById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.address = (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    })
    builder.addCase(getAddressById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createAddress.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createAddress.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createAddress.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateAddress.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateAddress.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(address => address.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateAddress.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteAddress.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteAddress.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(address => address.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteAddress.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default addressesSlice.reducer;