import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, FormLabel, Stack, Text, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import GButton from "components/ui/button/Button";
import GModal from 'components/ui/modal/Modal';
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import { useDispatch, useSelector } from 'react-redux';
import Input from 'components/form/Input';
import { editQuantityAdjasment, 
        getBranchesForQuantityAdjusment, 
        getQuantityAdjustmentDetails, 
        getReasonForQuantityAdjusment } from '../../services/quantityAdjustmentService';

export default function EditModal({onClose , id}) {
  const dispatch = useDispatch()
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { t } = useTranslation();
  const { handleSubmit, control, getValues, watch, setValue, register } = useForm();

  const QuantityAdjustment = useSelector(state => state.QuantityAdjustment);

  useEffect(() => {
    dispatch(getBranchesForQuantityAdjusment());
    dispatch(getReasonForQuantityAdjusment());
  }, [dispatch])


  return <>

      <GModal
          onClose={onClose}
          modalHeader={t("pages.quantity_adjustment.edit_quantity_djustment")}
          modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {

            if(values.BranchId == undefined || values.ReasonId === undefined){
              toast2({ description: t("validation.choose_first") });
              }else {
                values.BranchId = values.BranchId.BranchId;
                values.ReasonId = values.ReasonId.ReasonId;
                values.Notes = values.Notes;
                values.QuantityAdjustmentId = id;
                dispatch(editQuantityAdjasment(values))
                    .unwrap().then(_ => {
                    toast({ description: t("pages.quantity_adjustment.updated")  });
                    dispatch(getQuantityAdjustmentDetails(id));
                    onClose()
                    });
            }
          })} >

            <Stack spacing={6}>

                <MultiSelect
                  title={t("pages.quantity_adjustment.branch")}
                  data={QuantityAdjustment?.branches?.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))}
                //   name="BranchId"
                  isMulti={false}
                  isRequired={true}

                  onChange={(e) => setValue("BranchId", e)}
                />

                <MultiSelect
                  title={t("pages.quantity_adjustment.reason")}
                  data={QuantityAdjustment?.Reasons?.map((el) => ({
                    ...el,
                    label: el.ReasonNameEng,
                    value: el.ReasonId,
                  }))}
                //   name="ReasonId"
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("ReasonId", e)}
                />

                <Input
                  label={t("pages.quantity_adjustment.notes")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.quantity_adjustment.notes")}
                  name="Notes"
                  isFormController
                  control={control}
                  onChange={(e) => setValue("Notes", e.target.value)}
                />
              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  
  </>
}
