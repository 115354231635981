import React, { useState } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import AddPermissionTypeModal from "./components/Modal/AddPermissionTypeModal";

export default function LeavePermission() {
  const { t } = useTranslation();
  const [showNewModal, setShowNewModal] = useState(false);

  return (
    <Box>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.selfservices.leave_permission")}
          </Text>

          <Button
            type="button"
            bg="primary"
            color="light"
            textTransform="capitalize"
            fontSize="1rem"
            onClick={() => setShowNewModal(true)}
          >
            <Flex alignItems="center" gap={1}>
              {t("pages.hr.selfservices.new_leave_permission")}
            </Flex>
          </Button>
        </Flex>
      </Box>
      {showNewModal && (
        <AddPermissionTypeModal onClose={() => setShowNewModal(false)} />
      )}
    </Box>
  );
}
