import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPurchasingFilteredData } from '../../services/purchaseService';

export default function FilterModal({onClose , data }) {
    const { t } = useTranslation();
    const dispatch =useDispatch();

    const [filteredData, setFilteredData] = useState(data);

    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
      SuppliersName : "",
      Destination : "",
      Status : "",
      });

      const handleFilterPurchasing = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const { SuppliersName , Destination , Status} = filterCriteria;
    
          let filterSuppliersName = true;
          let filterDestination = true;
          let filterStatus = true;
    
          if (SuppliersName) {
            filterSuppliersName = item?.SuppliersName?.toLowerCase().includes(SuppliersName.toLowerCase());
          }
    
          if (Destination) {
            filterDestination = item?.Destination?.toLowerCase().includes(Destination.toLowerCase());
          }

          if (Status) {
            filterStatus = item?.Status?.toLowerCase().includes(Status.toLowerCase());
          }

          return (
            filterSuppliersName &&
            filterDestination &&
            filterStatus 
          );

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getPurchasingFilteredData(filteredResult));
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.purchases.purchasing_filter")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterPurchasing}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.purchases.supplier_name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.purchases.supplier_name")}
                name="SuppliersName"
                value={filterCriteria.SuppliersName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    SuppliersName: e.target.value,
                  })
                }
              />
              <Input
                label={t("pages.purchases.destination")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.purchases.destination")}
                name="Destination"
                value={filterCriteria.Destination}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Destination: e.target.value,
                  })
                }
              />
              <Input
                label={t("pages.purchases.status")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.purchases.status")}
                name="Status"
                value={filterCriteria.Status}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Status: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
