import { createSlice } from "@reduxjs/toolkit";
import {
  getInventoryHistoryList,
  getInventoryHistoryFilteredData,
  resetInventoryHistoryFilteredData,
} from "../services/inventoryHistoryServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData: [],
};

const reportsPurchaseOrderSLice = createSlice({
  name: "reportsInventoryHistory",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get purchaseOrder Reports List ------------------------------------ */
    builder.addCase(getInventoryHistoryList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getInventoryHistoryList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getInventoryHistoryList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- get Purchase Order Filtered Data------------------------------------------------ */
    builder.addCase(getInventoryHistoryFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getInventoryHistoryFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        state.errors = null;
      }
    );
    builder.addCase(getInventoryHistoryFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- reset Purchase Order Filtered Data------------------------------------------------ */
    builder.addCase(resetInventoryHistoryFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      resetInventoryHistoryFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.filteredData = [];
        state.errors = null;
      }
    );
    builder.addCase(resetInventoryHistoryFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default reportsPurchaseOrderSLice.reducer;
