import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { createModifier, getModifiers } from "../../services/modifiersService";
import { generator } from "utils/generator";

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const dispatch = useDispatch();
  const modifiers = useSelector((state) => state.modifiers);
  const { handleSubmit, getValues, setValue, watch, control,    formState: { errors },
  trigger, } = useForm();

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  watch(["ModifersRef"]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.modifiers.create_modifier")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.ModifersId = null;
              values.UserId = localStorage.getItem("userId");

              if (values.ModifersRef === undefined) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                dispatch(createModifier(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.modifiers.modifier_created"),
                    });
                    dispatch(getModifiers());
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
          




              <FormControl isInvalid={errors.ModifersNameEng}>
              <FormLabel
                htmlFor="ModifersNameEng"
                style={{ textTransform: "capitalize" }}
              >
              {t("pages.modifiers.name_localized")}          
              </FormLabel>
              <Controller
                name="ModifersNameEng"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: t("validation.this_name_invalid"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) ||
                      t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.modifiers.name_localized")}
                    onChange={(e) => handleInputChange(e, "ModifersNameEng")}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.ModifersNameEng && errors.ModifersNameEng.message}
              </FormErrorMessage>
            </FormControl>




            <FormControl isInvalid={errors.ModifersNameAr}>
            <FormLabel
              htmlFor="ModifersNameAr"
              style={{ textTransform: "capitalize" }}
            >
            {t("pages.modifiers.name")} 
            </FormLabel>
            <Controller
              name="ModifersNameAr"
              control={control}
              defaultValue=""
              rules={{
                required: t("validation.required"),
                minLength: {
                  value: 2,
                  message: t("validation.name_long_2"),
                },
                maxLength: {
                  value: 25,
                  message: t("validation.name_long_25"),
                },
                pattern: {
                  value: /^[\p{L}\s]+$/u,
                  message: t("validation.this_name_invalid"),
                },
                validate: {
                  noOnlySpaces: (value) =>
                    !isOnlySpaces(value) ||
                    t("validation.no_only_spaces"),
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t("pages.modifiers.name")}
                  onChange={(e) => handleInputChange(e, "ModifersNameAr")}
                />
              )}
            />
            <FormErrorMessage>
              {errors.ModifersNameAr && errors.ModifersNameAr.message}
            </FormErrorMessage>
          </FormControl>



       

              {/* <Input
                label={t("pages.modifiers.ref")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.modifiers.ref")}
                name="ModifersRef"
                isFormController
                isRequired
                control={control}
              /> */}

              <Flex alignItems="flex-end" gap={4}>
                <Input
                  label={t("pages.inventory.sku")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.inventory.sku")}
                  isRequired
                  value={getValues("ModifersRef") || ""}
                  readOnly
                />
                <GButton
                  title={t("pages.inventory.generate_sku")}
                  height="50px"
                  bg="light"
                  border="1px solid"
                  borderColor="border"
                  hover={{
                    bg: "primary",
                    borderColor: "primary",
                    color: "light",
                  }}
                  onClick={() => setValue("ModifersRef", generator({}))}
                />
              </Flex>

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={modifiers.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={modifiers.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default CreateModal;
