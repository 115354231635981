import {
  Box,
  Flex,
  Stack,
  Text,
  useToast,
  Input as ChInput,
} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import GModal from "components/ui/modal/Modal";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { editQty, editQtyDrop, getIngerdiants } from "../../services/ingrediantsService";

const EditQtyModal = ({ onClose, id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({ position: "top",  duration: 3000,  status: "success", });
  const { handleSubmit } = useForm();


  const productionsData = useSelector((state) => state.ingrediants);

  const [itemData, setItemData] = useState({});


  useEffect(() => {
    dispatch(editQtyDrop(id));
  }, [dispatch, id]);


  useEffect(() => {
    const initialItemData = {};

    productionsData.data2.forEach((el) => {
      // Store each element in a separate object
      initialItemData[el.ItemsIngredientId] = {
        ItemsIngredientId: el.ItemsIngredientId,
        NetQty: el.NetQty,
      };
    });

    // Set the state with both the object and the array
    setItemData(initialItemData);
  }, [productionsData.data2]);

  const handleQuantityChange = (newQuantity, itemId) => {
    // Step 3: Update the state with the new data using a unique identifier
    setItemData((prevData) => {
      return {
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          NetQty: newQuantity,
        },
      };
    });
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.inventory.edit_quantities")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              const editedItems = Object.keys(itemData).map((itemId) => ({
                ItemsIngredientId: parseInt(itemId, 10),
                NetQty: itemData[itemId].NetQty,
              }));
              values.ItemsIngredient = editedItems;
              dispatch(editQty(values))
                .unwrap()
                .then((_) => {
                  toast({ description: t("pages.inventory.ingrediant_items_updated")});
                  dispatch(getIngerdiants(id));
                  onClose();
                });
            })}
          >
            <Stack spacing={6}>
              {productionsData.data2.map((el) => (
                <Box>
                  <Text
                    marginBottom={"7px"}
                    fontSize={"1rem"}
                    fontWeight={"500"}
                    textTransform={"capitalize"}
                    textAlign={"start"}
                    marginInlineEnd={"0.75rem"}
                    style={{ marginBottom: "0.5rem" }}
                  >
                    {el.Item}
                  </Text>
                  <ChInput
                    value={itemData[el.ItemsIngredientId]?.NetQty || ""}
                    name="NetQty"
                    type="number"
                    onChange={(e) =>
                      handleQuantityChange(
                        e.target.value,
                        el.ItemsIngredientId
                      )
                    }
                  />

                  {/* <ChInput value={el.Quantity} onChange={(e) => handleQuantityChange(e.target.value)} /> */}
                </Box>
              ))}

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default EditQtyModal;
