import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useState } from "react";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({ branches,filteredData }) => {
  //console.log("all branches", branches);
  console.log(filteredData)
  const { t } = useTranslation();


  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, branches?.length);
    return data?.slice(firstIndex, lastIndex);
  };

// Calculate records for activeDiscounts
const BranchtData = calculateRecords(
  branches,
    currentPageActive,
    recordsPerPage
  );
/* --------------------------------------------- End Pagination  -----------------------------------------------*/




  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.branches.name")}</Th>
            <Th>{t("pages.branches.branch_code")}</Th>
            <Th>{t("pages.branches.tax_group_id")}</Th>
            <Th>{t("pages.categories.created")}</Th>
          </Tr>
        </Thead>
        <Tbody>
       
        {filteredData.length > 0 ? (
          // If filteredData has elements, map over filteredData
          filteredData.map((filteredItem, index) => (
            <Tr key={filteredItem.BranchId}>
              <Td>{index + 1}</Td>
              <Td>
                <Link to={`${filteredItem.BranchId}`}>{filteredItem.BranchName}</Link>
              </Td>
              <Td>{filteredItem.BranchCode}</Td>
              <Td>{filteredItem.TaxGroupName}</Td>
              <Td>{filteredItem.Created}</Td>
            </Tr>
          ))
        ) : (
          // If filteredData is empty, map over branches
          BranchtData.map((branch, index) => (
            <Tr key={branch.BranchId}>
              <Td>{index + 1}</Td>
              <Td>
                <Link to={`${branch.BranchId}`}>{branch.BranchName}</Link>
              </Td>
              <Td>{branch.BranchCode}</Td>
              <Td>{branch.TaxGroupName}</Td>
              <Td>{branch.Created}</Td>
            </Tr>
          ))
        )}
        
        </Tbody>
      </Table>
      <Pagination
      currentPage={currentPageActive}
      setCurrentPage={setCurrentPageActive}
      npage={Math.ceil(branches?.length / recordsPerPage)}
      prePage={() => prePage(currentPageActive, setCurrentPageActive)}
      nextPage={() =>
        nextPage(
          currentPageActive,
          setCurrentPageActive,
          Math.ceil(branches?.length / recordsPerPage)
          )
        }
  />
    </Box>
  );
};

export default CTable;
