import { useState } from "react";
import {Table,Tbody,Td, Th, Thead, Tr,} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";

export default function OrderPaymentsReportTable({ data }) {
  const { t } = useTranslation();
  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const  orderPaymentsData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return (
    <>
      <>
        <Table>
          <Thead>
            <Tr>
              <Th>{t("pages.reports.reports_tables.payment_method")}</Th>
              <Th>{t("pages.reports.reports_tables.amount")}</Th>
              <Th>{t("pages.reports.reports_tables.count")}</Th>
              <Th>{t("pages.reports.reports_tables.return_amount")}</Th>
              <Th>{t("pages.reports.reports_tables.return_count")}</Th>
              <Th>{t("pages.reports.reports_tables.net_amount")}</Th>
              <Th>{t("pages.reports.reports_tables.tips")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            { orderPaymentsData?.map((item, index) => (
              <Tr key={index}>
                <Td>{item?.PaymentMethod}</Td>
                <Td>{item?.Amount}</Td>
                <Td> {item?.Count} </Td>
                <Td> {item?.ReturnAmount} </Td>
                <Td> {item?.ReturnCount} </Td>
                <Td> {item?.NetAmount} </Td>
                <Td> {item?.Tips} </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(data?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(data?.length / recordsPerPage)
            )
          }
        />
      </>
    </>
  );
};
