import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import CreateDeliveryZoneModal from "./modals/CreateDeliveryZoneModal";
import DeliveryZoneTable from "./DeliveryZoneTable";
import { getDeliveryZoneInBranches } from "../services/branchesDeliveryZonesService";

export default function DeliveryZones(){
  const { branchId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const branchesDeliveryZones = useSelector((state) => state.branchesDeliveryZones);

  const [showCreateDeliveryZoneModal, setShowCreateDeliveryZoneModal] =useState(null);

  useEffect(() => {
    dispatch(getDeliveryZoneInBranches(branchId));
  }, [dispatch, branchId]);

  return <>
      <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.branches.delivery_zones")}
        </Text>
        <GButton
          title={t("pages.branches.create_delivery_zone")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowCreateDeliveryZoneModal({ branchId })}
        />
      </Flex>
      {branchesDeliveryZones.isLoading ? (
        <Spinner />
      ) : branchesDeliveryZones.data?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : (
        <DeliveryZoneTable deliveryZones={branchesDeliveryZones.data} />
      )}

      {showCreateDeliveryZoneModal && (
        <CreateDeliveryZoneModal
          data={showCreateDeliveryZoneModal}
          onClose={() => setShowCreateDeliveryZoneModal(null)}
        />
      )}
    </Box>
  </>
};
