import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import GButton from "components/ui/button/Button"
import CreateTagModal from "./modals/CreateTagModal";
import { getCustomersTags } from "../services/customersTagsService";
import STable from "./TagsTable";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


export default function Tags ({id}){
  const retrievedArray =useDecryptedPrivilege()
  const ManageCustomers = checkIdExists(retrievedArray, 6);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customersTags = useSelector(state => state.customersTags);

  const [showCreateTagModal, setShowCreateTagModal] = useState(false);

  useEffect(() => {
    dispatch(getCustomersTags(id))
  }, [dispatch, id])

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(showCreateTagModal===true && ManageCustomers===false){
    toast2({ description:  t("general.authorization") })
    setShowCreateTagModal(false)
  }




  return<>
      <Box>
        <Flex
          alignItems="center" justifyContent="space-between"
          gap={2} flexWrap="wrap" mb={6}
        >
          <Text color="text" textTransform="capitalize">
            {t("pages.customers.tags")}
          </Text>
          <GButton
            title={t("pages.customers.create_tag")}
            bg="light"
            color="text"
            fontSize="14px"
            border="1px solid"
            borderColor="border"
            hover={{ bg: "light" }}
            onClick={() => setShowCreateTagModal(true)}
          />
        </Flex>
      {customersTags.isLoading ? (
        <Spinner />
      ) : customersTags.data?.length === 0 ? (
        <Flex
          p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
        >
          {t("pages.customers.no_data_hint")}
        </Flex>
      ) : (
        <STable
          customersTags={customersTags.data}
        />
      )}

      {showCreateTagModal && (
        <CreateTagModal
          id={id}
          onClose={() => setShowCreateTagModal(false)}
        />
      )}
      </Box>

  </>
}

