import React, { useEffect } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
  Input as ChInput,
  Input,
  Text,
} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { Controller, useForm } from "react-hook-form";
import {
  addNewEmployeeContactInfo,
  getEmployeeList,
} from "features/hr/employees/services/employeeServices";

export default function AddContactCompo({ setshowCompoNumber }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const data = useSelector((state) => state.employeeSlice);

  /*-------------------------------------------- Form Handeling ------------------------------------------------*/
  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
      setError("ParLevel", {
        type: "manual",
        message: t("validation.must_be_within_min_max_range"),
      });
    } else {
      clearErrors("ParLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };
  /*-------------------------------------------- Return ------------------------------------------------*/
  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.UserId = localStorage.getItem("userId");
          values.HiringNewEmployeeRequestId =
            data?.NewEmployeeId?.HiringNewEmployeeRequestId;
          values.Step = 3;
          dispatch(addNewEmployeeContactInfo(values))
            .unwrap()
            .then((res) => {
              toast({
                description: t("pages.hr.new_employee_contact_Added"),
              });
              dispatch(getEmployeeList());
              setshowCompoNumber(4);
            });
        })}
      >
        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Text
            color="dark"
            textTransform="capitalize"
            fontSize="xl"
            fontWeight={"bold"}
          >
            {t("general.phone")}
          </Text>
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <FormControl isInvalid={errors.Mobile}>
              <FormLabel
                htmlFor="Mobile"
                style={{ textTransform: "capitalize" }}
              >
                {t("general.mobile")}
              </FormLabel>
              <Controller
                name="Mobile"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 10,
                    message: t("pages.hr.hr_validation.number_short_10"),
                  },
                  maxLength: {
                    value: 15,
                    message: t("pages.hr.hr_validation.number_long"),
                  },
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("general.mobile")}
                    onChange={(e) => {
                      handleInputChange(e, "Mobile");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.Mobile && errors.Mobile.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.Telephone}>
              <FormLabel
                htmlFor="Telephone"
                style={{ textTransform: "capitalize" }}
              >
                {t("general.telephone")}
              </FormLabel>
              <Controller
                name="Telephone"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 10,
                    message: t("pages.hr.hr_validation.number_short_10"),
                  },
                  maxLength: {
                    value: 15,
                    message: t("pages.hr.hr_validation.number_long"),
                  },
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("general.telephone")}
                    onChange={(e) => {
                      handleInputChange(e, "Telephone");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.Telephone && errors.Telephone.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Box>

        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Text
            color="dark"
            textTransform="capitalize"
            fontSize="xl"
            fontWeight={"bold"}
          >
            {t("general.email")}
          </Text>
          <SimpleGrid
            spacing={6}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <FormControl isInvalid={errors.Email}>
              <FormLabel
                htmlFor="Email"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.customers.email")}
              </FormLabel>
              <Controller
                name="Email"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: t("validation.invalid_email"),
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.customers.email")}
                    onChange={(e) => {
                      handleInputChange(e, "Email");
                    }}
                    type="email"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.Email && errors.Email.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.Address}>
              <FormLabel
                htmlFor="Address"
                style={{ textTransform: "capitalize" }}
              >
                {t("pages.hr.Address")}
              </FormLabel>
              <Controller
                name="Address"
                control={control}
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 10,
                    message: t("validation.address_long_10"),
                  },
                  maxLength: {
                    value: 50,
                    message: t("validation.address_short_50"),
                  },
                  // pattern: {
                  //   value: /^[\p{L}\s]+$/u,
                  //   message: t("validation.this_name_invalid"),
                  // },
                  // validate: {
                  //   noOnlySpaces: (value) =>
                  //     !isOnlySpaces(value) || t("validation.no_only_spaces"),
                  // },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.hr.Address")}
                    onChange={(e) => {
                      handleInputChange(e, "Address");
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.Address && errors.Address.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </Box>

        <Box bg={"white"} paddingY={5} paddingX={5}>
          <Flex gap={2} flexWrap="wrap">
            <GButton
              title={t("pages.profile.save_changes")}
              color="light"
              border="1px solid transparent"
              bg="primary"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              type="submit"
            />
          </Flex>
        </Box>
      </form>
    </>
  );
}
