import { Box, Flex, Stack, useToast } from '@chakra-ui/react';
import GButton from 'components/ui/button/Button';
import GModal from 'components/ui/modal/Modal'
import React from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from "components/form/Input";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AddTableToSection, getSectionTables } from '../../services/branchesService';

const AddTableToSectionModal = ({onClose}) => {
    const { t } = useTranslation();
    const { handleSubmit, control } = useForm({});
    const id = useParams()
    //console.log(id.BranchSectionId)
    const dispatch=useDispatch()
    const toast = useToast({position: "top",duration: 3000,status: "success",});

  return (
    <GModal
    modalHeader={t("pages.branches.update_section")}
    onClose={onClose}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleSubmit((values)=>{
            values.BranchSectionId=id.BranchSectionId
            values.TableId=null
            dispatch(AddTableToSection(values)).then((_) => {
              toast({ description: t("pages.branches.branch_created") });
              dispatch(getSectionTables(id.BranchSectionId))
              onClose();
          });
            //console.log(values)
        })}
        >
          <Stack spacing={6}>
          <Input
            label={t("pages.branches.TableName")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.branches.TableName")}
            name="TableName"
            isFormController
            isRequired
            control={control}
          />

          <Input
            type="number"
            label={t("pages.branches.number_Of_Sets")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t("pages.branches.number_Of_Sets")}
            name="SeatsNo"
            isFormController
            isRequired
            control={control}
          />

          <Flex gap={4}>
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              border="1px solid transparent"
              //isLoading={sections.isLoading}
              hover={{
                color: "primary",
                borderColor: "primary",
                bg: "none",
              }}
            />
            <GButton
              title={t("general.close")}
              onClick={onClose}
              bg="red.600"
              border="1px solid transparent"
              color="light"
              //isLoading={sections.isLoading}
              hover={{
                color: "red.600",
                borderColor: "red.600",
                bg: "none",
              }}
            />
          </Flex>
        </Stack>
          </form>
        </Box>
      }
    />
  )
}

export default AddTableToSectionModal
