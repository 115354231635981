import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- create products Timed Events  -------------------------------------------------------------------*/
export const createTimedEventProductTags = createAsyncThunk(
  "timedEventsProductTags/createTimedEventProductTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/AddTimedEventProductTags?timeeventid=${JSON.stringify(
          args
        )}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Get products Timed Events List  -------------------------------------------------------------------*/
export const getTimedEventsProductTags = createAsyncThunk(
  "timedEventsProductTags/getTimedEventsProductTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetTimedEventProductTags?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete Product Tag In Timed Event  -------------------------------------------------------------------*/
export const deleteProductTagInTimedEvent = createAsyncThunk(
  "timedEventsProductTags/deleteProductTagInTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DeleteTimedEventProductTags?timeeventproducttagid={"TimedEventProductTagId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Get Tags In Product Tags ---------------------------------------------------*/
export const getTagsInProductTags = createAsyncThunk(
  "timedEventsProductTags/getTagsInProductTags",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/LookupTimedEventProductTags?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
