import React from "react";
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Button, Flex, SimpleGrid, Input as ChInput } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import * as FiIcons from "react-icons/fi";
import { BsLock } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { CloseFinancialPeriods } from "../service/AccountAddService";
import NoDataFound from "components/ui/noDataFound/NoDataFound";
import { formatDate } from "utils/FormatDate";

const AccountAddSearch = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const data = useSelector((state) => state.AccountAdd);

  if (data?.isLoading) {
    return (
      <Box
        bg="light"
        marginTop={6}
        padding={5}
        borderTop="1px solid"
        borderColor="border"
        marginInline={-4}
        borderRadius={5}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      </Box>
    );
  }
  if (data?.accountCycle?.length === 0) {
    return <NoDataFound />;
  }

  const CloseFinancial = (id) => {
    const dataSend = {
      FinancialPeriodsId: id,
      UserId: localStorage.getItem("userId"),
      OperatingUserID: 1,
    };
    dispatch(CloseFinancialPeriods(dataSend));
  };
  return (
    <>
      <Box
        bg="light"
        marginTop={6}
        padding={5}
        borderTop="1px solid"
        borderColor="border"
        marginInline={-4}
        borderRadius={5}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        marginBottom={10}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingBottom={3}
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.AccountAdd.results")}
          </Text>
        </Flex>
        <SimpleGrid
          spacing={6}
          columns={{ base: 2, md: 4 }}
          bg="white"
          p={4}
          borderRadius={6}
        >
          {data.accountCycle.map((el) => {
            return (
              <>
                <div style={{ display: "flex" }}>
                  <h1>{t("pages.AccountAdd.dataForm")} : </h1>

                  {formatDate(el.DateFrom)}
                </div>
                <div style={{ display: "flex" }}>
                  <h1>{t("pages.AccountAdd.dataTo")} : </h1>
                  {formatDate(el.DateTo)}
                </div>
                <div>
                  {el.IsClosed ? (
                    <div
                      style={{
                        backgroundColor: "red",
                        padding: "5px 10px ",
                        color: "white",
                        width: "fit-content",
                        borderRadius: "6px",
                      }}
                    >
                      {t("pages.AccountAdd.closed")}
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "green",
                        padding: "7px 15px ",
                        color: "white",
                        width: "fit-content",
                        borderRadius: "6px",
                      }}
                    >
                      {t("pages.AccountAdd.open")}
                    </div>
                  )}
                </div>
                <div>
                  {el.IsClosed ? null : (
                    <Menu>
                      <MenuButton
                        as={Button}
                        size="sm"
                        bg="none"
                        border="1px solid"
                        borderColor="border"
                        color="text"
                        _hover={{
                          bg: "primary",
                          borderColor: "primary",
                          color: "light",
                        }}
                      >
                        <Flex alignItems="center" gap={2}>
                          <FiIcons.FiMoreHorizontal size={18} />
                        </Flex>
                      </MenuButton>
                      <MenuList p={0} minW="auto">
                        <MenuItem
                          onClick={() => CloseFinancial(el.FinancialPeriodsId)}
                        >
                          <Flex alignItems="center" gap={2}>
                            <BsLock size={18} style={{ color: "red" }}></BsLock>
                            {t("pages.AccountAdd.close")}
                          </Flex>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </div>
              </>
            );
          })}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default AccountAddSearch;
