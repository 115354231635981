import { createSlice } from "@reduxjs/toolkit";
import {
  getDiscountsBranches,
  createDiscountBranches,
  allBranchesInDiscount,
  deleteBranchInDiscount,
  getTagsInBranches,
} from "../services/discountsBranchesService";

let initialState = {
  isLoading: false,
  data: [],
  errors: null,
  tagsInBranch: [],
};

const discountsBranchesSlice = createSlice({
  name: "discountsBranches",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get Discounts List  -------------------------------------------------------------------*/
    builder.addCase(getDiscountsBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDiscountsBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getDiscountsBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create Discount  -------------------------------------------------------------------*/
    builder.addCase(createDiscountBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createDiscountBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createDiscountBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- all Branches In Discount  -------------------------------------------------------------------*/
    builder.addCase(allBranchesInDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(allBranchesInDiscount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(allBranchesInDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Branch In Discount  -------------------------------------------------------------------*/
    builder.addCase(deleteBranchInDiscount.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteBranchInDiscount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deleteBranchInDiscount.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------------- Get Tags In Branches ---------------------------------------------------*/
    builder.addCase(getTagsInBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getTagsInBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.tagsInBranch =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getTagsInBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default discountsBranchesSlice.reducer;
