import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


export const getBranchesLookUpOrder = createAsyncThunk(
    "OrdersServices/getBranchesLookUpOrder",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpBranches`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const GetOrderByDate = createAsyncThunk(
    "OrdersServices/GetOrderByDate",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Orders/Orders/FY20?data=${JSON.stringify(args)}`);
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const GetOrderDetails = createAsyncThunk(
    "OrdersServices/GetOrderDetails",
    async (args, thunkApi) => {
      try {
       const { data } = await axios.get(`/Orders/Orders/FY19?data={"OrderId":${args}}`);
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getorderTypesforOrders = createAsyncThunk(
    "OrdersServices/getorderTypesforOrders",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/Setting/CallCenter/LookUpOrderTypes`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const getCustomerPhoneNumberOrders = createAsyncThunk(
    "OrdersServices/getCustomerPhoneNumberOrders",
    async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Customers/LookupCustomerPhone`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );

  export const sendOrder = createAsyncThunk(
    "OrdersServices/sendOrder",
    async (args, thunkApi) => {
      console.log(args)
      try {
       const { data } = await axios.get(`/Orders/Orders/FY18?data=${JSON.stringify(args)}`);
       console.log(data)
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );


  export const payThereceiptService = createAsyncThunk(
    "OrdersServices/payThereceipt",
    async (args, thunkApi) => {
      console.log(args)
      try {
       const { data } = await axios.get(`/Orders/Orders/FY17?data=${JSON.stringify(args)}`);
       console.log(data)
       return thunkApi.fulfillWithValue(data);
        
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );