import { Box, Text } from "@chakra-ui/react"
import React from 'react'

const StaticsCard = ({
  text,
  count
}) => {
  return (
    <Box bg="light" p={4}>
      <Text>
        {text}
      </Text>
      <Text>
        {count}
      </Text>
    </Box>
  )
}

export default StaticsCard