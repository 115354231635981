import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import {
  Button,
  Flex,
  Text,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";

import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import {
  getModifiers,
  getDeleted,
  resetFilteredData,
} from "../services/modifiersService";
import NoData from "components/ui/noData/NoData";
import DeletedModifierTable from "../components/DeletedModifierTable";
import ExportModal from "components/ui/modal/ExportModal";
import { IoCloseOutline } from "react-icons/io5";
import CryptoJS from "crypto-js";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Modifiers = () => {
  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const dispatch = useDispatch();
  const modifiers = useSelector((state) => state.modifiers);
  const { t } = useTranslation();
  const [sortType, setSortType] = useState("all");

  const [showCreateModifierModal, setShowCreateModifierModal] = useState(null);
  const [showUpdateModifierModal, setShowUpdateModifierModal] = useState(null);
  const [showDeleteModifierModal, setShowDeleteModifierModal] = useState(null);
  const [showFilterModifiersModal, setShowFilterModifiersModal] =
    useState(null);
  const [exportModal, SetExportModal] = useState(false);

  useEffect(() => {
    dispatch(getModifiers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDeleted());
  }, [dispatch]);

  const resetFunction = () => {
    dispatch(resetFilteredData());
  };

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  if (ManageMenu === false && ReadMenu === false) {
    return <NotAuthorizedCompo />;
  }

  if (showCreateModifierModal === true && ManageMenu === false) {
    toast2({ description: t("general.authorization") });
    setShowCreateModifierModal(false);
  }

  if (showUpdateModifierModal && ManageMenu === false) {
    toast2({ description: t("general.authorization") });
    setShowUpdateModifierModal(false);
  }

  if (showDeleteModifierModal && ManageMenu === false) {
    toast2({ description: t("general.authorization") });
    setShowDeleteModifierModal(false);
  }

  const deletedObjects = modifiers.data.filter((obj) => obj.IsDeleted === true);

  const notDeletedObjects = modifiers.data.filter(
    (obj) => obj.IsDeleted === false
  );

  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.modifiers.modifiers")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            {/* <Menu>
              <MenuButton
                as={Button}
                bg="none"
                border="1px solid"
                borderColor="border"
                color="text"
                _hover={{
                  bg: "primary",
                  borderColor: "primary",
                  color: "light",
                }}
              >
                <Flex alignItems="center" gap={2}>
                  <FiIcons.FiCloud size={16} />
                  <Text>{t("general.import_export")}</Text>
                  <FiIcons.FiChevronDown size={16} />
                </Flex>
              </MenuButton>
              <MenuList p={0} minW="auto">
                <MenuItem onClick={() => setShowImportModifiersModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiUpload />
                    <Text textTransform="capitalize">
                      {t("general.import")}
                    </Text>
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => setShowExportModifiersModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiDownload />
                    <Text textTransform="capitalize">
                      {t("general.export")}
                    </Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu> */}

            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
            >
              <div onClick={() => SetExportModal(true)}>
                {t("general.export")}
              </div>
            </Button>

            <GButton
              title={t("pages.modifiers.create_modifier")}
              onClick={() => setShowCreateModifierModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color={sortType === "all" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderBottom={sortType === "all" ? "2px solid" : "none"}
            borderColor="primary"
            onClick={() => setSortType("all")}
          />

          <GButton
            title={t("general.deleted")}
            bg="none"
            color={sortType === "deleted" ? "primary" : "none"}
            isDisabled={false}
            borderRadius={0}
            borderColor="primary"
            borderBottom={sortType === "deleted" ? "2px solid" : "none"}
            onClick={() => setSortType("deleted")}
          />
        </Flex>

        <GButton
          title={
            modifiers.filteredData.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
            modifiers.filteredData.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          onClick={
            modifiers.filteredData.length === 0
              ? () => setShowFilterModifiersModal(true)
              : resetFunction
          }
        />
      </Flex>

      {modifiers.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : modifiers.data.length === 0 ? (
        <NoData
          onClickCreateBtn={() => setShowCreateModifierModal(true)}
          title={t("pages.modifiers.no_modifier")}
          btnTitle={t("pages.modifiers.create_modifier")}
        />
      ) : sortType === "all" ? (
        <Table
          modifiers={notDeletedObjects}
          handleUpdate={(modifier) => setShowUpdateModifierModal(modifier)}
          handleDelete={(modifier) => setShowDeleteModifierModal(modifier)}
        />
      ) : (
        <DeletedModifierTable data={deletedObjects}></DeletedModifierTable>
      )}

      {showCreateModifierModal && (
        <CreateModal onClose={() => setShowCreateModifierModal(null)} />
      )}

      {showUpdateModifierModal && (
        <UpdateModal
          data={showUpdateModifierModal}
          onClose={() => setShowUpdateModifierModal(null)}
        />
      )}

      {showDeleteModifierModal && (
        <DeleteModal
          data={showDeleteModifierModal}
          onClose={() => setShowDeleteModifierModal(null)}
        />
      )}

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={sortType === "all" ? modifiers.data : modifiers.item}
          fileName={sortType === "all" ? "Modifiers" : "Deleted Modifiers"}
        ></ExportModal>
      )}

      {showFilterModifiersModal && (
        <FilterModal
          data={modifiers.data}
          onClose={() => setShowFilterModifiersModal(null)}
        />
      )}
    </>
  );
};

export default Modifiers;
