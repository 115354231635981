import React from "react";
import { Outlet } from "react-router-dom";
import { Image } from "@chakra-ui/react";
import image from "../../assets/images/hr.jpg";
import HrSideBar from "./HrSideBar/HrSideBar";
import { useSelector } from "react-redux";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "layout/navbar/Navbar";
import HrSectionSideBar from "./HrSideBar/HrSectionSideBar";
const HrSectionLayout = () => {
  const sidebar = useSelector((state) => state.sidebar);
  return (
    <>
      <Flex>
        <HrSectionSideBar />
        <Box
          width={{
            base: "100%",
            lg: `calc(100% - ${sidebar.isActive ? "80px" : "260px"})`,
          }}
          marginStart={{ base: 0, lg: sidebar.isActive ? "80px" : "260px" }}
          transition=".3s"
        >
          <Navbar />
          <Box minHeight="calc(100vh - 70px)" padding={{ base: 3, md: 6 }}>
            <Box>
              <Image
                src={image}
                alt="hr"
                className=""
                rounded={"2xl"}
                border="1px solid"
                borderColor="gray.400"
              />
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default HrSectionLayout;
