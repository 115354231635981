import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- create Timed Event Combos  -------------------------------------------------------------------*/
export const createTimedEventCombos = createAsyncThunk(
  "timedEventsCombos/createTimedEventCombos",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/AddTimedEventCombos?timeeventid=${JSON.stringify(args)}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Get Combos Timed Events List  -------------------------------------------------------------------*/
export const getTimedEventsCombos = createAsyncThunk(
  "timedEventsCombos/getTimedEventsCombos",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetTimedEventCombos?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------------- Delete Combo In Timed Event  -------------------------------------------------------------------*/
export const deleteComboInTimedEvent = createAsyncThunk(
  "timedEventsCombos/deleteComboInTimedEvent",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DeleteTimedEventCombo?timeeventcomboid={"TimedEventComboId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Get Tags In Combo ---------------------------------------------------*/
export const getTagsInCombo = createAsyncThunk(
  "timedEventsCombos/getTagsInCombo",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/LookupTimedEventCombos?timeeventid={"TimedEventId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
