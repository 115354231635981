import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInventoryCategoriesFilteredData } from '../../services/InventoryCategoriesServices';

export default function FilterModal({onClose , data }) {
    const { t } = useTranslation();
    const dispatch =useDispatch();

    const [filteredData, setFilteredData] = useState(data);

    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
      InventoryCategoryName: "" ,
      InventoryCategoryNameLoc: "" 
      });

      const handleFilterCostAdjustment = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const { InventoryCategoryName , InventoryCategoryNameLoc } = filterCriteria;
    
          let filterInventoryCategoryName = true;
          let filterInventoryCategoryNameLoc  = true;
    
          if (InventoryCategoryName) {
            filterInventoryCategoryName = item.InventoryCategoryName?.toLowerCase().includes(InventoryCategoryName.toLowerCase());
          }
          if (InventoryCategoryNameLoc) {
            filterInventoryCategoryNameLoc = item.InventoryCategoryNameLoc?.toLowerCase().includes(InventoryCategoryNameLoc.toLowerCase());
          }

          return (
            filterInventoryCategoryName     &&      
            filterInventoryCategoryNameLoc           
            );

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getInventoryCategoriesFilteredData(filteredResult));
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.inventoryCategories.inventoryCategories_filter")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterCostAdjustment}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.inventoryCategories.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.inventoryCategories.name")}
                name="InventoryCategoryName"
                value={filterCriteria.InventoryCategoryName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    InventoryCategoryName: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.inventoryCategories.name_Localized")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.inventoryCategories.name_Localized")}
                name="InventoryCategoryNameLoc"
                value={filterCriteria.InventoryCategoryNameLoc}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    InventoryCategoryNameLoc: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
