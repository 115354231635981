import React, { useEffect, useState } from "react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, SimpleGrid, Stack, Text, useToast } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProductionItems from "../components/ProductionItems";
import { getProductionDetails, getProductionItems } from "../services/productionService";
import EditProductionModal from "../components/modals/EditProductionModal";
import ProductionDeleteModal from "../components/modals/ProductionDeleteModal";
import ProductionSubmitModal from "../components/modals/ProductionSubmitModal";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

const ProductionDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = useParams();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const supplierDetails = useSelector((state) => state.productions);
  const data = supplierDetails.data1;

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    dispatch(getProductionDetails(id));  
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getProductionItems(id));
  }, [dispatch, id]);

/* -----------------------------------Privilige-------------------------------*/
  const retrievedArray =useDecryptedPrivilege()
  const ManageProduction  = checkIdExists(retrievedArray, 26);

  if(showSubmitModal === true && ManageProduction === false){
    toast2({ description:  t("general.authorization")});
    setShowSubmitModal(false);
  };
  if(showEditModal === true && ManageProduction === false){
    toast2({ description:  t("general.authorization")});
    setShowEditModal(false);
  };
  if(showDeleteModal === true && ManageProduction === false){
    toast2({ description:  t("general.authorization")});
    setShowDeleteModal(false);
  };
/* -----------------------------------Privilige-------------------------------*/

  return (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            {t("layout.sidebar.production")}
            <Box
              bg={"#718096"}
              display={"inline-block"}
              marginX={"5px"}
              borderRadius={"5px"}
              color={"white"}
              fontSize={"15px"}
              padding={" 2px 10px"}
            >
              {data[0]?.Status}
            </Box>
          </Box>
          <Flex gap={2}>
            <GButton
              prefixElement={<FiIcons.FiPrinter size={16} />}
              title={t("pages.transfers_orders.print")}
              bg="light"
              color="text"
              border="1px"
              borderColor={"#CBD5E0"}
              opacity="0.7"
              onClick={handlePrint}
            />
            <>
            {
              data[0]?.Status === "Closed" ? 
              ""
              : 
              <>
                  <GButton
                    prefixElement={<FiIcons.FiTrash2 size={16} />}
                    title={t("pages.transfers_orders.delete_permenantly")}
                    bg="light"
                    color="text"
                    border="1px"
                    borderColor={"#CBD5E0"}
                    opacity="0.7"
                    onClick={() => setShowDeleteModal(true)}
                  />

                  <GButton
                    prefixElement={<FiIcons.FiEdit2 size={16} />}
                    title={t("general.edit")}
                    bg="light"
                    color="text"
                    border="1px"
                    borderColor={"#CBD5E0"}
                    opacity="0.7"
                    onClick={() => setShowEditModal(true)}
                  />

                  <GButton
                    title={t("pages.inventory.produce_items")}
                    bg="#54A0FF"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      bg: "none",
                      borderColor: "#54A0FF",
                      color: "#54A0FF",
                    }}
                    onClick={() => setShowSubmitModal(true)}
                  />
              </>
              }
            </>
          </Flex>
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4}
          borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.inventory.branch")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.BranchName || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.inventory.business_date")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.SuppliersName || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.transfers_orders.creator")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.PrimaryEmail || "-"}
            </Text>
          </Box>

          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text" opacity={"0.7"}>
              {t("pages.inventory.created")}
            </Text>
            <Text marginBlock={2} fontWeight={"bold"}>
              {data[0]?.CreateDate || "-"}
            </Text>
          </Box>
        </SimpleGrid>

        <ProductionItems subGroup={supplierDetails} id={id}/>

        {showEditModal && (
          <EditProductionModal
            onClose={() => setShowEditModal(false)}
            data={data}
            id={id}
          ></EditProductionModal>
        )}

        {showSubmitModal && (
          <ProductionSubmitModal
            onClose={() => setShowSubmitModal(false)}
            id={id}
          ></ProductionSubmitModal>
        )}

        {showDeleteModal && (
          <ProductionDeleteModal
            onClose={() => setShowDeleteModal(false)}
            id={id}
          ></ProductionDeleteModal>
        )}
      </Stack>
    </>
  );
};

export default ProductionDetails;
