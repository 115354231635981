import GModal from "components/ui/modal/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {Box,Flex, Stack,
 useToast,
  Input as ChInput,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { editQuantities, getEditQuantities, getInvCountItems } from "../../services/inventoryCountService";

const EditQuantitiesModal = ({ onClose, id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({position: "top", duration: 3000, status: "success"});
  const toast2 = useToast({position: "top", duration: 3000, status: "error"});
  const { handleSubmit } =useForm();

  const [itemData, setItemData] = useState({});

  const productionsData = useSelector((state) => state.inventoryCount);

  useEffect(() => {
    dispatch(getEditQuantities(id));
  }, [dispatch, id]);

  useEffect(() => {
    const initialItemData = {};
    productionsData.data2.forEach((el) => {
      // Store each element in a separate object
      initialItemData[el.InventoryCountItemId] = {
        InventoryCountItemId: el.InventoryCountItemId,
        EnteredQuantity: el.EnteredQuantity,
      };
    });

    // Set the state with both the object and the array
    setItemData(initialItemData);
  }, [productionsData.data2]);

  const handleQuantityChange = (newQuantity, itemId) => {
    // Step 3: Update the state with the new data using a unique identifier
    setItemData((prevData) => {
      return {
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          EnteredQuantity: newQuantity,
        },
      };
    });
  };

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.transfers_orders.edit_quantities")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
                  const editedItems = Object.keys(itemData).map((itemId) => ({
                    InventoryCountItemId: parseInt(itemId,10),
                    EnteredQuantity: itemData[itemId].EnteredQuantity,
                  }));
                  values.InventoryCountItem = editedItems;
                  dispatch(editQuantities(values))
                  .unwrap()
                  .then((_) => {
                    toast({ description: "Inventory Count updated Successfully" });
                    dispatch(getInvCountItems(id));
                    onClose();
                  });
            })}
          >
            <Stack spacing={6}>
              {productionsData.data2.map((el) => (
                <Box>
                  <Text
                    marginBottom={"7px"}
                    fontSize={"1rem"}
                    fontWeight={"500"}
                    textTransform={"capitalize"}
                    textAlign={"start"}
                    marginInlineEnd={"0.75rem"}
                    style={{ marginBottom: "0.5rem" }}
                  >
                    {el.InventoryItemNameEng}
                  </Text>
                  <ChInput
                    value={itemData[el.InventoryCountItemId]?.EnteredQuantity || ""}
                    name="EnteredQuantity"
                    type="number"
                    required
                    onChange={(e) =>
                      handleQuantityChange(
                        e.target.value,
                        el.InventoryCountItemId
                      )
                    }
                  />
                </Box>
              ))}

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default EditQuantitiesModal;
