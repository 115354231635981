import React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text, Spinner, useToast } from "@chakra-ui/react"
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import CostAdjustmentTable from '../components/CostAdjustmentTable';
import CreateCostAdjustment from '../components/modals/CreateCostAdjustment';
import { getCostAdjustmentList } from '../services/costAdjustment';
import ExportModal from 'components/ui/modal/ExportModal';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

export default function CostAdjustment() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [showCreatCostAdjustmentModal, setShowCreatCostAdjustmentModal] = useState(false);
  const [exportModal,SetExportModal]=useState(false);
  
  const data = useSelector(state => state.costAdjustment);

  useEffect(() => {
    dispatch(getCostAdjustmentList());
  }, [dispatch]);
  

/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageCostAdjustments = checkIdExists(retrievedArray, 29);

if(showCreatCostAdjustmentModal===true && ManageCostAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowCreatCostAdjustmentModal(false);
};
/* -----------------------------------Privilige-------------------------------*/

  return <>
  
  <BreadCrumb>
      <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize" >
            {t("pages.cost_adjustment.cost_adjustment")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
                <Button
                  type="button" bg="none" border="1px solid" borderColor="border"
                  textTransform="capitalize" fontSize="1rem" color="text"
                  _hover={{ bg: "primary", color: "light" }}
                  onClick={() => SetExportModal(true)}
                >
                    <Text>{t("general.export")}</Text>
                </Button>

                <GButton
                  title={t("pages.cost_adjustment.create_new")}
                  onClick={() => setShowCreatCostAdjustmentModal(true)}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{ bg: "none", borderColor: "primary", color: "primary" }}
                />
          </Flex>
      </Flex>
  </BreadCrumb>
  
  {
    data.isLoading ? 
    <Flex bg="light" p={4} justifyContent="center">
      <Spinner />
    </Flex>
    :
    <CostAdjustmentTable 
      data={data.costAdjustmentList} 
      filteredData={data.filteredData} 
    />
  }


  { showCreatCostAdjustmentModal && 
    <>
        <CreateCostAdjustment 
            onClose={()=>setShowCreatCostAdjustmentModal(false)}
        > </CreateCostAdjustment>
    </>
  }
  {exportModal&&(
      <ExportModal 
        onClose={() => SetExportModal(false)}   
        data={data.costAdjustmentList}
        fileName={"costAdjsutment"}
        >
      </ExportModal>
  )}

  </>
}
