import React, { useEffect } from 'react';
import WarehouseTableDetails from '../components/WarehouseTableDetails';
import DeletedWarehouse from '../components/DeletedWarehouse';
import DeleteInventory from '../components/DeleteInventory';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getWarehouseList } from '../services/WarehouseServices';

export default function WarehouseDetails() {
  const dispatch = useDispatch();
  const id = useParams();
  const warehouse = useSelector((state) => state.warehouse);

  const warehouseDetails = warehouse.data?.filter((item)=>{
    return item.WarehouseId == id.WarehouseId 
  })

  useEffect(() => {
    dispatch(getWarehouseList())
  }, [dispatch])
  
  return <>
  
    <WarehouseTableDetails data={warehouseDetails} />
  
    <DeleteInventory />

    <DeletedWarehouse  id={id.WarehouseId } />
  
  </>
}
