import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Button,Flex,Text,Spinner,useToast} from "@chakra-ui/react";
import GButton from "../../../../components/ui/button/Button";
import PurchaseTable from "../components/PurchaseTable";
import CreatePurchaseModal from "../components/modals/CreatePurchaseModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import {getPurchasesList} from "../services/purchaseService";
import NoData from "components/ui/noData/NoData";
import ExportModal from "components/ui/modal/ExportModal";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  

export default function Purchases () {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [exportModal,SetExportModal]=useState(false);
  
  const [showNewPurchaseModal, setShowNewPurchaseModal] =useState(null);
  const purchases = useSelector((state) => state.purchases);

  useEffect(() => {
    dispatch(getPurchasesList());
  }, [dispatch]);


  /* -----------------------------------Privilige-------------------------------*/
  const retrievedArray =useDecryptedPrivilege()
  const ManagePurchasing  = checkIdExists(retrievedArray, 21);

  if(showNewPurchaseModal === true && ManagePurchasing === false){
    toast2({ description:  t("general.authorization")});
    setShowNewPurchaseModal(false);
  };
/* -----------------------------------Privilige-------------------------------*/

  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.purchases.purchasing")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
              <Button
                type="button" bg="none" border="1px solid" borderColor="border"
                textTransform="capitalize" fontSize="1rem" color="text"
                _hover={{ bg: "primary", color: "light" }}
                onClick={() => SetExportModal(true)}
              >
                  <Text>{t("general.export")}</Text>
              </Button>

              <GButton
                title={t("pages.purchases.new_purchase")}
                onClick={() => setShowNewPurchaseModal(true)}
                bg="primary"
                color="light"
                border="1px solid transparent"
                hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              />
          </Flex>
        </Flex>
      </BreadCrumb>

      {purchases.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : purchases?.data?.length === 0 ? (
        <NoData
          btnTitle={t("pages.purchases.new_purchase")}
          title={t("pages.purchases.no_data")}
          onClickCreateBtn={() => setShowNewPurchaseModal(true)}
        />
      ) : (
        <PurchaseTable data={purchases?.data} filteredData={purchases?.filteredData} />
      )}

      {showNewPurchaseModal && (
        <CreatePurchaseModal onClose={() => setShowNewPurchaseModal(null)} />
      )}

      {exportModal&&(
          <ExportModal 
            onClose={() => SetExportModal(false)}   
            data={purchases.data}
            fileName={"purchases"}
            >
          </ExportModal>
      )}
    </>
  );
};

