import { Box, Text } from "@chakra-ui/react"
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";

const NoDevices = ({
  onClickCreateDeviceBtn
}) => {
  const { t } = useTranslation();
  return (
    <Box bg="light" paddingInline={4} paddingBlock={8} textAlign="center">
      <Text textTransform="capitalize" color="text" mb={4} fontSize="1.1rem">
        {t("pages.devices.no_data_hint")}
      </Text>
      <GButton
        onClick={onClickCreateDeviceBtn}
        size="sm"
        title={t("pages.devices.create_device")}
        bg="primary"
        color="light"
        border="1px solid transparent"
        hover={{ bg: "none", color: "primary", borderColor: "primary" }}
      />
    </Box>
  )
}

export default NoDevices