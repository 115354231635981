import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getPromotions = createAsyncThunk(
  "promotions/getPromotions",
  async (args, thunkApi) => {
    try {
      const { size } = args;
      const { data } = await axios.get(`/promotions?_limit=${size}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createPromotion = createAsyncThunk(
  "promotions/createPromotion",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/promotions", args);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getPrmotionsTime = createAsyncThunk(
  "promotions/getPrmotionsTime",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Branches/LookUpDayTime`);
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updatePromotion = createAsyncThunk(
  "promotions/updatePromotion",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(`/promotions/${args.id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deletePromotion = createAsyncThunk(
  "promotions/deletePromotion",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(`/promotions/${args.id}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);