import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
  Input as ChInput,
} from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { generator } from "utils/generator";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import {
  createDiscount,
  getDiscountById,
} from "../../services/discountsService";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function EditModal({ onClose, data }) {
  const id = useParams();
  function transformDataWithDiscountAmount(data) {
    // Clone the original data to avoid modifying it directly
    const newData = { ...data };

    // Check if DiscountAmount exists and is a string
    if (newData && typeof newData.DiscountAmount === "string") {
      // Remove whitespaces and "SAR", then parse to integer
      newData.DiscountAmount = parseInt(
        newData.DiscountAmount.replace(/\s/g, "").replace("SAR", "")
      );
    }

    return newData;
  }
  const transformedData = transformDataWithDiscountAmount(data);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const discounts = useSelector((state) => state.discounts);

  const [discountType, setDiscountType] = useState({});

  const discountsType = {
    discountsTypeDropdown: [
      {
        discountsTypeTypeId: 1,
        discountsTypeTypeName: "Fixed",
      },
      {
        discountsTypeTypeId: 2,
        discountsTypeTypeName: "Percentage",
      },
    ],
  };

  useEffect(() => {
    setDiscountType(
      data?.IsFixed
        ? {
            discountsTypeTypeId: 1,
            discountsTypeTypeName: "Fixed",
          }
        : {
            discountsTypeTypeId: 2,
            discountsTypeTypeName: "Percentage",
          }
    );
  }, [data]);

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    register,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: transformedData,
  });
  watch(["Reference"]);

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const defaultOrderTypes = [
    {
      OrderTypeId: 1,
      OrderTypeName: "Dine in",
    },
    {
      OrderTypeId: 2,
      OrderTypeName: "Pick Up",
    },
    {
      OrderTypeId: 3,
      OrderTypeName: "Delivery",
    },
    {
      OrderTypeId: 4,
      OrderTypeName: "Drive Thru",
    },
  ];

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.discounts.edit_discount")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.OrderType = values.OrderType?.map((el) => ({
                  OrderTypeId: el.OrderTypeId,
                }));
                values.Name = values.DisCountName;
                values.NameLoc = values.DisCountNameLoc;
                values.Ref = values.Reference;
                values.MaxDiscount = values.MaximumDiscount;
                values.MinOrderPrice = values.MinimumOrderPrice;
                values.MinProductPrice = values.MinimumProductPrice;
                values.UserId = localStorage.getItem("userId");
                if (
                  (Array.isArray(values.OrderType) &&
                    values.OrderType.length === 0) ||
                  Object.keys(discountType).length === 0
                ) {
                  toast2({ description: t("validation.required") });
                } else {
                  dispatch(createDiscount(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.discounts.discount_updated"),
                      });
                      dispatch(getDiscountById(id.id));
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.DisCountName}>
                  <FormLabel
                    htmlFor="DisCountName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.discounts.name")}
                  </FormLabel>
                  <Controller
                    name="DisCountName"
                    control={control}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.discounts.name")}
                        onChange={(e) => {
                          handleInputChange(e, "DisCountName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.DisCountName && errors.DisCountName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.DisCountNameLoc}>
                  <FormLabel
                    htmlFor="DisCountNameLoc"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.discounts.name_localized")}
                  </FormLabel>
                  <Controller
                    name="DisCountNameLoc"
                    control={control}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.discounts.name_localized")}
                        onChange={(e) => {
                          handleInputChange(e, "DisCountNameLoc");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.DisCountNameLoc && errors.DisCountNameLoc.message}
                  </FormErrorMessage>
                </FormControl>

                <MultiSelect
                  title={t("pages.discounts.discount_type")}
                  data={
                    discountsType.discountsTypeDropdown &&
                    discountsType.discountsTypeDropdown.map((el) => ({
                      ...el,
                      label: el.discountsTypeTypeName,
                      value: el.discountsTypeTypeId,
                    }))
                  }
                  isMulti={false}
                  isRequired={true}
                  defaultValue={{
                    label: data?.IsFixed ? "Fixed" : "Percentage",
                    value: data?.IsFixed ? 1 : 2,
                  }}
                  onChange={(e) => {
                    let isFixedValue;
                    if (e.discountsTypeTypeName === "Fixed") {
                      isFixedValue = true;
                    } else if (e.discountsTypeTypeName === "Percentage") {
                      isFixedValue = false;
                    }
                    setValue("IsFixed", isFixedValue);
                    setDiscountType(e);
                  }}
                />

                {(data?.QualificationDiscountType[0]
                  ?.QualificationDiscountTypeName === "Orders" ||
                  data?.QualificationDiscountType[0]
                    ?.QualificationDiscountTypeName ===
                    "Orders and Products") && (
                  <FormControl isInvalid={errors.MinimumOrderPrice}>
                    <FormLabel
                      htmlFor="MinimumOrderPrice"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.discounts.minmium_order_price")}
                    </FormLabel>
                    <Controller
                      name="MinimumOrderPrice"
                      control={control}
                      rules={{
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.discounts.minmium_order_price")}
                          onChange={(e) => {
                            handleInputChange(e, "MinimumOrderPrice");
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.MinimumOrderPrice &&
                        errors.MinimumOrderPrice.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                {discountType &&
                  discountType?.discountsTypeTypeName === "Fixed" && (
                    <FormControl isInvalid={errors.DiscountAmount}>
                      <FormLabel
                        htmlFor="DiscountAmount"
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("pages.discounts.discount_amount")}
                      </FormLabel>
                      <Controller
                        name="DiscountAmount"
                        control={control}
                        rules={{
                          required: t("validation.required"),
                          validate: (value) => {
                            const intValue = parseInt(value, 10);
                            return (
                              intValue >= 0 ||
                              t("validation.must_be_non_negative")
                            );
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={t("pages.discounts.discount_amount")}
                            onChange={(e) => {
                              handleInputChange(e, "DiscountAmount");
                            }}
                            type="number"
                            min="0"
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.DiscountAmount && errors.DiscountAmount.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}

                {discountType &&
                  discountType?.discountsTypeTypeName === "Percentage" && (
                    <>
                      <FormControl isInvalid={errors.DiscountAmount}>
                        <FormLabel
                          htmlFor="DiscountAmount"
                          style={{ textTransform: "capitalize" }}
                        >
                          {t("pages.discounts.discount_amount_percentage")}
                        </FormLabel>
                        <Controller
                          name="DiscountAmount"
                          control={control}
                          rules={{
                            required: t("validation.required"),
                            validate: (value) => {
                              const intValue = parseInt(value, 10);
                              return (
                                intValue >= 0 ||
                                t("validation.must_be_non_negative")
                              );
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t(
                                "pages.discounts.discount_amount_percentage"
                              )}
                              onChange={(e) => {
                                handleInputChange(e, "DiscountAmount");
                              }}
                              type="number"
                              min="0"
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors.DiscountAmount &&
                            errors.DiscountAmount.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={errors.MaximumDiscount}>
                        <FormLabel
                          htmlFor="MaximumDiscount"
                          style={{ textTransform: "capitalize" }}
                        >
                          {t("pages.discounts.maximum_discount")}
                        </FormLabel>
                        <Controller
                          name="MaximumDiscount"
                          control={control}
                          rules={{
                            validate: (value) => {
                              const intValue = parseInt(value, 10);
                              return (
                                intValue >= 0 ||
                                t("validation.must_be_non_negative")
                              );
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t(
                                "pages.discounts.maximum_discount"
                              )}
                              onChange={(e) => {
                                handleInputChange(e, "MaximumDiscount");
                              }}
                              type="number"
                              min="0"
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors.MaximumDiscount &&
                            errors.MaximumDiscount.message}
                        </FormErrorMessage>
                      </FormControl>
                    </>
                  )}

                {(data?.QualificationDiscountType[0]
                  ?.QualificationDiscountTypeName === "Products" ||
                  data?.QualificationDiscountType[0]
                    ?.QualificationDiscountTypeName ===
                    "Orders and Products") && (
                  <FormControl isInvalid={errors.MinimumProductPrice}>
                    <FormLabel
                      htmlFor="MinimumProductPrice"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.discounts.minmium_product_price")}
                    </FormLabel>
                    <Controller
                      name="MinimumProductPrice"
                      control={control}
                      rules={{
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t(
                            "pages.discounts.minmium_product_price"
                          )}
                          onChange={(e) => {
                            handleInputChange(e, "MinimumProductPrice");
                          }}
                          type="number"
                          min="0"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.MinimumProductPrice &&
                        errors.MinimumProductPrice.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                <MultiSelect
                  title={t("pages.discounts.applies_on_order_types")}
                  data={
                    Array.isArray(defaultOrderTypes)
                      ? defaultOrderTypes.map((el) => ({
                          ...el,
                          label: el.OrderTypeName,
                          value: el.OrderTypeId,
                        }))
                      : []
                  }
                  isMulti={true}
                  isRequired={true}
                  defaultValue={data?.OrderType.map((order) => ({
                    ...order,
                    label: order.OrderTypeName,
                    value: order.OrderTypeId,
                  }))}
                  onChange={(e) => {
                    console.log("Selected Order Types:", e);
                    setValue("OrderType", e);
                    trigger("OrderType");
                  }}
                />

                <Flex alignItems="flex-end" gap={4}>
                  <Input
                    label={t("pages.discounts.sku")}
                    labelStyle={{ textTransform: "capitalize" }}
                    placeholder={t("pages.discounts.sku")}
                    value={getValues("Reference") || ""}
                    readOnly
                  />
                  <GButton
                    title={t("pages.discounts.generate")}
                    height="50px"
                    bg="light"
                    border="1px solid"
                    borderColor="border"
                    hover={{
                      bg: "primary",
                      borderColor: "primary",
                      color: "light",
                    }}
                    onClick={() => setValue("Reference", generator({}))}
                  />
                </Flex>

                <Checkbox {...register("IsTaxable")}>
                  {t("pages.discounts.IsTaxable")}
                </Checkbox>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    isLoading={discounts.isLoading}
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    isLoading={discounts.isLoading}
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
