import { GetAccountTypeNew, GetFinancialPeriodsList, createAccountTreeNode, getAcoountsTree } from "../service/AccountingTreeService";

const { createSlice } = require("@reduxjs/toolkit");


let initialState = {
    isLoading: false,
    data: [],
    dataById:[],
    Accounts:[],
    tableData:[],
    headerLookUp:[],
    searchData:[],
    accountsLookUp:[],
    accountCycle:[],
    pages: 0,
    errors: null,
    FinancialPeriodList:[],
    accountType:[],
    FinancialPeriodDailyRestriction:[],
    trialBalance:[]
  };

const AccountingTreeSlice = createSlice({

    name: "AccountTreeNew",
    initialState,

    extraReducers:(builder)=>{

        builder.addCase(GetAccountTypeNew.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(GetAccountTypeNew.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.accountCycle = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.accountCycle);
            state.errors = null;
          })
    
          builder.addCase(GetAccountTypeNew.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          builder.addCase(GetFinancialPeriodsList.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(GetFinancialPeriodsList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.FinancialPeriodList = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.FinancialPeriodList);
            state.errors = null;
          })
    
          builder.addCase(GetFinancialPeriodsList.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          
        builder.addCase(getAcoountsTree.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(getAcoountsTree.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.data);
            state.errors = null;
          })
    
          builder.addCase(getAcoountsTree.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          builder.addCase(createAccountTreeNode.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
    
          builder.addCase(createAccountTreeNode.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //state.accountType = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.accountType);
            state.errors = null;
          })
    
          builder.addCase(createAccountTreeNode.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
    }

})


export default AccountingTreeSlice.reducer