import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import { Button, Flex, Text, Select, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react"
import List from "../components/List";
import GButton from "../../../../components/ui/button/Button";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import NoData from "components/ui/noData/NoData";
import { getPaymentMethods, resetPaymentMethodFilteredData } from "../services/paymentMethodsService";
import ExportModal from "components/ui/modal/ExportModal";
import { IoCloseOutline } from "react-icons/io5";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const PaymentMethods = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 51);

  const dispatch = useDispatch();
  const paymentMethods = useSelector(state => state.paymentMethods);
  const { t } = useTranslation();

  const [showCreatePaymentMethodModal, setShowCreatePaymentMethodModal] = useState(null);
  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = useState(null);
  const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] = useState(null);
  const [showFilterPaymentMethodsModal, setShowFilterPaymentMethodsModal] = useState(null);
  const [exportModal, SetExportModal] = useState(false);

  const resetFunction = () => {
    dispatch(resetPaymentMethodFilteredData());
  };


  useEffect(() => {
    dispatch(getPaymentMethods());
  }, [dispatch]);


  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.payment_methods.payment_methods")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
          <Button
          type="button"
          bg="none"
          border="1px solid"
          borderColor="border"
          textTransform="capitalize"
          fontSize="1rem"
          color="text"
          _hover={{ bg: "primary", color: "light" }}
        >
          <div onClick={() => SetExportModal(true)}>
            {t("general.export")}
          </div>
        </Button>

            <GButton
              title={t("pages.payment_methods.create_payment_method")}
              onClick={() => setShowCreatePaymentMethodModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
      >
        <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom="2px solid"
            borderColor="primary"
          />
        </Flex>

        <GButton
                    title={
                      paymentMethods?.filteredData?.length === 0
                        ? t("general.filter")
                        : t("general.clear_filter")
                    }
                    border="1px solid"
                    borderColor="border"
                    postfixElement={
                      paymentMethods?.filteredData?.length === 0 ? (
                        <FiIcons.FiFilter size={16} />
                        ) : (
                        <IoCloseOutline fontWeight={800} size={20} />
                        )
                    }
                    hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                    onClick={
                      paymentMethods?.filteredData?.length === 0
                        ? () => setShowFilterPaymentMethodsModal(true)
                        : resetFunction
                    }
                />
      </Flex>

      {paymentMethods?.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : paymentMethods?.data?.length === 0 ? (
        <NoData
          title={t("pages.payment_methods.no_data_hint")}
          btnTitle={t("pages.payment_methods.create_payment_method")}
          onClickCreateBtn={() => setShowCreatePaymentMethodModal(true)}
        />
      ) : (
        paymentMethods?.filteredData?.length>0?( <List
          data={paymentMethods.filteredData}
          handleUpdate={(paymentMethod) => setShowUpdatePaymentMethodModal(paymentMethod)}
          handleDelete={(paymentMethod) => setShowDeletePaymentMethodModal(paymentMethod)}
          
        />):( <List
          data={paymentMethods?.data}
          handleUpdate={(paymentMethod) => setShowUpdatePaymentMethodModal(paymentMethod)}
          handleDelete={(paymentMethod) => setShowDeletePaymentMethodModal(paymentMethod)}
          
        />)
      )}

      {/* <Flex
        alignItems="center" flexWrap="wrap" justifyContent="space-between"
        mt={4} gap={4}
      >
        <Pagination
          page={page}
          size={size}
          itemsCouns={100}
          totalItemsCount={100}
          onChange={(page) => setPage(page)}
        />
        <Select
          w="auto" bg="light" border="none"
          onChange={(e) => setSize(e.target.value)}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </Select>
      </Flex> */}

      {showCreatePaymentMethodModal && (
        <CreateModal
          onClose={() => setShowCreatePaymentMethodModal(null)}
        />
      )}

      {showUpdatePaymentMethodModal && (
        <UpdateModal
          data={showUpdatePaymentMethodModal}
          onClose={() => setShowUpdatePaymentMethodModal(null)}
        />
      )}

      {showDeletePaymentMethodModal && (
        <DeleteModal
          data={showDeletePaymentMethodModal}
          onClose={() => setShowDeletePaymentMethodModal(null)}
        />
      )}     

      {showFilterPaymentMethodsModal && (
        <FilterModal
          onClose={() => setShowFilterPaymentMethodsModal(null)}
          data={paymentMethods.data}
        />
      )}

      {exportModal && (
        <ExportModal onClose={() => SetExportModal(false)}
        data={paymentMethods.data}
          fileName={"paymentMethod"}
        ></ExportModal>
      )}
    </>
  )
}

export default PaymentMethods