import React, { useEffect, useState } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast, Input as ChInput, Text, } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { editItemCostAdjustment, getCostAdjustmentItemsList } from "../../services/costAdjustmentItem";


export default function EditItemCostAdjustment({onClose, id}) {  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success",});
  const { handleSubmit } =useForm();

  const costAdjustMentData = useSelector(state => state.costAdjustment);

  const [itemData, setItemData] = useState({});
  
  useEffect(() => {
    const initialItemData = {};
    costAdjustMentData.costAdjusmentItemsList.forEach((el) => {
      // Store each element in a separate object
      initialItemData[el.CostAdjustmentItemId] = {
        CostAdjustmentItemId: el.CostAdjustmentItemId,
        NewCostPerUnit: el.NewCostPerUnit,
      };
    });
    // Set the state with both the object and the array
    setItemData(initialItemData);
  }, [costAdjustMentData.costAdjusmentItemsList]);

  const handleQuantityChange = (newQuantity, itemId) => {
    // Step 3: Update the state with the new data using a unique identifier
    setItemData((prevData) => {
      return {
        ...prevData,
        [itemId]: {
          ...prevData[itemId],
          NewCostPerUnit: newQuantity,
        },
      };
    });
  };
  
  return <>
                <GModal
                onClose={onClose}
                modalHeader={t("pages.cost_adjustment.cost")}
                modalContent={(
                <Box p={4}>
                    <form onSubmit={handleSubmit( (values) => {
                            const editedItems = Object.keys(itemData).map((itemId) => ({
                              CostAdjustmentItemId: parseInt(itemId,10),
                              NewCostPerUnit: itemData[itemId].NewCostPerUnit,
                            }));
                            values.CostAdjustmentItem = editedItems;   
                            dispatch(editItemCostAdjustment(values))
                            .unwrap()
                            .then((_) => {
                              toast({ description: t("pages.production.production_item_updated") });
                              dispatch(getCostAdjustmentItemsList(id));
                              onClose();
                            });
                    })}>
              <Stack spacing={6}>
                {costAdjustMentData.costAdjusmentItemsList.map((el) => (
                  <Box>
                    <Text
                      marginBottom={"7px"}
                      fontSize={"1rem"}
                      fontWeight={"500"}
                      textTransform={"capitalize"}
                      textAlign={"start"}
                      marginInlineEnd={"0.75rem"}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {el.InventoryItemNameEng}
                    </Text>
                    <ChInput
                      placeholder={itemData[el.CostAdjustmentItemId]?.NewCostPerUnit || ""}
                      name="NewCostPerUnit"
                      onChange={(e) =>
                        handleQuantityChange(
                          e.target.value,
                          el.CostAdjustmentItemId
                        )
                      }
                    />
                  </Box>
                ))}
  
                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
                    </form>
                </Box>
      )}
    />
  
  </>
}
