import React, { useEffect } from 'react';
import GModal from "components/ui/modal/Modal";
import { useTranslation } from 'react-i18next';
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import Input from "components/form/Input"
import { Box, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react"
import { useForm } from 'react-hook-form';
import GButton from "components/ui/button/Button"
import { useDispatch, useSelector } from 'react-redux';
import { editSubCasherSettings, getBrnachesSection, getkitchenLanguage, getorderTags, getordersType, getordersTypeDisabled } from '../../services/devicesTypesService';
import { useParams } from 'react-router-dom';

const SettingsModal = ({onClose}) => {

  const { t } = useTranslation();

  const devicesTypes=[]


  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getordersType())
  },[dispatch])


  useEffect(()=>{
    dispatch(getordersTypeDisabled())
  },[dispatch])

  useEffect(()=>{
    dispatch(getkitchenLanguage())
  },[dispatch])

  useEffect(()=>{
    dispatch(getorderTags())
  },[dispatch])

  useEffect(()=>{
    dispatch(getBrnachesSection())
  },[dispatch])


  const data = useSelector(state => state.devicesTypes);

  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  //console.log(data)
  
  const {
    handleSubmit,
    setValue,
    register,
    control
  } = useForm();

  const id = useParams()

  //console.log(id.id)

  return (
    <GModal
    
    onClose={onClose}

    modalHeader={t("pages.devices.edit_device_settings")}
    modalContent={(

      <Box p={4}>
      

      <form onSubmit={handleSubmit(values=>{
        values.DefaultOrderTypeId=values.DefaultOrderTypeId.DefaultOrderTypeId
        values.DisabledOrderTypeId=values.DisabledOrderTypeId.DisabledOrderTypeId
        values.KitchenPrintLanguageId=values.KitchenPrintLanguageId.KitchenPrintLanguageId
        values.TagId= values.TagId?.map(el => ({ TagId: el.TagId}));
        values.BranchSectionId=values.BranchSectionId.BranchSectionId
        values.DeviceId=id.id
        dispatch(editSubCasherSettings(values)).unwrap()
        .then(_ => {
          toast({ description: t("pages.devices.device_created") });
          onClose()
        });
        //console.log(values)
      })}>
      
      <Stack spacing={6}>
      
      
    <Input
    label={t("pages.devices.Call_Number_Start")}
    labelStyle={{ textTransform: "capitalize" }}
    placeholder={t("pages.devices.Call_Number_Start")}
    name="CallNumberStart"
    isFormController
    isRequired
    control={control}
  />


    <Input
    label={t("pages.devices.Call_Number_Reset")}
    labelStyle={{ textTransform: "capitalize" }}
    placeholder={t("pages.devices.Call_Number_Reset")}
    name="CallNumberReset"
    isFormController
    isRequired
    control={control}
  />


  <MultiSelect
  data={data.ordersType && data.ordersType.map(el => ({
    ...el,
    label: el.OrderTypeName,
    value: el.DefaultOrderTypeId
  }))}
  title={t("pages.devices.defult_Order_Type")}
  isMulti={false}
  isRequired
  onChange={(e) => setValue("DefaultOrderTypeId", e)}
/>



<MultiSelect
data={data.ordersTypeDisabled&& data.ordersTypeDisabled.map(el => ({
  ...el,
  label: el.OrderTypeName,
  value: el.DisabledOrderTypeId
}))}
title={t("pages.devices.Disabled_Order_Types")}
isMulti={false}
isRequired
onChange={(e) => setValue("DisabledOrderTypeId", e)}
/>



<MultiSelect
data={data.kitchenLanguage&& data.kitchenLanguage.map(el => ({
  ...el,
  label: el.LanguageNameEng,
  value: el.KitchenPrintLanguageId
}))}
title={t("pages.devices.Kitchen_Print_Language")}
isMulti={false}
isRequired
onChange={(e) => setValue("KitchenPrintLanguageId", e)}
/>



<MultiSelect
data={data.ordertags && data.ordertags.map(el => ({
  ...el,
  label: el.TagName,
  value: el.TagId
}))}
title={t("pages.devices.Order_Tags")}
isMulti={true}
isRequired
onChange={(e) => setValue("TagId", e)}
/>

<Input
label={t("pages.devices.Send_End_of_Day_Report_To_Email")}
labelStyle={{ textTransform: "capitalize" }}
placeholder={t("pages.devices.Send_End_of_Day_Report_To_Email")}
name="EndDayReportEmail"
isFormController
isRequired
control={control}
/>

<Input
label={t("pages.devices.Send_Shift_Report_To_Email")}
labelStyle={{ textTransform: "capitalize" }}
placeholder={t("pages.devices.Send_Shift_Report_To_Email")}
name="ShiftReportEmail"
isFormController
isRequired
control={control}
/>

<Input
label={t("pages.devices.Send_Till_Report_To_Email")}
labelStyle={{ textTransform: "capitalize" }}
placeholder={t("pages.devices.Send_Till_Report_To_Email")}
name="TillReportEmail"
isFormController
isRequired
control={control}
/>
      

<MultiSelect
data={data.branchesSection&& data.branchesSection.map(el => ({
  ...el,
  label: el.BranchSectionName,
  value: el.BranchSectionId
}))}
title={t("pages.devices.Assigned_Sections")}
isMulti={false}
isRequired
onChange={(e) => setValue("BranchSectionId", e)}
/>

              <Checkbox {...register("AutoPairWithOtherDevices")}>
                {t("pages.devices.Auto_Pair_With_Other_Devices")}
              </Checkbox>


              <Checkbox {...register("EnableBarcodeScanners")}>
                {t("pages.devices.Enable_Barcode_Scanners")}
              </Checkbox>

              <Checkbox {...register("AutoAcceptSendOnlineOrdersKitchen")}>
                {t("pages.devices.Auto_Accept_and_Send_Online_Orders_to_Kitchen")}
              </Checkbox>

              <Checkbox {...register("PrintOnlineOrderInfoWhenReceived")}>
                {t("pages.devices.Print_Online_Order_Info_When_Received")}
              </Checkbox>

              <Checkbox {...register("AutoSendAheadOrdertoKitchen")}>
                {t("pages.devices.Auto_Send_Ahead_Order_to_Kitchen")}
              </Checkbox>

              <Checkbox {...register("DisableAutoReceiptPrinting")}>
                {t("pages.devices.Disable_Automatic_Receipt_Printing")}
              </Checkbox>

              <Checkbox {...register("UseCallNofromMasterCashier")}>
                {t("pages.devices.Use_Call_Number_from_Master_Cashier")}
              </Checkbox>

              <Checkbox {...register("PrintDrawerOperations")}>
                {t("pages.devices.Print_Drawer_Operations")}
              </Checkbox>

              <Checkbox {...register("ForceSelectPriceTag")}>
                {t("pages.devices.Force_Select_Price_Tag")}
              </Checkbox>

              <Checkbox {...register("ForceSelectSeat")}>
                {t("pages.devices.Force_Select_Seat")}
              </Checkbox>

              
              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  //isLoading={devices.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  //isLoading={devices.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
      
      </Stack>
      
      
      </form>
      
      
      </Box>
    )}
    
    />
  );
}

export default SettingsModal;
