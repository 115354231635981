import React from 'react';
import Input from "components/form/Input";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getProductionFilteredData } from '../../services/productionService';

export default function FilterModal({onClose , data }) {
    const { t } = useTranslation();
    const dispatch =useDispatch();
    const toast = useToast({position: "top", duration: 3000, status: "success", });

    const [filteredData, setFilteredData] = useState(data);

    // Filter Data
    const [filterCriteria, setFilterCriteria] = useState({
        BranchName: "",
        ProductionTypeName: "",
        Status: ""
      });

      const handleFilterProductions = (e) => {
        e.preventDefault();
    
        const filteredResult = data.filter((item) => {
          const { BranchName , ProductionTypeName , Status } = filterCriteria;
    
          let filterBranchName = true;
          let filterProductionTypeName = true;
          let filterStatus= true;
    
          if (BranchName) {
            filterBranchName = item.BranchName.toLowerCase().includes(BranchName.toLowerCase());
          }
    
          if (ProductionTypeName) {
            filterProductionTypeName = item.ProductionTypeName.toLowerCase().includes(ProductionTypeName.toLowerCase());
          }
    
          if (Status) {
            filterStatus = item.Status.toLowerCase().includes(Status.toLowerCase());
          }

          return (
            filterBranchName &&
            filterProductionTypeName &&
            filterStatus 
          );

        });
    
        setFilteredData(filteredResult);
        onClose();
        dispatch(getProductionFilteredData(filteredResult));
      };

  return <>
  
  <GModal
      onClose={onClose}
      modalHeader={t("pages.production.production_filter")}
      modalContent={
        <Box p={4}>
          <form 
            onSubmit={handleFilterProductions}
          >
            <Stack spacing={6}>
              <Input
                label={t("pages.production.branch")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.production.branch")}
                name="BranchName"
                value={filterCriteria.BranchName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    BranchName: e.target.value,
                  })
                }
              />
              <Input
                label={t("pages.production.type")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.production.type")}
                name="ProductionTypeName"
                value={filterCriteria.ProductionTypeName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    ProductionTypeName: e.target.value,
                  })
                }
              />
              <Input
                label={t("pages.production.status")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.production.status")}
                name="Status"
                value={filterCriteria.Status}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Status: e.target.value,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  
  </>
}
