import { useTranslation } from "react-i18next";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GoCheckCircleFill, GoXCircleFill } from "react-icons/go";

import { useNavigate } from "react-router-dom";
import NoCoupons from "./NoCoupons";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { MdSettingsBackupRestore } from "react-icons/md";
import RestoreModal from "./modals/RestoreModal";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({ coupons, couponsSelectedTable, onClickCreateCouponBtn }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log("couponss", coupons);

  const filteredCoupons = useSelector((state) => state.coupons);

  const allCoupons = coupons.filter((coupon) => coupon.IsDeleted === false);
  const deletedCoupons = coupons.filter((coupon) => coupon.IsDeleted === true);
  const ActiveCoupons = coupons.filter(
    (coupon) => coupon.IsActive === true && coupon.IsDeleted === false
  );
  const InActiveCoupons = coupons.filter(
    (coupon) => coupon.IsActive === false && coupon.IsDeleted === false
  );

  const allFilteredCoupons = filteredCoupons.filteredData.filter(
    (coupon) => coupon.IsDeleted === false
  );
  const deletedFilteredCoupons = filteredCoupons.filteredData.filter(
    (coupon) => coupon.IsDeleted === true
  );
  const ActiveFilteredCoupons = filteredCoupons.filteredData.filter(
    (coupon) => coupon.IsActive === true && coupon.IsDeleted === false
  );
  const InActiveFilteredCoupons = filteredCoupons.filteredData.filter(
    (coupon) => coupon.IsActive === false && coupon.IsDeleted === false
  );
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState(null);
  const clickedbuttom = (id) => {
    setShowModel(true);
    setId(id);
  };

  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const [currentPageInActive, setCurrentPageInActive] = useState(1);
  const [currentPageDeleted, setCurrentPageDeleted] = useState(1);
  const [filteredPageAll, setFilteredPageAll] = useState(1);
  const [filteredPageActive, setFilteredPageActive] = useState(1);
  const [filteredPageInActive, setFilteredPageInActive] = useState(1);
  const [filteredPageDeleted, setFilteredPageDeleted] = useState(1);

  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const recordsPerPage = 10;

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  const recordsAll = calculateRecords(
    allCoupons,
    currentPageAll,
    recordsPerPage
  );
  const recordsActive = calculateRecords(
    ActiveCoupons,
    currentPageActive,
    recordsPerPage
  );
  const recordsInActive = calculateRecords(
    InActiveCoupons,
    currentPageInActive,
    recordsPerPage
  );

  const recordsDeleted = calculateRecords(
    deletedCoupons,
    currentPageDeleted,
    recordsPerPage
  );

  const filteredRecordsAll = calculateRecords(
    allFilteredCoupons,
    filteredPageAll,
    recordsPerPage
  );
  const filteredRecordsActive = calculateRecords(
    ActiveFilteredCoupons,
    filteredPageActive,
    recordsPerPage
  );
  const filteredRecordsInActive = calculateRecords(
    InActiveFilteredCoupons,
    filteredPageInActive,
    recordsPerPage
  );

  const filterdRecordsDeleted = calculateRecords(
    deletedFilteredCoupons,
    filteredPageDeleted,
    recordsPerPage
  );
  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      {filteredCoupons.filteredData.length === 0 ? (
        <Fragment>
          {couponsSelectedTable === "all" && allCoupons.length > 0 && (
            <Fragment>
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>{t("pages.coupons.name")}</Th>
                    <Th>{t("pages.coupons.name_localized")}</Th>
                    <Th>{t("pages.coupons.code")}</Th>
                    <Th>{t("pages.coupons.discount")}</Th>
                    <Th>{t("pages.coupons.max_usage")}</Th>
                    <Th>{t("pages.coupons.coupon_type")}</Th>
                    <Th>{t("pages.coupons.active")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {recordsAll.map((coupon, index) => (
                    <Tr
                      key={coupon.CouponId}
                      onClick={() =>
                        navigate(
                          `/management/coupons_details/${coupon.CouponId}`
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Td>{index + 1}</Td>
                      <Td>{coupon?.CouponName}</Td>
                      <Td>{coupon?.CouponNameLoc}</Td>
                      <Td>{coupon?.CouponCode}</Td>
                      <Td>{coupon?.DisCount[0]?.DisCountName}</Td>
                      <Td>{coupon?.CouponMaximumUses}</Td>
                      <Td>{coupon?.DisCount[0]?.CouponType}</Td>
                      <Td className="activeIcon">
                        {coupon.IsActive ? (
                          <GoCheckCircleFill size="25px" />
                        ) : (
                          <GoXCircleFill size="25px" />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                currentPage={currentPageAll}
                setCurrentPage={setCurrentPageAll}
                npage={Math.ceil(allCoupons.length / recordsPerPage)}
                prePage={() => prePage(currentPageAll, setCurrentPageAll)}
                nextPage={() =>
                  nextPage(
                    currentPageAll,
                    setCurrentPageAll,
                    Math.ceil(allCoupons.length / recordsPerPage)
                  )
                }
              />
            </Fragment>
          )}

          {couponsSelectedTable === "all" && allCoupons.length === 0 && (
            <NoCoupons onClickCreateDiscountBtn={onClickCreateCouponBtn} />
          )}

          {couponsSelectedTable === "active" && ActiveCoupons.length > 0 && (
            <Fragment>
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>{t("pages.coupons.name")}</Th>
                    <Th>{t("pages.coupons.name_localized")}</Th>
                    <Th>{t("pages.coupons.code")}</Th>
                    <Th>{t("pages.coupons.discount")}</Th>
                    <Th>{t("pages.coupons.max_usage")}</Th>
                    <Th>{t("pages.coupons.coupon_type")}</Th>
                    <Th>{t("pages.coupons.active")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {recordsActive.map((coupon, index) => (
                    <Tr
                      key={coupon.CouponId}
                      onClick={() =>
                        navigate(
                          `/management/coupons_details/${coupon.CouponId}`
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Td>{index + 1}</Td>
                      <Td>{coupon?.CouponName}</Td>
                      <Td>{coupon?.CouponNameLoc}</Td>
                      <Td>{coupon?.CouponCode}</Td>
                      <Td>{coupon?.DisCount[0]?.DisCountName}</Td>
                      <Td>{coupon?.CouponMaximumUses}</Td>
                      <Td>{coupon?.DisCount[0]?.CouponType}</Td>
                      <Td className="activeIcon">
                        {coupon.IsActive ? (
                          <GoCheckCircleFill size="25px" />
                        ) : (
                          <GoXCircleFill size="25px" />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                currentPage={currentPageActive}
                setCurrentPage={setCurrentPageActive}
                npage={Math.ceil(ActiveCoupons.length / recordsPerPage)}
                prePage={() => prePage(currentPageActive, setCurrentPageActive)}
                nextPage={() =>
                  nextPage(
                    currentPageActive,
                    setCurrentPageActive,
                    Math.ceil(ActiveCoupons.length / recordsPerPage)
                  )
                }
              />
            </Fragment>
          )}

          {couponsSelectedTable === "inactive" &&
            InActiveCoupons.length > 0 && (
              <Fragment>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>{t("pages.coupons.name")}</Th>
                      <Th>{t("pages.coupons.name_localized")}</Th>
                      <Th>{t("pages.coupons.code")}</Th>
                      <Th>{t("pages.coupons.discount")}</Th>
                      <Th>{t("pages.coupons.max_usage")}</Th>
                      <Th>{t("pages.coupons.coupon_type")}</Th>
                      <Th>{t("pages.coupons.active")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {recordsInActive.map((coupon, index) => (
                      <Tr
                        key={coupon.CouponId}
                        onClick={() =>
                          navigate(
                            `/management/coupons_details/${coupon.CouponId}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Td>{index + 1}</Td>
                        <Td>{coupon?.CouponName}</Td>
                        <Td>{coupon?.CouponNameLoc}</Td>
                        <Td>{coupon?.CouponCode}</Td>
                        <Td>{coupon?.DisCount[0]?.DisCountName}</Td>
                        <Td>{coupon?.CouponMaximumUses}</Td>
                        <Td>{coupon?.DisCount[0]?.CouponType}</Td>
                        <Td className="activeIcon">
                          {coupon.IsActive ? (
                            <GoCheckCircleFill size="25px" />
                          ) : (
                            <GoXCircleFill size="25px" />
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Pagination
                  currentPage={currentPageInActive}
                  setCurrentPage={setCurrentPageInActive}
                  npage={Math.ceil(InActiveCoupons.length / recordsPerPage)}
                  prePage={() =>
                    prePage(currentPageInActive, setCurrentPageInActive)
                  }
                  nextPage={() =>
                    nextPage(
                      currentPageInActive,
                      setCurrentPageInActive,
                      Math.ceil(InActiveCoupons.length / recordsPerPage)
                    )
                  }
                />
              </Fragment>
            )}

          {couponsSelectedTable === "deleted" && deletedCoupons.length > 0 && (
            <Fragment>
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>{t("pages.coupons.name")}</Th>
                    <Th>{t("pages.coupons.name_localized")}</Th>
                    <Th>{t("pages.coupons.code")}</Th>
                    <Th>{t("pages.coupons.discount")}</Th>
                    <Th>{t("pages.coupons.max_usage")}</Th>
                    <Th>{t("pages.coupons.coupon_type")}</Th>
                    <Th>{t("general.action")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {recordsDeleted.map((coupon, index) => (
                    <Tr key={coupon.CouponId}>
                      <Td>{index + 1}</Td>
                      <Td>{coupon?.CouponName}</Td>
                      <Td>{coupon?.CouponNameLoc}</Td>
                      <Td>{coupon?.CouponCode}</Td>
                      <Td>{coupon?.DisCount[0]?.DisCountName}</Td>
                      <Td>{coupon?.CouponMaximumUses}</Td>
                      <Td>{coupon?.DisCount[0]?.CouponType}</Td>
                      <Td className="activeIcon">
                        <Flex>
                          <Box
                            color="#C53030"
                            _hover={{ color: "#38A169" }}
                            p={2}
                            width={"fit-content"}
                            cursor="pointer"
                            onClick={() => clickedbuttom(coupon.CouponId)}
                          >
                            <MdSettingsBackupRestore size={36} />
                          </Box>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                currentPage={currentPageDeleted}
                setCurrentPage={setCurrentPageDeleted}
                npage={Math.ceil(deletedCoupons.length / recordsPerPage)}
                prePage={() =>
                  prePage(currentPageDeleted, setCurrentPageDeleted)
                }
                nextPage={() =>
                  nextPage(
                    currentPageDeleted,
                    setCurrentPageDeleted,
                    Math.ceil(deletedCoupons.length / recordsPerPage)
                  )
                }
              />
            </Fragment>
          )}

          {((couponsSelectedTable === "deleted" &&
            deletedCoupons.length === 0) ||
            (couponsSelectedTable === "active" && ActiveCoupons.length === 0) ||
            (couponsSelectedTable === "inactive" &&
              InActiveCoupons.length === 0)) && (
            <Flex
              p={4}
              bg="white"
              alignItems="center"
              justifyContent="center"
              color="text"
              textTransform="capitalize"
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
              borderRadius={6}
            >
              {t("pages.branches.no_data")}
            </Flex>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {couponsSelectedTable === "all" && allFilteredCoupons.length > 0 && (
            <Fragment>
              <Table>
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>{t("pages.coupons.name")}</Th>
                    <Th>{t("pages.coupons.name_localized")}</Th>
                    <Th>{t("pages.coupons.code")}</Th>
                    <Th>{t("pages.coupons.discount")}</Th>
                    <Th>{t("pages.coupons.max_usage")}</Th>
                    <Th>{t("pages.coupons.coupon_type")}</Th>
                    <Th>{t("pages.coupons.active")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredRecordsAll.map((coupon, index) => (
                    <Tr
                      key={coupon.CouponId}
                      onClick={() =>
                        navigate(
                          `/management/coupons_details/${coupon.CouponId}`
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Td>{index + 1}</Td>
                      <Td>{coupon?.CouponName}</Td>
                      <Td>{coupon?.CouponNameLoc}</Td>
                      <Td>{coupon?.CouponCode}</Td>
                      <Td>{coupon?.DisCount[0]?.DisCountName}</Td>
                      <Td>{coupon?.CouponMaximumUses}</Td>
                      <Td>{coupon?.DisCount[0]?.CouponType}</Td>
                      <Td className="activeIcon">
                        {coupon.IsActive ? (
                          <GoCheckCircleFill size="25px" />
                        ) : (
                          <GoXCircleFill size="25px" />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                currentPage={filteredPageAll}
                setCurrentPage={setFilteredPageAll}
                npage={Math.ceil(allFilteredCoupons.length / recordsPerPage)}
                prePage={() => prePage(filteredPageAll, setFilteredPageAll)}
                nextPage={() =>
                  nextPage(
                    filteredPageAll,
                    setFilteredPageAll,
                    Math.ceil(allFilteredCoupons.length / recordsPerPage)
                  )
                }
              />
            </Fragment>
          )}

          {couponsSelectedTable === "all" &&
            allFilteredCoupons.length === 0 && (
              <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
                color="text"
                textTransform="capitalize"
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                borderRadius={6}
              >
                {t("pages.branches.no_data")}
              </Flex>
            )}

          {couponsSelectedTable === "active" &&
            ActiveFilteredCoupons.length > 0 && (
              <Fragment>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>{t("pages.coupons.name")}</Th>
                      <Th>{t("pages.coupons.name_localized")}</Th>
                      <Th>{t("pages.coupons.code")}</Th>
                      <Th>{t("pages.coupons.discount")}</Th>
                      <Th>{t("pages.coupons.max_usage")}</Th>
                      <Th>{t("pages.coupons.coupon_type")}</Th>
                      <Th>{t("pages.coupons.active")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredRecordsActive.map((coupon, index) => (
                      <Tr
                        key={coupon.CouponId}
                        onClick={() =>
                          navigate(
                            `/management/coupons_details/${coupon.CouponId}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Td>{index + 1}</Td>
                        <Td>{coupon?.CouponName}</Td>
                        <Td>{coupon?.CouponNameLoc}</Td>
                        <Td>{coupon?.CouponCode}</Td>
                        <Td>{coupon?.DisCount[0]?.DisCountName}</Td>
                        <Td>{coupon?.CouponMaximumUses}</Td>
                        <Td>{coupon?.DisCount[0]?.CouponType}</Td>
                        <Td className="activeIcon">
                          {coupon.IsActive ? (
                            <GoCheckCircleFill size="25px" />
                          ) : (
                            <GoXCircleFill size="25px" />
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Pagination
                  currentPage={filteredPageActive}
                  setCurrentPage={setFilteredPageActive}
                  npage={Math.ceil(
                    ActiveFilteredCoupons.length / recordsPerPage
                  )}
                  prePage={() =>
                    prePage(filteredPageActive, setFilteredPageActive)
                  }
                  nextPage={() =>
                    nextPage(
                      filteredPageActive,
                      setFilteredPageActive,
                      Math.ceil(ActiveFilteredCoupons.length / recordsPerPage)
                    )
                  }
                />
              </Fragment>
            )}

          {couponsSelectedTable === "inactive" &&
            InActiveFilteredCoupons.length > 0 && (
              <Fragment>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>{t("pages.coupons.name")}</Th>
                      <Th>{t("pages.coupons.name_localized")}</Th>
                      <Th>{t("pages.coupons.code")}</Th>
                      <Th>{t("pages.coupons.discount")}</Th>
                      <Th>{t("pages.coupons.max_usage")}</Th>
                      <Th>{t("pages.coupons.coupon_type")}</Th>
                      <Th>{t("pages.coupons.active")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredRecordsInActive.map((coupon, index) => (
                      <Tr
                        key={coupon.CouponId}
                        onClick={() =>
                          navigate(
                            `/management/coupons_details/${coupon.CouponId}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Td>{index + 1}</Td>
                        <Td>{coupon?.CouponName}</Td>
                        <Td>{coupon?.CouponNameLoc}</Td>
                        <Td>{coupon?.CouponCode}</Td>
                        <Td>{coupon?.DisCount[0]?.DisCountName}</Td>
                        <Td>{coupon?.CouponMaximumUses}</Td>
                        <Td>{coupon?.DisCount[0]?.CouponType}</Td>
                        <Td className="activeIcon">
                          {coupon.IsActive ? (
                            <GoCheckCircleFill size="25px" />
                          ) : (
                            <GoXCircleFill size="25px" />
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Pagination
                  currentPage={filteredPageInActive}
                  setCurrentPage={setFilteredPageInActive}
                  npage={Math.ceil(
                    InActiveFilteredCoupons.length / recordsPerPage
                  )}
                  prePage={() =>
                    prePage(filteredPageInActive, setFilteredPageInActive)
                  }
                  nextPage={() =>
                    nextPage(
                      filteredPageInActive,
                      setFilteredPageInActive,
                      Math.ceil(InActiveFilteredCoupons.length / recordsPerPage)
                    )
                  }
                />
              </Fragment>
            )}

          {couponsSelectedTable === "deleted" &&
            deletedFilteredCoupons.length > 0 && (
              <Fragment>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>{t("pages.coupons.name")}</Th>
                      <Th>{t("pages.coupons.name_localized")}</Th>
                      <Th>{t("pages.coupons.code")}</Th>
                      <Th>{t("pages.coupons.discount")}</Th>
                      <Th>{t("pages.coupons.max_usage")}</Th>
                      <Th>{t("pages.coupons.coupon_type")}</Th>
                      <Th>{t("general.action")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filterdRecordsDeleted.map((coupon, index) => (
                      <Tr key={coupon.CouponId}>
                        <Td>{index + 1}</Td>
                        <Td>{coupon?.CouponName}</Td>
                        <Td>{coupon?.CouponNameLoc}</Td>
                        <Td>{coupon?.CouponCode}</Td>
                        <Td>{coupon?.DisCount[0]?.DisCountName}</Td>
                        <Td>{coupon?.CouponMaximumUses}</Td>
                        <Td>{coupon?.DisCount[0]?.CouponType}</Td>
                        <Td className="activeIcon">
                          <Flex>
                            <Box
                              color="#C53030"
                              _hover={{ color: "#38A169" }}
                              p={2}
                              width={"fit-content"}
                              cursor="pointer"
                              onClick={() => clickedbuttom(coupon.CouponId)}
                            >
                              <MdSettingsBackupRestore size={36} />
                            </Box>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Pagination
                  currentPage={filteredPageDeleted}
                  setCurrentPage={setFilteredPageDeleted}
                  npage={Math.ceil(
                    deletedFilteredCoupons.length / recordsPerPage
                  )}
                  prePage={() =>
                    prePage(filteredPageDeleted, setFilteredPageDeleted)
                  }
                  nextPage={() =>
                    nextPage(
                      filteredPageDeleted,
                      setFilteredPageDeleted,
                      Math.ceil(deletedFilteredCoupons.length / recordsPerPage)
                    )
                  }
                />
              </Fragment>
            )}

          {((couponsSelectedTable === "deleted" &&
            deletedFilteredCoupons.length === 0) ||
            (couponsSelectedTable === "active" &&
              ActiveFilteredCoupons.length === 0) ||
            (couponsSelectedTable === "inactive" &&
              InActiveFilteredCoupons.length === 0)) && (
            <Flex
              p={4}
              bg="white"
              alignItems="center"
              justifyContent="center"
              color="text"
              textTransform="capitalize"
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
              borderRadius={6}
            >
              {t("pages.branches.no_data")}
            </Flex>
          )}
        </Fragment>
      )}
      {showModel && (
        <RestoreModal id={id} onClose={() => setShowModel(false)} />
      )}
    </Box>
  );
};

export default CTable;
