import { Box, Flex, Stack } from '@chakra-ui/react'
import GButton from 'components/ui/button/Button'
import GModal from 'components/ui/modal/Modal'
import MultiSelect from 'components/ui/multiSelect/MultiSelect'
import { getCustomerPhoneNumberOrders } from 'features/orders/services/ordersService'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const CustomerModal = ({onClose,SetCustomerData}) => {


    const { t } = useTranslation();

    const dispatch=useDispatch()

    useEffect(()=>{
        dispatch(getCustomerPhoneNumberOrders())
    },[dispatch])

    const dataorder = useSelector((state) => state.ordersSlice);

  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.call_center.Customer_Phone")}
    modalContent={
        <Box p={4}>
        
        <Stack>
        
        <MultiSelect
        title={t("pages.call_center.Customer_Phone")}
        data={dataorder?.customers&& dataorder?.customers.map(el => ({
          ...el,
          label: `${el.CustomerName} ${el.PhoneNo}`,  // Fixed this line
          value: el.CustomerPhoneId
        }))}
        isMulti={false}
        isRequired={true}
        onChange={(e) => SetCustomerData(e)}
      />

      <Flex gap={4}>
      
      <GButton
        title={t("general.close")}
        onClick={onClose}
        bg="red.600"
        border="1px solid transparent"
        color="light"
        hover={{
          color: "red.600",
          borderColor: "red.600",
          bg: "none",
        }}
      />

      <GButton
      title={t("general.save")}
      onClick={onClose}
      bg="green"
      border="1px solid transparent"
      color="light"
      hover={{
        color: "green",
        borderColor: "green",
        bg: "none",
      }}
    />
    </Flex>
        </Stack>

        </Box>
    }/>
  )
}

export default CustomerModal
