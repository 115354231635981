import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Icon,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  Button,
  Flex,
  Spinner,
  SimpleGrid,
  Checkbox,
  Input as ChInput,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import Ledgertable from "../components/Ledgertable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccounts,
  getAfterFilter,
} from "features/general_accounts/service/accountsTreeService";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { filterObjectsByValue } from "utils/DynamicFilter";
import { formatDate } from "utils/FormatDate";
import { getAcoountCycleNew } from "features/general_accounts/AccountAdd/service/AccountAddService";
import { GetAccByFinancialId } from "features/general_accounts/DailyRestrictions/service/DailyRestrictoionsServers";
import { MdOutlineChangeCircle } from "react-icons/md";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import { checkIdExists } from "utils/cheackAuth";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const LedgerPage = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const { handleSubmit, control, getValues, watch, setValue, register } =
    useForm();

  const [showTable, SetShowTabel] = useState(false);

  const [all, setAll] = useState(null);

  const [selectedValue, setSelectedValue] = useState("all");

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    //console.log(selectedValue)
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccounts());
  }, [dispatch]);

  // const data = useSelector(state => state.AccountTree);

  const data = [{}];

  useEffect(() => {
    const dataSend = { DateFrom: "0" };
    dispatch(getAcoountCycleNew(dataSend));
  }, [dispatch]);

  const AccountCycleData = useSelector((state) => state.AccountAdd);
  // console.log(AccountCycleData.accountCycle);

  const filteredArray = filterObjectsByValue(
    AccountCycleData.accountCycle,
    "IsClosed",
    false
  );

  const [selectedPeriod, setSelectedPeriod] = useState(null);

  useEffect(() => {
    if (selectedPeriod === null) {
      console.log("no fitch");
    } else {
      dispatch(GetAccByFinancialId(selectedPeriod?.FinancialPeriodsId));
    }
  }, [dispatch, selectedPeriod]);

  const AccountData = useSelector((state) => state.DailyRestrictions);
  // console.log(AccountData.data);

  const [filterByName, setFilterByName] = useState(true);

  watch(["AccountNumber"]);
  //console.log(data)

  /* ------------------------------------- Authrzation ------------------------------ */
  const retrievedArray = useDecryptedPrivilege();
  const ManageAccountant_Ledger = checkIdExists(retrievedArray, 1018);

  if (ManageAccountant_Ledger === false) {
    return <NotAuthorizedCompo />;
  }

  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.Ledger.Ledger_account")}
          </Text>
        </Flex>
      </Box>

      <Box
        bg="light"
        marginInline={-6}
        marginTop={5}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div>{t("pages.Ledger.account_type")}</div>

        <Stack spacing={4}>
          <RadioGroup onChange={handleRadioChange} value={selectedValue}>
            <Radio value="all" style={{ marginRight: "50px" }}>
              {t("pages.Ledger.all")}
            </Radio>
            <Radio value="selected" style={{ marginRight: "50px" }}>
              {t("pages.Ledger.selected")}
            </Radio>
          </RadioGroup>
        </Stack>
      </Box>

      <Box
        bg="light"
        marginInline={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex>
          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit((values) => {
              if (selectedValue === "all") {
                values.IsAll = 1;
              } else if (selectedValue === "selected") {
                values.IsAll = 0;
              }
              if (values.AccountNumber) {
                values.Field_3 = values.AccountNumber.AccountNumber;
              }
              //console.log(values)
              dispatch(getAfterFilter(values))
                .unwrap()
                .then((_) => {
                  SetShowTabel(true);
                  toast({
                    description: t("pages.general_accounts.dataFetchingOk"),
                  });
                  setValue("DateTo", 0);
                  setValue("DateFrom", 0);
                });

              //console.log(values)
            })}
          >
            <SimpleGrid
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
              style={{ width: "100%" }}
              columnGap={20}
              rowGap={7}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  gap: "50px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "120px",
                  }}
                >
                  {t("pages.DailyRestrictions.finacial_period")}
                </label>
                <div style={{ width: "100%" }}>
                  <MultiSelect
                    data={
                      filteredArray &&
                      filteredArray.map((el) => ({
                        ...el,
                        label: ` ${t(
                          "pages.AccountAdd.date_From"
                        )} : ${formatDate(el.DateFrom)} ${t(
                          "pages.AccountAdd.date_to"
                        )} ${formatDate(el.DateTo)}`,
                        value: el.FinancialPeriodsId,
                      }))
                    }
                    isMulti={false}
                    isDisabled={selectedValue === "all"}
                    onChange={(e) => {
                      setSelectedPeriod(e);
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  gap: "15px",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setFilterByName(!filterByName)}
                >
                  <MdOutlineChangeCircle color="green" size={25} />
                </div>
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "150px",
                  }}
                >
                  {filterByName
                    ? t("pages.Ledger.account_name")
                    : t("pages.Ledger.account_number")}
                </label>

                <MultiSelect
                  title={""}
                  data={AccountData.data?.map((el) => ({
                    ...el,
                    label: filterByName ? el.AccountName : el.AccountNumber,
                    value: el.AccountNumber,
                  }))}
                  isMulti={false}
                  onChange={(e) => setValue("AccountNumber", e)}
                  isDisabled={selectedValue === "all" || !selectedPeriod}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "150px",
                  }}
                >
                  {t("pages.Ledger.period_from")}
                </label>
                <Controller
                  name="DateFrom"
                  control={control}
                  render={({ field }) => (
                    <ChInput
                      {...field}
                      style={{ width: "100%" }}
                      required
                      type="date"
                    />
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "150px",
                  }}
                >
                  {t("pages.Ledger.period_to")}
                </label>
                <Controller
                  name="DateTo"
                  control={control}
                  render={({ field }) => (
                    <ChInput
                      {...field}
                      style={{ width: "100%" }}
                      required
                      type="date"
                    />
                  )}
                />
              </div>

              {/*<div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "150px",
                  }}
                >
                  {t("pages.Ledger.account_code_from")}
                </label>
                <ChInput
                  disabled={selectedValue === "all"}
                  style={{ width: "100%" }}
                ></ChInput>
              </div>/*}
              {/*<div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "150px",
                  }}
                >
                  {t("pages.Ledger.Cost_center")}
                </label>
                <ChInput
                  disabled={selectedValue === "all"}
                  style={{ width: "100%" }}
                ></ChInput>
                </div>*/}

              {/*<div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "150px",
                  }}
                >
                  {t("pages.Ledger.account_name")}
                </label>
                <ChInput
                  readOnly
                  style={{ width: "100%" }}
                  value={getValues("AccountNumber.AccountName") || ""}
                ></ChInput>
                </div>*/}
              {/*<div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <label
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "150px",
                  }}
                >
                  {t("pages.Ledger.Cost_center")}
                </label>
                <ChInput
                  disabled={selectedValue === "all"}
                  style={{ width: "100%" }}
                ></ChInput>
                </div>*/}

              {/*<div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Checkbox {...register("IsFixed")}>
                  {t("pages.Ledger.View_current_account")}
                </Checkbox>
              </div>/*}
              {/*<div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Checkbox {...register("IsFixed")}>
                  {t("pages.Ledger.Display_uncleared_checks")}
                </Checkbox>
              </div>*/}
            </SimpleGrid>

            <GButton
              title={t("pages.Ledger.dispaly")}
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              style={{ marginTop: "15px" }}
              //onClick={() => SetShowTabel(true)}
              type="submit"
              // onClick={() => setShowUpdateComboModal({ ...combo, ComboId: +comboId || null })}
            />
          </form>
        </Flex>
      </Box>

      {showTable && <Ledgertable></Ledgertable>}
    </>
  );
};

export default LedgerPage;
