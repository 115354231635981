import { Box, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import GModal from "components/ui/modal/Modal";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AddOrEditPrivileges,
  getAccountAuth,
  getAdminMangmentAuth,
  getApplicationsAuth,
  getCustomerAuth,
  getDashBoardAuth,
  getInventoryAuth,
  getMangmentOthersAuth,
  getMenuAuth,
  getOrderAuth,
  getReportsAuth,
  getRoleDetailsAuthById,
} from "../../services/rolesService";
import { AllPrivilegesData } from "../../pages/AllPrivileges";

const Addprivileges = ({ onClose, role }) => {
  const { t } = useTranslation();
  //console.log(role)
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    register,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useForm();

  const dispatch = useDispatch();

  const [showContent, setShowContent] = useState(false);
  // console.log(role)
  useEffect(() => {
    dispatch(getOrderAuth(role.roleId));
    dispatch(getCustomerAuth(role.roleId));
    dispatch(getInventoryAuth(role.roleId));
    dispatch(getMenuAuth(role.roleId));
    dispatch(getAdminMangmentAuth(role.roleId));
    dispatch(getMangmentOthersAuth(role.roleId));
    dispatch(getReportsAuth(role.roleId));
    dispatch(getDashBoardAuth(role.roleId));
    dispatch(getApplicationsAuth(role.roleId));
    dispatch(getAccountAuth(role.roleId));
    dispatch(getRoleDetailsAuthById(role.roleId));
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, [dispatch, role]);

  const data = useSelector((state) => state.roles);

  console.log(data?.RoleDetails[0]);
  console.log(data)

  const transformArray = (inputArray) => {
    return inputArray.map((item) => ({
      value: item.AuthPermission_ID,
      label: item.PermissionName,
    }));
  };

  const transformArrayTogel = (inputArray) => {
    return inputArray.map((item) => ({
      value: item.Id,
      label: item.componentName,
    }));
  };

  //DefaultOrderAuth
  var DefaultOrderAuth;

  if (data?.RoleDetails[0]?.OrderAuth == undefined) {
  } else {
    DefaultOrderAuth = transformArray(data?.RoleDetails[0].OrderAuth);
    //console.log(DefaultOrderAuth);
  }

  //DefaultCustomerAuth
  var DefaultCustomerAuth;

  if (data?.RoleDetails[0]?.CustomerAuth == undefined) {
  } else {
    DefaultCustomerAuth = transformArray(data?.RoleDetails[0].CustomerAuth);
    //console.log(DefaultOrderAuth);
  }

  //InventoryAuth
  var DefaultInventoryAuth;

  if (data?.RoleDetails[0]?.InventoryAuth == undefined) {
  } else {
    DefaultInventoryAuth = transformArray(data?.RoleDetails[0].InventoryAuth);
    //console.log(DefaultOrderAuth);
  }

  //menuAuth
  var DefaultMenuAuth;

  if (data?.RoleDetails[0]?.MenuAuth == undefined) {
  } else {
    DefaultMenuAuth = transformArray(data?.RoleDetails[0].MenuAuth);
    //console.log(DefaultOrderAuth);
  }

  //AdminAuth
  var DefaultAdminAuth;

  if (data?.RoleDetails[0]?.AdminAuth == undefined) {
  } else {
    DefaultAdminAuth = transformArray(data?.RoleDetails[0].AdminAuth);
    //console.log(DefaultOrderAuth);
  }

  //OtherAuth

  var DefaultOtherAuth;

  if (data?.RoleDetails[0]?.OtherAuth == undefined) {
  } else {
    DefaultOtherAuth = transformArray(data?.RoleDetails[0].OtherAuth);
    //console.log(DefaultOrderAuth);
  }

  //ReportsAuth
  var DefaultReportsAuth;

  if (data?.RoleDetails[0]?.ReportsAuth == undefined) {
  } else {
    DefaultReportsAuth = transformArray(data?.RoleDetails[0].ReportsAuth);
    //console.log(DefaultOrderAuth);
  }

  //DashboardAuth
  var DefaultDashboardAuth;

  if (data?.RoleDetails[0]?.DashboardAuth == undefined) {
  } else {
    DefaultDashboardAuth = transformArray(data?.RoleDetails[0].DashboardAuth);
    //console.log(DefaultOrderAuth);
  }

  //CashierWaiterAppsAuth
  var DefaultCashierWaiterAppsAuth;

  if (data?.RoleDetails[0]?.CashierWaiterAppsAuth == undefined) {
  } else {
    DefaultCashierWaiterAppsAuth = transformArray(
      data?.RoleDetails[0].CashierWaiterAppsAuth
    );
    //console.log(DefaultOrderAuth);
  }

    var DefaultAccountingAuth;

    if (data?.RoleDetails[0]?.AccountAuth == undefined) {
    } else {
      DefaultAccountingAuth = transformArray(data?.RoleDetails[0].AccountAuth);
      //console.log(DefaultOrderAuth);
    }

  //data to send to the api
  function extractValues(obj) {
    return Object.values(obj).flatMap((authArray) =>
      authArray.map((authItem) => authItem.value)
    );
  }

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const [isCheckedAll, setisCheckedAll] = useState(false);
  const [isCheckedOrder, setisCheckedOrder] = useState(false);
  const [isCheckedCustomer, setisCheckedCustomer] = useState(false);
  const [isCheckedInventory, setisCheckedInventory] = useState(false);
  const [isCheckedMenu, setisCheckedMenu] = useState(false);
  const [isCheckedAdmin, setisCheckedAdmin] = useState(false);
  const [isCheckedOther, setisCheckedOther] = useState(false);
  const [isCheckedReports, setisCheckedReporst] = useState(false);
  const [isCheckedDashBoard, setisCheckedDashBoard] = useState(false);
  const [isCheckedApplication, setisCheckedApplication] = useState(false);

  //console.log(isCheckedAll)

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.roles.privileges")}
      modalContent={
        showContent && (
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                if (values.OrderAuth === undefined) {
                  values.OrderAuth = DefaultOrderAuth;
                }
                if (values.CustomerAuth === undefined) {
                  values.CustomerAuth = DefaultCustomerAuth;
                }
                if (values.InventoryAuth === undefined) {
                  values.InventoryAuth = DefaultInventoryAuth;
                }
                if (values.menuAuth === undefined) {
                  values.menuAuth = DefaultMenuAuth;
                }
                if (values.AdminMangmentAuth === undefined) {
                  values.AdminMangmentAuth = DefaultAdminAuth;
                }
                if (values.OtherAuth === undefined) {
                  values.OtherAuth = DefaultOtherAuth;
                }
                if (values.ReportsAuth === undefined) {
                  values.ReportsAuth = DefaultReportsAuth;
                }
                if (values.DashBoardAuth === undefined) {
                  values.DashBoardAuth = DefaultDashboardAuth;
                }
                if (values.ApplicationAuth === undefined) {
                  values.ApplicationAuth = DefaultCashierWaiterAppsAuth;
                }
                console.log(values)
                if (isCheckedAll === true) {
                  const dataTest = transformArrayTogel(AllPrivilegesData);
                  values = { AdminMangmentAuth: dataTest };
                }
                if (isCheckedOrder === true) {
                  values.OrderAuth = [
                    { value: 1, label: "Read Orders" },
                    { value: 2, label: "Manage Orders" },
                    { value: 3, label: "Manage Orders Tags" },
                  ];
                }
                if (isCheckedCustomer === true) {
                  values.CustomerAuth = [
                    { value: 4, label: "Read Customers" },
                    { value: 6, label: "Manage Customers" },
                    { value: 5, label: "Read Customers Insights" },
                    { value: 7, label: "Manage Customers House Account" },
                    { value: 8, label: "Manage Customers Loyalty" },
                  ];
                }
                if (isCheckedInventory === true) {
                  values.InventoryAuth = [
                    { value: 9, label: "Read Inventory Items" },
                    { value: 10, label: "Manage Inventory Items" },
                    { value: 11, label: "Read Supplers" },
                    { value: 12, label: "Manage Supplers" },
                    { value: 13, label: "Create Purchase Orders" },
                    { value: 14, label: "Submit Purchase Orders" },
                    { value: 15, label: "Approve Purchase Orders" },
                    { value: 16, label: "View Approved Purchase Orders" },
                    { value: 17, label: "Create Transfer Orders" },
                    { value: 18, label: "Submit Transfer Orders" },
                    { value: 19, label: "Create Transfers" },
                    { value: 20, label: "Send & Receive Transfers" },
                    { value: 21, label: "Create Purchasing" },
                    { value: 22, label: "Submit Purchasing" },
                    { value: 23, label: "Create Purchasing From PO" },
                    { value: 24, label: "Create Direct Purchasing" },
                    { value: 25, label: "Create Production" },
                    { value: 26, label: "Submit Production" },
                    { value: 27, label: "Create Quantity Adjustment" },
                    { value: 28, label: "Submit Quantity Adjustment" },
                    { value: 29, label: "Create Cost Adjustment" },
                    { value: 30, label: "Submit Cost Adjustment" },
                    { value: 31, label: "Create Inventory Count" },
                    { value: 32, label: "Submit Inventory Count" },
                    { value: 33, label: "Read Order Transactions" },
                    { value: 34, label: "Create Inventory Spot Check" },
                    { value: 35, label: "Submit Inventory Spot Check" },
                    { value: 36, label: "Create Inventory Count Sheets" },
                  ];
                }

                if (isCheckedMenu === true) {
                  values.menuAuth = [
                    { value: 37, label: "Read Menu" },
                    { value: 38, label: "Manage Menu" },
                  ];
                }

                if (isCheckedAdmin === true) {
                  values.AdminMangmentAuth = [
                    { value: 39, label: "Manage Branches" },
                    { value: 40, label: "Manage Coupons" },
                    { value: 41, label: "Manage Devices" },
                    { value: 42, label: "Manage Discounts" },
                    { value: 43, label: "Manage Gift Cards" },
                    { value: 44, label: "Manage Promotions" },
                    { value: 45, label: "Manage Settings" },
                    { value: 46, label: "Manage Delivery Zones" },
                    { value: 47, label: "Manage Timed Events" },
                    { value: 48, label: "Manage Users" },
                    { value: 49, label: "Manage Apps" },
                    { value: 50, label: "Manage Taxes & Tax Groups" },
                    { value: 51, label: "Payment Methods" },
                    { value: 52, label: "Manage Charges" },
                    { value: 53, label: "Manage Tags" },
                    { value: 54, label: "Manage Reasons" },
                    { value: 55, label: "Manage Kitchen Flows" },
                    { value: 56, label: "Manage Reservations" },
                    { value: 57, label: "Manage Online Ordering" },
                    { value: 58, label: "Manage Price Tags" },
                    { value: 59, label: "Manage Notifications" },
                    { value: 60, label: "Manage Loyalty" },
                  ];
                }

                if (isCheckedOther === true) {
                  values.OtherAuth = [
                    { value: 61, label: "Manage Ingredients" },
                    { value: 62, label: "Manage Costs" },
                  ];
                }

                if (isCheckedReports === true) {
                  values.ReportsAuth = [
                    { value: 63, label: "View Cost Analysis Report" },
                    { value: 64, label: "View Inventory Control Report" },
                    { value: 65, label: "View Inventory Levels Report" },
                    { value: 66, label: "Inventory Transactions Reports" },
                    { value: 67, label: "View Other Reports" },
                    { value: 68, label: "View Sales Reports" },
                    { value: 69, label: "View Cost Adjustment History Report" },
                    { value: 70, label: "View Ristecho Pay Reports" },
                    { value: 71, label: "View Menu Cost Reports" },
                    { value: 72, label: "View Inventory Items Cost Report" },
                  ];
                }

                if (isCheckedDashBoard === true) {
                  values.DashBoardAuth = [
                    { value: 73, label: "Access General Dashboard" },
                    { value: 74, label: "Access Branches Dashboard" },
                    { value: 75, label: "Access Inventory Dashboard" },
                    { value: 76, label: "Access Call Center Dashboard" },
                  ];
                }

                if (isCheckedApplication === true) {
                  values.ApplicationAuth = [
                    { value: 77, label: "Access Cash Register" },
                    { value: 78, label: "Access Devices Management" },
                    { value: 79, label: "Access Reports" },
                    { value: 80, label: "Act As Driver" },
                    { value: 81, label: "Act As Waiter" },
                    { value: 82, label: "Add Open Charge" },
                    { value: 83, label: "Add Open Price Product" },
                    { value: 84, label: "Apply Ahead Orders" },
                    { value: 85, label: "Apply Predefined Discounts" },
                    { value: 86, label: "Apply Open Discounts" },
                    { value: 87, label: "Edit Products Sent To Kitchen" },
                    { value: 88, label: "Join Order" },
                    { value: 89, label: "Access Drawer Operations" },
                    { value: 90, label: "Perform End Of Day" },
                    { value: 91, label: "Print Check" },
                    { value: 92, label: "Return Order" },
                    { value: 93, label: "Split Order" },
                    { value: 94, label: "View Done Orders" },
                    { value: 95, label: "Void Orders And Products" },
                    { value: 96, label: "Perform Payment" },
                    { value: 97, label: "Edit Orders Opened By Other Users" },
                    { value: 98, label: "Change Table Owner" },
                    { value: 99, label: "Register Users Fingerprint" },
                    { value: 100, label: "Send To Kitchen Before Payment" },
                    { value: 101, label: "Kitchen Reprint" },
                    { value: 102, label: "Edit Tables Layout" },
                    {
                      value: 103,
                      label: "Close Till/Shift With Active Orders",
                    },
                    { value: 104, label: "Manage Product Availability" },
                    { value: 105, label: "Perform Spot Check" },
                    { value: 106, label: "Pay Orders Without Closing" },
                  ];
                }
                const resultArray = extractValues(values);
                const valueSend = {
                  roleId: role.roleId,
                  body: resultArray,
                };
                console.log(valueSend);
                dispatch(AddOrEditPrivileges(valueSend))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.roles.privileges_Added_successfully"
                      ),
                    });
                    onClose();
                  });
                //console.log(resultArray); // Output: [5, 1, 2]
              })}
            >
              <Stack spacing={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedAll}
                    onChange={() => setisCheckedAll(!isCheckedAll)}
                  />
                  <label>{t("pages.roles.Togel_All_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.OrderAuth")}
                  data={
                    data.OrderAuthLookUp &&
                    data?.OrderAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultOrderAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("OrderAuth", e)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedOrder}
                    onChange={() => setisCheckedOrder(!isCheckedOrder)}
                  />
                  <label>{t("pages.roles.choose_All_Order_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.customerAuth")}
                  data={
                    data.CustomerAuthLookUp &&
                    data.CustomerAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultCustomerAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("CustomerAuth", e)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedCustomer}
                    onChange={() => setisCheckedCustomer(!isCheckedCustomer)}
                  />
                  <label>{t("pages.roles.choose_All_Customer_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.InventoryAuth")}
                  data={
                    data.InventoryAuthLookUp &&
                    data.InventoryAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultInventoryAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("InventoryAuth", e)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedInventory}
                    onChange={() => setisCheckedInventory(!isCheckedInventory)}
                  />
                  <label>{t("pages.roles.choose_All_inventory_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.MenuAuth")}
                  data={
                    data.menuAuthLookUp &&
                    data.menuAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultMenuAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("menuAuth", e)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedMenu}
                    onChange={() => setisCheckedMenu(!isCheckedMenu)}
                  />
                  <label>{t("pages.roles.menu_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.AdminMangmentAuth")}
                  data={
                    data.AdminMangmentAuthLookUp &&
                    data.AdminMangmentAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultAdminAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("AdminMangmentAuth", e)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedAdmin}
                    onChange={() => setisCheckedAdmin(!isCheckedAdmin)}
                  />
                  <label>{t("pages.roles.Admin_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.OtherAuth")}
                  data={
                    data.MangmentOtherAuthLookUp &&
                    data.MangmentOtherAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultOtherAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("OtherAuth", e)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedOther}
                    onChange={() => setisCheckedOther(!isCheckedOther)}
                  />
                  <label>{t("pages.roles.Other_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.ReporstAuth")}
                  data={
                    data.ReportsAuthLookUp &&
                    data.ReportsAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultReportsAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("ReportsAuth", e)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedReports}
                    onChange={() => setisCheckedReporst(!isCheckedReports)}
                  />
                  <label>{t("pages.roles.Reports_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.dashboardAuth")}
                  data={
                    data.DashBoardAuthLookUp &&
                    data.DashBoardAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultDashboardAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("DashBoardAuth", e)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedDashBoard}
                    onChange={() => setisCheckedDashBoard(!isCheckedDashBoard)}
                  />
                  <label>{t("pages.roles.DashBoard_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.ApplicationsAuth")}
                  data={
                    data.ApplicationsAuthLookUp &&
                    data.ApplicationsAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultCashierWaiterAppsAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("ApplicationAuth", e)}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderBottom: "2px solid black",
                    height: "50px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedApplication}
                    onChange={() =>
                      setisCheckedApplication(!isCheckedApplication)
                    }
                  />
                  <label>{t("pages.roles.Application_Auth")}</label>
                </div>

                <MultiSelect
                  title={t("pages.roles.Accounting")}
                  data={
                    data.AccountAuthLookUp &&
                    data.AccountAuthLookUp.map((el) => ({
                      ...el,
                      label: el.PermissionName,
                      value: el.AuthPermission_ID,
                    }))
                  }
                  defaultValue={DefaultAccountingAuth}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("AccountAuth", e)}
                />
              </Stack>
              <Flex gap={4} marginTop={5}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  //isLoading={roles.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  //isLoading={roles.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </form>
          </Box>
        )
      }
    />
  );
};

export default Addprivileges;
