import { createSlice } from "@reduxjs/toolkit";
import {
  getIngredientsForProduct,
  getItems,
  addIngredientsForProduct,
} from "../services/linkingIngredientsService";

let initialState = {
  isLoading: false,
  data: [],
  errors: null,
  ingredintsData: [],
};

const linkingIngredientsSlice = createSlice({
  name: "addIngredient",
  initialState,
  extraReducers: (builder) => {
    /*-------------------------------------------------------  Linking Ingredients To Products ----------------------------------------------- */
    builder.addCase(getIngredientsForProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(
      getIngredientsForProduct.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.ingredintsData =
          (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      }
    );

    builder.addCase(getIngredientsForProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/*-------------------------------------------------------  Get Items To Linking Ingredients ----------------------------------------------- */
    builder.addCase(getItems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getItems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/*---------------------------------------------------- Get Linking Ingredients List ------------------------------------------------- */
    builder.addCase(addIngredientsForProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      addIngredientsForProduct.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      }
    );
    builder.addCase(addIngredientsForProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default linkingIngredientsSlice.reducer;
