import { createSlice } from "@reduxjs/toolkit";
import { AddReciptJornul } from "../service/receiptService";


let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
};


  const receiptSlice = createSlice({
    name: "ReciptJornul",
    initialState,
    extraReducers:(builder)=>{

          builder.addCase(AddReciptJornul.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          });

          builder.addCase(AddReciptJornul.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            // state.Accounts =
            //   (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.Accounts);
            state.errors = null;
          });

          builder.addCase(AddReciptJornul.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          });

    }
  });


    export default receiptSlice.reducer;