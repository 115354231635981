import { createSlice } from "@reduxjs/toolkit";
import {getCallcenter,addTransfersorders,getListTransferorder,getTransfersOrdersDetails,editTransferOrders, getTranOrderItems, getInvItemDrop, submitForReview, sendItems, deleteOrder, getEditQuantities, getTransfereOrdersFilteredData, resetTransfereOrdersFilteredData, editTransfereOrdersItemsQuantities, getWareHouseDropdown} from "../services/transfersOrdersService"


let initialState = {
    isLoading: false,
    pages: 0,
    errors: null,
    data:[],
    transferOrderItems:[],
    data2:[],
    DropData:[],
    DropData1:[],
    databyId:[],
    filteredData:[],
    warehouseDropdown:[],
  };


  const transfersOrdersSlice = createSlice({
    name: "transfersorders",
    initialState,

    extraReducers:(builder)=>{

      builder.addCase(getCallcenter.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getCallcenter.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.DropData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getCallcenter.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

/* ------------------------------------------------------------  get WareHouse Dropdown  ----------------------------------------------------------*/
builder.addCase(getWareHouseDropdown.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getWareHouseDropdown.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.warehouseDropdown = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getWareHouseDropdown.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
/* ------------------------------------------------------------------------------------------------------------------------------------------------------*/
      builder.addCase(getInvItemDrop.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getInvItemDrop.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.DropData1 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getInvItemDrop.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })



       builder.addCase(getListTransferorder.pending, (state) => {
         state.isLoading = true;
         state.errors = null;
       })

      builder.addCase(getListTransferorder.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getListTransferorder.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(getTransfersOrdersDetails.pending, (state) => {
         state.isLoading = true;
         state.errors = null;
       })

      builder.addCase(getTransfersOrdersDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.databyId = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getTransfersOrdersDetails.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })


    
      builder.addCase(addTransfersorders.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(addTransfersorders.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(addTransfersorders.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(submitForReview.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(submitForReview.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(submitForReview.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      builder.addCase(sendItems.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(sendItems.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(sendItems.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      /* ------------------------------------------- Edit Transfere Orders Items Quantities ---------------------------------------------- */
      builder.addCase(editTransfereOrdersItemsQuantities.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(editTransfereOrdersItemsQuantities.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })

      builder.addCase(editTransfereOrdersItemsQuantities.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //

      builder.addCase(editTransferOrders.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(editTransferOrders.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(editTransferOrders.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      
      builder.addCase(deleteOrder.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(deleteOrder.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(deleteOrder.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
      //
/* ------------------------------------------- Get Transfere Orders Items ---------------------------------------------- */
      builder.addCase(getTranOrderItems.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

     builder.addCase(getTranOrderItems.fulfilled, (state, { payload }) => {
       state.isLoading = false;
       state.transferOrderItems = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
       state.errors = null;
     })

     builder.addCase(getTranOrderItems.rejected, (state) => {
       state.isLoading = false;
       state.errors = [{ msg: "something went wrong!" }];
     })

     builder.addCase(getEditQuantities.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

     builder.addCase(getEditQuantities.fulfilled, (state, { payload }) => {
       state.isLoading = false;
       state.data2 = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
       state.errors = null;
     })

     builder.addCase(getEditQuantities.rejected, (state) => {
       state.isLoading = false;
       state.errors = [{ msg: "something went wrong!" }];
     })

       /* -------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
    builder.addCase(getTransfereOrdersFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTransfereOrdersFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getTransfereOrdersFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetTransfereOrdersFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetTransfereOrdersFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetTransfereOrdersFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    }
  })


  export default transfersOrdersSlice.reducer