import React from "react";
import { useTranslation } from "react-i18next";
import * as FiIcons from "react-icons/fi";
import { Box, Flex, Heading, Button } from "@chakra-ui/react";
import ExcelButton from "components/excelButton/ExcelButton";
import GButton from "components/ui/button/Button";
import GModal from "components/ui/modal/Modal";
const ExportOvertimeModal = ({ onClose, data, fileName, title }) => {
  const { t } = useTranslation();

  return (
    <GModal
      modalHeader={t("general.export")}
      onClose={onClose}
      modalContent={
        <Box p={6}>
          <Heading as="h4" fontSize="1.3rem" mb={6} textTransform="capitalize">
            {title}
          </Heading>

          <Flex gap={4}>
            <div onClick={() => onClose()}>
              <Button
                type="button"
                bg="none"
                border="1px solid"
                borderColor="border"
                textTransform="capitalize"
                fontSize="1rem"
                color="text"
                _hover={{ bg: "primary", color: "light" }}
              >
                <ExcelButton data={data} fileName={fileName}></ExcelButton>
              </Button>
            </div>

            <GButton
              title={t("general.close")}
              prefixElement={<FiIcons.FiMinimize size={16} />}
              bg="none"
              color="dark"
              border="1px solid"
              borderColor="border"
              hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
              onClick={onClose}
            />
          </Flex>
        </Box>
      }
    />
  );
};

export default ExportOvertimeModal;
