import { createSlice } from "@reduxjs/toolkit";
import { giftCardDropList , giftCardList , addgiftCard } from "../services/giftCardService";

let initialState = {
  isLoading: false,
  errors: null,
  giftCardData: [],
  giftCardData1: [],
};

const inActiveBranchesSlice = createSlice({
  name: "giftsCard",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(giftCardDropList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(giftCardDropList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.giftCardData1 =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(giftCardDropList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    
    builder.addCase(addgiftCard.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
    });
    
    builder.addCase(addgiftCard.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
    });
    
    builder.addCase(addgiftCard.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(giftCardList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(giftCardList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.giftCardData =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(giftCardList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default inActiveBranchesSlice.reducer;
