import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import {
  Button,
  Flex,
  Text,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import ImportModal from "../components/modals/ImportModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import { getCombos, resetFilteredData } from "../services/combosService";
import NoData from "components/ui/noData/NoData";
import ExportModal from "components/ui/modal/ExportModal";
import { IoCloseOutline } from "react-icons/io5";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


const Combos = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const dispatch = useDispatch();
  const combos = useSelector((state) => state.combos);
  const { t } = useTranslation();

  const [showCreateComboModal, setShowCreateComboModal] = useState(null);
  const [showUpdateComboModal, setShowUpdateComboModal] = useState(null);
  const [showDeleteComboModal, setShowDeleteComboModal] = useState(null);
  const [showImportCombosModal, setShowImportCombosModal] = useState(null);
  const [showExportCombosModal, setShowExportCombosModal] = useState(null);
  const [showFilterCombosModal, setShowFilterCombosModal] = useState(null);
  const [exportModal, SetExportModal] = useState(false);

  useEffect(() => {
    dispatch(getCombos());
  }, [dispatch]);

  const resetFunction = () => {
    dispatch(resetFilteredData());
  };

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  
  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  if(showCreateComboModal===true && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowCreateComboModal(false)
  }

  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.combos.combos")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            {/* <Menu>
              <MenuButton as={Button}
                bg="none"
                border="1px solid"
                borderColor="border"
                color="text"
                _hover={{ bg: "primary", borderColor: "primary", color: "light" }}
              >
                <Flex alignItems="center" gap={2}>
                  <FiIcons.FiCloud size={16} />
                  <Text>{t("general.import_export")}</Text>
                  <FiIcons.FiChevronDown size={16} />
                </Flex>
              </MenuButton>
              <MenuList p={0} minW="auto">
                <MenuItem onClick={() => setShowImportCombosModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiUpload />
                    <Text textTransform="capitalize">{t("general.import")}</Text>
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => setShowExportCombosModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiDownload />
                    <Text textTransform="capitalize">{t("general.export")}</Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu> */}

            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
            >
              <div onClick={() => SetExportModal(true)}>
                {t("general.export")}
              </div>
            </Button>

            <GButton
              title={t("pages.combos.create_combo")}
              onClick={() => setShowCreateComboModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom="2px solid"
            borderColor="primary"
          />
        </Flex>

        <GButton
          title={
            combos.filteredData.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
            combos.filteredData.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          onClick={
            combos.filteredData.length === 0
              ? () => setShowFilterCombosModal(true)
              : resetFunction
          }
        />
      </Flex>

      {combos.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : combos.data.length === 0 ? (
        <NoData
          btnTitle={t("pages.combos.create_combo")}
          title={t("pages.combos.no_data_hint")}
          onClickCreateBtn={() => setShowCreateComboModal(true)}
          onClickCreateComboBtn={() => setShowCreateComboModal(true)}
        />
      ) : (
        <Table
          combos={combos.data}
          handleUpdate={(combo) => setShowUpdateComboModal(combo)}
          handleDelete={(combo) => setShowDeleteComboModal(combo)}
        />
      )}

      {showCreateComboModal && (
        <CreateModal onClose={() => setShowCreateComboModal(null)} />
      )}

      {showUpdateComboModal && (
        <UpdateModal
          data={showUpdateComboModal}
          onClose={() => setShowUpdateComboModal(null)}
        />
      )}

      {showDeleteComboModal && (
        <DeleteModal
          data={showDeleteComboModal}
          onClose={() => setShowDeleteComboModal(null)}
        />
      )}

      {/* {showImportCombosModal && (
        <ImportModal onClose={() => setShowImportCombosModal(null)} />
      )}

      {showExportCombosModal && (
        <ExportModal onClose={() => setShowExportCombosModal(null)} />
      )} */}

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={combos.data}
          fileName={"Combos"}
        ></ExportModal>
      )}

      {showFilterCombosModal && (
        <FilterModal
          data={combos.data}
          onClose={() => setShowFilterCombosModal(null)}
        />
      )}
    </>
  );
};

export default Combos;
