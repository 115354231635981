import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";


/* -------------------------------------------------------- Get Tags List ----------------------------------------------------------*/ 
export const getTags = createAsyncThunk("tags/getTags", async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Tags/TagbyTagGroupList");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* -------------------------------------------------------- Create New Tag  ----------------------------------------------------------*/ 
export const createTag = createAsyncThunk("tags/createTag", async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Tags/AddEditTags?tags=${JSON.stringify(args)}`);
      console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------------- Update New Tag  ----------------------------------------------------------*/ 
export const updateTag = createAsyncThunk("tags/updateTag", async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Tags/AddEditTags?tags=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* -------------------------------------------------------------- Delete Tag ------------------------------------------------------------------ */
export const deleteTag = createAsyncThunk( "tags/deleteTag", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`Manage2/Tag/TagById?TagId={"TagId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);