import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GoCheckCircleFill, GoXCircleFill } from "react-icons/go";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({ giftsCards, selectedTable }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const navigatefunction = (id) => {
    navigate(`${id}`);
  };

  const filteredGifts = useSelector((state) => state.giftsCards);

  const ActiveGiftCards = giftsCards.filter(
    (coupon) => coupon.IsActive === true
  );
  const InActiveGiftCards = giftsCards.filter(
    (coupon) => coupon.IsActive === false
  );
  const ActiveFilteredGiftCards = filteredGifts.filteredData.filter(
    (coupon) => coupon.IsActive === true
  );
  const InActiveFilteredGiftCards = filteredGifts.filteredData.filter(
    (coupon) => coupon.IsActive === false
  );

  const [currentPageAll, setCurrentPageAll] = useState(1);
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const [currentPageInActive, setCurrentPageInActive] = useState(1);
  const [filteredPageAll, setFilteredPageAll] = useState(1);
  const [filteredPageActive, setFilteredPageActive] = useState(1);
  const [filteredPageInActive, setFilteredPageInActive] = useState(1);

  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const recordsPerPage = 10;

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
    return data.slice(firstIndex, lastIndex);
  };

  const recordsAll = calculateRecords(
    giftsCards,
    currentPageAll,
    recordsPerPage
  );
  const recordsActive = calculateRecords(
    ActiveGiftCards,
    currentPageActive,
    recordsPerPage
  );
  const recordsInActive = calculateRecords(
    InActiveGiftCards,
    currentPageInActive,
    recordsPerPage
  );

  const filteredRecordsAll = calculateRecords(
    filteredGifts.filteredData,
    filteredPageAll,
    recordsPerPage
  );
  const filteredRecordsActive = calculateRecords(
    ActiveFilteredGiftCards,
    filteredPageActive,
    recordsPerPage
  );
  const filteredRecordsInActive = calculateRecords(
    InActiveFilteredGiftCards,
    filteredPageInActive,
    recordsPerPage
  );

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      {selectedTable === "active" ? (
        filteredGifts.filteredData.length === 0 ? (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.gifts_cards.name")}</Th>
                  <Th>{t("pages.gifts_cards.sku")}</Th>
                  <Th>{t("pages.gifts_cards.category")}</Th>
                  <Th>{t("pages.gifts_cards.price")}</Th>
                  <Th>{t("pages.gifts_cards.active")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recordsActive.map((giftsCard, index) => (
                  <Tr
                    key={giftsCard.GiftCardsId}
                    onClick={() => navigatefunction(giftsCard.GiftCardsId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td>{giftsCard?.GiftCardsName || "-"}</Td>
                    <Td>Sk-{giftsCard.GiftCardsSUK}</Td>
                    <Td>{giftsCard.ProductCategoriesNameEng}</Td>
                    <Td>SAR {giftsCard.Price}</Td>
                    <Td className="activeIcon">
                      {giftsCard.IsActive ? (
                        <GoCheckCircleFill size="25px" />
                      ) : (
                        <GoXCircleFill size="25px" />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={currentPageActive}
              setCurrentPage={setCurrentPageActive}
              npage={Math.ceil(ActiveGiftCards.length / recordsPerPage)}
              prePage={() => prePage(currentPageActive, setCurrentPageActive)}
              nextPage={() =>
                nextPage(
                  currentPageActive,
                  setCurrentPageActive,
                  Math.ceil(ActiveGiftCards.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.gifts_cards.name")}</Th>
                  <Th>{t("pages.gifts_cards.sku")}</Th>
                  <Th>{t("pages.gifts_cards.category")}</Th>
                  <Th>{t("pages.gifts_cards.price")}</Th>
                  <Th>{t("pages.gifts_cards.active")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredRecordsActive.map((giftsCard, index) => (
                  <Tr
                    key={giftsCard.GiftCardsId}
                    onClick={() => navigatefunction(giftsCard.GiftCardsId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td>{giftsCard?.GiftCardsName || "-"}</Td>
                    <Td>Sk-{giftsCard.GiftCardsSUK}</Td>
                    <Td>{giftsCard.ProductCategoriesNameEng}</Td>
                    <Td>SAR {giftsCard.Price}</Td>
                    <Td className="activeIcon">
                      {giftsCard.IsActive ? (
                        <GoCheckCircleFill size="25px" />
                      ) : (
                        <GoXCircleFill size="25px" />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={filteredPageActive}
              setCurrentPage={setFilteredPageActive}
              npage={Math.ceil(ActiveFilteredGiftCards.length / recordsPerPage)}
              prePage={() => prePage(filteredPageActive, setFilteredPageActive)}
              nextPage={() =>
                nextPage(
                  filteredPageActive,
                  setFilteredPageActive,
                  Math.ceil(ActiveFilteredGiftCards.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        )
      ) : selectedTable === "inActive" ? (
        filteredGifts.filteredData.length === 0 ? (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.gifts_cards.name")}</Th>
                  <Th>{t("pages.gifts_cards.sku")}</Th>
                  <Th>{t("pages.gifts_cards.category")}</Th>
                  <Th>{t("pages.gifts_cards.price")}</Th>
                  <Th>{t("pages.gifts_cards.active")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recordsInActive.map((giftsCard, index) => (
                  <Tr
                    key={giftsCard.GiftCardsId}
                    onClick={() => navigatefunction(giftsCard.GiftCardsId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td>{giftsCard?.GiftCardsName || "-"}</Td>
                    <Td>Sk-{giftsCard.GiftCardsSUK}</Td>
                    <Td>{giftsCard.ProductCategoriesNameEng}</Td>
                    <Td>SAR {giftsCard.Price}</Td>
                    <Td className="activeIcon">
                      {giftsCard.IsActive ? (
                        <GoCheckCircleFill size="25px" />
                      ) : (
                        <GoXCircleFill size="25px" />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={currentPageInActive}
              setCurrentPage={setCurrentPageInActive}
              npage={Math.ceil(InActiveGiftCards.length / recordsPerPage)}
              prePage={() =>
                prePage(currentPageInActive, setCurrentPageInActive)
              }
              nextPage={() =>
                nextPage(
                  currentPageInActive,
                  setCurrentPageInActive,
                  Math.ceil(InActiveGiftCards.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{t("pages.gifts_cards.name")}</Th>
                  <Th>{t("pages.gifts_cards.sku")}</Th>
                  <Th>{t("pages.gifts_cards.category")}</Th>
                  <Th>{t("pages.gifts_cards.price")}</Th>
                  <Th>{t("pages.gifts_cards.active")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredRecordsInActive.map((giftsCard, index) => (
                  <Tr
                    key={giftsCard.GiftCardsId}
                    onClick={() => navigatefunction(giftsCard.GiftCardsId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td>{giftsCard?.GiftCardsName || "-"}</Td>
                    <Td>Sk-{giftsCard.GiftCardsSUK}</Td>
                    <Td>{giftsCard.ProductCategoriesNameEng}</Td>
                    <Td>SAR {giftsCard.Price}</Td>
                    <Td className="activeIcon">
                      {giftsCard.IsActive ? (
                        <GoCheckCircleFill size="25px" />
                      ) : (
                        <GoXCircleFill size="25px" />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              currentPage={filteredPageInActive}
              setCurrentPage={setFilteredPageInActive}
              npage={Math.ceil(
                InActiveFilteredGiftCards.length / recordsPerPage
              )}
              prePage={() =>
                prePage(filteredPageInActive, setFilteredPageInActive)
              }
              nextPage={() =>
                nextPage(
                  filteredPageInActive,
                  setFilteredPageInActive,
                  Math.ceil(InActiveFilteredGiftCards.length / recordsPerPage)
                )
              }
            />
          </Fragment>
        )
      ) : filteredGifts.filteredData.length === 0 ? (
        <Fragment>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{t("pages.gifts_cards.name")}</Th>
                <Th>{t("pages.gifts_cards.sku")}</Th>
                <Th>{t("pages.gifts_cards.category")}</Th>
                <Th>{t("pages.gifts_cards.price")}</Th>
                <Th>{t("pages.gifts_cards.active")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {recordsAll.map((giftsCard, index) => (
                <Tr
                  key={giftsCard.GiftCardsId}
                  onClick={() => navigatefunction(giftsCard.GiftCardsId)}
                  style={{ cursor: "pointer" }}
                >
                  <Td>{index + 1}</Td>
                  <Td>{giftsCard?.GiftCardsName || "-"}</Td>
                  <Td>Sk-{giftsCard.GiftCardsSUK}</Td>
                  <Td>{giftsCard.ProductCategoriesNameEng}</Td>
                  <Td>SAR {giftsCard.Price}</Td>
                  <Td className="activeIcon">
                    {giftsCard.IsActive ? (
                      <GoCheckCircleFill size="25px" />
                    ) : (
                      <GoXCircleFill size="25px" />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            currentPage={currentPageAll}
            setCurrentPage={setCurrentPageAll}
            npage={Math.ceil(giftsCards.length / recordsPerPage)}
            prePage={() => prePage(currentPageAll, setCurrentPageAll)}
            nextPage={() =>
              nextPage(
                currentPageAll,
                setCurrentPageAll,
                Math.ceil(giftsCards.length / recordsPerPage)
              )
            }
          />
        </Fragment>
      ) : (
        <Fragment>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{t("pages.gifts_cards.name")}</Th>
                <Th>{t("pages.gifts_cards.sku")}</Th>
                <Th>{t("pages.gifts_cards.category")}</Th>
                <Th>{t("pages.gifts_cards.price")}</Th>
                <Th>{t("pages.gifts_cards.active")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredRecordsAll.map((giftsCard, index) => (
                <Tr
                  key={giftsCard.GiftCardsId}
                  onClick={() => navigatefunction(giftsCard.GiftCardsId)}
                  style={{ cursor: "pointer" }}
                >
                  <Td>{index + 1}</Td>
                  <Td>{giftsCard?.GiftCardsName || "-"}</Td>
                  <Td>Sk-{giftsCard.GiftCardsSUK}</Td>
                  <Td>{giftsCard.ProductCategoriesNameEng}</Td>
                  <Td>SAR {giftsCard.Price}</Td>
                  <Td className="activeIcon">
                    {giftsCard.IsActive ? (
                      <GoCheckCircleFill size="25px" />
                    ) : (
                      <GoXCircleFill size="25px" />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            currentPage={filteredPageAll}
            setCurrentPage={setFilteredPageAll}
            npage={Math.ceil(
              filteredGifts.filteredData.length / recordsPerPage
            )}
            prePage={() => prePage(filteredPageAll, setFilteredPageAll)}
            nextPage={() =>
              nextPage(
                filteredPageAll,
                setFilteredPageAll,
                Math.ceil(filteredGifts.filteredData.length / recordsPerPage)
              )
            }
          />
        </Fragment>
      )}
    </Box>
  );
};

export default CTable;
