import { createSlice } from "@reduxjs/toolkit";
import {
  getOutOfStockForProduct,
  setOutStockForProduct,
  getBranchesInStock,
} from "../services/outOfStackService";

let initialState = {
  isLoading: false,
  data: [],
  errors: null,
  branchesInStocks: [],
};

const outOfStackSlice = createSlice({
  name: "addStock",
  initialState,
  extraReducers: (builder) => {
    /* --------------------------------------------------Get Stocks In Products ------------------------------------------------ */
    builder.addCase(getOutOfStockForProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getOutOfStockForProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getOutOfStockForProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* --------------------------------------------------Get Branches In Stack ------------------------------------------------ */
    builder.addCase(getBranchesInStock.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(getBranchesInStock.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.branchesInStocks = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(getBranchesInStock.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
/* -------------------------------------------------------------------------------------------------- */
    builder.addCase(setOutStockForProduct.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(setOutStockForProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });

    builder.addCase(setOutStockForProduct.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default outOfStackSlice.reducer;
