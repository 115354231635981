import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import DeleteModal from "./modals/DeleteModal";
import { checkIdExists } from "utils/cheackAuth";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const DeletedProduct = ({ idOfProduct }) => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const { t } = useTranslation();
  const [showDeleteBranchModal, setShowDeleteBtanchModal] = useState(null);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(showDeleteBranchModal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowDeleteBtanchModal(false)
  }

  return (
    <div>
      <Flex
        bg="light"
        borderRadius={6}
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        p={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Text fontSize=".9rem" color="text">
          {t("general.delete_message")}
        </Text>
        <GButton
          bg="red"
          color="light"
          title={t("general.delete")}
          hover={{ bg: "red" }}
          onClick={() => setShowDeleteBtanchModal(true)}
        />

        {showDeleteBranchModal && (
          <DeleteModal id={idOfProduct} onClose={() => setShowDeleteBtanchModal(null)} />
        )}
      </Flex>
    </div>
  );
};

export default DeletedProduct;
