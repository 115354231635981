import GModal from "components/ui/modal/Modal";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvItemDrop,
  addInvCountItems,
  getInvCountItems,
} from "../../services/inventoryCountService";

const AddICItemsModal = ({ onClose, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue } = useForm();

  const productionsData = useSelector((state) => state.inventoryCount);
  const InventoryCountId = id.InventoryCountId;

  useEffect(() => {
    dispatch(getInvItemDrop());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.purchases.add_items")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              if (
                values.InventoryCountItem === undefined ||
                values.InventoryCountItem.length === 0
              ) {
                toast2({ description: t("validation.choose_first") });
              } else {
                values.InventoryCountItem = values.InventoryCountItem?.map(
                  (el) => ({
                    InventoryItemId: el.InventoryItemId,
                    InventoryCountId,
                  })
                );
                values.InventoryCountItemId = null;
                dispatch(addInvCountItems(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.inventory_count.inventory_count_item_added"
                      ),
                    });
                    dispatch(getInvCountItems(id));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("general.items")}
                data={productionsData.DropData1.map((el) => ({
                  ...el,
                  label: el.InventoryItemNameEng,
                  value: el.InventoryItemId,
                }))}
                isMulti={true}
                isRequired={true}
                onChange={(e) => setValue("InventoryCountItem", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default AddICItemsModal;
