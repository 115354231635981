
import React, { useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr,} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import Pagination from "components/ui/pagination/Pagination";
import { MdSettingsBackupRestore } from "react-icons/md";
import RestoreCustomerModal from 'features/customers/components/modals/RestoreCustomerModal';


export default function DeletedDataTable({data}) {
    const { t } = useTranslation();
    const [showModel, setShowModel] = useState(false);

    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
      const recordsPerPage = 10;
      const [currentPageActive, setCurrentPageActive] = useState(1);
      const prePage = (currentPage, setCurrentPage) => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const nextPage = (currentPage, setCurrentPage, npage) => {
        if (currentPage < npage) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const calculateRecords = (data, currentPage, recordsPerPage) => {
        const firstIndex = (currentPage - 1) * recordsPerPage;
        const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
        return data?.slice(firstIndex, lastIndex);
      };
    
    // Calculate records for activeDiscounts
    const deletedData = calculateRecords(
        data,
        currentPageActive,
        recordsPerPage
      );
 /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <>
    <Table>
        <Thead>
            <Tr>
                <Th>#</Th>
                <Th>{t("pages.customers.name")}</Th>
                <Th>{t("pages.customers.phone")}</Th>
                <Th>{t("pages.customers.restore")}</Th>
            </Tr>
        </Thead>
        <Tbody>
            {
            deletedData?.map((item, index) => (
              <Tr key={item?.CustomerId}>
                  <Td>  {index+1} </Td>
                  <Td>  {item?.CustomerName} </Td>
                  <Td>  {item?.PhoneNo} </Td>
                  <Td className="activeIcon">
                      <Flex>
                        <Box
                          color="#C53030"
                          _hover={{ color: "#38A169" }}
                          p={2}
                          width={"fit-content"}
                          cursor="pointer"
                          onClick={()=>setShowModel(item?.CustomerId)}
                        >
                          <MdSettingsBackupRestore size={36} />
                        </Box>
                      </Flex>
                  </Td>
              </Tr>
            ))
            }
        </Tbody>
    </Table>
    <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
    </>
    {showModel && <RestoreCustomerModal  id={showModel} onClose={() => setShowModel(false)} />}
  </>
};

