import { createSlice } from "@reduxjs/toolkit";
import {getCustomPriceBranches,addComboSize,getCombosSize,getAllCombosSize,getAllGroups,getGroupofSize,addComboGroup,deleteGroup,addComboGroupOptions,getGroupofOptions,getGroups,getProductCompoSize} from "../services/comboSizeService"


let initialState = {
    isLoading: false,
    data: [],
    pages: 0,
    errors: null,
    allsize:[],
    allgroups:[],
    groupofSize:[],
    options:[],
    Products:[],
    customPriceBranchesData:[]
  };



  const comboSizeSlice=createSlice({

    name: "comboSize",
    initialState,
    extraReducers: (builder) => {

        builder.addCase(addComboSize.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(addComboSize.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            
            state.errors = null;
          })
          builder.addCase(addComboSize.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })
          
          //

          builder.addCase(getCombosSize.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
          builder.addCase(getCombosSize.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            //console.log(payload)
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            state.errors = null;
          })
          builder.addCase(getCombosSize.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })

          //


          
        builder.addCase(getAllCombosSize.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(getAllCombosSize.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.allsize = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          state.errors = null;
        })
        builder.addCase(getAllCombosSize.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

        //

        builder.addCase(getAllGroups.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(getAllGroups.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.allgroups = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          //console.log(state.allgroups)
          state.errors = null;
        })
        builder.addCase(getAllGroups.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

        //
        

        builder.addCase(getGroupofSize.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(getGroupofSize.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.groupofSize = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          //console.log(state.groupofSize)
          state.errors = null;
        })
        builder.addCase(getGroupofSize.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

        //


        builder.addCase(addComboGroup.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(addComboGroup.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          //state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          
          state.errors = null;
        })
        builder.addCase(addComboGroup.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

        //

        builder.addCase(deleteGroup.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(deleteGroup.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          console.log(payload)
          //state.groupofSize = state.data.filter(combo => combo.id !== payload.id);
          state.errors = null;
        })
        builder.addCase(deleteGroup.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })


        //


        builder.addCase(addComboGroupOptions.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(addComboGroupOptions.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          //state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          
          state.errors = null;
        })
        builder.addCase(addComboGroupOptions.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

        //

        builder.addCase(getGroupofOptions.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(getGroupofOptions.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.options = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
         // console.log( state.options)
          state.errors = null;
        })
        builder.addCase(getGroupofOptions.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })


        //


        builder.addCase(getProductCompoSize.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(getProductCompoSize.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.Products = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          //console.log( state.Products)
          state.errors = null;
        })
        builder.addCase(getProductCompoSize.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

        builder.addCase(getCustomPriceBranches.pending, (state) => {
          state.isLoading = true;
          state.errors = null;
        })
        builder.addCase(getCustomPriceBranches.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.customPriceBranchesData = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
          console.log(state.customPriceBranchesData)
          state.errors = null;
        })
        builder.addCase(getCustomPriceBranches.rejected, (state) => {
          state.isLoading = false;
          state.errors = [{ msg: "something went wrong!" }];
        })

    }

  })

  export default comboSizeSlice.reducer