import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DeactivateDevice, getDeviceDetails } from "../services/devicesService";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import UpdateModal from "../components/modals/UpdateModal";
import SettingsModal from "../components/modals/SettingsModal";
import DeleteDeviceCopmo from "../components/DeleteDeviceCopmo";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const DeviceDetails = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 41);


  const id = useParams();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getDeviceDetails(id.id));
  }, [dispatch, id]);

  const DeviceDetails = useSelector((state) => state.devices);

  //console.log(DeviceDetails.deviceDetaisl)

  // console.log("tony",id)
  // console.log(id.id)

  const [ShowModal, setShoModal] = useState(false);

  const [settingsModal, setSettingsModal] = useState(false);

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const activeFunction = () => {
    dispatch(DeactivateDevice(id.id))
      .unwrap()
      .then((_) => {
        toast({ description: t("pages.devices.device_created") });
        dispatch(getDeviceDetails(id.id));
      });
  };

  const data = DeviceDetails.deviceDetaisl;

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Box as="p" fontSize="1.6rem" textTransform="capitalize">
            {data[0]?.DevicesName || "-"}

            <Button
              // title=
              bg={data[0]?.DevicStatus ? "#00D1D1" : "red"}
              color="light"
              _hover={"none"}
              style={{ cursor: "default" }}
            >
              <Text>
                {data[0]?.DevicStatus
                  ? t("general.activated")
                  : t("general.inactive")}
              </Text>
            </Button>
          </Box>
          <Box>
            <GButton
              title={t("general.edit")}
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() => setShoModal(true)}
              // onClick={() => setShowUpdateComboModal({ ...combo, ComboId: +comboId || null })}
            />

            <GButton
              title={
                data[0]?.DevicStatus
                  ? t("general.deactive")
                  : t("general.active")
              }
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() => activeFunction()}
              style={{ margin: "15px" }}
            />
          </Box>
        </Flex>
      </BreadCrumb>

      <SimpleGrid
        spacing={6}
        columns={{ base: 1, md: 2 }}
        bg="white"
        p={4}
        borderRadius={6}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.devices.name")}
          </Text>
          <Text marginBlock={2}>{data[0]?.DevicesName || "-"}</Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.devices.branch")}
          </Text>
          <Text marginBlock={2}>{data[0]?.BranchName || "-"}</Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.devices.ref")}
          </Text>
          <Text marginBlock={2}>{data[0]?.DevicesRef || "-"}</Text>
        </Box>

        {ShowModal && (
          <UpdateModal
            data={data}
            onClose={() => setShoModal(false)}
          ></UpdateModal>
        )}

        {settingsModal && (
          <SettingsModal
            onClose={() => setSettingsModal(false)}
          ></SettingsModal>
        )}
      </SimpleGrid>

      <DeleteDeviceCopmo/>
    </>
  );
};

export default DeviceDetails;
