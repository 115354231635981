import { useState } from "react";
import { useTranslation } from "react-i18next"
import { Flex, Text } from "@chakra-ui/react"
import GButton from "components/ui/button/Button"
import DeleteModal from "./modals/DeleteModal";

const Delete = ({ Id }) => {
  const { t } = useTranslation();

  const [showDeleteBranchModal, setShowDeleteBtanchModal] = useState(null);

  return (
    <Flex
      bg="light" borderRadius={6} alignItems="center" justifyContent="space-between" gap={4} p={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Text fontSize=".9rem" color="text">
        if you delete this branch, all of its data will be permanently deleted.
      </Text>
      <GButton
        bg="red"
        color="light"
        title={t("general.delete")}
        hover={{ bg: "red" }}
        onClick={() => setShowDeleteBtanchModal(true)}
      />

      {showDeleteBranchModal && (
        <DeleteModal
        id={Id}
          onClose={() => setShowDeleteBtanchModal(null)}
        />
      )}
    </Flex>
  )
}

export default Delete