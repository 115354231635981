import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function DetailsTable({ data }) {
  const { t } = useTranslation();

  return (
    <>
      <SimpleGrid
        spacing={6}
        columns={{ base: 1, md: 2 }}
        bg="white"
        p={4}
        borderRadius={6}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.discounts.name")}
          </Text>
          <Text marginBlock={2}>
            {data?.DisCountName || "-"}
          </Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.discounts.name_Localized")}
          </Text>
          <Text marginBlock={2}>
            {data?.DisCountNameLoc || "-"}
          </Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.discounts.qualifications")}
          </Text>
          <Text marginBlock={2}>
            {data?.QualificationDiscountType[0]
              ?.QualificationDiscountTypeName || "-"}
          </Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.discounts.reference")}
          </Text>
          <Text marginBlock={2}>
            {data?.Reference || "-"}
          </Text>
        </Box>

        <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
            {t("pages.discounts.taxable")}
          </Text>
          <Text marginBlock={2}>
            {data?.IsTaxable === true
              ? "Yes"
              : "No" || "-"}
          </Text>
        </Box>

        {(data?.QualificationDiscountType[0]
          ?.QualificationDiscountTypeName === "Orders" ||
          data?.QualificationDiscountType[0]
            ?.QualificationDiscountTypeName === "Orders and Products") && (
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.discounts.minmium_order_price")}
            </Text>
            <Text marginBlock={2}>
              {data?.MinimumOrderPrice
                ? `${data?.MinimumOrderPrice} SAR`
                : "-"}
            </Text>
          </Box>
        )}

        {(data?.QualificationDiscountType[0]
          ?.QualificationDiscountTypeName === "Products" ||
          data?.QualificationDiscountType[0]
            ?.QualificationDiscountTypeName === "Orders and Products") && (
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.discounts.minmium_product_price")}
            </Text>
            <Text marginBlock={2}>
              {data?.MinimumProductPrice
                ? `${data?.MinimumProductPrice} SAR`
                : "-"}
            </Text>
          </Box>
        )}

        {data?.IsFixed === true && (
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.discounts.discount_amount")}
            </Text>
            <Text marginBlock={2}>
              {data?.DiscountAmount || "-"}
            </Text>
          </Box>
        )}

        {data?.IsFixed === false && (
          <>
            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text">
                {t("pages.discounts.discount_amount_percentage")}
              </Text>
              <Text marginBlock={2}>
                {data?.DiscountAmount || "-"}
              </Text>
            </Box>
            <Box borderBottom="1px solid" borderColor="border">
              <Text textTransform="capitalize" color="text">
                {t("pages.discounts.maximum_discounts")}
              </Text>
              <Text marginBlock={2}>
                {data?.MaximumDiscount
                  ? `${data?.MaximumDiscount} SAR`
                  : "-"}
              </Text>
            </Box>
          </>
        )}
      </SimpleGrid>
    </>
  );
}
