import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { getGroupById, updateGroup } from "../../services/groupsService";
import { useParams } from "react-router-dom";

const UpdateModal = ({data, onClose}) => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { handleSubmit, register, control, watch, getValues, setValue,formState: { errors }, trigger } =useForm({ defaultValues: data});



  const groups = useSelector(state => state.groups);


  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };



  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.groups.update_group")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit(values => {
            values.UserId = localStorage.getItem("userId");
            dispatch(updateGroup({ values }))
              .unwrap()
              .then(_ => {
                toast({ description: t("pages.groups.group_updated") });
                dispatch(getGroupById({ groupId }));
                onClose()
              });
          })}>
            <Stack spacing={6}>
            <FormControl isInvalid={errors.GroupName}>
                    <FormLabel
                      htmlFor="GroupName"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.groups.name")}
                    </FormLabel>
                    <Controller
                      name="GroupName"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t("validation.required"),
                        minLength: {
                          value: 2,
                          message: t("validation.name_long_2"),
                        },
                        maxLength: {
                          value: 25,
                          message: t("validation.name_long_25"),
                        },
                        pattern: {
                          value: /^[\p{L}\s]+$/u,
                          message: t("validation.this_name_invalid"),
                        },
                        validate: {
                          noOnlySpaces: (value) =>
                            !isOnlySpaces(value) || t("validation.no_only_spaces"),
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.groups.name")}
                          onChange={(e) => {
                            handleInputChange(e, "GroupName");
                          }}
                        />
                      )}
                    />
                    <FormErrorMessage>  {errors.GroupName &&   errors.GroupName.message}  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.GroupNameLoc}>
                    <FormLabel
                      htmlFor="GroupNameLoc"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.groups.name_localized")}
                    </FormLabel>
                    <Controller
                      name="GroupNameLoc"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t("validation.required"),
                        minLength: {
                          value: 2,
                          message: t("validation.name_long_2"),
                        },
                        maxLength: {
                          value: 25,
                          message: t("validation.name_long_25"),
                        },
                        pattern: {
                          value: /^[\p{L}\s]+$/u,
                          message: t("validation.this_name_invalid"),
                        },
                        validate: {
                          noOnlySpaces: (value) =>
                            !isOnlySpaces(value) || t("validation.no_only_spaces"),
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.groups.name_localized")}
                          onChange={(e) => {
                            handleInputChange(e, "GroupNameLoc");
                          }}
                        />
                      )}
                    />
                    <FormErrorMessage>  {errors.GroupNameLoc &&   errors.GroupNameLoc.message}  </FormErrorMessage>
                </FormControl>


              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={groups.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={groups.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default UpdateModal