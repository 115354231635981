import axios from "axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");
/* -------------------  Get Department Dropdown ---------------------*/
export const getHrDepartmentDropdown = createAsyncThunk(
  "leaveReports/getHrDepartmentDropdown",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      "https://rostoapi.smartgate-egypt.com/Hr/Department/LookupDepartment"
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Hr Vacation Type Dropdown ---------------------*/
export const getHrVacationTypeDropdown = createAsyncThunk(
  "leaveReports/getHrVacationTypeDropdown",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      "https://rostoapi.smartgate-egypt.com/Hr/LookupVacationType"
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Hr Leave Reports---------------------*/
export const getHrLeaveReports = createAsyncThunk(
  "leaveReports/getHrLeaveReports",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetEmployeeLeaveReport?data=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
