import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Spinner, Text,useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
import InventorySupplierTable from '../components/InventorySupplierTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSuppliers } from '../services/inventorySupplierService';
import AddInventorySuppliersModal from '../components/modals/AddInventorySuppliersModal';
import ExportModal from 'components/ui/modal/ExportModal';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";  
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const Suppliers = () => {
    const { t } = useTranslation();
    const dispatch=useDispatch();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const [showModal,setshowModal]=useState(false);
    const [exportModal,SetExportModal]=useState(false);

    const suppliers = useSelector(state => state.inventorySuppliers);

    useEffect(()=>{
      dispatch(getAllSuppliers());
    },[dispatch]);



/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()

const ManageSuppliers = checkIdExists(retrievedArray, 12);
const ReadSuppliers = checkIdExists(retrievedArray, 11);

if(showModal===true && ManageSuppliers===false){
  toast2({ description:  t("general.authorization")});
  setshowModal(false);
};
// Cant Show Any Thing
if(ManageSuppliers===false && ReadSuppliers===false ){
  return(
    <NotAuthorizedCompo/>
  )
};
/* -----------------------------------Privilige-------------------------------*/
  return (
    <div>
    <Box
    bg="light" marginInline={-6} marginTop={-6} padding={6}
    borderTop="1px solid" borderColor="border" marginBottom={6}
  >
    <Flex alignItems="center" justifyContent="space-between" gap={4}>
      <Text
        fontSize="1.2rem" color="dark" textTransform="capitalize"
      >
        {t("pages.suppliers.suppliers")}
      </Text>

      <Flex gap={2}>
      <Button
      type="button" bg="none" border="1px solid" borderColor="border"
      textTransform="capitalize" fontSize="1rem" color="text"
      _hover={{ bg: "primary", color: "light" }}
    >
 
    <div onClick={()=>SetExportModal(true)}>
        {t("general.export")}
    </div>

    </Button>
        <Button
          type="button" bg="primary" color="light"
          textTransform="capitalize" fontSize="1rem"
          onClick={() => setshowModal(true)}
        >
          <Flex alignItems="center" gap={1} >
            {t("pages.suppliers.add_supplier")}
          </Flex>
        </Button>
      </Flex>
    </Flex>
    </Box>


  {
    suppliers.isLoading?(
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    ):(
      <InventorySupplierTable  
        filteredData={suppliers.filteredData} 
        data={suppliers.data} 
        deletedData={suppliers.DeletedSuppliers}>
        </InventorySupplierTable>
    )
  }

  { showModal && (
    <AddInventorySuppliersModal onClose={() => setshowModal(false)}/>
  )}

  {
      exportModal&&(
        <ExportModal 
          onClose={() => SetExportModal(false)}   
          data={suppliers.data}
          fileName={"suppliers"}
          >
        </ExportModal>
      )
    }

    </div>
  );
}

export default Suppliers;
