import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";


 export const AddExpensesJornul = createAsyncThunk(
   "ExpensesJornul/AddExpensesJornul",
   async (args, thunkApi) => {
     try {
       const { data } = await axios.get(
         `Accountant/AddExpensesJornul?accountchart=${JSON.stringify(args)}`
       );
       console.log(data);
       return thunkApi.fulfillWithValue(data);
     } catch (e) {
       return thunkApi.rejectWithValue(e.response.data);
     }
   }
 );