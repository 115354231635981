import { createSlice } from "@reduxjs/toolkit";
import {getItemTags,getTagsOfItem} from "../services/itemTagsService"

let initialState = {
    isLoading: false,
    data: [],
    pages: 0,
    errors: null,
    itemTags:[]
  };


  const itemTagsSlice = createSlice({
    name: "itemstags",
    initialState,

    extraReducers: (builder) => {

        builder.addCase(getItemTags.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getItemTags.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.data)
            state.errors = null;
          })
      
          builder.addCase(getItemTags.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


          //


          builder.addCase(getTagsOfItem.pending, (state) => {
            state.isLoading = true;
            state.errors = null;
          })
      
          builder.addCase(getTagsOfItem.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.itemTags = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
            //console.log(state.itemTags)
            state.errors = null;
          })
      
          builder.addCase(getTagsOfItem.rejected, (state) => {
            state.isLoading = false;
            state.errors = [{ msg: "something went wrong!" }];
          })


    }
  })


  export default itemTagsSlice.reducer