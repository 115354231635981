import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getOrdersTypes = createAsyncThunk(
  "taxes/getOrdersTypes",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Setting/CallCenter/LookUpOrderTypes");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

