//test
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Link as CLink,
  Text,
  Menu,
  Avatar,
  MenuButton,
  MenuList,
  MenuItem,
  position,
} from "@chakra-ui/react";
import Input from "../../../components/form/Input";
import logo from "../../../assets/images/logo.png";
import authImg from "../../../assets/images/auth-img.jpeg";
import { login } from "../services/authService";
import routes from "../../../navigations/routes";
import * as FiIcons from "react-icons/fi";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  //console.log("auth" , auth);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const { t, i18n } = useTranslation();

    const langs = [
      { id: 1, lang: "en" },
      { id: 2, lang: "ar" },
      // { id: 3, lang: "fr" },
      // { id: 4, lang: "it" }
    ];

  const loginValidationSchema = {
    email: {
      required: t("validation.required"),
    },
    password: {
      required: t("validation.required"),
    },
  };

  return (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      <Container maxW="container.lg">
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          bg="light"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          paddingInline={8}
          paddingBlock={14}
          borderRadius={6}
          paddingTop={"100px"}
          style={{ position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              top: "15px",
              left: "15px",
              right: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              direction:"ltr",
            }}
          >
            
            <Image src={logo} alt="brand" width="120px" />

            <Menu>
              <MenuButton
                as={Button}
                p={0}
                bg="none"
                height="auto"
                _hover={{ bg: "none" }}
              >
                <Flex
                  gap={2}
                  paddingBlock={1}
                  paddingInline={2}
                  borderRadius="20px"
                  bg="#EEE8F6"
                >
                  <FiIcons.FiGlobe size={20} style={{ margin: "auto" }} />
                  <Avatar size="xs" name={i18n.language} />
                </Flex>
              </MenuButton>
              <MenuList p={2} minW="auto">
                {langs.map((lang) => (
                  <MenuItem
                    as={Button}
                    isDisabled={i18n.language === lang.lang}
                    key={lang.id}
                    textTransform="capitalize"
                    onClick={() => i18n.changeLanguage(lang.lang)}
                  >
                    {t(`general.${lang.lang}`)}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </div>
          <Box flex="1" display={{ base: "none", md: "block" }}>
            <Image
              src={authImg}
              alt="brand"
              height="100%"
              borderRadius={6}
              objectFit="contain"
            />
          </Box>
          <Box flex="1" paddingInline={{ base: 2, md: 8 }}>
            <form
              onSubmit={handleSubmit((values) => {
                dispatch(login(values))
                  .unwrap()
                  .then((_) => navigate(`/${routes.dashboard_general}`))
                  .catch((_) => console.log("something went wrong"));
              })}
            >
              <Heading
                as="h2"
                color="dark"
                fontSize="1.5rem"
                textTransform="capitalize"
                mb={2}
              >
                {t("login.loginNow")}
              </Heading>
              <Text
                mb={6}
                color="text"
                _firstLetter={{ textTransform: "capitalize" }}
                fontSize=".9rem"
              >
                {t("login.login_With")}
              </Text>

              <Stack spacing={4}>
                <Input
                  type="text"
                  name="email"
                  label={t("login.email")}
                  isRequired
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder="email@example.com"
                  isFormController
                  control={control}
                  rules={loginValidationSchema.email}
                  error={errors.email}
                />
                <Input
                  type="password"
                  name="password"
                  label={t("login.password")}
                  isRequired
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("login.password")}
                  isFormController
                  control={control}
                  rules={loginValidationSchema.password}
                  error={errors.password}
                />
                {/*  <Flex alignItems="center">
                  <Checkbox
                    size="lg"
                    textTransform="capitalize"
                    color="dark"
                    alignItems="center"
                    colorScheme="purple"
                  >
                    remember my information
                  </Checkbox>
                </Flex>
                */}
                <Button
                  type="submit"
                  height="45px"
                  bg="primary"
                  color="light"
                  textTransform="capitalize"
                  fontSize="1rem"
                  border="1px solid transparent"
                  _hover={{
                    bg: "none",
                    color: "primary",
                    borderColor: "primary",
                  }}
                  isLoading={auth.isLoading}
                >
                  {t("login.Login")}
                </Button>
                {/* <Flex
                  alignItems="center"
                  color="dark"
                  textTransform="capitalize"
                >
                  forget your password?
                  <CLink to={`/signup`} color="primary" ms={2}>
                    reset now
                  </CLink>
                </Flex>
                <Flex
                  alignItems="center" color="dark" textTransform="capitalize"
                >
                  you can signup from here?
                  <CLink onClick={()=>navigate("/signup")} color="primary" ms={2}>sign up</CLink>
                </Flex> */}
              </Stack>
            </form>
          </Box>
        </SimpleGrid>
      </Container>
    </Flex>
  );
};

export default Login;
