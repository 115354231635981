import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteModifier, getDeleted, getModifiers } from "../../services/modifiersService";

const DeleteModal = ({
  data,
  onClose
}) => {
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const dispatch = useDispatch();
  const modifiers = useSelector(state => state.modifiers);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteModifier({ values: data }))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.modifiers.modifier_deleted") });
            dispatch(getModifiers());
            dispatch(getDeleted());
            onClose();
          })
      }}
      isLoading={modifiers.isLoading}
    />
  )
}

export default DeleteModal