import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";
import NoData from "components/ui/noData/NoData";
import NoDataFound from "components/ui/noDataFound/NoDataFound";
//

const EmployeesTabel = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigatefunction = (employeeId) => {
    navigate(`/HREmployeesPage/${employeeId}`);
  };

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const employeeData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  if (data?.length === 0) {
    return <NoDataFound />;
  } else {
    return (
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <GButton
              title={t("general.all")}
              bg="none"
              isDisabled={false}
              borderRadius={0}
              borderColor="primary"
            />
          </Flex>
          {/* 
          <GButton
            title={t("general.filter")}
            border="1px solid"
            borderColor="border"
            postfixElement={<FiIcons.FiFilter size={16} />}
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            //onClick={() => setShowFilterCategoriesModal(true)}
          /> */}
        </Flex>

        <Table>
          <Thead>
            <Tr>
              <Th>{t("pages.hr.picture")}</Th>
              <Th>{t("pages.hr.Employee_code")}</Th>
              <Th>{t("pages.hr.Employee_name")}</Th>
              <Th>{t("pages.hr.job_title")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {employeeData?.map((item, index) => (
              <Tr
                key={index}
                onClick={() => navigatefunction(item?.EmployeeId)}
                cursor={"pointer"}
              >
                <Td style={{ textAlign: "center" }}>
                  <img
                    src="https://tse4.mm.bing.net/th?id=OIP.tGZ5kxcOjgqCQz8twG27mQHaFj&pid=Api&P=0&h=220"
                    alt="p"
                    style={{ width: "80px", display: "block", margin: "auto" }}
                  ></img>
                </Td>
                <Td> {item?.EmployeeCode} </Td>
                <Td> {item?.FullArName} </Td>
                <Td> {item?.Title} </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(data?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(data?.length / recordsPerPage)
            )
          }
        />
      </Box>
    );
  }
};

export default EmployeesTabel;
