import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

    /* -------------------------------------------------- Get All Charges ----------------------------------------------------- */
export const getCharges = createAsyncThunk("charges/getCharges",async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Charge/ChargeList");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Create New Charge ----------------------------------------------------- */
export const createCharge = createAsyncThunk( "charges/createCharge",  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Charge/AddEditCharge?charge=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Update Charge ----------------------------------------------------- */
export const updateCharge = createAsyncThunk("charges/updateCharge", async (args, thunkApi) => {
    try {
      const { data } = await axios.post(`/Manage/Charge/AddEditCharge?charge=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Delete Charge ----------------------------------------------------- */
export const deleteCharge = createAsyncThunk("charges/deleteCharge",  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/Charge/ChargeById?ChargeId={"ChargeId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getChargesFilteredData = createAsyncThunk("charges/getChargesFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetChargesFilteredData = createAsyncThunk( "charges/resetChargesFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
