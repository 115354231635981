import React from "react";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeleteItemFromPurchaseOrder, getPurchaseOrdersList } from "../../services/purchasrOrderServices";

export default function DeleteItemPurchaseOrderModal({ onClose, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  return (
    <>
      <GDeleteModal
        onClose={onClose}
        onSubmit={() => {
          dispatch(DeleteItemFromPurchaseOrder(id))
            .unwrap()
            .then((_) => {
              toast({ description: t("pages.purchase_orders.purchase_orders_deleted") });
              dispatch(getPurchaseOrdersList());
              onClose();
              navigate(`/inventory/purchase_orders/`);
            });
        }}
      />
    </>
  );
}
