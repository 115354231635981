import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Checkbox,
  Flex,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";

export default function ViewVacationModal({ onClose, item, classifications }) {
  const { t } = useTranslation();
  const dateFromStr = item?.FromDate;
  const dateToStr = item?.ToDate;
  const [day, month, year] = dateFromStr.split("-");
  const [day1, month1, year1] = dateToStr.split("-");

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.view_vacation")}
        modalContent={
          <Box p={4}>
            <Stack spacing={6}>
              <Box>
                <Checkbox
                  fontWeight={"semibold"}
                  color="blue.500"
                  colorScheme="green"
                  isChecked={item?.ForSpecificClassification}
                >
                  {t("pages.hr.ForSpecificClassification")}
                </Checkbox>
                {item?.ForSpecificClassification && (
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    defaultValue={
                      classifications[item.EmployeeClassificationId - 1]
                        ?.EmployeeClassificationNameEn
                    }
                  />
                )}
              </Box>
              <Box>
                <Text fontWeight={"semibold"} color="blue.500">
                  {t("pages.hr.OfficialLeaveCause")}
                </Text>
                <Textarea
                  placeholder="Here is a sample placeholder"
                  marginTop={"2"}
                  defaultValue={item?.OfficialVacationCause}
                />
              </Box>

              <Box>
                <Text fontWeight={"semibold"} color="blue.500">
                  {t("pages.hr.from_date")}
                </Text>
                <Input
                  size="md"
                  type="date"
                  marginTop={"2"}
                  defaultValue={`${year}-${month}-${day}`}
                />
              </Box>
              <Box>
                <Text fontWeight={"semibold"} color="blue.500">
                  {t("pages.hr.to_date")}
                </Text>
                <Input
                  size="md"
                  type="date"
                  marginTop={"2"}
                  defaultValue={`${year1}-${month1}-${day1}`}
                />
              </Box>

              <Checkbox colorScheme="green" isChecked={item?.IsAcademicLeave}>
                {t("pages.hr.IsAcademicLeave")}
              </Checkbox>

              <Checkbox
                colorScheme="green"
                isChecked={item?.IsNonAcademicLeave}
              >
                {t("pages.hr.IsNonAcademicLeave")}
              </Checkbox>

              <Flex gap={4} justifyContent={"flex-end"}>
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </Box>
        }
      ></GModal>
    </>
  );
}
