import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Stack } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  getFilteredData,
  getQualificationsDropdown,
} from "../../services/discountsService";

const FilterModal = ({ onClose, data }) => {
  console.log("al data", data);

  const [filterCriteria, setFilterCriteria] = useState({
    DisCountName: "",
    DisCountNameLoc: "",
    Reference: "",
    QualificationDiscountTypeName: "",
  });

  const [filteredData, setFilteredData] = useState(data);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleFilterProducts = (e) => {
    e.preventDefault();

    const filteredResult = data.filter((item) => {
      const {
        DisCountName,
        DisCountNameLoc,
        Reference,
        QualificationDiscountTypeName,
      } = filterCriteria;

      console.log(item?.DisCountNameLoc, "-----", DisCountNameLoc);

      // Create variables to hold filter conditions
      let filterByName = true;
      let filterByNameLoc = true;
      let filterBySKU = true;
      let filterByQualificationDiscountTypeName = true;

      // Check if the filter criteria are defined, and if so, apply the filter
      if (DisCountName) {
        filterByName = item?.DisCountName?.toLowerCase().includes(
          DisCountName.toLowerCase()
        );
      }

      if (DisCountNameLoc) {
        filterByNameLoc = item?.DisCountNameLoc?.toLowerCase().includes(
          DisCountNameLoc.toLowerCase()
        );
      }

      if (Reference) {
        filterBySKU = String(item.Reference).trim().includes(Reference);
      }

      if (QualificationDiscountTypeName) {
        filterByQualificationDiscountTypeName =
          item?.QualificationDiscountType[0]?.QualificationDiscountTypeName?.toLowerCase() ===
          QualificationDiscountTypeName.toLowerCase();
      }

      // Return true if all conditions are met
      return (
        filterByName &&
        filterByNameLoc &&
        filterBySKU &&
        filterByQualificationDiscountTypeName
      );
    });

    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredData(filteredResult));
  };

  useEffect(() => {
    dispatch(getQualificationsDropdown());
  }, [dispatch]);

  const discounts = useSelector((state) => state.discounts);

  const handleClose = () => {
    onClose(filteredData);
  };

  return (
    <GModal
      onClose={handleClose}
      modalHeader={t("pages.discounts.filter_discounts")}
      modalContent={
        <Box p={4}>
          <form onSubmit={handleFilterProducts}>
            <Stack spacing={6}>
              <Input
                label={t("pages.discounts.name")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.discounts.name")}
                name="DisCountName"
                value={filterCriteria.DisCountName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    DisCountName: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.discounts.name_localized")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.discounts.name_localized")}
                name="DisCountNameLoc"
                value={filterCriteria.DisCountNameLoc}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    DisCountNameLoc: e.target.value,
                  })
                }
              />

              <Input
                label={t("pages.products.sku")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.products.sku")}
                name="Ref"
                value={filterCriteria.Reference}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    Reference: e.target.value,
                  })
                }
              />

              <MultiSelect
                title={t("pages.discounts.qualifications")}
                data={
                  discounts.QualificationsDropdown &&
                  discounts.QualificationsDropdown.map((el) => ({
                    ...el,
                    label: el.QualificationDiscountTypeName,
                    value: el.QualificationDiscountTypeId,
                  }))
                }
                isMulti={false}
                value={filterCriteria.QualificationDiscountTypeName}
                onChange={(e) =>
                  setFilterCriteria({
                    ...filterCriteria,
                    QualificationDiscountTypeName:
                      e.QualificationDiscountTypeName,
                  })
                }
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={handleClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default FilterModal;
