import React from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react"
import Input from "components/form/Input"
import GButton from "components/ui/button/Button"
import MultiSelect from "components/ui/multiSelect/MultiSelect"

const AddModal = ({onClose,showModal}) => {

  console.log(showModal)
    const { t } = useTranslation();

    const inventoryCatData=[]

    const { handleSubmit, control, getValues, watch, setValue, register } = useForm();

  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.general_accounts.add")}
    modalContent={(

        <Box p={4}>
        
        <form>
        
        <Stack spacing={6}>
        

        <Input
        label={t("pages.general_accounts.add")}
        labelStyle={{ textTransform: "capitalize" }}
        placeholder={t("pages.general_accounts.add")}
        name="InventoryItemNameEng"
        isFormController
        isRequired
        control={control}
      />


              <Flex gap={4}>
              <GButton
                type="submit"
                title={t("general.save")}
                bg="primary"
                color="light"
                border="1px solid transparent"
                //isLoading={categories.isLoading}
                hover={{ color: "primary", borderColor: "primary", bg: "none" }}
              />
              <GButton
                title={t("general.close")}
                onClick={onClose}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                //isLoading={categories.isLoading}
                hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
              />
            </Flex>
        
        </Stack>
        
        
        </form>
        

        </Box>
    )}
    
    />
  );
}

export default AddModal;
