import React, { useEffect } from "react";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createQuantityAdjasment,
  getAllQuantityAdjusment,
  getBranchesForQuantityAdjusment,
  getReasonForQuantityAdjusment,
} from "../../services/quantityAdjustmentService";

const CreateQuantityAdjasmentModal = ({ onClose }) => {
  const { handleSubmit, setValue } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const data = useSelector((state) => state.QuantityAdjustment);

  useEffect(() => {
    dispatch(getBranchesForQuantityAdjusment());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getReasonForQuantityAdjusment());
  }, [dispatch]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.quantity_adjustment.new_quantity_adjustment")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");
              if (
                values.BranchId === undefined ||
                values.ReasonId === undefined
              ) {
                toast2({ description: t("validation.choose_first") });
              } else {
                values.BranchId = values.BranchId.BranchId;
                values.ReasonId = values.ReasonId.ReasonId;
                values.QuantityAdjustmentId = null;
                dispatch(createQuantityAdjasment(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.quantity_adjustment.quantity_adjustment_created"
                      ),
                    });
                    dispatch(getAllQuantityAdjusment());
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
              <MultiSelect
                title={t("pages.quantity_adjustment.branch")}
                data={
                  data.branches &&
                  data.branches.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("BranchId", e)}
              />

              <MultiSelect
                title={t("pages.quantity_adjustment.reason")}
                data={
                  data.Reasons &&
                  data.Reasons.map((el) => ({
                    ...el,
                    label: el.ReasonNameEng,
                    value: el.ReasonId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("ReasonId", e)}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  //isLoading={categories.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  //isLoading={categories.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default CreateQuantityAdjasmentModal;
