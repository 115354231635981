import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Get Tills Report List ------------------------------- */
  export const getTillsReportList = createAsyncThunk("tillsReport/getTillsReportList",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetTillsReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
 /* --------------------------------------- TillsFilter Data ------------------------------*/
 export const getTillsReportFilteredData = createAsyncThunk("tillsReport/getTillsReportFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetTillsReportFilteredData = createAsyncThunk( "tillsReport/resetTillsReportFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);