export function checkAccountNumber(objectsArray) {
  for (let i = 0; i < objectsArray.length; i++) {
    if (
      !objectsArray[i].hasOwnProperty("AccountNumber") ||
      objectsArray[i].AccountNumber === undefined ||
      objectsArray[i].AccountNumber === ""
    ) {
      return true; // If any object doesn't have AccountNumber or it's empty, return true
    }
  }
  return false; // If all objects have AccountNumber, return false
}
