import React, { useEffect, useState } from 'react';
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQuantityAdjustmentDetails } from '../services/quantityAdjustmentService';
import QuantityAdjustmentAddtem from '../components/QuantityAdjustmentAddtem';
import EditModal from '../components/modal/EditModal';
import DeleteQuantityAdjustment from '../components/DeleteQuantityAdjustment';
import SubmitingModal from '../components/modal/SubmitingModal';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 

const DetailsPageQuantityAdjustmen = () => {
    const { t } = useTranslation();
    const id =useParams();
    const dispatch =useDispatch();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const dataDetails = useSelector(state => state.QuantityAdjustment);

    const [showEditModal,setShowEditModal]=useState(false);
    const [showSubmitModal,setShowSubmitModal]=useState(false);

    useEffect(()=>{
        dispatch(getQuantityAdjustmentDetails(id.id))
    },[dispatch,id])

/* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageQuantityAdjustments = checkIdExists(retrievedArray, 28);

if(showEditModal===true && ManageQuantityAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowEditModal(false);
};
if(showSubmitModal===true && ManageQuantityAdjustments===false){
  toast2({ description:  t("general.authorization")});
  setShowSubmitModal(false);
};
/* -----------------------------------Privilige-------------------------------*/

  return (
    <>
    <BreadCrumb>
    <Flex justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">
      <Box
        as="p" fontSize="1.6rem" textTransform="capitalize"
        style={{display:"flex",alignItems:"center",justifyContent:"center"}}
      >
          {dataDetails.details[0]?.BranchName|| "-"} 

          <Box
              bg={"#718096"}
              display={"inline-block"}
              marginX={"5px"}
              borderRadius={"5px"}
              color={"white"}
              fontSize={"15px"}
              padding={" 2px 10px"}
            >
            {dataDetails.details[0]?.QuantityAdjustmentStatus|| "-"}             
          </Box>

      </Box>
   
      {
          dataDetails.details[0]?.QuantityAdjustmentStatus === "Closed" ?  ""   :
            <Flex justifyContent={"space-between"} alignItems={"center"} gap={"10px"}>                  
              <GButton
                    title={t("general.edit")}
                    bg="light"
                    color="text"
                    border="1px"
                    borderColor={"#CBD5E0"}
                    opacity="0.7"
                    onClick={() => setShowEditModal(true)}
                  />

              <GButton
                  title={t("pages.quantity_adjustment.submit_adjustment")}
                  bg="#54A0FF"
                  color="light"
                  hover={{ bg: "primary" }}
                  onClick={() => setShowSubmitModal(true)}
                />           
            </Flex>
      }
      
    </Flex>
  </BreadCrumb>

  <Stack spacing={12}>
  {/* details */}
  { dataDetails.isLoading ?
    <Flex bg="light" p={4} justifyContent="center">
      <Spinner />
    </Flex>
    :
    <SimpleGrid
      spacing={6}
      columns={{ base: 1, md: 2 }}
      bg="white"
      p={4} borderRadius={6}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Box borderBottom="1px solid" borderColor="border">
        <Text textTransform="capitalize" color="text">
        {t("pages.quantity_adjustment.branch")}
        </Text>
        <Text marginBlock={2}>
        {dataDetails.details[0]?.BranchName|| "-"} 
        </Text>
      </Box>
      <Box borderBottom="1px solid" borderColor="border">
        <Text textTransform="capitalize" color="text">
        {t("pages.quantity_adjustment.reason")}
        </Text>
        <Text marginBlock={2}>
        {dataDetails.details[0]?.ReasonNameEng|| "-"} 
        </Text>
      </Box>

      <Box borderBottom="1px solid" borderColor="border">
        <Text textTransform="capitalize" color="text">
        {t("pages.quantity_adjustment.status")}
        </Text>
        <Text marginBlock={2}>
        {dataDetails.details[0]?.QuantityAdjustmentStatus|| "-"} 
        </Text>
      </Box>

      <Box borderBottom="1px solid" borderColor="border">
        <Text textTransform="capitalize" color="text">
        {t("pages.quantity_adjustment.Business_Date")}
        </Text>
        <Text marginBlock={2}>
          {dataDetails.details[0]?.BusinessDate|| "-"}  
        </Text>
      </Box>


      <Box borderBottom="1px solid" borderColor="border">
          <Text textTransform="capitalize" color="text">
              {t("pages.quantity_adjustment.Created")}
          </Text>
          <Text marginBlock={2}>
          
          {dataDetails.details[0]?.CreatedDate|| "-"} 
          </Text>
      </Box>

    
    <Box borderBottom="1px solid" borderColor="border">
    <Text textTransform="capitalize" color="text">
    {t("pages.quantity_adjustment.creator")}
    </Text>
    <Text marginBlock={2}>

    
    {dataDetails.details[0]?.Creator|| "-"} 
    </Text>
  </Box>

    </SimpleGrid>
  }

  <QuantityAdjustmentAddtem   quantityDetailsStatus={dataDetails.details[0]?.QuantityAdjustmentStatus} />

  {
    dataDetails.details[0]?.QuantityAdjustmentStatus === "Closed"  ? 
    "" 
    : 
    <DeleteQuantityAdjustment />
  }
</Stack>


{
  showEditModal&&(
    <EditModal id={id.id} onClose={() => setShowEditModal(false)}></EditModal>
  )
}

{
  showSubmitModal&&
  (
    <SubmitingModal onClose={() => setShowSubmitModal(false)}/>
  )
}



</>
  );
}

export default DetailsPageQuantityAdjustmen;
