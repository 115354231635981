import { Box, Stack,Input as ChInput, Flex  } from '@chakra-ui/react';
import GButton from 'components/ui/button/Button';
import GModal from 'components/ui/modal/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next';

const KitchennNoteModal = ({onClose,setKitchetNotesData}) => {
    const { t } = useTranslation();

  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.orders.receipt_notes")}
    modalContent={
      <Box p={4}>
      <Stack>
      <ChInput     
          placeholder={t("pages.orders.receipt_notes")}
          onChange={(e)=>setKitchetNotesData(e.target.value)}
      />
      <Flex gap={4}>
    
      <GButton
        title={t("general.close")}
        onClick={onClose}
        bg="red.600"
        border="1px solid transparent"
        color="light"
        hover={{
          color: "red.600",
          borderColor: "red.600",
          bg: "none",
        }}
      />
    </Flex>
      </Stack>
  
      </Box>
    }
    />
  )
}

export default KitchennNoteModal
