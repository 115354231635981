import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({ groups }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const navigatefunction = (id) => {
    navigate(`${id}`);
  };

  const filteredGroups = useSelector((state) => state.groups);

    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
    const recordsPerPage = 10;
    const [currentPageActive, setCurrentPageActive] = useState(1);
    const prePage = (currentPage, setCurrentPage) => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const nextPage = (currentPage, setCurrentPage, npage) => {
      if (currentPage < npage) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const calculateRecords = (data, currentPage, recordsPerPage) => {
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
      return data.slice(firstIndex, lastIndex);
    };
  
      // Calculate records for activeDiscounts
      const groupsData = calculateRecords(
        groups,
        currentPageActive,
        recordsPerPage
      );
      const groupsFilteredData = calculateRecords(
        filteredGroups?.filteredData,
        currentPageActive,
        recordsPerPage
      );
    /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.groups.name")}</Th>
            <Th>{t("pages.categories.created")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredGroups?.filteredData?.length === 0
            ? groupsData.map((group, index) => (
                <Tr
                  key={group.MenuGroupId}
                  onClick={() => navigatefunction(group.MenuGroupId)}
                  style={{ cursor: "pointer" }}
                >
                  <Td>{index + 1}</Td>
                  <Td>{group.GroupName}</Td>
                  <Td>{group.UserDate}</Td>
                </Tr>
              ))
            : groupsFilteredData?.map((group, index) => (
                <Tr
                  key={group.MenuGroupId}
                  onClick={() => navigatefunction(group.MenuGroupId)}
                  style={{ cursor: "pointer" }}
                >
                  <Td>{index + 1}</Td>
                  <Td>{group.GroupName}</Td>
                  <Td>{group.UserDate}</Td>
                </Tr>
              ))}
        </Tbody>
      </Table>

      { filteredGroups?.filteredData?.length === 0 ?
        <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(groups.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(groups.length / recordsPerPage)
                )
              }
        />
      :
        <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(filteredGroups?.filteredData?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(filteredGroups?.filteredData?.length / recordsPerPage)
                )
              }
        />
      }

    </Box>
  );
};

export default CTable;
