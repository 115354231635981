import { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Icon,
  List,
  ListItem,
  Text,
  Stack,
  Input as ChInput,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { Button, Flex, Spinner, SimpleGrid, Checkbox } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import AddModal from "../components/modal/AddModal";
import { AiFillFolderOpen } from "react-icons/ai";
import { FcFactoryBreakdown } from "react-icons/fc";
import Input from "components/form/Input";
import { useForm, Controller } from "react-hook-form";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getByIdTree } from "../../service/accountsTreeService";
import {
  GetAccountTypeNew,
  GetFinancialPeriodsList,
  getAcoountsTree,
  createAccountTreeNode,
} from "../service/AccountingTreeService";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import { checkIdExists } from "utils/cheackAuth";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const AccountingPage = () => {
  const dispatch = useDispatch();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  useEffect(() => {
    dispatch(getAcoountsTree());
  }, [dispatch]);

  const data = useSelector((state) => state.AccountTreeNew);

  useEffect(() => {
    dispatch(GetFinancialPeriodsList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetAccountTypeNew());
  }, [dispatch]);

  const filteredHeader = [];
  const seenAccParents = [];

  data.data.forEach((el) => {
    if (el.AccParent.length === 2 && !seenAccParents.includes(el.AccParent)) {
      filteredHeader.push(el);
      seenAccParents.push(el.AccParent);
    }
  });

  const clickedNode = (id) => {};

  function findHighestAccountLevel() {
    let highestAccountLevel = -1; // Initialize with a value lower than any possible AccountLevel

    data.data.forEach((obj) => {
      if (obj.AccountLevel > highestAccountLevel) {
        highestAccountLevel = obj.AccountLevel;
      }
    });

    return highestAccountLevel;
  }

  const [addDisabeled, setaddDisabeled] = useState(true);

  const [AccountLevel, setAccountLevel] = useState("");
  const [ParentAcntNum, setParentAcntNum] = useState("");

  const dataForAddNode = (el, level) => {
    toast({
      description:
        t("pages.general_accounts.addNow") +
        " " +
        (el.AccParentName || el.AccDetailName),
    });
    console.log(el);
    if (el.AccDetail === undefined) {
      setParentAcntNum(el.AccParent);
    } else if (el.AccDetail) {
      setParentAcntNum(el.AccDetail);
    }

    setAccountLevel(el.AccountLevel);
  };

  const TreeNode = ({ label, children, icon, el, number, level }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };

    const addNewNode = (el) => {
      //setShowModal(el)
      //console.log(el)
    };

    return (
      <ListItem>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={() => {
            handleToggle();
          }}
        >
          <Icon as={isOpen ? ChevronRightIcon : icon} boxSize={4} m={3} />
          <div style={{ display: "inline-flex", gap: "15px" }}>
            <Text>{number}</Text>
            <Text style={{ minWidth: "100px" }}>{label}</Text>
          </div>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              addNewNode(el);
              setaddDisabeled(false);
              dataForAddNode(el, level);
            }}
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              width: "20px",
              textAlign: "center",
              borderRadius: "50%",
            }}
          >
            +
          </Box>
        </Box>
        <Collapse in={isOpen} animateOpacity>
          <List ml={4} mr={4}>
            {children}
          </List>
        </Collapse>
      </ListItem>
    );
  };

  const { t, i18n } = useTranslation();

  // console.log( i18n.language)

  const [showModal, setShowModal] = useState(false);

  const { handleSubmit, control, getValues, watch, setValue, register, reset } =
    useForm();

  const x = 1;
  const inventoryCatData = [];

  /* ------------------------------------- Authrzation ------------------------------ */
  const retrievedArray = useDecryptedPrivilege();
  const ManageAccountant_AccountsTree = checkIdExists(retrievedArray, 1018);
  if (ManageAccountant_AccountsTree === false) {
    return <NotAuthorizedCompo />;
  }

  return (
    <>
      {data.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Box
            bg="light"
            marginInline={-6}
            marginTop={-6}
            padding={6}
            borderTop="1px solid"
            borderColor="border"
            marginBottom={6}
          >
            <Flex alignItems="center" justifyContent="space-between" gap={4}>
              <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
                {t("pages.general_accounts.Accounts_tree")}
              </Text>

              <Flex gap={2}>
                {/* 
                  <Button
                    type="button" bg="primary" color="light"
                    textTransform="capitalize" fontSize="1rem"
                    onClick={() => setShowModal(true)}
                  >
                    <Flex alignItems="center" gap={1} >
                      {t("pages.general_accounts.add")}
                    </Flex>
                  </Button>*/}
              </Flex>
            </Flex>
          </Box>

          <SimpleGrid
            spacing={1}
            columns={{ base: 1, md: 2 }}
            bg="white"
            p={4}
            borderRadius={6}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <List>
              {filteredHeader.map((el, index) => (
                <div
                  key={index}
                  onClick={() => clickedNode(el)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TreeNode
                    label={el.AccParentName}
                    icon={AiFillFolderOpen}
                    el={el}
                    number={el.AccParent}
                    level={el}
                  >
                    {data.data.map((j) => {
                      if (el.AccParent === j.AccParent) {
                        //console.log(j);
                        //console.log("j",j)
                        return j.detailAccount.map((x) => (
                          <div
                            key={x.someUniqueKey}
                            onClick={() => clickedNode(j)}
                          >
                            <TreeNode
                              label={x.AccDetailName}
                              icon={AiFillFolderOpen}
                              number={x.AccDetail}
                              el={x}
                              level={j}
                            >
                              {
                                //console.log(x)
                                data.data.map((y) => {
                                  if (x.AccDetail === y.AccParent) {
                                    //console.log(y)
                                    return y.detailAccount.map((z) => {
                                      //console.log(z)
                                      return (
                                        <div
                                          key={z.someUniqueKey}
                                          onClick={() => clickedNode(z)}
                                        >
                                          <TreeNode
                                            label={z.AccDetailName}
                                            icon={AiFillFolderOpen}
                                            number={z.AccDetail}
                                            el={z}
                                            level={y}
                                          >
                                            {
                                              // //console.log(data.data)
                                              // console.log(z)
                                              data.data.map((y2) => {
                                                if (
                                                  z.AccDetail === y2.AccParent
                                                ) {
                                                  //console.log(y2)
                                                  return y2.detailAccount.map(
                                                    (z2) => {
                                                      return (
                                                        <div
                                                          key={z2.someUniqueKey}
                                                          onClick={() =>
                                                            clickedNode(z2)
                                                          }
                                                        >
                                                          <TreeNode
                                                            label={
                                                              z2.AccDetailName
                                                            }
                                                            icon={
                                                              AiFillFolderOpen
                                                            }
                                                            number={
                                                              z2.AccDetail
                                                            }
                                                            el={z2}
                                                            level={y2}
                                                          >
                                                            {data.data.map(
                                                              (y3) => {
                                                                if (
                                                                  z2.AccDetail ===
                                                                  y3.AccParent
                                                                ) {
                                                                  return y3.detailAccount.map(
                                                                    (z3) => {
                                                                      return (
                                                                        <div
                                                                          key={
                                                                            z3.someUniqueKey
                                                                          }
                                                                          onClick={() =>
                                                                            clickedNode(
                                                                              z3
                                                                            )
                                                                          }
                                                                        >
                                                                          <TreeNode
                                                                            label={
                                                                              z3.AccDetailName
                                                                            }
                                                                            icon={
                                                                              AiFillFolderOpen
                                                                            }
                                                                            number={
                                                                              z3.AccParent
                                                                            }
                                                                            el={
                                                                              z3
                                                                            }
                                                                            level={
                                                                              y3
                                                                            }
                                                                          >
                                                                            {data.data.map(
                                                                              (
                                                                                y4
                                                                              ) => {
                                                                                if (
                                                                                  z3.AccDetail ===
                                                                                  y4.AccParent
                                                                                ) {
                                                                                  return y4.detailAccount.map(
                                                                                    (
                                                                                      z4
                                                                                    ) => {
                                                                                      return (
                                                                                        <div
                                                                                          key={
                                                                                            z4.someUniqueKey
                                                                                          }
                                                                                          onClick={() =>
                                                                                            clickedNode(
                                                                                              z4
                                                                                            )
                                                                                          }
                                                                                        >
                                                                                          <TreeNode
                                                                                            label={
                                                                                              z4.AccDetailName
                                                                                            }
                                                                                            icon={
                                                                                              AiFillFolderOpen
                                                                                            }
                                                                                            number={
                                                                                              z4.AccParent
                                                                                            }
                                                                                            el={
                                                                                              z4
                                                                                            }
                                                                                            level={
                                                                                              y4
                                                                                            }
                                                                                          >
                                                                                            {data.data.map(
                                                                                              (
                                                                                                y5
                                                                                              ) => {
                                                                                                if (
                                                                                                  z4.AccDetail ===
                                                                                                  y5.AccParent
                                                                                                ) {
                                                                                                  return y5.detailAccount.map(
                                                                                                    (
                                                                                                      z5
                                                                                                    ) => {
                                                                                                      return (
                                                                                                        <div
                                                                                                          key={
                                                                                                            z5.someUniqueKey
                                                                                                          }
                                                                                                          onClick={() =>
                                                                                                            clickedNode(
                                                                                                              z5
                                                                                                            )
                                                                                                          }
                                                                                                        >
                                                                                                          <TreeNode
                                                                                                            label={
                                                                                                              z5.AccDetailName
                                                                                                            }
                                                                                                            icon={
                                                                                                              AiFillFolderOpen
                                                                                                            }
                                                                                                            el={
                                                                                                              z5
                                                                                                            }
                                                                                                            number={
                                                                                                              z5.AccParent
                                                                                                            }
                                                                                                            level={
                                                                                                              y5
                                                                                                            }
                                                                                                          >
                                                                                                            {data.data.map(
                                                                                                              (
                                                                                                                y6
                                                                                                              ) => {
                                                                                                                if (
                                                                                                                  z5.AccDetail ===
                                                                                                                  y6.AccParent
                                                                                                                ) {
                                                                                                                  return y6.detailAccount.map(
                                                                                                                    (
                                                                                                                      z6
                                                                                                                    ) => {
                                                                                                                      return (
                                                                                                                        <div
                                                                                                                          key={
                                                                                                                            z6.someUniqueKey
                                                                                                                          }
                                                                                                                          onClick={() =>
                                                                                                                            clickedNode(
                                                                                                                              z6
                                                                                                                            )
                                                                                                                          }
                                                                                                                        >
                                                                                                                          <TreeNode
                                                                                                                            label={
                                                                                                                              z6.AccDetailName
                                                                                                                            }
                                                                                                                            icon={
                                                                                                                              AiFillFolderOpen
                                                                                                                            }
                                                                                                                            el={
                                                                                                                              z6
                                                                                                                            }
                                                                                                                            number={
                                                                                                                              z6.AccParent
                                                                                                                            }
                                                                                                                            level={
                                                                                                                              y6
                                                                                                                            }
                                                                                                                          >
                                                                                                                            {data.data.map(
                                                                                                                              (
                                                                                                                                y7
                                                                                                                              ) => {
                                                                                                                                if (
                                                                                                                                  z6.AccDetail ===
                                                                                                                                  y6.AccParent
                                                                                                                                ) {
                                                                                                                                  return y7.detailAccount.map(
                                                                                                                                    (
                                                                                                                                      z7
                                                                                                                                    ) => {
                                                                                                                                      return (
                                                                                                                                        <div
                                                                                                                                          key={
                                                                                                                                            z7.someUniqueKey
                                                                                                                                          }
                                                                                                                                          onClick={() =>
                                                                                                                                            clickedNode(
                                                                                                                                              z7
                                                                                                                                            )
                                                                                                                                          }
                                                                                                                                        >
                                                                                                                                          <TreeNode
                                                                                                                                            label={
                                                                                                                                              z7.AccDetailName
                                                                                                                                            }
                                                                                                                                            icon={
                                                                                                                                              AiFillFolderOpen
                                                                                                                                            }
                                                                                                                                            el={
                                                                                                                                              z7
                                                                                                                                            }
                                                                                                                                            number={
                                                                                                                                              z7.AccParent
                                                                                                                                            }
                                                                                                                                            level={
                                                                                                                                              y7
                                                                                                                                            }
                                                                                                                                          ></TreeNode>
                                                                                                                                        </div>
                                                                                                                                      );
                                                                                                                                    }
                                                                                                                                  );
                                                                                                                                }
                                                                                                                              }
                                                                                                                            )}
                                                                                                                          </TreeNode>
                                                                                                                        </div>
                                                                                                                      );
                                                                                                                    }
                                                                                                                  );
                                                                                                                }
                                                                                                              }
                                                                                                            )}
                                                                                                          </TreeNode>
                                                                                                        </div>
                                                                                                      );
                                                                                                    }
                                                                                                  );
                                                                                                }
                                                                                              }
                                                                                            )}
                                                                                          </TreeNode>
                                                                                        </div>
                                                                                      );
                                                                                    }
                                                                                  );
                                                                                }
                                                                              }
                                                                            )}
                                                                          </TreeNode>
                                                                        </div>
                                                                      );
                                                                    }
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </TreeNode>
                                                        </div>
                                                      );
                                                    }
                                                  );
                                                }
                                              })
                                            }
                                          </TreeNode>
                                        </div>
                                      );
                                    });
                                  }
                                })
                              }
                            </TreeNode>
                          </div>
                        ));
                      }
                      return null;
                    })}
                  </TreeNode>
                </div>
              ))}
            </List>

            <div>
              <form
                onSubmit={handleSubmit((values) => {
                  if (
                    values.FinancialPeriodsId === undefined ||
                    values.AccountType === undefined
                  ) {
                    toast2({
                      description: t(
                        "pages.DailyRestrictions.searchvalidation"
                      ),
                    });
                  } else {
                    values.AccountLevel = AccountLevel + 1;
                    values.ParentAcntNum = ParentAcntNum;
                    values.AccountNumber = "0000";
                    values.AccountChartId = null;
                    values.AccountType = values.AccountType.AccountTypeId;
                    values.FinancialPeriodsId =
                      values.FinancialPeriodsId.FinancialPeriodsId;
                    if (values.Master) {
                      values.Master = 1;
                    } else {
                      values.Master = 0;
                    }
                    console.log(values);
                    dispatch(createAccountTreeNode(values))
                      .unwrap()
                      .then((_) => {
                        toast({
                          description: t(
                            "pages.general_accounts.submitted.item_created"
                          ),
                        });
                        dispatch(getAcoountsTree());
                        reset();
                      });
                  }
                })}
              >
                <Stack spacing={6}>
                  <MultiSelect
                    title={t("pages.general_accounts.FinancialPeriods")}
                    data={
                      data.FinancialPeriodList &&
                      data.FinancialPeriodList.map((el) => ({
                        ...el,
                        label: el.Description,
                        value: el.FinancialPeriodsId,
                      }))
                    }
                    isMulti={false}
                    isRequired={true}
                    onChange={(e) => setValue("FinancialPeriodsId", e)}
                    isDisabled={addDisabeled}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <label>{t("pages.general_accounts.Account_nameAr")}</label>
                    {/* Wrap your input component with the Controller component */}
                    <Controller
                      name="AccountNameAr" // Set a unique name for your input field
                      control={control}
                      render={({ field }) => (
                        <ChInput
                          {...field}
                          disabled={addDisabeled}
                          style={{ border: "1px solid" }}
                          placeholder={t(
                            "pages.general_accounts.Account_nameAr"
                          )}
                          isRequired={true}
                        />
                      )}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <label>{t("pages.general_accounts.Account_nameEn")}</label>
                    <Controller
                      name="AccountNameEn" // Set a unique name for your input field
                      control={control}
                      render={({ field }) => (
                        <ChInput
                          {...field}
                          disabled={addDisabeled}
                          style={{ border: "1px solid" }}
                          placeholder={t(
                            "pages.general_accounts.Account_nameEn"
                          )}
                          isRequired={true}
                        />
                      )}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <label>{t("pages.general_accounts.discription")}</label>
                    <Controller
                      name="AccountDescription" // Set a unique name for your input field
                      control={control}
                      render={({ field }) => (
                        <ChInput
                          {...field}
                          disabled={addDisabeled}
                          style={{ border: "1px solid" }}
                          placeholder={t("pages.general_accounts.discription")}
                          isRequired={true}
                        />
                      )}
                    />
                  </div>

                  <Checkbox
                    {...register("Master")}
                    style={{
                      marginTop: "10px",
                      paddingBottom: "20px",
                      borderBottom: "1px solid #eee",
                      width: "100%",
                    }}
                  >
                    {t("pages.general_accounts.master")}
                  </Checkbox>

                  <Checkbox
                    {...register("IsFreez")}
                    style={{
                      marginTop: "10px",
                      paddingBottom: "20px",
                      borderBottom: "1px solid #eee",
                      width: "100%",
                    }}
                  >
                    {t("pages.general_accounts.IsFreez")}
                  </Checkbox>

                  <MultiSelect
                    title={t("pages.general_accounts.Account_type")}
                    data={
                      data.accountCycle &&
                      data.accountCycle.map((el) => ({
                        ...el,
                        label: el.AccountTypeName,
                        value: el.AccountTypeId,
                      }))
                    }
                    isMulti={false}
                    isRequired={true}
                    onChange={(e) => setValue("AccountType", e)}
                    isDisabled={addDisabeled}
                  />

                  <Flex gap={4} style={{ marginTop: "15px" }}>
                    <GButton
                      disabled={addDisabeled}
                      type="submit"
                      title={t("general.save")}
                      bg="primary"
                      color="light"
                      border="1px solid transparent"
                      hover={{
                        color: "primary",
                        borderColor: "primary",
                        bg: "none",
                      }}
                    />
                  </Flex>
                </Stack>
              </form>
            </div>
          </SimpleGrid>
        </>
      )}

      {showModal && (
        <AddModal
          onClose={() => setShowModal(false)}
          showModal={showModal}
        ></AddModal>
      )}
    </>
  );
};

export default AccountingPage;
