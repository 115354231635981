import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { deleteCustomer } from "../../services/customersService";
import { useNavigate } from 'react-router-dom';


export default function DeleteCustomerModal({onClose ,id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  
  const customers = useSelector(state => state.customers);

  return <>
      <GDeleteModal
      onClose={onClose}
      onSubmit={() => {

        dispatch(deleteCustomer(id))
          .unwrap()
          .then(_ => {
            toast({ description: t("pages.customers.customer_deleted") });
            navigate("/customers")
            onClose();
          })
      }}
      isLoading={customers.isLoading}
    />
  </>
}
