//test
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  getModifiersOptionById,
  updateModifiersOption,
} from "../../services/modifiersOptionsService";
import { getModifiers } from "features/products/modifiers/services/modifiersService";
import { getTaxesGroups } from "features/management/taxesGroups/services/taxesGroupsService";
import { getCostTypes } from "features/products/products/services/costTypesService";
import { generator } from "utils/generator";
import { useParams } from "react-router-dom";

const UpdateModal = ({ data, onClose }) => {
  const { t } = useTranslation();

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const toast2 = useToast({
    position: "top",
    duration: 3000,
    status: "error",
  });

  const dispatch = useDispatch();
  const modifiersOptions = useSelector((state) => state.modifiersOptions);
  const taxesGroups = useSelector((state) => state.taxesGroups);
  const modifiers = useSelector((state) => state.modifiers);
  const costTypes = useSelector((state) => state.costTypes);
  const { modifiersOptionId } = useParams();

  const { handleSubmit, control, getValues, watch, setValue, register , formState: { errors },
  trigger,} =
    useForm({
      defaultValues: data,
    });

  watch(["ModiferOptionRef"]);

  useEffect(() => {
    dispatch(getTaxesGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getModifiers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCostTypes());
  }, [dispatch]);


  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };


  const [costType,SetCostType]=useState({})

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.modifiers_options.update_modifiers_option")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              values.UserId = localStorage.getItem("userId");

              values.ModifersId = values.ModifersId?.ModifersId;
              values.TaxGroupId = values.TaxGroupId?.TaxGroupId;
              values.ProductCostTypeId =
                values.ProductCostTypeId?.ProductCostTypeId;
              if (
                values.ModifersId === undefined ||
                values.ModiferOptionRef === undefined ||
                values.TaxGroupId === undefined ||
                values.ProductCostTypeId === undefined
              ) {
                toast2({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                dispatch(updateModifiersOption({ values }))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.modifiers_options.modifiers_option_updated"
                      ),
                    });
                    dispatch(getModifiersOptionById({ modifiersOptionId }));
                    onClose();
                  });
              }
            })}
          >
            <Stack spacing={6}>
            <FormControl isInvalid={errors.ModiferOptionNameEng}>
            <FormLabel
              htmlFor="ModiferOptionNameEng"
              style={{ textTransform: "capitalize" }}
            >
            {t("pages.modifiers_options.name")}     
           </FormLabel>
            <Controller
              name="ModiferOptionNameEng"
              control={control}
              defaultValue=""
              rules={{
                required: t("validation.required"),
                minLength: {
                  value: 2,
                  message: t("validation.name_long_2"),
                },
                maxLength: {
                  value: 25,
                  message: t("validation.name_long_25"),
                },
                pattern: {
                  value: /^[\p{L}\s]+$/u,
                  message: t("validation.this_name_invalid"),
                },
                validate: {
                  noOnlySpaces: (value) =>
                    !isOnlySpaces(value) ||
                    t("validation.no_only_spaces"),
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={t("pages.modifiers_options.name")}
                  onChange={(e) => handleInputChange(e, "ModiferOptionNameEng")}
                />
              )}
            />
            <FormErrorMessage>
              {errors.ModiferOptionNameEng && errors.ModiferOptionNameEng.message}
            </FormErrorMessage>
          </FormControl>




          <FormControl isInvalid={errors.ModiferOptionNameAr}>
          <FormLabel
            htmlFor="ModiferOptionNameAr"
            style={{ textTransform: "capitalize" }}
          >
          {t("pages.modifiers_options.name_localized")}    
         </FormLabel>
          <Controller
            name="ModiferOptionNameAr"
            control={control}
            defaultValue=""
            rules={{
              required: t("validation.required"),
              minLength: {
                value: 2,
                message: t("validation.name_long_2"),
              },
              maxLength: {
                value: 25,
                message: t("validation.name_long_25"),
              },
              pattern: {
                value: /^[\p{L}\s]+$/u,
                message: t("validation.this_name_invalid"),
              },
              validate: {
                noOnlySpaces: (value) =>
                  !isOnlySpaces(value) ||
                  t("validation.no_only_spaces"),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("pages.modifiers_options.name_localized")}
                onChange={(e) => handleInputChange(e, "ModiferOptionNameAr")}
              />
            )}
          />
          <FormErrorMessage>
            {errors.ModiferOptionNameAr && errors.ModiferOptionNameAr.message}
          </FormErrorMessage>
        </FormControl>

              <MultiSelect
                title={t("pages.modifiers_options.modifiers")}
                data={
                  modifiers.data &&
                  modifiers.data.map((el) => ({
                    ...el,
                    label: el.ModifersNameAr,
                    value: el.ModifersId,
                  }))
                }
                isMulti={false}
                isRequired
                onChange={(e) => setValue("ModifersId", e)}
              />

              <Flex alignItems="flex-end" gap={4}>
                <Input
                  label={t("pages.modifiers_options.sku")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.modifiers_options.sku")}
                  isRequired
                  value={getValues("ModiferOptionRef") || ""}
                  readOnly
                />
                <GButton
                  title={t("pages.inventory.generate_sku")}
                  height="50px"
                  bg="light"
                  border="1px solid"
                  borderColor="border"
                  hover={{
                    bg: "primary",
                    borderColor: "primary",
                    color: "light",
                  }}
                  onClick={() => setValue("ModiferOptionRef", generator({}))}
                />
              </Flex>

              <FormControl isInvalid={errors.ModiferOptionPrice}>
              <FormLabel
                htmlFor="ModiferOptionPrice"
                style={{ textTransform: "capitalize" }}
              >
              {t("pages.modifiers_options.price")}
              </FormLabel>
              <Controller
                name="ModiferOptionPrice"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  validate: (value) => {
                    const intValue = parseInt(value, 10);
                    return (
                      intValue >= 0 || t("validation.must_be_non_negative")
                    );
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.modifiers_options.price")}
                    onChange={(e) => {
                      handleInputChange(e, "ModiferOptionPrice");
                    }}
                    type="number"
                    min="0"
                  />
                )}
              />

              <FormErrorMessage>
                {errors.ModiferOptionPrice && errors.ModiferOptionPrice.message}
              </FormErrorMessage>
            </FormControl>

              <MultiSelect
                title={t("pages.modifiers_options.tax_group")}
                data={
                  taxesGroups.data &&
                  taxesGroups.data.map((el) => ({
                    ...el,
                    label: el.TaxGroupName,
                    value: el.TaxGroupId,
                  }))
                }
                onChange={(e) => setValue("TaxGroupId", e)}
                isRequired
              />

              <MultiSelect
              title={t("pages.modifiers_options.cost_type")}
              data={
                costTypes.data &&
                costTypes.data.map((el) => ({
                  ...el,
                  label: el.ProductCostTypeName,
                  value: el.ProductCostTypeId,
                }))
              }
              isMulti={false}
              onChange={(e) => {
                setValue("ProductCostTypeId", e)
                SetCostType(e)
                if(e.ProductCostTypeId===1){
                  setValue("ProductCost",0)
                }
              }}
              isRequired
            />

            {
              costType?.ProductCostTypeId===2?(
                <FormControl isInvalid={errors.ProductCost}>
                <FormLabel
                  htmlFor="ProductCost"
                  style={{ textTransform: "capitalize" }}
                >
                {t("pages.modifiers_options.price")}
                </FormLabel>
                <Controller
                  name="ProductCost"
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: (value) => {
                      const intValue = parseInt(value, 10);
                      return (
                        intValue >= 0 || t("validation.must_be_non_negative")
                      );
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.modifiers_options.price")}
                      onChange={(e) => {
                        handleInputChange(e, "ProductCost");
                      }}
                      type="number"
                      min="0"
                    />
                  )}
                />
  
                <FormErrorMessage>
                  {errors.ProductCost && errors.ProductCost.message}
                </FormErrorMessage>
              </FormControl>
              ):(null)
            }

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={modifiersOptions.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={modifiersOptions.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default UpdateModal;
