import React, { useEffect, useState } from "react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { Checkbox, Flex, Stack, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Input from "components/form/Input";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  createCaherApp,
  getKitchenSortingMethod,
  getOrderType,
  getRoundingMethod,
} from "services/chasherAppService";

const CasherApp = () => {
  const [enableTips, setEnableTips] = useState(false);
  const [discountRequiresCustomerInfo, setDiscountRequiresCustomerInfo] =
    useState(false);
  const [voidRequiresCustomerInfo, setVoidRequiresCustomerInfo] =
    useState(false);
  const [
    requireTableAndGuestCountSelectionFDineInOrders,
    setRequireTableAndGuestCountSelectionFDineInOrders,
  ] = useState(false);
  const [alwaysAskForVoidReason, setAlwaysAskForVoidReason] = useState(false);
  const [
    autoSendToKitchenAfterFullPayment,
    setAutoSendToKitchenAfterFullPayment,
  ] = useState(false);
  const [autoDataSyncAtStartOfDay, setAutoDataSyncAtStartOfDay] =
    useState(false);
  const [autoPrintProductsMix, setAutoPrintProductsMix] = useState(false);
  const [autoPrintTillsReports, setAutoPrintTillsReports] = useState(false);
  const [forceInventoryBeforeEndOfDay, setForceInventoryBeforeEndOfDay] =
    useState(false);
  const [autoCloseKioskOrders, setAutoCloseKioskOrders] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { handleSubmit, control, setValue, register, reset } = useForm();

  const data = useSelector((state) => state.casherApp);

  useEffect(() => {
    dispatch(getOrderType());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRoundingMethod());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getKitchenSortingMethod());
  }, [dispatch]);

  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.KitchenSortingMethodId =
            values.KitchenSortingMethodId.KitchenSortingMethodId;
          values.OrderTypeId = values.OrderTypeId.OrderTypeId;
          values.RoundingMethodId = values.RoundingMethodId.RoundingMethodId;
          dispatch(createCaherApp(values));
          //console.log(values)
        })}
      >
        <Stack spacing={6} bg="light" p={4}>
          <Input
            label={t(
              "pages.management.settings.casher_app.preset_Tendered_Amounts"
            )}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t(
              "pages.management.settings.casher_app.preset_Tendered_Amounts"
            )}
            name="PresetTenderedAmounts"
            isFormController
            isRequired
            control={control}
          />

          <Input
            label={t(
              "pages.management.settings.casher_app.Tendered_Amount_Currencies"
            )}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t(
              "pages.management.settings.casher_app.Tendered_Amount_Currencies"
            )}
            name="TenderedAmountCurrencies"
            isFormController
            isRequired
            control={control}
          />

          <Input
            label={t(
              "pages.management.settings.casher_app.Predefined_Tips_Percentages"
            )}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t(
              "pages.management.settings.casher_app.Predefined_Tips_Percentages"
            )}
            name="PredefinedTipsPercentages"
            isFormController
            isRequired
            control={control}
          />

          <Input
            label={t(
              "pages.management.settings.casher_app.Upload_Orders_Delay"
            )}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t(
              "pages.management.settings.casher_app.Upload_Orders_Delay"
            )}
            name="UploadOrdersDelayMinute"
            type="number"
            isFormController
            isRequired
            control={control}
          />

          <Input
            label={t(
              "pages.management.settings.casher_app.Inactive_Users_Logout"
            )}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t(
              "pages.management.settings.casher_app.Inactive_Users_Logout"
            )}
            name="InactiveUsersLogoutMinute"
            type="number"
            isFormController
            isRequired
            control={control}
          />

          <Input
            label={t(
              "pages.management.settings.casher_app.Maximum_Return_Period"
            )}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t(
              "pages.management.settings.casher_app.Maximum_Return_Period"
            )}
            name="MaximumReturnPeriodMinute"
            isFormController
            isRequired
            type="number"
            control={control}
          />

          <MultiSelect
            data={
              data.orderType &&
              data.orderType.map((el) => ({
                ...el,
                label: el.OrderTypeName,
                value: el.OrderTypeId,
              }))
            }
            title={t(
              "pages.management.settings.casher_app.Require_order_tags_for_orders"
            )}
            onChange={(e) => setValue("OrderTypeId", e)}
            isMulti={false}
          />

          <MultiSelect
            data={
              data.RoundingMethod &&
              data.RoundingMethod.map((el) => ({
                ...el,
                label: el.RoundingMethodName,
                value: el.RoundingMethodId,
              }))
            }
            title={t("pages.management.settings.casher_app.Rounding_Method")}
            onChange={(e) => setValue("RoundingMethodId", e)}
            isMulti={false}
          />

          <MultiSelect
            data={
              data.KitchenSortingMethod &&
              data.KitchenSortingMethod.map((el) => ({
                ...el,
                label: el.KitchenSortingMethodName,
                value: el.KitchenSortingMethodId,
              }))
            }
            title={t(
              "pages.management.settings.casher_app.Kitchen_Sorting_Method"
            )}
            onChange={(e) => setValue("KitchenSortingMethodId", e)}
            isMulti={false}
          />

          <Checkbox
            {...register("EnableTips")}
            isChecked={enableTips}
            onChange={(e) => setEnableTips(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.casher_app.Enable_Tips")}
          </Checkbox>

          <Checkbox
            {...register("DiscountRequiresCustomerInfo")}
            isChecked={discountRequiresCustomerInfo}
            onChange={(e) => setDiscountRequiresCustomerInfo(e.target.checked)}
            textTransform="capitalize"
          >
            {t(
              "pages.management.settings.casher_app.Discount_Requires_Customer_Info"
            )}
          </Checkbox>

          <Checkbox
            {...register("VoidRequiresCustomerInfo")}
            isChecked={voidRequiresCustomerInfo}
            onChange={(e) => setVoidRequiresCustomerInfo(e.target.checked)}
            textTransform="capitalize"
          >
            {t(
              "pages.management.settings.casher_app.Void_Requires_Customer_Info"
            )}
          </Checkbox>

          <Checkbox
            {...register("RequireTableandGuestCountSelectionFDineInOrders")}
            isChecked={requireTableAndGuestCountSelectionFDineInOrders}
            onChange={(e) =>
              setRequireTableAndGuestCountSelectionFDineInOrders(
                e.target.checked
              )
            }
            textTransform="capitalize"
          >
            {t(
              "pages.management.settings.casher_app.Require_Table_and_Guest_Count_Selection_For_Dine_In_Orders"
            )}
          </Checkbox>

          <Checkbox
            {...register("AlwaysAskForVoidReason")}
            isChecked={alwaysAskForVoidReason}
            onChange={(e) => setAlwaysAskForVoidReason(e.target.checked)}
            textTransform="capitalize"
          >
            {t(
              "pages.management.settings.casher_app.Always_Ask_For_Void_Reasons"
            )}
          </Checkbox>

          <Checkbox
            {...register("AutoSendToKitchenAfterFullPayment")}
            isChecked={autoSendToKitchenAfterFullPayment}
            onChange={(e) =>
              setAutoSendToKitchenAfterFullPayment(e.target.checked)
            }
            textTransform="capitalize"
          >
            {t(
              "pages.management.settings.casher_app.Auto_Send_To_Kitchen_After_Full_Payment"
            )}
          </Checkbox>

          <Checkbox
            {...register("AutoDataSyncAtStartOfDay")}
            isChecked={autoDataSyncAtStartOfDay}
            onChange={(e) => setAutoDataSyncAtStartOfDay(e.target.checked)}
            textTransform="capitalize"
          >
            {t(
              "pages.management.settings.casher_app.Auto_Data_Sync_at_Start_of_Day"
            )}
          </Checkbox>

          <Checkbox
            {...register("AutoPrintProductsMix")}
            isChecked={autoPrintProductsMix}
            onChange={(e) => setAutoPrintProductsMix(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.casher_app.Auto_Print_Products_Mix")}
          </Checkbox>

          <Checkbox
            {...register("AutoPrintTillsreports")}
            isChecked={autoPrintTillsReports}
            onChange={(e) => setAutoPrintTillsReports(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.casher_app.Auto_Print_Tills_reports")}
          </Checkbox>

          <Checkbox
            {...register("ForceInventoryBeforeEndOfDay")}
            isChecked={forceInventoryBeforeEndOfDay}
            onChange={(e) => setForceInventoryBeforeEndOfDay(e.target.checked)}
            textTransform="capitalize"
          >
            {t(
              "pages.management.settings.casher_app.Force_Inventory_Before_End_Of_Day"
            )}
          </Checkbox>

          <Checkbox
            {...register("AutoCloseKioskOrders")}
            isChecked={autoCloseKioskOrders}
            onChange={(e) => setAutoCloseKioskOrders(e.target.checked)}
            textTransform="capitalize"
          >
            {t("pages.management.settings.casher_app.Auto_Close_Kiosk_Orders")}
          </Checkbox>

          <Flex justifyContent="flex-end">
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              //isDisabled={isLoading}
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Stack>
      </form>
    </>
  );
};

export default CasherApp;
