import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { Box, Button, Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import EditModal from "../components/modals/EditModal";
import AppliesOnBranches from "../components/AppliesOnBranches";
import AppliesOnCategories from "../components/AppliesOnCategories";
import AppliesOnProducts from "../components/AppliesOnProducts";
import AppliesOnCombos from "../components/AppliesOnCombos";
import AppliesOnProductTags from "../components/AppliesOnProductTags";
import DeleteTimedEvent from "../components/DeleteTimedEvent";
import {
  activateTimedEvent,
  getTimedEventById,
} from "../services/timedEventsService";
import DetailsTable from "../components/DetailsTable";

import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

export default function TimedEventsDetails() {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 47);

  const dispatch = useDispatch();
  const id = useParams();
  const { t } = useTranslation();

  const timedEvent = useSelector((state) => state.timedEvents);

  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    dispatch(getTimedEventById(id.id));
  }, [dispatch, id.id]);

  const activeFunction = () => {
    dispatch(activateTimedEvent(id.id))
      .unwrap()
      .then((_) => {
        dispatch(getTimedEventById(id.id));
      });
  };

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return timedEvent.isLoading ? (
    <Spinner />
  ) : (
    <>
      <BreadCrumb>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <Box as="p" fontSize="1.6rem" textTransform="capitalize">
              {timedEvent?.TimedEventDetails?.TimedEventName || "-"}
            </Box>
            <Button
              bg={timedEvent?.TimedEventDetails?.IsActive ? "#00D1D1" : "red"}
              color="light"
              _hover={"none"}
              style={{ cursor: "default" }}
            >
              <Text>
                {timedEvent?.TimedEventDetails?.IsActive
                  ? t("general.activated")
                  : t("general.inactive")}
              </Text>
            </Button>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
          >
            <GButton
              title={
                timedEvent?.TimedEventDetails?.IsActive
                  ? t("general.deactive")
                  : t("general.active")
              }
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() => activeFunction()}
            />
            <GButton
              title={t("pages.items.edit")}
              bg="#54A0FF"
              color="light"
              hover={{ bg: "primary" }}
              onClick={() => setShowEditModal(true)}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Stack spacing={12}>
        <DetailsTable data={timedEvent?.TimedEventDetails} />

        <AppliesOnBranches></AppliesOnBranches>

        <AppliesOnCategories></AppliesOnCategories>

        <AppliesOnProducts></AppliesOnProducts>

        {timedEvent?.TimedEventDetails?.EventTypeId !== 1 &&
          timedEvent?.TimedEventDetails?.EventTypeId !== 2 &&
          timedEvent?.TimedEventDetails?.EventTypeId !== 4 && (
            <AppliesOnCombos></AppliesOnCombos>
          )}

        <AppliesOnProductTags></AppliesOnProductTags>

        <DeleteTimedEvent idOfTimeEvent={id.id}></DeleteTimedEvent>
      </Stack>

      {showEditModal && (
        <EditModal
          onClose={() => setShowEditModal(false)}
          data={timedEvent?.TimedEventDetails}
        />
      )}
    </>
  );
}
