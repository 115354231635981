import { createSlice } from "@reduxjs/toolkit";
import {
  getTransferOrdersList,
  getTransfersOrdersFilteredData,
  resetTransfersOrdersFilteredData,
} from "../services/transfersOrdersServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,
  data: [],
  // Filter data
  filteredData: [],
};

const reportsTransfersOrders = createSlice({
  name: "reportsTransfersOrders",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get Transfers List ------------------------------------ */
    builder.addCase(getTransferOrdersList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTransferOrdersList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getTransferOrdersList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- get Transfers Filtered Data------------------------------------------------ */
    builder.addCase(getTransfersOrdersFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getTransfersOrdersFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        state.errors = null;
      }
    );
    builder.addCase(getTransfersOrdersFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- reset Transfers Filtered Data------------------------------------------------ */
    builder.addCase(resetTransfersOrdersFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      resetTransfersOrdersFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.filteredData = [];
        state.errors = null;
      }
    );
    builder.addCase(resetTransfersOrdersFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default reportsTransfersOrders.reducer;
