import { createSlice } from "@reduxjs/toolkit";
import {
  createBranchDeliveryZone,
  updateBranchDeliveryZone,
  getBranchesDeliveryZones,
  getDeliveryZoneInBranches
} from "../services/branchesDeliveryZonesService";

let initialState = {
  isLoading: false,
  data: [],
  section: null,
  pages: 0,
  errors: null,
  DeliveryZones:[]
};

const branchesDeliveryZonesSlice = createSlice({
  name: "branchesDeliveryZones",
  initialState,
  extraReducers: (builder) => {
/* ------------------------------------------ getBranchesDeliveryZones------------------------------------*/
    builder.addCase(getBranchesDeliveryZones.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getBranchesDeliveryZones.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.DeliveryZones = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getBranchesDeliveryZones.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ------------------------------------------ get DeliveryZones In Branches ------------------------------------*/
    builder.addCase(getDeliveryZoneInBranches.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDeliveryZoneInBranches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getDeliveryZoneInBranches.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ------------------------------------------ Create DeliveryZones In Branches ------------------------------------*/
    builder.addCase(createBranchDeliveryZone.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createBranchDeliveryZone.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createBranchDeliveryZone.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ------------------------------------------ Update DeliveryZones In Branches ------------------------------------*/
    builder.addCase(updateBranchDeliveryZone.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateBranchDeliveryZone.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const indexAt = state.data.findIndex(section => section.id === payload.id);
      // state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateBranchDeliveryZone.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default branchesDeliveryZonesSlice.reducer;