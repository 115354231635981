import { createSlice } from "@reduxjs/toolkit";
import { getTimeZone } from "../services/timezoneService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null
};

const timezoneSlice = createSlice({
  name: "timezone",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTimeZone.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTimeZone.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload.data;
      state.errors = null;
    })
    builder.addCase(getTimeZone.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default timezoneSlice.reducer;