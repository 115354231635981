import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {  Text,Flex } from "@chakra-ui/react"
import * as FiIcons from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const ExcelButton = ({ data,fileName }) => {

    const { t } = useTranslation();


    const downloadExcel = (items) => {
        // Customize the style for the Excel sheet
        const ws = XLSX.utils.json_to_sheet(items, {
          cellStyles: true,
          headerStyles: {
            fill: {
              bgColor: '5A5A5A',  // Dark Gray
            },
            font: {
              sz: '14',
              bold: true,
              color: 'FFFFFF',  // White text
            },
          },
          bodyStyles: {
            fill: {
              bgColor: 'FFFFFF',  // White
            },
            font: {
              sz: '12',
              color: '000000',  // Black text
            },
          },
        });
      
        // Set column widths
        const columnWidths = [
          { wch: 20 }, // Width of Column 1
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 1
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 1
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 1
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          { wch: 20 }, // Width of Column 2
          // Add more widths as needed
        ];
      
        ws['!cols'] = columnWidths;
      


// Apply styles to the header row
for (let i = 0; i < columnWidths.length; i++) {
  const cellRef = XLSX.utils.encode_cell({ c: i, r: 0 });  // First row

  // Check if the cell exists before setting properties
  if (ws[cellRef]) {
    ws[cellRef].s = {
      fill: {
        bgColor: 'FF0000',  // Red background color for the entire first row
      },
      font: {
        sz: '14',
        bold: true,
        color: 'FFFFFF',  // White text
      },
    };
  } else {
    // If the cell doesn't exist, create it and set properties
    ws[cellRef] = {
      s: {
        fill: {
          bgColor: 'FF0000',  // Red background color for the entire first row
        },
        font: {
          sz: '14',
          bold: true,
          color: 'FFFFFF',  // White text
        },
      },
    };
  }



 
}

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
      
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, `${fileName}.xlsx`);
    };
      
      


  return (
    <div>
        <button onClick={() => downloadExcel(data)}>
        <Flex alignItems="center" gap={2}>
        <FiIcons.FiCloud size={16} />
        <Text>{t("general.export")}</Text>
        <FiIcons.FiChevronDown size={16} />
        </Flex>
        </button>
    </div>
  );
}

export default ExcelButton;
