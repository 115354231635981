import React, { useEffect, useState } from 'react';
import { Button, Flex, Text} from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ExportModal from 'components/ui/modal/ExportModal';
import OrderTransactionsTable from '../components/OrderTransactionsTable';
import { getOrderTransactions } from '../services/OrderTransactionServices';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

export default function OrderTransactions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const orderTransactionsData = useSelector(state => state.inventoryOrderTransactions);

  const [exportModal,SetExportModal]=useState(false);

  useEffect(() => {
    dispatch(getOrderTransactions());
  }, [dispatch]);
  


  /* -----------------------------------Privilige-------------------------------*/
const retrievedArray =useDecryptedPrivilege()
const ManageOrderTransactions = checkIdExists(retrievedArray, 33);

if(ManageOrderTransactions===false ){
  return(
    <NotAuthorizedCompo/>
  )
};
/* -----------------------------------Privilige-------------------------------*/
  return <>
    <BreadCrumb>
      <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize" >
            {t("pages.order_transactions.order_transactions")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
                <Button
                  type="button" bg="none" border="1px solid" borderColor="border"
                  textTransform="capitalize" fontSize="1rem" color="text"
                  _hover={{ bg: "primary", color: "light" }}
                  onClick={() => SetExportModal(true)}
                >
                    <Text>{t("general.export")}</Text>
                </Button>
          </Flex>
      </Flex>
    </BreadCrumb>

    <OrderTransactionsTable data={orderTransactionsData.data} filteredData={orderTransactionsData.filteredData} />


    {exportModal&&(
      <ExportModal 
        onClose={() => SetExportModal(false)}   
        data={orderTransactionsData.data}
        fileName={"OrderTransactions"}
        >
      </ExportModal>
  )}
  
  </>
}
