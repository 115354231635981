import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text,} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import Pagination from "components/ui/pagination/Pagination";
import { useState } from "react";

const GTable = ({
  data,
  handleAddBranchToGroup,
  handleRemoveBranchFromGroup,
  handleAddProductToGroup,
  handleRemoveProductFromGroup,
  handleAddModifiersOptionToGroup,
}) => {

    /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
    const recordsPerPage = 12;
    const [currentPageActive, setCurrentPageActive] = useState(1);
    const prePage = (currentPage, setCurrentPage) => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const nextPage = (currentPage, setCurrentPage, npage) => {
      if (currentPage < npage) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const calculateRecords = (data, currentPage, recordsPerPage) => {
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
      return data?.slice(firstIndex, lastIndex);
    };
  
  // Calculate records for activeDiscounts
  const taxesGroupsData = calculateRecords(
      data,
      currentPageActive,
      recordsPerPage
    );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return <>
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6} mt={6}>
      {taxesGroupsData.map((taxGroup,index) => (
        <Box
          key={index}
          bg="light"
          p={6}
          borderRadius={4}
          boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
        >
          <Flex justifyContent="space-between">
            <Text textTransform="capitalize">
              {taxGroup?.TaxGroupName || "-"}
            </Text>
            <Menu>
              <MenuButton
                as={Button}
                size="sm"
                bg="none"
                border="1px solid"
                borderColor="border"
                color="text"
                _hover={{
                  bg: "primary",
                  borderColor: "primary",
                  color: "light",
                }}
              >
                <Flex alignItems="center" gap={2}>
                  <FiIcons.FiMoreHorizontal size={18} />
                </Flex>

              </MenuButton>
                  <MenuList p={2} minW="auto">
                    <MenuItem onClick={() => handleAddBranchToGroup(taxGroup)}>
                      <Text color="text" textTransform="capitalize">
                        add to branches
                      </Text>
                    </MenuItem>

                    <MenuItem onClick={() => handleRemoveBranchFromGroup(taxGroup)}>
                      <Text color="text" textTransform="capitalize">
                        remove from branches
                      </Text>
                    </MenuItem>

                    <MenuItem onClick={() => handleAddProductToGroup(taxGroup)}>
                      <Text color="text" textTransform="capitalize">
                        add to products
                      </Text>
                    </MenuItem>

                    <MenuItem
                      onClick={() => handleRemoveProductFromGroup(taxGroup)}
                    >
                      <Text color="text" textTransform="capitalize">
                        remove from products
                      </Text>
                    </MenuItem>

                    <MenuItem
                      onClick={() => handleAddModifiersOptionToGroup(taxGroup)}
                    >
                      <Text color="text" textTransform="capitalize">
                        add to modifiers options
                      </Text>
                    </MenuItem>
                    
                  </MenuList>
            </Menu>
          </Flex>
          <Text
            textTransform="capitalize"
            color="text"
            border="1px solid"
            borderColor="border"
            display="inline-block"
            paddingInline={3}
            paddingBlock={1}
            borderRadius={4}
          >
            {taxGroup?.Reference}
          </Text>
        </Box>
      ))}
    </SimpleGrid>
    <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(data?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(data?.length / recordsPerPage)
            )
          }
    />
  </>;
};

export default GTable;
