import { useEffect, useState } from "react";
import { Checkbox, Flex, Stack, useToast } from "@chakra-ui/react";
import { axios } from "services/axios";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import Input from "components/form/Input";

const InventoryTransactions = () => {
  const { t } = useTranslation();

  const { control, reset, handleSubmit, register } = useForm();
  const toast = useToast({
    position: "top",
    status: "success",
    duration: 3000,
  });

  const [isLoading, setIsLoading] = useState(false);

  const getDisplayAppSetting = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        "/Manage/Setting/DisplaySettig/ListDisplaySettig"
      );
      if (typeof data === "object" && Array.isArray(data)) {
        const jsonData = JSON.parse(data[0]?.jsonData)[0] || {};
        reset(jsonData);
      }
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  };
  const updateDisplayAppSetting = async (values) => {
    setIsLoading(true);
    try {
      console.log(values);
      const { data } = await axios.post(
        `/Manage/Setting/DisplayAppSettings/AddSettingDisplayApp?displayapp=${JSON.stringify(
          values
        )}`
      );
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  };

  useEffect(() => {
    getDisplayAppSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit((values, event) => {
          let formData = new FormData(event.target);
          formData.set("DisplayAppSettingsId", null);
          updateDisplayAppSetting(formData)
            .then((_) =>
              toast({
                description: t("pages.management.settings.settings_updated"),
              })
            )
            .catch((_) =>
              toast({ status: "error", description: "something went wrong!" })
            );
        })}
      >
        <Stack spacing={6} bg="light" p={4}>
          <input name="DisplayAppSettingsName" type="file" />

          <Input
            label={t("pages.management.settings.inventory_transactions.header")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t(
              "pages.management.settings.inventory_transactions.header"
            )}
            isFormController
            control={control}
            name="Header"
          />

          <Input
            label={t("pages.management.settings.inventory_transactions.footer")}
            labelStyle={{ textTransform: "capitalize" }}
            placeholder={t(
              "pages.management.settings.inventory_transactions.footer"
            )}
            isFormController
            control={control}
            name="Footer"
          />

          <Checkbox
            {...register("IsTaxInclusivePricing")}
            textTransform="capitalize"
          >
            {t(
              "pages.management.settings.inventory_transactions.restrict-inv-transactions"
            )}
          </Checkbox>

          <Flex justifyContent="flex-end">
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              isDisabled={isLoading}
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Stack>
      </form>
    </>
  );
};

export default InventoryTransactions;
