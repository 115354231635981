import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from '@chakra-ui/react'
import GModal from 'components/ui/modal/Modal'
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from "components/form/Input";
import GButton from 'components/ui/button/Button';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addComboGroup, getGroupofSize } from '../../services/comboSizeService';

const NewAddGroupModal = ({onClose}) => {
    const { t } = useTranslation();


    const {
        handleSubmit,
        control,
        register,
        setValue,
        formState: { errors },
        trigger,
      } = useForm();


      const handleInputChange = async (e, type) => {
        setValue(type, e.target.value);
        await trigger(type);
      };
    
      const isOnlySpaces = (value) => {
        // Check if the value contains only spaces
        return !value.trim();
      };


      const id = useParams()
      //console.log(id.comboId)
      const dispatch=useDispatch()

      const toast = useToast({
        position: "top",
        duration: 3000,
        status: "success",
      });

  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.combos.add_group")}
    modalContent={
        <Box p={4}>
        <form onSubmit={handleSubmit((values)=>{
            values.UserId = localStorage.getItem("userId");
            values.ComboSizeGroupId=null
            values.ComboId=id.comboId
            dispatch(addComboGroup(values)).unwrap()
            .then((_) => {
              toast({ description: t("pages.combos.combo_created") });
              dispatch(getGroupofSize(id.comboId))
              onClose();
            });
            //console.log(values)
        })}>
        <Stack spacing={6}>
        <FormControl isInvalid={errors.ComboSizeGroupName}>
                <FormLabel
                  htmlFor="ComboSizeGroupName"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.categories.name")}
                </FormLabel>
                <Controller
                  name="ComboSizeGroupName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.categories.name")}
                      onChange={(e) => {
                        handleInputChange(e, "ComboSizeGroupName");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.ComboSizeGroupName &&
                    errors.ComboSizeGroupName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.ComboSizeGroupNameLoc}>
                <FormLabel
                  htmlFor="ComboSizeGroupNameLoc"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.categories.name_localized")}
                </FormLabel>
                <Controller
                  name="ComboSizeGroupNameLoc"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.categories.name_localized")}
                      onChange={(e) => {
                        handleInputChange(e, "ComboSizeGroupNameLoc");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.ComboSizeGroupNameLoc &&
                    errors.ComboSizeGroupNameLoc.message}
                </FormErrorMessage>
              </FormControl>

              <Checkbox {...register("IsUpgradable")}>
                {t("pages.combos.upgradable ")}
              </Checkbox>


              <Flex gap={4}>
              <GButton
                type="submit"
                title={t("pages.inventory_item_details.apply")}
                bg="primary"
                color="light"
                border="1px solid transparent"
                //isLoading={itemTags.isLoading}
                hover={{
                  color: "primary",
                  borderColor: "primary",
                  bg: "none",
                }}
              />
              <GButton
                title={t("general.close")}
                onClick={onClose}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                //isLoading={itemTags.isLoading}
                hover={{
                  color: "red.600",
                  borderColor: "red.600",
                  bg: "none",
                }}
              />
            </Flex>
        
        </Stack>
        </form>
        </Box>
    }
    />
  )
}

export default NewAddGroupModal
