import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";
/* ------------------------------------------ Get Taxes -------------------------------------------------*/
export const getTaxes = createAsyncThunk("taxes/getTaxes",async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/Taxs/TaxsList");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------ Create Tax -------------------------------------------------*/
export const createTax = createAsyncThunk("taxes/createTax", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Taxs/AddEditTaxs?taxs=${JSON.stringify(args)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateTax = createAsyncThunk(
  "taxes/updateTax",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(`/taxes/${args.id}`, args.values);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteTax = createAsyncThunk(
  "taxes/deleteTax",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(`/taxes/${args.id}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


