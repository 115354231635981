import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getPaymentMethodsTypes = createAsyncThunk(
  "paymentMethods/getPaymentMethodsTypes",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get("/Manage/PaymentMethod/LookUpPaymentType");
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);