import React, { useEffect } from "react";
import GModal from "components/ui/modal/Modal";
import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemsToTransfers,
  getItemDropdown,
  getTransferItems,
} from "../services/transfersServices";

export default function AddItemstToTransfers({ onClose, id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue } = useForm();

  const transfersData = useSelector((state) => state.inventoryTransfers);

  useEffect(() => {
    dispatch(getItemDropdown());
  }, [dispatch]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.transfers.add_items")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.TransfersId = id.TransfersId;
                values.TransfersItems = values.TransfersItems?.map((el) => ({
                  InventoryItemId: el.InventoryItemId,
                }));
                values.UserId = localStorage.getItem("userId");
                if (values.TransfersItems?.length === 0 || values.TransfersItems === undefined ) {
                    toast2({ description: t("validation.choose_first") });
                } else {
                  dispatch(addItemsToTransfers(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.transfers.items_add_succesfuly"),
                      });
                      dispatch(getTransferItems(id));
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <MultiSelect
                  title={t("general.items")}
                  data={transfersData.ItemsDropDown.map((el) => ({
                    ...el,
                    label: el.InventoryItemNameEng,
                    value: el.InventoryItemId,
                  }))}
                  isMulti={true}
                  isRequired={true}
                  onChange={(e) => setValue("TransfersItems", e)}
                />

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
