import { createSlice } from "@reduxjs/toolkit";
import {getDeliveryCharges,getchargesForDeliveryCharge,getchargeType,getorderTypeCharge,getTaxesGroupForCharge,saveDilveryCharges, addDilveryChargesToDeliveryZone} from "../services/deliveryChargeService"


let initialState = {
    isLoading: false,
    data: [],
    delvierycharege:[],
    chargeType:[],
    orderTypes:[],
    taxesGroup:[],
    pages: 0,
    errors: null,
  };


  const deliveryChargeSlice = createSlice({
    name: "deliverycharge",
    initialState,

    extraReducers:(builder)=>{


/* -------------------------------------------------- Get Delivery Charges ---------------------------------------------------------- */
      builder.addCase(getDeliveryCharges.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(getDeliveryCharges.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.errors = null;
      })

      builder.addCase(getDeliveryCharges.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

      //


      builder.addCase(getchargesForDeliveryCharge.pending, (state) => {
        //state.isLoading = true;
        //state.errors = null;
      })

      builder.addCase(getchargesForDeliveryCharge.fulfilled, (state, { payload }) => {
        //state.isLoading = false;
        state.delvierycharege = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.delvierycharege)
        //state.errors = null;
      })

      builder.addCase(getchargesForDeliveryCharge.rejected, (state) => {
        //state.isLoading = false;
        //state.errors = [{ msg: "something went wrong!" }];
      })


      //

      
      builder.addCase(getchargeType.pending, (state) => {
        //state.isLoading = true;
        //state.errors = null;
      })

      builder.addCase(getchargeType.fulfilled, (state, { payload }) => {
        //state.isLoading = false;
        state.chargeType = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.delvierycharege)
        //state.errors = null;
      })

      builder.addCase(getchargeType.rejected, (state) => {
        //state.isLoading = false;
        //state.errors = [{ msg: "something went wrong!" }];
      })


      //

      builder.addCase(getorderTypeCharge.pending, (state) => {
        //state.isLoading = true;
        //state.errors = null;
      })

      builder.addCase(getorderTypeCharge.fulfilled, (state, { payload }) => {
        //state.isLoading = false;
        state.orderTypes = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.delvierycharege)
        //state.errors = null;
      })

      builder.addCase(getorderTypeCharge.rejected, (state) => {
        //state.isLoading = false;
        //state.errors = [{ msg: "something went wrong!" }];
      })


      //


      builder.addCase(getTaxesGroupForCharge.pending, (state) => {
        //state.isLoading = true;
        //state.errors = null;
      })

      builder.addCase(getTaxesGroupForCharge.fulfilled, (state, { payload }) => {
        //state.isLoading = false;
        state.taxesGroup = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.delvierycharege)
        //state.errors = null;
      })

      builder.addCase(getTaxesGroupForCharge.rejected, (state) => {
        //state.isLoading = false;
        //state.errors = [{ msg: "something went wrong!" }];
      })


      //

      builder.addCase(saveDilveryCharges.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })

      builder.addCase(saveDilveryCharges.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        //state.taxesGroup = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        //console.log(state.delvierycharege)
        state.errors = null;
      })

      builder.addCase(saveDilveryCharges.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })

  /* -------------------------------------------------- Add Dilvery Charges To Delivery Zone---------------------------------------------------------- */
      builder.addCase(addDilveryChargesToDeliveryZone.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(addDilveryChargesToDeliveryZone.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
      })
      builder.addCase(addDilveryChargesToDeliveryZone.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })




    }

})



export default deliveryChargeSlice.reducer