import React, { useState } from 'react';
import { Box, Flex} from "@chakra-ui/react"
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';
import * as FiIcons from "react-icons/fi";
import { Spinner } from '@chakra-ui/react';
import FilterModalDelivery from './Modal/FilterModal';
import { IoCloseOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilteredDataDeliveryZone } from '../services/deliveryZoneNewService';
import FilteredDataTable from './FilteredDataTable';
import DeliveryZoneDataTable from './DeliveryZoneDataTable';
import DeliveryZoneDeletedTable from './DeliveryZoneDeletedTable';

const DeliveryZoneTable = ({data,deletedData,filteredData}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const deliveryZoneData = useSelector(state => state.deliveryZoneNew);

    const [sortType,setSortType]=useState("all");
    const [FilterModal,setShowFilterModal]=useState(false)

    const resetFunction = () => {
      dispatch(resetFilteredDataDeliveryZone());
    };

    const deliveryZoneActiveData = data.filter((item)=>{
      return  item?.IsDeleted === false ;
    });

    const deliveryZoneDeletedData = data.filter((item)=>{
      return  item?.IsDeleted === true ;
    });
    const deliveryZoneActivefilteredData = filteredData.filter((item)=>{
      return  item?.IsDeleted === false ;
    });

    const deliveryZoneDeletedfilteredData= filteredData.filter((item)=>{
      return  item?.IsDeleted === true ;
    });

  return <>
    <Box
    bg="light" overflow="auto"
    borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >

      <Flex
          flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
          p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
      >

      <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
  >
    <GButton
      title={t("general.all")}
      bg="none"
      isDisabled={false}
      borderRadius={0}
      color={sortType === "all" ? "primary" :"none"}  
      borderBottom={sortType === "all" ? "2px solid" :"none"}   
      borderColor={sortType === "all" ? "primary" :"none"}  
      onClick={()=>setSortType("all")}
    />
 

    <GButton
    title={t("general.deleted_item")}
    bg="none"
    isDisabled={false}
    borderRadius={0}
    color={sortType === "deleted" ? "primary" :"none"}  
    borderBottom={sortType === "deleted" ? "2px solid" :"none"}   
    borderColor={sortType === "deleted" ? "primary" :"none"}  
    onClick={()=>setSortType("deleted")}
  />
  </Flex>
          <GButton
          title={
              filteredData?.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
              filteredData?.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          onClick={
              filteredData?.length === 0
              ? () => setShowFilterModal(true)
              : resetFunction
          }
        />
      </Flex>

      {
            deliveryZoneData?.isLoading ? 
            <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
            >
                <Spinner /> 
            </Flex>
            :
            data?.length > 0 ? 
            <>
            {
                filteredData?.length > 0 ? 
                <FilteredDataTable 
                    data={sortType === "all" ? deliveryZoneActivefilteredData : deliveryZoneDeletedfilteredData} 
                />
                : 
                <>
                {
                  sortType === "all" ?
                  <DeliveryZoneDataTable data={deliveryZoneActiveData} />
                  :
                  <DeliveryZoneDeletedTable data={deliveryZoneDeletedData} />
                }
                </>
            }
            </>
            :
            <Flex
                p={4}
                bg="white"
                alignItems="center"
                justifyContent="center"
                color="text"
                textTransform="capitalize"
                boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                borderRadius={6}
                >
                {t("pages.branches.no_data")}
            </Flex>
        }

      {
        FilterModal&&(
          <FilterModalDelivery onClose={() => setShowFilterModal(false)}/>
        )
      }
    
    
  </Box>
    </>
}

export default DeliveryZoneTable;
