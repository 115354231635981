import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Icon,
  List,
  ListItem,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  Button,
  Flex,
  Spinner,
  SimpleGrid,
  Checkbox,
  Input as ChInput,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Input from "components/form/Input";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { getAcoountCycleNew } from "features/general_accounts/AccountAdd/service/AccountAddService";
import { useDispatch, useSelector } from "react-redux";
import { filterObjectsByValue } from "utils/DynamicFilter";
import { formatDate } from "utils/FormatDate";
import { MdOutlineChangeCircle } from "react-icons/md";
import { GetAccByFinancialId } from "features/general_accounts/DailyRestrictions/service/DailyRestrictoionsServers";
import { AddReciptJornul } from "../service/receiptService";
import { convertDateFormat } from "utils/convertDate";
import { getTimeStamp } from "utils/Utils_ForAccounting/RecordTimeStamp";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import { checkIdExists } from "utils/cheackAuth";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
const ReceiptPage = () => {
  const { t, i18n } = useTranslation();
  const { handleSubmit, control, getValues, watch, setValue, register } =
    useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    const dataSend = { DateFrom: "0" };
    dispatch(getAcoountCycleNew(dataSend));
  }, [dispatch]);

  const AccountCycleData = useSelector((state) => state.AccountAdd);
  // console.log(AccountCycleData.accountCycle);

  const filteredArray = filterObjectsByValue(
    AccountCycleData.accountCycle,
    "IsClosed",
    false
  );
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [filterByName, setFilterByName] = useState(true);

  useEffect(() => {
    if (selectedPeriod === null) {
      console.log("no fitch");
    } else {
      dispatch(GetAccByFinancialId(selectedPeriod?.FinancialPeriodsId));
    }
  }, [dispatch, selectedPeriod]);

  const AccountData = useSelector((state) => state.DailyRestrictions);

  watch(["AccountName"]);

  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  /* ------------------------------------- Authrzation ------------------------------ */
  const retrievedArray = useDecryptedPrivilege();
  const ManageAccountant_Receipt = checkIdExists(retrievedArray, 1019);

  if (ManageAccountant_Receipt === false) {
    return <NotAuthorizedCompo />;
  }

  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.receipt.receipt")}
          </Text>
        </Flex>
      </Box>

      <Box
        bg="light"
        marginTop={-4}
        padding={5}
        borderTop="1px solid"
        borderColor="border"
        marginInline={-4}
        borderRadius={5}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <form
            onSubmit={handleSubmit((values) => {
              if (
                values.FinancialPeriodsId === undefined ||
                values.AccountName === undefined
              ) {
                toast({
                  description: t("pages.DailyRestrictions.searchvalidation"),
                });
              } else {
                values.OperationNo = values.BookNo;
                values.ManualNumber = values.BookNo;
                values.UserId = localStorage.getItem("userId");
                values.FinancialPeriodsId =
                  values.FinancialPeriodsId.FinancialPeriodsId;
                values.JournalHeaderID = null;
                values.FieldTDate_1 = convertDateFormat(values.TransactionDate);
                values.AccountName.JournalDetailID = null;
                values.AccountName.JournalDetailID = null;
                values.AccountName.Field_1 = convertDateFormat(
                  values.TransactionDate
                );
                values.AccountName.OperatingUserID = 1;
                values.AccountName.PageID = values.BookNo;
                values.AccountName.CurrencyCode = "";
                values.AccountName.CurrencyRate = 1;
                values.AccountName.Notes = "";
                values.AccountName.ReferenceNumber = "";
                values.AccountName.DescriptionAccountCode = "";
                values.AccountName.DescriptionID = 0;
                values.AccountName.SpecialRecordTrackID = 0;
                values.AccountName.Debit = 0;
                values.AccountName.Credit = values.Amount;
                values.AccountName.Field_3 = values.AccountName.AccountNumber;
                values.AccountName.DebitAmountInCurrency = 0;
                values.AccountName.DebitAmountInCurrency = values.Amount;
                values.AccountName.RecordTimeStamp = getTimeStamp();
                values.AccountName.Row_Numbe = "2";
                values.AccountName.Field_2 = values.BookNo;
                values.JournalDetail = [values.AccountName];
                values.AccountName = null;

                dispatch(AddReciptJornul({ JournalHeader: values }))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.general_accounts.addReceipt"),
                    });
                    setValue("Amount", 0);
                    setValue("BookNo", 0);
                    setValue("AccountName", "");
                    setValue("ManualNumber", 0);
                    setValue("OperationNo", 0);
                    setValue("TransactionDate", undefined);
                    setValue("JournalDescription", 0);
                  });
              }
            })}
          >
            <SimpleGrid
              spacing={4}
              columns={{ base: 1, md: 2 }}
              bg="white"
              borderRadius={6}
              m={5}
              width={"100%"}
              columnGap={20}
              rowGap={5}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Input
                  label={t("pages.Expenses.amount")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={"0.00"}
                  name="Amount"
                  isFormController
                  control={control}
                  isRequired
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Input
                  label={t("pages.Expenses.code_number")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.Expenses.code_number")}
                  name="BookNo"
                  isFormController
                  control={control}
                  isRequired
                  type="number"
                />
              </div>

              <MultiSelect
                title={t("pages.DailyRestrictions.finacial_period")}
                data={
                  filteredArray &&
                  filteredArray.map((el) => ({
                    ...el,
                    label: ` ${t("pages.AccountAdd.date_From")} : ${formatDate(
                      el.DateFrom
                    )} ${t("pages.AccountAdd.date_to")} ${formatDate(
                      el.DateTo
                    )}`,
                    value: el.FinancialPeriodsId,
                  }))
                }
                isMulti={false}
                // isDisabled={selectedValue === "all"}
                onChange={(e) => {
                  setValue("FinancialPeriodsId", e);
                  setSelectedPeriod(e);
                }}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  gap: "15px",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setFilterByName(!filterByName)}
                >
                  <MdOutlineChangeCircle color="green" size={25} />
                </div>
                <MultiSelect
                  title={
                    filterByName
                      ? t("pages.Ledger.account_name")
                      : t("pages.Ledger.account_number")
                  }
                  data={AccountData.data?.map((el) => ({
                    ...el,
                    label: filterByName ? el.AccountName : el.AccountNumber,
                    value: el.AccountNumber,
                  }))}
                  isMulti={false}
                  onChange={(e) => setValue("AccountName", e)}
                  isDisabled={!selectedPeriod}
                />
              </div>

              <Input
                label={
                  filterByName
                    ? t("pages.Ledger.account_number")
                    : t("pages.Ledger.account_name")
                }
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={
                  filterByName
                    ? t("pages.Ledger.account_number")
                    : t("pages.Ledger.account_name")
                }
                value={
                  getValues(
                    filterByName
                      ? "AccountName.AccountNumber"
                      : "AccountName.AccountName"
                  ) || ""
                }
                readOnly
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  gap: "10px",
                }}
              >
                <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                  {t("pages.Expenses.data")}
                </label>

                <Controller
                  name="TransactionDate"
                  control={control}
                  render={({ field }) => (
                    <ChInput
                      {...field}
                      style={{ width: "100%" }}
                      required
                      type="date"
                    />
                  )}
                />
              </div>

              <Controller
                name="JournalDescription"
                control={control}
                render={({ field }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <label style={{ marginLeft: "3px", marginRight: "3px" }}>
                      {t("pages.Expenses.description")}
                    </label>
                    <textarea
                      {...field}
                      required
                      style={{
                        width: "100%",
                        border: "2px solid black",
                        borderRadius: "10px",
                        padding: "5px",
                      }}
                    />
                  </div>
                )}
              />
            </SimpleGrid>
            <Button
              type="submit"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.Expenses.save")}
              </Flex>
            </Button>
          </form>
        </div>
      </Box>
    </>
  );
};

export default ReceiptPage;
