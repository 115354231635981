import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getCustomerAddresses = createAsyncThunk("addresses/getCustomerAddresses", async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Customers/CustomerAddressList?customerid={"CustomerId": ${args} }`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createAddress = createAsyncThunk("addresses/createAddress",async (args, thunkApi) => {
  try {
    const { data } = await axios.get(`/Customers/AddCustomerAddress?customeraddress=${JSON.stringify(args)}`);
    return thunkApi.fulfillWithValue(data);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);



export const getAddressById = createAsyncThunk(
  "addresses/getAddressById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Addresses/AddressListById?addressid=${JSON.stringify({ "AddressId": args.addressId })}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const updateAddress = createAsyncThunk(
  "addresses/updateAddress",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Addresses/EditAddress?address=${JSON.stringify(args.values)}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "addresses/deleteAddress",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/addresses/${args.id}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);