import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import AddItemstToTransfers from "../Modal/AddItemstToTransfers";
import { getItemDropdown, getTransferItems } from "../services/transfersServices";
import EditQuantityItems from "./../Modal/EditQuantityItems";

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 

export default function TransferItems({id , status}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const transfersData = useSelector(state => state.inventoryTransfers);

    const [showAddItem, setShowAddItem] = useState(false);
    const [showEditItemsQuantity, setShowEditItemsQuantity] = useState(false);

    useEffect(() => {
      dispatch(getItemDropdown());
    }, [dispatch])

    useEffect(() => {
       dispatch(getTransferItems(id));
    }, [dispatch , id])

    /* -----------------------------------Privilige-------------------------------*/
    const retrievedArray =useDecryptedPrivilege();

    const ManageTransfers = checkIdExists(retrievedArray, 20);
  
    if(showAddItem === true && ManageTransfers === false){
      toast2({ description:  t("general.authorization")});
      setShowAddItem(false);
    };
    if(showEditItemsQuantity === true && ManageTransfers === false){
      toast2({ description:  t("general.authorization")});
      setShowEditItemsQuantity(false);
    };
/* -----------------------------------Privilige-------------------------------*/  
return <>
    <Box marginTop={10} >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
            <Text color="text" textTransform="capitalize">
              {t("general.items")}
            </Text>

            {
              status === "Closed" || status === "Rejected" ?
              ""
              :
              <Flex gap={4}>
                {
                  transfersData?.TransfersItemsList?.length > 0 ? 
                      <GButton
                        title={t("pages.transfers.edit_quantity")}
                        bg="light"
                        color="text"
                        fontSize="14px"
                        border="1px"
                        borderColor={"#CBD5E0"}
                        hover={{ bg: "light" }}
                        opacity="0.7"
                        onClick={() => setShowEditItemsQuantity(true)}
                      />
                  : 
                  ""
                }
                <GButton
                  title={t("pages.transfers.add_items")}
                  bg="light"
                  color="text"
                  fontSize="14px"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  hover={{ bg: "light" }}
                  opacity="0.7"
                  onClick={() => setShowAddItem(true)}
                />
                {/* <GButton
                  title={t("pages.transfers.import_items")}
                  bg="light"
                  color="text"
                  fontSize="14px"
                  border="1px"
                  borderColor={"#CBD5E0"}
                  hover={{ bg: "light" }}
                  opacity="0.7"
                /> */}
              </Flex>
            }

      </Flex>

      {
      transfersData.isLoading ? (
        <Spinner />
      ) : transfersData?.TransfersItemsList?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : transfersData?.TransfersItemsList?.length > 0 ? (
        <Box
          bg="light"
          overflow="auto"
          borderRadius={4}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Table>
            <Thead>
              <Tr>
                <Th>{t("pages.cost_adjustment.name")}</Th>
                <Th>{t("pages.cost_adjustment.suk")}</Th>
                <Th>{t("pages.cost_adjustment.storage_unit")}</Th>
                <Th>{t("pages.cost_adjustment.new_cost_perunit")}</Th>
                
              </Tr>
            </Thead>
            <Tbody>
              {transfersData?.TransfersItemsList?.map((el, index) => (
                <Tr key={index}>
                  <Td>{el?.InventoryItemNameEng}</Td>
                  <Td>{el?.SUK}</Td>
                  <Td>{el?.StorageUnit}</Td>
                  <Td>{el?.NewCostPerUnit}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )
      }

    </Box>


      {/*Show Edit Quantity Transfers  Modal*/}
      {showEditItemsQuantity && ( 
        <EditQuantityItems  
          onClose={() => setShowEditItemsQuantity(false)} 
          data={transfersData.TransfersItemsList}
          id={id}
        >
        </EditQuantityItems>
      )}

      {/*Show Add Items To Transfers Modal*/}
      {showAddItem && (
        <AddItemstToTransfers
          onClose={() => setShowAddItem(false)}
          id={id}
        ></AddItemstToTransfers>
      )}
  </>
}
