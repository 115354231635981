import React, { useEffect, useState } from 'react';
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb"
import { Box, Flex, SimpleGrid, Spinner, Stack, Text } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button"
import EditDeliveryZoneModal from '../components/Modal/EditDeliveryZoneModal';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryZoneDetails } from '../services/deliveryZoneNewService';
import DeleteDeliveryZone from '../components/DeleteDeliveryZone';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';



const DeliveryZoneDetailsPage = () => {
  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 46);

    const id =useParams();
    const dispatch=useDispatch();
    const { t } = useTranslation();
    const data=[]
    const [showModal,setShowModal]=useState(false)


    useEffect(()=>{
        dispatch(getDeliveryZoneDetails(id.id))
    },[dispatch,id])

    const dataDetails = useSelector(state => state.deliveryZoneNew);

    if(ManageBranches===false  ){
      return(
        <NotAuthorizedCompo/>
      )
    }

    return (
    <div>
    <BreadCrumb>
    <Flex justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">
      <Box
        as="p" fontSize="1.6rem" textTransform="capitalize"
      >
          {dataDetails.deliveryZoneDetails[0]?.DeliveryZoneName || "-"} 
      </Box>
      <GButton
        title={t("general.edit")}
        bg="#54A0FF"
        color="light"
        hover={{ bg: "primary" }}
        onClick={() => setShowModal(true)}
       // onClick={() => setShowUpdateComboModal({ ...combo, ComboId: +comboId || null })}
      />
      
    </Flex>
  </BreadCrumb>

  <Stack spacing={12}>
  
  
  <SimpleGrid
    spacing={6}
    columns={{ base: 1, md: 2 }}
    bg="white"
    p={4} borderRadius={6}
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >
    <Box borderBottom="1px solid" borderColor="border">
      <Text textTransform="capitalize" color="text">
        {t("pages.inventory_item_details.name")}
      </Text>
      <Text marginBlock={2}>
        {dataDetails.deliveryZoneDetails[0]?.DeliveryZoneName  || "-"} 
      </Text>
    </Box>
    <Box borderBottom="1px solid" borderColor="border">
      <Text textTransform="capitalize" color="text">
        {t("pages.inventory_item_details.name_Localized")}
      </Text>
      <Text marginBlock={2}>
      {dataDetails.deliveryZoneDetails[0]?.DeliveryZoneNameLoc|| "-"} 
      </Text>
    </Box>

    <Box borderBottom="1px solid" borderColor="border">
      <Text textTransform="capitalize" color="text">
        {t("pages.DeliverZone.refrance")}
      </Text>
      <Text marginBlock={2}>
      {dataDetails.deliveryZoneDetails[0]?.RefCode|| "-"} 
      </Text>
    </Box>


    <Box borderBottom="1px solid" borderColor="border">
    <Text textTransform="capitalize" color="text">
      {t("pages.DeliverZone.Coordinates")}
    </Text>
    <Text marginBlock={2}>
    {dataDetails.deliveryZoneDetails[0]?.Coordinates|| "-"} 
    </Text>
  </Box>
    

  </SimpleGrid>
  
  <DeleteDeliveryZone></DeleteDeliveryZone>
  
  </Stack>



  {showModal&&
    (<EditDeliveryZoneModal onClose={() => setShowModal(false)}/>)
}



    </div>
  );
}

export default DeliveryZoneDetailsPage;
