import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import GButton from "components/ui/button/Button";
import GModal from "components/ui/modal/Modal";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/form/Input";
import {
  editCostAdjustment,
  getCostAdjustmentBranch,
} from "../../services/costAdjustment";
import { getCostAdjustmentDetails } from "../../services/costAdjustmentDetails";

export default function EditCostAdjustment({ onClose, id }) {
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { t } = useTranslation();
  const { handleSubmit, control, setValue } = useForm();

  const costAdjustMentData = useSelector((state) => state.costAdjustment);

  useEffect(() => {
    dispatch(getCostAdjustmentBranch());
  }, [dispatch]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.cost_adjustment.edit_cost_djustment")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.UserId = localStorage.getItem("userId");
                if (values.BranchId === undefined) {
                  toast2({ description: t("validation.choose_first") });
                } else {
                  values.BranchId = values.BranchId.BranchId;
                  values.CostAdjustmentId = id;
                  dispatch(editCostAdjustment(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t("pages.cost_adjustment.cost_updated"),
                      });
                      dispatch(getCostAdjustmentDetails(id));
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <MultiSelect
                  title={t("pages.cost_adjustment.branch")}
                  data={costAdjustMentData.costAdjustmentBranch.map((el) => ({
                    ...el,
                    label: el.BranchName,
                    value: el.BranchId,
                  }))}
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("BranchId", e)}
                />
                <Input
                  label={t("pages.cost_adjustment.notes")}
                  labelStyle={{ textTransform: "capitalize" }}
                  placeholder={t("pages.cost_adjustment.notes")}
                  name="Notes"
                  isFormController
                  control={control}
                  onChange={(e) => setValue("Notes", e.target.value)}
                />
                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      />
    </>
  );
}
