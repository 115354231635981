import { createSlice } from "@reduxjs/toolkit";
import {
  getSectiones,
  createSection,
  updateSection,
} from "../services/sectionsService";

let initialState = {
  isLoading: false,
  data: [],
  section: null,
  pages: 0,
  errors: null
};

const sectionesSlice = createSlice({
  name: "sectiones",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSectiones.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getSectiones.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getSectiones.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createSection.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createSection.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createSection.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateSection.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateSection.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const indexAt = state.data.findIndex(section => section.id === payload.id);
      // state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateSection.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default sectionesSlice.reducer;