import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import DeleteModal from "./modals/DeleteModal";

const DeleteTimedEvent = ({ idOfTimeEvent }) => {
  const { t } = useTranslation();
  const [showDeleteTimedEventModal, setShowDeleteTimedEventModal] =
    useState(null);

  return (
    <div>
      <Flex
        bg="light"
        borderRadius={6}
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        p={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Text fontSize=".9rem" color="text">
          {t("pages.timedEvents.delete_message")}
        </Text>
        <GButton
          bg="red"
          color="light"
          title={t("general.delete")}
          hover={{ bg: "red" }}
          onClick={() => setShowDeleteTimedEventModal(true)}
        />

        {showDeleteTimedEventModal && (
          <DeleteModal
            id={idOfTimeEvent}
            onClose={() => setShowDeleteTimedEventModal(null)}
          />
        )}
      </Flex>
    </div>
  );
};

export default DeleteTimedEvent;
