import React from 'react';
import DetailsItem from '../components/DetailsItem';

const InventoryItemsDetails = () => {
  return (
    <div>
        <DetailsItem></DetailsItem>
    </div>
  );
}

export default InventoryItemsDetails;
