import React from "react";
import { useTranslation } from "react-i18next";
import routes from "navigations/routes";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


export default function BusinessReports() {
  const retrievedArray =useDecryptedPrivilege()
 const Auth = checkIdExists(retrievedArray, 67);

  const { t, i18n } = useTranslation();
  const getArrowIcon = () => {
    return i18n.language === "ar" ? (
      <MdKeyboardArrowLeft size={25} /> // Right arrow for Arabic
    ) : (
      <MdKeyboardArrowRight size={25} /> // Left arrow for English
    );
  };

  const items = [
    {
      id: 1,
      text: t("pages.reports.business_reports.taxes"),
      icon: getArrowIcon(),
      path: routes.reports_business_reports_taxes,
    },
    {
      id: 2,
      text: t("pages.reports.business_reports.tips"),
      icon: getArrowIcon(),
      path: routes.reports_business_reports_tips,
    },
    {
      id: 3,
      text: t("pages.reports.business_reports.giftcards"),
      icon: getArrowIcon(),
      path: routes.reports_business_reports_gift_cards,
    },
    {
      id: 4,
      text: t("pages.reports.business_reports.business_days"),
      icon: getArrowIcon(),
      path: routes.reports_business_reports_business_days,
    },
    {
      id: 5,
      text: t("pages.reports.business_reports.shifts"),
      icon: getArrowIcon(),
      path: routes.reports_business_reports_shifts,
    },
    {
      id: 6,
      text: t("pages.reports.business_reports.tills"),
      icon: getArrowIcon(),
      path: routes.reports_business_reports_tills,
    },
    {
      id: 7,
      text: t("pages.reports.business_reports.drawer_operation"),
      icon: getArrowIcon(),
      path: routes.reports_business_reports_drawer_operations,
    },
    {
      id: 8,
      text: t("pages.reports.business_reports.voids_returns"),
      icon: getArrowIcon(),
      path: routes.reports_business_reports_void_returns,
    },
  ];

  if(Auth===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <BreadCrumb>
        <Box as="p" fontSize="1.6rem" textTransform="capitalize">
          {t("pages.reports.business_reports.business_reports")}
        </Box>
      </BreadCrumb>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
        {items.map((item) => (
          <Flex
            key={item.id}
            as={NavLink}
            to={`/${item.path}`}
            gap={2}
            alignItems="center"
            justifyContent="center"
            bg="light"
            p={4}
            borderRadius={4}
            paddingBlock={6}
            color="text"
            textTransform="capitalize"
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
          >
            {item.text}
            {item.icon}
          </Flex>
        ))}
      </SimpleGrid>
    </>
  );
}
