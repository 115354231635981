import { useTranslation } from "react-i18next";

const Card = ({ count, title }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        style={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1 style={{ fontSize: "20px" }}>{t(title)}</h1>
        </div>
        <h3
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            height: "100%",
            marginBottom: "10px",
            marginTop: "20px",
            marginLeft: "5px",
          }}
        >
          {count}
        </h3>
      </div>
    </div>
  );
};

export default Card;
