import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { Button, Flex, Text, Select, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import Pagination from "components/ui/pagination/Pagination";
import List from "../components/List";
import GButton from "../../../../components/ui/button/Button";
import NoCharges from "../components/NoCharges";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import { getCharges, resetChargesFilteredData } from "../services/chargesService";
import ExportModal from 'components/ui/modal/ExportModal';
import RestoreCharge from "../components/modals/RestoreCharge";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Charges = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 52);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const charges = useSelector(state => state.charges);

  const [showCreateChargeModal, setShowCreateChargeModal] = useState(null);
  const [showUpdateChargeModal, setShowUpdateChargeModal] = useState(null);
  const [showDeleteChargeModal, setShowDeleteChargeModal] = useState(null);
  const [showRestoreChargeModal, setShowRestoreChargeModal] = useState(null);
  const [showFilterChargesModal, setShowFilterChargesModal] = useState(null);
  const [exportModal,SetExportModal] = useState(false);
  const [allData, setallData] = useState([]);

  const [activeNavbar, setActiveNavbar] = useState("all");

  const resetFunction = () => {
    dispatch(resetChargesFilteredData());
  };

  const handelDeltedData = ()=>{
    const DeletedData = charges.data?.filter((el)=>{
        return el.Isdeleted === true ;
    })
    setallData(DeletedData);
  };
  const handelAllData = ()=>{
    const UnDeletedData = charges.data?.filter((el)=>{
        return el.Isdeleted === false ;
    })
    setallData(UnDeletedData);
  };

  useEffect(() => {
    dispatch(getCharges());
  }, [dispatch]);

  useEffect(() => {
    handelAllData();
  }, [charges?.data]);

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.charges.charges")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button" bg="none" border="1px solid" borderColor="border"
              textTransform="capitalize" fontSize="1rem" color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
                <Text>{t("general.export")}</Text>
            </Button>

            <GButton
              title={t("pages.charges.create_charge")}
              onClick={() => setShowCreateChargeModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />

          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap" justifyContent="space-between" paddingBlock={2}
        p={4} borderBottom="1px solid" borderColor="border" bg="light" gap={2}
      >
        <Flex
          alignItems="center" flexWrap="wrap"
          textTransform="capitalize" gap={4} color="text" bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom={activeNavbar === "all" ? "2px solid" : ""}
            borderColor="primary"
            onClick={()=>{
              handelAllData();
              setActiveNavbar("all");
            }
            }
          />

          <GButton
            title={t("general.deleted")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom={activeNavbar === "deleted" ? "2px solid" : ""}
            borderColor="primary"
            onClick={()=>{
              handelDeltedData();
              setActiveNavbar("deleted");
              }
             }
          />

        </Flex>

          <GButton
              title={
                  charges.filteredData?.length === 0
                  ? t("general.filter")
                  : t("general.clear_filter")
              }
              border="1px solid"
              borderColor="border"
              postfixElement={
                  charges.filteredData?.length === 0 ? (
                  <FiIcons.FiFilter size={16} />
                  ) : (
                  <IoCloseOutline fontWeight={800} size={20} />
                  )
              }
              hover={{ bg: "primary", borderColor: "primary", color: "light" }}
              onClick={
                  charges.filteredData?.length === 0
                  ? () => setShowFilterChargesModal(true)
                  : resetFunction
              }
          />
        
      </Flex>

      { charges.isLoading ? <>
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
          </Flex>
        </>
          :
        <>
            {
              charges?.filteredData?.length > 0 ? 
              (
                <List
                  data={charges?.filteredData}
                  handleUpdate={(charge) => setShowUpdateChargeModal(charge)}
                  handleDelete={(charge) => setShowDeleteChargeModal(charge)}
                />
              )
              :
              (
                    charges.data.length === 0 ? (
                      <NoCharges
                        onClickCreateChargeBtn={() => setShowCreateChargeModal(true)}
                      />
                    )
                    :
                    <List
                      data={allData}
                      handleUpdate={(charge) => setShowUpdateChargeModal(charge)}
                      handleDelete={(charge) => setShowDeleteChargeModal(charge)}
                      handleRestore={(charge) => setShowRestoreChargeModal(charge)}
                    />
              )
            }
        </>
      }

      {showCreateChargeModal && (
        <CreateModal
          onClose={() => setShowCreateChargeModal(null)}
        />
      )}

      {showUpdateChargeModal && (
        <UpdateModal
          data={showUpdateChargeModal}
          onClose={() => setShowUpdateChargeModal(null)}
        />
      )}

      {showDeleteChargeModal && (
        <DeleteModal
          data={showDeleteChargeModal}
          setActiveNavbar={setActiveNavbar}
          onClose={() => setShowDeleteChargeModal(null)}
        />
      )}

      {showRestoreChargeModal && (
        <RestoreCharge
          data={showRestoreChargeModal}
          setActiveNavbar={setActiveNavbar}
          onClose={() => setShowRestoreChargeModal(null)}
        />
      )}

      {showFilterChargesModal && (
        <FilterModal
          onClose={() => setShowFilterChargesModal(null)}
          data={charges?.data}
        />
      )}

    {exportModal&&(
        <ExportModal 
          onClose={() => SetExportModal(false)}   
          data={charges?.data}
          fileName={"charges"}
          >
        </ExportModal>
      )}

    </>
  )
}

export default Charges;