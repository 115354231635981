import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Button, Checkbox, Flex, Stack, useToast } from "@chakra-ui/react";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import {  getReservationsList, getSectionsInReservation, getTableInReservation, getTimeInReservation, updateReservation } from "../../services/reservationsService";
import { generator } from "utils/generator";
import { getTime } from "services/timeService";
import { getTaxesGroups } from "features/management/taxesGroups/services/taxesGroupsService";

const UpdateModal = ({ onClose , data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const { handleSubmit,  register,  control, watch,  getValues,  setValue} = useForm();

  const reservations = useSelector(state => state.reservations);
  console.log(reservations);
  console.log(data);

  const [selectTableSection, setSelectTableSection] = useState("sections");
  const [selectTableData, setSelectTableData] = useState([]);
  const [selectSectionData, setSelectSectionData] = useState([]);

  useEffect(() => {
    dispatch(getTimeInReservation());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTableInReservation(data.ReservationId));
  }, [dispatch , data]);

  useEffect(() => {
    dispatch(getSectionsInReservation(data.ReservationId));
  }, [dispatch , data]);

  useEffect(() => { 
      const SectionDropdown = reservations?.sectionsTime.filter((item)=>{
          return item.BranchSectionId === data?.BranchId
      });
      setSelectSectionData(SectionDropdown);
  }, [reservations?.sectionsTime]);

  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.reservations.update_reservation")}
      modalContent={(
        <Box p={4}>
          <form onSubmit={handleSubmit( values => {
            if(selectTableSection === "tables"){
                  values.SectonTables = values.SectonTables?.map(el => ({TableId: el.TableId , ReservationId : data.ReservationId}));
                  values.SaturDayTimeId = values.SaturDayTimeId?.TimeRangId;
                  values.SunDayTimeId = values.SunDayTimeId?.TimeRangId;
                  values.MonDayTimeId = values.MonDayTimeId?.TimeRangId;
                  values.TuDayTimeId = values.TuDayTimeId?.TimeRangId;
                  values.WenDayTimeId = values.WenDayTimeId?.TimeRangId;
                  values.ThDayTimeId = values.ThDayTimeId?.TimeRangId;
                  values.FriDayTimeId = values.FriDayTimeId?.TimeRangId;
                  values.UserId = 1;
                  console.log(values);
            }
            else {
                  values.SectonTables = values.SectonTables;
                  values.SectonTables = values.SectonTables?.map(el => ({BranchSectionId: el.BranchSectionId}));
                  values.SaturDayTimeId = values.SaturDayTimeId?.TimeRangId;
                  values.SunDayTimeId = values.SunDayTimeId?.TimeRangId;
                  values.MonDayTimeId = values.MonDayTimeId?.TimeRangId;
                  values.TuDayTimeId = values.TuDayTimeId?.TimeRangId;
                  values.WenDayTimeId = values.WenDayTimeId?.TimeRangId;
                  values.ThDayTimeId = values.ThDayTimeId?.TimeRangId;
                  values.FriDayTimeId = values.FriDayTimeId?.TimeRangId;
                  values.UserId = 1;
                  values.ReservationId = data.ReservationId;
                  console.log(values);
            }
            dispatch(updateReservation({ values }))
              .unwrap()
              .then(_ => {
                toast({ description: t("pages.reservations.reservation_updated") });
                dispatch(getReservationsList());
                onClose();
              });
          })}>
            <Stack spacing={6}>
              <Input
                label={t("pages.reservations.reservation_duration")}
                labelStyle={{ textTransform: "capitalize" }}
                placeholder={t("pages.reservations.reservation_duration")}
                name="ReservationDuration"
                isFormController
                isRequired
                control={control}
              />
              <Box>
                  <Flex gap={3}>
                      <Button
                        onClick={()=>{setSelectTableSection("sections")}}
                        bg={selectTableSection === "sections" ? "primary" : ""}
                        color={selectTableSection === "sections" ? "white" : ""}
                        _hover={{ bg: "primary", color: "light" }}
                      > 
                      {t("pages.reservations.sections")}
                      </Button>

                      <Button
                        onClick={()=>{setSelectTableSection("tables")}}
                        bg={selectTableSection === "tables" ? "primary" : ""}
                        color={selectTableSection === "tables" ? "white" : ""}
                        _hover={{ bg: "primary", color: "light" }}
                      > 
                      {t("pages.reservations.tables")}
                      </Button>

                  </Flex>
                  <MultiSelect
                    // title={t("pages.reservations.tables")}
                    data={
                      selectTableSection === "tables" ? 
                      selectTableData?.map(el => ({
                          ...el,
                          label: el.TableName,
                          value: el.TableId
                        }))
                      : 
                      selectSectionData?.map(el => ({
                          ...el,
                          label: el.BranchSectionName,
                          value: el.BranchSectionId
                        }))
                      }
                    isMulti={true}
                    onChange={(e) => setValue("SectonTables", e)}
                  />
              </Box>


              <MultiSelect
                data={reservations.daysTime && reservations.daysTime.map(el => ({
                  ...el,
                  label: el.RangeName,
                  value: el.TimeRangId
                }))}
                onChange={(e) => setValue("SaturDayTimeId", e)}
                isMulti={false}
                isRequired
                title={t("pages.reservations.saturday")}
              />
              <MultiSelect
                data={reservations.daysTime && reservations.daysTime.map(el => ({
                  ...el,
                  label: el.RangeName,
                  value: el.TimeRangId
                }))}
                onChange={(e) => setValue("SunDayTimeId", e)}
                isMulti={false}
                isRequired
                title={t("pages.reservations.sunday")}
              />
              <MultiSelect
                data={reservations.daysTime && reservations.daysTime.map(el => ({
                  ...el,
                  label: el.RangeName,
                  value: el.TimeRangId
                }))}
                onChange={(e) => setValue("MonDayTimeId", e)}
                isMulti={false}
                isRequired
                title={t("pages.reservations.monday")}
              />

              <MultiSelect
                data={reservations.daysTime && reservations.daysTime.map(el => ({
                  ...el,
                  label: el.RangeName,
                  value: el.TimeRangId
                }))}
                onChange={(e) => setValue("TuDayTimeId", e)}
                isMulti={false}
                isRequired
                title={t("pages.reservations.tuseday")}
              />

              <MultiSelect
                data={reservations.daysTime && reservations.daysTime.map(el => ({
                  ...el,
                  label: el.RangeName,
                  value: el.TimeRangId
                }))}
                onChange={(e) => setValue("WenDayTimeId", e)}
                isMulti={false}
                isRequired
                title={t("pages.reservations.wendnesday")}
              />

              <MultiSelect
                data={reservations.daysTime && reservations.daysTime.map(el => ({
                  ...el,
                  label: el.RangeName,
                  value: el.TimeRangId
                }))}
                onChange={(e) => setValue("ThDayTimeId", e)}
                isMulti={false}
                isRequired
                title={t("pages.reservations.thursday")}
              />

              <MultiSelect
                data={reservations.daysTime && reservations.daysTime.map(el => ({
                  ...el,
                  label: el.RangeName,
                  value: el.TimeRangId
                }))}
                onChange={(e) => setValue("FriDayTimeId", e)}
                isMulti={false}
                isRequired
                title={t("pages.reservations.friday")}
              />

              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={reservations.isLoading}
                  hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={reservations.isLoading}
                  hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      )}
    />
  )
}

export default UpdateModal