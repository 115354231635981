import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import AddNewDepartmentsModal from "./components/modal/AddNewDepartmentsModal";

export default function HrSettingDepartment() {
  const disPatch = useDispatch();
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);
  const data = useSelector((state) => state.hrSetting);
  // useEffect(() => {
  //   disPatch(getHrNationalityList());
  // }, [disPatch]);

  return (
    <>
      <Box>
        <Box
          bg="light"
          marginInline={-6}
          marginTop={-6}
          padding={6}
          borderTop="1px solid"
          borderColor="border"
          marginBottom={6}
        >
          <Flex alignItems="center" justifyContent="space-between" gap={4}>
            <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
              {t("pages.hr.setting_department")}
            </Text>

            <Flex gap={2}>
              <Button
                type="button"
                bg="primary"
                color="light"
                textTransform="capitalize"
                fontSize="1rem"
                onClick={() => setShowAddModal(true)}
              >
                <Flex alignItems="center" gap={1}>
                  {t("pages.hr.add_department")}
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Box>

        {/* <HrSettingNationalityTable
          data={data?.nationalityList}
          isLoading={data?.isLoading}
          error={data?.error}
        /> */}

        {showAddModal && (
          <AddNewDepartmentsModal onClose={() => setShowAddModal(false)} />
        )}
      </Box>
    </>
  );
}
