import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import Pagination from "components/ui/pagination/Pagination";

const CTable = ({ modifiersOptions }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const navigatefunction = (id) => {
    navigate(`${id}`);
  };

  const filteredModifiersOptions = useSelector( (state) => state.modifiersOptions);

/* --------------------------------------------- Start Pagination  -----------------------------------------------*/
    const recordsPerPage = 10;
    const [currentPageActive, setCurrentPageActive] = useState(1);
    const prePage = (currentPage, setCurrentPage) => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    const nextPage = (currentPage, setCurrentPage, npage) => {
      if (currentPage < npage) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const calculateRecords = (data, currentPage, recordsPerPage) => {
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, data.length);
      return data.slice(firstIndex, lastIndex);
    };
  
      // Calculate records for activeDiscounts
      const modifiresOptionsData = calculateRecords(
        modifiersOptions,
        currentPageActive,
        recordsPerPage
      );
      const modifiresOptionsFilteredData = calculateRecords(
        filteredModifiersOptions?.filteredData,
        currentPageActive,
        recordsPerPage
      );
    /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{t("pages.modifiers_options.name")}</Th>
            <Th>{t("pages.modifiers_options.name_localized")}</Th>
            <Th>{t("pages.modifiers_options.modifier")}</Th>
            <Th>{t("pages.modifiers_options.price")}</Th>
            <Th>{t("pages.modifiers_options.tax_group")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredModifiersOptions.filteredData.length === 0 ?
               modifiresOptionsData?.map((modifiersOption, index) => (
                <Tr
                  key={modifiersOption.ModiferOptionId}
                  onClick={() =>
                    navigatefunction(modifiersOption.ModiferOptionId)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Td>{index + 1}</Td>
                  <Td>{modifiersOption.ModiferOptionNameEng}</Td>
                  <Td>{modifiersOption.ModiferOptionNameAr}</Td>
                  <Td>{modifiersOption.ModifersNameEng}</Td>
                  <Td>{modifiersOption.ModiferOptionPrice|| "-"}</Td>
                  <Td>{modifiersOption.TaxGroupName}</Td>
                </Tr>
              ))
            : modifiresOptionsFilteredData?.map(
                (modifiersOption, index) => (
                  <Tr
                    key={modifiersOption.ModiferOptionId}
                    onClick={() =>
                      navigatefunction(modifiersOption.ModiferOptionId)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td>{modifiersOption.ModiferOptionNameEng}</Td>
                    <Td>{modifiersOption.ModiferOptionNameAr}</Td>
                    <Td>{modifiersOption.ModifersNameEng}</Td>
                    <Td>{modifiersOption.ModiferOptionPrice|| "-"}</Td>
                    <Td>{modifiersOption.TaxGroupName}</Td>
                  </Tr>
                )
              )}
        </Tbody>
      </Table>

      { filteredModifiersOptions?.filteredData?.length === 0 ? 
        <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(modifiersOptions.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(modifiersOptions.length / recordsPerPage)
                )
              }
        />
      :
        <Pagination
           currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(modifiresOptionsFilteredData?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(modifiresOptionsFilteredData?.length / recordsPerPage)
                )
              }
        />
      }

    </Box>
  );
};

export default CTable;
