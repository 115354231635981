export function groupByNumber(obj) {
  const result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const match = key.match(/\d+$/);
      const number = match ? match[0] : null;

      if (number !== null) {
        // If a number is found, create an array for that number if it doesn't exist
        if (!result[number]) {
          result[number] = [];
        }

        // Push the key-value pair to the array for the corresponding number
        result[number].push({ key, value: obj[key] });
      }
    }
  }

  // Convert the result object into a flat array of objects with 'items' property
  const finalResult = Object.keys(result).map((number) => ({
    items: {
      Row_Number: number,
      ...result[number].reduce(
        (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
        {}
      ),
    },
  }));

  return finalResult;
}
