import React from 'react';
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useDispatch } from 'react-redux';
import { deleteDeliveryZone } from '../../services/deliveryZoneNewService';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from "navigations/routes";
import { useToast } from '@chakra-ui/react';


const DeleteDeliveryZoneModal = ({onClose}) => {
    const dispatch=useDispatch()
    const id=useParams()
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
  return (
    <GDeleteModal
    onClose={onClose}
    onSubmit={()=>{
        dispatch(deleteDeliveryZone(id.id)).unwrap().then(_ => {
                toast({ description: t("pages.inventory.delete_item") });
                onClose();
                navigate(`/${routes.management_DeliverZone}`);
              })
    }}
    
/>
  );
}

export default DeleteDeliveryZoneModal;
