import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

  /* -------------------------------------- Branch Sales Report List ------------------------------- */
  export const GetBranchSalesReport = createAsyncThunk("salesReport/GetBranchSalesReport",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetBranchSalesReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
 /* --------------------------------------- Branch Sales Filter Data ------------------------------*/
 export const getBranchSalesReportFilteredData = createAsyncThunk("salesReport/getBranchSalesReportFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetBranchSalesReportFilteredData = createAsyncThunk( "salesReport/resetBranchSalesReportFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);




  /* -------------------------------------- Cashier Sales Report List ------------------------------- */
  export const GetCashierSalesReport = createAsyncThunk("salesReport/GetCashierSalesReport",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetCashierSalesReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
 /* --------------------------------------- Cashier Sales Filter Data ------------------------------*/
 export const getCashierSalesReportFilteredData = createAsyncThunk("salesReport/getCashierSalesReportFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetCashierSalesReportFilteredData = createAsyncThunk( "salesReport/resetCashierSalesReportFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);




  /* -------------------------------------- Category Sales Report List ------------------------------- */
  export const GetCategorySalesReport = createAsyncThunk("salesReport/GetCategorySalesReport",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetCategorySalesReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
 /* --------------------------------------- Category Sales Filter Data ------------------------------*/
 export const getCategorySalesReportFilteredData = createAsyncThunk("salesReport/getCategorySalesReportFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetCategorySalesReportFilteredData = createAsyncThunk( "salesReport/resetCategorySalesReportFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);




  /* -------------------------------------- Combo Sales Report List ------------------------------- */
  export const GetComboSalesReport = createAsyncThunk("salesReport/GetComboSalesReport",  
    async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`https://rostoapi.smartgate-egypt.com/Reports/GetComboSalesReport`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
 /* --------------------------------------- Combo Sales Filter Data ------------------------------*/
 export const getComboSalesReportFilteredData = createAsyncThunk("salesReport/getComboSalesReportFilteredData ", 
  async (args, thunkApi) => {
  try {
    return (args);
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
export const resetComboSalesReportFilteredData = createAsyncThunk( "salesReport/resetComboSalesReportFilteredData ", 
  async (args, thunkApi) => { 
  try {
    return args;
  } catch (e) {
    return thunkApi.rejectWithValue(e.response.data);
  }
}
);
