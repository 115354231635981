import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import {
  Button,
  Flex,
  Text,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import ImportModal from "../components/modals/ImportModal";
// import ExportModal from "../components/modals/ExportModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import {
  getModifiersOptions,
  resetFilteredData,
} from "../services/modifiersOptionsService";
import NoData from "components/ui/noData/NoData";
import ExportModal from "components/ui/modal/ExportModal";
import { IoCloseOutline } from "react-icons/io5";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const ModifiersOptions = () => {


  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const dispatch = useDispatch();
  const modifiersOptions = useSelector((state) => state.modifiersOptions);
  const { t } = useTranslation();

  const [showCreateModifiersOptionModal, setShowCreateModifiersOptionModal] =
    useState(null);
  const [showUpdateModifiersOptionModal, setShowUpdateModifiersOptionModal] =
    useState(null);
  const [showDeleteModifiersOptionModal, setShowDeleteModifiersOptionModal] =
    useState(null);
  const [showImportModifiersOptionsModal, setShowImportModifiersOptionsModal] =
    useState(null);
  const [showExportModifiersOptionsModal, setShowExportModifiersOptionsModal] =
    useState(null);
  const [showFilterModifiersOptionsModal, setShowFilterModifiersOptionsModal] =
    useState(null);
  const [exportModal, SetExportModal] = useState(false);

  useEffect(() => {
    dispatch(getModifiersOptions());
  }, [dispatch]);

  const resetFunction = () => {
    dispatch(resetFilteredData());
  };

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  if(showCreateModifiersOptionModal===true && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowCreateModifiersOptionModal(false)
  }

  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.modifiers_options.modifiers_options")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            {/* <Menu>
              <MenuButton as={Button}
                bg="none"
                border="1px solid"
                borderColor="border"
                color="text"
                _hover={{ bg: "primary", borderColor: "primary", color: "light" }}
              >
                <Flex alignItems="center" gap={2}>
                  <FiIcons.FiCloud size={16} />
                  <Text>{t("general.import_export")}</Text>
                  <FiIcons.FiChevronDown size={16} />
                </Flex>
              </MenuButton>
              <MenuList p={0} minW="auto">
                <MenuItem onClick={() => setShowImportModifiersOptionsModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiUpload />
                    <Text textTransform="capitalize">{t("general.import")}</Text>
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => setShowExportModifiersOptionsModal(true)}>
                  <Flex alignItems="center" gap={2}>
                    <FiIcons.FiDownload />
                    <Text textTransform="capitalize">{t("general.export")}</Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu> */}

            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
            >
              <div onClick={() => SetExportModal(true)}>
                {t("general.export")}
              </div>
            </Button>

            <GButton
              title={t("pages.modifiers_options.create_modifiers_option")}
              onClick={() => setShowCreateModifiersOptionModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
        </Flex>
      </BreadCrumb>

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            color="primary"
            isDisabled={false}
            borderRadius={0}
            borderBottom="2px solid"
            borderColor="primary"
          />
        </Flex>

        {/* <GButton
          title={t("general.filter")}
          border="1px solid"
          borderColor="border"
          postfixElement={<FiIcons.FiFilter size={16} />}
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          onClick={() => setShowFilterModifiersOptionsModal(true)}
        /> */}

        <GButton
          // isDisabled={filteredGifts.filteredData.length === 0 ? false : true}
          // isDisabled={filteredGifts.filteredData.length === 0 ? false : true}
          title={
            modifiersOptions.filteredData.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
            modifiersOptions.filteredData.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          // onClick={() => setShowFilterModal(true)}
          onClick={
            modifiersOptions.filteredData.length === 0
              ? () => setShowFilterModifiersOptionsModal(true)
              : resetFunction
          }
        />
      </Flex>

      {modifiersOptions.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : modifiersOptions.data.length === 0 ? (
        <NoData
          btnTitle={t("pages.modifiers_options.create_modifiers_option")}
          title={t("pages.modifiers_options.no_data_hint")}
          onClickCreateBtn={() => setShowCreateModifiersOptionModal(true)}
          onClickCreateModifiersOptionBtn={() =>
            setShowCreateModifiersOptionModal(true)
          }
        />
      ) : (
        <Table
          modifiersOptions={modifiersOptions.data}
          handleUpdate={(modifiersOption) =>
            setShowUpdateModifiersOptionModal(modifiersOption)
          }
          handleDelete={(modifiersOption) =>
            setShowDeleteModifiersOptionModal(modifiersOption)
          }
        />
      )}

      {showCreateModifiersOptionModal && (
        <CreateModal onClose={() => setShowCreateModifiersOptionModal(null)} />
      )}

      {showUpdateModifiersOptionModal && (
        <UpdateModal
          data={showUpdateModifiersOptionModal}
          onClose={() => setShowUpdateModifiersOptionModal(null)}
        />
      )}

      {showDeleteModifiersOptionModal && (
        <DeleteModal
          data={showDeleteModifiersOptionModal}
          onClose={() => setShowDeleteModifiersOptionModal(null)}
        />
      )}

      {/* {showImportModifiersOptionsModal && (
        <ImportModal onClose={() => setShowImportModifiersOptionsModal(null)} />
      )}

      {showExportModifiersOptionsModal && (
        <ExportModal onClose={() => setShowExportModifiersOptionsModal(null)} />
      )} */}

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={modifiersOptions.data}
          fileName={"Modifiers Options"}
        ></ExportModal>
      )}

      {showFilterModifiersOptionsModal && (
        <FilterModal data={modifiersOptions.data} onClose={() => setShowFilterModifiersOptionsModal(null)} />
      )}
    </>
  );
};

export default ModifiersOptions;
