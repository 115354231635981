import { createSlice } from "@reduxjs/toolkit";
import { getBusinessDaysReportFilteredData, getBusinessDaysReportList, resetBusinessDaysReportFilteredData } from "../services/businessDaysReportServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const businessDaysReportSlice = createSlice({
  name: "businessDaysReport",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------- Business Days Cards  Report List ------------------------------- */
  builder.addCase(getBusinessDaysReportList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getBusinessDaysReportList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getBusinessDaysReportList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

/* ------------------------------------------------------------------------------------------- */
 /* --------------------------------------- Gift Cards Filter Data ----------------------------*/
 builder.addCase(getBusinessDaysReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getBusinessDaysReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getBusinessDaysReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetBusinessDaysReportFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetBusinessDaysReportFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetBusinessDaysReportFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default businessDaysReportSlice.reducer;