import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

/* ------------------------------------------------- GiftCards  DropDown List ------------------------------------------------ */
export const giftCardDropList = createAsyncThunk("giftsCard/giftCardDropList", async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Manage/GiftCards/LookupGiftCards`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
  /* ------------------------------------------------- Get GiftCards List ------------------------------------------------ */
  export const giftCardList = createAsyncThunk( "giftsCard/giftCardList",  async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Group/GroupGiftCardsListById?groupid={"MenuGroupId":${args}}`);
        return thunkApi.fulfillWithValue(data);

      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );
/* ------------------------------------------------- Add GiftCards  ------------------------------------------------ */
  export const addgiftCard = createAsyncThunk("giftsCard/addgiftCard",   async (args, thunkApi) => {
      try {
        const { data } = await axios.get(`/Menu/Group/AddGroupGiftCards?groupgiftcards=${JSON.stringify(args)}`);
        return thunkApi.fulfillWithValue(data);
      } catch (e) {
        return thunkApi.rejectWithValue(e.response.data);
      }
    }
  );