import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import List from "../components/List";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import NoData from "components/ui/noData/NoData";
import { getTags } from "../services/tagsService";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth"
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Tags = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageBranches = checkIdExists(retrievedArray, 53);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showCreateTagModal, setShowCreateTagModal] = useState(null);
  const [showUpdateTagModal, setShowUpdateTagModal] = useState(null);
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(null);

  const tags = useSelector(state => state.tags);

  useEffect(() => {
    dispatch(getTags());
  }, [dispatch]);

  if(ManageBranches===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.tags.tags")}
          </Text>
        </Flex>
      </BreadCrumb>

      {tags.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : tags.data.length === 0 ? (
        <NoData
          title={t("pages.tags.no_data_hint")}
          btnTitle={t("pages.tags.create_tag")}
          onClickCreateBtn={() => setShowCreateTagModal(true)}
        />
      ) : (
        <List
          tags={tags.data}
          handleCreate={(tag) => setShowCreateTagModal(tag)}
          handleUpdate={(tag) => setShowUpdateTagModal(tag)}
          handleDelete={(tag) => setShowDeleteTagModal(tag)}
        />
      )}

      {showCreateTagModal && (
        <CreateModal
          data={showCreateTagModal}
          onClose={() => setShowCreateTagModal(null)}
        />
      )}

      {showUpdateTagModal && (
        <UpdateModal
          data={showUpdateTagModal}
          onClose={() => setShowUpdateTagModal(null)}
        />
      )}

      {showDeleteTagModal && (
        <DeleteModal
          id={showDeleteTagModal}
          onClose={() => setShowDeleteTagModal(null)}
        />
      )}
    </>
  )
}

export default Tags;