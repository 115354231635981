import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button"
import ItemTagsTable from './ItemTagsTable';
import AddTagsModal from './modals/AddTagsModal';
import { useDispatch, useSelector } from 'react-redux';
import {getTagsOfItem} from "../services/itemTagsService"
import { useParams } from 'react-router-dom';
import { checkIdExists } from 'utils/cheackAuth';
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';



const ItemTags = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageItems = checkIdExists(retrievedArray, 10);

    const { t } = useTranslation();
    const [ShowAddTagModal, setShowAddTagModal] = useState(false);
    const dispatch=useDispatch();
    const id = useParams();

    const tagsOfItem = useSelector(state => state.tagsOfItem);

    useEffect(()=>{
      dispatch(getTagsOfItem(id.itemId))
    },[dispatch,id]);

    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


    if(ShowAddTagModal===true && ManageItems===false){
      toast2({ description:  t("general.authorization")})
      setShowAddTagModal(false)
    }

  return (
    <Box>
    <Flex
    alignItems="center" justifyContent="space-between"
    gap={2} flexWrap="wrap" mb={6}
  >
    <Text color="text" textTransform="capitalize">
      {t("pages.branches.tags")}
    </Text>
    <GButton
      title={t("pages.tags.add_tag")}
      bg="light"
      color="text"
      fontSize="14px"
      border="1px solid"
      borderColor="border"
      hover={{ bg: "light" }}
      onClick={() => setShowAddTagModal( true)}
    />
  </Flex>


  
  {tagsOfItem.isLoading ? (
    <Spinner />
  ) : tagsOfItem.itemTags?.length === 0 ? (
    <Flex
      p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
    >
      {t("pages.branches.no_data")}
    </Flex>
  ) : tagsOfItem.itemTags?.length > 0 ? (
    <ItemTagsTable
    ItemTagsData={tagsOfItem.itemTags}
    />
  ):(
    <Flex
    p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  >
    {t("pages.branches.no_data")}
  </Flex>
  )
}


 

{ShowAddTagModal && (
  <AddTagsModal
  //data={showCreateSectionModal}
  onClose={() => setShowAddTagModal(false)}
  />
)}

    </Box>
  );
}

export default ItemTags;
