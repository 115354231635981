import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Stack, useToast } from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { createRole, getRoles } from "../../services/rolesService";

const CreateModal = ({ onClose }) => {
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const dispatch = useDispatch();
  const roles= useSelector((state) => state.roles);
  const { handleSubmit, control, register, getValues, setValue, watch ,formState: { errors }, trigger} =
    useForm();
  const { t } = useTranslation();

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };



  return (
    <GModal
      onClose={onClose}
      modalHeader={t("pages.roles.create_role")}
      modalContent={
        <Box p={4}>
          <form
            onSubmit={handleSubmit((values) => {
              dispatch(createRole(values))
                .unwrap()
                .then(_ => {
                  toast({ description: t("pages.roles.role_created") });
                  dispatch(getRoles())
                  onClose()
                });
              console.log(values);
            })}
          >
            <Stack spacing={6}>

              <FormControl isInvalid={errors.roleName}>
              <FormLabel htmlFor="roleName" style={{textTransform:"capitalize"}}>
              {t("pages.devices.name")}
              </FormLabel>
              <Controller
                name="roleName"
                control={control}
                defaultValue=""
                rules={{
                  required: t("validation.required"),
                  minLength: {
                    value: 2,
                    message: t("validation.name_long_2"),
                  },
                  maxLength: {
                    value: 25,
                    message: t("validation.name_long_25"),
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message:t("validation.this_name_invalid") ,
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("pages.devices.name")}
                    onChange={(e)=>handleInputChange(e, "roleName")} 
                  />
                )}
              />
              <FormErrorMessage>{errors.roleName && errors.roleName.message}</FormErrorMessage>
            </FormControl>




              <Flex gap={4}>
                <GButton
                  type="submit"
                  title={t("general.save")}
                  bg="primary"
                  color="light"
                  border="1px solid transparent"
                  isLoading={roles.isLoading}
                  hover={{
                    color: "primary",
                    borderColor: "primary",
                    bg: "none",
                  }}
                />
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  isLoading={roles.isLoading}
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Stack>
          </form>
        </Box>
      }
    />
  );
};

export default CreateModal;
