import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "components/ui/noDataFound/NoDataFound";
import GroupShiftTable from "../components/GroupShiftTable";
import { getGroupShift } from "../services/attendanceSettingServices";
import { useNavigate } from "react-router-dom";

export default function GroupShift() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.attendanceSetting);
  useEffect(() => {
    dispatch(getGroupShift());
  }, [dispatch]);

  //--------------------------------- Compponents Return ----------------------------------------//
  if (data?.isLoading) {
    return (
      <Flex
        bg="light"
        p={4}
        gap={2}
        justifyContent="center"
        flexDir={"column"}
        alignItems={"center"}
      >
        <Spinner />
        <Text fontWeight={"bold"}> Loading </Text>
      </Flex>
    );
  }

  return (
    <>
      <Box>
        <Box
          bg="light"
          marginInline={-6}
          marginTop={-6}
          padding={6}
          borderTop="1px solid"
          borderColor="border"
          marginBottom={6}
        >
          <Flex alignItems="center" justifyContent="space-between" gap={4}>
            <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
              {t("pages.hr.attendance.group_shift")}
            </Text>

            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => navigate("/addgroupshift")}
            >
              {t("pages.hr.attendance.new_group")}
            </Button>
          </Flex>
        </Box>

        <Box>
          {data?.GroupShiftList?.length > 0 ? (
            <GroupShiftTable />
          ) : (
            <NoDataFound />
          )}
        </Box>
      </Box>
    </>
  );
}
