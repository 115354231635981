import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GDeleteModal from "components/ui/modal/DeleteModal";
import { useNavigate } from "react-router-dom";
import routes from "navigations/routes";
import { deleteDiscount } from "../../services/discountsService";

const DeleteModal = ({ id, onClose }) => {
  console.log("id", id);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const dispatch = useDispatch();
  const discounts = useSelector((state) => state.discounts);

  return (
    <GDeleteModal
      onClose={onClose}
      onSubmit={() => {
        dispatch(deleteDiscount(id))
          .unwrap()
          .then((_) => {
            toast({ description: t("pages.discounts.discount_deleted") });
            onClose();
            navigate(`/${routes.management_discount}`);
          });
      }}
      isLoading={discounts.isLoading}
    />
  );
};

export default DeleteModal;
