import { createSlice } from "@reduxjs/toolkit";
import { getInventoryItemsCostFilteredData, getInventoryItemsCostReportList, resetInventoryItemsCostFilteredData } from "../services/inventoryItemsCostServices";


let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const inventoryItemsCostReport = createSlice({
  name: "inventoryItemsCostReport",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------------- Get CostAdjustment Reports List ------------------------------------ */
    builder.addCase(getInventoryItemsCostReportList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getInventoryItemsCostReportList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getInventoryItemsCostReportList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

   /* --------------------------------------------------------------------------------------------------- */
 /* --------------------------------------- Menu Engineering Filter Data ------------------------------*/
    builder.addCase(getInventoryItemsCostFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getInventoryItemsCostFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getInventoryItemsCostFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetInventoryItemsCostFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetInventoryItemsCostFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetInventoryItemsCostFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default inventoryItemsCostReport.reducer;