import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import EditReciveOnlineOrderModal from './modal/EditReciveOnlineOrderModal';
import Pagination from "components/ui/pagination/Pagination";
import { Spinner } from '@chakra-ui/react';


export default function OnlineOrdersTable ({data}){
    const { t } = useTranslation();
    const [showEditModal,setShowEditModal]=useState(false);
    const onlineOrders = useSelector(state => state.RecivingOrders);

/* --------------------------------------------- Start Pagination  -----------------------------------------------*/
        const recordsPerPage = 10;
        const [currentPageActive, setCurrentPageActive] = useState(1);
        const prePage = (currentPage, setCurrentPage) => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          }
        };
      
        const nextPage = (currentPage, setCurrentPage, npage) => {
          if (currentPage < npage) {
            setCurrentPage(currentPage + 1);
          }
        };
      
        const calculateRecords = (data, currentPage, recordsPerPage) => {
          const firstIndex = (currentPage - 1) * recordsPerPage;
          const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
          return data?.slice(firstIndex, lastIndex);
        };
      
      // Calculate records for activeDiscounts
      const onlineOrdaringData = calculateRecords(
          data,
          currentPageActive,
          recordsPerPage
        );
 /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return <>
    <Box
    bg="light" overflow="auto"
    borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >
    { 
      onlineOrders?.isLoading  && ( 
      <Flex p={4}  bg="white"  alignItems="center" justifyContent="center">
          <Spinner /> 
      </Flex>)
    }
<Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.online_Orders.branch_name")}</Th>
          <Th>{t("pages.online_Orders.casher_device")}</Th>
          <Th>{t("pages.online_Orders.status")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {onlineOrdaringData.map((el, index) => (
          <Tr key={index}  style={{cursor:"pointer"}} onClick={()=>setShowEditModal(el)} >
            <Td> {index+1} </Td>
            <Td>  {el.BranchName} </Td>
            <Td>{el.DevicesName}</Td>
            <Td>
                {
                  el.DevicStatus?
                    <Box
                      height="15px" width="15px" borderRadius="50%"
                      bg={"#65B741"} margin="auto"
                    />
                  : 
                    <Box
                      height="15px" width="15px" borderRadius="50%"
                      bg={"#ED5253"} margin="auto"
                    />
                }
            </Td>
          </Tr>
  ))}
      </Tbody>
    </Table>  
    <Pagination
            currentPage={currentPageActive}
            setCurrentPage={setCurrentPageActive}
            npage={Math.ceil(data?.length / recordsPerPage)}
            prePage={() => prePage(currentPageActive, setCurrentPageActive)}
            nextPage={() =>
              nextPage(
                currentPageActive,
                setCurrentPageActive,
                Math.ceil(data?.length / recordsPerPage)
                )
              }
        />
  </Box>
  { showEditModal &&(
        <EditReciveOnlineOrderModal 
            onClose={() => setShowEditModal(false)}
            detailsData={showEditModal}
            />
  )}
  </>;
};
