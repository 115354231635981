import { useState } from "react";
import { useTranslation } from "react-i18next";
// import { useForm } from "react-hook-form";
import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import Input from "components/form/Input";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredDataDeliveryZone } from "../../services/deliveryZoneNewService";


const FilterModalDelivery = ({onClose}) => {
    const { t } = useTranslation();
    const data = useSelector(state => state.deliveryZoneNew);
    const [filteredData, setFilteredData] = useState(data.data);
    const dispatch = useDispatch();

  const [filterCriteria, setFilterCriteria] = useState({
    DeliveryZoneName: "",
    RefCode: "",
  });

  const handleFilterGiftsCard = (e) => {
    e.preventDefault();
    const filteredResult = data?.data?.filter((item) => {
      const {
        DeliveryZoneName,
        RefCode,
      } = filterCriteria;


      let filterDeliveryZoneName = true;
      let filterRefCode = true;

      if (DeliveryZoneName) {
        filterDeliveryZoneName = item.DeliveryZoneName.toLowerCase().includes(DeliveryZoneName.toLowerCase());
      }

      if (RefCode) {
        filterRefCode = item.RefCode.toLowerCase().includes(RefCode.toLowerCase());
      }
      return (
        filterDeliveryZoneName &&
        filterRefCode 
      );
    });

    setFilteredData(filteredResult);
    onClose(filteredResult);
    dispatch(getFilteredDataDeliveryZone(filteredResult));
  };




  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.DeliverZone.filter_delivery_zone")}
    modalContent={
      <Box p={4}>
        <form onSubmit={handleFilterGiftsCard}>
          <Stack spacing={6}>
            <Input
              label={t("pages.categories.name")}
              labelStyle={{ textTransform: "capitalize" }}
              placeholder={t("pages.categories.name")}
              name="DeliveryZoneName"
              value={filterCriteria.DeliveryZoneName}
              onChange={(e) =>
                setFilterCriteria({
                  ...filterCriteria,
                  DeliveryZoneName: e.target.value,
                })
              }
            />
            <Input
              label={t("pages.DeliverZone.refrance")}
              labelStyle={{ textTransform: "capitalize" }}
              placeholder={t("pages.DeliverZone.refrance")}
              name="RefCode"
              value={filterCriteria.RefCode}
              onChange={(e) =>
                setFilterCriteria({
                  ...filterCriteria,
                  RefCode: e.target.value,
                })
              }
            />

           

            <Flex gap={4}>
              <GButton
                type="submit"
                title={t("general.save")}
                bg="primary"
                color="light"
                border="1px solid transparent"
                hover={{
                  color: "primary",
                  borderColor: "primary",
                  bg: "none",
                }}
              />
              <GButton
                title={t("general.close")}
                onClick={onClose}
                bg="red.600"
                border="1px solid transparent"
                color="light"
                hover={{
                  color: "red.600",
                  borderColor: "red.600",
                  bg: "none",
                }}
              />
            </Flex>
          </Stack>
        </form>
      </Box>
    }
    />
  );
}

export default FilterModalDelivery;
