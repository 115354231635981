import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { FaDatabase } from "react-icons/fa";

export default function NoDataInThisPeriod() {
  return (
    <>
      <Box paddingX={5}>
        <Box
          bg="light"
          marginInline={-6}
          marginTop={-6}
          padding={6}
          borderTop="1px solid"
          borderColor="border"
          marginBottom={6}
        >
          <Flex
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDir={"column"}
          >
            <Box opacity={".7"}>
              <FaDatabase size={70} />
            </Box>
            <Text textAlign={"center"} fontSize="2xl" opacity={".5"}>
              No Data In This period
            </Text>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
