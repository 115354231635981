import React, { Fragment, useState } from "react";
import {Box, Flex, Table, Tbody, Td, Th, Thead, Tr, Checkbox,} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { IoCloseOutline } from "react-icons/io5";
import * as FiIcons from "react-icons/fi";
import DeletedSuppliersTable from "./DeletedSuppliersTable";
import FilterModal from "./modals/FilterModal";
import { useDispatch } from "react-redux";
import { resetSuppliersFilteredData } from "../services/inventorySupplierService";
import SupplierDataTable from "./SupplierDataTable";
import FilteredDataTable from "./FilteredDataTable";

const InventorySupplierTable = ({ data, filteredData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [sortType, setSortType] = useState("All");

  // Show Filter Modal State
  const [showFilterModal, setShowFilterModal] = useState(false);

  const resetFunction = () => {
    dispatch(resetSuppliersFilteredData());
  };

  const activeData = data?.filter((item)=>{
    return item?.IsDeleted === false
  });
  const deletedData = data?.filter((item)=>{
    return item?.IsDeleted === true;
  });
  const activeFilterData = filteredData?.filter((item)=>{
    return item?.IsDeleted === false
  });
  const deletedFilterData = filteredData?.filter((item)=>{
    return item?.IsDeleted === true
  });

  return <>
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            onClick={() => setSortType("All")}
            color={sortType === "All" ? "primary" :"none"}  
            borderBottom={sortType === "All" ? "2px solid" :"none"}   
            borderColor={sortType === "All" ? "primary" :"none"}  
          />

          <GButton
            title={t("general.deleted_item")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            onClick={() => setSortType("deleted")}
            color={sortType === "deleted" ? "primary" :"none"}  
            borderBottom={sortType === "deleted" ? "2px solid" :"none"}   
            borderColor={sortType === "deleted" ? "primary" :"none"}  
          />
        </Flex>

        <GButton
          title={
            filteredData?.length === 0
              ? t("general.filter")
              : t("general.clear_filter")
          }
          border="1px solid"
          borderColor="border"
          postfixElement={
            filteredData?.length === 0 ? (
              <FiIcons.FiFilter size={16} />
            ) : (
              <IoCloseOutline fontWeight={800} size={20} />
            )
          }
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          onClick={
            filteredData?.length === 0
              ? () => setShowFilterModal(true)
              : resetFunction
          }
        />
      </Flex>
      {
          data?.length === 0 ?
          <Flex
                  p={4}
                  bg="white"
                  alignItems="center"
                  justifyContent="center"
                  color="text"
                  textTransform="capitalize"
                  boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                  borderRadius={6}
                  >
                  {t("pages.branches.no_data")}
          </Flex>
          :
          <>
          {
              filteredData?.length > 0 ? 
              <FilteredDataTable 
                  data={ sortType === "All" ? activeFilterData :  deletedFilterData } 
              />
              : sortType === "All" ? 
              <SupplierDataTable data={activeData} />
              : 
              <DeletedSuppliersTable data={deletedData} />
          }
          </>
      }

      {showFilterModal && (
        <FilterModal
          onClose={() => setShowFilterModal(false)}
          data={data}
          filteredData={filteredData}
        />
      )}
    </Box>
    </>;
};

export default InventorySupplierTable;
