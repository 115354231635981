import React from "react";
import { Outlet } from "react-router-dom";
import HrSideBar from "./HrSideBar/HrSideBar";
import { useSelector } from "react-redux";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "layout/navbar/Navbar";
import HrSectionSideBar from "./HrSideBar/HrSectionSideBar";
const HrSelfServicesLayout = () => {
  const sidebar = useSelector((state) => state.sidebar);
  return (
    <>
      <Flex>
        <HrSectionSideBar />
        <Box
          width={{
            base: "100%",
            lg: `calc(100% - ${sidebar.isActive ? "80px" : "260px"})`,
          }}
          marginStart={{ base: 0, lg: sidebar.isActive ? "80px" : "260px" }}
          transition=".3s"
        >
          <Navbar />
          <Box minHeight="calc(100vh - 70px)" padding={{ base: 3, md: 6 }}>
            <Outlet />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default HrSelfServicesLayout;
