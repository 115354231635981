import {
  Box,
  Flex,
  SimpleGrid,
  Spinner,
  Text,
  Input as ChInput,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CountCompo from "features/dashboard/components/CountCompo";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetGeneralDashBoard } from "features/dashboard/services/dashbordChartsServices";
import { getCurrentDate } from "utils/currentDate";
import GeneralChart from "features/dashboard/components/GeneralChart";
import ChartContanier from "features/dashboard/components/ChartContanier";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";
import { checkIdExists } from "utils/cheackAuth";
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";

const General = () => {

    // const [inputValue, setInputValue] = useState("");
    // const [timer, setTimer] = useState(null);

    // useEffect(() => {
    //   // This effect runs whenever the inputValue changes
    //   if (inputValue !== "") {
    //     // If there's an existing timer, clear it
    //     if (timer) {
    //       clearTimeout(timer);
    //     }

    //     // Set a new timer that expires after 5 seconds
    //     const newTimer = setTimeout(() => {
    //       console.log("Timeout!"); // Perform any desired action upon timeout
    //       setTimer(null); // Reset timer after it expires
    //     }, 2000);

    //     // Update the timer state with the new timer
    //     setTimer(newTimer);
    //   }
    // }, [inputValue]);

    // const handleInputChange = (e) => {
    //   const newValue = e.target.value;

    //   // Update the input value state
    //   setInputValue(newValue);

    //   // Reset the timer when user makes a change in the input
    //   if (timer) {
    //     clearTimeout(timer);
    //     setTimer(null);
    //   }
    // };

      //  <input
      //    type="text"
      //    value={inputValue}
      //    onChange={handleInputChange}
      //    placeholder="Type something..."
      //  />;
  
  const retrievedArray =useDecryptedPrivilege()
  const mangeGeneral = checkIdExists(retrievedArray, 73);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [date, setDate] = useState();



  useEffect(() => {
    dispatch(GetGeneralDashBoard({ UserDate: getCurrentDate() }));
  }, [dispatch]);

  const handelDateBtn=()=>{
    console.log("applayed")
    if(date){
      dispatch(GetGeneralDashBoard({ UserDate: date }));
    }
  }

  const GeneralData = useSelector((state) => state.dashbordChartsSlice);

  // console.log(GeneralData?.Generaldata[0])

  if(GeneralData?.Generaldata[0]===undefined){
  
      return (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
    )
  }

  if(mangeGeneral===false  ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  const compoData2 = [
    {
      title: "pages.dashboard.Top_Products",
      data:GeneralData?.Generaldata[0].TopProducts      
    },
    {
      title: "pages.dashboard.Top_Payments",
      data:GeneralData?.Generaldata[0].TopPayments      
    },
    {
      title: "pages.dashboard.Top_Branches",
      data:GeneralData?.Generaldata[0]?.TopBranches
    },
  ];

  const compoData = [
    {
      title: "pages.dashboard.Order",
      count: GeneralData?.Generaldata[0]?.OrderCount,
      link: "/orders",
    },
    {
      title: "pages.dashboard.NetSales",
      count: GeneralData?.Generaldata[0]?.NetSales,
      link: "/reports/sales_reports/sales_reports",
    },
    {
      title: "pages.dashboard.NetPayments",
      count: GeneralData?.Generaldata[0]?.NetPayments,
      link: "/reports/sales_reports/payment_report",
    },
    {
      title: "pages.dashboard.ReturnAmount",
      count: GeneralData?.Generaldata[0]?.ReturnAmount,
      link: "/reports/sales_reports/sales_reports",
    },
    {
      title: "pages.dashboard.DiscountAmount",
      count: GeneralData?.Generaldata[0]?.DiscountAmount,
      link: "/reports/sales_reports/sales_reports",
    },
  ];

  if (GeneralData.isLoading) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  


  return (
    <>
   
      <Box
        bg="light"
        borderRadius={4}
        marginBottom={6}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        padding={15}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.reports.reports_tables.date")}
          </Text>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Flex gap={2} flexWrap="wrap">
              <ChInput type="date" onChange={(e) => setDate(e.target.value)} />
            </Flex>

            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              size={"sm"}
              onClick={handelDateBtn}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.customers.apply")}
              </Flex>
            </Button>
          </div>
        </Flex>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {compoData.map((el) => (
          <CountCompo title={el.title} count={el.count} link={el.link} />
        ))}
      </SimpleGrid>

      <GeneralChart data={GeneralData?.Generaldata} />

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} marginTop={5}>
        {compoData2.map((el) => (
          <ChartContanier title={el.title} count={el.count} data={el.data} />
        ))}
      </SimpleGrid>
    </>
  );
};

export default General;
