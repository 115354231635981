import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BouncesSettingTable from "./components/BouncesSettingTable";
import AddSettingBouncesModal from "./components/modal/AddSettingBouncesModal";
import { getBouncesList } from "../../services/hrSettingServices";

export default function BouncesSetting() {
  const disPatch = useDispatch();
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);

  const data = useSelector((state) => state.hrSetting);
  // console.log(data);
  useEffect(() => {
    disPatch(getBouncesList());
  }, [disPatch]);

  return (
    <Box>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.setting_bounces")}
          </Text>

          <Flex gap={2}>
            <Button
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => setShowAddModal(true)}
            >
              <Flex alignItems="center" gap={1}>
                {t("pages.hr.AddBouncesSetting")}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>
      <BouncesSettingTable
        data={data?.BouncesSettingList}
        isLoading={data.isLoading}
        error={data.error}
      />

      {showAddModal && (
        <AddSettingBouncesModal onClose={() => setShowAddModal(false)} />
      )}
    </Box>
  );
}
