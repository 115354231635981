import { createSlice } from "@reduxjs/toolkit";
import {
  getTaxesGroups,
  createTaxGroup,
  updateTaxGroup,
  deleteTaxGroup
} from "../services/taxesGroupsService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null
};

const taxesGroupsSlice = createSlice({
  name: "taxesGroups",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTaxesGroups.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getTaxesGroups.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getTaxesGroups.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(createTaxGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createTaxGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(createTaxGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(updateTaxGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(updateTaxGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(taxGroup => taxGroup.id === payload.id);
      state.data[indexAt] = payload;
      state.errors = null;
    })
    builder.addCase(updateTaxGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    builder.addCase(deleteTaxGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteTaxGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = state.data.filter(taxGroup => taxGroup.id !== payload.id);
      state.errors = null;
    })
    builder.addCase(deleteTaxGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  }
});

export default taxesGroupsSlice.reducer;