import { createSlice } from "@reduxjs/toolkit";
import { getCostAdjustmentDetails } from "../services/costAdjustmentDetails";
import { createNewCostAdjustment, deleteCostAdjustments, editCostAdjustment, getCostAdjustmentBranch, getCostAdjustmentList, getCostFilteredData, getItemByCostAdjustmentId, getListItemAfterSubmitByCostAdjustmentId, resetCostFilteredData, submitCostAdjustments } from "../services/costAdjustment";
import { addItemCostAdjustment, editItemCostAdjustment, getCostAdjustmentEditItem, getCostAdjustmentItems, getCostAdjustmentItemsList } from "../services/costAdjustmentItem";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  costAdjustmentBranch: [],

  costAdjustmentList: [],
  costAdjustmentDetails:[],

  costAdjusmentItemsList : [],

  costAdjustmentItems: [],
  costAdjustmentItemsEdit: [],

  listItemsAfterSubmit: [],

  // Filter data
  filteredData : []
};

const costAdjustmentSlice = createSlice({
  name: "costAdjustment",
  initialState,
  extraReducers: (builder) => {

  /* -------------------------------------------- Get CostAdjustment List -------------------------------------------- */
    builder.addCase(getCostAdjustmentList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.costAdjustmentList = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
    /* --------------------------------- Get Cost Adjustment Details ------------------------------------------------------------ */
    builder.addCase(getCostAdjustmentDetails.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentDetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.costAdjustmentDetails = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentDetails.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
/* ---------------------------Get Cost Adjustment Branch ----------------------------------------- */
    builder.addCase(getCostAdjustmentBranch.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentBranch.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.costAdjustmentBranch = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentBranch.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

/* --------------------------- Create New Cost Adjustment ----------------------------------------- */
builder.addCase(createNewCostAdjustment.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(createNewCostAdjustment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(createNewCostAdjustment.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

  /* --------------------------- Cost Adjustment----------------------------------------------------------------------- */
    // Edit Cost Adjustment
    builder.addCase(editCostAdjustment.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(editCostAdjustment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(editCostAdjustment.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    // submit Cost Adjustments
    builder.addCase(submitCostAdjustments.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(submitCostAdjustments.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(submitCostAdjustments.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    // Delete Cost Adjustments
    builder.addCase(deleteCostAdjustments.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(deleteCostAdjustments.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(deleteCostAdjustments.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })



  /*  -----------------------------------------------  Get Items To Push CostAdjustment  --------------------------------------------------*/ 
  builder.addCase(getCostAdjustmentItems.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentItems.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.costAdjustmentItems = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentItems.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    // Save Cost Adjustment Items
    builder.addCase(addItemCostAdjustment.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(addItemCostAdjustment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.costAdjustmentItems = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(addItemCostAdjustment.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    // Edit Items Cost Adjustment Items
    builder.addCase(editItemCostAdjustment.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(editItemCostAdjustment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    })
    builder.addCase(editItemCostAdjustment.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
  /* --------------------------------------------Get Items List By CostAdjustmentId-------------------------------------------- */
    builder.addCase(getCostAdjustmentItemsList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentItemsList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.costAdjusmentItemsList = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getCostAdjustmentItemsList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
 /* -------------------------------------------------------------------------------------- */
    // getListItemAfterSubmitByCostAdjustmentId
    builder.addCase(getListItemAfterSubmitByCostAdjustmentId.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getListItemAfterSubmitByCostAdjustmentId.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.listItemsAfterSubmit = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    })
    builder.addCase(getListItemAfterSubmitByCostAdjustmentId.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
   /* --------------------------------------------------------------------------------------------------- */
   /* ----------------------------------------- Filter Data --------------------------------------------- */
    builder.addCase(getCostFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getCostFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      state.errors = null;
    });
    builder.addCase(getCostFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* -------------------------------------------------------------------------------------- */
    builder.addCase(resetCostFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetCostFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetCostFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
   /* --------------------------------------------------------------------------------------------------- */
  }
});

export default costAdjustmentSlice.reducer;