import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text, Spinner } from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import PurchaseOrdersTable from "./components/PurchaseOrdersTable";
import {
  getPurchaseOrderList,
  getPurchaseOrderFilteredData,
} from "./services/purchaseOrderServices";
import ExportModal from "components/ui/modal/ExportModal";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

export default function ReportPurchaseOrders() {

  const retrievedArray =useDecryptedPrivilege()
  const Auth = checkIdExists(retrievedArray, 67);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data, isLoading, filteredData } = useSelector(
    (state) => state.reportsPurchaseOrder
  );

  const [exportModal, SetExportModal] = useState(false);

  useEffect(() => {
    // Dispatch getPurchaseOrderList action first
    dispatch(getPurchaseOrderList())
      .then(() => {
        // Once getPurchaseOrderFilteredData is completed, dispatch getPurchasingFilteredData
        dispatch(getPurchaseOrderFilteredData());
      })
      .catch((error) => {
        throw Error("Error purchase order report data:", error);
      });
  }, [dispatch]);

  if(Auth===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  return (
    <>
      <BreadCrumb>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          flexWrap="wrap"
        >
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.reports.inventory_reports.purchase_order")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
              type="button"
              bg="none"
              border="1px solid"
              borderColor="border"
              textTransform="capitalize"
              fontSize="1rem"
              color="text"
              _hover={{ bg: "primary", color: "light" }}
              onClick={() => SetExportModal(true)}
            >
              <Text>{t("general.export")}</Text>
            </Button>
          </Flex>
        </Flex>
      </BreadCrumb>

      <>
        {isLoading ? (
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <PurchaseOrdersTable data={data} filteredData={filteredData} />
        )}
      </>

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={data}
          fileName={"reportsPurchaseOrder"}
        ></ExportModal>
      )}
    </>
  );
}
