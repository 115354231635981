import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Text, Spinner, useToast} from "@chakra-ui/react"
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import GButton from "components/ui/button/Button";
import DeleteModal from "../components/modals/DeleteCustomerModal";
import { getCustomerlist } from "../services/customersService";
import NoData from "components/ui/noData/NoData";
import CreateCustomerModal from "../components/modals/CreateCustomerModal";
import ExportModal from 'components/ui/modal/ExportModal';
import FilterModal from "../components/modals/FilterModal";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import CustomarTables from "../components/CustomarTables";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const Customers = () => {
  const retrievedArray =useDecryptedPrivilege()
  const ManageCustomers = checkIdExists(retrievedArray, 6);
  const ReadCustomers = checkIdExists(retrievedArray, 4);



  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(null);
  const [showDeleteCustomerModal, setShowDeleteCustomerModal] = useState(null);
  const [showFilterCustomersModal, setShowFilterCustomersModal] = useState(null);

  const [exportModal,SetExportModal]=useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);


  const customers = useSelector(state => state.customers);

  const [tableStatus, setTableStatus] = useState("all");

  useEffect(() => {
    dispatch(getCustomerlist());
  }, [dispatch]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  
if(ManageCustomers===false && ReadCustomers===false ){
  return(
    <NotAuthorizedCompo/>
  )
}


if(showCreateCustomerModal===true && ManageCustomers===false){
  toast2({ description:  t("general.authorization")})
  setShowCreateCustomerModal(false)
}

  return (
    <>
      <BreadCrumb>
        <Flex alignItems="center" justifyContent="space-between" gap={4} flexWrap="wrap">
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("pages.customers.customers")}
          </Text>

          <Flex gap={2} flexWrap="wrap">
            <Button
                  type="button" bg="none" border="1px solid" borderColor="border"
                  textTransform="capitalize" fontSize="1rem" color="text"
                  _hover={{ bg: "primary", color: "light" }}
                  onClick={() => SetExportModal(true)}
                >
                    <Text>{t("general.export")}</Text>
                </Button>

            <GButton
              title={t("pages.customers.create_customer")}
              onClick={() => setShowCreateCustomerModal(true)}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
          </Flex>
          
        </Flex>
      </BreadCrumb>

      { 
        customers?.isLoading ? 
        <>
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        </> 
        :
        <>
            {
            customers?.customerData?.length === 0 ? 
              <>
                <NoData
                  btnTitle={t("pages.customers.create_customer")}
                  title={t("pages.customers.no_data_hint")}
                  onClickCreateBtn={() => setShowCreateCustomerModal(true)}
                  onClickCreateCustomerBtn={() => setShowCreateCustomerModal(true)}
                />
              </>
            :
                <>
                  <CustomarTables
                      data={customers.customerData}
                      filteredData={customers.filteredData}
                  />
                </>
            }
        </> 
      }

      {showCreateCustomerModal && (
        <CreateCustomerModal
          onClose={() => setShowCreateCustomerModal(false)}
        />
      )}
      {showDeleteCustomerModal && (
        <DeleteModal
          data={showDeleteCustomerModal}
          onClose={() => setShowDeleteCustomerModal(null)}
        />
      )}

      {showFilterCustomersModal && (
        <FilterModal
          onClose={() => setShowFilterCustomersModal(null)}
        />
      )}

    {exportModal&&(
      <ExportModal 
        onClose={() => SetExportModal(false)}   
        data={customers.customerData}
        fileName={"customers"}
        >
      </ExportModal>
      )}

    {     
      showFilterModal && (
          <FilterModal 
              onClose={()=>setShowFilterModal(false)} 
              data={customers.customerData} 
          />
      )
    }

    </>
  )
}

export default Customers ;