import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import GButton from "components/ui/button/Button"
import DeactiveInSomeBranchesModal from './modals/DeactiveInSomeBranchesModal';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDeactiveBranchesModififers } from '../services/modifiersOptionsCustomService';
import DeactiveBranchesTable from './DeactiveBranchesTable';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';


const DeactaiveInSomeBranches = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

    const { t } = useTranslation();

    const [showModal,setshowModal]=useState(false)

    const id = useParams()
    //console.log(id.modifiersOptionId)

    const dispatch=useDispatch()

    useEffect(()=>{
      dispatch(getDeactiveBranchesModififers(id.modifiersOptionId))
    },[dispatch,id])

    const inActiveData = useSelector(state => state.modifiersOptionscustom);

    //console.log(inActiveData)

    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


    if(showModal && ManageMenu===false){
      toast2({ description:  t("general.authorization")})
      setshowModal(false)
    }

  return (
    <div>
    <Box>
    

    <Flex
    alignItems="center" justifyContent="space-between"
    gap={2} flexWrap="wrap" mb={6}
  >
    <Text color="text" textTransform="capitalize">
      {t("pages.combos.inactive_in_branches")}
    </Text>
    <GButton
      title={t("pages.combos.select_branches")}
      bg="light"
      color="text"
      fontSize="14px"
      border="1px solid"
      borderColor="border"
      hover={{ bg: "light" }}
      onClick={() => setshowModal( true)}
    />
  </Flex>





  {showModal&&(
    <DeactiveInSomeBranchesModal onClose={() => setshowModal(false)} />
)}



{inActiveData.isLoading ? (
  <Spinner />
) : inActiveData.inActiveBranches?.length === 0 ? (
  <Flex
    p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
    boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
  >
    {t("pages.branches.no_data")}
  </Flex>
) : inActiveData.inActiveBranches?.length > 0 ? (
  <DeactiveBranchesTable
  data={inActiveData.inActiveBranches}
  />
):(
  <Flex
  p={4} bg="white" alignItems="center" justifyContent="center" color="text" textTransform="capitalize"
  boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" borderRadius={6}
>
  {t("pages.branches.no_data")}
</Flex>
)
}





    </Box>
    </div>
  );
}

export default DeactaiveInSomeBranches;
