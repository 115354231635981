import { createSlice } from "@reduxjs/toolkit";
import {
  getTimedEvents,
  createTimedEvent,
  updateTimedEvent,
  deleteTimedEvent,
  getTimedEventById,
  activateTimedEvent,
} from "../services/timedEventsService";

let initialState = {
  isLoading: false,
  data: [],
  errors: null,
  TimedEventDetails: null,
};

const timedEventsSlice = createSlice({
  name: "timedEvents",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------------------  Get Timed Events List  -------------------------------------------------------------------*/
    builder.addCase(getTimedEvents.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTimedEvents.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getTimedEvents.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- create Timed Event  -------------------------------------------------------------------*/
    builder.addCase(createTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createTimedEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(createTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Edit Timed Event  -------------------------------------------------------------------*/
    builder.addCase(updateTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(updateTimedEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const indexAt = state.data.findIndex(
        (TimedEvent) => TimedEvent.id === payload.id
      );
      state.data[indexAt] = payload;
      state.errors = null;
    });
    builder.addCase(updateTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ------------------------------------------------------- Delete Timed Event  -------------------------------------------------------------------*/
    builder.addCase(deleteTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteTimedEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(deleteTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* ----------------------------------------- Get  Timed Event By Id (Details) --------------------------------------- */
    builder.addCase(getTimedEventById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getTimedEventById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.TimedEventDetails =
        (payload[0] && JSON.parse(payload[0].jsonData))[0] || null;
      state.errors = null;
    });
    builder.addCase(getTimedEventById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -----------------------------------------------  Activate Timed Event  --------------------------------------- */
    builder.addCase(activateTimedEvent.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(activateTimedEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(activateTimedEvent.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default timedEventsSlice.reducer;
