import { createSlice } from "@reduxjs/toolkit";
import { addModifierToGroup, getModifierToTaxesGroup } from "../services/modifiersToGroupService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null
};

const modifiersToGroupSlice = createSlice({
  name: "modifierGroups",
  initialState,
  extraReducers: (builder) => {
   

    builder.addCase(getModifierToTaxesGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getModifierToTaxesGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    })
    builder.addCase(getModifierToTaxesGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })
   

    builder.addCase(addModifierToGroup.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(addModifierToGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(addModifierToGroup.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

  }
});

export default modifiersToGroupSlice.reducer;