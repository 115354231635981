import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";

export const getbranches = createAsyncThunk(
  "inActiveGiftCards/getbranches",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage/Setting/CallCenter/LookUpBranches`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const assigenGiftToBranch = createAsyncThunk(
  "inActiveGiftCards/assigenGiftToBranch",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/GiftCards/AddGiftCardsInActiveBranch?giftcardsinactivebranch=${JSON.stringify(
          args
        )}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getBranchesOfGift = createAsyncThunk(
  "inActiveGiftCards/getBranchesOfGift",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/GiftCards/InActiveBranchByGiftCardsId?giftcardsid={"GiftCardsId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
