import React from 'react'
import { Navigate, Outlet } from "react-router-dom";
import AppLayout from "../layout/AppLayout";
import routes from "./routes";

const ProtectedRoutes = ({ displayLayout }) => {
  const isAuth = localStorage.getItem("token");
  return (isAuth && isAuth !== "" ) ? (
    displayLayout ?
      <AppLayout>
        <Outlet />
      </AppLayout> : <Outlet />
  ) : (
    <Navigate to={routes.login} />
  )
}

export default ProtectedRoutes