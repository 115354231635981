import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as FiIcons from "react-icons/fi";
import {
  Button,
  Flex,
  Text,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import Table from "../components/Table";
import GButton from "../../../../components/ui/button/Button";
import CreateModal from "../components/modals/CreateModal";
import UpdateModal from "../components/modals/UpdateModal";
import DeleteModal from "../components/modals/DeleteModal";
import ImportModal from "../components/modals/ImportModal";
import FilterModal from "../components/modals/FilterModal";
import BreadCrumb from "../../../../components/ui/breadcrumb/BreadCrumb";
import { getProducts, resetFilteredData } from "../services/productsService";
import NoData from "components/ui/noData/NoData";
import { IoCloseOutline } from "react-icons/io5";
import ExportModal from "components/ui/modal/ExportModal";
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";


export default function Products() {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);



  const dispatch = useDispatch();
  const { t } = useTranslation();

  const products = useSelector((state) => state.products);
  const allproducts = useSelector((state) => state.products);
  const filteredProducts = useSelector((state) => state.products);

  const [selectedTable, setSelectedTable] = useState("all");

  const [showCreateProductModal, setShowCreateProductModal] = useState(null);
  const [showUpdateProductModal, setShowUpdateProductModal] = useState(null);
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(null);
  const [showFilterProductsModal, setShowFilterProductsModal] = useState(null);
  const [exportModal, SetExportModal] = useState(false);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const resetFunction = () => {
    dispatch(resetFilteredData());
  };

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }


  if(showCreateProductModal===true && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowCreateProductModal(false)
  }
  
  return (
    <>
      <>
        <BreadCrumb>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            flexWrap="wrap"
          >
            <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
              {t("pages.products.product_page")}
            </Text>

            <Flex gap={2} flexWrap="wrap">
              <Button
                type="button"
                bg="none"
                border="1px solid"
                borderColor="border"
                textTransform="capitalize"
                fontSize="1rem"
                color="text"
                _hover={{ bg: "primary", color: "light" }}
              >
                <div onClick={() => SetExportModal(true)}>
                  {t("general.export")}
                </div>
              </Button>

              <GButton
                title={t("pages.products.create_product")}
                onClick={() => setShowCreateProductModal(true)}
                bg="primary"
                color="light"
                border="1px solid transparent"
                hover={{ bg: "none", borderColor: "primary", color: "primary" }}
              />
            </Flex>
          </Flex>
        </BreadCrumb>

        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <GButton
              title={t("general.all")}
              bg="none"
              color={selectedTable === "all" ? "primary" : "text"}
              isDisabled={false}
              borderRadius={0}
              borderBottom={selectedTable === "all" ? "2px solid" : "none"}
              borderColor="primary"
              onClick={() => setSelectedTable("all")}
            />
            <GButton
              title={t("pages.gifts_cards.active")}
              bg="none"
              color={selectedTable === "active" ? "primary" : "text"}
              isDisabled={false}
              borderRadius={0}
              borderBottom={selectedTable === "active" ? "2px solid" : "none"}
              borderColor="primary"
              onClick={() => setSelectedTable("active")}
            />
            <GButton
              title={t("pages.gifts_cards.in_active")}
              bg="none"
              color={selectedTable === "inActive" ? "primary" : "text"}
              isDisabled={false}
              borderRadius={0}
              borderBottom={selectedTable === "inActive" ? "2px solid" : "none"}
              borderColor="primary"
              onClick={() => setSelectedTable("inActive")}
            />
           {/*  <GButton
              title={t("general.deleted")}
              bg="none"
              color={selectedTable === "deleted" ? "primary" : "text"}
              isDisabled={false}
              borderRadius={0}
              borderBottom={selectedTable === "deleted" ? "2px solid" : "none"}
              borderColor="primary"
              onClick={() => setSelectedTable("deleted")}
            />*/}
          </Flex>

          <GButton
            title={
              filteredProducts.filteredData.length === 0
                ? t("general.filter")
                : t("general.clear_filter")
            }
            border="1px solid"
            borderColor="border"
            postfixElement={
              filteredProducts.filteredData.length === 0 ? (
                <FiIcons.FiFilter size={16} />
              ) : (
                <IoCloseOutline fontWeight={800} size={20} />
              )
            }
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            onClick={
              filteredProducts.filteredData.length === 0
                ? () => setShowFilterProductsModal(true)
                : resetFunction
            }
          />
        </Flex>

        {products.isLoading ? (
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ) : products.data.length === 0 ? (
          <NoData
            btnTitle={t("pages.products.create_product")}
            title={t("pages.products.no_data_hint")}
            onClickCreateBtn={() => setShowCreateProductModal(true)}
            onClickCreateProductBtn={() => setShowCreateProductModal(true)}
          />
        ) : (
          <Table
            products={products.data}
            tabs={selectedTable}
            handleUpdate={(product) => setShowUpdateProductModal(product)}
            handleDelete={(product) => setShowDeleteProductModal(product)}
          />
        )}

        {showCreateProductModal && (
          <CreateModal onClose={() => setShowCreateProductModal(null)} />
        )}

        {showUpdateProductModal && (
          <UpdateModal
            data={showUpdateProductModal}
            onClose={() => setShowUpdateProductModal(null)}
          />
        )}

        {showDeleteProductModal && (
          <DeleteModal
            data={showDeleteProductModal}
            onClose={() => setShowDeleteProductModal(null)}
          />
        )}

        {exportModal && (
          <ExportModal
            onClose={() => SetExportModal(false)}
            data={
              selectedTable === "active"
                ? products.data.filter((el) => el.IsActive === true)
                : selectedTable === "inActive"
                ? products.data.filter((el) => el.IsActive === false)
                : products.data
            }
            fileName={
              selectedTable === "active"
                ? "Active Products"
                : selectedTable === "inActive"
                ? "InActive Products"
                : "Products"
            }
          ></ExportModal>
        )}

        {/* {showImportProductsModal && (
          <ImportModal onClose={() => setShowImportProductsModal(null)} />
        )}

        {showExportProductsModal && (
          <ExportModal onClose={() => setShowExportProductsModal(null)} />
        )} */}

        {showFilterProductsModal && (
          <FilterModal
            onClose={() => setShowFilterProductsModal(null)}
            data={allproducts.data}
          />
        )}
      </>
    </>
  );
}
