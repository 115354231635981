import { useTranslation } from "react-i18next";
import { Flex, Text, Spinner, Box } from "@chakra-ui/react";
import PendingTransactionsDataTable from "./PendingTransactionsDataTable";

export default function PendingTransactionsTable({data}) {
  const { t } = useTranslation();
  const isLoading = false;

  return (
    <>
      <Text
        margin={"30px 0"}
        fontSize="1.2rem"
        color="dark"
        textTransform="capitalize"
      >
        {t("pages.dashboard.pending_transactions")}
      </Text>

      <>
        {isLoading ? (
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <Box
            bg="light"
            overflow="auto"
            borderRadius={4}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          >
            <PendingTransactionsDataTable data={data} />
          </Box>
        )}
      </>
    </>
  );
}
