import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import ItemTagsTable from "./ItemTagsTable";
import CustomLevelModal from "./modals/CustomLevelModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBranchesOfItem } from "../services/customLevelItemService";
import CustomLevelBranches from "./CustomLevelBranches";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege ";

const CustomItemLevels = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageItems = checkIdExists(retrievedArray, 10);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });



  const { t } = useTranslation();
  const [showCustomLevelModal, setCustomLevelModal] = useState(false);
  const id = useParams();
  //console.log(id.itemId)

  const dispatch = useDispatch();

  const branchesOfItem = useSelector((state) => state.customLevel);

  //console.log(branchesOfItem)

  useEffect(() => {
    dispatch(getBranchesOfItem(id.itemId));
  }, [dispatch, id]);

  if(showCustomLevelModal===true && ManageItems===false){
    toast2({ description:  t("general.authorization")})
    setCustomLevelModal(false)
  }

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.inventory.custom_level")}
        </Text>

        <GButton
          title={t("pages.inventory.select_branche")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setCustomLevelModal(true)}
        />
      </Flex>

      {branchesOfItem.isLoading ? (
        <Spinner />
      ) : branchesOfItem.branchesOfItem?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : branchesOfItem.branchesOfItem?.length > 0 ? (
        <CustomLevelBranches branchesOfItem={branchesOfItem.branchesOfItem} />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {showCustomLevelModal && (
        <CustomLevelModal
          //data={showCreateSectionModal}
          onClose={() => setCustomLevelModal(false)}
        />
      )}
    </Box>
  );
};

export default CustomItemLevels;
