import React from 'react';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr ,Checkbox} from "@chakra-ui/react"
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';

const LedgerBranchestable = () => {
    const { t } = useTranslation();
    const items=[{}]
  return (
    <Box
    bg="light" overflow="auto"
    borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >
<Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.Ledger.operation_number")}</Th>
          <Th>{t("pages.Ledger.refrance_number")}</Th>
          <Th>{t("pages.Ledger.operation_data")}</Th>
          <Th>{t("pages.Ledger.defnation")}</Th>
          <Th>{t("pages.Ledger.debtor")}</Th>
          <Th>{t("pages.Ledger.Creditor")}</Th>
          <Th>{t("pages.Ledger.balance")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {items.map((el, index) => (
          <Tr key={el.InventoryItemId}  style={{cursor:"pointer"}}>
            <Td>
              <form>
                <Checkbox >
                </Checkbox>
              </form>
            </Td>
            <Td >{el.InventoryItemNameAr}</Td>
            <Td>{el.InventoryItemRef}</Td>
            <Td>{el.InventoryCategoryName}</Td>
            <Td>
            
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>


    
  </Box>
  );
}

export default LedgerBranchestable;
