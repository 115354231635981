import React, { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import AppliesOnProductsModal from "./modals/AppliesOnProductsModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDiscountsProducts } from "../services/discountsProductsService";
import ProductsTable from "./ProductsTable";
import DeleteAppliesModal from "./modals/DeleteAppliesModal";

const AppliesOnProducts = () => {
  const id = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showDiscountProducts, setShowDiscountProducts] = useState(false);
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(null);

  const productsOfDiscounts = useSelector((state) => state.discountsProducts);

  useEffect(() => {
    dispatch(getDiscountsProducts(id.id));
  }, [dispatch, id]);

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
        mb={6}
      >
        <Text color="text" textTransform="capitalize">
          {t("pages.discounts.applies_on_products")}
        </Text>

        <GButton
          title={t("pages.discounts.add_products")}
          bg="light"
          color="text"
          fontSize="14px"
          border="1px solid"
          borderColor="border"
          hover={{ bg: "light" }}
          onClick={() => setShowDiscountProducts(true)}
        />
      </Flex>

      {productsOfDiscounts.isLoading ? (
        <Spinner />
      ) : productsOfDiscounts.data?.length === 0 ? (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      ) : productsOfDiscounts.data?.length > 0 ? (
        <ProductsTable
          productsOfDiscount={productsOfDiscounts.data}
          handleDelete={(product) => setShowDeleteProductModal(product)}
        />
      ) : (
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
        >
          {t("pages.branches.no_data")}
        </Flex>
      )}

      {showDiscountProducts && (
        <AppliesOnProductsModal
          onClose={() => setShowDiscountProducts(false)}
        />
      )}
      {showDeleteProductModal && (
        <DeleteAppliesModal
          id={showDeleteProductModal}
          onClose={() => setShowDeleteProductModal(null)}
          type="Product"
        />
      )}
    </Box>
  );
};

export default AppliesOnProducts;
