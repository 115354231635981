import React, { useEffect } from 'react';
import GModal from "components/ui/modal/Modal"
import { useTranslation } from 'react-i18next';
import { Box, Flex, Stack, useToast ,Checkbox , Text} from "@chakra-ui/react"
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { useForm } from 'react-hook-form';
import GButton from "components/ui/button/Button"
import { useDispatch, useSelector } from 'react-redux';
import { getCahers } from '../../services/casherForOnlineOrdersService';
import { setSettingsOnlineOrder } from '../../services/onlineOrderService';



export default function EditReciveOnlineOrderModal ({onClose , detailsData}){
  console.log(detailsData);
    const dispatch=useDispatch();
    const { t } = useTranslation();
    const {  handleSubmit,    register,    control,    setValue,  } = useForm();
    const toast = useToast({ position: "top", duration: 3000, status: "success" });
    const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

    const data = useSelector(state => state.casherOnlineOrder);

    useEffect(()=>{
        dispatch(getCahers())
    },[dispatch])

  return <>
    <GModal
    onClose={onClose}
    modalHeader={t("pages.online_Orders.branch_order_online_settings")}
    modalContent={(

        <Box p={4}>
        
        <form onSubmit={handleSubmit(values=>{
            values.DevicesId=values.DevicesId?.DevicesId;
            if(values.DevicesId === undefined){
                toast2({ description: t("pages.validation.choose_first") });
            }
            else {
                dispatch(setSettingsOnlineOrder(values)).
                unwrap().
                then(_ => {
                    toast({ description: t("pages.online_Orders.item_updated") });
                    onClose()
                  });
            } 
        })}>
        

        <Stack spacing={6}>
            <Box borderBottom="1px solid" borderColor="border" paddingY={3}> 
                {t("pages.online_Orders.branch") } : {detailsData?.BranchName} 
            </Box>
            

        <MultiSelect
        data={data.data && data.data.map(el => ({
          ...el,
          label: el.DevicesName,
          value: el.DevicesId
        }))}
        title={t("pages.online_Orders.Cashier")}
        onChange={(e) => setValue("DevicesId", e)}
        isMulti={false}
      />



      <Checkbox
      {...register("AutoAcceptSendOnlineOrdersKitchen")}
      textTransform="capitalize"
    >
      {t("pages.online_Orders.Auto_Accept_and_Send_Online_Orders_to_Kitchen")}
    </Checkbox>


    <Checkbox
    {...register("PrintOnlineOrderInfoWhenReceived")}
    textTransform="capitalize"
  >
    {t("pages.online_Orders.Print_Online_Order_Info_When_Received")}
  </Checkbox>
        



  <Flex gap={4}>
  <GButton
    type="submit"
    title={t("general.save")}
    bg="primary"
    color="light"
    border="1px solid transparent"
    isLoading={data?.isLoading}
    hover={{ color: "primary", borderColor: "primary", bg: "none" }}
  />
  <GButton
    title={t("general.close")}
    onClick={onClose}
    bg="red.600"
    border="1px solid transparent"
    color="light"
    isLoading={data?.isLoading}
    hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
  />
</Flex>


        
        </Stack>
        
        
        </form>
        
        
        </Box>


    )}

    />
  </>;
};