import React, { useEffect } from 'react';
import GModal from 'components/ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import MultiSelect from "components/ui/multiSelect/MultiSelect"
import Input from "components/form/Input"
import { Controller , useForm } from "react-hook-form";
import {Box,Checkbox, Flex,FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput} from "@chakra-ui/react";
import GButton from "components/ui/button/Button"
import { useDispatch, useSelector } from 'react-redux';
import { getTaxesGroupForCharge, getchargeType, getchargesForDeliveryCharge, getorderTypeCharge, saveDilveryCharges } from '../../services/deliveryChargeService';

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch=useDispatch();
  const { handleSubmit, control, setValue,  register,  formState: { errors },  trigger} =  useForm();
  const toast = useToast({ position: "top", duration: 3000, status: "success" });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const data = useSelector(state => state.deliverycharge);
  const inventoryCatData=[]

  useEffect(()=>{
    dispatch(getchargesForDeliveryCharge())
  },[dispatch])

  useEffect(()=>{
    dispatch(getchargeType())
  },[dispatch])

  useEffect(()=>{
    dispatch(getorderTypeCharge())
  },[dispatch])

  useEffect(()=>{
    dispatch(getTaxesGroupForCharge())
  },[dispatch])

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <GModal
    onClose={onClose}
    modalHeader={t("pages.DeliverCharge.Delivery_Charges")}

    modalContent={(

  <Box p={4}>
        <form onSubmit={handleSubmit(values=>{
            values.ChargeId = null ;
            values.ChargeTypeId=values.ChargeTypeId?.ChargeTypeId
            values.OrderTypeId=values.OrderTypeId?.OrderTypeId
            values.TaxGroupId=values.TaxGroupId?.TaxGroupId
            if(   values.OrderTypeId === undefined  || values.ChargeTypeId === undefined || values.TaxGroupId === undefined){
                toast2( { description: t("validation.choose_first") });
              }
              else {
                dispatch(saveDilveryCharges(values)).unwrap().then(_ => {
                  toast({ description: t("pages.DeliverCharge.created") });
                  onClose()
                });
              }
        })}>
              
        <Stack spacing={6}>

              <FormControl isInvalid={errors.ChargeName}>
                  <FormLabel
                    htmlFor="ChargeName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.DeliverCharge.ChargeName")}
                  </FormLabel>
                  <Controller
                    name="ChargeName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) || t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.DeliverCharge.ChargeName")}
                        onChange={(e) => {
                          handleInputChange(e, "ChargeName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.ChargeName &&
                      errors.ChargeName.message}
                  </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.ChargeNameLocalized}>
                  <FormLabel
                    htmlFor="ChargeNameLocalized"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.DeliverCharge.Charge_Name_Localized")}
                  </FormLabel>
                  <Controller
                    name="ChargeNameLocalized"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) || t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.DeliverCharge.Charge_Name_Localized")}
                        onChange={(e) => {
                          handleInputChange(e, "ChargeNameLocalized");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.ChargeNameLocalized &&
                      errors.ChargeNameLocalized.message}
                  </FormErrorMessage>
              </FormControl>

              <MultiSelect
                  title={t("pages.DeliverCharge.type")}
                  data={data.chargeType&& data.chargeType.map(el => ({
                    ...el,
                    label: el.ChargeTypeName,
                    value: el.ChargeTypeId
                  }))}
                  isMulti={false}
                  isRequired={true}
                  onChange={(e) => setValue("ChargeTypeId", e)}
              />


                <Checkbox {...register("IsOpenValue")} >
                {t("pages.DeliverCharge.Is_Open_Value")}
                </Checkbox>

                <FormControl isInvalid={errors.Value}>
                      <FormLabel
                        htmlFor="Value"
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("pages.DeliverCharge.Value")}
                      </FormLabel>
                      <Controller
                        name="Value"
                        control={control}
                        defaultValue="0"
                        rules={{
                          required: t("validation.required"),
                          validate: (value) => {
                            const intValue = parseInt(value, 10);
                            return (
                              intValue >= 0 || t("validation.must_be_non_negative")
                            );
                          },
                        }}
                        // rules={{
                        //   required: t("validation.required"),
                        //   validate: {
                        //     nonNegative: (value) => parseInt(value, 10) >= 0 || t("validation.must_be_non_negative"),
                        //     lessThanOrEqual100: (value) => parseInt(value, 10) <= 100 || t("validation.must_be_less_than_or_equal_to_100"),
                        //   },
                        // }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            onChange={(e) => {
                              handleInputChange(e, "Value");
                            }}
                            type="number"
                            min="0"
                            // max="100"
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.Value &&
                          errors.Value.message}
                      </FormErrorMessage>
                </FormControl>

                <MultiSelect
                    title={t("pages.DeliverCharge.orders_type")}
                    data={data.orderTypes && data.orderTypes.map(el => ({
                      ...el,
                      label: el.OrderTypeName,
                      value: el.OrderTypeId
                    }))}
                    isMulti={false}
                    isRequired={true}
                    onChange={(e) => setValue("OrderTypeId", e)}
                />


                <MultiSelect
                    title={t("pages.DeliverCharge.Tax_Group")}
                    data={data.taxesGroup && data.taxesGroup.map(el => ({
                    ...el,
                    label: el.TaxGroupName,
                    value: el.TaxGroupId
                    }))}
                    isMulti={false}
                    isRequired={true}
                    onChange={(e) => setValue("TaxGroupId", e)}
                />
                    


                <Checkbox {...register("AutoApply")} >
                {t("pages.DeliverCharge.Auto_Apply")}
                </Checkbox>


                <Flex gap={4}>
                    <GButton
                      type="submit"
                      title={t("general.save")}
                      bg="primary"
                      color="light"
                      border="1px solid transparent"
                      hover={{ color: "primary", borderColor: "primary", bg: "none" }}
                    />
                    <GButton
                      title={t("general.close")}
                      onClick={onClose}
                      bg="red.600"
                      border="1px solid transparent"
                      color="light"
                      hover={{ color: "red.600", borderColor: "red.600", bg: "none" }}
                    />
                </Flex>


              </Stack>
        </form>
  </Box>
        
    )}

    />
  );
}

export default CreateModal;
