import { createSlice } from "@reduxjs/toolkit";
import { comboDropList , comboList , addCombo } from "../services/comboService";

let initialState = {
  isLoading: false,
  errors: null,
  comboData: [],
  comboData1: [],
};

const inActiveBranchesSlice = createSlice({
  name: "combo",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(comboDropList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(comboDropList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.comboData1 =
        (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(comboDropList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    
    builder.addCase(addCombo.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
    });
    
    builder.addCase(addCombo.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errors = null;
    });
    
    builder.addCase(addCombo.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(comboList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });

    builder.addCase(comboList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.comboData =(payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });

    builder.addCase(comboList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default inActiveBranchesSlice.reducer;
