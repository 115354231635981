import { createSlice } from "@reduxjs/toolkit";
import {
  getPurchaseOrderList,
  getPurchaseOrderFilteredData,
  resetPurchaseOrderFilteredData,
} from "../services/purchaseOrderServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData: [],
};

const reportsPurchaseOrderSLice = createSlice({
  name: "reportsPurchaseOrder",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get purchaseOrder Reports List ------------------------------------ */
    builder.addCase(getPurchaseOrderList.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getPurchaseOrderList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getPurchaseOrderList.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- get Purchase Order Filtered Data------------------------------------------------ */
    builder.addCase(getPurchaseOrderFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      getPurchaseOrderFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        state.errors = null;
      }
    );
    builder.addCase(getPurchaseOrderFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    /* --------------------------------------------------- reset Purchase Order Filtered Data------------------------------------------------ */
    builder.addCase(resetPurchaseOrderFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(
      resetPurchaseOrderFilteredData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
        state.filteredData = [];
        state.errors = null;
      }
    );
    builder.addCase(resetPurchaseOrderFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default reportsPurchaseOrderSLice.reducer;
