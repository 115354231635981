import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getRoles = createAsyncThunk(
  "roles/getRoles",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/Roles/Roles`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createRole = createAsyncThunk(
  "roles/createRole",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/Roles/AddRoles", args);
      console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const updateRole = createAsyncThunk(
  "roles/updateRole",
  async (args, thunkApi) => {
    try {
      console.log(args.values)
      const { data } = await axios.put(`/api/Roles/UpdateRoles`, args.values);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(`/api/Roles/DeleteRoles/${args.id}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const getOrderAuth = createAsyncThunk(
  "roles/getOrderAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/AuthPermissions/LookUpOrderAuth?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getCustomerAuth = createAsyncThunk(
  "roles/getCustomerAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/AuthPermissions/LookUpCustomerAuth?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getInventoryAuth = createAsyncThunk(
  "roles/getInventoryAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/AuthPermissions/LookUpInventoryAuth?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getMenuAuth = createAsyncThunk(
  "roles/getMenuAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/AuthPermissions/LookUpMenuAuth?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getAdminMangmentAuth = createAsyncThunk(
  "roles/getAdminMangmentAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/AuthPermissions/LookUpAdminAuth?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const getMangmentOthersAuth = createAsyncThunk(
  "roles/getMangmentOthersAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/AuthPermissions/LookUpOtherAuth?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getReportsAuth = createAsyncThunk(
  "roles/getReportsAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/AuthPermissions/LookUpReportsAuth?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);



export const getDashBoardAuth = createAsyncThunk(
  "roles/getDashBoardAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/AuthPermissions/LookUpDashboardAuth?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getApplicationsAuth = createAsyncThunk(
  "roles/getApplicationsAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/AuthPermissions/LookUpCashierWaiterAppsAuth?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const getAccountAuth = createAsyncThunk(
  "roles/getAccountAuth",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/api/AuthPermissions/LookUpAccountingAuth?roleid={"RoleId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getRoleDetailsAuthById = createAsyncThunk(
  "roles/getRoleDetailsAuthById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/Roles/GetRoleById?roleid={"RoleId":${args}}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const AddOrEditPrivileges = createAsyncThunk(
  "roles/AddOrEditPrivileges",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axios.post(`/api/RolesPriviliges/AddRolesPriviliges?roleId=${values.roleId}`, values.body, {
        headers: {
          "Content-type": "application/json",
        },
      });

      const data = res.data;
      console.log(data);

      // Return data or status as needed by your application
      return data;
    } catch (error) {
      console.error(error);

      // Returning an error message or value using rejectWithValue
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
