import React, { useState } from "react";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
  Button,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NoDataFound from "components/ui/noDataFound/NoDataFound";
import ExportModal from "components/ui/modal/ExportModal";
import ExportPDFButton from "../../../../components/exportPdf/ExportPdf";
import Pagination from "components/ui/pagination/Pagination";
import PrintCart from "../../../../components/exportPdf/ExportPdf";

const Ledgertable = () => {
  const [exportModal, SetExportModal] = useState(false);

  function filterArray(arr) {
    // Check if arr is an array
    if (!Array.isArray(arr)) {
      console.error("Input is not an array");
      return; // Exit the function
    }

    // Map the array
    return arr.map((obj) => ({
      PageID: obj.PageID,
      DateFormated: obj.DateFormated,
      Row_Description: obj.Row_Description, // Assuming Row_Description is a property you want to include, but it's not present in the provided sample data. You should replace it with the actual property name.
      Debit: obj.Debit,
      Credit: obj.Credit,
    }));
  }

  const { t } = useTranslation();
  const dataLedger = useSelector((state) => state.AccountTree);
  console.log(dataLedger?.tableData);

  const newArray = filterArray(dataLedger?.tableData);
  // console.log(newArray);



  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    if (Array.isArray(data)) {
      console.log("array");
      const firstIndex = (currentPage - 1) * recordsPerPage;
      const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
      return data?.slice(firstIndex, lastIndex);
    } else {
      console.error("data.trialBalance is not an array.");
      return [];
    }
  };

  // Calculate records for activeDiscounts
  const itemsData = calculateRecords(
    dataLedger?.tableData,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  if (dataLedger?.tableData && Object.keys(dataLedger.tableData).length === 0) {
    return <NoDataFound />;
  }

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <div style={{ padding: "15px", borderBottom: "2px solid gray" }}>
        <Flex gap={2}>
          <Button
            type="button"
            bg="none"
            border="1px solid"
            borderColor="border"
            textTransform="capitalize"
            fontSize="1rem"
            color="text"
            _hover={{ bg: "primary", color: "light" }}
          >
            <div onClick={() => SetExportModal(true)}>
              {t("general.export")}
            </div>
          </Button>

          <Button
            type="button"
            bg="none"
            border="1px solid"
            borderColor="border"
            textTransform="capitalize"
            fontSize="1rem"
            color="text"
            _hover={{ bg: "primary", color: "light" }}
          >
            <PrintCart text="حسبات دفتر الإستاذ" tableData={newArray} />

          </Button>
        </Flex>
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th>{t("pages.Ledger.operation_number")}</Th>
            {/*<Th>{t("pages.Ledger.refrance_number")}</Th>*/}
            <Th>{t("pages.Ledger.operation_data")}</Th>
            <Th>{t("pages.Ledger.defnation")}</Th>
            <Th>{t("pages.Ledger.debtor")}</Th>
            <Th>{t("pages.Ledger.Creditor")}</Th>
            {/*<Th>{t("pages.Ledger.balance")}</Th>*/}
          </Tr>
        </Thead>
        <Tbody>
          {itemsData?.map((el, index) => (
            <Tr key={el.InventoryItemId} style={{ cursor: "pointer" }}>
              <Td>{el.PageID}</Td>
              {/*<Td>{el.ReferenceNumber}</Td>*/}
              <Td>{el.DateFormated}</Td>
              <Td>{el.Row_Description}</Td>
              <Td>{el.Debit}</Td>
              <Td>{el.Credit}</Td>
              {/*<Td></Td>*/}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {/* Pagination  */}

      <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(dataLedger?.tableData?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(dataLedger?.tableData?.length / recordsPerPage)
          )
        }
      />

      {exportModal && (
        <ExportModal
          onClose={() => SetExportModal(false)}
          data={newArray}
          fileName={"حسبات دفتر الإستاذ"}
        ></ExportModal>
      )}
    </Box>
  );
};

export default Ledgertable;

