import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "services/axios";
  
  
  /*  ------------------------------------------------- Get Return Suppliser Details------------------------------------------------ */
  export const getReturnSuppliserDetails = createAsyncThunk("returnSupplier/getReturnSuppliserDetails", async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/Inventory/GetPurchasingReturnListById?purchasingreturnid={"ReturnId":${args}}`);
     return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/*  ------------------------------------------------- Delete Return Suppliser ------------------------------------------------ */
export const deletingReturnSupllier = createAsyncThunk("returnSupplier/deletingReturnSupllier", async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/Inventory/DeletePurchasingReturn?returnid={"ReturnId":${args}}`);
     return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /*  ------------------------------------------------- get Returned Item List------------------------------------------------ */
    export const getReturnedItemList = createAsyncThunk("returnSupplier/getReturnedItemList", async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/Inventory/GetPurchasingReturnItems?returnid={"ReturnId":${args}}`);
     return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/*  ------------------------------------------------- edit Quantity Returned Item List ------------------------------------------------ */
export const editQuantityReturnedItemList = createAsyncThunk("returnSupplier/editQuantityReturnedItemList", async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/Inventory/EditPurchasingReturnItems?items=${JSON.stringify(args)}`);
     return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /*  ------------------------------------------------- get Return Items To Edit Quantity ------------------------------------------------ */
    export const getReturnItemsToEditQuantity = createAsyncThunk("returnSupplier/getReturnItemsToEditQuantity", async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/Inventory/GetEditPurchasingReturnItems?returnid={"ReturnId":${args}}`);
     return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /*  -------------------------------------------------add Supplier Return Items------------------------------------------------ */
    export const addSupplierReturnItems = createAsyncThunk("returnSupplier/addSupplierReturnItems", async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/SecInventory/AddReturnItems?returns=${JSON.stringify(args)}`);
     return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /*  -------------------------------------------------add Supplier Return Items------------------------------------------------ */
    export const getDropDownReturnItems = createAsyncThunk("returnSupplier/getDropDownReturnItems", async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/SecInventory/ReturnItems?returns={"PurchasingId":${args}}`);
     return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
  /*  ------------------------------------------------- Submit Return Items------------------------------------------------ */
    export const submitReturnItems = createAsyncThunk("returnSupplier/submitReturnItems", async (args, thunkApi) => {
    try {
     const { data } = await axios.get(`/SecInventory/CloseReturnItems?returnid={"ReturnId":${args}}`);
     return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);