import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

/* ------------------------------------------------------- create products discounts  -------------------------------------------------------------------*/
export const createDiscountProducts = createAsyncThunk(
  "discountsProducts/createDiscountProducts",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCount/AddDiscountProducts?productid=${JSON.stringify(
          args
        )}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Get products Discounts List  -------------------------------------------------------------------*/
export const getDiscountsProducts = createAsyncThunk(
  "discountsProducts/getDiscountsProducts",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/GetDiscountProducts?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

/* ------------------------------------------------------- Delete Product In Discount  -------------------------------------------------------------------*/
export const deleteProductInDiscount = createAsyncThunk(
  "discountsProducts/deleteProductInDiscount",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Manage2/DisCountProduct/DisCountProductById?DisCountProductId={"DisCountProductId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------- Get Tags In Product ---------------------------------------------------*/
export const getTagsInProduct = createAsyncThunk(
  "discountsProducts/getTagsInProduct",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(
        `/Menu/LookupProduct?discountid={"DisCountId":${args}}`
      );
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
