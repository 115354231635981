import { createSlice } from "@reduxjs/toolkit";
import { getInventoryLevelsFilteredData, getInventoryLevelsReportList, resetInventoryLevelsFilteredData } from "../services/inventoryLevelsServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  data: [],
  // Filter data
  filteredData : []
};

const inventoryLevelsReport = createSlice({
  name: "inventoryLevelsReport",
  initialState,
  extraReducers: (builder) => {

      /* -------------------------------------------- Get Inventory Reports List --------------------------------------- */
      builder.addCase(getInventoryLevelsReportList.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      })
      builder.addCase(getInventoryLevelsReportList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || null;
        state.errors = null;
      })
      builder.addCase(getInventoryLevelsReportList.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      })
  
     /* --------------------------------------------------------------------------------------------------- */
     /* -----------------------------------------Inventory Filter Filter Data --------------------------------------------- */
      builder.addCase(getInventoryLevelsFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(getInventoryLevelsFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = payload;
        state.errors = null;
      });
      builder.addCase(getInventoryLevelsFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
     /* -------------------------------------------------------------------------------------- */
      builder.addCase(resetInventoryLevelsFilteredData.pending, (state) => {
        state.isLoading = true;
        state.errors = null;
      });
      builder.addCase(resetInventoryLevelsFilteredData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.filteredData = [];
        state.errors = null;
      });
      builder.addCase(resetInventoryLevelsFilteredData.rejected, (state) => {
        state.isLoading = false;
        state.errors = [{ msg: "something went wrong!" }];
      });
     /* --------------------------------------------------------------------------------------------------- */

  }
});

export default inventoryLevelsReport.reducer;