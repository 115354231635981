import React from 'react';
import { useTranslation } from "react-i18next";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"

const CustomLevelBranches = ({branchesOfItem}) => {
    //console.log(branchesOfItem)
   // const branchesTags=[]
    const { t } = useTranslation();
  return (
    <Box
    bg="light" overflow="auto"
    borderRadius={4} boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
  >
    <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>{t("pages.branches.name")}</Th>
          <Th>{t("pages.inventory.maximum_level")}</Th>
          <Th>{t("pages.inventory.minimum_level")}</Th>
          <Th>{t("pages.inventory.par_level")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {branchesOfItem.map((el, index) => (
          <Tr key={el.ItemsBranchesId}>
            <Td>{index + 1}</Td>
            <Td>{el?.Branches[0].BranchName}</Td>
            <Td>{el?.MaxLevel}</Td>
            <Td>{el?.MinLevel}</Td>
            <Td>{el?.ParLevel}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
  );
}

export default CustomLevelBranches;
