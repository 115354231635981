import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Flex, SimpleGrid, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";
import UpdateModal from "../components/modals/UpdateModal";
import GButton from "components/ui/button/Button";
import { getGroupById } from "../services/groupsService";
import SubGroups from '../components/SubGroups';
import Product from '../components/Product';
import Combo from '../components/Combo';
import GiftsCard from 'features/products/groups/components/GiftCard';
import CryptoJS from 'crypto-js';
import NotAuthorizedCompo from "components/ui/NotAuthorizedCompo/NotAuthorizedCompo";
import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from 'hooks/useDecryptedPrivilege ';

const Details = () => {

  const retrievedArray =useDecryptedPrivilege()
  const ManageMenu = checkIdExists(retrievedArray, 38);
  const ReadMenu = checkIdExists(retrievedArray, 37);

  const { groupId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const groups = useSelector(state => state.groups);
  const group = groups.group;

  const [showUpdateGroupModal, setShowUpdateGroupModal] = useState(null);

  useEffect(() => {
    dispatch(getGroupById({ groupId }));
  }, [dispatch, groupId]);

  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });


  if (groups.isLoading) return <Spinner />


  
  if(ManageMenu===false && ReadMenu===false ){
    return(
      <NotAuthorizedCompo/>
    )
  }

  if(showUpdateGroupModal && ManageMenu===false){
    toast2({ description:  t("general.authorization")})
    setShowUpdateGroupModal(false)
  }

  return group ? (
    <>
      <BreadCrumb>
        <Flex justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">
          <Box
            as="p" fontSize="1.6rem" textTransform="capitalize"
          >
            {group?.GroupName || "-"}
          </Box>
          <GButton
            title={t("general.edit")}
            bg="#54A0FF"
            color="light"
            hover={{ bg: "primary" }}
            onClick={() => setShowUpdateGroupModal({ ...group, GroupId: +groupId || null })}
          />
        </Flex>
      </BreadCrumb>
      <Stack spacing={12}>
        {/* details */}
        <SimpleGrid
          spacing={6}
          columns={{ base: 1, md: 2 }}
          bg="white"
          p={4} borderRadius={6}
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        >
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.groups.name")}
            </Text>
            <Text marginBlock={2}>
              {group?.GroupName || "-"}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="border">
            <Text textTransform="capitalize" color="text">
              {t("pages.groups.name_localized")}
            </Text>
            <Text marginBlock={2}>
              {group?.GroupNameLoc || "-"}
            </Text>
          </Box>
        </SimpleGrid>
        <SubGroups></SubGroups>
        <Product></Product>
        <Combo></Combo>
        <GiftsCard></GiftsCard>
      </Stack>

      {showUpdateGroupModal && (
        <UpdateModal
          data={showUpdateGroupModal}
          onClose={() => setShowUpdateGroupModal(null)}
        />
      )}
    </>
  ) : (
    <Flex bg="light" alignItems="center" justifyContent="center" p={4} textTransform="capitalize">
      {t("pages.groups.no_group")}
    </Flex>
  )
}

export default Details ;