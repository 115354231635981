import { Button, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";


const GModal = ({
  onClose,
  size = "md",
  modalHeader,
  modalContent
}) => {

  return (
    <>
      <Modal isOpen={true} onClose={onClose} size={size}  >
        <ModalOverlay  />
        <ModalContent >
          <ModalHeader
            borderBottom="1px solid" borderColor="border"
            textTransform="capitalize" color="dark"
          >
            {modalHeader}
          </ModalHeader>
          {modalContent}
          
        </ModalContent>
      
     
      </Modal>
    </>
  )
}

export default GModal