import { useTranslation } from "react-i18next";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import routes from "navigations/routes";
import BreadCrumb from "components/ui/breadcrumb/BreadCrumb";

export default function InventoryReports() {
  const { t, i18n } = useTranslation();
  const getArrowIcon = () => {
    return i18n.language === "ar" ? (
      <MdKeyboardArrowLeft size={25} /> // Right arrow for Arabic
    ) : (
      <MdKeyboardArrowRight size={25} /> // Left arrow for English
    );
  };

  const items = [
    {
      id: 1,
      text: t("pages.reports.inventory_reports.inventory_levels"),
      icon: getArrowIcon(),
      path: routes.reports_inventory_reports_inventory_levels,
    },
    {
      id: 2,
      text: t("pages.reports.inventory_reports.inventory_history"),
      icon: getArrowIcon(),
      path: routes.reports_inventory_reports_inventory_history,
    },
    {
      id: 3,
      text: t("pages.reports.inventory_reports.inventory_control"),
      icon: getArrowIcon(),
      path: routes.reports_inventory_reports_inventory_control,
    },
    {
      id: 4,
      text: t("pages.reports.inventory_reports.purchase_order"),
      icon: getArrowIcon(),
      path: routes.reports_inventory_reports_purchase_orders,
    },
    {
      id: 5,
      text: t("pages.reports.inventory_reports.transfers_orders"),
      icon: getArrowIcon(),
      path: routes.reports_inventory_reports_transfers_orders,
    },
    {
      id: 6,
      text: t("pages.reports.inventory_reports.transfers"),
      icon: getArrowIcon(),
      path: routes.reports_inventory_reports_transfers,
    },
    {
      id: 7,
      text: t("pages.reports.inventory_reports.purchasing"),
      icon: getArrowIcon(),
      path: routes.reports_inventory_reports_purchasing,
    },
    {
      id: 8,
      text: t("pages.reports.inventory_reports.cost_adjustment_history"),
      icon: getArrowIcon(),
      path: routes.reports_inventory_reports_cost_adjustment_history,
    },
  ];

  return (
    <>
      <BreadCrumb>
        <Box as="p" fontSize="1.6rem" textTransform="capitalize">
          {t("pages.inventory.inventory")}
        </Box>
      </BreadCrumb>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
        {items.map((item) => (
          <Flex
            key={item.id}
            as={NavLink}
            to={`/${item.path}`}
            gap={2}
            alignItems="center"
            justifyContent="center"
            bg="light"
            p={4}
            borderRadius={4}
            paddingBlock={6}
            color="text"
            textTransform="capitalize"
            boxShadow="5px 5px 20px 0px rgba(82, 63, 105, 0.05)"
          >
            {item.text}
            {item.icon}
          </Flex>
        ))}
      </SimpleGrid>
    </>
  );
}
