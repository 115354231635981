//youssef new
//youssefKe
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "./routes";
import ProtectedRoutes from "./ProtectedRoutes";

import Login from "../features/auth/pages/Login";

import Dashboard from "../features/dashboard/pages/Dashboard";
import DashboardGeneral from "../features/dashboard/tabs/general/pages/General";
// import DashboardBranches from "../features/dashboard/tabs/branches/pages/Branches";
// import DashboardInventory from "../features/dashboard/tabs/inventory/pages/inventory";
// import DashboardCallCenter from "../features/dashboard/tabs/callCenter/pages/CallCenter";

import Orders from "../features/orders/pages/Orders";

import CallCenter from "../features/callCenter/pages/CallCenter";

import Customers from "../features/customers/pages/Customers";
import CustomersDetails from "../features/customers/pages/Details";

import ProductsCategories from "../features/products/categories/pages/Categories";
import ProductsSortCategories from "../features/products/categories/pages/Sort";
import ProductsProducts from "../features/products/products/pages/Products";
import ProductsProductsDetails from "../features/products/products/pages/Details";
import ProductsCombos from "../features/products/combos/pages/Combos";
import ProductsCombosDetails from "../features/products/combos/pages/Details";
import ProductsGiftsCards from "../features/products/giftsCards/pages/GiftsCards";
import ProductsGiftsCardsDetails from "../features/products/giftsCards/pages/Details";
import ProductsModifiers from "../features/products/modifiers/pages/Modifiers";
import ProductsModifiersOptions from "../features/products/modifiersOptions/pages/ModifiersOptions";
import ProductsModifiersOptionsDetails from "../features/products/modifiersOptions/pages/Details";
import ProductsGroups from "../features/products/groups/pages/Groups";
import ProductsGroupsDetails from "../features/products/groups/pages/Details";

//menu

// inventory
import Items from "features/inventory/items/pages/Items";
import InventoryItemsDetails from "features/inventory/items/pages/InventoryItemsDetails";
import TransfersOrders from "features/inventory/transfersOrders/pages/TransfersOrders";
// Purchase Order
import PurchaseOrders from "features/inventory/purchaseOrders/pages/PurchaseOrders";
import PurchaseOrdersDetails from "features/inventory/purchaseOrders/pages/PurchaseOrdersDetails";

// purchasing
import Purchases from "features/inventory/purchasing/pages/Purchases";
import InventoryPurchasesDetails from "features/inventory/purchasing/pages/InventoryPurchasesDetails";
import ReturnSupplierDetails from "features/inventory/purchasing/pages/ReturnSupplierDetails";

// purchasing
import Transfers from "features/inventory/transfers/page/Transfers";
import TransferDetails from "features/inventory/transfers/page/TransferDetails";

//inventory more
import InventoryMorePage from "features/inventory/more/pages/InventoryMorePage";

// Cost Adjustment
import CostAdjustment from "features/inventory/costAdjustment/pages/CostAdjustment";
import CostAdjustmentDetails from "features/inventory/costAdjustment/pages/CostAdjustmentDetails";

//inventory quantity adjustments
import QuantityAdjustmentPage from "features/inventory/quantityAdjustment/pages/QuantityAdjustmentPage";
import DetailsPageQuantityAdjustmen from "features/inventory/quantityAdjustment/pages/DetailsPageQuantityAdjustmen";

// Order Transactions
import OrderTransactions from "../features/inventory/orderTransactions/pages/OrderTransactions";

// WareHouse
import Warehouse from "../features/inventory/warehouse/pages/Warehouse";
import WarehouseDetails from "../features/inventory/warehouse/pages/WarehouseDetails";

// Inventory Categories
import InventoryCategories from "../features/inventory/inventoryCategories/pages/InventoryCategories";

import ManagementUsers from "../features/management/users/pages/Users";
import ManagementRoles from "../features/management/roles/pages/Roles";

import ManagementDevices from "../features/management/devices/pages/Devices";
import DeviceDetails from "features/management/devices/pages/DeviceDetails";

import ManagementBranches from "../features/management/branches/pages/Branches";
import ManagementBranchesDetails from "../features/management/branches/pages/Details";

import Discounts from "../features/management/discounts/pages/Discounts";
import DiscountDetails from "../features/management/discounts/pages/DiscountDetails";

import ManagementCoupons from "../features/management/coupons/pages/Coupons";

import TimedEvents from "features/management/timedEvents/pages/TimedEvents";
import TimedEventsDetails from "features/management/timedEvents/pages/TimedEventsDetails";

import CouponsDetails from "../features/management/coupons/pages/CouponsDetails";
import ManagementPromotions from "../features/management/promotions/pages/Promotions";
import ManagementMore from "../features/management/more/pages/More";
import ManagementSettings from "../features/management/settings/pages/Settings";
import ManagementSettingsGeneral from "../features/management/settings/tabs/general/pages/General";
import ManagementSettingsLoyality from "../features/management/settings/tabs/loyality/pages/Loyality";
import CasherApp from "features/management/settings/tabs/casherApp/pages/CasherApp";
import ManagementSettingsReceipt from "../features/management/settings/tabs/receipt/pages/Receipt";
import ManagementSettingsCallCenter from "../features/management/settings/tabs/callCenter/pages/CallCenter";
import ManagementSettingsDisplayApp from "../features/management/settings/tabs/displayApp/pages/DisplayApp";
import ManagementSettingsDigitalWallet from "../features/management/settings/tabs/digitalWallet/pages/DigitalWallet";
import ManagementSettingsSmsProvider from "../features/management/settings/tabs/smsProvider/pages/SmsProvider";
import InventoryTransactions from "features/management/settings/tabs/inventoryTransactions/pages/InventoryTransactions";
import ManagementTaxes from "../features/management/taxesGroups/pages/Taxes";
import ManagementPaymentMethods from "../features/management/paymentMethods/pages/PaymentMethods";

// Management More
import OnlineOrdering from "features/management/onlineOrdering/pages/OnlineOrdering";
import DeliverChargepage from "features/management/deliveryCharge/pages/DeliverChargepage";
import ManagementCharges from "../features/management/charges/pages/Charges";
import ManagementBrands from "../features/management/brands/pages/Brands";
import ManagementTags from "../features/management/tags/pages/Tags";
import ManagementReasons from "../features/management/reasons/pages/Reasons";
import ManagementReservations from "../features/management/reservations/pages/Reservations";

//delivery zone
import DeliveryZonesPage from "features/management/deliveryZones/Pages/DeliveryZonesPage";
import DeliveryZoneDetailsPage from "features/management/deliveryZones/Pages/DeliveryZoneDetailsPage";
//Price Tags
import PriceTags from "../features/management/priceTags/pages/PriceTags";
import PriceTagsDetails from "../features/management/priceTags/pages/PriceTagsDetails";

import Suppliers from "features/inventory/suppliers/pages/Suppliers";
import SuppliersDetailsInventory from "features/inventory/suppliers/pages/SuppliersDetailsInventory";
import Production from "features/inventory/production/pages/Production";
import ProductionDetails from "features/inventory/production/pages/ProductionDetails";

import CallCenterProduct from "features/callCenter/pages/CallCenterProduct";
import TransfersOrdersDetails from "features/inventory/transfersOrders/pages/TransfersOrdersDetails";

import InventoryCount from "features/inventory/inventoryCount/pages/InventoryCount";
import InventoryCountDetails from "features/inventory/inventoryCount/pages/InventoryCountDetails";

import KitchenFlow from "features/management/kitchenFlow/pages/KitchenFlow";
import KitchenFlowDetails from "features/management/kitchenFlow/pages/KitchenFlowDetails";

//accounting
import AccountingPage from "features/general_accounts/AccountingTree/pages/AccountingPage";
import DailyRestrictionsPage from "features/general_accounts/DailyRestrictions/pages/DailyRestrictionsPage";
import LedgerPage from "features/general_accounts/Ledger/pages/LedgerPage";
import TrialBalancePage from "features/general_accounts/TrialBalance/pages/TrialBalancePage";
import LedgerBranchesPage from "features/general_accounts/LedgerBranches/pages/LedgerBranchesPage";
import IncomeStatementpage from "features/general_accounts/IncomeStatement/pages/IncomeStatementpage";
import ExpensesPage from "features/general_accounts/Expenses/Pages/ExpensesPage";
import BankAccountsPage from "features/general_accounts/BankAccounts/Pages/BankAccountsPage";
import ReceiptPage from "features/general_accounts/receipt/pages/ReceiptPage";
import AccountAddPage from "features/general_accounts/AccountAdd/pages/AccountAddPage";
// import MyTasksPage from "features/hr/myTasks/pages/MyTasksPage";
import DashboardHrPage from "features/hr/dashboardHr/pages/DashboardHrPage";
import EmployeesPage from "features/hr/employees/pages/EmployeesPage";
import EmployeeDetailsPage from "features/hr/employees/pages/EmployeeDetailsPage";
import TodayAttendancePage from "features/hr/todayAttendance/pages/TodayAttendancePage";
import AttendanceByDatePage from "features/hr/attendanceByDate/pages/AttendanceByDatePage";
import LeaveReportsPage from "features/hr/LeaveReports/pages/LeaveReportsPage";
import AttendanceSettingsPage from "features/hr/AttendanceSettings/pages/AttendanceSettingsPage";
import OverTimePage from "features/hr/overTme/pages/OverTimePage";
import PositionsPage from "features/hr/Positions/pages/PositionsPage";
import VacationListPage from "features/hr/VacationList/pages/VacationListPage";
import PayrollPage from "features/hr/Payroll/pages/PayrollPage";
import HRLayOut from "layout/HrLayOut/HRLayOut";
import WelcomeHrPage from "features/hr/welcomeHr/pages/WelcomeHrPage";
import AccountingLayOut from "layout/AccountingLayOut/AccountingLayOut";

import FinancialLayOut from "layout/FinancialLayOut/FinancialLayOut";
import OrderDetails from "features/orders/pages/OrderDetails";
import CreateOrderPage from "features/orders/pages/CreateOrderPage";
import BrancheSectionTable from "features/management/branches/pages/BrancheSectionTable";
import UserDetails from "features/management/users/pages/UserDetails";
import CreatePromotionPage from "features/management/promotions/pages/CreatePromotionPage";

//  ----------------------- Reports -----------------------
// Invenytory
import InventoryReports from "features/reports/inventory_reports/InventoryReports";
import InventoryLevels from "features/reports/inventory_reports/pages/inventory_levels/InventoryLevels";
import InventoryHistory from "features/reports/inventory_reports/pages/inventory_history/InventoryHistory";
import InventoryControl from "features/reports/inventory_reports/pages/inventory_control/InventoryControl";
import ReportPurchaseOrders from "features/reports/inventory_reports/pages/purchase_order/ReportPurchaseOrders";
import ReportTransfersOrders from "features/reports/inventory_reports/pages/transfers_orders/ReportTransfersOrders";
import ReportTransfers from "features/reports/inventory_reports/pages/transfers/ReportTransfers";
import ReportPurchasing from "features/reports/inventory_reports/pages/purchasing/ReportPurchasing";
import ReportCostAdjustment from "features/reports/inventory_reports/pages/cost_adjustment_history/ReportCostAdjustment";
// Sales
import SalesReports from "features/reports/sales_reports/SalesReports";
import SalesReport from "features/reports/sales_reports/pages/sales_seport/SalesReport";
import PaymentReport from "features/reports/sales_reports/pages/payment_report/PaymentReport";
// Business
import BusinessReports from "features/reports/business_reports/BusinessReports";
import ReportBusinessDays from "features/reports/business_reports/pages/business_days/ReportBusinessDays";
import ReportDrawerOperations from "features/reports/business_reports/pages/drawer_operations/ReportDrawerOperations";
import ReportGiftCards from "features/reports/business_reports/pages/gift_cards/ReportGiftCards";
import ReportShifts from "features/reports/business_reports/pages/shifts/ReportShifts";
import ReportTaxes from "features/reports/business_reports/pages/taxes/ReportTaxes";
import ReportTills from "features/reports/business_reports/pages/tills/ReportTills";
import ReportTips from "features/reports/business_reports/pages/tips/ReportTips";
import ReportVoidsReturns from "features/reports/business_reports/pages/voids_returns/ReportVoidsReturns";
// Analysis
import AnalysisReports from "features/reports/analysis_reports/AnalysisReports";
import MenuEngineering from "features/reports/analysis_reports/pages/menu_engineering/MenuEngineering";
import InventoryCostAnalysis from "features/reports/analysis_reports/pages/inventory_cost_analysis/InventoryCostAnalysis";
import SpeedOfServices from "features/reports/analysis_reports/pages/speed_of_services/SpeedOfServices";
import ProductCost from "features/reports/analysis_reports/pages/product_cost/ProductCost";
import ModifierOptionsCost from "features/reports/analysis_reports/pages/modifier_options_cost/ModifierOptionsCost";
import InventoryItemsCost from "features/reports/analysis_reports/pages/inventory_items_cost/InventoryItemsCost";
import BranchesTrend from "features/reports/analysis_reports/pages/branches_trend/BranchesTrend";
import Branches from "features/dashboard/tabs/branches/pages/Branches";
import Inventory from "features/dashboard/tabs/inventory/pages/Inventory";
import AddEmployeePage from "features/hr/employees/pages/addEmployee/AddEmployeePage";
import SignUp from "features/auth/pages/SignUp";
import Profile from "features/profile/Profile";
// import NewAccountingPage from "features/general_accounts/AccountingTree/pages/NewAccountingPage";
import GroupShift from "features/hr/AttendanceSettings/pages/GroupShift";
import TimeShift from "features/hr/AttendanceSettings/pages/TimeShift";
import TimeShiftDetails from "features/hr/AttendanceSettings/pages/TimeShiftDetails";
import AddNewGroupShift from "features/hr/AttendanceSettings/components/modal/AddNewGroupShift";
import GroupshiftDetails from "features/hr/AttendanceSettings/pages/GroupshiftDetails";
import HrSettings from "features/hr/hrSetting/pages/HrSettings";
import ReligionSetting from "features/hr/hrSetting/pages/religionSetting/ReligionSetting";
import CitiesSetting from "features/hr/hrSetting/pages/citiessetting/CitiesSetting";
import NationalitySetting from "features/hr/hrSetting/pages/nationalitysetting/NationalitySetting";
import HrSectionLayout from "layout/HrLayOut/HrSectionLayout";
import HrSelfServicesLayout from "layout/HrLayOut/HrSelfServicesLayout";
import LeaveRequests from "features/hr/selfServices/LeaveRequests/LeaveRequests";
import HrLetter from "features/hr/selfServices/hrLetter/HrLetter";
import BankLetter from "features/hr/selfServices/bankLetter/BankLetter";
import LeavePermission from "features/hr/selfServices/leavePermission/LeavePermission";
import VacationTypesSetting from "features/hr/hrSetting/pages/vacationTypesSetting/VacationTypesSetting";
import PermessionTypes from "features/hr/hrSetting/pages/permessiontypes/PermessionTypes";
import BouncesSetting from "features/hr/hrSetting/pages/bounces/BouncesSetting";
import HrSettingDepartment from "features/hr/hrSetting/pages/departments/HrSettingDepartment";

//hr

const Navigations = () => {
  return (
    <Routes>
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.signup} element={<SignUp />} />
      <Route element={<ProtectedRoutes displayLayout />}>
        <Route path={routes.profile} element={<Profile />} />
        <Route path={routes.dashboard} element={<Dashboard />}>
          <Route
            path={routes.dashboard_general}
            element={<DashboardGeneral />}
          />
          <Route path={routes.dashboard_branches} element={<Branches />} />
          <Route path={routes.dashboard_inventory} element={<Inventory />} />
          <Route path={routes.dashboard_call_center} element={<CallCenter />} />
        </Route>
        <Route path={routes.orders} element={<Orders />} />
        <Route path={routes.orderDetails} element={<OrderDetails />} />
        <Route path={routes.createOrder} element={<CreateOrderPage />} />

        <Route path={routes.call_center} element={<CallCenter />} />
        <Route
          path={routes.call_center_product}
          element={<CallCenterProduct />}
        />
        <Route path={routes.customers} element={<Customers />} />
        <Route path={routes.customersـdetails} element={<CustomersDetails />} />

        {/* --------------------------------- Start Reports-------------------------------- */}
        {/* Inventory Reports */}
        <Route
          path={routes.reports_inventory_reports}
          element={<InventoryReports />}
        />
        <Route
          path={routes.reports_inventory_reports_inventory_levels}
          element={<InventoryLevels />}
        />
        <Route
          path={routes.reports_inventory_reports_inventory_history}
          element={<InventoryHistory />}
        />
        <Route
          path={routes.reports_inventory_reports_inventory_control}
          element={<InventoryControl />}
        />
        <Route
          path={routes.reports_inventory_reports_purchase_orders}
          element={<ReportPurchaseOrders />}
        />
        <Route
          path={routes.reports_inventory_reports_transfers_orders}
          element={<ReportTransfersOrders />}
        />
        <Route
          path={routes.reports_inventory_reports_transfers}
          element={<ReportTransfers />}
        />
        <Route
          path={routes.reports_inventory_reports_purchasing}
          element={<ReportPurchasing />}
        />
        <Route
          path={routes.reports_inventory_reports_cost_adjustment_history}
          element={<ReportCostAdjustment />}
        />
        {/* Sales Reports */}
        <Route path={routes.reports_sales} element={<SalesReports />} />
        <Route
          path={routes.reports_sales_report_sales}
          element={<SalesReport />}
        />
        <Route
          path={routes.reports_sales_payment_reports}
          element={<PaymentReport />}
        />
        {/* Business Reports */}
        <Route
          path={routes.reports_business_reports}
          element={<BusinessReports />}
        />
        <Route
          path={routes.reports_business_reports_taxes}
          element={<ReportTaxes />}
        />
        <Route
          path={routes.reports_business_reports_tips}
          element={<ReportTips />}
        />
        <Route
          path={routes.reports_business_reports_shifts}
          element={<ReportShifts />}
        />
        <Route
          path={routes.reports_business_reports_drawer_operations}
          element={<ReportDrawerOperations />}
        />
        <Route
          path={routes.reports_business_reports_gift_cards}
          element={<ReportGiftCards />}
        />
        <Route
          path={routes.reports_business_reports_business_days}
          element={<ReportBusinessDays />}
        />
        <Route
          path={routes.reports_business_reports_void_returns}
          element={<ReportVoidsReturns />}
        />
        <Route
          path={routes.reports_business_reports_tills}
          element={<ReportTills />}
        />
        {/* Analysis Reports */}
        <Route
          path={routes.reports_analysis_reports}
          element={<AnalysisReports />}
        />
        <Route
          path={routes.reports_analysis_reports_menu_engineering}
          element={<MenuEngineering />}
        />
        <Route
          path={routes.reports_analysis_reports_inventory_cost_analysis}
          element={<InventoryCostAnalysis />}
        />
        <Route
          path={routes.reports_analysis_reports_branches_trend}
          element={<BranchesTrend />}
        />
        <Route
          path={routes.reports_analysis_reports_speed_of_services}
          element={<SpeedOfServices />}
        />
        <Route
          path={routes.reports_analysis_reports_product_cost}
          element={<ProductCost />}
        />
        <Route
          path={routes.reports_analysis_reports_modifier_options_cost}
          element={<ModifierOptionsCost />}
        />
        <Route
          path={routes.reports_analysis_reports_inventory_items__cost}
          element={<InventoryItemsCost />}
        />

        <Route path={routes.reports_payments} element={<ProductsCombos />} />
        {/* --------------------------------- End Reports----------------------------------- */}

        <Route path={routes.inventory_items} element={<Items />} />
        <Route
          path={routes.inventory_items_details}
          element={<InventoryItemsDetails />}
        />

        <Route path={routes.inventory_suppliers} element={<Suppliers />} />
        <Route
          path={routes.inventory_suppliers_details}
          element={<SuppliersDetailsInventory />}
        />

        <Route
          path={routes.inventory_transfers_orders}
          element={<TransfersOrders />}
        />
        <Route
          path={routes.inventory_transfers_orders_details}
          element={<TransfersOrdersDetails />}
        />
        <Route
          path={routes.inventory_inventory_count}
          element={<InventoryCount />}
        />
        <Route
          path={routes.inventory_inventory_count_details}
          element={<InventoryCountDetails />}
        />

        {/* purchase Orders */}
        <Route
          path={routes.inventory_purchase_orders}
          element={<PurchaseOrders />}
        />
        <Route
          path={routes.inventory_purchase_orders_details}
          element={<PurchaseOrdersDetails />}
        />

        {/* purchasing */}
        <Route path={routes.inventory_purchases} element={<Purchases />} />
        <Route
          path={routes.inventory_purchases_details}
          element={<InventoryPurchasesDetails />}
        />
        <Route
          path={routes.inventory_purchases_return_details}
          element={<ReturnSupplierDetails />}
        />

        {/* Transfers */}
        <Route path={routes.inventory_transfers} element={<Transfers />} />
        <Route
          path={routes.inventory_transfers_details}
          element={<TransferDetails />}
        />

        <Route
          path={routes.inventory_transfers}
          element={<ProductsModifiersOptions />}
        />
        <Route path={routes.inventory_production} element={<Production />} />
        <Route
          path={routes.inventory_production_details}
          element={<ProductionDetails />}
        />

        {/* inventory more */}
        <Route path={routes.inventory_More} element={<InventoryMorePage />} />

        {/* inventory Cost adjustment */}
        <Route
          path={routes.inventory_more_costadjsutment}
          element={<CostAdjustment />}
        />
        <Route
          path={routes.inventory_more_costadjsutment_Details}
          element={<CostAdjustmentDetails />}
        />

        {/* inventory Quantity adjustment */}
        <Route
          path={routes.inventory_quantity_adjustment}
          element={<QuantityAdjustmentPage />}
        />
        <Route
          path={routes.inventory_quantity_adjustment_details}
          element={<DetailsPageQuantityAdjustmen />}
        />

        {/* inventory Order Transactions */}
        <Route
          path={routes.inventory_order_transactions}
          element={<OrderTransactions />}
        />
        {/* <Route path={routes.inventory_quantity_adjustment_details} element={<DetailsPageQuantityAdjustmen />} /> */}

        {/* inventory WareHouse */}
        <Route path={routes.inventory_warehouse} element={<Warehouse />} />
        <Route
          path={routes.inventory_warehouse_details}
          element={<WarehouseDetails />}
        />

        {/* inventory Inventory_Categories */}
        <Route
          path={routes.inventory_Categories}
          element={<InventoryCategories />}
        />
        {/* <Route path={routes.inventory_warehouse_details} element={<WarehouseDetails />} /> */}

        <Route
          path={routes.products_categories}
          element={<ProductsCategories />}
        />
        <Route
          path={routes.products_sort_categories}
          element={<ProductsSortCategories />}
        />
        <Route path={routes.products_products} element={<ProductsProducts />} />
        <Route
          path={routes.products_products_details}
          element={<ProductsProductsDetails />}
        />
        <Route path={routes.products_combos} element={<ProductsCombos />} />
        <Route
          path={routes.products_combos_details}
          element={<ProductsCombosDetails />}
        />
        <Route
          path={routes.products_gifts_cards}
          element={<ProductsGiftsCards />}
        />
        <Route
          path={routes.products_gifts_cards_details}
          element={<ProductsGiftsCardsDetails />}
        />
        <Route
          path={routes.products_modifiers}
          element={<ProductsModifiers />}
        />
        <Route
          path={routes.products_modifiers_options}
          element={<ProductsModifiersOptions />}
        />
        <Route
          path={routes.products_modifiers_options_details}
          element={<ProductsModifiersOptionsDetails />}
        />
        <Route path={routes.products_groups} element={<ProductsGroups />} />
        <Route
          path={routes.products_groups_details}
          element={<ProductsGroupsDetails />}
        />

        <Route path={routes.management_users} element={<ManagementUsers />} />
        <Route
          path={routes.management_users_Details}
          element={<UserDetails />}
        />
        <Route path={routes.management_roles} element={<ManagementRoles />} />
        <Route
          path={routes.management_branches}
          element={<ManagementBranches />}
        />
        <Route
          path={routes.management_branches_details}
          element={<ManagementBranchesDetails />}
        />
        <Route
          path={routes.management_branches_details_sectionTables}
          element={<BrancheSectionTable />}
        />
        <Route
          path={routes.management_devices}
          element={<ManagementDevices />}
        />
        <Route
          path={routes.management_devices_details}
          element={<DeviceDetails />}
        />

        <Route path={routes.management_discount} element={<Discounts />} />
        <Route
          path={routes.management_discount_details}
          element={<DiscountDetails />}
        />

        <Route
          path={routes.management_coupons}
          element={<ManagementCoupons />}
        />
        <Route
          path={routes.management_coupons_details}
          element={<CouponsDetails />}
        />
        <Route
          path={routes.management_promotion}
          element={<ManagementPromotions />}
        />
        <Route
          path={routes.management_CreatePromotion}
          element={<CreatePromotionPage />}
        />
        <Route
          path={routes.management_timed_events}
          element={<TimedEvents />}
        />
        <Route
          path={routes.management_timed_events_details}
          element={<TimedEventsDetails />}
        />
        <Route path={routes.management_more} element={<ManagementMore />} />
        <Route
          path={routes.management_settings}
          element={<ManagementSettings />}
        >
          <Route
            path={`/${routes.management_settings_general}`}
            element={<ManagementSettingsGeneral />}
          />
          <Route
            path={`/${routes.management_settings_loyality}`}
            element={<ManagementSettingsLoyality />}
          />
          <Route
            path={`/${routes.management_settings_casherApp}`}
            element={<CasherApp />}
          />
          <Route
            path={`/${routes.management_settings_receipt}`}
            element={<ManagementSettingsReceipt />}
          />
          <Route
            path={`/${routes.management_settings_call_center}`}
            element={<ManagementSettingsCallCenter />}
          />
          <Route
            path={`/${routes.management_settings_display_app}`}
            element={<ManagementSettingsDisplayApp />}
          />
          <Route
            path={`/${routes.management_settings_digital_wallet}`}
            element={<ManagementSettingsDigitalWallet />}
          />
          <Route
            path={`/${routes.management_settings_sms_providers}`}
            element={<ManagementSettingsSmsProvider />}
          />
          <Route
            path={`/${routes.management_settings_inventory_transactions}`}
            element={<InventoryTransactions />}
          />
        </Route>
        <Route
          path={routes.management_taxes_groups}
          element={<ManagementTaxes />}
        />
        <Route
          path={routes.management_payment_methods}
          element={<ManagementPaymentMethods />}
        />

        <Route
          path={routes.management_OnlineOrdering}
          element={<OnlineOrdering />}
        />
        <Route
          path={routes.management_deliverCharge}
          element={<DeliverChargepage />}
        />

        <Route
          path={routes.management_charges}
          element={<ManagementCharges />}
        />
        <Route path={routes.management_brands} element={<ManagementBrands />} />
        <Route path={routes.management_tags} element={<ManagementTags />} />
        <Route
          path={routes.management_reasons}
          element={<ManagementReasons />}
        />

        <Route path={routes.management_kitchenFlow} element={<KitchenFlow />} />
        <Route
          path={routes.management_kitchenFlow_details}
          element={<KitchenFlowDetails />}
        />

        <Route
          path={routes.management_DeliverZone}
          element={<DeliveryZonesPage />}
        />
        <Route
          path={routes.management_DeliverZone_details}
          element={<DeliveryZoneDetailsPage />}
        />

        <Route
          path={routes.management_reservations}
          element={<ManagementReservations />}
        />

        {/* ------- Price Tags ------- */}
        <Route path={routes.management_PriceTags} element={<PriceTags />} />
        <Route
          path={routes.management_PriceTag_Details}
          element={<PriceTagsDetails />}
        />

        <Route path={routes.hr} element={<ManagementBranches />} />

        {/* HR*/}

        {/*<Route path={routes.MyTasks} element={<MyTasksPage />} />*/}
      </Route>

      <Route path={routes.HrNavigations} element={<HrSectionLayout />}>
        <Route
          path={routes.HrSelfServices}
          element={<HrSelfServicesLayout />}
        ></Route>
      </Route>
      {/*--------------------HRLayOut------------ */}
      <Route element={<HRLayOut />}>
        <Route element={<Navigate replace to={routes.dashboardHr} />} />
        <Route path={routes.dashboardHr} element={<DashboardHrPage />} />
        <Route path={routes.welcomHr} element={<WelcomeHrPage />} />
        <Route path={routes.Employees} element={<EmployeesPage />} />
        <Route
          path={routes.EmployeesDetails}
          element={<EmployeeDetailsPage />}
        />

        <Route path={routes.AddEmployee} element={<AddEmployeePage />} />
        <Route
          path={routes.TodayAttendance}
          element={<TodayAttendancePage />}
        />
        <Route
          path={routes.addendanceByDate}
          element={<AttendanceByDatePage />}
        />
        <Route path={routes.LeaveReports} element={<LeaveReportsPage />} />
        <Route
          path={routes.AttendanceSettings}
          element={<AttendanceSettingsPage />}
        />
        <Route path={routes.GroupShift} element={<GroupShift />} />
        <Route
          path={routes.GroupShiftDetails}
          element={<GroupshiftDetails />}
        />
        <Route path={routes.AddGroupShift} element={<AddNewGroupShift />} />
        <Route path={routes.TimeShift} element={<TimeShift />} />
        <Route path={routes.TimeShiftDetails} element={<TimeShiftDetails />} />
        <Route path={routes.overTime} element={<OverTimePage />} />
        <Route path={routes.Positions} element={<PositionsPage />} />
        <Route path={routes.VacationList} element={<VacationListPage />} />
        {/* --------------- Hr Setting Routers  --------------- */}
        <Route path={routes.setting} element={<HrSettings />} />
        <Route path={routes.religionsetting} element={<ReligionSetting />} />
        <Route path={routes.citiessetting} element={<CitiesSetting />} />
        <Route
          path={routes.nationalitysetting}
          element={<NationalitySetting />}
        />
        <Route
          path={routes.vacationTypessetting}
          element={<VacationTypesSetting />}
        />
        <Route
          path={routes.permessionTypessetting}
          element={<PermessionTypes />}
        />
        <Route path={routes.departmentssetting} element={<HrSettingDepartment />} />
        <Route path={routes.bouncessetting} element={<BouncesSetting />} />
        {/* --------------- Hr Setting Routers  --------------- */}
        {/* <Route path={routes.Payroll} element={<PayrollPage />} /> */}
      </Route>
      {/*--------------------HrSelfServicesLayout------------ */}

      <Route element={<HrSelfServicesLayout />}>
        <Route
          path={routes.ServicesLeaveRequests}
          element={<LeaveRequests />}
        />
        <Route
          path={routes.ServicesLeavePermission}
          element={<LeavePermission />}
        />
        <Route path={routes.ServicesBankLetter} element={<BankLetter />} />
        <Route path={routes.HrLetter} element={<HrLetter />} />
      </Route>
      {/*--------------------AccountingLayOut------------ */}
      <Route element={<AccountingLayOut />}>
        {/*AccountingPage  NewAccountingPage*/}
        <Route path={routes.general_accounts} element={<AccountingPage />} />
        <Route path={routes.Trial_Balance} element={<TrialBalancePage />} />
        <Route
          path={routes.income_statement}
          element={<IncomeStatementpage />}
        />
        <Route path={routes.Ledger} element={<LedgerPage />} />
        <Route path={routes.Ledger_branches} element={<LedgerBranchesPage />} />
        <Route path={routes.bankingAccount} element={<BankAccountsPage />} />
        <Route path={routes.AccountAdd} element={<AccountAddPage />} />
      </Route>

      <Route element={<FinancialLayOut />}>
        <Route
          path={routes.Daily_restrictions}
          element={<DailyRestrictionsPage />}
        />
        <Route path={routes.Expenses} element={<ExpensesPage />} />
        <Route path={routes.receipt} element={<ReceiptPage />} />
      </Route>
    </Routes>
  );
};

export default Navigations;
