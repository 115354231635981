import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { getPaymentMethods } from "features/management/paymentMethods/services/paymentMethodsService";
import { getDigitalWalletSettings } from "../services/digitalWalletService";
import WalletList from "../component/WalletList";

const DigitalWallet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const walletList = useSelector((state) => state.digitalWallet);

  useEffect(() => {
    dispatch(getDigitalWalletSettings());
  }, [dispatch]);



  return (
    <>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Flex
          marginTop="1%"
          alignItems="center"
          justifyContent="space-between"
          w={"70%"}
          gap={2}
          flexWrap="wrap"
          mb={6}
        >
          <Text color="text" textTransform="capitalize">
            {t(
              "pages.management.settings.digital_wallet.payment_integrations_settings"
            )}
          </Text>
        </Flex>
        <Flex
          p={4}
          bg="white"
          alignItems="center"
          justifyContent="center"
          color="text"
          flexDirection="column"
          textTransform="capitalize"
          boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
          borderRadius={6}
          padding="5px 0px"
          w={"70%"}
        >
          <WalletList list={walletList.data} />
        </Flex>
      </Flex>
    </>
  );
};

export default DigitalWallet;
