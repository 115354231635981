import { createSlice } from "@reduxjs/toolkit";
import {
  getModifiers,
  createModifier,
  updateModifier,
  deleteModifier,
  getDeleted,
  restoreModifier,
  getFilteredData,
  resetFilteredData,
} from "../services/modifiersService";

let initialState = {
  isLoading: false,
  data: [],
  pages: 0,
  errors: null,
  filteredData:[],
};

const modifiersSlice = createSlice({
  name: "modifiers",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getModifiers.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getModifiers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getModifiers.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(createModifier.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(createModifier.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    });
    builder.addCase(createModifier.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(updateModifier.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(updateModifier.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const indexAt = state.data.findIndex(modifier => modifier.id === payload.id);
      // state.data[indexAt] = payload;
      state.errors = null;
    });
    builder.addCase(updateModifier.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(deleteModifier.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(deleteModifier.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = state.data.filter(modifier => modifier.id !== payload.id);
      state.errors = null;
    });
    builder.addCase(deleteModifier.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getDeleted.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getDeleted.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.item = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.errors = null;
    });
    builder.addCase(getDeleted.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(restoreModifier.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(restoreModifier.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(restoreModifier.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(getFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.filteredData = payload;
      // console.log("youssef" , state.filteredData);
      state.errors = null;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });

    builder.addCase(resetFilteredData.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(resetFilteredData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      state.filteredData = [];
      state.errors = null;
    });
    builder.addCase(resetFilteredData.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default modifiersSlice.reducer;
