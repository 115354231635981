import React, { useState } from "react";
import { Box, Text, Flex, SimpleGrid, Button } from "@chakra-ui/react";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import EditNationalityModal from "../modal/EditNationalityModal";
import GButton from "components/ui/button/Button";

const NationalIdCompo = ({ details, nationalityDropDown }) => {
  const { t } = useTranslation();
  const [showEditNationalityModal, setShowEditNationalityModal] =
    useState(false);

  return (
    <Box
      marginBottom={10}
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      border="1px"
      borderColor={"gray.400"}
      width="100%"
      padding="10px"
    >
      <Box padding={3}>
        <Box>
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"2px"}
            borderColor={"gray.300"}
            padding={3}
          >
            <Flex gap={2} alignItems={"center"}>
              <HiOutlineClipboardDocumentList style={{ fontSize: "22px" }} />
              <Text fontSize="xl" fontWeight={"bold"} color="gray.600">
                {t("pages.hr.National_id")}
              </Text>
            </Flex>
            <Box onClick={() => setShowEditNationalityModal(true)}>
              <GButton
                title={t("general.edit")}
                color="black"
                border="1px solid gray"
              />
            </Box>
          </Flex>
        </Box>
        <Box padding={3}>
          <SimpleGrid columns={2} spacing={5}>
            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.National_id")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.NationalID}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.extraction_place")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.NationalIDPlace}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.nationality")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.NationalityNameEn}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.extraction_date")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.ExtractionDate}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.end_date")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.EndDate}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
      {showEditNationalityModal && (
        <EditNationalityModal
          onClose={() => setShowEditNationalityModal(false)}
          nationalityDropDown={nationalityDropDown}
          id={details?.EmployeeId}
          details={details}
        />
      )}
    </Box>
  );
};

export default NationalIdCompo;
