import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import { FaUsers, FaEarthAmericas, FaCalendarCheck } from "react-icons/fa6";
import { PiBagSimpleFill } from "react-icons/pi";
import { IoBook } from "react-icons/io5";
import { FaCity } from "react-icons/fa";
import { BsFillCalendar3WeekFill } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi2";
import { TiPlus } from "react-icons/ti";
import { FaUnlock } from "react-icons/fa";
import routes from "navigations/routes";
export default function HrSettings() {
  const { t } = useTranslation();

  return (
    <>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.setting")}
          </Text>
        </Flex>
      </Box>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
        py={"20px"}
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <Link to={`/${routes.Positions}`}>
              <Box
                cursor={"pointer"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#EAF2F6"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"primary"}
                borderRadius={5}
                gap={2}
                width={"160px"}
                height={"160px"}
              >
                <FaUsers size={60} />
                <Text fontWeight={"semibold"} fontSize={"xl"}>
                  {t("pages.hr.Positions")}
                </Text>
              </Box>
            </Link>
            <Link to={routes.departmentssetting}>
              <Box
                cursor={"pointer"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#EAF2F6"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"primary"}
                borderRadius={5}
                gap={2}
                width={"160px"}
                height={"160px"}
              >
                <PiBagSimpleFill size={60} />
                <Text fontWeight={"semibold"} fontSize={"xl"}>
                  {t("pages.hr.departments")}
                </Text>
              </Box>
            </Link>
          </Flex>
        </Flex>

        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <Link to={routes.citiessetting}>
              <Box
                cursor={"pointer"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#EAF2F6"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"primary"}
                borderRadius={5}
                gap={2}
                width={"160px"}
                height={"160px"}
              >
                <FaCity size={60} />
                <Text fontWeight={"semibold"} fontSize={"xl"}>
                  {t("pages.hr.cities")}
                </Text>
              </Box>
            </Link>

            <Link to={routes.nationalitysetting}>
              <Box
                cursor={"pointer"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#EAF2F6"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"primary"}
                borderRadius={5}
                gap={2}
                width={"160px"}
                height={"160px"}
              >
                <FaEarthAmericas size={60} />
                <Text fontWeight={"semibold"} fontSize={"xl"}>
                  {t("pages.hr.nationalities")}
                </Text>
              </Box>
            </Link>

            <Link to={routes.religionsetting}>
              <Box
                cursor={"pointer"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#EAF2F6"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"primary"}
                borderRadius={5}
                gap={2}
                width={"160px"}
                height={"160px"}
              >
                <IoBook size={60} />
                <Text fontWeight={"semibold"} fontSize={"xl"}>
                  {t("pages.hr.religions")}
                </Text>
              </Box>
            </Link>
          </Flex>
        </Flex>

        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <Link to={`/${routes.VacationList}`}>
              <Box
                cursor={"pointer"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#EAF2F6"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"primary"}
                borderRadius={5}
                gap={2}
                width={"160px"}
                height={"160px"}
              >
                <FaCalendarCheck size={60} />
                <Text
                  fontWeight={"semibold"}
                  fontSize={"xl"}
                  textAlign={"center"}
                >
                  {t("pages.hr.vacation_official")}
                </Text>
              </Box>
            </Link>

            <Link to={routes.vacationTypessetting}>
              <Box
                cursor={"pointer"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#EAF2F6"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"primary"}
                borderRadius={5}
                gap={2}
                width={"160px"}
                height={"160px"}
              >
                <BsFillCalendar3WeekFill size={60} />
                <Text
                  fontWeight={"semibold"}
                  fontSize={"xl"}
                  textAlign={"center"}
                >
                  {t("pages.hr.vacation_types")}
                </Text>
              </Box>
            </Link>
          </Flex>
        </Flex>

        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <Link to={routes.permessionTypessetting}>
              <Box
                cursor={"pointer"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#EAF2F6"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"primary"}
                borderRadius={5}
                gap={2}
                width={"160px"}
                height={"160px"}
              >
                <FaUnlock size={60} />
                <Text
                  fontWeight={"semibold"}
                  fontSize={"xl"}
                  textAlign={"center"}
                >
                  {t("pages.hr.permessions_types")}
                </Text>
              </Box>
            </Link>
            {/* <Link to={routes.GroupShift}> */}
            {/* <Box
              cursor={"pointer"}
              display={"flex"}
              flexDirection={"column"}
              bg={"#EAF2F6"}
              paddingY={5}
              paddingX={10}
              justifyContent={"center"}
              alignItems={"center"}
              color={"primary"}
              borderRadius={5}
              gap={2}
              width={"160px"}
              height={"160px"}
            >
              <HiDocumentText size={60} />
              <Text
                fontWeight={"semibold"}
                fontSize={"xl"}
                textAlign={"center"}
              >
                {t("pages.hr.employee_document")}
              </Text>
            </Box> */}
            {/* </Link> */}
            <Link to={routes.bouncessetting}>
              <Box
                cursor={"pointer"}
                display={"flex"}
                flexDirection={"column"}
                bg={"#EAF2F6"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"primary"}
                borderRadius={5}
                gap={2}
                width={"160px"}
                height={"160px"}
              >
                <TiPlus size={60} />
                <Text
                  fontWeight={"semibold"}
                  fontSize={"xl"}
                  textAlign={"center"}
                >
                  {t("pages.hr.bounces")}
                </Text>
              </Box>
            </Link>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
