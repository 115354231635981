import { createSlice } from "@reduxjs/toolkit";
import {getCustomPriceModifierOptionById,addOutOfStockbranche,getOutOfStock,addDeactiveBranchModifier,getDeactiveBranchesModififers} from "../services/modifiersOptionsCustomService"

let initialState = {
    isLoading: false,
    data: [],
    pages: 0,
    errors: null,
    outOfStock:[],
    inActiveBranches:[]
  };


  const modifierOptionCustomSlice = createSlice({
    name: "modifiersOptionscustom",
    initialState,

    extraReducers: (builder) => {


    builder.addCase(getCustomPriceModifierOptionById.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getCustomPriceModifierOptionById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.data)
      state.errors = null;
    })
    builder.addCase(getCustomPriceModifierOptionById.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })



    //


    builder.addCase(addOutOfStockbranche.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(addOutOfStockbranche.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(addOutOfStockbranche.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    //

    builder.addCase(getOutOfStock.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getOutOfStock.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.outOfStock = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.outOfStock)
      state.errors = null;
    })
    builder.addCase(getOutOfStock.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })


    //


    builder.addCase(addDeactiveBranchModifier.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(addDeactiveBranchModifier.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // state.data.push(payload);
      state.errors = null;
    })
    builder.addCase(addDeactiveBranchModifier.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })



    //


    builder.addCase(getDeactiveBranchesModififers.pending, (state) => {
      state.isLoading = true;
      state.errors = null;
    })
    builder.addCase(getDeactiveBranchesModififers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.inActiveBranches = (payload[0] && JSON.parse(payload[0].jsonData)) || [];
      //console.log(state.inActiveBranches)
      state.errors = null;
    })
    builder.addCase(getDeactiveBranchesModififers.rejected, (state) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    })

    }
  })


  export default modifierOptionCustomSlice.reducer;
  