import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../../services/axios";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/Users/GetAllUsers`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (args, thunkApi) => {
    try {
      const res = await axios.post("/api/Users/AddUser", args);
      //console.log(res)
      return thunkApi.fulfillWithValue(res);
    } catch (e) {
      //console.log(e)
      return (e.response.data);
    }
  }
);

export const updateUser = createAsyncThunk("users/updateUser", async (args, thunkApi) => {
  console.log(args);
    try {
      const { data } = await axios.put("/api/Users/UpdateUser", args);
      //console.log(data);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
/* ---------------------------------------------------------- Delete User --------------------------------------------------------*/
export const deleteUser = createAsyncThunk( "users/deleteUser",  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(`/api/Users/DeleteUser/${args.id}`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

  /* ----------------------------------------------------------------- Filter Data  -------------------------------------------------------*/
  export const getUsersFilteredData = createAsyncThunk("users/getUsersFilteredData ", async (args, thunkApi) => {
    try {
      return (args);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);
export const resetUsersFilteredData = createAsyncThunk( "users/resetUsersFilteredData ", async (args, thunkApi) => { 
    try {
      return args;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getLanguage = createAsyncThunk(
  "users/getLanguage",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Setting/ReciptSetting/LookUpLanguage`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getTagGroup = createAsyncThunk(
  "users/getTagGroup",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage/Taxs/LookUpTagGroup`);
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);


export const getUserById = createAsyncThunk(
  "users/getUserById",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/Manage2/Users/UserById?branchid=${args}`);
      //console.log(data)
      return thunkApi.fulfillWithValue(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);