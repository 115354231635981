import { Link as ChakraLink, Flex } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const Tabs = ({ items = [] }) => {

  return (
    <Flex
      alignItems="center" flexWrap="wrap" mt={-6} marginInline={-6} mb={6}
      textTransform="capitalize" gap={6} color="text" bg="light" paddingInline={6}
    >
      {items.map((item, idx) => (
        <ChakraLink key={idx} className="tab-link"
          as={NavLink} to={`/${item.path}`} end
          display="inline-block" pb={1}
          fontSize=".9rem" fontWeight="normal" _hover={{ textDecoration: "none" }}
        >
          {item.text}
        </ChakraLink>
      ))}
    </Flex>
  )
}

export default Tabs;